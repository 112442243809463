import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import "../../index.css";
import zoomImg from "../../images/zoomCompany.png";
import Cookies from 'js-cookie';
import calendaryLogo from "../../images/ScheduleAILogo.png";

import popupData from "../../audio/popup-alert.mp3";
import pageData from "../../audio/page-turn.wav";
import { Toast } from 'react-bootstrap';

function ZoomIntegrationSetup() {

    const serverUrl = "https://" + process.env.REACT_APP_DOMAINNAME + ":8443";
    const [user, setUser] = useState(null);
    const [access_token, setAccess_Token] = useState(null);
    const [refresh_token, setRefreshToken] = useState(null);
    const [integratedUser, setIntegratedUser] = useState(null);
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [codeAvailable, setCodeAvailable] = useState(false);

    const [disabled, setDisabled] = useState(true);

    const popupAlert = new Audio(popupData);
    const pageTurnSound = new Audio(pageData);

    const [viewToast, setViewToast] = useState(false);
    const [toastTitle, setToastTitle] = useState(null);
    const [toastBody, setToastBody] = useState(null);
    const [toastMessageType, setToastMessageType] = useState(null);

    useEffect(() => {
        async function getCookies() {

            let gettingUserByCookie = null;

            if (process.env.REACT_APP_DEPLOY_MODE === "production") {
                let b = Cookies.get('jwtToken')
                const get = await axios.get(serverUrl + "/getCookieUser", {
                    headers: {
                        "Authorization": `Bearer ${b}`
                    }
                });
                if (get.status === 200) {
                    if (Object.keys(get.data).length > 0 && Boolean(get.data)) {
                        gettingUserByCookie = await axios.post(serverUrl + "/users/getLoginUserNow", { "email": get.data.email });
                    } else {
                        window.location.href = "/login";
                        // console.log("undefinned cookies")
                    }
                } else {
                    window.location.href = "/login";
                    // console.log("undefined get");
                }
            }
            else {
                gettingUserByCookie = await axios.post(serverUrl + "/users/getLoginUserNow", { "email": "gulfan@kcloudtechnologies.com" });
            }


            if (gettingUserByCookie.status === 200) {
                setUser(gettingUserByCookie.data);

                const queryParams = new URLSearchParams(window.location.search)
                const code = queryParams.get("code");
                if (code) {
                    const getTokens = await axios.post(serverUrl + "/events/getZoomToken", { "code": code });
                    if (getTokens.status === 200) {
                        // console.log(getTokens.data);
                        setCodeAvailable(true);
                        setAccess_Token(getTokens.data.access_token);
                        setRefreshToken(getTokens.data.refresh_token);
                        setDisabled(false);


                        const saveNewTokens = await axios.post(serverUrl + "/events/saveNewZoomRefreshToken", { "tokens": getTokens.data, "email": gettingUserByCookie.data.email });
                        if (saveNewTokens.status === 200) {

                            // console.log(saveNewTokens.data);
                            const updatedUserData = await axios.post(serverUrl + "/users/getLoginUserNow", { "email": gettingUserByCookie.data.email });
                            if (updatedUserData) {
                                const getDecoded = await axios.post(serverUrl + "/events/decodeJWT", { "code": updatedUserData.data.integrated.zoom });
                                // console.log(getDecoded.data);

                                setIntegratedUser(getDecoded.data);
                                setFinishedLoading(true);


                                setViewToast(true);
                                popupAlert.play();
                                setToastTitle("Successful");
                                setToastBody("Zoom account integrated successfully.");
                                setToastMessageType("success");

                            } else {
                                // console.log("unable to get updated user");
                                setFinishedLoading(true);

                                setViewToast(true);
                                popupAlert.play();
                                setToastTitle("Error");
                                setToastBody("unable to get updated user");
                                setToastMessageType("invalid");
                            }
                        } else {
                            // console.log(saveNewTokens.data);

                            setViewToast(true);
                            popupAlert.play();
                            setToastTitle("Error");
                            setToastBody(saveNewTokens.data);
                            setToastMessageType("invalid");
                        }
                    } else {
                        // console.log(getTokens.data);
                        setCodeAvailable(false);
                        const updatedUserData = await axios.post(serverUrl + "/users/getLoginUserNow", { "email": gettingUserByCookie.data.email });
                        if (updatedUserData) {
                            if (updatedUserData.data.integrated) {
                                if (updatedUserData.data.integrated.zoom) {

                                    const getDecoded = await axios.post(serverUrl + "/events/decodeJWT", { "code": updatedUserData.data.integrated.zoom });
                                    // console.log(getDecoded.data);

                                    setIntegratedUser(getDecoded.data);
                                }
                            }
                            setFinishedLoading(true);
                            // console.log(updatedUserData.data);
                        } else {
                            // console.log("unable to get updated user");
                            setFinishedLoading(true);

                            setViewToast(true);
                            popupAlert.play();
                            setToastTitle("Error");
                            setToastBody("unable to get updated user");
                            setToastMessageType("invalid");
                        }
                    }
                } else {
                    const updatedUserData = await axios.post(serverUrl + "/users/getLoginUserNow", { "email": gettingUserByCookie.data.email });
                    if (updatedUserData) {
                        if (updatedUserData.data.integrated) {
                            if (updatedUserData.data.integrated.zoom) {

                                const getDecoded = await axios.post(serverUrl + "/events/decodeJWT", { "code": updatedUserData.data.integrated.zoom });
                                // console.log(getDecoded.data);

                                setIntegratedUser(getDecoded.data);
                            }
                        }
                        setFinishedLoading(true);
                        // console.log(updatedUserData.data);
                    } else {
                        // console.log("unable to get updated user");
                        setFinishedLoading(true);

                        setViewToast(true);
                        popupAlert.play();
                        setToastTitle("Error");
                        setToastBody("unable to get updated user");
                        setToastMessageType("invalid");
                    }
                }
            } else {
                // console.log("getting user error");
                window.location.href = "/login";
            }
        }
        getCookies();
    }, [])

    const deauthorizeZoomNow = async (id, secret, email) => {
        // console.log("deauthorized");

        const load = document.querySelector("#deauthorizeButton");
        load.disabled = true;
        load.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>Loading..';

        const query = {
            "user_id": id,
            "user_accessToken": secret,
            "email": email
        }
        const deauth = await axios.post(serverUrl + "/events/deauthorization/zoom", query);
        if (deauth.status === 200) {
            setIntegratedUser(null);

            setViewToast(true);
            popupAlert.play();
            setToastTitle("Successful");
            setToastBody("Zoom Account Deauthorized.");
            setToastMessageType("success");
            setCodeAvailable(false);

            load.disabled = false;
            load.innerHTML = "Deauthorize";
        } else {

            setViewToast(true);
            popupAlert.play();
            setToastTitle("Error");
            setToastBody(deauth.data);
            setToastMessageType("invalid");

            load.disabled = false;
            load.innerHTML = "Deauthorize";
        }
    }
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div className="rounded">
                            <div className="p-3 border-bottom">
                                <h1 className="display-6 fw-bold text-center py-3 text-primary">Connect Schedule AI with Zoom</h1>
                                <p className="text-center">Add Zoom in your account so we can automatically create Zoom meeting links for you.</p>
                            </div>
                            <div className="p-3 d-flex justify-content-center align-items-center gap-5">
                                <div className="Calendary">
                                    <img src={calendaryLogo} className='' style={{ height: "100px" }} alt="" />
                                </div>
                                <div className="display-1"><i className="fa fa-plus"></i></div>
                                <div className="zoom">
                                    <img src={zoomImg} className='' style={{ height: "100px" }} alt="" />
                                </div>
                            </div>

                            {
                                finishedLoading && integratedUser && integratedUser.user ?
                                    <div className="container-fluid insightBackground rounded border shadow-sm my-3" style={{ maxWidth: "600px", width: "max-content" }}>
                                        <div className="row">
                                            <div className="col-12 col-sm-4 col-md-4 col-lg-4 p-2">
                                                <div className='w-100 h-100 border rounded d-flex flex-column align-items-center justify-content-center p-2'>
                                                    <img src={integratedUser.user.pic_url} alt="" className='rounded-circle w-100' style={{ maxWidth: "60px" }} />
                                                    <span className='smallTextSize fw-bold text-center'>{integratedUser.user.display_name}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-8 col-md-8 col-lg-8 p-2">
                                                <div className='w-100 h-100 d-flex flex-column align-items-center justify-content-center'>
                                                    <p className='alert p-1 text-center alert-secondary smallTextSize fw-bold'>Wanna switch or remove the integration?</p>
                                                    <div className='d-flex gap-2 justify-content-center w-100'>
                                                        <button className='btn btn-danger btn-sm smallTextSize w-100' id="deauthorizeButton" style={{ maxWidth: "120px" }} onClick={() => { deauthorizeZoomNow(integratedUser.user.id, integratedUser.access_token, user.email); }}>Deauthorize</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    !integratedUser && finishedLoading ?
                                        <div className="container-fluid insightBackground rounded border shadow-sm my-3" style={{ maxWidth: "600px", width: "max-content" }}>
                                            <div className="row">
                                                <div className="col-12 col-sm-4 col-md-4 col-lg-4 p-2">
                                                    <div className='w-100 h-100 border rounded d-flex flex-column align-items-center justify-content-center '>
                                                        <img src={user?.profilePicture} alt="" className='rounded-circle w-100' style={{ maxWidth: "60px" }} />
                                                        <span className='smallTextSize fw-bold text-center'>{user?.name}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-8 col-md-8 col-lg-8 p-2">
                                                    <div className='w-100 h-100 d-flex flex-column align-items-center justify-content-center'>
                                                        <p className='alert p-1 text-center alert-secondary smallTextSize fw-bold'>No account is integrated! Connect your account now by clicking the button below:</p>
                                                        <div className='d-flex gap-2 justify-content-center w-100'>
                                                            <a
                                                                className='w-100'
                                                                style={{ maxWidth: "120px" }}
                                                                href={`https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_DOMAINNAME === "scheduleai.co" ? process.env.REACT_APP_ZOOM_PRODUCTION_CLIENT_ID : process.env.REACT_APP_ZOOM_SANDBOX_CLIENT_ID}&redirect_uri=https://${process.env.REACT_APP_DOMAINNAME}/integration/zoom`}>
                                                                <button className='btn salesforce-style btn-sm smallTextSize w-100'>Connect</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ""
                            }

                            {
                                codeAvailable && (
                                    <div className="border-top text-center py-4">
                                        {
                                            disabled ? (
                                                <p className="text-warning py-2">Connecting with Zoom</p>
                                            )
                                                :
                                                (
                                                    <>
                                                        <p className="text-success py-2">Successfully Integrated with Zoom</p>
                                                        <button className="btn salesforce-style rounded-pill px-5" onClick={() => { window.location.href = "/calendar#Integrations" }} disabled={disabled}>Continue</button>
                                                    </>
                                                )
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* ####################################################################################### */}
            <div style={{ position: "fixed", bottom: "10px", right: "10px", zIndex: "99999999" }}>
                <Toast onClose={() => setViewToast(false)} show={viewToast} delay={3000} autohide className='overflow-hidden'>
                    <Toast.Header>
                        {/* <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2 bg-primary"
                        alt="toastImage"
                        /> */}
                        <span style={{ fontWeight: "500" }} className={`me-auto ${toastMessageType === "invalid" ? "text-danger" : "text-success"}`}>{toastTitle}</span>
                        <small>just now</small>
                    </Toast.Header>
                    <Toast.Body style={toastMessageType === "success" ? { fontWeight: "500", background: "#d1e7dd", color: "#0f5132" } : { fontWeight: "500", background: "#f8d7da", color: "#842029" }}>
                        {toastBody}

                    </Toast.Body>
                </Toast>
            </div>
        </>
    )
}

export default ZoomIntegrationSetup