import React from 'react'
import ConsumerGoodsScheduleAiBannerPng from "../../images/Consumer Goods Schedule Ai Banner.png";
import StreamlinedSchedulingPng from "../../images/Streamlined Scheduling.png";
import ImprovedCustomerServicePng from "../../images/Improved Customer Service.png";
import IncreasedEfficiencyPng from "../../images/Increased Efficiency.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';

const ConsumerGoods = () => {
  return (
    <>
      <div className="heWhoRemains">
        <Header />
        <div>
          <section className='p'>
            <div className="container mt-5">
              <div className="row webDevRow">

                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                  <div className="mt-4 ">
                    <h1 className="mainHomeTitle">Consumer Goods Business With
                      <span style={{ color: "#0B5ED7" }}> Schedule AI Software
                      </span>
                    </h1>
                    <p className="lead_smd">
                      Are you in the consumer goods industry looking for a way to streamline operations and increase efficiency? Look no further than ScheduleAI scheduling software. This powerful tool provides an easy way to manage customer appointments and keep track of orders, making it a great choice for any business.

                    </p>
                    <a href="/login"><button
                      className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                      Demo</button></a>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <img className="w-100" src={ConsumerGoodsScheduleAiBannerPng} alt='Consumer Goods Schedule Ai Banner' />
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container mt-5">
              <div className="column pt-5">
                <h2 className="text-center display-6 fw-bold">Benefits of Using Schedule AI Software
                </h2>
              </div>
              <div className="row webDevRow my-5">
                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                  <div>

                    <h1 className="display-6 smd_title" style={{ fontWeight: "400" }}>  Streamlined Scheduling
                    </h1>
                    <p className="lead_smd"> Appointment scheduling software makes it easy to schedule appointments and track cancellations or reschedules. This helps to ensure that your customers can always get the time slot that they need.</p>

                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <img className="mainDevImg" src={StreamlinedSchedulingPng} alt="Streamlined Scheduling" />
                </div>

              </div>
            </div>
          </section>

          <section>
            <div className="container mt-5">
              <div className="row webDevRow">
                <div className="col-12 col-lg-6 mt-5">
                  <img className="mainDevImg" src={ImprovedCustomerServicePng} alt="Improved Customer Service" />
                </div>

                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                  <div>

                    <h1 className="display-6 smd_title">  Improved Customer Service
                    </h1>
                    <p className="lead_smd"> When appointments are well-organized, it leads to improved customer service. This means happier customers, which can lead to increased repeat business and positive online reviews.
                    </p>

                  </div>
                </div>

              </div>
            </div>
          </section>

          <section>
            <div className="container mt-5">
              <div className="row webDevRow">

                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                  <div>

                    <h1 className="display-6 smd_title">  Increased Efficiency
                    </h1>
                    <p className="lead_smd"> Appointment scheduling software can help you to be more efficient in how you run your business. This leads to savings on time and labor costs, which can boost your bottom line.

                      If you are looking for ways to improve your consumer goods business, appointment scheduling software is a great solution. It can help you streamline your scheduling process, improve customer service, and increase efficiency–all of which can lead to increased profits.
                    </p>

                  </div>
                </div>
                <div className="col-12 col-lg-6 mt-5">
                  <img className="w-100" src={IncreasedEfficiencyPng} alt="Increased Efficiency" />
                </div>

              </div>
            </div>
          </section>

          <section>
            <div className="container textDevLay pt-5 pb-4">
              <div className="column pt-5">
                <h2 className="text-center display-6 fw-bold">Conclusion
                </h2>
                <p className='lead_smd text-center'>ScheduleAI software is a great way to make the most of your consumer goods business. It helps you streamline operations, provide an enhanced customer experience, and increase efficiency. With this software, you can reduce manual tasks and focus on delivering quality services to your customers. By using ScheduleAI software for your consumer goods business, you can ensure that every customer gets the best possible service each time they visit. This will help build brand loyalty and create a lasting relationship with them in the long run.
                </p>
              </div>
            </div>
          </section>

        </div>

        <Footer />
      </div>
    </>
  )
}

export default ConsumerGoods
