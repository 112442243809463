import React, {useEffect} from 'react';
import IndustrialAndProcessManufacturingBannerPng from "../../images/Industrial and process manufacturing banner.png";
import ImprovedCommunicationAndCollaborationPng from "../../images/Improved communication and collaboration.png";
import IncreasedCustomerSatisfactionPng from "../../images/Increased customer satisfaction.png";
import ImprovedEfficiencyPng from "../../images/Improved efficiency.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';


const IndustrialManufacture = () => {

    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <>
            <div className="heWhoRemains">
                <Header />
                <div>
                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">
                                        <h1 className="smd_text">ScheduleAI for <br />
                                            <span style={{ color: "#0B5ED7" }}> Industrial and Process Manufacturing
                                            </span>
                                        </h1>
                                        <p className="lead_smd">
                                            The software can help you keep track of your appointments, schedule industrial processes, and track your production goals. With this software, you can optimize your factory's output by keeping track of every aspect of the manufacturing process.
                                        </p>
                                        <a href="/login"><button
                                            className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                                            Demo</button></a>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="mainDevImg" src={IndustrialAndProcessManufacturingBannerPng} alt='Industrial and process manufacturing banner' />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="column pt-5">
                                <h2 className="text-center display-6 fw-bold">Benefits of Schedule AI for <br />
                                    Industrial & Process Manufacturing
                                </h2>
                            </div>
                            <div className="row webDevRow">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-up"
                                    data-aos-duration="2000">
                                    <div>

                                        <h1 className="display-6 smd_title" style={{ fontWeight: "400" }}>Improved communication and collaboration
                                        </h1>
                                        <p className="lead_smd"> Appointment scheduling software can help streamline communication between different departments within your organization.
                                        </p>

                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 mt-5" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="mainDevImg" src={ImprovedCommunicationAndCollaborationPng} alt="Improved communication and collaboration" />
                                </div>

                            </div>
                        </div>
                    </section>


                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">

                                <div className="col-12 col-lg-6 mt-5" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="mainDevImg" src={IncreasedCustomerSatisfactionPng} alt="Increased customer satisfaction" />
                                </div>
                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-up"
                                    data-aos-duration="2000">
                                    <div className="mt-4 ">

                                        <h1 className="display-6 smd_title" style={{ fontWeight: "400" }}>Increased customer satisfaction
                                        </h1>
                                        <p className="lead_smd"> When customers are able to schedule appointments online or through self-service portals, they're more likely to be satisfied with the overall experience.
                                        </p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">
                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-up"
                                    data-aos-duration="2000">
                                    <div className="mt-4 ">

                                        <h1 className="display-6 smd_title" style={{ fontWeight: "400" }}>Improved efficiency
                                        </h1>
                                        <p className="lead_smd"> One of the biggest benefits of using appointment scheduling software is that it can help improve efficiency throughout your organization.
                                        </p>

                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 mt-5" data-aos="fade-up" data-aos-duration="2000">
                                    <img className='mainDevImg' src={ImprovedEfficiencyPng} alt="Improved efficiency" />
                                </div>

                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container textDevLay pb-4">
                            <div className="column pt-5 webDevRow">
                                <h2 className="display-6 fw-bold">Conclusion
                                </h2>
                                <p className='lead_smd'>With this software, you can optimize your factory's output by keeping track of every aspect of the manufacturing process. In addition, the software can help you improve communication between your team members and customers.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default IndustrialManufacture
