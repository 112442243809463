import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import Login from "./pages/login/Login";
import Home from "./pages/home/Home";

import Bookings from './pages/BookingPage/Bookings';
import Successpage from './pages/SuccessBooking/Successpage';
import Editevent from "./pages/EditEvent/Editevent";
import EventListing from "./pages/EventListing/EventListing";

import CancellationPage from "./pages/CancellationPage/CancellationPage";
import ReschedulePage from "./pages/ReschedulePage/ReschedulePage";

import ZoomIntegrationSetup from "./pages/integration/ZoomIntegrationSetup";
import GotoIntegration from "./pages/integration/GotoIntegration";
import WebexIntegration from "./pages/integration/WebexIntegration";
import TeamsIntegration from "./pages/integration/TeamsIntegration"
import SalseforceIntegration from "./pages/integration/SalseforceIntegration";

import Homepage from './pages/homepage/Homepage';
import Individuals from "./pages/individuals/Individuals";
import Enterprise from "./pages/enterprise/Enterprise";
import Pricing from "./pages/pricing/Pricing";
import Support from "./pages/support/Support";
import Solutions from "./pages/Solutions/Solutions";
import About from "./pages/about/About";
import TermsAndConditions from "./pages/terms and conditions/TermsAndConditions";
import PrivacyPolicy from "./pages/privacy and policy/PrivacyPolicy";
import RefundAndCancellation from "./pages/refund and cancellation/RefundAndCancellation";
import ZoomIntegration from "./pages/zoom integration/ZoomIntegration";
import WebexDocs from "./pages/webex docs/WebexDocs";
import Education from "./pages/Education/Education";
import Banking from "./pages/Banking/Banking";
import Transport from "./pages/Transport/Transport";
import AerospaceAndDefence from "./pages/Aerospace and Defence/AerospaceAndDefence";
import ConsumerGoods from "./pages/Consumer Goods/ConsumerGoods";
import HealthcareIndustry from "./pages/Healthcare Industry/HealthcareIndustry";
import Insurance from "./pages/Insurance/Insurance";
import Retail from "./pages/Retail/Retail";
import SciencePharma from "./pages/Life Scince and Pharma/SciencePharma";
import CapitalMarkets from "./pages/Capital Markets/CapitalMarkets";
import MediaServices from "./pages/Media Services/MediaServices";
import PublicSector from "./pages/Public Sector/PublicSector";
import NaturalResources from "./pages/Natural Resources/NaturalResources";
import ProfessionalServices from "./pages/Professional Services/ProfessionalServices";
import EngineeringConstructions from "./pages/Engineering Constructions/EngineeringConstructions";
import OilAndGas from "./pages/Oil and Gas/OilAndGas";
import IndustrialManufacture from "./pages/Industrial Manufacture/IndustrialManufacture";
import Utilities from "./pages/Utilities/Utilities";
import NetworkEdgeProvider from "./pages/Network Edge Provider/NetworkEdgeProvider";
import ConsumerElectronics from "./pages/Consumer electronics/ConsumerElectronics";
import PlatformAndSoftware from "./pages/Platform and Software/PlatformAndSoftware";
import Semiconductors from "./pages/Semiconductors/Semiconductors";
import NonProfit from "./pages/Non Profit/NonProfit";
import Telecommunication from "./pages/Telecommunication/Telecommunication";
import Automotive from "./pages/Automotive/Automotive";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import BridgeToHome from "./pages/home/BridgeToHome";
import AccountSetup from "./pages/accountSetup/AccountSetup";
import NotFoundPage from "./pages/notFoundPage/NotFoundPage";
import ThankYou from "./pages/Thankyou/ThankYou";
import Phone from "./pages/phone/Phone";
import Stracker from "./pages/stracker/Stracker";
import MarkerUp from "./pages/stracker/MarkerUp";
function App({ socket, socketId }) {

  const serverUrl = "https://" + process.env.REACT_APP_DOMAINNAME + ":8443";
  //const serverUrl = "http://" + process.env.REACT_APP_DOMAINNAME + ":8443";

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />}></Route>
          <Route path="/individuals" element={<Individuals />}></Route>
          <Route path="/enterprise" element={<Enterprise />}></Route>
          <Route path="/pricing" element={<Pricing />}></Route>
          <Route path="/support" element={<Support />}></Route>
          <Route path="/solutions" element={<Solutions />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/termsandconditions" element={<TermsAndConditions />}></Route>
          <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
          <Route path="/refundandcancellation" element={<RefundAndCancellation />}></Route>
          <Route path="/zoomintegration" element={<ZoomIntegration />}></Route>
          <Route path="/webexdocs" element={<WebexDocs />}></Route>
          <Route path="/education" element={<Education />}></Route>
          <Route path="/banking" element={<Banking />}></Route>
          <Route path="/transport" element={<Transport />}></Route>
          <Route path="/aerospace-and-defence" element={<AerospaceAndDefence />}></Route>
          <Route path="/consumer-goods" element={<ConsumerGoods />}></Route>
          <Route path="/healthcare-industry" element={<HealthcareIndustry />}></Route>
          <Route path="/insurance" element={<Insurance />}></Route>
          <Route path="/retail" element={<Retail />}></Route>
          <Route path="/science-pharma" element={<SciencePharma />}></Route>
          <Route path="/capital-markets" element={<CapitalMarkets />}></Route>
          <Route path="/media-services" element={<MediaServices />}></Route>
          <Route path="/public-sector" element={<PublicSector />}></Route>
          <Route path="/natural-resources" element={<NaturalResources />}></Route>
          <Route path="/professional-services" element={<ProfessionalServices />}></Route>
          <Route path="/engineering-constructions" element={<EngineeringConstructions />}></Route>
          <Route path="/oil-and-gas" element={<OilAndGas />}></Route>
          <Route path="/industrial-manufacture" element={<IndustrialManufacture />}></Route>
          <Route path="/utilities" element={<Utilities />}></Route>
          <Route path="/network-edge-provider" element={<NetworkEdgeProvider />}></Route>
          <Route path="/consumer-electronics" element={<ConsumerElectronics />}></Route>
          <Route path="/platform-and-software" element={<PlatformAndSoftware />}></Route>
          <Route path="/semiconductors" element={<Semiconductors />}></Route>
          <Route path="/non-profit" element={<NonProfit />}></Route>
          <Route path="/telecommunication" element={<Telecommunication />}></Route>
          <Route path="/automotive" element={<Automotive />}></Route>

          <Route path="/thank-you" element={<ThankYou />}></Route>


          <Route path="/calendar" element={<BridgeToHome socket={socket} socketId={socketId} />}></Route>
          <Route path="/login" element={<Login />}></Route>

          <Route path="/integration/zoom" element={<ZoomIntegrationSetup />} />
          <Route path="/integration/gotoMeeting" element={<GotoIntegration />} />
          <Route path="/integration/teams" element={<TeamsIntegration />} />
          <Route path="/integration/webex" element={<WebexIntegration />} />
          <Route path="/integration/salesforce" element={<SalseforceIntegration />} />

          <Route path="/:username/:meetingName" element={<Bookings />} />
          <Route path="/:username/successpage/:uuid/:uuid2" element={<Successpage />} />
          <Route path="/:username" element={<EventListing />} />
          <Route path="/accountSetup" element={<AccountSetup />} />

          <Route path="/edit/:id" element={<Editevent />} />

          <Route path="/cancellation/:eventId" element={<CancellationPage />} />
          <Route path="/reschedule/:eventId" element={<ReschedulePage />} />
          {/* <Route path="/*" element={<Navigate to="/login" />} /> */}

          <Route path="/phone" element={<Phone serverURL={serverUrl} />} />


          <Route path="/stracker" element={<Stracker serverURL={serverUrl} />} />
          <Route path="/markerup" element={<MarkerUp serverURL={serverUrl} />} />
          <Route path="/markerup/:uid" element={<MarkerUp serverURL={serverUrl} />} />

          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
