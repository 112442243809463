import Button from 'react-bootstrap/Button';
import React, { useEffect } from 'react';
import { Container, Row, Col, Nav, Navbar, Image } from 'react-bootstrap';
import NewScheduleAiLogo from "../../images/logo/New ScheduleAi Logo.png";
import "./header.css";
import { useState } from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TerminalIcon from '@mui/icons-material/Terminal';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import TaskIcon from '@mui/icons-material/Task';
// import { LinkContainer } from 'react-router-bootstrap';
import { Link, Router, useLocation } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import PersonIcon from '@mui/icons-material/Person';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';

function Header() {
    const [productActive, setProductActive] = useState(false);
    const [resourcesActive, setResourcesActive] = useState(false);
    const [industriesActive, setIndustriesActive] = useState(false);


    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <>


            <Navbar bg="light" collapseOnSelect expand="lg" className='sticky-top' style={{ zIndex: "999" }}>
                <Container fluid>
                    <Link to="/">
                        <Image src={NewScheduleAiLogo} className="logoHeader" />
                    </Link>
                    <div className='d-lg-none d-md-block'>
                        <Nav>
                            <Nav.Item className='contDev text-center mt-3'>
                                <p><a href={`mailto:info@${process.env.REACT_APP_DOMAINNAME}`} className=" text-dark"><i className="fa-solid fa-envelope"></i> info@scheduleai.co</a></p>
                            </Nav.Item>

                        </Nav>
                    </div>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
                        <Nav activeKey="/home"
                            style={{ background: "transparent" }} className="d-flex align-items-center justify-content-center w-100 navBarDev">
                            <Nav.Item>
                                <Nav.Link as={Link} to={"/individuals"} className='headerNavLinks' onMouseOver={(e) => { setProductActive(false); setResourcesActive(false); setIndustriesActive(false) }}>Individuals</Nav.Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Nav.Link as={Link} to={"/enterprise"} className='headerNavLinks' onMouseOver={(e) => { setProductActive(false); setResourcesActive(false); setIndustriesActive(false) }}>Enterprise</Nav.Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Nav.Link className='headerNavLinks IndusDev d-none d-lg-block d-md-none' onMouseOver={(e) => { setIndustriesActive(true); setProductActive(false); }}>Industries

                                    {
                                        industriesActive ?
                                            <KeyboardArrowUpIcon />
                                            :
                                            <KeyboardArrowDownIcon />
                                    }
                                </Nav.Link>

                                <DropdownButton className="d-lg-none d-block DevDropdown" id="Devdropdown-basic-button" title="Industries">
                                    <div className="container indusDevDropDiv">
                                        <div className="row border-bottom">
                                            <div className="col-12 col-md-6 col-sm-12">
                                                {/* <ul>
                                                    <li className='liDropDev'><a href="/aerospace and defence">Aerospace & Defence</a></li>
                                                    <li className='liDropDev'><a href="/automotive">Automotive</a></li>
                                                    <li className='liDropDev'><a href="/banking">Banking</a></li>
                                                    <li className='liDropDev'><a href="/capital markets">Capital Markets</a></li>
                                                    <li className='liDropDev'>
                                                        <a href="/telecommunication">Telecommunications</a>
                                                    </li>
                                                    <li className='liDropDev'><a href="/consumer electronics">Consumer Electronics</a></li>
                                                    <li className='liDropDev'><a href="/consumer goods">Consumer Packaged Goods</a></li>
                                                </ul> */}
                                                <ul className='ListItem'>
                                                    <Link to="/aerospace-and-defence">Aerospace & Defence</Link>
                                                    <Link to="/automotive">Automotive</Link>
                                                    <Link to="/banking">Banking</Link>
                                                    <Link to="/capital-markets">Capital Markets</Link>
                                                    <Link to="/telecommunication">Telecommunication</Link>
                                                    <Link to="/consumer-electronics">Consumer Electronics</Link>
                                                </ul>
                                            </div>
                                            <div className="col-12 col-md-6 col-sm-12">
                                                <ul className='ListItem'>
                                                    <Link to="/consumer-goods">Consumer Packaged Goods</Link>
                                                    <Link to="/Education">Education</Link>
                                                    <Link to="/natural-resources">Natural Resources</Link>
                                                    <Link to="/network-edge-provider">Network and Edge Providers</Link>
                                                    <Link to="/transport">Transportation & Services</Link>
                                                    <Link to="/utilities">Utilities</Link>
                                                    <Link to="/non-profit">Non Profit</Link>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-sm-12">
                                                {/* <ul>
                                                    <li className='liDropDev'><a href="/media services">Medical & Info Services</a></li>
                                                    <li className='liDropDev'><a href="/natural resoures">Natural Resources</a></li>
                                                    <li className='liDropDev'><a href="/network edge provider">Network and Edge Providers</a></li>
                                                    <li className='liDropDev'><a href="/oil and gas">Oil & Gas</a></li>
                                                    <li className='liDropDev'><a href="/platform and software">Platforms & Software Products</a></li>
                                                    <li className='liDropDev'><a href="/professional services">Professional Services</a></li>
                                                </ul> */}
                                                <ul className='ListItem'>
                                                    <Link to="/engineering-constructions">Engineering Construction & Operations</Link>
                                                    <Link to="/healthcare-industry">Healthcare</Link>
                                                    <Link to="/industrial-manufacture">Industrial & Process Manufacturing</Link>
                                                    <Link to="/insurance">Insurance</Link>
                                                    <Link to="/science-pharma">Life Sciences & Pharma</Link>
                                                    <Link to="/media-services">Media & Info Services</Link>
                                                </ul>
                                            </div>
                                            <div className="col-12 col-md-6 col-sm-12">
                                                {/* <ul>
                                                    <li className='liDropDev'><a href="/public sector">Public Sector</a></li>
                                                    <li className='liDropDev'><a href="/retail">Retail</a></li>
                                                    <li className='liDropDev'><a href="/semiconductors">Semiconductors</a></li>
                                                    <li className='liDropDev'><a href="/transport">Transportation & Services</a></li>
                                                    <li className='liDropDev'><a href="/utilities">Utilities</a></li>
                                                    <li className='liDropDev'><a href="/non profit">Non Profit</a></li>
                                                </ul> */}

                                                <ul className='ListItem'>
                                                    <Link to="/oil-and-gas">Oil and Gas</Link>
                                                    <Link to="/platform-and-software">Platforms & Software Products</Link>
                                                    <Link to="/professional-services">Professional Services</Link>
                                                    <Link to="/public-sector">Public Sector</Link>
                                                    <Link to="/retail">Retail</Link>
                                                    <Link to="/semiconductors">Semiconductors</Link>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </DropdownButton>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Product" className='headerNavLinks d-none d-lg-block d-md-none DevPro' onMouseOver={(e) => { setProductActive(true); setResourcesActive(false); setIndustriesActive(false) }}>
                                    Product
                                    {
                                        productActive ?
                                            <KeyboardArrowUpIcon />
                                            :
                                            <KeyboardArrowDownIcon />
                                    }
                                </Nav.Link>
                                <DropdownButton className="d-lg-none d-block DevDropdown" id="Devdropdown-basic-button" title="Product">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-sm-12">
                                                <ul>
                                                    <Link to="/features/availability">
                                                        <h6 className='text-dark'>Availability</h6>
                                                    </Link>
                                                    <Link to="/features/personalized-scheduling">
                                                        <h6 className='text-dark'>Personalized Scheduling</h6>
                                                    </Link>
                                                    <Link to="/features/integration">
                                                        <h6 className='text-dark'>Integration</h6>
                                                    </Link>
                                                    <Link to="/product/sales">
                                                        <h6 className='text-dark'>Sales</h6>
                                                    </Link>
                                                    <Link to="/product/recruiting">
                                                        <h6 className="text-dark">Recruiting</h6>
                                                    </Link>
                                                    <Link to="/product/revenue-operations">
                                                        <h6 className='text-dark'>Revenue Operations</h6>
                                                    </Link>
                                                    <Link to="/product/customer-success">
                                                        <h6 className="text-dark">Customer Success</h6>
                                                    </Link>
                                                    <Link to="/product/information-technology">
                                                        <h6 className="text-dark">Information Technology</h6>
                                                    </Link>
                                                    <Link to="/product/marketing">
                                                        <h6 className='text-dark'>Marketing</h6>
                                                    </Link>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </DropdownButton>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} to={"/pricing"} className='headerNavLinks' onMouseOver={(e) => { setProductActive(false); setResourcesActive(false); setIndustriesActive(false) }}>Pricing</Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
                                <Nav.Link eventKey="Resources" className='headerNavLinks d-none d-lg-block d-md-none DevPro' onMouseOver={(e) => { setProductActive(false); setResourcesActive(true); setIndustriesActive(false) }}>
                                    Resources
                                    {
                                        resourcesActive ?
                                            <KeyboardArrowUpIcon />
                                            :
                                            <KeyboardArrowDownIcon />
                                    }
                                </Nav.Link>
                                <DropdownButton className="d-lg-none d-block DevDropdown" id="Devdropdown-basic-button" title="Resources">
                                    <div className="resDropDev">
                                        <Dropdown.Item href="/about">
                                            <a href="/"><i className="fa-solid fa-users iconDev"></i></a>
                                            About</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">
                                            <a href="/"><i className="fa-solid fa-square-check iconDev"></i></a>
                                            Resources Center</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">
                                            <a href="/"><i className="fa-solid fa-user-group iconDev"></i></a>
                                            Blog</Dropdown.Item>
                                        <Dropdown.Item href="/3">
                                            <a href="/"><i className="fa-solid fa-headset iconDev"></i></a>
                                            Customer Stories</Dropdown.Item>
                                        <Dropdown.Item href="/">
                                            <a href="/"><i className="fa-regular fa-face-smile iconDev"></i></a>
                                            Help Center</Dropdown.Item>
                                        <ul>
                                            <li><a href="/">Careers</a></li>
                                            <li><a href="/">Partners</a></li>
                                            <li><a href="/">Newspaper</a></li>
                                            <li><a href="/">Developer</a></li>
                                        </ul>
                                    </div>
                                </DropdownButton>
                            </Nav.Item> */}
                            <Nav.Item>
                                <Nav.Link as={Link} to={"/support"} className='headerNavLinks' onMouseOver={(e) => { setProductActive(false); setResourcesActive(false); setIndustriesActive(false) }}>Support</Nav.Link>
                            </Nav.Item>
                        </Nav>

                        <Nav>
                            <Nav.Item className='contDev text-center mt-3'>
                                <p><a href={`mailto:info@${process.env.REACT_APP_DOMAINNAME}`} className=" text-dark"><i className="fa-solid fa-envelope"></i> info@scheduleai.co</a></p>
                            </Nav.Item>

                        </Nav>

                        <Nav>
                            <Nav.Item className='text-center loginDev'>
                                <Nav.Link as={Link} to="/login" eventKey="login">
                                    <Button variant="primary" className='headerNavLogin px-4'>
                                        <PersonIcon className='icon' />
                                        Login</Button>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>



            <section className={productActive ? "product-wrapper product-wrapper-active" : "product-wrapper d-lg-block"}>
                <div className="container bg-light rounded" id="ProductBox" onMouseLeave={(e) => { setProductActive(false); setResourcesActive(false); }}>
                    <div className="row">
                        <div className="col-12 col-lg-3 py-5 rounded product_page_bg">
                            <h4 className="fw-bold text-dark">Product</h4>
                            <p className='py-3 paraDev'>Get to realize the forerunner in computerized booking and see the reason why millions associate
                                utilizing {process.env.REACT_APP_DOMAINNAME}.</p>
                        </div>


                        <div className="col-12 col-lg-6  py-5 Product-Dev">
                            <h4 className="fw-bold text-dark">
                                <a href="">Features</a>
                            </h4>
                            <p className="border-bottom text-secondary">
                                Adaptable, instinctive elements for every<br />
                                booking
                                need.
                            </p>


                            <div className="pt-2 d-flex gap-2 justify-content-between">
                                <div className="card border-0 w-50 px-3">
                                    <Link to="/features/availability">
                                        <div className='p-2'>
                                            <QueryStatsIcon />
                                        </div>
                                        <div className="card-body p-2">
                                            <h6 className="card-title fw-bold text-dark">Availability</h6>
                                            <p className="text-secondary fs-6">Set your custom accessibility to make your
                                                occasions smoother than at any other time.</p>
                                        </div>
                                    </Link>
                                </div>

                                <div className="card border-0 w-50  px-3">
                                    <Link to="/features/personalized-scheduling">
                                        <div className='p-2'>
                                            <Diversity3Icon />
                                        </div>
                                        <div className="card-body p-2">
                                            <h6 className="card-title fw-bold text-dark">Personalized
                                                Scheduling page</h6>
                                            <p className='text-secondary fs-6'>
                                                Make your internet based arrangement booking
                                                page to permit.
                                            </p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="pt-2 d-flex gap-2 justify-content-between">
                                <div className="card border-0 w-50 px-3 pt-2 gap-2">
                                    <Link to="/features/integration">
                                        <div className='p-2'>
                                            <IntegrationInstructionsIcon />
                                        </div>
                                        <div className="card-body p-2">
                                            <h6 className="card-title fw-bold text-dark">Integration</h6>
                                            <p className="text-secondary fs-6">Consistent associations with your most loved calendars,tools and applications.</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3  py-5  Product-Dev">
                            <a href='/solutions'>
                                <h4 className="fw-bold text-dark">Solutions</h4>
                                <p className="border-bottom text-secondary">Investigate how thought forerunners in your industry use {process.env.REACT_APP_DOMAINNAME}</p>

                            </a>
                            <div className="innerListsdParent pt-3">
                                <div className="innerListsd">
                                    <Link to="/product/sales">
                                        <h6 className='fw-bold text-dark'>Sales</h6>
                                    </Link>
                                </div>
                                <div className="innerListsd">
                                    <Link to="/product/recruiting">
                                        <h6 className="fw-bold  text-dark">Recruiting</h6>
                                    </Link>
                                </div>
                                <div className="innerListsd">
                                    <Link to="/product/revenue-operations">
                                        <h6 className='fw-bold text-dark'>Revenue Operations</h6>
                                    </Link>
                                </div>
                                <div className="innerListsd">
                                    <Link to="/product/customer-success">
                                        <h6 className="fw-bold  text-dark">Customer Success</h6>
                                    </Link>
                                </div>
                                <div className="innerListsd">
                                    <Link to="/product/information-technology">
                                        <h6 className="fw-bold  text-dark">Information Technology</h6>
                                    </Link>
                                </div>
                                <div className="innerListsd">
                                    <Link to="/product/marketing">
                                        <h6 className='fw-bold text-dark'>Marketing</h6>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={industriesActive ? "industries-wrapper industries-wrapper-active" : "industries-wrapper"}>
                <div className="container" id="IndustriesBox" onMouseLeave={(e) => { setProductActive(false); setIndustriesActive(false); }}>
                    <div className="row bg-light d-flex innerNav shadow bg-light rounded">
                        <div className='innerNav_Dev'>
                            <ul className='ListItem'>
                                <Link to="/aerospace-and-defence">Aerospace & Defence</Link>
                                <Link to="/automotive">Automotive</Link>
                                <Link to="/banking">Banking</Link>
                                <Link to="/capital-markets">Capital Markets</Link>
                                <Link to="/telecommunication">Telecommunication</Link>
                                <Link to="/consumer-electronics">Consumer Electronics</Link>
                                <Link to="/consumer-goods">Consumer Packaged Goods</Link>
                                <Link to="/Education">Education</Link>
                            </ul>
                            <ul className='leftWrapperUl'></ul>

                            <ul className='ListItem'>
                                <Link to="/engineering-constructions">Engineering Construction & Operations</Link>
                                <Link to="/healthcare-industry">Healthcare</Link>
                                <Link to="/industrial-manufacture">Industrial & Process Manufacturing</Link>
                                <Link to="/insurance">Insurance</Link>
                                <Link to="/science-pharma">Life Sciences & Pharma</Link>
                                <Link to="/media-services">Media & Info Services</Link>
                                <Link to="/natural-resources">Natural Resources</Link>
                                <Link to="/network-edge-provider">Network and Edge Providers</Link>
                            </ul>
                            <ul className='leftWrapperUl'></ul>

                            <ul className='ListItem'>
                                <Link to="/oil-and-gas">Oil and Gas</Link>
                                <Link to="/platform-and-software">Platforms & Software Products</Link>
                                <Link to="/professional-services">Professional Services</Link>
                                <Link to="/public-sector">Public Sector</Link>
                                <Link to="/retail">Retail</Link>
                                <Link to="/semiconductors">Semiconductors</Link>
                                <Link to="/transport">Transportation & Services</Link>
                                <Link to="/utilities">Utilities</Link>
                                <Link to="/non-profit">Non Profit</Link>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>



            {/* <section className={resourcesActive ? "resources-wrapper resources-wrapper-active" : "resources-wrapper"} id="resources-wrapper">
                <div className="container bg-light rounded" id="ResourcesBox" onMouseLeave={(e) => { setProductActive(false); setResourcesActive(false); }}>
                    <div className="row">
                        <div className="col-12 col-lg-4 smd_left_col pt-5 rounded product_page_bg">
                            <h4 className="fw-bold" style={{ fontSize: "2em" }}>Resources</h4>
                        </div>
                        <div className="col-12 col-lg-4 col-sm-12 pt-5 pb-5  Resources-Dev col">
                            <div className="card-group pb-2">
                                <div className="card border-0 smd_card-group">
                                    <a href="/" className="p-2">
                                        <Diversity3Icon />
                                    </a>
                                    <div className="card-body p-2">
                                        <a href="/about">
                                            <h6 className="card-title fw-bold text-dark">About</h6>
                                            <p className="card-text text-secondary">Learn about who we are.</p>
                                        </a>

                                    </div>
                                </div>

                            </div>

                            <div className="card-group pb-2">
                                <div className="card border-0 smd_card-group">
                                    <a href="/" className="p-2">
                                        <TaskIcon />
                                    </a>
                                    <div className="card-body p-2">
                                        <a href="/">
                                            <h6 className="card-title fw-bold text-dark">Resources Center</h6>
                                            <p className="card-text  text-secondary">Explore all of our resources
                                            </p>
                                        </a>

                                    </div>
                                </div>
                            </div>

                            <div className="card-group pb-2">
                                <div className="card border-0 smd_card-group">
                                    <a href="/" className="p-2">
                                        <SupervisedUserCircleIcon />
                                    </a>
                                    <div className="card-body p-2">
                                        <a href="/">
                                            <h6 className="card-title fw-bold text-dark">Blog</h6>
                                            <p className="card-text  text-secondary">New, Thoughts, and best practices.</p>
                                        </a>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-12 col-lg-4 col-sm-12 mt-5 pb-5  Resources-Dev col">
                            <div className="card border-0 smd_card-group">
                                <a href="/" className="p-2"><i className="fa-solid fa-headset" style={{ fontSize: "25px" }}></i></a>
                                <div className="card-body">
                                    <a href="/">
                                        <h6 className="card-title fw-bold text-dark">Customer Stories</h6>
                                        <p className="card-text  text-secondary">Hear from our valued customers</p>
                                    </a>

                                </div>
                            </div>
                            <div className="card border-0 smd_card-group">
                                <a href="/" className="p-2"><i className="fa-regular fa-face-smile" style={{ fontSize: "25px" }}></i></a>
                                <div className="card-body">
                                    <a href="/">
                                        <h6 className="card-title fw-bold text-dark">Help Center</h6>
                                        <p className="card-text  text-secondary">Explore how to use {process.env.REACT_APP_DOMAINNAME}</p>
                                    </a>

                                </div>
                            </div>
                            <div className="card border-0 smd_card-group">
                                <div className="card-body">
                                    <h6 className="card-title fw-bold smd_line">Developers</h6>
                                    <h6 className="card-title fw-bold smd_line">Newspaper</h6>
                                    <h6 className="card-title fw-bold smd_line">Partners</h6>
                                    <h6 className="card-title fw-bold">Careers</h6>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section> */}
        </>
    )
}

export default Header