import React from 'react';
import MediaBannerPng from "../../images/Media banner.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';

const MediaServices = () => {
    return (
        <>
            <div className="heWhoRemains">
                <Header />
                <div>
                    <section>
                        <div className="container mt-5">
                            <div className="row">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">
                                        <h1 className=" display-4 fw-bold smd_text">Schedule AI for  <br />
                                            <span style={{ color: "#0B5ED7" }}> Media Sector
                                            </span>
                                        </h1>
                                        <p className="lead_smd text-black" style={{ fontSize: "1.1em" }}>
                                            Scheduling appointments can be a very time-consuming task, especially if you have a lot of them. With ScheduleMe software, you can simply enter all the relevant information and the software will do the rest. This can free up a lot of your time so that you can focus on other tasks.
                                        </p>
                                        <a href="/login"><button
                                            className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                                            Demo</button></a>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="w-100" src={MediaBannerPng} alt='media banner' />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold text-center">Benefits of ScheduleMe for the <br />Media Sector
                                </h2>
                            </div>

                            <ul>
                                <li className='liListDevind'>It can help save time. With an effective appointment scheduling system in place, you can avoid the back-and-forth of scheduling appointments via email or phone.
                                </li>

                                <li className='liListDevind'>By having all appointments and associated information in one central location, both you and your clients can easily stay up-to-date on the details of upcoming meetings.
                                </li>

                                <li className='liListDevind'>It can also help to ensure that appointments are kept and that there are no missed appointments.
                                </li>

                                <li className='liListDevind'>Using ScheduleMe software can help to improve customer satisfaction levels as it can make it easier for them to book appointments and track their appointments.
                                </li>
                            </ul>

                        </div>
                    </section>

                    <section>
                        <div className="container textDevLay pb-4">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold">Conclusion
                                </h2>
                                <p className='lead_smd text-justify'>Overall, there are many benefits of using ScheduleMe software in the media sector. When used effectively, this type of software can save you time, improve communication with clients, and help you better manage your resources.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default MediaServices
