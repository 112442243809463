import React from 'react';

import notFoundPng from "../../images/notFound.png";

function NotFoundPage() {
    return (
        <div className='freshMain'>
            <div className='freshSecond'>
                <div className="freshThird border insightBackground shadow p-3">
                    <a href="/" target='_Blank' className="freshAncore">
                        <div className="freshBranding">
                            <div className="freshBrandingOne">powered by</div>
                            <div className="freshBrandingTwo">Schedule AI</div>
                        </div>
                    </a>
                    <div className="w-100 rounded">
                        {/* <button type='button' onClick={() => { window.location.href = "/calendar"; }} className='btn salesforce-style rounded-circle fw-bold backButtonAbs'>
                                            <i className='fa fa-arrow-left'></i>
                                        </button> */}

                        <div className={`container-fluid`}>
                            <div className="row h-100 d-flex align-items-center justify-content-center">
                                <div className="col-12 p-4 d-flex flex-column gap-5 justify-content-center align-items-center">
                                    <h6 className='text-dark text-center'>
                                        <img src={notFoundPng} className="w-100" style={{ maxWidth: "350px" }} alt="not found" />
                                        <p className='color-salesforce mt-5 display-6' style={{ fontWeight: "600" }}>Broken URL</p>
                                        <p className='mediumTextSize text-muted'>Looks like the current url is not available.</p>
                                    </h6>
                                    <button type='button' onClick={() => { window.location.href = "/calendar"; }} className='btn btn-sm salesforce-style rounded-pill px-3'>
                                        {/* <i className='fa fa-arrow-left'></i> */}
                                        Back to Home
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFoundPage