import React, { useEffect, useRef, useState } from 'react'
import "./Login.css";
import calendaryLogo from "../../images/ScheduleAILogo.png";
import { Carousel, Modal, Toast } from "react-bootstrap";
import slider1 from "../../images/slider/background-login-slider1.png";
import { GoogleLogin } from 'react-google-login';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import axios from 'axios';
import { gapi } from 'gapi-script';
import Cookies from 'js-cookie'
import popupData from "../../audio/popup-alert.mp3";


function Login() {

  const popupAlert = new Audio(popupData);

  const newPasswordField = useRef();
  const newPasswordFieldVarified = useRef();

  const [myOTP, setMyOTP] = useState("");
  const [newPass, setNewPass] = useState("");
  const [newPassConf, setNewPassConf] = useState("");

  const [showLoginPage, setShowLoginPage] = useState(false);

  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");

  const [warning, setWarning] = useState(false);
  const [warningData, setWarningData] = useState(null);

  const [forgotPasswordView, setForgotPasswordView] = useState(false);
  const [userExist, setUserExist] = useState(null);
  const [oTPForReset, setOTPForReset] = useState(null);

  const [forgetPassStep, setForgetPassStep] = useState(1);

  const [isOTPValid, setIsOTPValid] = useState(false);

  const [mismatchPass, setMismatchPass] = useState(null);

  const [viewToast, setViewToast] = useState(false);
  const [toastTitle, setToastTitle] = useState(null);
  const [toastBody, setToastBody] = useState(null);
  const [toastMessageType, setToastMessageType] = useState(null);

  const [userEmailOTP, setUserEmailOTP] = useState("");

  const [lowercaseAvailable, setLowercaseAvailable] = useState(false);
  const [uppercaseAvailable, setUppercaseAvailable] = useState(false);
  const [numbersAvailable, setNumbersAvailable] = useState(false);
  const [specialCharsAvailable, setSpecialCharsAvailable] = useState(false);
  const [minimumCharsAvailable, setMinimumCharsAvailable] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);

  const serverUrl = "https://" + process.env.REACT_APP_DOMAINNAME + ":8443";
  //const serverUrl = "http://" + process.env.REACT_APP_DOMAINNAME + ":8443";

  useEffect(() => {
    gapi.load('client:auth2', function () {
      gapi.auth2.init({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: 'openid email profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
        prompt: 'consent',
      });
    });
  }, [])



  // async function getCookies() {
  //   console.log("Getting cookies server url ====>", serverUrl);
  //   const b = Cookies.get('jwtToken')
  //   try {
  //     const get = await axios.get(serverUrl + "/getCookieUser", { withCredentials: true });

  //     if (get.status === 200) {
  //       if (get.data !== "" && get.data !== undefined && get.data.length > 1) {
  //         const gettingUserByCookie = await axios.post(serverUrl + "/users/getLoginUserNow", { "email": get.data }, { headers: { "Authorization": process.env.REACT_APP_REST_API_AUTHORIZATION } });

  //         if (gettingUserByCookie.status === 200) {
  //           window.location.href = "https://" + process.env.REACT_APP_DOMAINNAME + "/calendar";
  //         } else {
  //           setShowLoginPage(true);
  //         }
  //       } else {
  //         setShowLoginPage(true);
  //       }
  //     } else {
  //       // console.log("no cookies");
  //       setShowLoginPage(true);
  //     }

  //   } catch (error) {
  //     console.log("error in login cookies function===>", error);
  //     if (error.response && error.response.status === 402) {
  //       console.log("Token expired or invalid. Redirecting to login page...1");
  //       // Redirect to the login page or handle the error accordingly
  //       window.location.href = process.env.REACT_APP_DOMAINNAME1 + "/login";
  //     } else if (error.response && error.response.status === 401) {
  //       console.log("Token expired or invalid. Redirecting to login page...2");
  //       // Redirect to the login page or handle the error accordingly
  //       window.location.href = process.env.REACT_APP_DOMAINNAME1 + "/login";
  //     } else {
  //       console.error("Error occurred while fetching user data:", error);
  //     }
  //   }

  // }

  useEffect(() => {
    async function getCookies() {
      console.log("Getting cookies server url ====>", serverUrl);

      const b = Cookies.get('jwtToken')

      console.log("cookies with login page accessToken b===> " + b);

      try {
        const get = await axios.get(serverUrl + "/getCookieUser", {
          headers: {
            "Authorization": `Bearer ${b}`
          }
        });

        // if (get.status === 304) {
        console.log("Resource not modified. Using cached data.", get.status);
        // Handle the case when the resource is not modified, e.g., update UI with cached data
        //return;
        // }

        if (get.status === 200) {
          console.log("im here =====.", get);
          if (get.data !== "" && get.data !== undefined /* && get.data.length > 1 */) {
            console.log("getdata====== >", get.data);
            const gettingUserByCookie = await axios.post(serverUrl + "/users/getLoginUserNow", { "email": get.data.email },);
            console.log("user with login page user data===> " + gettingUserByCookie);
            if (gettingUserByCookie.status === 200) {
              console.log("Redirecting to calendar...", gettingUserByCookie);
              window.location.href = process.env.REACT_APP_DOMAINNAME1 + "/calendar";
            } else {
              setShowLoginPage(true);
            }
          } else {
            setShowLoginPage(true);
          }
        } else if (get.status === 401) {
          console.log("Unauthorized, redirecting to login page...1");
          window.location.href = process.env.REACT_APP_DOMAINNAME1 + "/login";
          setShowLoginPage(true);
        } else if (get.status === 402) {
          console.log("Unauthorized, redirecting to login page...2");
          window.location.href = process.env.REACT_APP_DOMAINNAME1 + "/login";
          setShowLoginPage(true);
        } else {
          console.log("Unexpected error, redirecting to login page...3");
          window.location.href = process.env.REACT_APP_DOMAINNAME1 + "/login";
          setShowLoginPage(true);
        }
      } catch (error) {
        console.log("Error occurred while getting cookies:", error);

        setShowLoginPage(true);
      }
    }

    getCookies();



  }, [serverUrl]);


  const handleLogin = async (e) => {
    e.preventDefault();
    const button = document.querySelector("#loginButton");

    button.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>';

    const quary = {
      "userEmail": userEmail,
      "password": password
    }
    const getDirectLogin = await axios.post(serverUrl + "/auth/getDirectLogin", quary);

    if (getDirectLogin.status === 200) {
      // console.log(getDirectLogin.data);

      const storeUser = await axios.get(serverUrl + "/storeUser/" + getDirectLogin.data.email, { withCredentials: true });
      if (storeUser) {
        // console.log(storeUser.data);

        setWarningData(
          <>
            <p style={{ fontWeight: "500" }} className={`mediumTextSize text-${getDirectLogin.data.type}`}>{getDirectLogin.data.message}</p>
            <p style={{ fontWeight: "500" }} className='mediumTextSize text-success d-flex align-items-center justify-content-center'>
              <span>Redirecting..</span>
              <span className="spinner-border spinner-border-sm text-success"></span>
            </p>
          </>);
        setWarning(true);
        setTimeout(() => {
          window.location.href = process.env.REACT_APP_DOMAINNAME1 + "/calendar";
        }, 1000);
      } else {
        // console.log("not stored");
      }
    } else {
      setWarningData(<p style={{ fontWeight: "500" }} className={`mediumTextSize text-${getDirectLogin.data.type}`}>{getDirectLogin.data.message}</p>);
      setWarning(true);

      button.innerHTML = 'Log in';
    }
  }

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const emailForReset = userEmailOTP;

    const query = {
      "email": emailForReset,
    }

    const checkUser = await axios.post(serverUrl + "/users/checkUserExistance", query);
    if (checkUser.status === 200) {
      setUserExist("exist");
      setOTPForReset(checkUser.data.otp);
      setMyOTP("");
      setForgetPassStep(2);
    } else {
      setUserExist("invalid");
    }
  }

  const checkOTPNow = (e) => {
    e.preventDefault();

    if (myOTP === oTPForReset) {
      setIsOTPValid("Verified");

      setTimeout(() => {
        setForgetPassStep(3);
      }, 2000);

    } else {
      setIsOTPValid("Invalid");
    }
  }

  const saveNewPass = async (e) => {
    e.preventDefault();
    const newPass1 = newPass;
    const newPass2 = newPassConf;

    const query = {
      "email": userEmailOTP,
      "password": newPass1
    }
    if (newPass1 === newPass2) {
      setMismatchPass("matched");

      const saveNewPass = await axios.post(serverUrl + "/users/UpdatePassword", query);

      if (saveNewPass.status === 200) {

        setForgotPasswordView(false);
        setForgetPassStep(false);
        setUserEmailOTP("");
        setMyOTP("");
        setNewPass("");
        setNewPassConf("");
        setUserExist(null);
        setIsOTPValid(null);
        setMismatchPass(null);

        setViewToast(true);
        popupAlert.play();
        setToastTitle("Successful");
        setToastBody("Password Updated Successfully.");
        setToastMessageType("success");

      } else {

        setViewToast(true);
        popupAlert.play();
        setToastTitle("Error");
        setToastBody("Something went wrong while updating your password..!");
        setToastMessageType("invalid");
      }
    }
    else {
      setMismatchPass("mismatch");
    }
  }

  const responceGoogle = async (response) => {
    // console.log(response);
    const getUserData = await axios.post(serverUrl + "/auth/loginWithReact", { "code": response.code })
    if (getUserData.status === 200) {
      // console.log(getUserData.data);

      const storeUser = await axios.get(serverUrl + "/storeUser/" + getUserData.data.user.email, { withCredentials: true });
      if (storeUser) {
        // console.log(storeUser.data);
        window.location.href = "/calendar"
      } else {
        // console.log("not stored");
      }
    }
    else {
      // console.log(getUserData.data);
      // console.log("error while getting the user");
    }
  }

  const responseError = error => {
    console.log(error)
  }

  const passwordValidation = (field) => {
    let lowercase = /[abcdefghijklmnopqrstuvwxyz]/;
    let uppercase = /[ABCDEFGHIJKLMNOPQRSTUVWXYZ]/;
    let numbers = /[0123456789]/;
    let specials = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    if (lowercase.test(field)) {
      setLowercaseAvailable(true);
    } else {
      setLowercaseAvailable(false);
    }
    if (uppercase.test(field)) {
      setUppercaseAvailable(true);
    } else {
      setUppercaseAvailable(false);
    }
    if (numbers.test(field)) {
      setNumbersAvailable(true);
    } else {
      setNumbersAvailable(false);
    }
    if (specials.test(field)) {
      setSpecialCharsAvailable(true);
    } else {
      setSpecialCharsAvailable(false);
    }

    if (field.length >= 8) {
      setMinimumCharsAvailable(true);
    } else {
      setMinimumCharsAvailable(false);
    }

    if (newPasswordField.current.value === newPasswordFieldVarified.current.value) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  }

  const resetAllFields = () => {
    setForgotPasswordView(false);
    setUserEmailOTP("");
    setForgetPassStep(false);
    setUserEmail("");
    setMyOTP("");
    setNewPass("");
    setNewPassConf("");
    setUserExist(null);
    setIsOTPValid(null);
    setMismatchPass(null);
    setLowercaseAvailable(false);
    setUppercaseAvailable(false);
    setNumbersAvailable(false);
    setSpecialCharsAvailable(false);
    setMinimumCharsAvailable(false);
  }
  return (
    <>
      {
        showLoginPage ?
          (
            <div className={`container-fluid`}>
              <div className="row">
                <div className="col-12 col-lg-6 d-flex justify-content-center loginSideCOl bg-soul">
                  <div className='form-holder'>

                    <div className='centralFormGroup'>
                      <img src={calendaryLogo} style={{ width: "150px" }} alt='schedule ai' className="cursor-pointer" onClick={() => { window.location.href = "/" }} />
                      <div className="card cardLoginForm">
                        <div className="card-body">

                          <form onSubmit={handleLogin}>
                            <label className='formFieldLogin'>
                              <span className='loginPageFormlables'>Username</span>
                              <input type="text" value={userEmail} autoComplete='off' required onChange={(e) => { setUserEmail(e.target.value) }} className='formFieldLoginInput' />
                            </label>

                            <label className='formFieldLogin'>
                              <span className='loginPageFormlables'>Password</span>
                              <input type="password" value={password} autoComplete='off' role='presentation' onChange={(e) => { setPassword(e.target.value) }} required className='formFieldLoginInput' />
                            </label>


                            <button className='loginButton salesforce-style' id="loginButton">Login</button>
                            <div className={`w-100 text-center mt-3 ${warning ? "" : "d-none"}`}>
                              {warningData}
                            </div>
                            <div className='bottomLinksLogin'>
                              <span onClick={() => { setForgotPasswordView(true); setForgetPassStep(1); }} style={{ cursor: "pointer" }}>Forget Password?</span>
                            </div>
                          </form>
                        </div>
                      </div>


                      <GoogleLogin
                        className='googleLoginButton'
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        buttonText='Sign in & Authorize Calendar'
                        onSuccess={responceGoogle}
                        onFailure={responseError}
                        cookiePolicy={"single_host_origin"}
                        fetchBasicProfile={true}
                        responseType="code"
                        prompt="consent"
                        accessType='offline'
                        scope='openid email profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'
                      />
                    </div>
                    <div className='copyrightFooter'>
                      &copy; {new Date().getFullYear()} {process.env.REACT_APP_DOMAINNAME}. All rights reserved
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 bg-light p-0  d-none d-lg-block">
                  <Carousel className='sliderHalf'
                    controls={false}
                    fade={true}
                    interval={5000}
                    keyboard={false}
                    slide={true}
                    indicators={false}
                    touch={true}
                    wrap={true}
                    variant="light"

                  >
                    <Carousel.Item style={{ minHeight: "100vh" }}>
                      <div className='vh-100 bg-info'>
                        <div className='bg-body p-5 h-75' style={{ borderRadius: "0 00% 70% 30%" }}>
                          <p style={{ fontSize: "2.1rem", fontWeight: "600", lineHeight: "2.4rem" }}>Start your free trial. No credit card required, no software to install.</p>
                          <p>With your trial, you get: </p>
                          <div className='d-flex flex-column gap-3'>
                            <div className='d-flex gap-2'><DoneOutlineIcon className='text-success' />Create Unlimited One on One Events</div>

                            <div className='d-flex gap-2'><DoneOutlineIcon className='text-success' />Preconfigured processes, reports, and dashboards</div>

                            <div className='d-flex gap-2'><DoneOutlineIcon className='text-success' />Email Reminders to your hosts</div>

                            <div className='d-flex gap-2'><DoneOutlineIcon className='text-success' />Manage your events smoothly</div>
                          </div>
                        </div>
                        <img src={slider1} className="w-75 position-absolute bottom-0" alt='schedule ai' style={{ left: "50%", transform: "translateX(-50%)" }} />
                      </div>
                      {/* <img
                            className="imageSliding"
                            src={slider1}
                            alt="First slide"
                          />
                          <Carousel.Caption>
                            <h5>Schedule Meetings Easily</h5>
                            <p>Schedule AI is the best app to create and manage meetings</p>
                          </Carousel.Caption> */}
                    </Carousel.Item>
                    {/* <Carousel.Item style={{ minHeight: "100vh" }}>
                          <img
                            className="imageSliding"
                            src={slider2}
                            alt="Second slide"
                          />
                          <Carousel.Caption>
                            <h5>AI Automated Meetings</h5>
                            <p>With Schedule AI AI all of your events will be manages smoothly</p>
                          </Carousel.Caption>
                        </Carousel.Item>

                        <Carousel.Item style={{ minHeight: "100vh" }}>
                          <img
                            className="imageSliding"
                            src={slider3}
                            alt="Third slide"
                          />
                          <Carousel.Caption>
                            <h5>Custom Availability</h5>
                            <p>
                              Let your invitees know the best time to schedule the meeting with you.
                            </p>
                          </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item style={{ minHeight: "100vh" }}>
                          <img
                            className="imageSliding"
                            src={slider4}
                            alt="fourth slide"
                          />
                          <Carousel.Caption>
                            <h5>User Friendly Interface</h5>
                            <p>Stay one step ahead with most user friendly meeting schedular.</p>
                          </Carousel.Caption>
                        </Carousel.Item> */}
                  </Carousel>
                </div>
              </div>
            </div>
          )
          :
          (
            <div className="min-vh-100 w-100 position-absolute top-0 left-start d-flex align-items-center justify-content-center">
              <span className="spinner-border spinner-border-lg text-secondary"></span>
            </div>
          )

      }



      {/* #################################################### FORGET PASSWORD PANEL ############################################################# */}

      <Modal
        show={forgotPasswordView}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => { setForgotPasswordView(false); resetAllFields(); }}
      >

        <Modal.Header className="salesforce-style py-2" closeButton closeVariant='white'>
          <Modal.Title id="contained-modal-title-vcenter">
            <span style={{ fontSize: "1rem" }}>Reset Your Password</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            forgetPassStep === 1 ?
              <form className='d-flex w-100 flex-column gap-3 px-3' onSubmit={(e) => { handleResetPassword(e); }}>
                <span className='color-salesforce w-100 my-2'>Enter your linked email address</span>
                <input type="email" className='customInputFieldFocus w-100' placeholder='Your account email' required value={userEmailOTP} onChange={(e) => { setUserEmailOTP(e.target.value); }} />
                <div className='d-flex w-100 align-items-center justify-content-between gap-3'>
                  <input type="reset" className='btn btn-sm btn-secondary rounded-pill px-5' value={"Cancel"} onClick={() => { resetAllFields(); }} />
                  <button type="submit" className='btn btn-sm salesforce-style rounded-pill px-5' >Send OTP</button>
                </div>
                {
                  userExist === "exist" ?
                    <span className='text-success'>OTP Send</span>
                    :
                    userExist === "invalid" ?
                      <span className='text-danger'>Invalid Email..!</span>
                      :
                      ""
                }
              </form>
              :
              forgetPassStep === 2 ?
                <form className='d-flex w-100 flex-column gap-3 px-3' onSubmit={(e) => { checkOTPNow(e); }}>
                  <span className='color-salesforce w-100 mb-4 mt-2'>Enter the OTP sent to <span style={{ fontWeight: "600" }}>{userEmailOTP}</span></span>
                  <input type="text" className='customInputFieldFocus w-100' placeholder='Enter the OTP' value={myOTP} required onChange={(e) => { setMyOTP(e.target.value) }} />
                  <div className='d-flex w-100 align-items-center justify-content-between gap-3'>
                    <input type="button" className='btn btn-sm btn-secondary rounded-pill px-5' value={"Cancel"} onClick={() => { resetAllFields() }} />
                    <input type="submit" className='btn btn-sm salesforce-style rounded-pill px-5' value={"Verify"} />
                  </div>
                  {
                    isOTPValid === "Verified" ?
                      <span className='text-success'>Verified</span>
                      :
                      isOTPValid === "Invalid" ?
                        <span className='text-danger'>Invalid OTP..!</span>
                        :
                        ""
                  }
                </form>
                :
                forgetPassStep === 3 ?
                  <form className='d-flex w-100 flex-column gap-3 px-3' onSubmit={(e) => { saveNewPass(e); }}>
                    <span className='color-salesforce w-100 m-0 '>Enter New Password for <span style={{ fontWeight: "600" }}>{userEmailOTP}</span></span>
                    <input autoComplete='off' type="password" role='presentation' ref={newPasswordField} placeholder='New password' className='customInputFieldFocus w-100' style={{ fontSize: ".9rem" }} value={newPass} required onInput={(e) => { setNewPass(e.target.value); passwordValidation(e.target.value) }} />
                    <input autoComplete='off' type="password" role='presentation' ref={newPasswordFieldVarified} placeholder='Confirm new password' className='customInputFieldFocus w-100' style={{ fontSize: ".9rem" }} value={newPassConf} required onInput={(e) => { setNewPassConf(e.target.value); passwordValidation(e.target.value) }} />
                    <p className='color-salesforce'>
                      <span className='cursor-pointer' onClick={(e) => {
                        if (newPasswordField.current.type === "text") {
                          newPasswordField.current.type = "password";
                          newPasswordFieldVarified.current.type = "password";
                          e.target.innerHTML = "Show";

                        } else {
                          newPasswordField.current.type = "text";
                          newPasswordFieldVarified.current.type = "text";
                          e.target.innerHTML = "Hide";
                        }
                      }}>Show</span>
                    </p>

                    {
                      <ul className='p-0 m-0 alert bg-soul p-1'>
                        <li className={`mediumTextSize d-flex align-items-center gap-2 ${lowercaseAvailable ? "text-success" : "text-danger"}`}><i className={`text-center fa ${lowercaseAvailable ? "fa-check" : "fa-close"}`} style={{ width: "15px" }}></i>A lowercase</li>
                        <li className={`mediumTextSize d-flex align-items-center gap-2 ${uppercaseAvailable ? "text-success" : "text-danger"}`}><i className={`text-center fa ${uppercaseAvailable ? "fa-check" : "fa-close"}`} style={{ width: "15px" }}></i>A uppercase</li>
                        <li className={`mediumTextSize d-flex align-items-center gap-2 ${numbersAvailable ? "text-success" : "text-danger"}`}><i className={`text-center fa ${numbersAvailable ? "fa-check" : "fa-close"}`} style={{ width: "15px" }}></i>A number</li>
                        <li className={`mediumTextSize d-flex align-items-center gap-2 ${specialCharsAvailable ? "text-success" : "text-danger"}`}><i className={`text-center fa ${specialCharsAvailable ? "fa-check" : "fa-close"}`} style={{ width: "15px" }}></i>A special character</li>
                        <li className={`mediumTextSize d-flex align-items-center gap-2 ${minimumCharsAvailable ? "text-success" : "text-danger"}`}><i className={`text-center fa ${minimumCharsAvailable ? "fa-check" : "fa-close"}`} style={{ width: "15px" }}></i>Minimum 8 characters</li>
                        <li className={`mediumTextSize d-flex align-items-center gap-2 ${passwordMatch ? "text-success" : "text-danger"}`}><i className={`text-center fa ${minimumCharsAvailable ? "fa-check" : "fa-close"}`} style={{ width: "15px" }}></i>Password Match</li>
                      </ul>
                    }
                    {
                      mismatchPass === "mismatch" ?
                        <span className="text-danger my-2">Password Mismatch</span>
                        :
                        mismatchPass === "matched" ?
                          <span className='text-success my-2'>Password Matched</span>
                          :
                          ""
                    }
                    <div className='d-flex w-100 align-items-center justify-content-end gap-3'>
                      <input type="button" className='btn btn-sm btn-secondary rounded-pill px-4' value={"Cancel"} onClick={() => { resetAllFields(); }} />
                      <input type="submit"
                        className='btn btn-sm salesforce-style rounded-pill px-4'
                        value={"Verify"}
                        disabled={
                          lowercaseAvailable === false ||
                          uppercaseAvailable === false ||
                          numbersAvailable === false ||
                          specialCharsAvailable === false ||
                          minimumCharsAvailable === false ||
                          passwordMatch === false
                        } />
                    </div>

                  </form>
                  :
                  ""
          }
        </Modal.Body>
      </Modal>

      {/* ################################################## END FORGET PASSWORD PANEL ########################################################### */}


      {/* #################################### TOAST FRAME ##################################### */}

      <div style={{ position: "fixed", bottom: "10px", right: "10px", zIndex: "99999999" }}>


        <Toast onClose={() => setViewToast(false)} show={viewToast} delay={3000} autohide className='overflow-hidden'>
          <Toast.Header>
            {/* <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2 bg-primary"
              alt="toastImage"
              /> */}
            <span style={{ fontWeight: "500" }} className={`me-auto ${toastMessageType === "invalid" ? "text-danger" : "text-success"}`}>{toastTitle}</span>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body style={toastMessageType === "success" ? { fontWeight: "500", background: "#d1e7dd", color: "#0f5132" } : { fontWeight: "500", background: "#f8d7da", color: "#842029" }}>
            {toastBody}
          </Toast.Body>
        </Toast>
      </div>

      {/* #################################### TOAST FRAME ##################################### */}
    </>
  )
}

export default Login