import { AttachFile, EmojiPeople, Info, PlusOne, Send } from '@mui/icons-material'
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react'
import { ListGroup } from 'react-bootstrap'
import RichTextEditor from 'react-rte';
import { v4 as uuidv4 } from 'uuid';
import parse from 'html-react-parser';

function Messanger({ people, user, socket, serverUrl, setAskForDeleteMember, setDeleteMemberEmail }) {
    const [currentChat, setCurrentChat] = useState(people ? Object.keys(people).length > 0 ? people[Object.keys(people)[0]] : false : false);

    const curChat = useRef(people ? Object.keys(people).length > 0 ? people[Object.keys(people)[0]] : false : false);

    const curConv = useRef(null);


    const [statusOfContact, setStatusOfContact] = useState("offline");
    const [messageType, setMessageType] = useState();
    const [onlinePeoples, setOnlinePeoples] = useState([]);
    const messageBox = useRef();
    const attachedFile = useRef();

    const [conversations, setConversations] = useState(null);

    const [sentButtonEnable, setSentButtonEnable] = useState(null);

    const [wobble, setWobble] = useState(null);
    const [wobbleGroup, setWobbleGroup] = useState(null);





    const [editorValue, setEditorValue] = useState(RichTextEditor.createEmptyValue());

    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
            { label: 'Italic', style: 'ITALIC' },
            { label: 'Underline', style: 'UNDERLINE' }
        ],
        BLOCK_TYPE_DROPDOWN: [
            { label: 'Normal', style: 'unstyled' },
            { label: 'Heading Large', style: 'header-one' },
            { label: 'Heading Medium', style: 'header-two' },
            { label: 'Heading Small', style: 'header-three' }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item' },
            { label: 'OL', style: 'ordered-list-item' }
        ]
    };




    useEffect(() => {

        let x = null;
        socket.on("wobbleTriggered", (data) => {
            clearTimeout(x)
            setWobble(data);
            x = setTimeout(() => { setWobble(null) }, 500);
        })

        socket.emit("onlineUsers", user.email);

        socket.on("onlineUsersAreHere", (onlineUsers) => {
            let allOnlines = [];
            onlineUsers.map((data, key) => {
                return allOnlines.push(data.email)
            })

            setOnlinePeoples(allOnlines);
        });

        socket.on("messageArrived", (message) => {
            // console.log(curChat.current.email)

            if (curChat.current.email === message.fromEmail) {
                console.log("same person")
                updateMessagesList(message);
            } else {
                console.log("arrived but not the same")
            }
        })

    }, [])

    useEffect(() => {
        if (currentChat) {

            curChat.current = currentChat;

            async function getMessagesNow() {

                const members = [
                    user.email,
                    currentChat.email
                ]
                const getMessages = await axios.post(serverUrl + "/chatai/find", members)

                if (getMessages.status === 200) {
                    // console.log(getMessages.data)
                    setConversations(getMessages.data.messages);
                    curConv.current = getMessages.data.messages;

                    setTimeout(() => {
                        const messagesSection = document.querySelector("#messagesSection");
                        messagesSection?.scrollIntoView({ behavior: "instant", block: "start", inline: "nearest" });
                    }, 200)
                } else {
                    setConversations(null);
                    curConv.current = null;
                }
            }

            getMessagesNow();

        }
    }, [currentChat]);

    useEffect(() => {
        if (currentChat && wobble) {
            if (wobble.email === currentChat.email) {
                const messagesSection = document.querySelector("#messagesSection");
                messagesSection?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            }
        }
    }, [wobble])

    const sendTheMessage = async (e) => {
        e.preventDefault();
        const preparedData = {
            "uuid": uuidv4(),
            "fromEmail": user.email,
            "toEmail": currentChat.email,
            "messageType": messageType,
            "message": editorValue.toString("html"),
            "conversationType": messageType,
            "attachments": "",
            "sentTime": dayjs().format("YYYY-MM-DD h:mm a"),
        }

        // console.log(preparedData);

        const sendMessage = await axios.post(serverUrl + "/chatai/send", preparedData);
        if (sendMessage.status === 200) {

            // console.log([...conversations, sendMessage.data])

            setEditorValue(RichTextEditor.createEmptyValue());

            if (conversations && conversations.length > 0) {
                setConversations([...conversations, sendMessage.data]);
                curConv.current = [...conversations, sendMessage.data];

                // const motherFrame = document.querySelector(`#motherFrameMessages_${currentChat.email.split("@")[0]}`);

                // const frame1 = document.createElement("div");
                // frame1.setAttribute("class", "d-flex alert alert-light text-break p-1 gap-2 m-0")

                // const frame2 = document.createElement("div");

                // const image = document.createElement("img");

                // image.setAttribute("src", sendMessage.data.fromEmail !== user.email ? people[sendMessage.data.fromEmail].image : user.profilePicture);
                // image.setAttribute("alt", sendMessage.data.fromEmail !== user.email ? people[sendMessage.data.fromEmail].name : user.name);
                // image.setAttribute("class", "rounded-circle border-0 profilePicture overflow-hidden");
                // image.setAttribute("style", "max-height:30px;");

                // frame2.appendChild(image);


                // const frame3 = document.createElement("div");

                // frame3.setAttribute("class", "d-flex flex-column mediumTextSize");

                // const span1 = document.createElement("span");
                // span1.setAttribute("class", "d-flex align-items-center gap-2");

                // const span2 = document.createElement("span");
                // span2.setAttribute("class", "fw-bold");
                // span2.innerHTML = sendMessage.data.fromEmail !== user.email ? people[sendMessage.data.fromEmail].name : user.name;

                // const span3 = document.createElement("span");
                // span3.setAttribute("class", "smallTextSize");
                // span3.innerHTML = `${sendMessage.data.sentTime.split(" ")[1]} ${sendMessage.data.sentTime.split(" ")[2]}`;

                // span1.appendChild(span2)
                // span1.appendChild(span3)

                // const span4 = document.createElement("span");
                // span4.innerHTML = sendMessage.data.message;

                // frame3.appendChild(span1)
                // frame3.appendChild(span4)

                // frame1.appendChild(frame2)
                // frame1.appendChild(frame3)


                // motherFrame.appendChild(frame1);



                // <div key={key} className='d-flex alert alert-light text-break p-1 gap-2 m-0'>
                //     <div>
                //         <img src={data.fromEmail !== user.email ? people[data.fromEmail].image : user.profilePicture} alt={data.fromEmail !== user.email ? people[data.fromEmail].name : user.name} className="rounded-circle border-0 profilePicture overflow-hidden" style={{ maxHeight: "30px" }} />
                //     </div>
                //     <div className='d-flex flex-column mediumTextSize'>
                //         <span className=' d-flex align-items-center gap-2'>
                //             <span className='fw-bold'>
                //                 {data.fromEmail !== user.email ? people[data.fromEmail].name : user.name}
                //             </span>
                //             <span className='smallTextSize'>
                //                 {`${data.sentTime.split(" ")[1]} ${data.sentTime.split(" ")[2]}`}
                //             </span>
                //         </span>
                //         <span>
                //             {data.message}
                //         </span>
                //     </div>
                // </div>

                socket.emit("sendMessageIndividual", sendMessage.data);

                setTimeout(() => {
                    const messagesSection = document.querySelector("#messagesSection");
                    messagesSection.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                }, 50)
            } else {
                console.log(sendMessage.data.messages)
                setConversations(sendMessage.data.messages);
                curConv.current = sendMessage.data.messages;

                socket.emit("sendMessageIndividual", sendMessage.data.messages[0]);
            }


        } else {
            console.log(sendMessage.data)
        }
    }

    const wobbleFromMe = () => {
        const data = {
            "fromEmail": user.email,
            "fromName": user.name,
            "toEmail": currentChat.email,
            "toName": currentChat.name,

        }

        socket.emit("wobbleIndividual", data);
    }

    const updateMessagesList = (message) => {
        setConversations([...curConv.current, message]);
        curConv.current = [...curConv.current, message];

        setTimeout(() => {
            const messagesSection = document.querySelector("#messagesSection");
            messagesSection?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }, 50)
    }


    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-12 col-sm-12 col-md-3 col-lg-2 bg-soul text-dark p-0 overflow-hidden">
                    <ListGroup className='bg-none rounded-0' style={{ height: "95vh", overflowY: "auto", overflowX: "hidden" }}>
                        {
                            people ?
                                Object.keys(people).length > 0 ?
                                    Object.keys(people).map((emails, keys) => {
                                        return (
                                            <ListGroup.Item key={keys} className='p-2 rounded-0 bg-none'>
                                                <div className='d-flex align-items-center justify-content-start gap-2 p-1 cursor-pointer position-relative' onClick={() => { setCurrentChat(people[emails]); curChat.current = people[emails]; setMessageType("individual"); }}>
                                                    <div className='d-flex align-items-center justify-content-start'>
                                                        <img src={people[emails].image} alt={people[emails].name} className="rounded-circle border-0 profilePicture overflow-hidden" style={{ maxHeight: "40px" }} />
                                                    </div>
                                                    <div className='d-flex flex-column align-items-start justify-content-between w-75'>
                                                        <span className='d-inline-block text-truncate text-capitalize mw-100' style={{ fontSize: "0.8rem", fontWeight: "500" }}>{people[emails].name}</span>
                                                        {/* <span className='d-inline-block text-truncate mw-100 text-muted mediumTextSize'>{emails}</span> */}
                                                    </div>
                                                    {/* <i className='fa fa-trash position-absolute p-1 top-0 end-0 d-flex align-items-center text-danger rounded-circle pe-auto cursor-pointer' onClick={() => { setAskForDeleteMember(true); setDeleteMemberEmail(emails); }} style={{ aspectRatio: "1", width: "2rem", height: "2rem" }} /> */}
                                                </div>
                                            </ListGroup.Item>
                                        )
                                    })
                                    :
                                    "No members"
                                :
                                "No members"
                        }
                    </ListGroup>
                </div>
                <div className="col-12 col-sm-12 col-md-9 col-lg-10 bg-soul p-0 shadow backgroundFiller">
                    {
                        currentChat ?
                            currentChat.email ?
                                <div className='d-flex flex-column' style={{ height: "95vh", overflowY: "hidden", overflowX: "hidden" }}>
                                    <div className='shadow-sm insightBackground px-2 py-1 sticky-top'>
                                        <div className='d-flex align-items-center justify-content-start gap-2 p-1 rounded position-relative' onClick={() => { }}>
                                            <div className='d-flex align-items-center justify-content-start'>
                                                <img src={currentChat.image} alt={"Admin"} className="rounded-circle border-0 profilePicture overflow-hidden" style={{ maxHeight: "40px" }} />
                                            </div>
                                            <div className='d-flex flex-column align-items-start justify-content-between flex-fill'>
                                                <span className='d-inline-block text-truncate mw-100 text-dark' style={{ fontSize: "1rem", fontWeight: "500" }}>{currentChat.name}</span>
                                                {/* <span className='d-inline-block text-truncate mw-100 text-muted mediumTextSize'>{currentChat.email}</span> */}
                                                <div className='d-inline-block text-truncate mw-100 text-muted mediumTextSize text-capitalize d-flex align-items-center gap-1'>
                                                    {
                                                        onlinePeoples ?
                                                            onlinePeoples.length > 0 ?
                                                                onlinePeoples.includes(currentChat.email) ?
                                                                    <button className='btn btn-success p-1'></button>
                                                                    :
                                                                    <button className='btn btn-warning p-1'></button>
                                                                :
                                                                <button className='btn btn-warning p-1'></button>
                                                            :
                                                            <button className='btn btn-warning p-1'></button>
                                                    }
                                                    <span>
                                                        {
                                                            onlinePeoples ?
                                                                onlinePeoples.length > 0 ?
                                                                    onlinePeoples.includes(currentChat.email) ?
                                                                        "Online"
                                                                        :
                                                                        "Offline"
                                                                    :
                                                                    "Offline"
                                                                :
                                                                "Offline"
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-end'>
                                                <Info className='text-primary cursor-pointer' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex-fill p-3' style={{ overflowX: "hidden", height: "100%" }}>
                                        {
                                            conversations ?
                                                <>
                                                    <div className='w-100'>
                                                        {
                                                            conversations.map((data, key) => {
                                                                console.log(data.fromEmail + " : " + people[data.fromEmail]?.image);

                                                                return (
                                                                    <div key={key} className='d-flex alert text-break p-1 gap-2 m-0'>
                                                                        <div>
                                                                            <img src={data.fromEmail !== user.email ? people[data.fromEmail]?.image : user.profilePicture} alt={data.fromEmail !== user.email ? people[data.fromEmail].name : user.name} className="rounded-circle border-0 profilePicture overflow-hidden" style={{ maxHeight: "30px" }} />
                                                                        </div>
                                                                        <div className='d-flex flex-column mediumTextSize'>
                                                                            <span className=' d-flex align-items-center gap-2'>
                                                                                <span className='fw-bold' style={{ lineHeight: "0px" }}>
                                                                                    {data.fromEmail !== user.email ? people[data.fromEmail].name : user.name}
                                                                                </span>
                                                                                <span className='smallTextSize'>
                                                                                    {`${data.sentTime.split(" ")[1]} ${data.sentTime.split(" ")[2]}`}
                                                                                </span>
                                                                            </span>
                                                                            <span className='messageFrame'>
                                                                                {parse(data.message)}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    {
                                                        (wobble !== null) ?
                                                            wobble.email === currentChat.email ?
                                                                <div id="messagesSection" className='wobbleTyping'>
                                                                    <label className="wobbleName">
                                                                        <img src={people[wobble.email].image} alt={wobble.name} className="rounded-circle border-0 profilePicture overflow-hidden" style={{ maxHeight: "20px" }} />
                                                                        {/* {wobble.name} */}
                                                                    </label>
                                                                    <span></span>
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                                :
                                                                <div id="messagesSection" className='pb-1 w-100'></div>
                                                            :
                                                            <div id="messagesSection" className='pb-1 w-100'></div>
                                                    }
                                                </>
                                                :
                                                <>
                                                    <div className='d-flex align-items-center justify-content-center text-center p-3 w-100 rounded-0 h-100'>
                                                        <div className='alert alert-info w-100'>
                                                            <EmojiPeople />
                                                            Say hii to {currentChat.name}
                                                            {
                                                                (wobble !== null) ?
                                                                    wobble.email === currentChat.email ?
                                                                        <div id="messagesSection" className='wobbleTyping'>
                                                                            <label className="wobbleName">
                                                                                <img src={people[wobble.email].image} alt={wobble.name} className="rounded-circle border-0 profilePicture overflow-hidden" style={{ maxHeight: "20px" }} />
                                                                                {/* {wobble.name} */}
                                                                            </label>
                                                                            <span></span>
                                                                            <span></span>
                                                                            <span></span>
                                                                        </div>
                                                                        :
                                                                        <div id="messagesSection" className='w-100'></div>
                                                                    :
                                                                    <div id="messagesSection" className='w-100'></div>
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                    <div className='d-flex align-items-center' style={{ height: "auto" }}>
                                        <form className='w-100 p-3 pt-0' action="" onSubmit={(e) => { sendTheMessage(e); }}>
                                            <div className='d-flex flex-column align-items-center w-100 shadow-sm border rounded overflow-hidden insightBackground'>
                                                <RichTextEditor
                                                    // toolbarConfig={toolbarConfig}
                                                    value={editorValue}
                                                    placeholder={`Message ${currentChat.name}`}
                                                    onChange={(e) => {
                                                        setEditorValue(e);
                                                        if (e.toString("html").length > 0) {
                                                            wobbleFromMe();
                                                            console.log(e.toString("html"))
                                                            const messagesSection = document.querySelector("#messagesSection");
                                                            messagesSection.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                                                            setSentButtonEnable(true);
                                                        } else {
                                                            setSentButtonEnable(false);
                                                        }
                                                    }}
                                                    className='w-100 p-0 rounded-0 border-0'
                                                />

                                                <div className='d-flex align-items-center w-100 justify-content-between p-1 border-top'>
                                                    <div className='d-flex gap-1'>
                                                        <label htmlFor="fileAtatach" className='btn p-0 h-100 d-flex align-items-center justify-content-center btn-sm shadow-sm rounded-circle' style={{ aspectRatio: "1" }}>
                                                            <AttachFile style={{ height: "20px" }} />
                                                            <input type="file" id='fileAtatach' className='w-100 rounded-pill h-100 shadow-sm border px-3 d-none' />
                                                        </label>
                                                        <label htmlFor="" className='btn p-0 h-100 d-flex align-items-center justify-content-center btn-sm shadow rounded-circle' style={{ aspectRatio: "1" }}>
                                                            <PlusOne style={{ height: "20px" }} />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <button className='btn btn-success rounded shadow-sm p-0' disabled={!sentButtonEnable} style={{ aspectRatio: "1" }} >
                                                            <Send style={{ height: "20px" }} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                :
                                <div className="alert alert-info w-100 text-center">
                                    choose a contact
                                </div>
                            :
                            <div className="alert alert-info w-100 text-center">
                                choose a contact
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Messanger