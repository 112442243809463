import React from 'react';
import LifeScienceAndPharmaPng from "../../images/Life Science and Pharma.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';

const SciencePharma = () => {
  return (
    <>
      <div className="heWhoRemains">
        <Header />
        <div>
          <section>
            <div className="container mt-5">
              <div className="row">

                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                  <div className="mt-4 ">
                    <h1 className=" display-4 fw-bold smd_text">Schedule AI for <br />
                      <span style={{ color: "#0B5ED7" }}> Life Sciences & Pharma
                      </span>
                    </h1>
                    <p className="lead_smd text-black" style={{ fontSize: "1.1em" }}>
                      For life science & pharma businesses, ScheduleAI software can be an invaluable tool for keeping things running smoothly and efficiently. This can save valuable time for both patients and practitioners alike. In addition, it can help to ensure that appointments are made in a timely manner and that cancellations or no-shows are kept to a minimum.
                    </p>
                    <a href="/login"><button
                      className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                      Demo</button></a>
                  </div>
                </div>
                <div className="col-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                  <img className="w-100" src={LifeScienceAndPharmaPng} alt='Life Science and Pharma' />
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container mt-5">
              <div className="column pt-5">
                <h2 className="display-6 fw-bold text-center">Benefits of ScheduleAI for <br />Life Sciences & Pharma
                </h2>
              </div>

              <ul>
                <li className='liListDevind'>This software may include the ability to send reminders to patients about upcoming appointments, as well as the ability to track payments and schedule follow-up appointments.
                </li>

                <li className='liListDevind'> It can save the company time and money by automating the scheduling process. In addition, it can also help to keep track of customer appointments and make sure that they are always up to date.
                </li>

                <li className='liListDevind'>It can help to improve customer satisfaction by providing them with an easy way to schedule appointments and track their progress.
                </li>

                <li className='liListDevind'>It can save you a great deal of time and hassle when trying to schedule appointments with clients or patients.
                </li>

                <li className='liListDevind'> It can also help to ensure that appointments are kept, which can be important for both business and personal reasons.
                </li>
              </ul>

            </div>
          </section>

          <section>
            <div className="container textDevLay pb-4">
              <div className="column pt-5">
                <h2 className="display-6 fw-bold">Conclusion
                </h2>
                <p className='lead_smd text-justify'>Schedule AI software can often provide valuable insights into patterns of behavior or demand, which can be extremely helpful in managing future appointments.
                </p>
              </div>
            </div>
          </section>

        </div>

        <Footer />
      </div>
    </>
  )
}

export default SciencePharma
