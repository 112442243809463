import React from 'react';
import ScheduleAIForConsumerElectronicsBannerPng from "../../images/ScheduleAI for Consumer Electronics banner.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';

function ConsumerElectronics() {
    return (
        <>
            <div className="heWhoRemains">
                <Header />
                <div>
                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">
                                        <h1 className="mainHomeTitle">Schedule AI for
                                            <span style={{ color: "#0B5ED7" }}> Consumer Electronics
                                            </span>
                                        </h1>
                                        <p className="lead_smd">
                                            Are you looking for a way to streamline your consumer electronics business? ScheduleAI software can be a great way to do just that! With ScheduleAI software, you can keep track of your appointments and customers in one easy-to-use system. This can save you time and money by keeping your business organized and running smoothly.
                                        </p>
                                        <a href="/login"><button
                                            className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                                            Demo</button></a>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <img className="mainDevImg" src={ScheduleAIForConsumerElectronicsBannerPng} alt='ScheduleAI for Consumer Electronics banner' />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold">Benefits of using ScheduleAI for Consumer Electronics
                                </h2>
                            </div>
                            <div className="row">
                                <ul>
                                    <li className='liListDevind'>
                                        It can help you keep track of your appointments and customers. This can save you time and money by keeping your business organized and running smoothly.
                                    </li>

                                    <li className='liListDevind'>
                                        It can save you a lot of time. manually scheduling appointments can be very time-consuming, especially if you have a large customer base. With ScheduleAI software, all of that can be taken care of quickly and easily.
                                    </li>

                                    <li className='liListDevind'>
                                        When you are manually scheduling appointments, it can be easy to make mistakes that result in two or more customers being scheduled for the same time slot.
                                    </li>
                                    <li className='liListDevind'>
                                        When customers see that you are using professional software to schedule their appointments, they will likely have a higher opinion of your business as a whole.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container textDevLay pb-4">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold">Conclusion
                                </h2>
                                <p className='lead_smd text-justify'>If you are looking for a way to improve your consumer electronics business, consider using ScheduleAI software. It can save you time and money while helping you better understand and serve your customers!.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default ConsumerElectronics
