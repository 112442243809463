import React, { useEffect } from 'react'
import EnterpriseBannerPng from "../../images/enterprise banner.png";
import salesProfit from "../../images/sales-profile.png";
import marketingProfilePng from "../../images/marketing-profile.png";
import customerSuccessPng from "../../images/customer-success.png";
import recruitementProfilePng from "../../images/recruiting-profile.png";
import informationTechPng from "../../images/Information-technology.png";
import { Tab, Tabs, Accordion } from 'react-bootstrap';
import educationPng from "../../images/education.png"
import TerminalIcon from '@mui/icons-material/Terminal';
import GroupIcon from '@mui/icons-material/Group';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import PartnerCustomerScucessPng from "../../images/partner customer scucess.png";
import KeepDataSafePng from "../../images/Keep data safe.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';
import centralizeBillingUserPng from "../../images/centralize billing user.png";
import GuaranteeSecurityPng from "../../images/Guarantee security.png";
import HealthcareScheduleaiPng from "../../images/Healthcare scheduleai.png";
import { Link } from 'react-router-dom';
import "../../Custom.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet";
import ScrollTopButton from '../scrolltopbtn/ScrollTopButton';

function Enterprise() {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <div className='heWhoRemains'>
            <Helmet>
                <title>For enterprise appointments scheduling software - Scheduleai</title>
                <meta name="description" content="Over 100,00 companies and 10 million users globally trust Scheduleai's user-friendly scheduling platform to create wonderful and effortless experiences.
" />
            </Helmet>
            <Header />
            <div>

                <div>
                    <section>
                        <div className="container">
                            <div className="row webDevRow">
                                <div className="col-12 col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">
                                        <h1 className="smd_text fw-bold" style={{ fontSize: "2em" }}>Solving
                                            <span style={{ color: "#0B5ED7" }}> Enterprise External </span>
                                            Scheduling Challenges
                                        </h1>
                                        <p className="text-black lead_smd" style={{ fontSize: "1em" }}>
                                            Scheduleai's powerful scheduling solution is trusted by over 4 out of 5 Fortune 500 companies.
                                        </p>
                                        <div data-aos="flip-up">
                                            <Link to="/login" className='btnDev'>Get Started</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-md-6 pt-5" >
                                    <img className="mainDevImg" title="Enterprise Schedule Ai" src={EnterpriseBannerPng} alt="enterprise-section-banner" />
                                </div>
                            </div>
                        </div>
                    </section>


                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">
                                <div className="col-12 col-lg-6 col-md-6">
                                    <img className="mainDevImg" title="Billing Centralize" src={centralizeBillingUserPng} alt="Centralize billing user" />
                                </div>
                                <div className="col-12 col-lg-6 col-md-6 d-flex justify-content-center align-items-center">
                                    <div className="mt-4 pb-3 px-4">
                                        <h6 className="text-primary" style={{ letterSpacing: "2px" }}>Business Goals</h6>
                                        <h1 className="smd_title fw-bold" style={{ letterSpacing: "1px" }}>Achieve Business Goals
                                        </h1>
                                        <p className="lead_smd">Drive efficiency and productivity across your teams with accelerated scheduling that seamlessly integrates with your preferred tools, apps, and browsers.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container num_DevCont mt-5 pt-5 pb-5">
                            <div className="row webDevRow pt-4">
                                <div className="col-12 col-lg-4 col-md-4 num_Dev">
                                    <div className='num_DevBrd'>
                                        <h2 className="display-4 text-center fw-bold" style={{ color: "#0A2540" }}><span
                                            className="text-primary fw-bold">50</span>%</h2>
                                        <h5 className="text-center" style={{ letterSpacing: "1px" }}>Quicker meeting <br />planning</h5>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 col-md-4 num_Dev" data-aos="zoom-in"
                                    data-aos-duration="500"
                                    data-aos-delay="100" >
                                    <div className='num_DevBrd'>
                                        <h2 className="display-4 text-center fw-bold" style={{ color: "#0A2540" }}><span
                                            className="text-primary fw-bold">30</span>%</h2>
                                        <h5 className="text-center" style={{ letterSpacing: "1px" }}>More income <br />per lead</h5>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 col-md-4 num_Dev" style={{ border: "none" }}>
                                    <div className='num_DevBrd'>
                                        <h2 className="display-4 text-center fw-bold" style={{ color: "#0A2540" }}><span
                                            className="text-primary fw-bold">100</span>%</h2>
                                        <h5 className="text-center" style={{ letterSpacing: "1px" }}>More applicant <br />screens</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className='mt-4' style={{ background: "#0a7bdd26" }}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col">
                                    <h2 className="text-center smd_text mt-5 mb-4">Intended for groups who direct <br />gatherings
                                        at scale</h2>
                                </div>
                            </div>
                            <div className="row d-none d-lg-flex insideGlassDev">
                                <div className="col">
                                    <Tabs
                                        id="uncontrolled-tab-example"
                                        className='justify-content-center'>
                                        <Tab eventKey="sales" title={<><i className="fa-solid fa-chart-line d-flex justify-content-center" style={{ fontSize: "1.5em", maxHeight: "10px" }}></i><br /><span className=' text-capitalize'>Sales</span></>}>

                                            <div id="collapseOne" className="accordion-collapse collapse show  d-lg-block"
                                                aria-labelledby="headingOne" data-bs-parent="#myTabContent">
                                                <div className="accordion-body d-flex pt-4">
                                                    <div className="container">
                                                        <div className="row DevRow">
                                                            <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                                                                <div>
                                                                    <ol>
                                                                        <li className="d-flex">
                                                                            <div className="icon-dollar">
                                                                                <i className="fa-solid fa-dollar-sign text-primary icon_Dev"></i>
                                                                            </div>
                                                                            <div className="drive-text-dev">
                                                                                <h5 className="align-items-center tab-textDev"
                                                                                    style={{ marginLeft: "5px" }}>Drive
                                                                                    more income</h5>
                                                                                <p className="tab-paraDev"
                                                                                    style={{ marginLeft: "5px" }}>Instantly book high-value meetings to gain a competitive advantage.
                                                                                </p>
                                                                            </div>
                                                                        </li>
                                                                        <li className="d-flex">
                                                                            <div className="icon-dollar">
                                                                                <i className="fa-solid fa-arrows-rotate text-primary icon_Dev"></i>
                                                                            </div>
                                                                            <div className="drive-text-dev">
                                                                                <h5 className="align-items-center tab-textDev"
                                                                                    style={{ marginLeft: "5px" }}>
                                                                                    Accelerate your sales cycle</h5>
                                                                                <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Maintain high deal momentum and eliminate scheduling friction at every stage of your sales process.
                                                                                </p>
                                                                            </div>
                                                                        </li>
                                                                        <li className="d-flex">
                                                                            <div className="icon-dollar">
                                                                                <i className="fa-solid fa-handshake text-primary icon_Dev"></i>
                                                                            </div>
                                                                            <div className="drive-text-dev">
                                                                                <h5 className="align-items-center tab-textDev"
                                                                                    style={{ marginLeft: "5px" }}>Close more deals</h5>
                                                                                <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Tailor reminders and follow-ups to keep deals moving, integrate with sales tools, and remove logistical tasks to focus on selling.
                                                                                </p>
                                                                            </div>
                                                                        </li>
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-lg-6">
                                                                <img className="homeTabImgDev" title="Schedule Ai for Sales" src={salesProfit} alt='' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="marketing" title={<><i className="fa-solid fa-magnifying-glass-dollar d-flex justify-content-center" style={{ fontSize: "2em", maxHeight: "10px" }}></i><br /><span className=' text-capitalize'>marketing</span></>}>
                                            <h2 className="accordion-header d-lg-none" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    Marketing
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse d-lg-block" aria-labelledby="headingTwo"
                                                data-bs-parent="#myTabContent">
                                                <div className="accordion-body pt-4">
                                                    <div className="container">
                                                        <div className="row DevRow">
                                                            <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                                                                <div className="mt-4 ">
                                                                    <ol>
                                                                        <li className="d-flex">
                                                                            <div className="icon-dollar">
                                                                                <i className="fa-solid fa-user text-primary icon_Dev"></i>
                                                                            </div>
                                                                            <div className="drive-text-dev">
                                                                                <h5 className="align-items-center tab-textDev"
                                                                                    style={{ marginLeft: "5px" }}>Drive more pipeline
                                                                                </h5>
                                                                                <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Convert marketing leads into booked meetings faster.</p>
                                                                            </div>
                                                                        </li>
                                                                        <li className="d-flex">
                                                                            <div className="icon-dollar">
                                                                                <i className="fa-regular fa-clock text-primary icon_Dev"></i>
                                                                            </div>
                                                                            <div className="drive-text-dev">
                                                                                <h5 className="align-items-center tab-textDev"
                                                                                    style={{ marginLeft: "5px" }}>Improve lead response times</h5>
                                                                                <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Gain a competitive advantage by quickly qualifying, routing, and booking leads.
                                                                                </p>
                                                                            </div>
                                                                        </li>
                                                                        <li className="d-flex">
                                                                            <div className="icon-dollar">
                                                                                <i className="fa-solid fa-chart-line text-primary icon_Dev"></i>
                                                                            </div>
                                                                            <div className="drive-text-dev">
                                                                                <h5 className="align-items-center tab-textDev"
                                                                                    style={{ marginLeft: "5px" }}>Boost conversion rates</h5>
                                                                                <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Reduce friction in the sales pipeline and close more deals.
                                                                                </p>
                                                                            </div>
                                                                        </li>
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-lg-6">
                                                                <img className="homeTabImgDev" title="Schedule Ai for Marketng" src={marketingProfilePng} alt='' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="CustomerSuccess" title={<><i className="fa-solid fa-users d-flex justify-content-center" style={{ fontSize: "1.5em", maxHeight: "10px" }}></i><br /><span className=' text-capitalize'>Customer Success</span></>}>
                                            <h2 className="accordion-header d-lg-none" id="headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    Customer Success
                                                </button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse d-lg-block"
                                                aria-labelledby="headingThree" data-bs-parent="#myTabContent">
                                                <div className="accordion-body pt-4">
                                                    <div className="container">
                                                        <div className="row DevRow">
                                                            <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center" >
                                                                <div className="mt-4 ">
                                                                    <ol>
                                                                        <li className="d-flex">
                                                                            <div className="icon-dollar">
                                                                                <i className="fa-regular fa-face-smile text-primary icon_Dev"></i>
                                                                            </div>
                                                                            <div className="drive-text-dev">
                                                                                <h5 className="align-items-center tab-textDev"
                                                                                    style={{ marginLeft: "5px" }}>Drive more maintenance</h5>
                                                                                <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Unite all your specialists and engage with clients throughout their journey to build lasting relationships.
                                                                                </p>
                                                                            </div>
                                                                        </li>
                                                                        <li className="d-flex">
                                                                            <div className="icon-dollar">
                                                                                <i className="fa-regular fa-clock text-primary icon_Dev"></i>
                                                                            </div>
                                                                            <div className="drive-text-dev">
                                                                                <h5 className="align-items-center tab-textDev"
                                                                                    style={{ marginLeft: "5px" }}>
                                                                                    Accelerate your response times
                                                                                </h5>
                                                                                <p className="tab-paraDev" style={{ marginLeft: "5px" }}>IQuickly schedule a time to address clients' needs and assist them in achieving their goals efficiently.
                                                                                </p>
                                                                            </div>
                                                                        </li>
                                                                        <li className="d-flex">
                                                                            <div className="icon-dollar">
                                                                                <i className="fa-solid fa-star text-primary icon_Dev"></i>
                                                                            </div>
                                                                            <div className="drive-text-dev">
                                                                                <h5 className="align-items-center tab-textDev"
                                                                                    style={{ marginLeft: "5px" }}>Elevate NPS and Delight Customers</h5>
                                                                                <p className="tab-paraDev" style={{ marginLeft: "5px" }}>By optimizing meeting scheduling, you can elevate customer satisfaction to new heights. Keep customers engaged and satisfied through well-timed reminders and efficient follow-up workflows.</p>
                                                                            </div>
                                                                        </li>
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-lg-6">
                                                                <img className="homeTabImgDev" title="Schedule Ai for Customer Success" src={customerSuccessPng} alt='' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Recruiting" title={<><i className="fa-solid fa-user-graduate d-flex justify-content-center" style={{ fontSize: "1.3em", maxHeight: "10px" }}></i><br /><span className=' text-capitalize'>Recruiting</span></>}>
                                            <h2 className="accordion-header d-lg-none" id="headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    Recruiting
                                                </button>
                                            </h2>
                                            <div id="collapseFour" className="accordion-collapse collapse d-lg-block" aria-labelledby="headingFour"
                                                data-bs-parent="#myTabContent">
                                                <div className="accordion-body pt-4">
                                                    <div className="container">
                                                        <div className="row DevRow">
                                                            <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                                                                <div>
                                                                    <ol>
                                                                        <li className="d-flex">
                                                                            <div className="icon-dollar">
                                                                                <i className="fa-solid fa-user-tie text-primary icon_Dev"></i>
                                                                            </div>
                                                                            <div className="drive-text-dev">
                                                                                <h5 className="align-items-center tab-textDev"
                                                                                    style={{ marginLeft: "5px" }}>Boost employee productivity</h5>
                                                                                <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Spend less time struggling with schedules and more time connecting with candidates.
                                                                                </p>
                                                                            </div>
                                                                        </li>
                                                                        <li className="d-flex">
                                                                            <div className="icon-dollar">
                                                                                <i className="fa-solid fa-gauge-simple-high text-primary icon_Dev"></i>
                                                                            </div>
                                                                            <div className="drive-text-dev">
                                                                                <h5 className="align-items-center tab-textDev"
                                                                                    style={{ marginLeft: "5px" }}>
                                                                                    Accelerate your recruiting cycle</h5>
                                                                                <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Schedule interviews in a matter of minutes and reduce time-to-fill positions.</p>
                                                                            </div>
                                                                        </li>
                                                                        <li className="d-flex">
                                                                            <div className="icon-dollar">
                                                                                <i className="fa-solid fa-user-tie text-primary icon_Dev"></i>
                                                                            </div>
                                                                            <div className="drive-text-dev">
                                                                                <h5 className="align-items-center tab-textDev"
                                                                                    style={{ marginLeft: "5px" }}>Improve NPS and candidate experience
                                                                                </h5>
                                                                                <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Eliminate friction and turn your recruiting process into a competitive advantage.
                                                                                </p>
                                                                            </div>
                                                                        </li>
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-lg-6">
                                                                <img className="homeTabImgDev" title="Schedule Ai for Recruiting" src={recruitementProfilePng} alt='' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="InformationTechnology" title={<><i className="fa-solid fa-laptop-code d-flex justify-content-center" style={{ fontSize: "1.2em", maxHeight: "10px" }}></i><br /><span className=' text-capitalize'>Information Technology</span></>}>
                                            <h2 className="accordion-header d-lg-none" id="headingFive">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFive">
                                                    Information Technology
                                                </button>
                                            </h2>
                                            <div id="collapseFive" className="accordion-collapse collapse d-lg-block" aria-labelledby="headingFive"
                                                data-bs-parent="#myTabContent">
                                                <div className="accordion-body pt-4">
                                                    <div className="container">
                                                        <div className="row DevRow">
                                                            <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                                                                <div className="mt-4 ">
                                                                    <ol>
                                                                        <li className="d-flex">
                                                                            <div className="icon-dollar">
                                                                                <i className="fa-sharp fa-solid fa-shield-halved text-primary icon_Dev"></i>
                                                                            </div>
                                                                            <div className="drive-text-dev">
                                                                                <h5 className="align-items-center tab-textDev"
                                                                                    style={{ marginLeft: "5px" }}>Ensure enterprise-grade security with booking automation
                                                                                </h5>
                                                                                <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Stay aligned with your security requirements and reduce risk throughout the organization.</p>
                                                                            </div>
                                                                        </li>
                                                                        <li className="d-flex">
                                                                            <div className="icon-dollar">
                                                                                <i className="fa-solid fa-laptop-code text-primary icon_Dev"></i>
                                                                            </div>
                                                                            <div className="drive-text-dev">
                                                                                <h5 className="align-items-center tab-textDev"
                                                                                    style={{ marginLeft: "5px" }}>Implement and manage effortlessly</h5>
                                                                                <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Eliminate the manual processes of implementing and managing client access and permissions on the platform.
                                                                                </p>
                                                                            </div>
                                                                        </li>
                                                                        <li className="d-flex">
                                                                            <div className="icon-dollar">
                                                                                <i className="fa-solid fa-dollar-sign text-primary icon_Dev"></i>
                                                                            </div>
                                                                            <div className="drive-text-dev">
                                                                                <h5 className="align-items-center tab-textDev"
                                                                                    style={{ marginLeft: "5px" }}>Drive adoption and ROI across teams</h5>
                                                                                <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Partner with our team to onboard seamlessly, drive adoption, and identify success metrics to achieve greater value, faster.
                                                                                </p>
                                                                            </div>
                                                                        </li>
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-lg-6">
                                                                <img className="homeTabImgDev" title="Schedule Ai for Information Technology" src={informationTechPng} alt='' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Education" title={<><i className="fa-solid fa-school d-flex justify-content-center" style={{ fontSize: "1.2em", maxHeight: "10px" }}></i><br /><span className=' text-capitalize'>Education</span></>}>
                                            <h2 className="accordion-header d-lg-none d-flex justify-content-center align-items-center" id="headingFive">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                    Education
                                                </button>
                                            </h2>
                                            <div id="collapseSix" className="accordion-collapse collapse d-lg-block" aria-labelledby="headingSix"
                                                data-bs-parent="#myTabContent">
                                                <div className="accordion-body pt-4">
                                                    <div className="container">
                                                        <div className="row DevRow">
                                                            <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                                                                <div className="mt-4 ">
                                                                    <ol>
                                                                        <li className="d-flex">
                                                                            <div className="icon-dollar">
                                                                                <i className="fa-solid fa-bolt text-primary icon_Dev"></i>
                                                                            </div>
                                                                            <div className="drive-text-dev">
                                                                                <h5 className="align-items-center tab-textDev"
                                                                                    style={{ marginLeft: "5px" }}>Foster deeper connections through the automated booking</h5>
                                                                                <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Dedicate higher-quality time to support students in advising, mentoring, career planning, and counseling.</p>
                                                                            </div>
                                                                        </li>
                                                                        <li className="d-flex">
                                                                            <div className="icon-dollar">
                                                                                <i className="fa-solid fa-calendar-days text-primary icon_Dev"></i>
                                                                            </div>
                                                                            <div className="drive-text-dev">
                                                                                <h5 className="align-items-center tab-textDev"
                                                                                    style={{ marginLeft: "5px" }}>
                                                                                    Enhance communication and streamline coordination</h5>
                                                                                <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Increase accessibility of your schedule to students and prospects, empowering them to book time when they need assistance the most.
                                                                                </p>
                                                                            </div>
                                                                        </li>
                                                                        <li className="d-flex">
                                                                            <div className="icon-dollar">
                                                                                <i className="fa-solid fa-person-chalkboard text-primary icon_Dev"></i>
                                                                            </div>
                                                                            <div className="drive-text-dev">
                                                                                <h5 className="align-items-center tab-textDev"
                                                                                    style={{ marginLeft: "5px" }}>Deliver an enriched educational experience
                                                                                </h5>
                                                                                <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Focus on what truly matters: supporting and engaging with your students, empowering them to thrive and succeed.
                                                                                </p>
                                                                            </div>
                                                                        </li>
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-lg-6">
                                                                <img className="homeTabImgDev" title="Schedule Ai for Education" src={educationPng} alt='' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Healthcare" title={<><i className="fa-solid fa-hospital d-flex justify-content-center" style={{ fontSize: "1.2em", maxHeight: "10px" }}></i><br /><span className=' text-capitalize'>Healthcare</span></>}>
                                            <h2 className="accordion-header d-lg-none d-flex justify-content-center align-items-center" id="headingFive">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                    Healthcare
                                                </button>
                                            </h2>
                                            <div id="collapseSeven" className="accordion-collapse collapse d-lg-block" aria-labelledby="headingSeven"
                                                data-bs-parent="#myTabContent">
                                                <div className="accordion-body pt-4">
                                                    <div className="container">
                                                        <div className="row DevRow">
                                                            <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                                                                <div className="mt-4 ">
                                                                    <ol>
                                                                        <li className="d-flex">
                                                                            <div className="icon-dollar">
                                                                                <i className="fa-solid fa-calendar-check text-primary icon_Dev"></i>
                                                                            </div>
                                                                            <div className="drive-text-dev">
                                                                                <h5 className="align-items-center tab-textDev"
                                                                                    style={{ marginLeft: "5px" }}>Intelligent Appointment Booking</h5>
                                                                                <p className="tab-paraDev" style={{ marginLeft: "5px" }}>ScheduleAI utilizes cutting-edge algorithms to analyze patient data, availability, and preferences, suggesting the most suitable appointment slots. Eliminate long wait times and scheduling conflicts.
                                                                                </p>
                                                                            </div>
                                                                        </li>
                                                                        <li className="d-flex">
                                                                            <div className="icon-dollar">
                                                                                <i className="fa-solid fa-notes-medical text-primary icon_Dev"></i>
                                                                            </div>
                                                                            <div className="drive-text-dev">
                                                                                <h5 className="align-items-center tab-textDev"
                                                                                    style={{ marginLeft: "5px" }}>
                                                                                    Tailored Workflows</h5>
                                                                                <p className="tab-paraDev" style={{ marginLeft: "5px" }}>ScheduleAI adapts to the unique needs of your healthcare facility. Whether you run a hospital, clinic, or specialized practice, you can customize workflows and scheduling rules to meet your specific requirements.</p>
                                                                            </div>
                                                                        </li>
                                                                        <li className="d-flex">
                                                                            <div className="icon-dollar">
                                                                                <i className="fa-sharp fa-solid fa-shield-halved text-primary icon_Dev"></i>
                                                                            </div>
                                                                            <div className="drive-text-dev">
                                                                                <h5 className="align-items-center tab-textDev"
                                                                                    style={{ marginLeft: "5px" }}>Compliance and Data Security</h5>
                                                                                <p className="tab-paraDev" style={{ marginLeft: "5px" }}>We prioritize patient privacy and data security. ScheduleAI adheres to the highest industry standards, ensuring that patient information remains protected at all times.
                                                                                </p>
                                                                            </div>
                                                                        </li>
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-lg-6">
                                                                <img className="w-100" title="Schedule Ai for Education" src={HealthcareScheduleaiPng} alt='' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </section>


                    <Accordion flush className='d-lg-none'>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Sales</Accordion.Header>
                            <Accordion.Body>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <div className="mt-4 ">
                                                <ol>
                                                    <li className="d-flex">
                                                        <div className="icon-dollar">
                                                            <i className="fa-solid fa-dollar-sign text-primary icon_Dev"></i>
                                                        </div>
                                                        <div className="drive-text-dev">
                                                            <h5 className="align-items-center tab-textDev"
                                                                style={{ marginLeft: "5px" }}>Drive more income
                                                            </h5>
                                                            <p className="tab-paraDev"
                                                                style={{ marginLeft: "5px" }}>Instantly book high-value meetings to gain a competitive advantage.</p>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex">
                                                        <div className="icon-dollar">
                                                            <i className="fa-solid fa-arrows-rotate text-primary icon_Dev"></i>
                                                        </div>
                                                        <div className="drive-text-dev">
                                                            <h5 className="align-items-center tab-textDev"
                                                                style={{ marginLeft: "5px" }}>
                                                                Accelerate your sales cycle</h5>
                                                            <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Maintain high deal momentum and eliminate scheduling friction at every stage of your sales process.</p>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex">
                                                        <div className="icon-dollar">
                                                            <i className="fa-solid fa-handshake text-primary icon_Dev"></i>
                                                        </div>
                                                        <div className="drive-text-dev">
                                                            <h5 className="align-items-center tab-textDev"
                                                                style={{ marginLeft: "5px" }}>Close more deals</h5>
                                                            <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Tailor reminders and follow-ups to keep deals moving, integrate with sales tools, and remove logistical tasks to focus on selling.</p>
                                                        </div>
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <img className="w-100" src={salesProfit} alt='' />
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Marketing</Accordion.Header>
                            <Accordion.Body>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <div className="mt-4 ">
                                                <ol>
                                                    <li className="d-flex">
                                                        <div className="icon-dollar">
                                                            <i className="fa-solid fa-user text-primary icon_Dev"></i>
                                                        </div>
                                                        <div className="drive-text-dev">
                                                            <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                                Drive more pipeline
                                                            </h5>
                                                            <p className="tab-paraDev" style={{ marginLeft: "5px" }}>TConvert marketing leads into booked meetings faster.</p>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex">
                                                        <div className="icon-dollar">
                                                            <i className="fa-regular fa-clock text-primary icon_Dev"></i>
                                                        </div>
                                                        <div className="drive-text-dev">
                                                            <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                                Improve lead response times</h5>
                                                            <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Gain a competitive advantage by quickly qualifying, routing, and booking leads.</p>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex">
                                                        <div className="icon-dollar">
                                                            <i className="fa-solid fa-chart-line text-primary icon_Dev"></i>
                                                        </div>
                                                        <div className="drive-text-dev">
                                                            <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                                Boost conversion rates</h5>
                                                            <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Reduce friction in the sales pipeline and close more deals.</p>
                                                        </div>
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <img className="w-100" title="Schedule Ai for Marketing" src={marketingProfilePng} alt='' />
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Customer Success</Accordion.Header>
                            <Accordion.Body>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <div className="mt-4 ">
                                                <ol>
                                                    <li className="d-flex">
                                                        <div className="icon-dollar">
                                                            <i className="fa-regular fa-face-smile text-primary icon_Dev"></i>
                                                        </div>
                                                        <div className="drive-text-dev">
                                                            <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>Drive more maintenance
                                                            </h5>
                                                            <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Unite all your specialists and engage with clients throughout their journey to build lasting relationships.</p>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex">
                                                        <div className="icon-dollar">
                                                            <i className="fa-regular fa-clock text-primary icon_Dev"></i>
                                                        </div>
                                                        <div className="drive-text-dev">
                                                            <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                                Accelerate your response times</h5>
                                                            <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Quickly schedule a time to address clients' needs and assist them in achieving their goals efficiently.
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex">
                                                        <div className="icon-dollar">
                                                            <i className="fa-solid fa-star text-primary icon_Dev"></i>
                                                        </div>
                                                        <div className="drive-text-dev">
                                                            <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                                Elevate NPS and Delight Customers</h5>
                                                            <p className="tab-paraDev" style={{ marginLeft: "5px" }}>By optimizing meeting scheduling, you can elevate customer satisfaction to new heights. Keep customers engaged and satisfied through well-timed reminders and efficient follow-up workflows.</p>
                                                        </div>
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <img className="w-100" src={customerSuccessPng} alt='customer success' />
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Recruiting</Accordion.Header>
                            <Accordion.Body>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <div className="mt-4 ">
                                                <ol>
                                                    <li className="d-flex">
                                                        <div className="icon-dollar">
                                                            <i className="fa-solid fa-user-tie text-primary icon_Dev"></i>
                                                        </div>
                                                        <div className="drive-text-dev">
                                                            <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                                Boost employee productivity</h5>
                                                            <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Spend less time struggling with schedules and more time connecting with candidates.</p>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex">
                                                        <div className="icon-dollar">
                                                            <i className="fa-solid fa-gauge-simple-high text-primary icon_Dev"></i>
                                                        </div>
                                                        <div className="drive-text-dev">
                                                            <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                                Accelerate your recruiting cycle</h5>
                                                            <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Schedule interviews in a matter of minutes and reduce time-to-fill positions.</p>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex">
                                                        <div className="icon-dollar">
                                                            <i className="fa-solid fa-user-tie text-primary icon_Dev"></i>
                                                        </div>
                                                        <div className="drive-text-dev">
                                                            <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                                Improve NPS and candidate experience</h5>
                                                            <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Eliminate friction and turn your recruiting process into a competitive advantage.</p>
                                                        </div>
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <img className="w-100" src={recruitementProfilePng} alt='recruitement' />
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Information Technology</Accordion.Header>
                            <Accordion.Body>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <div className="mt-4 ">
                                                <ol>
                                                    <li className="d-flex">
                                                        <div className="icon-dollar">
                                                            <i className="fa-sharp fa-solid fa-shield-halved text-primary icon_Dev"></i>
                                                        </div>
                                                        <div className="drive-text-dev">
                                                            <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>Ensure enterprise-grade security with booking automation
                                                            </h5>
                                                            <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Stay aligned with your security requirements and reduce risk throughout the organization.</p>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex">
                                                        <div className="icon-dollar">
                                                            <i className="fa-solid fa-laptop-code text-primary icon_Dev"></i>
                                                        </div>
                                                        <div className="drive-text-dev">
                                                            <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                                Implement and manage effortlessly</h5>
                                                            <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Eliminate the manual processes of implementing and managing client access and permissions on the platform.</p>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex">
                                                        <div className="icon-dollar">
                                                            <i className="fa-solid fa-dollar-sign text-primary icon_Dev"></i>
                                                        </div>
                                                        <div className="drive-text-dev">
                                                            <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                                Drive adoption and ROI across teams</h5>
                                                            <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Partner with our team to onboard seamlessly, drive adoption, and identify success metrics to achieve greater value, faster.</p>
                                                        </div>
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <img className="w-100" src={informationTechPng} alt='information technology' />
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Education</Accordion.Header>
                            <Accordion.Body>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <div className="mt-4 ">
                                                <ol>
                                                    <li className="d-flex">
                                                        <div className="icon-dollar">
                                                            <i className="fa-solid fa-bolt text-primary icon_Dev"></i>
                                                        </div>
                                                        <div className="drive-text-dev">
                                                            <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                                Foster deeper connections through the automated booking</h5>
                                                            <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Dedicate higher-quality time to support students in advising, mentoring, career planning, and counseling.</p>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex">
                                                        <div className="icon-dollar">
                                                            <i className="fa-solid fa-calendar-days text-primary icon_Dev"></i>
                                                        </div>
                                                        <div className="drive-text-dev">
                                                            <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                                Enhance communication and streamline coordination</h5>
                                                            <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Increase accessibility of your schedule to students and prospects, empowering them to book time when they need assistance the most.</p>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex">
                                                        <div className="icon-dollar">
                                                            <i className="fa-solid fa-person-chalkboard text-primary icon_Dev"></i>
                                                        </div>
                                                        <div className="drive-text-dev">
                                                            <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                                Deliver an enriched educational experience</h5>
                                                            <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Focus on what truly matters: supporting and engaging with your students, empowering them to thrive and succeed.</p>
                                                        </div>
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <img className="w-100" src={educationPng} alt='education' />
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Healthcare</Accordion.Header>
                            <Accordion.Body>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                                            <div className="mt-4 ">
                                                <ol>
                                                    <li className="d-flex">
                                                        <div className="icon-dollar">
                                                            <i className="fa-solid fa-calendar-check text-primary icon_Dev"></i>
                                                        </div>
                                                        <div className="drive-text-dev">
                                                            <h5 className="align-items-center tab-textDev"
                                                                style={{ marginLeft: "5px" }}>Intelligent Appointment Booking</h5>
                                                            <p className="tab-paraDev" style={{ marginLeft: "5px" }}>ScheduleAI utilizes cutting-edge algorithms to analyze patient data, availability, and preferences, suggesting the most suitable appointment slots. Eliminate long wait times and scheduling conflicts.
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex">
                                                        <div className="icon-dollar">
                                                            <i className="fa-solid fa-notes-medical text-primary icon_Dev"></i>
                                                        </div>
                                                        <div className="drive-text-dev">
                                                            <h5 className="align-items-center tab-textDev"
                                                                style={{ marginLeft: "5px" }}>
                                                                Tailored Workflows</h5>
                                                            <p className="tab-paraDev" style={{ marginLeft: "5px" }}>ScheduleAI adapts to the unique needs of your healthcare facility. Whether you run a hospital, clinic, or specialized practice, you can customize workflows and scheduling rules to meet your specific requirements.</p>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex">
                                                        <div className="icon-dollar">
                                                            <i className="fa-sharp fa-solid fa-shield-halved text-primary icon_Dev"></i>
                                                        </div>
                                                        <div className="drive-text-dev">
                                                            <h5 className="align-items-center tab-textDev"
                                                                style={{ marginLeft: "5px" }}>Compliance and Data Security
                                                            </h5>
                                                            <p className="tab-paraDev" style={{ marginLeft: "5px" }}>We prioritize patient privacy and data security. ScheduleAI adheres to the highest industry standards, ensuring that patient information remains protected at all times.
                                                            </p>
                                                        </div>
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <img className="homeTabImgDev" title="Schedule Ai for Education" src={HealthcareScheduleaiPng} alt='Healthcare scheduleai' />
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <section>
                        <div className="container">
                            <div class="column">
                                <h2 className="text-center smd_text mt-4 mb-4 fw-bold">A booking robotization stage <br />worked for
                                    big business</h2>
                            </div>
                            <div className="row webDevRow">
                                <div className="col-12 col-lg-6 col-md-6 d-flex justify-content-center align-items-center">
                                    <div className="mt-4 mx-4">
                                        <h2 className="fw-bold smd_text">Guarantee security <br />and consistence
                                        </h2>
                                        <p className="lead_smd text-black" style={{ fontSize: "1em" }}>Engage IT divisions with SOC 2 Sort II
                                            accreditation, as well as secure Scheduleai use with SAML SSO, locally available and offboard
                                            clients with SCIM provisioning, and guarantee GDPR consistence.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-md-6">
                                    <img class="mainDevImg" title="Guarantee security" src={GuaranteeSecurityPng} alt="Security and Consistence" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="container mt-5">
                        <div className="row webDevRow">
                            <div class="col-12 col-lg-6 col-md-6">
                                <img className="mainDevImg" title="Keep your information yours" src={KeepDataSafePng} alt="Keep your information Safe" />
                            </div>
                            <div className="col-12 col-lg-6 col-md-6 d-flex justify-content-center align-items-center">
                                <div className="mt-4 px-4">
                                    <h2 className="fw-bold">Keep your information yours
                                    </h2>
                                    <p className="lead_smd text-black" style={{ fontSize: "1em" }}>Accomplish information security with a stage
                                        that never stores passwords, content, or PII related with your groups' records.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <section className='bg-dark'>
                        <div className="container mt-5 px-5 py-5">
                            <h2 className="text-center text-white fw-bold mt-4 mb-4">Increment transformations <br />by planning
                                collectively</h2>

                            <div className="row pt-4 incrementDevRow">
                                <div className="col-12 col-lg-6 col-md-4" data-aos="zoom-in"
                                    data-aos-duration="800"
                                    data-aos-delay="100">
                                    <div className="incrementDev h-100">
                                        <IntegrationInstructionsIcon />
                                        <h5 className="text-center my-2 salesContent-Dev" style={{ color: "#fff" }}>100+ Integrations booking</h5>
                                        <p className="text-center sales-Para-Dev">Ischeduleai offers a wide range of integrations, ensuring a smooth workflow where your team can excel.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-md-4" data-aos="zoom-in"
                                    data-aos-duration="1000"
                                    data-aos-delay="300">
                                    <div className="incrementDev h-100">
                                        <ElectricalServicesIcon />
                                        <h5 className="text-center my-2 salesContent-Dev" style={{ color: "#fff" }}>Enhance Company Performance</h5>
                                        <p className="text-center sales-Para-Dev">Streamline lead conversion and improve customer experience by empowering your teams with time-saving automations.</p>
                                    </div>
                                </div>

                            </div>
                            <div className="row my-5">
                                <div className="col-12 col-lg-4" data-aos="zoom-in"
                                    data-aos-duration="1000"
                                    data-aos-delay="500">
                                    <div className="incrementDev h-100">
                                        <TerminalIcon />
                                        <h5 className="text-center my-2 salesContent-Dev" style={{ color: "#fff" }}>Discover scheduleai Routing</h5>
                                        <p className="text-center sales-Para-Dev">Explore scheduleai Routing to simplify qualified lead interactions and enhance the candidate-facing experience.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4" data-aos="zoom-in"
                                    data-aos-duration="1000"
                                    data-aos-delay="300">
                                    <div className="incrementDev h-100">
                                        <GroupIcon />
                                        <h5 className="text-center my-2 salesContent-Dev" style={{ color: "#fff" }}>Reduce Total Cost of Ownership<br />
                                            groups</h5>
                                        <p className="text-center sales-Para-Dev">Maximize your software investments by consolidating your scheduling needs into a single, comprehensive platform.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4" data-aos="zoom-in"
                                    data-aos-duration="1000"
                                    data-aos-delay="300">
                                    <div className="incrementDev h-100">
                                        <ElectricalServicesIcon />
                                        <h5 className="text-center my-2 salesContent-Dev" style={{ color: "#fff" }}>Learn about Team Scheduling</h5>
                                        <p className="text-center sales-Para-Dev">Find out how scheduleai caters to meeting-heavy teams, including recruiting, sales, and more, to enhance efficiency and effectiveness.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section> */}

                    <div className="container">
                        <div className="row webDevRow pt-4">
                            <h2 className="text-center smd_text">Embrace ScheduleAI as an adjunct to your workforce
                            </h2>
                            <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                <div className="mt-4 ">
                                    <ol>
                                        <li className="d-flex">
                                            <div className="icon-dollar">
                                                <i className="fa-solid fa-check text-primary"></i>
                                            </div>
                                            <div className="drive-text-dev">
                                                <p className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>Thoroughly examine legal and security aspects</p>
                                            </div>
                                        </li>
                                        <li className="d-flex">
                                            <div className="icon-dollar">
                                                <i className="fa-solid fa-check text-primary"></i>
                                            </div>
                                            <div className="drive-text-dev">
                                                <p className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                    Our team guarantees alignment with your company's needs</p>
                                            </div>
                                        </li>
                                        <li className="d-flex">
                                            <div className="icon-dollar">
                                                <i className="fa-solid fa-check text-primary"></i>
                                            </div>
                                            <div className="drive-text-dev">
                                                <p className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>Prepare your account for expansive operations</p>
                                            </div>
                                        </li>
                                        <li className="d-flex">
                                            <div className="icon-dollar">
                                                <i className="fa-solid fa-check text-primary"></i>
                                            </div>
                                            <div className="drive-text-dev">
                                                <p className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>Proficient implementers tailor onboarding and provide setup assistance</p>
                                            </div>
                                        </li>
                                        <li className="d-flex">
                                            <div className="icon-dollar">
                                                <i className="fa-solid fa-check text-primary"></i>
                                            </div>
                                            <div className="drive-text-dev">
                                                <p className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>Formulate and attain your milestones</p>
                                            </div>
                                        </li>
                                        <li className="d-flex">
                                            <div className="icon-dollar">
                                                <i className="fa-solid fa-check text-primary"></i>
                                            </div>
                                            <div className="drive-text-dev">
                                                <p className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>Attain your objectives with the aid of successful collaborators</p>
                                            </div>
                                        </li>
                                        <li className="d-flex">
                                            <div className="icon-dollar">
                                                <i className="fa-solid fa-check text-primary"></i>
                                            </div>
                                            <div className="drive-text-dev">
                                                <p className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>Instant assistance available from product experts around the clock</p>
                                            </div>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <img className="w-100" title="Customer Success team" src={PartnerCustomerScucessPng} alt="partner-customer-scucess" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollTopButton />
            <Footer />
        </div>
    )
}

export default Enterprise
