import { Backspace, Call, Cancel, SimCard } from '@mui/icons-material';
import React, { useRef } from 'react'
import ContactsLayout from './ContactsLayout';
import axios from 'axios';

function DialerLayout({ setPhone, serverURL }) {

  const callNow = async () => {
    const phone = phoneField.current.value;

    const damn = await axios.post(serverURL + "/users/phoneCallBound", { phone: phone });

    if (damn.status === 200) {
      console.log(damn.data);
    } else {
      console.log(damn.data);
    }
  }

  const phoneField = useRef();
  return (
    <div className='d-flex flex-column'>
      <div className='flex-fill border-bottom bg-light' style={{ height: "150px", overflow: "auto" }}>
        <ContactsLayout />
      </div>
      <div className='d-flex flex-column justify-content-center align-items-center' style={{ background: "rgba(0,0,0,0.5)" }}>
        <div className='w-100 p-3 d-flex'>
          <input ref={phoneField} maxLength={14} className='form-control w-100 border-0 m-0 text-light text-center' style={{ background: "none", fontSize: "1.3rem" }} type="tel" />
          <button className='btn' style={{ color: "rgba(255,255,255,0.5)" }} onClick={() => { phoneField.current.value = phoneField.current.value.slice(0, -1) }}>
            <Backspace />
          </button>
        </div>
        <div className='d-flex align-items-center align-content-start justify-content-center flex-wrap gap-3'>
          <div className='btn border text-light w-25' onClick={() => { if (phoneField.current.value.length < 13) { phoneField.current.value = phoneField.current.value + "1"; setPhone(phoneField.current.value + "1") } }} style={{ background: "rgba(255,255,255,0.3)" }}>1</div>
          <div className='btn border text-light w-25' onClick={() => { if (phoneField.current.value.length < 13) { phoneField.current.value = phoneField.current.value + "2"; setPhone(phoneField.current.value + "2") } }} style={{ background: "rgba(255,255,255,0.3)" }}>2</div>
          <div className='btn border text-light w-25' onClick={() => { if (phoneField.current.value.length < 13) { phoneField.current.value = phoneField.current.value + "3"; setPhone(phoneField.current.value + "3") } }} style={{ background: "rgba(255,255,255,0.3)" }}>3</div>
          <div className='btn border text-light w-25' onClick={() => { if (phoneField.current.value.length < 13) { phoneField.current.value = phoneField.current.value + "4"; setPhone(phoneField.current.value + "4") } }} style={{ background: "rgba(255,255,255,0.3)" }}>4</div>
          <div className='btn border text-light w-25' onClick={() => { if (phoneField.current.value.length < 13) { phoneField.current.value = phoneField.current.value + "5"; setPhone(phoneField.current.value + "5") } }} style={{ background: "rgba(255,255,255,0.3)" }}>5</div>
          <div className='btn border text-light w-25' onClick={() => { if (phoneField.current.value.length < 13) { phoneField.current.value = phoneField.current.value + "6"; setPhone(phoneField.current.value + "6") } }} style={{ background: "rgba(255,255,255,0.3)" }}>6</div>
          <div className='btn border text-light w-25' onClick={() => { if (phoneField.current.value.length < 13) { phoneField.current.value = phoneField.current.value + "7"; setPhone(phoneField.current.value + "7") } }} style={{ background: "rgba(255,255,255,0.3)" }}>7</div>
          <div className='btn border text-light w-25' onClick={() => { if (phoneField.current.value.length < 13) { phoneField.current.value = phoneField.current.value + "8"; setPhone(phoneField.current.value + "8") } }} style={{ background: "rgba(255,255,255,0.3)" }}>8</div>
          <div className='btn border text-light w-25' onClick={() => { if (phoneField.current.value.length < 13) { phoneField.current.value = phoneField.current.value + "9"; setPhone(phoneField.current.value + "9") } }} style={{ background: "rgba(255,255,255,0.3)" }}>9</div>
          <div className='btn border text-light w-25' onClick={() => { if (phoneField.current.value.length < 13) { phoneField.current.value = phoneField.current.value + "*"; setPhone(phoneField.current.value + "*") } }} style={{ background: "rgba(255,255,255,0.3)" }}>*</div>
          <div className='btn border text-light w-25' onClick={() => { if (phoneField.current.value.length < 13) { phoneField.current.value = phoneField.current.value + "0"; setPhone(phoneField.current.value + "0") } }} style={{ background: "rgba(255,255,255,0.3)" }}>0</div>
          <div className='btn border text-light w-25' onClick={() => { if (phoneField.current.value.length < 13) { phoneField.current.value = phoneField.current.value + "#"; setPhone(phoneField.current.value + "#") } }} style={{ background: "rgba(255,255,255,0.3)" }}>#</div>
        </div>
        <div className='w-100 d-flex justify-content-around p-3'>
          <button className='btn btn-sm btn-success rounded-pill d-flex align-items-center justify-content-center w-100' style={{ maxWidth: "80px", fontSize: "1rem" }} onClick={() => { callNow() }}>
            <Call />
            <SimCard />1
          </button>
          <button className='btn btn-sm btn-primary rounded-pill d-flex align-items-center justify-content-center w-100' style={{ maxWidth: "80px", fontSize: "1rem" }} onClick={() => { callNow() }}>
            <Call />
            <SimCard />2
          </button>
        </div>
      </div>
    </div>
  )
}

export default DialerLayout