import React from 'react';
import ScheduleAIForSemiconductorsBannerPng from "../../images/ScheduleAI for Semiconductors banner.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';

function Semiconductors() {
    return (
        <>
            <div className="heWhoRemains">
                <Header />
                <div>
                    <section>
                        <div className="container mt-5">
                            <div className="row">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">
                                        <h1 className=" display-4 fw-bold smd_text">Schedule AI for
                                            <span style={{ color: "#0B5ED7" }}> Semiconductors
                                            </span>
                                        </h1>
                                        <p className="lead_smd text-black" style={{ fontSize: "1.1em" }}>
                                            As the world progresses, technology plays an increasingly important role in our lives. In the semiconductor industry, one of the most important pieces of technology is appointment scheduling software. This software can have a profound impact on your business, and there are many benefits to using it.
                                        </p>
                                        <a href="/login"><button
                                            className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                                            Demo</button></a>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="w-100" src={ScheduleAIForSemiconductorsBannerPng} alt='ScheduleAI for Semiconductors banner' />
                                </div>
                            </div>
                        </div>
                    </section>


                    <section>
                        <div className="container mt-5">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold">Benefits of using Schedule AI for Semiconductors
                                </h2>
                            </div>
                            <div className="row">
                                <ul>
                                    <li className='liListDevind'>
                                        It can help you save time by keeping all your appointments in one place. You won't have to search through multiple calendars or email inboxes to find what you're looking for.
                                    </li>

                                    <li className='liListDevind'>
                                        With so many deadlines and moving parts, it's easy to lose track of what needs to be done and when. But with this type of software, everything will be right at your fingertips so you can stay on top of things.
                                    </li>

                                    <li className='liListDevind'>
                                        It allows you to easily schedule appointments with clients and customers. This can save a lot of time and hassle, as you won't have to constantly be emailing or calling back and forth to schedule meetings.
                                    </li>

                                    <li className='liListDevind'>
                                        ScheduleAI software can help you keep track of your inventory. This is crucial for the semiconductor industry, as keeping track of inventory levels is essential for production planning.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container textDevLay pb-4">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold">Conclusion
                                </h2>
                                <p className='lead_smd text-justify'>Overall, there are many benefits to using the appointment scheduling software for semiconductors. This software can save you time and money, and it can also help you provide better service to your customers. If you're not already using this type of software, now is the time to consider doing so.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default Semiconductors
