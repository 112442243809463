import { Call } from '@mui/icons-material'
import React from 'react'

function ContactsLayout({ setPhone, serverURL }) {
  return (
    <div className='flex-fill' style={{ background: "rgba(0,0,0,0.8)" }}>
      <div className='d-flex flex-column py-3 px-3'>
        <div className='d-flex gap-2 align-items-center justify-content-between'>
          <div className='d-flex align-items-center gap-2'>
            <div className='p-3 bg-light rounded-circle'></div>
            <p className='m-0 p-0 text-light'>97116541561</p>
          </div>
          <Call className='text-success' />
        </div>
      </div>

      <div className='d-flex flex-column py-3 px-3'>
        <div className='d-flex gap-2 align-items-center justify-content-between'>
          <div className='d-flex align-items-center gap-2'>
            <div className='p-3 bg-light rounded-circle'></div>
            <p className='m-0 p-0 text-light'>97116541561</p>
          </div>
          <Call className='text-success' />
        </div>
      </div>
      <div className='d-flex flex-column py-3 px-3'>
        <div className='d-flex gap-2 align-items-center justify-content-between'>
          <div className='d-flex align-items-center gap-2'>
            <div className='p-3 bg-light rounded-circle'></div>
            <p className='m-0 p-0 text-light'>97116541561</p>
          </div>
          <Call className='text-success' />
        </div>
      </div>
      <div className='d-flex flex-column py-3 px-3'>
        <div className='d-flex gap-2 align-items-center justify-content-between'>
          <div className='d-flex align-items-center gap-2'>
            <div className='p-3 bg-light rounded-circle'></div>
            <p className='m-0 p-0 text-light'>97116541561</p>
          </div>
          <Call className='text-success' />
        </div>
      </div>
      <div className='d-flex flex-column py-3 px-3'>
        <div className='d-flex gap-2 align-items-center justify-content-between'>
          <div className='d-flex align-items-center gap-2'>
            <div className='p-3 bg-light rounded-circle'></div>
            <p className='m-0 p-0 text-light'>97116541561</p>
          </div>
          <Call className='text-success' />
        </div>
      </div>
      <div className='d-flex flex-column py-3 px-3'>
        <div className='d-flex gap-2 align-items-center justify-content-between'>
          <div className='d-flex align-items-center gap-2'>
            <div className='p-3 bg-light rounded-circle'></div>
            <p className='m-0 p-0 text-light'>97116541561</p>
          </div>
          <Call className='text-success' />
        </div>
      </div>
    </div>
  )
}

export default ContactsLayout