import React , {useEffect} from 'react';
import EngineeringConstructionBannerPng from "../../images/Engineering Construction Banner.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';


const EngineeringConstructions = () => {

    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <>
            <div className="heWhoRemains">
                <Header />
                <div>
                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">
                                        <h1 className="smd_text">Schedule AI for 
                                            <span style={{ color: "#0B5ED7" }}> Engineering Constructions & Operations
                                            </span>
                                        </h1>
                                        <p className="lead_smd">
                                            Scheduling appointments can be a very time-consuming task, especially if you have a lot of them. With ScheduleAI software, you can simply enter all the relevant information and the software will do the rest. This can free up a lot of your time so that you can focus on other tasks.
                                        </p>
                                        <a href="/login"><button
                                            className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                                            Demo</button></a>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="mainDevImg" src={EngineeringConstructionBannerPng} alt='Engineering Construction Banner' />
                                </div>
                            </div>
                        </div>
                    </section>


                    <section>
                        <div className="container mt-5">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold text-center">Benefits of Schedule AI for <br />Engineering Constructions & Operations
                                </h2>
                            </div>

                            <ul>
                                <li className='liListDevind'>It can help to improve communication and coordination between different team members.
                                </li>

                                <li className='liListDevind'>By keeping track of all your appointments, you can get a better idea of how much time and money you are spending on each one.
                                </li>

                                <li className='liListDevind'>By using this type of software, you can save time and money by reducing the need for manual scheduling.
                                </li>

                                <li className='liListDevind'>ScheduleAI software can help to increase productivity and efficiency by allowing employees to see their schedules in one central location.
                                </li>

                                <li className='liListDevind'>
                                    This type of software can also help to improve communication between different departments within a company.
                                </li>
                            </ul>

                        </div>
                    </section>

                    <section>
                        <div className="container textDevLay pb-4">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold">Conclusion
                                </h2>
                                <p className='lead_smd text-justify'>Overall, there are many benefits of using ScheduleAI software for engineering construction & operations. If you are looking for ways to improve efficiency and communication within your team, or simply want to save yourself some time, then this type of software could be perfect for you.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default EngineeringConstructions
