import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import callimg from "../../images/callImg.png";
import zoomImg from "../../images/zoomImg.png";
import meetImg from "../../images/meetImg.png";
import addressImg from "../../images/addressImg.png";
import webexImg from "../../images/webexCompany.png";
import gotoImg from "../../images/gotoCompany.png";
import teamsImg from "../../images/teamsCompany.png";
import Cookies from 'js-cookie';

import forwardImg from "../../images/forward.png";

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import notFoundPng from "../../images/notFound.png";

import invalidPng from "../../images/invalidPng.gif";
import successPng from "../../images/successPng.gif";
import caneldarDark from "../../images/caneldarDark.png";
import queryPng from "../../images/help.png";

import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import requiredQuestionPng from "../../images/helpTexts/requiredQuestion.png";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import uniqueLinkHelpText from "../../images/helpTexts/unique-link.png"

import popupData from "../../audio/popup-alert.mp3";
import pageData from "../../audio/page-turn.wav";

// import Timelines from '../components/Timelines';
import TimezoneSelect, { allTimezones } from 'react-timezone-select';
import { getMonth } from '../../utils';
import Month from '../../components/Month';
import dayjs from "dayjs";
import { GoogleLogin } from 'react-google-login';
import PhoneInput from 'react-phone-number-input';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, DateRange, Calendar } from 'react-date-range';
import { addDays } from 'date-fns';
import axios from "axios";
// import Sidebar from '../../components/sidebar/Sidebar';
import { useRef } from 'react';
import { Accordion, Dropdown, DropdownButton, Modal, OverlayTrigger, Popover, Tab, Tabs, Toast } from 'react-bootstrap';

const ct = require('countries-and-timezones');
const timezones = ct.getAllTimezones();
const allCountries = ct.getAllCountries();


function Editevent() {
    let minutesGroup = useRef();
    let minutesOneOff = useRef();
    let minutesOneOnOne = useRef();

    let rollingDaysRef = useRef();

    const [pageLinks, setPageLinks] = useState([]);
    let customLinkName = useRef("");
    let customLinkData = useRef("");

    const serverUrl = "https://" + process.env.REACT_APP_DOMAINNAME + ":8443";
    //const serverUrl = "http://" + process.env.REACT_APP_DOMAINNAME + ":8443";

    const [loadingComplete, setLoadingComplete] = useState(false);

    const [user, setUser] = useState(null);
    const [myEvent, setMyEvent] = useState();
    const [refreshToken, setRefreshToken] = useState();
    const [id, setId] = useState()
    const [eventType, setEventType] = useState()
    const [eventName, setEventName] = useState()
    const [oneOffMeetingName, setOneOffMeetingName] = useState()
    const [location, setLocation] = useState()
    const [oneOffLocation, setOneOffLocation] = useState()
    const [meetingType, setMeetingType] = useState()
    const [oneOffMeetingType, setOneOffMeetingType] = useState()
    const [inboundCallOneOff, setInboundCallOneOff] = useState()
    const [description, setDescription] = useState()
    const [oneOffDescription, setOneOffDescription] = useState()
    const [eventLink, setEventLink] = useState()
    const [maxInvitees, setMaxInvitees] = useState()
    const [displaySpotes, setDisplaySpots] = useState()
    const [attendees, setAttendees] = useState([])
    const [eventColor, setEventColor] = useState()
    const [duration, setDuration] = useState()
    const [oneOffDuration, setOneOffDuration] = useState()
    const [calendarIds, setCalendarIds] = useState([])
    const [availability, setAvailability] = useState({})
    const [beforeEventTime, setBeforeEventTime] = useState()
    const [afterEventTime, setAfterEventTime] = useState()
    const [stopSchedule, setStopSchedule] = useState()
    const [secretEvent, setSecretEvent] = useState()
    const [locationSelection, setLocationSelection] = useState(false)
    const [oneOffLocationSelection, setOneOffLocationSelection] = useState(false)
    const [selectedTimezone, setSelectedTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

    const [rerenderRequired, setRerenderRequired] = useState(true);

    const [newScheduleName, setNewScheduleName] = useState("");

    const [updateButtonEnabled, setUpdateButtonEnabled] = useState(true);
    const [overrideButtonEnabled, setOverrideButtonEnabled] = useState(true);

    const [currntOverrideDateSelected, setCurrntOverrideDateSelected] = useState(null);



    const [customHourView, setCustomHourView] = useState(false);
    const [durationType, setDurationType] = useState("minutes");


    const [customAlertBox, setCustomAlertBox] = useState(false);
    const [alertBoxMessages, setAlertBoxMessages] = useState("");
    const [alertErrorType, setAlertErrorType] = useState("invalid");


    const popupAlert = new Audio(popupData);
    const pageTurnSound = new Audio(pageData);

    const [viewToast, setViewToast] = useState(false);
    const [toastTitle, setToastTitle] = useState(null);
    const [toastBody, setToastBody] = useState(null);
    const [toastMessageType, setToastMessageType] = useState(null);

    const [whatEvent, setWhatEvent] = useState(false);
    const [whenEvent, setWhenEvent] = useState(false);


    const [countriesList, setCountriesList] = useState([]);
    const [timezonesList, setTimezonesList] = useState([]);
    const [filteredTimezonesList, setFilteredTimezonesList] = useState([]);
    const [filteredTimezonesListSchedule, setFilteredTimezonesListSchedule] = useState([]);
    const [countrySchedule, setCountrySchedule] = useState([]);


    const [showDateRangeSelector, setShowDateRangeSelector] = useState(false);
    const [straightRollingDays, setStraightRollingDays] = useState(true);
    const [withinDateRange, setWithinDateRange] = useState(false);
    const [infiniteDateRange, setInfiniteDateRange] = useState(false);



    const [viewEventTypes, setViewEventTypes] = useState(true);
    const [scheduledEvents, setScheduledEvents] = useState(false);
    const [workflows, setWorkflows] = useState(false);
    const [showEventCreater, setShowEventCreater] = useState(false);
    const [eventTypeSector, setEventTypeSector] = useState(false);
    const [oneOffMeetingSelector, setOneOffMeetingSelector] = useState(false);
    const [oneOffPollSelector, setOneOffPollSelector] = useState(false);

    const [eventCreationSteps, setEventCreationSteps] = useState(false);
    // const [viewRangeSelector, setViewRangeSelector] = useState(false);
    const [existingScheduleView, setExistingScheduleView] = useState(true);
    const [customScheduleView, setCustomScheduleView] = useState(false);
    const [selectedTimezoneUTC, setSelectedTimezoneUTC] = useState(dayjs().format("Z"));

    const timeListToMeasureData = ["12:00am", "12:15am", "12:30am", "12:45am", "1:00am", "1:15am", "1:30am", "1:45am", "2:00am", "2:15am", "2:30am", "2:45am", "3:00am", "3:15am", "3:30am", "3:45am", "4:00am", "4:15am", "4:30am", "4:45am", "5:00am", "5:15am", "5:30am", "5:45am", "6:00am", "6:15am", "6:30am", "6:45am", "7:00am", "7:15am", "7:30am", "7:45am", "8:00am", "8:15am", "8:30am", "8:45am", "9:00am", "9:15am", "9:30am", "9:45am", "10:00am", "10:15am", "10:30am", "10:45am", "11:00am", "11:15am", "11:30am", "11:45am", "12:00pm", "12:15pm", "12:30pm", "12:45pm", "1:00pm", "1:15pm", "1:30pm", "1:45pm", "2:00pm", "2:15pm", "2:30pm", "2:45pm", "3:00pm", "3:15pm", "3:30pm", "3:45pm", "4:00pm", "4:15pm", "4:30pm", "4:45pm", "5:00pm", "5:15pm", "5:30pm", "5:45pm", "6:00pm", "6:15pm", "6:30pm", "6:45pm", "7:00pm", "7:15pm", "7:30pm", "7:45pm", "8:00pm", "8:15pm", "8:30pm", "8:45pm", "9:00pm", "9:15pm", "9:30pm", "9:45pm", "10:00pm", "10:15pm", "10:30pm", "10:45pm", "11:00pm", "11:15pm", "11:30pm", "11:45pm"];
    const timeListToMeasureData24 = ["00:00", "00:15", "00:30", "00:45", "01:00", "01:15", "01:30", "01:45", "02:00", "02:15", "02:30", "02:45", "03:00", "03:15", "03:30", "03:45", "04:00", "04:15", "04:30", "04:45", "05:00", "05:15", "05:30", "05:45", "06:00", "06:15", "06:30", "06:45", "07:00", "07:15", "07:30", "07:45", "08:00", "08:15", "08:30", "08:45", "09:00", "09:15", "09:30", "09:45", "10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45", "14:00", "14:15", "14:30", "14:45", "15:00", "15:15", "15:30", "15:45", "16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30", "17:45", "18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45", "20:00", "20:15", "20:30", "20:45", "21:00", "21:15", "21:30", "21:45", "22:00", "22:15", "22:30", "22:45", "23:00", "23:15", "23:30", "23:45"];

    const [timeListToMeasure, setTimeListToMeasure] = useState(timeListToMeasureData);



    const [monthIndexCurrent, setMonthIndexCurrent] = useState(dayjs().month());
    const [currentMonth, setCurrentMonth] = useState(getMonth(monthIndexCurrent));
    const [nextMonth, setNextMonth] = useState(getMonth(monthIndexCurrent + 1));

    // const [attendees, setAttendees] = useState('')
    const [colorId, setColorId] = useState('1')
    // const [startDateTime, setStartDateTime] = useState('')
    // const [endDateTime, setEndDateTime] = useState('')
    const [signedIn, setSignedIn] = useState(false)




    const [needToken, setNeedToken] = useState(false);
    const [needZoomToken, setNeedZoomToken] = useState(false);
    const [needGTMToken, setNeedGTMToken] = useState(false);
    const [needTeamsToken, setNeedTeamsToken] = useState(false);
    const [needWebexToken, setNeedWebexToken] = useState(false);




    const [myRefreshToken, setMyRefreshToken] = useState('');

    // const [intoFutureRadio, setIntoFutureRadio] = useState(false);
    // const [daysBetweenRadio, setDaysBetweenRadio] = useState(true);
    // const [indefinitelyRadio, setIndefinitelyRadio] = useState(true);

    // const [changableDate, setChangableDate] = useState(30);
    const [selectedFirst, setSelectedFirst] = useState(false);
    const [selectedSecond, setSelectedSecond] = useState(false);
    const [betweenDays, setBetweenDays] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 30),
            key: 'selection'
        }
    ]);

    const [eventBeforeTimeActive, setEventBeforeTimeActive] = useState(false);
    const [eventAfterTimeActive, setEventAfterTimeActive] = useState(false);

    const [eventBeforeTime, setEventBeforeTime] = useState(null);
    const [eventAfterTime, setEventAfterTime] = useState(null);

    const [oneOffDate, setOneOffDate] = useState("");
    const [oneToOneDate, setOneToOneDate] = useState("");
    const [roundRobinDate, setRoundRobinDate] = useState("");
    const [inboundCall, setInboundCall] = useState(false);

    const [oneOffDates, setOneOffDates] = useState({});
    const [roundRobinDates, setRoundRobinDates] = useState({});
    const [oneToOneDates, setOneToOneDates] = useState({});

    const [allEvents, setAllEvents] = useState([]);

    const [myCustomLink, setMyCustomLink] = useState("");

    const [groupEventCreationSteps, setGroupEventCreationSteps] = useState(false)

    const [groupSummary, setGroupSummary] = useState("")

    const [groupLocationSelection, setGroupLocationSelection] = useState(false)

    const [groupMeetingType, setGroupMeetingType] = useState("address")

    const [groupLocation, setGroupLocation] = useState("")

    const [groupDescription, setGroupDescription] = useState("")

    const [groupInboundCall, setGroupInboundCall] = useState(false)

    const [groupColorId, setGroupColorId] = useState('1')


    const [groupDuration, setGroupDuration] = useState()

    const [groupDate, setGroupDate] = useState()

    const [eventGroupBeforeTimeActive, setEventGroupBeforeTimeActive] = useState(false)

    const [eventGroupAfterTimeActive, setEventGroupAfterTimeActive] = useState(false)

    const [eventGroupBeforeTime, setEventGroupBeforeTime] = useState()

    const [eventGroupAfterTime, setEventGroupAfterTime] = useState()

    const [groupSecretEvent, setGroupSecretEvent] = useState(false)

    const [groupDates, setGroupDates] = useState({})

    const [maxInvitiesActive, setMaxInvitiesActive] = useState(false)

    const [roundRobinCreationSteps, setRoundRobinCreationSteps] = useState(false);


    const [customizedEvent, setCustomizedEvent] = useState(false);
    const [redirectView, setRedirectView] = useState(false);
    const [anotherBookingButton, setAnotherBookingButton] = useState(false);
    const [addCustomLinkView, setAddCustomLinkView] = useState(false);


    const [toggleSwitchOn, setToggleSwitchOn] = useState(false);


    const [showShareYourEventPanel, setShowShareYourEventPanel] = useState(false);
    const [sharingViewOf, setSharingViewOf] = useState("LinkCopy");




    // new useStates after schedule changes


    const [cancelContents, setCancelContents] = useState("");

    const [isSundayActive, setIsSundayActive] = useState(false);
    const [isMondayActive, setIsMondayActive] = useState(false);
    const [isTuesdayActive, setIsTuesdayActive] = useState(false);
    const [isWednesdayActive, setIsWednesdayActive] = useState(false);
    const [isThursdayActive, setIsThursdayActive] = useState(false);
    const [isFridayActive, setIsFridayActive] = useState(false);
    const [isSaturdayActive, setIsSaturdayActive] = useState(false);
    const [userSchedules, setUserSchedules] = useState(null);
    const [scheduleName, setScheduleName] = useState(false);


    const [viewFromSunday, setViewFromSunday] = useState([false, null]);
    const [viewFromMonday, setViewFromMonday] = useState([false, null]);
    const [viewFromTuesday, setViewFromTuesday] = useState([false, null]);
    const [viewFromWednesday, setViewFromWednesday] = useState([false, null]);
    const [viewFromThursday, setViewFromThursday] = useState([false, null]);
    const [viewFromFriday, setViewFromFriday] = useState([false, null]);
    const [viewFromSaturday, setViewFromSaturday] = useState([false, null]);

    const [sundayCopy, setSundayCopy] = useState(false);
    const [mondayCopy, setMondayCopy] = useState(false);
    const [tuesdayCopy, setTuesdayCopy] = useState(false);
    const [wednesdayCopy, setWednesdayCopy] = useState(false);
    const [thursdayCopy, setThursdayCopy] = useState(false);
    const [fridayCopy, setFridayCopy] = useState(false);
    const [saturdayCopy, setSaturdayCopy] = useState(false);

    const [schedule, setSchedule] = useState({
        "sunday": {
            "active": true,
            "times": ["9:00am-11:00am"]
        },
        "monday": {
            "active": true,
            "times": ["9:00am-11:00am"]
        },
        "tuesday": {
            "active": true,
            "times": ["9:00am-11:00am"]
        },
        "wednesday": {
            "active": true,
            "times": ["9:00am-11:00am"]
        },
        "thursday": {
            "active": false,
            "times": ["9:00am-11:00am"]
        },
        "friday": {
            "active": true,
            "times": ["9:00am-11:00am"]
        },
        "saturday": {
            "active": false,
            "times": ["9:00am-11:00am"]
        },
    });

    const [phoneList, setPhoneList] = useState(null);
    const [optionListForPhone, setOptionListForPhone] = useState(null);
    const [userScheduleListForSelect, setUserScheduleListForSelect] = useState(null);
    const [meetingDurationListForSelect, setMeetingDurationListForSelect] = useState([
        { label: "15 minutes", value: 15 },
        { label: "30 minutes", value: 30 },
        { label: "45 minutes", value: 45 },
        { label: "60 minutes", value: 60 },
        { label: "custom", value: "custom" }
    ]);
    const [addTimeAfterBeforeForSelect, setAddTimeAfterBeforeForSelect] = useState([
        { label: "15 minutes", value: 15 },
        { label: "30 minutes", value: 30 },
        { label: "45 minutes", value: 45 },
        { label: "1 hr", value: 60 },
        { label: "1 hr 30 min", value: 90 },
        { label: "2 hr", value: 120 },
        { label: "2 hr 30 min", value: 150 },
        { label: "3 hr", value: 180 },
    ]);
    const [stopBookingBeforeListForSelect, setStopBookingBeforeListForSelect] = useState([
        { label: "1 Hour", value: 1 },
        { label: "2 Hour", value: 2 },
        { label: "3 Hour", value: 3 },
        { label: "4 Hour", value: 4 },
        { label: "custom", value: "custom" }
    ]);

    const [selectedDurationLabel, setSelectedDurationLabel] = useState(null);
    const [selectedEventBeforeTimeFromList, setSelectedEventBeforeTimeFromList] = useState(null);
    const [selectedEventAfterTimeFromList, setSelectedEventAfterTimeFromList] = useState(null);
    const [selectedStopBookingBeforeLabel, setSelectedStopBookingBeforeLabel] = useState(null);
    const [selectedPhoneNumberLabel, setSelectedPhoneNumberLabel] = useState(null);


    const [defaultSchedule, setDefaultSchedule] = useState({});
    const [defaultOverride, setDefaultOverride] = useState({});


    const [createNewSchedule, setCreateNewSchedule] = useState(false);

    const [scheduleNameCounter, setScheduleNameCounter] = useState(false);
    const [overrideDateEnabled, setOverrideDateEnabled] = useState(false);
    const [overrideStartTime, setOverrideStartTime] = useState("9:00am");
    const [overrideEndTime, setOverrideEndTime] = useState("5:00pm");


    const [viewDateOverridePanel, setViewDateOverridePanel] = useState(false);
    const [overrideDate, setOverrideDate] = useState(null);
    const [dateOverrides, setDateOverrides] = useState({});

    const [overrideArray, setOverrideArray] = useState(["9:00am-5:00pm"]);

    const [existingOverride, setExistingOverride] = useState(false);


    const [activeWorkflow, setActiveWorkflow] = useState(null);
    const [showCustomWorkflowStep1, setShowCustomWorkflowStep1] = useState(false);
    const [showCustomWorkflowStep2, setShowCustomWorkflowStep2] = useState(false);
    const [workflowDuration, setWorkflowDuration] = useState(15);


    const [isDescriptionRequired, setIsDescriptionRequired] = useState(true);


    const [stopBookingTime, setStopBookingTime] = useState(["hours", 1]);
    const [customStopBookingView, setCustomStopBookingView] = useState(null);
    const [stopTimeForBookingType, setStopTimeForBookingType] = useState("hours");

    const [roundRobinQuestions, setRoundRobinQuestions] = useState("Share anything that can help us to prepare the meeting.");
    const [oneOnOneQuestions, setOneOnOneQuestions] = useState("Share anything that can help us to prepare the meeting.");
    const [groupQuestions, setGroupQuestions] = useState("Share anything that can help us to prepare the meeting.");


    const [customEventLink, setCustomEventLink] = useState("");

    const [isCustomLinkAvailable, setIsCustomLinkAvailable] = useState(true);




    const [uRLNotFound, setURLNotFound] = useState();

    const [scheduleIdentifier, setScheduleIdentifier] = useState(null);



    const checkThisTab = (data) => {
        // console.log(data)
        const tabs = document.querySelectorAll(".event-color-tab");
        for (let i = 0; i < tabs.length; i++) {
            tabs[i].classList.remove("checked-color");
        }
        data.classList.add("checked-color");
    }

    useEffect(() => {
        async function getCookies() {
            let gettingUserByCookie = null;

            if (process.env.REACT_APP_DEPLOY_MODE === "production") {
                let b = Cookies.get('jwtToken')
                const get = await axios.get(serverUrl + "/getCookieUser", {
                    headers: {
                        "Authorization": `Bearer ${b}`
                    }
                });
                if (get.status === 200) {
                    if (get.data !== undefined) {
                        gettingUserByCookie = await axios.post(serverUrl + "/users/getLoginUserNow", { "email": get.data.email });

                    } else {
                        window.location.href = process.env.REACT_APP_DOMAINNAME1 + "/login";
                        // console.log("undefinned cookies")
                    }
                } else {
                    window.location.href = process.env.REACT_APP_DOMAINNAME1 + "/login";
                    // console.log("undefined get")
                }
            }
            else {
                gettingUserByCookie = await axios.post(serverUrl + "/users/getLoginUserNow", { "email": "gulfan@kcloudtechnologies.com" });
            }





            if (gettingUserByCookie.status === 200) {
                setUser(gettingUserByCookie.data);

                setTimeListToMeasure(gettingUserByCookie.data.settings.timeFormat.value !== "12 Hours" ? timeListToMeasureData24 : timeListToMeasureData)

                // ########################################## CHECK GOOGLE TOKEN ####################################################
                const checkTokenAvailable = await axios.post(serverUrl + "/events/checkToken", { "email": gettingUserByCookie.data.email });

                if (checkTokenAvailable.status === 200) {
                    // console.log(checkTokenAvailable.data);
                    setMyRefreshToken(checkTokenAvailable.data);
                }
                else {
                    // console.log(checkTokenAvailable.data);
                    setNeedToken(true);
                }

                // ########################################## CHECK ZOOM TOKEN ####################################################
                const checkZoomTokenAvailable = await axios.post(serverUrl + "/events/checkZoomToken", { "email": gettingUserByCookie.data.email })
                if (checkZoomTokenAvailable.status === 200) {
                    // console.log(checkTokenAvailable.data);
                    // setMyRefreshToken(checkTokenAvailable.data);
                }
                else {
                    // console.log(checkZoomTokenAvailable.data);
                    setNeedZoomToken(true);
                }



                // ########################################## CHECK GOTO MEETING TOKEN ####################################################
                const checkGotoTokenAvailable = await axios.post(serverUrl + "/events/checkGotoMeetingToken", { "email": gettingUserByCookie.data.email })
                if (checkGotoTokenAvailable.status === 200) {
                    // console.log(checkGotoTokenAvailable.data);
                    // setMyRefreshToken(checkGotoTokenAvailable.data);
                }
                else {
                    // console.log(checkGotoTokenAvailable.data)
                    setNeedGTMToken(true);
                }


                // ########################################## CHECK WEBEX TOKEN ####################################################
                const checkWebexTokenAvailable = await axios.post(serverUrl + "/events/checkWebexToken", { "email": gettingUserByCookie.data.email })
                if (checkWebexTokenAvailable.status === 200) {
                    // console.log(checkTokenAvailable.data);
                    // setMyRefreshToken(checkWebexTokenAvailable.data);
                }
                else {
                    // console.log(checkWebexTokenAvailable.data);
                    setNeedWebexToken(true);
                }


                // ########################################## CHECK TEAMS MEETING TOKEN ####################################################
                const checkTeamsTokenAvailable = await axios.post(serverUrl + "/events/checkTeamsToken", { "email": gettingUserByCookie.data.email })
                if (checkTeamsTokenAvailable.status === 200) {
                    // console.log(checkTeamsTokenAvailable.data);
                    // setMyRefreshToken(checkTeamsTokenAvailable.data);
                }
                else {
                    // console.log(checkTeamsTokenAvailable.data);
                    setNeedTeamsToken(true);
                }

            } else {
                // window.location.href = process.env.REACT_APP_DOMAINNAME1+"/login";
                // console.log("getting user error");
            }
        }
        getCookies();
    }, []);


    useEffect(() => {
        if (user) {
            async function getEventDetails() {
                const id = window.location.href.split("/")[window.location.href.split("/").length - 1];
                // console.log(id);
                // console.log(username);
                const query = {
                    "id": id,
                    "email": user.email
                };
                const getEventInfo = await axios.post(serverUrl + "/events/editevent", query);

                if (getEventInfo.status === 200) {
                    setMyEvent(getEventInfo.data[0]);
                    // console.log(getEventInfo.data[0]);
                    setURLNotFound(false);
                }
                else {
                    // console.log(getEventInfo.data);
                    setURLNotFound(true);
                    setLoadingComplete(true);
                }



                const getMyCustomeLink = await axios.post(serverUrl + "/mainIds/get", { "email": user.email });
                if (getMyCustomeLink.status === 200) {
                    // console.log(getMyCustomeLink.data);
                    setMyCustomLink(getMyCustomeLink.data);
                }
                else {
                    // console.log(getMyCustomeLink.data)
                }
            }

            getEventDetails();

            setPhoneList(user.phoneNumbers);

            if (user.phoneNumbers) {
                let list = [];

                Object.keys(user.phoneNumbers).map((name, key) => {
                    if (user.phoneNumbers[name].active) {
                        return list.push({ label: name, value: name })
                    }
                })
                setOptionListForPhone(list);
            }


            setUserSchedules(user.userSchedules);

            let listSchedules = [];
            Object.keys(user.userSchedules).map((name, key) => {
                return listSchedules.push({ label: name, value: name })
            })
            setUserScheduleListForSelect(listSchedules);

            // Object.keys(user.userSchedules).map((name, key) => {
            //     if (user.userSchedules[name].inUse === true) {
            //         setSelectedTimezone(user.userSchedules[name].timezone);

            //         setSchedule(user.userSchedules[name].days);

            //         setDateOverrides(user.userSchedules[name].dateOverrides);

            //         setIsSundayActive(user.userSchedules[name].days.sunday.active);
            //         setIsMondayActive(user.userSchedules[name].days.monday.active);
            //         setIsTuesdayActive(user.userSchedules[name].days.tuesday.active);
            //         setIsWednesdayActive(user.userSchedules[name].days.wednesday.active);
            //         setIsThursdayActive(user.userSchedules[name].days.thursday.active);
            //         setIsFridayActive(user.userSchedules[name].days.friday.active);
            //         setIsSaturdayActive(user.userSchedules[name].days.saturday.active);

            //         // setSundayStartTime(user.userSchedules[name].days.sunday.times.split("-")[0]);
            //         // setSundayEndTime(user.userSchedules[name].days.sunday.times.split("-")[1]);
            //         // setMondayStartTime(user.userSchedules[name].days.monday.times.split("-")[0]);
            //         // setMondayEndTime(user.userSchedules[name].days.monday.times.split("-")[1]);
            //         // setTuesdayStartTime(user.userSchedules[name].days.tuesday.times.split("-")[0]);
            //         // setTuesdayEndTime(user.userSchedules[name].days.tuesday.times.split("-")[1]);
            //         // setWednesdayStartTime(user.userSchedules[name].days.wednesday.times.split("-")[0]);
            //         // setWednesdayEndTime(user.userSchedules[name].days.wednesday.times.split("-")[1]);
            //         // setThursdayStartTime(user.userSchedules[name].days.thursday.times.split("-")[0]);
            //         // setThursdayEndTime(user.userSchedules[name].days.thursday.times.split("-")[1]);
            //         // setFridayStartTime(user.userSchedules[name].days.friday.times.split("-")[0]);
            //         // setFridayEndTime(user.userSchedules[name].days.friday.times.split("-")[1]);
            //         // setSaturdayStartTime(user.userSchedules[name].days.saturday.times.split("-")[0]);
            //         // setSaturdayEndTime(user.userSchedules[name].days.saturday.times.split("-")[1]);

            //         return setScheduleName(name);
            //     }
            //     return
            // });
        }
    }, [user])

    useEffect(() => {
        if (myEvent) {

            if (myEvent.inbound) {
                setSelectedPhoneNumberLabel([{ label: myEvent.location, value: myEvent.location }]);
            } else {
                if (user.phoneNumbers) {
                    setSelectedPhoneNumberLabel([{ label: Object.keys(user.phoneNumbers)[0], value: Object.keys(user.phoneNumbers)[0] }]);
                } else {
                    setSelectedPhoneNumberLabel(null)
                }
            }

            if (parseInt(myEvent.Duration) > 60) {
                setCustomHourView(true);
                if (parseInt(myEvent.Duration) % 60 === 0) {
                    setDurationType("hours");
                } else {
                    setDurationType("minutes");
                }
                setSelectedDurationLabel([{ label: "custom", value: "custom" }])
                // console.log("found on one")
            }
            else if (parseInt(myEvent.Duration) !== 15 && parseInt(myEvent.Duration) !== 30 && parseInt(myEvent.Duration) !== 45 && parseInt(myEvent.Duration) !== 60) {
                setCustomHourView(true);
                setSelectedDurationLabel([{ label: "custom", value: "custom" }])
                if (parseInt(myEvent.Duration) % 60 === 0) {
                    setDurationType("hours");
                } else {
                    setDurationType("minutes");
                }
                // console.log("found on two")
            } else {
                setCustomHourView(false);
                setSelectedDurationLabel(meetingDurationListForSelect.filter((data) => { return data.value === myEvent.Duration }))
                // console.log("found on three")
            }

            setSelectedTimezone(myEvent.availability.timezone ? myEvent.availability.timezone : null);

            setIsDescriptionRequired(myEvent.inviteeQuestions.required);


            if (parseInt(myEvent.stopBookingBeforeTime.value) > 4) {
                setCustomStopBookingView(true);
                setStopBookingTime([myEvent.stopBookingBeforeTime.type, myEvent.stopBookingBeforeTime.value]);
            } {
                setCustomStopBookingView(false);
                setStopBookingTime([myEvent.stopBookingBeforeTime.type, myEvent.stopBookingBeforeTime.value]);
            }


            switch (myEvent.availability.schedulabel.selection) {

                case "straightRollingDays":
                    setStraightRollingDays(true);
                    setWithinDateRange(false);
                    setInfiniteDateRange(false);
                    break;
                case "infiniteDays":
                    setStraightRollingDays(false);
                    setWithinDateRange(false);
                    setInfiniteDateRange(true);
                    break;
                case "withinDateRange":
                    setStraightRollingDays(false);
                    setWithinDateRange(true);
                    setInfiniteDateRange(false);
                    break;
                default:
                    setStraightRollingDays(true);
                    setWithinDateRange(false);
                    setInfiniteDateRange(false);
                    break;

            }




            if (myEvent.beforeEventTime) {
                if (myEvent.beforeEventTime === 15) {
                    setSelectedEventBeforeTimeFromList({ label: "15 minutes", value: 15 })
                } else if (myEvent.beforeEventTime === 30) {
                    setSelectedEventBeforeTimeFromList({ label: "30 minutes", value: 30 })
                }
                else if (myEvent.beforeEventTime === 45) {
                    setSelectedEventBeforeTimeFromList({ label: "45 minutes", value: 45 })
                }
                else if (myEvent.beforeEventTime === 60) {
                    setSelectedEventBeforeTimeFromList({ label: "60 minutes", value: 60 })
                }
                else if (myEvent.beforeEventTime === 90) {
                    setSelectedEventBeforeTimeFromList({ label: "1 hr 30 minutes", value: 90 })
                }
                else if (myEvent.beforeEventTime === 120) {
                    setSelectedEventBeforeTimeFromList({ label: "2 hr", value: 120 })
                }
                else if (myEvent.beforeEventTime === 150) {
                    setSelectedEventBeforeTimeFromList({ label: "2 hr 30 minutes", value: 150 })
                }
                else if (myEvent.beforeEventTime === 180) {
                    setSelectedEventBeforeTimeFromList({ label: "3 hr", value: 180 })
                }
                else {
                    setSelectedEventBeforeTimeFromList({ label: "custom", value: "custom" })
                }
            }


            if (myEvent.afterEventTime) {
                if (myEvent.afterEventTime === 15) {
                    setSelectedEventAfterTimeFromList({ label: "15 minutes", value: 15 })
                } else if (myEvent.afterEventTime === 30) {
                    setSelectedEventAfterTimeFromList({ label: "30 minutes", value: 30 })
                }
                else if (myEvent.afterEventTime === 45) {
                    setSelectedEventAfterTimeFromList({ label: "45 minutes", value: 45 })
                }
                else if (myEvent.afterEventTime === 60) {
                    setSelectedEventAfterTimeFromList({ label: "60 minutes", value: 60 })
                }
                else if (myEvent.afterEventTime === 90) {
                    setSelectedEventAfterTimeFromList({ label: "1 hr 30 minutes", value: 90 })
                }
                else if (myEvent.afterEventTime === 120) {
                    setSelectedEventAfterTimeFromList({ label: "2 hr", value: 120 })
                }
                else if (myEvent.afterEventTime === 150) {
                    setSelectedEventAfterTimeFromList({ label: "2 hr 30 minutes", value: 150 })
                }
                else if (myEvent.afterEventTime === 180) {
                    setSelectedEventAfterTimeFromList({ label: "3 hr", value: 180 })
                }
                else {
                    setSelectedEventAfterTimeFromList({ label: "custom", value: "custom" })
                }
            }
            if (myEvent.stopBookingBeforeTime) {
                if (myEvent.stopBookingBeforeTime.value === 1) {
                    setSelectedStopBookingBeforeLabel({ label: "1 Hour", value: 1 })
                } else if (myEvent.stopBookingBeforeTime.value === 2) {
                    setSelectedStopBookingBeforeLabel({ label: "2 Hours", value: 2 })
                }
                else if (myEvent.stopBookingBeforeTime.value === 3) {
                    setSelectedStopBookingBeforeLabel({ label: "3 Hours", value: 3 })
                }
                else if (myEvent.stopBookingBeforeTime.value === 4) {
                    setSelectedStopBookingBeforeLabel({ label: "4 Hours", value: 4 })
                }
                else {
                    setSelectedStopBookingBeforeLabel({ label: "custom", value: "custom" })
                }
            }

            // console.log(myEvent.stopBookingBeforeTime.value);

            let isTheScheduleExist = null;
            let identifier = null;
            Object.keys(user.userSchedules).map((sched, key) => {
                if (myEvent.availability.userSchedules.identifier === user.userSchedules[sched].identifier) {
                    // console.log("found")
                    if (JSON.stringify(myEvent.availability.userSchedules.days) === JSON.stringify(user.userSchedules[sched].days)) {
                        // console.log("days are same")
                        if (myEvent.availability.userSchedules.dateOverrides && user.userSchedules[sched].dateOverrides) {
                            if (JSON.stringify(myEvent.availability.userSchedules.dateOverrides) === JSON.stringify(user.userSchedules[sched].dateOverrides)) {
                                // console.log("overrides are same ")
                                identifier = myEvent.availability.userSchedules.identifier;
                                return isTheScheduleExist = sched;
                            } else {
                                // console.log("overrides not same ")
                            }
                        } else if (!(myEvent.availability.userSchedules.dateOverrides) && !(user.userSchedules[sched].dateOverrides)) {
                            // console.log("bothe override empty");
                            identifier = myEvent.availability.userSchedules.identifier;
                            return isTheScheduleExist = sched;
                        } else {
                            // console.log("overrides not same ");
                        }
                    } else {
                        // console.log("days not same")
                    }
                }
            });

            if (isTheScheduleExist) {
                setScheduleName(isTheScheduleExist);
                setScheduleIdentifier(identifier);
                setExistingScheduleView(true);
                setCustomScheduleView(false);
                // console.log("existing schedule", isTheScheduleExist)
            } else {
                setScheduleIdentifier(null);
                setScheduleName("Default Used");
                setExistingScheduleView(false);
                setCustomScheduleView(true);
                // console.log("not existing schedule")
            }


            if (myEvent.eventType === "One on One") {

                setSelectedTimezone(myEvent.availability.timezone);

                setSchedule(myEvent.availability.userSchedules.days);

                setDateOverrides(myEvent.availability.userSchedules.dateOverrides);


                setDefaultSchedule(myEvent.availability.userSchedules.days);
                setDefaultOverride(myEvent.availability.userSchedules.dateOverrides);



                setIsSundayActive(myEvent.availability.userSchedules.days.sunday.active);
                setIsMondayActive(myEvent.availability.userSchedules.days.monday.active);
                setIsTuesdayActive(myEvent.availability.userSchedules.days.tuesday.active);
                setIsWednesdayActive(myEvent.availability.userSchedules.days.wednesday.active);
                setIsThursdayActive(myEvent.availability.userSchedules.days.thursday.active);
                setIsFridayActive(myEvent.availability.userSchedules.days.friday.active);
                setIsSaturdayActive(myEvent.availability.userSchedules.days.saturday.active);


                setOneOnOneQuestions(myEvent.inviteeQuestions.data);

                setId(myEvent.id);
                setCustomEventLink(myEvent.EventLink.split("/")[myEvent.EventLink.split("/").length - 1]);
                setRefreshToken(myEvent.refreshToken);
                setEventType(myEvent.eventType);
                setEventName(myEvent.eventName);
                setLocation(myEvent.location);
                setMeetingType(myEvent.meetingType);
                setInboundCall(myEvent.inbound);
                setDescription(myEvent.description);
                setEventLink(myEvent.EventLink);
                setMaxInvitees(myEvent.MaxInvitees);
                setDisplaySpots(myEvent.DisplaySpots);
                setAttendees(myEvent.attendees);
                setEventColor(myEvent.EventColor);
                setDuration(myEvent.Duration);
                setCalendarIds(myEvent.calendarIds);
                setAvailability(myEvent.availability);
                setEventBeforeTime(myEvent.beforeEventTime);
                setEventAfterTime(myEvent.afterEventTime);
                setStopSchedule(myEvent.stopSchedule);
                setSecretEvent(myEvent.secretEvent);


                // setSelectedDurationLabel(
                //     myEvent.Duration > 60 ?
                //         [{ "custom": "custom" }] :
                //         meetingDurationListForSelect.filter((data) => {
                //             return data.value === myEvent.Duration
                //         })
                // );


                setOneToOneDates(myEvent.availability.dateTimes);

                if (Object.keys(myEvent.availability.dateTimes).length > 0) {
                    setOneToOneDate(dayjs(new Date()).format("D MMMM YYYY"));
                    setExistingScheduleView(false);
                    setCustomScheduleView(true);
                }

                setBetweenDays([
                    {
                        startDate: new Date(myEvent.availability.schedulabel.startDate),
                        endDate: myEvent.availability.schedulabel.endDate ? new Date(myEvent.availability.schedulabel.endDate) : null,
                        key: 'selection'
                    }
                ])


                if (myEvent.beforeEventTimeActive) {
                    setEventBeforeTimeActive(true);
                }
                if (myEvent.afterEventTimeActive) {
                    setEventAfterTimeActive(true);
                }
            }
            else if (myEvent.eventType === "Group") {

                setSelectedTimezone(myEvent.availability.timezone);

                setSchedule(myEvent.availability.userSchedules.days);

                setDateOverrides(myEvent.availability.userSchedules.dateOverrides);

                setDefaultSchedule(myEvent.availability.userSchedules.days);
                setDefaultOverride(myEvent.availability.userSchedules.dateOverrides);

                setIsSundayActive(myEvent.availability.userSchedules.days.sunday.active);
                setIsMondayActive(myEvent.availability.userSchedules.days.monday.active);
                setIsTuesdayActive(myEvent.availability.userSchedules.days.tuesday.active);
                setIsWednesdayActive(myEvent.availability.userSchedules.days.wednesday.active);
                setIsThursdayActive(myEvent.availability.userSchedules.days.thursday.active);
                setIsFridayActive(myEvent.availability.userSchedules.days.friday.active);
                setIsSaturdayActive(myEvent.availability.userSchedules.days.saturday.active);


                setGroupQuestions(myEvent.inviteeQuestions.data);



                setId(myEvent.id);
                setCustomEventLink(myEvent.EventLink.split("/")[myEvent.EventLink.split("/").length - 1]);

                setRefreshToken(myEvent.refreshToken);
                setEventType(myEvent.eventType);
                setGroupSummary(myEvent.eventName);
                setGroupLocation(myEvent.location);
                setGroupMeetingType(myEvent.meetingType);
                setGroupInboundCall(myEvent.inbound);
                setGroupDescription(myEvent.description);
                setEventLink(myEvent.EventLink);
                setMaxInvitees(myEvent.MaxInvitees);
                setMaxInvitiesActive(myEvent.DisplaySpots);
                setAttendees(myEvent.attendees);
                setGroupColorId(myEvent.EventColor);
                setGroupDuration(myEvent.Duration);
                setCalendarIds(myEvent.calendarIds);
                setAvailability(myEvent.availability);
                setEventGroupBeforeTime(myEvent.beforeEventTime);
                setEventGroupAfterTime(myEvent.afterEventTime);
                setStopSchedule(myEvent.stopSchedule);
                setGroupSecretEvent(myEvent.secretEvent);

                // setSelectedDurationLabel(
                //     myEvent.Duration > 60 ?
                //         [{ "custom": "custom" }] :
                //         meetingDurationListForSelect.filter((data) => {
                //             return data.value === myEvent.Duration
                //         })
                // );

                setGroupDates(myEvent.availability.dateTimes);

                if (Object.keys(myEvent.availability.dateTimes).length > 0) {
                    setGroupDate(dayjs(new Date()).format("D MMMM YYYY"));
                    setExistingScheduleView(false);
                    setCustomScheduleView(true);
                }

                setBetweenDays([
                    {
                        startDate: new Date(myEvent.availability.schedulabel.startDate),
                        endDate: myEvent.availability.schedulabel.endDate ? new Date(myEvent.availability.schedulabel.endDate) : null,
                        key: 'selection'
                    }
                ])
                if (myEvent.beforeEventTimeActive) {
                    setEventGroupBeforeTimeActive(true)
                }
                if (myEvent.afterEventTimeActive) {
                    setEventGroupAfterTimeActive(true)
                }
            }
            else if (myEvent.eventType === "Round Robin") {

                setSelectedTimezone(myEvent.availability.timezone);

                setSchedule(myEvent.availability.userSchedules.days);

                setDateOverrides(myEvent.availability.userSchedules.dateOverrides);

                setDefaultSchedule(myEvent.availability.userSchedules.days);
                setDefaultOverride(myEvent.availability.userSchedules.dateOverrides);

                setIsSundayActive(myEvent.availability.userSchedules.days.sunday.active);
                setIsMondayActive(myEvent.availability.userSchedules.days.monday.active);
                setIsTuesdayActive(myEvent.availability.userSchedules.days.tuesday.active);
                setIsWednesdayActive(myEvent.availability.userSchedules.days.wednesday.active);
                setIsThursdayActive(myEvent.availability.userSchedules.days.thursday.active);
                setIsFridayActive(myEvent.availability.userSchedules.days.friday.active);
                setIsSaturdayActive(myEvent.availability.userSchedules.days.saturday.active);


                setRoundRobinQuestions(myEvent.inviteeQuestions.data);


                setId(myEvent.id);
                setCustomEventLink(myEvent.EventLink.split("/")[myEvent.EventLink.split("/").length - 1]);
                setRefreshToken(myEvent.refreshToken);
                setEventType(myEvent.eventType);
                setEventName(myEvent.eventName);
                setLocation(myEvent.location);
                setMeetingType(myEvent.meetingType);
                setInboundCall(myEvent.inbound);
                setDescription(myEvent.description);
                setEventLink(myEvent.EventLink);
                setMaxInvitees(myEvent.MaxInvitees);
                setDisplaySpots(myEvent.DisplaySpots);
                setAttendees(myEvent.attendees);
                setEventColor(myEvent.EventColor);
                setDuration(myEvent.Duration);
                setCalendarIds(myEvent.calendarIds);
                setAvailability(myEvent.availability);
                setEventAfterTime(myEvent.afterEventTime)
                setEventBeforeTime(myEvent.beforeEventTime);
                setStopSchedule(myEvent.stopSchedule);
                setSecretEvent(myEvent.secretEvent);

                // setSelectedDurationLabel(myEvent.Duration > 60 ? [{ "custom": "custom" }] : meetingDurationListForSelect.filter((data) => { return data.value === myEvent.Duration }));

                setRoundRobinDates(myEvent.availability.dateTimes);

                if (Object.keys(myEvent.availability.dateTimes).length > 0) {
                    setRoundRobinDate(dayjs(new Date()).format("D MMMM YYYY"));
                    setExistingScheduleView(false);
                    setCustomScheduleView(true);
                }
                setBetweenDays([
                    {
                        startDate: new Date(myEvent.availability.schedulabel.startDate),
                        endDate: myEvent.availability.schedulabel.endDate ? new Date(myEvent.availability.schedulabel.endDate) : null,
                        key: 'selection'
                    }
                ])
                if (myEvent.beforeEventTimeActive) {
                    setEventBeforeTimeActive(true)
                }
                if (myEvent.afterEventTimeActive) {
                    setEventAfterTimeActive(true)
                }
            }
            else if (myEvent.eventType === "One-off") {
                setId(myEvent.id);
                setRefreshToken(myEvent.refreshToken);
                setEventType(myEvent.eventType);
                setOneOffMeetingName(myEvent.eventName);
                setOneOffLocation(myEvent.location);
                setOneOffMeetingType(myEvent.meetingType);
                setInboundCallOneOff(myEvent.inbound);
                setOneOffDescription(myEvent.description);
                setEventLink(myEvent.EventLink);
                setMaxInvitees(myEvent.MaxInvitees);
                setDisplaySpots(myEvent.DisplaySpots);
                setAttendees(myEvent.attendees);
                setEventColor(myEvent.EventColor);
                setOneOffDuration(myEvent.Duration);
                setCalendarIds(myEvent.calendarIds);
                setAvailability(myEvent.availability);
                setBeforeEventTime(myEvent.beforeEventTime);
                setAfterEventTime(myEvent.afterEventTime);
                setStopSchedule(myEvent.stopSchedule);
                setSecretEvent(myEvent.secretEvent);

                setOneOffDates(myEvent.availability.dateTimes)

                if (Object.keys(myEvent.availability.dateTimes).length > 0) {
                    setOneOffDate(dayjs(new Date()).format("D MMMM YYYY"));
                    setExistingScheduleView(false);
                    setCustomScheduleView(true);
                }
            }




            let timezonesArray = Object.entries(timezones);
            let countryArray = Object.entries(allCountries);
            let arrays = [];
            timezonesArray.map((val, key) => {
                arrays.push({ label: val[0], value: val[1] });
            })
            setTimezonesList(arrays);

            let newArray = [];
            countryArray.map((val, key) => {
                newArray.push({ label: val[1].name, value: val[0] });
            })

            setCountriesList(newArray);


            const countryFromSchedule = newArray.filter((countryNames) => { return countryNames.value === myEvent.availability.timezone.value.countries[0] });

            const restTimezonesSchedule = arrays.filter((zone) => { return zone.value.countries.includes(countryFromSchedule[0].value); });
            setFilteredTimezonesListSchedule(restTimezonesSchedule);

            setCountrySchedule(countryFromSchedule);


            const rest = arrays.filter((zone) => { return zone.value.countries.includes(user.settings.country.value); });
            setFilteredTimezonesList(rest);

            setLoadingComplete(true);

        }
    }, [myEvent])

    useEffect(() => {
        const selected = document.getElementsByClassName("selectedDayTabOneOff");
        if (selected.length) {
            for (var i = 0; i <= selected.length; i++) {
                selected[i].classList.remove("selectedDayTabOneOff");
            }
        }
        setCurrentMonth(getMonth(monthIndexCurrent));
        setNextMonth(getMonth(monthIndexCurrent + 1));
        // console.log(monthIndexCurrent);

    }, [monthIndexCurrent])

    useEffect(() => {
        if (eventType === "One on One") { convertToMinutesOneOnOne(minutesOneOnOne.current.value); }
        else if (eventType === "Group") { convertToMinutesGroup(minutesGroup.current.value); }
        else if (eventType === "One-off") { convertToMinutesOneOff(minutesOneOff.current.value); }
        else if (eventType === "Round Robin") { convertToMinutesOneOnOne(minutesOneOnOne.current.value); }
        else { convertToMinutesOneOnOne(duration); }
    }, [durationType])

    const handleSubmit = async (e) => {
        e.preventDefault();

        const load1 = document.querySelector("#oneOnOneEditButton1");
        const load2 = document.querySelector("#oneOnOneEditButton2");
        load1.disabled = true;
        load1.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>Loading..';

        load2.disabled = true;
        load2.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>Loading..';

        if (eventName.replaceAll(" ", "").length < 1) {
            // setCustomAlertBox(true);
            // setAlertBoxMessages("Invalid Meeting Name");
            // setAlertErrorType("invalid");

            setViewToast(true);
            popupAlert.play();
            setToastTitle("Error");
            setToastBody("Invalid Meeting Name.");
            setToastMessageType("invalid");

            load1.disabled = false;
            load1.innerHTML = 'Update';

            load2.disabled = false;
            load2.innerHTML = 'Update';
        }
        else if (description.replaceAll(" ", "").length < 1) {
            // setCustomAlertBox(true);
            // setAlertBoxMessages("Invalid Description");
            // setAlertErrorType("invalid");

            setViewToast(true);
            popupAlert.play();
            setToastTitle("Error");
            setToastBody("Invalid Description.");
            setToastMessageType("invalid");

            load1.disabled = false;
            load1.innerHTML = 'Update';

            load2.disabled = false;
            load2.innerHTML = 'Update';
        }
        else if (meetingType === "phone" && location.replaceAll(" ", "").length < 8 && inboundCall) {
            // setCustomAlertBox(true);
            // setAlertBoxMessages("Invalid Phone");
            // setAlertErrorType("invalid");

            setViewToast(true);
            popupAlert.play();
            setToastTitle("Error");
            setToastBody("Invalid Phone.");
            setToastMessageType("invalid");


            load1.disabled = false;
            load1.innerHTML = 'Update';

            load2.disabled = false;
            load2.innerHTML = 'Update';
        }
        else if (meetingType === "address" && location.replaceAll(" ", "").length < 1) {
            // setCustomAlertBox(true);
            // setAlertBoxMessages("Invalid Address");
            // setAlertErrorType("invalid");

            setViewToast(true);
            popupAlert.play();
            setToastTitle("Error");
            setToastBody("Invalid Address.");
            setToastMessageType("invalid");


            load1.disabled = false;
            load1.innerHTML = 'Update';

            load2.disabled = false;
            load2.innerHTML = 'Update';
        }
        else {
            const query = {
                "id": customEventLink,
                "email": user.email,
                "eventData": myEvent,
                "eventName": eventName,
                "description": description,
                "EventLink": "/" + myCustomLink.username.replaceAll(" ", "-") + "/" + customEventLink,
                "eventColor": eventColor,
                "eventType": eventType,
                "location": location,
                "meetingType": meetingType,
                "inbound": inboundCall,
                "maxInvitees": maxInvitees,
                "displaySpots": maxInvitiesActive,
                "duration": duration,
                "availability": {
                    "timezone": selectedTimezone,
                    "timezoneUTC": selectedTimezoneUTC,
                    "dateTimes": oneToOneDates,
                    "userSchedules": {
                        "identifier": scheduleIdentifier,
                        "days": {
                            "sunday": { "active": isSundayActive, "times": isSundayActive ? schedule.sunday.times : [] },
                            "monday": { "active": isMondayActive, "times": isMondayActive ? schedule.monday.times : [] },
                            "tuesday": { "active": isTuesdayActive, "times": isTuesdayActive ? schedule.tuesday.times : [] },
                            "wednesday": { "active": isWednesdayActive, "times": isWednesdayActive ? schedule.wednesday.times : [] },
                            "thursday": { "active": isThursdayActive, "times": isThursdayActive ? schedule.thursday.times : [] },
                            "friday": { "active": isFridayActive, "times": isFridayActive ? schedule.friday.times : [] },
                            "saturday": { "active": isSaturdayActive, "times": isSaturdayActive ? schedule.saturday.times : [] },
                        },
                        "dateOverrides": dateOverrides
                    },
                    "schedulabel": {
                        "startDate": betweenDays[0].startDate,
                        "endDate": infiniteDateRange ? null : betweenDays[0].endDate,
                        "selection": straightRollingDays ? "straightRollingDays" : infiniteDateRange ? "infiniteDays" : "withinDateRange"
                    }
                },
                "beforeEventTime": eventBeforeTime,
                "afterEventTime": eventAfterTime,
                "beforeEventTimeActive": eventBeforeTimeActive,
                "afterEventTimeActive": eventAfterTimeActive,
                "stopSchedule": stopSchedule,
                "stopBookingBeforeTime": { "type": stopBookingTime[0], "value": stopBookingTime[1] },
                "inviteeQuestions": { "data": oneOnOneQuestions, "required": isDescriptionRequired },
                "secretEvent": secretEvent,
                "lastEdited": dayjs().format("h:mm:ssa D MMM YYYY")
            }
            const eventEdit = await axios.post(serverUrl + "/events/editEventNow", query)
            if (eventEdit.status === 200) {
                // setCustomAlertBox(true);
                // setAlertBoxMessages("Event Updated Successfully");
                // setAlertErrorType("success");

                setViewToast(true);
                popupAlert.play();
                setToastTitle("Successful");
                setToastBody("Event Published Successfully.");
                setToastMessageType("success");

                setWhenEvent(false);
                setWhatEvent(false);

                setTimeout(() => {

                    load1.disabled = false;
                    load1.innerHTML = 'Update';

                    load2.disabled = false;
                    load2.innerHTML = 'Update';

                    window.location.href = `/edit/${customEventLink}`;
                }, 2000)
            } else {
                // setCustomAlertBox(true);
                // setAlertBoxMessages("error while updating the event...!");
                // setAlertErrorType("invalid");

                setViewToast(true);
                popupAlert.play();
                setToastTitle("Error");
                setToastBody("Error while updating the event.");
                setToastMessageType("invalid");



                load1.disabled = false;
                load1.innerHTML = 'Update';

                load2.disabled = false;
                load2.innerHTML = 'Update';
            }
        }
    }

    const updateRoundRobinEvent = async (e) => {
        e.preventDefault();

        const load1 = document.querySelector("#roundRobinEditButton1");
        const load2 = document.querySelector("#roundRobinEditButton2");
        load1.disabled = true;
        load1.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>Loading..';

        load2.disabled = true;
        load2.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>Loading..';

        if (eventName.replaceAll(" ", "").length < 1) {
            // setCustomAlertBox(true);
            // setAlertBoxMessages("Invalid Meeting Name");
            // setAlertErrorType("invalid");
            setViewToast(true);
            popupAlert.play();
            setToastTitle("Error");
            setToastBody("Invalid Meeting Name.");
            setToastMessageType("invalid");

            load1.disabled = false;
            load1.innerHTML = 'Update';

            load2.disabled = false;
            load2.innerHTML = 'Update';
        }
        else if (description.replaceAll(" ", "").length < 1) {
            // setCustomAlertBox(true);
            // setAlertBoxMessages("Invalid Description");
            // setAlertErrorType("invalid");

            setViewToast(true);
            popupAlert.play();
            setToastTitle("Error");
            setToastBody("Invalid Description.");
            setToastMessageType("invalid");

            load1.disabled = false;
            load1.innerHTML = 'Update';

            load2.disabled = false;
            load2.innerHTML = 'Update';
        }
        else if (meetingType === "phone" && location.replaceAll(" ", "").length < 8 && inboundCall) {
            // setCustomAlertBox(true);
            // setAlertBoxMessages("Invalid Phone");
            // setAlertErrorType("invalid");

            setViewToast(true);
            popupAlert.play();
            setToastTitle("Error");
            setToastBody("Invalid Phone.");
            setToastMessageType("invalid");


            load1.disabled = false;
            load1.innerHTML = 'Update';

            load2.disabled = false;
            load2.innerHTML = 'Update';
        }
        else if (meetingType === "address" && location.replaceAll(" ", "").length < 1) {
            // setCustomAlertBox(true);
            // setAlertBoxMessages("Invalid Address");
            // setAlertErrorType("invalid");

            setViewToast(true);
            popupAlert.play();
            setToastTitle("Error");
            setToastBody("Invalid Address.");
            setToastMessageType("invalid");


            load1.disabled = false;
            load1.innerHTML = 'Update';

            load2.disabled = false;
            load2.innerHTML = 'Update';
        }
        else {
            const query = {
                "id": customEventLink,
                "email": user.email,
                "eventData": myEvent,
                "eventName": eventName,
                "description": description,
                "EventLink": "/" + myCustomLink.username.replaceAll(" ", "-") + "/" + customEventLink,
                "eventColor": eventColor,
                "eventType": eventType,
                "location": location,
                "meetingType": meetingType,
                "inbound": inboundCall,
                "maxInvitees": maxInvitees,
                "displaySpots": maxInvitiesActive,
                "duration": duration,
                "availability": {
                    "timezone": selectedTimezone,
                    "timezoneUTC": selectedTimezoneUTC,
                    "dateTimes": roundRobinDates,
                    "userSchedules": {
                        "identifier": scheduleIdentifier,
                        "days": {
                            "sunday": { "active": isSundayActive, "times": isSundayActive ? schedule.sunday.times : [] },
                            "monday": { "active": isMondayActive, "times": isMondayActive ? schedule.monday.times : [] },
                            "tuesday": { "active": isTuesdayActive, "times": isTuesdayActive ? schedule.tuesday.times : [] },
                            "wednesday": { "active": isWednesdayActive, "times": isWednesdayActive ? schedule.wednesday.times : [] },
                            "thursday": { "active": isThursdayActive, "times": isThursdayActive ? schedule.thursday.times : [] },
                            "friday": { "active": isFridayActive, "times": isFridayActive ? schedule.friday.times : [] },
                            "saturday": { "active": isSaturdayActive, "times": isSaturdayActive ? schedule.saturday.times : [] },
                        },
                        "dateOverrides": dateOverrides
                    },
                    "schedulabel": {
                        "startDate": betweenDays[0].startDate,
                        "endDate": infiniteDateRange ? null : betweenDays[0].endDate,
                        "selection": straightRollingDays ? "straightRollingDays" : infiniteDateRange ? "infiniteDays" : "withinDateRange"
                    }
                },
                "beforeEventTime": eventBeforeTime,
                "afterEventTime": eventAfterTime,
                "beforeEventTimeActive": eventBeforeTimeActive,
                "afterEventTimeActive": eventAfterTimeActive,
                "stopSchedule": stopSchedule,
                "stopBookingBeforeTime": { "type": stopBookingTime[0], "value": stopBookingTime[1] },
                "inviteeQuestions": { "data": roundRobinQuestions, "required": isDescriptionRequired },
                "secretEvent": secretEvent,
                "lastEdited": dayjs().format("h:mm:ssa D MMM YYYY")
            }
            const eventEdit = await axios.post(serverUrl + "/events/editEventNow", query)
            if (eventEdit.status === 200) {
                // setCustomAlertBox(true);
                // setAlertBoxMessages("Event Updated Successfully");
                // setAlertErrorType("success");

                setViewToast(true);
                popupAlert.play();
                setToastTitle("Successful");
                setToastBody("Event Updated Successfully.");
                setToastMessageType("success");

                setWhenEvent(false);
                setWhatEvent(false);

                setTimeout(() => {
                    load1.disabled = false;
                    load1.innerHTML = 'Update';

                    load2.disabled = false;
                    load2.innerHTML = 'Update';

                    window.location.href = `/edit/${customEventLink}`;
                }, 2000)
            } else {
                // setCustomAlertBox(true);
                // setAlertBoxMessages("Error while updating the Event...!");
                // setAlertErrorType("invalid");

                setViewToast(true);
                popupAlert.play();
                setToastTitle("Error");
                setToastBody("Error while updating the Event..!");
                setToastMessageType("invalid");

                load1.disabled = false;
                load1.innerHTML = 'Update';

                load2.disabled = false;
                load2.innerHTML = 'Update';
            }
        }
    }

    const updateOneOffMeeting = async (e) => {
        e.preventDefault();
        if (oneOffMeetingName.replaceAll(" ", "").length < 1) {
            // setCustomAlertBox(true);
            // setAlertBoxMessages("Invalid Meeting Name");
            // setAlertErrorType("invalid");

            setViewToast(true);
            popupAlert.play();
            setToastTitle("Error");
            setToastBody("Invalid Meeting Name");
            setToastMessageType("invalid");
        }
        else if (oneOffDescription.replaceAll(" ", "").length < 1) {
            // setCustomAlertBox(true);
            // setAlertBoxMessages("Invalid Description");
            // setAlertErrorType("invalid");

            setViewToast(true);
            popupAlert.play();
            setToastTitle("Error");
            setToastBody("Invalid Description.");
            setToastMessageType("invalid");
        }
        else if (oneOffMeetingType === "phone" && oneOffLocation.replaceAll(" ", "").length < 8 && inboundCallOneOff) {
            // setCustomAlertBox(true);
            // setAlertBoxMessages("Invalid Phone Number");
            // setAlertErrorType("invalid");

            setViewToast(true);
            popupAlert.play();
            setToastTitle("Error");
            setToastBody("Invalid Phone Number");
            setToastMessageType("invalid");
        }
        else if (oneOffMeetingType === "address" && oneOffLocation.replaceAll(" ", "").length < 1) {
            // setCustomAlertBox(true);
            // setAlertBoxMessages("Invalid Address");
            // setAlertErrorType("invalid");

            setViewToast(true);
            popupAlert.play();
            setToastTitle("Error");
            setToastBody("Invalid Address");
            setToastMessageType("invalid");
        }
        else {
            const query = {
                "email": user.email,
                "eventData": myEvent,
                "eventName": oneOffMeetingName,
                "description": oneOffDescription,
                "eventColor": eventColor,
                "eventType": eventType,
                "location": oneOffLocation,
                "meetingType": oneOffMeetingType,
                "inbound": inboundCallOneOff,
                "maxInvitees": maxInvitees,
                "displaySpots": true,
                "duration": oneOffDuration,
                "calendarIds": [],
                "availability": {
                    "timezone": selectedTimezone,
                    "timezoneUTC": selectedTimezoneUTC,
                    "dateTimes": oneOffDates
                },
                "beforeEventTime": eventBeforeTime,
                "afterEventTime": eventAfterTime,
                "beforeEventTimeActive": false,
                "afterEventTimeActive": false,
                "stopSchedule": eventAfterTime,
                "secretEvent": secretEvent,
                "lastEdited": dayjs().format("h:mm:ssa D MMM YYYY")
            }
            const eventEdit = await axios.post(serverUrl + "/events/editEventNow", query)
            if (eventEdit.status === 200) {
                // setCustomAlertBox(true);
                // setAlertBoxMessages("Event Updated Successfully.");
                // setAlertErrorType("success");

                setViewToast(true);
                popupAlert.play();
                setToastTitle("Successful");
                setToastBody("Event Updated Successfully");
                setToastMessageType("success");

                setTimeout(() => {
                    window.location.href = `/edit/${customEventLink}`;
                }, 2000)
            } else {
                // setCustomAlertBox(true);
                // setAlertBoxMessages("Error while updating the Event..!");
                // setAlertErrorType("invalid");
                setViewToast(true);
                popupAlert.play();
                setToastTitle("Error");
                setToastBody("Error while updating the event.");
                setToastMessageType("invalid");
            }
        }
    }

    const updateGroupMeeting = async (e) => {
        e.preventDefault();

        console.log(groupDuration);


        const load1 = document.querySelector("#groupEditButton1");
        const load2 = document.querySelector("#groupEditButton2");
        load1.disabled = true;
        load1.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>Loading..';

        load2.disabled = true;
        load2.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>Loading..';

        if (groupSummary.replaceAll(" ", "").length < 1) {
            // setCustomAlertBox(true);
            // setAlertBoxMessages("Invalid Meeting Name");
            // setAlertErrorType("invalid");
            setViewToast(true);
            popupAlert.play();
            setToastTitle("Error");
            setToastBody("Invalid Meeting Name");
            setToastMessageType("invalid");

            load1.disabled = false;
            load1.innerHTML = 'Update';

            load2.disabled = false;
            load2.innerHTML = 'Update';
        }
        else if (groupDescription.replaceAll(" ", "").length < 1) {
            // setCustomAlertBox(true);
            // setAlertBoxMessages("Invalid Description.");
            // setAlertErrorType("invalid");
            setViewToast(true);
            popupAlert.play();
            setToastTitle("Error");
            setToastBody("Invalid Description.");
            setToastMessageType("invalid");

            load1.disabled = false;
            load1.innerHTML = 'Update';

            load2.disabled = false;
            load2.innerHTML = 'Update';
        }
        else if (groupMeetingType === "phone" && groupLocation.replaceAll(" ", "").length < 8 && groupInboundCall) {
            // setCustomAlertBox(true);
            // setAlertBoxMessages("Invalid Phone");
            // setAlertErrorType("invalid");
            setViewToast(true);
            popupAlert.play();
            setToastTitle("Error");
            setToastBody("Invalid Phone");
            setToastMessageType("invalid");

            load1.disabled = false;
            load1.innerHTML = 'Update';

            load2.disabled = false;
            load2.innerHTML = 'Update';
        }
        else if (groupMeetingType === "address" && groupLocation.replaceAll(" ", "").length < 1) {
            // setCustomAlertBox(true);
            // setAlertBoxMessages("Invalid Address");
            // setAlertErrorType("invalid");

            setViewToast(true);
            popupAlert.play();
            setToastTitle("Error");
            setToastBody("Invalid Address");
            setToastMessageType("invalid");

            load1.disabled = false;
            load1.innerHTML = 'Update';

            load2.disabled = false;
            load2.innerHTML = 'Update';
        }
        else {
            const query = {
                "id": customEventLink,
                "email": user.email,
                "eventData": myEvent,
                "eventName": groupSummary,
                "description": groupDescription,
                "EventLink": "/" + myCustomLink.username.replaceAll(" ", "-") + "/" + customEventLink,
                "eventColor": groupColorId,
                "eventType": eventType,
                "location": groupLocation,
                "meetingType": groupMeetingType,
                "inbound": groupInboundCall,
                "maxInvitees": maxInvitees,
                "displaySpots": maxInvitiesActive,
                "duration": groupDuration,
                "availability": {
                    "timezone": selectedTimezone,
                    "timezoneUTC": selectedTimezoneUTC,
                    "dateTimes": groupDates,
                    "userSchedules": {
                        "identifier": scheduleIdentifier,
                        "days": {
                            "sunday": { "active": isSundayActive, "times": isSundayActive ? schedule.sunday.times : [] },
                            "monday": { "active": isMondayActive, "times": isMondayActive ? schedule.monday.times : [] },
                            "tuesday": { "active": isTuesdayActive, "times": isTuesdayActive ? schedule.tuesday.times : [] },
                            "wednesday": { "active": isWednesdayActive, "times": isWednesdayActive ? schedule.wednesday.times : [] },
                            "thursday": { "active": isThursdayActive, "times": isThursdayActive ? schedule.thursday.times : [] },
                            "friday": { "active": isFridayActive, "times": isFridayActive ? schedule.friday.times : [] },
                            "saturday": { "active": isSaturdayActive, "times": isSaturdayActive ? schedule.saturday.times : [] },
                        },
                        "dateOverrides": dateOverrides
                    },
                    "schedulabel": {
                        "startDate": betweenDays[0].startDate,
                        "endDate": infiniteDateRange ? null : betweenDays[0].endDate,
                        "selection": straightRollingDays ? "straightRollingDays" : infiniteDateRange ? "infiniteDays" : "withinDateRange"
                    }
                },
                "beforeEventTime": eventGroupBeforeTime,
                "afterEventTime": eventGroupAfterTime,
                "beforeEventTimeActive": eventGroupBeforeTimeActive,
                "afterEventTimeActive": eventGroupAfterTimeActive,
                "stopSchedule": eventGroupAfterTime,
                "stopBookingBeforeTime": { "type": stopBookingTime[0], "value": stopBookingTime[1] },
                "inviteeQuestions": { "data": groupQuestions, "required": isDescriptionRequired },
                "secretEvent": groupSecretEvent,
                "lastEdited": dayjs().format("h:mm:ssa D MMM YYYY")
            }
            const eventEdit = await axios.post(serverUrl + "/events/editEventNow", query)
            if (eventEdit.status === 200) {
                // setCustomAlertBox(true);
                // setAlertBoxMessages("Event Updated Successfully.");
                // setAlertErrorType("success");

                setViewToast(true);
                popupAlert.play();
                setToastTitle("Successful");
                setToastBody("Event Updated Successfully");
                setToastMessageType("success");

                setWhenEvent(false);
                setWhatEvent(false);

                setTimeout(() => {
                    load1.disabled = false;
                    load1.innerHTML = 'Update';

                    load2.disabled = false;
                    load2.innerHTML = 'Update';

                    window.location.href = `/edit/${customEventLink}`;
                }, 1000);
            } else {
                // setCustomAlertBox(true);
                // setAlertBoxMessages("Error while updating the Event...!");
                // setAlertErrorType("invalid");

                setViewToast(true);
                popupAlert.play();
                setToastTitle("Error");
                setToastBody("Error while updating the event.");
                setToastMessageType("invalid");

                load1.disabled = false;
                load1.innerHTML = 'Update';

                load2.disabled = false;
                load2.innerHTML = 'Update';
            }
        }
    }

    const responceGoogle = response => {
        // console.log(response);
        axios.post(serverUrl + "/events/create-tokens", { "email": user.email, "code": response.code })
            .then(response => {
                // console.log(response.data);

                if (response.data.myToken) {
                    setMyRefreshToken(response.data.myToken);
                }

                axios.post(serverUrl + "/storeUser", response).then(response => {
                    // console.log(response.data);
                })

                if (response.data.allTokens.refresh_token) {
                    // console.log("New Refresh Token has Recieved..!");
                    axios.post(serverUrl + "/events/saveNewRefreshToken", { "email": user.email, "token": response.data.allTokens.refresh_token })
                        .then(response => {
                            // console.log(response.data.refreshTokens[0].token);
                            setNeedToken(false);
                            setMyRefreshToken(response.data.refreshTokens[0].token);
                        }).catch(err => {
                            // console.log(err);
                        });
                }
                setSignedIn(true)
            })
            .catch((error) => { console.log(error.message) })
    }

    const responseError = error => {
        // console.log(error)
    }

    const putOneToOneDateToStart = (e) => {
        if (isNaN(e.target.innerHTML)) {
            // alert("not a number");
        }
        else {
            const vgh = document.getElementsByClassName("selectedDayTabOneOnOne");
            if (vgh.length) {
                for (let x = 0; x < vgh.length; x++) {
                    vgh[x].classList.remove("selectedDayTabOneOnOne");
                }

                e.target.classList.add("selectedDayTabOneOnOne");
            }
            else {
                e.target.classList.add("selectedDayTabOneOnOne");
            }

            // console.log(e.target)
            const time = parseInt(e.target.id.replace("id_", ""));
            setOneToOneDate(dayjs(time).format("D MMMM YYYY"))
        }
    }

    const putTimeToOneToOneMeetingList = (date, time) => {
        // console.log(date)
        const container = document.querySelector(`#oneToOneTimes`);

        let resultFound = false;

        if (Object.keys(oneToOneDates).includes(date)) {
            resultFound = true;
        }
        if (resultFound) {
            // console.log("adding");
            if (oneToOneDates[date].includes(time)) {
                // console.log("already Added this time");
            }
            else {
                const existingContainer = document.querySelector(`#id_${date.replaceAll(" ", "")}`)

                const timeButtons = document.createElement("button");
                timeButtons.setAttribute("id", "time_" + time);
                timeButtons.setAttribute("title", "click to delete this time");
                timeButtons.classList.add("timeButton");
                timeButtons.innerHTML = time;

                timeButtons.addEventListener("click", (e) => {
                    const deletedTime = e.target.innerHTML;
                    let dates = oneToOneDates;
                    dates[date] = dates[date].filter(time => time !== deletedTime);

                    if (dates[date].length < 1) {
                        delete dates[date];
                        existingContainer.remove();
                    }
                    e.target.remove();
                    // console.table(dates);
                })

                existingContainer.childNodes[1].appendChild(timeButtons);
                container.appendChild(existingContainer);

                let dates = oneToOneDates;
                dates[date].push(time);
                setOneToOneDates(dates);


                // console.table(dates);
            }
        }
        else {
            // console.log("creating");
            const newContainer = document.createElement("div");
            newContainer.setAttribute("id", `id_${date.replaceAll(" ", "")}`);
            newContainer.classList.add("dateContainer");
            newContainer.classList.add("rounded");

            const heading = document.createElement("h1");
            const headingText = document.createTextNode(date);
            heading.appendChild(headingText);

            const buttonHolders = document.createElement("div");
            buttonHolders.classList.add("timeButtonHolders");


            const timeButtons = document.createElement("button");
            timeButtons.setAttribute("id", "time_" + time);
            timeButtons.setAttribute("title", "click to delete this time");
            timeButtons.classList.add("timeButton");
            timeButtons.innerHTML = time;

            timeButtons.addEventListener("click", (e) => {
                const deletedTime = e.target.innerHTML;
                let dates = oneToOneDates;
                dates[date] = dates[date].filter(time => time !== deletedTime);

                if (dates[date].length < 1) {
                    delete dates[date];
                    newContainer.remove();
                }

                e.target.remove();
                // console.table(dates);
            })

            buttonHolders.appendChild(timeButtons);

            newContainer.appendChild(heading);
            newContainer.appendChild(buttonHolders);

            container.appendChild(newContainer);

            let dates = oneToOneDates;

            dates[date] = [time];

            setOneToOneDates(dates);
        }
        // console.table(oneOffDates)
    }

    const putRoundRobinDateToStart = (e) => {
        if (isNaN(e.target.innerHTML)) {
            // alert("not a number");
        }
        else {
            const vgh = document.getElementsByClassName("selectedDayTabRoundRobin");
            if (vgh.length) {
                for (let x = 0; x < vgh.length; x++) {
                    vgh[x].classList.remove("selectedDayTabRoundRobin");
                }

                e.target.classList.add("selectedDayTabRoundRobin");
            }
            else {
                e.target.classList.add("selectedDayTabRoundRobin");
            }
            // console.log(e.target)
            const time = parseInt(e.target.id.replace("id_", ""));
            setRoundRobinDate(dayjs(time).format("D MMMM YYYY"))
        }
    }

    const putTimeToRoundRobinTimesMeetingList = (date, time) => {
        // console.log(date)
        const container = document.querySelector(`#roundRobinTimes`);

        let resultFound = false;

        if (Object.keys(roundRobinDates).includes(date)) {
            resultFound = true;
        }
        if (resultFound) {
            // console.log("adding");
            if (roundRobinDates[date].includes(time)) {
                // console.log("already Added this time");
            }
            else {
                const existingContainer = document.querySelector(`#id_${date.replaceAll(" ", "")}`)

                const timeButtons = document.createElement("button");
                timeButtons.setAttribute("id", "time_" + time);
                timeButtons.setAttribute("title", "click to delete this time");
                timeButtons.classList.add("timeButton");
                timeButtons.innerHTML = time;

                timeButtons.addEventListener("click", (e) => {
                    const deletedTime = e.target.innerHTML;
                    let dates = roundRobinDates;
                    dates[date] = dates[date].filter(time => time !== deletedTime);

                    if (dates[date].length < 1) {
                        delete dates[date];
                        existingContainer.remove();
                    }
                    e.target.remove();
                    // console.table(dates);
                })

                existingContainer.childNodes[1].appendChild(timeButtons);
                container.appendChild(existingContainer);

                let dates = roundRobinDates;
                dates[date].push(time);
                setRoundRobinDates(dates);


                // console.table(dates);
            }
        }
        else {
            // console.log("creating");
            const newContainer = document.createElement("div");
            newContainer.setAttribute("id", `id_${date.replaceAll(" ", "")}`);
            newContainer.classList.add("dateContainer");
            newContainer.classList.add("rounded");

            const heading = document.createElement("h1");
            const headingText = document.createTextNode(date);
            heading.appendChild(headingText);

            const buttonHolders = document.createElement("div");
            buttonHolders.classList.add("timeButtonHolders");


            const timeButtons = document.createElement("button");
            timeButtons.setAttribute("id", "time_" + time);
            timeButtons.setAttribute("title", "click to delete this time");
            timeButtons.classList.add("timeButton");
            timeButtons.innerHTML = time;

            timeButtons.addEventListener("click", (e) => {
                const deletedTime = e.target.innerHTML;
                let dates = roundRobinDates;
                dates[date] = dates[date].filter(time => time !== deletedTime);

                if (dates[date].length < 1) {
                    delete dates[date];
                    newContainer.remove();
                }

                e.target.remove();
                // console.table(dates);
            })

            buttonHolders.appendChild(timeButtons);

            newContainer.appendChild(heading);
            newContainer.appendChild(buttonHolders);

            container.appendChild(newContainer);

            let dates = roundRobinDates;

            dates[date] = [time];

            setRoundRobinDates(dates);
        }
        // console.table(oneOffDates)
    }

    const putTimeToOneOfMeetingList = (date, time) => {

        const container = document.querySelector(`#oneOffTimes`);

        let resultFound = false;

        if (Object.keys(oneOffDates).includes(date)) {
            resultFound = true;
        }
        if (resultFound) {
            // console.log("adding");
            if (oneOffDates[date].includes(time)) {
                // console.log("already Added this time");
            }
            else {
                const existingContainer = document.querySelector(`#id_${date.replaceAll(" ", "")}`)

                const timeButtons = document.createElement("button");
                timeButtons.setAttribute("id", "time_" + time);
                timeButtons.setAttribute("title", "click to delete this time");
                timeButtons.classList.add("timeButton");
                timeButtons.innerHTML = time;

                timeButtons.addEventListener("click", (e) => {
                    const deletedTime = e.target.innerHTML;
                    let dates = oneOffDates;
                    dates[date] = dates[date].filter(time => time !== deletedTime);

                    if (dates[date].length < 1) {
                        delete dates[date];
                        existingContainer.remove();
                    }

                    e.target.remove();
                    // console.table(dates);
                })

                existingContainer.childNodes[1].appendChild(timeButtons);
                container.appendChild(existingContainer);

                let dates = oneOffDates;
                dates[date].push(time);
                setOneOffDates(dates);


                // console.table(dates);
            }
        }
        else {
            // console.log("creating");
            const newContainer = document.createElement("div");
            newContainer.setAttribute("id", `id_${date.replaceAll(" ", "")}`);
            newContainer.classList.add("dateContainer");
            newContainer.classList.add("rounded");

            const heading = document.createElement("h1");
            const headingText = document.createTextNode(date);
            heading.appendChild(headingText);

            const buttonHolders = document.createElement("div");
            buttonHolders.classList.add("timeButtonHolders");


            const timeButtons = document.createElement("button");
            timeButtons.setAttribute("id", "time_" + time);
            timeButtons.setAttribute("title", "click to delete this time");
            timeButtons.classList.add("timeButton");
            timeButtons.innerHTML = time;

            timeButtons.addEventListener("click", (e) => {
                const deletedTime = e.target.innerHTML;
                let dates = oneOffDates;
                dates[date] = dates[date].filter(time => time !== deletedTime);

                if (dates[date].length < 1) {
                    delete dates[date];
                    newContainer.remove();
                }

                e.target.remove();
                // console.table(dates);
            })

            buttonHolders.appendChild(timeButtons);

            newContainer.appendChild(heading);
            newContainer.appendChild(buttonHolders);

            container.appendChild(newContainer);

            let dates = oneOffDates;

            dates[date] = [time];

            setOneOffDates(dates);
        }
        // console.table(oneOffDates)
    }

    const putOneOffDateToStart = (e) => {
        if (isNaN(e.target.innerHTML)) {
            // alert("not a number");
        }
        else {
            const vgh = document.getElementsByClassName("selectedDayTabOneOff");
            if (vgh.length) {
                for (let x = 0; x < vgh.length; x++) {
                    vgh[x].classList.remove("selectedDayTabOneOff");
                }

                e.target.classList.add("selectedDayTabOneOff");
            }
            else {
                e.target.classList.add("selectedDayTabOneOff");
            }
            // console.log(e.target)
            const time = parseInt(e.target.id.replace("id_", ""));
            setOneOffDate(dayjs(time).format("D MMMM YYYY"))
        }
    }

    const putGroupDateToStart = (e) => {
        if (isNaN(e.target.innerHTML)) {
            // alert("not a number");
        }
        else {
            const vgh = document.getElementsByClassName("selectedDayTabGroup");
            if (vgh.length) {
                for (let x = 0; x < vgh.length; x++) {
                    vgh[x].classList.remove("selectedDayTabGroup");
                }

                e.target.classList.add("selectedDayTabGroup");
            }
            else {
                e.target.classList.add("selectedDayTabGroup");
            }
            // console.log(e.target)
            const time = parseInt(e.target.id.replace("id_", ""));
            setGroupDate(dayjs(time).format("D MMMM YYYY"))
        }
    }

    const putTimeToGroupMeetingList = (date, time) => {
        // console.log(date)
        const container = document.querySelector(`#groupTimes`);

        let resultFound = false;

        if (Object.keys(groupDates).includes(date)) {
            resultFound = true;
        }
        if (resultFound) {
            // console.log("adding");
            if (groupDates[date].includes(time)) {
                // console.log("already Added this time");
            }
            else {
                const existingContainer = document.querySelector(`#id_${date.replaceAll(" ", "")}`)

                const timeButtons = document.createElement("button");
                timeButtons.setAttribute("id", "time_" + time);
                timeButtons.setAttribute("title", "click to delete this time");
                timeButtons.classList.add("timeButton");
                timeButtons.innerHTML = time;

                timeButtons.addEventListener("click", (e) => {
                    const deletedTime = e.target.innerHTML;
                    let dates = groupDates;
                    dates[date] = dates[date].filter(time => time !== deletedTime);

                    if (dates[date].length < 1) {
                        delete dates[date];
                        existingContainer.remove();
                    }
                    e.target.remove();
                    // console.table(dates);
                })

                existingContainer.childNodes[1].appendChild(timeButtons);
                container.appendChild(existingContainer);

                let dates = groupDates;
                dates[date].push(time);
                setGroupDates(dates);


                // console.table(dates);
            }
        }
        else {
            // console.log("creating");
            const newContainer = document.createElement("div");
            newContainer.setAttribute("id", `id_${date.replaceAll(" ", "")}`);
            newContainer.classList.add("dateContainer");
            newContainer.classList.add("rounded");

            const heading = document.createElement("h1");
            const headingText = document.createTextNode(date);
            heading.appendChild(headingText);

            const buttonHolders = document.createElement("div");
            buttonHolders.classList.add("timeButtonHolders");


            const timeButtons = document.createElement("button");
            timeButtons.setAttribute("id", "time_" + time);
            timeButtons.setAttribute("title", "click to delete this time");
            timeButtons.classList.add("timeButton");
            timeButtons.innerHTML = time;

            timeButtons.addEventListener("click", (e) => {
                const deletedTime = e.target.innerHTML;
                let dates = groupDates;
                dates[date] = dates[date].filter(time => time !== deletedTime);

                if (dates[date].length < 1) {
                    delete dates[date];
                    newContainer.remove();
                }

                e.target.remove();
                // console.table(dates);
            })

            buttonHolders.appendChild(timeButtons);

            newContainer.appendChild(heading);
            newContainer.appendChild(buttonHolders);

            container.appendChild(newContainer);

            let dates = groupDates;

            dates[date] = [time];

            setGroupDates(dates);
        }
        // console.table(oneOffDates)
    }

    const convertToMinutesOneOff = (time) => {
        if (time) {
            if (durationType === "hours") {
                setOneOffDuration(Math.floor(parseInt(time)) * 60);
                // console.log(Math.floor(parseInt(time)) * 60)
            } else {
                setOneOffDuration(Math.floor(parseInt(time)));
                // console.log(Math.floor(parseInt(time)))
            }
        }
        else {
            setOneOffDuration(15)
        }
    }

    const convertToMinutesGroup = (time) => {
        if (time) {
            if (durationType === "hours") {
                setGroupDuration(Math.floor(parseInt(time)) * 60);
                // console.log(Math.floor(parseInt(time)) * 60)
            } else {
                setGroupDuration(Math.floor(parseInt(time)));
                // console.log(Math.floor(parseInt(time)))
            }
        }
        else {
            setGroupDuration(15)
        }
    }

    const convertToMinutesOneOnOne = (time) => {
        if (time) {
            if (durationType === "hours") {
                setDuration(Math.floor(parseInt(time)) * 60);
                // console.log(Math.floor(parseInt(time)) * 60)
            } else {
                setDuration(Math.floor(parseInt(time)));
                // console.log(Math.floor(parseInt(time)))
            }
        }
        else {
            setDuration(15)
        }
    }


    const removeThisTimeNow = (mainContainer, element, dateList, type, givenDate, givenTime) => {

        dateList[givenDate] = dateList[givenDate].filter(time => time !== givenTime);

        if (dateList[givenDate].length < 1) {
            delete dateList[givenDate];
            document.getElementById(mainContainer).style.display = "none";

            // console.log("removed container")
        }

        element.target.remove();

        if (type === "One on One") {
            setOneToOneDates(dateList);
        } else if (type === "Round Robin") {
            setRoundRobinDates(dateList);
        } else if (type === "Group") {
            setGroupDates(dateList);
        } else {
            setOneOffDates(dateList);
        }

        // console.log("deleted");
    }

    const toogleScheduleSet = async (scheduleName) => {

        setIsSundayActive(false);
        setIsMondayActive(false);
        setIsTuesdayActive(false);
        setIsWednesdayActive(false);
        setIsThursdayActive(false);
        setIsFridayActive(false);
        setIsSaturdayActive(false);

        setTimeout(() => {

            const countryFromSchedule = countriesList.filter((countryNames) => { return countryNames.value === userSchedules[scheduleName].timezone.value.countries[0] });

            setCountrySchedule(countryFromSchedule);

            const rest = timezonesList.filter((zone) => { return zone.value.countries.includes(countryFromSchedule[0].value); });
            setFilteredTimezonesListSchedule(rest);

            setSelectedTimezone(userSchedules[scheduleName].timezone);

            // setUserSchedules(userSchedules);
            setSchedule(userSchedules[scheduleName].days);

            setDateOverrides(userSchedules[scheduleName].dateOverrides);
            setIsSundayActive(userSchedules[scheduleName].days.sunday.active);
            setIsMondayActive(userSchedules[scheduleName].days.monday.active);
            setIsTuesdayActive(userSchedules[scheduleName].days.tuesday.active);
            setIsWednesdayActive(userSchedules[scheduleName].days.wednesday.active);
            setIsThursdayActive(userSchedules[scheduleName].days.thursday.active);
            setIsFridayActive(userSchedules[scheduleName].days.friday.active);
            setIsSaturdayActive(userSchedules[scheduleName].days.saturday.active);


            // setSundayStartTime(user.userSchedules[scheduleName].days.sunday.times.split("-")[0]);
            // setSundayEndTime(user.userSchedules[scheduleName].days.sunday.times.split("-")[1]);
            // setMondayStartTime(user.userSchedules[scheduleName].days.monday.times.split("-")[0]);
            // setMondayEndTime(user.userSchedules[scheduleName].days.monday.times.split("-")[1]);
            // setTuesdayStartTime(user.userSchedules[scheduleName].days.tuesday.times.split("-")[0]);
            // setTuesdayEndTime(user.userSchedules[scheduleName].days.tuesday.times.split("-")[1]);
            // setWednesdayStartTime(user.userSchedules[scheduleName].days.wednesday.times.split("-")[0]);
            // setWednesdayEndTime(user.userSchedules[scheduleName].days.wednesday.times.split("-")[1]);
            // setThursdayStartTime(user.userSchedules[scheduleName].days.thursday.times.split("-")[0]);
            // setThursdayEndTime(user.userSchedules[scheduleName].days.thursday.times.split("-")[1]);
            // setFridayStartTime(user.userSchedules[scheduleName].days.friday.times.split("-")[0]);
            // setFridayEndTime(user.userSchedules[scheduleName].days.friday.times.split("-")[1]);
            // setSaturdayStartTime(user.userSchedules[scheduleName].days.saturday.times.split("-")[0]);
            // setSaturdayEndTime(user.userSchedules[scheduleName].days.saturday.times.split("-")[1]);

            setScheduleName(scheduleName);
        }, 300);

        setScheduleIdentifier(userSchedules[scheduleName].identifier)
    }

    const updateNewSchedule = async (e, type) => {
        e.preventDefault();
        // console.log(userSchedules);

        let load = document.getElementById("newSchedulePopup");



        switch (type) {
            case "OneToOne":
                load = document.querySelector("#saveSchedule1");
                break;
            case "Group":
                load = document.querySelector("#saveSchedule2");
                break;
            case "RoundRobin":
                load = document.querySelector("#saveSchedule3");
                break;
        }

        load.disabled = true;
        load.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>Loading..';


        let currentScheduleName = null;

        if (type === "model") {
            currentScheduleName = newScheduleName;
        } else {
            currentScheduleName = scheduleName;
        }


        let existing = false;
        let isDefault = false;
        let allSchedules = userSchedules;

        const sundayTabsStart = document.getElementsByClassName("sundayStartTabs" + type);
        const sundayTabsEnd = document.getElementsByClassName("sundayEndTabs" + type);

        const mondayTabsStart = document.getElementsByClassName("mondayStartTabs" + type);
        const mondayTabsEnd = document.getElementsByClassName("mondayEndTabs" + type);

        const tuesdayTabsStart = document.getElementsByClassName("tuesdayStartTabs" + type);
        const tuesdayTabsEnd = document.getElementsByClassName("tuesdayEndTabs" + type);

        const wednesdayTabsStart = document.getElementsByClassName("wednesdayStartTabs" + type);
        const wednesdayTabsEnd = document.getElementsByClassName("wednesdayEndTabs" + type);

        const thursdayTabsStart = document.getElementsByClassName("thursdayStartTabs" + type);
        const thursdayTabsEnd = document.getElementsByClassName("thursdayEndTabs" + type);

        const fridayTabsStart = document.getElementsByClassName("fridayStartTabs" + type);
        const fridayTabsEnd = document.getElementsByClassName("fridayEndTabs" + type);

        const saturdayTabsStart = document.getElementsByClassName("saturdayStartTabs" + type);
        const saturdayTabsEnd = document.getElementsByClassName("saturdayEndTabs" + type);



        let sundayArray = [];
        let mondayArray = [];
        let tuesdayArray = [];
        let wednesdayArray = [];
        let thursdayArray = [];
        let fridayArray = [];
        let saturdayArray = [];


        let lengthSunday = sundayTabsStart.length;
        for (let i = 0; i < lengthSunday; i++) {
            sundayArray.push(sundayTabsStart[i].value + "-" + sundayTabsEnd[i].value);
        }

        let lengthMonday = mondayTabsStart.length;
        for (let i = 0; i < lengthMonday; i++) {
            mondayArray.push(mondayTabsStart[i].value + "-" + mondayTabsEnd[i].value);
        }

        let lengthTuesday = tuesdayTabsStart.length;
        for (let i = 0; i < lengthTuesday; i++) {
            tuesdayArray.push(tuesdayTabsStart[i].value + "-" + tuesdayTabsEnd[i].value);
        }

        let lengthWednesday = wednesdayTabsStart.length;
        for (let i = 0; i < lengthWednesday; i++) {
            wednesdayArray.push(wednesdayTabsStart[i].value + "-" + wednesdayTabsEnd[i].value);
        }

        let lengthThursday = thursdayTabsStart.length;
        for (let i = 0; i < lengthThursday; i++) {
            thursdayArray.push(thursdayTabsStart[i].value + "-" + thursdayTabsEnd[i].value);
        }

        let lengthFriday = fridayTabsStart.length;
        for (let i = 0; i < lengthFriday; i++) {
            fridayArray.push(fridayTabsStart[i].value + "-" + fridayTabsEnd[i].value);
        }

        let lengthSaturday = saturdayTabsStart.length;
        for (let i = 0; i < lengthSaturday; i++) {
            saturdayArray.push(saturdayTabsStart[i].value + "-" + saturdayTabsEnd[i].value);
        }



        let sundaySubset = [];
        let mondaySubset = [];
        let tuesdaySubset = [];
        let wednesdaySubset = [];
        let thursdaySubset = [];
        let fridaySubset = [];
        let saturdaySubset = [];

        let dateOverridesSubnet = {};

        sundayArray.map((data, key) => {
            const times = data.split("-");
            const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
            const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
            const combineString = `${timeF1}-${timeF2}`;
            return sundaySubset.push(combineString);
        })
        mondayArray.map((data, key) => {
            const times = data.split("-");
            const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
            const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
            const combineString = `${timeF1}-${timeF2}`;
            return mondaySubset.push(combineString);
        })
        tuesdayArray.map((data, key) => {
            const times = data.split("-");
            const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
            const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
            const combineString = `${timeF1}-${timeF2}`;
            return tuesdaySubset.push(combineString);
        })
        wednesdayArray.map((data, key) => {
            const times = data.split("-");
            const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
            const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
            const combineString = `${timeF1}-${timeF2}`;
            return wednesdaySubset.push(combineString);
        })
        thursdayArray.map((data, key) => {
            const times = data.split("-");
            const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
            const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
            const combineString = `${timeF1}-${timeF2}`;
            return thursdaySubset.push(combineString);
        })
        fridayArray.map((data, key) => {
            const times = data.split("-");
            const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
            const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
            const combineString = `${timeF1}-${timeF2}`;
            return fridaySubset.push(combineString);
        })
        saturdayArray.map((data, key) => {
            const times = data.split("-");
            const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
            const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
            const combineString = `${timeF1}-${timeF2}`;
            return saturdaySubset.push(combineString);
        })

        if (dateOverrides) {

            Object.keys(dateOverrides).map((data, key) => {
                const origionalTimeSet = dateOverrides[data].timeSet;
                let subnetForNow = [];

                origionalTimeSet.map((data, key) => {
                    const times = data.split("-");
                    const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
                    const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
                    const combineString = `${timeF1}-${timeF2}`;
                    return subnetForNow.push(combineString);
                })

                dateOverridesSubnet[data] = {
                    "active": dateOverrides[data].active,
                    "date": dateOverrides[data].date,
                    "timeSet": subnetForNow,
                    "uid": dateOverrides[data].uid,
                    "sortingNumber": dateOverrides[data].sortingNumber
                }

            })
        }


        setSchedule(
            {
                "sunday": { "active": isSundayActive, "times": isSundayActive ? sundayArray.length === 0 ? ["9:00am-5:00pm"] : sundaySubset : [] },
                "monday": { "active": isMondayActive, "times": isMondayActive ? mondayArray.length === 0 ? ["9:00am-5:00pm"] : mondaySubset : [] },
                "tuesday": { "active": isTuesdayActive, "times": isTuesdayActive ? tuesdayArray.length === 0 ? ["9:00am-5:00pm"] : tuesdaySubset : [] },
                "wednesday": { "active": isWednesdayActive, "times": isWednesdayActive ? wednesdayArray.length === 0 ? ["9:00am-5:00pm"] : wednesdaySubset : [] },
                "thursday": { "active": isThursdayActive, "times": isThursdayActive ? thursdayArray.length === 0 ? ["9:00am-5:00pm"] : thursdaySubset : [] },
                "friday": { "active": isFridayActive, "times": isFridayActive ? fridayArray.length === 0 ? ["9:00am-5:00pm"] : fridaySubset : [] },
                "saturday": { "active": isSaturdayActive, "times": isSaturdayActive ? saturdayArray.length === 0 ? ["9:00am-5:00pm"] : saturdaySubset : [] },
            }
        );

        Object.keys(userSchedules).map((name, key) => {
            if (currentScheduleName === name) {
                return existing = true;
            }
            return
        });

        if (existing) {

            Object.keys(userSchedules).map((name, key) => {
                if (currentScheduleName !== name) {
                    allSchedules[name].inUse = false;
                } else {
                    if (allSchedules[name].default === true) {
                        isDefault = true;
                    }
                }
                return
            });

            allSchedules[currentScheduleName] = {
                "inUse": true,
                "default": isDefault,
                "identifier": allSchedules[currentScheduleName].identifier,
                "dateOverrides": dateOverridesSubnet,
                "timezone": selectedTimezone,
                "days": {
                    "sunday": { "active": isSundayActive, "times": isSundayActive ? sundayArray.length === 0 ? ["9:00am-5:00pm"] : sundaySubset : [] },
                    "monday": { "active": isMondayActive, "times": isMondayActive ? mondayArray.length === 0 ? ["9:00am-5:00pm"] : mondaySubset : [] },
                    "tuesday": { "active": isTuesdayActive, "times": isTuesdayActive ? tuesdayArray.length === 0 ? ["9:00am-5:00pm"] : tuesdaySubset : [] },
                    "wednesday": { "active": isWednesdayActive, "times": isWednesdayActive ? wednesdayArray.length === 0 ? ["9:00am-5:00pm"] : wednesdaySubset : [] },
                    "thursday": { "active": isThursdayActive, "times": isThursdayActive ? thursdayArray.length === 0 ? ["9:00am-5:00pm"] : thursdaySubset : [] },
                    "friday": { "active": isFridayActive, "times": isFridayActive ? fridayArray.length === 0 ? ["9:00am-5:00pm"] : fridaySubset : [] },
                    "saturday": { "active": isSaturdayActive, "times": isSaturdayActive ? saturdayArray.length === 0 ? ["9:00am-5:00pm"] : saturdaySubset : [] },
                }
            }
        } else {

            Object.keys(userSchedules).map((name, key) => {
                if (currentScheduleName !== name) {
                    allSchedules[name].inUse = false;
                } else {
                    if (allSchedules[name].default === true) {
                        isDefault = true;
                    }
                }
                return
            });

            allSchedules[currentScheduleName] = {
                "inUse": true,
                "default": isDefault,
                "identifier": uuidv4(),
                "dateOverrides": dateOverridesSubnet,
                "timezone": selectedTimezone,
                "days": {
                    "sunday": { "active": isSundayActive, "times": isSundayActive ? sundayArray.length === 0 ? ["9:00am-5:00pm"] : sundaySubset : [] },
                    "monday": { "active": isMondayActive, "times": isMondayActive ? mondayArray.length === 0 ? ["9:00am-5:00pm"] : mondaySubset : [] },
                    "tuesday": { "active": isTuesdayActive, "times": isTuesdayActive ? tuesdayArray.length === 0 ? ["9:00am-5:00pm"] : tuesdaySubset : [] },
                    "wednesday": { "active": isWednesdayActive, "times": isWednesdayActive ? wednesdayArray.length === 0 ? ["9:00am-5:00pm"] : wednesdaySubset : [] },
                    "thursday": { "active": isThursdayActive, "times": isThursdayActive ? thursdayArray.length === 0 ? ["9:00am-5:00pm"] : thursdaySubset : [] },
                    "friday": { "active": isFridayActive, "times": isFridayActive ? fridayArray.length === 0 ? ["9:00am-5:00pm"] : fridaySubset : [] },
                    "saturday": { "active": isSaturdayActive, "times": isSaturdayActive ? saturdayArray.length === 0 ? ["9:00am-5:00pm"] : saturdaySubset : [] },
                }
            }
        }

        const updateScheduleList = await axios.post(serverUrl + "/users/updateScheduleList", { "newSchedualData": allSchedules, "email": user.email });
        if (updateScheduleList.status === 200) {
            // setCustomAlertBox(true);
            // setAlertBoxMessages(updateScheduleList.data);
            // setAlertErrorType("success");

            setViewToast(true);
            popupAlert.play();
            setToastTitle("Successful");
            setToastBody(updateScheduleList.data);
            setToastMessageType("success");


            setNewScheduleName("");
            setCreateNewSchedule(false);

            if (allSchedules) {
                let listSchedules = []

                Object.keys(allSchedules).map((name, key) => {
                    return listSchedules.push({ label: name, value: name })
                })
                setUserScheduleListForSelect(listSchedules);
            }


            // if (type === "OneToOne") {
            //     handleSubmit(e);
            // } else if (type === "Group") {
            //     updateGroupMeeting(e);
            // } else if (type === "RoundRobin") {
            //     updateRoundRobinEvent(e);
            // } else {
            //     handleSubmit(e);
            // }

        } else {
            // setCustomAlertBox(true);
            // setAlertBoxMessages(updateScheduleList.data);
            // setAlertErrorType("invalid");
            setViewToast(true);
            popupAlert.play();
            setToastTitle("Error");
            setToastBody(updateScheduleList.data);
            setToastMessageType("invalid");
        }

        load.disabled = false;
        load.innerHTML = 'Save Schedule';

    }

    const creatingNewDefaultSchedule = async (e) => {
        e.preventDefault();
        let currentScheduleName = newScheduleName;

        let existing = false;
        let isDefault = false;
        let allSchedules = userSchedules;


        Object.keys(userSchedules).map((name, key) => {
            if (currentScheduleName === name) {
                return existing = true;
            }
            return
        });

        if (existing) {
            Object.keys(userSchedules).map((name, key) => {
                if (currentScheduleName !== name) {
                    allSchedules[name].inUse = false;
                } else {
                    if (allSchedules[name].default === true) {
                        isDefault = true;
                    }
                }
                return
            });

            allSchedules[currentScheduleName] = {
                "inUse": true,
                "default": isDefault,
                "identifier": allSchedules[currentScheduleName].identifier,
                "dateOverrides": allSchedules[currentScheduleName].dateOverrides,
                "timezone": allSchedules[currentScheduleName].timezone,
                "days": allSchedules[currentScheduleName].days
            }

        } else {
            Object.keys(userSchedules).map((name, key) => {
                if (currentScheduleName !== name) {
                    allSchedules[name].inUse = false;
                } else {
                    if (allSchedules[name].default === true) {
                        isDefault = true;
                    }
                }
                return
            });

            allSchedules[currentScheduleName] = {
                "inUse": true,
                "identifier": uuidv4(),
                "default": isDefault,
                "dateOverrides": {},
                "timezone": user.settings.selectedTimezone,
                "days": {
                    "sunday": { "active": false, "times": [] },
                    "monday": { "active": true, "times": ["9:00am-5:00pm"] },
                    "tuesday": { "active": true, "times": ["9:00am-5:00pm"] },
                    "wednesday": { "active": true, "times": ["9:00am-5:00pm"] },
                    "thursday": { "active": true, "times": ["9:00am-5:00pm"] },
                    "friday": { "active": true, "times": ["9:00am-5:00pm"] },
                    "saturday": { "active": false, "times": [] },
                }
            }

        }


        const updateScheduleList = await axios.post(serverUrl + "/users/updateScheduleList", { "newSchedualData": allSchedules, "email": user.email });
        if (updateScheduleList.status === 200) {
            setViewToast(true);
            popupAlert.play();
            setToastTitle("Successful");
            setToastBody(updateScheduleList.data);
            setToastMessageType("success");

            setCreateNewSchedule(false);
            setNewScheduleName("");

            // console.log(allSchedules[currentScheduleName]);

            setUserSchedules(allSchedules);

            toogleScheduleSet(currentScheduleName);

            if (allSchedules) {
                let listSchedules = []

                Object.keys(allSchedules).map((name, key) => {
                    return listSchedules.push({ label: name, value: name })
                })
                setUserScheduleListForSelect(listSchedules);
            }


            // setCustomAlertBox(true);
            // setAlertBoxMessages(updateScheduleList.data);
            // setAlertErrorType("success");
        } else {
            setViewToast(true);
            popupAlert.play();
            setToastTitle("Error");
            setToastBody(updateScheduleList.data);
            setToastMessageType("invalid");

            // setCustomAlertBox(true);
            // setAlertBoxMessages(updateScheduleList.data);
            // setAlertErrorType("invalid");
        }

    }

    const startDeleteSchedule = async (scheduleTitle) => {
        const deleteScheduleName = await axios.post(serverUrl + "/users/deleteSchedule", { "email": user.email, "scheduleName": scheduleTitle });
        if (deleteScheduleName.status === 200) {
            // console.log(deleteScheduleName.data)
            setUserSchedules(deleteScheduleName.data);

            let allSchedules = deleteScheduleName.data;

            if (allSchedules) {
                let listSchedules = []

                Object.keys(allSchedules).map((name, key) => {
                    return listSchedules.push({ label: name, value: name })
                })
                setUserScheduleListForSelect(listSchedules);
            }

            Object.keys(allSchedules).map((scheduleName, key) => {
                if (allSchedules[scheduleName].default) {
                    return toogleScheduleSet(scheduleName);
                    // setScheduleName(scheduleName);
                    // return setSchedule(allSchedules[scheduleName].days);
                }
                else {
                    return <></>
                }
            })


            // setCustomAlertBox(true);
            // setAlertBoxMessages("Schedule Deleted Successfully.");
            // setAlertErrorType("success");

            setCustomAlertBox(false);

            setViewToast(true);
            popupAlert.play();
            setToastTitle("Successful");
            setToastBody("Schedule Deleted Successfully.");
            setToastMessageType("success");

            // console.log(deleteScheduleName.data);
        } else {
            // setCustomAlertBox(true);
            // setAlertBoxMessages(deleteScheduleName.data);
            // setAlertErrorType("invalid");

            setViewToast(true);
            popupAlert.play();
            setToastTitle("Error");
            setToastBody(deleteScheduleName.data);
            setToastMessageType("invalid");

            // console.log(deleteScheduleName.data);
        }
    }

    const putOverrideDateToStart = (e) => {
        if (isNaN(e.target.innerHTML)) {
            // alert("not a number");
        }
        else {
            const vgh = document.getElementsByClassName("selectedDayTabOneOff");
            if (vgh.length) {
                for (let x = 0; x < vgh.length; x++) {
                    vgh[x].classList.remove("selectedDayTabOneOff");
                }

                e.target.classList.add("selectedDayTabOneOff");
            }
            else {
                e.target.classList.add("selectedDayTabOneOff");
            }
            // console.log(e.target)
            const time = parseInt(e.target.id.replace("id_", ""));
            setCurrntOverrideDateSelected(e.target.id);
            setOverrideDate(dayjs(time).format("D MMMM YYYY"))
        }
    }

    const startAddingTheOverride = async (e) => {
        e.preventDefault();


        const overrideStartTabs = document.getElementsByClassName("overrideStartTabs");
        const overrideEndTabs = document.getElementsByClassName("overrideEndTabs");


        let lengthStart = overrideStartTabs.length;

        let overrideArrayList = [];

        for (let i = 0; i < lengthStart; i++) {
            overrideArrayList.push(overrideStartTabs[i].value + "-" + overrideEndTabs[i].value);
        }

        // console.log(overrideArrayList);

        setOverrideArray(overrideArrayList);

        let allOverrides = dateOverrides;


        if (!allOverrides) {
            allOverrides = {};
        }


        allOverrides[overrideDate.toISOString()] = { "active": overrideDateEnabled, "date": overrideDate?.toISOString(), "timeSet": overrideArrayList, "uid": uuidv4(), "sortingNumber": Date.parse(overrideDate) };

        let arrCOnv = Object.entries(allOverrides)
        arrCOnv.sort((a, b) => a[1].sortingNumber - b[1].sortingNumber);

        let rebirth = Object.fromEntries(arrCOnv);

        setDateOverrides(rebirth);




        const vgh = document.getElementsByClassName("selectedDayTabOneOff");
        if (vgh.length) {
            for (let x = 0; x < vgh.length; x++) {
                vgh[x].classList.remove("selectedDayTabOneOff");
            }
        }



        setViewDateOverridePanel(false);
        setOverrideDate(null);
        setOverrideDateEnabled(false);
        setOverrideEndTime(user.settings.timeFormat.value !== "12 Hours" ? "17:00" : "5:00pm");
        setOverrideStartTime(user.settings.timeFormat.value !== "12 Hours" ? "09:00" : "9:00am");
    }

    const deleteTimeSetOverride = (type, uid) => {
        document.getElementById(uid).remove();
        let isEverythingOk = true;

        const allTabs = document.getElementsByClassName("overrideEndTabs");

        if (allTabs.length < 1) {
            setOverrideDateEnabled(false);
        } else {
            let getElemts1 = document.getElementsByClassName("overrideStartTabs");
            let getElemts2 = document.getElementsByClassName("overrideEndTabs");


            let length = getElemts1.length;


            for (let i = 0; i < length; i++) {
                if (getElemts1[i].value.length >= 6 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                    if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                        // #########################################################
                        let allStartTabs = [];

                        for (let i = 0; i < length; i++) {
                            allStartTabs.push(getElemts1[i].value);
                        }

                        allStartTabs.map((startT, key) => {
                            function getAllIndexes(arr, val) {
                                var indexes = [], i = -1;
                                while ((i = arr.indexOf(val, i + 1)) != -1) {
                                    indexes.push(i);
                                }
                                return indexes;
                            }

                            var indexes = getAllIndexes(allStartTabs, startT);

                            if (indexes.length > 1) {
                                indexes.map((name, key) => {
                                    getElemts1[name].style.border = "1px solid red";
                                    getElemts2[name].style.border = "1px solid red";
                                });
                                isEverythingOk = false;
                            } else {
                                getElemts1[i].style.border = "1px solid #ced4da";
                                getElemts2[i].style.border = "1px solid #ced4da";
                            }
                        });


                        // #########################################################
                    }
                    else {
                        getElemts1[i].style.border = "1px solid red";
                        getElemts2[i].style.border = "1px solid red";

                        isEverythingOk = false;
                    }
                } else {
                    getElemts1[i].style.border = "1px solid red";
                    getElemts2[i].style.border = "1px solid red";

                    isEverythingOk = false;
                }
            }
        }


        if (!isEverythingOk) {
            setUpdateButtonEnabled(false);
        } else {
            setUpdateButtonEnabled(true);
        }
    }

    const convertToMinutes = (time) => {
        if (durationType === "hours") {
            setWorkflowDuration(Math.floor(parseInt(time)) * 60);
            // console.log("1504 ", Math.floor(parseInt(time)) * 60);
        } else {
            setWorkflowDuration(Math.floor(parseInt(time)));
            // console.log("1507 ", Math.floor(parseInt(time)));
        }
    }

    const deleteOverrideDate = (date, uid) => {
        let allOverrides = dateOverrides;

        delete allOverrides[date];
        // console.log(allOverrides);
        setDateOverrides(allOverrides);


        setRerenderRequired(!rerenderRequired)
        // document.getElementById(uid).style.display = "none";
    }

    const addTimeSetInOverride = (type, uid) => {

        // console.log(type, uid);


        let findIndexOf = "";
        const allOverrideTimes = document.getElementsByClassName("overrideEndTabs");
        if (allOverrideTimes.length) {
            findIndexOf = allOverrideTimes[allOverrideTimes.length - 1].value
        } else {
            findIndexOf = user.settings.timeFormat.value !== "12 Hours" ? "17:00" : "5:00pm";
        }

        const last_usedIndex = timeListToMeasure.indexOf(findIndexOf);

        if (last_usedIndex === timeListToMeasure.length - 1) {
            return
        }




        if (overrideDateEnabled) {
            const uid = uuidv4();

            const mainContainer = document.createElement("div");
            mainContainer.setAttribute("class", "d-flex gap-2 align-items-center");
            mainContainer.setAttribute("id", "overrride_" + uid);

            const innerDiv1 = document.createElement("div");
            innerDiv1.setAttribute("class", "w-50 h-100");


            const inputField1 = document.createElement("input");
            inputField1.setAttribute("list", "overrideStart_" + uid);
            inputField1.setAttribute("maxlength", "7");
            inputField1.setAttribute("required", "");
            inputField1.setAttribute("class", "customInputFieldFocus w-100 smallTextNote shadow-sm overrideStartTabs");
            inputField1.setAttribute("value", timeListToMeasure[last_usedIndex]);

            inputField1.addEventListener("input", (e) => {
                filterTimeFieldNow(e);
                checkValidTiming(e, "override");
            });


            const dataList1 = document.createElement("datalist");
            dataList1.setAttribute("class", "smallTextNote");
            dataList1.setAttribute("id", "overrideStart_" + uid);

            timeListToMeasure.map((times, key) => {
                const optionsTag1 = document.createElement("option");
                optionsTag1.setAttribute("key", key);
                optionsTag1.setAttribute("value", times);

                const textNode1 = document.createTextNode(times);

                optionsTag1.appendChild(textNode1);
                return dataList1.appendChild(optionsTag1);
            });

            innerDiv1.appendChild(inputField1);
            innerDiv1.appendChild(dataList1);


            // ###############################################################


            let useIndexToCall = last_usedIndex;

            if (last_usedIndex + 2 <= timeListToMeasure.length - 1) {
                useIndexToCall = last_usedIndex + 2;
            }
            else {
                useIndexToCall = timeListToMeasure.length - 1;
            }


            const innerDiv2 = document.createElement("div");
            innerDiv2.setAttribute("class", "w-50 h-100");


            const inputField2 = document.createElement("input");
            inputField2.setAttribute("list", "overrideEnd_" + uid);
            inputField2.setAttribute("maxlength", "7");
            inputField2.setAttribute("required", "");
            inputField2.setAttribute("class", "customInputFieldFocus w-100 smallTextNote shadow-sm overrideEndTabs");
            inputField2.setAttribute("value", timeListToMeasure[useIndexToCall]);
            inputField2.addEventListener("input", (e) => {
                filterTimeFieldNow(e);
                checkValidTiming(e, "override");
            });

            const dataList2 = document.createElement("datalist");
            dataList2.setAttribute("class", "smallTextNote");
            dataList2.setAttribute("id", "overrideEnd_" + uid);

            timeListToMeasure.map((times, key) => {
                const optionsTag2 = document.createElement("option");
                optionsTag2.setAttribute("key", key);
                optionsTag2.setAttribute("value", times);

                const textNode2 = document.createTextNode(times);

                optionsTag2.appendChild(textNode2);
                return dataList2.appendChild(optionsTag2);
            });


            innerDiv2.appendChild(inputField2);
            innerDiv2.appendChild(dataList2);

            const deleteIcon = document.createElement("i");
            deleteIcon.setAttribute("class", "fa fa-trash")
            deleteIcon.setAttribute("style", "padding:0 5px");
            deleteIcon.addEventListener("click", () => {
                document.getElementById("overrride_" + uid).remove();
                const allOverrideTabs = document.getElementsByClassName("overrideEndTabs");
                if (allOverrideTabs.length < 1) {
                    setOverrideDateEnabled(false);
                }
                checkOverridesTimesCorrect();
            })


            mainContainer.appendChild(innerDiv1);
            mainContainer.appendChild(innerDiv2);
            mainContainer.appendChild(deleteIcon);


            const overrideMainFrame = document.getElementById("overrideMainFrame");
            overrideMainFrame.appendChild(mainContainer);

        } else {
            setOverrideDateEnabled(true);
        }
    }

    useEffect(() => {
        if (userSchedules) {
            if (userSchedules[scheduleName]) {
                if (userSchedules[scheduleName].dateOverrides) {
                    if (Object.keys(userSchedules[scheduleName].dateOverrides).length > 0) {
                        if (Object.keys(userSchedules[scheduleName].dateOverrides).includes(overrideDate?.toISOString())) {
                            setOverrideDateEnabled(userSchedules[scheduleName].dateOverrides[overrideDate?.toISOString()].active);
                            setExistingOverride(true);
                            setOverrideArray(userSchedules[scheduleName].dateOverrides[overrideDate?.toISOString()].timeSet);
                            // setOverrideStartTime(userSchedules[scheduleName].dateOverrides[overrideDate].timeSet.split("-")[0]);
                            // setOverrideEndTime(userSchedules[scheduleName].dateOverrides[overrideDate].timeSet.split("-")[1]);
                            // console.log(userSchedules[scheduleName].dateOverrides[overrideDate].active);
                        } else {
                            setOverrideDateEnabled(false);
                            setOverrideArray(["9:00am-5:00pm"]);
                            // setOverrideStartTime("9:00am");
                            // setOverrideEndTime("5:00pm");
                            setExistingOverride(false);
                        }
                    } else {
                        setExistingOverride(false);
                    }
                } else {
                    setExistingOverride(false);
                }
            } else {
                setExistingOverride(false);
            }
        } else {
            setExistingOverride(false);
        }

    }, [overrideDate])

    // const deleteTimeSet = (dayName, type, uid) => {
    //     console.log(dayName, uid);
    //     switch (dayName) {
    //         case "sunday":
    //             document.getElementById("sundayTimeFrame_" + uid).remove();

    //             const allEndSundayTimes = document.getElementsByClassName("sundayEndTabs" + type);
    //             if (allEndSundayTimes.length) {
    //                 checkUpdatedStateOfTimeValidation(type);
    //             } else {
    //                 setIsSundayActive(false);
    //                 checkUpdatedStateOfTimeValidation(type);
    //             }
    //             break;
    //         case "monday":
    //             document.getElementById("mondayTimeFrame_" + uid).remove();

    //             const allEndMondayTimes = document.getElementsByClassName("mondayEndTabs" + type);
    //             if (allEndMondayTimes.length) {
    //                 checkUpdatedStateOfTimeValidation(type);
    //             } else {
    //                 setIsMondayActive(false);
    //                 checkUpdatedStateOfTimeValidation(type);
    //             }
    //             break;
    //         case "tuesday":
    //             document.getElementById("tuesdayTimeFrame_" + uid).remove();

    //             const allEndTuesdayTimes = document.getElementsByClassName("tuesdayEndTabs" + type);
    //             if (allEndTuesdayTimes.length) {
    //                 checkUpdatedStateOfTimeValidation(type);
    //             } else {
    //                 setIsTuesdayActive(false);
    //                 checkUpdatedStateOfTimeValidation(type);
    //             }
    //             break;
    //         case "wednesday":
    //             document.getElementById("wednesdayTimeFrame_" + uid).remove();

    //             const allEndWednesdayTimes = document.getElementsByClassName("wednesdayEndTabs" + type);
    //             if (allEndWednesdayTimes.length) {
    //                 checkUpdatedStateOfTimeValidation(type);
    //             } else {
    //                 setIsWednesdayActive(false);
    //                 checkUpdatedStateOfTimeValidation(type);
    //             }
    //             break;
    //         case "thursday":
    //             document.getElementById("thursdayTimeFrame_" + uid).remove();

    //             const allEndThursdayTimes = document.getElementsByClassName("thursdayEndTabs" + type);
    //             if (allEndThursdayTimes.length) {
    //                 checkUpdatedStateOfTimeValidation(type);
    //             } else {
    //                 setIsThursdayActive(false);
    //                 checkUpdatedStateOfTimeValidation(type);
    //             }
    //             break;
    //         case "friday":
    //             document.getElementById("fridayTimeFrame_" + uid).remove();

    //             const allEndFridayTimes = document.getElementsByClassName("fridayEndTabs" + type);
    //             if (allEndFridayTimes.length) {
    //                 checkUpdatedStateOfTimeValidation(type);
    //             } else {
    //                 setIsFridayActive(false);
    //                 checkUpdatedStateOfTimeValidation(type);
    //             }
    //             break;
    //         case "saturday":
    //             document.getElementById("saturdayTimeFrame_" + uid).remove();

    //             const allEndSaturdayTimes = document.getElementsByClassName("saturdayEndTabs" + type);
    //             if (allEndSaturdayTimes.length) {
    //                 checkUpdatedStateOfTimeValidation(type);
    //             } else {
    //                 setIsSaturdayActive(false);
    //                 checkUpdatedStateOfTimeValidation(type);
    //             }
    //             break;
    //         default:
    //             console.log("invalid dates are placed in field.");
    //     }
    // }

    const deleteTimeSet = (dayName, frameName, uid) => {
        // console.log(dayName, uid);
        switch (dayName) {
            case "sunday":
                document.getElementById("sundayTimeFrame_" + uid).remove();

                const allEndSundayTimes = document.getElementsByClassName("sundayEndTabs" + frameName);
                if (allEndSundayTimes.length) {
                    checkUpdatedStateOfTimeValidation(frameName);
                } else {
                    setIsSundayActive(false);
                    checkUpdatedStateOfTimeValidation(frameName);
                }
                break;
            case "monday":
                document.getElementById("mondayTimeFrame_" + uid).remove();

                const allEndMondayTimes = document.getElementsByClassName("mondayEndTabs" + frameName);
                if (allEndMondayTimes.length) {
                    checkUpdatedStateOfTimeValidation(frameName);
                } else {
                    setIsMondayActive(false);
                    checkUpdatedStateOfTimeValidation(frameName);
                }
                break;
            case "tuesday":
                document.getElementById("tuesdayTimeFrame_" + uid).remove();

                const allEndTuesdayTimes = document.getElementsByClassName("tuesdayEndTabs" + frameName);
                if (allEndTuesdayTimes.length) {
                    checkUpdatedStateOfTimeValidation(frameName);
                } else {
                    setIsTuesdayActive(false);
                    checkUpdatedStateOfTimeValidation(frameName);
                }
                break;
            case "wednesday":
                document.getElementById("wednesdayTimeFrame_" + uid).remove();

                const allEndWednesdayTimes = document.getElementsByClassName("wednesdayEndTabs" + frameName);
                if (allEndWednesdayTimes.length) {
                    checkUpdatedStateOfTimeValidation(frameName);
                } else {
                    setIsWednesdayActive(false);
                    checkUpdatedStateOfTimeValidation(frameName);
                }
                break;
            case "thursday":
                document.getElementById("thursdayTimeFrame_" + uid).remove();

                const allEndThursdayTimes = document.getElementsByClassName("thursdayEndTabs" + frameName);
                if (allEndThursdayTimes.length) {
                    checkUpdatedStateOfTimeValidation(frameName);
                } else {
                    setIsThursdayActive(false);
                    checkUpdatedStateOfTimeValidation(frameName);
                }
                break;
            case "friday":
                document.getElementById("fridayTimeFrame_" + uid).remove();

                const allEndFridayTimes = document.getElementsByClassName("fridayEndTabs" + frameName);
                if (allEndFridayTimes.length) {
                    checkUpdatedStateOfTimeValidation(frameName);
                } else {
                    setIsFridayActive(false);
                    checkUpdatedStateOfTimeValidation(frameName);
                }
                break;
            case "saturday":
                document.getElementById("saturdayTimeFrame_" + uid).remove();

                const allEndSaturdayTimes = document.getElementsByClassName("saturdayEndTabs" + frameName);
                if (allEndSaturdayTimes.length) {
                    checkUpdatedStateOfTimeValidation(frameName);
                } else {
                    setIsSaturdayActive(false);
                    checkUpdatedStateOfTimeValidation(frameName);
                }
                break;
            default:
            // console.log("invalid dates are placed in field.");
        }
    }

    const addTimeSetIn = (dayName, frameName, uid) => {
        switch (dayName) {
            case "sunday":
                if (isSundayActive) {
                    const getUniqueId = uid;
                    const spanMain = document.createElement("span");
                    spanMain.setAttribute("class", "d-flex align-items-center gap-1 justify-content-start");
                    spanMain.setAttribute("id", "sundayTimeFrame_" + getUniqueId);

                    let findIndexOf = "";
                    const allEndSundayTimes = document.getElementsByClassName("sundayEndTabs" + frameName);
                    if (allEndSundayTimes.length) {
                        findIndexOf = allEndSundayTimes[allEndSundayTimes.length - 1].value
                    } else {
                        findIndexOf = user.settings.timeFormat.value !== "12 Hours" ? "17:00" : "5:00pm";
                    }

                    const last_usedIndex = timeListToMeasure.indexOf(findIndexOf);

                    if (last_usedIndex === timeListToMeasure.length - 1) {
                        return
                    }




                    const innerDiv1 = document.createElement("div");

                    const inputField1 = document.createElement("input");
                    inputField1.setAttribute("list", "sundayStart");
                    inputField1.setAttribute("maxlength", "7");
                    inputField1.setAttribute("value", timeListToMeasure[last_usedIndex]);
                    inputField1.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm sundayStartTabs${frameName}`);
                    inputField1.addEventListener("input", (e) => {
                        filterTimeFieldNow(e);
                        checkValidTiming(e, frameName);
                    })


                    const dataList1 = document.createElement("datalist");
                    dataList1.setAttribute("id", "sundayStart");
                    dataList1.setAttribute("class", "smallTextNote");

                    timeListToMeasure.map((times, key) => {
                        const optionsTag1 = document.createElement("option");
                        optionsTag1.setAttribute("key", key);
                        optionsTag1.setAttribute("value", times);

                        const textNode1 = document.createTextNode(times);

                        optionsTag1.appendChild(textNode1);
                        return dataList1.appendChild(optionsTag1);
                    });

                    innerDiv1.appendChild(inputField1);
                    innerDiv1.appendChild(dataList1);

                    // ###########################################################################################

                    const innerDiv2 = document.createElement("div");
                    let useIndexToCall = last_usedIndex;

                    if (last_usedIndex + 2 <= timeListToMeasure.length - 1) {
                        useIndexToCall = last_usedIndex + 2;
                    }
                    else {
                        useIndexToCall = timeListToMeasure.length - 1;
                    }

                    const inputField2 = document.createElement("input");
                    inputField2.setAttribute("list", "sundayEnd");
                    inputField2.setAttribute("maxlength", "7");
                    inputField2.setAttribute("value", timeListToMeasure[useIndexToCall]);
                    inputField2.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm sundayEndTabs${frameName}`);
                    inputField2.addEventListener("input", (e) => {
                        filterTimeFieldNow(e);
                        checkValidTiming(e, frameName);
                    })



                    const dataList2 = document.createElement("datalist");
                    dataList2.setAttribute("id", "sundayEnd");
                    dataList2.setAttribute("class", "smallTextNote");

                    timeListToMeasure.map((times, key) => {
                        const optionsTag2 = document.createElement("option");
                        optionsTag2.setAttribute("key", key);
                        optionsTag2.setAttribute("value", times);

                        const textNode2 = document.createTextNode(times);

                        optionsTag2.appendChild(textNode2);
                        return dataList2.appendChild(optionsTag2);
                    });

                    innerDiv2.appendChild(inputField2);
                    innerDiv2.appendChild(dataList2);
                    // ###########################################################################################

                    const deleteBtn = document.createElement("div");
                    deleteBtn.setAttribute("class", "d-flex align-items-center h-100");
                    deleteBtn.setAttribute("style", "padding:0 5px;");

                    const deleteIcon = document.createElement("i");
                    deleteIcon.setAttribute("class", "fa fa-trash");

                    deleteIcon.addEventListener("click", (e) => {
                        document.getElementById("sundayTimeFrame_" + getUniqueId).remove();

                        const allEndSundayTimes = document.getElementsByClassName("sundayEndTabs" + frameName);
                        if (allEndSundayTimes.length) {
                            checkUpdatedStateOfTimeValidation(frameName);
                        } else {
                            setIsSundayActive(false);
                            checkUpdatedStateOfTimeValidation(frameName);
                        }
                    });

                    deleteBtn.appendChild(deleteIcon);


                    spanMain.appendChild(innerDiv1);
                    spanMain.appendChild(innerDiv2);
                    spanMain.appendChild(deleteBtn);

                    const mainContainer = document.getElementById("sundayFrame" + frameName);
                    mainContainer.appendChild(spanMain);
                } else {
                    setIsSundayActive(true);
                }
                break;
            case "monday":
                if (isMondayActive) {
                    const getUniqueId = uid;
                    const spanMain = document.createElement("span");
                    spanMain.setAttribute("class", "d-flex align-items-center gap-1 justify-content-start");
                    spanMain.setAttribute("id", "mondayTimeFrame_" + getUniqueId);

                    let findIndexOf = "";
                    const allEndMondayTimes = document.getElementsByClassName("mondayEndTabs" + frameName);
                    if (allEndMondayTimes.length) {
                        findIndexOf = allEndMondayTimes[allEndMondayTimes.length - 1].value
                    } else {
                        findIndexOf = user.settings.timeFormat.value !== "12 Hours" ? "17:00" : "5:00pm";
                    }

                    const last_usedIndex = timeListToMeasure.indexOf(findIndexOf);

                    if (last_usedIndex === timeListToMeasure.length - 1) {
                        return
                    }

                    const innerDiv1 = document.createElement("div");

                    const inputField1 = document.createElement("input");
                    inputField1.setAttribute("list", "mondayStart");
                    inputField1.setAttribute("maxlength", "7");
                    inputField1.setAttribute("value", timeListToMeasure[last_usedIndex]);
                    inputField1.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm mondayStartTabs${frameName}`);
                    inputField1.addEventListener("input", (e) => {
                        filterTimeFieldNow(e);
                        checkValidTiming(e, frameName);
                    })


                    const dataList1 = document.createElement("datalist");
                    dataList1.setAttribute("id", "mondayStart");
                    dataList1.setAttribute("class", "smallTextNote");

                    timeListToMeasure.map((times, key) => {
                        const optionsTag1 = document.createElement("option");
                        optionsTag1.setAttribute("key", key);
                        optionsTag1.setAttribute("value", times);

                        const textNode1 = document.createTextNode(times);

                        optionsTag1.appendChild(textNode1);
                        return dataList1.appendChild(optionsTag1);
                    });

                    innerDiv1.appendChild(inputField1);
                    innerDiv1.appendChild(dataList1);

                    // ###########################################################################################

                    const innerDiv2 = document.createElement("div");
                    let useIndexToCall = last_usedIndex;

                    if (last_usedIndex + 2 <= timeListToMeasure.length - 1) {
                        useIndexToCall = last_usedIndex + 2;
                    }
                    else {
                        useIndexToCall = timeListToMeasure.length - 1;
                    }

                    const inputField2 = document.createElement("input");
                    inputField2.setAttribute("list", "mondayEnd");

                    inputField2.setAttribute("maxlength", "7");
                    inputField2.setAttribute("value", timeListToMeasure[useIndexToCall]);
                    inputField2.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm mondayEndTabs${frameName}`);
                    inputField2.addEventListener("input", (e) => {
                        filterTimeFieldNow(e);
                        checkValidTiming(e, frameName);
                    })



                    const dataList2 = document.createElement("datalist");
                    dataList2.setAttribute("id", "mondayEnd");
                    dataList2.setAttribute("class", "smallTextNote");

                    timeListToMeasure.map((times, key) => {
                        const optionsTag2 = document.createElement("option");
                        optionsTag2.setAttribute("key", key);
                        optionsTag2.setAttribute("value", times);

                        const textNode2 = document.createTextNode(times);

                        optionsTag2.appendChild(textNode2);
                        return dataList2.appendChild(optionsTag2);
                    });

                    innerDiv2.appendChild(inputField2);
                    innerDiv2.appendChild(dataList2);
                    // ###########################################################################################

                    const deleteBtn = document.createElement("div");
                    deleteBtn.setAttribute("class", "d-flex align-items-center h-100");


                    const deleteIcon = document.createElement("i");
                    deleteIcon.setAttribute("class", "fa fa-trash");

                    deleteIcon.addEventListener("click", (e) => {
                        document.getElementById("mondayTimeFrame_" + getUniqueId).remove();

                        const allEndMondayTimes = document.getElementsByClassName("mondayEndTabs" + frameName);
                        if (allEndMondayTimes.length) {
                            checkUpdatedStateOfTimeValidation(frameName);
                        } else {
                            setIsMondayActive(false);
                            checkUpdatedStateOfTimeValidation(frameName);
                        }
                    });

                    deleteBtn.setAttribute("style", "padding:0 5px;");
                    deleteBtn.appendChild(deleteIcon);

                    spanMain.appendChild(innerDiv1);
                    spanMain.appendChild(innerDiv2);
                    spanMain.appendChild(deleteBtn);

                    const mainContainer = document.getElementById("mondayFrame" + frameName);
                    mainContainer.appendChild(spanMain);
                } else {
                    setIsMondayActive(true);
                }
                break;
            case "tuesday":
                if (isTuesdayActive) {
                    const getUniqueId = uid;
                    const spanMain = document.createElement("span");
                    spanMain.setAttribute("class", "d-flex align-items-center gap-1 justify-content-start");
                    spanMain.setAttribute("id", "tuesdayTimeFrame_" + getUniqueId);

                    let findIndexOf = "";
                    const allEndTuesdayTimes = document.getElementsByClassName("tuesdayEndTabs" + frameName);
                    if (allEndTuesdayTimes.length) {
                        findIndexOf = allEndTuesdayTimes[allEndTuesdayTimes.length - 1].value
                    } else {
                        findIndexOf = user.settings.timeFormat.value !== "12 Hours" ? "17:00" : "5:00pm";
                    }

                    const last_usedIndex = timeListToMeasure.indexOf(findIndexOf);

                    if (last_usedIndex === timeListToMeasure.length - 1) {
                        return
                    }




                    const innerDiv1 = document.createElement("div");

                    const inputField1 = document.createElement("input");
                    inputField1.setAttribute("list", "tuesdayStart");
                    inputField1.setAttribute("maxlength", "7");
                    inputField1.setAttribute("value", timeListToMeasure[last_usedIndex]);
                    inputField1.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm tuesdayStartTabs${frameName}`);
                    inputField1.addEventListener("input", (e) => {
                        filterTimeFieldNow(e);
                        checkValidTiming(e, frameName);
                    })


                    const dataList1 = document.createElement("datalist");
                    dataList1.setAttribute("id", "tuesdayStart");
                    dataList1.setAttribute("class", "smallTextNote");

                    timeListToMeasure.map((times, key) => {
                        const optionsTag1 = document.createElement("option");
                        optionsTag1.setAttribute("key", key);
                        optionsTag1.setAttribute("value", times);

                        const textNode1 = document.createTextNode(times);

                        optionsTag1.appendChild(textNode1);
                        return dataList1.appendChild(optionsTag1);
                    });

                    innerDiv1.appendChild(inputField1);
                    innerDiv1.appendChild(dataList1);

                    // ###########################################################################################

                    const innerDiv2 = document.createElement("div");
                    let useIndexToCall = last_usedIndex;

                    if (last_usedIndex + 2 <= timeListToMeasure.length - 1) {
                        useIndexToCall = last_usedIndex + 2;
                    }
                    else {
                        useIndexToCall = timeListToMeasure.length - 1;
                    }

                    const inputField2 = document.createElement("input");
                    inputField2.setAttribute("list", "tuesdayEnd");
                    inputField2.setAttribute("maxlength", "7");
                    inputField2.setAttribute("value", timeListToMeasure[useIndexToCall]);
                    inputField2.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm tuesdayEndTabs${frameName}`);
                    inputField2.addEventListener("input", (e) => {
                        filterTimeFieldNow(e);
                        checkValidTiming(e, frameName);
                    })


                    const dataList2 = document.createElement("datalist");
                    dataList2.setAttribute("id", "tuesdayEnd");
                    dataList2.setAttribute("class", "smallTextNote");

                    timeListToMeasure.map((times, key) => {
                        const optionsTag2 = document.createElement("option");
                        optionsTag2.setAttribute("key", key);
                        optionsTag2.setAttribute("value", times);

                        const textNode2 = document.createTextNode(times);

                        optionsTag2.appendChild(textNode2);
                        return dataList2.appendChild(optionsTag2);
                    });

                    innerDiv2.appendChild(inputField2);
                    innerDiv2.appendChild(dataList2);
                    // ###########################################################################################

                    const deleteBtn = document.createElement("div");
                    deleteBtn.setAttribute("class", "d-flex align-items-center h-100");

                    const deleteIcon = document.createElement("i");
                    deleteIcon.setAttribute("class", "fa fa-trash");

                    deleteIcon.addEventListener("click", (e) => {
                        document.getElementById("tuesdayTimeFrame_" + getUniqueId).remove();

                        const allEndTuesdayTimes = document.getElementsByClassName("tuesdayEndTabs" + frameName);
                        if (allEndTuesdayTimes.length) {
                            checkUpdatedStateOfTimeValidation(frameName);
                        } else {
                            setIsTuesdayActive(false);
                            checkUpdatedStateOfTimeValidation(frameName);
                        }
                    });

                    deleteBtn.setAttribute("style", "padding:0 5px;");
                    deleteBtn.appendChild(deleteIcon);

                    spanMain.appendChild(innerDiv1);
                    spanMain.appendChild(innerDiv2);
                    spanMain.appendChild(deleteBtn);

                    const mainContainer = document.getElementById("tuesdayFrame" + frameName);
                    mainContainer.appendChild(spanMain);
                } else {
                    setIsTuesdayActive(true);
                }
                break;
            case "wednesday":
                if (isWednesdayActive) {
                    const getUniqueId = uid;
                    const spanMain = document.createElement("span");
                    spanMain.setAttribute("class", "d-flex align-items-center gap-1 justify-content-start");
                    spanMain.setAttribute("id", "wednesdayTimeFrame_" + getUniqueId);

                    let findIndexOf = "";
                    const allEndWednesdayTimes = document.getElementsByClassName("wednesdayEndTabs" + frameName);
                    if (allEndWednesdayTimes.length) {
                        findIndexOf = allEndWednesdayTimes[allEndWednesdayTimes.length - 1].value
                    } else {
                        findIndexOf = user.settings.timeFormat.value !== "12 Hours" ? "17:00" : "5:00pm";
                    }

                    const last_usedIndex = timeListToMeasure.indexOf(findIndexOf);

                    if (last_usedIndex === timeListToMeasure.length - 1) {
                        return
                    }


                    const innerDiv1 = document.createElement("div");

                    const inputField1 = document.createElement("input");
                    inputField1.setAttribute("list", "wednesdayStart");
                    inputField1.setAttribute("maxlength", "7");
                    inputField1.setAttribute("value", timeListToMeasure[last_usedIndex]);
                    inputField1.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm wednesdayStartTabs${frameName}`);
                    inputField1.addEventListener("input", (e) => {
                        filterTimeFieldNow(e);
                        checkValidTiming(e, frameName);
                    })


                    const dataList1 = document.createElement("datalist");
                    dataList1.setAttribute("id", "wednesdayStart");
                    dataList1.setAttribute("class", "smallTextNote");

                    timeListToMeasure.map((times, key) => {
                        const optionsTag1 = document.createElement("option");
                        optionsTag1.setAttribute("key", key);
                        optionsTag1.setAttribute("value", times);

                        const textNode1 = document.createTextNode(times);

                        optionsTag1.appendChild(textNode1);
                        return dataList1.appendChild(optionsTag1);
                    });

                    innerDiv1.appendChild(inputField1);
                    innerDiv1.appendChild(dataList1);

                    // ###########################################################################################

                    const innerDiv2 = document.createElement("div");
                    let useIndexToCall = last_usedIndex;

                    if (last_usedIndex + 2 <= timeListToMeasure.length - 1) {
                        useIndexToCall = last_usedIndex + 2;
                    }
                    else {
                        useIndexToCall = timeListToMeasure.length - 1;
                    }

                    const inputField2 = document.createElement("input");
                    inputField2.setAttribute("list", "wednesdayEnd");
                    inputField1.setAttribute("maxlength", "7");
                    inputField2.setAttribute("value", timeListToMeasure[useIndexToCall]);
                    inputField2.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm wednesdayEndTabs${frameName}`);
                    inputField2.addEventListener("input", (e) => {
                        filterTimeFieldNow(e);
                        checkValidTiming(e, frameName);
                    })



                    const dataList2 = document.createElement("datalist");
                    dataList2.setAttribute("id", "wednesdayEnd");
                    dataList2.setAttribute("class", "smallTextNote");

                    timeListToMeasure.map((times, key) => {
                        const optionsTag2 = document.createElement("option");
                        optionsTag2.setAttribute("key", key);
                        optionsTag2.setAttribute("value", times);

                        const textNode2 = document.createTextNode(times);

                        optionsTag2.appendChild(textNode2);
                        return dataList2.appendChild(optionsTag2);
                    });

                    innerDiv2.appendChild(inputField2);
                    innerDiv2.appendChild(dataList2);
                    // ###########################################################################################

                    const deleteBtn = document.createElement("div");
                    deleteBtn.setAttribute("class", "d-flex align-items-center h-100");

                    const deleteIcon = document.createElement("i");
                    deleteIcon.setAttribute("class", "fa fa-trash");

                    deleteIcon.addEventListener("click", (e) => {
                        document.getElementById("wednesdayTimeFrame_" + getUniqueId).remove();

                        const allEndWednesdayTimes = document.getElementsByClassName("wednesdayEndTabs" + frameName);
                        if (allEndWednesdayTimes.length) {
                            checkUpdatedStateOfTimeValidation(frameName);
                        } else {
                            setIsWednesdayActive(false);
                            checkUpdatedStateOfTimeValidation(frameName);
                        }
                    });

                    deleteBtn.setAttribute("style", "padding:0 5px;");
                    deleteBtn.appendChild(deleteIcon);

                    spanMain.appendChild(innerDiv1);
                    spanMain.appendChild(innerDiv2);
                    spanMain.appendChild(deleteBtn);

                    const mainContainer = document.getElementById("wednesdayFrame" + frameName);
                    mainContainer.appendChild(spanMain);
                } else {
                    setIsWednesdayActive(true);
                }
                break;
            case "thursday":
                if (isThursdayActive) {
                    const getUniqueId = uid;
                    const spanMain = document.createElement("span");
                    spanMain.setAttribute("class", "d-flex align-items-center gap-1 justify-content-start");
                    spanMain.setAttribute("id", "thursdayTimeFrame_" + getUniqueId);

                    let findIndexOf = "";
                    const allEndThursdayTimes = document.getElementsByClassName("thursdayEndTabs" + frameName);
                    if (allEndThursdayTimes.length) {
                        findIndexOf = allEndThursdayTimes[allEndThursdayTimes.length - 1].value
                    } else {
                        findIndexOf = user.settings.timeFormat.value !== "12 Hours" ? "17:00" : "5:00pm";
                    }

                    const last_usedIndex = timeListToMeasure.indexOf(findIndexOf);

                    if (last_usedIndex === timeListToMeasure.length - 1) {
                        return
                    }




                    const innerDiv1 = document.createElement("div");

                    const inputField1 = document.createElement("input");
                    inputField1.setAttribute("list", "thursdayStart");
                    inputField1.setAttribute("maxlength", "7");
                    inputField1.setAttribute("value", timeListToMeasure[last_usedIndex]);
                    inputField1.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm thursdayStartTabs${frameName}`);
                    inputField1.addEventListener("input", (e) => {
                        filterTimeFieldNow(e);
                        checkValidTiming(e, frameName);
                    })


                    const dataList1 = document.createElement("datalist");
                    dataList1.setAttribute("id", "thursdayStart");
                    dataList1.setAttribute("class", "smallTextNote");

                    timeListToMeasure.map((times, key) => {
                        const optionsTag1 = document.createElement("option");
                        optionsTag1.setAttribute("key", key);
                        optionsTag1.setAttribute("value", times);

                        const textNode1 = document.createTextNode(times);

                        optionsTag1.appendChild(textNode1);
                        return dataList1.appendChild(optionsTag1);
                    });

                    innerDiv1.appendChild(inputField1);
                    innerDiv1.appendChild(dataList1);

                    // ###########################################################################################

                    const innerDiv2 = document.createElement("div");
                    let useIndexToCall = last_usedIndex;

                    if (last_usedIndex + 2 <= timeListToMeasure.length - 1) {
                        useIndexToCall = last_usedIndex + 2;
                    }
                    else {
                        useIndexToCall = timeListToMeasure.length - 1;
                    }

                    const inputField2 = document.createElement("input");
                    inputField2.setAttribute("list", "thursdayEnd");
                    inputField2.setAttribute("maxlength", "7");
                    inputField2.setAttribute("value", timeListToMeasure[useIndexToCall]);
                    inputField2.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm thursdayEndTabs${frameName}`);
                    inputField2.addEventListener("input", (e) => {
                        filterTimeFieldNow(e);
                        checkValidTiming(e, frameName);
                    })



                    const dataList2 = document.createElement("datalist");
                    dataList2.setAttribute("id", "thursdayEnd");
                    dataList2.setAttribute("class", "smallTextNote");

                    timeListToMeasure.map((times, key) => {
                        const optionsTag2 = document.createElement("option");
                        optionsTag2.setAttribute("key", key);
                        optionsTag2.setAttribute("value", times);

                        const textNode2 = document.createTextNode(times);

                        optionsTag2.appendChild(textNode2);
                        return dataList2.appendChild(optionsTag2);
                    });

                    innerDiv2.appendChild(inputField2);
                    innerDiv2.appendChild(dataList2);
                    // ###########################################################################################

                    const deleteBtn = document.createElement("div");
                    deleteBtn.setAttribute("class", "d-flex align-items-center h-100");

                    const deleteIcon = document.createElement("i");
                    deleteIcon.setAttribute("class", "fa fa-trash");

                    deleteIcon.addEventListener("click", (e) => {
                        document.getElementById("thursdayTimeFrame_" + getUniqueId).remove();

                        const allEndThursdayTimes = document.getElementsByClassName("thursdayEndTabs" + frameName);
                        if (allEndThursdayTimes.length) {
                            checkUpdatedStateOfTimeValidation(frameName);
                        } else {
                            setIsThursdayActive(false);
                            checkUpdatedStateOfTimeValidation(frameName);
                        }
                    });

                    deleteBtn.setAttribute("style", "padding:0 5px;");
                    deleteBtn.appendChild(deleteIcon);

                    spanMain.appendChild(innerDiv1);
                    spanMain.appendChild(innerDiv2);
                    spanMain.appendChild(deleteBtn);

                    const mainContainer = document.getElementById("thursdayFrame" + frameName);
                    mainContainer.appendChild(spanMain);
                } else {
                    setIsThursdayActive(true);
                }
                break;
            case "friday":
                if (isFridayActive) {
                    const getUniqueId = uid;
                    const spanMain = document.createElement("span");
                    spanMain.setAttribute("class", "d-flex align-items-center gap-1 justify-content-start");
                    spanMain.setAttribute("id", "fridayTimeFrame_" + getUniqueId);

                    let findIndexOf = "";
                    const allEndFridayTimes = document.getElementsByClassName("fridayEndTabs" + frameName);
                    if (allEndFridayTimes.length) {
                        findIndexOf = allEndFridayTimes[allEndFridayTimes.length - 1].value
                    } else {
                        findIndexOf = user.settings.timeFormat.value !== "12 Hours" ? "17:00" : "5:00pm";
                    }

                    const last_usedIndex = timeListToMeasure.indexOf(findIndexOf);

                    if (last_usedIndex === timeListToMeasure.length - 1) {
                        return
                    }




                    const innerDiv1 = document.createElement("div");

                    const inputField1 = document.createElement("input");
                    inputField1.setAttribute("list", "fridayStart");
                    inputField1.setAttribute("maxlength", "7");
                    inputField1.setAttribute("value", timeListToMeasure[last_usedIndex]);
                    inputField1.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm fridayStartTabs${frameName}`);
                    inputField1.addEventListener("input", (e) => {
                        filterTimeFieldNow(e);
                        checkValidTiming(e, frameName);
                    })


                    const dataList1 = document.createElement("datalist");
                    dataList1.setAttribute("id", "fridayStart");
                    dataList1.setAttribute("class", "smallTextNote");

                    timeListToMeasure.map((times, key) => {
                        const optionsTag1 = document.createElement("option");
                        optionsTag1.setAttribute("key", key);
                        optionsTag1.setAttribute("value", times);

                        const textNode1 = document.createTextNode(times);

                        optionsTag1.appendChild(textNode1);
                        return dataList1.appendChild(optionsTag1);
                    });

                    innerDiv1.appendChild(inputField1);
                    innerDiv1.appendChild(dataList1);

                    // ###########################################################################################

                    const innerDiv2 = document.createElement("div");
                    let useIndexToCall = last_usedIndex;

                    if (last_usedIndex + 2 <= timeListToMeasure.length - 1) {
                        useIndexToCall = last_usedIndex + 2;
                    }
                    else {
                        useIndexToCall = timeListToMeasure.length - 1;
                    }

                    const inputField2 = document.createElement("input");
                    inputField2.setAttribute("list", "fridayEnd");
                    inputField2.setAttribute("maxlength", "7");
                    inputField2.setAttribute("value", timeListToMeasure[useIndexToCall]);
                    inputField2.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm fridayEndTabs${frameName}`);
                    inputField2.addEventListener("input", (e) => {
                        filterTimeFieldNow(e);
                        checkValidTiming(e, frameName);
                    })


                    const dataList2 = document.createElement("datalist");
                    dataList2.setAttribute("id", "fridayEnd");
                    dataList2.setAttribute("class", "smallTextNote");

                    timeListToMeasure.map((times, key) => {
                        const optionsTag2 = document.createElement("option");
                        optionsTag2.setAttribute("key", key);
                        optionsTag2.setAttribute("value", times);

                        const textNode2 = document.createTextNode(times);

                        optionsTag2.appendChild(textNode2);
                        return dataList2.appendChild(optionsTag2);
                    });

                    innerDiv2.appendChild(inputField2);
                    innerDiv2.appendChild(dataList2);
                    // ###########################################################################################

                    const deleteBtn = document.createElement("div");
                    deleteBtn.setAttribute("class", "d-flex align-items-center h-100");

                    const deleteIcon = document.createElement("i");
                    deleteIcon.setAttribute("class", "fa fa-trash");

                    deleteIcon.addEventListener("click", (e) => {
                        document.getElementById("fridayTimeFrame_" + getUniqueId).remove();

                        const allEndFridayTimes = document.getElementsByClassName("fridayEndTabs" + frameName);
                        if (allEndFridayTimes.length) {
                            checkUpdatedStateOfTimeValidation(frameName);
                        } else {
                            setIsFridayActive(false);
                            checkUpdatedStateOfTimeValidation(frameName);
                        }
                    });


                    deleteBtn.setAttribute("style", "padding:0 5px;");
                    deleteBtn.appendChild(deleteIcon);

                    spanMain.appendChild(innerDiv1);
                    spanMain.appendChild(innerDiv2);
                    spanMain.appendChild(deleteBtn);

                    const mainContainer = document.getElementById("fridayFrame" + frameName);
                    mainContainer.appendChild(spanMain);
                } else {
                    setIsFridayActive(true);
                }
                break;
            case "saturday":
                if (isSaturdayActive) {
                    const getUniqueId = uid;
                    const spanMain = document.createElement("span");
                    spanMain.setAttribute("class", "d-flex align-items-center gap-1 justify-content-start");
                    spanMain.setAttribute("id", "saturdayTimeFrame_" + getUniqueId);

                    let findIndexOf = "";
                    const allEndSaturdayTimes = document.getElementsByClassName("saturdayEndTabs" + frameName);
                    if (allEndSaturdayTimes.length) {
                        findIndexOf = allEndSaturdayTimes[allEndSaturdayTimes.length - 1].value
                    } else {
                        findIndexOf = user.settings.timeFormat.value !== "12 Hours" ? "17:00" : "5:00pm";
                    }

                    const last_usedIndex = timeListToMeasure.indexOf(findIndexOf);

                    if (last_usedIndex === timeListToMeasure.length - 1) {
                        return
                    }

                    const innerDiv1 = document.createElement("div");

                    const inputField1 = document.createElement("input");
                    inputField1.setAttribute("list", "saturdayStart");
                    inputField1.setAttribute("maxlength", "7");
                    inputField1.setAttribute("value", timeListToMeasure[last_usedIndex]);
                    inputField1.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm saturdayStartTabs${frameName}`);
                    inputField1.addEventListener("input", (e) => {
                        filterTimeFieldNow(e);
                        checkValidTiming(e, frameName);
                    });


                    const dataList1 = document.createElement("datalist");
                    dataList1.setAttribute("id", "saturdayStart");
                    dataList1.setAttribute("class", "smallTextNote");

                    timeListToMeasure.map((times, key) => {
                        const optionsTag1 = document.createElement("option");
                        optionsTag1.setAttribute("key", key);
                        optionsTag1.setAttribute("value", times);

                        const textNode1 = document.createTextNode(times);

                        optionsTag1.appendChild(textNode1);
                        return dataList1.appendChild(optionsTag1);
                    });

                    innerDiv1.appendChild(inputField1);
                    innerDiv1.appendChild(dataList1);

                    // ###########################################################################################

                    const innerDiv2 = document.createElement("div");
                    let useIndexToCall = last_usedIndex;

                    if (last_usedIndex + 2 <= timeListToMeasure.length - 1) {
                        useIndexToCall = last_usedIndex + 2;
                    }
                    else {
                        useIndexToCall = timeListToMeasure.length - 1;
                    }

                    const inputField2 = document.createElement("input");
                    inputField2.setAttribute("list", "saturdayEnd");
                    inputField2.setAttribute("maxlength", "7");
                    inputField2.setAttribute("value", timeListToMeasure[useIndexToCall]);
                    inputField2.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm saturdayEndTabs${frameName}`);
                    inputField2.addEventListener("input", (e) => {
                        filterTimeFieldNow(e);
                        checkValidTiming(e, frameName);
                    });

                    const dataList2 = document.createElement("datalist");
                    dataList2.setAttribute("id", "saturdayEnd");
                    dataList2.setAttribute("class", "smallTextNote");

                    timeListToMeasure.map((times, key) => {
                        const optionsTag2 = document.createElement("option");
                        optionsTag2.setAttribute("key", key);
                        optionsTag2.setAttribute("value", times);

                        const textNode2 = document.createTextNode(times);

                        optionsTag2.appendChild(textNode2);
                        return dataList2.appendChild(optionsTag2);
                    });

                    innerDiv2.appendChild(inputField2);
                    innerDiv2.appendChild(dataList2);
                    // ###########################################################################################

                    const deleteBtn = document.createElement("div");
                    deleteBtn.setAttribute("class", "d-flex align-items-center h-100");

                    const deleteIcon = document.createElement("i");
                    deleteIcon.setAttribute("class", "fa fa-trash");

                    deleteIcon.addEventListener("click", (e) => {
                        document.getElementById("saturdayTimeFrame_" + getUniqueId).remove();

                        const allEndSaturdayTimes = document.getElementsByClassName("saturdayEndTabs" + frameName);
                        if (allEndSaturdayTimes.length) {
                            checkUpdatedStateOfTimeValidation(frameName);
                        } else {
                            setIsSaturdayActive(false);
                            checkUpdatedStateOfTimeValidation(frameName);
                        }
                    });

                    deleteBtn.setAttribute("style", "padding:0 5px;");
                    deleteBtn.appendChild(deleteIcon);

                    spanMain.appendChild(innerDiv1);
                    spanMain.appendChild(innerDiv2);
                    spanMain.appendChild(deleteBtn);

                    const mainContainer = document.getElementById("saturdayFrame" + frameName);
                    mainContainer.appendChild(spanMain);
                } else {
                    setIsSaturdayActive(true);
                }
                break;
            default:
                // console.log("unknown day name is passed");
                break;
        }
    }

    const copyTimesTo = (dayName, type) => {
        switch (dayName) {
            case "sunday":
                setViewFromSunday([true, type]);
                setViewFromMonday([false, null]);
                setViewFromTuesday([false, null]);
                setViewFromWednesday([false, null]);
                setViewFromThursday([false, null]);
                setViewFromFriday([false, null]);
                setViewFromSaturday([false, null]);
                break;
            case "monday":
                setViewFromSunday([false, null]);
                setViewFromMonday([true, type]);
                setViewFromTuesday([false, null]);
                setViewFromWednesday([false, null]);
                setViewFromThursday([false, null]);
                setViewFromFriday([false, null]);
                setViewFromSaturday([false, null]);
                break;
            case "tuesday":
                setViewFromSunday([false, null]);
                setViewFromMonday([false, null]);
                setViewFromTuesday([true, type]);
                setViewFromWednesday([false, null]);
                setViewFromThursday([false, null]);
                setViewFromFriday([false, null]);
                setViewFromSaturday([false, null]);
                break;
            case "wednesday":
                setViewFromSunday([false, null]);
                setViewFromMonday([false, null]);
                setViewFromTuesday([false, null]);
                setViewFromWednesday([true, type]);
                setViewFromThursday([false, null]);
                setViewFromFriday([false, null]);
                setViewFromSaturday([false, null]);
                break;
            case "thursday":
                setViewFromSunday([false, null]);
                setViewFromMonday([false, null]);
                setViewFromTuesday([false, null]);
                setViewFromWednesday([false, null]);
                setViewFromThursday([true, type]);
                setViewFromFriday([false, null]);
                setViewFromSaturday([false, null]);
                break;
            case "friday":
                setViewFromSunday([false, null]);
                setViewFromMonday([false, null]);
                setViewFromTuesday([false, null]);
                setViewFromWednesday([false, null]);
                setViewFromThursday([false, null]);
                setViewFromFriday([true, type]);
                setViewFromSaturday([false, null]);
                break;
            case "saturday":
                setViewFromSunday([false, null]);
                setViewFromMonday([false, null]);
                setViewFromTuesday([false, null]);
                setViewFromWednesday([false, null]);
                setViewFromThursday([false, null]);
                setViewFromFriday([false, null]);
                setViewFromSaturday([true, type]);
                break;
            default:
            // console.log(dayName);
        }
    }

    const copyTimesNow = (e, frameName) => {
        var getSchedule = schedule;
        // console.log(getSchedule);
        switch (e) {
            case "sunday":
                const allSetsStart = document.getElementsByClassName("sundayStartTabs" + frameName);
                const allSetsEnd = document.getElementsByClassName("sundayEndTabs" + frameName);

                const length = allSetsStart.length;
                let sundayArray = [];

                for (let i = 0; i < length; i++) {
                    sundayArray.push(allSetsStart[i].value + "-" + allSetsEnd[i].value);
                }

                if (mondayCopy) {
                    setIsMondayActive(false);
                    getSchedule.monday.times = sundayArray.length > 0 ? sundayArray : ['9:00am-5:00pm'];
                    getSchedule.monday.active = isSundayActive;
                }

                if (tuesdayCopy) {
                    setIsTuesdayActive(false);
                    getSchedule.tuesday.times = sundayArray.length > 0 ? sundayArray : ['9:00am-5:00pm'];
                    getSchedule.tuesday.active = isSundayActive;
                }

                if (wednesdayCopy) {
                    setIsWednesdayActive(false);
                    getSchedule.wednesday.times = sundayArray.length > 0 ? sundayArray : ['9:00am-5:00pm'];
                    getSchedule.wednesday.active = isSundayActive;
                }

                if (thursdayCopy) {
                    setIsThursdayActive(false);
                    getSchedule.thursday.times = sundayArray.length > 0 ? sundayArray : ['9:00am-5:00pm'];
                    getSchedule.thursday.active = isSundayActive;
                }

                if (fridayCopy) {
                    setIsFridayActive(false);
                    getSchedule.friday.times = sundayArray.length > 0 ? sundayArray : ['9:00am-5:00pm'];
                    getSchedule.friday.active = isSundayActive;
                }

                if (saturdayCopy) {
                    setIsSaturdayActive(false);
                    getSchedule.saturday.times = sundayArray.length > 0 ? sundayArray : ['9:00am-5:00pm'];
                    getSchedule.saturday.active = isSundayActive;
                }

                setSchedule(getSchedule);

                setTimeout(() => {
                    if (mondayCopy) {
                        setIsMondayActive(isSundayActive);
                    }

                    if (tuesdayCopy) {
                        setIsTuesdayActive(isSundayActive);
                    }

                    if (wednesdayCopy) {
                        setIsWednesdayActive(isSundayActive);
                    }

                    if (thursdayCopy) {
                        setIsThursdayActive(isSundayActive);
                    }

                    if (fridayCopy) {
                        setIsFridayActive(isSundayActive);
                    }

                    if (saturdayCopy) {
                        setIsSaturdayActive(isSundayActive);
                    }

                }, 300)

                break;
            case "monday":
                const allSetsStart2 = document.getElementsByClassName("mondayStartTabs" + frameName);
                const allSetsEnd2 = document.getElementsByClassName("mondayEndTabs" + frameName);

                const length2 = allSetsStart2.length;
                let mondayArray = [];

                for (let i = 0; i < length2; i++) {
                    mondayArray.push(allSetsStart2[i].value + "-" + allSetsEnd2[i].value);
                }

                if (sundayCopy) {
                    setIsSundayActive(false);
                    getSchedule.sunday.times = mondayArray.length > 0 ? mondayArray : ['9:00am-5:00pm'];
                    getSchedule.sunday.active = isMondayActive;
                }

                if (tuesdayCopy) {
                    setIsTuesdayActive(false);
                    getSchedule.tuesday.times = mondayArray.length > 0 ? mondayArray : ['9:00am-5:00pm'];
                    getSchedule.tuesday.active = isMondayActive;
                }

                if (wednesdayCopy) {
                    setIsWednesdayActive(false);
                    getSchedule.wednesday.times = mondayArray.length > 0 ? mondayArray : ['9:00am-5:00pm'];
                    getSchedule.wednesday.active = isMondayActive;
                }

                if (thursdayCopy) {
                    setIsThursdayActive(false);
                    getSchedule.thursday.times = mondayArray.length > 0 ? mondayArray : ['9:00am-5:00pm'];
                    getSchedule.thursday.active = isMondayActive;
                }

                if (fridayCopy) {
                    setIsFridayActive(false);
                    getSchedule.friday.times = mondayArray.length > 0 ? mondayArray : ['9:00am-5:00pm'];
                    getSchedule.friday.active = isMondayActive;
                }

                if (saturdayCopy) {
                    setIsSaturdayActive(false);
                    getSchedule.saturday.times = mondayArray.length > 0 ? mondayArray : ['9:00am-5:00pm'];
                    getSchedule.saturday.active = isMondayActive;
                }
                setSchedule(getSchedule);

                setTimeout(() => {
                    if (sundayCopy) {
                        setIsSundayActive(isMondayActive);
                    }

                    if (tuesdayCopy) {
                        setIsTuesdayActive(isMondayActive);
                    }

                    if (wednesdayCopy) {
                        setIsWednesdayActive(isMondayActive);
                    }

                    if (thursdayCopy) {
                        setIsThursdayActive(isMondayActive);
                    }

                    if (fridayCopy) {
                        setIsFridayActive(isMondayActive);
                    }

                    if (saturdayCopy) {
                        setIsSaturdayActive(isMondayActive);
                    }

                }, 300)

                break;
            case "tuesday":
                const allSetsStart3 = document.getElementsByClassName("tuesdayStartTabs" + frameName);
                const allSetsEnd3 = document.getElementsByClassName("tuesdayEndTabs" + frameName);

                const length3 = allSetsStart3.length;
                let tuesdayArray = [];

                for (let i = 0; i < length3; i++) {
                    tuesdayArray.push(allSetsStart3[i].value + "-" + allSetsEnd3[i].value);
                }

                if (sundayCopy) {
                    setIsSundayActive(false);
                    getSchedule.sunday.times = tuesdayArray.length > 0 ? tuesdayArray : ['9:00am-5:00pm'];
                    getSchedule.sunday.active = isTuesdayActive;
                }

                if (mondayCopy) {
                    setIsMondayActive(false);
                    getSchedule.monday.times = tuesdayArray.length > 0 ? tuesdayArray : ['9:00am-5:00pm'];
                    getSchedule.monday.active = isTuesdayActive;
                }



                if (wednesdayCopy) {
                    setIsWednesdayActive(false);
                    getSchedule.wednesday.times = tuesdayArray.length > 0 ? tuesdayArray : ['9:00am-5:00pm'];
                    getSchedule.wednesday.active = isTuesdayActive;
                }

                if (thursdayCopy) {
                    setIsThursdayActive(false);
                    getSchedule.thursday.times = tuesdayArray.length > 0 ? tuesdayArray : ['9:00am-5:00pm'];
                    getSchedule.thursday.active = isTuesdayActive;
                }

                if (fridayCopy) {
                    setIsFridayActive(false);
                    getSchedule.friday.times = tuesdayArray.length > 0 ? tuesdayArray : ['9:00am-5:00pm'];
                    getSchedule.friday.active = isTuesdayActive;
                }

                if (saturdayCopy) {
                    setIsSaturdayActive(false);
                    getSchedule.saturday.times = tuesdayArray.length > 0 ? tuesdayArray : ['9:00am-5:00pm'];
                    getSchedule.saturday.active = isTuesdayActive;
                }

                setSchedule(getSchedule);

                setTimeout(() => {
                    if (sundayCopy) {
                        setIsSundayActive(isTuesdayActive);
                    }
                    if (mondayCopy) {
                        setIsMondayActive(isTuesdayActive);
                    }
                    if (wednesdayCopy) {
                        setIsWednesdayActive(isTuesdayActive);
                    }

                    if (thursdayCopy) {
                        setIsThursdayActive(isTuesdayActive);
                    }

                    if (fridayCopy) {
                        setIsFridayActive(isTuesdayActive);
                    }

                    if (saturdayCopy) {
                        setIsSaturdayActive(isTuesdayActive);
                    }

                }, 300)

                break;
            case "wednesday":
                const allSetsStart4 = document.getElementsByClassName("wednesdayStartTabs" + frameName);
                const allSetsEnd4 = document.getElementsByClassName("wednesdayEndTabs" + frameName);

                const length4 = allSetsStart4.length;
                let wednesdayArray = [];

                for (let i = 0; i < length4; i++) {
                    wednesdayArray.push(allSetsStart4[i].value + "-" + allSetsEnd4[i].value);
                }

                if (mondayCopy) {
                    setIsMondayActive(false);
                    getSchedule.monday.times = wednesdayArray.length > 0 ? wednesdayArray : ['9:00am-5:00pm'];
                    getSchedule.monday.active = isWednesdayActive;
                }

                if (tuesdayCopy) {
                    setIsTuesdayActive(false);
                    getSchedule.tuesday.times = wednesdayArray.length > 0 ? wednesdayArray : ['9:00am-5:00pm'];
                    getSchedule.tuesday.active = isWednesdayActive;
                }

                if (sundayCopy) {
                    setIsSundayActive(false);
                    getSchedule.sunday.times = wednesdayArray.length > 0 ? wednesdayArray : ['9:00am-5:00pm'];
                    getSchedule.sunday.active = isWednesdayActive;
                }

                if (thursdayCopy) {
                    setIsThursdayActive(false);
                    getSchedule.thursday.times = wednesdayArray.length > 0 ? wednesdayArray : ['9:00am-5:00pm'];
                    getSchedule.thursday.active = isWednesdayActive;
                }

                if (fridayCopy) {
                    setIsFridayActive(false);
                    getSchedule.friday.times = wednesdayArray.length > 0 ? wednesdayArray : ['9:00am-5:00pm'];
                    getSchedule.friday.active = isWednesdayActive;
                }

                if (saturdayCopy) {
                    setIsSaturdayActive(false);
                    getSchedule.saturday.times = wednesdayArray.length > 0 ? wednesdayArray : ['9:00am-5:00pm'];
                    getSchedule.saturday.active = isWednesdayActive;
                }
                setSchedule(getSchedule);

                setTimeout(() => {
                    if (sundayCopy) {
                        setIsSundayActive(isWednesdayActive);
                    }

                    if (mondayCopy) {
                        setIsMondayActive(isWednesdayActive);
                    }

                    if (tuesdayCopy) {
                        setIsTuesdayActive(isWednesdayActive);
                    }

                    if (thursdayCopy) {
                        setIsThursdayActive(isWednesdayActive);
                    }

                    if (fridayCopy) {
                        setIsFridayActive(isWednesdayActive);
                    }

                    if (saturdayCopy) {
                        setIsSaturdayActive(isWednesdayActive);
                    }

                }, 300)
                break;
            case "thursday":
                const allSetsStart5 = document.getElementsByClassName("thursdayStartTabs" + frameName);
                const allSetsEnd5 = document.getElementsByClassName("thursdayEndTabs" + frameName);

                const length5 = allSetsStart5.length;
                let thursdayArray = [];

                for (let i = 0; i < length5; i++) {
                    thursdayArray.push(allSetsStart5[i].value + "-" + allSetsEnd5[i].value);
                }

                if (sundayCopy) {
                    setIsSundayActive(false);
                    getSchedule.sunday.times = thursdayArray.length > 0 ? thursdayArray : ['9:00am-5:00pm'];
                    getSchedule.sunday.active = isThursdayActive;
                }

                if (mondayCopy) {
                    setIsMondayActive(false);
                    getSchedule.monday.times = thursdayArray.length > 0 ? thursdayArray : ['9:00am-5:00pm'];
                    getSchedule.monday.active = isThursdayActive;
                }

                if (tuesdayCopy) {
                    setIsTuesdayActive(false);
                    getSchedule.tuesday.times = thursdayArray.length > 0 ? thursdayArray : ['9:00am-5:00pm'];
                    getSchedule.tuesday.active = isThursdayActive;
                }

                if (wednesdayCopy) {
                    setIsWednesdayActive(false);
                    getSchedule.wednesday.times = thursdayArray.length > 0 ? thursdayArray : ['9:00am-5:00pm'];
                    getSchedule.wednesday.active = isThursdayActive;
                }

                if (fridayCopy) {
                    setIsFridayActive(false);
                    getSchedule.friday.times = thursdayArray.length > 0 ? thursdayArray : ['9:00am-5:00pm'];
                    getSchedule.friday.active = isThursdayActive;
                }

                if (saturdayCopy) {
                    setIsSaturdayActive(false);
                    getSchedule.saturday.times = thursdayArray.length > 0 ? thursdayArray : ['9:00am-5:00pm'];
                    getSchedule.saturday.active = isThursdayActive;
                }

                setSchedule(getSchedule);

                setTimeout(() => {
                    if (sundayCopy) {
                        setIsSundayActive(isThursdayActive);
                    }

                    if (mondayCopy) {
                        setIsMondayActive(isThursdayActive);
                    }

                    if (tuesdayCopy) {
                        setIsTuesdayActive(isThursdayActive);
                    }

                    if (wednesdayCopy) {
                        setIsWednesdayActive(isThursdayActive);
                    }

                    if (fridayCopy) {
                        setIsFridayActive(isThursdayActive);
                    }

                    if (saturdayCopy) {
                        setIsSaturdayActive(isThursdayActive);
                    }

                }, 300)
                break;
            case "friday":
                const allSetsStart6 = document.getElementsByClassName("fridayStartTabs" + frameName);
                const allSetsEnd6 = document.getElementsByClassName("fridayEndTabs" + frameName);

                const length6 = allSetsStart6.length;
                let fridayArray = [];

                for (let i = 0; i < length6; i++) {
                    fridayArray.push(allSetsStart6[i].value + "-" + allSetsEnd6[i].value);
                }

                if (sundayCopy) {
                    setIsSundayActive(false);
                    getSchedule.sunday.times = fridayArray.length > 0 ? fridayArray : ['9:00am-5:00pm'];
                    getSchedule.sunday.active = isFridayActive;
                }

                if (mondayCopy) {
                    setIsMondayActive(false);
                    getSchedule.monday.times = fridayArray.length > 0 ? fridayArray : ['9:00am-5:00pm'];
                    getSchedule.monday.active = isFridayActive;
                }

                if (tuesdayCopy) {
                    setIsTuesdayActive(false);
                    getSchedule.tuesday.times = fridayArray.length > 0 ? fridayArray : ['9:00am-5:00pm'];
                    getSchedule.tuesday.active = isFridayActive;
                }

                if (wednesdayCopy) {
                    setIsWednesdayActive(false);
                    getSchedule.wednesday.times = fridayArray.length > 0 ? fridayArray : ['9:00am-5:00pm'];
                    getSchedule.wednesday.active = isFridayActive;
                }

                if (thursdayCopy) {
                    setIsThursdayActive(false);
                    getSchedule.thursday.times = fridayArray.length > 0 ? fridayArray : ['9:00am-5:00pm'];
                    getSchedule.thursday.active = isFridayActive;
                }

                if (saturdayCopy) {
                    setIsSaturdayActive(false);
                    getSchedule.saturday.times = fridayArray.length > 0 ? fridayArray : ['9:00am-5:00pm'];
                    getSchedule.saturday.active = isFridayActive;
                }

                setSchedule(getSchedule);

                setTimeout(() => {

                    if (sundayCopy) {
                        setIsSundayActive(isFridayActive);
                    }

                    if (mondayCopy) {
                        setIsMondayActive(isFridayActive);
                    }

                    if (tuesdayCopy) {
                        setIsTuesdayActive(isFridayActive);
                    }

                    if (wednesdayCopy) {
                        setIsWednesdayActive(isFridayActive);
                    }

                    if (thursdayCopy) {
                        setIsThursdayActive(isFridayActive);
                    }

                    if (saturdayCopy) {
                        setIsSaturdayActive(isFridayActive);
                    }

                }, 300)
                break;
            case "saturday":
                const allSetsStart7 = document.getElementsByClassName("saturdayStartTabs" + frameName);
                const allSetsEnd7 = document.getElementsByClassName("saturdayEndTabs" + frameName);

                const length7 = allSetsStart7.length;
                let saturdayArray = [];

                for (let i = 0; i < length7; i++) {
                    saturdayArray.push(allSetsStart7[i].value + "-" + allSetsEnd7[i].value);
                }


                if (sundayCopy) {
                    setIsSundayActive(false);
                    getSchedule.sunday.times = saturdayArray.length > 0 ? saturdayArray : ['9:00am-5:00pm'];
                    getSchedule.sunday.active = isSaturdayActive;
                }

                if (mondayCopy) {
                    setIsMondayActive(false);
                    getSchedule.monday.times = saturdayArray.length > 0 ? saturdayArray : ['9:00am-5:00pm'];
                    getSchedule.monday.active = isSaturdayActive;
                }

                if (tuesdayCopy) {
                    setIsTuesdayActive(false);
                    getSchedule.tuesday.times = saturdayArray.length > 0 ? saturdayArray : ['9:00am-5:00pm'];
                    getSchedule.tuesday.active = isSaturdayActive;
                }

                if (wednesdayCopy) {
                    setIsWednesdayActive(false);
                    getSchedule.wednesday.times = saturdayArray.length > 0 ? saturdayArray : ['9:00am-5:00pm'];
                    getSchedule.wednesday.active = isSaturdayActive;
                }

                if (thursdayCopy) {
                    setIsThursdayActive(false);
                    getSchedule.thursday.times = saturdayArray.length > 0 ? saturdayArray : ['9:00am-5:00pm'];
                    getSchedule.thursday.active = isSaturdayActive;
                }

                if (fridayCopy) {
                    setIsFridayActive(false);
                    getSchedule.friday.times = saturdayArray.length > 0 ? saturdayArray : ['9:00am-5:00pm'];
                    getSchedule.friday.active = isSaturdayActive;
                }


                setSchedule(getSchedule);

                setTimeout(() => {

                    if (sundayCopy) {
                        setIsSundayActive(isSaturdayActive);
                    }

                    if (mondayCopy) {
                        setIsMondayActive(isSaturdayActive);
                    }

                    if (tuesdayCopy) {
                        setIsTuesdayActive(isSaturdayActive);
                    }

                    if (wednesdayCopy) {
                        setIsWednesdayActive(isSaturdayActive);
                    }

                    if (thursdayCopy) {
                        setIsThursdayActive(isSaturdayActive);
                    }

                    if (fridayCopy) {
                        setIsFridayActive(isSaturdayActive);
                    }
                }, 300)

                break;
            default:
            // console.log("Invalid Date");
        }

        setSundayCopy(false);
        setMondayCopy(false);
        setTuesdayCopy(false);
        setWednesdayCopy(false);
        setThursdayCopy(false);
        setFridayCopy(false);
        setSaturdayCopy(false);

        setTimeout(() => { checkUpdatedStateOfTimeValidation(frameName) }, 1000)
    }

    const checkCustomLinkAvailable = async (username) => {
        let regEx = /[A-Za-z0-9-]/g;

        let final = username.target.value.replaceAll(" ", "-").match(regEx)?.join("")

        setCustomEventLink(final)

        const query = {
            "username": final,
            "email": user.email
        }

        const check = await axios.post(serverUrl + "/users/checkCustomLink", query);

        if (myEvent.id === final || check.status === 200) {
            setIsCustomLinkAvailable(true);
            setUpdateButtonEnabled(true);
        } else {
            setIsCustomLinkAvailable(false);
            setUpdateButtonEnabled(false);
        }
    }

    const validateAndSetScheduleName = (e) => {
        setNewScheduleName(e.target.value
            .replaceAll("  ", " ")
            .replaceAll("?", "")
            .replaceAll(".", "")
            .replaceAll("!", "")
            .replaceAll("@", "")
            .replaceAll("#", "")
            .replaceAll("$", "")
            .replaceAll("%", "")
            .replaceAll("^", "")
            .replaceAll("&", "")
            .replaceAll("*", "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll("_", "")
            .replaceAll("=", "")
            .replaceAll("+", "")
            .replaceAll("{", "")
            .replaceAll("}", "")
            .replaceAll("\\", "")
            .replaceAll("/", "")
            .replaceAll(":", "")
            .replaceAll("|", "")
            .replaceAll('"', '')
            .replaceAll("'", "")
            .replaceAll(",", "")
            .replaceAll("<", "")
            .replaceAll(">", "")
            .replaceAll("`", "")
            .replaceAll("~", "")
            .replaceAll("[", "")
            .replaceAll("]", "")
        );
    }

    const insertLinkToEvent = (e) => {
        e.preventDefault();
        setAddCustomLinkView(true);
        // console.log(customLinkName.current.value, customLinkData.current.value);
    }

    const filterTimeFieldNow = (e) => {


        e.target.value = e.target.value
            .replaceAll("  ", " ")
            .replaceAll("?", "")
            .replaceAll(".", "")
            .replaceAll("!", "")
            .replaceAll("@", "")
            .replaceAll("#", "")
            .replaceAll("$", "")
            .replaceAll("%", "")
            .replaceAll("^", "")
            .replaceAll("&", "")
            .replaceAll("*", "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll("_", "")
            .replaceAll("=", "")
            .replaceAll("+", "")
            .replaceAll("{", "")
            .replaceAll("}", "")
            .replaceAll("\\", "")
            .replaceAll("/", "")
            .replaceAll("|", "")
            .replaceAll('"', '')
            .replaceAll("'", "")
            .replaceAll(",", "")
            .replaceAll("<", "")
            .replaceAll(">", "")
            .replaceAll("`", "")
            .replaceAll("~", "")
            .replaceAll("[", "")
            .replaceAll("]", "")
    }

    const checkValidTiming = (e, type) => {
        // console.log("checking validation");
        let isEverythingOk = true;

        if (e.target.value.length <= 4) {
            e.target.style.border = "1px solid red";
            isEverythingOk = false;
        } else {

            if (timeListToMeasure.includes(e.target.value)) {
                e.target.style.border = "1px solid #ced4da";

                let allElements = e.target.classList;



                allElements.value.split(" ").map((className, key) => {

                    if (type === "override") {

                        let getElemts1 = document.getElementsByClassName("overrideStartTabs");
                        let getElemts2 = document.getElementsByClassName("overrideEndTabs");


                        let length = getElemts1.length;


                        for (let i = 0; i < length; i++) {
                            if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                                if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                                    // #########################################################
                                    let allStartTabs = [];

                                    for (let i = 0; i < length; i++) {
                                        allStartTabs.push(getElemts1[i].value);
                                    }

                                    allStartTabs.map((startT, key) => {
                                        function getAllIndexes(arr, val) {
                                            var indexes = [], i = -1;
                                            while ((i = arr.indexOf(val, i + 1)) != -1) {
                                                indexes.push(i);
                                            }
                                            return indexes;
                                        }

                                        var indexes = getAllIndexes(allStartTabs, startT);

                                        if (indexes.length > 1) {
                                            indexes.map((name, key) => {
                                                getElemts1[name].style.border = "1px solid red";
                                                getElemts2[name].style.border = "1px solid red";
                                            });
                                            isEverythingOk = false;
                                        } else {
                                            getElemts1[i].style.border = "1px solid #ced4da";
                                            getElemts2[i].style.border = "1px solid #ced4da";
                                        }
                                    });


                                    // #########################################################
                                }
                                else {
                                    getElemts1[i].style.border = "1px solid red";
                                    getElemts2[i].style.border = "1px solid red";

                                    isEverythingOk = false;
                                }
                            } else {
                                getElemts1[i].style.border = "1px solid red";
                                getElemts2[i].style.border = "1px solid red";

                                isEverythingOk = false;
                            }
                        }

                    } else {

                        if (className.includes("sunday")) {
                            let getElemts1 = document.getElementsByClassName(`sundayStartTabs${type}`);
                            let getElemts2 = document.getElementsByClassName(`sundayEndTabs${type}`);


                            let length = getElemts1.length;


                            for (let i = 0; i < length; i++) {
                                if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                                    if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                                        // #########################################################
                                        let allStartTabs = [];

                                        for (let i = 0; i < length; i++) {
                                            allStartTabs.push(getElemts1[i].value);
                                        }

                                        allStartTabs.map((startT, key) => {
                                            function getAllIndexes(arr, val) {
                                                var indexes = [], i = -1;
                                                while ((i = arr.indexOf(val, i + 1)) != -1) {
                                                    indexes.push(i);
                                                }
                                                return indexes;
                                            }

                                            var indexes = getAllIndexes(allStartTabs, startT);

                                            if (indexes.length > 1) {
                                                indexes.map((name, key) => {
                                                    getElemts1[name].style.border = "1px solid red";
                                                    getElemts2[name].style.border = "1px solid red";
                                                });
                                                isEverythingOk = false;
                                            } else {
                                                getElemts1[i].style.border = "1px solid #ced4da";
                                                getElemts2[i].style.border = "1px solid #ced4da";
                                            }
                                        });


                                        // #########################################################
                                    }
                                    else {
                                        getElemts1[i].style.border = "1px solid red";
                                        getElemts2[i].style.border = "1px solid red";

                                        isEverythingOk = false;
                                    }
                                } else {
                                    getElemts1[i].style.border = "1px solid red";
                                    getElemts2[i].style.border = "1px solid red";

                                    isEverythingOk = false;
                                }
                            }



                        }
                        else if (className.includes("monday")) {
                            let getElemts1 = document.getElementsByClassName(`mondayStartTabs${type}`);
                            let getElemts2 = document.getElementsByClassName(`mondayEndTabs${type}`);


                            let length = getElemts1.length;


                            for (let i = 0; i < length; i++) {
                                if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                                    if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                                        // #########################################################
                                        let allStartTabs = [];

                                        for (let i = 0; i < length; i++) {
                                            allStartTabs.push(getElemts1[i].value);
                                        }

                                        allStartTabs.map((startT, key) => {
                                            function getAllIndexes(arr, val) {
                                                var indexes = [], i = -1;
                                                while ((i = arr.indexOf(val, i + 1)) != -1) {
                                                    indexes.push(i);
                                                }
                                                return indexes;
                                            }

                                            var indexes = getAllIndexes(allStartTabs, startT);

                                            if (indexes.length > 1) {
                                                indexes.map((name, key) => {
                                                    getElemts1[name].style.border = "1px solid red";
                                                    getElemts2[name].style.border = "1px solid red";
                                                });
                                                isEverythingOk = false;
                                            } else {
                                                getElemts1[i].style.border = "1px solid #ced4da";
                                                getElemts2[i].style.border = "1px solid #ced4da";
                                            }
                                        });


                                        // #########################################################
                                    }
                                    else {
                                        getElemts1[i].style.border = "1px solid red";
                                        getElemts2[i].style.border = "1px solid red";

                                        isEverythingOk = false;
                                    }
                                } else {
                                    getElemts1[i].style.border = "1px solid red";
                                    getElemts2[i].style.border = "1px solid red";

                                    isEverythingOk = false;
                                }
                            }
                        }
                        else if (className.includes("tuesday")) {
                            let getElemts1 = document.getElementsByClassName(`tuesdayStartTabs${type}`);
                            let getElemts2 = document.getElementsByClassName(`tuesdayEndTabs${type}`);

                            let length = getElemts1.length;


                            for (let i = 0; i < length; i++) {
                                if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                                    if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                                        // #########################################################
                                        let allStartTabs = [];

                                        for (let i = 0; i < length; i++) {
                                            allStartTabs.push(getElemts1[i].value);
                                        }

                                        allStartTabs.map((startT, key) => {
                                            function getAllIndexes(arr, val) {
                                                var indexes = [], i = -1;
                                                while ((i = arr.indexOf(val, i + 1)) != -1) {
                                                    indexes.push(i);
                                                }
                                                return indexes;
                                            }

                                            var indexes = getAllIndexes(allStartTabs, startT);

                                            if (indexes.length > 1) {
                                                indexes.map((name, key) => {
                                                    getElemts1[name].style.border = "1px solid red";
                                                    getElemts2[name].style.border = "1px solid red";
                                                });
                                                isEverythingOk = false;
                                            } else {
                                                getElemts1[i].style.border = "1px solid #ced4da";
                                                getElemts2[i].style.border = "1px solid #ced4da";
                                            }
                                        });


                                        // #########################################################
                                    }
                                    else {
                                        getElemts1[i].style.border = "1px solid red";
                                        getElemts2[i].style.border = "1px solid red";

                                        isEverythingOk = false;
                                    }
                                } else {
                                    getElemts1[i].style.border = "1px solid red";
                                    getElemts2[i].style.border = "1px solid red";

                                    isEverythingOk = false;
                                }
                            }
                        }
                        else if (className.includes("wednesday")) {
                            let getElemts1 = document.getElementsByClassName(`wednesdayStartTabs${type}`);
                            let getElemts2 = document.getElementsByClassName(`wednesdayEndTabs${type}`);

                            let length = getElemts1.length;


                            for (let i = 0; i < length; i++) {
                                if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                                    if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                                        // #########################################################
                                        let allStartTabs = [];

                                        for (let i = 0; i < length; i++) {
                                            allStartTabs.push(getElemts1[i].value);
                                        }

                                        allStartTabs.map((startT, key) => {
                                            function getAllIndexes(arr, val) {
                                                var indexes = [], i = -1;
                                                while ((i = arr.indexOf(val, i + 1)) != -1) {
                                                    indexes.push(i);
                                                }
                                                return indexes;
                                            }

                                            var indexes = getAllIndexes(allStartTabs, startT);

                                            if (indexes.length > 1) {
                                                indexes.map((name, key) => {
                                                    getElemts1[name].style.border = "1px solid red";
                                                    getElemts2[name].style.border = "1px solid red";
                                                });
                                                isEverythingOk = false;
                                            } else {
                                                getElemts1[i].style.border = "1px solid #ced4da";
                                                getElemts2[i].style.border = "1px solid #ced4da";
                                            }
                                        });


                                        // #########################################################
                                    }
                                    else {
                                        getElemts1[i].style.border = "1px solid red";
                                        getElemts2[i].style.border = "1px solid red";

                                        isEverythingOk = false;
                                    }
                                } else {
                                    getElemts1[i].style.border = "1px solid red";
                                    getElemts2[i].style.border = "1px solid red";

                                    isEverythingOk = false;
                                }
                            }
                        }
                        else if (className.includes("thursday")) {
                            let getElemts1 = document.getElementsByClassName(`thursdayStartTabs${type}`);
                            let getElemts2 = document.getElementsByClassName(`thursdayEndTabs${type}`);

                            let length = getElemts1.length;


                            for (let i = 0; i < length; i++) {
                                if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                                    if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                                        // #########################################################
                                        let allStartTabs = [];

                                        for (let i = 0; i < length; i++) {
                                            allStartTabs.push(getElemts1[i].value);
                                        }

                                        allStartTabs.map((startT, key) => {
                                            function getAllIndexes(arr, val) {
                                                var indexes = [], i = -1;
                                                while ((i = arr.indexOf(val, i + 1)) != -1) {
                                                    indexes.push(i);
                                                }
                                                return indexes;
                                            }

                                            var indexes = getAllIndexes(allStartTabs, startT);

                                            if (indexes.length > 1) {
                                                indexes.map((name, key) => {
                                                    getElemts1[name].style.border = "1px solid red";
                                                    getElemts2[name].style.border = "1px solid red";
                                                });
                                                isEverythingOk = false;
                                            } else {
                                                getElemts1[i].style.border = "1px solid #ced4da";
                                                getElemts2[i].style.border = "1px solid #ced4da";
                                            }
                                        });


                                        // #########################################################
                                    }
                                    else {
                                        getElemts1[i].style.border = "1px solid red";
                                        getElemts2[i].style.border = "1px solid red";

                                        isEverythingOk = false;
                                    }
                                } else {
                                    getElemts1[i].style.border = "1px solid red";
                                    getElemts2[i].style.border = "1px solid red";

                                    isEverythingOk = false;
                                }
                            }
                        }
                        else if (className.includes("friday")) {
                            let getElemts1 = document.getElementsByClassName(`fridayStartTabs${type}`);
                            let getElemts2 = document.getElementsByClassName(`fridayEndTabs${type}`);

                            let length = getElemts1.length;


                            for (let i = 0; i < length; i++) {
                                if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                                    if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                                        // #########################################################
                                        let allStartTabs = [];

                                        for (let i = 0; i < length; i++) {
                                            allStartTabs.push(getElemts1[i].value);
                                        }

                                        allStartTabs.map((startT, key) => {
                                            function getAllIndexes(arr, val) {
                                                var indexes = [], i = -1;
                                                while ((i = arr.indexOf(val, i + 1)) != -1) {
                                                    indexes.push(i);
                                                }
                                                return indexes;
                                            }

                                            var indexes = getAllIndexes(allStartTabs, startT);

                                            if (indexes.length > 1) {
                                                indexes.map((name, key) => {
                                                    getElemts1[name].style.border = "1px solid red";
                                                    getElemts2[name].style.border = "1px solid red";
                                                });
                                                isEverythingOk = false;
                                            } else {
                                                getElemts1[i].style.border = "1px solid #ced4da";
                                                getElemts2[i].style.border = "1px solid #ced4da";
                                            }
                                        });


                                        // #########################################################
                                    }
                                    else {
                                        getElemts1[i].style.border = "1px solid red";
                                        getElemts2[i].style.border = "1px solid red";

                                        isEverythingOk = false;
                                    }
                                } else {
                                    getElemts1[i].style.border = "1px solid red";
                                    getElemts2[i].style.border = "1px solid red";

                                    isEverythingOk = false;
                                }
                            }
                        }
                        else if (className.includes("saturday")) {
                            let getElemts1 = document.getElementsByClassName(`saturdayStartTabs${type}`);
                            let getElemts2 = document.getElementsByClassName(`saturdayEndTabs${type}`);

                            let length = getElemts1.length;


                            for (let i = 0; i < length; i++) {
                                if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                                    if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                                        // #########################################################
                                        let allStartTabs = [];

                                        for (let i = 0; i < length; i++) {
                                            allStartTabs.push(getElemts1[i].value);
                                        }

                                        allStartTabs.map((startT, key) => {
                                            function getAllIndexes(arr, val) {
                                                var indexes = [], i = -1;
                                                while ((i = arr.indexOf(val, i + 1)) != -1) {
                                                    indexes.push(i);
                                                }
                                                return indexes;
                                            }

                                            var indexes = getAllIndexes(allStartTabs, startT);

                                            if (indexes.length > 1) {
                                                indexes.map((name, key) => {
                                                    getElemts1[name].style.border = "1px solid red";
                                                    getElemts2[name].style.border = "1px solid red";
                                                });
                                                isEverythingOk = false;
                                            } else {
                                                getElemts1[i].style.border = "1px solid #ced4da";
                                                getElemts2[i].style.border = "1px solid #ced4da";
                                            }
                                        });


                                        // #########################################################
                                    }
                                    else {
                                        getElemts1[i].style.border = "1px solid red";
                                        getElemts2[i].style.border = "1px solid red";

                                        isEverythingOk = false;
                                    }
                                } else {
                                    getElemts1[i].style.border = "1px solid red";
                                    getElemts2[i].style.border = "1px solid red";

                                    isEverythingOk = false;
                                }
                            }
                        }
                    }

                });

            } else {
                e.target.style.border = "1px solid red";
                isEverythingOk = false;
            }
        }

        if (!isEverythingOk) {
            if (type === "override") {
                setOverrideButtonEnabled(false);
            } else {
                setUpdateButtonEnabled(false);
            }
        } else {
            if (type === "override") {
                setOverrideButtonEnabled(true);
            } else {
                setUpdateButtonEnabled(true);
            }
        }
    }

    // const checkUpdatedStateOfTimeValidation = (dayName, type) => {
    //     let isEverythingOk = true;

    //     console.log(dayName, type)

    //     if (dayName === "sunday") {

    //         var getElemts1 = document.getElementsByClassName(`sundayStartTabs${type}`);
    //         var getElemts2 = document.getElementsByClassName(`sundayEndTabs${type}`);

    //         var length = getElemts1.length;

    //         for (let i = 0; i < length; i++) {
    //             if (getElemts1[i].value.length >= 6 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
    //                 if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
    //                     // #########################################################
    //                     let allStartTabs = [];

    //                     for (let i = 0; i < length; i++) {
    //                         allStartTabs.push(getElemts1[i].value);
    //                     }

    //                     allStartTabs.map((startT, key) => {
    //                         function getAllIndexes(arr, val) {
    //                             var indexes = [], i = -1;
    //                             while ((i = arr.indexOf(val, i + 1)) != -1) {
    //                                 indexes.push(i);
    //                             }
    //                             return indexes;
    //                         }

    //                         var indexes = getAllIndexes(allStartTabs, startT);

    //                         if (indexes.length > 1) {
    //                             indexes.map((name, key) => {
    //                                 getElemts1[name].style.border = "1px solid red";
    //                                 getElemts2[name].style.border = "1px solid red";
    //                             });
    //                             isEverythingOk = false;
    //                         } else {
    //                             getElemts1[i].style.border = "1px solid #ced4da";
    //                             getElemts2[i].style.border = "1px solid #ced4da";
    //                         }
    //                     });


    //                     // #########################################################
    //                 }
    //                 else {
    //                     getElemts1[i].style.border = "1px solid red";
    //                     getElemts2[i].style.border = "1px solid red";

    //                     isEverythingOk = false;
    //                 }
    //             } else {
    //                 getElemts1[i].style.border = "1px solid red";
    //                 getElemts2[i].style.border = "1px solid red";

    //                 isEverythingOk = false;
    //             }
    //         }
    //     }
    //     else if (dayName === "monday") {

    //         let getElemts1 = document.getElementsByClassName(`mondayStartTabs${type}`);
    //         let getElemts2 = document.getElementsByClassName(`mondayEndTabs${type}`);


    //         let length = getElemts1.length;


    //         for (let i = 0; i < length; i++) {
    //             if (getElemts1[i].value.length >= 6 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
    //                 if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
    //                     // #########################################################
    //                     let allStartTabs = [];

    //                     for (let i = 0; i < length; i++) {
    //                         allStartTabs.push(getElemts1[i].value);
    //                     }

    //                     allStartTabs.map((startT, key) => {
    //                         function getAllIndexes(arr, val) {
    //                             var indexes = [], i = -1;
    //                             while ((i = arr.indexOf(val, i + 1)) != -1) {
    //                                 indexes.push(i);
    //                             }
    //                             return indexes;
    //                         }

    //                         var indexes = getAllIndexes(allStartTabs, startT);

    //                         if (indexes.length > 1) {
    //                             indexes.map((name, key) => {
    //                                 getElemts1[name].style.border = "1px solid red";
    //                                 getElemts2[name].style.border = "1px solid red";
    //                             });
    //                             isEverythingOk = false;
    //                         } else {
    //                             getElemts1[i].style.border = "1px solid #ced4da";
    //                             getElemts2[i].style.border = "1px solid #ced4da";
    //                         }
    //                     });


    //                     // #########################################################
    //                 }
    //                 else {
    //                     getElemts1[i].style.border = "1px solid red";
    //                     getElemts2[i].style.border = "1px solid red";

    //                     isEverythingOk = false;
    //                 }
    //             } else {
    //                 getElemts1[i].style.border = "1px solid red";
    //                 getElemts2[i].style.border = "1px solid red";

    //                 isEverythingOk = false;
    //             }
    //         }
    //     }
    //     else if (dayName === "tuesday") {
    //         let getElemts1 = document.getElementsByClassName(`tuesdayStartTabs${type}`);
    //         let getElemts2 = document.getElementsByClassName(`tuesdayEndTabs${type}`);

    //         let length = getElemts1.length;


    //         for (let i = 0; i < length; i++) {
    //             if (getElemts1[i].value.length >= 6 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
    //                 if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
    //                     // #########################################################
    //                     let allStartTabs = [];

    //                     for (let i = 0; i < length; i++) {
    //                         allStartTabs.push(getElemts1[i].value);
    //                     }

    //                     allStartTabs.map((startT, key) => {
    //                         function getAllIndexes(arr, val) {
    //                             var indexes = [], i = -1;
    //                             while ((i = arr.indexOf(val, i + 1)) != -1) {
    //                                 indexes.push(i);
    //                             }
    //                             return indexes;
    //                         }

    //                         var indexes = getAllIndexes(allStartTabs, startT);

    //                         if (indexes.length > 1) {
    //                             indexes.map((name, key) => {
    //                                 getElemts1[name].style.border = "1px solid red";
    //                                 getElemts2[name].style.border = "1px solid red";
    //                             });
    //                             isEverythingOk = false;
    //                         } else {
    //                             getElemts1[i].style.border = "1px solid #ced4da";
    //                             getElemts2[i].style.border = "1px solid #ced4da";
    //                         }
    //                     });


    //                     // #########################################################
    //                 }
    //                 else {
    //                     getElemts1[i].style.border = "1px solid red";
    //                     getElemts2[i].style.border = "1px solid red";

    //                     isEverythingOk = false;
    //                 }
    //             } else {
    //                 getElemts1[i].style.border = "1px solid red";
    //                 getElemts2[i].style.border = "1px solid red";

    //                 isEverythingOk = false;
    //             }
    //         }
    //     }
    //     else if (dayName === "wednesday") {
    //         let getElemts1 = document.getElementsByClassName(`wednesdayStartTabs${type}`);
    //         let getElemts2 = document.getElementsByClassName(`wednesdayEndTabs${type}`);

    //         let length = getElemts1.length;


    //         for (let i = 0; i < length; i++) {
    //             if (getElemts1[i].value.length >= 6 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
    //                 if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
    //                     // #########################################################
    //                     let allStartTabs = [];

    //                     for (let i = 0; i < length; i++) {
    //                         allStartTabs.push(getElemts1[i].value);
    //                     }

    //                     allStartTabs.map((startT, key) => {
    //                         function getAllIndexes(arr, val) {
    //                             var indexes = [], i = -1;
    //                             while ((i = arr.indexOf(val, i + 1)) != -1) {
    //                                 indexes.push(i);
    //                             }
    //                             return indexes;
    //                         }

    //                         var indexes = getAllIndexes(allStartTabs, startT);

    //                         if (indexes.length > 1) {
    //                             indexes.map((name, key) => {
    //                                 getElemts1[name].style.border = "1px solid red";
    //                                 getElemts2[name].style.border = "1px solid red";
    //                             });
    //                             isEverythingOk = false;
    //                         } else {
    //                             getElemts1[i].style.border = "1px solid #ced4da";
    //                             getElemts2[i].style.border = "1px solid #ced4da";
    //                         }
    //                     });


    //                     // #########################################################
    //                 }
    //                 else {
    //                     getElemts1[i].style.border = "1px solid red";
    //                     getElemts2[i].style.border = "1px solid red";

    //                     isEverythingOk = false;
    //                 }
    //             } else {
    //                 getElemts1[i].style.border = "1px solid red";
    //                 getElemts2[i].style.border = "1px solid red";

    //                 isEverythingOk = false;
    //             }
    //         }
    //     }
    //     else if (dayName === "thursday") {
    //         let getElemts1 = document.getElementsByClassName(`thursdayStartTabs${type}`);
    //         let getElemts2 = document.getElementsByClassName(`thursdayEndTabs${type}`);

    //         let length = getElemts1.length;


    //         for (let i = 0; i < length; i++) {
    //             if (getElemts1[i].value.length >= 6 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
    //                 if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
    //                     // #########################################################
    //                     let allStartTabs = [];

    //                     for (let i = 0; i < length; i++) {
    //                         allStartTabs.push(getElemts1[i].value);
    //                     }

    //                     allStartTabs.map((startT, key) => {
    //                         function getAllIndexes(arr, val) {
    //                             var indexes = [], i = -1;
    //                             while ((i = arr.indexOf(val, i + 1)) != -1) {
    //                                 indexes.push(i);
    //                             }
    //                             return indexes;
    //                         }

    //                         var indexes = getAllIndexes(allStartTabs, startT);

    //                         if (indexes.length > 1) {
    //                             indexes.map((name, key) => {
    //                                 getElemts1[name].style.border = "1px solid red";
    //                                 getElemts2[name].style.border = "1px solid red";
    //                             });
    //                             isEverythingOk = false;
    //                         } else {
    //                             getElemts1[i].style.border = "1px solid #ced4da";
    //                             getElemts2[i].style.border = "1px solid #ced4da";
    //                         }
    //                     });


    //                     // #########################################################
    //                 }
    //                 else {
    //                     getElemts1[i].style.border = "1px solid red";
    //                     getElemts2[i].style.border = "1px solid red";

    //                     isEverythingOk = false;
    //                 }
    //             } else {
    //                 getElemts1[i].style.border = "1px solid red";
    //                 getElemts2[i].style.border = "1px solid red";

    //                 isEverythingOk = false;
    //             }
    //         }
    //     }
    //     else if (dayName === "friday") {
    //         let getElemts1 = document.getElementsByClassName(`fridayStartTabs${type}`);
    //         let getElemts2 = document.getElementsByClassName(`fridayEndTabs${type}`);

    //         let length = getElemts1.length;


    //         for (let i = 0; i < length; i++) {
    //             if (getElemts1[i].value.length >= 6 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
    //                 if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
    //                     // #########################################################
    //                     let allStartTabs = [];

    //                     for (let i = 0; i < length; i++) {
    //                         allStartTabs.push(getElemts1[i].value);
    //                     }

    //                     allStartTabs.map((startT, key) => {
    //                         function getAllIndexes(arr, val) {
    //                             var indexes = [], i = -1;
    //                             while ((i = arr.indexOf(val, i + 1)) != -1) {
    //                                 indexes.push(i);
    //                             }
    //                             return indexes;
    //                         }

    //                         var indexes = getAllIndexes(allStartTabs, startT);

    //                         if (indexes.length > 1) {
    //                             indexes.map((name, key) => {
    //                                 getElemts1[name].style.border = "1px solid red";
    //                                 getElemts2[name].style.border = "1px solid red";
    //                             });
    //                             isEverythingOk = false;
    //                         } else {
    //                             getElemts1[i].style.border = "1px solid #ced4da";
    //                             getElemts2[i].style.border = "1px solid #ced4da";
    //                         }
    //                     });


    //                     // #########################################################
    //                 }
    //                 else {
    //                     getElemts1[i].style.border = "1px solid red";
    //                     getElemts2[i].style.border = "1px solid red";

    //                     isEverythingOk = false;
    //                 }
    //             } else {
    //                 getElemts1[i].style.border = "1px solid red";
    //                 getElemts2[i].style.border = "1px solid red";

    //                 isEverythingOk = false;
    //             }
    //         }

    //     }
    //     else if (dayName === "saturday") {

    //         let getElemts1 = document.getElementsByClassName(`saturdayStartTabs${type}`);
    //         let getElemts2 = document.getElementsByClassName(`saturdayEndTabs${type}`);

    //         let length = getElemts1.length;


    //         for (let i = 0; i < length; i++) {
    //             if (getElemts1[i].value.length >= 6 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
    //                 if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
    //                     // #########################################################
    //                     let allStartTabs = [];

    //                     for (let i = 0; i < length; i++) {
    //                         allStartTabs.push(getElemts1[i].value);
    //                     }

    //                     allStartTabs.map((startT, key) => {
    //                         function getAllIndexes(arr, val) {
    //                             var indexes = [], i = -1;
    //                             while ((i = arr.indexOf(val, i + 1)) != -1) {
    //                                 indexes.push(i);
    //                             }
    //                             return indexes;
    //                         }

    //                         var indexes = getAllIndexes(allStartTabs, startT);

    //                         if (indexes.length > 1) {
    //                             indexes.map((name, key) => {
    //                                 getElemts1[name].style.border = "1px solid red";
    //                                 getElemts2[name].style.border = "1px solid red";
    //                             });
    //                             isEverythingOk = false;
    //                         } else {
    //                             getElemts1[i].style.border = "1px solid #ced4da";
    //                             getElemts2[i].style.border = "1px solid #ced4da";
    //                         }
    //                     });


    //                     // #########################################################
    //                 }
    //                 else {
    //                     getElemts1[i].style.border = "1px solid red";
    //                     getElemts2[i].style.border = "1px solid red";

    //                     isEverythingOk = false;
    //                 }
    //             } else {
    //                 getElemts1[i].style.border = "1px solid red";
    //                 getElemts2[i].style.border = "1px solid red";

    //                 isEverythingOk = false;
    //             }
    //         }
    //     }


    //     if (!isEverythingOk) {
    //         setUpdateButtonEnabled(false);
    //     } else {
    //         setUpdateButtonEnabled(true);
    //     }
    // }

    const checkUpdatedStateOfTimeValidation = (frameName) => {
        let isEverythingOk = true;


        var getElemts1 = document.getElementsByClassName(`sundayStartTabs${frameName}`);
        var getElemts2 = document.getElementsByClassName(`sundayEndTabs${frameName}`);

        var length = getElemts1.length;

        if (length > 0) {
            for (let i = 0; i < length; i++) {
                if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                    if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                        // #########################################################
                        let allStartTabs = [];

                        for (let i = 0; i < length; i++) {
                            allStartTabs.push(getElemts1[i].value);
                        }

                        allStartTabs.map((startT, key) => {
                            function getAllIndexes(arr, val) {
                                var indexes = [], i = -1;
                                while ((i = arr.indexOf(val, i + 1)) != -1) {
                                    indexes.push(i);
                                }
                                return indexes;
                            }

                            var indexes = getAllIndexes(allStartTabs, startT);

                            if (indexes.length > 1) {
                                indexes.map((name, key) => {
                                    getElemts1[name].style.border = "1px solid red";
                                    getElemts2[name].style.border = "1px solid red";
                                });
                                isEverythingOk = false;
                            } else {
                                getElemts1[i].style.border = "1px solid #ced4da";
                                getElemts2[i].style.border = "1px solid #ced4da";
                            }
                        });


                        // #########################################################
                    }
                    else {
                        getElemts1[i].style.border = "1px solid red";
                        getElemts2[i].style.border = "1px solid red";

                        isEverythingOk = false;
                    }
                } else {
                    getElemts1[i].style.border = "1px solid red";
                    getElemts2[i].style.border = "1px solid red";

                    isEverythingOk = false;
                }
            }
        }


        var getElemts1 = document.getElementsByClassName(`mondayStartTabs${frameName}`);
        var getElemts2 = document.getElementsByClassName(`mondayEndTabs${frameName}`);


        var length = getElemts1.length;


        if (length > 0) {
            for (let i = 0; i < length; i++) {
                if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                    if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                        // #########################################################
                        let allStartTabs = [];

                        for (let i = 0; i < length; i++) {
                            allStartTabs.push(getElemts1[i].value);
                        }

                        allStartTabs.map((startT, key) => {
                            function getAllIndexes(arr, val) {
                                var indexes = [], i = -1;
                                while ((i = arr.indexOf(val, i + 1)) != -1) {
                                    indexes.push(i);
                                }
                                return indexes;
                            }

                            var indexes = getAllIndexes(allStartTabs, startT);

                            if (indexes.length > 1) {
                                indexes.map((name, key) => {
                                    getElemts1[name].style.border = "1px solid red";
                                    getElemts2[name].style.border = "1px solid red";
                                });
                                isEverythingOk = false;
                            } else {
                                getElemts1[i].style.border = "1px solid #ced4da";
                                getElemts2[i].style.border = "1px solid #ced4da";
                            }
                        });


                        // #########################################################
                    }
                    else {
                        getElemts1[i].style.border = "1px solid red";
                        getElemts2[i].style.border = "1px solid red";

                        isEverythingOk = false;
                    }
                } else {
                    getElemts1[i].style.border = "1px solid red";
                    getElemts2[i].style.border = "1px solid red";

                    isEverythingOk = false;
                }
            }
        }

        var getElemts1 = document.getElementsByClassName(`tuesdayStartTabs${frameName}`);
        var getElemts2 = document.getElementsByClassName(`tuesdayEndTabs${frameName}`);

        var length = getElemts1.length;


        if (length > 0) {
            for (let i = 0; i < length; i++) {
                if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                    if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                        // #########################################################
                        let allStartTabs = [];

                        for (let i = 0; i < length; i++) {
                            allStartTabs.push(getElemts1[i].value);
                        }

                        allStartTabs.map((startT, key) => {
                            function getAllIndexes(arr, val) {
                                var indexes = [], i = -1;
                                while ((i = arr.indexOf(val, i + 1)) != -1) {
                                    indexes.push(i);
                                }
                                return indexes;
                            }

                            var indexes = getAllIndexes(allStartTabs, startT);

                            if (indexes.length > 1) {
                                indexes.map((name, key) => {
                                    getElemts1[name].style.border = "1px solid red";
                                    getElemts2[name].style.border = "1px solid red";
                                });
                                isEverythingOk = false;
                            } else {
                                getElemts1[i].style.border = "1px solid #ced4da";
                                getElemts2[i].style.border = "1px solid #ced4da";
                            }
                        });


                        // #########################################################
                    }
                    else {
                        getElemts1[i].style.border = "1px solid red";
                        getElemts2[i].style.border = "1px solid red";

                        isEverythingOk = false;
                    }
                } else {
                    getElemts1[i].style.border = "1px solid red";
                    getElemts2[i].style.border = "1px solid red";

                    isEverythingOk = false;
                }
            }
        }


        var getElemts1 = document.getElementsByClassName(`wednesdayStartTabs${frameName}`);
        var getElemts2 = document.getElementsByClassName(`wednesdayEndTabs${frameName}`);

        var length = getElemts1.length;


        if (length > 0) {
            for (let i = 0; i < length; i++) {
                if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                    if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                        // #########################################################
                        let allStartTabs = [];

                        for (let i = 0; i < length; i++) {
                            allStartTabs.push(getElemts1[i].value);
                        }

                        allStartTabs.map((startT, key) => {
                            function getAllIndexes(arr, val) {
                                var indexes = [], i = -1;
                                while ((i = arr.indexOf(val, i + 1)) != -1) {
                                    indexes.push(i);
                                }
                                return indexes;
                            }

                            var indexes = getAllIndexes(allStartTabs, startT);

                            if (indexes.length > 1) {
                                indexes.map((name, key) => {
                                    getElemts1[name].style.border = "1px solid red";
                                    getElemts2[name].style.border = "1px solid red";
                                });
                                isEverythingOk = false;
                            } else {
                                getElemts1[i].style.border = "1px solid #ced4da";
                                getElemts2[i].style.border = "1px solid #ced4da";
                            }
                        });


                        // #########################################################
                    }
                    else {
                        getElemts1[i].style.border = "1px solid red";
                        getElemts2[i].style.border = "1px solid red";

                        isEverythingOk = false;
                    }
                } else {
                    getElemts1[i].style.border = "1px solid red";
                    getElemts2[i].style.border = "1px solid red";

                    isEverythingOk = false;
                }
            }
        }


        var getElemts1 = document.getElementsByClassName(`thursdayStartTabs${frameName}`);
        var getElemts2 = document.getElementsByClassName(`thursdayEndTabs${frameName}`);

        var length = getElemts1.length;


        if (length > 0) {
            for (let i = 0; i < length; i++) {
                if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                    if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                        // #########################################################
                        let allStartTabs = [];

                        for (let i = 0; i < length; i++) {
                            allStartTabs.push(getElemts1[i].value);
                        }

                        allStartTabs.map((startT, key) => {
                            function getAllIndexes(arr, val) {
                                var indexes = [], i = -1;
                                while ((i = arr.indexOf(val, i + 1)) != -1) {
                                    indexes.push(i);
                                }
                                return indexes;
                            }

                            var indexes = getAllIndexes(allStartTabs, startT);

                            if (indexes.length > 1) {
                                indexes.map((name, key) => {
                                    getElemts1[name].style.border = "1px solid red";
                                    getElemts2[name].style.border = "1px solid red";
                                });
                                isEverythingOk = false;
                            } else {
                                getElemts1[i].style.border = "1px solid #ced4da";
                                getElemts2[i].style.border = "1px solid #ced4da";
                            }
                        });


                        // #########################################################
                    }
                    else {
                        getElemts1[i].style.border = "1px solid red";
                        getElemts2[i].style.border = "1px solid red";

                        isEverythingOk = false;
                    }
                } else {
                    getElemts1[i].style.border = "1px solid red";
                    getElemts2[i].style.border = "1px solid red";

                    isEverythingOk = false;
                }
            }
        }



        var getElemts1 = document.getElementsByClassName(`fridayStartTabs${frameName}`);
        var getElemts2 = document.getElementsByClassName(`fridayEndTabs${frameName}`);

        var length = getElemts1.length;


        if (length > 0) {
            for (let i = 0; i < length; i++) {
                if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                    if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                        // #########################################################
                        let allStartTabs = [];

                        for (let i = 0; i < length; i++) {
                            allStartTabs.push(getElemts1[i].value);
                        }

                        allStartTabs.map((startT, key) => {
                            function getAllIndexes(arr, val) {
                                var indexes = [], i = -1;
                                while ((i = arr.indexOf(val, i + 1)) != -1) {
                                    indexes.push(i);
                                }
                                return indexes;
                            }

                            var indexes = getAllIndexes(allStartTabs, startT);

                            if (indexes.length > 1) {
                                indexes.map((name, key) => {
                                    getElemts1[name].style.border = "1px solid red";
                                    getElemts2[name].style.border = "1px solid red";
                                });
                                isEverythingOk = false;
                            } else {
                                getElemts1[i].style.border = "1px solid #ced4da";
                                getElemts2[i].style.border = "1px solid #ced4da";
                            }
                        });


                        // #########################################################
                    }
                    else {
                        getElemts1[i].style.border = "1px solid red";
                        getElemts2[i].style.border = "1px solid red";

                        isEverythingOk = false;
                    }
                } else {
                    getElemts1[i].style.border = "1px solid red";
                    getElemts2[i].style.border = "1px solid red";

                    isEverythingOk = false;
                }
            }
        }



        var getElemts1 = document.getElementsByClassName(`saturdayStartTabs${frameName}`);
        var getElemts2 = document.getElementsByClassName(`saturdayEndTabs${frameName}`);

        var length = getElemts1.length;


        if (length > 0) {
            for (let i = 0; i < length; i++) {
                if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                    if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                        // #########################################################
                        let allStartTabs = [];

                        for (let i = 0; i < length; i++) {
                            allStartTabs.push(getElemts1[i].value);
                        }

                        allStartTabs.map((startT, key) => {
                            function getAllIndexes(arr, val) {
                                var indexes = [], i = -1;
                                while ((i = arr.indexOf(val, i + 1)) != -1) {
                                    indexes.push(i);
                                }
                                return indexes;
                            }

                            var indexes = getAllIndexes(allStartTabs, startT);

                            if (indexes.length > 1) {
                                indexes.map((name, key) => {
                                    getElemts1[name].style.border = "1px solid red";
                                    getElemts2[name].style.border = "1px solid red";
                                });
                                isEverythingOk = false;
                            } else {
                                getElemts1[i].style.border = "1px solid #ced4da";
                                getElemts2[i].style.border = "1px solid #ced4da";
                            }
                        });


                        // #########################################################
                    }
                    else {
                        getElemts1[i].style.border = "1px solid red";
                        getElemts2[i].style.border = "1px solid red";

                        isEverythingOk = false;
                    }
                } else {
                    getElemts1[i].style.border = "1px solid red";
                    getElemts2[i].style.border = "1px solid red";

                    isEverythingOk = false;
                }
            }
        }


        var getElemts1 = document.getElementsByClassName(`overrideStartTabs`);
        var getElemts2 = document.getElementsByClassName(`overrideEndTabs`);

        var length = getElemts1.length;

        if (length > 0) {
            for (let i = 0; i < length; i++) {
                if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                    if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                        // #########################################################
                        let allStartTabs = [];

                        for (let i = 0; i < length; i++) {
                            allStartTabs.push(getElemts1[i].value);
                        }

                        allStartTabs.map((startT, key) => {
                            function getAllIndexes(arr, val) {
                                var indexes = [], i = -1;
                                while ((i = arr.indexOf(val, i + 1)) != -1) {
                                    indexes.push(i);
                                }
                                return indexes;
                            }

                            var indexes = getAllIndexes(allStartTabs, startT);

                            if (indexes.length > 1) {
                                indexes.map((name, key) => {
                                    getElemts1[name].style.border = "1px solid red";
                                    getElemts2[name].style.border = "1px solid red";
                                });
                                isEverythingOk = false;
                            } else {
                                getElemts1[i].style.border = "1px solid #ced4da";
                                getElemts2[i].style.border = "1px solid #ced4da";
                            }
                        });


                        // #########################################################
                    }
                    else {
                        getElemts1[i].style.border = "1px solid red";
                        getElemts2[i].style.border = "1px solid red";

                        isEverythingOk = false;
                    }
                } else {
                    getElemts1[i].style.border = "1px solid red";
                    getElemts2[i].style.border = "1px solid red";

                    isEverythingOk = false;
                }
            }
        }

        if (!isEverythingOk) {
            setUpdateButtonEnabled(false);
        } else {
            setUpdateButtonEnabled(true);
        }
    }

    const checkOverridesTimesCorrect = () => {
        let isEverythingOk = true;

        const allTabs = document.getElementsByClassName("overrideEndTabs");

        if (allTabs.length < 1) {
            setOverrideButtonEnabled(false);
        } else {

            var getElemts1 = document.getElementsByClassName(`overrideStartTabs`);
            var getElemts2 = document.getElementsByClassName(`overrideEndTabs`);

            var length = getElemts1.length;

            if (length > 0) {
                for (let i = 0; i < length; i++) {
                    if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                        if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                            // #########################################################
                            let allStartTabs = [];

                            for (let i = 0; i < length; i++) {
                                allStartTabs.push(getElemts1[i].value);
                            }

                            allStartTabs.map((startT, key) => {
                                function getAllIndexes(arr, val) {
                                    var indexes = [], i = -1;
                                    while ((i = arr.indexOf(val, i + 1)) != -1) {
                                        indexes.push(i);
                                    }
                                    return indexes;
                                }

                                var indexes = getAllIndexes(allStartTabs, startT);

                                if (indexes.length > 1) {
                                    indexes.map((name, key) => {
                                        getElemts1[name].style.border = "1px solid red";
                                        getElemts2[name].style.border = "1px solid red";
                                    });
                                    isEverythingOk = false;
                                } else {
                                    getElemts1[i].style.border = "1px solid #ced4da";
                                    getElemts2[i].style.border = "1px solid #ced4da";
                                }
                            });


                            // #########################################################
                        }
                        else {
                            getElemts1[i].style.border = "1px solid red";
                            getElemts2[i].style.border = "1px solid red";

                            isEverythingOk = false;
                        }
                    } else {
                        getElemts1[i].style.border = "1px solid red";
                        getElemts2[i].style.border = "1px solid red";

                        isEverythingOk = false;
                    }
                }
            }

        }


        if (!isEverythingOk) {
            setOverrideButtonEnabled(false);
        } else {
            setOverrideButtonEnabled(true);
        }
    }

    const checkDayOnToggle = (sunday, monday, tuesday, wednesday, thursday, friday, saturday, type) => {
        let isEverythingOk = true;


        if (sunday) {


            var getElemts1 = document.getElementsByClassName(`sundayStartTabs${type}`);
            var getElemts2 = document.getElementsByClassName(`sundayEndTabs${type}`);

            var length = getElemts1.length;

            if (length > 0) {
                for (let i = 0; i < length; i++) {
                    if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                        if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                            getElemts1[i].style.border = "1px solid #ced4da";
                            getElemts2[i].style.border = "1px solid #ced4da";
                        }
                        else {
                            getElemts1[i].style.border = "1px solid red";
                            getElemts2[i].style.border = "1px solid red";

                            isEverythingOk = false;
                        }
                    } else {
                        getElemts1[i].style.border = "1px solid red";
                        getElemts2[i].style.border = "1px solid red";

                        isEverythingOk = false;
                    }
                }
            }
        }


        if (monday) {
            var getElemts1 = document.getElementsByClassName(`mondayStartTabs${type}`);
            var getElemts2 = document.getElementsByClassName(`mondayEndTabs${type}`);


            var length = getElemts1.length;


            if (length > 0) {
                for (let i = 0; i < length; i++) {
                    if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                        if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                            getElemts1[i].style.border = "1px solid #ced4da";
                            getElemts2[i].style.border = "1px solid #ced4da";
                        }
                        else {
                            getElemts1[i].style.border = "1px solid red";
                            getElemts2[i].style.border = "1px solid red";

                            isEverythingOk = false;
                        }
                    } else {
                        getElemts1[i].style.border = "1px solid red";
                        getElemts2[i].style.border = "1px solid red";

                        isEverythingOk = false;
                    }
                }
            }
        }

        if (tuesday) {
            var getElemts1 = document.getElementsByClassName(`tuesdayStartTabs${type}`);
            var getElemts2 = document.getElementsByClassName(`tuesdayEndTabs${type}`);

            var length = getElemts1.length;


            if (length > 0) {
                for (let i = 0; i < length; i++) {
                    if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                        if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                            getElemts1[i].style.border = "1px solid #ced4da";
                            getElemts2[i].style.border = "1px solid #ced4da";
                        }
                        else {
                            getElemts1[i].style.border = "1px solid red";
                            getElemts2[i].style.border = "1px solid red";

                            isEverythingOk = false;
                        }
                    } else {
                        getElemts1[i].style.border = "1px solid red";
                        getElemts2[i].style.border = "1px solid red";

                        isEverythingOk = false;
                    }
                }
            }
        }


        if (wednesday) {
            var getElemts1 = document.getElementsByClassName(`wednesdayStartTabs${type}`);
            var getElemts2 = document.getElementsByClassName(`wednesdayEndTabs${type}`);

            var length = getElemts1.length;


            if (length > 0) {
                for (let i = 0; i < length; i++) {
                    if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                        if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                            getElemts1[i].style.border = "1px solid #ced4da";
                            getElemts2[i].style.border = "1px solid #ced4da";
                        }
                        else {
                            getElemts1[i].style.border = "1px solid red";
                            getElemts2[i].style.border = "1px solid red";

                            isEverythingOk = false;
                        }
                    } else {
                        getElemts1[i].style.border = "1px solid red";
                        getElemts2[i].style.border = "1px solid red";

                        isEverythingOk = false;
                    }
                }
            }
        }


        if (thursday) {
            var getElemts1 = document.getElementsByClassName(`thursdayStartTabs${type}`);
            var getElemts2 = document.getElementsByClassName(`thursdayEndTabs${type}`);

            var length = getElemts1.length;


            if (length > 0) {
                for (let i = 0; i < length; i++) {
                    if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                        if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                            getElemts1[i].style.border = "1px solid #ced4da";
                            getElemts2[i].style.border = "1px solid #ced4da";
                        }
                        else {
                            getElemts1[i].style.border = "1px solid red";
                            getElemts2[i].style.border = "1px solid red";

                            isEverythingOk = false;
                        }
                    } else {
                        getElemts1[i].style.border = "1px solid red";
                        getElemts2[i].style.border = "1px solid red";

                        isEverythingOk = false;
                    }
                }
            }
        }



        if (friday) {
            var getElemts1 = document.getElementsByClassName(`fridayStartTabs${type}`);
            var getElemts2 = document.getElementsByClassName(`fridayEndTabs${type}`);

            var length = getElemts1.length;


            if (length > 0) {
                for (let i = 0; i < length; i++) {
                    if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                        if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                            getElemts1[i].style.border = "1px solid #ced4da";
                            getElemts2[i].style.border = "1px solid #ced4da";
                        }
                        else {
                            getElemts1[i].style.border = "1px solid red";
                            getElemts2[i].style.border = "1px solid red";

                            isEverythingOk = false;
                        }
                    } else {
                        getElemts1[i].style.border = "1px solid red";
                        getElemts2[i].style.border = "1px solid red";

                        isEverythingOk = false;
                    }
                }
            }
        }



        if (saturday) {
            var getElemts1 = document.getElementsByClassName(`saturdayStartTabs${type}`);
            var getElemts2 = document.getElementsByClassName(`saturdayEndTabs${type}`);

            var length = getElemts1.length;


            if (length > 0) {
                for (let i = 0; i < length; i++) {
                    if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                        if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                            getElemts1[i].style.border = "1px solid #ced4da";
                            getElemts2[i].style.border = "1px solid #ced4da";
                        }
                        else {
                            getElemts1[i].style.border = "1px solid red";
                            getElemts2[i].style.border = "1px solid red";

                            isEverythingOk = false;
                        }
                    } else {
                        getElemts1[i].style.border = "1px solid red";
                        getElemts2[i].style.border = "1px solid red";

                        isEverythingOk = false;
                    }
                }
            }
        }

        if (!isEverythingOk) {
            setUpdateButtonEnabled(false);
        } else {
            setUpdateButtonEnabled(true);
        }
    }

    useEffect(() => {
        let list = [];
        let listSchedules = [];

        if (userSchedules) {

            Object.keys(userSchedules).map((name, key) => {
                return listSchedules.push({ label: name, value: name })
            })
            setUserScheduleListForSelect(listSchedules);
        }

    }, [phoneList, userSchedules])

    const startTimeZoneSwitchSchedules = (e) => {
        const rest = timezonesList.filter((zone) => { return zone.value.countries.includes(e.value); });
        setFilteredTimezonesListSchedule(rest);
        setSelectedTimezone(rest[0]);
    }


    const changeHandlerSchedule = (value) => {
        // console.log(value);
        setCountrySchedule(value);
        startTimeZoneSwitchSchedules(value);
    };



    const completeLinkSharing = async () => {
        setShowShareYourEventPanel(false);
    }


    return (

        loadingComplete === false ?
            (
                <div className={`min-vh-100 w-100 position-absolute top-0 left-start d-flex align-items-center justify-content-center`}>
                    <span className="spinner-border spinner-border-lg text-secondary"></span>
                </div>
            )
            :
            (

                <>
                    {
                        user && myEvent ?
                            (
                                <>
                                    <div className='d-flex flex-column align-items-center justify-content-between w-100 backgroundFiller bg-soul'>
                                        {/* <Sidebar user={user} /> */}

                                        <div className='w-100'>
                                            <div className='innerFrame d-flex flex-column align-items-center justify-content-start w-100'>
                                                {
                                                    myEvent ?
                                                        <div className="container-fluid w-100">
                                                            <div className="row salesforce-style sticky-top">
                                                                <div className="col-2 col-md-1 col-lg-1 p-2 d-flex align-items-center justify-content-start">
                                                                    <button className='btn btn-outline-light rounded-circle' onClick={() => { window.location.href = "/calendar#Meetings#eventTypes" }}>
                                                                        <i className='fa fa-arrow-left'></i>
                                                                    </button>
                                                                </div>
                                                                <div className='col-10 p-2 d-flex align-items-center justify-content-center'>
                                                                    <h3 className='fs-4 text-center'>Edit {myEvent?.eventType} Event</h3>
                                                                </div>
                                                            </div>
                                                            <div className="row my-2">
                                                                <div className="col-12 col-lg-6 col-md-6 col-sm-6 d-flex align-items-center text-muted mediumTextSize mb-2">
                                                                    <span className='text-center text-lg-start text-md-start text-sm-start w-100'>
                                                                        last edited {myEvent.lastEdited}
                                                                    </span>
                                                                </div>
                                                                <div className="col-12 col-lg-6 col-md-6 col-sm-6 d-flex align-items-center justify-content-center justify-content-lg-end justify-content-md-end justify-content-sm-end gap-3 mb-2">
                                                                    <button className='btn btn-sm btn-success smallTextSize h-100' onClick={(e) => {
                                                                        navigator.clipboard.writeText("https://" + process.env.REACT_APP_DOMAINNAME + myEvent.EventLink);
                                                                        e.target.innerHTML = "<span class='d-flex align-items-center gap-1'><i class='fa fa-check text-light'></i>Copied</span>";
                                                                        e.target.classList.add("salesforce-style");
                                                                        e.target.classList.remove("btn-success");
                                                                        setTimeout(() => {
                                                                            e.target.innerHTML = "Copy Link";
                                                                            e.target.classList.add("btn-success");
                                                                            e.target.classList.remove("salesforce-style");
                                                                        }, 2000);

                                                                    }}>
                                                                        Copy link
                                                                    </button>
                                                                    <button className='salesforce-style btn btn-sm smallTextSize h-100' onClick={() => { setShowShareYourEventPanel(true) }}>
                                                                        <img alt="forward" src={forwardImg} className="copyIcon cursor-pointer" />
                                                                        Share
                                                                    </button>
                                                                    <a className=' text-primary h-100' href={myEvent.EventLink} target="_blank">
                                                                        <button className='btn btn-sm btn-secondary smallTextSize h-100'>
                                                                            View live page
                                                                        </button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        ""
                                                }

                                                {
                                                    myEvent?.eventType === "One on One" ?
                                                        (
                                                            <>
                                                                <form onSubmit={handleSubmit} className="w-100 d-flex justify-content-start align-items-start gap-3 flex-column eventGoogleForm p-0">
                                                                    <Accordion className="w-100 insightBackground">
                                                                        <Accordion.Item eventKey="1">
                                                                            <Accordion.Header onClick={(e) => { setWhatEvent(!whatEvent); setWhenEvent(false); setCustomizedEvent(false); }}>
                                                                                <div className="container-fluid p-0">
                                                                                    <div className="row sticky-top">
                                                                                        <div className="col p-0">
                                                                                            <div className='eventPreview'>
                                                                                                <div style={{ height: "2em", width: "2em", borderRadius: "100%", pointerEvents: "none", alignSelf: "center", aspectRatio: "1" }} className={` event-color-${eventColor}`} ></div>
                                                                                                <div className='d-flex flex-column gap-1'>
                                                                                                    <div className='prewEventWhich text-dark'>What event is this?</div>
                                                                                                    <div className='eventNamePrew text-dark d-flex align-items-center gap-2 flex-wrap mediumTextSize'>
                                                                                                        <span id="text-capitalize text-dark text-break" style={eventName ? eventName.includes(" ") ? { wordBreak: "break-word" } : { wordBreak: "break-all" } : { wordBreak: "break-all" }}>
                                                                                                            {
                                                                                                                eventName ?
                                                                                                                    <>
                                                                                                                        {eventName},
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    ""
                                                                                                            }
                                                                                                        </span>
                                                                                                        <span id="eventLocationPrew text-dark">
                                                                                                            {
                                                                                                                meetingType === "phone" ?
                                                                                                                    <span className='selectedLocation'>
                                                                                                                        <img alt="Call" src={callimg} className='iconsForLocation' />
                                                                                                                        <span className='text-dark'>Phone Call</span>
                                                                                                                    </span> :
                                                                                                                    meetingType === "meet" ?
                                                                                                                        <span className='selectedLocation'>
                                                                                                                            <img src={meetImg} alt="Meet" className='iconsForLocation' />
                                                                                                                            <span className='text-dark'>Google Meet Conference</span>
                                                                                                                        </span> :
                                                                                                                        meetingType === "zoom" ?
                                                                                                                            <span className='selectedLocation'>
                                                                                                                                <img src={zoomImg} alt="Zoom" className='iconsForLocation' />
                                                                                                                                <span>Zoom Conference</span>
                                                                                                                                {
                                                                                                                                    needZoomToken ?
                                                                                                                                        <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                                                                                        : ""
                                                                                                                                }
                                                                                                                            </span> :
                                                                                                                            meetingType === "goto" ?
                                                                                                                                <span className='selectedLocation'>
                                                                                                                                    <img src={gotoImg} alt="GoTo" className='iconsForLocation' />
                                                                                                                                    <span>GoTo Conference</span>
                                                                                                                                    {
                                                                                                                                        needGTMToken ?
                                                                                                                                            <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                                                                                            : ""
                                                                                                                                    }
                                                                                                                                </span> :
                                                                                                                                meetingType === "webex" ?
                                                                                                                                    <span className='selectedLocation'>
                                                                                                                                        <img src={webexImg} alt="Webex" className='iconsForLocation' />
                                                                                                                                        <span>Webex Conference</span>
                                                                                                                                        {
                                                                                                                                            needWebexToken ?
                                                                                                                                                <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                                                                                                : ""
                                                                                                                                        }
                                                                                                                                    </span> :
                                                                                                                                    meetingType === "teams" ?
                                                                                                                                        <span className='selectedLocation'>
                                                                                                                                            <img src={teamsImg} alt="Teams" className='iconsForLocation' />
                                                                                                                                            <span>Teams Conference</span>
                                                                                                                                            {
                                                                                                                                                needTeamsToken ?
                                                                                                                                                    <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                                                                                                    : ""
                                                                                                                                            }
                                                                                                                                        </span> :
                                                                                                                                        meetingType === "address" && myEvent.location.length > 0 && groupLocation.length > 0 ?
                                                                                                                                            <span className='selectedLocation'>
                                                                                                                                                <img src={addressImg} alt="Address" className='iconsForLocation' />
                                                                                                                                                <span className='text-dark'>{location ? location : myEvent.location}</span>
                                                                                                                                            </span>
                                                                                                                                            :
                                                                                                                                            <>
                                                                                                                                                <img src={addressImg} alt="Address" className='iconsForLocation' />
                                                                                                                                                {location ? location : "No location Given"}
                                                                                                                                            </>
                                                                                                            }
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Accordion.Header>
                                                                            <Accordion.Body className='bg-soul'>
                                                                                <div className="container-fluid p-0">
                                                                                    <div className={`m-0 p-0`}>
                                                                                        <div className="row my-3">
                                                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                                                <label htmlFor='eventName' className='border w-100 p-2 insightBackground rounded'>
                                                                                                    <span style={{ fontWeight: "500" }}>Event Name *</span>
                                                                                                    <input id="eventName" required className='customInputFieldFocus w-100 px-2' placeholder='Event Name' type="text" maxLength={100} defaultValue={eventName} onChange={(e) => { setEventName(e.target.value) }} />
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="col-12 col-lg-6 col-md-6"></div>
                                                                                        </div>
                                                                                        <div className="row mb-3">
                                                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                                                <div>
                                                                                                    <label htmlFor='selectLocation' className={`${locationSelection ? "mb-0" : ""} border w-100 p-2 insightBackground rounded `} onClick={() => { setLocationSelection(!locationSelection); }}>
                                                                                                        <span style={{ fontWeight: "500" }}>Select Location</span>
                                                                                                        <div id="selectLocation" className='locationFrame bg-soul rounded'>
                                                                                                            {
                                                                                                                meetingType ?
                                                                                                                    meetingType === "phone" ?
                                                                                                                        (
                                                                                                                            <div className='selectedLocation'>
                                                                                                                                <img alt="Call" src={callimg} className='iconsForLocation' />
                                                                                                                                Phone call
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                        :
                                                                                                                        meetingType === "meet" ?
                                                                                                                            (
                                                                                                                                <div className='selectedLocation'>
                                                                                                                                    <img alt="Meet" src={meetImg} className='iconsForLocation' />
                                                                                                                                    Google Meet Conference
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                            :
                                                                                                                            meetingType === "zoom" ?
                                                                                                                                (<div className='selectedLocation'>
                                                                                                                                    <img alt="Zoom" src={zoomImg} className='iconsForLocation' />
                                                                                                                                    <span>
                                                                                                                                        Zoom Conference
                                                                                                                                    </span>
                                                                                                                                    {
                                                                                                                                        needZoomToken ?
                                                                                                                                            <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i> : ""
                                                                                                                                    }
                                                                                                                                </div>)
                                                                                                                                :
                                                                                                                                meetingType === "goto" ?
                                                                                                                                    (<div className='selectedLocation'>
                                                                                                                                        <img alt="Goto" src={gotoImg} className='iconsForLocation' />
                                                                                                                                        <span>
                                                                                                                                            GoTo Conference
                                                                                                                                        </span>
                                                                                                                                        {
                                                                                                                                            needGTMToken ?
                                                                                                                                                <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i> : ""
                                                                                                                                        }
                                                                                                                                    </div>)
                                                                                                                                    :
                                                                                                                                    meetingType === "webex" ?
                                                                                                                                        (<div className='selectedLocation'>
                                                                                                                                            <img alt="Webex" src={webexImg} className='iconsForLocation' />
                                                                                                                                            <span>
                                                                                                                                                Webex Conference
                                                                                                                                            </span>
                                                                                                                                            {
                                                                                                                                                needWebexToken ?
                                                                                                                                                    <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i> : ""
                                                                                                                                            }
                                                                                                                                        </div>)
                                                                                                                                        :
                                                                                                                                        meetingType === "teams" ?
                                                                                                                                            (<div className='selectedLocation'>
                                                                                                                                                <img alt="Teams" src={teamsImg} className='iconsForLocation' />
                                                                                                                                                <span>
                                                                                                                                                    Teams Conference
                                                                                                                                                </span>
                                                                                                                                                {
                                                                                                                                                    needTeamsToken ?
                                                                                                                                                        <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i> : ""
                                                                                                                                                }
                                                                                                                                            </div>)
                                                                                                                                            :
                                                                                                                                            meetingType === "address" ?
                                                                                                                                                (<div className='selectedLocation'>
                                                                                                                                                    <img alt="Address" src={addressImg} className='iconsForLocation' />
                                                                                                                                                    <span>
                                                                                                                                                        Custom Address
                                                                                                                                                    </span>
                                                                                                                                                </div>)
                                                                                                                                                : "unkown meeting Type"
                                                                                                                    :
                                                                                                                    ""
                                                                                                            }

                                                                                                        </div>
                                                                                                    </label>
                                                                                                </div>


                                                                                                {
                                                                                                    locationSelection &&
                                                                                                    <div id='locationSelector' className="locationSelector bg-body rounded">
                                                                                                        <ul>
                                                                                                            <li title='Phone Call' onClick={() => { setMeetingType("phone"); setLocation("phone"); setLocationSelection(false); }}>
                                                                                                                <span>
                                                                                                                    <img alt="Call" src={callimg} className='iconsForLocation' />
                                                                                                                </span>
                                                                                                                <span>
                                                                                                                    Phone Call
                                                                                                                </span>
                                                                                                            </li>
                                                                                                            <li title='Google Meet Conference' onClick={() => { setMeetingType("meet"); setLocation("meet"); setLocationSelection(false); }}>
                                                                                                                <span>
                                                                                                                    <img alt="Meet" src={meetImg} className='iconsForLocation' />
                                                                                                                </span>
                                                                                                                <span>
                                                                                                                    Google Meet Conference
                                                                                                                </span>
                                                                                                            </li>
                                                                                                            <li title='Zoom Conference' onClick={() => { setMeetingType("zoom"); setLocation("zoom"); setLocationSelection(false); }}>
                                                                                                                <span>
                                                                                                                    <img alt="Zoom" src={zoomImg} className='iconsForLocation' />
                                                                                                                </span>
                                                                                                                <span>
                                                                                                                    Zoom Conference
                                                                                                                </span>
                                                                                                                {
                                                                                                                    needZoomToken ?
                                                                                                                        <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i> : ""
                                                                                                                }
                                                                                                            </li>
                                                                                                            <li title='GoTo Conference' onClick={() => { setMeetingType("goto"); setLocation("goto"); setLocationSelection(false); }}>
                                                                                                                <span>
                                                                                                                    <img alt="Goto" src={gotoImg} className='iconsForLocation' />
                                                                                                                </span>
                                                                                                                <span>
                                                                                                                    GoTo Conference
                                                                                                                </span>
                                                                                                                {
                                                                                                                    needGTMToken ?
                                                                                                                        <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i> : ""
                                                                                                                }
                                                                                                            </li>
                                                                                                            <li title='Webex Conference' onClick={() => { setMeetingType("webex"); setLocation("webex"); setLocationSelection(false); }}>
                                                                                                                <span>
                                                                                                                    <img alt="Webex" src={webexImg} className='iconsForLocation' />
                                                                                                                </span>
                                                                                                                <span>
                                                                                                                    Webex Conference
                                                                                                                </span>
                                                                                                                {
                                                                                                                    needWebexToken ?
                                                                                                                        <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i> : ""
                                                                                                                }
                                                                                                            </li>
                                                                                                            <li title='Teams Conference' onClick={() => { setMeetingType("teams"); setLocation("teams"); setLocationSelection(false); }}>
                                                                                                                <span>
                                                                                                                    <img alt="Teams" src={teamsImg} className='iconsForLocation' />
                                                                                                                </span>
                                                                                                                <span>
                                                                                                                    Teams Conference
                                                                                                                </span>
                                                                                                                {
                                                                                                                    needTeamsToken ?
                                                                                                                        <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i> : ""
                                                                                                                }
                                                                                                            </li>
                                                                                                            <li title='Address' onClick={() => { setMeetingType("address"); setLocation(myEvent.meetingType === "address" ? myEvent.location : ""); setLocationSelection(false); }}>
                                                                                                                <span>
                                                                                                                    <img alt="Zoom" src={addressImg} className='iconsForLocation' />
                                                                                                                </span>
                                                                                                                <span>
                                                                                                                    Custom Address
                                                                                                                </span>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                }

                                                                                                {
                                                                                                    meetingType === "phone" && (
                                                                                                        <label className='w-100'>
                                                                                                            <div className="askWhich">
                                                                                                                {
                                                                                                                    inboundCall ?
                                                                                                                        (
                                                                                                                            <>
                                                                                                                                <label htmlFor="outboundCalloff2">
                                                                                                                                    <input type="radio" id="outboundCalloff2" name="callBound" onClick={() => { setInboundCall(false); }} />
                                                                                                                                    I should call my invitees
                                                                                                                                </label>
                                                                                                                                <label htmlFor="inboundCallonn2">
                                                                                                                                    {
                                                                                                                                        phoneList ?
                                                                                                                                            optionListForPhone.length > 0 ?
                                                                                                                                                <>
                                                                                                                                                    <input defaultChecked type="radio" id="inboundCallonn2" name="callBound" onClick={() => { setInboundCall(true); }} />
                                                                                                                                                    <span>Invitees should call me</span>
                                                                                                                                                </>
                                                                                                                                                :
                                                                                                                                                <>
                                                                                                                                                    <input defaultChecked type="radio" id="inboundCallonn2" name="callBound" disabled={true} onClick={() => { setInboundCall(true); }} />
                                                                                                                                                    <span className='text-muted'>Invitees should call me</span>
                                                                                                                                                </>
                                                                                                                                            :
                                                                                                                                            <>
                                                                                                                                                <input defaultChecked type="radio" id="inboundCallonn2" name="callBound" disabled={true} onClick={() => { setInboundCall(true); }} />
                                                                                                                                                <span className='text-muted'>Invitees should call me</span>
                                                                                                                                            </>
                                                                                                                                    }
                                                                                                                                </label>
                                                                                                                            </>
                                                                                                                        )
                                                                                                                        :
                                                                                                                        (
                                                                                                                            <>
                                                                                                                                <label htmlFor="outboundCalloff2">
                                                                                                                                    <input type="radio" defaultChecked id="outboundCalloff2" name="callBound" onClick={() => { setInboundCall(false); }} />
                                                                                                                                    I should call my invitees
                                                                                                                                </label>
                                                                                                                                <label htmlFor="inboundCallonn2">
                                                                                                                                    {
                                                                                                                                        phoneList ?
                                                                                                                                            optionListForPhone.length > 0 ?
                                                                                                                                                <>
                                                                                                                                                    <input type="radio" id="inboundCallonn2" name="callBound" onClick={() => { setInboundCall(true); }} />
                                                                                                                                                    <span>Invitees should call me</span>
                                                                                                                                                </>
                                                                                                                                                :
                                                                                                                                                <>
                                                                                                                                                    <input type="radio" id="inboundCallonn2" name="callBound" disabled={true} onClick={() => { setInboundCall(true); }} />
                                                                                                                                                    <span className='text-muted'>Invitees should call me</span>
                                                                                                                                                </>
                                                                                                                                            :
                                                                                                                                            <>
                                                                                                                                                <input type="radio" id="inboundCallonn2" name="callBound" disabled={true} onClick={() => { setInboundCall(true); }} />
                                                                                                                                                <span className='text-muted'>Invitees should call me</span>
                                                                                                                                            </>
                                                                                                                                    }
                                                                                                                                </label>
                                                                                                                            </>
                                                                                                                        )
                                                                                                                }

                                                                                                            </div>

                                                                                                        </label>
                                                                                                    )
                                                                                                    // setInboundCall
                                                                                                }


                                                                                                {
                                                                                                    meetingType === "phone" && inboundCall ?
                                                                                                        <label htmlFor='phoneCallOneOnOne' className='border p-2 bg-body w-100 rounded'>
                                                                                                            <span style={{ fontWeight: "500" }}>
                                                                                                                Choose Phone Number *
                                                                                                            </span>
                                                                                                            <PhoneInput
                                                                                                                required
                                                                                                                className="p-1 w-100 phoneNUmberInpurJHSGFUGDSFIY pointer-events-none"
                                                                                                                style={{ pointerEvents: "none" }}
                                                                                                                placeholder=""
                                                                                                                value={location}
                                                                                                                onChange={setLocation} />
                                                                                                            {
                                                                                                                selectedPhoneNumberLabel ?
                                                                                                                    <Select
                                                                                                                        id='phoneCallOneOnOne'
                                                                                                                        options={optionListForPhone}
                                                                                                                        defaultValue={selectedPhoneNumberLabel}
                                                                                                                        onChange={(e) => { setLocation(e.label) }}
                                                                                                                    />
                                                                                                                    : ""
                                                                                                            }
                                                                                                        </label>
                                                                                                        :
                                                                                                        meetingType === "address" ?
                                                                                                            <label className='border w-100 p-2 bg-body rounded'>
                                                                                                                <span style={{ fontWeight: "500" }}>
                                                                                                                    Enter Address Here *
                                                                                                                </span>
                                                                                                                <input
                                                                                                                    required
                                                                                                                    type="text"
                                                                                                                    className='customInputFieldFocus w-100 border px-2'
                                                                                                                    placeholder=""
                                                                                                                    defaultValue={myEvent.meetingType === "address" ? myEvent.location : ""}
                                                                                                                    onChange={(e) => setLocation(e.target.value)}
                                                                                                                />
                                                                                                            </label>
                                                                                                            :
                                                                                                            meetingType === "meet" && needToken ?
                                                                                                                <label className='w-100'>
                                                                                                                    <GoogleLogin
                                                                                                                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                                                                                                        buttonText='Sign in & Authorize Calendar'
                                                                                                                        onSuccess={responceGoogle}
                                                                                                                        onFailure={responseError}
                                                                                                                        cookiePolicy={"single_host_origin"}
                                                                                                                        fetchBasicProfile={true}
                                                                                                                        responseType="code"
                                                                                                                        accessType='offline'
                                                                                                                        scope='openid email profile https://www.googleapis.com/auth/calendar'
                                                                                                                    />
                                                                                                                </label>
                                                                                                                :
                                                                                                                meetingType === "zoom" && needZoomToken ?
                                                                                                                    <label>
                                                                                                                        <Link to={"../calendar#Integrations"} className='text-primary'>
                                                                                                                            Click to Connect Zoom.
                                                                                                                        </Link>
                                                                                                                    </label> :
                                                                                                                    meetingType === "webex" && needWebexToken ?
                                                                                                                        <label>
                                                                                                                            <Link to={"../calendar#Integrations"} className='text-primary'>
                                                                                                                                Click to Connect Webex.
                                                                                                                            </Link>
                                                                                                                        </label> :
                                                                                                                        meetingType === "goto" && needGTMToken ?
                                                                                                                            <label>
                                                                                                                                <Link to={"../calendar#Integrations"} className='text-primary'>
                                                                                                                                    Click to Connect GoTo Meeting.
                                                                                                                                </Link>
                                                                                                                            </label> :
                                                                                                                            meetingType === "teams" && needTeamsToken ?
                                                                                                                                <label>
                                                                                                                                    <Link to={"../calendar#Integrations"} className='text-primary'>
                                                                                                                                        Click to Connect Teams.
                                                                                                                                    </Link>
                                                                                                                                </label> :
                                                                                                                                ""
                                                                                                }
                                                                                            </div>
                                                                                            <div className="col-12 col-md-6 col-lg-6"></div>
                                                                                        </div>

                                                                                        <div className="row mb-3">
                                                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                                                <label htmlFor='description' className='border rounded insightBackground p-2 w-100'>
                                                                                                    <span className='d-flex gap-1 align-items-center' style={{ fontWeight: "500" }}>
                                                                                                        Event Link *

                                                                                                        <OverlayTrigger
                                                                                                            placement="right"
                                                                                                            delay={{ show: 100, hide: 200 }}
                                                                                                            overlay={
                                                                                                                <Popover id="popover-basic">
                                                                                                                    <Popover.Header className='salesforce-style' as="h3">Unique event links</Popover.Header>
                                                                                                                    <Popover.Body>
                                                                                                                        <p className='p-0 m-0 helpTextSize'>
                                                                                                                            Use this field to change the unique url for this event.
                                                                                                                        </p>
                                                                                                                        <img src={uniqueLinkHelpText} className='img img-responsive w-100' alt='helpText' />
                                                                                                                    </Popover.Body>
                                                                                                                </Popover>
                                                                                                            }>
                                                                                                            <HelpOutlineIcon style={{ maxWidth: "15px", cursor: "help" }} />
                                                                                                        </OverlayTrigger>
                                                                                                    </span>
                                                                                                    <span className='mediumTextSize text-muted'>{process.env.REACT_APP_DOMAINNAME + `/${user.username.replaceAll(" ", "-")}/`}</span>

                                                                                                    <div className="input-group w-100 flex-nowrap rounded">
                                                                                                        <input type="text" maxLength={50} id="UID" required className='customInputFieldFocus w-100 px-2' placeholder='UID' onClick={(e) => { if (e.target.value.length === 0) { checkCustomLinkAvailable({ target: { value: eventName.replaceAll(" ", "-") } }); } }} autoComplete="off" value={customEventLink} onChange={checkCustomLinkAvailable} />
                                                                                                        <div className="input-group-append">
                                                                                                            <span className="input-group-text h-100 border-0" style={{ borderRadius: "0px 0.375rem 0.375rem 0px" }}>
                                                                                                                {
                                                                                                                    isCustomLinkAvailable ?
                                                                                                                        <i className='fa fa-check text-success'></i>
                                                                                                                        :
                                                                                                                        <i className='fa fa-times text-danger'></i>
                                                                                                                }
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>


                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="col-12 col-md-6 col-lg-6">

                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="row mb-3">
                                                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                                                <label htmlFor='agenda' className='w-100 border rounded insightBackground p-2'>
                                                                                                    <span style={{ fontWeight: "500" }} className="d-block">Description *</span>
                                                                                                    <textarea id="agenda" required className='customInputFieldFocus w-100 px-2' placeholder='Description/Agenda' defaultValue={description} onChange={(e) => { setDescription(e.target.value) }} />
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="col-12 col-lg-6 col-md-6"></div>
                                                                                        </div>
                                                                                        <div className="row mb-3">
                                                                                            <div className="col-12">
                                                                                                <label className='d-flex flex-column align-items-start rounded border insightBackground w-100 p-2'>
                                                                                                    <span style={{ fontWeight: "500" }} className="d-block">
                                                                                                        Event Color
                                                                                                    </span>
                                                                                                    <div className='event-color-holder pb-2'>
                                                                                                        <div onClick={(e) => { setEventColor('1'); checkThisTab(e.target); }} className={`event-color-tab event-color-1 ${eventColor === '1' ? "checked-color" : ""}`}></div>
                                                                                                        <div onClick={(e) => { setEventColor('2'); checkThisTab(e.target); }} className={`event-color-tab event-color-2 ${eventColor === '2' ? "checked-color" : ""}`}></div>
                                                                                                        <div onClick={(e) => { setEventColor('3'); checkThisTab(e.target); }} className={`event-color-tab event-color-3 ${eventColor === '3' ? "checked-color" : ""}`}></div>
                                                                                                        <div onClick={(e) => { setEventColor('4'); checkThisTab(e.target); }} className={`event-color-tab event-color-4 ${eventColor === '4' ? "checked-color" : ""}`}></div>
                                                                                                        <div onClick={(e) => { setEventColor('5'); checkThisTab(e.target); }} className={`event-color-tab event-color-5 ${eventColor === '5' ? "checked-color" : ""}`}></div>
                                                                                                        <div onClick={(e) => { setEventColor('6'); checkThisTab(e.target); }} className={`event-color-tab event-color-6 ${eventColor === '6' ? "checked-color" : ""}`}></div>
                                                                                                        <div onClick={(e) => { setEventColor('7'); checkThisTab(e.target); }} className={`event-color-tab event-color-7 ${eventColor === '7' ? "checked-color" : ""}`}></div>
                                                                                                        <div onClick={(e) => { setEventColor('8'); checkThisTab(e.target); }} className={`event-color-tab event-color-8 ${eventColor === '8' ? "checked-color" : ""}`}></div>
                                                                                                        <div onClick={(e) => { setEventColor('9'); checkThisTab(e.target); }} className={`event-color-tab event-color-9 ${eventColor === '9' ? "checked-color" : ""}`}></div>
                                                                                                        <div onClick={(e) => { setEventColor('10'); checkThisTab(e.target); }} className={`event-color-tab event-color-10 ${eventColor === '10' ? "checked-color" : ""}`}></div>
                                                                                                        <div onClick={(e) => { setEventColor('11'); checkThisTab(e.target); }} className={`event-color-tab event-color-11 ${eventColor === '11' ? "checked-color" : ""}`}></div>
                                                                                                    </div>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row my-3">
                                                                                            <div className="col d-flex align-items-center justify-content-center">
                                                                                                <div className='d-flex justify-content-center gap-2 w-100'>
                                                                                                    <button type="button" style={{ maxWidth: "120px" }} className='btn btn-sm w-100 text-center btn-secondary rounded-pill' onClick={() => { setWhatEvent(false); window.location.reload(); }}>Cancel</button>
                                                                                                    <button type="submit" style={{ maxWidth: "120px" }} className='btn btn-sm w-100 text-center salesforce-style rounded-pill d-flex align-items-center justify-content-center gap-2' id="oneOnOneEditButton1" disabled={!updateButtonEnabled || !isCustomLinkAvailable}>Update</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Accordion.Body>
                                                                        </Accordion.Item>
                                                                        <Accordion.Item eventKey="2">
                                                                            <Accordion.Header>
                                                                                <div className="container-fluid p-0">
                                                                                    <div className="row sticky-top">
                                                                                        <div className="col p-0" onClick={(e) => { setWhenEvent(!whenEvent); setWhatEvent(false); setCustomizedEvent(false); }}>
                                                                                            <div className='eventPreview'>
                                                                                                <img src={caneldarDark} alt="calendar Icon" className="iconsForLocation" style={{ width: "2em", alignSelf: "center" }} />
                                                                                                <div className='d-flex flex-column gap-1'>
                                                                                                    <div className='prewEventWhich text-dark'>When can people book this event?</div>
                                                                                                    <div className='eventNamePrew text-dark d-flex align-items-center gap-2 flex-wrap mediumTextSize'>
                                                                                                        <span id="eventNamePrew text-dark">
                                                                                                            {
                                                                                                                duration > 60 ?
                                                                                                                    Math.floor(duration % 60) === 0 ?
                                                                                                                        Math.floor(duration / 60) + " hrs"
                                                                                                                        : Math.floor(duration / 60) + " hrs, " + Math.floor(duration % 60) + " min"
                                                                                                                    : Math.floor(duration) + " min"
                                                                                                            },
                                                                                                        </span>
                                                                                                        <span id="eventLocationPrew">{dayjs(betweenDays[0].startDate).format("D MMM YYYY")} - {betweenDays[0].endDate ? dayjs(betweenDays[0].endDate).format("D MMM YYYY") : "Continuous"}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Accordion.Header>
                                                                            <Accordion.Body className='bg-soul'>
                                                                                <div className="container-fluid p-0">

                                                                                    <div className="row my-3">
                                                                                        <div className="col">
                                                                                            <div className='p-3 insightBackground rounded border'>
                                                                                                <span style={{ fontWeight: "500" }}>
                                                                                                    Date Range
                                                                                                </span>
                                                                                                <p className='m-0'>Set a range of dates when you can accept meetings.</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="row mb-3">
                                                                                        <div className="col-12">
                                                                                            <div className='rescheduler'>
                                                                                                <label className='h6' style={{ fontWeight: "500" }}>
                                                                                                    Invitees can schedule...
                                                                                                </label>
                                                                                                {/* <div className="w-100 overflow-auto">
                                                                                                    <DateRange
                                                                                                        // editableDateInputs={true}
                                                                                                        onChange={item => setBetweenDays([item.selection])}
                                                                                                        // showSelectionPreview={true}
                                                                                                        // moveRangeOnFirstSelection={false}
                                                                                                        months={2}
                                                                                                        minDate={new Date()}
                                                                                                        ranges={betweenDays}
                                                                                                        direction="horizontal"
                                                                                                    />
                                                                                                </div> */}

                                                                                                <div className='w-100 d-flex align-items-center gap-2 mb-4'>
                                                                                                    <label htmlFor="rollingDaysStraight" className={`d-flex align-items-center gap-2 ${straightRollingDays ? "" : "text-muted"}`}
                                                                                                        onClick={() => {
                                                                                                            setStraightRollingDays(true);
                                                                                                            setWithinDateRange(false);
                                                                                                            setInfiniteDateRange(false);
                                                                                                            setBetweenDays([
                                                                                                                {
                                                                                                                    startDate: new Date(),
                                                                                                                    endDate: addDays(new Date(), parseInt(rollingDaysRef.current.value)),
                                                                                                                    key: 'selection'
                                                                                                                }
                                                                                                            ]);

                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            straightRollingDays ?
                                                                                                                <input type="radio" checked id='rollingDaysStraight' className='form-check-input p-0 m-0' name="rollingDays" />
                                                                                                                :
                                                                                                                <input type="radio" id='rollingDaysStraight' className='form-check-input p-0 m-0' name="rollingDays" />
                                                                                                        }

                                                                                                        <input
                                                                                                            type="number"
                                                                                                            disabled={!straightRollingDays}
                                                                                                            defaultValue={30}
                                                                                                            ref={rollingDaysRef}
                                                                                                            onClick={
                                                                                                                (e) => {
                                                                                                                    setBetweenDays([
                                                                                                                        {
                                                                                                                            startDate: new Date(),
                                                                                                                            endDate: addDays(new Date(), parseInt(e.target.value)),
                                                                                                                            key: 'selection'
                                                                                                                        }
                                                                                                                    ]);
                                                                                                                }}
                                                                                                            onInput={
                                                                                                                (e) => {
                                                                                                                    setBetweenDays([
                                                                                                                        {
                                                                                                                            startDate: new Date(),
                                                                                                                            endDate: addDays(new Date(), parseInt(e.target.value)),
                                                                                                                            key: 'selection'
                                                                                                                        }
                                                                                                                    ]);

                                                                                                                    // console.log(addDays(new Date(), parseInt(e.target.value)))
                                                                                                                }
                                                                                                            } className='customInputFieldFocus rounded border' />
                                                                                                    </label>
                                                                                                    <div style={{ position: "relative", zIndex: "10" }}>
                                                                                                        <Select
                                                                                                            isDisabled={!straightRollingDays}
                                                                                                            className='mediumTextSize'
                                                                                                            options={
                                                                                                                [
                                                                                                                    { label: "calendar days", value: "calendardays" },
                                                                                                                    // { label: "weekdays", value: "weekdays" }
                                                                                                                ]
                                                                                                            }
                                                                                                            defaultValue={[{ label: "calendar days", value: "calendardays" }]}
                                                                                                            onChange={(e) => { }}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className={`${straightRollingDays ? "" : "text-muted"}`}>
                                                                                                        into the future
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='w-100 d-flex align-items-center gap-2 mb-4'>
                                                                                                    <label htmlFor="betweenForever" className={`d-flex align-items-center gap-2 ${withinDateRange ? "" : "text-muted"}`}
                                                                                                        onClick={(e) => {
                                                                                                            setStraightRollingDays(false);
                                                                                                            setWithinDateRange(true);
                                                                                                            setInfiniteDateRange(false);
                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            withinDateRange ?
                                                                                                                <input type="radio" checked id='betweenForever' className='form-check-input p-0 m-0' name="rollingDays" />
                                                                                                                :
                                                                                                                <input type="radio" id='betweenForever' className='form-check-input p-0 m-0' name="rollingDays" />
                                                                                                        }
                                                                                                        <span className='mediumTextSize'>Within a date range</span>
                                                                                                    </label>
                                                                                                    {
                                                                                                        withinDateRange ?
                                                                                                            <div className='flex-fill' style={{ position: "relative", zIndex: "9", maxWidth: "max-content" }} onClick={() => { setShowDateRangeSelector(true); setWithinDateRange(true); setStraightRollingDays(false); setInfiniteDateRange(false); }}>
                                                                                                                <input type="text" disabled={!withinDateRange} value={`${dayjs(betweenDays[0].startDate).format("D MMM YYYY")} - ${betweenDays[0].endDate ? dayjs(betweenDays[0].endDate).format("D MMM YYYY") : "Continuous"}`} className=" w-100 rounded p-2 border mediumTextSize" />
                                                                                                            </div>
                                                                                                            :
                                                                                                            ""
                                                                                                    }
                                                                                                </div>

                                                                                                <div className='w-100 mb-4'>
                                                                                                    <label htmlFor="foreversRoll" className={`d-flex align-items-center gap-2  ${infiniteDateRange ? "" : "text-muted"}`}
                                                                                                        onClick={(e) => {
                                                                                                            setStraightRollingDays(false);
                                                                                                            setWithinDateRange(false);
                                                                                                            setInfiniteDateRange(true);

                                                                                                            setBetweenDays([
                                                                                                                {
                                                                                                                    startDate: new Date(),
                                                                                                                    endDate: null,
                                                                                                                    key: 'selection'
                                                                                                                }
                                                                                                            ]);
                                                                                                        }}

                                                                                                    >
                                                                                                        {
                                                                                                            infiniteDateRange ?
                                                                                                                <input type="radio" checked id='foreversRoll' className='form-check-input p-0 m-0' name="rollingDays" />
                                                                                                                :
                                                                                                                <input type="radio" id='foreversRoll' className='form-check-input p-0 m-0' name="rollingDays" />
                                                                                                        }
                                                                                                        <span className={`mediumTextSize`}>Indefinitely into the future</span>

                                                                                                    </label>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="row mb-3">
                                                                                        <div className="col-12">
                                                                                            <label htmlFor='endDateTime' className='w-100 border rounded p-3 insightBackground' style={{ position: "relative", zIndex: "5" }}>
                                                                                                <span style={{ fontWeight: "500" }}>Meeting Duration</span>
                                                                                                <p className='m-0 mb-2 mediumTextSize'>Define how long your event will be. It can be as long as 12 hours.</p>
                                                                                                {
                                                                                                    // ############# one on on #########################
                                                                                                    // <DropdownButton
                                                                                                    //     as="ButtonGroup"
                                                                                                    //     className='w-100'
                                                                                                    //     id={`dropdown-button-drop-down`}
                                                                                                    //     drop={"down"}
                                                                                                    //     variant="light"
                                                                                                    //     title={
                                                                                                    //         duration > 60 ?
                                                                                                    //             Math.floor(duration % 60) === 0 ?
                                                                                                    //                 Math.floor(duration / 60) + " hrs"
                                                                                                    //                 : Math.floor(duration / 60) + " hrs, " + Math.floor(duration % 60) + " min"
                                                                                                    //             : Math.floor(duration) + " min"
                                                                                                    //     }
                                                                                                    //     onSelect={(e, event) => { if (e !== "custom") { setDuration(e); setCustomHourView(false); } else { setCustomHourView(true); setDuration(15) } }}

                                                                                                    // >
                                                                                                    //     <Dropdown.Item eventKey="15">15 minutes</Dropdown.Item>
                                                                                                    //     <Dropdown.Item eventKey="30">30 minutes</Dropdown.Item>
                                                                                                    //     <Dropdown.Item eventKey="45">45 minutes</Dropdown.Item>
                                                                                                    //     <Dropdown.Item eventKey="60">60 minutes</Dropdown.Item>
                                                                                                    //     <Dropdown.Divider />
                                                                                                    //     <Dropdown.Item eventKey="custom">Custom</Dropdown.Item>
                                                                                                    // </DropdownButton>

                                                                                                    myEvent.Duration &&
                                                                                                    <>
                                                                                                        {
                                                                                                            selectedDurationLabel ?
                                                                                                                Object.keys(selectedDurationLabel).length > 0 ?
                                                                                                                    <Select
                                                                                                                        options={meetingDurationListForSelect}
                                                                                                                        defaultValue={selectedDurationLabel}
                                                                                                                        onChange={(e) => { if (e.value !== "custom") { setDuration(e.value); setCustomHourView(false); } else { setCustomHourView(true); } }}
                                                                                                                    />
                                                                                                                    :
                                                                                                                    ""
                                                                                                                :
                                                                                                                ""
                                                                                                        }
                                                                                                    </>
                                                                                                }

                                                                                                {
                                                                                                    customHourView ?
                                                                                                        (
                                                                                                            <div className='d-flex justify-content-center align-items-center gap-2 my-2'>
                                                                                                                {
                                                                                                                    durationType === "hours" ?
                                                                                                                        <input className='customInputFieldFocus w-100' ref={minutesOneOnOne} value={Math.floor(parseInt(duration) / 60) === 0 ? 1 : Math.floor(parseInt(duration) / 60)} required type="number" min="1" max="12" onChange={(e) => { convertToMinutesOneOnOne(e.target.value); }} />
                                                                                                                        :
                                                                                                                        <input className='customInputFieldFocus w-100' ref={minutesOneOnOne} value={parseInt(duration) === 0 ? 15 : duration} required type="number" min="15" max="720" onChange={(e) => { convertToMinutesOneOnOne(e.target.value); }} />
                                                                                                                }

                                                                                                                <Select
                                                                                                                    className='w-50'
                                                                                                                    options={[{ label: "minutes", value: "minutes" }, { label: "hours", value: "hours" }]}
                                                                                                                    defaultValue={[durationType === "hours" ? { label: "hours", value: "hours" } : { label: "minutes", value: "minutes" }]}
                                                                                                                    onChange={(e) => { setDurationType(e.value); }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        )
                                                                                                        :
                                                                                                        ""
                                                                                                }
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="col-12 col-lg-6 col-md-6"></div>
                                                                                    </div>

                                                                                    <div className='row mb-3'>
                                                                                        <div className="col">
                                                                                            <div className='w-100 border rounded p-3 insightBackground'>
                                                                                                <span style={{ fontWeight: "500" }}>How do you want to offer your availability for this event type?</span>
                                                                                                <p>Select one of your schedules or define custom hours specific to this type of event.</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="row mb-3">
                                                                                        <div className="col">
                                                                                            <button type='button' className={`btn ${existingScheduleView ? "salesforce-style" : "bg-body border"}`} onClick={() => { setExistingScheduleView(true); setCustomScheduleView(false); }}>Use an existing schedule</button>
                                                                                            <button type='button' className={`btn mx-3 ${customScheduleView ? "salesforce-style" : "bg-body border"}`} onClick={() => { setExistingScheduleView(false); setCustomScheduleView(true); }}>Set custom hours</button>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={`row mb-3 ${existingScheduleView ? "" : "d-none"}`}>
                                                                                        <div className="col">

                                                                                            <div className="rounded text-dark border">
                                                                                                <div className="border-bottom p-3">
                                                                                                    <span className='mb-1 w-100 d-block' style={{ fontWeight: "500" }}>Which schedule type do you want to use for this event type?</span>

                                                                                                    {
                                                                                                        scheduleName && (
                                                                                                            <Select
                                                                                                                options={userScheduleListForSelect}
                                                                                                                defaultValue={[{ label: scheduleName, value: scheduleName }]}
                                                                                                                onChange={(e) => { toogleScheduleSet(e.label); setScheduleName(e.label); }}
                                                                                                            />
                                                                                                        )
                                                                                                    }

                                                                                                    <span style={{ fontWeight: "500" }} className="d-block mb-1">
                                                                                                        Time Zone
                                                                                                    </span>
                                                                                                    <Select value={[selectedTimezone]} options={filteredTimezonesListSchedule} isDisabled={true} onChange={(e) => { setSelectedTimezone(e); }} />
                                                                                                    <div className="d-flex align-items-center gap-2 my-3">
                                                                                                        <i className='fa fa-globe fs-5'></i>
                                                                                                        <span style={{ fontWeight: "600" }}>
                                                                                                            {selectedTimezone.value ? selectedTimezone.value.name : ""}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <p className='mediumTextSize'>
                                                                                                        Automatically detected timezone is {selectedTimezone.value ? selectedTimezone.value.name : ""}. You may change it to specify default time-zone for your calendar.
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className="p-3">
                                                                                                    <div className="container-fluid">
                                                                                                        <div className="row">
                                                                                                            <div className="col-12 col-md-12 col-lg-7 border rounded shadow-sm insightBackground">
                                                                                                                <div className="container-fluid h-100">
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-4 col-md-2 col-lg-2 h6 p-2">SUN</div>
                                                                                                                        <div className="col-8 col-md-10 col-lg-10 p-2">
                                                                                                                            {
                                                                                                                                isSundayActive ?
                                                                                                                                    schedule.sunday.times.map((time, key) => {
                                                                                                                                        return <p className='w-100 mb-2 text-start' key={key}>
                                                                                                                                            {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                        </p>
                                                                                                                                    })
                                                                                                                                    :
                                                                                                                                    <span className='text-muted'>unavailable</span>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                    <div className="row">
                                                                                                                        <div className=" col-4 col-md-2 col-lg-2 h6 p-2">MON</div>
                                                                                                                        <div className="col-8 col-md-10 col-lg-10 p-2">
                                                                                                                            {
                                                                                                                                isMondayActive ?
                                                                                                                                    schedule.monday.times.map((time, key) => {
                                                                                                                                        return <p className='w-100 mb-2 text-start' key={key}>
                                                                                                                                            {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                        </p>
                                                                                                                                    })
                                                                                                                                    :
                                                                                                                                    <span className='text-muted'>unavailable</span>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                    <div className="row">
                                                                                                                        <div className=" col-4 col-md-2 col-lg-2 h6 p-2">TUE</div>
                                                                                                                        <div className="col-8 col-md-10 col-lg-10 p-2">
                                                                                                                            {
                                                                                                                                isTuesdayActive ?
                                                                                                                                    schedule.tuesday.times.map((time, key) => {
                                                                                                                                        return <p className='w-100 mb-2 text-start' key={key}>
                                                                                                                                            {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                        </p>
                                                                                                                                    })
                                                                                                                                    :
                                                                                                                                    <span className='text-muted'>unavailable</span>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                    <div className="row">
                                                                                                                        <div className=" col-4 col-md-2 col-lg-2 h6 p-2">WED</div>
                                                                                                                        <div className="col-8 col-md-10 col-lg-10 p-2">
                                                                                                                            {
                                                                                                                                isWednesdayActive ?
                                                                                                                                    schedule.wednesday.times.map((time, key) => {
                                                                                                                                        return <p className='w-100 mb-2 text-start' key={key}>
                                                                                                                                            {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                        </p>
                                                                                                                                    })
                                                                                                                                    :
                                                                                                                                    <span className='text-muted'>unavailable</span>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                    <div className="row">
                                                                                                                        <div className=" col-4 col-md-2 col-lg-2 h6 p-2">THU</div>
                                                                                                                        <div className="col-8 col-md-10 col-lg-10 p-2">
                                                                                                                            {
                                                                                                                                isThursdayActive ?
                                                                                                                                    schedule.thursday.times.map((time, key) => {
                                                                                                                                        return <p className='w-100 mb-2 text-start' key={key}>
                                                                                                                                            {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                        </p>
                                                                                                                                    })
                                                                                                                                    :
                                                                                                                                    <span className='text-muted'>unavailable</span>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                    <div className="row">
                                                                                                                        <div className=" col-4 col-md-2 col-lg-2 h6 p-2">FRI</div>
                                                                                                                        <div className="col-8 col-md-10 col-lg-10 p-2">
                                                                                                                            {
                                                                                                                                isFridayActive ?
                                                                                                                                    schedule.friday.times.map((time, key) => {
                                                                                                                                        return <p className='w-100 mb-2 text-start' key={key}>
                                                                                                                                            {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                        </p>
                                                                                                                                    })
                                                                                                                                    :
                                                                                                                                    <span className='text-muted'>unavailable</span>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                    <div className="row">
                                                                                                                        <div className=" col-4 col-md-2 col-lg-2 h6 p-2">SAT</div>
                                                                                                                        <div className="col-8 col-md-10 col-lg-10 p-2">
                                                                                                                            {
                                                                                                                                isSaturdayActive ?
                                                                                                                                    schedule.saturday.times.map((time, key) => {
                                                                                                                                        return <p className='w-100 mb-2 text-start' key={key}>
                                                                                                                                            {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                        </p>
                                                                                                                                    })
                                                                                                                                    :
                                                                                                                                    <span className='text-muted'>unavailable</span>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-12 col-lg-1 py-2"></div>
                                                                                                            <div className="col-12 col-md-12 col-lg-4 border rounded shadow-sm insightBackground">
                                                                                                                <h5 className='py-3 text-center'>Date Overrides</h5>
                                                                                                                {
                                                                                                                    dateOverrides ?
                                                                                                                        Object.keys(dateOverrides).length > 0 ?
                                                                                                                            Object.keys(dateOverrides).map((data, key) => {
                                                                                                                                return (
                                                                                                                                    <div key={key} className="card mb-2" id={`datesOverrides_${dateOverrides[data].uid}`}>
                                                                                                                                        <div className='card-header salesforce-style d-flex align-items-center justify-content-between'>
                                                                                                                                            <span>{dayjs(dateOverrides[data].date).format("ddd, D MMM YYYY")}</span>
                                                                                                                                            {/* <span><i className='fa fa-trash' id={dateOverrides[data].uid} onClick={(e) => { deleteOverrideDate(dateOverrides[data].date, `datesOverrides_${dateOverrides[data].uid}`); }}></i></span> */}
                                                                                                                                        </div>
                                                                                                                                        <div className='card-body d-flex justify-content-between align-items-center'>
                                                                                                                                            {
                                                                                                                                                dateOverrides[data].active ?
                                                                                                                                                    <>
                                                                                                                                                        <div className='d-flex flex-column align-items-start justify-content-center'>
                                                                                                                                                            {
                                                                                                                                                                dateOverrides[data].timeSet.map((time, key) => {
                                                                                                                                                                    return (
                                                                                                                                                                        <React.Fragment key={key}>
                                                                                                                                                                            <button disabled className='btn btn-secondary border-0 mb-1 w-100'>
                                                                                                                                                                                {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                                                            </button>
                                                                                                                                                                        </React.Fragment>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                            }
                                                                                                                                                        </div>
                                                                                                                                                    </>
                                                                                                                                                    :
                                                                                                                                                    <>
                                                                                                                                                        <span className='text-muted'>unavailable</span>
                                                                                                                                                    </>
                                                                                                                                            }
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            })
                                                                                                                            :
                                                                                                                            <p className='text-center'>No overrides for now</p>
                                                                                                                        :
                                                                                                                        <p className='text-center'>No overrides for now</p>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>


                                                                                    <div className={`row ${customScheduleView ? "" : "d-none"}`} >
                                                                                        <div className="col-12">
                                                                                            {/* ######################## NEW SCHEDULE SYSTEM ONE TO ONE ######################### */}

                                                                                            <div className="">
                                                                                                <div className="mb-3">
                                                                                                    <p className='smallTextNote p-3 m-0'>Choose a schedule below to edit or create a new one that you can apply to your event types</p>
                                                                                                    <div className="conatiner-fluid border p-3 rounded insightBackground">
                                                                                                        <div className="row mb-3">
                                                                                                            <div className="col">
                                                                                                                <span style={{ fontWeight: "600" }}>SCHEDULE TEMPLATES</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="row">
                                                                                                            <div className="col">
                                                                                                                <div className='d-flex flex-wrap align-items-center gap-2'>
                                                                                                                    {
                                                                                                                        userSchedules ?
                                                                                                                            Object.keys(userSchedules).map((name, key) => {
                                                                                                                                if (scheduleName === name) {
                                                                                                                                    return <button key={key} type="button" className='btn salesforce-style rounded-pill border' onClick={() => { toogleScheduleSet(name); setScheduleName(name); }}>{name}</button>
                                                                                                                                } else {
                                                                                                                                    return <button key={key} type="button" className='btn btn-outline-dark rounded-pill border' onClick={() => { toogleScheduleSet(name); setScheduleName(name); }}>{name}</button>
                                                                                                                                }
                                                                                                                            })
                                                                                                                            :
                                                                                                                            ""
                                                                                                                    }
                                                                                                                    <button type='button' className='btn btn-outline-dark rounded-pill border' onClick={() => { setCreateNewSchedule(true); setDateOverrides({}); }}>+ New Schedule</button>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="rounded text-dark mt-3 border">
                                                                                                        <div className="border-bottom p-3">
                                                                                                            <div className="container-fluid">
                                                                                                                <div className="row mb-3">
                                                                                                                    <div className="col p-0">
                                                                                                                        <div className='w-100 d-flex justify-content-between align-items-center'>
                                                                                                                            <span className='text-capitalize' style={{ fontWeight: "600" }}>{scheduleName ? scheduleName : "Working Hours"}</span>
                                                                                                                            <OverlayTrigger
                                                                                                                                placement="left"
                                                                                                                                delay={{ show: 100, hide: 200 }}
                                                                                                                                overlay={
                                                                                                                                    <Popover id="popover-basic">
                                                                                                                                        <Popover.Header className='salesforce-style' as="h3">Delete Schedule</Popover.Header>
                                                                                                                                        <Popover.Body>
                                                                                                                                            <p className='p-0 m-0 helpTextSize'>By clicking this you can delete this schedule template.</p>
                                                                                                                                        </Popover.Body>
                                                                                                                                    </Popover>
                                                                                                                                }>
                                                                                                                                <i
                                                                                                                                    className='fa fa-trash cursor-pointer'
                                                                                                                                    onClick={() => {
                                                                                                                                        setCustomAlertBox(true);
                                                                                                                                        setAlertBoxMessages(<p>Do you want to delete this schedule <br />"{scheduleName}"?</p>);
                                                                                                                                        setAlertErrorType("queryDelSchedule");

                                                                                                                                    }}
                                                                                                                                ></i>
                                                                                                                            </OverlayTrigger>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="row mb-3">
                                                                                                                    <div className="col-12 col-lg-6 p-0">
                                                                                                                        <div className='d-flex flex-column align-items-start gap-3 h-100 w-100'>

                                                                                                                            <div className='d-flex align-items-start flex-column justify-content-between gap-1 h-100 w-100'>
                                                                                                                                <label htmlFor='timezoneGds'>Country *</label>
                                                                                                                                <div className='w-100'>
                                                                                                                                    <Select options={countriesList} value={countrySchedule} onChange={changeHandlerSchedule} />
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className='d-flex align-items-start flex-column justify-content-between gap-1 h-100 w-100'>
                                                                                                                                <label htmlFor='timezoneGds'>Time Zone</label>
                                                                                                                                <div className='w-100'>
                                                                                                                                    <Select value={[selectedTimezone]} options={filteredTimezonesListSchedule} onChange={(e) => { setSelectedTimezone(e); }} />
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="col-12 col-lg-6">

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="p-3">
                                                                                                            <div className="container-fluid">
                                                                                                                <div className="row">
                                                                                                                    <div className="col-12 col-md-12 col-lg-7 border rounded shadow-sm insightBackground">
                                                                                                                        <p className='text-center pt-4' style={{ fontWeight: "600" }}>Set your weekly hours</p>
                                                                                                                        <div className="container-fluid p-0">
                                                                                                                            <div className="row mb-4">
                                                                                                                                <div className="col-3">
                                                                                                                                    <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                                                                                        <label className='d-flex gap-1 align-items-center justify-content-between'>
                                                                                                                                            <div className="outerToggleSwitch" onClick={() => { setIsSundayActive(!isSundayActive); checkDayOnToggle(!isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, "OneToOne"); }}>
                                                                                                                                                <div className={`frameOfToggleSwitch ${isSundayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                                                                    <span>On</span>
                                                                                                                                                    <div className="toggleSwitchBall"></div>
                                                                                                                                                    <span>Off</span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <span className='smallTextNote'>SUN</span>
                                                                                                                                        </label>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-7">
                                                                                                                                    {
                                                                                                                                        isSundayActive ?
                                                                                                                                            <div className='m-0 p-0' id="sundayFrameOneToOne">
                                                                                                                                                {
                                                                                                                                                    schedule.sunday.times.length > 0 ?
                                                                                                                                                        schedule.sunday.times.map((sadn, key) => {
                                                                                                                                                            const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                            const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                            const uid = uuidv4();
                                                                                                                                                            return (
                                                                                                                                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"sundayTimeFrame_" + uid}>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="sundayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote shadow-sm sundayStartTabsOneToOne' />
                                                                                                                                                                        <datalist id="sundayStart" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="sundayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote sundayEndTabsOneToOne' />
                                                                                                                                                                        <datalist id="sundayEnd" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div className='d-flex align-items-center h-100'>
                                                                                                                                                                        <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("sunday", "OneToOne", uid); }}></i>
                                                                                                                                                                    </div>
                                                                                                                                                                </span>
                                                                                                                                                            )
                                                                                                                                                        })
                                                                                                                                                        :
                                                                                                                                                        ["9:00am-5:00pm"].map((sadn, key) => {
                                                                                                                                                            const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                            const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                            const uid = uuidv4();
                                                                                                                                                            return (
                                                                                                                                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"sundayTimeFrame_" + uid}>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="sundayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote shadow-sm sundayStartTabsOneToOne' />
                                                                                                                                                                        <datalist id="sundayStart" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="sundayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote sundayEndTabsOneToOne' />
                                                                                                                                                                        <datalist id="sundayEnd" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div className='d-flex align-items-center h-100'>
                                                                                                                                                                        <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("sunday", "OneToOne", uid); }}></i>
                                                                                                                                                                    </div>
                                                                                                                                                                </span>
                                                                                                                                                            )
                                                                                                                                                        })

                                                                                                                                                }

                                                                                                                                            </div>
                                                                                                                                            :
                                                                                                                                            <span className='smallTextNote'>Unavailable</span>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                                <div className="col-2">
                                                                                                                                    <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                                                                                        <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("sunday", "OneToOne", uuidv4()); }} ></i>
                                                                                                                                        <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("sunday", "OneToOne"); }} />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="row mb-4">
                                                                                                                                <div className="col-3">
                                                                                                                                    <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                                                                                        <label className='d-flex gap-1 align-items-center justify-content-start'>
                                                                                                                                            <div className="outerToggleSwitch" onClick={() => { setIsMondayActive(!isMondayActive); checkDayOnToggle(isSundayActive, !isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, "OneToOne"); }}>
                                                                                                                                                <div className={`frameOfToggleSwitch ${isMondayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                                                                    <span>On</span>
                                                                                                                                                    <div className="toggleSwitchBall"></div>
                                                                                                                                                    <span>Off</span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <span className='smallTextNote'>MON</span>
                                                                                                                                        </label>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-7">
                                                                                                                                    {
                                                                                                                                        isMondayActive ?
                                                                                                                                            <div className='m-0 p-0' id="mondayFrameOneToOne">
                                                                                                                                                {
                                                                                                                                                    schedule.monday.times.length > 0 ?
                                                                                                                                                        schedule.monday.times.map((sadn, key) => {
                                                                                                                                                            const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                            const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                            const uid = uuidv4();
                                                                                                                                                            return (
                                                                                                                                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"mondayTimeFrame_" + uid}>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="mondayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote mondayStartTabsOneToOne' />
                                                                                                                                                                        <datalist id="mondayStart" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>

                                                                                                                                                                    </div>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="mondayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote mondayEndTabsOneToOne' />
                                                                                                                                                                        <datalist id="mondayEnd" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div className='d-flex align-items-center h-100'>
                                                                                                                                                                        <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("monday", "OneToOne", uid); }}></i>
                                                                                                                                                                    </div>
                                                                                                                                                                </span>
                                                                                                                                                            )
                                                                                                                                                        })
                                                                                                                                                        :
                                                                                                                                                        ["9:00am-5:00pm"].map((sadn, key) => {
                                                                                                                                                            const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                            const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                            const uid = uuidv4();
                                                                                                                                                            return (
                                                                                                                                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"mondayTimeFrame_" + uid}>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="mondayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote mondayStartTabsOneToOne' />
                                                                                                                                                                        <datalist id="mondayStart" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="mondayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote mondayEndTabsOneToOne' />
                                                                                                                                                                        <datalist id="mondayEnd" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div className='d-flex align-items-center h-100'>
                                                                                                                                                                        <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("monday", "OneToOne", uid); }}></i>
                                                                                                                                                                    </div>
                                                                                                                                                                </span>
                                                                                                                                                            )
                                                                                                                                                        })
                                                                                                                                                }
                                                                                                                                            </div>
                                                                                                                                            :
                                                                                                                                            <span className='smallTextNote'>Unavailable</span>
                                                                                                                                    }

                                                                                                                                </div>
                                                                                                                                <div className="col-2">
                                                                                                                                    <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                                                                                        <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("monday", "OneToOne", uuidv4()); }}></i>
                                                                                                                                        <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("monday", "OneToOne"); }} />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="row mb-4">
                                                                                                                                <div className="col-3">
                                                                                                                                    <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                                                                                        <label className='d-flex gap-1 align-items-center justify-content-start'>
                                                                                                                                            <div className="outerToggleSwitch" onClick={() => { setIsTuesdayActive(!isTuesdayActive); checkDayOnToggle(isSundayActive, isMondayActive, !isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, "OneToOne"); }}>
                                                                                                                                                <div className={`frameOfToggleSwitch ${isTuesdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                                                                    <span>On</span>
                                                                                                                                                    <div className="toggleSwitchBall"></div>
                                                                                                                                                    <span>Off</span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <span className='smallTextNote'>TUE</span>
                                                                                                                                        </label>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-7">
                                                                                                                                    {
                                                                                                                                        isTuesdayActive ?
                                                                                                                                            <div className='m-0 p-0' id="tuesdayFrameOneToOne">
                                                                                                                                                {
                                                                                                                                                    schedule.tuesday.times.length > 0 ?
                                                                                                                                                        schedule.tuesday.times.map((sadn, key) => {
                                                                                                                                                            const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                            const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                            const uid = uuidv4();
                                                                                                                                                            return (
                                                                                                                                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"tuesdayTimeFrame_" + uid}>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="tuesdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote tuesdayStartTabsOneToOne' />
                                                                                                                                                                        <datalist id="tuesdayStart" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="tuesdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote tuesdayEndTabsOneToOne' />
                                                                                                                                                                        <datalist id="tuesdayEnd" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div className='d-flex align-items-center h-100'>
                                                                                                                                                                        <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("tuesday", "OneToOne", uid); }}></i>
                                                                                                                                                                    </div>
                                                                                                                                                                </span>
                                                                                                                                                            )
                                                                                                                                                        })
                                                                                                                                                        :
                                                                                                                                                        ["9:00am-5:00pm"].map((sadn, key) => {
                                                                                                                                                            const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                            const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                            const uid = uuidv4();
                                                                                                                                                            return (
                                                                                                                                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"tuesdayTimeFrame_" + uid}>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="tuesdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote tuesdayStartTabsOneToOne' />
                                                                                                                                                                        <datalist id="tuesdayStart" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="tuesdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote tuesdayEndTabsOneToOne' />
                                                                                                                                                                        <datalist id="tuesdayEnd" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div className='d-flex align-items-center h-100'>
                                                                                                                                                                        <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("tuesday", "OneToOne", uid); }}></i>
                                                                                                                                                                    </div>
                                                                                                                                                                </span>
                                                                                                                                                            )
                                                                                                                                                        })
                                                                                                                                                }
                                                                                                                                            </div>
                                                                                                                                            :
                                                                                                                                            <span className='smallTextNote'>Unavailable</span>
                                                                                                                                    }

                                                                                                                                </div>
                                                                                                                                <div className="col-2">
                                                                                                                                    <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                                                                                        <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("tuesday", "OneToOne", uuidv4()); }}></i>
                                                                                                                                        <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("tuesday", "OneToOne"); }} />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="row mb-4">
                                                                                                                                <div className="col-3">
                                                                                                                                    <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                                                                                        <label className='d-flex gap-1 align-items-center justify-content-start'>
                                                                                                                                            <div className="outerToggleSwitch" onClick={() => { setIsWednesdayActive(!isWednesdayActive); checkDayOnToggle(isSundayActive, isMondayActive, isTuesdayActive, !isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, "OneToOne"); }}>
                                                                                                                                                <div className={`frameOfToggleSwitch ${isWednesdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                                                                    <span>On</span>
                                                                                                                                                    <div className="toggleSwitchBall"></div>
                                                                                                                                                    <span>Off</span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <span className='smallTextNote'>WED</span>
                                                                                                                                        </label>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-7">
                                                                                                                                    {
                                                                                                                                        isWednesdayActive ?
                                                                                                                                            <div className='m-0 p-0' id="wednesdayFrameOneToOne">
                                                                                                                                                {
                                                                                                                                                    schedule.wednesday.times.length > 0 ?
                                                                                                                                                        schedule.wednesday.times.map((sadn, key) => {
                                                                                                                                                            const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                            const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                            const uid = uuidv4();
                                                                                                                                                            return (
                                                                                                                                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"wednesdayTimeFrame_" + uid}>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="wednesdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote wednesdayStartTabsOneToOne' />
                                                                                                                                                                        <datalist id="wednesdayStart" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="wednesdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote wednesdayEndTabsOneToOne' />
                                                                                                                                                                        <datalist id="wednesdayEnd" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div className='d-flex align-items-center h-100'>
                                                                                                                                                                        <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("wednesday", "OneToOne", uid); }}></i>
                                                                                                                                                                    </div>
                                                                                                                                                                </span>
                                                                                                                                                            )
                                                                                                                                                        })
                                                                                                                                                        :
                                                                                                                                                        ["9:00am-5:00pm"].map((sadn, key) => {
                                                                                                                                                            const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                            const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                            const uid = uuidv4();
                                                                                                                                                            return (
                                                                                                                                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"wednesdayTimeFrame_" + uid}>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="wednesdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote wednesdayStartTabsOneToOne' />
                                                                                                                                                                        <datalist id="wednesdayStart" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="wednesdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote wednesdayEndTabsOneToOne' />
                                                                                                                                                                        <datalist id="wednesdayEnd" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div className='d-flex align-items-center h-100'>
                                                                                                                                                                        <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("wednesday", "OneToOne", uid); }}></i>
                                                                                                                                                                    </div>
                                                                                                                                                                </span>
                                                                                                                                                            )
                                                                                                                                                        })

                                                                                                                                                }
                                                                                                                                            </div>
                                                                                                                                            :
                                                                                                                                            <span className='smallTextNote'>Unavailable</span>
                                                                                                                                    }

                                                                                                                                </div>
                                                                                                                                <div className="col-2">
                                                                                                                                    <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                                                                                        <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("wednesday", "OneToOne", uuidv4()); }}></i>
                                                                                                                                        <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("wednesday", "OneToOne"); }} />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="row mb-4">
                                                                                                                                <div className="col-3">
                                                                                                                                    <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                                                                                        <label className='d-flex gap-1 align-items-center justify-content-start'>
                                                                                                                                            <div className="outerToggleSwitch" onClick={() => { setIsThursdayActive(!isThursdayActive); checkDayOnToggle(isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, !isThursdayActive, isFridayActive, isSaturdayActive, "OneToOne"); }}>
                                                                                                                                                <div className={`frameOfToggleSwitch ${isThursdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                                                                    <span>On</span>
                                                                                                                                                    <div className="toggleSwitchBall"></div>
                                                                                                                                                    <span>Off</span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <span className='smallTextNote'>THU</span>
                                                                                                                                        </label>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-7">
                                                                                                                                    {
                                                                                                                                        isThursdayActive ?
                                                                                                                                            <div className='m-0 p-0' id="thursdayFrameOneToOne">
                                                                                                                                                {
                                                                                                                                                    schedule.thursday.times.length > 0 ?
                                                                                                                                                        schedule.thursday.times.map((sadn, key) => {
                                                                                                                                                            const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                            const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                            const uid = uuidv4();
                                                                                                                                                            return (
                                                                                                                                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"thursdayTimeFrame_" + uid}>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="thursdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote thursdayStartTabsOneToOne' />
                                                                                                                                                                        <datalist id="thursdayStart" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="thursdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote thursdayEndTabsOneToOne' />
                                                                                                                                                                        <datalist id="thursdayEnd" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div className='d-flex align-items-center h-100'>
                                                                                                                                                                        <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("thursday", "OneToOne", uid); }}></i>
                                                                                                                                                                    </div>
                                                                                                                                                                </span>
                                                                                                                                                            )
                                                                                                                                                        })
                                                                                                                                                        :
                                                                                                                                                        ["9:00am-5:00pm"].map((sadn, key) => {
                                                                                                                                                            const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                            const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                            const uid = uuidv4();
                                                                                                                                                            return (
                                                                                                                                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"thursdayTimeFrame_" + uid}>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="thursdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote thursdayStartTabsOneToOne' />
                                                                                                                                                                        <datalist id="thursdayStart" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="thursdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote thursdayEndTabsOneToOne' />
                                                                                                                                                                        <datalist id="thursdayEnd" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div className='d-flex align-items-center h-100'>
                                                                                                                                                                        <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("thursday", "OneToOne", uid); }}></i>
                                                                                                                                                                    </div>
                                                                                                                                                                </span>
                                                                                                                                                            )
                                                                                                                                                        })
                                                                                                                                                }
                                                                                                                                            </div>
                                                                                                                                            :
                                                                                                                                            <span className='smallTextNote'>Unavailable</span>
                                                                                                                                    }

                                                                                                                                </div>
                                                                                                                                <div className="col-2">
                                                                                                                                    <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                                                                                        <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("thursday", "OneToOne", uuidv4()); }}></i>
                                                                                                                                        <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("thursday", "OneToOne"); }} />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="row mb-4">
                                                                                                                                <div className="col-3">
                                                                                                                                    <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                                                                                        <label className='d-flex gap-1 align-items-center justify-content-start'>
                                                                                                                                            <div className="outerToggleSwitch" onClick={() => { setIsFridayActive(!isFridayActive); checkDayOnToggle(isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, !isFridayActive, isSaturdayActive, "OneToOne"); }}>
                                                                                                                                                <div className={`frameOfToggleSwitch ${isFridayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                                                                    <span>On</span>
                                                                                                                                                    <div className="toggleSwitchBall"></div>
                                                                                                                                                    <span>Off</span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <span className='smallTextNote'>FRI</span>
                                                                                                                                        </label>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-7">
                                                                                                                                    {
                                                                                                                                        isFridayActive ?
                                                                                                                                            <div className='m-0 p-0' id="fridayFrameOneToOne">
                                                                                                                                                {
                                                                                                                                                    schedule.friday.times.length > 0 ?
                                                                                                                                                        schedule.friday.times.map((sadn, key) => {
                                                                                                                                                            const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                            const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                            const uid = uuidv4();
                                                                                                                                                            return (
                                                                                                                                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"fridayTimeFrame_" + uid}>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="fridayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote fridayStartTabsOneToOne' />
                                                                                                                                                                        <datalist id="fridayStart" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="fridayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote fridayEndTabsOneToOne' />
                                                                                                                                                                        <datalist id="fridayEnd" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div className='d-flex align-items-center h-100'>
                                                                                                                                                                        <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("friday", "OneToOne", uid); }}></i>
                                                                                                                                                                    </div>
                                                                                                                                                                </span>
                                                                                                                                                            )
                                                                                                                                                        })
                                                                                                                                                        :
                                                                                                                                                        ["9:00am-5:00pm"].map((sadn, key) => {
                                                                                                                                                            const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                            const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                            const uid = uuidv4();
                                                                                                                                                            return (
                                                                                                                                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"fridayTimeFrame_" + uid}>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="fridayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote fridayStartTabsOneToOne' />
                                                                                                                                                                        <datalist id="fridayStart" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="fridayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote fridayEndTabsOneToOne' />
                                                                                                                                                                        <datalist id="fridayEnd" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div className='d-flex align-items-center h-100'>
                                                                                                                                                                        <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("friday", "OneToOne", uid); }}></i>
                                                                                                                                                                    </div>
                                                                                                                                                                </span>
                                                                                                                                                            )
                                                                                                                                                        })
                                                                                                                                                }
                                                                                                                                            </div>
                                                                                                                                            :
                                                                                                                                            <span className='smallTextNote'>Unavailable</span>
                                                                                                                                    }

                                                                                                                                </div>
                                                                                                                                <div className="col-2">
                                                                                                                                    <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                                                                                        <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("friday", "OneToOne", uuidv4()); }}></i>
                                                                                                                                        <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("friday", "OneToOne"); }} />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="row mb-4">
                                                                                                                                <div className="col-3">
                                                                                                                                    <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                                                                                        <label className='d-flex gap-1 align-items-center justify-content-start'>
                                                                                                                                            <div className="outerToggleSwitch" onClick={() => { setIsSaturdayActive(!isSaturdayActive); checkDayOnToggle(isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, !isSaturdayActive, "OneToOne"); }}>
                                                                                                                                                <div className={`frameOfToggleSwitch ${isSaturdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                                                                    <span>On</span>
                                                                                                                                                    <div className="toggleSwitchBall"></div>
                                                                                                                                                    <span>Off</span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <span className='smallTextNote'>SAT</span>
                                                                                                                                        </label>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-7">
                                                                                                                                    {
                                                                                                                                        isSaturdayActive ?
                                                                                                                                            <div className='m-0 p-0' id="saturdayFrameOneToOne">
                                                                                                                                                {
                                                                                                                                                    schedule.saturday.times.length > 0 ?
                                                                                                                                                        schedule.saturday.times.map((sadn, key) => {
                                                                                                                                                            const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                            const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                            const uid = uuidv4();
                                                                                                                                                            return (
                                                                                                                                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"saturdayTimeFrame_" + uid}>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="saturdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote saturdayStartTabsOneToOne' />
                                                                                                                                                                        <datalist id="saturdayStart" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="saturdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote saturdayEndTabsOneToOne' />
                                                                                                                                                                        <datalist id="saturdayEnd" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div className='d-flex align-items-center h-100'>
                                                                                                                                                                        <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("saturday", "OneToOne", uid); }}></i>
                                                                                                                                                                    </div>
                                                                                                                                                                </span>
                                                                                                                                                            )
                                                                                                                                                        })
                                                                                                                                                        :
                                                                                                                                                        ["9:00am-5:00pm"].map((sadn, key) => {
                                                                                                                                                            const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                            const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                            const uid = uuidv4();
                                                                                                                                                            return (
                                                                                                                                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"saturdayTimeFrame_" + uid}>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="saturdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote saturdayStartTabsOneToOne' />
                                                                                                                                                                        <datalist id="saturdayStart" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div>
                                                                                                                                                                        <input list="saturdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "OneToOne"); checkUpdatedStateOfTimeValidation("OneToOne"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote saturdayEndTabsOneToOne' />
                                                                                                                                                                        <datalist id="saturdayEnd" className='smallTextNote'>
                                                                                                                                                                            {
                                                                                                                                                                                timeListToMeasure.map((times, key) => {
                                                                                                                                                                                    let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                    return (
                                                                                                                                                                                        <option key={key} value={timeFile}>
                                                                                                                                                                                            {timeFile}
                                                                                                                                                                                        </option>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </datalist>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div className='d-flex align-items-center h-100'>
                                                                                                                                                                        <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("saturday", "OneToOne", uid); }}></i>
                                                                                                                                                                    </div>
                                                                                                                                                                </span>
                                                                                                                                                            )
                                                                                                                                                        })
                                                                                                                                                }
                                                                                                                                            </div>
                                                                                                                                            :
                                                                                                                                            <span className='smallTextNote'>Unavailable</span>
                                                                                                                                    }

                                                                                                                                </div>
                                                                                                                                <div className="col-2">
                                                                                                                                    <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                                                                                        <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("saturday", "OneToOne", uuidv4()); }}></i>
                                                                                                                                        <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("saturday", "OneToOne"); }} />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className='col-12 col-lg-1 py-2'></div>
                                                                                                                    <div className="col-12 col-lg-4 border rounded shadow-sm insightBackground">
                                                                                                                        <div className="alert d-flex flex-column align-items-center">
                                                                                                                            <p className='text-center' style={{ fontWeight: "600" }}>Date overrides</p>
                                                                                                                            <button type='button' className='btn btn-sm rounded-pill salesforce-style px-3 d-flex align-items-center' onClick={() => { setViewDateOverridePanel(true); }}>
                                                                                                                                <AddIcon /> Add date override
                                                                                                                            </button>
                                                                                                                        </div>
                                                                                                                        {
                                                                                                                            dateOverrides ?
                                                                                                                                Object.keys(dateOverrides).length > 0 ?
                                                                                                                                    Object.keys(dateOverrides).map((data, key) => {
                                                                                                                                        return (
                                                                                                                                            <div key={key} className="card mb-2 shadow-sm" id={`datesOverrides_${dateOverrides[data].uid}_OneToOne`}>
                                                                                                                                                <div className='card-header salesforce-style d-flex align-items-center justify-content-between'>
                                                                                                                                                    <span>{dayjs(dateOverrides[data].date).format("ddd, D MMM YYYY")}</span>
                                                                                                                                                    <span><i className='fa fa-trash' id={dateOverrides[data].uid} onClick={(e) => { deleteOverrideDate(dateOverrides[data].date, `datesOverrides_${dateOverrides[data].uid}_OneToOne`); }}></i></span>
                                                                                                                                                </div>
                                                                                                                                                <div className='card-body d-flex justify-content-between align-items-center'>
                                                                                                                                                    {
                                                                                                                                                        dateOverrides[data].active ?
                                                                                                                                                            <>
                                                                                                                                                                <div className='d-flex flex-column align-items-start justify-content-center'>
                                                                                                                                                                    {
                                                                                                                                                                        dateOverrides[data].timeSet.map((time, key) => {
                                                                                                                                                                            const set = time.split("-");
                                                                                                                                                                            return (
                                                                                                                                                                                <React.Fragment key={key}>
                                                                                                                                                                                    <button disabled className='btn btn-secondary border-0 mb-1 w-100'>
                                                                                                                                                                                        {
                                                                                                                                                                                            user.settings.timeFormat.value === "12 Hours" ?
                                                                                                                                                                                                `${dayjs(`2 November 1999 ${set[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")}-${dayjs(`2 November 1999 ${set[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")}`
                                                                                                                                                                                                :
                                                                                                                                                                                                `${dayjs(`2 November 1999 ${set[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm")}-${dayjs(`2 November 1999 ${set[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm")}`
                                                                                                                                                                                        }
                                                                                                                                                                                    </button>
                                                                                                                                                                                </React.Fragment>
                                                                                                                                                                            )
                                                                                                                                                                        })
                                                                                                                                                                    }
                                                                                                                                                                </div>
                                                                                                                                                            </>
                                                                                                                                                            :
                                                                                                                                                            <>
                                                                                                                                                                <span>Unavailable</span>
                                                                                                                                                            </>
                                                                                                                                                    }
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        )
                                                                                                                                    })
                                                                                                                                    :
                                                                                                                                    <p className='text-center'>No overrides for now</p>
                                                                                                                                :
                                                                                                                                <p className='text-center'>No overrides for now</p>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div className="card-footer d-flex align-items-center justify-content-center gap-3">
                                                                                                    {/* <button style={{ maxWidth: "120px" }} className='w-100 text-center btn btn-secondary rounded-pill py-1' type='button' onClick={() => { setCreateNewSchedule(false); setSchedule(defaultSchedule); setDateOverrides(defaultOverride); window.location.reload(); }}>Cancel</button> */}
                                                                                                    <button style={{ maxWidth: "max-content" }} className='w-100 text-center btn salesforce-style rounded-pill py-1' id="saveSchedule1" type='button' disabled={!updateButtonEnabled || !isCustomLinkAvailable} onClick={(e) => { updateNewSchedule(e, "OneToOne"); }}>Save Schedule</button>
                                                                                                </div>
                                                                                            </div>

                                                                                            {/* ##################### END OF NEW SCHEDULE SYSTEM ONE TO ONE ###################### */}

                                                                                        </div>
                                                                                    </div>



                                                                                    <div className="row my-3">
                                                                                        <div className="col-12 col-md-6 col-lg-6 mb-4">
                                                                                            <div className="container-fluid border insightBackground rounded" style={{ position: "relative", zIndex: "3" }}>
                                                                                                <div className="row">
                                                                                                    <div className="col-12">
                                                                                                        <span style={{ fontWeight: "500" }} className='my-3 d-block'>Want to add time before or after your events?</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row mb-2">
                                                                                                    <div className="col-12 d-flex align-items-center gap-4 py-2">
                                                                                                        {
                                                                                                            eventBeforeTimeActive && (
                                                                                                                <input type="checkbox" defaultChecked onClick={() => { setEventBeforeTimeActive(false); }} className='form-check-input checkboxInpage' />
                                                                                                            )
                                                                                                        }
                                                                                                        {
                                                                                                            eventBeforeTimeActive === false && (
                                                                                                                <input type="checkbox" onClick={() => { setEventBeforeTimeActive(true); }} className='form-check-input checkboxInpage' />
                                                                                                            )
                                                                                                        }

                                                                                                        <div className=''>
                                                                                                            <span className={`${eventBeforeTimeActive ? "" : "text-muted"} d-block`} style={{ fontWeight: "500" }}>
                                                                                                                Before Event
                                                                                                            </span>

                                                                                                            {/* <DropdownButton
                                                                                                        as="ButtonGroup"
                                                                                                        className='w-100'
                                                                                                        id={`dropdown-button-drop-down`}
                                                                                                        drop={"down"}
                                                                                                        variant="light"
                                                                                                        disabled={eventBeforeTimeActive ? false : true}
                                                                                                        title={
                                                                                                            eventBeforeTime > 60 ?
                                                                                                                Math.floor(eventBeforeTime % 60) === 0 ?
                                                                                                                    Math.floor(eventBeforeTime / 60) + " hrs"
                                                                                                                    : Math.floor(eventBeforeTime / 60) + " hrs, " + Math.floor(eventBeforeTime % 60) + " min"
                                                                                                                : Math.floor(eventBeforeTime) + " min"
                                                                                                        }
                                                                                                        onSelect={(e, event) => { setEventBeforeTime(e); }}
                                                                                                    >
                                                                                                        <Dropdown.Item eventKey="15">15 minutes</Dropdown.Item>
                                                                                                        <Dropdown.Item eventKey="30">30 minutes</Dropdown.Item>
                                                                                                        <Dropdown.Item eventKey="45">45 minutes</Dropdown.Item>
                                                                                                        <Dropdown.Item eventKey="60">1 hr</Dropdown.Item>
                                                                                                        <Dropdown.Item eventKey="90">1 hr 30 min</Dropdown.Item>
                                                                                                        <Dropdown.Item eventKey="120">2 hr</Dropdown.Item>
                                                                                                        <Dropdown.Item eventKey="150">2 hr 30 min</Dropdown.Item>
                                                                                                        <Dropdown.Item eventKey="180">3 hr</Dropdown.Item>
                                                                                                    </DropdownButton> */}

                                                                                                            {
                                                                                                                selectedEventBeforeTimeFromList ?
                                                                                                                    <Select
                                                                                                                        isDisabled={eventBeforeTimeActive ? false : true}
                                                                                                                        options={addTimeAfterBeforeForSelect}
                                                                                                                        defaultValue={selectedEventBeforeTimeFromList}
                                                                                                                        onChange={(e) => { setEventBeforeTime(e.value) }}
                                                                                                                    />
                                                                                                                    :
                                                                                                                    ""
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="row mb-2">
                                                                                                    <div className="col-12 d-flex align-items-center gap-4 py-2">
                                                                                                        {
                                                                                                            eventAfterTimeActive && (
                                                                                                                <input type="checkbox" defaultChecked onClick={() => { setEventAfterTimeActive(false); }} className='form-check-input checkboxInpage' />
                                                                                                            )
                                                                                                        }
                                                                                                        {
                                                                                                            eventAfterTimeActive === false && (
                                                                                                                <input type="checkbox" onClick={() => { setEventAfterTimeActive(true); }} className='form-check-input checkboxInpage' />
                                                                                                            )
                                                                                                        }
                                                                                                        <div>
                                                                                                            <span className={`${eventAfterTimeActive ? "" : "text-muted"} d-block`} style={{ fontWeight: "500" }}>
                                                                                                                After Event
                                                                                                            </span>
                                                                                                            {/* <DropdownButton
                                                                                                        as="ButtonGroup"
                                                                                                        className='w-100'
                                                                                                        id={`dropdown-button-drop-down`}
                                                                                                        drop={"down"}
                                                                                                        variant="light"
                                                                                                        disabled={eventAfterTimeActive ? false : true}
                                                                                                        title={
                                                                                                            eventAfterTime > 60 ?
                                                                                                                Math.floor(eventAfterTime % 60) === 0 ?
                                                                                                                    Math.floor(eventAfterTime / 60) + " hrs"
                                                                                                                    : Math.floor(eventAfterTime / 60) + " hrs, " + Math.floor(eventAfterTime % 60) + " min"
                                                                                                                : Math.floor(eventAfterTime) + " min"
                                                                                                        }
                                                                                                        onSelect={(e, event) => { setEventAfterTime(e); }}
                                                                                                    >
                                                                                                        <Dropdown.Item eventKey="15">15 minutes</Dropdown.Item>
                                                                                                        <Dropdown.Item eventKey="30">30 minutes</Dropdown.Item>
                                                                                                        <Dropdown.Item eventKey="45">45 minutes</Dropdown.Item>
                                                                                                        <Dropdown.Item eventKey="60">1 hr</Dropdown.Item>
                                                                                                        <Dropdown.Item eventKey="90">1 hr 30 min</Dropdown.Item>
                                                                                                        <Dropdown.Item eventKey="120">2 hr</Dropdown.Item>
                                                                                                        <Dropdown.Item eventKey="150">2 hr 30 min</Dropdown.Item>
                                                                                                        <Dropdown.Item eventKey="180">3 hr</Dropdown.Item>
                                                                                                    </DropdownButton> */}

                                                                                                            {
                                                                                                                selectedEventAfterTimeFromList ?
                                                                                                                    <Select
                                                                                                                        isDisabled={eventAfterTimeActive ? false : true}
                                                                                                                        options={addTimeAfterBeforeForSelect}
                                                                                                                        defaultValue={selectedEventAfterTimeFromList}
                                                                                                                        onChange={(e) => { setEventAfterTime(e.value) }}
                                                                                                                    />
                                                                                                                    :
                                                                                                                    ""
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-md-5 col-lg-5 d-flex flex-column">
                                                                                            <p className='mediumTextSize'>Give yourself some buffer time to prepare for or wrap up from booked Event.</p>
                                                                                            <div className="rounded h-100">
                                                                                                <div className="card rounded overflow-hidden insightBackground">
                                                                                                    <div className={`card-header p-1 ${eventBeforeTimeActive ? "salesforce-style" : ""}`}></div>
                                                                                                    <div className="card-body p-0">
                                                                                                        <button type='button' className='btn bg-body w-100 text-break'>{eventName}</button>
                                                                                                    </div>
                                                                                                    <div className={`card-footer p-1 ${eventAfterTimeActive ? "salesforce-style" : ""}`}></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row mb-3">
                                                                                        <div className="col-12 col-md-6 col-lg-6 d-flex align-items-center gap-2 mb-2">
                                                                                            <div className='border insightBackground rounded p-3 w-100'>
                                                                                                <div className='mb-3'>
                                                                                                    <span style={{ fontWeight: "500" }}>Secret Event</span>
                                                                                                    <p className='m-0 p-0 mediumTextSize'>Use this to hide the event on your main scheduling page.</p>
                                                                                                </div>
                                                                                                <div className='mb-2 d-flex align-items-center justify-content-start gap-2'>
                                                                                                    {
                                                                                                        secretEvent && (
                                                                                                            <input type="checkbox" defaultChecked onClick={() => { setSecretEvent(false); }} className='form-check-imput checkboxInpage' />
                                                                                                        )
                                                                                                    }
                                                                                                    {
                                                                                                        secretEvent === false && (
                                                                                                            <input type="checkbox" onClick={() => { setSecretEvent(true); }} className='form-check-imput checkboxInpage' />
                                                                                                        )
                                                                                                    }
                                                                                                    <span className={`${secretEvent ? "" : "text-muted"} mediumTextSize`} style={{ fontWeight: "500" }}>
                                                                                                        Make this a Secret Event
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-12 col-md-6 col-lg-6'>
                                                                                            <div className='mediumTextSize w-100'>
                                                                                                <span style={{ fontWeight: "500" }}> Note:</span> Only Invitees who have this Schedule AI link can schedule with you.
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* #################################### Additional Settings Started ############################################### */}

                                                                                    <div className="row mb-3">
                                                                                        <div className="col-12 col-md-6 col-lg-6">
                                                                                            <span style={{ fontWeight: "500", fontSize: "1.2rem" }}>Additional Settings</span>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="row mb-3">
                                                                                        <div className="col-12 col-md-6 col-lg-6">
                                                                                            <div className='insightBackground border p-3 rounded' style={{ position: "relative", zIndex: "2" }}>
                                                                                                <span className='d-block mb-2' style={{ fontWeight: "500" }}>Stop Bookings before:</span>
                                                                                                {/* <select id='endDateTime' className=' mt-2' value={stopBookingTime[1] > 4 ? "custom" : stopBookingTime[1]} required onChange={(e) => { if (e.target.value !== "custom") { setStopBookingTime([stopTimeForBookingType, e.target.value]); setCustomStopBookingView(false); } else { setCustomStopBookingView(true); setStopBookingTime([stopTimeForBookingType, 1]); } }}>
                                                                                    <option value="1">1 Hour</option>
                                                                                    <option value="2">2 Hours</option>
                                                                                    <option value="3">3 Hours</option>
                                                                                    <option value="4">4 Hours</option>
                                                                                    <option value="custom">Custom</option>
                                                                                </select> */}
                                                                                                {/* <DropdownButton
                                                                                            as="ButtonGroup"
                                                                                            className='w-100'
                                                                                            drop={"down"}
                                                                                            variant="light"
                                                                                            title={
                                                                                                parseInt(stopBookingTime[1]) === 1 ? stopBookingTime[1] + " Hour" : stopBookingTime[1] + " Hours"
                                                                                            }
                                                                                            onSelect={(e, event) => { if (e !== "custom") { setStopBookingTime([stopTimeForBookingType, e]); setCustomStopBookingView(false); } else { setCustomStopBookingView(true); setStopBookingTime([stopTimeForBookingType, 1]); } }}

                                                                                        >
                                                                                            <Dropdown.Item eventKey="1">1 Hour</Dropdown.Item>
                                                                                            <Dropdown.Item eventKey="2">2 Hours</Dropdown.Item>
                                                                                            <Dropdown.Item eventKey="3">3 Hours</Dropdown.Item>
                                                                                            <Dropdown.Item eventKey="4">4 Hours</Dropdown.Item>
                                                                                            <Dropdown.Item eventKey="custom">custom</Dropdown.Item>
                                                                                        </DropdownButton> */}
                                                                                                {
                                                                                                    selectedStopBookingBeforeLabel ?
                                                                                                        <Select
                                                                                                            options={stopBookingBeforeListForSelect}
                                                                                                            defaultValue={selectedStopBookingBeforeLabel}
                                                                                                            onChange={(e) => {
                                                                                                                if (e.label !== "custom") {
                                                                                                                    setStopBookingTime([stopTimeForBookingType, e.value]);
                                                                                                                    setCustomStopBookingView(false);
                                                                                                                }
                                                                                                                else {
                                                                                                                    setCustomStopBookingView(true);
                                                                                                                    setStopBookingTime([stopTimeForBookingType, 1]);
                                                                                                                }
                                                                                                            }}
                                                                                                        />
                                                                                                        : ""
                                                                                                }
                                                                                                {
                                                                                                    customStopBookingView &&
                                                                                                    <div className={`${customStopBookingView} d-flex justify-content-center align-items-center gap-2 my-2`}>
                                                                                                        {
                                                                                                            stopTimeForBookingType === "hours" ?
                                                                                                                <input className='customInputFieldFocus w-100' required type="number" min="1" max="23" value={stopBookingTime[1]} onChange={(e) => { setStopBookingTime(["hours", e.target.value]); }} />
                                                                                                                :
                                                                                                                <input className='customInputFieldFocus w-100' required type="number" min="1" max="60" defaultValue={stopBookingTime[1]} onChange={(e) => { setStopBookingTime(["days", e.target.value]); }} />
                                                                                                        }
                                                                                                        {/* <DropdownButton
                                                                                                        as="ButtonGroup"
                                                                                                        className='w-100'
                                                                                                        id={`dropdown-button-drop-down`}
                                                                                                        drop={"down"}
                                                                                                        variant="light"
                                                                                                        title="Hours"
                                                                                                        onSelect={(e, event) => { setStopTimeForBookingType(e); }}
                                                                                                    >
                                                                                                        <Dropdown.Item eventKey="hours">hours</Dropdown.Item>
                                                                                                    </DropdownButton> */}

                                                                                                        <Select
                                                                                                            className='w-100'
                                                                                                            options={[{ label: "hours", value: "hours" }]}
                                                                                                            defaultValue={[{ label: "hours", value: "hours" }]}
                                                                                                            onChange={(e) => { setStopTimeForBookingType(e.label); }}
                                                                                                        />
                                                                                                        {/* <select className='' onChange={(e) => { setStopTimeForBookingType(e.target.value); }} value={stopTimeForBookingType === "hours" ? "hours" : "days"}>
                                                                                                <option value="hours">hours</option>
                                                                                                <option value="days">days</option>
                                                                                            </select> */}
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-md-6 col-lg-6">
                                                                                            <div className='mediumTextSize w-100'>
                                                                                                <span style={{ fontWeight: "500" }}>Note:</span> This feature can control the last minute booking problems. Set your desired time to stop bookings after that particular time.
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="row mb-3">
                                                                                        <div className="col-9 col-sm-9 col-md-9 col-lg-9">
                                                                                            <span style={{ fontWeight: "500", fontSize: "1.2rem" }}>Questions for Invitees:</span>
                                                                                        </div>
                                                                                        <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                                                                            <span className="mediumTextSize" style={{ fontWeight: "500" }}>Required</span>
                                                                                            <OverlayTrigger
                                                                                                placement="left"
                                                                                                delay={{ show: 100, hide: 200 }}
                                                                                                overlay={
                                                                                                    <Popover id="popover-basic">
                                                                                                        <Popover.Header className='salesforce-style' as="h3">Required Questions</Popover.Header>
                                                                                                        <Popover.Body>
                                                                                                            <p className='p-0 m-0 mb-2 helpTextSize'>You can make a questions mandatory while your invitees book a meeting whith you by using this switch.</p>
                                                                                                            <img src={requiredQuestionPng} className='w-100' alt="" />
                                                                                                        </Popover.Body>
                                                                                                    </Popover>
                                                                                                }>
                                                                                                <HelpOutlineIcon style={{ maxWidth: "15px" }} />
                                                                                            </OverlayTrigger>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="row mb-3">
                                                                                        <div className="col-9 col-md-9 col-lg-9">
                                                                                            <input type="text" className='customInputFieldFocus w-100' onChange={(e) => { setOneOnOneQuestions(e.target.value); }} value={oneOnOneQuestions} placeholder='enter your question here?' />
                                                                                        </div>
                                                                                        <div className="col-3 col-md-3 col-lg-3 d-flex align-items-center">
                                                                                            <div className="outerToggleSwitch" onClick={() => { setIsDescriptionRequired(!isDescriptionRequired); }}>
                                                                                                <div className={`frameOfToggleSwitch ${isDescriptionRequired === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                    <span>On</span>
                                                                                                    <div className="toggleSwitchBall"></div>
                                                                                                    <span>Off</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* #################################### Additional Settings End ############################################### */}

                                                                                    <div className="row my-3">
                                                                                        <div className="col d-flex align-items-center justify-content-center">
                                                                                            <div className='d-flex justify-content-center gap-2 w-100'>
                                                                                                <button type='reset' style={{ maxWidth: "120px" }} className='btn btn-sm w-100 text-center btn-secondary rounded-pill' onClick={() => { setWhenEvent(false); window.location.reload(); }}>Cancel</button>
                                                                                                <button type="submit" style={{ maxWidth: "120px" }} className='btn btn-sm w-100 text-center salesforce-style rounded-pill d-flex align-items-center justify-content-center gap-2' id="oneOnOneEditButton2" disabled={!updateButtonEnabled || !isCustomLinkAvailable}>Update</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </Accordion.Body>
                                                                        </Accordion.Item>
                                                                        {/* <Accordion.Item eventKey="3">
                                                                                <Accordion.Header onClick={(e) => { setWhenEvent(false); setWhatEvent(false); setCustomizedEvent(!customizedEvent); }}>
                                                                                    <div className="container-fluid p-0">
                                                                                        <div className="row sticky-top">
                                                                                            <div className="col p-0">
                                                                                                <div className='eventPreview'>
                                                                                                    <img src={caneldarDark} alt="Calendar Icon" className="iconsForLocation" style={{ width: "2em", alignSelf: "center" }} />
                                                                                                    <div className='d-flex flex-column gap-1'>
                                                                                                        <div className='prewEventWhich text-dark'>Confirmation Page.</div>
                                                                                                        <span className='mediumTextSize text-dark'>Set things after the booking.</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Accordion.Header>
                                                                                <Accordion.Body className='bg-soul'>
                                                                                    <div className="row mb-3">
                                                                                        <div className="col-12 col-md-6 col-lg-6">
                                                                                            <label className='p-3 border bg-body rounded w-100' >
                                                                                                <h6>On Confirmation</h6>
                                                                                                <select onChange={(e) => { e.target.value === "default" ? setRedirectView(false) : setRedirectView(true); }}>
                                                                                                    <option value="default">Display Default Confirmation Page.</option>
                                                                                                    <option value="redirect">Redirect to an External Site.</option>
                                                                                                </select>
                                                                                            </label>

                                                                                            {
                                                                                                redirectView ?
                                                                                                    <label>
                                                                                                        <h6>Redirect URL *</h6>
                                                                                                        <input type="url" className="customInputFieldFocus w-100 rounded mt-2 px-2" placeholder="https://example.com" pattern="https://.*" size="30" required />
                                                                                                    </label>
                                                                                                    :
                                                                                                    <div className='w-100'>
                                                                                                        <label className='mt-2'>
                                                                                                            <h6>Display button to schedule another event?</h6>
                                                                                                            <div className='d-flex align-items-center justify-content-start gap-2'>
                                                                                                                <div className="container-fluid p-2">
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-10 p-1">
                                                                                                                            <input type="text" className='customInputFieldFocus w-100 rounded px-2' placeholder='Label on button' disabled={!anotherBookingButton} />
                                                                                                                        </div>
                                                                                                                        <div className="col-2 p-1 d-flex align-items-center justify-content-center">
                                                                                                                            {
                                                                                                                                <div className="outerToggleSwitch" onClick={() => { setToggleSwitchOn(!toggleSwitchOn); setAnotherBookingButton(!anotherBookingButton); }}>
                                                                                                                                    <div className={`frameOfToggleSwitch ${toggleSwitchOn === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                                                        <span>On</span>
                                                                                                                                        <div className="toggleSwitchBall"></div>
                                                                                                                                        <span>Off</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                        </label>
                                                                                                        {
                                                                                                            pageLinks ?
                                                                                                                pageLinks.length > 0 ?
                                                                                                                    <div className='d-flex align-items-start justify-content-start flex-column gap-3'>
                                                                                                                        {
                                                                                                                            pageLinks.map((data, key) => {
                                                                                                                                return <a key={key} className="alert alert-primary py-1" href={data.link}>{data.title}</a>
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    ""
                                                                                                                :
                                                                                                                ""
                                                                                                        }

                                                                                                        {
                                                                                                            addCustomLinkView ?
                                                                                                                <form className='w-100' onSubmit={(e) => { insertLinkToEvent(e); }}>
                                                                                                                    <label className="mt-2">
                                                                                                                        <h6>Add Link</h6>
                                                                                                                        <input type="text" required ref={customLinkName} className="customInputFieldFocus w-100 rounded mt-2 px-2" placeholder='Your Custom Link' />
                                                                                                                        <input type="url" required ref={customLinkData} className="customInputFieldFocus w-100 rounded mt-2 px-2" placeholder="https://example.com" pattern="https://.*" size="30" />
                                                                                                                        <div className='d-flex align-items-center justify-content-start w-100 gap-3 mt-2'>
                                                                                                                            <button type="button" className='btn btn-outline-primary rounded-pill px-4 py-2'>Add</button>
                                                                                                                            <button type="submit" className='btn btn-outline-dark rounded-pill px-4 py-2' onClick={() => { setAddCustomLinkView(false); }}>Cancel</button>
                                                                                                                        </div>
                                                                                                                    </label>
                                                                                                                </form>
                                                                                                                :
                                                                                                                <button type='button' onClick={() => { setAddCustomLinkView(true) }} className='btn salesforce-style rounded-pill px-4 py-2 mt-2'>Add Custom Link?</button>
                                                                                                        }

                                                                                                    </div>

                                                                                            }

                                                                                        </div>
                                                                                        <div className="col-12 col-lg-6 col-md-6"></div>
                                                                                    </div>

                                                                                    <div className="row my-3">
                                                                                        <div className="col d-flex align-items-center justify-content-center">
                                                                                            <div className='d-flex justify-content-center gap-2'>
                                                                                                <button type='button' className='btn btn-danger rounded-pill px-4 py-2' onClick={() => { setCustomizedEvent(false); window.location.reload() }}>Cancel</button>
                                                                                                <button className='btn salesforce-style rounded-pill px-4 py-2'>Update</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>
                                                                            <Accordion.Item eventKey="4">
                                                                                <Accordion.Header>
                                                                                    Workflows
                                                                                </Accordion.Header>
                                                                                <Accordion.Body className='bg-soul'>
                                                                                    Set Your Workflows Here
                                                                                </Accordion.Body>
                                                                            </Accordion.Item> */}
                                                                    </Accordion>
                                                                </form>


                                                            </>
                                                        )
                                                        :
                                                        myEvent?.eventType === "Group" ?
                                                            (
                                                                <>
                                                                    <form id="eventFormHandle" className="eventGoogleForm p-0 w-100" onSubmit={updateGroupMeeting}>
                                                                        <Accordion className="w-100 insightBackground">
                                                                            <Accordion.Item eventKey="1">
                                                                                <Accordion.Header>
                                                                                    <div className="container-fluid p-0">
                                                                                        <div className="row sticky-top">
                                                                                            <div className="col p-0" onClick={(e) => { setWhatEvent(!whatEvent); setWhenEvent(false); setCustomizedEvent(false); }}>
                                                                                                <div className='eventPreview'>
                                                                                                    <div style={{ height: "2em", width: "2em", borderRadius: "100%", pointerEvents: "none", alignSelf: "center", aspectRatio: "1" }} className={` event-color-${groupColorId}`} ></div>
                                                                                                    <div className='d-flex flex-column gap-1'>
                                                                                                        <div className='prewEventWhich text-dark'>What event is this?</div>
                                                                                                        <div className='eventNamePrew text-dark d-flex align-items-center gap-2 flex-wrap mediumTextSize'>
                                                                                                            <span id="text-capitalize text-dark text-break" style={groupSummary ? groupSummary.includes(" ") ? { wordBreak: "break-word" } : { wordBreak: "break-all" } : { wordBreak: "break-all" }}>
                                                                                                                {
                                                                                                                    groupSummary ?
                                                                                                                        <>
                                                                                                                            {groupSummary},
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        ""
                                                                                                                }
                                                                                                            </span>
                                                                                                            <span id="eventLocationPrew text-dark">
                                                                                                                {
                                                                                                                    groupMeetingType === "phone" ?
                                                                                                                        <span className='selectedLocation'>
                                                                                                                            <img alt="Call" src={callimg} className='iconsForLocation' />
                                                                                                                            <span className='text-dark'>Phone Call</span>
                                                                                                                        </span> :
                                                                                                                        groupMeetingType === "meet" ?
                                                                                                                            <span className='selectedLocation'>
                                                                                                                                <img src={meetImg} alt="Meet" className='iconsForLocation' />
                                                                                                                                <span className='text-dark'>Google Meet Conference</span>
                                                                                                                            </span> :
                                                                                                                            groupMeetingType === "zoom" ?
                                                                                                                                <span className='selectedLocation'>
                                                                                                                                    <img src={zoomImg} alt="Zoom" className='iconsForLocation' />
                                                                                                                                    <span>Zoom Conference</span>
                                                                                                                                    {
                                                                                                                                        needZoomToken ?
                                                                                                                                            <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                                                                                            : ""
                                                                                                                                    }
                                                                                                                                </span> :
                                                                                                                                groupMeetingType === "goto" ?
                                                                                                                                    <span className='selectedLocation'>
                                                                                                                                        <img src={gotoImg} alt="GoTo" className='iconsForLocation' />
                                                                                                                                        <span>GoTo Conference</span>
                                                                                                                                        {
                                                                                                                                            needGTMToken ?
                                                                                                                                                <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                                                                                                : ""
                                                                                                                                        }
                                                                                                                                    </span> :
                                                                                                                                    groupMeetingType === "webex" ?
                                                                                                                                        <span className='selectedLocation'>
                                                                                                                                            <img src={webexImg} alt="Webex" className='iconsForLocation' />
                                                                                                                                            <span>Webex Conference</span>
                                                                                                                                            {
                                                                                                                                                needWebexToken ?
                                                                                                                                                    <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                                                                                                    : ""
                                                                                                                                            }
                                                                                                                                        </span> :
                                                                                                                                        groupMeetingType === "teams" ?
                                                                                                                                            <span className='selectedLocation'>
                                                                                                                                                <img src={teamsImg} alt="Teams" className='iconsForLocation' />
                                                                                                                                                <span>Teams Conference</span>
                                                                                                                                                {
                                                                                                                                                    needTeamsToken ?
                                                                                                                                                        <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                                                                                                        : ""
                                                                                                                                                }
                                                                                                                                            </span> :
                                                                                                                                            groupMeetingType === "address" && myEvent.location.length > 0 && groupLocation.length > 0 ?
                                                                                                                                                <span className='selectedLocation'>
                                                                                                                                                    <img src={addressImg} alt="Address" className='iconsForLocation' />
                                                                                                                                                    <span className='text-dark'>{groupLocation ? groupLocation : myEvent.location}</span>
                                                                                                                                                </span>
                                                                                                                                                :
                                                                                                                                                <>
                                                                                                                                                    <img src={addressImg} alt="Address" className='iconsForLocation' />
                                                                                                                                                    {groupLocation ? groupLocation : "No location Given"}
                                                                                                                                                </>
                                                                                                                }
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Accordion.Header>
                                                                                <Accordion.Body className='bg-soul'>
                                                                                    <div className="container-fluid p-0">
                                                                                        <div className="row my-3">
                                                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                                                <label htmlFor='eventName' className='border w-100 p-2 insightBackground rounded'>
                                                                                                    <span style={{ fontWeight: "500" }}>
                                                                                                        Event Name *
                                                                                                    </span>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        maxLength={100}
                                                                                                        id="summary"
                                                                                                        className='customInputFieldFocus w-100 px-2'
                                                                                                        defaultValue={groupSummary}
                                                                                                        required
                                                                                                        placeholder="Add the name of the event"
                                                                                                        onChange={e => setGroupSummary(e.target.value)}
                                                                                                    />
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="col-12 col-md-6 col-lg-6">

                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="row my-3">
                                                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                                                <label htmlFor='selectLocation' className={`${groupLocationSelection ? "mb-0" : ""} border insightBackground rounded p-2 w-100`} onClick={() => { setGroupLocationSelection(!groupLocationSelection); }}>
                                                                                                    <span style={{ fontWeight: "500" }}>Select Location</span>
                                                                                                    <div id="selectLocation" className='locationFrame bg-soul rounded'>
                                                                                                        {
                                                                                                            groupMeetingType ?
                                                                                                                groupMeetingType === "phone" ?
                                                                                                                    (
                                                                                                                        <div className='selectedLocation'>
                                                                                                                            <img alt="call" src={callimg} className='iconsForLocation' />
                                                                                                                            <span>
                                                                                                                                Phone call
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                    :
                                                                                                                    groupMeetingType === "meet" ?
                                                                                                                        (
                                                                                                                            <div className='selectedLocation'>
                                                                                                                                <img alt="meet" src={meetImg} className='iconsForLocation' />
                                                                                                                                <span>
                                                                                                                                    Google Meet Conference
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                        :
                                                                                                                        groupMeetingType === "zoom" ?
                                                                                                                            (<div className='selectedLocation'>
                                                                                                                                <img alt="zoom" src={zoomImg} className='iconsForLocation' />
                                                                                                                                <span>
                                                                                                                                    Zoom Conference
                                                                                                                                </span>
                                                                                                                                {
                                                                                                                                    needZoomToken ?
                                                                                                                                        <i className="fa fa-chain-broken text-danger" title="not integrated" aria-hidden="true"></i> : ""
                                                                                                                                }
                                                                                                                            </div>)
                                                                                                                            :
                                                                                                                            groupMeetingType === "goto" ?
                                                                                                                                (<div className='selectedLocation'>
                                                                                                                                    <img alt="Goto" src={gotoImg} className='iconsForLocation' />
                                                                                                                                    <span>
                                                                                                                                        GoTo Conference
                                                                                                                                    </span>
                                                                                                                                    {
                                                                                                                                        needGTMToken ?
                                                                                                                                            <i className="fa fa-chain-broken text-danger" title="not integrated" aria-hidden="true"></i> : ""
                                                                                                                                    }
                                                                                                                                </div>)
                                                                                                                                :
                                                                                                                                groupMeetingType === "webex" ?
                                                                                                                                    (<div className='selectedLocation'>
                                                                                                                                        <img alt="Webex" src={webexImg} className='iconsForLocation' />
                                                                                                                                        <span>
                                                                                                                                            Webex Conference
                                                                                                                                        </span>
                                                                                                                                        {
                                                                                                                                            needWebexToken ?
                                                                                                                                                <i className="fa fa-chain-broken text-danger" title="not integrated" aria-hidden="true"></i> : ""
                                                                                                                                        }
                                                                                                                                    </div>)
                                                                                                                                    :
                                                                                                                                    groupMeetingType === "teams" ?
                                                                                                                                        (<div className='selectedLocation'>
                                                                                                                                            <img alt="Teams" src={teamsImg} className='iconsForLocation' />
                                                                                                                                            <span>
                                                                                                                                                Teams Conference
                                                                                                                                            </span>
                                                                                                                                            {
                                                                                                                                                needTeamsToken ?
                                                                                                                                                    <i className="fa fa-chain-broken text-danger" title="not integrated" aria-hidden="true"></i> : ""
                                                                                                                                            }
                                                                                                                                        </div>)
                                                                                                                                        :
                                                                                                                                        groupMeetingType === "address" ?
                                                                                                                                            (
                                                                                                                                                <div className='selectedLocation'>
                                                                                                                                                    <img alt="address" src={addressImg} className='iconsForLocation' />
                                                                                                                                                    <span>
                                                                                                                                                        Custom Address
                                                                                                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                            )
                                                                                                                                            : "unkown meeting Type"
                                                                                                                :
                                                                                                                ""
                                                                                                        }

                                                                                                    </div>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="col-12 col-md-6 col-lg-6">

                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="row my-3">
                                                                                            <div className="col-12 col-md-6 col-lg-6">

                                                                                                {
                                                                                                    groupLocationSelection &&
                                                                                                    <div id='locationSelector' className="locationSelector insightBackground rounded">
                                                                                                        <ul>
                                                                                                            <li title='Phone Call' onClick={() => { setGroupMeetingType("phone"); setGroupLocation("phone"); setGroupLocationSelection(false); }}>
                                                                                                                <span>
                                                                                                                    <img alt="call" src={callimg} className='iconsForLocation' />
                                                                                                                </span>
                                                                                                                <span>
                                                                                                                    Phone Call
                                                                                                                </span>
                                                                                                            </li>
                                                                                                            <li title='Google Meet Conference' onClick={() => { setGroupMeetingType("meet"); setGroupLocation("meet"); setGroupLocationSelection(false); }}>
                                                                                                                <span>
                                                                                                                    <img alt="meet" src={meetImg} className='iconsForLocation' />
                                                                                                                </span>
                                                                                                                <span>
                                                                                                                    Google Meet Conference
                                                                                                                </span>
                                                                                                            </li>
                                                                                                            <li title='Zoom Conference' onClick={() => { setGroupMeetingType("zoom"); setGroupLocation("zoom"); setGroupLocationSelection(false); }}>
                                                                                                                <span>
                                                                                                                    <img alt="Zoom" src={zoomImg} className='iconsForLocation' />
                                                                                                                </span>
                                                                                                                <span>
                                                                                                                    Zoom Conference
                                                                                                                </span>
                                                                                                                {
                                                                                                                    needZoomToken ?
                                                                                                                        <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i> : ""
                                                                                                                }
                                                                                                            </li>
                                                                                                            <li title='GoTo Conference' onClick={() => { setGroupMeetingType("goto"); setGroupLocation("goto"); setGroupLocationSelection(false); }}>
                                                                                                                <span>
                                                                                                                    <img alt="Goto" src={gotoImg} className='iconsForLocation' />
                                                                                                                </span>
                                                                                                                <span>
                                                                                                                    GoTo Conference
                                                                                                                </span>
                                                                                                                {
                                                                                                                    needGTMToken ?
                                                                                                                        <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i> : ""
                                                                                                                }
                                                                                                            </li>
                                                                                                            <li title='Webex Conference' onClick={() => { setGroupMeetingType("webex"); setGroupLocation("webex"); setGroupLocationSelection(false); }}>
                                                                                                                <span>
                                                                                                                    <img alt="Webex" src={webexImg} className='iconsForLocation' />
                                                                                                                </span>
                                                                                                                <span>
                                                                                                                    Webex Conference
                                                                                                                </span>
                                                                                                                {
                                                                                                                    needWebexToken ?
                                                                                                                        <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i> : ""
                                                                                                                }
                                                                                                            </li>
                                                                                                            <li title='Teams Conference' onClick={() => { setGroupMeetingType("teams"); setGroupLocation("teams"); setGroupLocationSelection(false); }}>
                                                                                                                <span>
                                                                                                                    <img alt="Teams" src={teamsImg} className='iconsForLocation' />
                                                                                                                </span>
                                                                                                                <span>
                                                                                                                    Teams Conference
                                                                                                                </span>
                                                                                                                {
                                                                                                                    needTeamsToken ?
                                                                                                                        <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i> : ""
                                                                                                                }
                                                                                                            </li>
                                                                                                            <li title='Address' onClick={() => { setGroupMeetingType("address"); setGroupLocation(myEvent.meetingType === "address" ? myEvent.location : ""); setGroupLocationSelection(false); }}>
                                                                                                                <span>
                                                                                                                    <img alt="zoom" src={addressImg} className='iconsForLocation' />
                                                                                                                </span>
                                                                                                                <span>
                                                                                                                    Custom Address
                                                                                                                </span>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                }

                                                                                                {
                                                                                                    groupMeetingType === "phone" && (
                                                                                                        <label className='border insightBackground rounded p-2 w-100'>
                                                                                                            <div className="askWhich">
                                                                                                                {
                                                                                                                    groupInboundCall ? (
                                                                                                                        <>
                                                                                                                            <label htmlFor="outboundCalloff2">
                                                                                                                                <input type="radio" id="outboundCalloff2" name="callBound" onClick={() => { setGroupInboundCall(false); }} />
                                                                                                                                I should call my invitees
                                                                                                                            </label>
                                                                                                                            <label htmlFor="inboundCallonn2">
                                                                                                                                {
                                                                                                                                    phoneList ?
                                                                                                                                        optionListForPhone.length > 0 ?
                                                                                                                                            <>
                                                                                                                                                <input defaultChecked type="radio" id="inboundCallonn2" name="callBound" onClick={() => { setGroupInboundCall(true); }} />
                                                                                                                                                <span>Invitees should call me</span>
                                                                                                                                            </>
                                                                                                                                            :
                                                                                                                                            <>
                                                                                                                                                <input defaultChecked type="radio" id="inboundCallonn2" name="callBound" disabled={true} onClick={() => { setGroupInboundCall(true); }} />
                                                                                                                                                <span className='text-muted'>Invitees should call me</span>
                                                                                                                                            </>
                                                                                                                                        :
                                                                                                                                        <>
                                                                                                                                            <input defaultChecked type="radio" id="inboundCallonn2" name="callBound" disabled={true} onClick={() => { setGroupInboundCall(true); }} />
                                                                                                                                            <span className='text-muted'>Invitees should call me</span>
                                                                                                                                        </>
                                                                                                                                }
                                                                                                                            </label>
                                                                                                                        </>
                                                                                                                    )
                                                                                                                        :
                                                                                                                        (
                                                                                                                            <>
                                                                                                                                <label htmlFor="outboundCalloff2">
                                                                                                                                    <input type="radio" defaultChecked id="outboundCalloff2" name="callBound" onClick={() => { setGroupInboundCall(false); }} />
                                                                                                                                    I should call my invitees
                                                                                                                                </label>
                                                                                                                                <label htmlFor="inboundCallonn2">
                                                                                                                                    {
                                                                                                                                        phoneList ?
                                                                                                                                            optionListForPhone.length > 0 ?
                                                                                                                                                <>
                                                                                                                                                    <input type="radio" id="inboundCallonn2" name="callBound" onClick={() => { setGroupInboundCall(true); }} />
                                                                                                                                                    <span>Invitees should call me</span>
                                                                                                                                                </>
                                                                                                                                                :
                                                                                                                                                <>
                                                                                                                                                    <input type="radio" id="inboundCallonn2" name="callBound" disabled={true} onClick={() => { setGroupInboundCall(true); }} />
                                                                                                                                                    <span className='text-muted'>Invitees should call me</span>
                                                                                                                                                </>
                                                                                                                                            :
                                                                                                                                            <>
                                                                                                                                                <input type="radio" id="inboundCallonn2" name="callBound" disabled={true} onClick={() => { setGroupInboundCall(true); }} />
                                                                                                                                                <span className='text-muted'>Invitees should call me</span>
                                                                                                                                            </>
                                                                                                                                    }
                                                                                                                                </label>
                                                                                                                            </>
                                                                                                                        )


                                                                                                                }
                                                                                                            </div>

                                                                                                        </label>
                                                                                                    )
                                                                                                    // setInboundCall
                                                                                                }


                                                                                                {
                                                                                                    groupMeetingType === "phone" && groupInboundCall ?
                                                                                                        <label htmlFor='phoneCallGroup' className='border p-2 bg-body w-100 rounded'>
                                                                                                            <span style={{ fontWeight: "500" }}>
                                                                                                                Choose Phone Number *
                                                                                                            </span>
                                                                                                            <PhoneInput
                                                                                                                required
                                                                                                                className="p-1 w-100 phoneNUmberInpurJHSGFUGDSFIY pointer-events-none"
                                                                                                                style={{ pointerEvents: "none" }}
                                                                                                                placeholder=""
                                                                                                                value={groupLocation}
                                                                                                                onChange={setGroupLocation} />

                                                                                                            {
                                                                                                                selectedPhoneNumberLabel ?
                                                                                                                    <Select
                                                                                                                        id='phoneCallGroup'
                                                                                                                        options={optionListForPhone}
                                                                                                                        defaultValue={selectedPhoneNumberLabel}
                                                                                                                        onChange={(e) => { setGroupLocation(e.label) }}
                                                                                                                    />
                                                                                                                    :
                                                                                                                    ""
                                                                                                            }



                                                                                                        </label>
                                                                                                        :
                                                                                                        groupMeetingType === "address" ?
                                                                                                            <label className='border bg-body rounded p-2 w-100'>
                                                                                                                <span style={{ fontWeight: "500" }}>
                                                                                                                    Enter Address Here *
                                                                                                                </span>
                                                                                                                <input
                                                                                                                    required
                                                                                                                    type="text"
                                                                                                                    className='customInputFieldFocus w-100 border px-2'
                                                                                                                    placeholder=""
                                                                                                                    defaultValue={myEvent.meetingType === "address" ? myEvent.location : ""}
                                                                                                                    onChange={(e) => setGroupLocation(e.target.value)}
                                                                                                                />
                                                                                                            </label>
                                                                                                            :
                                                                                                            groupMeetingType === "meet" && needToken ?
                                                                                                                <label>
                                                                                                                    <GoogleLogin
                                                                                                                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                                                                                                        buttonText='Sign in & Authorize Calendar'
                                                                                                                        onSuccess={responceGoogle}
                                                                                                                        onFailure={responseError}
                                                                                                                        cookiePolicy={"single_host_origin"}
                                                                                                                        fetchBasicProfile={true}
                                                                                                                        responseType="code"
                                                                                                                        accessType='offline'
                                                                                                                        scope='openid email profile https://www.googleapis.com/auth/calendar'


                                                                                                                    />
                                                                                                                </label>
                                                                                                                :
                                                                                                                groupMeetingType === "zoom" && needZoomToken ?
                                                                                                                    <label>
                                                                                                                        <Link to={"../calendar#Integrations"} className='color-salesforce'>
                                                                                                                            Click to Connect Zoom.
                                                                                                                        </Link>
                                                                                                                    </label> :
                                                                                                                    groupMeetingType === "webex" && needWebexToken ?
                                                                                                                        <label>
                                                                                                                            <Link to={"../calendar#Integrations"} className='color-salesforce'>
                                                                                                                                Click to Connect Webex.
                                                                                                                            </Link>
                                                                                                                        </label> :
                                                                                                                        groupMeetingType === "goto" && needGTMToken ?
                                                                                                                            <label>
                                                                                                                                <Link to={"../calendar#Integrations"} className='color-salesforce'>
                                                                                                                                    Click to Connect GoTo Meeting.
                                                                                                                                </Link>
                                                                                                                            </label> :
                                                                                                                            groupMeetingType === "teams" && needTeamsToken ?
                                                                                                                                <label>
                                                                                                                                    <Link to={"../calendar#Integrations"} className='color-salesforce'>
                                                                                                                                        Click to Connect Teams.
                                                                                                                                    </Link>
                                                                                                                                </label> :
                                                                                                                                ""
                                                                                                }
                                                                                            </div>
                                                                                            <div className='col-12 col-md-6 col-lg-6'>

                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="row my-3">
                                                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                                                <label htmlFor='description' className='border insightBackground rounded p-2 w-100'>
                                                                                                    <span style={{ fontWeight: "500" }}>
                                                                                                        Description *
                                                                                                    </span>
                                                                                                    <textarea
                                                                                                        id="description"
                                                                                                        className='customInputFieldFocus w-100 px-2'
                                                                                                        defaultValue={groupDescription}
                                                                                                        required
                                                                                                        placeholder="Write a summary and any details your invitee should know about the event."
                                                                                                        onChange={e => setGroupDescription(e.target.value)}
                                                                                                    />
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="col-12 col-md-6 col-lg-6">

                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="row my-3">
                                                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                                                <div className='border insightBackground rounded p-2 w-100'>
                                                                                                    <label htmlFor='maxInvities' className='w-100 d-flex flex-column gap-1'>
                                                                                                        <span style={{ fontWeight: "500" }} className="d-block">
                                                                                                            Max invitees in a spot *
                                                                                                        </span>
                                                                                                        <input
                                                                                                            type="number"
                                                                                                            id="maxInvities"
                                                                                                            className='customInputFieldFocus w-100 px-2'
                                                                                                            style={{ maxWidth: "100px" }}
                                                                                                            value={maxInvitees}
                                                                                                            min="2"
                                                                                                            max="500"
                                                                                                            required
                                                                                                            onChange={e => setMaxInvitees(e.target.value)}
                                                                                                        />
                                                                                                    </label>
                                                                                                    <label htmlFor='maxInvitiesEnable' className='d-flex align-items-center gap-1 mt-1'>
                                                                                                        {
                                                                                                            maxInvitiesActive && (
                                                                                                                <input id="maxInvitiesEnable" type="checkbox" defaultChecked onClick={() => { setMaxInvitiesActive(false); }} className='form-check-input checkboxInpage m-0 p-0' />
                                                                                                            )
                                                                                                        }
                                                                                                        {
                                                                                                            maxInvitiesActive === false && (
                                                                                                                <input id="maxInvitiesEnable" type="checkbox" onClick={() => { setMaxInvitiesActive(true); }} className='form-check-input checkboxInpage m-0 p-0' />
                                                                                                            )
                                                                                                        }
                                                                                                        <span className={`${maxInvitiesActive ? "" : "text-muted"} d-flex p-0 align-items-center h-100 mediumTextSize`}>
                                                                                                            Display remaining spots on booking page
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-md-6 col-lg-6">

                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="row my-3">
                                                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                                                <label htmlFor='description' className='border insightBackground w-100 p-2 rounded'>
                                                                                                    <span className='d-flex gap-1 align-items-center' style={{ fontWeight: "500" }}>
                                                                                                        Event Link *

                                                                                                        <OverlayTrigger
                                                                                                            placement="right"
                                                                                                            delay={{ show: 100, hide: 200 }}
                                                                                                            overlay={
                                                                                                                <Popover id="popover-basic">
                                                                                                                    <Popover.Header className='salesforce-style' as="h3">Unique event links</Popover.Header>
                                                                                                                    <Popover.Body>
                                                                                                                        <p className='p-0 m-0 helpTextSize'>
                                                                                                                            Use this field to change the unique url for this event.
                                                                                                                        </p>
                                                                                                                        <img src={uniqueLinkHelpText} className='img img-responsive w-100' alt='helpText' />
                                                                                                                    </Popover.Body>
                                                                                                                </Popover>
                                                                                                            }>
                                                                                                            <HelpOutlineIcon style={{ maxWidth: "15px", cursor: "help" }} />
                                                                                                        </OverlayTrigger>
                                                                                                    </span>
                                                                                                    <span className='mediumTextSize'>{process.env.REACT_APP_DOMAINNAME + `/${user.username.replaceAll(" ", "-")}/`}</span>

                                                                                                    <div className="input-group w-100 flex-nowrap rounded">
                                                                                                        <input type="text" maxLength={50} id="UID" required className='customInputFieldFocus w-100 px-2' placeholder='UID' onClick={(e) => { if (e.target.value.length === 0) { checkCustomLinkAvailable({ target: { value: groupSummary.replaceAll(" ", "-") } }); } }} autoComplete="off" value={customEventLink} onChange={checkCustomLinkAvailable} />
                                                                                                        <div className="input-group-append">
                                                                                                            <span className="input-group-text h-100 border-0" style={{ borderRadius: "0px 0.375rem 0.375rem 0px" }}>
                                                                                                                {
                                                                                                                    isCustomLinkAvailable ?
                                                                                                                        <i className='fa fa-check text-success'></i>
                                                                                                                        :
                                                                                                                        <i className='fa fa-times text-danger'></i>
                                                                                                                }
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>


                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="col-12 col-md-6 col-lg-6">

                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="row my-3">
                                                                                            <div className="col-12">
                                                                                                <label className='border p-2 insightBackground w-100 rounded d-flex flex-column'>
                                                                                                    <span style={{ fontWeight: "500" }} className="d-block">
                                                                                                        Event Color
                                                                                                    </span>
                                                                                                    <div className='event-color-holder pb-2'>
                                                                                                        <div onClick={(e) => { setGroupColorId('1'); checkThisTab(e.target); }} className={`event-color-tab event-color-1 ${groupColorId === "1" ? "checked-color" : ""}`}></div>
                                                                                                        <div onClick={(e) => { setGroupColorId('2'); checkThisTab(e.target); }} className={`event-color-tab event-color-2 ${groupColorId === "2" ? "checked-color" : ""}`}></div>
                                                                                                        <div onClick={(e) => { setGroupColorId('3'); checkThisTab(e.target); }} className={`event-color-tab event-color-3 ${groupColorId === "3" ? "checked-color" : ""}`}></div>
                                                                                                        <div onClick={(e) => { setGroupColorId('4'); checkThisTab(e.target); }} className={`event-color-tab event-color-4 ${groupColorId === "4" ? "checked-color" : ""}`}></div>
                                                                                                        <div onClick={(e) => { setGroupColorId('5'); checkThisTab(e.target); }} className={`event-color-tab event-color-5 ${groupColorId === "5" ? "checked-color" : ""}`}></div>
                                                                                                        <div onClick={(e) => { setGroupColorId('6'); checkThisTab(e.target); }} className={`event-color-tab event-color-6 ${groupColorId === "6" ? "checked-color" : ""}`}></div>
                                                                                                        <div onClick={(e) => { setGroupColorId('7'); checkThisTab(e.target); }} className={`event-color-tab event-color-7 ${groupColorId === "7" ? "checked-color" : ""}`}></div>
                                                                                                        <div onClick={(e) => { setGroupColorId('8'); checkThisTab(e.target); }} className={`event-color-tab event-color-8 ${groupColorId === "8" ? "checked-color" : ""}`}></div>
                                                                                                        <div onClick={(e) => { setGroupColorId('9'); checkThisTab(e.target); }} className={`event-color-tab event-color-9 ${groupColorId === "9" ? "checked-color" : ""}`}></div>
                                                                                                        <div onClick={(e) => { setGroupColorId('10'); checkThisTab(e.target); }} className={`event-color-tab event-color-10 ${groupColorId === "10" ? "checked-color" : ""}`}></div>
                                                                                                        <div onClick={(e) => { setGroupColorId('11'); checkThisTab(e.target); }} className={`event-color-tab event-color-11 ${groupColorId === "11" ? "checked-color" : ""}`}></div>
                                                                                                    </div>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="row my-3">
                                                                                            <div className="col d-flex align-items-center justify-content-center">
                                                                                                <div className='d-flex justify-content-center gap-2 w-100'>
                                                                                                    <button type='button' style={{ maxWidth: "120px" }} className='btn btn-sm w-100 text-center btn-secondary rounded-pill' onClick={() => { setWhatEvent(false); window.location.reload(); }}>Cancel</button>
                                                                                                    <button type="submit" style={{ maxWidth: "120px" }} className='btn btn-sm w-100 text-center salesforce-style rounded-pill d-flex align-items-center justify-content-center gap-2' id="groupEditButton1" disabled={!updateButtonEnabled || !isCustomLinkAvailable}>Update</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>
                                                                            <Accordion.Item eventKey="2">
                                                                                <Accordion.Header onClick={(e) => { setWhenEvent(!whenEvent); setWhatEvent(false); setCustomizedEvent(false); }}>
                                                                                    <div className="container-fluid p-0">
                                                                                        <div className="row sticky-top">
                                                                                            <div className="col p-0">
                                                                                                <div className='eventPreview'>
                                                                                                    <img src={caneldarDark} alt="Caledary" className="iconsForLocation" style={{ width: "2em", alignSelf: "center" }} />
                                                                                                    <div className='d-flex flex-column gap-1'>
                                                                                                        <div className='prewEventWhich text-dark'>When can people book this event?</div>
                                                                                                        <div className='eventNamePrew text-dark d-flex align-items-center gap-2 flex-wrap mediumTextSize'>
                                                                                                            <span id="eventNamePrew text-dark">
                                                                                                                {
                                                                                                                    groupDuration > 60 ?
                                                                                                                        Math.floor(groupDuration % 60) === 0 ?
                                                                                                                            Math.floor(groupDuration / 60) + " hrs"
                                                                                                                            : Math.floor(groupDuration / 60) + " hrs, " + Math.floor(groupDuration % 60) + " min"
                                                                                                                        : Math.floor(groupDuration) + " min"
                                                                                                                },
                                                                                                            </span>
                                                                                                            <span id="eventLocationPrew">{dayjs(betweenDays[0].startDate).format("D MMM YYYY")} - {betweenDays[0].endDate ? dayjs(betweenDays[0].endDate).format("D MMM YYYY") : "Continuous"}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Accordion.Header>
                                                                                <Accordion.Body className='bg-soul'>
                                                                                    <div className="container-fluid p-0">

                                                                                        <div className={`m-0 p-0 ${whenEvent === true ? "" : "d-none"}`}>

                                                                                            <div className="row my-3">
                                                                                                <div className="col">
                                                                                                    <div className='w-100 insightBackground rounded border p-2'>
                                                                                                        <h5>
                                                                                                            Date Range
                                                                                                        </h5>
                                                                                                        <p>Set a range of dates when you can accept meetings.</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="row mb-3">
                                                                                                <div className="col-12">
                                                                                                    <div className='rescheduler'>
                                                                                                        <label className='h6'>
                                                                                                            Invitees can schedule...
                                                                                                        </label>
                                                                                                        {/* <div className="w-100 overflow-auto">
                                                                                                                <DateRange
                                                                                                                    // editableDateInputs={true}
                                                                                                                    onChange={item => setBetweenDays([item.selection])}
                                                                                                                    // showSelectionPreview={true}
                                                                                                                    // moveRangeOnFirstSelection={false}
                                                                                                                    months={2}
                                                                                                                    minDate={new Date()}
                                                                                                                    ranges={betweenDays}
                                                                                                                    direction="horizontal"
                                                                                                                />
                                                                                                            </div> */}

                                                                                                        <div className='w-100 d-flex align-items-center gap-2 mb-4'>
                                                                                                            <label htmlFor="rollingDaysStraight" className={`d-flex align-items-center gap-2 ${straightRollingDays ? "" : "text-muted"}`}
                                                                                                                onClick={() => {
                                                                                                                    setStraightRollingDays(true);
                                                                                                                    setWithinDateRange(false);
                                                                                                                    setInfiniteDateRange(false);
                                                                                                                    setBetweenDays([
                                                                                                                        {
                                                                                                                            startDate: new Date(),
                                                                                                                            endDate: addDays(new Date(), parseInt(rollingDaysRef.current.value)),
                                                                                                                            key: 'selection'
                                                                                                                        }
                                                                                                                    ]);

                                                                                                                }}
                                                                                                            >
                                                                                                                {
                                                                                                                    straightRollingDays ?
                                                                                                                        <input type="radio" checked id='rollingDaysStraight' className='form-check-input p-0 m-0' name="rollingDays" />
                                                                                                                        :
                                                                                                                        <input type="radio" id='rollingDaysStraight' className='form-check-input p-0 m-0' name="rollingDays" />
                                                                                                                }

                                                                                                                <input
                                                                                                                    type="number"
                                                                                                                    disabled={!straightRollingDays}
                                                                                                                    defaultValue={30}
                                                                                                                    ref={rollingDaysRef}
                                                                                                                    onClick={
                                                                                                                        (e) => {
                                                                                                                            setBetweenDays([
                                                                                                                                {
                                                                                                                                    startDate: new Date(),
                                                                                                                                    endDate: addDays(new Date(), parseInt(e.target.value)),
                                                                                                                                    key: 'selection'
                                                                                                                                }
                                                                                                                            ]);
                                                                                                                        }}
                                                                                                                    onInput={
                                                                                                                        (e) => {
                                                                                                                            setBetweenDays([
                                                                                                                                {
                                                                                                                                    startDate: new Date(),
                                                                                                                                    endDate: addDays(new Date(), parseInt(e.target.value)),
                                                                                                                                    key: 'selection'
                                                                                                                                }
                                                                                                                            ]);

                                                                                                                            // console.log(addDays(new Date(), parseInt(e.target.value)))
                                                                                                                        }
                                                                                                                    } className='customInputFieldFocus rounded border' />
                                                                                                            </label>
                                                                                                            <div style={{ position: "relative", zIndex: "10" }}>
                                                                                                                <Select
                                                                                                                    isDisabled={!straightRollingDays}
                                                                                                                    className='mediumTextSize'
                                                                                                                    options={
                                                                                                                        [
                                                                                                                            { label: "calendar days", value: "calendardays" },
                                                                                                                            // { label: "weekdays", value: "weekdays" }
                                                                                                                        ]
                                                                                                                    }
                                                                                                                    defaultValue={[{ label: "calendar days", value: "calendardays" }]}
                                                                                                                    onChange={(e) => { }}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                into the future
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className='w-100 d-flex align-items-center gap-2 mb-4'>
                                                                                                            <label htmlFor="betweenForever" className={`d-flex align-items-center gap-2 ${withinDateRange ? "" : "text-muted"}`}
                                                                                                                onClick={(e) => {
                                                                                                                    setStraightRollingDays(false);
                                                                                                                    setWithinDateRange(true);
                                                                                                                    setInfiniteDateRange(false);
                                                                                                                }}
                                                                                                            >
                                                                                                                {
                                                                                                                    withinDateRange ?
                                                                                                                        <input type="radio" checked id='betweenForever' className='form-check-input p-0 m-0' name="rollingDays" />
                                                                                                                        :
                                                                                                                        <input type="radio" id='betweenForever' className='form-check-input p-0 m-0' name="rollingDays" />
                                                                                                                }
                                                                                                                <span className='mediumTextSize'>Within a date range</span>
                                                                                                            </label>
                                                                                                            {
                                                                                                                withinDateRange ?
                                                                                                                    <div className='flex-fill' style={{ position: "relative", zIndex: "9", maxWidth: "max-content" }} onClick={() => { setShowDateRangeSelector(true); setWithinDateRange(true); setStraightRollingDays(false); setInfiniteDateRange(false); }}>
                                                                                                                        <input type="text" disabled={!withinDateRange} value={`${dayjs(betweenDays[0].startDate).format("D MMM YYYY")} - ${betweenDays[0].endDate ? dayjs(betweenDays[0].endDate).format("D MMM YYYY") : "Continuous"}`} className=" w-100 rounded p-2 border mediumTextSize" />
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    ""
                                                                                                            }
                                                                                                        </div>

                                                                                                        <div className='w-100 mb-4'>
                                                                                                            <label htmlFor="foreversRoll" className={`d-flex align-items-center gap-2  ${infiniteDateRange ? "" : "text-muted"}`}
                                                                                                                onClick={(e) => {
                                                                                                                    setStraightRollingDays(false);
                                                                                                                    setWithinDateRange(false);
                                                                                                                    setInfiniteDateRange(true);

                                                                                                                    setBetweenDays([
                                                                                                                        {
                                                                                                                            startDate: new Date(),
                                                                                                                            endDate: null,
                                                                                                                            key: 'selection'
                                                                                                                        }
                                                                                                                    ]);
                                                                                                                }}

                                                                                                            >
                                                                                                                {
                                                                                                                    infiniteDateRange ?
                                                                                                                        <input type="radio" checked id='foreversRoll' className='form-check-input p-0 m-0' name="rollingDays" />
                                                                                                                        :
                                                                                                                        <input type="radio" id='foreversRoll' className='form-check-input p-0 m-0' name="rollingDays" />
                                                                                                                }
                                                                                                                <span className={`mediumTextSize`}>Indefinitely into the future</span>

                                                                                                            </label>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="row mb-3">
                                                                                                <div className="col-12">
                                                                                                    <label htmlFor='endDateTime' className='w-100 border rounded p-3 insightBackground' style={{ position: "relative", zIndex: "5" }}>
                                                                                                        <span style={{ fontWeight: "500" }}>Meeting Duration</span>
                                                                                                        <p className='m-0 mb-2 mediumTextSize'>Define how long your event will be. It can be as long as 12 hours.</p>
                                                                                                        {
                                                                                                            // <DropdownButton
                                                                                                            //     as="ButtonGroup"
                                                                                                            //     className='w-100'
                                                                                                            //     id={`dropdown-button-drop-down`}
                                                                                                            //     drop={"down"}
                                                                                                            //     variant="light"
                                                                                                            //     title={
                                                                                                            //         groupDuration > 60 ?
                                                                                                            //             Math.floor(groupDuration % 60) === 0 ?
                                                                                                            //                 Math.floor(groupDuration / 60) + " hrs"
                                                                                                            //                 : Math.floor(groupDuration / 60) + " hrs, " + Math.floor(groupDuration % 60) + " min"
                                                                                                            //             : Math.floor(groupDuration) + " min"
                                                                                                            //     }
                                                                                                            //     onSelect={(e, event) => { if (e !== "custom") { setGroupDuration(e); setCustomHourView(false); } else { setCustomHourView(true); setGroupDuration(15); setDurationType("minutes"); } }}

                                                                                                            // >
                                                                                                            //     <Dropdown.Item eventKey="15">15 minutes</Dropdown.Item>
                                                                                                            //     <Dropdown.Item eventKey="30">30 minutes</Dropdown.Item>
                                                                                                            //     <Dropdown.Item eventKey="45">45 minutes</Dropdown.Item>
                                                                                                            //     <Dropdown.Item eventKey="60">60 minutes</Dropdown.Item>
                                                                                                            //     <Dropdown.Divider />
                                                                                                            //     <Dropdown.Item eventKey="custom">Custom</Dropdown.Item>
                                                                                                            // </DropdownButton>


                                                                                                            selectedDurationLabel ?
                                                                                                                Object.keys(selectedDurationLabel).length > 0 ?
                                                                                                                    <Select
                                                                                                                        options={meetingDurationListForSelect}
                                                                                                                        defaultValue={selectedDurationLabel}
                                                                                                                        onChange={(e) => { if (e.value !== "custom") { setGroupDuration(e.value); setCustomHourView(false); } else { setCustomHourView(true); } }}
                                                                                                                    />
                                                                                                                    :
                                                                                                                    ""
                                                                                                                :
                                                                                                                ""

                                                                                                        }

                                                                                                        {
                                                                                                            customHourView ?
                                                                                                                (
                                                                                                                    <div className='d-flex justify-content-center align-items-center gap-2 my-2'>
                                                                                                                        {
                                                                                                                            durationType === "hours" ?
                                                                                                                                <input className='customInputFieldFocus w-100' ref={minutesGroup} value={Math.floor(parseInt(groupDuration) / 60) === 0 ? 1 : Math.floor(parseInt(groupDuration) / 60)} required type="number" min="1" max="12" onChange={(e) => { convertToMinutesGroup(e.target.value); }} />
                                                                                                                                :
                                                                                                                                <input className='customInputFieldFocus w-100' ref={minutesGroup} value={parseInt(groupDuration) === 0 ? 15 : groupDuration} required type="number" min="15" max="720" onChange={(e) => { convertToMinutesGroup(e.target.value); }} />
                                                                                                                        }
                                                                                                                        {/* <select className='' onChange={(e) => { setDurationType(e.target.value); }} value={durationType}>
                                                                                                                        <option value="minutes">minutes</option>
                                                                                                                        <option value="hours">hours</option>
                                                                                                                    </select> */}
                                                                                                                        {/* <DropdownButton
                                                                                                                        as="ButtonGroup"
                                                                                                                        className='w-100'
                                                                                                                        id={`dropdown-button-drop-down`}
                                                                                                                        drop={"down"}
                                                                                                                        variant="light"
                                                                                                                        title={durationType}

                                                                                                                        onSelect={(e, event) => { setDurationType(e) }}
                                                                                                                    >
                                                                                                                        <Dropdown.Item eventKey="minutes">minutes</Dropdown.Item>
                                                                                                                        <Dropdown.Item eventKey="hours">hours</Dropdown.Item>
                                                                                                                    </DropdownButton> */}
                                                                                                                        <Select
                                                                                                                            className='w-50'
                                                                                                                            options={[{ label: "minutes", value: "minutes" }, { label: "hours", value: "hours" }]}
                                                                                                                            defaultValue={[durationType === "hours" ? { label: "hours", value: "hours" } : { label: "minutes", value: "minutes" }]}
                                                                                                                            onChange={(e) => { setDurationType(e.value); }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                )
                                                                                                                :
                                                                                                                ""
                                                                                                        }
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="col-12 col-lg-6 col-md-6"></div>
                                                                                            </div>

                                                                                            <div className='row mb-3'>
                                                                                                <div className="col">
                                                                                                    <div className='w-100 border rounded p-3 insightBackground'>
                                                                                                        <span style={{ fontWeight: "500" }}>How do you want to offer your availability for this event type?</span>
                                                                                                        <p>Select one of your schedules or define custom hours specific to this type of event.</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="row mb-3">
                                                                                                <div className="col">
                                                                                                    <div className='w-100'>
                                                                                                        <button type='button' className={`btn ${existingScheduleView ? "salesforce-style" : "bg-body border"}`} onClick={() => { setExistingScheduleView(true); setCustomScheduleView(false); }}>Use an existing schedule</button>
                                                                                                        <button type='button' className={`btn mx-3 ${customScheduleView ? "salesforce-style" : "bg-body border"}`} onClick={() => { setExistingScheduleView(false); setCustomScheduleView(true); }}>Set custom hours</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            {/* Existing schedule */}

                                                                                            <div className={`row mb-3 ${existingScheduleView ? "" : "d-none"}`}>
                                                                                                <div className="col">

                                                                                                    <div className="rounded text-dark border">
                                                                                                        <div className="border-bottom p-3">
                                                                                                            <span className='mb-1 w-100 d-block' style={{ fontWeight: "500" }}>Which schedule type do you want to use for this event type?</span>
                                                                                                            {/* <select className=' my-2' value={scheduleName} onChange={(e) => { toogleScheduleSet(e.target.value); setScheduleName(e.target.value); }}>
                                                                                                {
                                                                                                    userSchedules ?
                                                                                                        Object.keys(userSchedules).map((name, key) => {
                                                                                                            return <option key={key} value={name}>{name}</option>
                                                                                                        })
                                                                                                        :
                                                                                                        <option value="working hours">Working Hours</option>
                                                                                                }
                                                                                            </select> */}

                                                                                                            {/* <span className='border bg-body rounded p-2 pb-3 mb-3 d-block'> */}
                                                                                                            {/* <DropdownButton
                                                                                                                as="ButtonGroup"
                                                                                                                className='w-100'
                                                                                                                drop={"down"}
                                                                                                                variant="light"
                                                                                                                title={scheduleName ? scheduleName : ""}
                                                                                                                onSelect={(e, event) => { toogleScheduleSet(e); setScheduleName(e); }}

                                                                                                            >
                                                                                                                {
                                                                                                                    userSchedules ?
                                                                                                                        Object.keys(userSchedules).map((name, key) => {
                                                                                                                            return <Dropdown.Item eventKey={name}>{name}</Dropdown.Item>
                                                                                                                        })
                                                                                                                        :
                                                                                                                        <Dropdown.Item eventKey="working hours">Working Hours</Dropdown.Item>
                                                                                                                }
                                                                                                            </DropdownButton> */}
                                                                                                            {
                                                                                                                scheduleName && (
                                                                                                                    <Select
                                                                                                                        options={userScheduleListForSelect}
                                                                                                                        defaultValue={[{ label: scheduleName, value: scheduleName }]}
                                                                                                                        onChange={(e) => { toogleScheduleSet(e.label); setScheduleName(e.label); }}
                                                                                                                    />
                                                                                                                )
                                                                                                            }
                                                                                                            {/* </span> */}

                                                                                                            <span style={{ fontWeight: "500" }} className="d-block mb-1 mt-3">
                                                                                                                Time Zone
                                                                                                            </span>
                                                                                                            <Select className='mb-3 p-0 w-100' value={[selectedTimezone]} options={filteredTimezonesListSchedule} isDisabled={true} onChange={(e) => { setSelectedTimezone(e); }} />
                                                                                                            <div className="d-flex align-items-center gap-2 my-3">
                                                                                                                <i className='fa fa-globe fs-5'></i>
                                                                                                                <span style={{ fontWeight: "600" }}>
                                                                                                                    {selectedTimezone.value ? selectedTimezone.value.name : ""}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <p className='mediumTextSize'>Automatically detected timezone is {selectedTimezone.value ? selectedTimezone.value.name : ""}. You may change it to specify default time-zone for your calendar.</p>
                                                                                                        </div>
                                                                                                        <div className="p-3">
                                                                                                            <div className="container-fluid">
                                                                                                                <div className="row">
                                                                                                                    <div className="col-12 col-md-12 col-lg-7 border rounded shadow-sm insightBackground">
                                                                                                                        <div className="container-fluid h-100">
                                                                                                                            <div className="row">
                                                                                                                                <div className="col-4 col-md-2 col-lg-2 h6 p-2">SUN</div>
                                                                                                                                <div className="col-8 col-md-10 col-lg-10 p-2">
                                                                                                                                    {
                                                                                                                                        isSundayActive ?
                                                                                                                                            schedule.sunday.times.map((time, key) => {
                                                                                                                                                return <p className='w-100 mb-2 text-start' key={key}>
                                                                                                                                                    {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                                </p>
                                                                                                                                            })
                                                                                                                                            :
                                                                                                                                            <span className='text-muted'>unavailable</span>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="row">
                                                                                                                                <div className=" col-4 col-md-2 col-lg-2 h6 p-2">MON</div>
                                                                                                                                <div className="col-8 col-md-10 col-lg-10 p-2">
                                                                                                                                    {
                                                                                                                                        isMondayActive ?
                                                                                                                                            schedule.monday.times.map((time, key) => {
                                                                                                                                                return <p className='w-100 mb-2 text-start' key={key}>
                                                                                                                                                    {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                                </p>
                                                                                                                                            })
                                                                                                                                            :
                                                                                                                                            <span className='text-muted'>unavailable</span>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="row">
                                                                                                                                <div className=" col-4 col-md-2 col-lg-2 h6 p-2">TUE</div>
                                                                                                                                <div className="col-8 col-md-10 col-lg-10 p-2">
                                                                                                                                    {
                                                                                                                                        isTuesdayActive ?
                                                                                                                                            schedule.tuesday.times.map((time, key) => {
                                                                                                                                                return <p className='w-100 mb-2 text-start' key={key}>
                                                                                                                                                    {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                                </p>
                                                                                                                                            })
                                                                                                                                            :
                                                                                                                                            <span className='text-muted'>unavailable</span>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="row">
                                                                                                                                <div className=" col-4 col-md-2 col-lg-2 h6 p-2">WED</div>
                                                                                                                                <div className="col-8 col-md-10 col-lg-10 p-2">
                                                                                                                                    {
                                                                                                                                        isWednesdayActive ?
                                                                                                                                            schedule.wednesday.times.map((time, key) => {
                                                                                                                                                return <p className='w-100 mb-2 text-start' key={key}>
                                                                                                                                                    {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                                </p>
                                                                                                                                            })
                                                                                                                                            :
                                                                                                                                            <span className='text-muted'>unavailable</span>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="row">
                                                                                                                                <div className=" col-4 col-md-2 col-lg-2 h6 p-2">THU</div>
                                                                                                                                <div className="col-8 col-md-10 col-lg-10 p-2">
                                                                                                                                    {
                                                                                                                                        isThursdayActive ?
                                                                                                                                            schedule.thursday.times.map((time, key) => {
                                                                                                                                                return <p className='w-100 mb-2 text-start' key={key}>
                                                                                                                                                    {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                                </p>
                                                                                                                                            })
                                                                                                                                            :
                                                                                                                                            <span className='text-muted'>unavailable</span>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="row">
                                                                                                                                <div className=" col-4 col-md-2 col-lg-2 h6 p-2">FRI</div>
                                                                                                                                <div className="col-8 col-md-10 col-lg-10 p-2">
                                                                                                                                    {
                                                                                                                                        isFridayActive ?
                                                                                                                                            schedule.friday.times.map((time, key) => {
                                                                                                                                                return <p className='w-100 mb-2 text-start' key={key}>
                                                                                                                                                    {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                                </p>
                                                                                                                                            })
                                                                                                                                            :
                                                                                                                                            <span className='text-muted'>unavailable</span>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="row">
                                                                                                                                <div className=" col-4 col-md-2 col-lg-2 h6 p-2">SAT</div>
                                                                                                                                <div className="col-8 col-md-10 col-lg-10 p-2">
                                                                                                                                    {
                                                                                                                                        isSaturdayActive ?
                                                                                                                                            schedule.saturday.times.map((time, key) => {
                                                                                                                                                return <p className='w-100 mb-2 text-start' key={key}>
                                                                                                                                                    {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                                </p>
                                                                                                                                            })
                                                                                                                                            :
                                                                                                                                            <span className='text-muted'>unavailable</span>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="col-12 col-lg-1 py-2"></div>
                                                                                                                    <div className="col-12 col-md-12 col-lg-4 border rounded shadow-sm insightBackground">
                                                                                                                        <h5 className='py-3 text-center'>Date Overrides</h5>
                                                                                                                        {
                                                                                                                            dateOverrides ?
                                                                                                                                Object.keys(dateOverrides).length > 0 ?
                                                                                                                                    Object.keys(dateOverrides).map((data, key) => {
                                                                                                                                        return (
                                                                                                                                            <div key={key} className="card mb-2" id={`datesOverrides_${dateOverrides[data].uid}`}>
                                                                                                                                                <div className='card-header salesforce-style d-flex align-items-center justify-content-between'>
                                                                                                                                                    <span>{dayjs(dateOverrides[data].date).format("ddd, D MMM YYYY")}</span>
                                                                                                                                                    {/* <span><i className='fa fa-trash' id={dateOverrides[data].uid} onClick={(e) => { deleteOverrideDate(dateOverrides[data].date, `datesOverrides_${dateOverrides[data].uid}`); }}></i></span> */}
                                                                                                                                                </div>
                                                                                                                                                <div className='card-body d-flex justify-content-between align-items-center'>
                                                                                                                                                    {
                                                                                                                                                        dateOverrides[data].active ?
                                                                                                                                                            <>
                                                                                                                                                                <div className='d-flex flex-column align-items-start justify-content-center'>
                                                                                                                                                                    {
                                                                                                                                                                        dateOverrides[data].timeSet.map((time, key) => {
                                                                                                                                                                            return (
                                                                                                                                                                                <React.Fragment key={key}>
                                                                                                                                                                                    <button disabled className='btn btn-secondary border-0 mb-1 w-100'>
                                                                                                                                                                                        {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                                                                    </button>
                                                                                                                                                                                </React.Fragment>
                                                                                                                                                                            )
                                                                                                                                                                        })
                                                                                                                                                                    }
                                                                                                                                                                </div>
                                                                                                                                                            </>
                                                                                                                                                            :
                                                                                                                                                            <>
                                                                                                                                                                <span className='text-muted'>unavailable</span>
                                                                                                                                                            </>
                                                                                                                                                    }
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        )
                                                                                                                                    })
                                                                                                                                    :
                                                                                                                                    <p className='text-center'>No overrides for now</p>
                                                                                                                                :
                                                                                                                                <p className='text-center'>No overrides for now</p>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className={`row mb-3 ${customScheduleView ? "" : "d-none"}`} >
                                                                                                <div className="col-12">

                                                                                                    {/* ######################## NEW SCHEDULE SYSTEM GROUP ######################### */}

                                                                                                    <div className="">
                                                                                                        <div className="mb-3">
                                                                                                            <p className='smallTextNote p-3 m-0'>Choose a schedule below to edit or create a new one that you can apply to your event types</p>
                                                                                                            <div className="conatiner-fluid border p-3 rounded insightBackground">
                                                                                                                <div className="row mb-3">
                                                                                                                    <div className="col">
                                                                                                                        <span style={{ fontWeight: "600" }}>SCHEDULE TEMPLATES</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="row">
                                                                                                                    <div className="col">
                                                                                                                        <div className='d-flex flex-wrap align-items-center gap-2'>
                                                                                                                            {
                                                                                                                                userSchedules ?
                                                                                                                                    Object.keys(userSchedules).map((name, key) => {
                                                                                                                                        if (scheduleName === name) {
                                                                                                                                            return <button key={key} type="button" className='btn salesforce-style rounded-pill border shadow-sm' onClick={() => { toogleScheduleSet(name); setScheduleName(name); }}>{name}</button>
                                                                                                                                        } else {
                                                                                                                                            return <button key={key} type="button" className='btn btn-outline-dark rounded-pill border shadow-sm' onClick={() => { toogleScheduleSet(name); setScheduleName(name); }}>{name}</button>
                                                                                                                                        }
                                                                                                                                    })
                                                                                                                                    :
                                                                                                                                    ""
                                                                                                                            }
                                                                                                                            <button type='button' className='btn btn-outline-dark rounded-pill border' onClick={() => { setCreateNewSchedule(true); setDateOverrides({}); }}>+ New Schedule</button>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="rounded text-dark mt-3 border">
                                                                                                                <div className="border-bottom p-3">
                                                                                                                    <div className="container-fluid">
                                                                                                                        <div className="row mb-3">
                                                                                                                            <div className="col p-0">
                                                                                                                                <div className='w-100 d-flex justify-content-between align-items-center'>
                                                                                                                                    <span className='text-capitalize' style={{ fontWeight: "600" }}>{scheduleName ? scheduleName : "Working Hours"}</span>
                                                                                                                                    <OverlayTrigger
                                                                                                                                        placement="left"
                                                                                                                                        delay={{ show: 100, hide: 200 }}
                                                                                                                                        overlay={
                                                                                                                                            <Popover id="popover-basic">
                                                                                                                                                <Popover.Header className='salesforce-style' as="h3">Delete Schedule</Popover.Header>
                                                                                                                                                <Popover.Body>
                                                                                                                                                    <p className='p-0 m-0 helpTextSize'>By clicking this you can delete this schedule template.</p>
                                                                                                                                                </Popover.Body>
                                                                                                                                            </Popover>
                                                                                                                                        }>
                                                                                                                                        <i
                                                                                                                                            className='fa fa-trash cursor-pointer'
                                                                                                                                            onClick={() => {
                                                                                                                                                setCustomAlertBox(true);
                                                                                                                                                setAlertBoxMessages(<p>Do you want to delete this schedule <br />"{scheduleName}"?</p>);
                                                                                                                                                setAlertErrorType("queryDelSchedule");

                                                                                                                                            }}
                                                                                                                                        ></i>
                                                                                                                                    </OverlayTrigger>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="row mb-3">
                                                                                                                            <div className="col-12 col-lg-6 p-0">
                                                                                                                                <div className='d-flex flex-column align-items-start gap-3 h-100 w-100'>

                                                                                                                                    <div className='d-flex align-items-start flex-column justify-content-between gap-1 h-100 w-100'>
                                                                                                                                        <label htmlFor='timezoneGds'>Country *</label>
                                                                                                                                        <div className='w-100'>
                                                                                                                                            <Select options={countriesList} value={countrySchedule} onChange={changeHandlerSchedule} />
                                                                                                                                        </div>
                                                                                                                                    </div>

                                                                                                                                    <div className='d-flex align-items-start flex-column justify-content-between gap-1 h-100 w-100'>
                                                                                                                                        <label htmlFor='timezoneGds'>Time Zone</label>
                                                                                                                                        <div className='w-100'>
                                                                                                                                            <Select value={[selectedTimezone]} options={filteredTimezonesListSchedule} onChange={(e) => { setSelectedTimezone(e); }} />
                                                                                                                                        </div>
                                                                                                                                    </div>

                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="col-12 col-lg-6">

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="p-3">
                                                                                                                    <div className="container-fluid">
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-12 col-md-12 col-lg-7 border rounded shadow-sm insightBackground">
                                                                                                                                <p className='text-center pt-4' style={{ fontWeight: "600" }}>Set your weekly hours</p>
                                                                                                                                <div className="container-fluid p-0">
                                                                                                                                    <div className="row mb-4">
                                                                                                                                        <div className="col-3">
                                                                                                                                            <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                                                                                                <label className='d-flex gap-1 align-items-center justify-content-start'>
                                                                                                                                                    <div className="outerToggleSwitch" onClick={() => { setIsSundayActive(!isSundayActive); checkDayOnToggle(!isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, "OneToOne"); }}>
                                                                                                                                                        <div className={`frameOfToggleSwitch ${isSundayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                                                                            <span>On</span>
                                                                                                                                                            <div className="toggleSwitchBall"></div>
                                                                                                                                                            <span>Off</span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <span className='smallTextNote'>SUN</span>
                                                                                                                                                </label>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-7">
                                                                                                                                            {
                                                                                                                                                isSundayActive ?
                                                                                                                                                    <div className='m-0 p-0' id="sundayFrameGroup">
                                                                                                                                                        {
                                                                                                                                                            schedule.sunday.times.length > 0 ?
                                                                                                                                                                schedule.sunday.times.map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"sundayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="sundayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote sundayStartTabsGroup' />
                                                                                                                                                                                <datalist id="sundayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="sundayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote sundayEndTabsGroup' />
                                                                                                                                                                                <datalist id="sundayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("sunday", "Group", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                                :
                                                                                                                                                                ["9:00am-5:00pm"].map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"sundayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="sundayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote sundayStartTabsGroup' />
                                                                                                                                                                                <datalist id="sundayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="sundayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote sundayEndTabsGroup' />
                                                                                                                                                                                <datalist id="sundayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("sunday", "Group", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                        }

                                                                                                                                                    </div>
                                                                                                                                                    :
                                                                                                                                                    <span className='smallTextNote'>Unavailable</span>
                                                                                                                                            }
                                                                                                                                        </div>
                                                                                                                                        <div className="col-2">
                                                                                                                                            <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                                                                                                <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("sunday", "Group", uuidv4()); }}></i>
                                                                                                                                                <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("sunday", "Group"); }} />
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="row mb-4">
                                                                                                                                        <div className="col-3">
                                                                                                                                            <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                                                                                                <label className='d-flex gap-1 align-items-center justify-content-start'>
                                                                                                                                                    <div className="outerToggleSwitch" onClick={() => { setIsMondayActive(!isMondayActive); checkDayOnToggle(isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, "Group"); }}>
                                                                                                                                                        <div className={`frameOfToggleSwitch ${isMondayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                                                                            <span>On</span>
                                                                                                                                                            <div className="toggleSwitchBall"></div>
                                                                                                                                                            <span>Off</span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <span className='smallTextNote'>MON</span>
                                                                                                                                                </label>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-7">

                                                                                                                                            {
                                                                                                                                                isMondayActive ?
                                                                                                                                                    <div className='m-0 p-0' id="mondayFrameGroup">
                                                                                                                                                        {
                                                                                                                                                            schedule.monday.times.length > 0 ?
                                                                                                                                                                schedule.monday.times.map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"mondayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="mondayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote mondayStartTabsGroup' />
                                                                                                                                                                                <datalist id="mondayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="mondayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote mondayEndTabsGroup' />
                                                                                                                                                                                <datalist id="mondayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("monday", "Group", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                                :
                                                                                                                                                                ["9:00am-5:00pm"].map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"mondayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="mondayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote mondayStartTabsGroup' />
                                                                                                                                                                                <datalist id="mondayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="mondayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote mondayEndTabsGroup' />
                                                                                                                                                                                <datalist id="mondayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("monday", "Group", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                    :
                                                                                                                                                    <span className='smallTextNote'>Unavailable</span>
                                                                                                                                            }

                                                                                                                                        </div>
                                                                                                                                        <div className="col-2">
                                                                                                                                            <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                                                                                                <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("monday", "Group", uuidv4()); }}></i>
                                                                                                                                                <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("monday", "Group"); }} />
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="row mb-4">
                                                                                                                                        <div className="col-3">
                                                                                                                                            <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                                                                                                <label className='d-flex gap-1 align-items-center justify-content-start'>
                                                                                                                                                    <div className="outerToggleSwitch" onClick={() => { setIsTuesdayActive(!isTuesdayActive); checkDayOnToggle(isSundayActive, isMondayActive, !isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, "Group"); }}>
                                                                                                                                                        <div className={`frameOfToggleSwitch ${isTuesdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                                                                            <span>On</span>
                                                                                                                                                            <div className="toggleSwitchBall"></div>
                                                                                                                                                            <span>Off</span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <span className='smallTextNote'>TUE</span>
                                                                                                                                                </label>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-7">
                                                                                                                                            {
                                                                                                                                                isTuesdayActive ?
                                                                                                                                                    <div className='m-0 p-0' id="tuesdayFrameGroup">
                                                                                                                                                        {
                                                                                                                                                            schedule.tuesday.times.length > 0 ?
                                                                                                                                                                schedule.tuesday.times.map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"tuesdayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="tuesdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote tuesdayStartTabsGroup' />
                                                                                                                                                                                <datalist id="tuesdayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="tuesdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote tuesdayEndTabsGroup' />
                                                                                                                                                                                <datalist id="tuesdayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("tuesday", "Group", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                                :
                                                                                                                                                                ["9:00am-5:00pm"].map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"tuesdayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="tuesdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote tuesdayStartTabsGroup' />
                                                                                                                                                                                <datalist id="tuesdayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="tuesdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote tuesdayEndTabsGroup' />
                                                                                                                                                                                <datalist id="tuesdayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("tuesday", "Group", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                    :
                                                                                                                                                    <span className='smallTextNote'>Unavailable</span>
                                                                                                                                            }

                                                                                                                                        </div>
                                                                                                                                        <div className="col-2">
                                                                                                                                            <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                                                                                                <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("tuesday", "Group", uuidv4()); }}></i>
                                                                                                                                                <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("tuesday", "Group"); }} />
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="row mb-4">
                                                                                                                                        <div className="col-3">
                                                                                                                                            <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                                                                                                <label className='d-flex gap-1 align-items-center justify-content-start'>
                                                                                                                                                    <div className="outerToggleSwitch" onClick={() => { setIsWednesdayActive(!isWednesdayActive); checkDayOnToggle(isSundayActive, isMondayActive, isTuesdayActive, !isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, "Group"); }}>
                                                                                                                                                        <div className={`frameOfToggleSwitch ${isWednesdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                                                                            <span>On</span>
                                                                                                                                                            <div className="toggleSwitchBall"></div>
                                                                                                                                                            <span>Off</span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <span className='smallTextNote'>WED</span>
                                                                                                                                                </label>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-7">
                                                                                                                                            {
                                                                                                                                                isWednesdayActive ?
                                                                                                                                                    <div className='m-0 p-0' id="wednesdayFrameGroup">
                                                                                                                                                        {
                                                                                                                                                            schedule.wednesday.times.length > 0 ?
                                                                                                                                                                schedule.wednesday.times.map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"wednesdayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="wednesdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote wednesdayStartTabsGroup' />
                                                                                                                                                                                <datalist id="wednesdayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="wednesdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote wednesdayEndTabsGroup' />
                                                                                                                                                                                <datalist id="wednesdayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("wednesday", "Group", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                                :
                                                                                                                                                                ["9:00am-5:00pm"].map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"wednesdayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="wednesdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote wednesdayStartTabsGroup' />
                                                                                                                                                                                <datalist id="wednesdayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="wednesdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote wednesdayEndTabsGroup' />
                                                                                                                                                                                <datalist id="wednesdayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("wednesday", "Group", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                    :
                                                                                                                                                    <span className='smallTextNote'>Unavailable</span>
                                                                                                                                            }

                                                                                                                                        </div>
                                                                                                                                        <div className="col-2">
                                                                                                                                            <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                                                                                                <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("wednesday", "Group", uuidv4()); }}></i>
                                                                                                                                                <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("wednesday", "Group"); }} />
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="row mb-4">
                                                                                                                                        <div className="col-3">
                                                                                                                                            <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                                                                                                <label className='d-flex gap-1 align-items-center justify-content-start'>
                                                                                                                                                    <div className="outerToggleSwitch" onClick={() => { setIsThursdayActive(!isThursdayActive); checkDayOnToggle(isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, !isThursdayActive, isFridayActive, isSaturdayActive, "Group"); }}>
                                                                                                                                                        <div className={`frameOfToggleSwitch ${isThursdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                                                                            <span>On</span>
                                                                                                                                                            <div className="toggleSwitchBall"></div>
                                                                                                                                                            <span>Off</span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <span className='smallTextNote'>THU</span>
                                                                                                                                                </label>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-7">
                                                                                                                                            {
                                                                                                                                                isThursdayActive ?
                                                                                                                                                    <div className='m-0 p-0' id="thursdayFrameGroup">
                                                                                                                                                        {
                                                                                                                                                            schedule.thursday.times.length > 0 ?
                                                                                                                                                                schedule.thursday.times.map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"thursdayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="thursdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote thursdayStartTabsGroup' />
                                                                                                                                                                                <datalist id="thursdayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="thursdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote thursdayEndTabsGroup' />
                                                                                                                                                                                <datalist id="thursdayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("thursday", "Group", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                                :
                                                                                                                                                                ["9:00am-5:00pm"].map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"thursdayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="thursdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote thursdayStartTabsGroup' />
                                                                                                                                                                                <datalist id="thursdayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="thursdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote thursdayEndTabsGroup' />
                                                                                                                                                                                <datalist id="thursdayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("thursday", "Group", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                    :
                                                                                                                                                    <span className='smallTextNote'>Unavailable</span>
                                                                                                                                            }

                                                                                                                                        </div>
                                                                                                                                        <div className="col-2">
                                                                                                                                            <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                                                                                                <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("thursday", "Group", uuidv4()); }}></i>
                                                                                                                                                <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("thursday", "Group"); }} />
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="row mb-4">
                                                                                                                                        <div className="col-3">
                                                                                                                                            <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                                                                                                <label className='d-flex gap-1 align-items-center justify-content-start'>
                                                                                                                                                    <div className="outerToggleSwitch" onClick={() => { setIsFridayActive(!isFridayActive); checkDayOnToggle(isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, !isFridayActive, isSaturdayActive, "Group"); }}>
                                                                                                                                                        <div className={`frameOfToggleSwitch ${isFridayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                                                                            <span>On</span>
                                                                                                                                                            <div className="toggleSwitchBall"></div>
                                                                                                                                                            <span>Off</span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <span className='smallTextNote'>FRI</span>
                                                                                                                                                </label>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-7">
                                                                                                                                            {
                                                                                                                                                isFridayActive ?
                                                                                                                                                    <div className='m-0 p-0' id="fridayFrameGroup">
                                                                                                                                                        {
                                                                                                                                                            schedule.friday.times.length > 0 ?
                                                                                                                                                                schedule.friday.times.map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"fridayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="fridayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote fridayStartTabsGroup' />
                                                                                                                                                                                <datalist id="fridayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="fridayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote fridayEndTabsGroup' />
                                                                                                                                                                                <datalist id="fridayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("friday", "Group", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                                :
                                                                                                                                                                ["9:00am-5:00pm"].map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"fridayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="fridayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote fridayStartTabsGroup' />
                                                                                                                                                                                <datalist id="fridayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="fridayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote fridayEndTabsGroup' />
                                                                                                                                                                                <datalist id="fridayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("friday", "Group", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                    :
                                                                                                                                                    <span className='smallTextNote'>Unavailable</span>
                                                                                                                                            }

                                                                                                                                        </div>
                                                                                                                                        <div className="col-2">
                                                                                                                                            <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                                                                                                <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("friday", "Group", uuidv4()); }}></i>
                                                                                                                                                <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("friday", "Group"); }} />
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="row mb-4">
                                                                                                                                        <div className="col-3">
                                                                                                                                            <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                                                                                                <label className='d-flex gap-1 align-items-center justify-content-start'>
                                                                                                                                                    <div className="outerToggleSwitch" onClick={() => { setIsSaturdayActive(!isSaturdayActive); checkDayOnToggle(isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, !isSaturdayActive, "Group"); }}>
                                                                                                                                                        <div className={`frameOfToggleSwitch ${isSaturdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                                                                            <span>On</span>
                                                                                                                                                            <div className="toggleSwitchBall"></div>
                                                                                                                                                            <span>Off</span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <span className='smallTextNote'>SAT</span>
                                                                                                                                                </label>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-7">
                                                                                                                                            {
                                                                                                                                                isSaturdayActive ?
                                                                                                                                                    <div className='m-0 p-0' id="saturdayFrameGroup">
                                                                                                                                                        {
                                                                                                                                                            schedule.saturday.times.length > 0 ?
                                                                                                                                                                schedule.saturday.times.map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"saturdayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="saturdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote saturdayStartTabsGroup' />
                                                                                                                                                                                <datalist id="saturdayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="saturdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote saturdayEndTabsGroup' />
                                                                                                                                                                                <datalist id="saturdayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("saturday", "Group", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                                :
                                                                                                                                                                ["9:00am-5:00pm"].map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"saturdayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="saturdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote saturdayStartTabsGroup' />
                                                                                                                                                                                <datalist id="saturdayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="saturdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "Group"); checkUpdatedStateOfTimeValidation("Group"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote saturdayEndTabsGroup' />
                                                                                                                                                                                <datalist id="saturdayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("saturday", "Group", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                    :
                                                                                                                                                    <span className='smallTextNote'>Unavailable</span>
                                                                                                                                            }

                                                                                                                                        </div>
                                                                                                                                        <div className="col-2">
                                                                                                                                            <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                                                                                                <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("saturday", "Group", uuidv4()); }}></i>
                                                                                                                                                <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("saturday", "Group"); }} />
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="col-12 col-lg-1 d-lg-flex py-2"></div>
                                                                                                                            <div className="col-12 col-md-12 col-lg-4 border rounded shadow-sm insightBackground">
                                                                                                                                <div className="alert d-flex flex-column align-items-center">
                                                                                                                                    <p className='text-center' style={{ fontWeight: "600" }}>Date overrides</p>
                                                                                                                                    <button type='button' className='btn btn-sm rounded-pill salesforce-style px-3 d-flex align-items-center' onClick={() => { setViewDateOverridePanel(true); }}>
                                                                                                                                        <AddIcon /> Add date override
                                                                                                                                    </button>
                                                                                                                                </div>
                                                                                                                                {
                                                                                                                                    dateOverrides ?
                                                                                                                                        Object.keys(dateOverrides).length > 0 ?
                                                                                                                                            Object.keys(dateOverrides).map((data, key) => {
                                                                                                                                                return (
                                                                                                                                                    <div key={key} className="card mb-2" id={`datesOverrides_${dateOverrides[data].uid}_Group`}>
                                                                                                                                                        <div className='card-header salesforce-style d-flex align-items-center justify-content-between'>
                                                                                                                                                            <span>{dayjs(dateOverrides[data].date).format("ddd, D MMM YYYY")}</span>
                                                                                                                                                            <span><i className='fa fa-trash' id={dateOverrides[data].uid} onClick={(e) => { deleteOverrideDate(dateOverrides[data].date, `datesOverrides_${dateOverrides[data].uid}_Group`); }}></i></span>
                                                                                                                                                        </div>
                                                                                                                                                        <div className='card-body d-flex justify-content-between align-items-center'>
                                                                                                                                                            {
                                                                                                                                                                dateOverrides[data].active ?
                                                                                                                                                                    <>
                                                                                                                                                                        <div className='d-flex flex-column align-items-start justify-content-center'>
                                                                                                                                                                            {
                                                                                                                                                                                dateOverrides[data].timeSet.map((time, key) => {
                                                                                                                                                                                    const set = time.split("-");
                                                                                                                                                                                    return (
                                                                                                                                                                                        <React.Fragment key={key}>
                                                                                                                                                                                            <button disabled className='btn btn-secondary border-0 mb-1 w-100'>
                                                                                                                                                                                                {
                                                                                                                                                                                                    user.settings.timeFormat.value === "12 Hours" ?
                                                                                                                                                                                                        `${dayjs(`2 November 1999 ${set[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")}-${dayjs(`2 November 1999 ${set[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")}`
                                                                                                                                                                                                        :
                                                                                                                                                                                                        `${dayjs(`2 November 1999 ${set[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm")}-${dayjs(`2 November 1999 ${set[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm")}`
                                                                                                                                                                                                }
                                                                                                                                                                                            </button>
                                                                                                                                                                                        </React.Fragment>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </div>
                                                                                                                                                                    </>
                                                                                                                                                                    :
                                                                                                                                                                    <>
                                                                                                                                                                        <span className='smallTextNote'>Unavailable</span>
                                                                                                                                                                    </>
                                                                                                                                                            }
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                )
                                                                                                                                            })
                                                                                                                                            :
                                                                                                                                            <p className='text-center'>No overrides for now</p>
                                                                                                                                        :
                                                                                                                                        <p className='text-center'>No overrides for now</p>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                        <div className="card-footer d-flex align-items-center justify-content-center gap-3">
                                                                                                            {/* <button style={{ maxWidth: "120px" }} className='w-100 text-center btn btn-secondary rounded-pill py-1' type='button' onClick={() => { setCreateNewSchedule(false); setSchedule(defaultSchedule); setDateOverrides(defaultOverride); window.location.reload(); }}>Cancel</button> */}
                                                                                                            <button style={{ maxWidth: "max-content" }} className='w-100 text-center btn salesforce-style rounded-pill py-1' id="saveSchedule2" type='button' disabled={!updateButtonEnabled || !isCustomLinkAvailable} onClick={(e) => { updateNewSchedule(e, "Group") }}>Save Schedule</button>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    {/* ##################### END OF NEW SCHEDULE SYSTEM GROUP ###################### */}

                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="row my-3">
                                                                                                <div className="col-12 col-md-6 col-lg-6 mb-4">
                                                                                                    <div className="container-fluid border insightBackground rounded" style={{ position: "relative", zIndex: "3" }}>
                                                                                                        <div className="row">
                                                                                                            <div className="col-12">
                                                                                                                <span style={{ fontWeight: "500" }} className='my-3 d-block'>Want to add time before or after your events?</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="row mb-2">
                                                                                                            <div className="col-12 d-flex align-items-center gap-4 py-2">
                                                                                                                {
                                                                                                                    eventGroupBeforeTimeActive && (
                                                                                                                        <input type="checkbox" defaultChecked onClick={() => { setEventGroupBeforeTimeActive(false); }} className='form-check-input checkboxInpage' />
                                                                                                                    )
                                                                                                                }
                                                                                                                {
                                                                                                                    eventGroupBeforeTimeActive === false && (
                                                                                                                        <input type="checkbox" onClick={() => { setEventGroupBeforeTimeActive(true); }} className='form-check-input checkboxInpage' />
                                                                                                                    )
                                                                                                                }
                                                                                                                <div className="">
                                                                                                                    <h6 className={`${eventGroupBeforeTimeActive ? "" : "text-muted"}`}>
                                                                                                                        Before Event
                                                                                                                    </h6>
                                                                                                                    {/* <select disabled={eventGroupBeforeTimeActive ? false : true} className='' onChange={(e) => { setEventGroupBeforeTime(e.target.value); }} value={eventGroupBeforeTime}>
                                                                                                    <option value="5">5 min</option>
                                                                                                    <option value="10">10 min</option>
                                                                                                    <option value="15">15 min</option>
                                                                                                    <option value="30">30 min</option>
                                                                                                    <option value="45">45 min</option>
                                                                                                    <option value="60">1 hr</option>
                                                                                                    <option value="90">1 hr 30 min</option>
                                                                                                    <option value="120">2 hr</option>
                                                                                                    <option value="150">2 hr 30 min</option>
                                                                                                    <option value="180">3 hr</option>
                                                                                                </select> */}
                                                                                                                    {/* <DropdownButton
                                                                                                                    as="ButtonGroup"
                                                                                                                    className='w-100'
                                                                                                                    id={`dropdown-button-drop-down`}
                                                                                                                    drop={"down"}
                                                                                                                    variant="light"
                                                                                                                    disabled={eventGroupBeforeTimeActive ? false : true}
                                                                                                                    title={
                                                                                                                        eventGroupBeforeTime > 60 ?
                                                                                                                            Math.floor(eventGroupBeforeTime % 60) === 0 ?
                                                                                                                                Math.floor(eventGroupBeforeTime / 60) + " hrs"
                                                                                                                                : Math.floor(eventGroupBeforeTime / 60) + " hrs, " + Math.floor(eventGroupBeforeTime % 60) + " min"
                                                                                                                            : Math.floor(eventGroupBeforeTime) + " min"
                                                                                                                    }
                                                                                                                    onSelect={(e, event) => { setEventGroupBeforeTime(e); }}
                                                                                                                >
                                                                                                                    <Dropdown.Item eventKey="15">15 minutes</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="30">30 minutes</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="45">45 minutes</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="60">1 hr</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="90">1 hr 30 min</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="120">2 hr</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="150">2 hr 30 min</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="180">3 hr</Dropdown.Item>
                                                                                                                </DropdownButton> */}
                                                                                                                    {
                                                                                                                        selectedEventBeforeTimeFromList ?
                                                                                                                            <Select
                                                                                                                                isDisabled={eventGroupBeforeTimeActive ? false : true}
                                                                                                                                options={addTimeAfterBeforeForSelect}
                                                                                                                                defaultValue={selectedEventBeforeTimeFromList}
                                                                                                                                onChange={(e) => { setEventGroupBeforeTime(e.value) }}
                                                                                                                            />
                                                                                                                            :
                                                                                                                            ""
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="row mb-2">
                                                                                                            <div className="col-12 d-flex align-items-center gap-4 py-2">
                                                                                                                <div>
                                                                                                                    {
                                                                                                                        eventGroupAfterTimeActive && (
                                                                                                                            <input type="checkbox" defaultChecked onClick={() => { setEventGroupAfterTimeActive(false); }} className='form-check-input checkboxInpage' />
                                                                                                                        )
                                                                                                                    }
                                                                                                                    {
                                                                                                                        eventGroupAfterTimeActive === false && (
                                                                                                                            <input type="checkbox" onClick={() => { setEventGroupAfterTimeActive(true); }} className='form-check-input checkboxInpage' />
                                                                                                                        )
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <h6 className={`${eventGroupAfterTimeActive ? "" : "text-muted"}`}>
                                                                                                                        After Event
                                                                                                                    </h6>
                                                                                                                    {/* <select disabled={eventGroupAfterTimeActive ? false : true} className='' onChange={(e) => { setEventGroupAfterTime(e.target.value); }} value={eventGroupAfterTime}>
                                                                                                        <option value="5">5 min</option>
                                                                                                        <option value="10">10 min</option>
                                                                                                        <option value="15">15 min</option>
                                                                                                        <option value="30">30 min</option>
                                                                                                        <option value="45">45 min</option>
                                                                                                        <option value="60">1 hr</option>
                                                                                                        <option value="90">1 hr 30 min</option>
                                                                                                        <option value="120">2 hr</option>
                                                                                                        <option value="150">2 hr 30 min</option>
                                                                                                        <option value="180">3 hr</option>
                                                                                                    </select> */}
                                                                                                                    {/* <DropdownButton
                                                                                                                    as="ButtonGroup"
                                                                                                                    className='w-100'
                                                                                                                    id={`dropdown-button-drop-down`}
                                                                                                                    drop={"down"}
                                                                                                                    variant="light"
                                                                                                                    disabled={eventGroupAfterTimeActive ? false : true}
                                                                                                                    title={
                                                                                                                        eventGroupAfterTime > 60 ?
                                                                                                                            Math.floor(eventGroupAfterTime % 60) === 0 ?
                                                                                                                                Math.floor(eventGroupAfterTime / 60) + " hrs"
                                                                                                                                : Math.floor(eventGroupAfterTime / 60) + " hrs, " + Math.floor(eventGroupAfterTime % 60) + " min"
                                                                                                                            : Math.floor(eventGroupAfterTime) + " min"
                                                                                                                    }
                                                                                                                    onSelect={(e, event) => { setEventGroupAfterTime(e); }}

                                                                                                                >
                                                                                                                    <Dropdown.Item eventKey="15">15 minutes</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="30">30 minutes</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="45">45 minutes</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="60">1 hr</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="90">1 hr 30 min</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="120">2 hr</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="150">2 hr 30 min</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="180">3 hr</Dropdown.Item>
                                                                                                                </DropdownButton> */}
                                                                                                                    {
                                                                                                                        selectedEventAfterTimeFromList ?
                                                                                                                            <Select
                                                                                                                                isDisabled={eventGroupAfterTimeActive ? false : true}
                                                                                                                                options={addTimeAfterBeforeForSelect}
                                                                                                                                defaultValue={selectedEventAfterTimeFromList}
                                                                                                                                onChange={(e) => { setEventGroupAfterTime(e.value) }}
                                                                                                                            />
                                                                                                                            :
                                                                                                                            ""
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-12 col-md-5 col-lg-5 d-flex flex-column">
                                                                                                    <p className='mediumTextSize'>Give yourself some buffer time to prepare for or wrap up from booked Event.</p>
                                                                                                    <div className="rounded h-100">
                                                                                                        <div className="card rounded overflow-hidden insightBackground">
                                                                                                            <div className={`card-header p-1 ${eventGroupBeforeTimeActive ? "salesforce-style" : ""}`}></div>
                                                                                                            <div className="card-body p-0">
                                                                                                                <button type='button' className='btn bg-body w-100 text-break'>{groupSummary}</button>
                                                                                                            </div>
                                                                                                            <div className={`card-footer p-1 ${eventGroupAfterTimeActive ? "salesforce-style" : ""}`}></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="row mb-3">
                                                                                                <div className="col-12 col-md-6 col-lg-6 d-flex align-items-center gap-2 mb-2">
                                                                                                    <div className='border insightBackground rounded p-3 w-100'>
                                                                                                        <div className='mb-3'>
                                                                                                            <span style={{ fontWeight: "500" }}>Secret Event</span>
                                                                                                            <p className='m-0 p-0 mediumTextSize'>Use this to hide the event on your main scheduling page.</p>
                                                                                                        </div>
                                                                                                        <div className="mb-2 d-flex align-items-center justify-content-start gap-2">
                                                                                                            {
                                                                                                                groupSecretEvent && (
                                                                                                                    <input type="checkbox" defaultChecked onClick={() => { setGroupSecretEvent(false); }} className='form-check-input checkboxInpage p-0 m-0' />
                                                                                                                )
                                                                                                            }
                                                                                                            {
                                                                                                                groupSecretEvent === false && (
                                                                                                                    <input type="checkbox" onClick={() => { setGroupSecretEvent(true); }} className='form-check-input checkboxInpage p-0 m-0' />
                                                                                                                )
                                                                                                            }
                                                                                                            <span className={`${groupSecretEvent ? "" : "text-muted"} mediumTextSize`} style={{ fontWeight: "500" }}>
                                                                                                                Make this a Secret Event
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='col-12 col-md-6 col-lg-6'>
                                                                                                    <div className='mediumTextSize w-100'>
                                                                                                        <span style={{ fontWeight: "500" }}> Note:</span> Only Invitees who have this Schedule AI link can schedule with you.
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            {/* #################################### Additional Settings Started ############################################### */}

                                                                                            <div className="row mb-3">
                                                                                                <div className="col-12 col-md-6 col-lg-6">
                                                                                                    <span style={{ fontWeight: "500", fontSize: "1.2rem" }}>Additional Settings</span>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="row mb-3">
                                                                                                <div className="col-12 col-md-6 col-lg-6">
                                                                                                    <div className='insightBackground border p-3 rounded' style={{ position: "relative", zIndex: "2" }}>
                                                                                                        <span className='d-block mb-2' style={{ fontWeight: "500" }}>Stop Bookings before:</span>

                                                                                                        {/* <DropdownButton
                                                                                                        as="ButtonGroup"
                                                                                                        className='w-100'
                                                                                                        drop={"down"}
                                                                                                        variant="light"
                                                                                                        title={
                                                                                                            parseInt(stopBookingTime[1]) === 1 ? stopBookingTime[1] + " Hour" : stopBookingTime[1] + " Hours"
                                                                                                        }
                                                                                                        onSelect={(e, event) => { if (e !== "custom") { setStopBookingTime([stopTimeForBookingType, e]); setCustomStopBookingView(false); } else { setCustomStopBookingView(true); setStopBookingTime([stopTimeForBookingType, 1]) } }}
                                                                                                    >
                                                                                                        <Dropdown.Item eventKey="1">1 Hour</Dropdown.Item>
                                                                                                        <Dropdown.Item eventKey="2">2 Hours</Dropdown.Item>
                                                                                                        <Dropdown.Item eventKey="3">3 Hours</Dropdown.Item>
                                                                                                        <Dropdown.Item eventKey="4">4 Hours</Dropdown.Item>
                                                                                                        <Dropdown.Item eventKey="custom">custom</Dropdown.Item>
                                                                                                    </DropdownButton> */}
                                                                                                        {
                                                                                                            selectedStopBookingBeforeLabel ?
                                                                                                                <Select
                                                                                                                    options={stopBookingBeforeListForSelect}
                                                                                                                    defaultValue={selectedStopBookingBeforeLabel}
                                                                                                                    onChange={(e) => {
                                                                                                                        if (e.label !== "custom") {
                                                                                                                            setStopBookingTime([stopTimeForBookingType, e.value]);
                                                                                                                            setCustomStopBookingView(false);
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            setCustomStopBookingView(true);
                                                                                                                            setStopBookingTime([stopTimeForBookingType, 1]);
                                                                                                                        }
                                                                                                                    }}
                                                                                                                />
                                                                                                                :
                                                                                                                ""
                                                                                                        }
                                                                                                        {
                                                                                                            customStopBookingView &&
                                                                                                            <div className={`${customStopBookingView} d-flex justify-content-center align-items-center gap-2 my-2`}>
                                                                                                                {
                                                                                                                    stopTimeForBookingType === "hours" ?
                                                                                                                        <input className='customInputFieldFocus w-100' required type="number" min="1" max="23" value={stopBookingTime[1]} onChange={(e) => { setStopBookingTime(["hours", e.target.value]); }} />
                                                                                                                        :
                                                                                                                        <input className='customInputFieldFocus w-100' required type="number" min="1" max="60" defaultValue={"1"} onChange={(e) => { setStopBookingTime(["days", e.target.value]); }} />
                                                                                                                }

                                                                                                                {/* <DropdownButton
                                                                                                                    as="ButtonGroup"
                                                                                                                    className='w-100'
                                                                                                                    id={`dropdown-button-drop-down`}
                                                                                                                    drop={"down"}
                                                                                                                    variant="light"
                                                                                                                    title={stopTimeForBookingType === "hours" ? "hours" : "days"}
                                                                                                                    onSelect={(e, event) => { setStopTimeForBookingType(e); }}
                                                                                                                >
                                                                                                                    <Dropdown.Item eventKey="hours">hours</Dropdown.Item>
                                                                                                                </DropdownButton> */}
                                                                                                                <Select
                                                                                                                    className='w-100'
                                                                                                                    options={[{ label: "hours", value: "hours" }]}
                                                                                                                    defaultValue={[{ label: "hours", value: "hours" }]}
                                                                                                                    onChange={(e) => { setStopTimeForBookingType(e.label); }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-12 col-md-6 col-lg-6">
                                                                                                    <div className='mediumTextSize w-100'>
                                                                                                        <span style={{ fontWeight: "500" }}>Note:</span> This feature can control the last minute booking problems. Set your desired time to stop bookings after that particular time.
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="row mb-3">
                                                                                                <div className="col-9 col-sm-9 col-md-9 col-lg-9">
                                                                                                    <span style={{ fontWeight: "500", fontSize: "1.2rem" }}>Questions for Invitees:</span>
                                                                                                </div>
                                                                                                <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                                                                                    <span className="mediumTextSize" style={{ fontWeight: "500" }}>Required</span>
                                                                                                    <OverlayTrigger
                                                                                                        placement="left"
                                                                                                        delay={{ show: 100, hide: 200 }}
                                                                                                        overlay={
                                                                                                            <Popover id="popover-basic">
                                                                                                                <Popover.Header className='salesforce-style' as="h3">Required Questions</Popover.Header>
                                                                                                                <Popover.Body>
                                                                                                                    <p className='p-0 m-0 mb-2 helpTextSize'>You can make a questions mandatory while your invitees book a meeting whith you by using this switch.</p>
                                                                                                                    <img src={requiredQuestionPng} className='w-100' alt="" />
                                                                                                                </Popover.Body>
                                                                                                            </Popover>
                                                                                                        }>
                                                                                                        <HelpOutlineIcon style={{ maxWidth: "15px" }} />
                                                                                                    </OverlayTrigger>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="row mb-3">
                                                                                                <div className="col-9 col-md-9 col-lg-9">
                                                                                                    <input type="text" className='customInputFieldFocus w-100' onChange={(e) => { setGroupQuestions(e.target.value); }} value={groupQuestions} placeholder='enter your question here?' />
                                                                                                </div>
                                                                                                <div className="col-3 col-md-3 col-lg-3 d-flex align-items-center">
                                                                                                    <div className="outerToggleSwitch" onClick={() => { setIsDescriptionRequired(!isDescriptionRequired); }}>
                                                                                                        <div className={`frameOfToggleSwitch ${isDescriptionRequired === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                            <span>On</span>
                                                                                                            <div className="toggleSwitchBall"></div>
                                                                                                            <span>Off</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            {/* #################################### Additional Settings End ############################################### */}
                                                                                            <div className="row my-3">
                                                                                                <div className="col d-flex align-items-center justify-content-center">
                                                                                                    <div className='d-flex justify-content-center gap-2 w-100'>
                                                                                                        <button type='button' style={{ maxWidth: "120px" }} className='btn btn-sm w-100 text-center btn-secondary rounded-pill' onClick={() => { setWhenEvent(false); window.location.reload(); }}>Cancel</button>
                                                                                                        <button type="submit" style={{ maxWidth: "120px" }} className='btn btn-sm w-100 text-center salesforce-style rounded-pill d-flex align-items-center justify-content-center gap-2' id="groupEditButton2" disabled={!updateButtonEnabled || !isCustomLinkAvailable}>Update</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>
                                                                            {/* <Accordion.Item eventKey="3">
                                                                <Accordion.Header>
                                                                    One Header
                                                                </Accordion.Header>
                                                                <Accordion.Body className='bg-soul'>
                                                                    One Body
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="4">
                                                                <Accordion.Header>
                                                                    One Header
                                                                </Accordion.Header>
                                                                <Accordion.Body className='bg-soul'>
                                                                    One Body
                                                                </Accordion.Body>
                                                            </Accordion.Item> */}
                                                                        </Accordion>
                                                                    </form>
                                                                </>
                                                            )
                                                            :
                                                            myEvent?.eventType === "Round Robin" ?
                                                                (
                                                                    <>
                                                                        <form id="eventFormHandle" className="eventGoogleForm w-100 p-0" onSubmit={updateRoundRobinEvent}>
                                                                            <Accordion className="w-100 insightBackground">
                                                                                <Accordion.Item eventKey="1">
                                                                                    <Accordion.Header>
                                                                                        <div className="container-fluid p-0">
                                                                                            <div className="row sticky-top">
                                                                                                <div className="col p-0" onClick={(e) => { setWhatEvent(!whatEvent); setWhenEvent(false); setCustomizedEvent(false); }}>
                                                                                                    <div className='eventPreview'>
                                                                                                        <div style={{ height: "2em", width: "2em", borderRadius: "100%", pointerEvents: "none", alignSelf: "center", aspectRatio: "1" }} className={` event-color-${colorId}`} ></div>
                                                                                                        <div className='d-flex flex-column gap-1'>
                                                                                                            <div className='prewEventWhich text-dark'>What event is this?</div>
                                                                                                            <div className='eventNamePrew text-dark d-flex align-items-center gap-2 flex-wrap mediumTextSize'>
                                                                                                                <span id="text-capitalize text-dark text-break" style={eventName ? eventName.includes(" ") ? { wordBreak: "break-word" } : { wordBreak: "break-all" } : { wordBreak: "break-all" }}>
                                                                                                                    {
                                                                                                                        eventName ?
                                                                                                                            <>
                                                                                                                                {eventName},
                                                                                                                            </>
                                                                                                                            :
                                                                                                                            ""
                                                                                                                    }
                                                                                                                </span>
                                                                                                                <span id="d-flex text-break text-dark align-items-center gap-1">
                                                                                                                    {
                                                                                                                        meetingType === "phone" ?
                                                                                                                            <span className='selectedLocation'>
                                                                                                                                <img alt="Call" src={callimg} className='iconsForLocation' />
                                                                                                                                <span className='text-dark'>Phone Call</span>
                                                                                                                            </span> :
                                                                                                                            meetingType === "meet" ?
                                                                                                                                <span className='selectedLocation'>
                                                                                                                                    <img src={meetImg} alt="Meet" className='iconsForLocation' />
                                                                                                                                    <span className='text-dark'>Google Meet Conference</span>
                                                                                                                                </span> :
                                                                                                                                meetingType === "zoom" ?
                                                                                                                                    <span className='selectedLocation'>
                                                                                                                                        <img src={zoomImg} alt="Zoom" className='iconsForLocation' />
                                                                                                                                        <span>Zoom Conference</span>
                                                                                                                                        {
                                                                                                                                            needZoomToken ?
                                                                                                                                                <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                                                                                                : ""
                                                                                                                                        }
                                                                                                                                    </span> :
                                                                                                                                    meetingType === "goto" ?
                                                                                                                                        <span className='selectedLocation'>
                                                                                                                                            <img src={gotoImg} alt="GoTo" className='iconsForLocation' />
                                                                                                                                            <span>GoTo Conference</span>
                                                                                                                                            {
                                                                                                                                                needGTMToken ?
                                                                                                                                                    <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                                                                                                    : ""
                                                                                                                                            }
                                                                                                                                        </span> :
                                                                                                                                        meetingType === "webex" ?
                                                                                                                                            <span className='selectedLocation'>
                                                                                                                                                <img src={webexImg} alt="Webex" className='iconsForLocation' />
                                                                                                                                                <span>Webex Conference</span>
                                                                                                                                                {
                                                                                                                                                    needWebexToken ?
                                                                                                                                                        <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                                                                                                        : ""
                                                                                                                                                }
                                                                                                                                            </span> :
                                                                                                                                            meetingType === "teams" ?
                                                                                                                                                <span className='selectedLocation'>
                                                                                                                                                    <img src={teamsImg} alt="Teams" className='iconsForLocation' />
                                                                                                                                                    <span>Teams Conference</span>
                                                                                                                                                    {
                                                                                                                                                        needTeamsToken ?
                                                                                                                                                            <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                                                                                                            : ""
                                                                                                                                                    }
                                                                                                                                                </span> :
                                                                                                                                                meetingType === "address" && myEvent.location.length > 0 && groupLocation.length > 0 ?
                                                                                                                                                    <span className='selectedLocation'>
                                                                                                                                                        <img src={addressImg} alt="Address" className='iconsForLocation' />
                                                                                                                                                        <span className='text-dark'>{location ? location : myEvent.location}</span>
                                                                                                                                                    </span>
                                                                                                                                                    :
                                                                                                                                                    <>
                                                                                                                                                        <img src={addressImg} alt="Address" className='iconsForLocation' />
                                                                                                                                                        {location ? location : "No location Given"}
                                                                                                                                                    </>
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Accordion.Header>
                                                                                    <Accordion.Body className='bg-soul'>
                                                                                        <div className="container-fluid">
                                                                                            <div className="row my-3">
                                                                                                <div className="col-12 col-md-6 col-lg-6 p-0">
                                                                                                    <label htmlFor='eventName' className='border w-100 p-2 insightBackground rounded'>
                                                                                                        <span className='' style={{ fontWeight: "500" }}>
                                                                                                            Event Name *
                                                                                                        </span>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            maxLength={100}
                                                                                                            id="summary"
                                                                                                            className='customInputFieldFocus w-100 px-2'
                                                                                                            defaultValue={eventName}
                                                                                                            required
                                                                                                            placeholder="Add the name of the event"
                                                                                                            onChange={e => setEventName(e.target.value)}
                                                                                                        />
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="col-12 col-md-6 col-lg-6">

                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="row mb-3">
                                                                                                <div className="col-12 col-md-6 col-lg-6 p-0">
                                                                                                    <label htmlFor='selectLocation' className={`border w-100 p-2 insightBackground rounded ${locationSelection ? "mb-0" : ""}`} onClick={() => { setLocationSelection(!locationSelection); }}>
                                                                                                        <span style={{ fontWeight: "500" }}>Select Location</span>
                                                                                                        <div id="selectLocation" className='locationFrame bg-soul rounded'>
                                                                                                            {
                                                                                                                meetingType ?
                                                                                                                    meetingType === "phone" ?
                                                                                                                        (
                                                                                                                            <div className='selectedLocation'>
                                                                                                                                <img alt="call" src={callimg} className='iconsForLocation' />
                                                                                                                                <span>
                                                                                                                                    Phone call
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                        :
                                                                                                                        meetingType === "meet" ?
                                                                                                                            (
                                                                                                                                <div className='selectedLocation'>
                                                                                                                                    <img alt="meet" src={meetImg} className='iconsForLocation' />
                                                                                                                                    <span>
                                                                                                                                        Google Meet Conference
                                                                                                                                    </span>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                            :
                                                                                                                            meetingType === "zoom" ?
                                                                                                                                (<div className='selectedLocation'>
                                                                                                                                    <img alt="zoom" src={zoomImg} className='iconsForLocation' />
                                                                                                                                    <span>
                                                                                                                                        Zoom Conference
                                                                                                                                    </span>
                                                                                                                                    {
                                                                                                                                        needZoomToken ?
                                                                                                                                            <i className="fa fa-chain-broken text-danger" title="not integrated" aria-hidden="true"></i> : ""
                                                                                                                                    }
                                                                                                                                </div>)
                                                                                                                                :
                                                                                                                                meetingType === "goto" ?
                                                                                                                                    (<div className='selectedLocation'>
                                                                                                                                        <img alt="GoTo" src={gotoImg} className='iconsForLocation' />
                                                                                                                                        <span>
                                                                                                                                            GoTo Conference
                                                                                                                                        </span>
                                                                                                                                        {
                                                                                                                                            needGTMToken ?
                                                                                                                                                <i className="fa fa-chain-broken text-danger" title="not integrated" aria-hidden="true"></i> : ""
                                                                                                                                        }
                                                                                                                                    </div>)
                                                                                                                                    :
                                                                                                                                    meetingType === "webex" ?
                                                                                                                                        (<div className='selectedLocation'>
                                                                                                                                            <img alt="Webex" src={webexImg} className='iconsForLocation' />
                                                                                                                                            <span>
                                                                                                                                                Webex Conference
                                                                                                                                            </span>
                                                                                                                                            {
                                                                                                                                                needWebexToken ?
                                                                                                                                                    <i className="fa fa-chain-broken text-danger" title="not integrated" aria-hidden="true"></i> : ""
                                                                                                                                            }
                                                                                                                                        </div>)
                                                                                                                                        :
                                                                                                                                        meetingType === "teams" ?
                                                                                                                                            (<div className='selectedLocation'>
                                                                                                                                                <img alt="Teams" src={teamsImg} className='iconsForLocation' />
                                                                                                                                                <span>
                                                                                                                                                    Teams Conference
                                                                                                                                                </span>
                                                                                                                                                {
                                                                                                                                                    needTeamsToken ?
                                                                                                                                                        <i className="fa fa-chain-broken text-danger" title="not integrated" aria-hidden="true"></i> : ""
                                                                                                                                                }
                                                                                                                                            </div>)
                                                                                                                                            :
                                                                                                                                            meetingType === "address" ?
                                                                                                                                                (
                                                                                                                                                    <div className='selectedLocation'>
                                                                                                                                                        <img alt="address" src={addressImg} className='iconsForLocation' />
                                                                                                                                                        <span>
                                                                                                                                                            Custom Address
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                )
                                                                                                                                                : "unkown meeting Type"
                                                                                                                    :
                                                                                                                    ""
                                                                                                            }

                                                                                                        </div>
                                                                                                    </label>


                                                                                                    {
                                                                                                        locationSelection &&
                                                                                                        <div id='locationSelector' className="locationSelector bg-body rounded shadow-sm">
                                                                                                            <ul>
                                                                                                                <li title='Phone Call' onClick={() => { setMeetingType("phone"); setLocation("phone"); setLocationSelection(false); }}>
                                                                                                                    <span>
                                                                                                                        <img alt="call" src={callimg} className='iconsForLocation' />
                                                                                                                    </span>
                                                                                                                    <span>
                                                                                                                        Phone Call
                                                                                                                    </span>
                                                                                                                </li>
                                                                                                                <li title='Google Meet Conference' onClick={() => { setMeetingType("meet"); setLocation("meet"); setLocationSelection(false); }}>
                                                                                                                    <span>
                                                                                                                        <img alt="meet" src={meetImg} className='iconsForLocation' />
                                                                                                                    </span>
                                                                                                                    <span>
                                                                                                                        Google Meet Conference
                                                                                                                    </span>
                                                                                                                </li>
                                                                                                                <li title='Zoom Conference' onClick={() => { setMeetingType("zoom"); setLocation("zoom"); setLocationSelection(false); }}>
                                                                                                                    <span>
                                                                                                                        <img alt="Zoom" src={zoomImg} className='iconsForLocation' />
                                                                                                                    </span>
                                                                                                                    <span>
                                                                                                                        Zoom Conference
                                                                                                                    </span>
                                                                                                                    {
                                                                                                                        needZoomToken ?
                                                                                                                            <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i> : ""
                                                                                                                    }
                                                                                                                </li>
                                                                                                                <li title='GoTo Conference' onClick={() => { setMeetingType("goto"); setLocation("goto"); setLocationSelection(false); }}>
                                                                                                                    <span>
                                                                                                                        <img alt="Goto" src={gotoImg} className='iconsForLocation' />
                                                                                                                    </span>
                                                                                                                    <span>
                                                                                                                        GoTo Conference
                                                                                                                    </span>
                                                                                                                    {
                                                                                                                        needGTMToken ?
                                                                                                                            <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i> : ""
                                                                                                                    }
                                                                                                                </li>
                                                                                                                <li title='Webex Conference' onClick={() => { setMeetingType("webex"); setLocation("webex"); setLocationSelection(false); }}>
                                                                                                                    <span>
                                                                                                                        <img alt="Webex" src={webexImg} className='iconsForLocation' />
                                                                                                                    </span>
                                                                                                                    <span>
                                                                                                                        Webex Conference
                                                                                                                    </span>
                                                                                                                    {
                                                                                                                        needWebexToken ?
                                                                                                                            <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i> : ""
                                                                                                                    }
                                                                                                                </li>
                                                                                                                <li title='Teams Conference' onClick={() => { setMeetingType("teams"); setLocation("teams"); setLocationSelection(false); }}>
                                                                                                                    <span>
                                                                                                                        <img alt="Teams" src={teamsImg} className='iconsForLocation' />
                                                                                                                    </span>
                                                                                                                    <span>
                                                                                                                        Teams Conference
                                                                                                                    </span>
                                                                                                                    {
                                                                                                                        needTeamsToken ?
                                                                                                                            <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i> : ""
                                                                                                                    }
                                                                                                                </li>
                                                                                                                <li title='Address' onClick={() => { setMeetingType("address"); setLocation(myEvent.meetingType === "address" ? myEvent.location : ""); setLocationSelection(false); }}>
                                                                                                                    <span>
                                                                                                                        <img alt="zoom" src={addressImg} className='iconsForLocation' />
                                                                                                                    </span>
                                                                                                                    Custom Address
                                                                                                                </li>
                                                                                                            </ul>
                                                                                                        </div>
                                                                                                    }

                                                                                                    {
                                                                                                        meetingType === "phone" && (
                                                                                                            <label className='border p-2 insightBackground w-100 rounded'>
                                                                                                                <div className="askWhich">
                                                                                                                    {
                                                                                                                        inboundCall ?
                                                                                                                            (
                                                                                                                                <>
                                                                                                                                    <label htmlFor="outboundCalloff2">
                                                                                                                                        <input type="radio" id="outboundCalloff2" name="callBound" onClick={() => { setInboundCall(false); }} />
                                                                                                                                        I should call my invitees
                                                                                                                                    </label>
                                                                                                                                    <label htmlFor="inboundCallonn2">
                                                                                                                                        {
                                                                                                                                            phoneList ?
                                                                                                                                                optionListForPhone.length > 0 ?
                                                                                                                                                    <>
                                                                                                                                                        <input defaultChecked type="radio" id="inboundCallonn2" name="callBound" onClick={() => { setInboundCall(true); }} />
                                                                                                                                                        <span>Invitees should call me</span>
                                                                                                                                                    </>
                                                                                                                                                    :
                                                                                                                                                    <>
                                                                                                                                                        <input defaultChecked type="radio" id="inboundCallonn2" name="callBound" disabled={true} onClick={() => { setInboundCall(true); }} />
                                                                                                                                                        <span className='text-muted'>Invitees should call me</span>
                                                                                                                                                    </>
                                                                                                                                                :
                                                                                                                                                <>
                                                                                                                                                    <input defaultChecked type="radio" id="inboundCallonn2" name="callBound" disabled={true} onClick={() => { setInboundCall(true); }} />
                                                                                                                                                    <span className='text-muted'>Invitees should call me</span>
                                                                                                                                                </>
                                                                                                                                        }
                                                                                                                                    </label>
                                                                                                                                </>
                                                                                                                            )
                                                                                                                            :
                                                                                                                            (
                                                                                                                                <>
                                                                                                                                    <label htmlFor="outboundCalloff2">
                                                                                                                                        <input type="radio" defaultChecked id="outboundCalloff2" name="callBound" onClick={() => { setInboundCall(false); }} />
                                                                                                                                        I should call my invitees
                                                                                                                                    </label>
                                                                                                                                    <label htmlFor="inboundCallonn2">
                                                                                                                                        {
                                                                                                                                            phoneList ?
                                                                                                                                                optionListForPhone.length > 0 ?
                                                                                                                                                    <>
                                                                                                                                                        <input type="radio" id="inboundCallonn2" name="callBound" onClick={() => { setInboundCall(true); }} />
                                                                                                                                                        <span>Invitees should call me</span>
                                                                                                                                                    </>
                                                                                                                                                    :
                                                                                                                                                    <>
                                                                                                                                                        <input type="radio" id="inboundCallonn2" name="callBound" disabled={true} onClick={() => { setInboundCall(true); }} />
                                                                                                                                                        <span className='text-muted'>Invitees should call me</span>
                                                                                                                                                    </>
                                                                                                                                                :
                                                                                                                                                <>
                                                                                                                                                    <input type="radio" id="inboundCallonn2" name="callBound" disabled={true} onClick={() => { setInboundCall(true); }} />
                                                                                                                                                    <span className='text-muted'>Invitees should call me</span>
                                                                                                                                                </>
                                                                                                                                        }
                                                                                                                                    </label>
                                                                                                                                </>
                                                                                                                            )
                                                                                                                    }

                                                                                                                </div>

                                                                                                            </label>
                                                                                                        )
                                                                                                        // setInboundCall
                                                                                                    }


                                                                                                    {
                                                                                                        meetingType === "phone" && inboundCall ?
                                                                                                            <label htmlFor='phoneCallOneOnOne' className='border p-2 bg-body w-100 rounded'>
                                                                                                                <span style={{ fontWeight: "500" }}>
                                                                                                                    Choose Phone Number *
                                                                                                                </span>
                                                                                                                <PhoneInput
                                                                                                                    required
                                                                                                                    className="p-1 w-100 phoneNUmberInpurJHSGFUGDSFIY pointer-events-none"
                                                                                                                    style={{ pointerEvents: "none" }}
                                                                                                                    placeholder=""
                                                                                                                    value={location}
                                                                                                                    onChange={setLocation} />
                                                                                                                {
                                                                                                                    selectedPhoneNumberLabel ?
                                                                                                                        <Select
                                                                                                                            id='phoneCallOneOnOne'
                                                                                                                            options={optionListForPhone}
                                                                                                                            defaultValue={selectedPhoneNumberLabel}
                                                                                                                            onChange={(e) => { setLocation(e.label) }}
                                                                                                                        />
                                                                                                                        : ""
                                                                                                                }
                                                                                                            </label>
                                                                                                            :
                                                                                                            meetingType === "address" ?
                                                                                                                <label className='border p-2 bg-body w-100 rounded'>
                                                                                                                    <span style={{ fontWeight: "500" }}>
                                                                                                                        Enter Address Here *
                                                                                                                    </span>
                                                                                                                    <input
                                                                                                                        required
                                                                                                                        type="text"
                                                                                                                        className='customInputFieldFocus w-100 border px-2'
                                                                                                                        placeholder=""
                                                                                                                        defaultValue={myEvent.meetingType === "address" ? myEvent.location : ""}
                                                                                                                        onChange={(e) => setLocation(e.target.value)}
                                                                                                                    />
                                                                                                                </label>
                                                                                                                :
                                                                                                                meetingType === "meet" && needToken ?
                                                                                                                    <label>
                                                                                                                        <GoogleLogin
                                                                                                                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                                                                                                            buttonText='Sign in & Authorize Calendar'
                                                                                                                            onSuccess={responceGoogle}
                                                                                                                            onFailure={responseError}
                                                                                                                            cookiePolicy={"single_host_origin"}
                                                                                                                            fetchBasicProfile={true}
                                                                                                                            responseType="code"
                                                                                                                            accessType='offline'
                                                                                                                            scope='openid email profile https://www.googleapis.com/auth/calendar'
                                                                                                                        />
                                                                                                                    </label>
                                                                                                                    :
                                                                                                                    meetingType === "zoom" && needZoomToken ?
                                                                                                                        <label>
                                                                                                                            <Link to={"../calendar#Integrations"} className='color-salesforce text-decoration-none mediumTextSize'>
                                                                                                                                Click to Connect Zoom.
                                                                                                                            </Link>
                                                                                                                        </label> :
                                                                                                                        meetingType === "webex" && needWebexToken ?
                                                                                                                            <label>
                                                                                                                                <Link to={"../calendar#Integrations"} className='color-salesforce text-decoration-none mediumTextSize'>
                                                                                                                                    Click to Connect Webex.
                                                                                                                                </Link>
                                                                                                                            </label> :
                                                                                                                            meetingType === "goto" && needGTMToken ?
                                                                                                                                <label>
                                                                                                                                    <Link to={"../calendar#Integrations"} className='color-salesforce text-decoration-none mediumTextSize'>
                                                                                                                                        Click to Connect GoTo Meeting.
                                                                                                                                    </Link>
                                                                                                                                </label> :
                                                                                                                                meetingType === "teams" && needTeamsToken ?
                                                                                                                                    <label>
                                                                                                                                        <Link to={"../calendar#Integrations"} className='color-salesforce text-decoration-none mediumTextSize'>
                                                                                                                                            Click to Connect Teams.
                                                                                                                                        </Link>
                                                                                                                                    </label> :
                                                                                                                                    ""
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="col-12 col-md-6 col-lg-6">

                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="row mb-3">
                                                                                                <div className="col-12 col-md-6 col-lg-6 p-0">
                                                                                                    <label htmlFor='description' className='border p-2 insightBackground w-100 rounded'>
                                                                                                        <span style={{ fontWeight: "500" }}>
                                                                                                            Description  *
                                                                                                        </span>
                                                                                                        <textarea
                                                                                                            id="description"
                                                                                                            className='customInputFieldFocus w-100 px-2'
                                                                                                            defaultValue={description}
                                                                                                            required
                                                                                                            placeholder="Write a summary and any details your invitee should know about the event."
                                                                                                            onChange={e => setDescription(e.target.value)}
                                                                                                        />
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="col-12 col-md-6 col-lg-6 p-0">

                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="row mb-3">
                                                                                                <div className="col-12 col-md-6 col-lg-6 p-0">
                                                                                                    <label htmlFor='description' className='border p-2 insightBackground w-100 rounded d-flex flex-column'>
                                                                                                        <span className='d-flex gap-1 align-items-center' style={{ fontWeight: "500" }}>
                                                                                                            Event Link *

                                                                                                            <OverlayTrigger
                                                                                                                placement="right"
                                                                                                                delay={{ show: 100, hide: 200 }}
                                                                                                                overlay={
                                                                                                                    <Popover id="popover-basic">
                                                                                                                        <Popover.Header className='salesforce-style' as="h3">Unique event links</Popover.Header>
                                                                                                                        <Popover.Body>
                                                                                                                            <p className='p-0 m-0 helpTextSize'>
                                                                                                                                Use this field to change the unique url for this event.
                                                                                                                            </p>
                                                                                                                            <img src={uniqueLinkHelpText} className='img img-responsive w-100' alt='helpText' />
                                                                                                                        </Popover.Body>
                                                                                                                    </Popover>
                                                                                                                }>
                                                                                                                <HelpOutlineIcon style={{ maxWidth: "15px", cursor: "help" }} />
                                                                                                            </OverlayTrigger>
                                                                                                        </span>
                                                                                                        <span className='mediumTextSize text-muted'>{process.env.REACT_APP_DOMAINNAME + `/${user.username.replaceAll(" ", "-")}/`}</span>

                                                                                                        <div className="input-group w-100 flex-nowrap rounded">
                                                                                                            <input type="text" maxLength={50} id="UID" required className='customInputFieldFocus w-100 px-2' placeholder='UID' onClick={(e) => { if (e.target.value.length === 0) { checkCustomLinkAvailable({ target: { value: eventName.replaceAll(" ", "-") } }); } }} autoComplete="off" value={customEventLink} onChange={checkCustomLinkAvailable} />
                                                                                                            <div className="input-group-append">
                                                                                                                <span className="input-group-text h-100 border-0" style={{ borderRadius: "0px 0.375rem 0.375rem 0px" }}>
                                                                                                                    {
                                                                                                                        isCustomLinkAvailable ?
                                                                                                                            <i className='fa fa-check text-success'></i>
                                                                                                                            :
                                                                                                                            <i className='fa fa-times text-danger'></i>
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>


                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="col-12 col-md-6 col-lg-6 p-0">

                                                                                                </div>
                                                                                            </div>



                                                                                            <div className="row mb-3">
                                                                                                <div className="col-12 p-0">
                                                                                                    <label className='border p-2 insightBackground w-100 rounded d-flex flex-column'>
                                                                                                        <span style={{ fontWeight: "500" }} className="d-block">
                                                                                                            Event Color
                                                                                                        </span>
                                                                                                        <div className='event-color-holder pb-2'>
                                                                                                            <div onClick={(e) => { setColorId('1'); checkThisTab(e.target); }} className='event-color-tab event-color-1 checked-color'></div>
                                                                                                            <div onClick={(e) => { setColorId('2'); checkThisTab(e.target); }} className='event-color-tab event-color-2'></div>
                                                                                                            <div onClick={(e) => { setColorId('3'); checkThisTab(e.target); }} className='event-color-tab event-color-3'></div>
                                                                                                            <div onClick={(e) => { setColorId('4'); checkThisTab(e.target); }} className='event-color-tab event-color-4'></div>
                                                                                                            <div onClick={(e) => { setColorId('5'); checkThisTab(e.target); }} className='event-color-tab event-color-5'></div>
                                                                                                            <div onClick={(e) => { setColorId('6'); checkThisTab(e.target); }} className='event-color-tab event-color-6'></div>
                                                                                                            <div onClick={(e) => { setColorId('7'); checkThisTab(e.target); }} className='event-color-tab event-color-7'></div>
                                                                                                            <div onClick={(e) => { setColorId('8'); checkThisTab(e.target); }} className='event-color-tab event-color-8'></div>
                                                                                                            <div onClick={(e) => { setColorId('9'); checkThisTab(e.target); }} className='event-color-tab event-color-9'></div>
                                                                                                            <div onClick={(e) => { setColorId('10'); checkThisTab(e.target); }} className='event-color-tab event-color-10'></div>
                                                                                                            <div onClick={(e) => { setColorId('11'); checkThisTab(e.target); }} className='event-color-tab event-color-11'></div>
                                                                                                        </div>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="row my-3">
                                                                                                <div className="col d-flex align-items-center justify-content-center">
                                                                                                    <div className='d-flex justify-content-center gap-2 w-100'>
                                                                                                        <button type='button' style={{ maxWidth: "120px" }} className='btn btn-sm w-100 text-center btn-secondary rounded-pill' onClick={() => { setWhatEvent(false); window.location.reload(); }}>Cancel</button>
                                                                                                        <button type="submit" style={{ maxWidth: "120px" }} className='btn btn-sm w-100 text-center salesforce-style rounded-pill d-flex align-items-center justify-content-center gap-2' id="roundRobinEditButton1" disabled={!updateButtonEnabled || !isCustomLinkAvailable}>Update</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Accordion.Body>
                                                                                </Accordion.Item>
                                                                                <Accordion.Item eventKey="2">
                                                                                    <Accordion.Header onClick={(e) => { setWhenEvent(!whenEvent); setWhatEvent(false); setCustomizedEvent(false); }}>
                                                                                        <div className="container-fluid p-0">
                                                                                            <div className="row sticky-top">
                                                                                                <div className="col p-0">
                                                                                                    <div className='eventPreview'>
                                                                                                        <img src={caneldarDark} alt="Calendar Icon" className="iconsForLocation" style={{ width: "2em", alignSelf: "center" }} />
                                                                                                        <div className='d-flex flex-column gap-1'>
                                                                                                            <div className='prewEventWhich text-dark'>When can people book this event?</div>
                                                                                                            <div className='eventNamePrew text-dark d-flex align-items-center gap-2 flex-wrap mediumTextSize'>
                                                                                                                <span id="eventNamePrew text-dark">
                                                                                                                    {
                                                                                                                        duration > 60 ?
                                                                                                                            Math.floor(duration % 60) === 0 ?
                                                                                                                                Math.floor(duration / 60) + " hrs"
                                                                                                                                : Math.floor(duration / 60) + " hrs, " + Math.floor(duration % 60) + " min"
                                                                                                                            : Math.floor(duration) + " min"
                                                                                                                    },
                                                                                                                </span>
                                                                                                                <span id="eventLocationPrew">{dayjs(betweenDays[0].startDate).format("D MMM YYYY")} - {betweenDays[0].endDate ? dayjs(betweenDays[0].endDate).format("D MMM YYYY") : "Continuous"}</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Accordion.Header>
                                                                                    <Accordion.Body className='bg-soul'>
                                                                                        <div className="container-fluid p-0">
                                                                                            <div className="row my-3">
                                                                                                <div className="col">
                                                                                                    <div className='p-3 insightBackground rounded border'>
                                                                                                        <span style={{ fontWeight: "500" }}>
                                                                                                            Date Range
                                                                                                        </span>
                                                                                                        <p className='m-0'>Set a range of dates when you can accept meetings.</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="row mb-3">
                                                                                                <div className="col">
                                                                                                    <div className='rescheduler'>
                                                                                                        <label className='h6' style={{ fontWeight: "500" }}>
                                                                                                            Invitees can schedule...
                                                                                                        </label>
                                                                                                        {/* <div className="w-100 overflow-auto">
                                                                                                                <DateRange
                                                                                                                    // editableDateInputs={true}
                                                                                                                    onChange={item => setBetweenDays([item.selection])}
                                                                                                                    // showSelectionPreview={true}
                                                                                                                    // moveRangeOnFirstSelection={false}
                                                                                                                    months={2}
                                                                                                                    minDate={new Date()}
                                                                                                                    ranges={betweenDays}
                                                                                                                    direction="horizontal"
                                                                                                                    editableDateInputs={true}
                                                                                                                />
                                                                                                            </div> */}


                                                                                                        <div className='w-100 d-flex align-items-center gap-2 mb-4'>
                                                                                                            <label htmlFor="rollingDaysStraight" className={`d-flex align-items-center gap-2 ${straightRollingDays ? "" : "text-muted"}`}
                                                                                                                onClick={() => {
                                                                                                                    setStraightRollingDays(true);
                                                                                                                    setWithinDateRange(false);
                                                                                                                    setInfiniteDateRange(false);
                                                                                                                    setBetweenDays([
                                                                                                                        {
                                                                                                                            startDate: new Date(),
                                                                                                                            endDate: addDays(new Date(), parseInt(rollingDaysRef.current.value)),
                                                                                                                            key: 'selection'
                                                                                                                        }
                                                                                                                    ]);

                                                                                                                }}
                                                                                                            >
                                                                                                                {
                                                                                                                    straightRollingDays ?
                                                                                                                        <input type="radio" checked id='rollingDaysStraight' className='form-check-input p-0 m-0' name="rollingDays" />
                                                                                                                        :
                                                                                                                        <input type="radio" id='rollingDaysStraight' className='form-check-input p-0 m-0' name="rollingDays" />
                                                                                                                }

                                                                                                                <input
                                                                                                                    type="number"
                                                                                                                    disabled={!straightRollingDays}
                                                                                                                    defaultValue={30}
                                                                                                                    ref={rollingDaysRef}
                                                                                                                    onClick={
                                                                                                                        (e) => {
                                                                                                                            setBetweenDays([
                                                                                                                                {
                                                                                                                                    startDate: new Date(),
                                                                                                                                    endDate: addDays(new Date(), parseInt(e.target.value)),
                                                                                                                                    key: 'selection'
                                                                                                                                }
                                                                                                                            ]);
                                                                                                                        }}
                                                                                                                    onInput={
                                                                                                                        (e) => {
                                                                                                                            setBetweenDays([
                                                                                                                                {
                                                                                                                                    startDate: new Date(),
                                                                                                                                    endDate: addDays(new Date(), parseInt(e.target.value)),
                                                                                                                                    key: 'selection'
                                                                                                                                }
                                                                                                                            ]);

                                                                                                                            // console.log(addDays(new Date(), parseInt(e.target.value)))
                                                                                                                        }
                                                                                                                    } className='customInputFieldFocus rounded border' />
                                                                                                            </label>
                                                                                                            <div style={{ position: "relative", zIndex: "10" }}>
                                                                                                                <Select
                                                                                                                    isDisabled={!straightRollingDays}
                                                                                                                    className='mediumTextSize'
                                                                                                                    options={
                                                                                                                        [
                                                                                                                            { label: "calendar days", value: "calendardays" },
                                                                                                                            // { label: "weekdays", value: "weekdays" }
                                                                                                                        ]
                                                                                                                    }
                                                                                                                    defaultValue={[{ label: "calendar days", value: "calendardays" }]}
                                                                                                                    onChange={(e) => { }}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                into the future
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className='w-100 d-flex align-items-center gap-2 mb-4'>
                                                                                                            <label htmlFor="betweenForever" className={`d-flex align-items-center gap-2 ${withinDateRange ? "" : "text-muted"}`}
                                                                                                                onClick={(e) => {
                                                                                                                    setStraightRollingDays(false);
                                                                                                                    setWithinDateRange(true);
                                                                                                                    setInfiniteDateRange(false);
                                                                                                                }}
                                                                                                            >
                                                                                                                {
                                                                                                                    withinDateRange ?
                                                                                                                        <input type="radio" checked id='betweenForever' className='form-check-input p-0 m-0' name="rollingDays" />
                                                                                                                        :
                                                                                                                        <input type="radio" id='betweenForever' className='form-check-input p-0 m-0' name="rollingDays" />
                                                                                                                }
                                                                                                                <span className='mediumTextSize'>Within a date range</span>
                                                                                                            </label>
                                                                                                            {
                                                                                                                withinDateRange ?
                                                                                                                    <div className='flex-fill' style={{ position: "relative", zIndex: "9", maxWidth: "max-content" }} onClick={() => { setShowDateRangeSelector(true); setWithinDateRange(true); setStraightRollingDays(false); setInfiniteDateRange(false); }}>
                                                                                                                        <input type="text" disabled={!withinDateRange} value={`${dayjs(betweenDays[0].startDate).format("D MMM YYYY")} - ${betweenDays[0].endDate ? dayjs(betweenDays[0].endDate).format("D MMM YYYY") : "Continuous"}`} className=" w-100 rounded p-2 border mediumTextSize" />
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    ""
                                                                                                            }
                                                                                                        </div>

                                                                                                        <div className='w-100 mb-4'>
                                                                                                            <label htmlFor="foreversRoll" className={`d-flex align-items-center gap-2  ${infiniteDateRange ? "" : "text-muted"}`}
                                                                                                                onClick={(e) => {
                                                                                                                    setStraightRollingDays(false);
                                                                                                                    setWithinDateRange(false);
                                                                                                                    setInfiniteDateRange(true);

                                                                                                                    setBetweenDays([
                                                                                                                        {
                                                                                                                            startDate: new Date(),
                                                                                                                            endDate: null,
                                                                                                                            key: 'selection'
                                                                                                                        }
                                                                                                                    ]);
                                                                                                                }}

                                                                                                            >
                                                                                                                {
                                                                                                                    infiniteDateRange ?
                                                                                                                        <input type="radio" checked id='foreversRoll' className='form-check-input p-0 m-0' name="rollingDays" />
                                                                                                                        :
                                                                                                                        <input type="radio" id='foreversRoll' className='form-check-input p-0 m-0' name="rollingDays" />
                                                                                                                }
                                                                                                                <span className={`mediumTextSize`}>Indefinitely into the future</span>

                                                                                                            </label>
                                                                                                        </div>



                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="row mb-3">
                                                                                                <div className="col-12">
                                                                                                    <label htmlFor='endDateTime' className='w-100 border rounded p-3 insightBackground' style={{ position: "relative", zIndex: "5" }}>
                                                                                                        <span style={{ fontWeight: "500" }}>Meeting Duration</span>
                                                                                                        <p className='m-0 mb-2 mediumTextSize'>Define how long your event will be. It can be as long as 12 hours.</p>
                                                                                                        {
                                                                                                            // <DropdownButton
                                                                                                            //     as="ButtonGroup"
                                                                                                            //     className='w-100'
                                                                                                            //     id={`dropdown-button-drop-down`}
                                                                                                            //     drop={"down"}
                                                                                                            //     variant="light"
                                                                                                            //     title={
                                                                                                            //         duration > 60 ?
                                                                                                            //             Math.floor(duration % 60) === 0 ?
                                                                                                            //                 Math.floor(duration / 60) + " hrs"
                                                                                                            //                 : Math.floor(duration / 60) + " hrs, " + Math.floor(duration % 60) + " min"
                                                                                                            //             : Math.floor(duration) + " min"
                                                                                                            //     }
                                                                                                            //     onSelect={(e, event) => { if (e !== "custom") { setDuration(e); setCustomHourView(false); } else { setCustomHourView(true); setDuration(15); setDurationType("minutes"); } }}

                                                                                                            // >
                                                                                                            //     <Dropdown.Item eventKey="15">15 minutes</Dropdown.Item>
                                                                                                            //     <Dropdown.Item eventKey="30">30 minutes</Dropdown.Item>
                                                                                                            //     <Dropdown.Item eventKey="45">45 minutes</Dropdown.Item>
                                                                                                            //     <Dropdown.Item eventKey="60">60 minutes</Dropdown.Item>
                                                                                                            //     <Dropdown.Divider />
                                                                                                            //     <Dropdown.Item eventKey="custom">Custom</Dropdown.Item>
                                                                                                            // </DropdownButton>


                                                                                                            selectedDurationLabel ?
                                                                                                                Object.keys(selectedDurationLabel).length > 0 ?
                                                                                                                    <Select
                                                                                                                        options={meetingDurationListForSelect}
                                                                                                                        defaultValue={selectedDurationLabel}
                                                                                                                        onChange={(e) => { if (e.value !== "custom") { setDuration(e.value); setCustomHourView(false); } else { setCustomHourView(true); } }}
                                                                                                                    />
                                                                                                                    :
                                                                                                                    ""
                                                                                                                :
                                                                                                                ""
                                                                                                        }

                                                                                                        {
                                                                                                            customHourView ?
                                                                                                                (
                                                                                                                    <div className='d-flex justify-content-center align-items-center gap-2 my-2'>
                                                                                                                        {
                                                                                                                            durationType === "hours" ?
                                                                                                                                <input className='customInputFieldFocus w-100' ref={minutesOneOnOne} value={Math.floor(parseInt(duration) / 60) === 0 ? 1 : Math.floor(parseInt(duration) / 60)} required type="number" min="1" max="12" onChange={(e) => { convertToMinutesOneOnOne(e.target.value); }} />
                                                                                                                                :
                                                                                                                                <input className='customInputFieldFocus w-100' ref={minutesOneOnOne} value={parseInt(duration) === 0 ? 15 : duration} required type="number" min="15" max="720" onChange={(e) => { convertToMinutesOneOnOne(e.target.value); }} />
                                                                                                                        }
                                                                                                                        {/* <select className='' onChange={(e) => { setDurationType(e.target.value); }} value={durationType}>
                                                                                                        <option value="minutes">minutes</option>
                                                                                                        <option value="hours">hours</option>
                                                                                                    </select> */}
                                                                                                                        {/* <DropdownButton
                                                                                                                        as="ButtonGroup"
                                                                                                                        className='w-100'
                                                                                                                        id={`dropdown-button-drop-down`}
                                                                                                                        drop={"down"}
                                                                                                                        variant="light"
                                                                                                                        title={durationType}

                                                                                                                        onSelect={(e, event) => { setDurationType(e) }}
                                                                                                                    >
                                                                                                                        <Dropdown.Item eventKey="minutes">minutes</Dropdown.Item>
                                                                                                                        <Dropdown.Item eventKey="hours">hours</Dropdown.Item>
                                                                                                                    </DropdownButton> */}
                                                                                                                        <Select
                                                                                                                            className='w-50'
                                                                                                                            options={[{ label: "minutes", value: "minutes" }, { label: "hours", value: "hours" }]}
                                                                                                                            defaultValue={[durationType === "hours" ? { label: "hours", value: "hours" } : { label: "minutes", value: "minutes" }]}
                                                                                                                            onChange={(e) => { setDurationType(e.value); }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                )
                                                                                                                :
                                                                                                                ""
                                                                                                        }
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="col-12 col-lg-6 col-md-6"></div>
                                                                                            </div>

                                                                                            <div className='row mb-3'>
                                                                                                <div className="col">
                                                                                                    <div className='w-100 border rounded p-3 insightBackground'>
                                                                                                        <span style={{ fontWeight: "500" }}>How do you want to offer your availability for this event type?</span>
                                                                                                        <p>Select one of your schedules or define custom hours specific to this type of event.</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="row mb-3">
                                                                                                <div className="col">
                                                                                                    <div className='w-100'>
                                                                                                        <button type='button' className={`btn ${existingScheduleView ? "salesforce-style" : "bg-body border"}`} onClick={() => { setExistingScheduleView(true); setCustomScheduleView(false); }}>Use an existing schedule</button>
                                                                                                        <button type='button' className={`btn mx-3 ${customScheduleView ? "salesforce-style" : "bg-body border"}`} onClick={() => { setExistingScheduleView(false); setCustomScheduleView(true); }}>Set custom hours</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            {/* Existing schedule */}

                                                                                            <div className={`row mb-3 ${existingScheduleView ? "" : "d-none"}`}>
                                                                                                <div className="col">

                                                                                                    <div className=" rounded text-dark border">
                                                                                                        <div className="border-bottom p-3">
                                                                                                            <span className='mb-1 w-100 d-block' style={{ fontWeight: "500" }}>Which schedule type do you want to use for this event type?</span>
                                                                                                            {/* <select className=' my-2' value={scheduleName} onChange={(e) => { toogleScheduleSet(e.target.value); setScheduleName(e.target.value); }}>
                                                                                                                {
                                                                                                                    userSchedules ?
                                                                                                                        Object.keys(userSchedules).map((name, key) => {
                                                                                                                            return <option key={key} value={name}>{name}</option>
                                                                                                                        })
                                                                                                                        :
                                                                                                                        <option value="working hours">Working Hours</option>
                                                                                                                }
                                                                                                            </select> */}

                                                                                                            {/* <span className='border bg-body rounded p-2 pb-3 mb-3 d-block'> */}
                                                                                                            {/* <DropdownButton
                                                                                                                as="ButtonGroup"
                                                                                                                className='w-100'
                                                                                                                drop={"down"}
                                                                                                                variant="light"
                                                                                                                title={scheduleName ? scheduleName : ""}
                                                                                                                onSelect={(e, event) => { toogleScheduleSet(e); setScheduleName(e); }}

                                                                                                            >
                                                                                                                {
                                                                                                                    userSchedules ?
                                                                                                                        Object.keys(userSchedules).map((name, key) => {
                                                                                                                            return <Dropdown.Item eventKey={name}>{name}</Dropdown.Item>
                                                                                                                        })
                                                                                                                        :
                                                                                                                        <Dropdown.Item eventKey="working hours">Working Hours</Dropdown.Item>
                                                                                                                }
                                                                                                            </DropdownButton> */}
                                                                                                            {
                                                                                                                scheduleName && (
                                                                                                                    <Select
                                                                                                                        options={userScheduleListForSelect}
                                                                                                                        defaultValue={[{ label: scheduleName, value: scheduleName }]}
                                                                                                                        onChange={(e) => { toogleScheduleSet(e.label); setScheduleName(e.label); }}
                                                                                                                    />
                                                                                                                )
                                                                                                            }
                                                                                                            {/* </span> */}

                                                                                                            <span style={{ fontWeight: "500" }} className="d-block mb-1 mt-3">
                                                                                                                Time Zone
                                                                                                            </span>
                                                                                                            <Select className='mb-3 p-0 w-100' value={[selectedTimezone]} options={filteredTimezonesListSchedule} isDisabled={true} onChange={(e) => { setSelectedTimezone(e); }} />
                                                                                                            <div className="d-flex align-items-center gap-2 my-3">
                                                                                                                <i className='fa fa-globe fs-5'></i>
                                                                                                                <span style={{ fontWeight: "600" }}>
                                                                                                                    {selectedTimezone.value ? selectedTimezone.value.name : ""}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <p className='mediumTextSize'>Automatically detected timezone is {selectedTimezone.value ? selectedTimezone.value.name : ""}. You may change it to specify default time-zone for your calendar.</p>
                                                                                                        </div>
                                                                                                        <div className="p-3">
                                                                                                            <div className="container-fluid">
                                                                                                                <div className="row">
                                                                                                                    <div className="col-12 col-md-12 col-lg-7 border rounded shadow-sm insightBackground">
                                                                                                                        <div className="container-fluid h-100">
                                                                                                                            <div className="row">
                                                                                                                                <div className="col-4 col-md-2 col-lg-2 h6 p-2">SUN</div>
                                                                                                                                <div className="col-8 col-md-10 col-lg-10 p-2">
                                                                                                                                    {
                                                                                                                                        isSundayActive ?
                                                                                                                                            schedule.sunday.times.map((time, key) => {
                                                                                                                                                return <p className='w-100 mb-2 text-start' key={key}>
                                                                                                                                                    {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                                </p>
                                                                                                                                            })
                                                                                                                                            :
                                                                                                                                            <span className='text-muted'>unavailable</span>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="row">
                                                                                                                                <div className=" col-4 col-md-2 col-lg-2 h6 p-2">MON</div>
                                                                                                                                <div className="col-8 col-md-10 col-lg-10 p-2">
                                                                                                                                    {
                                                                                                                                        isMondayActive ?
                                                                                                                                            schedule.monday.times.map((time, key) => {
                                                                                                                                                return <p className='w-100 mb-2 text-start' key={key}>
                                                                                                                                                    {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                                </p>
                                                                                                                                            })
                                                                                                                                            :
                                                                                                                                            <span className='text-muted'>unavailable</span>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="row">
                                                                                                                                <div className=" col-4 col-md-2 col-lg-2 h6 p-2">TUE</div>
                                                                                                                                <div className="col-8 col-md-10 col-lg-10 p-2">
                                                                                                                                    {
                                                                                                                                        isTuesdayActive ?
                                                                                                                                            schedule.tuesday.times.map((time, key) => {
                                                                                                                                                return <p className='w-100 mb-2 text-start' key={key}>
                                                                                                                                                    {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                                </p>
                                                                                                                                            })
                                                                                                                                            :
                                                                                                                                            <span className='text-muted'>unavailable</span>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="row">
                                                                                                                                <div className=" col-4 col-md-2 col-lg-2 h6 p-2">WED</div>
                                                                                                                                <div className="col-8 col-md-10 col-lg-10 p-2">
                                                                                                                                    {
                                                                                                                                        isWednesdayActive ?
                                                                                                                                            schedule.wednesday.times.map((time, key) => {
                                                                                                                                                return <p className='w-100 mb-2 text-start' key={key}>
                                                                                                                                                    {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                                </p>
                                                                                                                                            })
                                                                                                                                            :
                                                                                                                                            <span className='text-muted'>unavailable</span>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="row">
                                                                                                                                <div className=" col-4 col-md-2 col-lg-2 h6 p-2">THU</div>
                                                                                                                                <div className="col-8 col-md-10 col-lg-10 p-2">
                                                                                                                                    {
                                                                                                                                        isThursdayActive ?
                                                                                                                                            schedule.thursday.times.map((time, key) => {
                                                                                                                                                return <p className='w-100 mb-2 text-start' key={key}>
                                                                                                                                                    {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                                </p>
                                                                                                                                            })
                                                                                                                                            :
                                                                                                                                            <span className='text-muted'>unavailable</span>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="row">
                                                                                                                                <div className=" col-4 col-md-2 col-lg-2 h6 p-2">FRI</div>
                                                                                                                                <div className="col-8 col-md-10 col-lg-10 p-2">
                                                                                                                                    {
                                                                                                                                        isFridayActive ?
                                                                                                                                            schedule.friday.times.map((time, key) => {
                                                                                                                                                return <p className='w-100 mb-2 text-start' key={key}>
                                                                                                                                                    {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                                </p>
                                                                                                                                            })
                                                                                                                                            :
                                                                                                                                            <span className='text-muted'>unavailable</span>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="row">
                                                                                                                                <div className=" col-4 col-md-2 col-lg-2 h6 p-2">SAT</div>
                                                                                                                                <div className="col-8 col-md-10 col-lg-10 p-2">
                                                                                                                                    {
                                                                                                                                        isSaturdayActive ?
                                                                                                                                            schedule.saturday.times.map((time, key) => {
                                                                                                                                                return <p className='w-100 mb-2 text-start' key={key}>
                                                                                                                                                    {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                                </p>
                                                                                                                                            })
                                                                                                                                            :
                                                                                                                                            <span className='text-muted'>unavailable</span>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="col-12 col-lg-1 py-2"></div>
                                                                                                                    <div className="col-12 col-md-12 col-lg-4 border rounded shadow-sm insightBackground">
                                                                                                                        <h5 className='py-3 text-center'>Date Overrides</h5>
                                                                                                                        {
                                                                                                                            dateOverrides ?
                                                                                                                                Object.keys(dateOverrides).length > 0 ?
                                                                                                                                    Object.keys(dateOverrides).map((data, key) => {
                                                                                                                                        return (
                                                                                                                                            <div key={key} className="card mb-2" id={`datesOverrides_${dateOverrides[data].uid}`}>
                                                                                                                                                <div className='card-header salesforce-style d-flex align-items-center justify-content-between'>
                                                                                                                                                    <span>{dayjs(dateOverrides[data].date).format("ddd, D MMM YYYY")}</span>
                                                                                                                                                    {/* <span><i className='fa fa-trash' id={dateOverrides[data].uid} onClick={(e) => { deleteOverrideDate(dateOverrides[data].date, `datesOverrides_${dateOverrides[data].uid}`); }}></i></span> */}
                                                                                                                                                </div>
                                                                                                                                                <div className='card-body d-flex justify-content-between align-items-center'>
                                                                                                                                                    {
                                                                                                                                                        dateOverrides[data].active ?
                                                                                                                                                            <>
                                                                                                                                                                <div className='d-flex flex-column align-items-start justify-content-center'>
                                                                                                                                                                    {
                                                                                                                                                                        dateOverrides[data].timeSet.map((time, key) => {
                                                                                                                                                                            return (
                                                                                                                                                                                <React.Fragment key={key}>
                                                                                                                                                                                    <button disabled className='btn btn-secondary border-0 mb-1 w-100'>
                                                                                                                                                                                        {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(user.settings.timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                                                                                                                                    </button>
                                                                                                                                                                                </React.Fragment>
                                                                                                                                                                            )
                                                                                                                                                                        })
                                                                                                                                                                    }
                                                                                                                                                                </div>
                                                                                                                                                            </>
                                                                                                                                                            :
                                                                                                                                                            <>
                                                                                                                                                                <span className='text-muted'>unavailable</span>
                                                                                                                                                            </>
                                                                                                                                                    }
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        )
                                                                                                                                    })
                                                                                                                                    :
                                                                                                                                    <p className='text-center'>No overrides for now</p>
                                                                                                                                :
                                                                                                                                <p className='text-center'>No overrides for now</p>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className={`row mb-3 ${customScheduleView ? "" : "d-none"}`} >
                                                                                                <div className="col-12">

                                                                                                    {/* ######################## NEW SCHEDULE SYSTEM ROUND ROBIN ######################### */}

                                                                                                    <div className="">
                                                                                                        <div className="mb-3">
                                                                                                            <p className='smallTextNote p-3 m-0'>Choose a schedule below to edit or create a new one that you can apply to your event types</p>
                                                                                                            <div className="conatiner-fluid border p-3 rounded insightBackground">
                                                                                                                <div className="row mb-3">
                                                                                                                    <div className="col">
                                                                                                                        <span style={{ fontWeight: "600" }}>SCHEDULE TEMPLATES</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="row">
                                                                                                                    <div className="col">
                                                                                                                        <div className='d-flex flex-wrap align-items-center gap-2'>
                                                                                                                            {
                                                                                                                                userSchedules ?
                                                                                                                                    Object.keys(userSchedules).map((name, key) => {
                                                                                                                                        if (scheduleName === name) {
                                                                                                                                            return <button key={key} type="button" className='btn salesforce-style rounded-pill border shadow-sm' onClick={() => { toogleScheduleSet(name); setScheduleName(name); }}>{name}</button>
                                                                                                                                        } else {
                                                                                                                                            return <button key={key} type="button" className='btn btn-outline-dark rounded-pill border shadow-sm' onClick={() => { toogleScheduleSet(name); setScheduleName(name); }}>{name}</button>
                                                                                                                                        }
                                                                                                                                    })
                                                                                                                                    :
                                                                                                                                    ""
                                                                                                                            }
                                                                                                                            <button type='button' className='btn btn-outline-dark rounded-pill border shadow-sm' onClick={() => { setCreateNewSchedule(true); setDateOverrides({}); }}>+ New Schedule</button>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="rounded text-dark mt-3 border">
                                                                                                                <div className="border-bottom p-3">
                                                                                                                    <div className="container-fluid">
                                                                                                                        <div className="row mb-3">
                                                                                                                            <div className="col p-0">
                                                                                                                                <div className='w-100 d-flex justify-content-between align-items-center'>
                                                                                                                                    <span className='text-capitalize' style={{ fontWeight: "600" }}>{scheduleName ? scheduleName : "Working Hours"}</span>
                                                                                                                                    <OverlayTrigger
                                                                                                                                        placement="left"
                                                                                                                                        delay={{ show: 100, hide: 200 }}
                                                                                                                                        overlay={
                                                                                                                                            <Popover id="popover-basic">
                                                                                                                                                <Popover.Header className='salesforce-style' as="h3">Delete Schedule</Popover.Header>
                                                                                                                                                <Popover.Body>
                                                                                                                                                    <p className='p-0 m-0 helpTextSize'>By clicking this you can delete this schedule template.</p>
                                                                                                                                                </Popover.Body>
                                                                                                                                            </Popover>
                                                                                                                                        }>
                                                                                                                                        <i
                                                                                                                                            className='fa fa-trash cursor-pointer'
                                                                                                                                            onClick={() => {
                                                                                                                                                setCustomAlertBox(true);
                                                                                                                                                setAlertBoxMessages(<p>Do you want to delete this schedule <br />"{scheduleName}"?</p>);
                                                                                                                                                setAlertErrorType("queryDelSchedule");

                                                                                                                                            }}
                                                                                                                                        ></i>
                                                                                                                                    </OverlayTrigger>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="row mb-3">
                                                                                                                            <div className="col-12 col-lg-6 p-0">
                                                                                                                                <div className='d-flex flex-column align-items-start gap-3 h-100 w-100'>

                                                                                                                                    <div className='d-flex align-items-start flex-column justify-content-between gap-1 h-100 w-100'>
                                                                                                                                        <label htmlFor='timezoneGds'>Country *</label>
                                                                                                                                        <div className='w-100'>
                                                                                                                                            <Select options={countriesList} value={countrySchedule} onChange={changeHandlerSchedule} />
                                                                                                                                        </div>
                                                                                                                                    </div>

                                                                                                                                    <div className='d-flex align-items-start flex-column justify-content-between gap-1 h-100 w-100'>
                                                                                                                                        <label htmlFor='timezoneGds'>Time Zone</label>
                                                                                                                                        <div className='w-100'>
                                                                                                                                            <Select value={[selectedTimezone]} options={filteredTimezonesListSchedule} onChange={(e) => { setSelectedTimezone(e); }} />
                                                                                                                                        </div>
                                                                                                                                    </div>

                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="col-12 col-lg-6">

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="p-3">
                                                                                                                    <div className="container-fluid">
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-12 col-md-12 col-lg-7 border rounded shadow-sm insightBackground">
                                                                                                                                <p className='text-center pt-4' style={{ fontWeight: "600" }}>Set your weekly hours</p>
                                                                                                                                <div className="container-fluid p-0">
                                                                                                                                    <div className="row mb-4">
                                                                                                                                        <div className="col-3">
                                                                                                                                            <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                                                                                                <label className='d-flex gap-1 align-items-center justify-content-start'>
                                                                                                                                                    <div className="outerToggleSwitch" onClick={() => { setIsSundayActive(!isSundayActive); checkDayOnToggle(!isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, "RoundRobin"); }}>
                                                                                                                                                        <div className={`frameOfToggleSwitch ${isSundayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                                                                            <span>On</span>
                                                                                                                                                            <div className="toggleSwitchBall"></div>
                                                                                                                                                            <span>Off</span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <span className='smallTextNote'>SUN</span>
                                                                                                                                                </label>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-7">
                                                                                                                                            {
                                                                                                                                                isSundayActive ?
                                                                                                                                                    <div className='m-0 p-0' id="sundayFrameRoundRobin">
                                                                                                                                                        {
                                                                                                                                                            schedule.sunday.times.length > 0 ?
                                                                                                                                                                schedule.sunday.times.map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"sundayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="sundayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote sundayStartTabsRoundRobin' />
                                                                                                                                                                                <datalist id="sundayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="sundayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote sundayEndTabsRoundRobin' />
                                                                                                                                                                                <datalist id="sundayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("sunday", "RoundRobin", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                                :
                                                                                                                                                                ["9:00am-5:00pm"].map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"sundayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="sundayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote sundayStartTabsRoundRobin' />
                                                                                                                                                                                <datalist id="sundayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="sundayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote sundayEndTabsRoundRobin' />
                                                                                                                                                                                <datalist id="sundayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("sunday", "RoundRobin", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                        }

                                                                                                                                                    </div>
                                                                                                                                                    :
                                                                                                                                                    <span className='smallTextNote'>Unavailable</span>
                                                                                                                                            }
                                                                                                                                        </div>
                                                                                                                                        <div className="col-2">
                                                                                                                                            <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                                                                                                <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("sunday", "RoundRobin", uuidv4()); }}></i>
                                                                                                                                                <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("sunday", "RoundRobin"); }} />
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="row mb-4">
                                                                                                                                        <div className="col-3">
                                                                                                                                            <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                                                                                                <label className='d-flex gap-1 align-items-center justify-content-start'>
                                                                                                                                                    <div className="outerToggleSwitch" onClick={() => { setIsMondayActive(!isMondayActive); checkDayOnToggle(isSundayActive, !isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, "RoundRobin"); }}>
                                                                                                                                                        <div className={`frameOfToggleSwitch ${isMondayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                                                                            <span>On</span>
                                                                                                                                                            <div className="toggleSwitchBall"></div>
                                                                                                                                                            <span>Off</span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <span className='smallTextNote'>MON</span>
                                                                                                                                                </label>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-7">

                                                                                                                                            {
                                                                                                                                                isMondayActive ?
                                                                                                                                                    <div className='m-0 p-0' id="mondayFrameRoundRobin">
                                                                                                                                                        {
                                                                                                                                                            schedule.monday.times.length > 0 ?
                                                                                                                                                                schedule.monday.times.map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"mondayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="mondayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote mondayStartTabsRoundRobin' />
                                                                                                                                                                                <datalist id="mondayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="mondayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote mondayEndTabsRoundRobin' />
                                                                                                                                                                                <datalist id="mondayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("monday", "RoundRobin", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                                :
                                                                                                                                                                ["9:00am-5:00pm"].map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"mondayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="mondayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote mondayStartTabsRoundRobin' />
                                                                                                                                                                                <datalist id="mondayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="mondayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote mondayEndTabsRoundRobin' />
                                                                                                                                                                                <datalist id="mondayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("monday", "RoundRobin", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                    :
                                                                                                                                                    <span className='smallTextNote'>Unavailable</span>
                                                                                                                                            }

                                                                                                                                        </div>
                                                                                                                                        <div className="col-2">
                                                                                                                                            <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                                                                                                <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("monday", "RoundRobin", uuidv4()); }}></i>
                                                                                                                                                <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("monday", "RoundRobin"); }} />
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="row mb-4">
                                                                                                                                        <div className="col-3">
                                                                                                                                            <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                                                                                                <label className='d-flex gap-1 align-items-center justify-content-start'>
                                                                                                                                                    <div className="outerToggleSwitch" onClick={() => { setIsTuesdayActive(!isTuesdayActive); checkDayOnToggle(isSundayActive, isMondayActive, !isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, "RoundRobin"); }}>
                                                                                                                                                        <div className={`frameOfToggleSwitch ${isTuesdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                                                                            <span>On</span>
                                                                                                                                                            <div className="toggleSwitchBall"></div>
                                                                                                                                                            <span>Off</span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <span className='smallTextNote'>TUE</span>
                                                                                                                                                </label>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-7">
                                                                                                                                            {
                                                                                                                                                isTuesdayActive ?
                                                                                                                                                    <div className='m-0 p-0' id="tuesdayFrameRoundRobin">
                                                                                                                                                        {
                                                                                                                                                            schedule.tuesday.times.length > 0 ?
                                                                                                                                                                schedule.tuesday.times.map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"tuesdayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="tuesdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote tuesdayStartTabsRoundRobin' />
                                                                                                                                                                                <datalist id="tuesdayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="tuesdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote tuesdayEndTabsRoundRobin' />
                                                                                                                                                                                <datalist id="tuesdayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("tuesday", "RoundRobin", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                                :
                                                                                                                                                                ["9:00am-5:00pm"].map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"tuesdayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="tuesdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote tuesdayStartTabsRoundRobin' />
                                                                                                                                                                                <datalist id="tuesdayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="tuesdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote tuesdayEndTabsRoundRobin' />
                                                                                                                                                                                <datalist id="tuesdayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("tuesday", "RoundRobin", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                    :
                                                                                                                                                    <span className='smallTextNote'>Unavailable</span>
                                                                                                                                            }

                                                                                                                                        </div>
                                                                                                                                        <div className="col-2">
                                                                                                                                            <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                                                                                                <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("tuesday", "RoundRobin", uuidv4()); }}></i>
                                                                                                                                                <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("tuesday", "RoundRobin"); }} />
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="row mb-4">
                                                                                                                                        <div className="col-3">
                                                                                                                                            <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                                                                                                <label className='d-flex gap-1 align-items-center justify-content-start'>
                                                                                                                                                    <div className="outerToggleSwitch" onClick={() => { setIsWednesdayActive(!isWednesdayActive); checkDayOnToggle(isSundayActive, isMondayActive, isTuesdayActive, !isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, "RoundRobin"); }}>
                                                                                                                                                        <div className={`frameOfToggleSwitch ${isWednesdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                                                                            <span>On</span>
                                                                                                                                                            <div className="toggleSwitchBall"></div>
                                                                                                                                                            <span>Off</span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <span className='smallTextNote'>WED</span>
                                                                                                                                                </label>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-7">
                                                                                                                                            {
                                                                                                                                                isWednesdayActive ?
                                                                                                                                                    <div className='m-0 p-0' id="wednesdayFrameRoundRobin">
                                                                                                                                                        {
                                                                                                                                                            schedule.wednesday.times.length > 0 ?
                                                                                                                                                                schedule.wednesday.times.map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"wednesdayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="wednesdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote wednesdayStartTabsRoundRobin' />
                                                                                                                                                                                <datalist id="wednesdayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="wednesdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote wednesdayEndTabsRoundRobin' />
                                                                                                                                                                                <datalist id="wednesdayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("wednesday", "RoundRobin", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                                :
                                                                                                                                                                ["9:00am-5:00pm"].map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"wednesdayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="wednesdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote wednesdayStartTabsRoundRobin' />
                                                                                                                                                                                <datalist id="wednesdayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="wednesdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote wednesdayEndTabsRoundRobin' />
                                                                                                                                                                                <datalist id="wednesdayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("wednesday", "RoundRobin", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                    :
                                                                                                                                                    <span className='smallTextNote'>Unavailable</span>
                                                                                                                                            }

                                                                                                                                        </div>
                                                                                                                                        <div className="col-2">
                                                                                                                                            <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                                                                                                <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("wednesday", "RoundRobin", uuidv4()); }}></i>
                                                                                                                                                <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("wednesday", "RoundRobin"); }} />
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="row mb-4">
                                                                                                                                        <div className="col-3">
                                                                                                                                            <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                                                                                                <label className='d-flex gap-1 align-items-center justify-content-start'>
                                                                                                                                                    <div className="outerToggleSwitch" onClick={() => { setIsThursdayActive(!isThursdayActive); checkDayOnToggle(isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, !isThursdayActive, isFridayActive, isSaturdayActive, "RoundRobin"); }}>
                                                                                                                                                        <div className={`frameOfToggleSwitch ${isThursdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                                                                            <span>On</span>
                                                                                                                                                            <div className="toggleSwitchBall"></div>
                                                                                                                                                            <span>Off</span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <span className='smallTextNote'>THU</span>
                                                                                                                                                </label>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-7">
                                                                                                                                            {
                                                                                                                                                isThursdayActive ?
                                                                                                                                                    <div className='m-0 p-0' id="thursdayFrameRoundRobin">
                                                                                                                                                        {
                                                                                                                                                            schedule.thursday.times.length > 0 ?
                                                                                                                                                                schedule.thursday.times.map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"thursdayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="thursdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote thursdayStartTabsRoundRobin' />
                                                                                                                                                                                <datalist id="thursdayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="thursdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote thursdayEndTabsRoundRobin' />
                                                                                                                                                                                <datalist id="thursdayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("thursday", "RoundRobin", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                                :
                                                                                                                                                                ["9:00am-5:00pm"].map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"thursdayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="thursdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote thursdayStartTabsRoundRobin' />
                                                                                                                                                                                <datalist id="thursdayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="thursdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote thursdayEndTabsRoundRobin' />
                                                                                                                                                                                <datalist id="thursdayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("thursday", "RoundRobin", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                    :
                                                                                                                                                    <span className='smallTextNote'>Unavailable</span>
                                                                                                                                            }

                                                                                                                                        </div>
                                                                                                                                        <div className="col-2">
                                                                                                                                            <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                                                                                                <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("thursday", "RoundRobin", uuidv4()); }}></i>
                                                                                                                                                <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("thursday", "RoundRobin"); }} />
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="row mb-4">
                                                                                                                                        <div className="col-3">
                                                                                                                                            <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                                                                                                <label className='d-flex gap-1 align-items-center justify-content-start'>
                                                                                                                                                    <div className="outerToggleSwitch" onClick={() => { setIsFridayActive(!isFridayActive); checkDayOnToggle(isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, !isFridayActive, isSaturdayActive, "RoundRobin"); }}>
                                                                                                                                                        <div className={`frameOfToggleSwitch ${isFridayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                                                                            <span>On</span>
                                                                                                                                                            <div className="toggleSwitchBall"></div>
                                                                                                                                                            <span>Off</span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <span className='smallTextNote'>FRI</span>
                                                                                                                                                </label>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-7">
                                                                                                                                            {
                                                                                                                                                isFridayActive ?
                                                                                                                                                    <div className='m-0 p-0' id="fridayFrameRoundRobin">
                                                                                                                                                        {
                                                                                                                                                            schedule.friday.times.length > 0 ?
                                                                                                                                                                schedule.friday.times.map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"fridayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="fridayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote fridayStartTabsRoundRobin' />
                                                                                                                                                                                <datalist id="fridayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="fridayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote fridayEndTabsRoundRobin' />
                                                                                                                                                                                <datalist id="fridayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("friday", "RoundRobin", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                                :
                                                                                                                                                                ["9:00am-5:00pm"].map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"fridayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="fridayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote fridayStartTabsRoundRobin' />
                                                                                                                                                                                <datalist id="fridayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="fridayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote fridayEndTabsRoundRobin' />
                                                                                                                                                                                <datalist id="fridayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("friday", "RoundRobin", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                    :
                                                                                                                                                    <span className='smallTextNote'>Unavailable</span>
                                                                                                                                            }
                                                                                                                                        </div>
                                                                                                                                        <div className="col-2">
                                                                                                                                            <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                                                                                                <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("friday", "RoundRobin", uuidv4()); }}></i>
                                                                                                                                                <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("friday", "RoundRobin"); }} />
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="row mb-4">
                                                                                                                                        <div className="col-3">
                                                                                                                                            <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                                                                                                <label className='d-flex gap-1 align-items-center justify-content-start'>
                                                                                                                                                    <div className="outerToggleSwitch" onClick={() => { setIsSaturdayActive(!isSaturdayActive); checkDayOnToggle(isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, !isSaturdayActive, "RoundRobin"); }}>
                                                                                                                                                        <div className={`frameOfToggleSwitch ${isSaturdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                                                                            <span>On</span>
                                                                                                                                                            <div className="toggleSwitchBall"></div>
                                                                                                                                                            <span>Off</span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <span className='smallTextNote'>SAT</span>
                                                                                                                                                </label>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-7">
                                                                                                                                            {
                                                                                                                                                isSaturdayActive ?
                                                                                                                                                    <div className='m-0 p-0' id="saturdayFrameRoundRobin">
                                                                                                                                                        {
                                                                                                                                                            schedule.saturday.times.length > 0 ?
                                                                                                                                                                schedule.saturday.times.map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"saturdayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="saturdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote saturdayStartTabsRoundRobin' />
                                                                                                                                                                                <datalist id="saturdayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="saturdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote saturdayEndTabsRoundRobin' />
                                                                                                                                                                                <datalist id="saturdayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("saturday", "RoundRobin", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                                :
                                                                                                                                                                ["9:00am-5:00pm"].map((sadn, key) => {
                                                                                                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                                                                                                    const uid = uuidv4();
                                                                                                                                                                    return (
                                                                                                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"saturdayTimeFrame_" + uid}>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="saturdayStart" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote saturdayStartTabsRoundRobin' />
                                                                                                                                                                                <datalist id="saturdayStart" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div>
                                                                                                                                                                                <input list="saturdayEnd" defaultValue={user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "RoundRobin"); checkUpdatedStateOfTimeValidation("RoundRobin"); }} maxLength={7} name="activeOn" className='customInputFieldFocus w-100 smallTextNote saturdayEndTabsRoundRobin' />
                                                                                                                                                                                <datalist id="saturdayEnd" className='smallTextNote'>
                                                                                                                                                                                    {
                                                                                                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                                                                                                            let timeFile = user.settings.timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : times
                                                                                                                                                                                            return (
                                                                                                                                                                                                <option key={key} value={timeFile}>
                                                                                                                                                                                                    {timeFile}
                                                                                                                                                                                                </option>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                </datalist>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("saturday", "RoundRobin", uid); }}></i>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                    :
                                                                                                                                                    <span className='smallTextNote'>Unavailable</span>
                                                                                                                                            }

                                                                                                                                        </div>
                                                                                                                                        <div className="col-2">
                                                                                                                                            <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                                                                                                <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("saturday", "RoundRobin", uuidv4()); }}></i>
                                                                                                                                                <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("saturday", "RoundRobin"); }} />
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="col-12 col-lg-1 d-lg-flex py-2"></div>
                                                                                                                            <div className="col-12 col-md-12 col-lg-4 border rounded shadow-sm insightBackground">
                                                                                                                                <div className="alert d-flex flex-column align-items-center">
                                                                                                                                    <p className='text-center' style={{ fontWeight: "600" }}>Date overrides</p>
                                                                                                                                    <button type='button' className='btn btn-sm rounded-pill salesforce-style px-3 d-flex align-items-center' onClick={() => { setViewDateOverridePanel(true); }}>
                                                                                                                                        <AddIcon /> Add date override
                                                                                                                                    </button>
                                                                                                                                </div>
                                                                                                                                {
                                                                                                                                    dateOverrides ?
                                                                                                                                        Object.keys(dateOverrides).length > 0 ?
                                                                                                                                            Object.keys(dateOverrides).map((data, key) => {
                                                                                                                                                return (
                                                                                                                                                    <div key={key} className="card mb-2" id={`datesOverrides_${dateOverrides[data].uid}_RoundRobin`}>
                                                                                                                                                        <div className='card-header salesforce-style d-flex align-items-center justify-content-between'>
                                                                                                                                                            <span>{dayjs(dateOverrides[data].date).format("ddd, D MMM YYYY")}</span>
                                                                                                                                                            <span><i className='fa fa-trash' style={{ padding: "0 5px" }} id={dateOverrides[data].uid} onClick={(e) => { deleteOverrideDate(dateOverrides[data].date, `datesOverrides_${dateOverrides[data].uid}_RoundRobin`); }}></i></span>
                                                                                                                                                        </div>
                                                                                                                                                        <div className='card-body d-flex justify-content-between align-items-center'>
                                                                                                                                                            {
                                                                                                                                                                dateOverrides[data].active ?
                                                                                                                                                                    <>
                                                                                                                                                                        <div className='d-flex flex-column align-items-start justify-content-center'>
                                                                                                                                                                            {
                                                                                                                                                                                dateOverrides[data].timeSet.map((time, key) => {
                                                                                                                                                                                    const set = time.split("-");
                                                                                                                                                                                    return (
                                                                                                                                                                                        <React.Fragment key={key}>
                                                                                                                                                                                            <button disabled className='btn btn-secondary border-0 mb-1 w-100'>
                                                                                                                                                                                                {
                                                                                                                                                                                                    user.settings.timeFormat.value === "12 Hours" ?
                                                                                                                                                                                                        `${dayjs(`2 November 1999 ${set[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")}-${dayjs(`2 November 1999 ${set[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")}`
                                                                                                                                                                                                        :
                                                                                                                                                                                                        `${dayjs(`2 November 1999 ${set[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm")}-${dayjs(`2 November 1999 ${set[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm")}`
                                                                                                                                                                                                }
                                                                                                                                                                                            </button>
                                                                                                                                                                                        </React.Fragment>
                                                                                                                                                                                    )
                                                                                                                                                                                })
                                                                                                                                                                            }
                                                                                                                                                                        </div>
                                                                                                                                                                    </>
                                                                                                                                                                    :
                                                                                                                                                                    <>
                                                                                                                                                                        <span className='smallTextNote'>Unavailable</span>
                                                                                                                                                                    </>
                                                                                                                                                            }
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                )
                                                                                                                                            })
                                                                                                                                            :
                                                                                                                                            <p className='text-center'>No overrides for now</p>
                                                                                                                                        :
                                                                                                                                        <p className='text-center'>No overrides for now</p>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                        <div className="card-footer d-flex align-items-center justify-content-center gap-3">
                                                                                                            {/* <button style={{ maxWidth: "120px" }} className='w-100 text-center btn btn-secondary rounded-pill py-1' type='button' onClick={() => { setCreateNewSchedule(false); setSchedule(defaultSchedule); setDateOverrides(defaultOverride); window.location.reload(); }}>Cancel</button> */}
                                                                                                            <button style={{ maxWidth: "max-content" }} className='w-100 text-center btn salesforce-style rounded-pill py-1' id="saveSchedule3" type='button' disabled={!updateButtonEnabled || !isCustomLinkAvailable} onClick={(e) => { updateNewSchedule(e, "RoundRobin"); }}>Save Schedule</button>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    {/* ##################### END OF NEW SCHEDULE SYSTEM ROUND ROBIN ###################### */}
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="row mb-3">
                                                                                                <div className="col-12 col-md-6 col-lg-6 mb-4">
                                                                                                    <div className="container-fluid border insightBackground rounded" style={{ position: "relative", zIndex: "3" }}>
                                                                                                        <div className="row">
                                                                                                            <div className="col-12">
                                                                                                                <span style={{ fontWeight: "500" }} className='my-3 d-block'>Want to add time before or after your events?</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="row mb-2">
                                                                                                            <div className="col-12 d-flex align-items-center gap-4 py-2">
                                                                                                                {
                                                                                                                    eventBeforeTimeActive && (
                                                                                                                        <input type="checkbox" defaultChecked onClick={() => { setEventBeforeTimeActive(false); }} className='form-check-input checkboxInpage' />
                                                                                                                    )
                                                                                                                }
                                                                                                                {
                                                                                                                    eventBeforeTimeActive === false && (
                                                                                                                        <input type="checkbox" onClick={() => { setEventBeforeTimeActive(true); }} className='form-check-input checkboxInpage' />
                                                                                                                    )
                                                                                                                }
                                                                                                                <div className="">
                                                                                                                    <h6 className={`${eventBeforeTimeActive ? "" : "text-muted"}`}>
                                                                                                                        Before Event
                                                                                                                    </h6>
                                                                                                                    {/* <select disabled={eventBeforeTimeActive ? false : true} className='' onChange={(e) => { setEventBeforeTime(e.target.value); }} value={eventBeforeTime}>
                                                                                                                        <option value="5">5 min</option>
                                                                                                                        <option value="10">10 min</option>
                                                                                                                        <option value="15">15 min</option>
                                                                                                                        <option value="30">30 min</option>
                                                                                                                        <option value="45">45 min</option>
                                                                                                                        <option value="60">1 hr</option>
                                                                                                                        <option value="90">1 hr 30 min</option>
                                                                                                                        <option value="120">2 hr</option>
                                                                                                                        <option value="150">2 hr 30 min</option>
                                                                                                                        <option value="180">3 hr</option>
                                                                                                                    </select> */}

                                                                                                                    {/* <DropdownButton
                                                                                                                    as="ButtonGroup"
                                                                                                                    className='w-100'
                                                                                                                    id={`dropdown-button-drop-down`}
                                                                                                                    drop={"down"}
                                                                                                                    variant="light"
                                                                                                                    disabled={eventBeforeTimeActive ? false : true}
                                                                                                                    title={
                                                                                                                        eventBeforeTime > 60 ?
                                                                                                                            Math.floor(eventBeforeTime % 60) === 0 ?
                                                                                                                                Math.floor(eventBeforeTime / 60) + " hrs"
                                                                                                                                : Math.floor(eventBeforeTime / 60) + " hrs, " + Math.floor(eventBeforeTime % 60) + " min"
                                                                                                                            : Math.floor(eventBeforeTime) + " min"
                                                                                                                    }
                                                                                                                    onSelect={(e, event) => { setEventBeforeTime(e); }}

                                                                                                                >
                                                                                                                    <Dropdown.Item eventKey="15">15 minutes</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="30">30 minutes</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="45">45 minutes</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="60">1 hr</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="90">1 hr 30 min</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="120">2 hr</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="150">2 hr 30 min</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="180">3 hr</Dropdown.Item>
                                                                                                                </DropdownButton> */}
                                                                                                                    {
                                                                                                                        selectedEventBeforeTimeFromList ?
                                                                                                                            <Select
                                                                                                                                isDisabled={eventBeforeTimeActive ? false : true}
                                                                                                                                options={addTimeAfterBeforeForSelect}
                                                                                                                                defaultValue={selectedEventBeforeTimeFromList}
                                                                                                                                onChange={(e) => { setEventBeforeTime(e.value) }}
                                                                                                                            />
                                                                                                                            : ""
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="row mb-2">
                                                                                                            <div className="col-12 d-flex align-items-center gap-4 py-2">
                                                                                                                <div>
                                                                                                                    {
                                                                                                                        eventAfterTimeActive && (
                                                                                                                            <input type="checkbox" defaultChecked onClick={() => { setEventAfterTimeActive(false); }} className='form-check-input checkboxInpage' />
                                                                                                                        )
                                                                                                                    }
                                                                                                                    {
                                                                                                                        eventAfterTimeActive === false && (
                                                                                                                            <input type="checkbox" onClick={() => { setEventAfterTimeActive(true); }} className='form-check-input checkboxInpage' />
                                                                                                                        )
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <h6 className={`${eventAfterTimeActive ? "" : "text-muted"}`}>
                                                                                                                        After Event
                                                                                                                    </h6>
                                                                                                                    {/* <select disabled={eventAfterTimeActive ? false : true} className='' onChange={(e) => { setEventAfterTime(e.target.value); }} value={eventAfterTime}>
                                                                                                                        <option value="5">5 min</option>
                                                                                                                        <option value="10">10 min</option>
                                                                                                                        <option value="15">15 min</option>
                                                                                                                        <option value="30">30 min</option>
                                                                                                                        <option value="45">45 min</option>
                                                                                                                        <option value="60">1 hr</option>
                                                                                                                        <option value="90">1 hr 30 min</option>
                                                                                                                        <option value="120">2 hr</option>
                                                                                                                        <option value="150">2 hr 30 min</option>
                                                                                                                        <option value="180">3 hr</option>
                                                                                                                    </select> */}
                                                                                                                    {/* <DropdownButton
                                                                                                                    as="ButtonGroup"
                                                                                                                    className='w-100'
                                                                                                                    id={`dropdown-button-drop-down`}
                                                                                                                    drop={"down"}
                                                                                                                    variant="light"
                                                                                                                    disabled={eventAfterTimeActive ? false : true}
                                                                                                                    title={
                                                                                                                        eventAfterTime > 60 ?
                                                                                                                            Math.floor(eventAfterTime % 60) === 0 ?
                                                                                                                                Math.floor(eventAfterTime / 60) + " hrs"
                                                                                                                                : Math.floor(eventAfterTime / 60) + " hrs, " + Math.floor(eventAfterTime % 60) + " min"
                                                                                                                            : Math.floor(eventAfterTime) + " min"
                                                                                                                    }
                                                                                                                    onSelect={(e, event) => { setEventAfterTime(e); }}

                                                                                                                >
                                                                                                                    <Dropdown.Item eventKey="15">15 minutes</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="30">30 minutes</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="45">45 minutes</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="60">1 hr</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="90">1 hr 30 min</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="120">2 hr</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="150">2 hr 30 min</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="180">3 hr</Dropdown.Item>
                                                                                                                </DropdownButton> */}
                                                                                                                    {
                                                                                                                        selectedEventAfterTimeFromList ?
                                                                                                                            <Select
                                                                                                                                isDisabled={eventAfterTimeActive ? false : true}
                                                                                                                                options={addTimeAfterBeforeForSelect}
                                                                                                                                defaultValue={selectedEventAfterTimeFromList}
                                                                                                                                onChange={(e) => { setEventAfterTime(e.value) }}
                                                                                                                            />
                                                                                                                            :
                                                                                                                            ""
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-12 col-md-5 col-lg-5 d-flex flex-column">
                                                                                                    <p className='mediumTextSize'>Give yourself some buffer time to prepare for or wrap up from booked Event.</p>
                                                                                                    <div className="rounded h-100">
                                                                                                        <div className="card rounded overflow-hidden insightBackground">
                                                                                                            <div className={`card-header p-1 ${eventBeforeTimeActive ? "salesforce-style" : ""}`}></div>
                                                                                                            <div className="card-body p-0">
                                                                                                                <button type='button' className='btn insightBackground w-100 text-break'>{eventName}</button>
                                                                                                            </div>
                                                                                                            <div className={`card-footer p-1 ${eventAfterTimeActive ? "salesforce-style" : ""}`}></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="row mb-3">
                                                                                                <div className="col-12 col-md-6 col-lg-6 d-flex align-items-center gap-2 mb-2">
                                                                                                    <div className='border insightBackground rounded p-3 w-100'>
                                                                                                        <div className='mb-3'>
                                                                                                            <span style={{ fontWeight: "500" }}>Secret Event</span>
                                                                                                            <p className='m-0 p-0 mediumTextSize'>Use this to hide the event on your main scheduling page.</p>
                                                                                                        </div>
                                                                                                        <div className='mb-2 d-flex align-items-center justify-content-start gap-2'>
                                                                                                            {
                                                                                                                secretEvent && (
                                                                                                                    <input type="checkbox" defaultChecked onClick={() => { setSecretEvent(false); }} className='form-check-input checkboxInpage' />
                                                                                                                )
                                                                                                            }
                                                                                                            {
                                                                                                                secretEvent === false && (
                                                                                                                    <input type="checkbox" onClick={() => { setSecretEvent(true); }} className='form-check-input checkboxInpage' />
                                                                                                                )
                                                                                                            }
                                                                                                            <span className={`${secretEvent ? "" : "text-muted"} mediumTextSize`} style={{ fontWeight: "500" }}>
                                                                                                                Make this a Secret Event
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='col-12 col-md-6 col-lg-6'>
                                                                                                    <div className='mediumTextSize w-100'>
                                                                                                        <span style={{ fontWeight: "500" }}> Note:</span> Only Invitees who have this Schedule AI link can schedule with you.
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            {/* #################################### Additional Settings Started ############################################### */}

                                                                                            <div className="row mb-3">
                                                                                                <div className="col-12 col-md-6 col-lg-6">
                                                                                                    <span style={{ fontWeight: "500", fontSize: "1.2rem" }}>Additional Settings</span>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="row mb-3">
                                                                                                <div className="col-12 col-md-6 col-lg-6">
                                                                                                    <div className='insightBackground border p-3 rounded' style={{ position: "relative", zIndex: "2" }}>
                                                                                                        <span className='d-block mb-2' style={{ fontWeight: "500" }}>Stop Bookings before:</span>

                                                                                                        {
                                                                                                            selectedStopBookingBeforeLabel ?
                                                                                                                <Select
                                                                                                                    options={stopBookingBeforeListForSelect}
                                                                                                                    defaultValue={selectedStopBookingBeforeLabel}
                                                                                                                    onChange={(e) => {
                                                                                                                        if (e.label !== "custom") {
                                                                                                                            setStopBookingTime([stopTimeForBookingType, e.value]);
                                                                                                                            setCustomStopBookingView(false);
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            setCustomStopBookingView(true);
                                                                                                                            setStopBookingTime([stopTimeForBookingType, 1]);
                                                                                                                        }
                                                                                                                    }}
                                                                                                                />
                                                                                                                :
                                                                                                                ""
                                                                                                        }
                                                                                                        {
                                                                                                            customStopBookingView &&
                                                                                                            <div className={`${customStopBookingView} d-flex justify-content-center align-items-center gap-2 my-2`}>
                                                                                                                {
                                                                                                                    stopTimeForBookingType === "hours" ?
                                                                                                                        <input className='customInputFieldFocus css-13cymwt-control w-100' required type="number" min="1" max="6" defaultValue={"1"} onChange={(e) => { setStopBookingTime(["hours", e.target.value]); }} />
                                                                                                                        :
                                                                                                                        <input className='customInputFieldFocus css-13cymwt-control w-100' required type="number" min="1" max="60" defaultValue={"1"} onChange={(e) => { setStopBookingTime(["days", e.target.value]); }} />
                                                                                                                }
                                                                                                                <Select
                                                                                                                    className='w-100'
                                                                                                                    options={[{ label: "hours", value: "hours" }]}
                                                                                                                    defaultValue={[{ label: "hours", value: "hours" }]}
                                                                                                                    onChange={(e) => { setStopTimeForBookingType(e.label); }}
                                                                                                                />

                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-12 col-md-6 col-lg-6">
                                                                                                    <div className='mediumTextSize w-100'>
                                                                                                        <span style={{ fontWeight: "500" }}>Note:</span> This feature can control the last minute booking problems. Set your desired time to stop bookings after that particular time.
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="row mb-3">
                                                                                                <div className="col-9 col-sm-9 col-md-9 col-lg-9">
                                                                                                    <span style={{ fontWeight: "500", fontSize: "1.2rem" }}>Questions for Invitees:</span>
                                                                                                </div>
                                                                                                <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                                                                                    <span className="mediumTextSize" style={{ fontWeight: "500" }}>Required</span>
                                                                                                    <OverlayTrigger
                                                                                                        placement="left"
                                                                                                        delay={{ show: 100, hide: 200 }}
                                                                                                        overlay={
                                                                                                            <Popover id="popover-basic">
                                                                                                                <Popover.Header className='salesforce-style' as="h3">Required Questions</Popover.Header>
                                                                                                                <Popover.Body>
                                                                                                                    <p className='p-0 m-0 mb-2 helpTextSize'>You can make a questions mandatory while your invitees book a meeting whith you by using this switch.</p>
                                                                                                                    <img src={requiredQuestionPng} className='w-100' alt="" />
                                                                                                                </Popover.Body>
                                                                                                            </Popover>
                                                                                                        }>
                                                                                                        <HelpOutlineIcon style={{ maxWidth: "15px" }} />
                                                                                                    </OverlayTrigger>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="row mb-3">
                                                                                                <div className="col-9 col-md-9 col-lg-9">
                                                                                                    <input type="text" className='customInputFieldFocus w-100' onChange={(e) => { setRoundRobinQuestions(e.target.value); }} value={roundRobinQuestions} placeholder='enter your question here?' />
                                                                                                </div>
                                                                                                <div className="col-3 col-md-3 col-lg-3 d-flex align-items-center">
                                                                                                    <div className="outerToggleSwitch" onClick={() => { setIsDescriptionRequired(!isDescriptionRequired); }}>
                                                                                                        <div className={`frameOfToggleSwitch ${isDescriptionRequired === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                                            <span>On</span>
                                                                                                            <div className="toggleSwitchBall"></div>
                                                                                                            <span>Off</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            {/* #################################### Additional Settings End ############################################### */}

                                                                                            <div className="row mb-3">
                                                                                                <div className="col d-flex align-items-center justify-content-center">
                                                                                                    <div className='d-flex justify-content-center gap-2 w-100'>
                                                                                                        <button type='button' style={{ maxWidth: "120px" }} className='btn btn-sm w-100 text-center btn-secondary rounded-pill' onClick={() => { setWhenEvent(false); window.location.reload(); }}>Cancel</button>
                                                                                                        <button type="submit" style={{ maxWidth: "120px" }} className='btn btn-sm w-100 text-center salesforce-style rounded-pill d-flex align-items-center justify-content-center gap-2' id="roundRobinEditButton2" disabled={!updateButtonEnabled || !isCustomLinkAvailable}>Update</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Accordion.Body>
                                                                                </Accordion.Item>
                                                                                {/* <Accordion.Item eventKey="3">
                                                                                <Accordion.Header>
                                                                                    One Header
                                                                                </Accordion.Header>
                                                                                <Accordion.Body className='bg-soul'>
                                                                                    One Body
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>
                                                                            <Accordion.Item eventKey="4">
                                                                                <Accordion.Header>
                                                                                    One Header
                                                                                </Accordion.Header>
                                                                                <Accordion.Body className='bg-soul'>
                                                                                    One Body
                                                                                </Accordion.Body>
                                                                            </Accordion.Item> */}
                                                                            </Accordion>
                                                                        </form>
                                                                    </>
                                                                )
                                                                :
                                                                ""
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    {/* ############################################################################################################ */}
                                    {/* CUSTOM ALERT BOX*/}
                                    <Modal
                                        show={customAlertBox}
                                        // show={true}
                                        size="md"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                        onHide={() => { setCustomAlertBox(false) }}
                                    >
                                        <Modal.Header className="salesforce-style py-2" closeButton closeVariant='white'>
                                            <Modal.Title id="contained-modal-title-vcenter">
                                                <span style={{ fontSize: "1rem" }}>{alertErrorType === "queryDelSchedule" ? "Delete Schedule" : alertErrorType === "queryDelAccount" ? "Delete Account" : alertErrorType === "queryDel" ? "Delete Event" : alertErrorType === "queryCanc" ? "Cancel Event" : ""}</span>
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className='d-flex flex-column justify-content-center align-items-center gap-3'>
                                                {/* <img className='alertImageType' src={
                                            alertErrorType === "invalid" ? invalidPng :
                                                alertErrorType === "queryDel" ? queryPng :
                                                    alertErrorType === "queryDelAccount" ? queryPng :
                                                        alertErrorType === "queryCanc" ? queryPng :
                                                            alertErrorType === "queryDelSchedule" ? queryPng :
                                                                successPng} alt="alert Image" /> */}
                                                <span className={`alertMessageContent text-break py-3 text-center ${alertErrorType === "queryCanc" ? "d-none" : ""}`}>
                                                    {
                                                        alertBoxMessages
                                                    }
                                                </span>
                                                <div className={`w-100 ${alertErrorType === "queryCanc" ? "" : "d-none"}`}>
                                                    {
                                                        cancelContents
                                                    }
                                                </div>
                                                {
                                                    alertErrorType === "queryDelSchedule" ?
                                                        (
                                                            <div className='d-flex justify-content-center align-items-center gap-3'>
                                                                <button className='btn salesforce-style px-5 text-center rounded-pill my-1' onClick={() => { startDeleteSchedule(scheduleName); }}>Yes</button>
                                                                <button className='btn btn-secondary px-5 text-center rounded-pill my-1' onClick={() => { setCustomAlertBox(false); }}>No</button>
                                                            </div>
                                                        )
                                                        :
                                                        (
                                                            <button className='btn salesforce-style px-5 text-center rounded-pill my-1' onClick={() => { setCustomAlertBox(false); }}>OK</button>
                                                        )
                                                }
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                    {/* ############################################################################################################ */}


                                    {/* ################################# Create New Schedule Panel ############################### */}
                                    <Modal
                                        show={createNewSchedule}
                                        size="md"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                        onHide={() => { setCreateNewSchedule(false); setNewScheduleName(""); }}
                                    >
                                        <Modal.Header className='salesforce-style py-2' closeButton closeVariant="white">
                                            <Modal.Title id="contained-modal-title-vcenter">
                                                <span style={{ fontSize: "1rem" }}>New schedule</span>
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <form className='w-100' onSubmit={(e) => { creatingNewDefaultSchedule(e); }}>
                                                <div className="container-fluid">
                                                    <div className="row mb-3">
                                                        <div className="col-12">
                                                            <label className='w-100'>
                                                                <p className='p-0 my-2 fw-bold'>Schedule name</p>
                                                                <input type="text" maxLength={30} className='customInputFieldFocus w-100 mb-2' value={newScheduleName} required placeholder='Working Hours, Holidays, etc...' onChange={(e) => { validateAndSetScheduleName(e); setScheduleNameCounter(true); }} />
                                                            </label>
                                                        </div>
                                                        <div className={`col-12 d-flex justify-content-between align-items-center}`}>
                                                            <p className='text-dark text-start smallTextSize'>Max length 30 chars.</p>
                                                            {
                                                                scheduleNameCounter
                                                                    ?
                                                                    <span className='smallTextSize'>{30 - newScheduleName.length} / 30</span>
                                                                    :
                                                                    <span className='smallTextSize'>30 / 30</span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 d-flex gap-3 justify-content-center align-items-center">
                                                            <button
                                                                type="reset"
                                                                style={{ maxWidth: "120px", width: "100%" }}
                                                                className='btn btn-sm btn-secondary w-100 rounded-pill'
                                                                onClick={() => {
                                                                    setNewScheduleName("");
                                                                    setCreateNewSchedule(false);
                                                                }}>Cancel</button>
                                                            <button type="submit" style={{ maxWidth: "120px", width: "100%" }} id="newSchedulePopup" className='btn btn-sm salesforce-style w-100 rounded-pill'>Add</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </Modal.Body>
                                        {/* <Modal.Footer></Modal.Footer> */}
                                    </Modal>
                                    {/* ############################### Create New Schedule Panel End ############################# */}



                                    {/* ####################################################### DATE OVERRIDE PANEL ############################################################# */}
                                    <Modal
                                        show={viewDateOverridePanel}
                                        onHide={() => { setViewDateOverridePanel(false); setMonthIndexCurrent(dayjs().month()); setCurrntOverrideDateSelected(null); setOverrideDate(null); }}
                                        size="md"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                    >
                                        <Modal.Header className='salesforce-style py-2' closeButton closeVariant="white">
                                            <Modal.Title id="contained-modal-title-vcenter">
                                                <span style={{ fontSize: "1rem" }}>ADD DATE OVERRIDE</span>
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <form onSubmit={(e) => { startAddingTheOverride(e); }} className="w-100 p-0 m-0">
                                                <div className='container-fluid'>
                                                    <div className="row">
                                                        <div className="col-12 p-0 fw-bold">
                                                            {/* <div className='d-flex align-items-center pt-3 pb-3 justify-content-between'>
                                                                
                                                                <button type='button' disabled={monthIndexCurrent <= dayjs().month()} onClick={() => { setMonthIndexCurrent(monthIndexCurrent - 1); }} className={`${monthIndexCurrent <= dayjs().month() ? "cursor-not-allowed" : "cursor-pointer"} salesforce-style circleBbutton p-1 text-dark bg-light rounded-pill fw-bold`}>
                                                                    <ArrowBackIcon />
                                                                </button>
                                                                <span className='monthAndDate'>
                                                                    {dayjs(new Date(dayjs().year(), monthIndexCurrent)).format("MMMM YYYY")}
                                                                </span>
                                                                <button type='button' onClick={() => { setMonthIndexCurrent(monthIndexCurrent + 1); }} className='salesforce-style circleBbutton p-1 text-dark bg-light rounded-pill fw-bold'>
                                                                    <ArrowForwardIcon />
                                                                </button>
                                                            </div> */}
                                                            <div className="d-flex justify-content-center w-100 transparentCalendar text-dark">
                                                                <Calendar
                                                                    showSelectionPreview={true}
                                                                    shownDate={new Date()}
                                                                    minDate={new Date()}
                                                                    maxDate={undefined}
                                                                    color='#0b5cab'
                                                                    date={overrideDate}
                                                                    onChange={(e) => { setOverrideDate(e) }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className={`col-12 ${overrideDate ? "" : "d-none"}`}>
                                                            <hr />
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <span>
                                                                    <b>{dayjs(overrideDate).format("ddd, D MMM YYYY")}</b>
                                                                </span>
                                                                <div className="outerToggleSwitch" onClick={() => { setOverrideDateEnabled(!overrideDateEnabled); checkOverridesTimesCorrect(); }}>
                                                                    <div className={`frameOfToggleSwitch ${overrideDateEnabled ? "slideRightToogle" : "slideLeftToogle"}`}>
                                                                        <span>On</span>
                                                                        <div className="toggleSwitchBall"></div>
                                                                        <span>Off</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {
                                                                existingOverride && overrideDateEnabled ?

                                                                    <div className='container-fluid mt-3'>
                                                                        <div className="row">
                                                                            <div className="col-8 p-0 " id="overrideMainFrame">
                                                                                {
                                                                                    overrideDate ?
                                                                                        dateOverrides[overrideDate.toISOString()] ?
                                                                                            dateOverrides[overrideDate.toISOString()].timeSet ?
                                                                                                dateOverrides[overrideDate.toISOString()].timeSet.length > 0 ?
                                                                                                    dateOverrides[overrideDate.toISOString()].timeSet.map((times, key) => {
                                                                                                        let uid = uuidv4();
                                                                                                        return (
                                                                                                            <div className='d-flex align-items-center gap-2' key={key} id={`overrideframe_${uid}`}>
                                                                                                                <div className='w-50 h-100'>
                                                                                                                    <input list={`overrideStart_${uid}`} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "override") }} maxLength={7} required defaultValue={times.split("-")[0]} name="activeOn" className='customInputFieldFocus w-100 smallTextNote overrideStartTabs' />
                                                                                                                    <datalist id={`overrideStart_${uid}`} className='smallTextNote'>
                                                                                                                        {
                                                                                                                            timeListToMeasure.map((times, key) => {
                                                                                                                                return (
                                                                                                                                    <option key={key} value={times}>{times}</option>
                                                                                                                                )
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </datalist>
                                                                                                                </div>
                                                                                                                <div className='w-50 h-100 '>
                                                                                                                    <input list={`overrideEnd_${uid}`} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "override") }} maxLength={7} required defaultValue={times.split("-")[1]} name="activeOn" className='customInputFieldFocus w-100 smallTextNote overrideEndTabs' />
                                                                                                                    <datalist id={`overrideEnd_${uid}`} className='smallTextNote'>
                                                                                                                        {
                                                                                                                            timeListToMeasure.map((times, key) => {
                                                                                                                                return (
                                                                                                                                    <option key={key} value={times}>{times}</option>
                                                                                                                                )
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </datalist>
                                                                                                                </div>
                                                                                                                <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSetOverride("override", `overrideframe_${uid}`); }}></i>
                                                                                                            </div>
                                                                                                        )
                                                                                                    })
                                                                                                    :
                                                                                                    <div className='d-flex align-items-center gap-2 ' id="overrideframe_one">
                                                                                                        <div className='w-50 h-100'>
                                                                                                            <input list="overrideStart" maxLength={7} required defaultValue={user.settings.timeFormat.value !== "12 Hours" ? "09:00" : "9:00am"} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "override"); }} name="activeOn" className='customInputFieldFocus w-100 smallTextNote overrideStartTabs' />
                                                                                                            <datalist id="overrideStart" className='smallTextNote'>
                                                                                                                {
                                                                                                                    timeListToMeasure.map((times, key) => {
                                                                                                                        return (
                                                                                                                            <option key={key} value={times}>{times}</option>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </datalist>
                                                                                                        </div>
                                                                                                        <div className='w-50 h-100'>
                                                                                                            <input list="overrideEnd" maxLength={7} required defaultValue={user.settings.timeFormat.value !== "12 Hours" ? "17:00" : "5:00pm"} onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "override"); }} name="activeOn" className='customInputFieldFocus w-100 smallTextNote overrideEndTabs' />
                                                                                                            <datalist id="overrideEnd" className='smallTextNote'>
                                                                                                                {
                                                                                                                    timeListToMeasure.map((times, key) => {
                                                                                                                        return (
                                                                                                                            <option key={key} value={times}>{times}</option>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </datalist>
                                                                                                        </div>
                                                                                                        <i className='fa fa-trash' onClick={() => { deleteTimeSetOverride("override", "overrideframe_one"); }}></i>
                                                                                                    </div>
                                                                                                :
                                                                                                ""
                                                                                            :
                                                                                            ""
                                                                                        :
                                                                                        ""
                                                                                }

                                                                            </div>
                                                                            <div className="col-4 p-2">
                                                                                <div className='d-flex align-items-start justify-content-end w-100 h-100'>
                                                                                    <i className='fa fa-plus' onClick={(e) => { addTimeSetInOverride("override", uuidv4()); }} ></i>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    existingOverride === false && overrideDateEnabled ?
                                                                        <div className='container-fluid mt-3'>
                                                                            <div className="row">
                                                                                <div className="col-8 p-0" id="overrideMainFrame">
                                                                                    <div className='d-flex align-items-center gap-2 ' id="overrideframe_one">
                                                                                        <div className='w-50 h-100'>
                                                                                            <input list="overrideStart" onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "override") }} maxLength={7} required defaultValue={user.settings.timeFormat.value !== "12 Hours" ? "09:00" : "9:00am"} name="activeOn" className='customInputFieldFocus w-100 smallTextNote overrideStartTabs' />
                                                                                            <datalist id="overrideStart" className='smallTextNote'>
                                                                                                {
                                                                                                    timeListToMeasure.map((times, key) => {
                                                                                                        return (
                                                                                                            <option key={key} value={times}>{times}</option>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </datalist>
                                                                                        </div>
                                                                                        <div className='w-50 h-100'>
                                                                                            <input list="overrideEnd" onInput={(e) => { filterTimeFieldNow(e); checkValidTiming(e, "override") }} maxLength={7} required defaultValue={user.settings.timeFormat.value !== "12 Hours" ? "17:00" : "5:00pm"} name="activeOn" className='customInputFieldFocus w-100 smallTextNote overrideEndTabs' />
                                                                                            <datalist id="overrideEnd" className='smallTextNote'>
                                                                                                {
                                                                                                    timeListToMeasure.map((times, key) => {
                                                                                                        return (
                                                                                                            <option key={key} value={times}>{times}</option>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </datalist>
                                                                                        </div>
                                                                                        <i className='fa fa-trash' style={{ padding: "0 5px" }} onClick={() => { deleteTimeSetOverride("override", "overrideframe_one"); }}></i>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-4 p-2'>
                                                                                    <div className='d-flex align-items-start justify-content-end w-100 h-100'>
                                                                                        <i className='fa fa-plus' onClick={(e) => { addTimeSetInOverride("override", uuidv4()); }} ></i>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <span className='smallTextNote'>Unavailable</span>
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        overrideDate ?

                                                            <div className="row ">
                                                                <div className="col-12 p-0">
                                                                    <hr />
                                                                    <div className='d-flex justify-content-center align-items-center gap-3'>
                                                                        <button type='button' style={{ maxWidth: "120px", width: "100%" }} className='btn btn-sm btn-secondary text-center rounded-pill my-1' onClick={() => { setViewDateOverridePanel(false); setOverrideDate(null); setOverrideDateEnabled(false); setOverrideStartTime(null); setOverrideEndTime(null); }}>Cancel</button>
                                                                        <button type="submit" style={{ maxWidth: "120px", width: "100%" }} disabled={overrideDateEnabled === false ? false : overrideButtonEnabled ? false : true} className='btn btn-sm text-center rounded-pill my-1 salesforce-style'>Add</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="row ">
                                                                <div className="col-12 p-0">
                                                                    <hr />
                                                                    <p className='my-3 text-center'>
                                                                        Select a Date to Override
                                                                    </p>
                                                                    <div className='d-flex justify-content-center align-items-center gap-3'>
                                                                        <button type='button' style={{ maxWidth: "120px", width: "100%" }} className='btn btn-sm btn-secondary text-center rounded-pill my-1' onClick={() => { setViewDateOverridePanel(false); setOverrideDate(null); setOverrideDateEnabled(false); setOverrideStartTime(null); setOverrideEndTime(null); }}>Cancel</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }

                                                </div>
                                            </form>
                                        </Modal.Body>
                                    </Modal>
                                    {/* ####################################### DATE OVERRIDE PANEL END ################################################ */}



                                    {/* ############################################################################################################ */}
                                    {/* CUSTOM ALERT BOX*/}
                                    <div className={`alertBoxBakcground ${showCustomWorkflowStep1 ? "" : "d-none"}`}>
                                        <div className="alertBox glassmorphismLigher">
                                            <div className='alertBoxMessages'>
                                                <p className='text-center fw-bold my-2'>Create a workflow</p>

                                                <label htmlFor="whenHappens">
                                                    <span>When this happens</span>
                                                    <select name="" id="whenHappens">
                                                        <option value="New event is booked"></option>
                                                        <option value="Befor event starts"></option>
                                                        <option value="Event Starts"></option>
                                                        <option value="Event Ends"></option>
                                                        <option value="Event is Cancelled"></option>
                                                    </select>
                                                </label>

                                                <div className='card'>
                                                    <div className="card-header">
                                                        How long after this  happens?
                                                    </div>
                                                    <div className="card-body">
                                                        <ul className='p-1 m-1'>
                                                            <li className='d-flex gap-1 p-1 m-1'>
                                                                <input type="radio" name="" id="" />
                                                                <span>Immediately after this happens</span>
                                                            </li>
                                                            <li className='d-flex gap-1 p-1 m-1'>
                                                                <input type="radio" name="" id="" />
                                                                <select id='endDateTime' className='mt-2' defaultValue={workflowDuration} required onChange={(e) => { if (e.target.value !== "custom") { setWorkflowDuration(e.target.value); setCustomHourView(false); } else { setCustomHourView(true); setWorkflowDuration(15) } }}>
                                                                    <option value="15">15 minutes</option>
                                                                    <option value="30">30 minutes</option>
                                                                    <option value="45">45 minutes</option>
                                                                    <option value="60">60 minutes</option>
                                                                    <option value="custom">Custom</option>
                                                                </select>
                                                                {
                                                                    customHourView ?
                                                                        <div className='d-flex justify-content-center align-items-center gap-2 my-2'>
                                                                            {
                                                                                durationType === "hours" ?
                                                                                    <input className='customInputFieldFocus w-100' required type="number" min="1" max="12" defaultValue={"1"} onChange={(e) => { convertToMinutes(e.target.value); }} />
                                                                                    :
                                                                                    <input className='customInputFieldFocus w-100' required type="number" min="15" max="720" defaultValue={"15"} onChange={(e) => { convertToMinutes(e.target.value); }} />
                                                                            }
                                                                            <Select
                                                                                className='w-50'
                                                                                options={[{ label: "minutes", value: "minutes" }, { label: "hours", value: "hours" }]}
                                                                                defaultValue={[durationType === "hours" ? { label: "hours", value: "hours" } : { label: "minutes", value: "minutes" }]}
                                                                                onChange={(e) => { setDurationType(e.value); }}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        ""
                                                                }
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                <label htmlFor="whenHappens">
                                                    <span>When this happens</span>
                                                    <select name="" id="whenHappens">
                                                    </select>
                                                </label>

                                                <div className='d-flex justify-content-center align-items-center gap-3'>
                                                    <button style={{ maxWidth: "120px" }} className='btn-sm w-100 btn btn-secondary px-5 text-center rounded-pill my-1' onClick={() => { setShowCustomWorkflowStep1(false); }}>Cancel</button>
                                                    <button style={{ maxWidth: "120px" }} className='btn-sm w-100 btn salesforce-style px-5 text-center rounded-pill my-1' onClick={() => { setShowCustomWorkflowStep2(true); }}>Next</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* ############################################################################################################ */}



                                    {/* ################################## CUSTOM ALERT BOX ############################# */}
                                    <Modal
                                        show={viewFromSunday[0] || viewFromMonday[0] || viewFromTuesday[0] || viewFromWednesday[0] || viewFromThursday[0] || viewFromFriday[0] || viewFromSaturday[0]}
                                        size="sm"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                        onHide={() => {
                                            setViewFromSunday(false);
                                            setViewFromMonday(false);
                                            setViewFromTuesday(false);
                                            setViewFromWednesday(false);
                                            setViewFromThursday(false);
                                            setViewFromFriday(false);
                                            setViewFromSaturday(false);
                                        }
                                        }
                                    >
                                        <Modal.Header className='salesforce-style py-2' closeButton closeVariant="white">
                                            <Modal.Title id="contained-modal-title-vcenter">
                                                <span style={{ fontSize: "1rem" }}>Copy times to...</span>
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className={`copyDays`}>
                                                <div className="daysLabel">
                                                    <span>
                                                        Sunday
                                                    </span>
                                                    {
                                                        viewFromSunday[0] ?
                                                            <button className={`default`} title="This day is selected to copy times slotes.">
                                                            </button>
                                                            :
                                                            <button onClick={(e) => { setSundayCopy(!sundayCopy); }} className={sundayCopy ? "enabled" : "disabled"}>
                                                            </button>
                                                    }
                                                </div>
                                                <div className="daysLabel">
                                                    <span>
                                                        Monday
                                                    </span>
                                                    {
                                                        viewFromMonday[0] ?
                                                            <button className={`default`} title="This day is selected to copy times slotes.">
                                                            </button>
                                                            :
                                                            <button onClick={(e) => { setMondayCopy(!mondayCopy); }} className={mondayCopy ? "enabled" : "disabled"}>
                                                            </button>
                                                    }

                                                </div>
                                                <div className="daysLabel">
                                                    <span>
                                                        Tuesday
                                                    </span>
                                                    {
                                                        viewFromTuesday[0] ?
                                                            <button className={`default`} title="This day is selected to copy times slotes.">
                                                            </button>
                                                            :
                                                            <button onClick={(e) => { setTuesdayCopy(!tuesdayCopy); }} className={tuesdayCopy ? "enabled" : "disabled"}>
                                                            </button>
                                                    }

                                                </div>
                                                <div className="daysLabel">
                                                    <span>
                                                        Wednesday
                                                    </span>
                                                    {
                                                        viewFromWednesday[0] ?
                                                            <button className={`default`} title="This day is selected to copy times slotes.">
                                                            </button>
                                                            :
                                                            <button onClick={(e) => { setWednesdayCopy(!wednesdayCopy); }} className={wednesdayCopy ? "enabled" : "disabled"}>
                                                            </button>
                                                    }
                                                </div>
                                                <div className="daysLabel">
                                                    <span>
                                                        Thursday
                                                    </span>
                                                    {
                                                        viewFromThursday[0] ?
                                                            <button className={`default`} title="This day is selected to copy times slotes.">
                                                            </button>
                                                            :
                                                            <button onClick={(e) => { setThursdayCopy(!thursdayCopy); }} className={thursdayCopy ? "enabled" : "disabled"}>
                                                            </button>
                                                    }
                                                </div>
                                                <div className="daysLabel">
                                                    <span>
                                                        Friday
                                                    </span>
                                                    {
                                                        viewFromFriday[0] ?
                                                            <button className={`default`} title="This day is selected to copy times slotes.">
                                                            </button>
                                                            :
                                                            <button onClick={(e) => { setFridayCopy(!fridayCopy); }} className={fridayCopy ? "enabled" : "disabled"}>
                                                            </button>
                                                    }
                                                </div>
                                                <div className="daysLabel">
                                                    <span>
                                                        Saturday
                                                    </span>
                                                    {
                                                        viewFromSaturday[0] ?
                                                            <button className={`default`} title="This day is selected to copy times slotes.">
                                                            </button>
                                                            :
                                                            <button onClick={(e) => { setSaturdayCopy(!saturdayCopy); }} className={saturdayCopy ? "enabled" : "disabled"}>
                                                            </button>
                                                    }
                                                </div>
                                                <div className='mt-4 d-flex justify-content-center'>
                                                    {
                                                        viewFromSunday[0] ?
                                                            <button className="btn salesforce-style rounded-pill w-100 applyButtonCopyTimes" onClick={() => {
                                                                copyTimesNow("sunday", viewFromSunday[1]);
                                                                setViewFromSunday([false, null]);
                                                            }}>Apply</button>
                                                            :
                                                            viewFromMonday[0] ?
                                                                <button className="btn salesforce-style rounded-pill w-100 applyButtonCopyTimes" onClick={() => {
                                                                    copyTimesNow("monday", viewFromMonday[1]);
                                                                    setViewFromMonday([false, null]);
                                                                }}>Apply</button>
                                                                :
                                                                viewFromTuesday[0] ?
                                                                    <button className="btn salesforce-style rounded-pill w-100 applyButtonCopyTimes" onClick={() => {
                                                                        copyTimesNow("tuesday", viewFromTuesday[1]);
                                                                        setViewFromTuesday([false, null]);
                                                                    }}>Apply</button>
                                                                    :
                                                                    viewFromWednesday[0] ?
                                                                        <button className="btn salesforce-style rounded-pill w-100 applyButtonCopyTimes" onClick={() => {
                                                                            copyTimesNow("wednesday", viewFromWednesday[1]);
                                                                            setViewFromWednesday([false, null]);
                                                                        }}>Apply</button>
                                                                        :
                                                                        viewFromThursday[0] ?
                                                                            <button className="btn salesforce-style rounded-pill w-100 applyButtonCopyTimes" onClick={() => {
                                                                                copyTimesNow("thursday", viewFromThursday[1]);
                                                                                setViewFromThursday([false, null]);
                                                                            }}>Apply</button>
                                                                            :
                                                                            viewFromFriday[0] ?
                                                                                <button className="btn salesforce-style rounded-pill w-100 applyButtonCopyTimes" onClick={() => {
                                                                                    copyTimesNow("friday", viewFromFriday[1]);
                                                                                    setViewFromFriday([false, null]);
                                                                                }}>Apply</button>
                                                                                :
                                                                                viewFromSaturday[0] ?
                                                                                    <button className="btn salesforce-style rounded-pill w-100 applyButtonCopyTimes" onClick={() => {
                                                                                        copyTimesNow("saturday", viewFromSaturday[1]);
                                                                                        setViewFromSaturday([false, null]);
                                                                                    }}>Apply</button>
                                                                                    :
                                                                                    ""


                                                    }
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                    {/* ################################ CUSTOM ALERT BOX END ################################ */}


                                    {/* ############################################################################################## */}
                                    <Modal
                                        show={showDateRangeSelector}
                                        onHide={() => { setShowDateRangeSelector(false) }}
                                        size="lg"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                    >
                                        <Modal.Header className="salesforce-style py-2" closeButton closeVariant='white'>
                                            <Modal.Title id="contained-modal-title-vcenter">
                                                <span style={{ fontSize: "1rem" }}>Select Date Range</span>
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className='bg-soul text-center'>
                                            <div className={`w-100 overflow-auto`}>
                                                <DateRangePicker
                                                    // editableDateInputs={true}
                                                    onChange={(item) => { setBetweenDays([item.selection]); }}
                                                    // showSelectionPreview={true}
                                                    // moveRangeOnFirstSelection={false}
                                                    months={2}
                                                    minDate={new Date()}
                                                    ranges={betweenDays}
                                                    direction="horizontal"
                                                    editableDateInputs={true}
                                                />
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button className='btn salesforce-style' onClick={(e) => { setShowDateRangeSelector(false); }}>Confirm</button>
                                            {/* <button className='btn salesforce-style px-3 py-2' onClick={(e) => { setShowCalendarList(false); }}>Done</button> */}
                                        </Modal.Footer>
                                    </Modal>
                                    {/* ############################################################################## */}


                                    {/* ############################### SHARE LINK END ################################## */}
                                    <Modal
                                        show={showShareYourEventPanel}
                                        onHide={() => { completeLinkSharing(); }}
                                        size="lg"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                    >
                                        <Modal.Header className="salesforce-style py-2" closeButton closeVariant='white'>
                                            <Modal.Title id="contained-modal-title-vcenter">
                                                <span style={{ fontSize: "1rem" }}>Sharing Event</span>
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className='bg-soul text-center'>
                                            <Tabs
                                                id="uncontrolled-tab-example"
                                                className="glitchedNavPils"
                                                activeKey={sharingViewOf ? sharingViewOf : "LinkCopy"}
                                            >
                                                <Tab eventKey="LinkCopy" title={<div className='h-100 glitchedPanTabsLinks color-salesforce mediumTextSize' style={{ fontWeight: "500" }} onClick={() => { setSharingViewOf("LinkCopy"); }}>Share link</div>}>
                                                    <div className='bg-body w-100 p-3'>
                                                        <p className='mediumTextSize py-3 text-start'>Copy and paste your scheduling link into a message</p>
                                                        <div className="input-group w-100 d-flex flex-nowrap rounded">
                                                            <input type="text" disabled maxLength={50} id="UID" required className='customInputFieldFocus mediumTextSize w-100 border px-2' placeholder={"https://" + process.env.REACT_APP_DOMAINNAME + myEvent.EventLink} value={"https://" + process.env.REACT_APP_DOMAINNAME + myEvent.EventLink} />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text btn salesforce-style h-100 border-0 mediumTextSize" style={{ borderRadius: "0px 0.375rem 0.375rem 0px" }} onClick={
                                                                    (e) => {
                                                                        navigator.clipboard.writeText("https://" + process.env.REACT_APP_DOMAINNAME + myEvent.EventLink);
                                                                        e.target.innerHTML = "<span class='d-flex align-items-center gap-1'><i class='fa fa-check text-light'></i>Copied</span>";
                                                                        e.target.classList.remove("salesforce-style");
                                                                        e.target.classList.add("btn-success");
                                                                        setTimeout(() => {
                                                                            e.target.innerHTML = "Copy Link";
                                                                            e.target.classList.remove("btn-success");
                                                                            e.target.classList.add("salesforce-style");
                                                                        }, 2000);
                                                                    }}>
                                                                    Copy Link
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                {/* <Tab eventKey="CopyToEmail" title={<div className='h-100 glitchedPanTabsLinks color-salesforce mediumTextSize' style={{ fontWeight: "500" }} onClick={() => { setSharingViewOf("CopyToEmail"); }}>Copy times to email</div>}>
                                                    <button onClick={(e) => {
                                                        var items = [
                                                            new ClipboardItem({
                                                                'text/plain': new Blob(["10:30pm at los angeles"], { type: 'text/plain' }),
                                                                'text/html': new Blob(["<button style='border:1px solid red;color:yellow;background:black;'>10:30pm</button>"], { type: 'text/html' })
                                                            })
                                                        ];

                                                        navigator.clipboard.write(items).then(function () {
                                                            console.log('Text copied to clipboard');
                                                        }).catch(function (error) {
                                                            console.error('Failed to copy text:', error);
                                                        });
                                                    }}>Copy</button>
                                                </Tab>
                                                <Tab eventKey="EmbedToSite" title={<div className='h-100 glitchedPanTabsLinks color-salesforce mediumTextSize' style={{ fontWeight: "500" }} onClick={() => { setSharingViewOf("EmbedToSite"); }}>Add to website</div>}>
                                                </Tab> */}
                                            </Tabs>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button className='btn btn-sm salesforce-style w-100' style={{ maxWidth: "120px" }} onClick={(e) => { completeLinkSharing(); }}>Done</button>
                                        </Modal.Footer>
                                    </Modal>
                                    {/* ############################# SHARE LINK END ################################ */}


                                    {/* #################################### TOAST FRAME ##################################### */}

                                    <div style={{ position: "fixed", bottom: "10px", right: "10px", zIndex: "99999999" }}>
                                        <Toast onClose={() => setViewToast(false)} show={viewToast} delay={3000} autohide className='overflow-hidden'>
                                            <Toast.Header>
                                                {/* <img
                                                src="holder.js/20x20?text=%20"
                                                className="rounded me-2 bg-primary"
                                                alt="toastImage"
                                                /> */}
                                                <span style={{ fontWeight: "500" }} className={`me-auto ${toastMessageType === "invalid" ? "text-danger" : "text-success"}`}>{toastTitle}</span>
                                                <small>just now</small>
                                            </Toast.Header>
                                            <Toast.Body style={toastMessageType === "success" ? { fontWeight: "500", background: "#d1e7dd", color: "#0f5132" } : { fontWeight: "500", background: "#f8d7da", color: "#842029" }}>
                                                {toastBody}
                                            </Toast.Body>
                                        </Toast>
                                    </div>

                                    {/* #################################### TOAST FRAME ##################################### */}
                                </>
                            )
                            :
                            ""
                    }

                    {
                        uRLNotFound === true ?
                            (
                                <div className='freshMain'>
                                    <div className='freshSecond'>
                                        <div className="freshThird border insightBackground shadow p-3">
                                            <a href="/calendar" className="freshAncore">
                                                <div className="freshBranding">
                                                    <div className="freshBrandingOne">powered by</div>
                                                    <div className="freshBrandingTwo">Schedule AI</div>
                                                </div>
                                            </a>
                                            <div className="w-100 rounded">
                                                {/* <button type='button' onClick={() => { window.location.href = "/calendar"; }} className='btn salesforce-style rounded-circle fw-bold backButtonAbs'>
                                            <i className='fa fa-arrow-left'></i>
                                        </button> */}

                                                <div className={`container-fluid`}>
                                                    <div className="row h-100 d-flex align-items-center justify-content-center">
                                                        <div className="col-12 p-4 d-flex flex-column gap-5 justify-content-center align-items-center">
                                                            <h6 className='text-dark text-center'>
                                                                <img src={notFoundPng} className="w-100" style={{ maxWidth: "350px" }} alt="not found" />
                                                                <p className='color-salesforce mt-5 display-6' style={{ fontWeight: "600" }}>Broken URL</p>
                                                                <p className='mediumTextSize text-muted'>Looks like the current url is not available.</p>
                                                            </h6>
                                                            <button type='button' onClick={() => { window.location.href = "/calendar"; }} className='btn btn-sm salesforce-style rounded-pill px-3'>
                                                                {/* <i className='fa fa-arrow-left'></i> */}
                                                                Back
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                            :
                            ""
                    }
                </>
            )
    )
}

export default Editevent