import React from 'react'
import PublicSectorBannerPng from "../../images/Public Sector Banner.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';

const PublicSector = () => {
    return (
        <>
            <div className="heWhoRemains">
                <Header />
                <div>
                    <section>
                        <div className="container mt-5">
                            <div className="row">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">
                                        <h1 className=" display-4 fw-bold smd_text">Schedule AI for
                                            <br />
                                            <span style={{ color: "#0B5ED7" }}> Public Sector
                                            </span>
                                        </h1>
                                        <p className="lead_smd text-black" style={{ fontSize: "1.1em" }}>
                                            The retail sector is a fast-paced and competitive industry. In order to stay ahead of the competition, it is important to have an efficient system for managing appointments. ScheduleAI software can be a valuable tool for retail businesses.
                                        </p>
                                        <a href="/login"><button
                                            className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                                            Demo</button></a>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="w-100" src={PublicSectorBannerPng} alt='Public Sector Banner' />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold text-center">Benefits of using Schedule AI for <br />Public Sector
                                </h2>
                            </div>

                            <ul>
                                <li className='liListDevind'>By using this software, you can help to make sure that everyone gets the chance to be seen and that no one falls through the cracks.
                                </li>

                                <li className='liListDevind'>This type of software can also help to keep track of cancellations and reschedulings. This way, you can make sure that everyone who needs an appointment gets one in a timely manner.
                                </li>

                                <li className='liListDevind'>With ScheduleAI software, you can easily schedule appointments and track them.

                                </li>

                                <li className='liListDevind'>ScheduleAI software can also help to save money. By using this software, you can avoid wasting time and resources on missed appointments.
                                </li>
                            </ul>

                        </div>
                    </section>

                    <section>
                        <div className="container textDevLay pb-4">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold">Conclusion
                                </h2>
                                <p className='lead_smd text-justify'>Overall, using ScheduleAI software in the public sector can have a lot of benefits. It can help to streamline the process and make sure that everyone gets the chance to be seen. Additionally, it can also help to keep track of cancellations and reschedulings.
                                </p>
                            </div>
                        </div>
                    </section>

                </div>

                <Footer />
            </div>
        </>
    )
}

export default PublicSector
