import React from 'react';
import PlatformAndSoftwareProductsBannerPng from "../../images/Platform and Software Products banner.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';

function PlatformAndSoftware() {
    return (
        <>
            <div className="heWhoRemains">
                <Header />
                <div>
                    <section>
                        <div className="container mt-5">
                            <div className="row">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">
                                        <h1 className=" display-4 fw-bold smd_text">Schedule AI for
                                            <span style={{ color: "#0B5ED7" }}> Platform & Software Products
                                            </span>
                                        </h1>
                                        <p className="lead_smd text-black" style={{ fontSize: "1.1em" }}>
                                            When it comes to software products, one of the benefits of ScheduleAI software is that it can help you keep track of your appointments and schedule them accordingly. This can be a great way to stay organized and ensure that you are getting the most out of your time. In addition, ScheduleAI software can also help you save money by allowing you to book appointments in advance and get discounts on products and services.
                                        </p>
                                        <a href="/login"><button
                                            className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                                            Demo</button></a>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="w-100" src={PlatformAndSoftwareProductsBannerPng} alt='Platform and Software Products banner' />
                                </div>
                            </div>
                        </div>
                    </section>


                    <section>
                        <div className="container mt-5">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold">Benefits of using ScheduleAI for Platform & Software Products
                                </h2>
                            </div>
                            <div className="row">
                                <ul>
                                    <li className='liListDevind'>
                                        It can save you a lot of time. If you are constantly having to schedule appointments with different clients or customers, ScheduleAI software can help you keep track of everything in one place.
                                    </li>

                                    <li className='liListDevind'>
                                        It can help you become more organized. When all of your appointments are stored in one place, it becomes much easier to keep track of them and ensure that nothing gets forgotten or lost.
                                    </li>

                                    <li className='liListDevind'>
                                        ScheduleAI software can help streamline your business operations. If you have a team of employees who need to be kept updated on your schedule, this type of software can make it easy to do so.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container textDevLay pb-4">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold">Conclusion
                                </h2>
                                <p className='lead_smd text-justify'>Overall, there are many benefits of using ScheduleAI software for platforms and software products. If you are looking for ways to save time and become more organized, this type of software may be the perfect solution for you.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default PlatformAndSoftware
