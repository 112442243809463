import React from 'react'

function AvailabilityTemplates({
    setIsSundayActive,
    setIsMondayActive,
    setIsTuesdayActive,
    setIsWednesdayActive,
    setIsThursdayActive,
    setIsFridayActive,
    setIsSaturdayActive,
    isSundayActive,
    isMondayActive,
    isTuesdayActive,
    isWednesdayActive,
    isThursdayActive,
    isFridayActive,
    isSaturdayActive,
    checkDayOnToggle,
    schedule,
    timeListToMeasure,
    filterTimeFieldNow,
    checkUpdatedStateOfTimeValidation,
    checkValidTiming,
    timeFormat,
    addTimeSetIn,
    dayjs,
    deleteTimeSet,
    uuidv4,
    ContentCopyIcon,
    copyTimesTo,
    categoryOfFrame
}) {
    return (
        <div className="container-fluid p-0">
            <div className="row my-4">
                <div className="col-3">
                    <div className='d-flex align-items-start gap-3 w-100 h-100'>
                        <label className='d-flex gap-1 align-items-center justify-content-between'>
                            <div className="outerToggleSwitch" onClick={() => { setIsSundayActive(!isSundayActive); checkDayOnToggle(!isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, categoryOfFrame); }}>
                                <div className={`frameOfToggleSwitch ${isSundayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                    <span>On</span>
                                    <div className="toggleSwitchBall"></div>
                                    <span>Off</span>
                                </div>
                            </div>
                            <span className='smallTextNote'>SUN</span>
                        </label>
                    </div>
                </div>
                <div className="col-7">
                    {
                        isSundayActive ?
                            <div className='m-0 p-0' id={`sundayFrame${categoryOfFrame}`}>
                                {
                                    schedule.sunday.times.length > 0 ?
                                        schedule.sunday.times.map((sadn, key) => {
                                            const startTimeGiving = sadn.split("-")[0];
                                            const endTimeGiving = sadn.split("-")[1];
                                            const uid = uuidv4();
                                            return (
                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"sundayTimeFrame_" + uid}>
                                                    <div>
                                                        <input list="sundayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm sundayStartTabs${categoryOfFrame}`} />
                                                        <datalist id="sundayStart" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div>
                                                        <input list="sundayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm sundayEndTabs${categoryOfFrame}`} />
                                                        <datalist id="sundayEnd" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div className='d-flex align-items-center h-100'>
                                                        <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("sunday", categoryOfFrame, uid); }} />
                                                    </div>
                                                </span>
                                            )
                                        })
                                        :
                                        ["9:00am-5:00pm"].map((sadn, key) => {
                                            const startTimeGiving = sadn.split("-")[0];
                                            const endTimeGiving = sadn.split("-")[1];
                                            const uid = uuidv4();
                                            console.log(timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma"))
                                            return (
                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"sundayTimeFrame_" + uid}>
                                                    <div>
                                                        <input list="sundayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm sundayStartTabs${categoryOfFrame}`} />
                                                        <datalist id="sundayStart" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div>
                                                        <input list="sundayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm sundayEndTabs${categoryOfFrame}`} />
                                                        <datalist id="sundayEnd" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div className='d-flex align-items-center h-100'>
                                                        <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("sunday", categoryOfFrame, uid); }} />
                                                    </div>
                                                </span>
                                            )
                                        })
                                }

                            </div>
                            :
                            <span className='smallTextNote'>Unavailable</span>
                    }
                </div>
                <div className="col-2">
                    <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                        <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("sunday", categoryOfFrame, uuidv4()); }} ></i>
                        <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("sunday", categoryOfFrame); }} />
                    </div>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-3">
                    <div className='d-flex align-items-start gap-3 w-100 h-100'>
                        <label className='d-flex gap-1 align-items-center justify-content-between'>
                            <div className="outerToggleSwitch" onClick={() => { setIsMondayActive(!isMondayActive); checkDayOnToggle(isSundayActive, !isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, categoryOfFrame); }}>
                                <div className={`frameOfToggleSwitch ${isMondayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                    <span>On</span>
                                    <div className="toggleSwitchBall"></div>
                                    <span>Off</span>
                                </div>
                            </div>
                            <span className='smallTextNote'>MON</span>
                        </label>
                    </div>
                </div>
                <div className="col-7">

                    {
                        isMondayActive ?
                            <div className='m-0 p-0' id={`mondayFrame${categoryOfFrame}`}>
                                {
                                    schedule.monday.times.length > 0 ?
                                        schedule.monday.times.map((sadn, key) => {
                                            const startTimeGiving = sadn.split("-")[0];
                                            const endTimeGiving = sadn.split("-")[1];
                                            const uid = uuidv4();
                                            return (
                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"mondayTimeFrame_" + uid}>
                                                    <div>
                                                        <input list="mondayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm mondayStartTabs${categoryOfFrame}`} />
                                                        <datalist id="mondayStart" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div>
                                                        <input list="mondayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm mondayEndTabs${categoryOfFrame}`} />
                                                        <datalist id="mondayEnd" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div className='d-flex align-items-center h-100'>
                                                        <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("monday", categoryOfFrame, uid); }} />
                                                    </div>
                                                </span>
                                            )
                                        })
                                        :
                                        ["9:00am-5:00pm"].map((sadn, key) => {
                                            const startTimeGiving = sadn.split("-")[0];
                                            const endTimeGiving = sadn.split("-")[1];
                                            const uid = uuidv4();
                                            return (
                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"mondayTimeFrame_" + uid}>
                                                    <div>
                                                        <input list="mondayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm mondayStartTabs${categoryOfFrame}`} />
                                                        <datalist id="mondayStart" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div>
                                                        <input list="mondayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm mondayEndTabs${categoryOfFrame}`} />
                                                        <datalist id="mondayEnd" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div className='d-flex align-items-center h-100'>
                                                        <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("monday", categoryOfFrame, uid); }} />
                                                    </div>
                                                </span>
                                            )
                                        })
                                }
                            </div>
                            :
                            <span className='smallTextNote'>Unavailable</span>
                    }

                </div>
                <div className="col-2">
                    <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                        <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("monday", categoryOfFrame, uuidv4()); }} ></i>
                        <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("monday", categoryOfFrame); }} />
                    </div>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-3">
                    <div className='d-flex align-items-start gap-3 w-100 h-100'>
                        <label className='d-flex gap-1 align-items-center justify-content-between'>
                            <div className="outerToggleSwitch" onClick={() => { setIsTuesdayActive(!isTuesdayActive); checkDayOnToggle(isSundayActive, isMondayActive, !isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, categoryOfFrame); }}>
                                <div className={`frameOfToggleSwitch ${isTuesdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                    <span>On</span>
                                    <div className="toggleSwitchBall"></div>
                                    <span>Off</span>
                                </div>
                            </div>
                            <span className='smallTextNote'>TUE</span>
                        </label>
                    </div>
                </div>
                <div className="col-7">
                    {
                        isTuesdayActive ?
                            <div className='m-0 p-0' id={`tuesdayFrame${categoryOfFrame}`}>
                                {
                                    schedule.tuesday.times.length > 0 ?
                                        schedule.tuesday.times.map((sadn, key) => {
                                            const startTimeGiving = sadn.split("-")[0];
                                            const endTimeGiving = sadn.split("-")[1];
                                            const uid = uuidv4();
                                            return (
                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"tuesdayTimeFrame_" + uid}>
                                                    <div>
                                                        <input list="tuesdayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm tuesdayStartTabs${categoryOfFrame}`} />
                                                        <datalist id="tuesdayStart" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div>
                                                        <input list="tuesdayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm tuesdayEndTabs${categoryOfFrame}`} />
                                                        <datalist id="tuesdayEnd" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div className='d-flex align-items-center h-100'>
                                                        <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("tuesday", categoryOfFrame, uid); }} />
                                                    </div>
                                                </span>
                                            )
                                        })
                                        :
                                        ["9:00am-5:00pm"].map((sadn, key) => {
                                            const startTimeGiving = sadn.split("-")[0];
                                            const endTimeGiving = sadn.split("-")[1];
                                            const uid = uuidv4();
                                            return (
                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"tuesdayTimeFrame_" + uid}>
                                                    <div>
                                                        <input list="tuesdayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm tuesdayStartTabs${categoryOfFrame}`} />
                                                        <datalist id="tuesdayStart" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div>
                                                        <input list="tuesdayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm tuesdayEndTabs${categoryOfFrame}`} />
                                                        <datalist id="tuesdayEnd" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div className='d-flex align-items-center h-100'>
                                                        <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("tuesday", categoryOfFrame, uid); }} />
                                                    </div>
                                                </span>
                                            )
                                        })
                                }
                            </div>
                            :
                            <span className='smallTextNote'>Unavailable</span>
                    }

                </div>
                <div className="col-2">
                    <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                        <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("tuesday", categoryOfFrame, uuidv4()); }} ></i>
                        <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("tuesday", categoryOfFrame); }} />
                    </div>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-3">
                    <div className='d-flex align-items-start gap-3 w-100 h-100'>
                        <label className='d-flex gap-1 align-items-center justify-content-between'>
                            <div className="outerToggleSwitch" onClick={() => { setIsWednesdayActive(!isWednesdayActive); checkDayOnToggle(isSundayActive, isMondayActive, isTuesdayActive, !isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, categoryOfFrame); }}>
                                <div className={`frameOfToggleSwitch ${isWednesdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                    <span>On</span>
                                    <div className="toggleSwitchBall"></div>
                                    <span>Off</span>
                                </div>
                            </div>
                            <span className='smallTextNote'>WED</span>
                        </label>
                    </div>
                </div>
                <div className="col-7">
                    {
                        isWednesdayActive ?
                            <div className='m-0 p-0' id={`wednesdayFrame${categoryOfFrame}`}>
                                {
                                    schedule.wednesday.times.length > 0 ?
                                        schedule.wednesday.times.map((sadn, key) => {
                                            const startTimeGiving = sadn.split("-")[0];
                                            const endTimeGiving = sadn.split("-")[1];
                                            const uid = uuidv4();
                                            return (
                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"wednesdayTimeFrame_" + uid}>
                                                    <div>
                                                        <input list="wednesdayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm wednesdayStartTabs${categoryOfFrame}`} />
                                                        <datalist id="wednesdayStart" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div>
                                                        <input list="wednesdayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm wednesdayEndTabs${categoryOfFrame}`} />
                                                        <datalist id="wednesdayEnd" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div className='d-flex align-items-center h-100'>
                                                        <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("wednesday", categoryOfFrame, uid); }} />
                                                    </div>
                                                </span>
                                            )
                                        })
                                        :
                                        ["9:00am-5:00pm"].map((sadn, key) => {
                                            const startTimeGiving = sadn.split("-")[0];
                                            const endTimeGiving = sadn.split("-")[1];
                                            const uid = uuidv4();
                                            return (
                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"wednesdayTimeFrame_" + uid}>
                                                    <div>
                                                        <input list="wednesdayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm wednesdayStartTabs${categoryOfFrame}`} />
                                                        <datalist id="wednesdayStart" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div>
                                                        <input list="wednesdayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm wednesdayEndTabs${categoryOfFrame}`} />
                                                        <datalist id="wednesdayEnd" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div className='d-flex align-items-center h-100'>
                                                        <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("wednesday", categoryOfFrame, uid); }} />
                                                    </div>
                                                </span>
                                            )
                                        })
                                }
                            </div>
                            :
                            <span className='smallTextNote'>Unavailable</span>
                    }

                </div>
                <div className="col-2">
                    <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                        <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("wednesday", categoryOfFrame, uuidv4()); }} ></i>
                        <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("wednesday", categoryOfFrame); }} />
                    </div>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-3">
                    <div className='d-flex align-items-start gap-3 w-100 h-100'>
                        <label className='d-flex gap-1 align-items-center justify-content-between'>
                            <div className="outerToggleSwitch" onClick={() => { setIsThursdayActive(!isThursdayActive); checkDayOnToggle(isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, !isThursdayActive, isFridayActive, isSaturdayActive, categoryOfFrame); }}>
                                <div className={`frameOfToggleSwitch ${isThursdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                    <span>On</span>
                                    <div className="toggleSwitchBall"></div>
                                    <span>Off</span>
                                </div>
                            </div>
                            <span className='smallTextNote'>THU</span>
                        </label>
                    </div>
                </div>
                <div className="col-7">
                    {
                        isThursdayActive ?
                            <div className='m-0 p-0' id={`thursdayFrame${categoryOfFrame}`}>
                                {
                                    schedule.thursday.times.length > 0 ?
                                        schedule.thursday.times.map((sadn, key) => {
                                            const startTimeGiving = sadn.split("-")[0];
                                            const endTimeGiving = sadn.split("-")[1];
                                            const uid = uuidv4();
                                            return (
                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"thursdayTimeFrame_" + uid}>
                                                    <div>
                                                        <input list="thursdayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm thursdayStartTabs${categoryOfFrame}`}/>
                                                        <datalist id="thursdayStart" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div>
                                                        <input list="thursdayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm thursdayEndTabs${categoryOfFrame}`} />
                                                        <datalist id="thursdayEnd" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div className='d-flex align-items-center h-100'>
                                                        <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("thursday", "Shortcut", uid); }} />
                                                    </div>
                                                </span>
                                            )
                                        })
                                        :
                                        ["9:00am-5:00pm"].map((sadn, key) => {
                                            const startTimeGiving = sadn.split("-")[0];
                                            const endTimeGiving = sadn.split("-")[1];
                                            const uid = uuidv4();
                                            return (
                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"thursdayTimeFrame_" + uid}>
                                                    <div>
                                                        <input list="thursdayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm thursdayStartTabs${categoryOfFrame}`} />
                                                        <datalist id="thursdayStart" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div>
                                                        <input list="thursdayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm thursdayEndTabs${categoryOfFrame}`} />
                                                        <datalist id="thursdayEnd" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div className='d-flex align-items-center h-100'>
                                                        <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("thursday", "Shortcut", uid); }} />
                                                    </div>
                                                </span>
                                            )
                                        })
                                }
                            </div>
                            :
                            <span className='smallTextNote'>Unavailable</span>
                    }

                </div>
                <div className="col-2">
                    <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                        <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("thursday", "Shortcut", uuidv4()); }} ></i>
                        <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("thursday", "Shortcut"); }} />
                    </div>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-3">
                    <div className='d-flex align-items-start gap-3 w-100 h-100'>
                        <label className='d-flex gap-1 align-items-center justify-content-between'>
                            <div className="outerToggleSwitch" onClick={() => { setIsFridayActive(!isFridayActive); checkDayOnToggle(!isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, !isFridayActive, isSaturdayActive, categoryOfFrame); }}>
                                <div className={`frameOfToggleSwitch ${isFridayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                    <span>On</span>
                                    <div className="toggleSwitchBall"></div>
                                    <span>Off</span>
                                </div>
                            </div>
                            <span className='smallTextNote'>FRI</span>
                        </label>
                    </div>
                </div>
                <div className="col-7">
                    {
                        isFridayActive ?
                            <div className='m-0 p-0' id="fridayFrameShortcut">
                                {
                                    schedule.friday.times.length > 0 ?
                                        schedule.friday.times.map((sadn, key) => {
                                            const startTimeGiving = sadn.split("-")[0];
                                            const endTimeGiving = sadn.split("-")[1];
                                            const uid = uuidv4();
                                            return (
                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"fridayTimeFrame_" + uid}>
                                                    <div>
                                                        <input list="fridayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm fridayStartTabs${categoryOfFrame}`} />
                                                        <datalist id="fridayStart" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div>
                                                        <input list="fridayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm fridayEndTabs${categoryOfFrame}`} />
                                                        <datalist id="fridayEnd" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div className='d-flex align-items-center h-100'>
                                                        <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("friday", "Shortcut", uid); }} />
                                                    </div>
                                                </span>
                                            )
                                        })
                                        :
                                        ["9:00am-5:00pm"].map((sadn, key) => {
                                            const startTimeGiving = sadn.split("-")[0];
                                            const endTimeGiving = sadn.split("-")[1];
                                            const uid = uuidv4();
                                            return (
                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"fridayTimeFrame_" + uid}>
                                                    <div>
                                                        <input list="fridayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm fridayStartTabs${categoryOfFrame}`} />
                                                        <datalist id="fridayStart" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div>
                                                        <input list="fridayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm fridayEndTabs${categoryOfFrame}`} />
                                                        <datalist id="fridayEnd" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div className='d-flex align-items-center h-100'>
                                                        <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("friday", "Shortcut", uid); }} />
                                                    </div>
                                                </span>
                                            )
                                        })
                                }
                            </div>
                            :
                            <span className='smallTextNote'>Unavailable</span>
                    }
                </div>
                <div className="col-2">
                    <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                        <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("friday", "Shortcut", uuidv4()); }} ></i>
                        <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("friday", "Shortcut"); }} />
                    </div>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-3">
                    <div className='d-flex align-items-start gap-3 w-100 h-100'>
                        <label className='d-flex gap-1 align-items-center justify-content-between'>
                            <div className="outerToggleSwitch" onClick={() => { setIsSaturdayActive(!isSaturdayActive); checkDayOnToggle(isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, !isSaturdayActive, categoryOfFrame); }}>
                                <div className={`frameOfToggleSwitch ${isSaturdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                    <span>On</span>
                                    <div className="toggleSwitchBall"></div>
                                    <span>Off</span>
                                </div>
                            </div>
                            <span className='smallTextNote'>SAT</span>
                        </label>
                    </div>
                </div>
                <div className="col-7">
                    {
                        isSaturdayActive ?
                            <div className='m-0 p-0' id="saturdayFrameShortcut">
                                {
                                    schedule.saturday.times.length > 0 ?
                                        schedule.saturday.times.map((sadn, key) => {
                                            const startTimeGiving = sadn.split("-")[0];
                                            const endTimeGiving = sadn.split("-")[1];
                                            const uid = uuidv4();
                                            return (
                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"saturdayTimeFrame_" + uid}>
                                                    <div>
                                                        <input list="saturdayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm saturdayStartTabs${categoryOfFrame}`} />
                                                        <datalist id="saturdayStart" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div>
                                                        <input list="saturdayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm saturdayEndTabs${categoryOfFrame}`} />
                                                        <datalist id="saturdayEnd" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div className='d-flex align-items-center h-100'>
                                                        <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("saturday", "Shortcut", uid); }} />
                                                    </div>
                                                </span>
                                            )
                                        })
                                        :
                                        ["9:00am-5:00pm"].map((sadn, key) => {
                                            const startTimeGiving = sadn.split("-")[0];
                                            const endTimeGiving = sadn.split("-")[1];
                                            const uid = uuidv4();
                                            return (
                                                <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"saturdayTimeFrame_" + uid}>
                                                    <div>
                                                        <input list="saturdayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm saturdayStartTabs${categoryOfFrame}`} />
                                                        <datalist id="saturdayStart" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div>
                                                        <input list="saturdayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation(categoryOfFrame); checkValidTiming(e, categoryOfFrame); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm saturdayEndTabs${categoryOfFrame}`} />
                                                        <datalist id="saturdayEnd" className='smallTextNote'>
                                                            {
                                                                timeListToMeasure.map((times, key) => {
                                                                    return (
                                                                        <option key={key} value={times}>{times}</option>
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div className='d-flex align-items-center h-100'>
                                                        <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("saturday", categoryOfFrame, uid); }} />
                                                    </div>
                                                </span>
                                            )
                                        })
                                }
                            </div>
                            :
                            <span className='smallTextNote'>Unavailable</span>
                    }

                </div>
                <div className="col-2">
                    <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                        <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("saturday", "Shortcut", uuidv4()); }} ></i>
                        <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("saturday", "Shortcut"); }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AvailabilityTemplates