import React from 'react';
import ScheduleAIForUtilitiesBannerPng from "../../images/ScheduleAI for Utilities banner.png";
import BetterUseOfResourcesPng from "../../images/Better Use of Resources.png";
import IncreasedCustomerSatisfactionUtilitiesPng from "../../images/Increased Customer Satisfaction utilities.png";
import ImprovedCommunicationPng from "../../images/Improved Communication.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';
function Utilities() {
    return (
        <>
            <div className="heWhoRemains">
                <Header />
                <div>
                    <section>
                        <div className="container">
                            <div className="row mt-5">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-up"
                                    data-aos-duration="2000">
                                    <div className="mt-4 ">

                                        <h1 className=" display-4 fw-bold smd_text">Schedule AI for
                                            <span style={{ color: "#0B5ED7" }}> Utilities</span>
                                        </h1>
                                        <p className="lead_smd text-black" style={{ fontSize: "1.1em" }}>
                                            Utilities are always looking for ways to improve efficiency and effectiveness, and appointment scheduling software is one way to do that. This is because the software can automate many of the tasks associated with scheduling appointments, such as sending reminders and booking appointments.
                                        </p>
                                        <a href="/login"><button
                                            className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                                            Demo</button></a>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="w-100" src={ScheduleAIForUtilitiesBannerPng} alt="ScheduleAI for Utilities banner" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="column pt-5">
                                <h2 className="text-center display-6 fw-bold">Benefits of using ScheduleAI for Utilities
                                </h2>
                            </div>
                            <div className="row">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-up"
                                    data-aos-duration="2000">
                                    <div className="mt-4 ">

                                        <h1 className="display-5 smd_title" style={{ fontWeight: "400" }}>Increased Customer Satisfaction
                                        </h1>
                                        <p className="lead_smd"> Customers can schedule appointments online or through a mobile app, and they can see real-time updates if their appointment needs to be rescheduled.
                                        </p>

                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 mt-5" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="w-100" src={IncreasedCustomerSatisfactionUtilitiesPng} alt="Increased Customer Satisfaction utilities" />
                                </div>

                            </div>
                        </div>
                    </section>


                    <section>
                        <div className="container">
                            <div className="row mt-5">

                                <div className="col-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="w-100" src={BetterUseOfResourcesPng} alt="Better Use of Resources" />
                                </div>

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-up"
                                    data-aos-duration="2000">
                                    <div className="mt-4 ">

                                        <h1 className=" display-5 smd_title" style={{ fontWeight: "400" }}>Better Use of Resources
                                        </h1>
                                        <p className="lead_smd text-black" style={{ fontSize: "1.1em" }}>
                                            Utilities are always looking for ways to improve efficiency and effectiveness, and appointment scheduling software is one way to do that. This is because the software can automate many of the tasks associated with scheduling appointments, such as sending reminders and booking appointments.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section>
                        <div className="container">
                            <div className="row mt-5">
                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center" >
                                    <div className="mt-4 ">

                                        <h1 className=" display-5 smd_title" style={{ fontWeight: "400" }}>Improved Communication
                                        </h1>
                                        <p className="lead_smd text-black" style={{ fontSize: "1.1em" }}>
                                            Appointment scheduling software also improves communication between utilities and their customers. Customers can receive reminders about their appointments, and they can easily cancel or reschedule if necessary.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="w-100" src={ImprovedCommunicationPng} alt="Improved Communication.png" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container textDevLay pb-4">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold">Conclusion
                                </h2>
                                <p className='lead_smd text-center'>Overall, ScheduleAI software provides many benefits for utilities. By increasing customer satisfaction, better utilizing resources, and improving communication, this type of software can help utilities run more smoothly and efficiently.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default Utilities