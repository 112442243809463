import React, { useEffect, useState } from 'react'
import zoomImg from "../../images/zoomImg.png";
import meetImg from "../../images/meetImg.png";
import callimg from "../../images/callImg.png";
import addressImg from "../../images/addressImg.png";

import invalidPng from "../../images/invalidPng.gif";
import successPng from "../../images/successPng.gif";

import popupData from "../../audio/popup-alert.mp3";
import pageData from "../../audio/page-turn.wav";

import notFoundPng from "../../images/notFound.png";

import { v4 as uuidv4 } from 'uuid';
import TimezoneSelect, { allTimezones } from 'react-timezone-select';
import { getMonth } from '../../utils';
import MonthBooking from '../../components/MonthBooking';
import 'react-phone-number-input/style.css';
import { validate } from 'react-email-validator';
import timeZoneConverter from 'time-zone-converter';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import dayjs from "dayjs";
import axios from "axios";
import Basic from '../../components/MultiEmails.tsx';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "../BookingPage/Bookings.css";
import Select from 'react-select';
import { Modal, Toast } from 'react-bootstrap';
import MultiEmailsReschedule from '../../components/MultiEmailsReschedule';

import { Calendar } from 'react-date-range';
import { addDays } from 'date-fns';

export default function ReschedulePage() {
    const [codeDecode, setCodeDecode] = useState({});

    const serverUrl = "https://" + process.env.REACT_APP_DOMAINNAME + ":8443";
    const [monthIndexCurrent, setMonthIndexCurrent] = useState(dayjs().month());
    const [minMonthIndex, setMinMonthIndex] = useState(null);
    const [maxMonthIndex, setMaxMonthIndex] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(getMonth(monthIndexCurrent));
    const [myEvent, setMyEvent] = useState(null);
    const [timings, setTimings] = useState([]);
    const [creatorData, setCreatorData] = useState("");
    const [globalSettings, setGlobalSettings] = useState({});
    const [applyActive, setApplyActive] = useState(false);
    const [selectedTiming, setSelectedTiming] = useState(false);
    const [selectedTimingSet, setSelectedTimingSet] = useState("");
    const [selectedTimezone, setSelectedTimezone] = useState({
        "label": "America/Los_Angeles",
        "value": {
            "name": "America/Los_Angeles",
            "countries": [
                "US"
            ],
            "utcOffset": -480,
            "utcOffsetStr": "-08:00",
            "dstOffset": -420,
            "dstOffsetStr": "-07:00",
            "aliasOf": null
        }
    });
    const [selectedTimezoneUTC, setSelectedTimezoneUTC] = useState(dayjs().format("Z"));
    const [currentSelectedDayTab, setCurrentSelectedDayTab] = useState("");
    const [stopBookingBefore, setStopBookingBefore] = useState(2);

    const [appBranding, setAppBranding] = useState(true);
    const [brandLogo, setBrandLogo] = useState([]);
    const [brandLink, setBrandLink] = useState("#");

    const [showNow, setShowNow] = useState(false);
    const [userNotFound, setUserNotFound] = useState(false);



    const [customAlertBox, setCustomAlertBox] = useState(false);
    const [alertBoxMessages, setAlertBoxMessages] = useState("");
    const [alertErrorType, setAlertErrorType] = useState("invalid");


    const [countrySchedule, setCountrySchedule] = useState([]);
    const [countriesList, setCountriesList] = useState([]);
    const [timezonesList, setTimezonesList] = useState([]);
    const [filteredTimezonesList, setFilteredTimezonesList] = useState([]);
    const [filteredTimezonesListSchedule, setFilteredTimezonesListSchedule] = useState([]);


    const popupAlert = new Audio(popupData);
    const pageTurnSound = new Audio(pageData);

    const [viewToast, setViewToast] = useState(false);
    const [toastTitle, setToastTitle] = useState(null);
    const [toastBody, setToastBody] = useState(null);
    const [toastMessageType, setToastMessageType] = useState(null);


    const [viewAddGuest, setViewAddGuest] = useState(false);
    // const [guest, setGuest] = useState("");

    const [sharedContent, setSharedContent] = useState("");
    const [reschedulePolicy, setReschedulePolicy] = useState("");
    const [guestsForReschedule, setGuestsForReschedule] = useState("");

    const [shownDateNow, setShownDateNow] = useState(dayjs.tz(new Date(), dayjs.tz.guess()).$d);
    const [newDateStyle, setNewDateStyle] = useState(dayjs.tz(new Date(), dayjs.tz.guess()).$d);


    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const timeListToMeasure = ["12:00am", "12:15am", "12:30am", "12:45am", "1:00am", "1:15am", "1:30am", "1:45am", "2:00am", "2:15am", "2:30am", "2:45am", "3:00am", "3:15am", "3:30am", "3:45am", "4:00am", "4:15am", "4:30am", "4:45am", "5:00am", "5:15am", "5:30am", "5:45am", "6:00am", "6:15am", "6:30am", "6:45am", "7:00am", "7:15am", "7:30am", "7:45am", "8:00am", "8:15am", "8:30am", "8:45am", "9:00am", "9:15am", "9:30am", "9:45am", "10:00am", "10:15am", "10:30am", "10:45am", "11:00am", "11:15am", "11:30am", "11:45am", "12:00pm", "12:15pm", "12:30pm", "12:45pm", "1:00pm", "1:15pm", "1:30pm", "1:45pm", "2:00pm", "2:15pm", "2:30pm", "2:45pm", "3:00pm", "3:15pm", "3:30pm", "3:45pm", "4:00pm", "4:15pm", "4:30pm", "4:45pm", "5:00pm", "5:15pm", "5:30pm", "5:45pm", "6:00pm", "6:15pm", "6:30pm", "6:45pm", "7:00pm", "7:15pm", "7:30pm", "7:45pm", "8:00pm", "8:15pm", "8:30pm", "8:45pm", "9:00pm", "9:15pm", "9:30pm", "9:45pm", "10:00pm", "10:15pm", "10:30pm", "10:45pm", "11:00pm", "11:15pm", "11:30pm", "11:45pm"];

    const [globalUsername, setGlobalUsername] = useState(null);
    const [eventId, setEventId] = useState(null);
    const [refreshToken, setRefreshToken] = useState("");
    const [calendarId, setCalendarId] = useState("");
    const [calendarEventId, setCalendarEventId] = useState("");
    const [meetingId, setMeetingId] = useState("");

    const [formerDate, setFormerDate] = useState(null);
    const [formerTime, setFormerTime] = useState(null);
    const [formerDayName, setFormerDayName] = useState(null);
    const [eventReferenceUUID, setEventReferenceUUID] = useState(null);
    const [meetingStatus, setMeetingStatus] = useState('active');

    const [showTimeListNow, setShowTimeListNow] = useState(false);

    const ct = require('countries-and-timezones');
    const timezones = ct.getAllTimezones();
    const allCountries = ct.getAllCountries();

    const [calendarStartDate, setCalendarStartDate] = useState(dayjs.tz(new Date(), dayjs.tz.guess()).$d);
    const [calendarEndDate, setCalendarEndDate] = useState(dayjs.tz(addDays(new Date(), 30), dayjs.tz.guess()).$d);
    const [disabledDates, setDisabledDates] = useState([]);

    const [globalOrigionalTimezone, setGlobalOrigionalTimezone] = useState(null);

    const [activeDaysToList, setActiveDaysToList] = useState([]);
    const [activeDaysToListExternal, setActiveDaysToListExternal] = useState([]);

    const [bookedCalendarSlots, setBookedCalendarSlots] = useState([]);

    const [isBookingDisabled, setIsBookingDisabled] = useState(false);

    const [format24, setFormat24] = useState({ label: "12 Hours", value: false });


    const [phoneDisabled, setPhoneDisabled] = useState(false);



    useEffect(() => {
        async function getEvent() {
            const eventId = window.location.href.split("/")[window.location.href.split("/").length - 1];

            const getDetails = await axios.post(serverUrl + "/events/decodeJWT", { "code": eventId });

            if (getDetails.status === 200) {
                // console.log(getDetails.data);

                const getEventDetails = await axios.post(serverUrl + "/events/decryptEvent", getDetails.data);

                if (getEventDetails.status === 200) {

                    // console.log(getEventDetails.data)

                    setCodeDecode(getEventDetails.data);

                    var username = null;
                    const getLatestUsername = await axios.post(serverUrl + "/users/findUserByEmail", { "email": getEventDetails.data.origionalEmailForReschedule });

                    if (getLatestUsername.status === 200) {
                        // console.log(getLatestUsername.data);

                        username = getLatestUsername.data.username;
                        setGlobalUsername(username);
                        setEventId(getEventDetails.data.eventId);
                        setRefreshToken(getEventDetails.data.refreshToken);
                        setCalendarId(getEventDetails.data.calendarId);
                        setMeetingId(getEventDetails.data.meetingId);


                        const getEventNow = {
                            "adminEmail": getEventDetails.data.adminEmailForEvent,
                            "meetingId": getEventDetails.data.meetingId,
                        }

                        const getAllMeetingDetails = await axios.post(serverUrl + "/events/getEventDetailsForEmailLinks", getEventNow);
                        if (getAllMeetingDetails.status === 200) {
                            // console.log(getAllMeetingDetails.data);

                            let allPreGuests = null;
                            if (getAllMeetingDetails.data.guests) {
                                if (getAllMeetingDetails.data.guests.includes("@")) {
                                    if (getAllMeetingDetails.data.guests.includes(",")) {
                                        allPreGuests = getAllMeetingDetails.data.guests.split(",").join(",");
                                    } else {
                                        allPreGuests = getAllMeetingDetails.data.guests;
                                    }
                                }
                            }


                            if (allPreGuests) {

                                let AllRepeatingEmails = allPreGuests.replaceAll(" ", "").split(",");

                                let filteredRepeatingEmails = [];

                                AllRepeatingEmails.map((email, key) => {
                                    if (filteredRepeatingEmails.includes(email) || email === getEventDetails.data.adminEmailForEvent || email === getAllMeetingDetails.data.email) {
                                        return
                                    } else {
                                        return filteredRepeatingEmails.push(email);
                                    }
                                });

                                // console.log(filteredRepeatingEmails)

                                allPreGuests = filteredRepeatingEmails.join(",")

                            }

                            // console.log(allPreGuests)

                            if(getAllMeetingDetails.data.meetingCategory!=="Group"){
                                setEmail(getAllMeetingDetails.data.email);
                            }

                            setGuestsForReschedule(allPreGuests);
                            setRefreshToken(getAllMeetingDetails.data.assignedCalendarRefreshToken);
                            setCalendarEventId(getAllMeetingDetails.data.calendarEventId);
                            setFormerDate(getAllMeetingDetails.data.date);
                            setFormerTime(getAllMeetingDetails.data.startTime);
                            setFormerDayName(getAllMeetingDetails.data.dayName);
                            setEventReferenceUUID(getAllMeetingDetails.data.eventReferenceUUID);

                            setMeetingStatus(getAllMeetingDetails.data.status)



                            const query = {
                                "eventReferenceUUID": getAllMeetingDetails.data.eventReferenceUUID,
                                "email": getEventDetails.data.origionalEmailForReschedule
                            };

                            // console.log(query)

                            const getEventInfo = await axios.post(serverUrl + "/events/getEventByIdReschedule", query);

                            if (getEventInfo.status === 200) {
                                setMyEvent(getEventInfo.data[0]);
                                setStopBookingBefore(parseInt(getEventInfo.data[0].stopBookingBeforeTime?.value));
                                setCreatorData(getEventInfo.data[1]);

                                setAppBranding(getEventInfo.data[1].appBranding);

                                setGlobalSettings(getEventInfo.data[1].settings);

                                getEventInfo.data[1].brandLogos.map((logo, key) => {
                                    if (logo.active === true) {
                                        // console.log(logo.logo)
                                        setBrandLink(logo.brandName)
                                        setBrandLogo(logo.logo);
                                    }
                                });

                                // console.log(getEventInfo.data);
                                setShowNow(true);
                            }
                            else {
                                // console.log(getEventInfo.data);
                                setShowNow(true);
                                setUserNotFound(true);
                            }
                        } else {
                            // console.log(getAllMeetingDetails.data);
                            setShowNow(true);
                            setUserNotFound(true);
                        }
                    } else {
                        // console.log(getLatestUsername.data)
                        setShowNow(true);
                        setUserNotFound(true);
                    }
                } else {
                    setShowNow(true);
                    setUserNotFound(true);
                    // console.log(getEventDetails.data);
                    setCodeDecode(getEventDetails.data);
                }

            } else {
                setShowNow(true);
                // console.log(getDetails.data);
                setCodeDecode(getDetails.data);
            }
        }
        getEvent();


    }, []);

    useEffect(() => {
        const selected = document.getElementsByClassName("selectedDayTab");
        if (selected.length) {
            for (var i = 0; i <= selected.length; i++) {
                selected[i].classList.remove("selectedDayTab");
            }
        }
        setCurrentMonth(getMonth(monthIndexCurrent));
        // console.log(monthIndexCurrent);

    }, [monthIndexCurrent])

    useEffect(() => {
        if (myEvent) {

            // ###################### SAPARATION #######################

            setGlobalOrigionalTimezone(myEvent.availability["timezone"].value.name);


            let startDateForCalendar;

            let todayTime = dayjs.tz(new Date(), dayjs.tz.guess()).$d;

            let convertedCalendarStartDate = dayjs.tz(dayjs.tz(new Date(myEvent.availability["schedulabel"].startDate), myEvent.availability["timezone"].value.name), dayjs.tz.guess()).$d;

            if (Date.parse(todayTime) > Date.parse(convertedCalendarStartDate)) {
                startDateForCalendar = todayTime;
            } else {
                startDateForCalendar = convertedCalendarStartDate;
            }

            let endDateForCalendar;

            let convertedCalendarEndDate = dayjs.tz(dayjs.tz(new Date(myEvent.availability["schedulabel"].endDate), myEvent.availability["timezone"].value.name), dayjs.tz.guess()).$d;

            endDateForCalendar = convertedCalendarEndDate;


            let endDateForCalendarFinal = myEvent.availability["schedulabel"].endDate ? endDateForCalendar : undefined;


            setCalendarStartDate(startDateForCalendar);
            setCalendarEndDate(endDateForCalendarFinal ? endDateForCalendarFinal : undefined);



            removeDisableddates()

            // ###################### SAPARATION #######################

            setMonthIndexCurrent(dayjs(myEvent.availability["schedulabel"].startDate).month())
            setMinMonthIndex(dayjs(myEvent.availability["schedulabel"].startDate).month());
            setMaxMonthIndex(dayjs(myEvent.availability["schedulabel"].endDate).month());
            // console.log(myEvent.availability["schedulabel"].startDate)


            let timezonesArray = Object.entries(timezones);
            let countryArray = Object.entries(allCountries);
            let arrays = [];
            timezonesArray.map((val, key) => {
                arrays.push({ label: val[0], value: val[1] });
            })
            setTimezonesList(arrays);

            let newArray = [];
            countryArray.map((val, key) => {
                newArray.push({ label: val[1].name, value: val[0] });
            })

            setCountriesList(newArray);

            const countryFromSchedule = newArray.filter((countryNames) => { return countryNames.value === myEvent.availability.timezone.value.countries[0] });

            const restTimezonesSchedule = arrays.filter((zone) => { return zone.value.countries.includes(countryFromSchedule[0].value); });
            setFilteredTimezonesListSchedule(restTimezonesSchedule);

            setSelectedTimezone(restTimezonesSchedule[0]);

            setCountrySchedule(countryFromSchedule);

        }
    }, [myEvent])

    useEffect(() => {
        if (timezonesList.length > 0) {

            let autodetect = dayjs.tz.guess();

            if (autodetect === "Asia/Calcutta") {
                autodetect = "Asia/Kolkata";
            }

            const foundedTimezone = timezonesList.filter((timezones) => {
                return timezones.value?.name === autodetect;
            })
            // console.log(foundedTimezone[0]);
            setSelectedTimezone(foundedTimezone[0]);

            // console.log(countriesList)

            let autoCountry = countriesList.filter((country) => { return country.value.includes(foundedTimezone[0].value.countries[0]) });

            // console.log(autoCountry,foundedTimezone[0].label)

            setCountrySchedule(autoCountry);

            const rest = timezonesList.filter((zone) => { return zone.value.countries.includes(autoCountry[0].value); });
            setFilteredTimezonesListSchedule(rest);
        }
    }, [countriesList, timezonesList])

    const removeDisableddates = () => {
        let disabledDatesToList = [];

        if (myEvent.availability["userSchedules"].dateOverrides) {
            if (Object.keys(myEvent.availability["userSchedules"].dateOverrides).length > 0) {
                let allDates = myEvent.availability["userSchedules"].dateOverrides;
                Object.keys(allDates).map((dated, key) => {
                    if (allDates[dated].active == false) {

                        let tomorrowVals = false;
                        let yesturdayVals = false;


                        if (getZonedTimes(addDays(dayjs(new Date(dated)).$d, -1)).tomorrow) {
                            if (getZonedTimes(addDays(dayjs(new Date(dated)).$d, -1)).tomorrow.length < 1) {
                                tomorrowVals = true;
                            }
                        }

                        if (getZonedTimes(addDays(dayjs(new Date(dated)).$d, 1)).yesturday) {
                            if (getZonedTimes(addDays(dayjs(new Date(dated)).$d, 1)).yesturday.length < 1) {
                                yesturdayVals = true;
                            }
                        }
                        if (tomorrowVals && yesturdayVals) {
                            return disabledDatesToList.push(new Date(dated));
                        }

                    }
                })
            }
        }


        setDisabledDates(disabledDatesToList);
    }

    const checkTimes = async (e) => {
        if (globalOrigionalTimezone) {
            setShowTimeListNow(false)

            const foundDate = dayjs(dayjs(e).tz(selectedTimezone.value.name)).tz(dayjs.tz.guess()).format("D MMMM YYYY");

            const nextDate = dayjs(addDays(new Date(e), 1));
            const previousDate = dayjs(addDays(new Date(e), -1));

            // console.log(previousDate, foundDate, nextDate);

            setSelectedTiming(foundDate);//changing date in entire page

            const zonedTimesPresent = getZonedTimes(e);
            const zonedTimesFuture = activeDaysToList.includes(parseInt(nextDate.format("d"))) ? [] : getZonedTimes(nextDate);
            const zonedTimesPast = activeDaysToList.includes(parseInt(previousDate.format("d"))) ? [] : getZonedTimes(previousDate);
            // console.log(zonedTimesPast, zonedTimesPresent, zonedTimesFuture)

            let FinalConvertedTiming = [];
            if (zonedTimesPast.tomorrow?.length > 0) {
                FinalConvertedTiming = [...FinalConvertedTiming, ...zonedTimesPast.tomorrow]
            }
            if (zonedTimesPresent.today?.length > 0) {
                FinalConvertedTiming = [...FinalConvertedTiming, ...zonedTimesPresent.today]
            }
            if (zonedTimesFuture.yesturday?.length > 0) {
                FinalConvertedTiming = [...FinalConvertedTiming, ...zonedTimesFuture.yesturday]
            }

            // console.log(FinalConvertedTiming)

            let removePreReserve = Math.floor(myEvent.Duration / 15) - 1;


            if (myEvent.eventType === "One on One") {


                let busyTimes = [];

                const calendarQuery = {
                    "email": creatorData.email,
                    "timeMin": `${dayjs(dayjs(`${foundDate} 12:00 am`).$d).format("YYYY-MM-DD")}T00:00:00${dayjs(dayjs(e).tz(selectedTimezone.value.name)).format("Z")}`,
                    "timeMax": `${dayjs(addDays(dayjs(`${foundDate} 12:00 am`).$d, 1)).format("YYYY-MM-DD")}T00:00:00${dayjs(dayjs(e).tz(selectedTimezone.value.name)).format("Z")}`,
                    "timeZone": selectedTimezone.value.name
                }

                const calendarDates = await axios.post(serverUrl + "/events/freeBusy", calendarQuery);
                const allCalendars = calendarDates.data.calendars;

                const beforeActive = myEvent.beforeEventTimeActive ? Math.round((myEvent.beforeEventTime + myEvent.Duration) / 15) : 0;
                const afterActive = myEvent.afterEventTimeActive ? myEvent.afterEventTime / 15 : 0;


                // console.log(allCalendars)



                Object.keys(allCalendars).map((calendarName, key) => {
                    const times = allCalendars[calendarName].busy;
                    if (times.length > 0) {
                        times.map((when, key) => {
                            let startTime = dayjs.tz(dayjs(when.start), selectedTimezone.value.name).format("h:mma");
                            let endTime = dayjs.tz(dayjs(when.end), selectedTimezone.value.name).format("h:mma");

                            let startIndex = timeListToMeasure.indexOf(startTime);
                            let endIndex = timeListToMeasure.indexOf(endTime);

                            let finalRomoveBefore = startIndex - (beforeActive + removePreReserve) < 0 ? 0 : startIndex - (beforeActive + removePreReserve);
                            let finalRomoveAfter = endIndex + afterActive;

                            let newTrimmedArray = timeListToMeasure.slice(finalRomoveBefore, finalRomoveAfter);


                            busyTimes = [...busyTimes, ...newTrimmedArray];
                        })
                    }
                })

                // console.log(busyTimes)

                let restOfThemAfterCalendarFilter = [];

                FinalConvertedTiming.map((data, key) => {
                    if (!busyTimes.includes(data)) {
                        restOfThemAfterCalendarFilter.push(data);
                    }
                })

                setTimings(restOfThemAfterCalendarFilter);

            }
            else if (myEvent.eventType === "Round Robin") {
                setTimings(FinalConvertedTiming);
            }
            else {


                let busyTimes = [];

                const calendarQuery = {
                    "email": creatorData.email,
                    "timeMin": `${dayjs(dayjs(`${foundDate} 12:00 am`).$d).format("YYYY-MM-DD")}T00:00:00${dayjs(dayjs(e).tz(selectedTimezone.value.name)).format("Z")}`,
                    "timeMax": `${dayjs(addDays(dayjs(`${foundDate} 12:00 am`).$d, 1)).format("YYYY-MM-DD")}T00:00:00${dayjs(dayjs(e).tz(selectedTimezone.value.name)).format("Z")}`,
                    "timeZone": selectedTimezone.value.name
                }

                const calendarDates = await axios.post(serverUrl + "/events/freeBusy", calendarQuery);
                const allCalendars = calendarDates.data.calendars;

                const beforeActive = myEvent.beforeEventTimeActive ? myEvent.beforeEventTime / 15 : 0;
                const afterActive = myEvent.afterEventTimeActive ? myEvent.afterEventTime / 15 : 0;


                // console.log(allCalendars)



                Object.keys(allCalendars).map((calendarName, key) => {
                    const times = allCalendars[calendarName].busy;
                    if (times.length > 0) {
                        times.map((when, key) => {
                            let startTime = dayjs.tz(dayjs(when.start), selectedTimezone.value.name).format("h:mma");
                            let endTime = dayjs.tz(dayjs(when.end), selectedTimezone.value.name).format("h:mma");

                            let startIndex = timeListToMeasure.indexOf(startTime);
                            let endIndex = timeListToMeasure.indexOf(endTime);

                            let finalRomoveBefore = startIndex - (beforeActive + removePreReserve) < 0 ? 0 : startIndex - (beforeActive + removePreReserve);
                            let finalRomoveAfter = endIndex + afterActive;

                            let newTrimmedArray = timeListToMeasure.slice(finalRomoveBefore, finalRomoveAfter);


                            busyTimes = [...busyTimes, ...newTrimmedArray];
                        })
                    }
                })

                // console.log(busyTimes)

                let restOfThemAfterCalendarFilter = [];

                FinalConvertedTiming.map((data, key) => {
                    if (!busyTimes.includes(data) && remSlotsInGroupEvent(foundDate, data) > 0) {
                        restOfThemAfterCalendarFilter.push(data);
                    } else if (busyTimes.includes(data) && remSlotsInGroupEvent(foundDate, data) < myEvent.MaxInvitees && remSlotsInGroupEvent(foundDate, data) > 0) {
                        restOfThemAfterCalendarFilter.push(data);
                    } else {
                        // console.log(data + " was busy");
                    }
                })

                // console.log(restOfThemAfterCalendarFilter)

                setTimings(restOfThemAfterCalendarFilter);
            }

            setShowTimeListNow(true);

        }
    }

    const getZonedTimes = (e) => {
        const foundDate = dayjs(dayjs(e).tz(selectedTimezone.value.name)).tz(dayjs.tz.guess()).format("D MMMM YYYY");

        const time = dayjs(dayjs(e).tz(selectedTimezone.value.name)).tz(dayjs.tz.guess());
        let isThisOverrided = false;
        let offDays = [];
        let overrideTimings = [];
        if (myEvent.availability["userSchedules"].dateOverrides) {


            Object.keys(myEvent.availability["userSchedules"].dateOverrides).map((exdf, key) => {
                if (dayjs(myEvent.availability["userSchedules"].dateOverrides[exdf].date).format("D MMMM YYYY") === foundDate && myEvent.availability["userSchedules"].dateOverrides[exdf].active === true) {
                    overrideTimings = myEvent.availability["userSchedules"].dateOverrides[exdf].timeSet;
                    isThisOverrided = true;
                } else {
                    offDays.push(exdf)
                }
            });

        }

        if (offDays.length > 0) {
            setTimings(myEvent.availability["userSchedules"].dateOverrides[offDays[0]].timeSet)
        }


        if (isThisOverrided) {
            let timeRaw = [];
            let timeArrPresent = [];
            let timeArrPast = [];
            let timeArrFuture = [];

            let removeLastTimes = Math.floor(myEvent.Duration / 15);

            let timeSetsTrim = overrideTimings;



            timeSetsTrim.map((time, key) => {
                let startIndex = timeListToMeasure.indexOf(time.split("-")[0]);
                let endIndex = timeListToMeasure.indexOf(time.split("-")[1]);
                let newTrimmedArray = timeListToMeasure.slice(startIndex, endIndex + 1);

                newTrimmedArray.map((value, key) => {
                    if (!timeRaw.includes(newTrimmedArray[key])) {
                        return timeRaw.push(newTrimmedArray[key]);
                    }
                })
            });

            timeRaw = timeRaw.slice(0, - removeLastTimes);

            timeRaw.map((value, key) => {
                if (key < timeRaw.length) {

                    switch (changeTimezone(e.toISOString(), timeRaw[key], myEvent.availability["timezone"].label, selectedTimezone.value.name).day) {
                        case "today":

                            if (timeArrPresent.includes(changeTimezone(e.toISOString(), timeRaw[key], myEvent.availability["timezone"].label, selectedTimezone.value.name).value)) {
                                return null;
                            } else {
                                const converted = changeTimezone(e.toISOString(), timeRaw[key], myEvent.availability["timezone"].label, selectedTimezone.value.name).value;
                                if (converted) {
                                    return timeArrPresent.push(converted);
                                }
                            }

                            break;
                        case "yesterday":

                            if (timeArrPast.includes(changeTimezone(e.toISOString(), timeRaw[key], myEvent.availability["timezone"].label, selectedTimezone.value.name).value)) {
                                return null;
                            } else {
                                const converted = changeTimezone(e.toISOString(), timeRaw[key], myEvent.availability["timezone"].label, selectedTimezone.value.name).value;
                                if (converted) {
                                    return timeArrPast.push(converted);
                                }
                            }

                            break;
                        case "tomorrow":

                            if (timeArrFuture.includes(changeTimezone(e.toISOString(), timeRaw[key], myEvent.availability["timezone"].label, selectedTimezone.value.name).value)) {
                                return null;
                            } else {
                                const converted = changeTimezone(e.toISOString(), timeRaw[key], myEvent.availability["timezone"].label, selectedTimezone.value.name).value;
                                if (converted) {
                                    return timeArrFuture.push(converted);
                                }
                            }

                            break;
                        default:
                            break;
                    }
                }
                else {
                    return null;
                }
            })


            return { "yesturday": timeArrPast, "today": timeArrPresent, "tomorrow": timeArrFuture }

        } else {
            let timeRaw = [];
            let timeArrPresent = [];
            let timeArrPast = [];
            let timeArrFuture = [];

            let removeLastTimes = Math.floor(myEvent.Duration / 15);

            let timeSetsTrim = myEvent.availability["userSchedules"].days[time.format("dddd").toLowerCase()].times;

            timeSetsTrim.map((time, key) => {
                let startIndex = timeListToMeasure.indexOf(time.split("-")[0]);
                let endIndex = timeListToMeasure.indexOf(time.split("-")[1]);
                let newTrimmedArray = timeListToMeasure.slice(startIndex, endIndex + 1);

                newTrimmedArray.map((value, key) => {
                    if (!timeRaw.includes(newTrimmedArray[key])) {
                        return timeRaw.push(newTrimmedArray[key]);
                    }
                })
            });

            timeRaw = timeRaw.slice(0, - removeLastTimes);

            timeRaw.map((value, key) => {
                if (key < timeRaw.length) {

                    switch (changeTimezone(e.toISOString(), timeRaw[key], myEvent.availability["timezone"].label, selectedTimezone.value.name).day) {
                        case "today":

                            if (timeArrPresent.includes(changeTimezone(e.toISOString(), timeRaw[key], myEvent.availability["timezone"].label, selectedTimezone.value.name).value)) {
                                return null;
                            } else {
                                const converted = changeTimezone(e.toISOString(), timeRaw[key], myEvent.availability["timezone"].label, selectedTimezone.value.name).value;
                                if (converted) {
                                    return timeArrPresent.push(converted);
                                }
                            }

                            break;
                        case "yesterday":

                            if (timeArrPast.includes(changeTimezone(e.toISOString(), timeRaw[key], myEvent.availability["timezone"].label, selectedTimezone.value.name).value)) {
                                return null;
                            } else {
                                const converted = changeTimezone(e.toISOString(), timeRaw[key], myEvent.availability["timezone"].label, selectedTimezone.value.name).value;
                                if (converted) {
                                    return timeArrPast.push(converted);
                                }
                            }

                            break;
                        case "tomorrow":

                            if (timeArrFuture.includes(changeTimezone(e.toISOString(), timeRaw[key], myEvent.availability["timezone"].label, selectedTimezone.value.name).value)) {
                                return null;
                            } else {
                                const converted = changeTimezone(e.toISOString(), timeRaw[key], myEvent.availability["timezone"].label, selectedTimezone.value.name).value;
                                if (converted) {
                                    return timeArrFuture.push(converted);
                                }
                            }

                            break;
                        default:
                            break;
                    }
                }
                else {
                    return null;
                }

            })

            // console.log(timeArr)

            // console.log({ "yesturday": timeArrPast, "today": timeArrPresent, "tomorrow": timeArrFuture })
            return { "yesturday": timeArrPast, "today": timeArrPresent, "tomorrow": timeArrFuture }




        }
    }

    const startAnimate = (e) => {
        const conts = document.querySelectorAll(".frameSlideIn");

        conts.forEach(childs => {
            // console.log(childs)
            childs.childNodes[0].style.width = "100%";
            childs.childNodes[0].style.borderRadius = "500px";
            childs.childNodes[1].style.transform = "translateX(101%)";
        });

        e.target.parentNode.childNodes[0].style.width = "50%";
        e.target.parentNode.childNodes[0].style.borderRadius = "500px 0 0 500px";
        e.target.parentNode.childNodes[1].style.transform = "translateX(-1%)";
    }

    const bookNow = async (e) => {
        e.preventDefault();
        // console.log(email.split("@")[0][email.split("@")[0].length - 1]);
        const custName = document.querySelector("#custName");
        const custEmail = document.querySelector("#custEmail");
        const custAddSomething = document.querySelector("#custAddSomething");
        const custAddSomething1 = document.querySelector("#custAddSomething1");
        const load = document.querySelector("#loadingHere");

        if (fullName.replaceAll(" ", "").length === 0) {

            load.disabled = false;

            load.innerHTML = 'Book Now';
            // setCustomAlertBox(true);
            // setAlertBoxMessages("Invalid Name");
            // setAlertErrorType("invalid");

            setViewToast(true);
            popupAlert.play();
            setToastTitle("Error");
            setToastBody("Invalid Name");
            setToastMessageType("invalid");
            custName.focus();
            return
        }
        else {
            if (validate(email) === false || email.split(".")[email.split(".").length - 1].length < 2 || email.split("@")[0][email.split("@")[0].length - 1] === "." || isNaN(email.split("@")[1].split(".")[0]) === false || isNaN(email.split("@")[1].split(".")[1]) === false) {


                load.disabled = false;

                load.innerHTML = 'Book Now';
                // setCustomAlertBox(true);
                // setAlertBoxMessages("Invalid Email Address.");
                // setAlertErrorType("invalid");

                setViewToast(true);
                popupAlert.play();
                setToastTitle("Error");
                setToastBody("Invalid Email Address");
                setToastMessageType("invalid");

                custEmail.focus();

                return
            }
            else {

                if (email === codeDecode.origionalEmailForReschedule || email === codeDecode.adminEmailForEvent) {
                    load.disabled = false;

                    load.innerHTML = 'Book Now';

                    setViewToast(true);
                    popupAlert.play();
                    setToastTitle("Error");
                    setToastBody("Can't set host email as invitee");
                    setToastMessageType("invalid");

                    custEmail.focus();

                    return
                } else {

                    let guest = "";
                    if (viewAddGuest || guestsForReschedule) {
                        if (myEvent.eventType !== "Group") {
                            guest = document.getElementById("multiEmailValids").value;
                        }
                    }

                    let checkAllmailsFormat = true;

                    if (myEvent.eventType) {
                        if (guest) {
                            if (guest.length > 7) {
                                guest.split(",").map((email) => {
                                    // console.log(email.length);
                                    if (validate(email) === false) {
                                        checkAllmailsFormat = false;
                                    }
                                })
                            }
                        }
                    }

                    if (checkAllmailsFormat === false) {

                        load.disabled = false;

                        load.innerHTML = 'Book Now';
                        // setCustomAlertBox(true);
                        // setAlertBoxMessages("Invalid Guest Email address");
                        // setAlertErrorType("invalid");

                        setViewToast(true);
                        popupAlert.play();
                        setToastTitle("Error");
                        setToastBody("Invalid Guest Email address");
                        setToastMessageType("invalid");

                        return
                    }
                    else {

                        if (guest && guest.split(",").length > 10) {

                            load.disabled = false;

                            load.innerHTML = 'Book Now';
                            // setCustomAlertBox(true);
                            // setAlertBoxMessages(`Maximum limit of the Guest are 10 but given ${guest.split(",").length}.`);
                            // setAlertErrorType("invalid");

                            setViewToast(true);
                            popupAlert.play();
                            setToastTitle("Error");
                            setToastBody(`Maximum limit of the Guest are 10 but given ${guest.split(",").length}.`);
                            setToastMessageType("invalid");

                            return
                        }
                        else {


                            custName.disabled = true;
                            custEmail.disabled = true;
                            custAddSomething.disabled = true;
                            custAddSomething1.disabled = true;
                            setPhoneDisabled(true);

                            load.disabled = true;

                            load.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>Loading..';

                            const startTime = selectedTimingSet.split("-")[0];
                            const endTime = selectedTimingSet.split("-")[1];

                            const id = eventId;
                            const username = globalUsername;
                            // console.log(username);

                            // console.log(selectedTimezone)


                            const day = dayjs(selectedTiming).format("D");
                            const month = dayjs(selectedTiming).format("M");
                            const year = dayjs(selectedTiming).format("YYYY");

                            const hour = dayjs(`2 November 2023 ${startTime.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("H");
                            const minute = dayjs(`2 November 2023 ${startTime.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("m");
                            const second = 0;


                            const booking = {
                                "id": id,
                                "sortNumber": Date.parse(dayjs(dayjs.tz(dayjs(`${selectedTiming} ${startTime.replaceAll("am", " am").replaceAll("pm", " pm")}`), selectedTimezone.value.name)).tz("Asia/Kolkata").format()),
                                "uuid": meetingId,
                                "newIDtoUse": uuidv4(),
                                "eventReferenceUUID": codeDecode.eventReferenceUUID,
                                "origionalEmailForReschedule": codeDecode.origionalEmailForReschedule,
                                "adminEmailForEvent": codeDecode.adminEmailForEvent,
                                "refreshToken": refreshToken,
                                "calendarId": calendarId,
                                "calendarEventId": calendarEventId,
                                "reschedulePolicy": reschedulePolicy,
                                "guestsForReschedule": guestsForReschedule,
                                "eventType": myEvent.meetingType,
                                "meetingCategory": myEvent.eventType,
                                "eventLink": myEvent.EventLink,
                                "eventName": myEvent.eventName,
                                "location": myEvent.location,
                                "inbound": myEvent.inbound,
                                "duration": myEvent.Duration,
                                "calendarIds": myEvent.calendarIds,
                                "timezone": myEvent.availability["timezone"],
                                "timezoneUTC": selectedTimezone.value.utcOffsetStr,
                                "attendees": myEvent.attendees,
                                "beforeEventTime": parseInt(myEvent.beforeEventTime),
                                "afterEventTime": parseInt(myEvent.afterEventTime),
                                "eventColor": myEvent.EventColor,
                                "description": myEvent.description,
                                "questionsForInvitee": [],
                                "username": username,
                                "name": fullName,
                                "guest": guest,
                                "givenTimezone": selectedTimezone,
                                "sharedContent": sharedContent,
                                "phoneNumber": phoneNumber,
                                "email": email,
                                "date": selectedTiming,
                                "startTime": startTime,
                                "endTime": endTime,
                                "dayName": dayjs(selectedTiming).format("dddd"),
                                "bookedDateTimeSlote": dayjs(dayjs.tz(dayjs(`${selectedTiming} ${startTime.replaceAll("am", " am").replaceAll("pm", " pm")}`), selectedTimezone.label)).tz("Asia/Kolkata").format(),
                                "origionalDateTime": dayjs.tz(dayjs.tz(`${year}-${month}-${day} ${hour}:${minute}:${second}`, selectedTimezone.label), globalSettings.selectedTimezone.label).format("dddd-D MMMM YYYY-h:mm a"),
                                "origionalTimezone": globalSettings.selectedTimezone.label
                            }
                            // console.table(booking);
                            const bookMeetingNow = await axios.post(serverUrl + `/events/${myEvent.eventType !== "Group" ? "reschedule" : "rescheduleGroup"}/`, booking);

                            // console.log(bookMeetingNow)

                            if (bookMeetingNow.status === 200) {

                                // load.disabled = false;

                                // load.innerHTML = 'Book Now';

                                // setApplyActive(false);
                                // setFullName("");
                                // setEmail("");
                                // setViewAddGuest(false);
                                // setPhoneNumber("");
                                // setSharedContent("");

                                // console.log(bookMeetingNow.data);
                                // if (window.confirm("wanna go  to succeess page?")) {
                                //     setTimeout(() => {
                                window.location.href = `/${username}/successpage/${id}/${myEvent.eventType !== "Group" ? meetingId : bookMeetingNow.data.meetingIdForSuccessCheck}`;
                                //     }, 10)
                                // }
                                // else {

                                // }
                            }
                            else {
                                if (bookMeetingNow.data) {
                                    if (bookMeetingNow.data.toString().includes("already")) {
                                        // setCustomAlertBox(true);
                                        // setAlertBoxMessages("you have already booked the event");
                                        // setAlertErrorType("invalid");

                                        setViewToast(true);
                                        popupAlert.play();
                                        setToastTitle("Error");
                                        setToastBody("You have already booked the event");
                                        setToastMessageType("invalid");


                                        custName.disabled = false;
                                        custEmail.disabled = false;
                                        custAddSomething.disabled = false;
                                        custAddSomething1.disabled = false;
                                        setPhoneDisabled(false);

                                        load.disabled = false;

                                        load.innerHTML = 'Book Now';
                                    } else {
                                        // setCustomAlertBox(true);
                                        // setAlertBoxMessages("Something went wrong. While booking your event.");
                                        // setAlertErrorType("invalid");

                                        setViewToast(true);
                                        popupAlert.play();
                                        setToastTitle("Error");
                                        setToastBody(bookMeetingNow.data);
                                        setToastMessageType("invalid");

                                        custName.disabled = false;
                                        custEmail.disabled = false;
                                        custAddSomething.disabled = false;
                                        custAddSomething1.disabled = false;
                                        setPhoneDisabled(false);

                                        load.disabled = false;

                                        load.innerHTML = 'Book Now';
                                        // console.log(bookMeetingNow.data);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    function changeTimezone(giveMeDate, giveMeTime, fromZone, toZone) {
        let hourD = 0;
        let minuteD = 0;
        if (giveMeTime.includes("am")) {
            let pureTime = giveMeTime.replace("am", "");
            let timesArray = pureTime.split(":");
            hourD = parseInt(timesArray[0]) === 12 ? 0 : parseInt(timesArray[0]);
            minuteD = parseInt(timesArray[1]);
            // console.log("time includes am")
        }
        else {
            let pureTime = giveMeTime.replace("pm", "");
            let timesArray = pureTime.split(":");
            hourD = parseInt(timesArray[0]) === 12 ? parseInt(timesArray[0]) : parseInt(timesArray[0]) + 12;
            minuteD = parseInt(timesArray[1]);
        }
        const day = dayjs(giveMeDate).format("D");
        const month = dayjs(giveMeDate).format("M");
        const year = dayjs(giveMeDate).format("YYYY");
        const hour = hourD
        const minute = minuteD
        const second = 0;

        // console.log(selectedTimezone)

        // console.log(day, month, year, hour, minute, second);

        let date = dayjs.tz(`${year}-${month}-${day} ${hour}:${minute}:${second}`, fromZone);
        // console.log(selectedTimezone)


        // console.log(dayjs.tz(date, toZone).format("D MMMM YYYY h:mma"))

        // console.log(month, parseInt(dayjs.tz(date, toZone).month())+1)

        if (parseInt(month) === parseInt(dayjs.tz(date, toZone).month()) + 1) {
            if (parseInt(day) === parseInt(dayjs.tz(date, toZone).date())) {
                // console.log('USA datetime: ' + usaTime);
                return { "day": "today", "value": dayjs.tz(date, toZone).format("h:mma") };
            } else if (parseInt(day) < parseInt(dayjs.tz(date, toZone).date())) {
                return { "day": "tomorrow", "value": dayjs.tz(date, toZone).format("h:mma") };
            } else {
                return { "day": "yesterday", "value": dayjs.tz(date, toZone).format("h:mma") };
            }
        } else if (parseInt(month) > parseInt(dayjs.tz(date, toZone).month())) {
            return { "day": "tomorrow", "value": dayjs.tz(date, toZone).format("h:mma") };
        } else {
            return { "day": "yesturday", "value": dayjs.tz(date, toZone).format("h:mma") };
        }



        //fine but what if date changed??
    }

    function remSlotsInGroupEvent(date, time) {
        // console.log(date, time.replaceAll("am", " am").replaceAll("pm", " pm"))

        const bookedDate = dayjs(dayjs.tz(dayjs(`${date} ${time.replaceAll("am", " am").replaceAll("pm", " pm")}`), selectedTimezone.value.name)).tz("Asia/Kolkata").format();


        const allDates = Object.keys(myEvent.bookedSlotsDateTime);

        if (allDates.includes(bookedDate)) {
            if (parseInt(myEvent.bookedSlotsDateTime[bookedDate]) < myEvent.MaxInvitees) {
                return myEvent.MaxInvitees - parseInt(myEvent.bookedSlotsDateTime[bookedDate]);
            } else {
                return 0
            }
        }
        else {
            return myEvent.MaxInvitees;
        }
    }

    function bookedSlotsInOneOnOneEvent(date, time) {
        // console.log(date, time.replaceAll("am", " am").replaceAll("pm", " pm"))

        const allDates = Object.keys(myEvent.bookedSlotsDateTime);
        // console.log(allDates)

        if (allDates.includes(`${date}-${time.replaceAll("am", " am").replaceAll("pm", " pm")}`)) {
            // console.log("ok found it " + time)
            return true;
        }
        else {
            // console.log("not found " + time)
            return false;
        }
    }

    function isTimeAvailable(selectedTiming, first, second) {
        const givenTime = dayjs(`${selectedTiming} ${first.replaceAll("am", " am").replaceAll("pm", " pm")}`)
        const currentTime = dayjs(`${selectedTiming} ${second.replaceAll("pm", " pm")}`)

        const dateNowAsToday = dayjs.tz(new Date(), selectedTimezone.value.name).format("D MMMM YYYY");



        if (selectedTiming === dateNowAsToday) {
            // if (givenTime.date() >= currentTime.date()) {
            //     return true
            // }
            // else 

            if (givenTime.hour() > currentTime.hour() + stopBookingBefore) {
                return true
            } else if (givenTime.hour() < currentTime.hour() + stopBookingBefore) {
                return false
            }
            else if (givenTime.hour() === currentTime.hour() + stopBookingBefore) {
                if (givenTime.minute() > currentTime.minute()) {
                    return true
                }
                else if (givenTime.minute() < currentTime.minute()) {
                    return false
                }
                else if (givenTime.minute() === currentTime.minute()) {
                    return false
                }
                else {
                    return false
                }
            }
            else {
                return false
            }

        }
        else {
            return true;
        }
    }

    const checkAllTimeList = () => {
        // Checking if there is any time available in the selected date or not. if there is no avaialable time it will show no time available for the day.


        let flag = false;
        timings.map((time, key) => {

            // console.log(time.split("-")[0] ? time.split("-")[0] : time);

            if (
                isTimeAvailable(
                    selectedTiming,
                    time,
                    dayjs().tz(selectedTimezone.value.name).format("h:mm a")
                )
                === false
            ) {

            } else {
                flag = true;
            }
        })

        return flag;    //if false no time will be available
    }

    useEffect(() => {
        const conts = document.querySelectorAll(".frameSlideIn");

        conts.forEach(childs => {
            // console.log(childs)
            childs.childNodes[0].style.width = "100%";
            childs.childNodes[0].style.borderRadius = "500px";
            childs.childNodes[1].style.transform = "translateX(101%)";
        });

        const availableTimesText = document.getElementById("AvailableTimesLocation");
        const firstTimeDiv = document.getElementById("firstTimeDiv");

        if (availableTimesText && firstTimeDiv) {
            availableTimesText.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
            firstTimeDiv.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
        }

    }, [selectedTiming]);

    useEffect(() => {
        if (myEvent) {
            if (Object.keys(selectedTimezone).length > 0) {

                let startDateForCalendar;

                let todayTime = dayjs.tz(new Date(), dayjs.tz.guess()).$d;

                let convertedCalendarStartDate = dayjs.tz(dayjs.tz(new Date(myEvent.availability["schedulabel"].startDate), selectedTimezone.value.name), dayjs.tz.guess()).$d;

                if (Date.parse(todayTime) > Date.parse(convertedCalendarStartDate)) {
                    startDateForCalendar = todayTime;
                } else {
                    startDateForCalendar = convertedCalendarStartDate;
                }

                // console.log(selectedTimezone)
                const newD = dayjs.tz(new Date(startDateForCalendar), selectedTimezone.value.name);




                let endDateForCalendar;

                let convertedCalendarEndDate = dayjs.tz(new Date(myEvent.availability["schedulabel"].endDate), selectedTimezone.value.name).$d;

                endDateForCalendar = convertedCalendarEndDate;


                let endDateForCalendarFinal = myEvent.availability["schedulabel"].endDate ? endDateForCalendar : undefined;


                // setSelectedDate(newD.$d)
                setShownDateNow(newD.$d)
                setNewDateStyle(newD.$d);
                setCalendarStartDate(newD.$d)
                setCalendarEndDate(endDateForCalendarFinal);



                // console.log(dayjs().day(6).$d)

                let disabledDays = [];
                let disabledDaysExternal = [];

                if (myEvent.availability["userSchedules"].days) {
                    if (!myEvent.availability["userSchedules"].days.sunday.active) {
                        let enabledByTomorrow = false;
                        let enabledByYesturday = false;
                        if (getZonedTimes(dayjs().day(1).$d).yesturday.length > 0) {
                            enabledByYesturday = true;
                        }

                        if (myEvent.availability["userSchedules"].days.saturday.active) {
                            if (getZonedTimes(dayjs().day(6).$d).tomorrow.length > 0) {
                                enabledByTomorrow = true;
                            }
                        }

                        if (enabledByYesturday === false && enabledByTomorrow === false) {
                            disabledDays.push(0)
                        }
                    } else {
                        let todayVals = false;
                        let tomorrowVals = false;
                        let yesturdayVals = false;
                        const sourceToday = getZonedTimes(dayjs().day(0).$d);
                        if (sourceToday.today) {
                            if (sourceToday.today.length < 1) {
                                todayVals = true;
                            }
                        }

                        const sourseTomorrow = getZonedTimes(dayjs().day(6).$d)

                        if (sourseTomorrow.tomorrow) {
                            if (sourseTomorrow.tomorrow.length < 1) {
                                tomorrowVals = true;
                            }
                        }

                        const yesturday = getZonedTimes(dayjs().day(1).$d)

                        if (yesturday.yesturday) {
                            if (yesturday.yesturday.length < 1) {
                                yesturdayVals = true;
                            }
                        }

                        if (todayVals && tomorrowVals && yesturdayVals) {
                            disabledDaysExternal.push(0);
                        }
                    }
                    if (!myEvent.availability["userSchedules"].days.monday.active) {
                        let enabledByTomorrow = false;
                        let enabledByYesturday = false;
                        if (getZonedTimes(dayjs().day(2).$d).yesturday.length > 0) {
                            enabledByYesturday = true;
                        }

                        if (myEvent.availability["userSchedules"].days.sunday.active) {
                            if (getZonedTimes(dayjs().day(0).$d).tomorrow.length > 0) {
                                enabledByTomorrow = true;
                            }
                        }

                        if (enabledByYesturday === false && enabledByTomorrow === false) {
                            disabledDays.push(1)
                        }
                    } else {
                        let todayVals = false;
                        let tomorrowVals = false;
                        let yesturdayVals = false;

                        const sourceToday = getZonedTimes(dayjs().day(1).$d)

                        if (sourceToday.today) {
                            if (sourceToday.today.length < 1) {
                                todayVals = true;
                            }
                        }

                        const sourceTomorrow = getZonedTimes(dayjs().day(0).$d)

                        if (sourceTomorrow.tomorrow) {
                            if (sourceTomorrow.tomorrow.length < 1) {
                                tomorrowVals = true;
                            }
                        }

                        const sourceYesturday = getZonedTimes(dayjs().day(2).$d)
                        if (sourceYesturday.yesturday) {
                            if (sourceYesturday.yesturday.length < 1) {
                                yesturdayVals = true;
                            }
                        }

                        if (todayVals && tomorrowVals && yesturdayVals) {
                            disabledDaysExternal.push(1);
                        }
                    }
                    if (!myEvent.availability["userSchedules"].days.tuesday.active) {
                        let enabledByTomorrow = false;
                        let enabledByYesturday = false;
                        if (getZonedTimes(dayjs().day(3).$d).yesturday.length > 0) {
                            enabledByYesturday = true;
                        }

                        if (myEvent.availability["userSchedules"].days.monday.active) {
                            if (getZonedTimes(dayjs().day(1).$d).tomorrow.length > 0) {
                                enabledByTomorrow = true;
                            }
                        }

                        if (enabledByYesturday === false && enabledByTomorrow === false) {
                            disabledDays.push(2)
                        }
                    }
                    else {
                        let todayVals = false;
                        let tomorrowVals = false;
                        let yesturdayVals = false;

                        const sourceToday = getZonedTimes(dayjs().day(2).$d);
                        if (sourceToday.today) {
                            if (sourceToday.today.length < 1) {
                                todayVals = true;
                            }
                        }

                        const sourceTomorrow = getZonedTimes(dayjs().day(1).$d)

                        if (sourceTomorrow.tomorrow) {
                            if (sourceTomorrow.tomorrow.length < 1) {
                                tomorrowVals = true;
                            }
                        }

                        const sourceYesturday = getZonedTimes(dayjs().day(3).$d)

                        if (sourceYesturday.yesturday) {
                            if (sourceYesturday.yesturday.length < 1) {
                                yesturdayVals = true;
                            }
                        }

                        if (todayVals && tomorrowVals && yesturdayVals) {
                            disabledDaysExternal.push(2);
                        }
                    }
                    if (!myEvent.availability["userSchedules"].days.wednesday.active) {
                        let enabledByTomorrow = false;
                        let enabledByYesturday = false;
                        if (getZonedTimes(dayjs().day(4).$d).yesturday.length > 0) {
                            enabledByYesturday = true;
                        }

                        if (myEvent.availability["userSchedules"].days.tuesday.active) {
                            if (getZonedTimes(dayjs().day(2).$d).tomorrow.length > 0) {
                                enabledByTomorrow = true;
                            }
                        }

                        if (enabledByYesturday === false && enabledByTomorrow === false) {
                            disabledDays.push(3)
                        }
                    }
                    else {
                        let todayVals = false;
                        let tomorrowVals = false;
                        let yesturdayVals = false;

                        const sourceToday = getZonedTimes(dayjs().day(3).$d);
                        if (sourceToday.today) {
                            if (sourceToday.today.length < 1) {
                                todayVals = true;
                            }
                        }

                        const sourceTomorrow = getZonedTimes(dayjs().day(2).$d);
                        if (sourceTomorrow.tomorrow) {
                            if (sourceTomorrow.tomorrow.length < 1) {
                                tomorrowVals = true;
                            }
                        }


                        const sourceYesturday = getZonedTimes(dayjs().day(4).$d);
                        if (sourceYesturday.yesturday) {
                            if (sourceYesturday.yesturday.length < 1) {
                                yesturdayVals = true;
                            }
                        }

                        if (todayVals && tomorrowVals && yesturdayVals) {
                            disabledDaysExternal.push(3);
                        }
                    }
                    if (!myEvent.availability["userSchedules"].days.thursday.active) {
                        let enabledByTomorrow = false;
                        let enabledByYesturday = false;
                        if (getZonedTimes(dayjs().day(5).$d).yesturday.length > 0) {
                            enabledByYesturday = true;
                        }

                        if (myEvent.availability["userSchedules"].days.wednesday.active) {
                            if (getZonedTimes(dayjs().day(3).$d).tomorrow.length > 0) {
                                enabledByTomorrow = true;
                            }
                        }

                        if (enabledByYesturday === false && enabledByTomorrow === false) {
                            disabledDays.push(4)
                        }
                    }
                    else {
                        let todayVals = false;
                        let tomorrowVals = false;
                        let yesturdayVals = false;

                        const sourceToday = getZonedTimes(dayjs().day(4).$d);
                        if (sourceToday.today) {
                            if (sourceToday.today.length < 1) {
                                todayVals = true;
                            }
                        }

                        const sourceTomorrow = getZonedTimes(dayjs().day(3).$d)

                        if (sourceTomorrow.tomorrow) {
                            if (sourceTomorrow.tomorrow.length < 1) {
                                tomorrowVals = true;
                            }
                        }

                        const sourceYesturday = getZonedTimes(dayjs().day(5).$d)

                        if (sourceYesturday.yesturday) {
                            if (sourceYesturday.yesturday.length < 1) {
                                yesturdayVals = true;
                            }
                        }

                        if (todayVals && tomorrowVals && yesturdayVals) {
                            disabledDaysExternal.push(4);
                        }
                    }
                    if (!myEvent.availability["userSchedules"].days.friday.active) {
                        let enabledByTomorrow = false;
                        let enabledByYesturday = false;
                        if (getZonedTimes(dayjs().day(6).$d).yesturday.length > 0) {
                            enabledByYesturday = true;
                        }

                        if (myEvent.availability["userSchedules"].days.thursday.active) {
                            if (getZonedTimes(dayjs().day(4).$d).tomorrow.length > 0) {
                                enabledByTomorrow = true;
                            }
                        }

                        if (enabledByYesturday === false && enabledByTomorrow === false) {
                            disabledDays.push(5)
                        }
                    } else {
                        let todayVals = false;
                        let tomorrowVals = false;
                        let yesturdayVals = false;

                        const sourceToday = getZonedTimes(dayjs().day(5).$d)
                        if (sourceToday.today) {
                            if (sourceToday.today.length < 1) {
                                todayVals = true;
                            }
                        }

                        const sourceTomorrow = getZonedTimes(dayjs().day(4).$d)
                        if (sourceTomorrow.tomorrow) {
                            if (sourceTomorrow.tomorrow.length < 1) {
                                tomorrowVals = true;
                            }
                        }
                        const sourceYesturday = getZonedTimes(dayjs().day(6).$d)
                        if (sourceYesturday.yesturday) {
                            if (sourceYesturday.yesturday.length < 1) {
                                yesturdayVals = true;
                            }
                        }

                        if (todayVals && tomorrowVals && yesturdayVals) {
                            disabledDaysExternal.push(5);
                        }
                    }
                    if (!myEvent.availability["userSchedules"].days.saturday.active) {
                        let enabledByTomorrow = false;
                        let enabledByYesturday = false;
                        if (getZonedTimes(dayjs().day(0).$d).yesturday.length > 0) {
                            enabledByYesturday = true;
                        }

                        if (myEvent.availability["userSchedules"].days.friday.active) {
                            if (getZonedTimes(dayjs().day(5).$d).tomorrow.length > 0) {
                                enabledByTomorrow = true;
                            }
                        }

                        if (enabledByYesturday === false && enabledByTomorrow === false) {
                            disabledDays.push(6)
                        }

                    } else {
                        let todayVals = false;
                        let tomorrowVals = false;
                        let yesturdayVals = false;

                        const sourceToday = getZonedTimes(dayjs().day(4).$d);

                        if (sourceToday.today) {
                            if (sourceToday.today.length < 1) {
                                todayVals = true;
                            }
                        }
                        const sourceTomorrow = getZonedTimes(dayjs().day(5).$d);


                        if (sourceTomorrow.tomorrow) {
                            if (sourceTomorrow.tomorrow.length < 1) {
                                tomorrowVals = true;
                            }
                        }
                        const sourceYesturday = getZonedTimes(dayjs().day(0).$d);
                        if (sourceYesturday.yesturday) {
                            if (sourceYesturday.yesturday.length < 1) {
                                yesturdayVals = true;
                            }
                        }

                        if (todayVals && tomorrowVals && yesturdayVals) {
                            disabledDaysExternal.push(6);
                        }
                    }
                }
                setActiveDaysToList(disabledDays);
                setActiveDaysToListExternal(disabledDaysExternal)


                removeDisableddates()

                checkTimes(newD.$d);

            }
        }
    }, [selectedTimezone])

    useEffect(() => {
        if (phoneNumber) {
            if (isPossiblePhoneNumber(phoneNumber)) {
                setIsBookingDisabled(false)
            } else {
                setIsBookingDisabled(true)
            }
        } else {
            setIsBookingDisabled(false)
        }
    }, [phoneNumber])

    const changeHandlerSchedule = (value) => {
        // console.log(value);
        setCountrySchedule(value);
        startTimeZoneSwitchSchedules(value);
    };

    const startTimeZoneSwitchSchedules = (e) => {
        const rest = timezonesList.filter((zone) => { return zone.value.countries.includes(e.value); });
        setFilteredTimezonesListSchedule(rest);
        setSelectedTimezone(rest[0]);
    }

    return (
        <>
            {
                showNow === false &&
                (
                    <div className={`min-vh-100 w-100 position-absolute top-0 left-start d-flex align-items-center justify-content-center`}>
                        <span className="spinner-border spinner-border-lg text-secondary"></span>
                    </div>
                )
            }

            {
                myEvent !== null && meetingStatus !== 'cancelled' && showNow && userNotFound === false ? (
                    <>
                        <div className={`freshMain border ${showNow ? "" : "d-none"}`}>
                            <div className='freshSecond'>
                                <div className="freshThird border insightBackground shadow p-3" style={{ maxWidth: "max-content" }}>
                                    {
                                        appBranding ?
                                            <a href="/" target='_Blank' className="freshAncore">
                                                <div className="freshBranding">
                                                    <div className="freshBrandingOne">powered by</div>
                                                    <div className="freshBrandingTwo">Schedule AI</div>
                                                </div>
                                            </a>
                                            :
                                            <a href={brandLink} target='_Blank' className="freshAncoreCustom">
                                                <div className="freshBrandingCustom">
                                                    <img src={brandLogo} alt="anchor" className='brandLogo' />
                                                </div>
                                            </a>
                                    }
                                    <div className="freshThirdMains text-dark rounded w-100">
                                        <div className='container-fluid p-0 h-100'>
                                            <div className="row h-100">
                                                <div className="col-12 col-md-12 col-lg-4 ">
                                                    <div className="container-fluid">
                                                        <div className="row">
                                                            <div className="col-12 p-0">
                                                                <button type='button' onClick={() => { window.location.href = "/calendar" }} className='btn salesforce-style rounded-circle fw-bold'>
                                                                    <i className='fa fa-arrow-left'></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 p-0">
                                                                <div className="freshDassierOneProfile">
                                                                    <div className='mb-3 d-flex flex-column align-items-center'>
                                                                        <img src={creatorData.customProfilePicture ? JSON.stringify(creatorData.customProfilePicture).length > 5 ? creatorData.customProfilePicture.image : creatorData.profilePicture : creatorData.profilePicture} alt="Avatar" className="freshDassierOneImage" />
                                                                        <h1 className="freshDasierOneName fw-bold">
                                                                            <span className='text-capitalize my-1 d-block'>{creatorData.name}</span>
                                                                            {/* {myEvent.attendees.map(((attendee) => {
                                                                            if (attendee.type === "orgenizer") {
                                                                                return attendee.name;
                                                                            }
                                                                            else {
                                                                                return false;
                                                                            }
                                                                        }))} 
                                                                        */}
                                                                        </h1>
                                                                    </div>
                                                                    <div className='w-100 d-flex flex-column justify-content-start align-items-start gap-2 p-3'>
                                                                        <div className="fs-4 text-dark">
                                                                            {
                                                                                myEvent.eventName
                                                                            }
                                                                        </div>
                                                                        <p className=' m-0 d-flex text-dark justify-content-center align-items-center gap-1 color-salesforce'>
                                                                            <i className="fa-solid fa-clock"></i>
                                                                            <span style={{ fontWeight: "600" }}>
                                                                                {
                                                                                    myEvent.Duration > 60 ?
                                                                                        myEvent.Duration % 60 === 0 ?
                                                                                            Math.floor(myEvent.Duration / 60) + " hrs" :
                                                                                            Math.floor(myEvent.Duration / 60) + " hrs " + Math.floor(myEvent.Duration % 60) + " min"
                                                                                        : Math.floor(myEvent.Duration) + " min"
                                                                                }
                                                                            </span>
                                                                        </p>
                                                                        <p className='m-0 text-muted  text-start '>
                                                                            Meeting details will be available after booking
                                                                        </p>
                                                                        <p className='m-0 mt-2 color-salesforce text-start' style={{ fontWeight: "500" }}>Former Time</p>
                                                                        <p className=' color-salesforce text-start' style={{ fontWeight: "500" }}>
                                                                            {
                                                                                `${formerTime}, ${formerDayName}, ${formerDate}`
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`col-12 niceLeftBorderLg col-sm-7 col-md-7 col-lg-5 col-12 d-flex flex-column justify-content-start align-items-center p-3 p-lg-4`}>
                                                    <hr className='text-light m-0 d-lg-none d-md-none'></hr>
                                                    <h2 className='h5 pt-3  text-dark d-lg-none d-md-none'>Select a Day</h2>
                                                    <div className='cotrolTheCalendarSize'>
                                                        <h2 className='h6 text-center text-dark d-none d-lg-block d-md-block'>Select Date & Time</h2>
                                                        {/* <div className='d-flex align-items-center pt-3 pb-3 justify-content-between fs-5'>
                                                            
                                                            <button type='button' disabled={monthIndexCurrent <= minMonthIndex} onClick={() => { setMonthIndexCurrent(monthIndexCurrent - 1); }} className={`${monthIndexCurrent >= maxMonthIndex ? "cursor-not-allowed" : "cursor-pointer"} salesforce-style circleBbutton text-dark rounded-pill`}>
                                                                <ArrowBackIcon />
                                                            </button>
                                                            <span className='text-dark'>
                                                                {dayjs(new Date(dayjs().year(), monthIndexCurrent)).format("MMMM YYYY")}
                                                            </span>
                                                            <button type='button' disabled={monthIndexCurrent >= maxMonthIndex} onClick={() => { setMonthIndexCurrent(monthIndexCurrent + 1); }} className={`${monthIndexCurrent >= maxMonthIndex ? "cursor-not-allowed" : "cursor-pointer"} salesforce-style circleBbutton text-dark rounded-pill`}>
                                                                <ArrowForwardIcon />
                                                            </button>
                                                        </div> */}

                                                        <div className="d-flex justify-content-center w-100 transparentCalendar text-dark">
                                                            <Calendar
                                                                showSelectionPreview={true}
                                                                shownDate={shownDateNow}
                                                                minDate={calendarStartDate}
                                                                maxDate={calendarEndDate}
                                                                disabledDates={disabledDates}
                                                                disabledDay={
                                                                    (date) => {
                                                                        const day = date.getDay();
                                                                        if (activeDaysToList.includes(day) || activeDaysToListExternal.includes(day)) {

                                                                            // const present = getZonedTimes(date);
                                                                            // const future = getZonedTimes(dayjs(addDays(new Date(date), 1)));
                                                                            // const past = getZonedTimes(dayjs(addDays(new Date(date), -1)));

                                                                            // console.log(present)

                                                                            return true
                                                                        }
                                                                    }
                                                                }
                                                                color='#0b5cab'
                                                                date={newDateStyle}
                                                                onChange={(e) => { setNewDateStyle(e); checkTimes(e); }}
                                                            />
                                                        </div>

                                                        {/* <div className="d-flex justify-content-center w-100 transparentCalendar text-dark" onClick={(e) => { checkTimes(e); }}>
                                                            <MonthBooking userSchedules={myEvent.availability["userSchedules"]} selectedDayTab={currentSelectedDayTab} month={currentMonth} schedulable={myEvent.availability["schedulabel"] ? myEvent.availability["schedulabel"] : false} eventType={myEvent.eventType} highlights={myEvent.availability["dateTimes"]} />
                                                        </div> */}

                                                        <div className='d-flex gap-1 align-items-center mt-3'>
                                                            {/* <i className='fa fa-globe text-dark'></i>
                                                            <TimezoneSelect
                                                                className='custom-timezone-selector w-100'
                                                                value={selectedTimezone}
                                                                onChange={setSelectedTimezone}
                                                            // timezones={{
                                                            //   ...allTimezones,
                                                            //   'America/Lima': 'Pittsburgh',
                                                            //   'Europe/Berlin': 'Frankfurt',
                                                            // }}
                                                            /> */}

                                                            <div className='d-flex flex-column align-items-start gap-3 h-100 w-100'>

                                                                <div className='d-flex align-items-start flex-column justify-content-between gap-1 h-100 w-100' style={{ position: "relative", zIndex: "4" }}>
                                                                    <label htmlFor='timezoneGds'>Country *</label>
                                                                    <div className='w-100'>
                                                                        <Select options={countriesList} value={countrySchedule} onChange={changeHandlerSchedule} />
                                                                    </div>
                                                                </div>

                                                                <div className='d-flex align-items-start flex-column justify-content-between gap-1 h-100 w-100' style={{ position: "relative", zIndex: "3" }}>
                                                                    <label htmlFor='timezoneGds'>TIME ZONE</label>
                                                                    <div className='w-100'>
                                                                        <Select value={[selectedTimezone]} options={filteredTimezonesListSchedule} onChange={(e) => { setSelectedTimezone(e); }} />
                                                                    </div>
                                                                </div>

                                                                <div className='d-flex align-items-start flex-column justify-content-between gap-1 h-100 w-100' style={{ position: "relative", zIndex: "2" }}>
                                                                    <label htmlFor='timezoneGds'>Time Format</label>
                                                                    <div className='w-100'>
                                                                        <Select value={[format24]} options={[{ label: "12 Hours", value: false }, { label: "24 Hours", value: true }]} onChange={(e) => { setFormat24(e) }} />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`col-12 col-sm-5 col-md-5 col-lg-3 p-4 p-md-4 p-lg-1 niceLeftBorderLg niceLeftBorderMd`}>
                                                    <div className={`d-flex flex-column justify-content-start`}>
                                                        <p className='alert alert-light rounded-pill bg-light bg-gradient mt-0  mt-lg-5 text-center fw-bold'>
                                                            {
                                                                dayjs(selectedTiming).format("D MMM YYYY")
                                                            }
                                                        </p>
                                                        <div className="text-dark p-2 text-center h5" id="AvailableTimesLocation">Available Times</div>
                                                        {
                                                            showTimeListNow ?
                                                                <div style={{ maxHeight: "60vh", overflow: "auto" }}>
                                                                    {
                                                                        timings ?
                                                                            timings.length > 0 ?
                                                                                checkAllTimeList() === false ?
                                                                                    <>
                                                                                        <p className='fw-bold text-muted m-0 p-0 text-center'>No time available for {dayjs(selectedTiming).format("D MMM YYYY")}.</p>
                                                                                        <p className='m-0 p-0 text-muted text-center'>Please Select Another Date</p>
                                                                                    </>
                                                                                    :

                                                                                    timings.map((time, key) => {
                                                                                        if (myEvent.eventType !== "Group") {

                                                                                            if (isTimeAvailable(selectedTiming, time, dayjs().tz(selectedTimezone.value.name).format("h:mm a")) === false) {
                                                                                                return "";
                                                                                            }
                                                                                            else {
                                                                                                return (
                                                                                                    <React.Fragment key={key}>
                                                                                                        <div className='frameSlideIn p-1 d-flex gap-2' id={key == 0 ? "firstTimeDiv" : ""}>
                                                                                                            <div onClick={(e) => { startAnimate(e); }} className='timeShrink'>
                                                                                                                {
                                                                                                                    myEvent.eventType !== "Round Robin" ?
                                                                                                                        bookedSlotsInOneOnOneEvent(selectedTiming, time) ?
                                                                                                                            <button disabled={true} className='btn btn-danger p-1 rounded-circle'></button>
                                                                                                                            :
                                                                                                                            isTimeAvailable(selectedTiming, time, dayjs().tz(selectedTimezone.value.name).format("h:mm a")) === false ?
                                                                                                                                <button disabled={true} className='btn btn-danger p-1 rounded-circle'></button>
                                                                                                                                :
                                                                                                                                <button disabled={true} className='btn btn-success p-1 rounded-circle'></button>
                                                                                                                        :
                                                                                                                        <button disabled={true} className='btn btn-success p-1 rounded-circle'></button>
                                                                                                                }
                                                                                                                {
                                                                                                                    format24.value ?
                                                                                                                        dayjs(`2 November 2023 ${time.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm")
                                                                                                                        :
                                                                                                                        time
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className='timeShrinkBtn'
                                                                                                                disabled={
                                                                                                                    bookedSlotsInOneOnOneEvent(selectedTiming, time)
                                                                                                                    ||
                                                                                                                    isTimeAvailable(selectedTiming, time, dayjs().tz(selectedTimezone.value.name).format("h:mm a")) === false
                                                                                                                }
                                                                                                                onClick={() => {
                                                                                                                    setApplyActive(true);
                                                                                                                    setSelectedTimingSet(time);
                                                                                                                }}>
                                                                                                                Confirm
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </React.Fragment>
                                                                                                )

                                                                                            }

                                                                                        } else {
                                                                                            if (remSlotsInGroupEvent(selectedTiming, time) < 1 || isTimeAvailable(selectedTiming, time, dayjs().tz(selectedTimezone.value.name).format("h:mm a")) === false ? true : false) {
                                                                                                return "";
                                                                                            }
                                                                                            else {
                                                                                                return (
                                                                                                    <React.Fragment key={key}>
                                                                                                        <div className='frameSlideIn p-1 d-flex gap-2'>
                                                                                                            <button onClick={(e) => { startAnimate(e); }} className='timeShrink'>
                                                                                                                {
                                                                                                                    remSlotsInGroupEvent(selectedTiming, time) < 1 ?
                                                                                                                        <button disabled={true} className='btn btn-danger p-1 rounded-circle'></button>
                                                                                                                        :
                                                                                                                        isTimeAvailable(selectedTiming, time, dayjs().tz(selectedTimezone.value.name).format("h:mm a")) === false ?
                                                                                                                            <button disabled={true} className='btn btn-danger p-1 rounded-circle'></button>
                                                                                                                            :
                                                                                                                            <button disabled={true} className='btn btn-success p-1 rounded-circle'></button>
                                                                                                                }
                                                                                                                {
                                                                                                                    format24.value ?
                                                                                                                        dayjs(`2 November 2023 ${time.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm")
                                                                                                                        :
                                                                                                                        time
                                                                                                                }
                                                                                                            </button>
                                                                                                            <button className='timeShrinkBtn' disabled={
                                                                                                                remSlotsInGroupEvent(selectedTiming, time) < 1
                                                                                                                    ||
                                                                                                                    isTimeAvailable(selectedTiming, time, dayjs().tz(selectedTimezone.value.name).format("h:mm a")) === false ?
                                                                                                                    true : false
                                                                                                            }
                                                                                                                onClick={() => { setApplyActive(true); setSelectedTimingSet(time); }}>
                                                                                                                <span className='mediumTextSize'>Confirm</span>

                                                                                                                {
                                                                                                                    myEvent.DisplaySpots && myEvent.eventType === "Group" ?
                                                                                                                        <p className='m-0 smallTextSize slotsLeftText text-center w-100'>
                                                                                                                            {`${remSlotsInGroupEvent(selectedTiming, time)} slots left`}
                                                                                                                        </p>
                                                                                                                        : ""
                                                                                                                }
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                            }
                                                                                        }
                                                                                    })
                                                                                :
                                                                                <>
                                                                                    <p className='fw-bold text-muted m-0 p-0 text-center'>No time available for {dayjs(selectedTiming).format("D MMM YYYY")}.</p>
                                                                                    <p className='m-0 p-0 text-muted text-center'>Please Select Another Date</p>
                                                                                </>
                                                                            :
                                                                            <div className="w-100 d-flex align-items-center justify-content-center gap-3">
                                                                                <div className="spinner-grow spinner-grow-sm text-dark" role="status">
                                                                                    <span className="sr-only"></span>
                                                                                </div>
                                                                                <div className="spinner-grow spinner-grow-sm text-dark" role="status">
                                                                                    <span className="sr-only"></span>
                                                                                </div>
                                                                                <div className="spinner-grow spinner-grow-sm text-dark" role="status">
                                                                                    <span className="sr-only"></span>
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                                :
                                                                <div className="w-100 d-flex align-items-center justify-content-center gap-3">
                                                                    <div className="spinner-grow spinner-grow-sm text-dark" role="status">
                                                                        <span className="sr-only"></span>
                                                                    </div>
                                                                    <div className="spinner-grow spinner-grow-sm text-dark" role="status">
                                                                        <span className="sr-only"></span>
                                                                    </div>
                                                                    <div className="spinner-grow spinner-grow-sm text-dark" role="status">
                                                                        <span className="sr-only"></span>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* ############################################################################################################ */}
                        <Modal
                            show={applyActive}
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            onHide={() => {
                                setApplyActive(false);
                                setFullName("");
                                setEmail("");
                                setViewAddGuest(false);
                                setPhoneNumber("");
                                setSharedContent("");
                                setReschedulePolicy("");
                            }}
                        >
                            <Modal.Header className='salesforce-style py-2' closeButton closeVariant='white'>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Reschedule This Event
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form method='post' onSubmit={(e) => { bookNow(e); }}>
                                    <div className="card-header text-dark">
                                        <p className='text-dark' style={{ fontWeight: "600", fontSize: "1.2rem" }}>
                                            {
                                                dayjs(selectedTiming).format("D MMM YYYY")
                                            }
                                        </p>
                                        <p className='color-salesforce'>
                                            <i className="fa-solid fa-clock"></i>
                                            <span className='px-1' style={{ fontWeight: "600" }}>
                                                {selectedTimingSet}
                                            </span>
                                        </p>
                                        <p className='text-dark' style={{ fontWeight: "500" }}>
                                            <i className='fa fa-globe'></i>
                                            <span className='px-1'>
                                                {
                                                    selectedTimezone.value ? selectedTimezone.value.name : ""
                                                }
                                            </span>
                                        </p>
                                    </div>
                                    <div className='card-body d-flex flex-column align-items-start gap-3'>
                                        <label htmlFor="custName" className='w-100'>
                                            <span className="text-dark" style={{ fontWeight: 500 }}>Name*</span><br />
                                            <input id="custName" required type="text" value={fullName} onChange={(e) => { setFullName(e.target.value); }} className='customInputFieldFocus w-100' />
                                        </label>
                                        <label htmlFor="custEmail" className='w-100'>
                                            <span className="text-dark" style={{ fontWeight: 500 }}>Email*</span><br />
                                            <input id="custEmail" required type="email" value={email} onChange={(e) => { setEmail(e.target.value) }} className='customInputFieldFocus w-100' />
                                        </label>

                                        {
                                            myEvent.eventType !== "Group" ?
                                                viewAddGuest || guestsForReschedule?.length >= 1 ?
                                                    <>
                                                        <div className="d-flex flex-column justify-content-start align-items-start w-100">
                                                            <label htmlFor="custGuests" className='w-100'>
                                                                <span className='text-dark' style={{ fontWeight: 500 }}>Guests Emails:</span><br />
                                                                <MultiEmailsReschedule id="custGuests" prevGuests={guestsForReschedule} />
                                                                {/* <textarea id="custGuests" className='form-control booking-textarea' value={guest} onChange={(e) => { setGuest(e.target.value); }} placeholder='someone@mail.com, someone@mail.com...' /> */}
                                                            </label>
                                                            <span className='text-muted w-100 smallTextSize'>
                                                                Notify up to 10 additional guests of the scheduled event.
                                                            </span>
                                                        </div>
                                                    </>
                                                    :
                                                    <button className='addGuestButton py-1 px-3 rounded-pill' onClick={(e) => { setViewAddGuest(true); }}>Add Guest</button>
                                                :
                                                ""
                                        }
                                        {
                                            myEvent.meetingType === "phone" && myEvent.inbound === false && (
                                                <>
                                                    <label htmlFor="custPhone" className='w-100'>
                                                        <span className="text-dark">Mobile*</span><br />
                                                        <PhoneInput
                                                            id="custPhone"
                                                            required
                                                            className='customInputFieldFocus w-100 shadow-sm py-3 phoneNUmberInpurJHSGFUGDSFIY'
                                                            // placeholder="Enter phone number"
                                                            disabled={phoneDisabled}
                                                            value={phoneNumber}
                                                            onChange={setPhoneNumber} />
                                                    </label>
                                                    {
                                                        isBookingDisabled ?
                                                            <div className="alert alert-danger p-1 w-100 text-center">Invalid Phone</div>
                                                            :
                                                            ""
                                                    }
                                                </>
                                            )
                                        }
                                        <label htmlFor="custAddSomething" className='w-100'>
                                            <span className="text-dark" style={{ fontWeight: 500 }}>{myEvent.inviteeQuestions?.data}</span><br />
                                            {
                                                myEvent.inviteeQuestions?.required ?
                                                    <textarea id="custAddSomething" required value={sharedContent} onChange={(e) => { if (e.target.value.trim().length > 0) { setSharedContent(e.target.value); } else { setSharedContent(""); } }} className='customInputFieldFocus w-100 booking-textarea' />
                                                    :
                                                    <textarea id="custAddSomething" value={sharedContent} onChange={(e) => { if (e.target.value.trim().length > 0) { setSharedContent(e.target.value); } else { setSharedContent(""); } }} className='customInputFieldFocus w-100 booking-textarea' />
                                            }
                                        </label>

                                        <label htmlFor="custAddSomething" className='w-100'>
                                            <span className="text-dark" style={{ fontWeight: 500 }}>Reason for Reschedule</span><br />
                                            {
                                                myEvent.inviteeQuestions?.required ?
                                                    <textarea id="custAddSomething1" required value={reschedulePolicy} onChange={(e) => { if (e.target.value.trim().length > 0) { setReschedulePolicy(e.target.value); } else { setReschedulePolicy(""); } }} className='customInputFieldFocus w-100 booking-textarea' />
                                                    :
                                                    <textarea id="custAddSomething1" value={reschedulePolicy} onChange={(e) => { if (e.target.value.trim().length > 0) { setReschedulePolicy(e.target.value); } else { setReschedulePolicy(""); } }} className='customInputFieldFocus w-100 booking-textarea' />
                                            }
                                        </label>
                                    </div>


                                    {/* 
                                            <div className="card-footer text-center">
                                                <button type="button" onClick={() => { setApplyActive(false); }} className='my-2 mx-1 px-5 mr-1 btn rounded-pill btn-danger'>cancel</button>
                                                <button type="submit" id="loadingHere" className='my-2 mx-1 px-5 btn rounded-pill totalUpcomingMeetings'>
                                                    <span>Book Now</span>
                                                </button>
                                            </div> 
                                            */}

                                    <div className="card-footer text-center d-flex justify-content-center">
                                        <button type="reset" onClick={() => {
                                            setApplyActive(false);
                                            setFullName("");
                                            setEmail("");
                                            setViewAddGuest(false);
                                            setPhoneNumber("");
                                            setSharedContent("");
                                            setReschedulePolicy("");
                                        }} className="my-2 mx-1 w-100 mr-1 btn btn-sm rounded-pill btn-secondary">Cancel</button>
                                        <button type="submit" disabled={isBookingDisabled} id="loadingHere" className="my-2 mx-1 w-100 btn btn-sm rounded-pill salesforce-style d-flex gap-3 align-items-center justify-content-center">
                                            <span>Book Now</span>
                                        </button>
                                    </div>
                                </form>
                            </Modal.Body>
                        </Modal>
                        {/* ############################################################################################################ */}
                    </>
                )
                    :
                    myEvent !== null && meetingStatus === 'cancelled' && showNow && userNotFound === false ?
                        (
                            <>
                                <div className={`freshMain ${showNow ? "" : "d-none"}`}>
                                    <div className='freshSecond'>
                                        <div className="freshThird border insightBackground shadow p-3">
                                            {
                                                appBranding ?
                                                    <a href="/" target='_Blank' className="freshAncore">
                                                        <div className="freshBranding">
                                                            <div className="freshBrandingOne">powered by</div>
                                                            <div className="freshBrandingTwo">Schedule AI</div>
                                                        </div>
                                                    </a>
                                                    :
                                                    <a href={brandLink} target='_Blank' className="freshAncoreCustom">
                                                        <div className="freshBrandingCustom">
                                                            <img src={brandLogo} alt="anchor" className='brandLogo' />
                                                        </div>
                                                    </a>
                                            }
                                            <div className="w-100 rounded">
                                                <div className={`container-fluid h-100`}>
                                                    <div className="row h-100 d-flex align-items-center justify-content-center">
                                                        <div className="col-12 p-4 h-100 d-flex flex-column justify-content-center align-items-center">
                                                            <h6 className='text-dark text-center'>
                                                                <img src={notFoundPng} className="w-100" style={{ maxWidth: "350px" }} alt="not found" />
                                                                <p className='color-salesforce mt-5 display-6' style={{ fontWeight: "600" }}>Cancelled</p>
                                                                <p className='mediumTextSize text-muted mb-2'>The event you are looking for is cancelled.</p>
                                                            </h6>
                                                            <button type='button' onClick={() => { window.location.href = "/calendar"; }} className='btn btn-sm salesforce-style rounded-pill px-3'>
                                                                Back
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                        :
                        (
                            <>
                                <div className={`freshMain ${showNow ? "" : "d-none"}`}>
                                    <div className='freshSecond'>
                                        <div className="freshThird border insightBackground shadow p-3">
                                            {
                                                appBranding ?
                                                    <a href="/" target='_Blank' className="freshAncore">
                                                        <div className="freshBranding">
                                                            <div className="freshBrandingOne">powered by</div>
                                                            <div className="freshBrandingTwo">Schedule AI</div>
                                                        </div>
                                                    </a>
                                                    :
                                                    <a href={brandLink} target='_Blank' className="freshAncoreCustom">
                                                        <div className="freshBrandingCustom">
                                                            <img src={brandLogo} alt="anchor" className='brandLogo' />
                                                        </div>
                                                    </a>
                                            }
                                            <div className="w-100 rounded">

                                                <div className={`container-fluid h-100`}>
                                                    <div className="row h-100 d-flex align-items-center justify-content-center">
                                                        <div className="col-12 p-4 h-100 d-flex flex-column justify-content-center align-items-center">
                                                            <h6 className='text-dark text-center'>
                                                                <img src={notFoundPng} className="w-100" style={{ maxWidth: "350px" }} alt="not found" />
                                                                <p className='color-salesforce mt-5 display-6' style={{ fontWeight: "600" }}>Broken URL</p>
                                                                <p className='mediumTextSize text-muted mb-2'>It seems the Meeting You Are Looking for Doesn't exist anymore.</p>
                                                                <p className='mediumTextSize text-muted mb-2'>Please check the link or ask for a new link</p>
                                                            </h6>
                                                            <button type='button' onClick={() => { window.location.href = "/calendar"; }} className='btn btn-sm salesforce-style rounded-pill px-3'>
                                                                Back
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
            }

            {/* ############################################################################################################ */}
            {/* CUSTOM ALERT BOX*/}
            <div className={`alertBoxBakcground ${customAlertBox ? "" : "d-none"}`} >
                <div className="alertBox glassmorphismLigher">
                    <div className='alertBoxMessages'>
                        <img className='alertImageType' src={alertErrorType === "invalid" ? invalidPng : successPng} alt="alert Image" />
                        <span className="alertMessageContent">{alertBoxMessages}</span>
                        <button className='btn salesforce-style px-3 text-center rounded-pill my-3' onClick={() => { setCustomAlertBox(false); }}>OK</button>
                    </div>
                </div>
            </div>
            {/* ############################################################################################################ */}


            {/* #################################### TOAST FRAME ##################################### */}

            <div style={{ position: "fixed", bottom: "10px", right: "10px", zIndex: "99999999" }}>
                <Toast onClose={() => setViewToast(false)} show={viewToast} delay={3000} autohide className='overflow-hidden'>
                    <Toast.Header>
                        {/* <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2 bg-primary"
                        alt="toastImage"
                        /> */}
                        <span style={{ fontWeight: "500" }} className={`me-auto ${toastMessageType === "invalid" ? "text-danger" : "text-success"}`}>{toastTitle}</span>
                        <small>just now</small>
                    </Toast.Header>
                    <Toast.Body style={toastMessageType === "success" ? { fontWeight: "500", background: "#d1e7dd", color: "#0f5132" } : { fontWeight: "500", background: "#f8d7da", color: "#842029" }}>
                        {toastBody}
                    </Toast.Body>
                </Toast>
            </div>

            {/* #################################### TOAST FRAME ##################################### */}


        </>
    )
}
