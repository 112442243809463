import React from 'react'
import InsuranceBannerPng from "../../images/Insurance Banner.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';

const Insurance = () => {
    return (
        <>
            <div className="heWhoRemains">
                <Header />
                <div>
                    <section>
                        <div className="container mt-5">
                            <div className="row">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">
                                        <h1 className=" display-4 fw-bold smd_text">Schedule AI for <br />
                                            <span style={{ color: "#0B5ED7" }}> Insurance Sector
                                            </span>
                                        </h1>
                                        <p className="lead_smd text-black" style={{ fontSize: "1.1em" }}>
                                            ScheduleAI software for businesses in the insurance sector. This type of software can help to improve efficiency and productivity within the business, as well as provide a number of other benefits.
                                        </p>
                                        <a href="/login"><button
                                            className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                                            Demo</button></a>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="w-100" src={InsuranceBannerPng} alt='Insurance Banner' />
                                </div>
                            </div>
                        </div>
                    </section>


                    <section>
                        <div className="container mt-5">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold text-center">Benefits of Schedule AI In the <br />Insurance Sector

                                </h2>
                            </div>
                            <ul>
                                <li className='liListDevind'>ScheduleAI software can be used to automate a number of tasks, such as sending reminders to customers and booking appointments. This means that businesses can spend less time on administrative tasks, and more time on serving their customers.
                                </li>

                                <li className='liListDevind'> It can help to improve customer service. This is because businesses can use the software to track customer preferences and schedule appointments accordingly. This means that customers are more likely to have a positive experience when they visit the business, which could lead to them becoming loyal customers.

                                </li>

                                <li className='liListDevind'>This software is user-friendly and allows customers to easily book appointments online or via a mobile app. This is extremely convenient for busy customers who may not have time to visit a physical office.
                                </li>

                                <li className='liListDevind'>It can help to keep track of customer preferences and contact information. This means that businesses can provide a better service by tailoring their appointments to each individual customer.
                                </li>

                                <li className='liListDevind'> By using this type of software, you can keep track of when appointments are scheduled, as well as cancel or reschedule them as needed. This can be a huge time-saver, especially if you have a lot of appointments to keep track of.
                                </li>
                                <li className='liListDevind'> Another big benefit is that it can help to reduce no-shows. When clients have to schedule their own appointments, there is always the risk that they will forget or double-book.
                                </li>
                            </ul>

                        </div>
                    </section>

                    <section>
                        <div className="container textDevLay pb-4">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold">Conclusion
                                </h2>
                                <p className='lead_smd text-justify'>Overall, there are many benefits of using this software for businesses in the insurance sector. By taking advantage of these benefits, businesses can improve their operations and bottom line.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default Insurance
