import React, { useEffect } from 'react';
import BannerScheduleAiForAerospacePng from "../../images/Banner ScheduleAi for Aerospace.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { motion } from "framer-motion";

const AerospaceAndDefence = () => {

    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <>
            <div className="heWhoRemains">
                <Header />
                <div>
                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-up"
                                    data-aos-duration="2000">
                                    <div className="mt-4 ">

                                        <h1 className="fw-bold mainHomeTitle">Schedule AI for the
                                            <span style={{ color: "#0B5ED7" }}> Aerospace & Defence</span>
                                        </h1>
                                        <p className="lead_smd">
                                            Using ScheduleAI software can help to cut down on costs associated with missed or double-booked appointments, as well as wasted time and resources. In short, there are plenty of good reasons to consider using appointment scheduling software in the aerospace and defence sector.
                                        </p>
                                        <a href="/login"><motion.button
                                            className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit" whileHover={{ scale: 1.2 }}>Get a
                                            Demo</motion.button></a>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="mainDevImg" src={BannerScheduleAiForAerospacePng} alt="Banner ScheduleAi for Aerospace" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className='mt-5'>
                        <div className="container">
                            <div className="row webDevRow">
                                <div className="col-12">

                                    <h3 className="mt-4 text-center display-6 fw-bold">Benefits of Schedule AI for Aerospace & <br />Defence Sector</h3>

                                    <p className="lead_smd pt-3">It can save time and improve efficiency when booking appointments. This is because the software can automate various tasks associated with appointment scheduling, such as sending reminders to customers or checking for conflicts.
                                    </p>
                                    <p className="lead_smd">ScheduleAI software can also help to streamline communication between different departments within a company, which can further improve efficiency.
                                    </p>
                                    <p className="lead_smd"> It can help to improve customer satisfaction. This is because customers can easily view available appointments and book them online or via a mobile app.</p>

                                    <p className='lead_smd'>
                                        It can help streamline the process of booking appointments and managing bookings. This can save considerable time and hassle for managers and employees alike.
                                    </p>

                                    <p className='lead_smd'>
                                        It can also help to improve customer satisfaction by ensuring that appointments are booked in a timely and efficient manner.

                                    </p>

                                    <p className='lead_smd'>
                                        It can help to streamline communication between different parties involved in the sector, making coordination of appointments and other important events much simpler. This in turn can lead to greater efficiency and productivity, as well as improved customer satisfaction levels.

                                    </p>

                                </div>
                            </div>
                        </div>
                    </section>


                    <section>
                        <div className="container textDevLay pb-4">
                            <div className="column pt-5">
                                <h2 className="text-center display-6 fw-bold">Conclusion
                                </h2>
                                <p className='lead_smd text-center'>Overall, there are many benefits of using this software in the aerospace and defence sector. Companies that make use of this type of software can experience improved efficiency and customer satisfaction levels, which can ultimately lead to increased profits.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
            </div>

        </>
    )
}

export default AerospaceAndDefence
