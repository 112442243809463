import React from 'react';
import accentureClientLogoPng from "../../images/client/accenture client logo.png";
import aptoClientLogoPng from "../../images/client/apto client logo.png";
import berkeleyClientLogoPng from "../../images/client/berkeley client logo.png";
import leanplumClientLogoPng from "../../images/client/leanplum client logo.png";
import lloydspharmacyClientLogoPng from "../../images/client/lloydspharmacy client logo.png";
import moveClientLogoPng from "../../images/client/move client logo.png";
import NationalUltrasoundClientLogoPng from '../../images/client/National Ultrasound client logo.png';
import NaturewiseClientLogoPng from "../../images/client/Naturewise clientLogo.png";
import newsweaverClientLogoPng from "../../images/client/newsweaver client logo.png";
import nexfordUniversityClientLogoPng from "../../images/client/nexford university client logo.png";
import ticketmasterClientLogoPng from "../../images/client/ticketmaster client logo.png";
import XchangingClientLogoPng from "../../images/client/Xchanging client logo.png";

const Slider = () => {
    return (
        <>
            <section className='sliderWrapper bg-light py-5'>
                <div className="SliderContainer">
                    <div className="product_content-wrapper">
                        <h2 className="text-center product_maintitle">Simplified solution being used by fortune <span className="fw-bold"
                            style={{ color: "#0B5ED7" }}>500 </span>
                            companies worldwide</h2>
                    </div>
                    <div className="slider">
                        <div className="slide-track">
                            <div className="slide">
                                <img src={accentureClientLogoPng} style={{ width: "125px" }} />
                            </div>
                            <div className="slide">
                                <img src={aptoClientLogoPng} style={{ width: "125px" }} />
                            </div>
                            <div className="slide">
                                <img src={berkeleyClientLogoPng} style={{ width: "125px" }} />
                            </div>
                            <div className="slide">
                                <img src={leanplumClientLogoPng} style={{ width: "125px", }} />
                            </div>
                            <div className="slide">
                                <img src={lloydspharmacyClientLogoPng} style={{ width: "125px" }} />
                            </div>
                            <div className="slide">
                                <img src={moveClientLogoPng} style={{ width: "125px" }} />
                            </div>
                            <div className="slide">
                                <img src={NationalUltrasoundClientLogoPng} style={{ width: "125px", }} />
                            </div>
                            <div className="slide">
                                <img src={NaturewiseClientLogoPng} style={{ width: "125px", }} />
                            </div>
                            <div className="slide">
                                <img src={newsweaverClientLogoPng} style={{ width: "125px", }} />
                            </div>
                            <div className="slide">
                                <img src={nexfordUniversityClientLogoPng} style={{ width: "125px", }} />
                            </div>
                            <div className="slide">
                                <img src={ticketmasterClientLogoPng} style={{ width: "125px", }} />
                            </div>
                            <div className="slide">
                                <img src={XchangingClientLogoPng} style={{ width: "125px", }} />
                            </div>




                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Slider