import React from 'react';
import NetworkAndEdgeProvidersBannerPng from "../../images/Network and Edge Providers banner.png";
import IncreasedEfficiencyEdgePng from "../../images/Increased Efficiency edge.png";
import BetterTimeManagementEdgePng from "../../images/Better Time Management edge.png";
import ReducedNoShowsPng from "../../images/Reduced No shows.png";
import ImprovedCustomerSatisfactionEdgePng from "../../images/Improved Customer Satisfaction edge.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';

function NetworkEdgeProvider() {
    return (
        <>
            <div className="heWhoRemains">
                <Header />
                <div>
                    <section>
                        <div className="container mt-5">
                            <div className="row">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">
                                        <h1 className=" display-4 fw-bold smd_text">Schedule AI for
                                            <span style={{ color: "#0B5ED7" }}> Network & Edge Providers
                                            </span>
                                        </h1>
                                        <p className="lead_smd text-black" style={{ fontSize: "1.1em" }}>
                                            If you manage or own a network or edge provider, then you know how important it is to have an effective appointment scheduling system in place. Not only does this help to keep your patients and clients happy, but it can also save you time and money in the long run. Here are just some of the benefits of using ScheduleAI software for network and edge providers
                                        </p>
                                        <a href="/login"><button
                                            className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                                            Demo</button></a>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="w-100" src={NetworkAndEdgeProvidersBannerPng} alt='Industrial and process manufacturing banner' />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="column pt-5">
                                <h2 className="text-center display-6 fw-bold">Benefits of Schedule AI for Network and Edge Providers
                                </h2>
                            </div>
                            <div className="row">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-up"
                                    data-aos-duration="2000">
                                    <div className="mt-4 ">

                                        <h1 className="display-5 smd_title" style={{ fontWeight: "400" }}>Increased Efficiency
                                        </h1>
                                        <p className="lead_smd"> When everything is organized and streamlined, it can make a big difference in terms of efficiency. This is especially true when it comes to managing appointments.
                                        </p>

                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 mt-5" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="w-100" src={IncreasedEfficiencyEdgePng} alt="Increased Efficiency edge.png" />
                                </div>

                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="row">

                                <div className="col-12 col-lg-6 mt-5" data-aos="fade-up" data-aos-duration="2000">
                                    <img className='w-100' src={BetterTimeManagementEdgePng} alt="Better Time Management edge" />
                                </div>
                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-up"
                                    data-aos-duration="2000">
                                    <div className="mt-4 ">

                                        <h1 className="display-5 smd_title" style={{ fontWeight: "400" }}>Better Time Management
                                        </h1>
                                        <p className="lead_smd"> Schedule AI software can help you to better manage your time by allowing you to see your schedule at a glance, as well as set reminders for upcoming appointments.
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="row">
                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center" >
                                    <div className="mt-4 ">

                                        <h1 className="display-5 smd_title" style={{ fontWeight: "400" }}>Reduced No-shows
                                        </h1>
                                        <p className="lead_smd"> One of the biggest frustrations for any type of provider is when patients don't show up for their appointments. This can be costly in terms of both time and money.
                                        </p>

                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 mt-5" data-aos="fade-up" data-aos-duration="2000">
                                    <img className='w-100' src={ReducedNoShowsPng} alt="Reduced No-shows" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="row">

                                <div className="col-12 col-lg-6 mt-5" data-aos="fade-up" data-aos-duration="2000">
                                    <img className='w-100' src={ImprovedCustomerSatisfactionEdgePng} alt="Improved Customer Satisfaction edge" />
                                </div>
                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-up"
                                    data-aos-duration="2000">
                                    <div className="mt-4 ">

                                        <h1 className="display-5 smd_title" style={{ fontWeight: "400" }}>Improved Customer Satisfaction
                                        </h1>
                                        <p className="lead_smd"> In the end, providing a good customer experience is what matters most. By using ScheduleAI, you can give your patients or clients the ability to easily schedule appointments online or via mobile app - which they will appreciate!
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container textDevLay pb-4">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold">Conclusion
                                </h2>
                                <p className='lead_smd text-justify'>ScheduleAI software includes increased efficiency and productivity, as well as improved customer satisfaction. By providing customers with convenient online booking options and automated reminders, they are more likely to keep their appointments and be satisfied with their providers.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default NetworkEdgeProvider
