import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useRef } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { Offcanvas, Button, Tabs, Tab, Row, Col, Nav, OverlayTrigger, Tooltip, Popover, Accordion, Modal, ListGroup, Toast, DropdownButton, Dropdown } from 'react-bootstrap';
import axios from "axios";
import "./Home.css";

import manImage from "../../images/man.jpg";
import Charts from '../charts/Doughnut.tsx';
import Area from '../charts/Area.tsx';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteIcon from '@mui/icons-material/Delete';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PowerIcon from '@mui/icons-material/Power';
import AddIcon from '@mui/icons-material/Add';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HelpIcon from '@mui/icons-material/Help';

import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RepeatIcon from '@mui/icons-material/Repeat';
import LoopIcon from '@mui/icons-material/Loop';
import unknownUserPng from "../../images/default.png";

import SalseforceImg from "../../images/salesforceCompany.png";
import zoomImg from "../../images/zoomCompany.png";
import gotoImg from "../../images/gotoCompany.png";
import teamsImg from "../../images/teamsCompany.png";
import webexImg from "../../images/webexCompany.png";

import successPng from "../../images/successPng.gif";
import queryPng from "../../images/help.png";
import invalidPng from "../../images/help.png";


import oneOnOneImg from "../../images/one_on_oneImg.svg";
import groupImg from "../../images/groupImg.svg";
import roundRobinImg from "../../images/round_robinImg.svg";
import caneldarDark from "../../images/caneldarDark.png";

import nameHelpText from "../../images/helpTexts/name.png";
import welcomeMessageHelpText from "../../images/helpTexts/welcome-message.png";
import myLinkHelpText from "../../images/helpTexts/my-link-help-text.png";

import uniqueLinkHelpText from "../../images/helpTexts/unique-link.png"
import brandingLogo from "../../images/helpTexts/brandingLogo.png";
import calendaryLogo from "../../images/ScheduleAILogo.png";

import requiredQuestionPng from "../../images/helpTexts/requiredQuestion.png";

import moment from "moment";

import { getMonth } from '../../utils';
import Month from '../../components/Month';
import dayjs from "dayjs";

import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'

import ImageUploading from 'react-images-uploading';

import TimezoneSelect, { allTimezones } from 'react-timezone-select';

import { v4 as uuidv4 } from 'uuid';
import filesize from "filesize";

import { GoogleLogin } from 'react-google-login';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, DateRange, Calendar } from 'react-date-range';
import { addDays } from 'date-fns';

import meetImg from "../../images/meetImg.png";
import callimg from "../../images/callImg.png";
import addressImg from "../../images/addressImg.png";
import forwardImg from "../../images/forward.png";
import outgoingPng from "../../images/outgoing-call.png";
import calendly4Png from "../../images/calendly4.png";

import userPng from "../../images/calendly4.png";
import emailPng from "../../images/calendly4.png";
import chatPurplePng from "../../images/calendly4.png";
import copyImg from "../../images/calendly4.png";
import emptyFilterPng from "../../images/empty-filter.png";
import emptyResultPng from "../../images/emptyResult.png";

import emailTimer from "../../images/email-timer.svg";
import emailHeart from "../../images/email-heart.svg";
import emailEdit from "../../images/email-edit.svg";
import phoneTimer from "../../images/phone-timer.svg";
import phoneCheck from "../../images/phone-check.svg";
import { fontWeight } from '@mui/system';

import popupData from "../../audio/popup-alert.mp3";
import pageData from "../../audio/page-turn.wav";


import 'react-image-crop/dist/ReactCrop.css'
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'


import { gapi } from 'gapi-script';
import AvailabilityTemplates from '../../components/AvailabilityTemplates';
import { Chat } from '@mui/icons-material';
import Messanger from '../Messanger/Messanger';

const ct = require('countries-and-timezones');
const timezones = ct.getAllTimezones();
const allCountries = ct.getAllCountries();

function Home({ user, firstCreatedAt, socket }) {

  const welcomeText1 = useRef();
  const welcomeText2 = useRef();
  const welcomeText3 = useRef();
  const welcomeText4 = useRef();
  const welcomeText5 = useRef();
  const welcomeText6 = useRef();
  const progressBars = useRef();
  const rollingDaysRef = useRef();
  const [stepOnWay, setStepOnWay] = useState(1);

  const ownershipPassOne = useRef();
  const ownershipPassTwo = useRef();
  const [deletionPolicy, setDeletionPolicy] = useState("");


  const [askForDeleteMember, setAskForDeleteMember] = useState(false);
  const [deleteMemberEmail, setDeleteMemberEmail] = useState(false);


  const [countriesList, setCountriesList] = useState([]);
  const [timezonesList, setTimezonesList] = useState([]);
  const [filteredTimezonesList, setFilteredTimezonesList] = useState([]);
  const [filteredTimezonesListSchedule, setFilteredTimezonesListSchedule] = useState([]);



  const [lowercaseAvailable, setLowercaseAvailable] = useState(false);
  const [uppercaseAvailable, setUppercaseAvailable] = useState(false);
  const [numbersAvailable, setNumbersAvailable] = useState(false);
  const [specialCharsAvailable, setSpecialCharsAvailable] = useState(false);
  const [minimumCharsAvailable, setMinimumCharsAvailable] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const newPasswordField = useRef();
  const newPasswordFieldVarified = useRef();
  const [newPass, setNewPass] = useState("");
  const [newPassConf, setNewPassConf] = useState("");

  const [sharingViewOf, setSharingViewOf] = useState("LinkCopy");


  const TO_RADIANS = Math.PI / 180;
  const [newBrandName, setNewBrandName] = useState(null);
  const previewCanvasRef = useRef(null)
  const [imgSrc, setImgSrc] = useState('')
  const imgRef = useRef(null)
  const hiddenAnchorRef = useRef(null)
  const blobUrlRef = useRef('')
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState(null)
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState(1 / 1)

  const [cropperFileType, setCropperFileType] = useState("jpeg")

  const [timerForOTP, setTimerForOTP] = useState(60);
  const [resendOTP, setResendOTP] = useState(false);

  const OTPInterval = useRef(null);

  const [flipHorizontal, setFlipHorizontal] = useState(false);
  const [flipVertical, setFlipVertical] = useState(false);
  const [imageSizeError, setImageSizeError] = useState(null);

  const [newBrandLogoPreview, setNewBrandLogoPreview] = useState(null);

  const [brandNameAvailable, setBrandNameAvailable] = useState(false);

  const [allBrandsLogos, setAllBrandsLogos] = useState([]);


  const [currntOverrideDateSelected, setCurrntOverrideDateSelected] = useState(null);


  const [startCropperForBrand, setStartCropperForBrand] = useState(false);
  const [eventManagerInnerView, setEventManagerInnerView] = useState("upcoming");
  const [eventManagerView, setEventManagerView] = useState("event types");
  const [viewEventTypes, setViewEventTypes] = useState(true);
  const [scheduledEvents, setScheduledEvents] = useState(false);
  const [workflows, setWorkflows] = useState(false);
  const [activeWorkflow, setActiveWorkflow] = useState(null);
  const [showCustomWorkflowStep1, setShowCustomWorkflowStep1] = useState(false);

  const [viewNewEventCreate, setViewNewEventCreate] = useState(false);

  const [scheduledViewUpcoming, setScheduledViewUpcoming] = useState(true);
  const [scheduledViewPending, setScheduledViewPending] = useState(false);
  const [scheduledViewPast, setScheduledViewPast] = useState(false)
  const [allMeetings, setAllMeetings] = useState([]);

  const [alertCancIdOne, setAlertCancIdOne] = useState("");
  const [alertCancIdTwo, setAlertCancIdTwo] = useState("");
  const [cancellationOrigionalAdminOfEvent, setCancellationOrigionalAdminOfEvent] = useState("");
  const [assignedRefreshToken, setAssignedRefreshToken] = useState(null);
  const [alertCalenderEventId, setAlertCalenderEventId] = useState("");

  const [cancelContents, setCancelContents] = useState("");

  const cancellationPolicy = useRef("");

  const [bulkDeleteSection, setBulkDeleteSection] = useState(true)

  let showBulkDel = useRef([])
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const [showDeleteBtnLength, setShowDeleteBtnLength] = useState(0);

  const [showDateRangeSelector, setShowDateRangeSelector] = useState(false);
  const [straightRollingDays, setStraightRollingDays] = useState(true);
  const [withinDateRange, setWithinDateRange] = useState(false);
  const [infiniteDateRange, setInfiniteDateRange] = useState(false);


  const [eventCreationSteps, setEventCreationSteps] = useState(false);
  const [groupEventCreationSteps, setGroupEventCreationSteps] = useState(false)
  const [roundRobinCreationSteps, setRoundRobinCreationSteps] = useState(false)


  const [oneOnOneEventCreation, setOneOnOneEventCreation] = useState(false);
  const [groupEventCreation, setGroupEventCreation] = useState(false);
  const [roundRobinEventCreation, setRoundRobinEventCreation] = useState(false);


  const [meetingsTabType, setMeetingsTabType] = useState();

  const [needToken, setNeedToken] = useState(false);
  const [needZoomToken, setNeedZoomToken] = useState(false);
  const [needGTMToken, setNeedGTMToken] = useState(false);
  const [needTeamsToken, setNeedTeamsToken] = useState(false);
  const [needWebexToken, setNeedWebexToken] = useState(false);
  const [myCustomLink, setMyCustomLink] = useState("");
  const [myRefreshToken, setMyRefreshToken] = useState('');
  const [searchResult, setSearchResult] = useState(true);

  const [eventTypeSector, setEventTypeSector] = useState(false);





  const [summary, setSummary] = useState('');
  const [description, setDescription] = useState('');
  // const [attendees, setAttendees] = useState('')
  const [location, setLocation] = useState('meet');
  const [colorId, setColorId] = useState('1');
  // const [startDateTime, setStartDateTime] = useState('')
  // const [endDateTime, setEndDateTime] = useState('')
  const [duration, setDuration] = useState(30);
  const [signedIn, setSignedIn] = useState(false);
  const [locationSelection, setLocationSelection] = useState(false);
  const [meetingType, setMeetingType] = useState('meet');

  const [selectedFirst, setSelectedFirst] = useState(false);
  const [selectedSecond, setSelectedSecond] = useState(false);
  const [betweenDays, setBetweenDays] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 30),
      key: 'selection'
    }
  ]);

  const [eventBeforeTimeActive, setEventBeforeTimeActive] = useState(false);
  const [eventAfterTimeActive, setEventAfterTimeActive] = useState(false);

  const [eventBeforeTime, setEventBeforeTime] = useState(30);
  const [eventAfterTime, setEventAfterTime] = useState(30);

  const [oneOffDate, setOneOffDate] = useState("");
  const [oneToOneDate, setOneToOneDate] = useState("");
  const [roundRobinDate, setRoundRobinDate] = useState("");
  const [oneOffMeetingType, setOneOffMeetingType] = useState('address');
  const [oneOffDuration, setOneOffDuration] = useState(30);
  const [oneOffLocationSelection, setOneOffLocationSelection] = useState(false);
  const [oneOffLocation, setOneOffLocation] = useState("");
  const [oneOffDescription, setOneOffDescription] = useState("")
  const [oneOffMeetingName, setOneOffMeetingName] = useState("One-off Meeting");

  const [inboundCallOneOff, setInboundCallOneOff] = useState(false);
  const [inboundCall, setInboundCall] = useState(false);

  const [oneOffDates, setOneOffDates] = useState({});
  const [roundRobinDates, setRoundRobinDates] = useState({});
  const [oneToOneDates, setOneToOneDates] = useState({});


  const [secretEvent, setSecretEvent] = useState(false);


  const [groupSummary, setGroupSummary] = useState("")

  const [groupLocationSelection, setGroupLocationSelection] = useState(false)

  const [groupMeetingType, setGroupMeetingType] = useState("meet")

  const [groupLocation, setGroupLocation] = useState("")

  const [groupDescription, setGroupDescription] = useState("")

  const [groupInboundCall, setGroupInboundCall] = useState(false)

  const [groupColorId, setGroupColorId] = useState('1')

  const [groupDuration, setGroupDuration] = useState(30)

  const [groupDate, setGroupDate] = useState()

  const [eventGroupBeforeTimeActive, setEventGroupBeforeTimeActive] = useState(false)

  const [eventGroupAfterTimeActive, setEventGroupAfterTimeActive] = useState(false)

  const [eventGroupBeforeTime, setEventGroupBeforeTime] = useState(30)

  const [eventGroupAfterTime, setEventGroupAfterTime] = useState(30)

  const [groupSecretEvent, setGroupSecretEvent] = useState(false)

  const [groupDates, setGroupDates] = useState({})

  const [maxInvities, setMaxInvities] = useState(4)

  const [maxInvitiesActive, setMaxInvitiesActive] = useState(false)

  const [roundRobinSummary, setRoundRobinSummary] = useState("");
  const [roundRobinMeetingType, setRoundRobinMeetingType] = useState("meet");
  const [roundRobinLocation, setRoundRobinLocation] = useState("");
  const [roundRobinDescription, setRoundRobinDescription] = useState("");
  const [roundRobinColorId, setRoundRobinColorId] = useState("1");
  const [roundRobinDuration, setRoundRobinDuration] = useState(30);

  const [isDescriptionRequired, setIsDescriptionRequired] = useState(true);

  const [stopBookingTime, setStopBookingTime] = useState(["hours", 1]);
  const [customStopBookingView, setCustomStopBookingView] = useState(false);
  const [stopTimeForBookingType, setStopTimeForBookingType] = useState("hours");

  const [roundRobinQuestions, setRoundRobinQuestions] = useState("Share anything that can help us prepare the meeting.");
  const [oneOnOneQuestions, setOneOnOneQuestions] = useState("Share anything that can help us prepare the meeting.");
  const [groupQuestions, setGroupQuestions] = useState("Share anything that can help us prepare the meeting.");

  const [showCustomWorkflowStep2, setShowCustomWorkflowStep2] = useState(false);
  const [workflowDuration, setWorkflowDuration] = useState(30);

  const [copyTimeFromFrame, setCopyTimeFromFrame] = useState("");


  const [customHourView, setCustomHourView] = useState(false);
  const [durationType, setDurationType] = useState("minutes");


  const [customEventLink, setCustomEventLink] = useState("");

  const [isCustomLinkAvailable, setIsCustomLinkAvailable] = useState(true);


  const [needAccountSetup, setNeedAccountSetup] = useState(true);
  const [allUsers, setAllUsers] = useState(false);
  const [userName, setUserName] = useState(user.email.split("@")[0].replaceAll(".", "-"));
  const [me, setMe] = useState([]);

  let minutesGroup = useRef();
  let minutesOneOff = useRef();
  let minutesOneOnOne = useRef();
  let minutesRoundRobin = useRef();
  const [customScheduleView, setCustomScheduleView] = useState(false);

  const [existingScheduleView, setExistingScheduleView] = useState(true);

  const popupAlert = new Audio(popupData);
  const pageTurnSound = new Audio(pageData);

  // ############################################################################################
  // ############################################################################################
  // ############################################################################################
  // ############################################################################################
  // ############################################################################################
  // ############################################################################################
  const serverUrl = "https://" + process.env.REACT_APP_DOMAINNAME + ":8443";
  //const serverUrl = "http://" + process.env.REACT_APP_DOMAINNAME + ":8443";
  const [alertShow, setAlertShow] = useState(false);
  const [meetingsShow, setMeetingShow] = useState(false);
  const [availabilityShow, setAvailabilityShow] = useState(false);
  const [messangerShow, setMessangerShow] = useState(false);
  const [contactShow, setContactShow] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  const [viewAdminPage, setViewAdminPage] = useState(false);

  const [viewToast, setViewToast] = useState(false);
  const [toastTitle, setToastTitle] = useState(null);
  const [toastBody, setToastBody] = useState(null);
  const [toastMessageType, setToastMessageType] = useState(null);

  const [clockTIme, setClockTIme] = useState(new Date().toLocaleTimeString());
  const [clockDate, setClockDate] = useState(new Date().toLocaleTimeString());

  // #######################after adding bugs
  const [viewOf, setViewOf] = useState(null);
  const [accountInnerView, setAccountInnerView] = useState(null);

  const [trialDate, setTrialDate] = useState(new Date(firstCreatedAt).toLocaleDateString("en-us", { dateStyle: "long" }));

  const [phoneNumbers, setPhoneNumbers] = useState(user.phoneNumbers);
  const [phoneNumberForOTP, setPhoneNumberForOTP] = useState("");
  const newPeopleEmail = useRef("");
  const phoneAddOTP = useRef("");


  const timeListToMeasureData = ["12:00am", "12:15am", "12:30am", "12:45am", "1:00am", "1:15am", "1:30am", "1:45am", "2:00am", "2:15am", "2:30am", "2:45am", "3:00am", "3:15am", "3:30am", "3:45am", "4:00am", "4:15am", "4:30am", "4:45am", "5:00am", "5:15am", "5:30am", "5:45am", "6:00am", "6:15am", "6:30am", "6:45am", "7:00am", "7:15am", "7:30am", "7:45am", "8:00am", "8:15am", "8:30am", "8:45am", "9:00am", "9:15am", "9:30am", "9:45am", "10:00am", "10:15am", "10:30am", "10:45am", "11:00am", "11:15am", "11:30am", "11:45am", "12:00pm", "12:15pm", "12:30pm", "12:45pm", "1:00pm", "1:15pm", "1:30pm", "1:45pm", "2:00pm", "2:15pm", "2:30pm", "2:45pm", "3:00pm", "3:15pm", "3:30pm", "3:45pm", "4:00pm", "4:15pm", "4:30pm", "4:45pm", "5:00pm", "5:15pm", "5:30pm", "5:45pm", "6:00pm", "6:15pm", "6:30pm", "6:45pm", "7:00pm", "7:15pm", "7:30pm", "7:45pm", "8:00pm", "8:15pm", "8:30pm", "8:45pm", "9:00pm", "9:15pm", "9:30pm", "9:45pm", "10:00pm", "10:15pm", "10:30pm", "10:45pm", "11:00pm", "11:15pm", "11:30pm", "11:45pm"];
  const timeListToMeasureData24 = ["00:00", "00:15", "00:30", "00:45", "01:00", "01:15", "01:30", "01:45", "02:00", "02:15", "02:30", "02:45", "03:00", "03:15", "03:30", "03:45", "04:00", "04:15", "04:30", "04:45", "05:00", "05:15", "05:30", "05:45", "06:00", "06:15", "06:30", "06:45", "07:00", "07:15", "07:30", "07:45", "08:00", "08:15", "08:30", "08:45", "09:00", "09:15", "09:30", "09:45", "10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45", "14:00", "14:15", "14:30", "14:45", "15:00", "15:15", "15:30", "15:45", "16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30", "17:45", "18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45", "20:00", "20:15", "20:30", "20:45", "21:00", "21:15", "21:30", "21:45", "22:00", "22:15", "22:30", "22:45", "23:00", "23:15", "23:30", "23:45"];
  const [isSundayActive, setIsSundayActive] = useState(false);
  const [isMondayActive, setIsMondayActive] = useState(false);
  const [isTuesdayActive, setIsTuesdayActive] = useState(false);
  const [isWednesdayActive, setIsWednesdayActive] = useState(false);
  const [isThursdayActive, setIsThursdayActive] = useState(false);
  const [isFridayActive, setIsFridayActive] = useState(false);
  const [isSaturdayActive, setIsSaturdayActive] = useState(false);
  const [userSchedules, setUserSchedules] = useState(null);
  const [scheduleName, setScheduleName] = useState(false);

  const [timeListToMeasure, setTimeListToMeasure] = useState(user.settings.timeFormat.value !== "12 Hours" ? timeListToMeasureData24 : timeListToMeasureData);


  const [showShareYourEventPanel, setShowShareYourEventPanel] = useState(false);


  const [sundayStartTime, setSundayStartTime] = useState("9:00am");
  const [sundayEndTime, setSundayEndTime] = useState("5:00pm");
  const [mondayStartTime, setMondayStartTime] = useState("9:00am");
  const [mondayEndTime, setMondayEndTime] = useState("5:00pm");
  const [tuesdayStartTime, setTuesdayStartTime] = useState("9:00am");
  const [tuesdayEndTime, setTuesdayEndTime] = useState("5:00pm");
  const [wednesdayStartTime, setWednesdayStartTime] = useState("9:00am");
  const [wednesdayEndTime, setWednesdayEndTime] = useState("5:00pm");
  const [thursdayStartTime, setThursdayStartTime] = useState("9:00am");
  const [thursdayEndTime, setThursdayEndTime] = useState("5:00pm");
  const [fridayStartTime, setFridayStartTime] = useState("9:00am");
  const [fridayEndTime, setFridayEndTime] = useState("5:00pm");
  const [saturdayStartTime, setSaturdayStartTime] = useState("9:00am");
  const [saturdayEndTime, setSaturdayEndTime] = useState("5:00pm");




  const [viewFromSunday, setViewFromSunday] = useState(false);
  const [viewFromMonday, setViewFromMonday] = useState(false);
  const [viewFromTuesday, setViewFromTuesday] = useState(false);
  const [viewFromWednesday, setViewFromWednesday] = useState(false);
  const [viewFromThursday, setViewFromThursday] = useState(false);
  const [viewFromFriday, setViewFromFriday] = useState(false);
  const [viewFromSaturday, setViewFromSaturday] = useState(false);

  const [sundayCopy, setSundayCopy] = useState(false);
  const [mondayCopy, setMondayCopy] = useState(false);
  const [tuesdayCopy, setTuesdayCopy] = useState(false);
  const [wednesdayCopy, setWednesdayCopy] = useState(false);
  const [thursdayCopy, setThursdayCopy] = useState(false);
  const [fridayCopy, setFridayCopy] = useState(false);
  const [saturdayCopy, setSaturdayCopy] = useState(false);

  const [schedule, setSchedule] = useState({
    "sunday": {
      "active": true,
      "times": ["9:00am-11:00am"]
    },
    "monday": {
      "active": true,
      "times": ["9:00am-11:00am"]
    },
    "tuesday": {
      "active": true,
      "times": ["9:00am-11:00am"]
    },
    "wednesday": {
      "active": true,
      "times": ["9:00am-11:00am"]
    },
    "thursday": {
      "active": false,
      "times": ["9:00am-11:00am"]
    },
    "friday": {
      "active": true,
      "times": ["9:00am-11:00am"]
    },
    "saturday": {
      "active": false,
      "times": ["9:00am-11:00am"]
    },
  });


  const [totalUpcomingMeetings, setTotalUpcomingMeetings] = useState(0);
  const [totalPendingMeetings, setTotalPendingMeetings] = useState(0);
  const [totalPastMeetings, setTotalPastMeetings] = useState(0);

  const [minMonthIndex, setMinMonthIndex] = useState(dayjs().month());
  const [monthIndexCurrent, setMonthIndexCurrent] = useState(dayjs().month());
  const [currentMonth, setCurrentMonth] = useState(getMonth(monthIndexCurrent));
  const [nextMonth, setNextMonth] = useState(getMonth(monthIndexCurrent + 1));

  const [createNewSchedule, setCreateNewSchedule] = useState(false);
  const [scheduleNameCounter, setScheduleNameCounter] = useState(false);
  const [overrideDateEnabled, setOverrideDateEnabled] = useState(false);


  const [overrideButtonEnabled, setOverrideButtonEnabled] = useState(true);


  const [updateButtonEnabled, setUpdateButtonEnabled] = useState(true);
  const [overrideStartTime, setOverrideStartTime] = useState("9:00am");
  const [overrideEndTime, setOverrideEndTime] = useState("5:00pm");
  const [overrideArray, setOverrideArray] = useState(["9:00am-5:00pm"]);


  const [viewDateOverridePanel, setViewDateOverridePanel] = useState(false);
  const [overrideDate, setOverrideDate] = useState(null);
  const [dateOverrides, setDateOverrides] = useState({});

  const [existingOverride, setExistingOverride] = useState(false);


  const [calendaryBranding, setCalendaryBranding] = useState(user.appBranding);

  const options = useMemo(() => countryList().getData(), []);

  const [allPeople, setAllPeople] = useState(true);
  const [group, setGroup] = useState(false);
  const [templates, setTemplates] = useState(false);

  const [people, setPeople] = useState({});
  const [addMemberMessage, setAddMemberMessage] = useState(false);

  const [phoneAddView, setPhoneAddView] = useState(false);
  const [phoneAddingStage, setPhoneAddingStage] = useState(1);

  const [newOTPForPhone, setNewOTPForPhone] = useState("");

  const [phoneAddError, setPhoneAddError] = useState(null);

  const [newScheduleName, setNewScheduleName] = useState("");

  //Subscription

  const [subscription, setSubscription] = useState(null);

  // DATA FOR ACCOUNT SETTINGS
  const [name, setName] = useState(user.settings.name);
  const [nameError, setNameError] = useState(false);
  const [welcome, setWelcome] = useState(user.settings.welcome);
  const [timeFormat, setTimeFormat] = useState(user.settings.timeFormat);
  const [country, setCountry] = useState(user.settings.country);
  const [countrySchedule, setCountrySchedule] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [selectedTimezoneSettings, setSelectedTimezoneSettings] = useState(user.settings.selectedTimezone);

  const [phoneInvalidError, setPhoneInvalidError] = useState(null);
  const [myRefreshTokens, setMyRefreshTokens] = useState({});

  const [customAlertBox, setCustomAlertBox] = useState(false);
  const [selectedMeetingCategory, setSelectedMeetingCategory] = useState(null);


  const [alertBoxMessages, setAlertBoxMessages] = useState("");
  const [alertErrorType, setAlertErrorType] = useState("invalid");
  const [alertBoxIdData, setAlertBoxIdData] = useState("");
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const [username, setUsername] = useState("");
  const [usernameFaults, setUsernameFaults] = useState(null);
  const [phoneList, setPhoneList] = useState(null);

  const [showCalendarList, setShowCalendarList] = useState(false);

  const [deletionContents, setDeletionContents] = useState(null);
  const [askOwnership, setAskOwnership] = useState(null);

  const [addNewMember, setAddNewMember] = useState(false);

  const [file, setFile] = useState(null);
  const supportedMedia = ["jpg", "jpeg", "png", "tiff"];

  const [optionListForPhone, setOptionListForPhone] = useState(null);
  const [userScheduleListForSelect, setUserScheduleListForSelect] = useState(null);
  const [meetingDurationListForSelect, setMeetingDurationListForSelect] = useState([
    { label: "15 minutes", value: 15 },
    { label: "30 minutes", value: 30 },
    { label: "45 minutes", value: 45 },
    { label: "60 minutes", value: 60 },
    { label: "custom", value: "custom" }
  ]);
  const [addTimeAfterBeforeForSelect, setAddTimeAfterBeforeForSelect] = useState([
    { label: "15 minutes", value: 15 },
    { label: "30 minutes", value: 30 },
    { label: "45 minutes", value: 45 },
    { label: "1 hr", value: 60 },
    { label: "1 hr 30 min", value: 90 },
    { label: "2 hr", value: 120 },
    { label: "2 hr 30 min", value: 150 },
    { label: "3 hr", value: 180 },
  ]);
  const [stopBookingBeforeListForSelect, setStopBookingBeforeListForSelect] = useState([
    { label: "1 Hour", value: 1 },
    { label: "2 Hour", value: 2 },
    { label: "3 Hour", value: 3 },
    { label: "4 Hour", value: 4 },
    { label: "custom", value: "custom" }
  ]);


  const [currentGlobalProfile, setCurrentGlobalProfile] = useState(user.customProfilePicture ? user.customProfilePicture.image : user.profilePicture);
  const [globalProfileType, setGlobalProfileType] = useState(user.customProfilePicture ? user.customProfilePicture.type : "custom");
  useEffect(() => {
    // console.log(images)
    // console.log(defaultImage)
    // console.log(currentGlobalProfile)

  }, [currentGlobalProfile])

  const [viewImageUploader, setViewImageUploader] = useState(false);
  const maxNumber = 100;
  const maxfileSize = 2048000;
  const accepedFileTypes = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG", "HEIF"];

  const switchScheduledView = (type) => {
    if (type === "upcoming") {
      setScheduledViewUpcoming(true);
      setScheduledViewPending(false);
      setScheduledViewPast(false);
    } else if (type === "pending") {
      setScheduledViewUpcoming(false);
      setScheduledViewPending(true);
      setScheduledViewPast(false);
    }
    else if (type === "past") {
      setScheduledViewUpcoming(false);
      setScheduledViewPending(false);
      setScheduledViewPast(true);
    } else {
      setScheduledViewUpcoming(true);
      setScheduledViewPending(false);
      setScheduledViewPast(false);
    }
  }

  const hasUpcomingMeeting = (meetings) => {
    let has = false;

    meetings.map((meets, key) => {
      let date = new Date(meets.date).getDate();
      let month = new Date(meets.date).getMonth();
      let year = new Date(meets.date).getFullYear();

      let meridium = meets.startTime.split(" ")[1];
      let timing = meets.startTime.split(" ")[0];

      let OgHour = parseInt(timing.split(":")[0]);
      let OgMin = parseInt(timing.split(":")[1]);

      let hour = 0;

      if (meridium.includes("pm")) {
        if (OgHour < 12) {
          hour = OgHour + 12;
        }
        else {
          hour = OgHour;
        }
      } else {
        hour = OgHour;
      }

      let min = OgMin;
      let sec = 0;

      let finalTimeToUseInApp = dayjs.tz(dayjs.tz(`${year}-${month + 1}-${date} ${hour}:${min}:${sec}`, meets.givenTimezone.name), selectedTimezoneSettings.value.name);


      // console.log(meridium, timing, hour, min, sec);

      // console.log(dayjs(new Date(year, month, date, hour, min, sec)).valueOf(), dayjs(new Date()).valueOf());
      // console.log(dayjs(new Date(year, month, date, hour, min, sec)).valueOf() > dayjs(new Date()).valueOf());
      // console.log(dayjs(dayjs(new Date(year, month, date, hour, min, sec)).valueOf()).format("D MMMM YYYY H:m:s"), dayjs(dayjs(new Date()).valueOf()).format("D MMMM YYYY H:m:s"));
      if (finalTimeToUseInApp.valueOf() > dayjs.tz(dayjs.tz(new Date(), meets.givenTimezone.name), selectedTimezoneSettings.value.name).valueOf() && meets.status == "active") {
        has = true;
      }
    });

    return has;
  }

  const hasPendings = (allMeetings) => {
    let has = false;
    allMeetings.map((meeting, key) => {


      let date = new Date(meeting.date).getDate();
      let month = new Date(meeting.date).getMonth();
      let year = new Date(meeting.date).getFullYear();


      let meridium = meeting.startTime.split(" ")[1];
      let timing = meeting.startTime.split(" ")[0];

      let OgHour = parseInt(timing.split(":")[0]);
      let OgMin = parseInt(timing.split(":")[1]);

      let hour = 0;

      if (meridium.includes("pm")) {
        if (OgHour < 12) {
          hour = OgHour + 12;
        }
        else {
          hour = OgHour;
        }
      } else {
        hour = OgHour;
      }

      let min = OgMin;
      let sec = 0;

      let finalTimeToUseInApp = dayjs.tz(dayjs.tz(`${year}-${month + 1}-${date} ${hour}:${min}:${sec}`, meeting.givenTimezone.name), selectedTimezoneSettings.value.name);

      if (meeting.status == "cancelled" && finalTimeToUseInApp.valueOf() > dayjs.tz(dayjs.tz(new Date(), meeting.givenTimezone.name), selectedTimezoneSettings.value.name).valueOf()) {
        has = true
      }
    });
    return has;
  }

  const hasPastMeeting = (meetings) => {
    let has = false;

    meetings.map((meets, key) => {
      let date = new Date(meets.date).getDate();
      let month = new Date(meets.date).getMonth();
      let year = new Date(meets.date).getFullYear();

      let meridium = meets.startTime.split(" ")[1];
      let timing = meets.startTime.split(" ")[0];

      let OgHour = parseInt(timing.split(":")[0]);
      let OgMin = parseInt(timing.split(":")[1]);

      let hour = 0;

      if (meridium.includes("pm")) {
        if (OgHour < 12) {
          hour = OgHour + 12;
        }
        else {
          hour = OgHour;
        }
      } else {
        hour = OgHour;
      }

      let min = OgMin;
      let sec = 0;

      let finalTimeToUseInApp = dayjs.tz(dayjs.tz(`${year}-${month + 1}-${date} ${hour}:${min}:${sec}`, meets.givenTimezone.name), selectedTimezoneSettings.value.name);

      if (finalTimeToUseInApp.valueOf() < dayjs.tz(dayjs.tz(new Date(), meets.givenTimezone.name), selectedTimezoneSettings.value.name).valueOf()) {
        has = true;
      }
    });

    return has;
  }

  const hasBookings = (allEvenets) => {
    let x = false;
    allEvenets.forEach((event) => {
      if (event.attendees.length > 1) {
        x = true;
      }
    })
    return x;
  }

  const searchInAllEvent = async (textData) => {
    if (textData.length > 0) {
      const filteredEvents = await axios.post(serverUrl + "/events/filter", { "data": textData, "username": username });

      if (filteredEvents.status === 200) {
        // console.log(filteredEvents.data)
        if (filteredEvents.data.length > 0) {
          setAllEvents(filteredEvents.data)
          setSearchResult(true)
        }
        else {
          setAllEvents([]);
          setSearchResult(false);
        }
      } else {
        // console.log(filteredEvents.data)
        setAllEvents([]);
        setSearchResult(false);
      }
    } else {
      setSearchResult(true);
      const getEvents = await axios.post(serverUrl + "/events/getAll/", { "username": username });
      if (getEvents.status === 200) {
        // console.log(getEvents.data)
        setAllEvents(getEvents.data);
      }
      else {
        // console.log(getEvents.data);
      }
    }
  }

  function addToChecked(id) {
    let arr = showBulkDel.current;
    if (arr.includes(id)) {
      arr = arr.filter(ids => ids !== id);
      showBulkDel.current = arr;
    } else {
      arr.push(id);
      showBulkDel.current = arr;
    }
    // console.log(showBulkDel.current)

    if (showBulkDel.current.length > 0) {
      setShowDeleteBtn(true)
      setShowDeleteBtnLength(showBulkDel.current.length)
    } else {
      setShowDeleteBtn(false)
      setShowDeleteBtnLength(showBulkDel.current.length)
    }
  }

  const removeSelected = async () => {
    showBulkDel.current = [];
    setShowDeleteBtn(false);
    setShowDeleteBtnLength(showBulkDel.current.length);

    setAllEvents([]);
    setAllMeetings([]);

    const getEvents = await axios.post(serverUrl + "/events/getAll/", { "username": username });
    if (getEvents.status === 200) {
      // console.log(getEvents.data)
      setAllEvents(getEvents.data);
    }
    else {
      // console.log("error while fetching events");
    }

    const getAllMeetings = await axios.post(serverUrl + "/users/getAllMeetings", { "email": user.email });
    if (getAllMeetings.status === 200) {
      setAllMeetings(getAllMeetings.data);
    } else {
      // console.log("error while getting meetings");
    }

  }

  const changeHandler = (value) => {
    // console.log(value);
    setCountry(value);
    startTimeZoneSwitch(value);
  };

  const changeHandlerSchedule = (value) => {
    // console.log(value);
    setCountrySchedule(value);
    startTimeZoneSwitchSchedules(value);
  };

  const logout = async () => {
    // window.open("https://"+process.env.REACT_APP_DOMAINNAME+":8443/auth/logout", "_self");
    const deleteCookie = await axios.get(serverUrl + "/deleteCookie", { withCredentials: true });
    if (deleteCookie.status === 200) {
      window.location.href = process.env.REACT_APP_DOMAINNAME1 + "/login";
    }
    else {
      window.location.href = process.env.REACT_APP_DOMAINNAME1 + "/login";
    }
  };

  useEffect(() => {

    if (allMeetings.length && hasUpcomingMeeting(allMeetings)) {
      let totalUpcoming = 0
      allMeetings.map((meeting, key) => {
        let date = new Date(meeting.date).getDate();
        let month = new Date(meeting.date).getMonth();
        let year = new Date(meeting.date).getFullYear();


        let meridium = meeting.startTime.split(" ")[1];
        let timing = meeting.startTime.split(" ")[0];

        let OgHour = parseInt(timing.split(":")[0]);
        let OgMin = parseInt(timing.split(":")[1]);

        let hour = 0;

        if (meridium.includes("pm")) {
          if (OgHour < 12) {
            hour = OgHour + 12;
          }
          else {
            hour = OgHour;
          }
        } else {
          hour = OgHour;
        }

        let min = OgMin;
        let sec = 0;

        if (dayjs(new Date(year, month, date, hour, min, sec)).valueOf() > dayjs(new Date()).valueOf() && meeting.status == "active") {
          totalUpcoming++
        }
      })
      setTotalUpcomingMeetings(totalUpcoming);
    }

    if (allMeetings.length) {
      let totalPendingMeeting = 0;
      allMeetings.map((meeting, key) => {
        if (meeting.status == "cancelled") {
          return totalPendingMeeting++
        }
      })
      setTotalPendingMeetings(totalPendingMeeting);
    }

    if (allMeetings.length && hasPastMeeting(allMeetings)) {
      let totalPastMeeting = 0;
      allMeetings.map((meeting, key) => {
        let date = new Date(meeting.date).getDate();
        let month = new Date(meeting.date).getMonth();
        let year = new Date(meeting.date).getFullYear();


        let meridium = meeting.startTime.split(" ")[1];
        let timing = meeting.startTime.split(" ")[0];

        let OgHour = parseInt(timing.split(":")[0]);
        let OgMin = parseInt(timing.split(":")[1]);

        let hour = 0;

        if (meridium.includes("pm")) {
          if (OgHour < 12) {
            hour = OgHour + 12;
          }
          else {
            hour = OgHour;
          }
        } else {
          hour = OgHour;
        }

        let min = OgMin;
        let sec = 0;
        if (dayjs(new Date(year, month, date, hour, min, sec)).valueOf() < dayjs(new Date()).valueOf()) {
          return totalPastMeeting++
        }
      })

      setTotalPastMeetings(totalPastMeeting);
    }

  }, [allMeetings])

  useEffect(() => {

    gapi.load('client:auth2', function () {
      gapi.auth2.init({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: 'openid email profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
        prompt: 'consent',
      });
    });


    setTimeout(() => {
      if (welcomeText1.current) {
        welcomeText1.current.style.opacity = 1;
      }
    }, 1000)

    // console.log("default", defaultImage);
    setNeedAccountSetup(!user.accountSetup);
    setName(user.name);
    setUsername(user.username);
    setWelcome(user.settings.welcome);
    setTimeFormat(user.settings.timeFormat);
    setCountry(user.settings.country);

    setSelectedTimezoneSettings(user.settings.selectedTimezone);

    setPhoneList(user.phoneNumbers);


    if (user.phoneNumbers) {
      let allActivePhoneNumbers = [];
      Object.keys(user.phoneNumbers).map((number, key) => {
        if (user.phoneNumbers[number].active) {
          allActivePhoneNumbers.push({ label: number, value: number })
        }
      });
      setOptionListForPhone(allActivePhoneNumbers);
    }

    setMyRefreshTokens(user.calendarConnections);
    setAllBrandsLogos(user.brandLogos);

    setCalendaryBranding(user.appBranding);

    setUserSchedules(user.userSchedules);

    setPeople(user.people);

    let currentScheduleName = "";

    Object.keys(user.userSchedules).map((name, key) => {
      if (user.userSchedules[name].inUse === true) {

        setSelectedTimezone(user.userSchedules[name].timezone);

        currentScheduleName = name;


        setSchedule(user.userSchedules[name].days);

        setDateOverrides(user.userSchedules[name].dateOverrides);

        setIsSundayActive(user.userSchedules[name].days.sunday.active);
        setIsMondayActive(user.userSchedules[name].days.monday.active);
        setIsTuesdayActive(user.userSchedules[name].days.tuesday.active);
        setIsWednesdayActive(user.userSchedules[name].days.wednesday.active);
        setIsThursdayActive(user.userSchedules[name].days.thursday.active);
        setIsFridayActive(user.userSchedules[name].days.friday.active);
        setIsSaturdayActive(user.userSchedules[name].days.saturday.active);


        // setSundayStartTime(user.userSchedules[name].days.sunday.times.split("-")[0]);
        // setSundayEndTime(user.userSchedules[name].days.sunday.times.split("-")[1]);
        // setMondayStartTime(user.userSchedules[name].days.monday.times.split("-")[0]);
        // setMondayEndTime(user.userSchedules[name].days.monday.times.split("-")[1]);
        // setTuesdayStartTime(user.userSchedules[name].days.tuesday.times.split("-")[0]);
        // setTuesdayEndTime(user.userSchedules[name].days.tuesday.times.split("-")[1]);
        // setWednesdayStartTime(user.userSchedules[name].days.wednesday.times.split("-")[0]);
        // setWednesdayEndTime(user.userSchedules[name].days.wednesday.times.split("-")[1]);
        // setThursdayStartTime(user.userSchedules[name].days.thursday.times.split("-")[0]);
        // setThursdayEndTime(user.userSchedules[name].days.thursday.times.split("-")[1]);
        // setFridayStartTime(user.userSchedules[name].days.friday.times.split("-")[0]);
        // setFridayEndTime(user.userSchedules[name].days.friday.times.split("-")[1]);
        // setSaturdayStartTime(user.userSchedules[name].days.saturday.times.split("-")[0]);
        // setSaturdayEndTime(user.userSchedules[name].days.saturday.times.split("-")[1]);

        return setScheduleName(name);
      }
      return
    });


    const checkAndChangeHash = () => {
      const getTriggeredButton = window.location.href;

      if (getTriggeredButton.includes("#")) {
        const trigger = getTriggeredButton.split("#");
        var mainTrigger = null;
        var innerTrigger = null;


        if (trigger.length > 2) {
          mainTrigger = trigger[1];
          innerTrigger = trigger[2];
        } else {
          mainTrigger = trigger[1];
        }

        switch (mainTrigger) {
          case "NewMeetingCreation":
            setViewOf("NewMeetingCreation");
            setViewNewEventCreate(true);
            break;
          case "NewOneOnOneMeeting":
            setViewOf("NewOneOnOneMeeting");
            setOneOnOneEventCreation(true);
            setEventCreationSteps(1);
            break;
          case "NewGroupMeeting":
            setViewOf("NewGroupMeeting");
            setGroupEventCreation(true);
            setGroupEventCreationSteps(1);
            break;
          case "NewRoundRobinMeeting":
            setViewOf("NewRoundRobinMeeting");
            setRoundRobinEventCreation(true);
            setRoundRobinCreationSteps(1);
            break;
          case "Notifications":
            setViewOf("Notifications");
            setAlertShow(true);
            break;
          case "PeopleShortcut":
            setViewOf("PeopleShortcut");
            setContactShow(true);
            break;
          case "Meetings":
            setViewOf("Meetings");
            setMeetingShow(true);
            break;
          case "Schedules":
            setViewOf("Schedules");
            setAvailabilityShow(true);
            break;
          case "AccountSettings":
            setViewOf("AccountSettings");
            setViewAdminPage(true);
            break;
          case "People":
            setViewOf("People");
            setViewAdminPage(true);
            break;
          case "Billing":
            setViewOf("Billing");
            setViewAdminPage(true);
            break;
          case "CalendarConnections":
            setViewOf("CalendarConnections");
            setViewAdminPage(true);
            break;
          case "Availability":
            setViewOf("Availability");
            setViewAdminPage(true);
            break;
          case "Integrations":
            setViewOf("Integrations");
            setViewAdminPage(true);
            break;
          case "Logout":
            setViewOf("Logout");
            setViewAdminPage(true);
            break;
          default:
            setViewOf("AccountSettings");
            setViewAdminPage(true);
        }

        if (innerTrigger) {
          switch (innerTrigger) {
            case "scheduledEvents":
              setAccountInnerView("scheduledEvents");
              setMeetingsTabType("scheduledEvents");
              break;
            case "eventTypes":
              setAccountInnerView("eventTypes");
              setMeetingsTabType("eventTypes");
              break;
            case "Profile":
              setAccountInnerView("Profile");
              break;
            case "Branding":
              setAccountInnerView("Branding");
              break;
            case "MyLink":
              setAccountInnerView("MyLink");
              break;
            case "PhoneNumbers":
              setAccountInnerView("PhoneNumbers");
              break;
            default:
              if (mainTrigger === "Meetings") {
                setAccountInnerView("scheduledEvents");
              } else {
                setAccountInnerView("Profile");
              }
          }
        }
      }
    }
    checkAndChangeHash();

    window.addEventListener("hashchange", () => {
      const getTriggeredButtonRefresh = window.location.href;

      if (getTriggeredButtonRefresh.includes("#")) {
        const trigger = getTriggeredButtonRefresh.split("#");
        var mainTrigger = null;
        var innerTrigger = null;


        if (trigger.length > 2) {
          mainTrigger = trigger[1];
          innerTrigger = trigger[2];
        } else {
          mainTrigger = trigger[1];
        }

        switch (mainTrigger) {
          case "NewMeetingCreation":
            setViewOf("NewMeetingCreation");
            setViewNewEventCreate(true);

            setOneOnOneEventCreation(false);
            setEventCreationSteps(1);
            setGroupEventCreation(false);
            setGroupEventCreationSteps(1);
            setRoundRobinEventCreation(false);
            setRoundRobinCreationSteps(1);
            setAlertShow(false);
            setContactShow(false);
            setMeetingShow(false);
            setAvailabilityShow(false);
            setViewAdminPage(false);

            break;
          case "NewOneOnOneMeeting":
            setViewOf("NewOneOnOneMeeting");
            setOneOnOneEventCreation(true);
            setEventCreationSteps(1);

            setViewNewEventCreate(false);
            setGroupEventCreation(false);
            setGroupEventCreationSteps(1);
            setRoundRobinEventCreation(false);
            setRoundRobinCreationSteps(1);
            setAlertShow(false);
            setContactShow(false);
            setMeetingShow(false);
            setAvailabilityShow(false);
            setViewAdminPage(false);
            break;
          case "NewGroupMeeting":
            setViewOf("NewGroupMeeting");
            setGroupEventCreation(true);
            setGroupEventCreationSteps(1);

            setViewNewEventCreate(false);
            setOneOnOneEventCreation(false);
            setEventCreationSteps(1);
            setRoundRobinEventCreation(false);
            setRoundRobinCreationSteps(1);
            setAlertShow(false);
            setContactShow(false);
            setMeetingShow(false);
            setAvailabilityShow(false);
            setViewAdminPage(false);
            break;
          case "NewRoundRobinMeeting":
            setViewOf("NewRoundRobinMeeting");
            setRoundRobinEventCreation(true);
            setRoundRobinCreationSteps(1);

            setViewNewEventCreate(false);
            setOneOnOneEventCreation(false);
            setEventCreationSteps(1);
            setGroupEventCreation(false);
            setGroupEventCreationSteps(1);
            setAlertShow(false);
            setContactShow(false);
            setMeetingShow(false);
            setAvailabilityShow(false);
            setViewAdminPage(false);
            break;
          case "Notifications":
            setViewOf("Notifications");
            setAlertShow(true);

            setViewNewEventCreate(false);
            setOneOnOneEventCreation(false);
            setEventCreationSteps(1);
            setGroupEventCreation(false);
            setGroupEventCreationSteps(1);
            setRoundRobinEventCreation(false);
            setRoundRobinCreationSteps(1);
            setContactShow(false);
            setMeetingShow(false);
            setAvailabilityShow(false);
            setViewAdminPage(false);
            break;
          case "PeopleShortcut":
            setViewOf("PeopleShortcut");
            setContactShow(true);

            setViewNewEventCreate(false);
            setOneOnOneEventCreation(false);
            setEventCreationSteps(1);
            setGroupEventCreation(false);
            setGroupEventCreationSteps(1);
            setRoundRobinEventCreation(false);
            setRoundRobinCreationSteps(1);
            setAlertShow(false);
            setMeetingShow(false);
            setAvailabilityShow(false);
            setViewAdminPage(false);
            break;
          case "Meetings":
            setViewOf("Meetings");
            setMeetingShow(true);

            setViewNewEventCreate(false);
            setOneOnOneEventCreation(false);
            setEventCreationSteps(1);
            setGroupEventCreation(false);
            setGroupEventCreationSteps(1);
            setRoundRobinEventCreation(false);
            setRoundRobinCreationSteps(1);
            setAlertShow(false);
            setContactShow(false);
            setAvailabilityShow(false);
            setViewAdminPage(false);
            break;
          case "Schedules":
            setViewOf("Schedules");
            setAvailabilityShow(true);

            setViewNewEventCreate(false);
            setOneOnOneEventCreation(false);
            setEventCreationSteps(1);
            setGroupEventCreation(false);
            setGroupEventCreationSteps(1);
            setRoundRobinEventCreation(false);
            setRoundRobinCreationSteps(1);
            setAlertShow(false);
            setContactShow(false);
            setMeetingShow(false);
            setViewAdminPage(false);
            break;
          case "AccountSettings":
            setViewOf("AccountSettings");
            setViewAdminPage(true);

            setViewNewEventCreate(false);
            setOneOnOneEventCreation(false);
            setEventCreationSteps(1);
            setGroupEventCreation(false);
            setGroupEventCreationSteps(1);
            setRoundRobinEventCreation(false);
            setRoundRobinCreationSteps(1);
            setAlertShow(false);
            setContactShow(false);
            setMeetingShow(false);
            setAvailabilityShow(false);
            break;
          case "People":
            setViewOf("People");
            setViewAdminPage(true);

            setViewNewEventCreate(false);
            setOneOnOneEventCreation(false);
            setEventCreationSteps(1);
            setGroupEventCreation(false);
            setGroupEventCreationSteps(1);
            setRoundRobinEventCreation(false);
            setRoundRobinCreationSteps(1);
            setAlertShow(false);
            setContactShow(false);
            setMeetingShow(false);
            setAvailabilityShow(false);
            break;
          case "Billing":
            setViewOf("Billing");
            setViewAdminPage(true);

            setViewNewEventCreate(false);
            setOneOnOneEventCreation(false);
            setEventCreationSteps(1);
            setGroupEventCreation(false);
            setGroupEventCreationSteps(1);
            setRoundRobinEventCreation(false);
            setRoundRobinCreationSteps(1);
            setAlertShow(false);
            setContactShow(false);
            setMeetingShow(false);
            setAvailabilityShow(false);
            break;
          case "CalendarConnections":
            setViewOf("CalendarConnections");
            setViewAdminPage(true);

            setViewNewEventCreate(false);
            setOneOnOneEventCreation(false);
            setEventCreationSteps(1);
            setGroupEventCreation(false);
            setGroupEventCreationSteps(1);
            setRoundRobinEventCreation(false);
            setRoundRobinCreationSteps(1);
            setAlertShow(false);
            setContactShow(false);
            setMeetingShow(false);
            setAvailabilityShow(false);
            break;
          case "Availability":
            setViewOf("Availability");
            setViewAdminPage(true);

            setViewNewEventCreate(false);
            setOneOnOneEventCreation(false);
            setEventCreationSteps(1);
            setGroupEventCreation(false);
            setGroupEventCreationSteps(1);
            setRoundRobinEventCreation(false);
            setRoundRobinCreationSteps(1);
            setAlertShow(false);
            setContactShow(false);
            setMeetingShow(false);
            setAvailabilityShow(false);
            break;
          case "Integrations":
            setViewOf("Integrations");
            setViewAdminPage(true);

            setViewNewEventCreate(false);
            setOneOnOneEventCreation(false);
            setEventCreationSteps(1);
            setGroupEventCreation(false);
            setGroupEventCreationSteps(1);
            setRoundRobinEventCreation(false);
            setRoundRobinCreationSteps(1);
            setAlertShow(false);
            setContactShow(false);
            setMeetingShow(false);
            setAvailabilityShow(false);
            break;
          case "Logout":
            setViewOf("Logout");
            setViewAdminPage(true);

            setViewNewEventCreate(false);
            setOneOnOneEventCreation(false);
            setEventCreationSteps(1);
            setGroupEventCreation(false);
            setGroupEventCreationSteps(1);
            setRoundRobinEventCreation(false);
            setRoundRobinCreationSteps(1);
            setAlertShow(false);
            setContactShow(false);
            setMeetingShow(false);
            setAvailabilityShow(false);
            break;
          default:
            setViewOf("AccountSettings");
            setViewAdminPage(true);
        }

        if (innerTrigger) {
          switch (innerTrigger) {
            case "scheduledEvents":
              setAccountInnerView("scheduledEvents");
              setMeetingsTabType("scheduledEvents");
              break;
            case "eventTypes":
              setAccountInnerView("eventTypes");
              setMeetingsTabType("eventTypes");
              break;
            case "Profile":
              setAccountInnerView("Profile");
              break;
            case "Branding":
              setAccountInnerView("Branding");
              break;
            case "MyLink":
              setAccountInnerView("MyLink");
              break;
            case "PhoneNumbers":
              setAccountInnerView("PhoneNumbers");
              break;
            default:
              if (mainTrigger === "Meetings") {
                setAccountInnerView("scheduledEvents");
              } else {
                setAccountInnerView("Profile");
              }
          }
        }
      } else {
        setViewAdminPage(false);
        setViewNewEventCreate(false);
        setOneOnOneEventCreation(false);
        setEventCreationSteps(1);
        setGroupEventCreation(false);
        setGroupEventCreationSteps(1);
        setRoundRobinEventCreation(false);
        setRoundRobinCreationSteps(1);
        setAlertShow(false);
        setContactShow(false);
        setMeetingShow(false);
        setAvailabilityShow(false);
      }
    })

    setTrialDate(new Date(firstCreatedAt).toLocaleDateString("en-us", { dateStyle: "long" }))

    const checkSubscription = async () => {
      try {
        const query = {
          "email": user.email,
          "username": user.username
        }
        const checkSubs = await axios.post(serverUrl + "/subscription/checkSubscription", query);

        if (checkSubs.status === 200) {
          // console.log(checkSubs.data.details);
          setSubscription(checkSubs.data.details);
        } else {
          // console.log(checkSubs.data);
          setSubscription(null);
        }
      } catch (error) {
        console.log(error);
      }
    }
    checkSubscription();

    async function getAllEvents() {
      const getEvents = await axios.post(serverUrl + "/events/getAll/", { "username": user.username });
      if (getEvents.status === 200) {
        setAllEvents(getEvents.data);
      }
      else {
        // console.log(getEvents.data);
      }


      // ########################################## CHECK GOOGLE TOKEN ####################################################
      const checkTokenAvailable = await axios.post(serverUrl + "/events/checkToken", { "email": user.email });

      if (checkTokenAvailable.status === 200) {
        // console.log(checkTokenAvailable.data);
        setMyRefreshToken(checkTokenAvailable.data);
      }
      else {
        // console.log(checkTokenAvailable.data);
        setNeedToken(true);
      }

      // ########################################## CHECK ZOOM TOKEN ####################################################
      const checkZoomTokenAvailable = await axios.post(serverUrl + "/events/checkZoomToken", { "email": user.email })
      if (checkZoomTokenAvailable.status === 200) {
        // console.log(checkTokenAvailable.data);
        // setMyRefreshToken(checkTokenAvailable.data);
      }
      else {
        // console.log(checkZoomTokenAvailable.data);
        setNeedZoomToken(true);
      }



      // ########################################## CHECK GOTO MEETING TOKEN ####################################################
      const checkGotoTokenAvailable = await axios.post(serverUrl + "/events/checkGotoMeetingToken", { "email": user.email })
      if (checkGotoTokenAvailable.status === 200) {
        // console.log(checkGotoTokenAvailable.data);
        // setMyRefreshToken(checkGotoTokenAvailable.data);
      }
      else {
        // console.log(checkGotoTokenAvailable.data)
        setNeedGTMToken(true);
      }


      // ########################################## CHECK WEBEX TOKEN ####################################################
      const checkWebexTokenAvailable = await axios.post(serverUrl + "/events/checkWebexToken", { "email": user.email })
      if (checkWebexTokenAvailable.status === 200) {
        // console.log(checkTokenAvailable.data);
        // setMyRefreshToken(checkWebexTokenAvailable.data);
        setNeedWebexToken(false);
      }
      else {
        // console.log(checkWebexTokenAvailable.data);
        setNeedWebexToken(true);
      }


      // ########################################## CHECK TEAMS MEETING TOKEN ####################################################
      const checkTeamsTokenAvailable = await axios.post(serverUrl + "/events/checkTeamsToken", { "email": user.email })
      if (checkTeamsTokenAvailable.status === 200) {
        // console.log(checkTeamsTokenAvailable.data);
        // setMyRefreshToken(checkTeamsTokenAvailable.data);
        setNeedTeamsToken(false);
      }
      else {
        // console.log(checkTeamsTokenAvailable.data);
        setNeedTeamsToken(true);
      }


      const getMyCustomeLink = await axios.post(serverUrl + "/mainIds/get", { "email": user.email });

      if (getMyCustomeLink.status === 200) {
        // console.log(getMyCustomeLink.data);
        setMyCustomLink(getMyCustomeLink.data);
      }
      else {
        // console.log(getMyCustomeLink.data)
      }
    }
    getAllEvents();

    const getAllMeetings = async () => {
      try {
        const query = {
          "email": user.email
        }

        const getAllMeetings = await axios.post(serverUrl + "/users/getAllMeetings", query);
        if (getAllMeetings.status === 200) {
          setAllMeetings(getAllMeetings.data);
        } else {
          // console.log("error while getting meetings");
        }
      } catch (error) {
        // console.log(error);
      }
    }

    getAllMeetings();


    let timezonesArray = Object.entries(timezones);
    let countryArray = Object.entries(allCountries);
    let arrays = [];
    timezonesArray.map((val, key) => {
      arrays.push({ label: val[0], value: val[1] });
    })
    setTimezonesList(arrays);

    let newArray = [];
    countryArray.map((val, key) => {
      newArray.push({ label: val[1].name, value: val[0] });
    })

    setCountriesList(newArray);

    const countryFromSchedule = newArray.filter((countryNames) => { return countryNames.value === user.userSchedules[currentScheduleName].timezone.value.countries[0] });

    const restTimezonesSchedule = arrays.filter((zone) => { return zone.value.countries.includes(countryFromSchedule[0].value); });
    setFilteredTimezonesListSchedule(restTimezonesSchedule);

    setCountrySchedule(countryFromSchedule);


    const rest = arrays.filter((zone) => { return zone.value.countries.includes(user.settings.country.value); });
    setFilteredTimezonesList(rest);
    setSelectedTimezoneSettings(rest[0]);

  }, []);

  const startTimeZoneSwitch = (e) => {
    const rest = timezonesList.filter((zone) => { return zone.value.countries.includes(e.value); });
    setFilteredTimezonesList(rest);
    setSelectedTimezoneSettings(rest[0]);
  }

  const startTimeZoneSwitchSchedules = (e) => {
    const rest = timezonesList.filter((zone) => { return zone.value.countries.includes(e.value); });
    setFilteredTimezonesListSchedule(rest);
    setSelectedTimezone(rest[0]);
  }

  useEffect(() => {
    // console.log("used")


  }, [viewOf, accountInnerView, alertShow, contactShow, meetingsShow, availabilityShow, meetingsTabType]);

  const setToHistory = (primary, secondary) => {
    const href = window.location.href;
    var newView = null;
    var newInnerView = null;

    switch (primary) {
      case "NewMeetingCreation":
        newView = "NewMeetingCreation";
        break;
      case "NewOneOnOneMeeting":
        newView = "NewOneOnOneMeeting";
        break;
      case "NewGroupMeeting":
        newView = "NewGroupMeeting";
        break;
      case "NewRoundRobinMeeting":
        newView = "NewRoundRobinMeeting";
        break;
      case "Notifications":
        newView = "Notifications";
        break;
      case "PeopleShortcut":
        newView = "PeopleShortcut";
        break;
      case "Meetings":
        newView = "Meetings";
        break;
      case "Schedules":
        newView = "Schedules";
        break;
      case "AccountSettings":
        newView = "AccountSettings";
        break;
      case "People":
        newView = "People";
        break;
      case "Billing":
        newView = "Billing"
        break;
      case "CalendarConnections":
        newView = "CalendarConnections"
        break;
      case "Availability":
        newView = "Availability"
        break;
      case "Integrations":
        newView = "Integrations"
        break;
      case "Logout":
        newView = "Logout"
        break;
      default:
        newView = null;
        break;
    }


    switch (secondary) {
      case "Profile":
        newInnerView = "Profile";
        break;
      case "Branding":
        newInnerView = "Branding";
        break;
      case "MyLink":
        newInnerView = "MyLink";
        break;
      case "PhoneNumbers":
        newInnerView = "PhoneNumbers";
        break;
      case "scheduledEvents":
        newInnerView = "scheduledEvents";
        break;
      case "eventTypes":
        newInnerView = "eventTypes";
        break;
      default:
        if (newView === "Meetings") {
          newInnerView = "scheduledEvents";
        } else {
          newInnerView = "Profile";
        }
    }





    if (newView) {
      if (newView === "AccountSettings") {
        const realUrl = href.split("#")[0];
        const newUrl = realUrl + "#" + newView + "#" + newInnerView;
        window.history.pushState(null, "", newUrl);
        // window.history.replaceState(null, "", newUrl);
      } else if (newView === "Meetings") {
        const realUrl = href.split("#")[0];
        const newUrl = realUrl + "#" + newView + "#" + newInnerView;
        window.history.pushState(null, "", newUrl);
        // window.history.replaceState(null, "", newUrl);
      }
      else {
        const realUrl = href.split("#")[0];
        const newUrl = realUrl + "#" + newView;
        window.history.pushState(null, "", newUrl);
        // window.history.replaceState(null, "", newUrl);
      }
    } else {
      // console.log("elsed");
      window.history.pushState(null, "", href.split("#")[0]);
      // window.history.replaceState(null, "", href.split("#")[0]);
    }
  }

  useEffect(() => {
    // console.log(selectedTimezoneSettings.value)
    const interval = setInterval(() => {
      // setClockTIme(moment().format("hh:mm:ss A"));
      // setClockDate(moment().format("dddd D MMM YYYY"));
      setClockTIme(dayjs(new Date()).tz(selectedTimezoneSettings.value.name).format(timeFormat.value === "12 Hours" ? "h:mm:ss a" : "H:mm:ss"));
      setClockDate(new Date().toLocaleDateString("en-us", { timeZone: selectedTimezoneSettings.value.name, weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZoneName: "longGeneric" }));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [selectedTimezoneSettings])

  useEffect(() => {
    const selected = document.getElementsByClassName("selectedDayTabOneOff");
    if (selected.length) {
      for (var i = 0; i <= selected.length; i++) {
        selected[i].classList.remove("selectedDayTabOneOff");
      }
    }
    setCurrentMonth(getMonth(monthIndexCurrent));
    setNextMonth(getMonth(monthIndexCurrent + 1));
    // console.log(monthIndexCurrent);

  }, [monthIndexCurrent]);

  const toogleScheduleSet = async (scheduleName) => {

    setIsSundayActive(false);
    setIsMondayActive(false);
    setIsTuesdayActive(false);
    setIsWednesdayActive(false);
    setIsThursdayActive(false);
    setIsFridayActive(false);
    setIsSaturdayActive(false);

    setTimeout(() => {
      const countryFromSchedule = countriesList.filter((countryNames) => { return countryNames.value === userSchedules[scheduleName].timezone.value.countries[0] });

      setCountrySchedule(countryFromSchedule);

      const rest = timezonesList.filter((zone) => { return zone.value.countries.includes(countryFromSchedule[0].value); });
      setFilteredTimezonesListSchedule(rest);


      setSelectedTimezone(userSchedules[scheduleName].timezone);

      // setUserSchedules(userSchedules);
      setSchedule(userSchedules[scheduleName].days);

      setDateOverrides(userSchedules[scheduleName].dateOverrides);
      setIsSundayActive(userSchedules[scheduleName].days.sunday.active);
      setIsMondayActive(userSchedules[scheduleName].days.monday.active);
      setIsTuesdayActive(userSchedules[scheduleName].days.tuesday.active);
      setIsWednesdayActive(userSchedules[scheduleName].days.wednesday.active);
      setIsThursdayActive(userSchedules[scheduleName].days.thursday.active);
      setIsFridayActive(userSchedules[scheduleName].days.friday.active);
      setIsSaturdayActive(userSchedules[scheduleName].days.saturday.active);


      // setSundayStartTime(user.userSchedules[scheduleName].days.sunday.times.split("-")[0]);
      // setSundayEndTime(user.userSchedules[scheduleName].days.sunday.times.split("-")[1]);
      // setMondayStartTime(user.userSchedules[scheduleName].days.monday.times.split("-")[0]);
      // setMondayEndTime(user.userSchedules[scheduleName].days.monday.times.split("-")[1]);
      // setTuesdayStartTime(user.userSchedules[scheduleName].days.tuesday.times.split("-")[0]);
      // setTuesdayEndTime(user.userSchedules[scheduleName].days.tuesday.times.split("-")[1]);
      // setWednesdayStartTime(user.userSchedules[scheduleName].days.wednesday.times.split("-")[0]);
      // setWednesdayEndTime(user.userSchedules[scheduleName].days.wednesday.times.split("-")[1]);
      // setThursdayStartTime(user.userSchedules[scheduleName].days.thursday.times.split("-")[0]);
      // setThursdayEndTime(user.userSchedules[scheduleName].days.thursday.times.split("-")[1]);
      // setFridayStartTime(user.userSchedules[scheduleName].days.friday.times.split("-")[0]);
      // setFridayEndTime(user.userSchedules[scheduleName].days.friday.times.split("-")[1]);
      // setSaturdayStartTime(user.userSchedules[scheduleName].days.saturday.times.split("-")[0]);
      // setSaturdayEndTime(user.userSchedules[scheduleName].days.saturday.times.split("-")[1]);

      setScheduleName(scheduleName);
    }, 300);
  }

  const setUnderLined = (e) => {
    const tabs = document.querySelectorAll(".event-tabs");
    tabs.forEach(element => {
      element.classList.remove("underlinedButton");
    });
    e.target.classList.add("underlinedButton");
  }

  const updateProfile = async (e) => {
    e.preventDefault();

    const load = document.querySelector("#updateProfileInfoButton");
    load.disabled = true;
    load.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>Loading..';

    // console.log("processing");
    const eventData = {
      "email": user.email,
      "name": name,
      "welcome": welcome,
      "timeFormat": timeFormat,
      "country": country,
      "selectedTimezone": selectedTimezoneSettings,
    }
    const updateProfiles = await axios.post(serverUrl + "/users/updateSettings", eventData);

    if (updateProfiles.status === 200) {
      setViewToast(true); popupAlert.play();
      setToastTitle("Successful");
      setToastBody("Settings Updated.");
      setToastMessageType("success");

      // setCustomAlertBox(true);
      // setAlertBoxMessages("Settings Updated.");
      // setAlertErrorType("success");
      // console.log("success");

      load.disabled = false;
      load.innerHTML = 'Save Changes';

      const updateSettings = await axios.post(serverUrl + "/users/getUpdatedSettings", { "email": user.email });

      if (updateSettings.status === 200) {
        setName(updateSettings.data.name);
        setUsername(updateSettings.data.username);
        setWelcome(updateSettings.data.settings.welcome);
        setTimeFormat(updateSettings.data.settings.timeFormat);
        setTimeListToMeasure(updateSettings.data.settings.timeFormat.value !== "12 Hours" ? timeListToMeasureData24 : timeListToMeasureData)
        setCountry(updateSettings.data.settings.country);
        setSelectedTimezoneSettings(updateSettings.data.settings.selectedTimezone);
        setPhoneList(updateSettings.data.phoneNumbers);

        if (updateSettings.data.phoneNumbers) {
          let allActivePhoneNumbers = []
          Object.keys(updateSettings.data.phoneNumbers).map((number, key) => {
            if (updateSettings.data.phoneNumbers[number].active) {
              allActivePhoneNumbers.push({ label: number, value: number })
            }
          });
          setOptionListForPhone(allActivePhoneNumbers);
        }

      }
      else {
        // console.log("Unable to Update the User");
      }

    } else {

      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody("Error while updating the settings.");
      setToastMessageType("invalid");


      load.disabled = false;
      load.innerHTML = 'Save Changes';

      // setCustomAlertBox(true);
      // setAlertBoxMessages("Error while updating the settings.");
      // setAlertErrorType("invalid");
      // console.log(updateProfiles.data);
    }
  }

  const deleteAccount = async () => {

    const load = document.querySelector("#deleteAccountButton");
    load.disabled = true;
    load.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>Loading..';


    const deleteQuery = {
      "email": user.email,
      "deletionPolicy": deletionPolicy,
      "password": ownershipPassOne.current.value
    }

    const deleteAccount = await axios.post(serverUrl + "/users/deleteAccount", deleteQuery);
    if (deleteAccount.status === 200) {
      const deleteCookie = await axios.get(serverUrl + "/deleteCookie", { withCredentials: true });

      if (deleteCookie.status === 200) {
        setAskOwnership(false);

        setViewToast(true);
        popupAlert.play();
        setToastTitle("Successful");
        setToastBody("Account Deleted Successfully");
        setToastMessageType("success");

        setTimeFormat(() => {
          window.location.href = "/login";
        }, 3000)

        // setCustomAlertBox(true);
        // setAlertBoxMessages("Account Deleted Successfully");
        // setAlertErrorType("success");
      } else {
        setViewToast(true);
        popupAlert.play();
        setToastTitle("Error");
        setToastBody("Error while deleting your account");
        setToastMessageType("invalid");

        load.disabled = false;

        load.innerHTML = 'Authorize Now';

        // setCustomAlertBox(true);
        // setAlertBoxMessages("Account Deleted");
        // setAlertErrorType("success");
      }
    } else {
      setViewToast(true);
      popupAlert.play();
      setToastTitle("Error");
      setToastBody(deleteAccount.data);
      setToastMessageType("invalid");

      load.disabled = false;
      load.innerHTML = 'Authorize Now';

      // setCustomAlertBox(true);
      // setAlertBoxMessages(deleteAccount.data);
      // setAlertErrorType("invalid");
    }
  }

  const isUsernameAvailable = async (e) => {
    try {
      if (e.target.value.length > 0) {
        var nameRegex = /^[a-z0-9-]+$/;
        var validfirstUsername = e.target.value.match(nameRegex);
        if (validfirstUsername == null) {
          setUsernameFaults("invalid characters");
          setUsernameAvailable(false);
          // console.log("invalid characters")
        }
        else {
          setUsername(e.target.value);

          const queries = {
            "username": e.target.value,
            "email": user.email
          }
          const res = await axios.post(serverUrl + "/mainIds/check", queries);
          if (res.status === 200) {
            setUsernameAvailable(true);
            setUsernameFaults(null);
          } else {
            setUsernameFaults("username not available.");
            // console.log("username not available.");
            setUsernameAvailable(false);
          }
        }
      }
      else {
        setUsername(e.target.value);
        setUsernameAvailable(false);
        setUsernameFaults("Please provide a username.")
      }
    }
    catch (err) {
      // console.log(err)
    }
  }

  const updateLink = async (e) => {
    e.preventDefault();

    const loadSign = document.querySelector("#updateLinkFromSignUp");
    const loadAdmin = document.querySelector("#updateLinkFromAdmin");
    loadSign.disabled = true;
    loadAdmin.disabled = true;
    loadSign.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>Loading..';
    loadAdmin.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>Loading..';

    const query = {
      "email": user.email,
      "username": username,
    }

    const updateLinkNow = await axios.post(serverUrl + "/users/updateLink", query);
    if (updateLinkNow.status === 200) {
      setViewToast(true); popupAlert.play();
      setToastTitle("Successful");
      setToastBody("Link Updated.");
      setToastMessageType("success");


      loadSign.disabled = false;
      loadAdmin.disabled = false;
      loadSign.innerHTML = 'Next Step';
      loadAdmin.innerHTML = 'Save Changes';

      // setCustomAlertBox(true);
      // setAlertBoxMessages("Link Updated.");
      // setAlertErrorType("success");
      // console.log("link updated");

      const updateSettings = await axios.post(serverUrl + "/users/getUpdatedSettings", { "email": user.email });

      if (updateSettings.status === 200) {
        setName(updateSettings.data.name);
        setUsername(updateSettings.data.username);
        setWelcome(updateSettings.data.settings.welcome);
        setTimeFormat(updateSettings.data.settings.timeFormat);
        setTimeListToMeasure(updateSettings.data.settings.timeFormat.value !== "12 Hours" ? timeListToMeasureData24 : timeListToMeasureData);
        setCountry(updateSettings.data.settings.country);
        setSelectedTimezoneSettings(updateSettings.data.settings.selectedTimezone);
        setPhoneList(updateSettings.data.phoneNumbers);

        if (updateSettings.data.phoneNumbers) {
          let allActivePhoneNumbers = []
          Object.keys(updateSettings.data.phoneNumbers).map((number, key) => {
            if (updateSettings.data.phoneNumbers[number].active) {
              allActivePhoneNumbers.push({ label: number, value: number })
            }
          });
          setOptionListForPhone(allActivePhoneNumbers);
        }
      }
      else {
        // console.log("Unable to Update the User");
      }
    } else {
      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody("Error while updating the Link.");
      setToastMessageType("invalid");
      // setCustomAlertBox(true);
      // setAlertBoxMessages("Error while updating the Link.");
      // setAlertErrorType("invalid");
      // console.log(updateLinkNow.data);

      loadSign.disabled = false;
      loadAdmin.disabled = false;
      loadSign.innerHTML = 'Next Step';
      loadAdmin.innerHTML = 'Save Changes';
    }
  }

  const updateNumbers = async (e) => {
    e.preventDefault();

    const query = {
      "email": user.email,
      "phoneNumber": phoneNumberForOTP
    }

    const updateContacts = await axios.post(serverUrl + "/users/updatePhoneNow", query);
    if (updateContacts.status === 200) {
      setViewToast(true); popupAlert.play();
      setToastTitle("Successful");
      setToastBody("Phone Number Added.");
      setToastMessageType("success");

      // setCustomAlertBox(true);
      // setAlertBoxMessages("Phone Number Added");
      // setAlertErrorType("success");
      // console.log("link updated");

      const updateSettings = await axios.post(serverUrl + "/users/getUpdatedSettings", { "email": user.email });

      if (updateSettings.status === 200) {
        setName(updateSettings.data.name);
        setUsername(updateSettings.data.username);
        setWelcome(updateSettings.data.settings.welcome);
        setTimeFormat(updateSettings.data.settings.timeFormat);
        setTimeListToMeasure(updateSettings.data.settings.timeFormat.value !== "12 Hours" ? timeListToMeasureData24 : timeListToMeasureData);
        setCountry(updateSettings.data.settings.country);
        setSelectedTimezoneSettings(updateSettings.data.settings.selectedTimezone);
        setPhoneList(updateSettings.data.phoneNumbers);

        if (updateSettings.data.phoneNumbers) {
          let allActivePhoneNumbers = []
          Object.keys(updateSettings.data.phoneNumbers).map((number, key) => {
            if (updateSettings.data.phoneNumbers[number].active) {
              allActivePhoneNumbers.push({ label: number, value: number })
            }
          });
          setOptionListForPhone(allActivePhoneNumbers);
        }
      }
      else {
        // console.log("Unable to Update the User");
      }



    } else {
      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody("Error while updating the Contact List.");
      setToastMessageType("invalid");

      // setCustomAlertBox(true);
      // setAlertBoxMessages("Error while updating the Contact List.");
      // setAlertErrorType("invalid");
      // console.log(updateContacts.data);
    }
  }

  const deletePhoneNow = async (phone) => {

    const query = {
      "email": user.email,
      "phoneNumber": phone
    }

    const updateContacts = await axios.post(serverUrl + "/users/deletePhoneNow", query);
    if (updateContacts.status === 200) {

      setViewToast(true); popupAlert.play();
      setToastTitle("Successful");
      setToastBody("Phone Number Deleted.");
      setToastMessageType("success");

      // setCustomAlertBox(true);
      // setAlertBoxMessages("Phone Number Deleted");
      // setAlertErrorType("success");
      // console.log("link updated");


      const updateSettings = await axios.post(serverUrl + "/users/getUpdatedSettings", { "email": user.email });

      if (updateSettings.status === 200) {
        setName(updateSettings.data.name);
        setUsername(updateSettings.data.username);
        setWelcome(updateSettings.data.settings.welcome);
        setTimeFormat(updateSettings.data.settings.timeFormat);
        setTimeListToMeasure(updateSettings.data.settings.timeFormat.value !== "12 Hours" ? timeListToMeasureData24 : timeListToMeasureData);
        setCountry(updateSettings.data.settings.country);
        setSelectedTimezoneSettings(updateSettings.data.settings.selectedTimezone);
        setPhoneList(updateSettings.data.phoneNumbers);

        if (updateSettings.data.phoneNumbers) {
          let allActivePhoneNumbers = []
          Object.keys(updateSettings.data.phoneNumbers).map((number, key) => {
            if (updateSettings.data.phoneNumbers[number].active) {
              allActivePhoneNumbers.push({ label: number, value: number })
            }
          });
          setOptionListForPhone(allActivePhoneNumbers);
        }
      }
      else {
        setViewToast(true); popupAlert.play();
        setToastTitle("Error");
        setToastBody("Unable to Update the User.");
        setToastMessageType("invalid");

        // setCustomAlertBox(true);
        // setAlertBoxMessages("Unable to Update the User");
        // setAlertErrorType("invalid");
        // console.log(updateContacts.data);
        // console.log("Unable to Update the User");
      }

    } else {
      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody("Error while updating the Contact List.");
      setToastMessageType("invalid");

      // setCustomAlertBox(true);
      // setAlertBoxMessages("Error while updating the Contact List.");
      // setAlertErrorType("invalid");
      // console.log(updateContacts.data);
    }
  }

  const phoneActiveSwitcher = async (givenNumber) => {
    const query = {
      "email": user.email,
      "phoneNumber": givenNumber
    }

    const updatePhoneState = await axios.post(serverUrl + "/users/updatePhoneState", query);

    if (updatePhoneState.status === 200) {
      setPhoneList(updatePhoneState.data);

      let allActivePhoneNumbers = []
      Object.keys(updatePhoneState.data).map((number, key) => {
        if (updatePhoneState.data[number].active) {
          allActivePhoneNumbers.push({ label: number, value: number })
        }
      });
      setOptionListForPhone(allActivePhoneNumbers);

      // console.log("phone status updated");
    } else {
      // console.log(updatePhoneState.data);
    }
  }

  const showPreviewImages = (files) => {
    let imagesData = [];
    let i = 0;
    for (i = 0; i < files.length; i++) {
      const fileSize = filesize(files[i].size, { round: 0 });
      supportedMedia.includes(files[i].name.split(".")[files[i].name.split(".").length - 1]) ?
        imagesData.push(<div className='realImageFrame'>
          <img title={files[i].name} src={URL.createObjectURL(files[i])} alt="caledary preview" />
          <span className='fileSize'>
            {fileSize}
          </span>
        </div>)
        :
        imagesData.push(<div className='realImageFrame'>
          <img title={files[i].name} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKn0Wlac09KdThD3hIB9vHVlX3hZsym5_JuFwknrw6rGULNT7TWH2MqaCSpRG4lPboB6o&usqp=CAU" alt="demo" />
          <span className='fileSize'>
            {fileSize}
          </span>
        </div>)
    }
    return imagesData;
  }

  const responceGoogle = response => {
    // console.log(response);
    axios.post(serverUrl + "/events/create-tokens", { "email": user.email, "code": response.code })
      .then(response => {
        // console.log(response);

        if (response.data.allTokens.refresh_token) {
          // console.log("New Refresh Token has Recieved..!");
          // console.log(response.data.allTokens);

          axios.post(serverUrl + "/users/addCalendar/", { "adminEmail": user.email, "userData": response.data.otherData, "allTokens": response.data.allTokens })
            .then(response => {

              setViewToast(true); popupAlert.play();
              setToastTitle("Successful");
              setToastBody("Calendar added successfully.");
              setToastMessageType("success");

              // setCustomAlertBox(true);
              // setAlertBoxMessages("Calendar added successfully.");
              // setAlertErrorType("success");

              // console.log(response.data);
              setMyRefreshTokens(response.data);
            }).catch(err => {
              // console.log(err);
            });
        }
        else {
          // console.log("no tokens");
          axios.post(serverUrl + "/users/addCalendar/", { "userData": response.data.otherData, "refreshToken": null })
            .then(response => {
              setViewToast(true); popupAlert.play();
              setToastTitle("Successful");
              setToastBody("Calendar added successfully.");
              setToastMessageType("success");

              // setCustomAlertBox(true);
              // setAlertBoxMessages("Calendar added successfully.");
              // setAlertErrorType("success");
              // console.log(response.data);
            }).catch(err => {
              // console.log(err);
            });
        }
      })
      .catch((error) => { console.log(error.message) })
  }

  const responseError = error => {
    // console.log(error)
  }

  const removeCalendar = async (email) => {
    const query = {
      "email": email,
      "adminEmail": user.email
    }

    const removeCalendar = await axios.post(serverUrl + "/users/removeCalendar", query);
    if (removeCalendar.status === 200) {
      // console.log("Calendar removed successfully");
      setViewToast(true); popupAlert.play();
      setToastTitle("Successful");
      setToastBody("Calendar removed successfully.");
      setToastMessageType("success");

      // setCustomAlertBox(true);
      // setAlertBoxMessages("Calendar removed successfully.");
      // setAlertErrorType("success");
      setMyRefreshTokens(removeCalendar.data);
    } else {
      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody("Error while removing calendar.");
      setToastMessageType("invalid");

      // setCustomAlertBox(true);
      // setAlertBoxMessages("Error while removing calendar.");
      // setAlertErrorType("invalid");
      // console.log("Error while removing calendar.");
    }
  }

  const switchCalendar = async (email) => {
    const query = {
      "email": email,
      "adminEmail": user.email
    }

    const switchCalendar = await axios.post(serverUrl + "/users/switchCalendar", query);
    if (switchCalendar.status === 200) {
      // console.log("Calendar switched successfully");

      setViewToast(true); popupAlert.play();
      setToastTitle("Successful");
      setToastBody("Calendar switched successfully.");
      setToastMessageType("success");

      // setCustomAlertBox(true);
      // setAlertBoxMessages("Calendar switched successfully.");
      // setAlertErrorType("success");
      setMyRefreshTokens(switchCalendar.data);
      setShowCalendarList(false);
    } else {
      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody("Error while switching calendar.");
      setToastMessageType("invalid");

      // setCustomAlertBox(true);
      // setAlertBoxMessages("Error while switching calendar.");
      // setAlertErrorType("invalid");
      // console.log("Error while switching calendar.");

      setShowCalendarList(false);
    }
  }

  const handlePay = async (e, amount) => {
    e.preventDefault()
    const response = await axios.post(serverUrl + "/razorpay/order", { "amount": amount });
    const { data } = response;
    const options = {
      key: "rzp_live_sZ2FJASD87BTl8",
      name: "GLOBAL KCLOUD TECHNOLOGIES PVT. LTD.",
      description: "Salseforce Company",
      currency: "USD",
      order_id: data.id,
      handler: async (response) => {
        try {
          const paymentId = response.razorpay_payment_id;
          const captureResponse = await axios.post(serverUrl + `/razorpay/capture/${paymentId}`, { "amount": amount })
          const successObj = JSON.parse(captureResponse.data);
          if (successObj.captured) {

            // console.log("Payment Successful");

            const query = {
              email: user.email,
              username: username,
              paymentData: successObj
            }
            const addSubscription = await axios.post(serverUrl + "/subscription/saveSubscription", query);
            if (addSubscription) {

              setViewToast(true);
              popupAlert.play();
              setToastTitle("Successful");
              setToastBody(addSubscription.data);
              setToastMessageType("success");

              // setCustomAlertBox(true);
              // setAlertBoxMessages(addSubscription.data);
              // setAlertErrorType("success");


              // console.log("Success ", addSubscription);
            } else {
              setViewToast(true); popupAlert.play();
              setToastTitle("Successful");
              setToastBody("Failed to add Subscription");
              setToastMessageType("invalid");

              // setCustomAlertBox(true);
              // setAlertBoxMessages("Failed to add Subscription");
              // console.log("Failed to add Subscription");
              // setAlertErrorType("invalid");
            }
          }
        } catch (error) {
          // console.log(error)
        }
      },
      theme: {
        color: "#0b5cab"
      }
    }

    const rzp1 = new window.Razorpay(options);
    rzp1.open()
  }

  const updateNewSchedule = async (e, frameName) => {

    e.preventDefault();
    let currentScheduleName = null;

    if (frameName === "model") {
      currentScheduleName = newScheduleName;
    } else {
      currentScheduleName = scheduleName;
    }

    let existing = false;
    let isDefault = false;
    let allSchedules = userSchedules;

    const sundayTabsStart = document.getElementsByClassName("sundayStartTabs" + frameName);
    const sundayTabsEnd = document.getElementsByClassName("sundayEndTabs" + frameName);

    const mondayTabsStart = document.getElementsByClassName("mondayStartTabs" + frameName);
    const mondayTabsEnd = document.getElementsByClassName("mondayEndTabs" + frameName);

    const tuesdayTabsStart = document.getElementsByClassName("tuesdayStartTabs" + frameName);
    const tuesdayTabsEnd = document.getElementsByClassName("tuesdayEndTabs" + frameName);

    const wednesdayTabsStart = document.getElementsByClassName("wednesdayStartTabs" + frameName);
    const wednesdayTabsEnd = document.getElementsByClassName("wednesdayEndTabs" + frameName);

    const thursdayTabsStart = document.getElementsByClassName("thursdayStartTabs" + frameName);
    const thursdayTabsEnd = document.getElementsByClassName("thursdayEndTabs" + frameName);

    const fridayTabsStart = document.getElementsByClassName("fridayStartTabs" + frameName);
    const fridayTabsEnd = document.getElementsByClassName("fridayEndTabs" + frameName);

    const saturdayTabsStart = document.getElementsByClassName("saturdayStartTabs" + frameName);
    const saturdayTabsEnd = document.getElementsByClassName("saturdayEndTabs" + frameName);



    let sundayArray = [];
    let mondayArray = [];
    let tuesdayArray = [];
    let wednesdayArray = [];
    let thursdayArray = [];
    let fridayArray = [];
    let saturdayArray = [];


    let lengthSunday = sundayTabsStart.length;
    for (let i = 0; i < lengthSunday; i++) {
      sundayArray.push(sundayTabsStart[i].value + "-" + sundayTabsEnd[i].value);
    }

    let lengthMonday = mondayTabsStart.length;
    for (let i = 0; i < lengthMonday; i++) {
      mondayArray.push(mondayTabsStart[i].value + "-" + mondayTabsEnd[i].value);
    }

    let lengthTuesday = tuesdayTabsStart.length;
    for (let i = 0; i < lengthTuesday; i++) {
      tuesdayArray.push(tuesdayTabsStart[i].value + "-" + tuesdayTabsEnd[i].value);
    }

    let lengthWednesday = wednesdayTabsStart.length;
    for (let i = 0; i < lengthWednesday; i++) {
      wednesdayArray.push(wednesdayTabsStart[i].value + "-" + wednesdayTabsEnd[i].value);
    }

    let lengthThursday = thursdayTabsStart.length;
    for (let i = 0; i < lengthThursday; i++) {
      thursdayArray.push(thursdayTabsStart[i].value + "-" + thursdayTabsEnd[i].value);
    }

    let lengthFriday = fridayTabsStart.length;
    for (let i = 0; i < lengthFriday; i++) {
      fridayArray.push(fridayTabsStart[i].value + "-" + fridayTabsEnd[i].value);
    }

    let lengthSaturday = saturdayTabsStart.length;
    for (let i = 0; i < lengthSaturday; i++) {
      saturdayArray.push(saturdayTabsStart[i].value + "-" + saturdayTabsEnd[i].value);
    }


    Object.keys(userSchedules).map((name, key) => {
      if (currentScheduleName === name) {
        return existing = true;
      }
      return
    });

    if (existing) {

      Object.keys(userSchedules).map((name, key) => {
        if (currentScheduleName !== name) {
          allSchedules[name].inUse = false;
        } else {
          if (allSchedules[name].default === true) {
            isDefault = true;
          }
        }
        return
      });

      let sundaySubset = [];
      let mondaySubset = [];
      let tuesdaySubset = [];
      let wednesdaySubset = [];
      let thursdaySubset = [];
      let fridaySubset = [];
      let saturdaySubset = [];

      let dateOverridesSubnet = {};


      sundayArray.map((data, key) => {
        const times = data.split("-");
        const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const combineString = `${timeF1}-${timeF2}`;
        return sundaySubset.push(combineString);
      })
      mondayArray.map((data, key) => {
        const times = data.split("-");
        const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const combineString = `${timeF1}-${timeF2}`;
        return mondaySubset.push(combineString);
      })
      tuesdayArray.map((data, key) => {
        const times = data.split("-");
        const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const combineString = `${timeF1}-${timeF2}`;
        return tuesdaySubset.push(combineString);
      })
      wednesdayArray.map((data, key) => {
        const times = data.split("-");
        const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const combineString = `${timeF1}-${timeF2}`;
        return wednesdaySubset.push(combineString);
      })
      thursdayArray.map((data, key) => {
        const times = data.split("-");
        const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const combineString = `${timeF1}-${timeF2}`;
        return thursdaySubset.push(combineString);
      })
      fridayArray.map((data, key) => {
        const times = data.split("-");
        const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const combineString = `${timeF1}-${timeF2}`;
        return fridaySubset.push(combineString);
      })
      saturdayArray.map((data, key) => {
        const times = data.split("-");
        const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const combineString = `${timeF1}-${timeF2}`;
        return saturdaySubset.push(combineString);
      })

      if (dateOverrides) {
        Object.keys(dateOverrides).map((data, key) => {
          const origionalTimeSet = dateOverrides[data].timeSet;
          let subnetForNow = [];

          origionalTimeSet.map((data, key) => {
            const times = data.split("-");

            // console.log(times)
            const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
            const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
            const combineString = `${timeF1}-${timeF2}`;
            return subnetForNow.push(combineString);
          })

          dateOverridesSubnet[data] = {
            "active": dateOverrides[data].active,
            "date": dateOverrides[data].date,
            "timeSet": subnetForNow,
            "uid": dateOverrides[data].uid,
            "sortingNumber": dateOverrides[data].sortingNumber
          }

        })
      }

      // console.log(mondaySubset, dateOverridesSubnet);


      allSchedules[currentScheduleName] = {
        "identifier": allSchedules[currentScheduleName].identifier,
        "inUse": true,
        "default": isDefault,
        "dateOverrides": dateOverridesSubnet,
        "timezone": selectedTimezone,
        "days": {
          "sunday": { "active": isSundayActive, "times": isSundayActive ? sundayArray.length === 0 ? ["9:00am-5:00pm"] : sundaySubset : [] },
          "monday": { "active": isMondayActive, "times": isMondayActive ? mondayArray.length === 0 ? ["9:00am-5:00pm"] : mondaySubset : [] },
          "tuesday": { "active": isTuesdayActive, "times": isTuesdayActive ? tuesdayArray.length === 0 ? ["9:00am-5:00pm"] : tuesdaySubset : [] },
          "wednesday": { "active": isWednesdayActive, "times": isWednesdayActive ? wednesdayArray.length === 0 ? ["9:00am-5:00pm"] : wednesdaySubset : [] },
          "thursday": { "active": isThursdayActive, "times": isThursdayActive ? thursdayArray.length === 0 ? ["9:00am-5:00pm"] : thursdaySubset : [] },
          "friday": { "active": isFridayActive, "times": isFridayActive ? fridayArray.length === 0 ? ["9:00am-5:00pm"] : fridaySubset : [] },
          "saturday": { "active": isSaturdayActive, "times": isSaturdayActive ? saturdayArray.length === 0 ? ["9:00am-5:00pm"] : saturdaySubset : [] },
        }
      }
    } else {

      Object.keys(userSchedules).map((name, key) => {
        if (currentScheduleName !== name) {
          allSchedules[name].inUse = false;
        } else {
          if (allSchedules[name].default === true) {
            isDefault = true;
          }
        }
        return
      });


      let sundaySubset = [];
      let mondaySubset = [];
      let tuesdaySubset = [];
      let wednesdaySubset = [];
      let thursdaySubset = [];
      let fridaySubset = [];
      let saturdaySubset = [];

      let dateOverridesSubnet = {};

      sundayArray.map((data, key) => {
        const times = data.split("-");
        const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const combineString = `${timeF1}-${timeF2}`;
        return sundaySubset.push(combineString);
      })
      mondayArray.map((data, key) => {
        const times = data.split("-");
        const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const combineString = `${timeF1}-${timeF2}`;
        return mondaySubset.push(combineString);
      })
      tuesdayArray.map((data, key) => {
        const times = data.split("-");
        const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const combineString = `${timeF1}-${timeF2}`;
        return tuesdaySubset.push(combineString);
      })
      wednesdayArray.map((data, key) => {
        const times = data.split("-");
        const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const combineString = `${timeF1}-${timeF2}`;
        return wednesdaySubset.push(combineString);
      })
      thursdayArray.map((data, key) => {
        const times = data.split("-");
        const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const combineString = `${timeF1}-${timeF2}`;
        return thursdaySubset.push(combineString);
      })
      fridayArray.map((data, key) => {
        const times = data.split("-");
        const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const combineString = `${timeF1}-${timeF2}`;
        return fridaySubset.push(combineString);
      })
      saturdayArray.map((data, key) => {
        const times = data.split("-");
        const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
        const combineString = `${timeF1}-${timeF2}`;
        return saturdaySubset.push(combineString);
      })


      Object.keys(dateOverrides).map((data, key) => {
        const origionalTimeSet = dateOverrides[data].timeSet;
        let subnetForNow = [];

        origionalTimeSet.map((data, key) => {
          const times = data.split("-");
          const timeF1 = dayjs(`2 November 1999 ${times[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
          const timeF2 = dayjs(`2 November 1999 ${times[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma");
          const combineString = `${timeF1}-${timeF2}`;
          return subnetForNow.push(combineString);
        })

        dateOverridesSubnet[data] = {
          "active": dateOverrides[data].active,
          "date": dateOverrides[data].date,
          "timeSet": subnetForNow,
          "uid": dateOverrides[data].uid,
          "sortingNumber": dateOverrides[data].sortingNumber
        }

      })


      allSchedules[currentScheduleName] = {
        "identifier": uuidv4(),
        "inUse": true,
        "default": isDefault,
        "dateOverrides": dateOverridesSubnet,
        "timezone": selectedTimezone,
        "days": {
          "sunday": { "active": isSundayActive, "times": isSundayActive ? sundayArray.length === 0 ? ["9:00am-5:00pm"] : sundaySubset : [] },
          "monday": { "active": isMondayActive, "times": isMondayActive ? mondayArray.length === 0 ? ["9:00am-5:00pm"] : mondaySubset : [] },
          "tuesday": { "active": isTuesdayActive, "times": isTuesdayActive ? tuesdayArray.length === 0 ? ["9:00am-5:00pm"] : tuesdaySubset : [] },
          "wednesday": { "active": isWednesdayActive, "times": isWednesdayActive ? wednesdayArray.length === 0 ? ["9:00am-5:00pm"] : wednesdaySubset : [] },
          "thursday": { "active": isThursdayActive, "times": isThursdayActive ? thursdayArray.length === 0 ? ["9:00am-5:00pm"] : thursdaySubset : [] },
          "friday": { "active": isFridayActive, "times": isFridayActive ? fridayArray.length === 0 ? ["9:00am-5:00pm"] : fridaySubset : [] },
          "saturday": { "active": isSaturdayActive, "times": isSaturdayActive ? saturdayArray.length === 0 ? ["9:00am-5:00pm"] : saturdaySubset : [] },
        }
      }
    }

    const updateScheduleList = await axios.post(serverUrl + "/users/updateScheduleList", { "newSchedualData": allSchedules, "email": user.email });
    if (updateScheduleList.status === 200) {
      setViewToast(true); popupAlert.play();
      setToastTitle("Successful");
      setToastBody(updateScheduleList.data);
      setToastMessageType("success");

      setCreateNewSchedule(false);
      setNewScheduleName("");


      // console.log(allSchedules[currentScheduleName]);

      setUserSchedules(allSchedules);

      toogleScheduleSet(currentScheduleName);

      if (allSchedules) {
        let listSchedules = []

        Object.keys(allSchedules).map((name, key) => {
          return listSchedules.push({ label: name, value: name })
        })
        setUserScheduleListForSelect(listSchedules);
      }


      // setCustomAlertBox(true);
      // setAlertBoxMessages(updateScheduleList.data);
      // setAlertErrorType("success");
    } else {
      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody(updateScheduleList.data);
      setToastMessageType("invalid");

      // setCustomAlertBox(true);
      // setAlertBoxMessages(updateScheduleList.data);
      // setAlertErrorType("invalid");
    }
  }

  const creatingNewDefaultSchedule = async (e) => {
    e.preventDefault();
    let currentScheduleName = newScheduleName;

    let existing = false;
    let isDefault = false;
    let allSchedules = userSchedules;


    Object.keys(userSchedules).map((name, key) => {
      if (currentScheduleName === name) {
        return existing = true;
      }
      return
    });

    if (existing) {
      Object.keys(userSchedules).map((name, key) => {
        if (currentScheduleName !== name) {
          allSchedules[name].inUse = false;
        } else {
          if (allSchedules[name].default === true) {
            isDefault = true;
          }
        }
        return
      });

      allSchedules[currentScheduleName] = {
        "inUse": true,
        "default": isDefault,
        "identifier": allSchedules[currentScheduleName].identifier,
        "dateOverrides": allSchedules[currentScheduleName].dateOverrides,
        "timezone": allSchedules[currentScheduleName].timezone,
        "days": allSchedules[currentScheduleName].days
      }

    } else {
      Object.keys(userSchedules).map((name, key) => {
        if (currentScheduleName !== name) {
          allSchedules[name].inUse = false;
        } else {
          if (allSchedules[name].default === true) {
            isDefault = true;
          }
        }
        return
      });

      allSchedules[currentScheduleName] = {
        "inUse": true,
        "identifier": uuidv4(),
        "default": isDefault,
        "dateOverrides": {},
        "timezone": selectedTimezoneSettings,
        "days": {
          "sunday": { "active": false, "times": [] },
          "monday": { "active": true, "times": ["9:00am-5:00pm"] },
          "tuesday": { "active": true, "times": ["9:00am-5:00pm"] },
          "wednesday": { "active": true, "times": ["9:00am-5:00pm"] },
          "thursday": { "active": true, "times": ["9:00am-5:00pm"] },
          "friday": { "active": true, "times": ["9:00am-5:00pm"] },
          "saturday": { "active": false, "times": [] },
        }
      }

    }


    const updateScheduleList = await axios.post(serverUrl + "/users/updateScheduleList", { "newSchedualData": allSchedules, "email": user.email });
    if (updateScheduleList.status === 200) {
      setViewToast(true);
      popupAlert.play();
      setToastTitle("Successful");
      setToastBody(updateScheduleList.data);
      setToastMessageType("success");

      setCreateNewSchedule(false);
      setNewScheduleName("");

      // console.log(allSchedules[currentScheduleName]);

      setUserSchedules(allSchedules);

      toogleScheduleSet(currentScheduleName);

      if (allSchedules) {
        let listSchedules = []

        Object.keys(allSchedules).map((name, key) => {
          return listSchedules.push({ label: name, value: name })
        })
        setUserScheduleListForSelect(listSchedules);
      }


      // setCustomAlertBox(true);
      // setAlertBoxMessages(updateScheduleList.data);
      // setAlertErrorType("success");
    } else {
      setViewToast(true);
      popupAlert.play();
      setToastTitle("Error");
      setToastBody(updateScheduleList.data);
      setToastMessageType("invalid");

      // setCustomAlertBox(true);
      // setAlertBoxMessages(updateScheduleList.data);
      // setAlertErrorType("invalid");
    }

  }

  const startDeleteSchedule = async (scheduleTitle) => {
    const deleteScheduleName = await axios.post(serverUrl + "/users/deleteSchedule", { "email": user.email, "scheduleName": scheduleTitle });
    if (deleteScheduleName.status === 200) {
      // console.log(deleteScheduleName.data)
      setUserSchedules(deleteScheduleName.data);

      let allSchedules = deleteScheduleName.data;

      if (allSchedules) {
        let listSchedules = []

        Object.keys(allSchedules).map((name, key) => {
          return listSchedules.push({ label: name, value: name })
        })
        setUserScheduleListForSelect(listSchedules);
      }


      Object.keys(allSchedules).map((scheduleName, key) => {
        if (allSchedules[scheduleName].default) {
          return toogleScheduleSet(scheduleName)
          // setScheduleName(scheduleName);
          // return setSchedule(allSchedules[scheduleName].days);
        }
        else {
          return <></>
        }
      });

      setViewToast(true); popupAlert.play();
      setToastTitle("Successful");
      setToastBody("Schedule Deleted Successfully.");
      setToastMessageType("success");

      // setCustomAlertBox(true);
      // setAlertBoxMessages("Schedule Deleted Successfully.");
      // setAlertErrorType("success");
    } else {
      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody(deleteScheduleName.data);
      setToastMessageType("invalid");

      // setCustomAlertBox(true);
      // setAlertBoxMessages(deleteScheduleName.data);
      // setAlertErrorType("invalid");
    }

    setCustomAlertBox(false);
  }

  const startAddingTheOverride = async (e) => {
    e.preventDefault();


    const overrideStartTabs = document.getElementsByClassName("overrideStartTabs");
    const overrideEndTabs = document.getElementsByClassName("overrideEndTabs");


    let lengthStart = overrideStartTabs.length;

    let overrideArrayList = [];

    for (let i = 0; i < lengthStart; i++) {
      overrideArrayList.push(overrideStartTabs[i].value + "-" + overrideEndTabs[i].value);
    }

    // console.log(overrideArrayList);

    setOverrideArray(overrideArrayList);

    let allOverrides = dateOverrides;


    if (!allOverrides) {
      allOverrides = {};
    }

    allOverrides[overrideDate.toISOString()] = { "active": overrideDateEnabled, "date": overrideDate?.toISOString(), "timeSet": overrideArrayList, "uid": uuidv4(), "sortingNumber": Date.parse(overrideDate) };


    let arrCOnv = Object.entries(allOverrides)
    arrCOnv.sort((a, b) => a[1].sortingNumber - b[1].sortingNumber);

    let rebirth = Object.fromEntries(arrCOnv);

    setDateOverrides(rebirth);

    const vgh = document.getElementsByClassName("selectedDayTabOneOff");
    if (vgh.length) {
      for (let x = 0; x < vgh.length; x++) {
        vgh[x].classList.remove("selectedDayTabOneOff");
      }
    }



    setViewDateOverridePanel(false);
    setOverrideDate(null);
    setOverrideDateEnabled(false);
    setOverrideEndTime(timeFormat.value !== "12 Hours" ? "17:00" : "5:00pm");
    setOverrideStartTime(timeFormat.value !== "12 Hours" ? "09:00" : "9:00am");
  }

  const deleteOverrideDate = (date, uid) => {
    let allOverrides = dateOverrides;

    delete allOverrides[date];
    // console.log(allOverrides);
    setDateOverrides(allOverrides);

    // let x = document.getElementsByClassName(uid);
    // for (var i = 0; i < Object.keys(x).length; i++) {
    //   x[i].style.display = "none";
    // }
  }

  const deleteTimeSet = (dayName, frameName, uid) => {
    // console.log(dayName, uid);
    switch (dayName) {
      case "sunday":
        document.getElementById("sundayTimeFrame_" + uid).remove();

        const allEndSundayTimes = document.getElementsByClassName("sundayEndTabs" + frameName);
        if (allEndSundayTimes.length) {
          checkUpdatedStateOfTimeValidation(frameName);
        } else {
          setIsSundayActive(false);
          checkUpdatedStateOfTimeValidation(frameName);
        }
        break;
      case "monday":
        document.getElementById("mondayTimeFrame_" + uid).remove();

        const allEndMondayTimes = document.getElementsByClassName("mondayEndTabs" + frameName);
        if (allEndMondayTimes.length) {
          checkUpdatedStateOfTimeValidation(frameName);
        } else {
          setIsMondayActive(false);
          checkUpdatedStateOfTimeValidation(frameName);
        }
        break;
      case "tuesday":
        document.getElementById("tuesdayTimeFrame_" + uid).remove();

        const allEndTuesdayTimes = document.getElementsByClassName("tuesdayEndTabs" + frameName);
        if (allEndTuesdayTimes.length) {
          checkUpdatedStateOfTimeValidation(frameName);
        } else {
          setIsTuesdayActive(false);
          checkUpdatedStateOfTimeValidation(frameName);
        }
        break;
      case "wednesday":
        document.getElementById("wednesdayTimeFrame_" + uid).remove();

        const allEndWednesdayTimes = document.getElementsByClassName("wednesdayEndTabs" + frameName);
        if (allEndWednesdayTimes.length) {
          checkUpdatedStateOfTimeValidation(frameName);
        } else {
          setIsWednesdayActive(false);
          checkUpdatedStateOfTimeValidation(frameName);
        }
        break;
      case "thursday":
        document.getElementById("thursdayTimeFrame_" + uid).remove();

        const allEndThursdayTimes = document.getElementsByClassName("thursdayEndTabs" + frameName);
        if (allEndThursdayTimes.length) {
          checkUpdatedStateOfTimeValidation(frameName);
        } else {
          setIsThursdayActive(false);
          checkUpdatedStateOfTimeValidation(frameName);
        }
        break;
      case "friday":
        document.getElementById("fridayTimeFrame_" + uid).remove();

        const allEndFridayTimes = document.getElementsByClassName("fridayEndTabs" + frameName);
        if (allEndFridayTimes.length) {
          checkUpdatedStateOfTimeValidation(frameName);
        } else {
          setIsFridayActive(false);
          checkUpdatedStateOfTimeValidation(frameName);
        }
        break;
      case "saturday":
        document.getElementById("saturdayTimeFrame_" + uid).remove();

        const allEndSaturdayTimes = document.getElementsByClassName("saturdayEndTabs" + frameName);
        if (allEndSaturdayTimes.length) {
          checkUpdatedStateOfTimeValidation(frameName);
        } else {
          setIsSaturdayActive(false);
          checkUpdatedStateOfTimeValidation(frameName);
        }
        break;
      default:
      // console.log("invalid dates are placed in field.");
    }
  }

  const addTimeSetIn = (dayName, frameName, uid) => {

    switch (dayName) {
      case "sunday":
        if (isSundayActive) {
          const getUniqueId = uid;
          const spanMain = document.createElement("span");
          spanMain.setAttribute("class", "d-flex align-items-center gap-1 justify-content-start");
          spanMain.setAttribute("id", "sundayTimeFrame_" + getUniqueId);

          let findIndexOf = "";
          const allEndSundayTimes = document.getElementsByClassName("sundayEndTabs" + frameName);
          if (allEndSundayTimes.length) {
            findIndexOf = allEndSundayTimes[allEndSundayTimes.length - 1].value
          } else {
            findIndexOf = timeFormat.value !== "12 Hours" ? "17:00" : "5:00pm";
          }

          const last_usedIndex = timeListToMeasure.indexOf(findIndexOf);

          if (last_usedIndex === timeListToMeasure.length - 1) {
            return
          }




          const innerDiv1 = document.createElement("div");

          const inputField1 = document.createElement("input");
          inputField1.setAttribute("list", "sundayStart");
          inputField1.setAttribute("maxlength", "7");
          inputField1.setAttribute("value", timeListToMeasure[last_usedIndex]);
          inputField1.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm sundayStartTabs${frameName}`);
          inputField1.addEventListener("input", (e) => {
            filterTimeFieldNow(e);
            checkValidTiming(e, frameName);
          })


          const dataList1 = document.createElement("datalist");
          dataList1.setAttribute("id", "sundayStart");
          dataList1.setAttribute("class", "smallTextNote");

          timeListToMeasure.map((times, key) => {
            const optionsTag1 = document.createElement("option");
            optionsTag1.setAttribute("key", key);
            optionsTag1.setAttribute("value", times);

            const textNode1 = document.createTextNode(times);

            optionsTag1.appendChild(textNode1);
            return dataList1.appendChild(optionsTag1);
          });

          innerDiv1.appendChild(inputField1);
          innerDiv1.appendChild(dataList1);

          // ###########################################################################################

          const innerDiv2 = document.createElement("div");
          let useIndexToCall = last_usedIndex;

          if (last_usedIndex + 2 <= timeListToMeasure.length - 1) {
            useIndexToCall = last_usedIndex + 2;
          }
          else {
            useIndexToCall = timeListToMeasure.length - 1;
          }

          const inputField2 = document.createElement("input");
          inputField2.setAttribute("list", "sundayEnd");
          inputField2.setAttribute("maxlength", "7");
          inputField2.setAttribute("value", timeListToMeasure[useIndexToCall]);
          inputField2.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm sundayEndTabs${frameName}`);
          inputField2.addEventListener("input", (e) => {
            filterTimeFieldNow(e);
            checkValidTiming(e, frameName);
          })



          const dataList2 = document.createElement("datalist");
          dataList2.setAttribute("id", "sundayEnd");
          dataList2.setAttribute("class", "smallTextNote");

          timeListToMeasure.map((times, key) => {
            const optionsTag2 = document.createElement("option");
            optionsTag2.setAttribute("key", key);
            optionsTag2.setAttribute("value", times);

            const textNode2 = document.createTextNode(times);

            optionsTag2.appendChild(textNode2);
            return dataList2.appendChild(optionsTag2);
          });

          innerDiv2.appendChild(inputField2);
          innerDiv2.appendChild(dataList2);
          // ###########################################################################################

          const deleteBtn = document.createElement("div");
          deleteBtn.setAttribute("class", "d-flex align-items-center h-100");
          deleteBtn.setAttribute("style", "padding:0 5px;");

          const deleteIcon = document.createElement("i");
          deleteIcon.setAttribute("class", "fa fa-trash");

          deleteIcon.addEventListener("click", (e) => {
            document.getElementById("sundayTimeFrame_" + getUniqueId).remove();

            const allEndSundayTimes = document.getElementsByClassName("sundayEndTabs" + frameName);
            if (allEndSundayTimes.length) {
              checkUpdatedStateOfTimeValidation(frameName);
            } else {
              setIsSundayActive(false);
              checkUpdatedStateOfTimeValidation(frameName);
            }
          });

          deleteBtn.appendChild(deleteIcon);


          spanMain.appendChild(innerDiv1);
          spanMain.appendChild(innerDiv2);
          spanMain.appendChild(deleteBtn);

          const mainContainer = document.getElementById("sundayFrame" + frameName);
          mainContainer.appendChild(spanMain);
        } else {
          setIsSundayActive(true);
        }
        break;
      case "monday":
        if (isMondayActive) {
          const getUniqueId = uid;
          const spanMain = document.createElement("span");
          spanMain.setAttribute("class", "d-flex align-items-center gap-1 justify-content-start");
          spanMain.setAttribute("id", "mondayTimeFrame_" + getUniqueId);

          let findIndexOf = "";
          const allEndMondayTimes = document.getElementsByClassName("mondayEndTabs" + frameName);
          if (allEndMondayTimes.length) {
            findIndexOf = allEndMondayTimes[allEndMondayTimes.length - 1].value
          } else {
            findIndexOf = "5:00pm";
          }

          const last_usedIndex = timeListToMeasure.indexOf(findIndexOf);

          if (last_usedIndex === timeListToMeasure.length - 1) {
            return
          }

          const innerDiv1 = document.createElement("div");

          const inputField1 = document.createElement("input");
          inputField1.setAttribute("list", "mondayStart");
          inputField1.setAttribute("maxlength", "7");
          inputField1.setAttribute("value", timeListToMeasure[last_usedIndex]);
          inputField1.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm mondayStartTabs${frameName}`);
          inputField1.addEventListener("input", (e) => {
            filterTimeFieldNow(e);
            checkValidTiming(e, frameName);
          })


          const dataList1 = document.createElement("datalist");
          dataList1.setAttribute("id", "mondayStart");
          dataList1.setAttribute("class", "smallTextNote");

          timeListToMeasure.map((times, key) => {
            const optionsTag1 = document.createElement("option");
            optionsTag1.setAttribute("key", key);
            optionsTag1.setAttribute("value", times);

            const textNode1 = document.createTextNode(times);

            optionsTag1.appendChild(textNode1);
            return dataList1.appendChild(optionsTag1);
          });

          innerDiv1.appendChild(inputField1);
          innerDiv1.appendChild(dataList1);

          // ###########################################################################################

          const innerDiv2 = document.createElement("div");
          let useIndexToCall = last_usedIndex;

          if (last_usedIndex + 2 <= timeListToMeasure.length - 1) {
            useIndexToCall = last_usedIndex + 2;
          }
          else {
            useIndexToCall = timeListToMeasure.length - 1;
          }

          const inputField2 = document.createElement("input");
          inputField2.setAttribute("list", "mondayEnd");

          inputField2.setAttribute("maxlength", "7");
          inputField2.setAttribute("value", timeListToMeasure[useIndexToCall]);
          inputField2.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm mondayEndTabs${frameName}`);
          inputField2.addEventListener("input", (e) => {
            filterTimeFieldNow(e);
            checkValidTiming(e, frameName);
          })



          const dataList2 = document.createElement("datalist");
          dataList2.setAttribute("id", "mondayEnd");
          dataList2.setAttribute("class", "smallTextNote");

          timeListToMeasure.map((times, key) => {
            const optionsTag2 = document.createElement("option");
            optionsTag2.setAttribute("key", key);
            optionsTag2.setAttribute("value", times);

            const textNode2 = document.createTextNode(times);

            optionsTag2.appendChild(textNode2);
            return dataList2.appendChild(optionsTag2);
          });

          innerDiv2.appendChild(inputField2);
          innerDiv2.appendChild(dataList2);
          // ###########################################################################################

          const deleteBtn = document.createElement("div");
          deleteBtn.setAttribute("class", "d-flex align-items-center h-100");


          const deleteIcon = document.createElement("i");
          deleteIcon.setAttribute("class", "fa fa-trash");

          deleteIcon.addEventListener("click", (e) => {
            document.getElementById("mondayTimeFrame_" + getUniqueId).remove();

            const allEndMondayTimes = document.getElementsByClassName("mondayEndTabs" + frameName);
            if (allEndMondayTimes.length) {
              checkUpdatedStateOfTimeValidation(frameName);
            } else {
              setIsMondayActive(false);
              checkUpdatedStateOfTimeValidation(frameName);
            }
          });

          deleteBtn.setAttribute("style", "padding:0 5px;");
          deleteBtn.appendChild(deleteIcon);

          spanMain.appendChild(innerDiv1);
          spanMain.appendChild(innerDiv2);
          spanMain.appendChild(deleteBtn);

          const mainContainer = document.getElementById("mondayFrame" + frameName);
          mainContainer.appendChild(spanMain);
        } else {
          setIsMondayActive(true);
        }
        break;
      case "tuesday":
        if (isTuesdayActive) {
          const getUniqueId = uid;
          const spanMain = document.createElement("span");
          spanMain.setAttribute("class", "d-flex align-items-center gap-1 justify-content-start");
          spanMain.setAttribute("id", "tuesdayTimeFrame_" + getUniqueId);

          let findIndexOf = "";
          const allEndTuesdayTimes = document.getElementsByClassName("tuesdayEndTabs" + frameName);
          if (allEndTuesdayTimes.length) {
            findIndexOf = allEndTuesdayTimes[allEndTuesdayTimes.length - 1].value
          } else {
            findIndexOf = "5:00pm";
          }

          const last_usedIndex = timeListToMeasure.indexOf(findIndexOf);

          if (last_usedIndex === timeListToMeasure.length - 1) {
            return
          }




          const innerDiv1 = document.createElement("div");

          const inputField1 = document.createElement("input");
          inputField1.setAttribute("list", "tuesdayStart");
          inputField1.setAttribute("maxlength", "7");
          inputField1.setAttribute("value", timeListToMeasure[last_usedIndex]);
          inputField1.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm tuesdayStartTabs${frameName}`);
          inputField1.addEventListener("input", (e) => {
            filterTimeFieldNow(e);
            checkValidTiming(e, frameName);
          })


          const dataList1 = document.createElement("datalist");
          dataList1.setAttribute("id", "tuesdayStart");
          dataList1.setAttribute("class", "smallTextNote");

          timeListToMeasure.map((times, key) => {
            const optionsTag1 = document.createElement("option");
            optionsTag1.setAttribute("key", key);
            optionsTag1.setAttribute("value", times);

            const textNode1 = document.createTextNode(times);

            optionsTag1.appendChild(textNode1);
            return dataList1.appendChild(optionsTag1);
          });

          innerDiv1.appendChild(inputField1);
          innerDiv1.appendChild(dataList1);

          // ###########################################################################################

          const innerDiv2 = document.createElement("div");
          let useIndexToCall = last_usedIndex;

          if (last_usedIndex + 2 <= timeListToMeasure.length - 1) {
            useIndexToCall = last_usedIndex + 2;
          }
          else {
            useIndexToCall = timeListToMeasure.length - 1;
          }

          const inputField2 = document.createElement("input");
          inputField2.setAttribute("list", "tuesdayEnd");
          inputField2.setAttribute("maxlength", "7");
          inputField2.setAttribute("value", timeListToMeasure[useIndexToCall]);
          inputField2.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm tuesdayEndTabs${frameName}`);
          inputField2.addEventListener("input", (e) => {
            filterTimeFieldNow(e);
            checkValidTiming(e, frameName);
          })


          const dataList2 = document.createElement("datalist");
          dataList2.setAttribute("id", "tuesdayEnd");
          dataList2.setAttribute("class", "smallTextNote");

          timeListToMeasure.map((times, key) => {
            const optionsTag2 = document.createElement("option");
            optionsTag2.setAttribute("key", key);
            optionsTag2.setAttribute("value", times);

            const textNode2 = document.createTextNode(times);

            optionsTag2.appendChild(textNode2);
            return dataList2.appendChild(optionsTag2);
          });

          innerDiv2.appendChild(inputField2);
          innerDiv2.appendChild(dataList2);
          // ###########################################################################################

          const deleteBtn = document.createElement("div");
          deleteBtn.setAttribute("class", "d-flex align-items-center h-100");

          const deleteIcon = document.createElement("i");
          deleteIcon.setAttribute("class", "fa fa-trash");

          deleteIcon.addEventListener("click", (e) => {
            document.getElementById("tuesdayTimeFrame_" + getUniqueId).remove();

            const allEndTuesdayTimes = document.getElementsByClassName("tuesdayEndTabs" + frameName);
            if (allEndTuesdayTimes.length) {
              checkUpdatedStateOfTimeValidation(frameName);
            } else {
              setIsTuesdayActive(false);
              checkUpdatedStateOfTimeValidation(frameName);
            }
          });

          deleteBtn.setAttribute("style", "padding:0 5px;");
          deleteBtn.appendChild(deleteIcon);

          spanMain.appendChild(innerDiv1);
          spanMain.appendChild(innerDiv2);
          spanMain.appendChild(deleteBtn);

          const mainContainer = document.getElementById("tuesdayFrame" + frameName);
          mainContainer.appendChild(spanMain);
        } else {
          setIsTuesdayActive(true);
        }
        break;
      case "wednesday":
        if (isWednesdayActive) {
          const getUniqueId = uid;
          const spanMain = document.createElement("span");
          spanMain.setAttribute("class", "d-flex align-items-center gap-1 justify-content-start");
          spanMain.setAttribute("id", "wednesdayTimeFrame_" + getUniqueId);

          let findIndexOf = "";
          const allEndWednesdayTimes = document.getElementsByClassName("wednesdayEndTabs" + frameName);
          if (allEndWednesdayTimes.length) {
            findIndexOf = allEndWednesdayTimes[allEndWednesdayTimes.length - 1].value
          } else {
            findIndexOf = "5:00pm";
          }

          const last_usedIndex = timeListToMeasure.indexOf(findIndexOf);

          if (last_usedIndex === timeListToMeasure.length - 1) {
            return
          }


          const innerDiv1 = document.createElement("div");

          const inputField1 = document.createElement("input");
          inputField1.setAttribute("list", "wednesdayStart");
          inputField1.setAttribute("maxlength", "7");
          inputField1.setAttribute("value", timeListToMeasure[last_usedIndex]);
          inputField1.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm wednesdayStartTabs${frameName}`);
          inputField1.addEventListener("input", (e) => {
            filterTimeFieldNow(e);
            checkValidTiming(e, frameName);
          })


          const dataList1 = document.createElement("datalist");
          dataList1.setAttribute("id", "wednesdayStart");
          dataList1.setAttribute("class", "smallTextNote");

          timeListToMeasure.map((times, key) => {
            const optionsTag1 = document.createElement("option");
            optionsTag1.setAttribute("key", key);
            optionsTag1.setAttribute("value", times);

            const textNode1 = document.createTextNode(times);

            optionsTag1.appendChild(textNode1);
            return dataList1.appendChild(optionsTag1);
          });

          innerDiv1.appendChild(inputField1);
          innerDiv1.appendChild(dataList1);

          // ###########################################################################################

          const innerDiv2 = document.createElement("div");
          let useIndexToCall = last_usedIndex;

          if (last_usedIndex + 2 <= timeListToMeasure.length - 1) {
            useIndexToCall = last_usedIndex + 2;
          }
          else {
            useIndexToCall = timeListToMeasure.length - 1;
          }

          const inputField2 = document.createElement("input");
          inputField2.setAttribute("list", "wednesdayEnd");
          inputField1.setAttribute("maxlength", "7");
          inputField2.setAttribute("value", timeListToMeasure[useIndexToCall]);
          inputField2.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm wednesdayEndTabs${frameName}`);
          inputField2.addEventListener("input", (e) => {
            filterTimeFieldNow(e);
            checkValidTiming(e, frameName);
          })



          const dataList2 = document.createElement("datalist");
          dataList2.setAttribute("id", "wednesdayEnd");
          dataList2.setAttribute("class", "smallTextNote");

          timeListToMeasure.map((times, key) => {
            const optionsTag2 = document.createElement("option");
            optionsTag2.setAttribute("key", key);
            optionsTag2.setAttribute("value", times);

            const textNode2 = document.createTextNode(times);

            optionsTag2.appendChild(textNode2);
            return dataList2.appendChild(optionsTag2);
          });

          innerDiv2.appendChild(inputField2);
          innerDiv2.appendChild(dataList2);
          // ###########################################################################################

          const deleteBtn = document.createElement("div");
          deleteBtn.setAttribute("class", "d-flex align-items-center h-100");

          const deleteIcon = document.createElement("i");
          deleteIcon.setAttribute("class", "fa fa-trash");

          deleteIcon.addEventListener("click", (e) => {
            document.getElementById("wednesdayTimeFrame_" + getUniqueId).remove();

            const allEndWednesdayTimes = document.getElementsByClassName("wednesdayEndTabs" + frameName);
            if (allEndWednesdayTimes.length) {
              checkUpdatedStateOfTimeValidation(frameName);
            } else {
              setIsWednesdayActive(false);
              checkUpdatedStateOfTimeValidation(frameName);
            }
          });

          deleteBtn.setAttribute("style", "padding:0 5px;");
          deleteBtn.appendChild(deleteIcon);

          spanMain.appendChild(innerDiv1);
          spanMain.appendChild(innerDiv2);
          spanMain.appendChild(deleteBtn);

          const mainContainer = document.getElementById("wednesdayFrame" + frameName);
          mainContainer.appendChild(spanMain);
        } else {
          setIsWednesdayActive(true);
        }
        break;
      case "thursday":
        if (isThursdayActive) {
          const getUniqueId = uid;
          const spanMain = document.createElement("span");
          spanMain.setAttribute("class", "d-flex align-items-center gap-1 justify-content-start");
          spanMain.setAttribute("id", "thursdayTimeFrame_" + getUniqueId);

          let findIndexOf = "";
          const allEndThursdayTimes = document.getElementsByClassName("thursdayEndTabs" + frameName);
          if (allEndThursdayTimes.length) {
            findIndexOf = allEndThursdayTimes[allEndThursdayTimes.length - 1].value
          } else {
            findIndexOf = "5:00pm";
          }

          const last_usedIndex = timeListToMeasure.indexOf(findIndexOf);

          if (last_usedIndex === timeListToMeasure.length - 1) {
            return
          }




          const innerDiv1 = document.createElement("div");

          const inputField1 = document.createElement("input");
          inputField1.setAttribute("list", "thursdayStart");
          inputField1.setAttribute("maxlength", "7");
          inputField1.setAttribute("value", timeListToMeasure[last_usedIndex]);
          inputField1.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm thursdayStartTabs${frameName}`);
          inputField1.addEventListener("input", (e) => {
            filterTimeFieldNow(e);
            checkValidTiming(e, frameName);
          })


          const dataList1 = document.createElement("datalist");
          dataList1.setAttribute("id", "thursdayStart");
          dataList1.setAttribute("class", "smallTextNote");

          timeListToMeasure.map((times, key) => {
            const optionsTag1 = document.createElement("option");
            optionsTag1.setAttribute("key", key);
            optionsTag1.setAttribute("value", times);

            const textNode1 = document.createTextNode(times);

            optionsTag1.appendChild(textNode1);
            return dataList1.appendChild(optionsTag1);
          });

          innerDiv1.appendChild(inputField1);
          innerDiv1.appendChild(dataList1);

          // ###########################################################################################

          const innerDiv2 = document.createElement("div");
          let useIndexToCall = last_usedIndex;

          if (last_usedIndex + 2 <= timeListToMeasure.length - 1) {
            useIndexToCall = last_usedIndex + 2;
          }
          else {
            useIndexToCall = timeListToMeasure.length - 1;
          }

          const inputField2 = document.createElement("input");
          inputField2.setAttribute("list", "thursdayEnd");
          inputField2.setAttribute("maxlength", "7");
          inputField2.setAttribute("value", timeListToMeasure[useIndexToCall]);
          inputField2.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm thursdayEndTabs${frameName}`);
          inputField2.addEventListener("input", (e) => {
            filterTimeFieldNow(e);
            checkValidTiming(e, frameName);
          })



          const dataList2 = document.createElement("datalist");
          dataList2.setAttribute("id", "thursdayEnd");
          dataList2.setAttribute("class", "smallTextNote");

          timeListToMeasure.map((times, key) => {
            const optionsTag2 = document.createElement("option");
            optionsTag2.setAttribute("key", key);
            optionsTag2.setAttribute("value", times);

            const textNode2 = document.createTextNode(times);

            optionsTag2.appendChild(textNode2);
            return dataList2.appendChild(optionsTag2);
          });

          innerDiv2.appendChild(inputField2);
          innerDiv2.appendChild(dataList2);
          // ###########################################################################################

          const deleteBtn = document.createElement("div");
          deleteBtn.setAttribute("class", "d-flex align-items-center h-100");

          const deleteIcon = document.createElement("i");
          deleteIcon.setAttribute("class", "fa fa-trash");

          deleteIcon.addEventListener("click", (e) => {
            document.getElementById("thursdayTimeFrame_" + getUniqueId).remove();

            const allEndThursdayTimes = document.getElementsByClassName("thursdayEndTabs" + frameName);
            if (allEndThursdayTimes.length) {
              checkUpdatedStateOfTimeValidation(frameName);
            } else {
              setIsThursdayActive(false);
              checkUpdatedStateOfTimeValidation(frameName);
            }
          });

          deleteBtn.setAttribute("style", "padding:0 5px;");
          deleteBtn.appendChild(deleteIcon);

          spanMain.appendChild(innerDiv1);
          spanMain.appendChild(innerDiv2);
          spanMain.appendChild(deleteBtn);

          const mainContainer = document.getElementById("thursdayFrame" + frameName);
          mainContainer.appendChild(spanMain);
        } else {
          setIsThursdayActive(true);
        }
        break;
      case "friday":
        if (isFridayActive) {
          const getUniqueId = uid;
          const spanMain = document.createElement("span");
          spanMain.setAttribute("class", "d-flex align-items-center gap-1 justify-content-start");
          spanMain.setAttribute("id", "fridayTimeFrame_" + getUniqueId);

          let findIndexOf = "";
          const allEndFridayTimes = document.getElementsByClassName("fridayEndTabs" + frameName);
          if (allEndFridayTimes.length) {
            findIndexOf = allEndFridayTimes[allEndFridayTimes.length - 1].value
          } else {
            findIndexOf = "5:00pm";
          }

          const last_usedIndex = timeListToMeasure.indexOf(findIndexOf);

          if (last_usedIndex === timeListToMeasure.length - 1) {
            return
          }




          const innerDiv1 = document.createElement("div");

          const inputField1 = document.createElement("input");
          inputField1.setAttribute("list", "fridayStart");
          inputField1.setAttribute("maxlength", "7");
          inputField1.setAttribute("value", timeListToMeasure[last_usedIndex]);
          inputField1.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm fridayStartTabs${frameName}`);
          inputField1.addEventListener("input", (e) => {
            filterTimeFieldNow(e);
            checkValidTiming(e, frameName);
          })


          const dataList1 = document.createElement("datalist");
          dataList1.setAttribute("id", "fridayStart");
          dataList1.setAttribute("class", "smallTextNote");

          timeListToMeasure.map((times, key) => {
            const optionsTag1 = document.createElement("option");
            optionsTag1.setAttribute("key", key);
            optionsTag1.setAttribute("value", times);

            const textNode1 = document.createTextNode(times);

            optionsTag1.appendChild(textNode1);
            return dataList1.appendChild(optionsTag1);
          });

          innerDiv1.appendChild(inputField1);
          innerDiv1.appendChild(dataList1);

          // ###########################################################################################

          const innerDiv2 = document.createElement("div");
          let useIndexToCall = last_usedIndex;

          if (last_usedIndex + 2 <= timeListToMeasure.length - 1) {
            useIndexToCall = last_usedIndex + 2;
          }
          else {
            useIndexToCall = timeListToMeasure.length - 1;
          }

          const inputField2 = document.createElement("input");
          inputField2.setAttribute("list", "fridayEnd");
          inputField2.setAttribute("maxlength", "7");
          inputField2.setAttribute("value", timeListToMeasure[useIndexToCall]);
          inputField2.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm fridayEndTabs${frameName}`);
          inputField2.addEventListener("input", (e) => {
            filterTimeFieldNow(e);
            checkValidTiming(e, frameName);
          })


          const dataList2 = document.createElement("datalist");
          dataList2.setAttribute("id", "fridayEnd");
          dataList2.setAttribute("class", "smallTextNote");

          timeListToMeasure.map((times, key) => {
            const optionsTag2 = document.createElement("option");
            optionsTag2.setAttribute("key", key);
            optionsTag2.setAttribute("value", times);

            const textNode2 = document.createTextNode(times);

            optionsTag2.appendChild(textNode2);
            return dataList2.appendChild(optionsTag2);
          });

          innerDiv2.appendChild(inputField2);
          innerDiv2.appendChild(dataList2);
          // ###########################################################################################

          const deleteBtn = document.createElement("div");
          deleteBtn.setAttribute("class", "d-flex align-items-center h-100");

          const deleteIcon = document.createElement("i");
          deleteIcon.setAttribute("class", "fa fa-trash");

          deleteIcon.addEventListener("click", (e) => {
            document.getElementById("fridayTimeFrame_" + getUniqueId).remove();

            const allEndFridayTimes = document.getElementsByClassName("fridayEndTabs" + frameName);
            if (allEndFridayTimes.length) {
              checkUpdatedStateOfTimeValidation(frameName);
            } else {
              setIsFridayActive(false);
              checkUpdatedStateOfTimeValidation(frameName);
            }
          });


          deleteBtn.setAttribute("style", "padding:0 5px;");
          deleteBtn.appendChild(deleteIcon);

          spanMain.appendChild(innerDiv1);
          spanMain.appendChild(innerDiv2);
          spanMain.appendChild(deleteBtn);

          const mainContainer = document.getElementById("fridayFrame" + frameName);
          mainContainer.appendChild(spanMain);
        } else {
          setIsFridayActive(true);
        }
        break;
      case "saturday":
        if (isSaturdayActive) {
          const getUniqueId = uid;
          const spanMain = document.createElement("span");
          spanMain.setAttribute("class", "d-flex align-items-center gap-1 justify-content-start");
          spanMain.setAttribute("id", "saturdayTimeFrame_" + getUniqueId);

          let findIndexOf = "";
          const allEndSaturdayTimes = document.getElementsByClassName("saturdayEndTabs" + frameName);
          if (allEndSaturdayTimes.length) {
            findIndexOf = allEndSaturdayTimes[allEndSaturdayTimes.length - 1].value
          } else {
            findIndexOf = "5:00pm";
          }

          const last_usedIndex = timeListToMeasure.indexOf(findIndexOf);

          if (last_usedIndex === timeListToMeasure.length - 1) {
            return
          }

          const innerDiv1 = document.createElement("div");

          const inputField1 = document.createElement("input");
          inputField1.setAttribute("list", "saturdayStart");
          inputField1.setAttribute("maxlength", "7");
          inputField1.setAttribute("value", timeListToMeasure[last_usedIndex]);
          inputField1.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm saturdayStartTabs${frameName}`);
          inputField1.addEventListener("input", (e) => {
            filterTimeFieldNow(e);
            checkValidTiming(e, frameName);
          });


          const dataList1 = document.createElement("datalist");
          dataList1.setAttribute("id", "saturdayStart");
          dataList1.setAttribute("class", "smallTextNote");

          timeListToMeasure.map((times, key) => {
            const optionsTag1 = document.createElement("option");
            optionsTag1.setAttribute("key", key);
            optionsTag1.setAttribute("value", times);

            const textNode1 = document.createTextNode(times);

            optionsTag1.appendChild(textNode1);
            return dataList1.appendChild(optionsTag1);
          });

          innerDiv1.appendChild(inputField1);
          innerDiv1.appendChild(dataList1);

          // ###########################################################################################

          const innerDiv2 = document.createElement("div");
          let useIndexToCall = last_usedIndex;

          if (last_usedIndex + 2 <= timeListToMeasure.length - 1) {
            useIndexToCall = last_usedIndex + 2;
          }
          else {
            useIndexToCall = timeListToMeasure.length - 1;
          }

          const inputField2 = document.createElement("input");
          inputField2.setAttribute("list", "saturdayEnd");
          inputField2.setAttribute("maxlength", "7");
          inputField2.setAttribute("value", timeListToMeasure[useIndexToCall]);
          inputField2.setAttribute("class", `customInputFieldFocus w-100 smallTextNote shadow-sm saturdayEndTabs${frameName}`);
          inputField2.addEventListener("input", (e) => {
            filterTimeFieldNow(e);
            checkValidTiming(e, frameName);
          });

          const dataList2 = document.createElement("datalist");
          dataList2.setAttribute("id", "saturdayEnd");
          dataList2.setAttribute("class", "smallTextNote");

          timeListToMeasure.map((times, key) => {
            const optionsTag2 = document.createElement("option");
            optionsTag2.setAttribute("key", key);
            optionsTag2.setAttribute("value", times);

            const textNode2 = document.createTextNode(times);

            optionsTag2.appendChild(textNode2);
            return dataList2.appendChild(optionsTag2);
          });

          innerDiv2.appendChild(inputField2);
          innerDiv2.appendChild(dataList2);
          // ###########################################################################################

          const deleteBtn = document.createElement("div");
          deleteBtn.setAttribute("class", "d-flex align-items-center h-100");

          const deleteIcon = document.createElement("i");
          deleteIcon.setAttribute("class", "fa fa-trash");

          deleteIcon.addEventListener("click", (e) => {
            document.getElementById("saturdayTimeFrame_" + getUniqueId).remove();

            const allEndSaturdayTimes = document.getElementsByClassName("saturdayEndTabs" + frameName);
            if (allEndSaturdayTimes.length) {
              checkUpdatedStateOfTimeValidation(frameName);
            } else {
              setIsSaturdayActive(false);
              checkUpdatedStateOfTimeValidation(frameName);
            }
          });

          deleteBtn.setAttribute("style", "padding:0 5px;");
          deleteBtn.appendChild(deleteIcon);

          spanMain.appendChild(innerDiv1);
          spanMain.appendChild(innerDiv2);
          spanMain.appendChild(deleteBtn);

          const mainContainer = document.getElementById("saturdayFrame" + frameName);
          mainContainer.appendChild(spanMain);
        } else {
          setIsSaturdayActive(true);
        }
        break;
      default:
        // console.log("unknown day name is passed");
        break;
    }
  }

  const getTimeArrayForDays = () => {
    let timearrays = {};

    if (isSundayActive) {
      const allSetsStart = document.getElementsByClassName("sundayStartTabs");
      const allSetsEnd = document.getElementsByClassName("sundayEndTabs");

      const length = allSetsStart.length;
      let sundayArray = [];

      for (let i = 0; i < length; i++) {
        sundayArray.push(allSetsStart[i].value + "-" + allSetsEnd[i].value);
      }

      timearrays["sunday"] =
      {
        "active": true,
        "times": sundayArray
      }

    } else {
      timearrays["sunday"] = {
        "active": false,
        "times": []
      }
    }


    if (isMondayActive) {
      const allSetsStart = document.getElementsByClassName("mondayStartTabs");
      const allSetsEnd = document.getElementsByClassName("mondayEndTabs");

      const length = allSetsStart.length;
      let mondayArray = [];

      for (let i = 0; i < length; i++) {
        mondayArray.push(allSetsStart[i].value + "-" + allSetsEnd[i].value);
      }

      timearrays["monday"] = {
        "active": true,
        "times": mondayArray
      }
    } else {
      timearrays["monday"] = {
        "active": false,
        "times": []
      }
    }


    if (isTuesdayActive) {
      const allSetsStart = document.getElementsByClassName("tuesdayStartTabs");
      const allSetsEnd = document.getElementsByClassName("tuesdayEndTabs");

      const length = allSetsStart.length;
      let tuesdayArray = [];

      for (let i = 0; i < length; i++) {
        tuesdayArray.push(allSetsStart[i].value + "-" + allSetsEnd[i].value);
      }

      timearrays["tuesday"] = {
        "active": true,
        "times": tuesdayArray
      }
    } else {
      timearrays["tuesday"] = {
        "active": false,
        "times": []
      }
    }


    if (isWednesdayActive) {
      const allSetsStart = document.getElementsByClassName("wednesdayStartTabs");
      const allSetsEnd = document.getElementsByClassName("wednesdayEndTabs");

      const length = allSetsStart.length;
      let wednesdayArray = [];

      for (let i = 0; i < length; i++) {
        wednesdayArray.push(allSetsStart[i].value + "-" + allSetsEnd[i].value);
      }

      timearrays["wednesday"] = {
        "active": true,
        "times": wednesdayArray
      }

    } else {
      timearrays["wednesday"] = {
        "active": false,
        "times": []
      }
    }


    if (isThursdayActive) {
      const allSetsStart = document.getElementsByClassName("thursdayStartTabs");
      const allSetsEnd = document.getElementsByClassName("thursdayEndTabs");

      const length = allSetsStart.length;
      let thursdayArray = [];

      for (let i = 0; i < length; i++) {
        thursdayArray.push(allSetsStart[i].value + "-" + allSetsEnd[i].value);
      }

      timearrays["thursday"] = {
        "active": true,
        "times": thursdayArray
      }

    } else {
      timearrays["thursday"] = {
        "active": false,
        "times": []
      }
    }



    if (isFridayActive) {
      const allSetsStart = document.getElementsByClassName("fridayStartTabs");
      const allSetsEnd = document.getElementsByClassName("fridayEndTabs");

      const length = allSetsStart.length;
      let fridayArray = [];

      for (let i = 0; i < length; i++) {
        fridayArray.push(allSetsStart[i].value + "-" + allSetsEnd[i].value);
      }

      timearrays["friday"] = {
        "active": true,
        "times": fridayArray
      }

    } else {
      timearrays["friday"] = {
        "active": false,
        "times": []
      }
    }


    if (isSaturdayActive) {
      const allSetsStart = document.getElementsByClassName("saturdayStartTabs");
      const allSetsEnd = document.getElementsByClassName("saturdayEndTabs");

      const length = allSetsStart.length;
      let saturdayArray = [];

      for (let i = 0; i < length; i++) {
        saturdayArray.push(allSetsStart[i].value + "-" + allSetsEnd[i].value);
      }

      timearrays["saturday"] = {
        "active": true,
        "times": saturdayArray
      }

    } else {
      timearrays["saturday"] = {
        "active": false,
        "times": []
      }
    }

    // console.log(timearrays);
  }

  const copyTimesTo = (dayName, fromFrame) => {
    setCopyTimeFromFrame(fromFrame);
    switch (dayName) {
      case "sunday":
        setViewFromSunday(true);
        setViewFromMonday(false);
        setViewFromTuesday(false);
        setViewFromWednesday(false);
        setViewFromThursday(false);
        setViewFromFriday(false);
        setViewFromSaturday(false);
        break;
      case "monday":
        setViewFromSunday(false);
        setViewFromMonday(true);
        setViewFromTuesday(false);
        setViewFromWednesday(false);
        setViewFromThursday(false);
        setViewFromFriday(false);
        setViewFromSaturday(false);
        break;
      case "tuesday":
        setViewFromSunday(false);
        setViewFromMonday(false);
        setViewFromTuesday(true);
        setViewFromWednesday(false);
        setViewFromThursday(false);
        setViewFromFriday(false);
        setViewFromSaturday(false);
        break;
      case "wednesday":
        setViewFromSunday(false);
        setViewFromMonday(false);
        setViewFromTuesday(false);
        setViewFromWednesday(true);
        setViewFromThursday(false);
        setViewFromFriday(false);
        setViewFromSaturday(false);
        break;
      case "thursday":
        setViewFromSunday(false);
        setViewFromMonday(false);
        setViewFromTuesday(false);
        setViewFromWednesday(false);
        setViewFromThursday(true);
        setViewFromFriday(false);
        setViewFromSaturday(false);
        break;
      case "friday":
        setViewFromSunday(false);
        setViewFromMonday(false);
        setViewFromTuesday(false);
        setViewFromWednesday(false);
        setViewFromThursday(false);
        setViewFromFriday(true);
        setViewFromSaturday(false);
        break;
      case "saturday":
        setViewFromSunday(false);
        setViewFromMonday(false);
        setViewFromTuesday(false);
        setViewFromWednesday(false);
        setViewFromThursday(false);
        setViewFromFriday(false);
        setViewFromSaturday(true);
        break;
      default:
      // console.log(dayName);
    }
  }

  const copyTimesNow = (e, frameName) => {
    var getSchedule = schedule;

    switch (e) {
      case "sunday":
        const allSetsStart = document.getElementsByClassName("sundayStartTabs" + frameName);
        const allSetsEnd = document.getElementsByClassName("sundayEndTabs" + frameName);

        const length = allSetsStart.length;
        let sundayArray = [];

        for (let i = 0; i < length; i++) {
          sundayArray.push(allSetsStart[i].value + "-" + allSetsEnd[i].value);
        }

        if (mondayCopy) {
          setIsMondayActive(false);
          getSchedule.monday.times = sundayArray.length > 0 ? sundayArray : ['9:00am-5:00pm'];
          getSchedule.monday.active = isSundayActive;
        }

        if (tuesdayCopy) {
          setIsTuesdayActive(false);
          getSchedule.tuesday.times = sundayArray.length > 0 ? sundayArray : ['9:00am-5:00pm'];
          getSchedule.tuesday.active = isSundayActive;
        }

        if (wednesdayCopy) {
          setIsWednesdayActive(false);
          getSchedule.wednesday.times = sundayArray.length > 0 ? sundayArray : ['9:00am-5:00pm'];
          getSchedule.wednesday.active = isSundayActive;
        }

        if (thursdayCopy) {
          setIsThursdayActive(false);
          getSchedule.thursday.times = sundayArray.length > 0 ? sundayArray : ['9:00am-5:00pm'];
          getSchedule.thursday.active = isSundayActive;
        }

        if (fridayCopy) {
          setIsFridayActive(false);
          getSchedule.friday.times = sundayArray.length > 0 ? sundayArray : ['9:00am-5:00pm'];
          getSchedule.friday.active = isSundayActive;
        }

        if (saturdayCopy) {
          setIsSaturdayActive(false);
          getSchedule.saturday.times = sundayArray.length > 0 ? sundayArray : ['9:00am-5:00pm'];
          getSchedule.saturday.active = isSundayActive;
        }

        setSchedule(getSchedule);

        setTimeout(() => {
          if (mondayCopy) {
            setIsMondayActive(isSundayActive);
          }

          if (tuesdayCopy) {
            setIsTuesdayActive(isSundayActive);
          }

          if (wednesdayCopy) {
            setIsWednesdayActive(isSundayActive);
          }

          if (thursdayCopy) {
            setIsThursdayActive(isSundayActive);
          }

          if (fridayCopy) {
            setIsFridayActive(isSundayActive);
          }

          if (saturdayCopy) {
            setIsSaturdayActive(isSundayActive);
          }

        }, 300)

        break;
      case "monday":
        const allSetsStart2 = document.getElementsByClassName("mondayStartTabs" + frameName);
        const allSetsEnd2 = document.getElementsByClassName("mondayEndTabs" + frameName);

        const length2 = allSetsStart2.length;
        let mondayArray = [];

        for (let i = 0; i < length2; i++) {
          mondayArray.push(allSetsStart2[i].value + "-" + allSetsEnd2[i].value);
        }

        if (sundayCopy) {
          setIsSundayActive(false);
          getSchedule.sunday.times = mondayArray.length > 0 ? mondayArray : ['9:00am-5:00pm'];
          getSchedule.sunday.active = isMondayActive;
        }

        if (tuesdayCopy) {
          setIsTuesdayActive(false);
          getSchedule.tuesday.times = mondayArray.length > 0 ? mondayArray : ['9:00am-5:00pm'];
          getSchedule.tuesday.active = isMondayActive;
        }

        if (wednesdayCopy) {
          setIsWednesdayActive(false);
          getSchedule.wednesday.times = mondayArray.length > 0 ? mondayArray : ['9:00am-5:00pm'];
          getSchedule.wednesday.active = isMondayActive;
        }

        if (thursdayCopy) {
          setIsThursdayActive(false);
          getSchedule.thursday.times = mondayArray.length > 0 ? mondayArray : ['9:00am-5:00pm'];
          getSchedule.thursday.active = isMondayActive;
        }

        if (fridayCopy) {
          setIsFridayActive(false);
          getSchedule.friday.times = mondayArray.length > 0 ? mondayArray : ['9:00am-5:00pm'];
          getSchedule.friday.active = isMondayActive;
        }

        if (saturdayCopy) {
          setIsSaturdayActive(false);
          getSchedule.saturday.times = mondayArray.length > 0 ? mondayArray : ['9:00am-5:00pm'];
          getSchedule.saturday.active = isMondayActive;
        }
        setSchedule(getSchedule);

        setTimeout(() => {
          if (sundayCopy) {
            setIsSundayActive(isMondayActive);
          }

          if (tuesdayCopy) {
            setIsTuesdayActive(isMondayActive);
          }

          if (wednesdayCopy) {
            setIsWednesdayActive(isMondayActive);
          }

          if (thursdayCopy) {
            setIsThursdayActive(isMondayActive);
          }

          if (fridayCopy) {
            setIsFridayActive(isMondayActive);
          }

          if (saturdayCopy) {
            setIsSaturdayActive(isMondayActive);
          }

        }, 300)

        break;
      case "tuesday":
        const allSetsStart3 = document.getElementsByClassName("tuesdayStartTabs" + frameName);
        const allSetsEnd3 = document.getElementsByClassName("tuesdayEndTabs" + frameName);

        const length3 = allSetsStart3.length;
        let tuesdayArray = [];

        for (let i = 0; i < length3; i++) {
          tuesdayArray.push(allSetsStart3[i].value + "-" + allSetsEnd3[i].value);
        }

        if (sundayCopy) {
          setIsSundayActive(false);
          getSchedule.sunday.times = tuesdayArray.length > 0 ? tuesdayArray : ['9:00am-5:00pm'];
          getSchedule.sunday.active = isTuesdayActive;
        }

        if (mondayCopy) {
          setIsMondayActive(false);
          getSchedule.monday.times = tuesdayArray.length > 0 ? tuesdayArray : ['9:00am-5:00pm'];
          getSchedule.monday.active = isTuesdayActive;
        }



        if (wednesdayCopy) {
          setIsWednesdayActive(false);
          getSchedule.wednesday.times = tuesdayArray.length > 0 ? tuesdayArray : ['9:00am-5:00pm'];
          getSchedule.wednesday.active = isTuesdayActive;
        }

        if (thursdayCopy) {
          setIsThursdayActive(false);
          getSchedule.thursday.times = tuesdayArray.length > 0 ? tuesdayArray : ['9:00am-5:00pm'];
          getSchedule.thursday.active = isTuesdayActive;
        }

        if (fridayCopy) {
          setIsFridayActive(false);
          getSchedule.friday.times = tuesdayArray.length > 0 ? tuesdayArray : ['9:00am-5:00pm'];
          getSchedule.friday.active = isTuesdayActive;
        }

        if (saturdayCopy) {
          setIsSaturdayActive(false);
          getSchedule.saturday.times = tuesdayArray.length > 0 ? tuesdayArray : ['9:00am-5:00pm'];
          getSchedule.saturday.active = isTuesdayActive;
        }

        setSchedule(getSchedule);

        setTimeout(() => {
          if (sundayCopy) {
            setIsSundayActive(isTuesdayActive);
          }
          if (mondayCopy) {
            setIsMondayActive(isTuesdayActive);
          }
          if (wednesdayCopy) {
            setIsWednesdayActive(isTuesdayActive);
          }

          if (thursdayCopy) {
            setIsThursdayActive(isTuesdayActive);
          }

          if (fridayCopy) {
            setIsFridayActive(isTuesdayActive);
          }

          if (saturdayCopy) {
            setIsSaturdayActive(isTuesdayActive);
          }

        }, 300)

        break;
      case "wednesday":
        const allSetsStart4 = document.getElementsByClassName("wednesdayStartTabs" + frameName);
        const allSetsEnd4 = document.getElementsByClassName("wednesdayEndTabs" + frameName);

        const length4 = allSetsStart4.length;
        let wednesdayArray = [];

        for (let i = 0; i < length4; i++) {
          wednesdayArray.push(allSetsStart4[i].value + "-" + allSetsEnd4[i].value);
        }

        if (mondayCopy) {
          setIsMondayActive(false);
          getSchedule.monday.times = wednesdayArray.length > 0 ? wednesdayArray : ['9:00am-5:00pm'];
          getSchedule.monday.active = isWednesdayActive;
        }

        if (tuesdayCopy) {
          setIsTuesdayActive(false);
          getSchedule.tuesday.times = wednesdayArray.length > 0 ? wednesdayArray : ['9:00am-5:00pm'];
          getSchedule.tuesday.active = isWednesdayActive;
        }

        if (sundayCopy) {
          setIsSundayActive(false);
          getSchedule.sunday.times = wednesdayArray.length > 0 ? wednesdayArray : ['9:00am-5:00pm'];
          getSchedule.sunday.active = isWednesdayActive;
        }

        if (thursdayCopy) {
          setIsThursdayActive(false);
          getSchedule.thursday.times = wednesdayArray.length > 0 ? wednesdayArray : ['9:00am-5:00pm'];
          getSchedule.thursday.active = isWednesdayActive;
        }

        if (fridayCopy) {
          setIsFridayActive(false);
          getSchedule.friday.times = wednesdayArray.length > 0 ? wednesdayArray : ['9:00am-5:00pm'];
          getSchedule.friday.active = isWednesdayActive;
        }

        if (saturdayCopy) {
          setIsSaturdayActive(false);
          getSchedule.saturday.times = wednesdayArray.length > 0 ? wednesdayArray : ['9:00am-5:00pm'];
          getSchedule.saturday.active = isWednesdayActive;
        }
        setSchedule(getSchedule);

        setTimeout(() => {
          if (sundayCopy) {
            setIsSundayActive(isWednesdayActive);
          }

          if (mondayCopy) {
            setIsMondayActive(isWednesdayActive);
          }

          if (tuesdayCopy) {
            setIsTuesdayActive(isWednesdayActive);
          }

          if (thursdayCopy) {
            setIsThursdayActive(isWednesdayActive);
          }

          if (fridayCopy) {
            setIsFridayActive(isWednesdayActive);
          }

          if (saturdayCopy) {
            setIsSaturdayActive(isWednesdayActive);
          }

        }, 300)
        break;
      case "thursday":
        const allSetsStart5 = document.getElementsByClassName("thursdayStartTabs" + frameName);
        const allSetsEnd5 = document.getElementsByClassName("thursdayEndTabs" + frameName);

        const length5 = allSetsStart5.length;
        let thursdayArray = [];

        for (let i = 0; i < length5; i++) {
          thursdayArray.push(allSetsStart5[i].value + "-" + allSetsEnd5[i].value);
        }

        if (sundayCopy) {
          setIsSundayActive(false);
          getSchedule.sunday.times = thursdayArray.length > 0 ? thursdayArray : ['9:00am-5:00pm'];
          getSchedule.sunday.active = isThursdayActive;
        }

        if (mondayCopy) {
          setIsMondayActive(false);
          getSchedule.monday.times = thursdayArray.length > 0 ? thursdayArray : ['9:00am-5:00pm'];
          getSchedule.monday.active = isThursdayActive;
        }

        if (tuesdayCopy) {
          setIsTuesdayActive(false);
          getSchedule.tuesday.times = thursdayArray.length > 0 ? thursdayArray : ['9:00am-5:00pm'];
          getSchedule.tuesday.active = isThursdayActive;
        }

        if (wednesdayCopy) {
          setIsWednesdayActive(false);
          getSchedule.wednesday.times = thursdayArray.length > 0 ? thursdayArray : ['9:00am-5:00pm'];
          getSchedule.wednesday.active = isThursdayActive;
        }

        if (fridayCopy) {
          setIsFridayActive(false);
          getSchedule.friday.times = thursdayArray.length > 0 ? thursdayArray : ['9:00am-5:00pm'];
          getSchedule.friday.active = isThursdayActive;
        }

        if (saturdayCopy) {
          setIsSaturdayActive(false);
          getSchedule.saturday.times = thursdayArray.length > 0 ? thursdayArray : ['9:00am-5:00pm'];
          getSchedule.saturday.active = isThursdayActive;
        }

        setSchedule(getSchedule);

        setTimeout(() => {
          if (sundayCopy) {
            setIsSundayActive(isThursdayActive);
          }

          if (mondayCopy) {
            setIsMondayActive(isThursdayActive);
          }

          if (tuesdayCopy) {
            setIsTuesdayActive(isThursdayActive);
          }

          if (wednesdayCopy) {
            setIsWednesdayActive(isThursdayActive);
          }

          if (fridayCopy) {
            setIsFridayActive(isThursdayActive);
          }

          if (saturdayCopy) {
            setIsSaturdayActive(isThursdayActive);
          }

        }, 300)
        break;
      case "friday":
        const allSetsStart6 = document.getElementsByClassName("fridayStartTabs" + frameName);
        const allSetsEnd6 = document.getElementsByClassName("fridayEndTabs" + frameName);

        const length6 = allSetsStart6.length;
        let fridayArray = [];

        for (let i = 0; i < length6; i++) {
          fridayArray.push(allSetsStart6[i].value + "-" + allSetsEnd6[i].value);
        }

        if (sundayCopy) {
          setIsSundayActive(false);
          getSchedule.sunday.times = fridayArray.length > 0 ? fridayArray : ['9:00am-5:00pm'];
          getSchedule.sunday.active = isFridayActive;
        }

        if (mondayCopy) {
          setIsMondayActive(false);
          getSchedule.monday.times = fridayArray.length > 0 ? fridayArray : ['9:00am-5:00pm'];
          getSchedule.monday.active = isFridayActive;
        }

        if (tuesdayCopy) {
          setIsTuesdayActive(false);
          getSchedule.tuesday.times = fridayArray.length > 0 ? fridayArray : ['9:00am-5:00pm'];
          getSchedule.tuesday.active = isFridayActive;
        }

        if (wednesdayCopy) {
          setIsWednesdayActive(false);
          getSchedule.wednesday.times = fridayArray.length > 0 ? fridayArray : ['9:00am-5:00pm'];
          getSchedule.wednesday.active = isFridayActive;
        }

        if (thursdayCopy) {
          setIsThursdayActive(false);
          getSchedule.thursday.times = fridayArray.length > 0 ? fridayArray : ['9:00am-5:00pm'];
          getSchedule.thursday.active = isFridayActive;
        }

        if (saturdayCopy) {
          setIsSaturdayActive(false);
          getSchedule.saturday.times = fridayArray.length > 0 ? fridayArray : ['9:00am-5:00pm'];
          getSchedule.saturday.active = isFridayActive;
        }

        setSchedule(getSchedule);

        setTimeout(() => {

          if (sundayCopy) {
            setIsSundayActive(isFridayActive);
          }

          if (mondayCopy) {
            setIsMondayActive(isFridayActive);
          }

          if (tuesdayCopy) {
            setIsTuesdayActive(isFridayActive);
          }

          if (wednesdayCopy) {
            setIsWednesdayActive(isFridayActive);
          }

          if (thursdayCopy) {
            setIsThursdayActive(isFridayActive);
          }

          if (saturdayCopy) {
            setIsSaturdayActive(isFridayActive);
          }

        }, 300)
        break;
      case "saturday":
        const allSetsStart7 = document.getElementsByClassName("saturdayStartTabs" + frameName);
        const allSetsEnd7 = document.getElementsByClassName("saturdayEndTabs" + frameName);

        const length7 = allSetsStart7.length;
        let saturdayArray = [];

        for (let i = 0; i < length7; i++) {
          saturdayArray.push(allSetsStart7[i].value + "-" + allSetsEnd7[i].value);
        }


        if (sundayCopy) {
          setIsSundayActive(false);
          getSchedule.sunday.times = saturdayArray.length > 0 ? saturdayArray : ['9:00am-5:00pm'];
          getSchedule.sunday.active = isSaturdayActive;
        }

        if (mondayCopy) {
          setIsMondayActive(false);
          getSchedule.monday.times = saturdayArray.length > 0 ? saturdayArray : ['9:00am-5:00pm'];
          getSchedule.monday.active = isSaturdayActive;
        }

        if (tuesdayCopy) {
          setIsTuesdayActive(false);
          getSchedule.tuesday.times = saturdayArray.length > 0 ? saturdayArray : ['9:00am-5:00pm'];
          getSchedule.tuesday.active = isSaturdayActive;
        }

        if (wednesdayCopy) {
          setIsWednesdayActive(false);
          getSchedule.wednesday.times = saturdayArray.length > 0 ? saturdayArray : ['9:00am-5:00pm'];
          getSchedule.wednesday.active = isSaturdayActive;
        }

        if (thursdayCopy) {
          setIsThursdayActive(false);
          getSchedule.thursday.times = saturdayArray.length > 0 ? saturdayArray : ['9:00am-5:00pm'];
          getSchedule.thursday.active = isSaturdayActive;
        }

        if (fridayCopy) {
          setIsFridayActive(false);
          getSchedule.friday.times = saturdayArray.length > 0 ? saturdayArray : ['9:00am-5:00pm'];
          getSchedule.friday.active = isSaturdayActive;
        }


        setSchedule(getSchedule);

        setTimeout(() => {

          if (sundayCopy) {
            setIsSundayActive(isSaturdayActive);
          }

          if (mondayCopy) {
            setIsMondayActive(isSaturdayActive);
          }

          if (tuesdayCopy) {
            setIsTuesdayActive(isSaturdayActive);
          }

          if (wednesdayCopy) {
            setIsWednesdayActive(isSaturdayActive);
          }

          if (thursdayCopy) {
            setIsThursdayActive(isSaturdayActive);
          }

          if (fridayCopy) {
            setIsFridayActive(isSaturdayActive);
          }
        }, 300)

        break;
      default:
      // console.log("Invalid Date");
    }

    setSundayCopy(false);
    setMondayCopy(false);
    setTuesdayCopy(false);
    setWednesdayCopy(false);
    setThursdayCopy(false);
    setFridayCopy(false);
    setSaturdayCopy(false);

    // console.log(getSchedule);
    setTimeout(() => { checkUpdatedStateOfTimeValidation(frameName) }, 1000)

  }

  const deleteTimeSetOverride = (type, uid) => {
    document.getElementById(uid).remove();

    let isEverythingOk = true;

    const allTabs = document.getElementsByClassName("overrideEndTabs");

    if (allTabs.length < 1) {
      setOverrideDateEnabled(false);
    } else {
      let getElemts1 = document.getElementsByClassName("overrideStartTabs");
      let getElemts2 = document.getElementsByClassName("overrideEndTabs");


      let length = getElemts1.length;


      for (let i = 0; i < length; i++) {
        if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
          if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
            // getElemts1[i].style.border = "1px solid #ced4da";
            // getElemts2[i].style.border = "1px solid #ced4da";

            // #########################################################
            let allStartTabs = [];

            for (let i = 0; i < length; i++) {
              allStartTabs.push(getElemts1[i].value);
            }

            allStartTabs.map((startT, key) => {
              function getAllIndexes(arr, val) {
                var indexes = [], i = -1;
                while ((i = arr.indexOf(val, i + 1)) != -1) {
                  indexes.push(i);
                }
                return indexes;
              }

              var indexes = getAllIndexes(allStartTabs, startT);

              if (indexes.length > 1) {
                indexes.map((name, key) => {
                  getElemts1[name].style.border = "1px solid red";
                  getElemts2[name].style.border = "1px solid red";
                })
                isEverythingOk = false;
              } else {
                getElemts1[i].style.border = "1px solid #ced4da";
                getElemts2[i].style.border = "1px solid #ced4da";
              }
            });


            // #########################################################
          }
          else {
            getElemts1[i].style.border = "1px solid red";
            getElemts2[i].style.border = "1px solid red";

            isEverythingOk = false;
          }
        } else {
          getElemts1[i].style.border = "1px solid red";
          getElemts2[i].style.border = "1px solid red";

          isEverythingOk = false;
        }
      }
    }


    if (!isEverythingOk) {
      setOverrideButtonEnabled(false);
    } else {
      setOverrideButtonEnabled(true);
    }
  }

  const addTimeSetInOverride = (type, frameName, uid) => {

    // console.log(type, uid);


    let findIndexOf = "";
    const allOverrideTimes = document.getElementsByClassName("overrideEndTabs");
    if (allOverrideTimes.length) {
      findIndexOf = allOverrideTimes[allOverrideTimes.length - 1].value
    } else {
      findIndexOf = timeFormat.value !== "12 Hours" ? "17:00" : "5:00pm";
    }

    const last_usedIndex = timeListToMeasure.indexOf(findIndexOf);

    if (last_usedIndex === timeListToMeasure.length - 1) {
      return
    }




    if (overrideDateEnabled) {
      const uid = uuidv4();

      const mainContainer = document.createElement("div");
      mainContainer.setAttribute("class", "d-flex gap-2 align-items-center");
      mainContainer.setAttribute("id", "overrride_" + uid);

      const innerDiv1 = document.createElement("div");
      innerDiv1.setAttribute("class", "w-50 h-100");


      const inputField1 = document.createElement("input");
      inputField1.setAttribute("list", "overrideStart_" + uid);
      inputField1.setAttribute("maxlength", "7");
      inputField1.setAttribute("required", "");
      inputField1.setAttribute("class", "customInputFieldFocus w-100 smallTextNote shadow-sm overrideStartTabs");
      inputField1.setAttribute("value", timeListToMeasure[last_usedIndex]);
      inputField1.addEventListener("input", (e) => {
        filterTimeFieldNow(e);
        checkValidTiming(e, "override");
      })

      const dataList1 = document.createElement("datalist");
      dataList1.setAttribute("class", "smallTextNote");
      dataList1.setAttribute("id", "overrideStart_" + uid);

      timeListToMeasure.map((times, key) => {
        const optionsTag1 = document.createElement("option");
        optionsTag1.setAttribute("key", key);
        optionsTag1.setAttribute("value", times);

        const textNode1 = document.createTextNode(times);

        optionsTag1.appendChild(textNode1);
        return dataList1.appendChild(optionsTag1);
      });

      innerDiv1.appendChild(inputField1);
      innerDiv1.appendChild(dataList1);


      // ###############################################################


      let useIndexToCall = last_usedIndex;

      if (last_usedIndex + 2 <= timeListToMeasure.length - 1) {
        useIndexToCall = last_usedIndex + 2;
      }
      else {
        useIndexToCall = timeListToMeasure.length - 1;
      }


      const innerDiv2 = document.createElement("div");
      innerDiv2.setAttribute("class", "w-50 h-100");


      const inputField2 = document.createElement("input");
      inputField2.setAttribute("list", "overrideEnd_" + uid);
      inputField2.setAttribute("maxlength", "7");
      inputField2.setAttribute("required", "");
      inputField2.setAttribute("class", "customInputFieldFocus w-100 smallTextNote shadow-sm overrideEndTabs");
      inputField2.setAttribute("value", timeListToMeasure[useIndexToCall]);
      inputField2.addEventListener("input", (e) => {
        filterTimeFieldNow(e);
        checkValidTiming(e, "override");
      })

      const dataList2 = document.createElement("datalist");
      dataList2.setAttribute("class", "smallTextNote");
      dataList2.setAttribute("id", "overrideEnd_" + uid);

      timeListToMeasure.map((times, key) => {
        const optionsTag2 = document.createElement("option");
        optionsTag2.setAttribute("key", key);
        optionsTag2.setAttribute("value", times);

        const textNode2 = document.createTextNode(times);

        optionsTag2.appendChild(textNode2);
        return dataList2.appendChild(optionsTag2);
      });


      innerDiv2.appendChild(inputField2);
      innerDiv2.appendChild(dataList2);

      const deleteIcon = document.createElement("i");
      deleteIcon.setAttribute("class", "fa fa-trash")
      deleteIcon.addEventListener("click", () => {
        document.getElementById("overrride_" + uid).remove();
        const allOverrideTabs = document.getElementsByClassName("overrideEndTabs");
        if (allOverrideTabs.length < 1) {
          setOverrideDateEnabled(false);
          checkOverridesTimesCorrect();
        }
        checkOverridesTimesCorrect();
      })


      mainContainer.appendChild(innerDiv1);
      mainContainer.appendChild(innerDiv2);
      mainContainer.appendChild(deleteIcon);


      const overrideMainFrame = document.getElementById("overrideMainFrame" + frameName);
      overrideMainFrame.appendChild(mainContainer);

    } else {
      setOverrideDateEnabled(true);
    }
  }

  useEffect(() => {
    if (userSchedules) {
      if (userSchedules[scheduleName]) {
        if (userSchedules[scheduleName].dateOverrides) {
          if (Object.keys(userSchedules[scheduleName].dateOverrides).length > 0) {
            if (Object.keys(userSchedules[scheduleName].dateOverrides).includes(overrideDate?.toISOString())) {
              setOverrideDateEnabled(userSchedules[scheduleName].dateOverrides[overrideDate?.toISOString()].active);
              setExistingOverride(true);
              setOverrideArray(userSchedules[scheduleName].dateOverrides[overrideDate?.toISOString()].timeSet);
              // setOverrideStartTime(userSchedules[scheduleName].dateOverrides[overrideDate].timeSet.split("-")[0]);
              // setOverrideEndTime(userSchedules[scheduleName].dateOverrides[overrideDate].timeSet.split("-")[1]);
              // console.log(userSchedules[scheduleName].dateOverrides[overrideDate].active);
            } else {
              setOverrideDateEnabled(false);
              setOverrideArray(["9:00am-5:00pm"]);
              // setOverrideStartTime("9:00am");
              // setOverrideEndTime("5:00pm");
              setExistingOverride(false);
            }
          } else {
            setExistingOverride(false);
          }
        } else {
          setExistingOverride(false);
        }
      } else {
        setExistingOverride(false);
      }
    } else {
      setExistingOverride(false);
    }

  }, [overrideDate])

  const addNewMemberToPeople = async (e) => {
    e.preventDefault();

    const emailOfNewPerson = newPeopleEmail.current.value;

    const query = {
      "email": emailOfNewPerson.toLowerCase(),
      "adminEmail": user.email,
    }


    const checkUser = await axios.post(serverUrl + "/users/doesUserExist", query);

    if (checkUser.status === 200) {

      const addPersonToPeoples = await axios.post(serverUrl + "/users/addNewPersonToPeoples", query);

      if (addPersonToPeoples.status === 200) {

        setAddNewMember(false);
        setAddMemberMessage(false);
        newPeopleEmail.current.value = "";

        setPeople(addPersonToPeoples.data);

        setViewToast(true); popupAlert.play();
        setToastTitle("Successful");
        setToastBody("Member added successfully.");
        setToastMessageType("success");


        // setCustomAlertBox(true);
        // setAlertBoxMessages("Member added successfully.");
        // setAlertErrorType("success");

      } else {

        setAddMemberMessage(addPersonToPeoples.data);

      }
    } else {

      setAddMemberMessage(checkUser.data);
    }
  }

  const validateAndSetName = (e) => {
    setName(e.target.value
      .replaceAll("  ", " ")
      .replaceAll("?", "")
      .replaceAll(".", "")
      .replaceAll("!", "")
      .replaceAll("@", "")
      .replaceAll("#", "")
      .replaceAll("$", "")
      .replaceAll("%", "")
      .replaceAll("^", "")
      .replaceAll("&", "")
      .replaceAll("*", "")
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll("_", "")
      .replaceAll("=", "")
      .replaceAll("+", "")
      .replaceAll("{", "")
      .replaceAll("}", "")
      .replaceAll("\\", "")
      .replaceAll("/", "")
      .replaceAll(":", "")
      .replaceAll("|", "")
      .replaceAll('"', '')
      .replaceAll("'", "")
      .replaceAll(",", "")
      .replaceAll("<", "")
      .replaceAll(">", "")
      .replaceAll("`", "")
      .replaceAll("~", "")
      .replaceAll("[", "")
      .replaceAll("]", "")
    );
  }

  const validateAndSetScheduleName = (e) => {
    setNewScheduleName(e.target.value
      .replaceAll("  ", " ")
      .replaceAll("?", "")
      .replaceAll(".", "")
      .replaceAll("!", "")
      .replaceAll("@", "")
      .replaceAll("#", "")
      .replaceAll("$", "")
      .replaceAll("%", "")
      .replaceAll("^", "")
      .replaceAll("&", "")
      .replaceAll("*", "")
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll("_", "")
      .replaceAll("=", "")
      .replaceAll("+", "")
      .replaceAll("{", "")
      .replaceAll("}", "")
      .replaceAll("\\", "")
      .replaceAll("/", "")
      .replaceAll(":", "")
      .replaceAll("|", "")
      .replaceAll('"', '')
      .replaceAll("'", "")
      .replaceAll(",", "")
      .replaceAll("<", "")
      .replaceAll(">", "")
      .replaceAll("`", "")
      .replaceAll("~", "")
      .replaceAll("[", "")
      .replaceAll("]", "")
    )


    // setScheduleName(e.target.value
    //   .replaceAll("  ", " ")
    //   .replaceAll("?", "")
    //   .replaceAll(".", "")
    //   .replaceAll("!", "")
    //   .replaceAll("@", "")
    //   .replaceAll("#", "")
    //   .replaceAll("$", "")
    //   .replaceAll("%", "")
    //   .replaceAll("^", "")
    //   .replaceAll("&", "")
    //   .replaceAll("*", "")
    //   .replaceAll("(", "")
    //   .replaceAll(")", "")
    //   .replaceAll("_", "")
    //   .replaceAll("=", "")
    //   .replaceAll("+", "")
    //   .replaceAll("{", "")
    //   .replaceAll("}", "")
    //   .replaceAll("\\", "")
    //   .replaceAll("/", "")
    //   .replaceAll(":", "")
    //   .replaceAll("|", "")
    //   .replaceAll('"', '')
    //   .replaceAll("'", "")
    //   .replaceAll(",", "")
    //   .replaceAll("<", "")
    //   .replaceAll(">", "")
    //   .replaceAll("`", "")
    //   .replaceAll("~", "")
    //   .replaceAll("[", "")
    //   .replaceAll("]", "")
    // );
  }

  const deletePersonFromTheList = async (email) => {

    const load = document.querySelector("#deletingMembersRightNow");
    load.disabled = true;
    load.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>Loading..';


    const query = {
      "adminEmail": user.email,
      "emailToDelete": email
    }

    const deletePersonNow = await axios.post(serverUrl + "/users/deletePersonFromPeople", query);
    if (deletePersonNow.status === 200) {
      setAskForDeleteMember(false);
      setDeleteMemberEmail(false);
      load.disabled = false;
      load.innerHTML = 'Confirm';


      if (JSON.stringify(deletePersonNow.data).includes("fetch")) {
        popupAlert.play();
        setViewToast(true);
        setToastTitle("Successful");
        setToastBody(deletePersonNow.data);
        setToastMessageType("success");
        setPeople(deletePersonNow.data)

        // setCustomAlertBox(true);
        // setAlertBoxMessages(deletePersonNow.data);
        // setAlertErrorType("success");
      } else {
        popupAlert.play();
        setViewToast(true);
        setToastTitle("Successful");
        setToastBody("Member deleted successfully.");
        setToastMessageType("success");
        setPeople(deletePersonNow.data)

        // setCustomAlertBox(true);
        // setAlertBoxMessages("Member deleted successfully.");
        // setAlertErrorType("success");
      }

    } else {
      load.disabled = false;
      load.innerHTML = 'Confirm';

      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody(deletePersonNow.data);
      setToastMessageType("invalid");

      // setCustomAlertBox(true);
      // setAlertBoxMessages(deletePersonNow.data);
      // setAlertErrorType("invalid");
    }
  }

  const filterTimeFieldNow = (e) => {


    e.target.value = e.target.value
      .replaceAll("  ", " ")
      .replaceAll("?", "")
      .replaceAll(".", "")
      .replaceAll("!", "")
      .replaceAll("@", "")
      .replaceAll("#", "")
      .replaceAll("$", "")
      .replaceAll("%", "")
      .replaceAll("^", "")
      .replaceAll("&", "")
      .replaceAll("*", "")
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll("_", "")
      .replaceAll("=", "")
      .replaceAll("+", "")
      .replaceAll("{", "")
      .replaceAll("}", "")
      .replaceAll("\\", "")
      .replaceAll("/", "")
      .replaceAll("|", "")
      .replaceAll('"', '')
      .replaceAll("'", "")
      .replaceAll(",", "")
      .replaceAll("<", "")
      .replaceAll(">", "")
      .replaceAll("`", "")
      .replaceAll("~", "")
      .replaceAll("[", "")
      .replaceAll("]", "")
  }

  const checkValidTiming = (e, type) => {
    // console.log("checking validation");
    let isEverythingOk = true;

    if (e.target.value.length < 6) {
      e.target.style.border = "1px solid red";
      isEverythingOk = false;
    } else {

      if (timeListToMeasure.includes(e.target.value)) {

        let allElements = e.target.classList;

        allElements.value.split(" ").map((className, key) => {

          if (type === "override") {

            let getElemts1 = document.getElementsByClassName("overrideStartTabs");
            let getElemts2 = document.getElementsByClassName("overrideEndTabs");


            let length = getElemts1.length;


            for (let i = 0; i < length; i++) {

              if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                  // getElemts1[i].style.border = "1px solid #ced4da";
                  // getElemts2[i].style.border = "1px solid #ced4da";

                  // #########################################################
                  let allStartTabs = [];

                  for (let i = 0; i < length; i++) {
                    allStartTabs.push(getElemts1[i].value);
                  }

                  allStartTabs.map((startT, key) => {
                    function getAllIndexes(arr, val) {
                      var indexes = [], i = -1;
                      while ((i = arr.indexOf(val, i + 1)) != -1) {
                        indexes.push(i);
                      }
                      return indexes;
                    }

                    var indexes = getAllIndexes(allStartTabs, startT);

                    if (indexes.length > 1) {
                      indexes.map((name, key) => {
                        getElemts1[name].style.border = "1px solid red";
                        getElemts2[name].style.border = "1px solid red";
                      })
                      isEverythingOk = false;
                    } else {
                      getElemts1[i].style.border = "1px solid #ced4da";
                      getElemts2[i].style.border = "1px solid #ced4da";
                    }
                  });


                  // #########################################################
                }
                else {
                  getElemts1[i].style.border = "1px solid red";
                  getElemts2[i].style.border = "1px solid red";

                  isEverythingOk = false;
                }
              } else {
                getElemts1[i].style.border = "1px solid red";
                getElemts2[i].style.border = "1px solid red";

                isEverythingOk = false;
              }
            }

          } else {

            if (className.includes("sunday")) {
              let getElemts1 = document.getElementsByClassName(`sundayStartTabs${type}`);
              let getElemts2 = document.getElementsByClassName(`sundayEndTabs${type}`);



              let length = getElemts1.length;


              for (let i = 0; i < length; i++) {
                if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                  if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {

                    // #########################################################
                    let allStartTabs = [];

                    for (let i = 0; i < length; i++) {
                      allStartTabs.push(getElemts1[i].value);
                    }

                    allStartTabs.map((startT, key) => {
                      function getAllIndexes(arr, val) {
                        var indexes = [], i = -1;
                        while ((i = arr.indexOf(val, i + 1)) != -1) {
                          indexes.push(i);
                        }
                        return indexes;
                      }

                      var indexes = getAllIndexes(allStartTabs, startT);

                      if (indexes.length > 1) {
                        indexes.map((name, key) => {
                          getElemts1[name].style.border = "1px solid red";
                          getElemts2[name].style.border = "1px solid red";
                        });
                        isEverythingOk = false;
                      } else {
                        getElemts1[i].style.border = "1px solid #ced4da";
                        getElemts2[i].style.border = "1px solid #ced4da";
                      }
                    });


                    // #########################################################

                  }
                  else {
                    getElemts1[i].style.border = "1px solid red";
                    getElemts2[i].style.border = "1px solid red";

                    isEverythingOk = false;
                  }
                } else {
                  getElemts1[i].style.border = "1px solid red";
                  getElemts2[i].style.border = "1px solid red";
                  isEverythingOk = false;
                }
              }



            }
            else if (className.includes("monday")) {
              let getElemts1 = document.getElementsByClassName(`mondayStartTabs${type}`);
              let getElemts2 = document.getElementsByClassName(`mondayEndTabs${type}`);


              let length = getElemts1.length;


              for (let i = 0; i < length; i++) {
                if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                  if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                    // #########################################################
                    let allStartTabs = [];

                    for (let i = 0; i < length; i++) {
                      allStartTabs.push(getElemts1[i].value);
                    }

                    allStartTabs.map((startT, key) => {
                      function getAllIndexes(arr, val) {
                        var indexes = [], i = -1;
                        while ((i = arr.indexOf(val, i + 1)) != -1) {
                          indexes.push(i);
                        }
                        return indexes;
                      }

                      var indexes = getAllIndexes(allStartTabs, startT);

                      if (indexes.length > 1) {
                        indexes.map((name, key) => {
                          getElemts1[name].style.border = "1px solid red";
                          getElemts2[name].style.border = "1px solid red";
                        });
                        isEverythingOk = false;
                      } else {
                        getElemts1[i].style.border = "1px solid #ced4da";
                        getElemts2[i].style.border = "1px solid #ced4da";
                      }
                    });


                    // #########################################################
                  }
                  else {
                    getElemts1[i].style.border = "1px solid red";
                    getElemts2[i].style.border = "1px solid red";

                    isEverythingOk = false;
                  }
                } else {
                  getElemts1[i].style.border = "1px solid red";
                  getElemts2[i].style.border = "1px solid red";

                  isEverythingOk = false;
                }
              }
            }
            else if (className.includes("tuesday")) {
              let getElemts1 = document.getElementsByClassName(`tuesdayStartTabs${type}`);
              let getElemts2 = document.getElementsByClassName(`tuesdayEndTabs${type}`);

              let length = getElemts1.length;


              for (let i = 0; i < length; i++) {
                if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                  if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                    // #########################################################
                    let allStartTabs = [];

                    for (let i = 0; i < length; i++) {
                      allStartTabs.push(getElemts1[i].value);
                    }

                    allStartTabs.map((startT, key) => {
                      function getAllIndexes(arr, val) {
                        var indexes = [], i = -1;
                        while ((i = arr.indexOf(val, i + 1)) != -1) {
                          indexes.push(i);
                        }
                        return indexes;
                      }

                      var indexes = getAllIndexes(allStartTabs, startT);

                      if (indexes.length > 1) {
                        indexes.map((name, key) => {
                          getElemts1[name].style.border = "1px solid red";
                          getElemts2[name].style.border = "1px solid red";
                        });
                        isEverythingOk = false;
                      } else {
                        getElemts1[i].style.border = "1px solid #ced4da";
                        getElemts2[i].style.border = "1px solid #ced4da";
                      }
                    });


                    // #########################################################
                  }
                  else {
                    getElemts1[i].style.border = "1px solid red";
                    getElemts2[i].style.border = "1px solid red";

                    isEverythingOk = false;
                  }
                } else {
                  getElemts1[i].style.border = "1px solid red";
                  getElemts2[i].style.border = "1px solid red";

                  isEverythingOk = false;
                }
              }
            }
            else if (className.includes("wednesday")) {
              let getElemts1 = document.getElementsByClassName(`wednesdayStartTabs${type}`);
              let getElemts2 = document.getElementsByClassName(`wednesdayEndTabs${type}`);

              let length = getElemts1.length;


              for (let i = 0; i < length; i++) {
                if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                  if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                    // #########################################################
                    let allStartTabs = [];

                    for (let i = 0; i < length; i++) {
                      allStartTabs.push(getElemts1[i].value);
                    }

                    allStartTabs.map((startT, key) => {
                      function getAllIndexes(arr, val) {
                        var indexes = [], i = -1;
                        while ((i = arr.indexOf(val, i + 1)) != -1) {
                          indexes.push(i);
                        }
                        return indexes;
                      }

                      var indexes = getAllIndexes(allStartTabs, startT);

                      if (indexes.length > 1) {
                        indexes.map((name, key) => {
                          getElemts1[name].style.border = "1px solid red";
                          getElemts2[name].style.border = "1px solid red";
                        });
                        isEverythingOk = false;
                      } else {
                        getElemts1[i].style.border = "1px solid #ced4da";
                        getElemts2[i].style.border = "1px solid #ced4da";
                      }
                    });


                    // #########################################################
                  }
                  else {
                    getElemts1[i].style.border = "1px solid red";
                    getElemts2[i].style.border = "1px solid red";

                    isEverythingOk = false;
                  }
                } else {
                  getElemts1[i].style.border = "1px solid red";
                  getElemts2[i].style.border = "1px solid red";

                  isEverythingOk = false;
                }
              }
            }
            else if (className.includes("thursday")) {
              let getElemts1 = document.getElementsByClassName(`thursdayStartTabs${type}`);
              let getElemts2 = document.getElementsByClassName(`thursdayEndTabs${type}`);

              let length = getElemts1.length;


              for (let i = 0; i < length; i++) {
                if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                  if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                    // #########################################################
                    let allStartTabs = [];

                    for (let i = 0; i < length; i++) {
                      allStartTabs.push(getElemts1[i].value);
                    }

                    allStartTabs.map((startT, key) => {
                      function getAllIndexes(arr, val) {
                        var indexes = [], i = -1;
                        while ((i = arr.indexOf(val, i + 1)) != -1) {
                          indexes.push(i);
                        }
                        return indexes;
                      }

                      var indexes = getAllIndexes(allStartTabs, startT);

                      if (indexes.length > 1) {
                        indexes.map((name, key) => {
                          getElemts1[name].style.border = "1px solid red";
                          getElemts2[name].style.border = "1px solid red";
                        });
                        isEverythingOk = false;
                      } else {
                        getElemts1[i].style.border = "1px solid #ced4da";
                        getElemts2[i].style.border = "1px solid #ced4da";
                      }
                    });


                    // #########################################################
                  }
                  else {
                    getElemts1[i].style.border = "1px solid red";
                    getElemts2[i].style.border = "1px solid red";

                    isEverythingOk = false;
                  }
                } else {
                  getElemts1[i].style.border = "1px solid red";
                  getElemts2[i].style.border = "1px solid red";

                  isEverythingOk = false;
                }
              }
            }
            else if (className.includes("friday")) {
              let getElemts1 = document.getElementsByClassName(`fridayStartTabs${type}`);
              let getElemts2 = document.getElementsByClassName(`fridayEndTabs${type}`);

              let length = getElemts1.length;


              for (let i = 0; i < length; i++) {
                if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                  if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                    // #########################################################
                    let allStartTabs = [];

                    for (let i = 0; i < length; i++) {
                      allStartTabs.push(getElemts1[i].value);
                    }

                    allStartTabs.map((startT, key) => {
                      function getAllIndexes(arr, val) {
                        var indexes = [], i = -1;
                        while ((i = arr.indexOf(val, i + 1)) != -1) {
                          indexes.push(i);
                        }
                        return indexes;
                      }

                      var indexes = getAllIndexes(allStartTabs, startT);

                      if (indexes.length > 1) {
                        indexes.map((name, key) => {
                          getElemts1[name].style.border = "1px solid red";
                          getElemts2[name].style.border = "1px solid red";
                        });
                        isEverythingOk = false;
                      } else {
                        getElemts1[i].style.border = "1px solid #ced4da";
                        getElemts2[i].style.border = "1px solid #ced4da";
                      }
                    });


                    // #########################################################
                  }
                  else {
                    getElemts1[i].style.border = "1px solid red";
                    getElemts2[i].style.border = "1px solid red";

                    isEverythingOk = false;
                  }
                } else {
                  getElemts1[i].style.border = "1px solid red";
                  getElemts2[i].style.border = "1px solid red";

                  isEverythingOk = false;
                }
              }
            }
            else if (className.includes("saturday")) {
              let getElemts1 = document.getElementsByClassName(`saturdayStartTabs${type}`);
              let getElemts2 = document.getElementsByClassName(`saturdayEndTabs${type}`);

              let length = getElemts1.length;


              for (let i = 0; i < length; i++) {
                if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
                  if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
                    // #########################################################
                    let allStartTabs = [];

                    for (let i = 0; i < length; i++) {
                      allStartTabs.push(getElemts1[i].value);
                    }

                    allStartTabs.map((startT, key) => {
                      function getAllIndexes(arr, val) {
                        var indexes = [], i = -1;
                        while ((i = arr.indexOf(val, i + 1)) != -1) {
                          indexes.push(i);
                        }
                        return indexes;
                      }

                      var indexes = getAllIndexes(allStartTabs, startT);

                      if (indexes.length > 1) {
                        indexes.map((name, key) => {
                          getElemts1[name].style.border = "1px solid red";
                          getElemts2[name].style.border = "1px solid red";
                        });
                        isEverythingOk = false;
                      } else {
                        getElemts1[i].style.border = "1px solid #ced4da";
                        getElemts2[i].style.border = "1px solid #ced4da";
                      }
                    });


                    // #########################################################
                  }
                  else {
                    getElemts1[i].style.border = "1px solid red";
                    getElemts2[i].style.border = "1px solid red";

                    isEverythingOk = false;
                  }
                } else {
                  getElemts1[i].style.border = "1px solid red";
                  getElemts2[i].style.border = "1px solid red";

                  isEverythingOk = false;
                }
              }
            }
          }

        });

      } else {
        e.target.style.border = "1px solid red";
        isEverythingOk = false;
      }
    }

    if (!isEverythingOk) {
      if (type === "override") {
        setOverrideButtonEnabled(false);
      } else {
        setUpdateButtonEnabled(false);
      }
    } else {
      if (type === "override") {
        setOverrideButtonEnabled(true);
      } else {
        setUpdateButtonEnabled(true);
      }
    }

    checkUpdatedStateOfTimeValidation(type);
  }

  const checkOverridesTimesCorrect = () => {
    let isEverythingOk = true;

    const allTabs = document.getElementsByClassName("overrideEndTabs");

    if (allTabs.length < 1) {
      setOverrideButtonEnabled(false);
    } else {
      let getElemts1 = document.getElementsByClassName("overrideStartTabs");
      let getElemts2 = document.getElementsByClassName("overrideEndTabs");


      let length = getElemts1.length;


      for (let i = 0; i < length; i++) {
        if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
          if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
            getElemts1[i].style.border = "1px solid #ced4da";
            getElemts2[i].style.border = "1px solid #ced4da";
          }
          else {
            getElemts1[i].style.border = "1px solid red";
            getElemts2[i].style.border = "1px solid red";

            isEverythingOk = false;
          }
        } else {
          getElemts1[i].style.border = "1px solid red";
          getElemts2[i].style.border = "1px solid red";

          isEverythingOk = false;
        }
      }
    }


    if (!isEverythingOk) {
      setOverrideButtonEnabled(false);
    } else {
      setOverrideButtonEnabled(true);
    }
  }

  const checkUpdatedStateOfTimeValidation = (frameName) => {
    // console.log("checking everything")
    let isEverythingOk = true;


    var getElemts1 = document.getElementsByClassName(`sundayStartTabs${frameName}`);
    var getElemts2 = document.getElementsByClassName(`sundayEndTabs${frameName}`);

    var length = getElemts1.length;

    if (length > 0) {
      for (let i = 0; i < length; i++) {
        if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
          if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
            // #########################################################
            let allStartTabs = [];

            for (let i = 0; i < length; i++) {
              allStartTabs.push(getElemts1[i].value);
            }

            allStartTabs.map((startT, key) => {
              function getAllIndexes(arr, val) {
                var indexes = [], i = -1;
                while ((i = arr.indexOf(val, i + 1)) != -1) {
                  indexes.push(i);
                }
                return indexes;
              }

              var indexes = getAllIndexes(allStartTabs, startT);

              if (indexes.length > 1) {
                indexes.map((name, key) => {
                  getElemts1[name].style.border = "1px solid red";
                  getElemts2[name].style.border = "1px solid red";
                });
                isEverythingOk = false;
              } else {
                getElemts1[i].style.border = "1px solid #ced4da";
                getElemts2[i].style.border = "1px solid #ced4da";
              }
            });


            // #########################################################
          }
          else {
            getElemts1[i].style.border = "1px solid red";
            getElemts2[i].style.border = "1px solid red";

            isEverythingOk = false;
          }
        } else {
          getElemts1[i].style.border = "1px solid red";
          getElemts2[i].style.border = "1px solid red";

          isEverythingOk = false;
        }
      }
    }


    var getElemts1 = document.getElementsByClassName(`mondayStartTabs${frameName}`);
    var getElemts2 = document.getElementsByClassName(`mondayEndTabs${frameName}`);


    var length = getElemts1.length;


    if (length > 0) {
      for (let i = 0; i < length; i++) {
        if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
          if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
            // #########################################################
            let allStartTabs = [];

            for (let i = 0; i < length; i++) {
              allStartTabs.push(getElemts1[i].value);
            }

            allStartTabs.map((startT, key) => {
              function getAllIndexes(arr, val) {
                var indexes = [], i = -1;
                while ((i = arr.indexOf(val, i + 1)) != -1) {
                  indexes.push(i);
                }
                return indexes;
              }

              var indexes = getAllIndexes(allStartTabs, startT);

              if (indexes.length > 1) {
                indexes.map((name, key) => {
                  getElemts1[name].style.border = "1px solid red";
                  getElemts2[name].style.border = "1px solid red";
                });
                isEverythingOk = false;
              } else {
                getElemts1[i].style.border = "1px solid #ced4da";
                getElemts2[i].style.border = "1px solid #ced4da";
              }
            });


            // #########################################################
          }
          else {
            getElemts1[i].style.border = "1px solid red";
            getElemts2[i].style.border = "1px solid red";

            isEverythingOk = false;
          }
        } else {
          getElemts1[i].style.border = "1px solid red";
          getElemts2[i].style.border = "1px solid red";

          isEverythingOk = false;
        }
      }
    }

    var getElemts1 = document.getElementsByClassName(`tuesdayStartTabs${frameName}`);
    var getElemts2 = document.getElementsByClassName(`tuesdayEndTabs${frameName}`);

    var length = getElemts1.length;


    if (length > 0) {
      for (let i = 0; i < length; i++) {
        if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
          if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
            // #########################################################
            let allStartTabs = [];

            for (let i = 0; i < length; i++) {
              allStartTabs.push(getElemts1[i].value);
            }

            allStartTabs.map((startT, key) => {
              function getAllIndexes(arr, val) {
                var indexes = [], i = -1;
                while ((i = arr.indexOf(val, i + 1)) != -1) {
                  indexes.push(i);
                }
                return indexes;
              }

              var indexes = getAllIndexes(allStartTabs, startT);

              if (indexes.length > 1) {
                indexes.map((name, key) => {
                  getElemts1[name].style.border = "1px solid red";
                  getElemts2[name].style.border = "1px solid red";
                });
                isEverythingOk = false;
              } else {
                getElemts1[i].style.border = "1px solid #ced4da";
                getElemts2[i].style.border = "1px solid #ced4da";
              }
            });


            // #########################################################
          }
          else {
            getElemts1[i].style.border = "1px solid red";
            getElemts2[i].style.border = "1px solid red";

            isEverythingOk = false;
          }
        } else {
          getElemts1[i].style.border = "1px solid red";
          getElemts2[i].style.border = "1px solid red";

          isEverythingOk = false;
        }
      }
    }


    var getElemts1 = document.getElementsByClassName(`wednesdayStartTabs${frameName}`);
    var getElemts2 = document.getElementsByClassName(`wednesdayEndTabs${frameName}`);

    var length = getElemts1.length;


    if (length > 0) {
      for (let i = 0; i < length; i++) {
        if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
          if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
            // #########################################################
            let allStartTabs = [];

            for (let i = 0; i < length; i++) {
              allStartTabs.push(getElemts1[i].value);
            }

            allStartTabs.map((startT, key) => {
              function getAllIndexes(arr, val) {
                var indexes = [], i = -1;
                while ((i = arr.indexOf(val, i + 1)) != -1) {
                  indexes.push(i);
                }
                return indexes;
              }

              var indexes = getAllIndexes(allStartTabs, startT);

              if (indexes.length > 1) {
                indexes.map((name, key) => {
                  getElemts1[name].style.border = "1px solid red";
                  getElemts2[name].style.border = "1px solid red";
                });
                isEverythingOk = false;
              } else {
                getElemts1[i].style.border = "1px solid #ced4da";
                getElemts2[i].style.border = "1px solid #ced4da";
              }
            });


            // #########################################################
          }
          else {
            getElemts1[i].style.border = "1px solid red";
            getElemts2[i].style.border = "1px solid red";

            isEverythingOk = false;
          }
        } else {
          getElemts1[i].style.border = "1px solid red";
          getElemts2[i].style.border = "1px solid red";

          isEverythingOk = false;
        }
      }
    }


    var getElemts1 = document.getElementsByClassName(`thursdayStartTabs${frameName}`);
    var getElemts2 = document.getElementsByClassName(`thursdayEndTabs${frameName}`);

    var length = getElemts1.length;


    if (length > 0) {
      for (let i = 0; i < length; i++) {
        if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
          if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
            // #########################################################
            let allStartTabs = [];

            for (let i = 0; i < length; i++) {
              allStartTabs.push(getElemts1[i].value);
            }

            allStartTabs.map((startT, key) => {
              function getAllIndexes(arr, val) {
                var indexes = [], i = -1;
                while ((i = arr.indexOf(val, i + 1)) != -1) {
                  indexes.push(i);
                }
                return indexes;
              }

              var indexes = getAllIndexes(allStartTabs, startT);

              if (indexes.length > 1) {
                indexes.map((name, key) => {
                  getElemts1[name].style.border = "1px solid red";
                  getElemts2[name].style.border = "1px solid red";
                });
                isEverythingOk = false;
              } else {
                getElemts1[i].style.border = "1px solid #ced4da";
                getElemts2[i].style.border = "1px solid #ced4da";
              }
            });


            // #########################################################
          }
          else {
            getElemts1[i].style.border = "1px solid red";
            getElemts2[i].style.border = "1px solid red";

            isEverythingOk = false;
          }
        } else {
          getElemts1[i].style.border = "1px solid red";
          getElemts2[i].style.border = "1px solid red";

          isEverythingOk = false;
        }
      }
    }



    var getElemts1 = document.getElementsByClassName(`fridayStartTabs${frameName}`);
    var getElemts2 = document.getElementsByClassName(`fridayEndTabs${frameName}`);

    var length = getElemts1.length;


    if (length > 0) {
      for (let i = 0; i < length; i++) {
        if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
          if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
            // #########################################################
            let allStartTabs = [];

            for (let i = 0; i < length; i++) {
              allStartTabs.push(getElemts1[i].value);
            }

            allStartTabs.map((startT, key) => {
              function getAllIndexes(arr, val) {
                var indexes = [], i = -1;
                while ((i = arr.indexOf(val, i + 1)) != -1) {
                  indexes.push(i);
                }
                return indexes;
              }

              var indexes = getAllIndexes(allStartTabs, startT);

              if (indexes.length > 1) {
                indexes.map((name, key) => {
                  getElemts1[name].style.border = "1px solid red";
                  getElemts2[name].style.border = "1px solid red";
                });
                isEverythingOk = false;
              } else {
                getElemts1[i].style.border = "1px solid #ced4da";
                getElemts2[i].style.border = "1px solid #ced4da";
              }
            });


            // #########################################################
          }
          else {
            getElemts1[i].style.border = "1px solid red";
            getElemts2[i].style.border = "1px solid red";

            isEverythingOk = false;
          }
        } else {
          getElemts1[i].style.border = "1px solid red";
          getElemts2[i].style.border = "1px solid red";

          isEverythingOk = false;
        }
      }
    }



    var getElemts1 = document.getElementsByClassName(`saturdayStartTabs${frameName}`);
    var getElemts2 = document.getElementsByClassName(`saturdayEndTabs${frameName}`);

    var length = getElemts1.length;


    if (length > 0) {
      for (let i = 0; i < length; i++) {
        if (getElemts1[i].value.length >= 5 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
          if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
            // #########################################################
            let allStartTabs = [];

            for (let i = 0; i < length; i++) {
              allStartTabs.push(getElemts1[i].value);
            }

            allStartTabs.map((startT, key) => {
              function getAllIndexes(arr, val) {
                var indexes = [], i = -1;
                while ((i = arr.indexOf(val, i + 1)) != -1) {
                  indexes.push(i);
                }
                return indexes;
              }

              var indexes = getAllIndexes(allStartTabs, startT);

              if (indexes.length > 1) {
                indexes.map((name, key) => {
                  getElemts1[name].style.border = "1px solid red";
                  getElemts2[name].style.border = "1px solid red";
                });
                isEverythingOk = false;
              } else {
                getElemts1[i].style.border = "1px solid #ced4da";
                getElemts2[i].style.border = "1px solid #ced4da";
              }
            });


            // #########################################################
          }
          else {
            getElemts1[i].style.border = "1px solid red";
            getElemts2[i].style.border = "1px solid red";

            isEverythingOk = false;
          }
        } else {
          getElemts1[i].style.border = "1px solid red";
          getElemts2[i].style.border = "1px solid red";

          isEverythingOk = false;
        }
      }
    }

    if (!isEverythingOk) {
      setUpdateButtonEnabled(false);
    } else {
      setUpdateButtonEnabled(true);
    }
  }

  const checkDayOnToggle = (sunday, monday, tuesday, wednesday, thursday, friday, saturday, type) => {
    let isEverythingOk = true;


    if (sunday) {
      var getElemts1 = document.getElementsByClassName(`sundayStartTabs${type}`);
      var getElemts2 = document.getElementsByClassName(`sundayEndTabs${type}`);

      var length = getElemts1.length;

      if (length > 0) {
        for (let i = 0; i < length; i++) {
          if (getElemts1[i].value.length >= 6 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
            if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
              getElemts1[i].style.border = "1px solid #ced4da";
              getElemts2[i].style.border = "1px solid #ced4da";
            }
            else {
              getElemts1[i].style.border = "1px solid red";
              getElemts2[i].style.border = "1px solid red";

              isEverythingOk = false;
            }
          } else {
            getElemts1[i].style.border = "1px solid red";
            getElemts2[i].style.border = "1px solid red";

            isEverythingOk = false;
          }
        }
      }
    }


    if (monday) {
      var getElemts1 = document.getElementsByClassName(`mondayStartTabs${type}`);
      var getElemts2 = document.getElementsByClassName(`mondayEndTabs${type}`);


      var length = getElemts1.length;


      if (length > 0) {
        for (let i = 0; i < length; i++) {
          if (getElemts1[i].value.length >= 6 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
            if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
              getElemts1[i].style.border = "1px solid #ced4da";
              getElemts2[i].style.border = "1px solid #ced4da";
            }
            else {
              getElemts1[i].style.border = "1px solid red";
              getElemts2[i].style.border = "1px solid red";

              isEverythingOk = false;
            }
          } else {
            getElemts1[i].style.border = "1px solid red";
            getElemts2[i].style.border = "1px solid red";

            isEverythingOk = false;
          }
        }
      }
    }


    if (tuesday) {
      var getElemts1 = document.getElementsByClassName(`tuesdayStartTabs${type}`);
      var getElemts2 = document.getElementsByClassName(`tuesdayEndTabs${type}`);

      var length = getElemts1.length;


      if (length > 0) {
        for (let i = 0; i < length; i++) {
          if (getElemts1[i].value.length >= 6 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
            if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
              getElemts1[i].style.border = "1px solid #ced4da";
              getElemts2[i].style.border = "1px solid #ced4da";
            }
            else {
              getElemts1[i].style.border = "1px solid red";
              getElemts2[i].style.border = "1px solid red";

              isEverythingOk = false;
            }
          } else {
            getElemts1[i].style.border = "1px solid red";
            getElemts2[i].style.border = "1px solid red";

            isEverythingOk = false;
          }
        }
      }
    }


    if (wednesday) {
      var getElemts1 = document.getElementsByClassName(`wednesdayStartTabs${type}`);
      var getElemts2 = document.getElementsByClassName(`wednesdayEndTabs${type}`);

      var length = getElemts1.length;


      if (length > 0) {
        for (let i = 0; i < length; i++) {
          if (getElemts1[i].value.length >= 6 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
            if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
              getElemts1[i].style.border = "1px solid #ced4da";
              getElemts2[i].style.border = "1px solid #ced4da";
            }
            else {
              getElemts1[i].style.border = "1px solid red";
              getElemts2[i].style.border = "1px solid red";

              isEverythingOk = false;
            }
          } else {
            getElemts1[i].style.border = "1px solid red";
            getElemts2[i].style.border = "1px solid red";

            isEverythingOk = false;
          }
        }
      }
    }


    if (thursday) {
      var getElemts1 = document.getElementsByClassName(`thursdayStartTabs${type}`);
      var getElemts2 = document.getElementsByClassName(`thursdayEndTabs${type}`);

      var length = getElemts1.length;


      if (length > 0) {
        for (let i = 0; i < length; i++) {
          if (getElemts1[i].value.length >= 6 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
            if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
              getElemts1[i].style.border = "1px solid #ced4da";
              getElemts2[i].style.border = "1px solid #ced4da";
            }
            else {
              getElemts1[i].style.border = "1px solid red";
              getElemts2[i].style.border = "1px solid red";

              isEverythingOk = false;
            }
          } else {
            getElemts1[i].style.border = "1px solid red";
            getElemts2[i].style.border = "1px solid red";

            isEverythingOk = false;
          }
        }
      }
    }



    if (friday) {
      var getElemts1 = document.getElementsByClassName(`fridayStartTabs${type}`);
      var getElemts2 = document.getElementsByClassName(`fridayEndTabs${type}`);

      var length = getElemts1.length;


      if (length > 0) {
        for (let i = 0; i < length; i++) {
          if (getElemts1[i].value.length >= 6 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
            if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
              getElemts1[i].style.border = "1px solid #ced4da";
              getElemts2[i].style.border = "1px solid #ced4da";
            }
            else {
              getElemts1[i].style.border = "1px solid red";
              getElemts2[i].style.border = "1px solid red";

              isEverythingOk = false;
            }
          } else {
            getElemts1[i].style.border = "1px solid red";
            getElemts2[i].style.border = "1px solid red";

            isEverythingOk = false;
          }
        }
      }
    }


    if (saturday) {

      var getElemts1 = document.getElementsByClassName(`saturdayStartTabs${type}`);
      var getElemts2 = document.getElementsByClassName(`saturdayEndTabs${type}`);

      var length = getElemts1.length;


      if (length > 0) {
        for (let i = 0; i < length; i++) {
          if (getElemts1[i].value.length >= 6 && timeListToMeasure.includes(getElemts1[i].value) && timeListToMeasure.includes(getElemts2[i].value)) {
            if (timeListToMeasure.indexOf(getElemts1[i].value) < timeListToMeasure.indexOf(getElemts2[i].value)) {
              getElemts1[i].style.border = "1px solid #ced4da";
              getElemts2[i].style.border = "1px solid #ced4da";
            }
            else {
              getElemts1[i].style.border = "1px solid red";
              getElemts2[i].style.border = "1px solid red";

              isEverythingOk = false;
            }
          } else {
            getElemts1[i].style.border = "1px solid red";
            getElemts2[i].style.border = "1px solid red";

            isEverythingOk = false;
          }
        }
      }
    }

    if (!isEverythingOk) {
      setUpdateButtonEnabled(false);
    } else {
      setUpdateButtonEnabled(true);
    }

    checkUpdatedStateOfTimeValidation(type)
  }

  // const updateProfileImage = (imageList, addUpdateIndex) => {
  //   // data for submit
  //   // console.log(imageList, addUpdateIndex);
  //   setImages(imageList);
  //   if (imageList.length > 0) {
  //     setCurrentGlobalProfile(imageList[0].data_url)
  //   } else {
  //     console.log(defaultImage.data_url)
  //     setCurrentGlobalProfile(defaultImage.data_url)
  //   }
  // }

  const handlePhoneAddStage1 = async (e) => {
    e.preventDefault();

    const load = document.querySelector("#sendOTPButton");
    load.disabled = true;
    load.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>Sending..';

    setTimerForOTP(60);
    setPhoneAddError(null);

    setResendOTP(false);

    const query = {
      "phoneNumber": phoneNumberForOTP,
    }

    const sendUpdates = await axios.post(serverUrl + "/users/sendOTPToNumber", query);
    if (sendUpdates.status === 200) {
      // console.log(sendUpdates.data);
      setPhoneAddError(null);
      setNewOTPForPhone(sendUpdates.data.otp);
      setPhoneAddingStage(2);

      load.disabled = false;
      load.innerHTML = 'Send OTP'

      let counterOTP = 60;
      OTPInterval.current = setInterval(() => {
        setTimerForOTP(counterOTP);
        counterOTP--;
        if (counterOTP <= 0) {
          clearInterval(OTPInterval.current);
          setNewOTPForPhone(uuidv4().toString())
          setResendOTP(true);
        }
      }, 1000);
    } else {
      setPhoneAddError("Something went wrong!");

      load.disabled = false;
      load.innerHTML = 'Send OTP'
      // console.log(sendUpdates.data);
    }
  }

  const resendOTPNow = async (e) => {
    e.preventDefault();
    setTimerForOTP(60);
    setPhoneAddError(null);

    const load = document.querySelector("#resendOTPButton");
    load.disabled = true;
    load.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>Sending..';

    phoneAddOTP.current.value = "";





    const query = {
      "phoneNumber": phoneNumberForOTP,
    }

    const sendUpdates = await axios.post(serverUrl + "/users/sendOTPToNumber", query);
    if (sendUpdates.status === 200) {
      setResendOTP(false);
      // console.log(sendUpdates.data);
      setPhoneAddError(null);
      setNewOTPForPhone(sendUpdates.data.otp);
      setPhoneAddingStage(2);

      load.disabled = false;
      load.innerHTML = "Resend OTP";

      let counterOTP = 60;
      OTPInterval.current = setInterval(() => {
        setTimerForOTP(counterOTP);
        counterOTP--;
        if (counterOTP <= 0) {
          clearInterval(OTPInterval.current);
          setNewOTPForPhone(uuidv4().toString())
          setResendOTP(true);
        }
      }, 1000);
    } else {
      setPhoneAddError("Invalid Phone Number!");
      load.disabled = false;
      load.innerHTML = "Resend OTP";
      // console.log(sendUpdates.data);
    }
  }

  const handlePhoneAddStage2 = async (e) => {
    e.preventDefault();

    // console.log(parseInt(phoneAddOTP.current.value), parseInt(newOTPForPhone));
    if (parseInt(phoneAddOTP.current.value) === parseInt(newOTPForPhone)) {

      const query = {
        "email": user.email,
        "phoneNumber": phoneNumberForOTP
      }

      const updateContacts = await axios.post(serverUrl + "/users/updatePhoneNow", query);
      if (updateContacts.status === 200) {
        // setCustomAlertBox(true);
        // setAlertBoxMessages("Phone Number Added");
        // setAlertErrorType("success");
        // console.log("link updated");
        setPhoneAddError(null);
        setNewOTPForPhone("")
        setPhoneAddingStage(3);

        const updateSettings = await axios.post(serverUrl + "/users/getUpdatedSettings", { "email": user.email });

        if (updateSettings.status === 200) {
          setName(updateSettings.data.name);
          setUsername(updateSettings.data.username);
          setWelcome(updateSettings.data.settings.welcome);
          setTimeFormat(updateSettings.data.settings.timeFormat);
          setTimeListToMeasure(updateSettings.data.settings.timeFormat.value !== "12 Hours" ? timeListToMeasureData24 : timeListToMeasureData);
          setCountry(updateSettings.data.settings.country);
          setSelectedTimezoneSettings(updateSettings.data.settings.selectedTimezone);
          setPhoneList(updateSettings.data.phoneNumbers);
          setPhoneAddError(null);

          clearInterval(OTPInterval.current);


          let allActivePhoneNumbers = []
          Object.keys(updateSettings.data.phoneNumbers).map((number, key) => {
            if (updateSettings.data.phoneNumbers[number].active) {
              allActivePhoneNumbers.push({ label: number, value: number });
            }
          });
          setOptionListForPhone(allActivePhoneNumbers);

        }
        else {
          // console.log("Unable to Update the User");
          setPhoneAddError("Unable to Update the User..!");
        }

      } else {
        // setCustomAlertBox(true);
        // setAlertBoxMessages("Something went wrong while saving your new phone..!");
        // setAlertErrorType("invalid");
        // console.log(updateContacts.data);
        setPhoneAddError("Something went wrong while saving your new phone..!");
      }
    } else {
      // console.log("Wrong OTP..!");
      setPhoneAddError("Wrong OTP...!");
    }
  }

  const startBulkDelete = async () => {

    setBulkDeleteSection(false);

    const query = {
      "idsToDel": showBulkDel.current,
      "email": user.email
    }
    const delteNow = await axios.post(serverUrl + "/events/deleteBulk", query);
    if (delteNow.status === 200) {

      setViewToast(true); popupAlert.play();
      setToastTitle("Successful");
      setToastBody("Deleted Successfully");
      setToastMessageType("success");

      setBulkDeleteSection(true);

      // setCustomAlertBox(true);
      // setAlertBoxMessages("Deleted Successfully");
      // setAlertErrorType("success");

      showBulkDel.current = [];

      setShowDeleteBtn(false)
      setShowDeleteBtnLength(showBulkDel.current.length);


      setAllEvents([]);
      setAllMeetings([]);
      const getEvents = await axios.post(serverUrl + "/events/getAll/", { "username": username });
      if (getEvents.status === 200) {
        // console.log(getEvents.data)
        setAllEvents(getEvents.data);
      }
      else {
        // console.log("error while fetching events");
      }


      const getAllMeetings = await axios.post(serverUrl + "/users/getAllMeetings", { "email": user.email });
      if (getAllMeetings.status === 200) {
        setAllMeetings(getAllMeetings.data);
      } else {
        // console.log("error while getting meetings");
      }
    } else {
      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody("Error While Deleting Events..!");
      setToastMessageType("invalid");


      setBulkDeleteSection(true);

      // setCustomAlertBox(true);
      // setAlertBoxMessages("Error While Deleting Events..!!");
      // setAlertErrorType("invalid");
    }
  }

  const cancelThisMeeting = async (id, eventId, origionalAdminOfEvent, meetingCategory, assignedRefreshToken) => {

    console.log(assignedRefreshToken)
    const load = document.querySelector("#loadingHereCancel");

    load.disabled = true;

    load.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>Loading..';



    const cancelThisMeeting = await axios.post(serverUrl + `/events/${meetingCategory === "Group" ? "cancelThisGroupEvent" : "cancelThisEvent"}`, { "uuid": id, "calenderId": "primary", "calenderEventId": alertCalenderEventId, "refresh_token": assignedRefreshToken, "eventId": eventId, "email": user.email, "origionalAdminOfEvent": origionalAdminOfEvent, "cancelPolicy": cancellationPolicy.current.value, "dateCreated": dayjs().format("h:mm:ssa - dddd, D MMMM YYYY") + " (" + user.settings.selectedTimezone?.label + ")" });

    if (cancelThisMeeting.status === 200) {

      setViewToast(true); popupAlert.play();
      setToastTitle("Successful");
      setToastBody("Cancelled Successfully");
      setToastMessageType("success");

      // setCustomAlertBox(true);
      // setAlertBoxMessages("Cancelled Successfully");
      // setAlertErrorType("success");

      const getAllMeetings = await axios.post(serverUrl + "/users/getAllMeetings", { "email": user.email });

      if (getAllMeetings.status === 200) {
        // console.log("cancelled");
        setAllMeetings(getAllMeetings.data);
        setCustomAlertBox(false);
        cancellationPolicy.current.value = "";
        load.disabled = false;
        load.innerHTML = 'Yes, Cancel';
      } else {
        // console.log("error while getting meetings");
        load.disabled = false;
        load.innerHTML = 'Yes, Cancel';
      }

    } else {
      // console.log(cancelThisMeeting.data);
      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody("Something went wrong while cancelling the event");
      setToastMessageType("invalid");

      // setCustomAlertBox(true);
      // setAlertBoxMessages("Something went wrong while cancelling the event.");
      // setAlertErrorType("invalid");

      load.disabled = false;
      load.innerHTML = 'Yes, Cancel';
    }
  }

  const deleteOneOffEvent = async (delId) => {
    const deldata = {
      "username": username,
      "delId": delId,
      "email": user.email
    }
    const delOneOffEvent = await axios.post(serverUrl + "/events/delete/", deldata);

    if (delOneOffEvent.status === 200) {
      // console.log("deleted succeessfully");

      const allSelections = document.getElementsByClassName("eventsTemplate");

      for (let i = 0; i < allSelections.length; i++) {
        allSelections[i].checked = false;
      }



      setBulkDeleteSection(true);
      showBulkDel.current = [];
      setShowDeleteBtn(false)
      setShowDeleteBtnLength(showBulkDel.current.length);

      setViewToast(true); popupAlert.play();
      setToastTitle("Successful");
      setToastBody("Event Deleted Successfully.");
      setToastMessageType("success");

      // setCustomAlertBox(true);
      // setAlertBoxMessages("Event Deleted Successfully.");
      // setAlertErrorType("success");


      const getEvents = await axios.post(serverUrl + "/events/getAll/", { "username": username });
      if (getEvents.status === 200) {
        // console.log(getEvents.data)
        setAllEvents(getEvents.data);
      }
      else {
        // console.log("error while fetching events");
      }

      const getAllMeetings = await axios.post(serverUrl + "/users/getAllMeetings", { "email": user.email });
      if (getAllMeetings.status === 200) {
        setAllMeetings(getAllMeetings.data);
      } else {
        // console.log("error while getting meetings");
      }

    } else {
      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody("Error while Deleting The Event.");
      setToastMessageType("invalid");

      // setCustomAlertBox(true);
      // setAlertBoxMessages("Error while Deleting The Event");
      // setAlertErrorType("invalid");
      // console.log("error while deleting");
    }
  }

  const nextPageToEvent = (e) => {
    e.preventDefault();
    if (summary.replaceAll(" ", "").length < 1) {
      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Event Name.");
      setToastMessageType("invalid");

      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Event Name");
    } else if (meetingType === "phone" && location.replaceAll(" ", "").length < 1) {
      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Phone Number");
      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Phone Number");
      setToastMessageType("invalid");

    } else if (meetingType === "address" && location.replaceAll(" ", "").length < 1) {
      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Address");

      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Address");
      setToastMessageType("invalid");
    }
    else if (description.replaceAll(" ", "").length < 1) {
      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Description");

      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Description");
      setToastMessageType("invalid");
    } else {
      setEventCreationSteps(2);
    }
  }

  const nextPageToRoundRobin = (e) => {
    e.preventDefault();
    if (roundRobinSummary.replaceAll(" ", "").length < 1) {
      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Event Name");

      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Event Name.");
      setToastMessageType("invalid");

    } else if (roundRobinMeetingType === "phone" && roundRobinLocation.replaceAll(" ", "").length < 1) {
      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Phone Number");

      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Phone Number.");
      setToastMessageType("invalid");
    } else if (roundRobinMeetingType === "address" && roundRobinLocation.replaceAll(" ", "").length < 1) {
      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Address");

      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Address.");
      setToastMessageType("invalid");
    }
    else if (roundRobinDescription.replaceAll(" ", "").length < 1) {
      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Description");

      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Description.");
      setToastMessageType("invalid");
    } else {
      setRoundRobinCreationSteps(2);
    }
  }

  const nextPageToGroupEvent = (e) => {
    e.preventDefault();
    if (groupSummary.replaceAll(" ", "").length < 1) {
      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Event Name");

      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Event Name.");
      setToastMessageType("invalid");

    } else if (groupMeetingType === "phone" && groupLocation.replaceAll(" ", "").length < 1) {
      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Phone Number");

      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Phone Number.");
      setToastMessageType("invalid");
    } else if (groupMeetingType === "address" && groupLocation.replaceAll(" ", "").length < 1) {
      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Address");

      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Address.");
      setToastMessageType("invalid");
    }
    else if (groupDescription.replaceAll(" ", "").length < 1) {
      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Description");

      setViewToast(true); popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Description.");
      setToastMessageType("invalid");
    } else {
      setGroupEventCreationSteps(2);
    }
  }

  const putOneOffDateToStart = (e) => {
    if (isNaN(e.target.innerHTML)) {
      // alert("not a number");
    }
    else {
      const vgh = document.getElementsByClassName("selectedDayTabOneOff");
      if (vgh.length) {
        for (let x = 0; x < vgh.length; x++) {
          vgh[x].classList.remove("selectedDayTabOneOff");
        }

        e.target.classList.add("selectedDayTabOneOff");
      }
      else {
        e.target.classList.add("selectedDayTabOneOff");
      }
      // console.log(e.target)
      const time = parseInt(e.target.id.replace("id_", ""));
      setOneOffDate(dayjs(time).format("D MMMM YYYY"))
    }
  }

  const checkCustomLinkAvailable = async (username) => {

    let lowercase = /[A-Za-z0-9-]/g;

    let final = username.target.value.replaceAll(" ", "-").match(lowercase)?.join("")

    setCustomEventLink(final);

    const query = {
      "username": final,
      "email": user.email
    }

    const check = await axios.post(serverUrl + "/users/checkCustomLink", query);

    if (check.status === 200) {
      setIsCustomLinkAvailable(true);
    } else {
      setIsCustomLinkAvailable(false);
    }
  }

  const checkThisTab = (data) => {
    // console.log(data)
    const tabs = document.querySelectorAll(".event-color-tab");
    for (let i = 0; i < tabs.length; i++) {
      tabs[i].classList.remove("checked-color");
    }
    data.classList.add("checked-color");
  }

  const createOneToOneMeeting = async (e) => {
    e.preventDefault();

    // console.log(location);

    const load = document.querySelector("#oneToOneCreationButton");
    load.disabled = true;
    load.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>Loading..';


    if (summary.replaceAll(" ", "").length < 1) {
      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Meeting Name");
      // setAlertErrorType("invalid");

      setViewToast(true);
      popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Meeting Name.");
      setToastMessageType("invalid");

      load.disabled = false;
      load.innerHTML = 'Finish';

    }
    else if (description.replaceAll(" ", "").length < 1) {
      setViewToast(true);
      popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Description.");
      setToastMessageType("invalid");

      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Description");
      // setAlertErrorType("invalid");

      load.disabled = false;
      load.innerHTML = 'Finish';
    }
    else if (meetingType === "phone" && location.replaceAll(" ", "").length < 8 && inboundCall) {
      setViewToast(true);
      popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Phone.");
      setToastMessageType("invalid");

      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Phone");
      // setAlertErrorType("invalid");

      load.disabled = false;
      load.innerHTML = 'Finish';
    }
    else if (meetingType === "address" && location.replaceAll(" ", "").length < 1) {
      setViewToast(true);
      popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Address.");
      setToastMessageType("invalid");

      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Address");
      // setAlertErrorType("invalid");

      load.disabled = false;
      load.innerHTML = 'Finish';
    }
    else {
      const eventData = {
        "id": customEventLink,
        "uuid": uuidv4(),
        "refreshToken": myRefreshToken,
        "username": username,
        "eventType": "One on One",
        "eventName": summary,
        "location": location,
        "meetingType": meetingType,
        "inbound": inboundCall,
        "description": description,
        "EventLink": "/" + username + "/" + customEventLink,
        "MaxInvitees": "50",
        "DisplaySpots": true,
        "attendees": [{ "type": "organizer", "name": username, "email": user.email, "phoneNumber": inboundCall ? location : "" }],
        "EventColor": colorId,
        "Duration": duration,
        "calendarIds": [],
        "availability": {
          "timezone": selectedTimezone,
          "timezoneUTC": selectedTimezone?.value.utcOffsetStr,
          "dateTimes": oneToOneDates,
          "userSchedules": {
            "identifier": userSchedules[scheduleName].identifier,
            "days": {
              "sunday": { "active": isSundayActive, "times": isSundayActive ? schedule.sunday.times : [] },
              "monday": { "active": isMondayActive, "times": isMondayActive ? schedule.monday.times : [] },
              "tuesday": { "active": isTuesdayActive, "times": isTuesdayActive ? schedule.tuesday.times : [] },
              "wednesday": { "active": isWednesdayActive, "times": isWednesdayActive ? schedule.wednesday.times : [] },
              "thursday": { "active": isThursdayActive, "times": isThursdayActive ? schedule.thursday.times : [] },
              "friday": { "active": isFridayActive, "times": isFridayActive ? schedule.friday.times : [] },
              "saturday": { "active": isSaturdayActive, "times": isSaturdayActive ? schedule.saturday.times : [] },
            },
            "dateOverrides": dateOverrides
          },
          "schedulabel": {
            "startDate": betweenDays[0].startDate,
            "endDate": infiniteDateRange ? null : betweenDays[0].endDate,
            "selection": straightRollingDays ? "straightRollingDays" : infiniteDateRange ? "infiniteDays" : "withinDateRange"
          }
        },
        "beforeEventTime": eventBeforeTime,
        "afterEventTime": eventAfterTime,
        "beforeEventTimeActive": eventBeforeTimeActive,
        "afterEventTimeActive": eventAfterTimeActive,
        "stopSchedule": eventAfterTime,
        "stopBookingBeforeTime": { "type": stopBookingTime[0], "value": stopBookingTime[1] },
        "inviteeQuestions": { "data": oneOnOneQuestions, "required": isDescriptionRequired },
        "secretEvent": secretEvent,
        "dateCreated": dayjs().format("h:mm:ssa - dddd, D MMMM YYYY") + " (" + user.settings.selectedTimezone?.label + ")",
        "lastEdited": dayjs().format("h:mm:ssa D MMM YYYY"),
        "created": dayjs().format("h:mm:ssa D MMM YYYY")
      };
      // console.log(eventData)
      const createOneToOne = await axios.post(serverUrl + "/events/create/onetoone", eventData);
      if (createOneToOne.status === 200) {
        // console.log("ok", createOneToOne.data);

        setViewToast(true);
        popupAlert.play();
        setToastTitle("Successful");
        setToastBody("Event Published Successfully.");
        setToastMessageType("success");


        setShowShareYourEventPanel(true);

        window.history.pushState(null, "", window.location.href.split("#")[0] + "#Meetings#eventTypes");

        // setCustomAlertBox(true);
        // setAlertBoxMessages("Event Published Successfully");
        // setAlertErrorType("success");

        // setMeetingsTabType("eventTypes");
        // setMeetingShow(true);
        // setOneOnOneEventCreation(false);

        // setEventCreationSteps(false);

        // setCustomHourView(false);

        // setSummary("");
        // setLocation("");
        // setDescription("");
        // setColorId("1");
        // setDuration(30);
        // setSelectedTimezone(selectedTimezoneSettings);
        // setMeetingType("meet");
        // setOneToOneDates({});
        // setEventBeforeTime(30);
        // setEventAfterTime(30);
        // setEventBeforeTimeActive(false);
        // setEventAfterTimeActive(false);
        // setBetweenDays([
        //   {
        //     startDate: new Date(),
        //     endDate: addDays(new Date(), 30),
        //     key: 'selection'
        //   }
        // ]);


        // setStraightRollingDays(true);
        // setWithinDateRange(false);
        // setInfiniteDateRange(false);

        // setCustomEventLink("");


        // setSecretEvent(false);

        // setStopBookingTime(["hours", 1]);
        // setCustomStopBookingView(false);
        // setIsDescriptionRequired(false);

        // setOneOnOneQuestions("Share anything that help us prepare for the meeting");



        const getEvents = await axios.post(serverUrl + "/events/getAll/", { "username": username });
        if (getEvents.status === 200) {
          // console.log(getEvents.data)
          setAllEvents(getEvents.data);
          setViewEventTypes(true);
        }
        else {
          // console.log("error while fetching events");
        }

        const getAllMeetings = await axios.post(serverUrl + "/users/getAllMeetings", { "email": user.email });
        if (getAllMeetings.status === 200) {
          setAllMeetings(getAllMeetings.data);
        } else {
          // console.log("error while getting meetings");
        }

        load.disabled = false;
        load.innerHTML = 'Finish';
      }
      else {
        // console.log("else", createOneToOne.data);
        setViewToast(true);
        popupAlert.play();
        setToastTitle("Error");
        setToastBody("Error while publishing Event..!");
        setToastMessageType("invalid");

        // setCustomAlertBox(true);
        // setAlertBoxMessages("Error while publishing Event..!!");
        // setAlertErrorType("invalid");

        load.disabled = false;
        load.innerHTML = 'Finish';
      }
    }
  }

  const convertToMinutesOneOnOne = (time) => {
    if (durationType === "hours") {
      setDuration(Math.floor(parseInt(time)) * 60);
      // console.log("1524 ", Math.floor(parseInt(time)) * 60);
    } else {
      setDuration(Math.floor(parseInt(time)));
      // console.log("1527 ", Math.floor(parseInt(time)));
    }
  }

  const createGroupMeeting = async (e) => {
    e.preventDefault();

    const load = document.querySelector("#groupCreationButton");
    load.disabled = true;
    load.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>Loading..';


    if (groupSummary.replaceAll(" ", "").length < 1) {
      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Meeting Name");
      // setAlertErrorType("invalid");

      setViewToast(true);
      popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Meeting Name.");
      setToastMessageType("invalid");

      load.disabled = false;
      load.innerHTML = 'Finish';
    }
    else if (groupDescription.replaceAll(" ", "").length < 1) {
      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Description");
      // setAlertErrorType("invalid");
      setViewToast(true);
      popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Description.");
      setToastMessageType("invalid");

      load.disabled = false;
      load.innerHTML = 'Finish';
    }
    else if (groupMeetingType === "phone" && groupLocation.replaceAll(" ", "").length < 8 && groupInboundCall) {
      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Phone");
      // setAlertErrorType("invalid");

      setViewToast(true);
      popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Phone.");
      setToastMessageType("invalid");

      load.disabled = false;
      load.innerHTML = 'Finish';
    }
    else if (groupMeetingType === "address" && groupLocation.replaceAll(" ", "").length < 1) {
      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Address");
      // setAlertErrorType("invalid");

      setViewToast(true);
      popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Address.");
      setToastMessageType("invalid");

      load.disabled = false;
      load.innerHTML = 'Finish';
    }
    else {
      const eventData = {
        "id": customEventLink,
        "uuid": uuidv4(),
        "refreshToken": myRefreshToken,
        "username": username,
        "eventType": "Group",
        "eventName": groupSummary,
        "location": groupLocation,
        "meetingType": groupMeetingType,
        "inbound": groupInboundCall,
        "description": groupDescription,
        "EventLink": "/" + username + "/" + customEventLink,
        "MaxInvitees": maxInvities,
        "DisplaySpots": maxInvitiesActive,
        "attendees": [{ "type": "organizer", "name": username, "email": user.email, "phoneNumber": groupInboundCall ? groupLocation : "" }],
        "EventColor": groupColorId,
        "Duration": groupDuration,
        "calendarIds": [],
        "availability": {
          "timezone": selectedTimezone,
          "timezoneUTC": selectedTimezone?.value.utcOffsetStr,
          "dateTimes": groupDates,
          "userSchedules": {
            "identifier": userSchedules[scheduleName].identifier,
            "days": {
              "sunday": { "active": isSundayActive, "times": isSundayActive ? schedule.sunday.times : [] },
              "monday": { "active": isMondayActive, "times": isMondayActive ? schedule.monday.times : [] },
              "tuesday": { "active": isTuesdayActive, "times": isTuesdayActive ? schedule.tuesday.times : [] },
              "wednesday": { "active": isWednesdayActive, "times": isWednesdayActive ? schedule.wednesday.times : [] },
              "thursday": { "active": isThursdayActive, "times": isThursdayActive ? schedule.thursday.times : [] },
              "friday": { "active": isFridayActive, "times": isFridayActive ? schedule.friday.times : [] },
              "saturday": { "active": isSaturdayActive, "times": isSaturdayActive ? schedule.saturday.times : [] },
            },
            "dateOverrides": dateOverrides
          },
          "schedulabel": {
            "startDate": betweenDays[0].startDate,
            "endDate": infiniteDateRange ? null : betweenDays[0].endDate,
            "selection": straightRollingDays ? "straightRollingDays" : infiniteDateRange ? "infiniteDays" : "withinDateRange"
          }
        },
        "beforeEventTime": eventGroupBeforeTime,
        "afterEventTime": eventGroupAfterTime,
        "beforeEventTimeActive": eventGroupBeforeTimeActive,
        "afterEventTimeActive": eventGroupAfterTimeActive,
        "stopSchedule": eventGroupAfterTime,
        "stopBookingBeforeTime": { "type": stopBookingTime[0], "value": stopBookingTime[1] },
        "inviteeQuestions": { "data": groupQuestions, "required": isDescriptionRequired },
        "secretEvent": groupSecretEvent,
        "dateCreated": dayjs().format("h:mma - dddd, D MMMM YYYY") + " (" + user.settings.selectedTimezone?.label + ")",
        "lastEdited": dayjs().format("h:mm:ssa D MMM YYYY"),
        "created": dayjs().format("h:mm:ssa D MMM YYYY")
      };
      // console.log(betweenDays[0])
      const createGroup = await axios.post(serverUrl + "/events/create/group", eventData);
      if (createGroup.status === 200) {
        // console.log("ok", createGroup.data);
        // setCustomAlertBox(true);
        // setAlertBoxMessages("Event Published Successfully");
        // setAlertErrorType("success");

        setViewToast(true);
        popupAlert.play();
        setToastTitle("Successful");
        setToastBody("Event Published Successfully.");
        setToastMessageType("success");


        setShowShareYourEventPanel(true);

        window.history.pushState(null, "", window.location.href.split("#")[0] + "#Meetings#eventTypes");

        // setMeetingsTabType("eventTypes");
        // setMeetingShow(true);
        // setGroupEventCreation(false);

        // setCustomHourView(false);

        // setEventCreationSteps(false);
        // setGroupEventCreationSteps(false);

        // setGroupSummary("");
        // setGroupLocation("");
        // setGroupMeetingType("meet");
        // setGroupDescription("");
        // setCustomEventLink("");
        // setMaxInvities(4);
        // setMaxInvitiesActive(false);
        // setGroupColorId('1');
        // setGroupDuration(30);
        // setSelectedTimezone(selectedTimezoneSettings);
        // setGroupDate("");
        // setEventGroupBeforeTime(30);
        // setEventGroupAfterTime(30);
        // setEventGroupAfterTimeActive(false);
        // setEventGroupBeforeTimeActive(false);

        // setGroupInboundCall(false);

        // setGroupSecretEvent(false);

        // setStopBookingTime(["hours", 1]);
        // setCustomStopBookingView(false);
        // setIsDescriptionRequired(false);

        // setGroupQuestions("Share anything that help us prepare for the meeting");

        // setBetweenDays([
        //   {
        //     startDate: new Date(),
        //     endDate: addDays(new Date(), 30),
        //     key: 'selection'
        //   }
        // ]);


        // setStraightRollingDays(true);
        // setWithinDateRange(false);
        // setInfiniteDateRange(false);

        const getEvents = await axios.post(serverUrl + "/events/getAll/", { "username": username });
        if (getEvents.status === 200) {
          // console.log(getEvents.data)
          setAllEvents(getEvents.data);
          setViewEventTypes(true);
        }
        else {
          // console.log("error while fetching events");
        }

        const getAllMeetings = await axios.post(serverUrl + "/users/getAllMeetings", { "email": user.email });
        if (getAllMeetings.status === 200) {
          setAllMeetings(getAllMeetings.data);
        } else {
          // console.log("error while getting meetings");
        }


        load.disabled = false;
        load.innerHTML = 'Finish';
      }
      else {
        // console.log("else", createGroup.data);
        // setCustomAlertBox(true);
        // setAlertBoxMessages("Something went wrong while creating Event.");
        // setAlertErrorType("invalid");

        setViewToast(true);
        popupAlert.play();
        setToastTitle("Error");
        setToastBody("Something went wrong while creating Event.");
        setToastMessageType("invalid");

        load.disabled = false;
        load.innerHTML = 'Finish';
      }
    }
  }

  const createRoundRobinMeeting = async (e) => {
    e.preventDefault();

    const load = document.querySelector("#roundRobinCreationButton");
    load.disabled = true;
    load.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>Loading..';


    if (roundRobinSummary.replaceAll(" ", "").length < 1) {
      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Meeting Name");
      // setAlertErrorType("invalid");

      setViewToast(true);
      popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Meeting Name.");
      setToastMessageType("invalid");

      load.disabled = false;
      load.innerHTML = 'Finish';
    }
    else if (roundRobinDescription.replaceAll(" ", "").length < 1) {
      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Description");
      // setAlertErrorType("invalid");

      setViewToast(true);
      popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Description.");
      setToastMessageType("invalid");

      load.disabled = false;
      load.innerHTML = 'Finish';
    }
    else if (roundRobinMeetingType === "phone" && roundRobinLocation.replaceAll(" ", "").length < 8 && inboundCall) {
      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Phone");
      // setAlertErrorType("invalid");

      setViewToast(true);
      popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Phone.");
      setToastMessageType("invalid");

      load.disabled = false;
      load.innerHTML = 'Finish';
    }
    else if (roundRobinMeetingType === "address" && roundRobinLocation.replaceAll(" ", "").length < 1) {
      // setCustomAlertBox(true);
      // setAlertBoxMessages("Invalid Address");
      // setAlertErrorType("invalid");

      setViewToast(true);
      popupAlert.play();
      setToastTitle("Error");
      setToastBody("Invalid Address.");
      setToastMessageType("invalid");

      load.disabled = false;
      load.innerHTML = 'Finish';
    }
    else {
      const eventData = {
        "id": customEventLink,
        "uuid": uuidv4(),
        "refreshToken": myRefreshToken,
        "username": username,
        "eventType": "Round Robin",
        "eventName": roundRobinSummary,
        "location": roundRobinLocation,
        "meetingType": roundRobinMeetingType,
        "inbound": inboundCall,
        "description": roundRobinDescription,
        "EventLink": "/" + username + "/" + customEventLink,
        "MaxInvitees": "50",
        "DisplaySpots": true,
        "attendees": [{ "type": "organizer", "name": username, "email": user.email, "phoneNumber": inboundCall ? roundRobinLocation : "" }],
        "EventColor": roundRobinColorId,
        "Duration": roundRobinDuration,
        "calendarIds": [],
        "availability": {
          "timezone": selectedTimezone,
          "timezoneUTC": selectedTimezone?.value.utcOffsetStr,
          "dateTimes": roundRobinDates,
          "userSchedules": {
            "identifier": userSchedules[scheduleName].identifier,
            "days": {
              "sunday": { "active": isSundayActive, "times": isSundayActive ? schedule.sunday.times : [] },
              "monday": { "active": isMondayActive, "times": isMondayActive ? schedule.monday.times : [] },
              "tuesday": { "active": isTuesdayActive, "times": isTuesdayActive ? schedule.tuesday.times : [] },
              "wednesday": { "active": isWednesdayActive, "times": isWednesdayActive ? schedule.wednesday.times : [] },
              "thursday": { "active": isThursdayActive, "times": isThursdayActive ? schedule.thursday.times : [] },
              "friday": { "active": isFridayActive, "times": isFridayActive ? schedule.friday.times : [] },
              "saturday": { "active": isSaturdayActive, "times": isSaturdayActive ? schedule.saturday.times : [] },
            },
            "dateOverrides": dateOverrides
          },
          "schedulabel": {
            "startDate": betweenDays[0].startDate,
            "endDate": infiniteDateRange ? null : betweenDays[0].endDate,
            "selection": straightRollingDays ? "straightRollingDays" : infiniteDateRange ? "infiniteDays" : "withinDateRange"
          },
        },
        "beforeEventTime": eventBeforeTime,
        "afterEventTime": eventAfterTime,
        "beforeEventTimeActive": eventBeforeTimeActive,
        "afterEventTimeActive": eventAfterTimeActive,
        "stopSchedule": eventAfterTime,
        "stopBookingBeforeTime": { "type": stopBookingTime[0], "value": stopBookingTime[1] },
        "inviteeQuestions": { "data": roundRobinQuestions, "required": isDescriptionRequired },
        "secretEvent": secretEvent,
        "dateCreated": dayjs().format("h:mm:ssa - dddd, D MMMM YYYY") + " (" + user.settings.selectedTimezone?.label + ")",
        "lastEdited": dayjs().format("h:mm:ssa D MMM YYYY"),
        "created": dayjs().format("h:mm:ssa D MMM YYYY")
      };
      // console.log(betweenDays[0])
      const createRoundRobin = await axios.post(serverUrl + "/events/create/onetoone", eventData);
      if (createRoundRobin.status === 200) {
        // console.log("ok", createRoundRobin.data);
        // setCustomAlertBox(true);
        // setAlertBoxMessages("Event Published Successfully");
        // setAlertErrorType("success");


        setViewToast(true);
        popupAlert.play();
        setToastTitle("Successful");
        setToastBody("Event Published Successfully.");
        setToastMessageType("success");


        setShowShareYourEventPanel(true);

        window.history.pushState(null, "", window.location.href.split("#")[0] + "#Meetings#eventTypes");

        // setMeetingsTabType("eventTypes");
        // setMeetingShow(true);
        // setRoundRobinEventCreation(false);

        // setRoundRobinCreationSteps(false);

        // setInboundCall(false);

        // setCustomHourView(false);

        // setRoundRobinMeetingType("meet");
        // setRoundRobinSummary("");
        // setRoundRobinLocation("");
        // setRoundRobinDescription("");
        // setRoundRobinColorId("1");
        // setRoundRobinDuration(30);
        // setSelectedTimezone(selectedTimezoneSettings);
        // setRoundRobinDates({});
        // setEventBeforeTime(30);
        // setEventAfterTime(30);
        // setEventBeforeTimeActive(false);
        // setEventAfterTimeActive(false);


        // setCustomEventLink("");

        // setSecretEvent(false);

        // setStopBookingTime(["hours", 1]);

        // setCustomStopBookingView(false);
        // setIsDescriptionRequired(false);

        // setOneOnOneQuestions("Share anything that help us prepare for the meeting");

        const getEvents = await axios.post(serverUrl + "/events/getAll/", { "username": username });
        if (getEvents.status === 200) {
          // console.log(getEvents.data)
          setAllEvents(getEvents.data);
          setViewEventTypes(true);
          window.history.pushState(null, "", window.location.href.split("#")[0] + "#Meetings#eventTypes");
        }
        else {
          // console.log("error while fetching events");
        }

        const getAllMeetings = await axios.post(serverUrl + "/users/getAllMeetings", { "email": user.email });
        if (getAllMeetings.status === 200) {
          setAllMeetings(getAllMeetings.data);
        } else {
          // console.log("error while getting meetings");
        }

        load.disabled = false;
        load.innerHTML = 'Finish';
      }
      else {
        // console.log("else", createRoundRobin.data);
        // setCustomAlertBox(true);
        // setAlertBoxMessages("Error while publishing Event..!!");
        // setAlertErrorType("invalid");

        setViewToast(true);
        popupAlert.play();
        setToastTitle("Error");
        setToastBody("Error while publishing Event..!");
        setToastMessageType("invalid");

        load.disabled = false;
        load.innerHTML = 'Finish';
      }
    }
  }

  const convertToMinutesGroup = (time) => {
    if (durationType === "hours") {
      setGroupDuration(Math.floor(parseInt(time)) * 60);
      // console.log("1514 ", Math.floor(parseInt(time)) * 60);
    } else {
      setGroupDuration(Math.floor(parseInt(time)));
      // console.log("1517 ", Math.floor(parseInt(time)));
    }
  }

  const convertToMinutesRoundRobin = (time) => {
    if (durationType === "hours") {
      setRoundRobinDuration(Math.floor(parseInt(time)) * 60);
      // console.log("1524 ", Math.floor(parseInt(time)) * 60);
    } else {
      setRoundRobinDuration(Math.floor(parseInt(time)));
      // console.log("1527 ", Math.floor(parseInt(time)));
    }
  }

  useEffect(() => {
    // pageTurnSound.play();
  }, [
    availabilityShow,
    meetingsShow,
    viewNewEventCreate,
    oneOnOneEventCreation,
    groupEventCreation,
    roundRobinEventCreation,
    alertShow,
    contactShow,
  ])

  useEffect(() => {
    let list = [];
    let listSchedules = [];
    if (phoneList) {
      Object.keys(phoneList).map((name, key) => {
        if (phoneList[name].active) {
          return list.push({ label: name, value: name })
        }
      })
      setOptionListForPhone(list)
    }

    if (userSchedules) {

      Object.keys(userSchedules).map((name, key) => {
        return listSchedules.push({ label: name, value: name })
      })
      setUserScheduleListForSelect(listSchedules);
    }

  }, [phoneList, userSchedules])

  const resetAllMeetingDetails = () => {
    //############## One on One reset #####################

    setSummary("");
    setLocation("meet");
    setMeetingType("meet");
    setDescription("");
    setInboundCall(false);
    setColorId("1");
    setDuration(30);
    setSelectedTimezone(selectedTimezoneSettings);
    setOneToOneDates({});
    setEventBeforeTime(30);
    setEventAfterTime(30);
    setEventBeforeTimeActive(false);
    setEventAfterTimeActive(false);
    setBetweenDays([
      {
        startDate: new Date(),
        endDate: addDays(new Date(), 30),
        key: 'selection'
      }
    ]);

    setStraightRollingDays(true);
    setWithinDateRange(false);
    setInfiniteDateRange(false);

    setCustomEventLink("");
    setSecretEvent(false);
    setStopBookingTime(["hours", 1]);
    setCustomStopBookingView(false);
    setIsDescriptionRequired(false);
    setOneOnOneQuestions("Share anything that help us prepare for the meeting");


    //############## Group reset #####################

    setGroupSummary("");
    setGroupLocation("meet");
    setGroupMeetingType("meet");
    setGroupDescription("");
    setMaxInvities(4);
    setMaxInvitiesActive(false);
    setGroupColorId('1');
    setGroupDuration(30);
    setCustomEventLink("");
    setGroupDate("");
    setEventGroupBeforeTime(30);
    setEventGroupAfterTime(30);
    setEventGroupAfterTimeActive(false);
    setEventGroupBeforeTimeActive(false);
    setGroupInboundCall(false);
    setGroupSecretEvent(false);
    setGroupQuestions("Share anything that help us prepare for the meeting");

    //############## round robin reset #####################

    setRoundRobinEventCreation(false);
    setRoundRobinCreationSteps(false);

    setRoundRobinSummary("");
    setRoundRobinLocation("meet");
    setRoundRobinMeetingType("meet");
    setRoundRobinDescription("");
    setRoundRobinColorId("1");
    setRoundRobinDuration(30);
    setRoundRobinDates({});
    setRoundRobinDates({});

    setRoundRobinQuestions("Share anything that help us prepare for the meeting");
  }

  function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  }

  async function canvasPreview(
    image,
    canvas,
    crop,
    scale = 1,
    rotate = 0,
    flipHorizontal,
    flipVertical
  ) {
    const ctx = canvas.getContext('2d')

    if (!ctx) {
      throw new Error('No 2d context')
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio
    // const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = 'high'

    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY

    const rotateRads = rotate * TO_RADIANS
    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2

    ctx.save()
    // ctx.setTransform(
    //     flipHorizontal ? -1 : 1, 0, // set the direction of x axis
    //     0, flipVertical ? -1 : 1,   // set the direction of y axis
    //     0 + (flipHorizontal ? centerX : 0), // set the x origin
    //     0 + (flipVertical ? centerY : 0) // set the y origin
    // );

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY)
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY)
    // 3) Rotate around the origin
    ctx.rotate(rotateRads)
    // 2) Scale the image
    ctx.scale(flipHorizontal ? -scale : scale, flipVertical ? -scale : scale)
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY)
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
    )

    ctx.restore()
  }

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      if ((e.target.files[0].size < 2048000)) {

        setCropperFileType(e.target.files[0].type);

        setCrop(undefined) // Makes crop preview update between images.
        setImageSizeError(null);
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          setImgSrc(reader.result?.toString() || '');
          // console.log(reader.result?.toString())
        }
        )
        reader.readAsDataURL(e.target.files[0])
      } else {
        setImgSrc('');
        setImageSizeError("File exceded the max limit of 2 Megabyte.");
      }
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useEffect(() => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      // We use canvasPreview as it's much faster than imgPreview.
      canvasPreview(
        imgRef.current,
        previewCanvasRef.current,
        completedCrop,
        scale,
        rotate,
        flipHorizontal,
        flipVertical
      )
    }
  }, [completedCrop, scale, rotate, flipHorizontal, flipVertical])

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined)
    } else if (imgRef.current) {
      const { width, height } = imgRef.current
      setAspect(1 / 1)
      setCrop(centerAspectCrop(width, height, 1 / 1))
    }
  }

  const handleRotateValue = (e) => {
    if (e.nativeEvent.inputType != "deleteContentBackward") {
      const pattern = /[0123456789.]/;
      if (pattern.test(e.nativeEvent.data)) {
        setRotate(e.target.value);
      } else {
        // console.log("prevent")
        e.preventDefault();
      }
    } else {
      setRotate(e.target.value.slice(0))
    }
  }

  const updateBrandLogo = async (e) => {
    e.preventDefault();

    const load = document.querySelector("#brandsUpdateButton");
    load.disabled = true;
    load.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>Loading..';


    if (!previewCanvasRef.current) {
      load.disabled = false;
      load.innerHTML = 'Set Image';
    }
    else {
      let dataUrl = previewCanvasRef.current.toDataURL(cropperFileType, cropperFileType === "image/png" ? 0.05 : 0.1);
      setNewBrandLogoPreview(dataUrl);

      if (dataUrl != null) {
        const query = {
          "adminEmail": user.email,
          "brandName": newBrandName,
          "brandLogo": dataUrl,
          "active": true,
          "uuid": uuidv4()
        }

        const updateBrandLogo = await axios.post(serverUrl + "/users/updateBrandLogo/", query);

        if (updateBrandLogo.status === 200) {
          // console.log(updateBrandLogo.data)
          if (updateBrandLogo.data.length > 0) {
            setCalendaryBranding(true);
            const branding = {
              "adminEmail": user.email,
              "brandNow": false
            }
            const updateBranding = await axios.post(serverUrl + "/users/updateCalendaryBranding/", branding);
            if (updateBranding.status === 200) {
              load.disabled = false;
              load.innerHTML = 'Set Image';

              // console.log(updateBranding.data);
              setCalendaryBranding(false);
              setViewToast(true);
              popupAlert.play();
              setToastTitle("Successful");
              setToastBody("Brand Changed.");
              setToastMessageType("success");
            } else {
              load.disabled = false;
              load.innerHTML = 'Set Image';

              setViewToast(true);
              popupAlert.play();
              setToastTitle("Error");
              setToastBody(updateBranding.data);
              setToastMessageType("invalid");
            }
          } else {
            setCalendaryBranding(false);
            const branding = {
              "adminEmail": user.email,
              "brandNow": false
            }
            const updateBranding = await axios.post(serverUrl + "/users/updateCalendaryBranding/", branding);
            if (updateBranding.status === 200) {

              load.disabled = false;
              load.innerHTML = 'Set Image';

              // console.log(updateBranding.data);
              setCalendaryBranding(false);
              setViewToast(true);
              popupAlert.play();
              setToastTitle("Successful");
              setToastBody("Brand Changed.");
              setToastMessageType("success");
            } else {
              load.disabled = false;
              load.innerHTML = 'Set Image';

              setViewToast(true);
              popupAlert.play();
              setToastTitle("Error");
              setToastBody(updateBranding.data);
              setToastMessageType("invalid");
            }
          }
          setAllBrandsLogos(updateBrandLogo.data);

          setStartCropperForBrand(false);
          setRotate(0);
          setScale(1);
          setFlipHorizontal(false);
          setFlipVertical(false);
          setCompletedCrop(null);
          setAspect(true);
          setImgSrc('');
          setBrandNameAvailable(false);
          setNewBrandName(null);

          setViewToast(true);
          popupAlert.play();
          setToastTitle("Successful");
          setToastBody("Logo saved.");
          setToastMessageType("success");
        } else {
          load.disabled = false;
          load.innerHTML = 'Set Image';

          setViewToast(true);
          popupAlert.play();
          setToastTitle("Error");
          setToastBody(updateBrandLogo.data);
          setToastMessageType("invalid");
        }
      } else {
        load.disabled = false;
        load.innerHTML = 'Set Image';


        setViewToast(true);
        popupAlert.play();
        setToastTitle("Error");
        setToastBody("Invalid Brand Logo");
        setToastMessageType("invalid");
      }
    }
  }

  const updateProfilePicture = async (e) => {
    e.preventDefault();

    const load = document.querySelector("#profileUpdateButton");
    load.disabled = true;
    load.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>Loading..';

    if (!previewCanvasRef.current) {

      load.disabled = false;
      load.innerHTML = 'Set Image';
    }
    else {
      let dataUrl = previewCanvasRef.current.toDataURL(cropperFileType, cropperFileType === "image/png" ? 0.05 : 0.1);

      if (dataUrl != null) {
        const query = {
          "adminEmail": user.email,
          "image": dataUrl,
          "uuid": uuidv4(),
          "active": true,
          "type": "custom"
        }

        const updateProfilePicture = await axios.post(serverUrl + "/users/updateProfilePicture/", query);
        if (updateProfilePicture.status === 200) {

          load.disabled = false;
          load.innerHTML = 'Set Image';

          setCurrentGlobalProfile(updateProfilePicture.data.image);
          setGlobalProfileType(updateProfilePicture.data.type);

          setViewImageUploader(false);
          setRotate(0);
          setScale(1);
          setFlipHorizontal(false);
          setFlipVertical(false);
          setCompletedCrop(null);
          setAspect(true);
          setImgSrc('');

          setViewToast(true);
          popupAlert.play();
          setToastTitle("Successful");
          setToastBody("Profile picture saved.");
          setToastMessageType("success");
        } else {
          load.disabled = false;
          load.innerHTML = 'Set Image';

          setViewToast(true);
          popupAlert.play();
          setToastTitle("Error");
          setToastBody(updateProfilePicture.data);
          setToastMessageType("invalid");
        }
      } else {
        load.disabled = false;
        load.innerHTML = 'Set Image';

        setViewToast(true);
        popupAlert.play();
        setToastTitle("Error");
        setToastBody("Invalid Profile Picture");
        setToastMessageType("invalid");
      }
    }
  }

  const deleteProfilePicture = async () => {
    const query = {
      "adminEmail": user.email,
      "image": manImage,
      "uuid": uuidv4(),
      "active": true,
      "type": "deleted"
    }
    const deleteImage = await axios.post(serverUrl + "/users/deleteProfilePicture/", query);

    if (deleteImage.status === 200) {
      setCurrentGlobalProfile(deleteImage.data.image);
      setGlobalProfileType(deleteImage.data.type);

      setViewToast(true);
      popupAlert.play();
      setToastTitle("Successful");
      setToastBody("Profile picture saved.");
      setToastMessageType("success");
    } else {
      setViewToast(true);
      popupAlert.play();
      setToastTitle("Error");
      setToastBody(deleteImage.data);
      setToastMessageType("invalid");
    }
  }

  const turnBrandActivness = async (uid) => {
    const query = {
      "adminEmail": user.email,
      "uuid": uid,
    }
    const switchBrandLogoActive = await axios.post(serverUrl + "/users/switchBrandActive", query);
    if (switchBrandLogoActive.status === 200) {
      let have = false;

      switchBrandLogoActive.data.map((val, key) => {
        if (val.active === true) {
          return have = true;
        }
      })

      if (have) {
        setCalendaryBranding(false);

        const branding = {
          "adminEmail": user.email,
          "brandNow": false
        }
        const updateBranding = await axios.post(serverUrl + "/users/updateCalendaryBranding/", branding);
        if (updateBranding.status === 200) {
          // console.log(updateBranding.data);
          setCalendaryBranding(false);
          setViewToast(true);
          popupAlert.play();
          setToastTitle("Successful");
          setToastBody("Brand Changed.");
          setToastMessageType("success");
        } else {
          setViewToast(true);
          popupAlert.play();
          setToastTitle("Error");
          setToastBody(updateBranding.data);
          setToastMessageType("invalid");
        }
      } else {
        setCalendaryBranding(true);
        const branding = {
          "adminEmail": user.email,
          "brandNow": true
        }
        const updateBranding = await axios.post(serverUrl + "/users/updateCalendaryBranding/", branding);
        if (updateBranding.status === 200) {
          // console.log(updateBranding.data);
          setCalendaryBranding(true);
          setViewToast(true);
          popupAlert.play();
          setToastTitle("Successful");
          setToastBody("Brand Changed.");
          setToastMessageType("success");
        } else {
          setViewToast(true);
          popupAlert.play();
          setToastTitle("Error");
          setToastBody(updateBranding.data);
          setToastMessageType("invalid");
        }
      }

      setAllBrandsLogos(switchBrandLogoActive.data)

      setViewToast(true);
      popupAlert.play();
      setToastTitle("Successful");
      setToastBody("Brand Logo Updated.");
      setToastMessageType("success");
    } else {
      setViewToast(true);
      popupAlert.play();
      setToastTitle("Error");
      setToastBody(switchBrandLogoActive.data);
      setToastMessageType("invalid");
    }
  }

  const deleteBrandLogo = async (uid) => {
    const query = {
      "adminEmail": user.email,
      "uuid": uid,
    }
    const deleteLogo = await axios.post(serverUrl + "/users/deleteBrandLogo", query);
    if (deleteLogo.status === 200) {
      if (deleteLogo.data.length > 0) {
        let have = false;
        deleteLogo.data.map((val, key) => {
          if (val.active === true) {
            return have = true;
          }
        })
        if (have) {
          setCalendaryBranding(false);
          const branding = {
            "adminEmail": user.email,
            "brandNow": false
          }
          const updateBranding = await axios.post(serverUrl + "/users/updateCalendaryBranding/", branding);
          if (updateBranding.status === 200) {
            // console.log(updateBranding.data);
            setViewToast(true);
            popupAlert.play();
            setToastTitle("Successful");
            setToastBody("Brand Changed.");
            setToastMessageType("success");
          } else {
            setViewToast(true);
            popupAlert.play();
            setToastTitle("Error");
            setToastBody(updateBranding.data);
            setToastMessageType("invalid");
          }
        } else {
          setCalendaryBranding(true);
          const branding = {
            "adminEmail": user.email,
            "brandNow": true
          }
          const updateBranding = await axios.post(serverUrl + "/users/updateCalendaryBranding/", branding);
          if (updateBranding.status === 200) {
            // console.log(updateBranding.data);
            setViewToast(true);
            popupAlert.play();
            setToastTitle("Successful");
            setToastBody("Brand Changed.");
            setToastMessageType("success");
          } else {
            setViewToast(true);
            popupAlert.play();
            setToastTitle("Error");
            setToastBody(updateBranding.data);
            setToastMessageType("invalid");
          }
        }
      } else {
        setCalendaryBranding(true);
        const branding = {
          "adminEmail": user.email,
          "brandNow": true
        }
        const updateBranding = await axios.post(serverUrl + "/users/updateCalendaryBranding/", branding);
        if (updateBranding.status === 200) {
          // console.log(updateBranding.data);
          setViewToast(true);
          popupAlert.play();
          setToastTitle("Successful");
          setToastBody("Brand Changed.");
          setToastMessageType("success");
        } else {
          setViewToast(true);
          popupAlert.play();
          setToastTitle("Error");
          setToastBody(updateBranding.data);
          setToastMessageType("invalid");
        }
      }

      setAllBrandsLogos(deleteLogo.data);

      setStartCropperForBrand(false);
      setRotate(0);
      setScale(1);
      setFlipHorizontal(false);
      setFlipVertical(false);
      setCompletedCrop(null);
      setAspect(true);
      setImgSrc('');
      setBrandNameAvailable(false);
      setNewBrandName(null);

      setViewToast(true);
      popupAlert.play();
      setToastTitle("Successful");
      setToastBody("Brand Logo Deleted.");
      setToastMessageType("success");
    } else {
      setViewToast(true);
      popupAlert.play();
      setToastTitle("Error");
      setToastBody(deleteLogo.data);
      setToastMessageType("invalid");
    }
  }

  const resetImageCropperSettings = () => {
    setStartCropperForBrand(false);
    setRotate(0);
    setScale(1);
    setFlipHorizontal(false);
    setFlipVertical(false);
    setCompletedCrop(null);
    setAspect(true);
    setImgSrc('');
    setBrandNameAvailable(false);
    setNewBrandName(null);
  }

  const swtichBrandingToggle = async (state) => {
    const branding = {
      "adminEmail": user.email,
      "brandNow": state
    }

    if (allBrandsLogos.length > 0) {
      const updateBranding = await axios.post(serverUrl + "/users/updateCalendaryBranding/", branding);
      if (updateBranding.status === 200) {
        // console.log(updateBranding.data);
        setCalendaryBranding(state);

        setViewToast(true);
        popupAlert.play();
        setToastTitle("Successful");
        setToastBody("Brand Changed.");
        setToastMessageType("success");
      } else {
        setViewToast(true);
        popupAlert.play();
        setToastTitle("Error");
        setToastBody(updateBranding.data);
        setToastMessageType("invalid");
      }
    } else {
      setStartCropperForBrand(true);
    }
  }

  useEffect(() => {
    if (!calendaryBranding && allBrandsLogos.length) {

      async function gotIt() {
        const query = {
          "adminEmail": user.email,
          "uuid": allBrandsLogos[0].uuid,
        }
        const switchBrandLogoActive = await axios.post(serverUrl + "/users/switchPrimaryIfAllInactive", query);
        if (switchBrandLogoActive.status === 200) {
          let have = false;

          switchBrandLogoActive.data.map((val, key) => {
            if (val.active === true) {
              return have = true;
            }
          })

          if (have) {
            const branding = {
              "adminEmail": user.email,
              "brandNow": false
            }
            const updateBranding = await axios.post(serverUrl + "/users/updateCalendaryBranding/", branding);
            if (updateBranding.status === 200) {
              // console.log(updateBranding.data);
              setCalendaryBranding(false);
              setViewToast(true);
              popupAlert.play();
              setToastTitle("Successful");
              setToastBody("Brand Changed.");
              setToastMessageType("success");
            } else {
              setViewToast(true);
              popupAlert.play();
              setToastTitle("Error");
              setToastBody(updateBranding.data);
              setToastMessageType("invalid");
            }
          } else {
            const branding = {
              "adminEmail": user.email,
              "brandNow": true
            }
            const updateBranding = await axios.post(serverUrl + "/users/updateCalendaryBranding/", branding);
            if (updateBranding.status === 200) {
              // console.log(updateBranding.data);
              setCalendaryBranding(true);
              setViewToast(true);
              popupAlert.play();
              setToastTitle("Successful");
              setToastBody("Brand Changed.");
              setToastMessageType("success");
            } else {
              setViewToast(true);
              popupAlert.play();
              setToastTitle("Error");
              setToastBody(updateBranding.data);
              setToastMessageType("invalid");
            }
          }

          setAllBrandsLogos(switchBrandLogoActive.data)

          setStartCropperForBrand(false);
          setRotate(0);
          setScale(1);
          setFlipHorizontal(false);
          setFlipVertical(false);
          setCompletedCrop(null);
          setAspect(true);
          setImgSrc('');
          setBrandNameAvailable(false);
          setNewBrandName(null);

          setViewToast(true);
          popupAlert.play();
          setToastTitle("Successful");
          setToastBody("Brand Logo Updated.");
          setToastMessageType("success");
        } else {
          setViewToast(true);
          popupAlert.play();
          setToastTitle("Error");
          setToastBody(switchBrandLogoActive.data);
          setToastMessageType("invalid");
        }
      }
      gotIt();
    }
  }, [calendaryBranding]);


  const setUpToStep1 = () => {
    welcomeText1.current.style.opacity = 0;
    setTimeout(() => {
      welcomeText1.current.style.display = "none";
      welcomeText1.current.classList.remove("d-flex");
      welcomeText2.current.style.display = "block";
      progressBars.current.style.display = "flex";

      welcomeText2.current.style.opacity = 1;
      progressBars.current.style.opacity = 1;
      setStepOnWay(1);
    }, 1000)
  }
  const setUpToStep2 = () => {
    welcomeText2.current.style.opacity = 0;
    setTimeout(() => {
      welcomeText2.current.style.display = "none";
      welcomeText3.current.style.display = "block";
      welcomeText3.current.style.opacity = 1;
      setStepOnWay(2);
    }, 1000);
  }
  const setUpToStep3 = () => {
    welcomeText3.current.style.opacity = 0;
    setTimeout(() => {
      welcomeText3.current.style.display = "none";
      welcomeText4.current.style.display = "block";
      welcomeText4.current.style.opacity = 1;
      setStepOnWay(3);
    }, 1000);
  }
  const setUpToStep4 = () => {
    welcomeText4.current.style.opacity = 0;
    setTimeout(() => {
      welcomeText4.current.style.display = "none";
      welcomeText5.current.style.display = "block";
      welcomeText5.current.style.opacity = 1;
      setStepOnWay(4);
    }, 1000);
  }
  const setUpToStep5 = () => {
    welcomeText5.current.style.opacity = 0;
    setTimeout(() => {
      welcomeText5.current.style.display = "none";
      welcomeText6.current.style.display = "block";
      welcomeText6.current.style.opacity = 1;
      setStepOnWay(5);
    }, 1000);
  }

  const backtoStep1 = () => {
    welcomeText3.current.style.opacity = 0;
    setTimeout(() => {
      welcomeText3.current.style.display = "none";
      welcomeText2.current.style.display = "block";
      welcomeText2.current.style.opacity = 1;
      setStepOnWay(1);
    }, 1000);
  }
  const backtoStep2 = () => {
    welcomeText4.current.style.opacity = 0;
    setTimeout(() => {
      welcomeText4.current.style.display = "none";
      welcomeText3.current.style.display = "block";
      welcomeText3.current.style.opacity = 1;
      setStepOnWay(2);
    }, 1000);
  }
  const backtoStep3 = () => {
    welcomeText5.current.style.opacity = 0;
    setTimeout(() => {
      welcomeText5.current.style.display = "none";
      welcomeText4.current.style.display = "block";
      welcomeText4.current.style.opacity = 1;
      setStepOnWay(3);
    }, 1000);
  }
  const backtoStep4 = () => {
    welcomeText6.current.style.opacity = 0;
    setTimeout(() => {
      welcomeText6.current.style.display = "none";
      welcomeText5.current.style.display = "block";
      welcomeText5.current.style.opacity = 1;
      setStepOnWay(4);
    }, 1000);
  }
  const finalStep = async () => {
    const adminEmail = user.email;

    const updateAccountSetupState = await axios.post(serverUrl + "/users/accountSetupComplete", { "adminEmail": adminEmail });
    if (updateAccountSetupState.status === 200) {
      welcomeText6.current.style.opacity = 0;
      progressBars.current.style.opacity = 0;
      setTimeout(() => {
        welcomeText6.current.style.display = "none";
        setNeedAccountSetup(false);
        // window.history.pushState(null, '', window.location.href.split("#")[0] + "#AccountSettings#Profile");
        // setViewAdminPage(true);
      }, 1000);
    } else {
      setViewToast(true);
      popupAlert.play();
      setToastTitle("Error");
      setToastBody(updateAccountSetupState.data);
      setToastMessageType("invalid");
    }
  }

  const saveNewNameFromSignUp = async () => {
    const query = {
      "adminEmail": user.email,
      "name": name
    }
    const nameUpdateFromSignUp = await axios.post(serverUrl + "/users/nameUpdateFromSignUp", query);

    if (nameUpdateFromSignUp.status === 200) {
      setViewToast(true);
      popupAlert.play();
      setToastTitle("Successful");
      setToastBody("Name Updated.");
      setToastMessageType("success");
    } else {
      setViewToast(true);
      popupAlert.play();
      setToastTitle("Error");
      setToastBody(nameUpdateFromSignUp.data);
      setToastMessageType("invalid");
    }
  }
  const passwordValidation = (field) => {
    let lowercase = /[abcdefghijklmnopqrstuvwxyz]/;
    let uppercase = /[ABCDEFGHIJKLMNOPQRSTUVWXYZ]/;
    let numbers = /[0123456789]/;
    let specials = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let mismatch = false;

    if (lowercase.test(field)) {
      setLowercaseAvailable(true);
    } else {
      setLowercaseAvailable(false);
    }
    if (uppercase.test(field)) {
      setUppercaseAvailable(true);
    } else {
      setUppercaseAvailable(false);
    }
    if (numbers.test(field)) {
      setNumbersAvailable(true);
    } else {
      setNumbersAvailable(false);
    }
    if (specials.test(field)) {
      setSpecialCharsAvailable(true);
    } else {
      setSpecialCharsAvailable(false);
    }

    if (field.length >= 8) {
      setMinimumCharsAvailable(true);
    } else {
      setMinimumCharsAvailable(false);
    }

    if (newPasswordField.current.value === newPasswordFieldVarified.current.value) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  }
  const saveNewPassFromSignUp = async (e) => {
    const query = {
      "email": user.email,
      "password": newPass
    }

    const saveNewPassFromSignUp = await axios.post(serverUrl + "/users/UpdatePassword", query);

    if (saveNewPassFromSignUp.status === 200) {
      setViewToast(true);
      popupAlert.play();
      setToastTitle("Successful");
      setToastBody("Password Saved.");
      setToastMessageType("success");
    } else {
      setViewToast(true);
      popupAlert.play();
      setToastTitle("Error");
      setToastBody(saveNewPassFromSignUp.data);
      setToastMessageType("invalid");
    }
  }
  const updateBasicSettingsFromSignUp = async () => {
    const query = {
      "adminEmail": user.email,
      "welcome": welcome,
      "timeFormat": timeFormat,
      "country": country,
      "selectedTimezone": selectedTimezoneSettings,
    }

    const updateBasicSettingsFromSignUp = await axios.post(serverUrl + "/users/updateBasicSettingsFromSignUp", query);

    if (updateBasicSettingsFromSignUp.status === 200) {
      setViewToast(true);
      popupAlert.play();
      setToastTitle("Successful");
      setToastBody("Settings Updated.");
      setToastMessageType("success");

      setSelectedTimezone(selectedTimezoneSettings);
    } else {
      setViewToast(true);
      popupAlert.play();
      setToastTitle("Error");
      setToastBody(updateBasicSettingsFromSignUp.data);
      setToastMessageType("invalid");
    }
  }

  useEffect(() => {
    if (meetingType === "phone") {
      if (inboundCall) {
        if (optionListForPhone.length > 0) {
          setLocation(optionListForPhone[0].label);
        }
      } else {
        setLocation("Phone Call");
        setInboundCall(false);
      }
    } else if (roundRobinMeetingType === "phone") {
      if (inboundCall) {
        if (optionListForPhone.length > 0) {
          setRoundRobinLocation(optionListForPhone[0].label);
        }
      } else {
        setRoundRobinLocation("Phone Call");
        setInboundCall(false);
      }
    } else if (groupMeetingType === "phone") {
      if (groupInboundCall) {
        if (optionListForPhone.length > 0) {
          setGroupLocation(optionListForPhone[0].label);
        }
      } else {
        setGroupLocation("Phone Call");
        setGroupInboundCall(false);
      }
    } else {
      setInboundCall(false);
      setGroupInboundCall(false);
    }
  }, [inboundCall, groupInboundCall, meetingType, roundRobinMeetingType, groupMeetingType])

  const completeLinkSharing = async () => {

    setShowShareYourEventPanel(false);
    setMeetingsTabType("eventTypes");
    setMeetingShow(true);
    setOneOnOneEventCreation(false);
    setEventCreationSteps(false);
    setCustomHourView(false);

    resetAllMeetingDetails();
  }

  const verifyOwnerShip = async (e) => {
    e.preventDefault();
    const pass1 = ownershipPassOne.current.value;
    const pass2 = ownershipPassTwo.current.value;
    if (pass1 === pass2) {
      deleteAccount();

    } else {
      setViewToast(true);
      popupAlert.play();
      setToastTitle("Error");
      setToastBody("Password Mismatch!");
      setToastMessageType("invalid");
    }
  }

  return (
    // needAccountSetup === false ?
    //   (
    <>

      <div className={`framerCoverForAll `} style={needAccountSetup ? { opacity: "0" } : { opacity: "1" }}>
        <div className='leftSideBar d-none d-sm-flex d-md-flex d-lg-flex' style={{ borderRight: "1px solid #dee2e6" }}>
          <div className='mainMenue cursor-pointer' onClick={(e) => { setViewAdminPage(true); setViewOf("AccountSettings"); setToHistory("AccountSettings", "Profile"); }}>
            <OverlayTrigger
              placement="right"
              delay={{ show: 100, hide: 200 }}
              overlay={
                <Popover id="popover-basic">
                  <Popover.Header className='salesforce-style' as="h3">Admin Panel</Popover.Header>
                  <Popover.Body>
                    <p className='p-0 m-0 helpTextSize'>
                      In this panel you can control all settings like Phone Numbers, Branding Logos, Profile Picture etc.
                    </p>
                  </Popover.Body>
                </Popover>
              }>
              <img className='profilePictureForHome rounded-pill' align="center" src={currentGlobalProfile} alt="" />
            </OverlayTrigger>
            {/* {JSON.stringify(images ? images[images.length - 1]['data_url'] ? images[images.length - 1]['data_url'] : defaultImage['data_url'] : user.profilePicture)} */}
          </div>
          <div className="quickMenueHolder">
            <div className="interactionsButton alertHoverButton" onClick={() => { setAlertShow(true); setViewOf("Notifications"); setToHistory("Notifications", null); }}>
              <div className='iconFrameForMenue salesforce-style'>
                <NotificationsActiveIcon />
              </div>
              <div className='iconFrameForMenueTitle salesforce-style'>Notifications</div>
            </div>
            <div title={""} className="interactionsButton contactHoverButton" onClick={() => { setContactShow(true); setViewOf("PeopleShortcut"); setToHistory("PeopleShortcut", null); }}>
              <div className='iconFrameForMenue salesforce-style'>
                <PeopleAltIcon />
              </div>
              <div className='iconFrameForMenueTitle salesforce-style'>People</div>
            </div>
            <div title={""} className="interactionsButton meetingHoverButton" onClick={() => { setMeetingShow(true); setMeetingsTabType("scheduledEvents"); setViewOf("Meetings"); setToHistory("Meetings", "scheduledEvents"); }}>
              <div className='iconFrameForMenue salesforce-style'>
                <EventAvailableIcon />
              </div>
              <div className='iconFrameForMenueTitle salesforce-style'>Meetings</div>
            </div>
            <div title={""} className="interactionsButton availabilityHoverButton" onClick={() => { setAvailabilityShow(true); setViewOf("Schedules"); setToHistory("Schedules", null); }}>
              <div className='iconFrameForMenue salesforce-style'>
                <ScheduleIcon />
              </div>
              <div className='iconFrameForMenueTitle salesforce-style'>Schedules</div>
            </div>
            {
              process.env.REACT_APP_DOMAINNAME === "scheduleai.co" ?
                ""
                :
                <div title={""} className="interactionsButton availabilityHoverButton" onClick={() => { setMessangerShow(true); setViewOf("Messanger"); setToHistory("Messanger", null); }}>
                  <div className='iconFrameForMenue salesforce-style'>
                    <Chat />
                  </div>
                  <div className='iconFrameForMenueTitle salesforce-style'>Messages</div>
                </div>
            }
          </div>
          <div className='themeEditor' title="settings">
            {/* <SettingsSuggestIcon className='settingsIconHome' /> */}
          </div>
        </div>
        <div className="container-fluid rightSideBar">
          <div className="row mt-2">
            <div className="col-12">
              <div className="d-flex flex-row flex-wrap align-items-center justify-content-between">
                <div className="container-fluid p-0">
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-4">
                      <div className='clock text-start border rounded p-2 h-100 w-100 insightBackground d-flex flex-column justify-content-center align-items-center'>
                        {
                          parseInt(moment().format("H")) >= 5 && parseInt(moment().format("H")) < 12 ?
                            <>
                              <span className='fs-4'>Good Morning</span>
                            </>
                            :
                            parseInt(moment().format("H")) >= 12 && parseInt(moment().format("H")) <= 17 ?
                              <>
                                <span className='fs-4'>Good Afternoon</span>
                              </>
                              :
                              <>
                                <span className='fs-4'>Good Evening</span>
                              </>
                        }
                        {clockTIme}, {clockDate}
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 mb-4">
                      <div className='d-flex flex-column border rounded overflow-hidden w-100 insightBackground'>
                        <h6 className='m-0 p-2 border-bottom'>My Meeting Link</h6>
                        <div className='d-flex justify-content-start align-items-center gap-3 p-2'>
                          {/* <img alt="user" src={myCustomLink.customProfilePicture ? JSON.stringify(myCustomLink.customProfilePicture).length > 5 ? myCustomLink.customProfilePicture["data_url"] : myCustomLink.profilePicture : myCustomLink.profilePicture} className="rounded-circle profilePicEManager" /> */}
                          <img alt="user" src={currentGlobalProfile} className="rounded-circle" style={{ maxWidth: "40px" }} />

                          <div className="col d-flex flex-column justify-content-center align-items-start">
                            <p className='text-dark m-0 text-capitalize' style={{ fontWeight: "600" }}>
                              {
                                name
                              }
                            </p>
                            <a className='text-decoration-none text-primary m-0' href={`/${username.replaceAll(" ", "-")}`}>
                              {
                                `${window.location.href.split("/")[2]}/${username}`
                              }
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-8 col-md-8 col-lg-8 mb-4">
              <div className="card p-2 p-lg-3 h-100 insightBackground border">
                <Area meetings={allMeetings} />
              </div>
            </div>
            <div className="col-12 col-sm-4 col-md-4 col-lg-4 mb-4">
              <div className="card p-2 p-lg-3 h-100 insightBackground border">
                <Charts upcoming={totalUpcomingMeetings} pending={totalPendingMeetings} past={totalPastMeetings} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-4">
              <div className="card p-2 p-lg-3 insightBackground border">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col my-2">
                      <div className="card h-100 insightDetails defaultInsights rounded p-2">
                        <p>Total meetings.</p>
                        <label>{Object.keys(allMeetings).length}</label>
                      </div>
                    </div>
                    <div className="col my-2">
                      <div className="card h-100 insightDetails successinsights rounded p-2">
                        <p>Past meetings.</p>
                        <label>
                          <span id="pastMeetings">{totalPastMeetings}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col my-2">
                      <div className="card h-100 insightDetails upcomingInsights rounded p-2">
                        <p>Upcoming meetings.</p>
                        <label>{totalUpcomingMeetings}</label>
                      </div>
                    </div>
                    <div className="col my-2">
                      <div className="card h-100 insightDetails pendingInsights rounded p-2">
                        <p>Cancelled meetings.</p>
                        <label>{totalPendingMeetings}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-4">
              <div className="card p-0 insightBackground border h-100" style={{ overflow: "auto" }}>
                <h6 className='m-0 p-0 sticky-top bg-body border-bottom p-3 p-lg-3' style={{ zIndex: "2" }}>Recent Connections</h6>
                <div className='p-3 p-lg-3'>
                  {
                    allMeetings.length && hasPastMeeting(allMeetings) ?
                      allMeetings.map((meeting, key) => {
                        let date = new Date(meeting.date).getDate();
                        let month = new Date(meeting.date).getMonth();
                        let year = new Date(meeting.date).getFullYear();


                        let meridium = meeting.startTime.split(" ")[1];
                        let timing = meeting.startTime.split(" ")[0];

                        let OgHour = parseInt(timing.split(":")[0]);
                        let OgMin = parseInt(timing.split(":")[1]);

                        let hour = 0;

                        if (meridium.includes("pm")) {
                          if (OgHour < 12) {
                            hour = OgHour + 12;
                          }
                          else {
                            hour = OgHour;
                          }
                        } else {
                          hour = OgHour;
                        }

                        let min = OgMin;
                        let sec = 0;
                        if (dayjs(new Date(year, month, date, hour, min, sec)).valueOf() < dayjs(new Date()).valueOf()) {
                          return (
                            <React.Fragment key={key}>
                              <div className="alert alert-success p-1 d-flex flex-wrap gap-3 align-items-center contactLists overflow-hidden">
                                <img src={unknownUserPng} alt="" className='img rounded-circle profilePictureForHome' />
                                <div className='d-flex flex-column flex-fill'>
                                  <label className='fw-bold w-100'>{meeting.name}</label>
                                  <span className='text-truncate d-block w-100' style={{ maxWidth: "maxContent" }}>{meeting.email}</span>
                                  <span className='text-truncate d-block w-100 text-muted smallTextSize'>{meeting.date}, {meeting.startTime}</span>
                                </div>
                              </div>
                            </React.Fragment>
                          )
                        } else {
                          return
                        }
                      })
                      :
                      (
                        <div className="row">
                          <div className="col p-4 text-center">
                            <div className="alert alert-secondary">
                              No Recent Meetings.
                            </div>
                          </div>
                        </div>
                      )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Offcanvas show={alertShow} onHide={() => { setAlertShow(false); setViewOf(null); setAccountInnerView(null); setToHistory(null, null); }}
        scroll={true}
        backdrop={true}
        placement="top"
      >
        <Offcanvas.Header className='salesforce-style' closeButton closeVariant="white">
          <Offcanvas.Title>Alerts</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='backgroundFiller bg-soul'>
          <div className="alert alert-secondary text-center">
            There are no notifications for now
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas show={contactShow} onHide={() => { setContactShow(false); setViewOf(null); setAccountInnerView(null); setToHistory(null, null); }}
        scroll={true}
        backdrop={true}
        placement="bottom"
        className="">
        <Offcanvas.Header className='salesforce-style' closeButton closeVariant="white">
          <Offcanvas.Title>Contacts</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='bg-soul backgroundFiller'>
          <p className='text-center m-0 p-3 mediumTextSize'>
            Adding People to your account helps the round robin feature to select alternative meeting host for your events if you are not free on a particular time.
            <br />
            <span style={{ fontWeight: "500" }}>Note:</span> Members must have the same integrations like yours to create meeting links for them as well.
          </p>


          <div className='insightBackground p-3 rounded border'>

            <button className='btn px-4 salesforce-style mb-3' onClick={() => { setAddNewMember(true); }}>+ Add member</button>

            <div className='container-fluid p-0'>
              <div className="row">
                {
                  people ?
                    Object.keys(people).length > 0 ?
                      Object.keys(people).map((emails, keys) => {
                        return (
                          <div className="col-12 col-lg-4 col-md-6" key={keys}>
                            <div className='d-flex align-items-center justify-content-start flex-wrap gap-3 p-2 mb-2 rounded border shadow-sm position-relative'>
                              <div className='d-flex align-items-center justify-content-start'>
                                <img src={people[emails].image} alt={people[emails].name} className="rounded-circle border-0 profilePicture overflow-hidden" style={{ maxHeight: "60px" }} />
                              </div>
                              <div className='d-flex flex-column align-items-start justify-content-between w-75'>
                                <span className='d-inline-block text-truncate mw-100' style={{ fontSize: "1.12rem", fontWeight: "500" }}>{people[emails].name}</span>
                                <span className='d-inline-block text-truncate mw-100 text-muted mediumTextSize'>{emails}</span>
                              </div>
                              <i className='fa fa-trash position-absolute p-1 top-0 end-0 d-flex align-items-center text-danger rounded-circle pe-auto cursor-pointer' onClick={() => { setAskForDeleteMember(true); setDeleteMemberEmail(emails); }} style={{ aspectRatio: "1", width: "2rem", height: "2rem" }} />
                            </div>
                          </div>
                        )
                      })
                      :
                      <div className="col-12">
                        There are no members for now!
                      </div>
                    :
                    <div className="col-12">
                      There are no members for now!
                    </div>
                }
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas show={meetingsShow} onHide={() => { removeSelected(); setMeetingShow(false); setViewOf(null); setAccountInnerView(null); setMeetingsTabType("eventTypes"); setToHistory(null, null); }}
        scroll={true}
        backdrop={true}
        placement="start"
      >
        <Offcanvas.Header className='salesforce-style' closeButton closeVariant="white">
          <Offcanvas.Title>Meetings</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='backgroundFiller bg-soul'>
          <Tabs
            className="glitchedNavPils"
            activeKey={meetingsTabType}
          >
            <Tab eventKey="scheduledEvents" title={<div className='h-100 glitchedPanTabsLinks color-salesforce' onClick={() => { removeSelected(); setMeetingsTabType("scheduledEvents"); setAccountInnerView("scheduledEvents"); setToHistory("Meetings", "scheduledEvents"); }} style={{ fontWeight: "500" }}>Scheduled Events</div>}>
              <div className='allEvenets text-dark'>
                <div className='container-fluid p-0'>
                  <div className="row">
                    <div className="col py-2 bg-body"></div>
                  </div>
                  <div className="row my-2">
                    {/* <div className="col-12 col-md-3 col-lg-3 pt-3">
                          <div className='d-flex justify-content-center align-items-center'>
                              <span className='p-1 d-flex align-items-center justify-content-center'><i className='fa fa-search'></i></span>
                              <input type="search" className='customInputFieldFocus w-100 no-background' onChange={(e) => { searchInScheduled(e.target.value); }} name="searchInAll" id="searchInAll" placeholder="Filter" />
                          </div>
                      </div>
                      <div className="col-12 col-md-3 col-lg-3 py-3 d-flex align-items-center gap-1">
                          <div className='text-center fw-bold'>Event Types</div>
                          <div className='d-flex justify-content-center align-items-center'>
                              <select className='customInputFieldFocus w-100 no-background glassmorphism-light-calendar' onChange={(e) => { searchInScheduled(e.target.value); }} >
                                  <option value="One on One">One on One</option>
                                  <option value="One-off">One-off</option>
                                  <option value="Group">Group</option>
                                  <option value="Round Robin">Round Robin</option>
                              </select>
                          </div>
                      </div> */}
                  </div>
                  <div className="row">
                    <div className="col-12 p-0">
                      <Tabs
                        id="uncontrolled-tab-example"
                        className="glitchedNavPils"
                        defaultActiveKey={"Upcoming"}
                      >
                        <Tab eventKey="Upcoming" onClick={(e) => { setEventManagerInnerView("upcoming"); switchScheduledView("upcoming"); }} title={<div className='h-100 glitchedPanTabsLinks color-salesforce' style={{ fontWeight: "500" }}>Upcoming</div>}>
                          <div className="alert m-0 py-2 bg-body"></div>
                          <Accordion className='rounded-0'>
                            {
                              allMeetings.length && hasUpcomingMeeting(allMeetings) ?
                                (
                                  allMeetings.map((meeting, key) => {
                                    let date = new Date(meeting.date).getDate();
                                    let month = new Date(meeting.date).getMonth();
                                    let year = new Date(meeting.date).getFullYear();


                                    let meridium = meeting.startTime.split(" ")[1];
                                    let timing = meeting.startTime.split(" ")[0];

                                    let OgHour = parseInt(timing.split(":")[0]);
                                    let OgMin = parseInt(timing.split(":")[1]);

                                    let hour = 0;

                                    if (meridium.includes("pm")) {
                                      if (OgHour < 12) {
                                        hour = OgHour + 12;
                                      }
                                      else {
                                        hour = OgHour;
                                      }
                                    } else {
                                      hour = OgHour;
                                    }

                                    let min = OgMin;
                                    let sec = 0;

                                    let finalTimeToUseInApp = dayjs.tz(dayjs.tz(`${year}-${month + 1}-${date} ${hour}:${min}:${sec}`, meeting.givenTimezone.name), selectedTimezoneSettings.value.name);

                                    // console.log(finalTimeToUseInApp.valueOf(), dayjs.tz(dayjs.tz(new Date(), meeting.givenTimezone.name), selectedTimezoneSettings.value.name).valueOf())

                                    if (finalTimeToUseInApp.valueOf() > dayjs.tz(dayjs.tz(new Date(), meeting.givenTimezone.name), selectedTimezoneSettings.value.name).valueOf() && meeting.status == "active") {
                                      return (
                                        <React.Fragment key={key}>
                                          <Accordion.Item eventKey={key} className="rounded-0 insightBackground">
                                            <Accordion.Header className={`rounded-0 event-shade-color-${meeting.colorId}`}>
                                              <div className="container-fluid p-0">
                                                <div className="row">
                                                  <div className='col-12 col-sm-9 col-md-10 col-lg-10 d-flex align-items-start gap-3' style={{ wordBreak: "break-all" }}>
                                                    <div className={`repo-event-color-${meeting.colorId} p-1 rounded-pill h-100`}></div>
                                                    <div className='d-flex flex-column align-items-start'>
                                                      <span className='d-flex align-items-center justify-content-start mb-2 gap-1 text-dark' style={{ fontWeight: "500" }}>
                                                        <AccessTimeIcon />
                                                        {finalTimeToUseInApp.format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                        <span className={`mediumTextSize px-2 py-1 repo-event-color-${meeting.colorId} rounded-pill`}>{meeting.duration}</span>
                                                      </span>
                                                      <span className='d-flex align-items-center justify-content-start mb-2 gap-1 text-dark' style={{ fontWeight: "500" }}>
                                                        <CalendarMonthIcon />
                                                        {finalTimeToUseInApp.format("D MMMM YYYY")} ({finalTimeToUseInApp.format("dddd")})
                                                      </span>
                                                      <p className='m-0 mb-2 text-dark'>
                                                        <span style={{ fontWeight: "600" }}>{meeting.name}</span> with you
                                                      </p>
                                                      <p className='m-0 mediumTextSize text-secondary'>Event name <span style={{ fontWeight: "600" }}> {meeting.eventName}</span>, {meeting.meetingCategory}</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <div className="row mb-3 rounded-0">
                                                <div className="col-12 col-sm-3 col-md-2 col-lg-2 border-right">
                                                  <div className='d-flex flex-column gap-2'>
                                                    {
                                                      meeting.meetingCategory === "Group" && meeting.adminEmailForEvent === user.email ?
                                                        <button type='button' title='Rescheduling is not allowed for this event type.' disabled={true} className='btn salesforce-style rounded-pill w-100 d-flex flex-wrap gap-1 align-items-center justify-content-center'>
                                                          <i className='fa fa-repeat mediumTextSize'></i>
                                                          {/* <RepeatIcon/>
                                                            <LoopIcon/> */}
                                                          <span className='mediumTextSize'>Reschedule</span>
                                                        </button>
                                                        :
                                                        <Link to={`/reschedule/${meeting.encryptedJWTEvent}`} className="w-100 d-block text-decoration-none">
                                                          <button type='button' className='btn salesforce-style rounded-pill w-100 d-flex flex-wrap gap-1 align-items-center justify-content-center'>
                                                            <i className='fa fa-repeat mediumTextSize'></i>
                                                            {/* <RepeatIcon/>
                                                        <LoopIcon/> */}
                                                            <span className='mediumTextSize'>Reschedule</span>
                                                          </button>
                                                        </Link>
                                                    }

                                                    <button type='button' className='btn salesforce-style rounded-pill w-100 mediumTextSize d-flex flex-wrap align-items-center justify-content-center gap-1'
                                                      onClick={() => {
                                                        setCustomAlertBox(true);
                                                        setAlertBoxMessages("Do you want to Cancel '" + meeting.eventName + "' ?");
                                                        setAlertErrorType("queryCanc");
                                                        setAlertCancIdOne(meeting.uuid);
                                                        setAlertCancIdTwo(meeting.eventId);
                                                        setCancellationOrigionalAdminOfEvent(meeting.origionalAdminOfEvent);
                                                        setAssignedRefreshToken(meeting.assignedCalendarRefreshToken);
                                                        setAlertCalenderEventId(meeting.calendarEventId);
                                                        setSelectedMeetingCategory(meeting.meetingCategory);
                                                        setCancelContents(<>
                                                          <div className='w-100'>
                                                            <p className='m-0 mt-1 text-center fw-bold' style={{ wordBreak: "break-word" }}>{meeting.eventName}</p>
                                                            <p className='text-center m-0'>{finalTimeToUseInApp.format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}, {meeting.duration}</p>
                                                            <p className='m-0 mb-1 text-center'>{meeting.meetingCategory}</p>

                                                            <p className='w-100 text-center'>Please confirm that you would like to cancel this event. A cancellation email will also go out to the invitee.</p>

                                                            <textarea name="" className='w-100 customInputFieldFocus rounded p-2' rows="5" ref={cancellationPolicy} placeholder='Add an optional message.'></textarea>
                                                            {/* <p className='text-primary'>Report this event</p> */}
                                                          </div>
                                                        </>);
                                                      }}>
                                                      <i className='fa fa-trash mediumTextSize'></i>
                                                      <span className='mediumTextSize'>Cancel</span>
                                                    </button>
                                                  </div>
                                                </div>
                                                <div className="col-12 col-sm-9 col-md-10 col-lg-10 bg-soul">
                                                  <div className="py-2">

                                                    {
                                                      meeting.adminEmailForEvent ?
                                                        meeting.adminEmailForEvent.length > 0 ?
                                                          <div className='border rounded p-2 bg-body mb-3'>
                                                            <p className="m-0" style={{ fontWeight: "600" }}>MEETING HOST</p>
                                                            <span className=' text-dark'>
                                                              {
                                                                meeting.adminEmailForEvent
                                                              }
                                                            </span>
                                                          </div>
                                                          :
                                                          ""
                                                        :
                                                        ""
                                                    }

                                                    {
                                                      meeting.meetingCategory !== "Group" ?
                                                        <div className='border rounded p-2 bg-body mb-3'>
                                                          <p className="m-0" style={{ fontWeight: "600" }}>INVITIEE EMAIL</p>
                                                          <span className=' text-dark'>
                                                            {
                                                              meeting.email
                                                            }
                                                          </span>
                                                        </div>
                                                        :
                                                        ""
                                                    }

                                                    <div className='border rounded p-2 bg-body mb-3'>
                                                      <p className='m-0' style={{ fontWeight: "600" }}>LOCATION</p>
                                                      <span className='text-dark d-flex align-items-center gap-1'>
                                                        {
                                                          meeting.eventType ?
                                                            meeting.eventType === "phone" ?
                                                              meeting.inbound ?
                                                                (
                                                                  <>
                                                                    <img alt='call' src={callimg} className="copyIcon" />
                                                                    <span style={{ textTransform: "capitalize" }}>{meeting.location}</span>
                                                                  </>
                                                                )
                                                                :
                                                                (
                                                                  <>
                                                                    <img alt='copy' src={outgoingPng} className="copyIcon" />
                                                                    <span style={{ textTransform: "capitalize" }}>{meeting.phoneNumber}</span>
                                                                  </>
                                                                )
                                                              :
                                                              meeting.eventType === "meet" ?
                                                                (
                                                                  <>
                                                                    <img alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} src={meetImg} className="copyIcon" />
                                                                    <a target="_blank" className='text-decoration-none text-primary' href={`${meeting.hangoutLink.link}`}>Google Meet Conference</a>
                                                                  </>
                                                                )
                                                                :
                                                                meeting.eventType === "zoom" ?
                                                                  (
                                                                    <>
                                                                      <img alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} src={zoomImg} className="copyIcon" />
                                                                      <a target="_blank" className='text-decoration-none' href={`${meeting.hangoutLink.link}`}>Zoom Conference</a>
                                                                      {
                                                                        needZoomToken ?
                                                                          <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                          : ""
                                                                      }
                                                                    </>
                                                                  )
                                                                  :
                                                                  meeting.eventType === "goto" ?
                                                                    (
                                                                      <>
                                                                        <img alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} src={gotoImg} className="copyIcon" />
                                                                        <a target="_blank" className='text-decoration-none' href={`${meeting.hangoutLink.link}`}>GoTo Conference</a>
                                                                        {
                                                                          needGTMToken ?
                                                                            <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                            : ""
                                                                        }
                                                                      </>
                                                                    )
                                                                    :
                                                                    meeting.eventType === "webex" ?
                                                                      (
                                                                        <>
                                                                          <img alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} src={webexImg} className="copyIcon" />
                                                                          <a target="_blank" className='text-decoration-none' href={`${meeting.hangoutLink.link}`}>Webex Conference</a>
                                                                          {
                                                                            needWebexToken ?
                                                                              <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                              : ""
                                                                          }
                                                                        </>
                                                                      )
                                                                      :
                                                                      meeting.eventType === "teams" ?
                                                                        (
                                                                          <>
                                                                            <img alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} src={teamsImg} className="copyIcon" />
                                                                            <a target="_blank" className='text-decoration-none' href={`${meeting.hangoutLink.link}`}>Teams Conference</a>
                                                                            {
                                                                              needTeamsToken ?
                                                                                <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                                : ""
                                                                            }
                                                                          </>
                                                                        )
                                                                        :
                                                                        meeting.eventType === "address" ?
                                                                          (
                                                                            <>
                                                                              <img alt='copy' src={addressImg} className="copyIcon" />
                                                                              <span>{meeting.location}</span>
                                                                            </>
                                                                          )
                                                                          : "unkown meeting Type"
                                                            :
                                                            ""
                                                        }

                                                      </span>
                                                    </div>

                                                    {
                                                      meeting.meetingCategory !== "Group" ?
                                                        <div className='border rounded p-2 bg-body mb-3'>
                                                          <p className='m-0' style={{ fontWeight: "600" }}>INVITEE TIME ZONE</p>
                                                          <span className='mb-2 text-dark d-flex gap-2 align-items-center'>
                                                            {
                                                              meeting.givenTimezone ?
                                                                meeting.givenTimezone.name :
                                                                ""
                                                            }
                                                          </span>
                                                          <span className='mb-2 text-dark d-flex gap-2 align-items-center'>
                                                            {/* <img src={userPng} className="copyIcon" /> */}
                                                            <i className='fa-solid fa-clock fs-5 copyIcon text-center text-info'></i>
                                                            <span>
                                                              {
                                                                `${finalTimeToUseInApp.format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")} (${meeting.givenTimezone ? meeting.givenTimezone.name : ""})`
                                                              }
                                                            </span>
                                                          </span>
                                                          <span className='mb-2 text-dark d-flex gap-2'>
                                                            <img alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} src={calendly4Png} className="copyIcon" />
                                                            {finalTimeToUseInApp.format("D MMMM YYYY")}
                                                          </span>
                                                        </div>
                                                        :
                                                        ""
                                                    }


                                                    <div className='border rounded p-2 bg-body mb-3'>
                                                      <p className='m-0 mb-2' style={{ fontWeight: "600" }}>QUESTIONS</p>
                                                      {
                                                        typeof (meeting.sharedContent) == "object" ?
                                                          meeting.sharedContent.length > 0 ?
                                                            meeting.sharedContent.map((content, key) => {
                                                              return (
                                                                <>
                                                                  <div className='d-flex flex-column gap-1 alert alert-info rounded m-0 mb-2 p-1 shadow-sm' key={key}>
                                                                    <span className='mediumTextSize'>From: <span className='fw-bold'>{content.email}</span></span>
                                                                    <span className='mediumTextSize'>Message: {content.content}</span>
                                                                  </div>
                                                                </>
                                                              )
                                                            })
                                                            :
                                                            <span>
                                                              Not Provided
                                                            </span>
                                                          :
                                                          <span>
                                                            {meeting.sharedContent}
                                                          </span>

                                                      }
                                                    </div>

                                                    <div className='border rounded p-2 bg-body'>
                                                      <p className='text-uppercase text-dark m-0 pb-1' style={{ fontWeight: "600" }}>Event Members:</p>
                                                      <div className='d-flex gap-2 align-items-center flex-wrap justify-content-start pb-2'>
                                                        {
                                                          meeting.guests ?
                                                            meeting.guests.length > 0 ?
                                                              meeting.guests.split(",").map((email, key) => {
                                                                if (email !== meeting.adminEmailForEvent) {
                                                                  return <button key={key} className='btn btn-sm px-2 py-1 rounded-pill bg-body border'>{email}</button>
                                                                }
                                                              })
                                                              :
                                                              "Not Available"
                                                            :
                                                            "Not Available"
                                                        }
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        </React.Fragment>
                                      )
                                    }
                                  })
                                )
                                :
                                (
                                  <div className="col p-2 text-center">
                                    {/* <hr /> */}
                                    <img src={emptyResultPng} alt="empty filter" style={{ maxWidth: "150px", width: "100%" }} />
                                    <p className='mediumTextSize '>No upcoming events.</p>
                                    <p className='smallTextSize'>You will find events after getting bookings from your invitees</p>
                                  </div>
                                )
                            }
                          </Accordion>
                        </Tab>
                        <Tab eventKey="Pending" onClick={(e) => { setEventManagerInnerView("pending"); switchScheduledView("pending"); }} title={<div className='h-100 glitchedPanTabsLinks color-salesforce' style={{ fontWeight: "500" }}>Cancelled</div>}>
                          <div className="alert m-0 py-2 bg-body"></div>
                          <Accordion className='rounded-0'>
                            {
                              allMeetings.length && hasPendings(allMeetings) ?
                                allMeetings.map((meeting, key) => {
                                  if (meeting.status == "cancelled") {

                                    let date = new Date(meeting.date).getDate();
                                    let month = new Date(meeting.date).getMonth();
                                    let year = new Date(meeting.date).getFullYear();


                                    let meridium = meeting.startTime.split(" ")[1];
                                    let timing = meeting.startTime.split(" ")[0];

                                    let OgHour = parseInt(timing.split(":")[0]);
                                    let OgMin = parseInt(timing.split(":")[1]);

                                    let hour = 0;

                                    if (meridium.includes("pm")) {
                                      if (OgHour < 12) {
                                        hour = OgHour + 12;
                                      }
                                      else {
                                        hour = OgHour;
                                      }
                                    } else {
                                      hour = OgHour;
                                    }

                                    let min = OgMin;
                                    let sec = 0;

                                    let finalTimeToUseInApp = dayjs.tz(dayjs.tz(`${year}-${month + 1}-${date} ${hour}:${min}:${sec}`, meeting.givenTimezone.name), selectedTimezoneSettings.value.name);

                                    if (finalTimeToUseInApp.valueOf() > dayjs.tz(dayjs.tz(new Date(), meeting.givenTimezone.name), selectedTimezoneSettings.value.name).valueOf()) {

                                      return (
                                        <React.Fragment key={key}>
                                          <Accordion.Item eventKey={key} className="rounded-0 insightBackground">
                                            <Accordion.Header className={`rounded-0 event-shade-color-${meeting.colorId}`}>
                                              <div className="container-fluid p-0">
                                                <div className="row">
                                                  <div className='col-12 col-sm-9 col-md-10 col-lg-10 d-flex align-items-start gap-3' style={{ wordBreak: "break-all" }}>
                                                    <div className={`repo-event-color-${meeting.colorId} p-1 rounded-pill h-100`}></div>
                                                    <div className='d-flex flex-column align-items-start'>
                                                      {
                                                        meeting.status === "cancelled" ?
                                                          <div className='d-flex align-items-center justify-content-start mb-2 gap-1 text-dark alert alert-danger p-1' style={{ fontWeight: "500" }}>
                                                            Cancelled
                                                          </div>
                                                          :
                                                          ""
                                                      }
                                                      <span className='d-flex align-items-center justify-content-start mb-2 gap-1 text-dark' style={{ fontWeight: "500" }}>
                                                        <AccessTimeIcon />
                                                        {finalTimeToUseInApp.format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                        <span className={`mediumTextSize px-2 py-1 repo-event-color-${meeting.colorId} rounded-pill`}>{meeting.duration}</span>
                                                      </span>
                                                      <span className='d-flex align-items-center justify-content-start mb-2 gap-1 text-dark' style={{ fontWeight: "500" }}>
                                                        <CalendarMonthIcon />
                                                        {finalTimeToUseInApp.format("D MMMM YYYY")} ({finalTimeToUseInApp.format("dddd")})
                                                      </span>
                                                      <p className='m-0 mb-2 text-dark'>
                                                        <span style={{ fontWeight: "600" }}>{meeting.name}</span> with you
                                                      </p>
                                                      <p className='m-0 mediumTextSize text-secondary'>Event name <span style={{ fontWeight: "600" }}> {meeting.eventName}</span>, {meeting.meetingCategory}</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <div className="row mb-3 rounded-0">
                                                <div className="col-12 col-sm-3 col-md-2 col-lg-2 border-right">
                                                  <div className='d-flex flex-column gap-2'>
                                                    {/* <Link to={`/reschedule/${meeting.encryptedJWTEvent}`} className="w-100 d-block text-decoration-none">
                                                    <button type='button' className='btn salesforce-style rounded-pill w-100 d-flex flex-wrap gap-1 align-items-center justify-content-center'>
                                                      <i className='fa fa-repeat mediumTextSize'></i>
                                                      <span className='mediumTextSize'>Reschedule</span>
                                                    </button>
                                                  </Link>
                                                  <button type='button' className='btn salesforce-style rounded-pill w-100 mediumTextSize d-flex flex-wrap align-items-center justify-content-center gap-1'
                                                    onClick={() => {
                                                      setCustomAlertBox(true);
                                                      setAlertBoxMessages("Do you want to Cancel '" + meeting.eventName + "' ?");
                                                      setAlertErrorType("queryCanc");
                                                      setAlertCancIdOne(meeting.uuid);
                                                      setAlertCancIdTwo(meeting.eventId);
                                                      setAlertCalenderEventId(meeting.calendarEventId);
                                                      setCancelContents(<>
                                                        <div className='w-100'>
                                                          <p className='m-0 mt-1 text-center fw-bold' style={{ wordBreak: "break-word" }}>{meeting.eventName}</p>
                                                          <p className='text-center m-0'>{meeting.startTime}, {meeting.duration}</p>
                                                          <p className='m-0 mb-1 text-center'>{meeting.meetingCategory}</p>

                                                          <p className='w-100 text-center'>Please confirm that you would like to cancel this event. A cancellation email will also go out to the invitee.</p>

                                                          <textarea name="" className='w-100 rounded border' rows="5" ref={cancellationPolicy} placeholder='Add an optional message.'></textarea>
                                                        </div>
                                                      </>);
                                                    }}>
                                                    <i className='fa fa-trash mediumTextSize'></i>
                                                    <span className='mediumTextSize'>Cancel</span>
                                                  </button> */}
                                                  </div>
                                                </div>
                                                <div className="col-12 col-sm-9 col-md-10 col-lg-10 bg-soul">
                                                  <div className="py-2">

                                                    {
                                                      meeting.adminEmailForEvent ?
                                                        meeting.adminEmailForEvent.length > 0 ?
                                                          <div className='border rounded p-2 bg-body mb-3'>
                                                            <p className="m-0" style={{ fontWeight: "600" }}>MEETING HOST</p>
                                                            <span className=' text-dark'>
                                                              {
                                                                meeting.adminEmailForEvent
                                                              }
                                                            </span>
                                                          </div>
                                                          :
                                                          ""
                                                        :
                                                        ""
                                                    }

                                                    {
                                                      meeting.meetingCategory !== "Group" ?
                                                        <div className='border rounded p-2 bg-body mb-3'>
                                                          <p className="m-0" style={{ fontWeight: "600" }}>INVITIEE EMAIL</p>
                                                          <span className=' text-dark'>
                                                            {
                                                              meeting.email
                                                            }
                                                          </span>
                                                        </div>
                                                        :
                                                        ""
                                                    }

                                                    <div className='border rounded p-2 bg-body mb-3'>
                                                      <p className='m-0' style={{ fontWeight: "600" }}>LOCATION</p>
                                                      <span className='text-dark d-flex align-items-center gap-1'>
                                                        {
                                                          meeting.eventType ?
                                                            meeting.eventType === "phone" ?
                                                              meeting.inbound ?
                                                                (
                                                                  <>
                                                                    <img alt='call' src={callimg} className="copyIcon" />
                                                                    <span style={{ textTransform: "capitalize" }}>{meeting.location}</span>
                                                                  </>
                                                                )
                                                                :
                                                                (
                                                                  <>
                                                                    <img alt='copy' src={outgoingPng} className="copyIcon" />
                                                                    <span style={{ textTransform: "capitalize" }}>{meeting.phoneNumber}</span>
                                                                  </>
                                                                )
                                                              :
                                                              meeting.eventType === "meet" ?
                                                                (
                                                                  <>
                                                                    <img alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} src={meetImg} className="copyIcon" />
                                                                    <a target="_blank" className='text-decoration-none text-primary' href={`${meeting.hangoutLink.link}`}>Google Meet Conference</a>
                                                                  </>
                                                                )
                                                                :
                                                                meeting.eventType === "zoom" ?
                                                                  (
                                                                    <>
                                                                      <img alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} src={zoomImg} className="copyIcon" />
                                                                      <a target="_blank" className='text-decoration-none' href={`${meeting.hangoutLink.link}`}>Zoom Conference</a>
                                                                      {
                                                                        needZoomToken ?
                                                                          <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                          : ""
                                                                      }
                                                                    </>
                                                                  )
                                                                  :
                                                                  meeting.eventType === "goto" ?
                                                                    (
                                                                      <>
                                                                        <img alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} src={gotoImg} className="copyIcon" />
                                                                        <a target="_blank" className='text-decoration-none' href={`${meeting.hangoutLink.link}`}>GoTo Conference</a>
                                                                        {
                                                                          needGTMToken ?
                                                                            <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                            : ""
                                                                        }
                                                                      </>
                                                                    )
                                                                    :
                                                                    meeting.eventType === "webex" ?
                                                                      (
                                                                        <>
                                                                          <img alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} src={webexImg} className="copyIcon" />
                                                                          <a target="_blank" className='text-decoration-none' href={`${meeting.hangoutLink.link}`}>Webex Conference</a>
                                                                          {
                                                                            needWebexToken ?
                                                                              <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                              : ""
                                                                          }
                                                                        </>
                                                                      )
                                                                      :
                                                                      meeting.eventType === "teams" ?
                                                                        (
                                                                          <>
                                                                            <img alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} src={teamsImg} className="copyIcon" />
                                                                            <a target="_blank" className='text-decoration-none' href={`${meeting.hangoutLink.link}`}>Teams Conference</a>
                                                                            {
                                                                              needTeamsToken ?
                                                                                <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                                : ""
                                                                            }
                                                                          </>
                                                                        )
                                                                        :
                                                                        meeting.eventType === "address" ?
                                                                          (
                                                                            <>
                                                                              <img alt='copy' src={addressImg} className="copyIcon" />
                                                                              <span>{meeting.location}</span>
                                                                            </>
                                                                          )
                                                                          : "unkown meeting Type"
                                                            :
                                                            ""
                                                        }

                                                      </span>
                                                    </div>

                                                    {
                                                      meeting.meetingCategory !== "Group" ?
                                                        <div className='border rounded p-2 bg-body mb-3'>
                                                          <p className='m-0' style={{ fontWeight: "600" }}>INVITEE TIME ZONE</p>
                                                          <span className='mb-2 text-dark d-flex gap-2 align-items-center'>
                                                            {
                                                              meeting.givenTimezone ?
                                                                meeting.givenTimezone.name :
                                                                ""
                                                            }
                                                          </span>
                                                          <span className='mb-2 text-dark d-flex gap-2 align-items-center'>
                                                            {/* <img src={userPng} className="copyIcon" /> */}
                                                            <i className='fa-solid fa-clock fs-5 copyIcon text-center text-info'></i>
                                                            <span>
                                                              {
                                                                `${finalTimeToUseInApp.format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mm a")} (${meeting.givenTimezone ? meeting.givenTimezone.name : ""})`
                                                              }
                                                            </span>
                                                          </span>
                                                          <span className='mb-2 text-dark d-flex gap-2'>
                                                            <img alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} src={calendly4Png} className="copyIcon" />
                                                            {finalTimeToUseInApp.format("D MMMM YYYY")}
                                                          </span>
                                                        </div>
                                                        :
                                                        ""
                                                    }


                                                    <div className='border rounded p-2 bg-body mb-3'>
                                                      <p className='m-0 mb-2' style={{ fontWeight: "600" }}>QUESTIONS</p>
                                                      {
                                                        typeof (meeting.sharedContent) == "object" ?
                                                          meeting.sharedContent.length > 0 ?
                                                            meeting.sharedContent.map((content, key) => {
                                                              return (
                                                                <>
                                                                  <div className='d-flex flex-column gap-1 alert alert-info rounded m-0 mb-2 p-1 shadow-sm' key={key}>
                                                                    <span className='mediumTextSize'>From: <span className='fw-bold'>{content.email}</span></span>
                                                                    <span className='mediumTextSize'>Message: {content.content}</span>
                                                                  </div>
                                                                </>
                                                              )
                                                            })
                                                            :
                                                            <span>
                                                              Not Provided
                                                            </span>
                                                          :
                                                          <span>
                                                            {meeting.sharedContent}
                                                          </span>
                                                      }
                                                    </div>

                                                    <div className='border rounded p-2 bg-body'>
                                                      <p className='text-uppercase text-dark m-0 pb-1' style={{ fontWeight: "600" }}>Event Members:</p>
                                                      <div className='d-flex gap-2 align-items-center flex-wrap justify-content-start pb-2'>
                                                        {
                                                          meeting.guests.length > 0 ?
                                                            meeting.guests.split(",").map((email, key) => {
                                                              if (email != meeting.adminEmailForEvent) {
                                                                return <button key={key} className='btn btn-sm px-2 py-1 rounded-pill bg-body border'>{email}</button>
                                                              }
                                                            }) : "Not Available"
                                                        }
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>

                                        </React.Fragment>
                                      )
                                    }
                                  }
                                })
                                :
                                (
                                  <div className="col p-2 text-center">
                                    {/* <hr /> */}
                                    <img src={emptyResultPng} alt="empty filter" style={{ maxWidth: "150px", width: "100%" }} />
                                    <p className='mediumTextSize '>No pending events.</p>
                                    <p className='smallTextSize'>You will find events after getting bookings from you invitees.</p>
                                  </div>
                                )
                            }
                          </Accordion>
                        </Tab>
                        <Tab eventKey="Past" onClick={(e) => { setEventManagerInnerView("past"); switchScheduledView("past"); }} title={<div className='h-100 glitchedPanTabsLinks color-salesforce' style={{ fontWeight: "500" }}>Past</div>}>
                          <div className="alert m-0 py-2 bg-body"></div>

                          <Accordion className='rounded-0'>
                            {
                              allMeetings.length && hasPastMeeting(allMeetings) ?
                                allMeetings.map((meeting, key) => {
                                  let date = new Date(meeting.date).getDate();
                                  let month = new Date(meeting.date).getMonth();
                                  let year = new Date(meeting.date).getFullYear();


                                  let meridium = meeting.startTime.split(" ")[1];
                                  let timing = meeting.startTime.split(" ")[0];

                                  let OgHour = parseInt(timing.split(":")[0]);
                                  let OgMin = parseInt(timing.split(":")[1]);

                                  let hour = 0;

                                  if (meridium.includes("pm")) {
                                    if (OgHour < 12) {
                                      hour = OgHour + 12;
                                    }
                                    else {
                                      hour = OgHour;
                                    }
                                  } else {
                                    hour = OgHour;
                                  }

                                  let min = OgMin;
                                  let sec = 0;

                                  let finalTimeToUseInApp = dayjs.tz(dayjs.tz(`${year}-${month + 1}-${date} ${hour}:${min}:${sec}`, meeting.givenTimezone.name), selectedTimezoneSettings.value.name);

                                  // console.log(finalTimeToUseInApp.valueOf(), dayjs.tz(dayjs.tz(new Date(), meeting.givenTimezone.name), selectedTimezoneSettings.value.name).valueOf())

                                  if (finalTimeToUseInApp.valueOf() < dayjs.tz(dayjs.tz(new Date(), meeting.givenTimezone.name), selectedTimezoneSettings.value.name).valueOf()) {
                                    return (
                                      <React.Fragment key={key}>
                                        <Accordion.Item eventKey={key} className="rounded-0 insightBackground">
                                          <Accordion.Header className={`rounded-0 event-shade-color-${meeting.colorId}`}>
                                            <div className="container-fluid p-0">
                                              <div className="row">
                                                <div className='col-12 col-sm-9 col-md-10 col-lg-10 d-flex align-items-start gap-3' style={{ wordBreak: "break-all" }}>
                                                  <div className={`repo-event-color-${meeting.colorId} p-1 rounded-pill h-100`}></div>
                                                  <div className='d-flex flex-column align-items-start'>
                                                    {
                                                      meeting.status === "cancelled" ?
                                                        <div className='d-flex align-items-center justify-content-start mb-2 gap-1 text-dark alert alert-danger p-1' style={{ fontWeight: "500" }}>
                                                          Cancelled
                                                        </div>
                                                        :
                                                        ""
                                                    }
                                                    <span className='d-flex align-items-center justify-content-start mb-2 gap-1 text-dark' style={{ fontWeight: "500" }}>
                                                      <AccessTimeIcon />
                                                      {finalTimeToUseInApp.format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                      <span className={`mediumTextSize px-2 py-1 repo-event-color-${meeting.colorId} rounded-pill`}>{meeting.duration}</span>
                                                    </span>
                                                    <span className='d-flex align-items-center justify-content-start mb-2 gap-1 text-dark' style={{ fontWeight: "500" }}>
                                                      <CalendarMonthIcon />
                                                      {finalTimeToUseInApp.format("D MMMM YYYY")} ({finalTimeToUseInApp.format("dddd")})
                                                    </span>
                                                    <p className='m-0 mb-2 text-dark'>
                                                      <span style={{ fontWeight: "600" }}>{meeting.name}</span> with you
                                                    </p>
                                                    <p className='m-0 mediumTextSize text-secondary'>Event name <span style={{ fontWeight: "600" }}> {meeting.eventName}</span>, {meeting.meetingCategory}</p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <div className="row mb-3 rounded-0">
                                              <div className="col-12 col-sm-3 col-md-2 col-lg-2 border-right">
                                                <div className='d-flex flex-column gap-2'>
                                                  {/* <Link to={`/reschedule/${meeting.encryptedJWTEvent}`} className="w-100 d-block text-decoration-none">
                                                    <button type='button' className='btn salesforce-style rounded-pill w-100 d-flex flex-wrap gap-1 align-items-center justify-content-center'>
                                                      <i className='fa fa-repeat mediumTextSize'></i>
                                                      <span className='mediumTextSize'>Reschedule</span>
                                                    </button>
                                                  </Link>
                                                  <button type='button' className='btn salesforce-style rounded-pill w-100 mediumTextSize d-flex flex-wrap align-items-center justify-content-center gap-1'
                                                    onClick={() => {
                                                      setCustomAlertBox(true);
                                                      setAlertBoxMessages("Do you want to Cancel '" + meeting.eventName + "' ?");
                                                      setAlertErrorType("queryCanc");
                                                      setAlertCancIdOne(meeting.uuid);
                                                      setAlertCancIdTwo(meeting.eventId);
                                                      setAlertCalenderEventId(meeting.calendarEventId);
                                                      setCancelContents(<>
                                                        <div className='w-100'>
                                                          <p className='m-0 mt-1 text-center fw-bold' style={{ wordBreak: "break-word" }}>{meeting.eventName}</p>
                                                          <p className='text-center m-0'>{meeting.startTime}, {meeting.duration}</p>
                                                          <p className='m-0 mb-1 text-center'>{meeting.meetingCategory}</p>

                                                          <p className='w-100 text-center'>Please confirm that you would like to cancel this event. A cancellation email will also go out to the invitee.</p>

                                                          <textarea name="" className='w-100 rounded border' rows="5" ref={cancellationPolicy} placeholder='Add an optional message.'></textarea>
                                                        </div>
                                                      </>);
                                                    }}>
                                                    <i className='fa fa-trash mediumTextSize'></i>
                                                    <span className='mediumTextSize'>Cancel</span>
                                                  </button> */}
                                                </div>
                                              </div>
                                              <div className="col-12 col-sm-9 col-md-10 col-lg-10 bg-soul">
                                                <div className="py-2">

                                                  {
                                                    meeting.adminEmailForEvent ?
                                                      meeting.adminEmailForEvent.length > 0 ?
                                                        <div className='border rounded p-2 bg-body mb-3'>
                                                          <p className="m-0" style={{ fontWeight: "600" }}>MEETING HOST</p>
                                                          <span className=' text-dark'>
                                                            {
                                                              meeting.adminEmailForEvent
                                                            }
                                                          </span>
                                                        </div>
                                                        :
                                                        ""
                                                      :
                                                      ""
                                                  }

                                                  {
                                                    meeting.meetingCategory !== "Group" ?
                                                      <div className='border rounded p-2 bg-body mb-3'>
                                                        <p className="m-0" style={{ fontWeight: "600" }}>INVITIEE EMAIL</p>
                                                        <span className=' text-dark'>
                                                          {
                                                            meeting.email
                                                          }
                                                        </span>
                                                      </div>
                                                      :
                                                      ""
                                                  }

                                                  <div className='border rounded p-2 bg-body mb-3'>
                                                    <p className='m-0' style={{ fontWeight: "600" }}>LOCATION</p>
                                                    <span className='text-dark d-flex align-items-center gap-1'>
                                                      {
                                                        meeting.eventType ?
                                                          meeting.eventType === "phone" ?
                                                            meeting.inbound ?
                                                              (
                                                                <>
                                                                  <img alt='call' src={callimg} className="copyIcon" />
                                                                  <span style={{ textTransform: "capitalize" }}>{meeting.location}</span>
                                                                </>
                                                              )
                                                              :
                                                              (
                                                                <>
                                                                  <img alt='copy' src={outgoingPng} className="copyIcon" />
                                                                  <span style={{ textTransform: "capitalize" }}>{meeting.phoneNumber}</span>
                                                                </>
                                                              )
                                                            :
                                                            meeting.eventType === "meet" ?
                                                              (
                                                                <>
                                                                  <img alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} src={meetImg} className="copyIcon" />
                                                                  <a target="_blank" className='text-decoration-none text-primary' href={`${meeting.hangoutLink.link}`}>Google Meet Conference</a>
                                                                </>
                                                              )
                                                              :
                                                              meeting.eventType === "zoom" ?
                                                                (
                                                                  <>
                                                                    <img alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} src={zoomImg} className="copyIcon" />
                                                                    <a target="_blank" className='text-decoration-none' href={`${meeting.hangoutLink.link}`}>Zoom Conference</a>
                                                                    {
                                                                      needZoomToken ?
                                                                        <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                        : ""
                                                                    }
                                                                  </>
                                                                )
                                                                :
                                                                meeting.eventType === "goto" ?
                                                                  (
                                                                    <>
                                                                      <img alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} src={gotoImg} className="copyIcon" />
                                                                      <a target="_blank" className='text-decoration-none' href={`${meeting.hangoutLink.link}`}>GoTo Conference</a>
                                                                      {
                                                                        needGTMToken ?
                                                                          <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                          : ""
                                                                      }
                                                                    </>
                                                                  )
                                                                  :
                                                                  meeting.eventType === "webex" ?
                                                                    (
                                                                      <>
                                                                        <img alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} src={webexImg} className="copyIcon" />
                                                                        <a target="_blank" className='text-decoration-none' href={`${meeting.hangoutLink.link}`}>Webex Conference</a>
                                                                        {
                                                                          needWebexToken ?
                                                                            <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                            : ""
                                                                        }
                                                                      </>
                                                                    )
                                                                    :
                                                                    meeting.eventType === "teams" ?
                                                                      (
                                                                        <>
                                                                          <img alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} src={teamsImg} className="copyIcon" />
                                                                          <a target="_blank" className='text-decoration-none' href={`${meeting.hangoutLink.link}`}>Teams Conference</a>
                                                                          {
                                                                            needTeamsToken ?
                                                                              <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                                              : ""
                                                                          }
                                                                        </>
                                                                      )
                                                                      :
                                                                      meeting.eventType === "address" ?
                                                                        (
                                                                          <>
                                                                            <img alt='copy' src={addressImg} className="copyIcon" />
                                                                            <span>{meeting.location}</span>
                                                                          </>
                                                                        )
                                                                        : "unkown meeting Type"
                                                          :
                                                          ""
                                                      }

                                                    </span>
                                                  </div>
                                                  {
                                                    meeting.meetingCategory !== "Group" ?
                                                      <div className='border rounded p-2 bg-body mb-3'>
                                                        <p className='m-0' style={{ fontWeight: "600" }}>INVITEE TIME ZONE</p>
                                                        <span className='mb-2 text-dark d-flex gap-2 align-items-center'>
                                                          {
                                                            meeting.givenTimezone ?
                                                              meeting.givenTimezone.name
                                                              :
                                                              ""
                                                          }
                                                        </span>
                                                        <span className='mb-2 text-dark d-flex gap-2 align-items-center'>
                                                          {/* <img src={userPng} className="copyIcon" /> */}
                                                          <i className='fa-solid fa-clock fs-5 copyIcon text-center text-info'></i>
                                                          <span>
                                                            {
                                                              `${finalTimeToUseInApp.format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mm a")} (${meeting.givenTimezone ? meeting.givenTimezone.name : ""})`
                                                            }
                                                          </span>
                                                        </span>
                                                        <span className='mb-2 text-dark d-flex gap-2'>
                                                          <img alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} src={calendly4Png} className="copyIcon" />
                                                          {finalTimeToUseInApp.format("D MMMM YYYY")}
                                                        </span>
                                                      </div>
                                                      :
                                                      ""
                                                  }


                                                  <div className='border rounded p-2 bg-body mb-3'>
                                                    <p className='m-0 mb-2' style={{ fontWeight: "600" }}>QUESTIONS</p>
                                                    {
                                                      typeof (meeting.sharedContent) == "object" ?
                                                        meeting.sharedContent.length > 0 ?
                                                          meeting.sharedContent.map((content, key) => {
                                                            return (
                                                              <>
                                                                <div className='d-flex flex-column gap-1 alert alert-info rounded m-0 mb-2 p-1 shadow-sm' key={key}>
                                                                  <span className='mediumTextSize'>From: <span className='fw-bold'>{content.email}</span></span>
                                                                  <span className='mediumTextSize'>Message: {content.content}</span>
                                                                </div>
                                                              </>
                                                            )
                                                          })
                                                          :
                                                          <span>
                                                            Not Provided
                                                          </span>
                                                        :
                                                        <span>
                                                          {meeting.sharedContent}
                                                        </span>
                                                    }
                                                  </div>

                                                  <div className='border rounded p-2 bg-body'>
                                                    <p className='text-uppercase text-dark m-0 pb-1' style={{ fontWeight: "600" }}>Event Members:</p>
                                                    <div className='d-flex gap-2 align-items-center flex-wrap justify-content-start pb-2'>
                                                      {
                                                        meeting.guests.length > 0 ?
                                                          meeting.guests.split(",").map((email, key) => {
                                                            if (email != meeting.adminEmailForEvent) {
                                                              return <button key={key} className='btn btn-sm px-2 py-1 rounded-pill bg-body border'>{email}</button>
                                                            }
                                                          }) : "Not Available"
                                                      }
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </React.Fragment>
                                    )
                                  }
                                })
                                :
                                (
                                  <div className="col p-2 text-center">
                                    {/* <hr /> */}
                                    <img src={emptyResultPng} alt="empty filter" style={{ maxWidth: "150px", width: "100%" }} />
                                    <p className='mediumTextSize '>No past events.</p>
                                    <p className='smallTextSize'>All of your past events are will be listed here.</p>
                                  </div>
                                )
                            }
                          </Accordion>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="eventTypes" title={<div className='h-100 glitchedPanTabsLinks color-salesforce' onClick={() => { setMeetingsTabType("eventTypes"); setAccountInnerView("eventTypes"); setToHistory("Meetings", "eventTypes"); }} style={{ fontWeight: "500" }}>Event Templates</div>}>
              <div className='container-fluid p-0 mb-5'>
                <div className="row">
                  <div className="col py-2 bg-body"></div>
                </div>
                <div className="row">
                  <div className='col-12 d-flex align-items-center justify-content-between'>
                    <div className="container-fluid p-0">
                      <div className="row">
                        <div className="col-12 col-md-3 col-lg-3 pt-3">
                          <div className='d-flex justify-content-center align-items-center'>
                            <span className='p-1 d-flex align-items-center justify-content-center'><i className='fa fa-search'></i></span>
                            <input type="search" className='customInputFieldFocus w-100 insightBackground' onChange={(e) => { searchInAllEvent(e.target.value); }} name="searchInAll" id="searchInAll" placeholder="Filter" />
                          </div>
                        </div>
                      </div>
                      <div className="row insightBackground shadow-sm border my-3">
                        <div className="col-12 col-md-9 col-lg-10">
                          <div className='rounded-pill d-flex justify-content-start align-items-center gap-3 py-3'>
                            {/* <img alt="user" src={myCustomLink.customProfilePicture ? JSON.stringify(myCustomLink.customProfilePicture).length > 5 ? myCustomLink.customProfilePicture["data_url"] : myCustomLink.profilePicture : myCustomLink.profilePicture} className="rounded-circle profilePicEManager" /> */}
                            <img alt="user" src={currentGlobalProfile} className="rounded-circle profilePicEManager" />

                            <div className="col d-flex flex-column gap-1 justify-content-center align-items-start">
                              <p className='text-dark m-0 text-capitalize' style={{ fontWeight: "600" }}>
                                {
                                  name
                                }
                              </p>
                              <a className='text-decoration-none text-primary m-0' href={`/${username.replaceAll(" ", "-")}`}>
                                {
                                  `${window.location.href.split("/")[2]}/${username.replaceAll(" ", "-")}`
                                }
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-3 col-lg-2 d-flex flex-column justify-content-center align-items-end">
                          <hr className='w-100 d-lg-none d-md-none' />
                          <button type="button" className='btn btn-sm salesforce-style rounded-pill w-100' onClick={() => { removeSelected(); setViewNewEventCreate(true); setViewOf("NewMeetingCreation"); setToHistory("NewMeetingCreation", null); setMeetingShow(false); }}>
                            + New Event Type
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    {/* ######################################################################################################################################### */}
                    <div className={`bg-soul d-flex align-items-center mb-3 sticky-top ${showDeleteBtn && viewEventTypes ? "" : "d-none"}`}>
                      <button id="bulkDeleteButton" className='btn bg-body rounded-pill d-flex align-items-center justify-content-start text-danger gap-2 display-5'>
                        {
                          bulkDeleteSection ?
                            (
                              <>
                                <i className='fa fa-times' onClick={removeSelected}></i>
                                <span className='totalSelectedCount'>{showDeleteBtnLength}</span>
                                <span>SELECTED</span>
                                <i className='fa fa-trash' onClick={startBulkDelete}></i>
                              </>
                            )
                            :
                            <>
                              <span className="spinner-border spinner-border-sm text-danger"></span>Loading..
                            </>
                        }
                      </button>
                    </div>
                    {/* ######################################################################################################################################### */}
                  </div>
                  <div className="col h-100 overflow-auto">
                    <div className='container-fluid p-0'>
                      <div className="row ">
                        {
                          allEvents.length ?
                            allEvents.map((event, key) => {
                              if (event.secretEvent) {
                                return (
                                  <React.Fragment key={key}>
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                                      <div className="mb-3 text-dark insightBackground shadow-sm rounded border overflow-hidden d-flex flex-column justify-content-between h-100">
                                        <div className={`d-flex align-items-center justify-content-between p-2 border-bottom event-shade-color-${event.EventColor}`}>
                                          <div className={`d-flex gap-3 align-items-center justify-content-start`}>
                                            <OverlayTrigger
                                              placement="top"
                                              delay={{ show: 100, hide: 200 }}
                                              overlay={
                                                <Popover id="popover-basic">
                                                  <Popover.Header className='salesforce-style' as="h3">Select Event</Popover.Header>
                                                  <Popover.Body>
                                                    <p className='p-0 m-0 helpTextSize'>
                                                      Use this checkbox to Perform bulk action like <span style={{ fontWeight: "500" }}>Delete</span>.
                                                    </p>
                                                  </Popover.Body>
                                                </Popover>
                                              }>
                                              <input type="checkbox" className="form-check-input p-0 m-0 cursor-pointer eventsTemplate" onClick={() => { addToChecked(event.id); }} />
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                              placement="top"
                                              delay={{ show: 100, hide: 200 }}
                                              overlay={
                                                <Popover id="popover-basic">
                                                  <Popover.Header className='salesforce-style' as="h3">Edit Event</Popover.Header>
                                                  <Popover.Body>
                                                    <p className='p-0 m-0 helpTextSize'>
                                                      Use this button to edit this event template.
                                                    </p>
                                                  </Popover.Body>
                                                </Popover>
                                              }>
                                              <Link to={`/edit/${event.id}`}>
                                                <i className='fa fa-edit text-dark cursor-pointer'></i>
                                              </Link>
                                            </OverlayTrigger>


                                            <OverlayTrigger
                                              placement="top"
                                              delay={{ show: 100, hide: 200 }}
                                              overlay={
                                                <Popover id="popover-basic">
                                                  <Popover.Header className='salesforce-style' as="h3">Delete Event</Popover.Header>
                                                  <Popover.Body>
                                                    <p className='p-0 m-0 helpTextSize'>
                                                      Use this button to delete this event template.
                                                    </p>
                                                  </Popover.Body>
                                                </Popover>
                                              }>
                                              <i className='fa fa-trash text-dark cursor-pointer' onClick={() => {
                                                setCustomAlertBox(true);
                                                setAlertBoxMessages(
                                                  <>
                                                    <p className='mediumTextSize'>Do you want to delete {event.eventName}?</p>
                                                    <p className='mediumTextSize'>Users will be unable to schedule further meetings with deleted event types.</p>
                                                    <p className='mediumTextSize'>Meetings previously scheduled will not be affected.</p>
                                                  </>
                                                );
                                                setAlertBoxIdData(event.id)
                                                setAlertErrorType("queryDel")
                                              }}></i>
                                            </OverlayTrigger>
                                          </div>
                                          <div className='d-flex align-items-center justify-content-end'>

                                            <OverlayTrigger
                                              placement="top"
                                              delay={{ show: 100, hide: 200 }}
                                              overlay={
                                                <Popover id="popover-basic">
                                                  <Popover.Header className='salesforce-style' as="h3">Secret Event</Popover.Header>
                                                  <Popover.Body>
                                                    <p className='p-0 m-0 helpTextSize'>
                                                      This is a secret event, that means it won't be visible on your booking page.
                                                    </p>
                                                  </Popover.Body>
                                                </Popover>
                                              }>
                                              <VisibilityOffIcon />
                                            </OverlayTrigger>
                                          </div>
                                        </div>
                                        <div className="p-3 d-flex flex-column gap-1 mediumTextSize h-100">
                                          <h6 className='p-0 m-0 text-break' style={{ fontWeight: "500" }}>{event.eventName}</h6>
                                          <div>
                                            {
                                              event.Duration > 60 ?
                                                event.Duration % 60 === 0 ?
                                                  Math.floor(event.Duration / 60) + " hrs" :
                                                  Math.floor(event.Duration / 60) + " hrs " + Math.floor(event.Duration % 60) + " min"
                                                : Math.floor(event.Duration) + " min"
                                            }
                                            {", "}
                                            {event.eventType}
                                          </div>
                                          <div className='text-break text-truncate'>
                                            {
                                              event.description
                                            }
                                          </div>
                                          <a href={event.EventLink} target="_parent" className="color-salesforce text-decoration-none mt-1" style={{ fontWeight: "500" }}>Booking Page</a>
                                        </div>
                                        <div className="border-top px-2 d-flex justify-content-between align-items-center">
                                          <CopyToClipboard text={"https://" + process.env.REACT_APP_DOMAINNAME + "" + event.EventLink}
                                            onCopy={() => {
                                              setViewToast(true); popupAlert.play();
                                              setToastTitle("Successful");
                                              setToastBody("Link Copied..!");
                                              setToastMessageType("success");

                                              // setCustomAlertBox(true);
                                              // setAlertBoxMessages("Link Copied..!");
                                              // setAlertErrorType("success");
                                            }}>
                                            <div style={{ cursor: "pointer" }}>
                                              <img alt='copy' src={copyImg} className="copyIcon" />
                                              <span className='px-1 text-dark smallTextSize' style={{ fontWeight: "600" }}>
                                                Copy Link
                                              </span>
                                            </div>
                                          </CopyToClipboard>

                                          <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 100, hide: 200 }}
                                            overlay={
                                              <Popover id="popover-basic">
                                                <Popover.Header className='salesforce-style' as="h3">Forward Link</Popover.Header>
                                                <Popover.Body>
                                                  <p className='m-0 p-0 helpTextSize'>Forward this event as a link to your invitees. So they could book meetings with you. Very usefull for <span style={{ fontWeight: "500" }}>Secret Events</span>.</p>
                                                </Popover.Body>
                                              </Popover>
                                            }>
                                            <CopyToClipboard text={"https://" + process.env.REACT_APP_DOMAINNAME + "" + event.EventLink}
                                              onCopy={() => {
                                                setViewToast(true); popupAlert.play();
                                                setToastTitle("Successful");
                                                setToastBody("Link Copied..!");
                                                setToastMessageType("success");

                                                // setCustomAlertBox(true);
                                                // setAlertBoxMessages("Link Copied..!");
                                                // setAlertErrorType("success");
                                              }}>
                                              {/* <button className='btn  rounded-pill'> */}
                                              <img alt="forward" src={forwardImg} className="copyIcon my-2 cursor-pointer" />
                                              {/* </button> */}
                                            </CopyToClipboard>
                                          </OverlayTrigger>
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                )
                              } else {
                                return (
                                  <React.Fragment key={key}>
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                                      <div className="mb-3 text-dark insightBackground shadow-sm rounded border overflow-hidden d-flex flex-column justify-content-between h-100">
                                        <div className={`d-flex align-items-center justify-content-between p-2 border-bottom event-shade-color-${event.EventColor}`}>
                                          <div className='d-flex gap-3 align-items-center justify-content-start'>
                                            <OverlayTrigger
                                              placement="top"
                                              delay={{ show: 100, hide: 200 }}
                                              overlay={
                                                <Popover id="popover-basic">
                                                  <Popover.Header className='salesforce-style' as="h3">Select Event</Popover.Header>
                                                  <Popover.Body>
                                                    <p className='p-0 m-0 helpTextSize'>
                                                      Use this checkbox to Perform bulk action like <span style={{ fontWeight: "500" }}>Delete</span>.
                                                    </p>
                                                  </Popover.Body>
                                                </Popover>
                                              }>

                                              <input type="checkbox" className="form-check-input p-0 m-0 eventsTemplate" onClick={() => { addToChecked(event.id); }} />
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                              placement="top"
                                              delay={{ show: 100, hide: 200 }}
                                              overlay={
                                                <Popover id="popover-basic">
                                                  <Popover.Header className='salesforce-style' as="h3">Edit Event</Popover.Header>
                                                  <Popover.Body>
                                                    <p className='p-0 m-0 helpTextSize'>
                                                      Use this button to edit this event template.
                                                    </p>
                                                  </Popover.Body>
                                                </Popover>
                                              }>
                                              <Link to={`/edit/${event.id}`} className="text-dark">
                                                <i className='fa fa-edit'></i>
                                              </Link>
                                            </OverlayTrigger>




                                            <OverlayTrigger
                                              placement="top"
                                              delay={{ show: 100, hide: 200 }}
                                              overlay={
                                                <Popover id="popover-basic">
                                                  <Popover.Header className='salesforce-style' as="h3">Delete Event</Popover.Header>
                                                  <Popover.Body>
                                                    <p className='p-0 m-0 helpTextSize'>
                                                      Use this button to delete this event template.
                                                    </p>
                                                  </Popover.Body>
                                                </Popover>
                                              }>
                                              <i className='fa fa-trash text-dark cursor-pointer' onClick={() => {
                                                setCustomAlertBox(true);
                                                setAlertBoxMessages(
                                                  <>
                                                    <p className='mediumTextSize'>Do you want to delete {event.eventName}?</p>
                                                    <p className='mediumTextSize'>Users will be unable to schedule further meetings with deleted event types.</p>
                                                    <p className='mediumTextSize'>Meetings previously scheduled will not be affected.</p>
                                                  </>
                                                );
                                                setAlertBoxIdData(event.id)
                                                setAlertErrorType("queryDel")
                                              }}></i>
                                            </OverlayTrigger>
                                          </div>
                                          <div className='d-flex align-items-center justify-content-end'>
                                            {/* <i className="fa fa-eye-slash dark-dark"  title="Secret Event"></i> */}
                                          </div>
                                        </div>
                                        <div className="p-3 d-flex flex-column gap-1 mediumTextSize h-100">
                                          <h6 className='p-0 m-0 text-break' style={{ fontWeight: "500" }}>{event.eventName}</h6>
                                          <div>
                                            {
                                              event.Duration > 60 ?
                                                event.Duration % 60 === 0 ?
                                                  Math.floor(event.Duration / 60) + " hrs" :
                                                  Math.floor(event.Duration / 60) + " hrs " + Math.floor(event.Duration % 60) + " min"
                                                : Math.floor(event.Duration) + " min"
                                            }
                                            {", "}
                                            {event.eventType}
                                          </div>
                                          <div className='text-break text-truncate'>
                                            {event.description}
                                          </div>
                                          <a href={event.EventLink} target="_parent" className="text-decoration-none color-salesforce mt-1" style={{ fontWeight: "500" }}>Booking Page</a>
                                        </div>
                                        <div className="border-top px-2 d-flex justify-content-between align-items-center">
                                          <CopyToClipboard text={"https://" + process.env.REACT_APP_DOMAINNAME + "" + event.EventLink}
                                            onCopy={() => {
                                              setViewToast(true); popupAlert.play();
                                              setToastTitle("Successful");
                                              setToastBody("Link Copied..!");
                                              setToastMessageType("success");

                                              // setCustomAlertBox(true);
                                              // setAlertBoxMessages("Link Copied..!");
                                              // setAlertErrorType("success");
                                            }}>
                                            <div style={{ cursor: "pointer" }}>
                                              <img alt='copy' src={copyImg} className="copyIcon" />
                                              <span className='px-1 text-dark smallTextSize' style={{ fontWeight: "600" }}>
                                                Copy Link
                                              </span>
                                            </div>
                                          </CopyToClipboard>

                                          <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 100, hide: 200 }}
                                            overlay={
                                              <Popover id="popover-basic">
                                                <Popover.Header className='salesforce-style' as="h3">Forward Link</Popover.Header>
                                                <Popover.Body>
                                                  <p className='m-0 p-0 helpTextSize'>Forward this event as a link to your invitees. So they could book meetings with you. Very usefull for <span style={{ fontWeight: "500" }}>Secret Events</span>.</p>
                                                </Popover.Body>
                                              </Popover>
                                            }>
                                            <CopyToClipboard text={"https://" + process.env.REACT_APP_DOMAINNAME + "" + event.EventLink}
                                              onCopy={() => {
                                                setViewToast(true); popupAlert.play();
                                                setToastTitle("Successful");
                                                setToastBody("Link Copied..!");
                                                setToastMessageType("success");

                                                // setCustomAlertBox(true);
                                                // setAlertBoxMessages("Link Copied..!");
                                                // setAlertErrorType("success");
                                              }}>
                                              <img alt='forward' src={forwardImg} className="copyIcon my-2 cursor-pointer" />
                                            </CopyToClipboard>
                                          </OverlayTrigger>
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                )
                              }
                            })
                            :
                            searchResult === false ?
                              (
                                <div className="col p-2 text-center">
                                  {/* <hr /> */}
                                  <img src={emptyFilterPng} alt="empty filter" style={{ maxWidth: "150px", width: "100%" }} />
                                  <p className='mediumTextSize'>Sorry, it looks like nothing matches that search criteria.</p>
                                  <p className='smallTextSize'>You can search Event Types by name, duration or description</p>
                                </div>
                              )
                              :
                              (
                                <div className="col p-2 text-center">
                                  {/* <hr /> */}
                                  <img src={emptyResultPng} alt="empty filter" style={{ maxWidth: "150px", width: "100%" }} />
                                  <p className='mediumTextSize '>You don't have any event types yet.</p>
                                  <p className='smallTextSize'>You'll want to add an event type to allow people to schedule with you.</p>
                                </div>
                              )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            {/* <Tab eventKey="workflows" title={<div className='h-100 glitchedPanTabsLinks color-salesforce' style={{ fontWeight: "500" }}>Workflows</div>}>
              <div className='allEvenets text-dark text-center'>
                {
                  // <Workflows flowlist="all" user={user} />
                }
                <div className="container-fluid">
                  <div className="row">
                    <div className="col py-2 bg-body"></div>
                  </div>
                  <div className="row my-3">
                    <div className="col-12 d-flex align-items-center justify-content-between ">
                      <p className='p-0 m-0'>Choose workflows from below or Create your own</p>
                      <button type='button' onClick={() => { setShowCustomWorkflowStep1(true) }} className='btn btn-sm px-3 py-1 border-0 salesforce-style rounded-pill'>create your own workflow</button>
                    </div>
                    <div className="col-12">
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-4 col-md-4 col-lg-3 mb-4">
                      <div className="h-100 border rounded insightBackground shadow-sm d-flex flex-column justify-content-between">
                        <div className="p-2 border-bottom">
                          <img src={emailTimer} alt="email" />
                        </div>
                        <div className="p-3 h-100">
                          <h5>Email reminder to invitee</h5>
                          <p className='p-0 m-0 mediumTextSize'>Send automated email reminders to invitees</p>
                        </div>
                        <div className="border-top p-2">
                          <button type="button" onClick={() => { setActiveWorkflow("EmailToInvitee"); }} className="btn btn-sm salesforce-style rounded-pill px-4 py-1 border-0">
                            Use workflow
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-lg-3 mb-4">
                      <div className="h-100 border rounded insightBackground shadow-sm d-flex flex-column justify-content-between">
                        <div className="p-2 border-bottom">
                          <img src={emailTimer} alt="email" />
                        </div>
                        <div className="p-3 h-100">
                          <h5>Email reminder to host</h5>
                          <p className='p-0 m-0 mediumTextSize'>Never miss an event — get automated email reminders</p>
                        </div>
                        <div className="border-top p-2">
                          <button type="button" onClick={() => { setActiveWorkflow("EmailToHost"); }} className="btn btn-sm salesforce-style rounded-pill px-4 py-1 border-0">
                            Use workflow
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-lg-3 mb-4">
                      <div className="h-100 border rounded insightBackground shadow-sm d-flex flex-column justify-content-between">
                        <div className="p-2 border-bottom">
                          <img src={emailHeart} alt="email" />
                        </div>
                        <div className="p-3 h-100">
                          <h5>Send thank you email</h5>
                          <p className='p-0 m-0 mediumTextSize'>Build relationships with a quick thanks</p>
                        </div>
                        <div className="border-top p-2">
                          <button type="button" onClick={() => { setActiveWorkflow("ThankYouEmail"); }} className="btn btn-sm salesforce-style rounded-pill px-4 py-1 border-0">
                            Use workflow
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-lg-3 mb-4">
                      <div className="h-100 border rounded insightBackground shadow-sm d-flex flex-column justify-content-between">
                        <div className="p-2 border-bottom">
                          <img src={phoneTimer} alt="text" />
                        </div>
                        <div className="p-3 h-100">
                          <h5>Text reminder to host</h5>
                          <p className='p-0 m-0 mediumTextSize'>Never miss an event — set automated text reminders</p>
                        </div>
                        <div className="border-top p-2">
                          <button type="button" onClick={() => { setActiveWorkflow("TextReminderToHost"); }} className="btn btn-sm salesforce-style rounded-pill px-4 py-1 border-0">
                            Use workflow
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-lg-3 mb-4">
                      <div className="h-100 border rounded insightBackground shadow-sm d-flex flex-column justify-content-between">
                        <div className="p-2 border-bottom">
                          <img src={phoneCheck} alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} />
                        </div>
                        <div className="p-3 h-100">
                          <h5>Text booking confirmation to host</h5>
                          <p className='p-0 m-0 mediumTextSize'>Keep hosts up-to-date with scheduled events</p>
                        </div>
                        <div className="border-top p-2">
                          <button type="button" onClick={() => { setActiveWorkflow("TextConfirmationToHost"); }} className="btn btn-sm salesforce-style rounded-pill px-4 py-1 border-0">
                            Use workflow
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-lg-3 mb-4">
                      <div className="h-100 border rounded insightBackground shadow-sm d-flex flex-column justify-content-between">
                        <div className="p-2 border-bottom">
                          <img src={emailEdit} alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} />
                        </div>
                        <div className="p-3 h-100">
                          <h5>Email your own feedback survey</h5>
                          <p className='p-0 m-0 mediumTextSize'>Email a survey link from a third party like Typeform or Google Forms to get feedback from invitees after your event</p>
                        </div>
                        <div className="border-top p-2">
                          <button type="button" onClick={() => { setActiveWorkflow("EmailFeedbackSurvey"); }} className="btn btn-sm salesforce-style rounded-pill px-4 py-1 border-0">
                            Use workflow
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {
                  activeWorkflow === "EmailToInvitee" ?
                    "Email Reminder To Invitee is Active Now"
                    :
                    activeWorkflow === "EmailToHost" ?
                      "Email Reminder To Host Active"
                      :
                      activeWorkflow === "ThankYouEmail" ?
                        "Thank You Email Active"
                        :
                        activeWorkflow === "TextReminderToHost" ?
                          "Text reminder to Host Active"
                          :
                          activeWorkflow === "TextConfirmationToHost" ?
                            "Text Booking Confirmation To Host Active"
                            :
                            activeWorkflow === "EmailFeedbackSurvey" ?
                              "Email Feedback for Surveys"
                              :
                              "No Workflows are triggered yet"
                }
              </div>
            </Tab> */}
          </Tabs>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas show={availabilityShow} onHide={() => { setAvailabilityShow(false); setViewOf(null); setAccountInnerView(null); setToHistory(null, null); }}
        scroll={true}
        backdrop={true}
        placement="end"
      >
        <Offcanvas.Header className='salesforce-style' closeButton closeVariant="white">
          <Offcanvas.Title>Availability</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='p-0 p-sm-3 p-md-3 p-lg-3 backgroundFiller bg-soul'>
          <div className="">
            <div className='mb-3'>
              <p className='smallTextNote p-3 m-0'>Choose a schedule below to edit or create a new one that you can apply to your event types</p>
              <div className="conatiner-fluid border p-3 rounded insightBackground">
                <div className="row mb-3">
                  <div className="col">
                    <span style={{ fontWeight: "600" }}>SCHEDULE TEMPLATES</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className='d-flex flex-wrap align-items-center gap-2'>
                      {
                        userSchedules &&
                        Object.keys(userSchedules).map((name, key) => {
                          if (scheduleName === name) {
                            return <button type='button' key={key} className='btn salesforce-style rounded-pill border shadow-sm' onClick={() => { toogleScheduleSet(name); setScheduleName(name); }}>{name}</button>
                          } else {
                            return <button type='button' key={key} className='btn btn-outline-dark rounded-pill border shadow-sm' onClick={() => { toogleScheduleSet(name); setScheduleName(name); }}>{name}</button>
                          }
                        })
                      }
                      {/* {
                            createNewSchedule &&
                            <input type="text" className="customInputFieldFocus w-100 rounded-pill" style={{ maxWidth: "250px" }} onChange={(e) => { setScheduleName(e.target.value); }} required placeholder="Name of the Schedule..." />
                        } */}
                      <button type="button" className='btn btn-outline-dark rounded-pill shadow-sm border' onClick={() => { setCreateNewSchedule(true); }}>+ New Schedule</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card insightBackground">
              <div className="card-header insightBackground" style={{ position: "relative", zIndex: "2" }}>
                <div className="container-fluid">
                  <div className="row mb-3">
                    <div className="col p-0">
                      <div className='w-100 d-flex justify-content-between align-items-center'>
                        <span className='' style={{ fontWeight: "600" }}>{scheduleName ? scheduleName : "Working Hours"}</span>
                        <OverlayTrigger
                          placement="left"
                          delay={{ show: 100, hide: 200 }}
                          overlay={
                            <Popover id="popover-basic">
                              <Popover.Header className='salesforce-style' as="h3">Delete Schedule</Popover.Header>
                              <Popover.Body>
                                <p className='p-0 m-0 helpTextSize'>By clicking this you can delete this schedule template.</p>
                              </Popover.Body>
                            </Popover>
                          }>
                          <i className='fa fa-trash cursor-pointer'
                            onClick={() => {
                              setCustomAlertBox(true);
                              setAlertBoxMessages(<p>Do you want to delete this schedule <br />"{scheduleName}"?</p>);
                              setAlertErrorType("queryDelSchedule");
                            }}></i>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-lg-6 p-0">
                      <div className='d-flex flex-column align-items-start gap-3 h-100 w-100'>

                        <div className='d-flex align-items-start flex-column justify-content-between gap-1 h-100 w-100' style={{ position: "relative", zIndex: "4" }}>
                          <label htmlFor='timezoneGds'>Country *</label>
                          <div className='w-100'>
                            <Select options={countriesList} value={countrySchedule} onChange={changeHandlerSchedule} />
                          </div>
                        </div>

                        <div className='d-flex align-items-start flex-column justify-content-between gap-1 h-100 w-100' style={{ position: "relative", zIndex: "3" }}>
                          <label htmlFor='timezoneGds'>Time Zone</label>
                          <div className='w-100'>
                            <Select value={[selectedTimezone]} options={filteredTimezonesListSchedule} onChange={(e) => { setSelectedTimezone(e); }} />
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      {/* <div className='d-flex align-items-end justify-content-end h-100'>
                          <span className='d-flex align-items-center'>
                              <button className='btn rounded-0 border btn-light d-flex align-items-center justify-content-start gap-1'>
                                  <i className='fa fa-list'></i>
                                  <span>List View</span>
                              </button>
                              <button className='btn rounded-0 d-flex align-items-center justify-content-start gap-1'>
                                  <i className='fa fa-calendar'></i>
                                  <span>Calendar View</span>
                              </button>
                          </span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-7 border rounded shadow-sm insightBackground">
                      <p className='text-center pt-4' style={{ fontWeight: "600" }}>Set your weekly hours</p>
                      {
                        availabilityShow &&
                        <AvailabilityTemplates
                          setIsSundayActive={setIsSundayActive}
                          setIsMondayActive={setIsMondayActive}
                          setIsTuesdayActive={setIsTuesdayActive}
                          setIsWednesdayActive={setIsWednesdayActive}
                          setIsThursdayActive={setIsThursdayActive}
                          setIsFridayActive={setIsFridayActive}
                          setIsSaturdayActive={setIsSaturdayActive}
                          isSundayActive={isSundayActive}
                          isMondayActive={isMondayActive}
                          isTuesdayActive={isTuesdayActive}
                          isWednesdayActive={isWednesdayActive}
                          isThursdayActive={isThursdayActive}
                          isFridayActive={isFridayActive}
                          isSaturdayActive={isSaturdayActive}
                          checkDayOnToggle={checkDayOnToggle}
                          schedule={schedule}
                          timeListToMeasure={timeListToMeasure}
                          filterTimeFieldNow={filterTimeFieldNow}
                          checkUpdatedStateOfTimeValidation={checkUpdatedStateOfTimeValidation}
                          checkValidTiming={checkValidTiming}
                          timeFormat={timeFormat}
                          addTimeSetIn={addTimeSetIn}
                          dayjs={dayjs}
                          deleteTimeSet={deleteTimeSet}
                          uuidv4={uuidv4}
                          ContentCopyIcon={ContentCopyIcon}
                          copyTimesTo={copyTimesTo}
                          categoryOfFrame={"Shortcut"}
                        />
                      }
                    </div>
                    <div className="col-12 col-lg-1 d-lg-flex py-2"></div>
                    <div className="col-12 col-md-12 col-lg-4 border rounded shadow-sm insightBackground">
                      <div className="alert d-flex flex-column align-items-center">
                        <p className='text-center' style={{ fontWeight: "600" }}>Date overrides</p>
                        <button type='button' className='btn btn-sm btn-outline-primary rounded-pill salesforce-style px-3' style={{ maxWidth: "max-content" }} onClick={() => { setViewDateOverridePanel(true); }}>
                          <AddIcon /> Add date override
                        </button>
                      </div>
                      {
                        dateOverrides ?
                          Object.keys(dateOverrides).length > 0 ?
                            Object.keys(dateOverrides).map((data, key) => {
                              return (
                                <div key={key} className={`card mb-2 datesOverrides_${dateOverrides[data].uid}`}>
                                  <div className='card-header salesforce-style d-flex align-items-center justify-content-between'>
                                    <span>{dayjs(dateOverrides[data].date).format("ddd, DD MMM YYYY")}</span>
                                    <span>
                                      <i className="fa fa-trash" style={{ padding: "0 5px" }} id={dateOverrides[data].uid} onClick={(e) => { deleteOverrideDate(dateOverrides[data].date, `datesOverrides_${dateOverrides[data].uid}`); }} />
                                    </span>
                                  </div>
                                  <div className='card-body d-flex justify-content-between align-items-center'>
                                    {
                                      dateOverrides[data].active ?
                                        <>
                                          <div className='d-flex flex-column align-items-start justify-content-center'>
                                            {
                                              dateOverrides[data].timeSet.map((time, key) => {
                                                const set = time.split("-");
                                                return (
                                                  <React.Fragment key={key}>
                                                    <button disabled className='btn btn-secondary border-0 mb-1 w-100'>
                                                      {
                                                        timeFormat.value === "12 Hours" ?
                                                          `${dayjs(`2 November 1999 ${set[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")}-${dayjs(`2 November 1999 ${set[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")}`
                                                          :
                                                          `${dayjs(`2 November 1999 ${set[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm")}-${dayjs(`2 November 1999 ${set[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm")}`
                                                      }
                                                    </button>
                                                  </React.Fragment>
                                                )
                                              })
                                            }
                                          </div>
                                        </>
                                        :
                                        <>
                                          <span>Unavailable</span>
                                        </>
                                    }
                                  </div>
                                </div>
                              )
                            })
                            :
                            <p className='text-center'>No overrides for now</p>
                          :
                          <p className='text-center'>No overrides for now</p>
                      }
                    </div>

                    <div className="col-12 col-lg-1"></div>
                  </div>

                </div>
              </div>
              <div className='card-footer'>
                <div className="py-3 d-flex align-items-center justify-content-center gap-3 w-100">
                  <button className='btn btn-sm btn-secondary rounded-pill px-4 py-1' type='button' onClick={() => { window.location.reload(); }}>Cancel</button>
                  <button className='btn btn-sm salesforce-style rounded-pill px-4 py-1' type='button' disabled={!updateButtonEnabled || !overrideButtonEnabled} onClick={(e) => { updateNewSchedule(e, "Shortcut"); }}>Update</button>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas show={messangerShow} onHide={() => { setMessangerShow(false); setViewOf(null); setAccountInnerView(null); setToHistory(null, null); }}
        scroll={true}
        backdrop={true}
        placement="end"
      >
        <Offcanvas.Header className='salesforce-style py-2' style={{ height: "5vh" }} closeButton closeVariant="white">
          <Offcanvas.Title>
            <span style={{ fontSize: "1rem" }}>Messenger</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='p-0 backgroundFiller bg-soul'>
          <Messanger people={people} user={user} serverUrl={serverUrl} socket={socket} setAskForDeleteMember={setAskForDeleteMember} setDeleteMemberEmail={setDeleteMemberEmail} />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas show={viewNewEventCreate} onHide={() => { setViewNewEventCreate(false); setMeetingShow(true); setViewOf("Meetings"); setToHistory("Meetings", "scheduleEvents"); }}
        scroll={true}
        backdrop={true}
        placement="end"
      >
        <Offcanvas.Header className='salesforce-style' closeButton closeVariant="white">
          <Offcanvas.Title>Create New Event</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='backgroundFiller bg-soul'>
          {/* Create Event Page ###################################################### */}

          <div className="container-fluid text-dark p-0">
            <div className="row mb-3">
              <div className="col">
                <div className="container-fluid insightBackground border rounded shadow-sm">
                  <div className="row">
                    <div className="col-12 col-md-10 col-lg-10 d-flex align-items-center">
                      <div className='p-2'>
                        <img alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} src={oneOnOneImg} className="EventImgLablels" />
                      </div>
                      <div className='p-2'>
                        <h6>One On One</h6>
                        <p className='m-0'>
                          Let an invitee pick a time to meet with you.
                        </p>
                      </div>
                    </div>
                    <div className='col-12 col-md-2 col-lg-2 d-flex align-items-center'>
                      <button className='btn salesforce-style rounded-pill py-1 w-100' onClick={() => { setViewNewEventCreate(false); setViewOf("NewOneOnOneMeeting"); setToHistory("NewOneOnOneMeeting", null); setOneOnOneEventCreation(true); setEventCreationSteps(1) }}>Create</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <div className="container-fluid insightBackground border rounded shadow-sm">
                  <div className="row">
                    <div className="col-12 col-md-10 col-lg-10 d-flex align-items-center">
                      <div className='p-2'>
                        <img alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} src={groupImg} className="EventImgLablels" />
                      </div>
                      <div className='p-2'>
                        <h6>Group</h6>
                        <p className='m-0'>
                          Let multiple invitees meet with you at one time.
                        </p>
                      </div>
                    </div>
                    <div className='col-12 col-md-2 col-lg-2 d-flex align-items-center'>
                      <button className='btn salesforce-style rounded-pill py-1 w-100' onClick={() => { setViewNewEventCreate(false); setViewOf("NewGroupMeeting"); setToHistory("NewGroupMeeting"); setGroupEventCreation(true); setGroupEventCreationSteps(1); }}>Create</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <div className="container-fluid insightBackground border rounded shadow-sm">
                  <div className="row">
                    <div className="col-12 col-md-10 col-lg-10 d-flex align-items-center">
                      <div className='p-2'>
                        <img alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} src={roundRobinImg} className="EventImgLablels" />
                      </div>
                      <div className='p-2'>
                        <h6>Round Robin</h6>
                        <p className='m-0'>
                          Create an event that cycles between multiple hosts.
                        </p>
                      </div>
                    </div>

                    <div className='col-12 col-md-2 col-lg-2 d-flex align-items-center'>
                      <button className='btn salesforce-style rounded-pill py-1 w-100' onClick={() => { setViewNewEventCreate(false); setViewOf("NewRoundRobinMeeting"); setToHistory("NewRoundRobinMeeting", null); setRoundRobinEventCreation(true); setRoundRobinCreationSteps(1); }}>Create</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas show={oneOnOneEventCreation} onHide={() => { setOneOnOneEventCreation(false); setViewNewEventCreate(true); setViewOf("NewMeetingCreation"); setToHistory("NewMeetingCreation", null); resetAllMeetingDetails(); }}
        scroll={true}
        backdrop={true}
        placement="start"
      >
        <Offcanvas.Header className='salesforce-style' closeButton closeVariant="white">
          <Offcanvas.Title>New One On One Event</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='backgroundFiller bg-soul py-0'>
          <div className={`container-fluid rounded-bottom ${eventCreationSteps === 1 ? "" : "d-none"}`}>
            <div className="row sticky-top">
              <div className="col p-0">
                <div className='eventPreview insightBackground shadow-sm border'>
                  <div style={{ height: "2em", width: "2em", borderRadius: "100%", pointerEvents: "none", aspectRatio: "1" }} className={` repo-event-color-${colorId}`} ></div>
                  <div>
                    <div className='prewEventWhich'>What event is this?</div>
                    <div className='eventNamePrew d-flex align-items-center gap-2 flex-wrap'>
                      <span className="text-capitalize text-break mediumTextSize" style={summary.includes(" ") ? { wordBreak: "break-word" } : { wordBreak: "break-all" }}>
                        {
                          summary.length > 0 ?
                            <>
                              {summary},
                            </>
                            :
                            ""
                        }
                      </span>
                      <span className="d-flex text-break align-items-center gap-1 mediumTextSize">
                        {
                          meetingType === "phone" ?
                            <span className='selectedLocation'>
                              <img src={callimg} alt="call" className='iconsForLocation' />
                              <span>{location}</span>
                            </span> :
                            meetingType === "meet" ?
                              <span className='selectedLocation'>
                                <img src={meetImg} alt="meet" className='iconsForLocation' />
                                <span>Google Meet Conference</span>
                              </span> :
                              meetingType === "zoom" ?
                                <span className='selectedLocation'>
                                  <img src={zoomImg} alt="Zoom" className='iconsForLocation' />
                                  <span>Zoom Conference</span>
                                  {
                                    needZoomToken ?
                                      <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                      : ""
                                  }
                                </span> :
                                meetingType === "goto" ?
                                  <span className='selectedLocation'>
                                    <img src={gotoImg} alt="GoTo" className='iconsForLocation' />
                                    <span>GoTo Conference</span>
                                    {
                                      needGTMToken ?
                                        <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                        : ""
                                    }
                                  </span> :
                                  meetingType === "webex" ?
                                    <span className='selectedLocation'>
                                      <img src={webexImg} alt="Webex" className='iconsForLocation' />
                                      <span>Webex Conference</span>
                                      {
                                        needWebexToken ?
                                          <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                          : ""
                                      }
                                    </span> :
                                    meetingType === "teams" ?
                                      <span className='selectedLocation'>
                                        <img src={teamsImg} alt="Teams" className='iconsForLocation' />
                                        <span>Teams Conference</span>
                                        {
                                          needTeamsToken ?
                                            <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                            : ""
                                        }
                                      </span> :
                                      meetingType === "address" && location.length > 0 ?
                                        <span className='selectedLocation'>
                                          <img src={addressImg} alt="address" className='iconsForLocation' />
                                          <span>{location}</span>
                                        </span> :
                                        "No location Given"
                        }
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <form id="eventFormHandle" className="eventGoogleForm p-0" onSubmit={nextPageToEvent}>
                  <br />
                  <div className="container-fluid p-0">
                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6 p-0">
                        <label htmlFor='summary' className='border w-100 p-2 insightBackground rounded'>
                          <span className='' style={{ fontWeight: "500" }}>
                            Event Name *
                          </span>
                          <input
                            maxLength={100}
                            type="text"
                            id="summary"
                            className='customInputFieldFocus w-100 border px-2'
                            value={summary}
                            required
                            placeholder="Add the name of the event"
                            onChange={e => setSummary(e.target.value)}
                          />
                        </label>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 p-0">

                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6 p-0">
                        <label htmlFor='selectLocation' className={`border w-100 p-2 insightBackground rounded ${locationSelection ? "mb-0" : ""}`} onClick={() => { setLocationSelection(!locationSelection); }}>
                          <span className='' style={{ fontWeight: "500" }}>
                            Select Location
                          </span>
                          <div id="selectLocation" className='locationFrame bg-soul rounded shadow-sm'>
                            {
                              meetingType ?
                                meetingType === "phone" ?
                                  <div className='selectedLocation'>
                                    <img alt="call" src={callimg} className='iconsForLocation' />
                                    <span>
                                      Phone call
                                    </span>
                                  </div>
                                  :
                                  meetingType === "meet" ?
                                    <div className='selectedLocation'>
                                      <img alt="meet" src={meetImg} className='iconsForLocation' />
                                      <span>
                                        Google Meet Conference
                                      </span>
                                    </div>
                                    :
                                    meetingType === "zoom" ?
                                      <div className='selectedLocation'>
                                        <img alt="zoom" src={zoomImg} className='iconsForLocation' />
                                        <span>
                                          Zoom Conference
                                        </span>
                                        {
                                          needZoomToken ?
                                            <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                            : ""
                                        }
                                      </div>
                                      :
                                      meetingType === "goto" ?
                                        <div className='selectedLocation'>
                                          <img alt="Goto" src={gotoImg} className='iconsForLocation' />
                                          <span>
                                            GoTo Conference
                                          </span>
                                          {
                                            needGTMToken ?
                                              <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                              : ""
                                          }
                                        </div>
                                        :
                                        meetingType === "webex" ?
                                          <div className='selectedLocation'>
                                            <img alt="Webex" src={webexImg} className='iconsForLocation' />
                                            <span>
                                              Webex Conference
                                            </span>
                                            {
                                              needWebexToken ?
                                                <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                : ""
                                            }
                                          </div>
                                          :
                                          meetingType === "teams" ?
                                            <div className='selectedLocation'>
                                              <img alt="Teams" src={teamsImg} className='iconsForLocation' />
                                              <span>
                                                Teams Conference
                                              </span>
                                              {
                                                needTeamsToken ?
                                                  <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                  : ""
                                              }
                                            </div>
                                            :
                                            meetingType === "address" ?
                                              <div className='selectedLocation'>
                                                <img alt="address" src={addressImg} className='iconsForLocation' />
                                                <span>
                                                  Custom Address
                                                </span>
                                              </div>
                                              :
                                              <div className='selectedLocation'>
                                                <img alt="address" src={addressImg} className='iconsForLocation' />
                                                <span>
                                                  Custom Address
                                                </span>
                                              </div>
                                :
                                ""
                            }

                          </div>
                        </label>


                        {
                          locationSelection &&
                          <div id='locationSelector' className="locationSelector bg-body rounded shadow-sm">
                            <ul>
                              <li title='Phone Call' onClick={() => { setMeetingType("phone"); setLocation("phone"); setLocationSelection(false); }}>
                                <span>
                                  <img alt="Call" src={callimg} className='iconsForLocation' />
                                </span>
                                <span>
                                  Phone Call
                                </span>
                              </li>
                              <li title='Google Meet Conference' onClick={() => { setMeetingType("meet"); setLocation("meet"); setLocationSelection(false); }}>
                                <span>
                                  <img alt="Meet" src={meetImg} className='iconsForLocation' />
                                </span>
                                <span>
                                  Google Meet Conference
                                </span>
                              </li>
                              <li title='Zoom Conference' onClick={() => { setMeetingType("zoom"); setLocation("zoom"); setLocationSelection(false); }}>
                                <span>
                                  <img alt="Zoom" src={zoomImg} className='iconsForLocation' />
                                </span>
                                <span>
                                  Zoom Conference
                                </span>
                                {
                                  needZoomToken ?
                                    <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                    : ""
                                }
                              </li>
                              <li title='GoTo Conference' onClick={() => { setMeetingType("goto"); setLocation("goto"); setLocationSelection(false); }}>
                                <span>
                                  <img alt="Goto" src={gotoImg} className='iconsForLocation' />
                                </span>
                                <span>
                                  GoTo Conference
                                </span>
                                {
                                  needGTMToken ?
                                    <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                    : ""
                                }
                              </li>
                              <li title='Webex Conference' onClick={() => { setMeetingType("webex"); setLocation("webex"); setLocationSelection(false); }}>
                                <span>
                                  <img alt="Webex" src={webexImg} className='iconsForLocation' />
                                </span>
                                <span>
                                  Webex Conference
                                </span>
                                {
                                  needWebexToken ?
                                    <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                    : ""
                                }
                              </li>
                              <li title='Teams Conference' onClick={() => { setMeetingType("teams"); setLocation("teams"); setLocationSelection(false); }}>
                                <span>
                                  <img alt="Teams" src={teamsImg} className='iconsForLocation' />
                                </span>
                                <span>
                                  Teams Conference
                                </span>
                                {
                                  needTeamsToken ?
                                    <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                    : ""
                                }
                              </li>
                              <li title='Address' onClick={() => { setMeetingType("address"); setLocationSelection(false); }}>
                                <span>
                                  <img alt="Address" src={addressImg} className='iconsForLocation' />
                                </span>
                                <span>
                                  Custom Address
                                </span>
                              </li>
                            </ul>
                          </div>
                        }

                        {
                          meetingType === "phone" && (
                            <label className={`border p-2 insightBackground  w-100 ${inboundCall ? "rounded-top" : "rounded"}`}>
                              <div className="askWhich">
                                <label htmlFor="outboundCalloff2">
                                  <input type="radio" defaultChecked id="outboundCalloff2" name="callBound" onClick={() => { setInboundCall(false); }} />
                                  I should call my invitees
                                </label>
                                <label htmlFor="inboundCallonn2">
                                  {
                                    phoneList ?
                                      optionListForPhone.length > 0 ?
                                        <>
                                          <input type="radio" id="inboundCallonn2" name="callBound" onClick={() => { setInboundCall(true); }} />
                                          <span>Invitees should call me</span>
                                        </>
                                        :
                                        <>
                                          <input type="radio" id="inboundCallonn2" name="callBound" disabled={true} onClick={() => { setInboundCall(true); }} />
                                          <span className='text-muted'>Invitees should call me</span>
                                        </>
                                      :
                                      <>
                                        <input type="radio" id="inboundCallonn2" name="callBound" disabled={true} onClick={() => { setInboundCall(true); }} />
                                        <span className='text-muted'>Invitees should call me</span>
                                      </>
                                  }
                                </label>
                              </div>

                            </label>
                          )
                        }


                        {
                          meetingType === "phone" && inboundCall ?
                            <label htmlFor='phoneCallOneOnOne' className='border p-2 insightBackground w-100 rounded-bottom' style={{ zIndex: "2", position: "relative" }}>
                              <span style={{ fontWeight: "500" }}>
                                Choose Phone Number *
                              </span>
                              <PhoneInput
                                required
                                className="mb-1 w-100 phoneNUmberInpurJHSGFUGDSFIY pointer-events-none"
                                style={{ pointerEvents: "none" }}
                                placeholder=""
                                value={location ? location : optionListForPhone[0].label}
                                onChange={setLocation} />

                              <Select
                                id='phoneCallOneOnOne'
                                options={optionListForPhone}
                                defaultValue={optionListForPhone[0]}
                                onChange={(e) => { setLocation(e.label) }}
                              />
                            </label>
                            :
                            meetingType === "address" ?
                              <label className='border p-2 insightBackground border w-100 rounded'>
                                <span style={{ fontWeight: "500" }}>
                                  Enter Address Here *
                                </span>
                                <input
                                  required
                                  type="text"
                                  className='customInputFieldFocus w-100 border px-2'
                                  defaultValue={""}
                                  placeholder=""
                                  onChange={(e) => setLocation(e.target.value)}
                                />
                              </label>
                              :
                              meetingType === "meet" && needToken ?
                                <label>
                                  <GoogleLogin
                                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                    buttonText='Sign in & Authorize Calendar'
                                    onSuccess={responceGoogle}
                                    onFailure={responseError}
                                    cookiePolicy={"single_host_origin"}
                                    fetchBasicProfile={true}
                                    responseType="code"
                                    prompt="consent"
                                    accessType='offline'
                                    scope='openid email profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'
                                  />
                                </label>
                                :
                                meetingType === "zoom" && needZoomToken ?
                                  <label>
                                    <a href={"#Integrations"} onClick={(e) => {
                                      setViewOf("Integrations");
                                      setViewAdminPage(true);

                                      setViewNewEventCreate(false);
                                      setOneOnOneEventCreation(false);
                                      setEventCreationSteps(1);
                                      setGroupEventCreation(false);
                                      setGroupEventCreationSteps(1);
                                      setRoundRobinEventCreation(false);
                                      setRoundRobinCreationSteps(1);
                                      setAlertShow(false);
                                      setContactShow(false);
                                      setMeetingShow(false);
                                      setAvailabilityShow(false);
                                    }} className='color-salesforce text-decoration-none mediumTextSize'>
                                      Click to Connect Zoom.
                                    </a>
                                  </label> :
                                  meetingType === "webex" && needWebexToken ?
                                    <label>
                                      <a href={"#Integrations"} onClick={(e) => {
                                        setViewOf("Integrations");
                                        setViewAdminPage(true);

                                        setViewNewEventCreate(false);
                                        setOneOnOneEventCreation(false);
                                        setEventCreationSteps(1);
                                        setGroupEventCreation(false);
                                        setGroupEventCreationSteps(1);
                                        setRoundRobinEventCreation(false);
                                        setRoundRobinCreationSteps(1);
                                        setAlertShow(false);
                                        setContactShow(false);
                                        setMeetingShow(false);
                                        setAvailabilityShow(false);
                                      }} className='color-salesforce text-decoration-none mediumTextSize'>
                                        Click to Connect Webex.
                                      </a>
                                    </label> :
                                    meetingType === "goto" && needGTMToken ?
                                      <label>
                                        <a href={"#Integrations"} onClick={(e) => {
                                          setViewOf("Integrations");
                                          setViewAdminPage(true);

                                          setViewNewEventCreate(false);
                                          setOneOnOneEventCreation(false);
                                          setEventCreationSteps(1);
                                          setGroupEventCreation(false);
                                          setGroupEventCreationSteps(1);
                                          setRoundRobinEventCreation(false);
                                          setRoundRobinCreationSteps(1);
                                          setAlertShow(false);
                                          setContactShow(false);
                                          setMeetingShow(false);
                                          setAvailabilityShow(false);
                                        }} className='color-salesforce text-decoration-none mediumTextSize'>
                                          Click to Connect GoTo Meeting.
                                        </a>
                                      </label> :
                                      meetingType === "teams" && needTeamsToken ?
                                        <label>
                                          <a href={"#Integrations"} onClick={(e) => {
                                            setViewOf("Integrations");
                                            setViewAdminPage(true);

                                            setViewNewEventCreate(false);
                                            setOneOnOneEventCreation(false);
                                            setEventCreationSteps(1);
                                            setGroupEventCreation(false);
                                            setGroupEventCreationSteps(1);
                                            setRoundRobinEventCreation(false);
                                            setRoundRobinCreationSteps(1);
                                            setAlertShow(false);
                                            setContactShow(false);
                                            setMeetingShow(false);
                                            setAvailabilityShow(false);
                                          }} className='color-salesforce text-decoration-none mediumTextSize'>
                                            Click to Connect Teams.
                                          </a>
                                        </label> :
                                        ""
                        }
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 p-0">

                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6 p-0">
                        <label htmlFor='description' className='border p-2 insightBackground w-100 rounded'>
                          <div className='d-flex align-items-center justify-content-between w-100'>
                            <span style={{ fontWeight: "500" }}>
                              Description *
                            </span>
                          </div>
                          {
                            isDescriptionRequired ?
                              <textarea
                                id="description"
                                className='customInputFieldFocus w-100 border px-2'
                                value={description}
                                required
                                placeholder="Write a summary and any details your invitee should know about the event."
                                onChange={e => setDescription(e.target.value)}
                              />
                              :
                              <textarea
                                id="description"
                                className='customInputFieldFocus w-100 border'
                                value={description}
                                placeholder="Write a summary and any details your invitee should know about the event."
                                onChange={e => setDescription(e.target.value)}
                              />
                          }
                        </label>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 p-0">

                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6 p-0">
                        <label htmlFor='description' className='border p-2 insightBackground w-100 rounded d-flex flex-column'>
                          <span className='d-flex gap-1 align-items-center' style={{ fontWeight: "500" }}>
                            Event Link *

                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 100, hide: 200 }}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Header className='salesforce-style' as="h3">Unique event links</Popover.Header>
                                  <Popover.Body>
                                    <p className='p-0 m-0 helpTextSize'>
                                      Use this field to change the unique url for this event.
                                    </p>
                                    <img src={uniqueLinkHelpText} className='img img-responsive w-100' alt='helpText' />
                                  </Popover.Body>
                                </Popover>
                              }>
                              <HelpOutlineIcon style={{ maxWidth: "15px", cursor: "help" }} />
                            </OverlayTrigger>
                          </span>
                          <span className='mediumTextSize text-muted'>{process.env.REACT_APP_DOMAINNAME + `/${username.replaceAll(" ", "-")}/`}</span>

                          <div className="input-group w-100 d-flex flex-nowrap rounded">
                            <input type="text" maxLength={50} id="UID" required className='customInputFieldFocus w-100 border px-2' onClick={(e) => { if (e.target.value.length === 0) { checkCustomLinkAvailable({ target: { value: summary.replaceAll(" ", "-") } }); } }} autoComplete="off" placeholder='UID' value={customEventLink} onChange={checkCustomLinkAvailable} />
                            <div className="input-group-append">
                              <span className="input-group-text h-100 border-0" style={{ borderRadius: "0px 0.375rem 0.375rem 0px" }}>
                                {
                                  isCustomLinkAvailable ?
                                    <i className='fa fa-check text-success'></i>
                                    :
                                    <i className='fa fa-times text-danger'></i>
                                }
                              </span>
                            </div>
                          </div>


                        </label>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 p-0">

                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12 p-0">
                        <label className='border p-2 insightBackground  w-100 rounded d-flex flex-column'>
                          <span className='pb-1' style={{ fontWeight: "500" }}>
                            Event Color
                          </span>
                          <div className='event-color-holder pb-2'>
                            <div onClick={(e) => { setColorId('1'); checkThisTab(e.target); }} className='event-color-tab event-color-1 checked-color'></div>
                            <div onClick={(e) => { setColorId('2'); checkThisTab(e.target); }} className='event-color-tab event-color-2'></div>
                            <div onClick={(e) => { setColorId('3'); checkThisTab(e.target); }} className='event-color-tab event-color-3'></div>
                            <div onClick={(e) => { setColorId('4'); checkThisTab(e.target); }} className='event-color-tab event-color-4'></div>
                            <div onClick={(e) => { setColorId('5'); checkThisTab(e.target); }} className='event-color-tab event-color-5'></div>
                            <div onClick={(e) => { setColorId('6'); checkThisTab(e.target); }} className='event-color-tab event-color-6'></div>
                            <div onClick={(e) => { setColorId('7'); checkThisTab(e.target); }} className='event-color-tab event-color-7'></div>
                            <div onClick={(e) => { setColorId('8'); checkThisTab(e.target); }} className='event-color-tab event-color-8'></div>
                            <div onClick={(e) => { setColorId('9'); checkThisTab(e.target); }} className='event-color-tab event-color-9'></div>
                            <div onClick={(e) => { setColorId('10'); checkThisTab(e.target); }} className='event-color-tab event-color-10'></div>
                            <div onClick={(e) => { setColorId('11'); checkThisTab(e.target); }} className='event-color-tab event-color-11'></div>
                          </div>
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12 p-0">
                        <center>
                          <button type='submit' disabled={!isCustomLinkAvailable} className='btn salesforce-style px-5 rounded-pill'>Next</button>
                        </center>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className={`container-fluid rounded-bottom ${eventCreationSteps === 2 ? "" : "d-none"}`}>
            <div className="row sticky-top">
              <div className="col p-0">
                <div className='eventPreview insightBackground shadow-sm border'>
                  <button type='button' className='btn align-self-center salesforce-style border rounded-circle' onClick={() => { setEventCreationSteps(1); }}>
                    <i className='fa fa-arrow-left'></i>
                  </button>
                  <img src={caneldarDark} alt="" className="iconsForLocation" />
                  <div>
                    <div className='prewEventWhich'>When can people book this event?</div>
                    <div className='eventNamePrew d-flex align-items-center gap-2 flex-wrap'>
                      <span id="eventNamePrew">
                        {
                          duration > 60 ?
                            Math.floor(duration % 60) === 0 ?
                              Math.floor(duration / 60) + " hrs"
                              : Math.floor(duration / 60) + " hrs, " + Math.floor(duration % 60) + " min"
                            : Math.floor(duration) + " min"
                        },
                      </span>
                      <span id="eventLocationPrew">{dayjs(betweenDays[0].startDate).format("D MMM YYYY")} - {betweenDays[0].endDate ? dayjs(betweenDays[0].endDate).format("D MMM YYYY") : "Continuous"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col p-0">
                <form onSubmit={(e) => { createOneToOneMeeting(e); }}>
                  <div className='container-fluid p-0'>

                    <div className="row my-3">
                      <div className="col">
                        <div className='p-3 insightBackground rounded border'>
                          <span style={{ fontWeight: "500" }}>
                            Date Range
                          </span>
                          <p className='m-0'>Set a range of dates when you can accept meetings.</p>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col">
                        <div className='rescheduler'>
                          <label className='h6' style={{ fontWeight: "500" }}>
                            Invitees can schedule...
                          </label>
                          {/* <div className="w-100 overflow-auto">
                            <DateRange
                              // editableDateInputs={true}
                              onChange={(item) => { console.log([item.selection]); setBetweenDays([item.selection]); }}
                              // showSelectionPreview={true}
                              // moveRangeOnFirstSelection={false}
                              months={2}
                              minDate={new Date()}
                              ranges={betweenDays}
                              direction="horizontal"
                              editableDateInputs={true}
                            />
                          </div> */}





                          <div className='w-100 d-flex align-items-center gap-2 mb-4'>
                            <label htmlFor="rollingDaysStraight" className={`d-flex align-items-center gap-2 ${straightRollingDays ? "" : "text-muted"}`}
                              onClick={() => {
                                setStraightRollingDays(true);
                                setWithinDateRange(false);
                                setInfiniteDateRange(false);
                                setBetweenDays([
                                  {
                                    startDate: new Date(),
                                    endDate: addDays(new Date(), parseInt(rollingDaysRef.current.value)),
                                    key: 'selection'
                                  }
                                ]);

                              }}
                            >
                              {
                                straightRollingDays ?
                                  <input type="radio" checked id='rollingDaysStraight' className='form-check-input p-0 m-0' name="rollingDays" />
                                  :
                                  <input type="radio" id='rollingDaysStraight' className='form-check-input p-0 m-0' name="rollingDays" />
                              }

                              <input
                                type="number"
                                disabled={!straightRollingDays}
                                defaultValue={30}
                                min={1}
                                required
                                ref={rollingDaysRef}
                                onClick={
                                  (e) => {
                                    setBetweenDays([
                                      {
                                        startDate: new Date(),
                                        endDate: addDays(new Date(), parseInt(e.target.value)),
                                        key: 'selection'
                                      }
                                    ]);
                                  }}
                                onInput={
                                  (e) => {
                                    setBetweenDays([
                                      {
                                        startDate: new Date(),
                                        endDate: addDays(new Date(), parseInt(e.target.value)),
                                        key: 'selection'
                                      }
                                    ]);

                                    // console.log(addDays(new Date(), parseInt(e.target.value)))
                                  }
                                } className='customInputFieldFocus rounded border' />
                            </label>
                            <div style={{ position: "relative", zIndex: "10" }}>
                              <Select
                                isDisabled={!straightRollingDays}
                                className='mediumTextSize'
                                options={
                                  [
                                    { label: "calendar days", value: "calendardays" },
                                    // { label: "weekdays", value: "weekdays" }
                                  ]
                                }
                                defaultValue={[{ label: "calendar days", value: "calendardays" }]}
                                onChange={(e) => { }}
                              />
                            </div>
                            <div className={`${straightRollingDays ? "" : "text-muted"}`}>
                              into the future
                            </div>
                          </div>

                          <div className='w-100 d-flex align-items-center gap-2 mb-4'>
                            <label htmlFor="betweenForever" className={`d-flex align-items-center gap-2 ${withinDateRange ? "" : "text-muted"}`}
                              onClick={(e) => {
                                setStraightRollingDays(false);
                                setWithinDateRange(true);
                                setInfiniteDateRange(false);
                              }}
                            >
                              {
                                withinDateRange ?
                                  <input type="radio" checked id='betweenForever' className='form-check-input p-0 m-0' name="rollingDays" />
                                  :
                                  <input type="radio" id='betweenForever' className='form-check-input p-0 m-0' name="rollingDays" />
                              }
                              <span className='mediumTextSize'>Within a date range</span>
                            </label>
                            {
                              withinDateRange ?
                                <div className='flex-fill' style={{ position: "relative", zIndex: "9", maxWidth: "max-content" }} onClick={() => { setShowDateRangeSelector(true); setWithinDateRange(true); setStraightRollingDays(false); setInfiniteDateRange(false); }}>
                                  <input type="text" disabled={!withinDateRange} value={`${dayjs(betweenDays[0].startDate).format("D MMM YYYY")} - ${betweenDays[0].endDate ? dayjs(betweenDays[0].endDate).format("D MMM YYYY") : "Continuous"}`} className=" w-100 rounded p-2 border mediumTextSize" />
                                </div>
                                :
                                ""
                            }
                          </div>

                          <div className='w-100 mb-4'>
                            <label htmlFor="foreversRoll" className={`d-flex align-items-center gap-2  ${infiniteDateRange ? "" : "text-muted"}`}
                              onClick={(e) => {
                                setStraightRollingDays(false);
                                setWithinDateRange(false);
                                setInfiniteDateRange(true);

                                setBetweenDays([
                                  {
                                    startDate: new Date(),
                                    endDate: null,
                                    key: 'selection'
                                  }
                                ]);
                              }}
                            >
                              {
                                infiniteDateRange ?
                                  <input type="radio" checked id='foreversRoll' className='form-check-input p-0 m-0' name="rollingDays" />
                                  :
                                  <input type="radio" id='foreversRoll' className='form-check-input p-0 m-0' name="rollingDays" />
                              }
                              <span className={`mediumTextSize`}>Indefinitely into the future</span>
                            </label>
                          </div>


                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12">
                        <label htmlFor='endDateTime' className='w-100 border rounded p-3 insightBackground' style={{ position: "relative", zIndex: "5" }}>
                          <span style={{ fontWeight: "500" }}>Meeting Duration</span>
                          <p className='m-0 mb-2 mediumTextSize'>Define how long your event will be. It can be as long as 12 hours.</p>
                          <div className="container-fluid p-0">
                            <div className="row">
                              <div className="col-12 col-md-6 col-lg-6">
                                {/* <DropdownButton
                                  as="buttonGroup"
                                  className='w-100'
                                  id={`dropdown-button-drop-down-one-on-one`}
                                  drop={"right"}
                                  variant="light"
                                  title={
                                    duration > 60 ?
                                      Math.floor(duration % 60) === 0 ?
                                        Math.floor(duration / 60) + " hrs"
                                        : Math.floor(duration / 60) + " hrs, " + Math.floor(duration % 60) + " min"
                                      : Math.floor(duration) + " min"
                                  }
                                  onSelect={(e, event) => { if (e !== "custom") { setDuration(e); setCustomHourView(false); } else { setCustomHourView(true); setDuration(15) } }}

                                >
                                  <Dropdown.Item eventKey="15">15 minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="30">30 minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="45">45 minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="60">60 minutes</Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item eventKey="custom">Custom</Dropdown.Item>
                                </DropdownButton> */}

                                <Select
                                  options={meetingDurationListForSelect}
                                  defaultValue={[{ label: "30 minutes", value: "30" }]}
                                  onChange={(e) => { if (e.value !== "custom") { setDuration(e.value); setCustomHourView(false); } else { setCustomHourView(true); setDuration(15) } }}
                                />

                                {
                                  customHourView ?
                                    <div className='d-flex justify-content-center align-items-center gap-2 my-2'>
                                      {
                                        durationType === "hours" ?
                                          <input className='customInputFieldFocus css-13cymwt-control w-100' required type="number" min="1" max="12" defaultValue={"1"} ref={minutesOneOnOne} onChange={(e) => { convertToMinutesOneOnOne(e.target.value); }} />
                                          :
                                          <input className='customInputFieldFocus css-13cymwt-control w-100' required type="number" min="15" max="720" defaultValue={"15"} ref={minutesOneOnOne} onChange={(e) => { convertToMinutesOneOnOne(e.target.value); }} />
                                      }

                                      {/* <DropdownButton
                                  as="buttonGroup"
                                  className='w-100'
                                  id={`dropdown-button-drop-down`}
                                  drop={"down"}
                                  variant="light"
                                  title={durationType}

                                  onSelect={(e, event) => { setDurationType(e) }}

                                >
                                  <Dropdown.Item eventKey="minutes">minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="hours">hours</Dropdown.Item>
                                </DropdownButton> */}

                                      <Select
                                        className='w-50'
                                        options={[{ label: "minutes", value: "minutes" }, { label: "hours", value: "hours" }]}
                                        defaultValue={[{ label: "minutes", value: "minutes" }]}
                                        onChange={(e) => { setDurationType(e.value); }}
                                      />
                                    </div>
                                    :
                                    ""
                                }
                              </div>
                            </div>
                          </div>
                        </label>

                      </div>
                    </div>

                    <div className='row mb-3'>
                      <div className="col">
                        <div className='w-100 border rounded p-3 insightBackground'>
                          <span style={{ fontWeight: "500" }}>How do you want to offer your availability for this event type?</span>
                          <p>Select one of your schedules or define custom hours specific to this type of event.</p>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col">
                        <div className='w-100'>
                          <button type='button' className={`btn  ${existingScheduleView ? "salesforce-style" : "bg-body border"}`} onClick={() => { setExistingScheduleView(true); setCustomScheduleView(false); }}>Use an existing schedule</button>
                          <button type='button' className={`btn mx-3  ${customScheduleView ? "salesforce-style" : "bg-body border"}`} onClick={() => { setExistingScheduleView(false); setCustomScheduleView(true); }}>Set custom hours</button>
                        </div>
                      </div>
                    </div>

                    {/* Existing schedule */}

                    <div className={`row mb-3 ${existingScheduleView ? "" : "d-none"}`}>
                      <div className="col">

                        <div className="rounded text-dark insightBackground border" style={{ position: "relative", zIndex: "4" }}>
                          <div className="border-bottom p-3">
                            <span className='mb-1 w-100 d-block' style={{ fontWeight: "500" }}>Which schedule type do you want to use for this event type?</span>

                            {/* <select className=' my-2' value={scheduleName} onChange={(e) => { toogleScheduleSet(e.target.value); setScheduleName(e.target.value); }}>
                                  {
                                    userSchedules ?
                                      Object.keys(userSchedules).map((name, key) => {
                                        return <option value={name}>{name}</option>
                                      })
                                      :
                                      <option value="working hours">Working Hours</option>
                                  }
                                </select> */}
                            {/* <DropdownButton
                                as="buttonGroup"
                                className='w-100'
                                drop={"down"}
                                variant="light"
                                title={scheduleName ? scheduleName : ""}
                                onSelect={(e, event) => { toogleScheduleSet(e); setScheduleName(e); }}

                              >
                                {
                                  userSchedules ?
                                    Object.keys(userSchedules).map((name, key) => {
                                      return <Dropdown.Item key={key} eventKey={name}>{name}</Dropdown.Item>
                                    })
                                    :
                                    <Dropdown.Item eventKey="working hours">Working Hours</Dropdown.Item>
                                }
                              </DropdownButton> */}

                            <Select
                              options={userScheduleListForSelect}
                              defaultValue={[{ label: scheduleName, value: scheduleName }]}
                              onChange={(e) => { toogleScheduleSet(e.label); setScheduleName(e.label); }}
                            />


                            <span style={{ fontWeight: "500" }} className="d-block mb-1 mt-3">
                              Time Zone
                            </span>
                            <Select value={[selectedTimezone]} options={filteredTimezonesListSchedule} isDisabled={true} onChange={(e) => { setSelectedTimezone(e); }} />
                            <div className="d-flex align-items-center gap-2 my-3">
                              <i className='fa fa-globe fs-5'></i>
                              <span style={{ fontWeight: "600" }}>
                                {selectedTimezone.value ? selectedTimezone.value.name : ""}
                              </span>
                            </div>
                            <p className='mediumTextSize'>Automatically detected timezone is {selectedTimezone.value ? selectedTimezone.value.name : ""}. You may change it to specify default time-zone for your calendar.</p>
                          </div>
                          <div className="p-2">
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-12 col-lg-8 ">
                                  <div className="container-fluid bg-body rounded border h-100">
                                    <div className="row">
                                      <div className="col-4 col-md-2 col-lg-2 h6 p-2">SUN</div>
                                      <div className="col-8 col-md-10 col-lg-10 p-2">
                                        {
                                          isSundayActive ?
                                            schedule.sunday.times.map((time, key) => {
                                              return <p className='w-100 mb-2 text-start' key={key}>
                                                {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                              </p>
                                            })
                                            :
                                            <span className='text-muted'>unavailable</span>
                                        }
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className=" col-4 col-md-2 col-lg-2 h6 p-2">MON</div>
                                      <div className="col-8 col-md-10 col-lg-10 p-2">
                                        {
                                          isMondayActive ?
                                            schedule.monday.times.map((time, key) => {
                                              return <p className='w-100 mb-2 text-start' key={key}>
                                                {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                              </p>
                                            })
                                            :
                                            <span className='text-muted'>unavailable</span>
                                        }
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className=" col-4 col-md-2 col-lg-2 h6 p-2">TUE</div>
                                      <div className="col-8 col-md-10 col-lg-10 p-2">
                                        {
                                          isTuesdayActive ?
                                            schedule.tuesday.times.map((time, key) => {
                                              return <p className='w-100 mb-2 text-start' key={key}>
                                                {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                              </p>
                                            })
                                            :
                                            <span className='text-muted'>unavailable</span>
                                        }
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className=" col-4 col-md-2 col-lg-2 h6 p-2">WED</div>
                                      <div className="col-8 col-md-10 col-lg-10 p-2">
                                        {
                                          isWednesdayActive ?
                                            schedule.wednesday.times.map((time, key) => {
                                              return <p className='w-100 mb-2 text-start' key={key}>
                                                {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                              </p>
                                            })
                                            :
                                            <span className='text-muted'>unavailable</span>
                                        }
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className=" col-4 col-md-2 col-lg-2 h6 p-2">THU</div>
                                      <div className="col-8 col-md-10 col-lg-10 p-2">
                                        {
                                          isThursdayActive ?
                                            schedule.thursday.times.map((time, key) => {
                                              return <p className='w-100 mb-2 text-start' key={key}>
                                                {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                              </p>
                                            })
                                            :
                                            <span className='text-muted'>unavailable</span>
                                        }
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className=" col-4 col-md-2 col-lg-2 h6 p-2">FRI</div>
                                      <div className="col-8 col-md-10 col-lg-10 p-2">
                                        {
                                          isFridayActive ?
                                            schedule.friday.times.map((time, key) => {
                                              return <p className='w-100 mb-2 text-start' key={key}>
                                                {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                              </p>
                                            })
                                            :
                                            <span className='text-muted'>unavailable</span>
                                        }
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className=" col-4 col-md-2 col-lg-2 h6 p-2">SAT</div>
                                      <div className="col-8 col-md-10 col-lg-10 p-2">
                                        {
                                          isSaturdayActive ?
                                            schedule.saturday.times.map((time, key) => {
                                              return <p className='w-100 mb-2 text-start' key={key}>
                                                {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                              </p>
                                            })
                                            :
                                            <span className='text-muted'>unavailable</span>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-12 col-lg-4">
                                  <h5 className='py-3 text-center'>Date Overrides</h5>
                                  {
                                    dateOverrides ?
                                      Object.keys(dateOverrides).length > 0 ?
                                        Object.keys(dateOverrides).map((data, key) => {
                                          return (
                                            <div key={key} className={`card mb-2 datesOverrides_${dateOverrides[data].uid}`}>
                                              <div className='card-header salesforce-style d-flex align-items-center justify-content-between'>
                                                <span>{dateOverrides[data].date}</span>
                                                {/* <span><i className='fa fa-trash' id={dateOverrides[data].uid} onClick={(e) => { deleteOverrideDate(dateOverrides[data].date, `datesOverrides_${dateOverrides[data].uid}`); }}></i></span> */}
                                              </div>
                                              <div className='card-body d-flex justify-content-between align-items-center'>
                                                {
                                                  dateOverrides[data].active ?
                                                    <>
                                                      <div className='d-flex flex-column align-items-start justify-content-center'>
                                                        {
                                                          dateOverrides[data].timeSet.map((time, key) => {
                                                            return (
                                                              <React.Fragment key={key}>
                                                                <button disabled className='btn btn-secondary border-0 mb-1 w-100'>
                                                                  {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                </button>
                                                              </React.Fragment>
                                                            )
                                                          })
                                                        }
                                                      </div>
                                                    </>
                                                    :
                                                    <>
                                                      <span className='text-muted'>unavailable</span>
                                                    </>
                                                }
                                              </div>
                                            </div>
                                          )
                                        })
                                        :
                                        <p className='text-center'>No overrides for now</p>
                                      :
                                      <p className='text-center'>No overrides for now</p>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`row mb-3 ${customScheduleView ? "" : "d-none"}`} >
                      <div className="col-12">

                        {/* ######################## NEW SCHEDULE SYSTEM GROUP ######################### */}

                        <div className="">
                          <div className='mb-3'>
                            <p className='smallTextNote p-3 m-0'>Choose a schedule below to edit or create a new one that you can apply to your event types</p>
                            <div className="conatiner-fluid border p-3 rounded insightBackground">
                              <div className="row mb-3">
                                <div className="col">
                                  <span style={{ fontWeight: "600" }}>SCHEDULE TEMPLATES</span>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <div className='d-flex flex-wrap align-items-center gap-2'>
                                    {
                                      userSchedules &&
                                      Object.keys(userSchedules).map((name, key) => {
                                        if (scheduleName === name) {
                                          return <button type='button' key={key} className='btn salesforce-style rounded-pill border shadow-sm' onClick={() => { toogleScheduleSet(name); setScheduleName(name); }}>{name}</button>
                                        } else {
                                          return <button type='button' key={key} className='btn btn-outline-dark rounded-pill border shadow-sm' onClick={() => { toogleScheduleSet(name); setScheduleName(name); }}>{name}</button>
                                        }
                                      })
                                    }
                                    {/* {
                                              createNewSchedule &&
                                              <input type="text" className="customInputFieldFocus w-100 rounded-pill" style={{ maxWidth: "250px" }} onChange={(e) => { setScheduleName(e.target.value); }} required placeholder="Name of the Schedule..." />
                                          } */}
                                    <button type='button' className='btn btn-outline-dark rounded-pill shadow-sm border' onClick={() => { setCreateNewSchedule(true); }}>+ New Schedule</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card insightBackground">
                            <div className="card-header insightBackground" style={{ position: "relative", zIndex: "2" }}>
                              <div className="container-fluid">
                                <div className="row mb-3">
                                  <div className="col p-0">
                                    <div className='w-100 d-flex justify-content-between align-items-center'>
                                      <span className='' style={{ fontWeight: "600" }}>{scheduleName ? scheduleName : "Working Hours"}</span>
                                      <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 100, hide: 200 }}
                                        overlay={
                                          <Popover id="popover-basic">
                                            <Popover.Header className='salesforce-style' as="h3">Delete Schedule</Popover.Header>
                                            <Popover.Body>
                                              <p className='p-0 m-0 helpTextSize'>By clicking this you can delete this schedule template.</p>
                                            </Popover.Body>
                                          </Popover>
                                        }>
                                        <i className='fa fa-trash cursor-pointer'
                                          onClick={() => {
                                            setCustomAlertBox(true);
                                            setAlertBoxMessages(<p>Do you want to delete this schedule <br />"{scheduleName}"?</p>);
                                            setAlertErrorType("queryDelSchedule");
                                          }}
                                        ></i>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-12 col-lg-6 p-0">
                                    <div className='d-flex flex-column align-items-start gap-3 h-100 w-100'>

                                      <div className='d-flex align-items-start flex-column justify-content-between gap-1 h-100 w-100' style={{ position: "relative", zIndex: "4" }}>
                                        <label htmlFor='timezoneGds'>Country *</label>
                                        <div className='w-100'>
                                          <Select options={countriesList} value={countrySchedule} onChange={changeHandlerSchedule} />
                                        </div>
                                      </div>

                                      <div className='d-flex align-items-start flex-column justify-content-between gap-1 h-100 w-100' style={{ position: "relative", zIndex: "3" }}>
                                        <label htmlFor='timezoneGds'>Time Zone</label>
                                        <div className='w-100'>
                                          <Select value={[selectedTimezone]} options={filteredTimezonesListSchedule} onChange={(e) => { setSelectedTimezone(e); }} />
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-6">

                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card-body">
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-12 col-md-12 col-lg-7 border rounded shadow-sm insightBackground">
                                    <p className='text-center pt-4' style={{ fontWeight: "600" }}>Set your weekly hours</p>
                                    {
                                      <AvailabilityTemplates
                                        setIsSundayActive={setIsSundayActive}
                                        setIsMondayActive={setIsMondayActive}
                                        setIsTuesdayActive={setIsTuesdayActive}
                                        setIsWednesdayActive={setIsWednesdayActive}
                                        setIsThursdayActive={setIsThursdayActive}
                                        setIsFridayActive={setIsFridayActive}
                                        setIsSaturdayActive={setIsSaturdayActive}
                                        isSundayActive={isSundayActive}
                                        isMondayActive={isMondayActive}
                                        isTuesdayActive={isTuesdayActive}
                                        isWednesdayActive={isWednesdayActive}
                                        isThursdayActive={isThursdayActive}
                                        isFridayActive={isFridayActive}
                                        isSaturdayActive={isSaturdayActive}
                                        checkDayOnToggle={checkDayOnToggle}
                                        schedule={schedule}
                                        timeListToMeasure={timeListToMeasure}
                                        filterTimeFieldNow={filterTimeFieldNow}
                                        checkUpdatedStateOfTimeValidation={checkUpdatedStateOfTimeValidation}
                                        checkValidTiming={checkValidTiming}
                                        timeFormat={timeFormat}
                                        addTimeSetIn={addTimeSetIn}
                                        dayjs={dayjs}
                                        deleteTimeSet={deleteTimeSet}
                                        uuidv4={uuidv4}
                                        ContentCopyIcon={ContentCopyIcon}
                                        copyTimesTo={copyTimesTo}
                                        categoryOfFrame={"OneOnOne"}
                                      />
                                    }

                                  </div>
                                  <div className="col-12 col-lg-1 d-lg-flex py-2"></div>
                                  <div className="col-12 col-md-12 col-lg-4 border rounded shadow-sm insightBackground">
                                    <div className="alert d-flex flex-column align-items-center">
                                      <p className='text-center' style={{ fontWeight: "600" }}>Date overrides</p>
                                      <button type='button' className='btn btn-sm btn-outline-primary rounded-pill salesforce-style px-3 w-100' style={{ maxWidth: "max-content" }} onClick={() => { setViewDateOverridePanel(true); }}>
                                        <AddIcon /> Add date override
                                      </button>
                                    </div>
                                    {
                                      dateOverrides ?
                                        Object.keys(dateOverrides).length > 0 ?
                                          Object.keys(dateOverrides).map((data, key) => {
                                            return (
                                              <div key={key} className={`card mb-2 datesOverrides_${dateOverrides[data].uid}`}>
                                                <div className='card-header salesforce-style d-flex align-items-center justify-content-between'>
                                                  <span>{dayjs(dateOverrides[data].date).format("ddd, DD MMM YYYY")}</span>
                                                  <span>
                                                    <i className="fa fa-trash" style={{ padding: "0 5px" }} id={dateOverrides[data].uid} onClick={(e) => { deleteOverrideDate(dateOverrides[data].date, `datesOverrides_${dateOverrides[data].uid}`); }} />
                                                  </span>
                                                </div>
                                                <div className='card-body d-flex justify-content-between align-items-center'>
                                                  {
                                                    dateOverrides[data].active ?
                                                      <>
                                                        <div className='d-flex flex-column align-items-start justify-content-center'>
                                                          {
                                                            dateOverrides[data].timeSet.map((time, key) => {
                                                              const set = time.split("-");
                                                              return (
                                                                <React.Fragment key={key}>
                                                                  <button disabled className='btn btn-secondary border-0 mb-1 w-100'>
                                                                    {
                                                                      timeFormat.value === "12 Hours" ?
                                                                        `${dayjs(`2 November 1999 ${set[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")}-${dayjs(`2 November 1999 ${set[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")}`
                                                                        :
                                                                        `${dayjs(`2 November 1999 ${set[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm")}-${dayjs(`2 November 1999 ${set[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm")}`
                                                                    }
                                                                  </button>
                                                                </React.Fragment>
                                                              )
                                                            })
                                                          }
                                                        </div>
                                                      </>
                                                      :
                                                      <>
                                                        <span>Unavailable</span>
                                                      </>
                                                  }
                                                </div>
                                              </div>
                                            )
                                          })
                                          :
                                          <p className='text-center'>No overrides for now</p>
                                        :
                                        <p className='text-center'>No overrides for now</p>
                                    }
                                  </div>

                                  <div className="col-12 col-lg-1"></div>
                                </div>
                              </div>
                            </div>
                            <div className="card-footer d-flex align-items-center justify-content-center gap-3">
                              {/* <button style={{ maxWidth: "120px" }} className='w-100 text-center btn btn-secondary rounded-pill py-1' type='button' onClick={() => { setCreateNewSchedule(false); setSchedule(defaultSchedule); setDateOverrides(defaultOverride); window.location.reload(); }}>Cancel</button> */}
                              <button style={{ maxWidth: "max-content" }} className='w-100 text-center btn salesforce-style rounded-pill py-1' type='button' disabled={!updateButtonEnabled || !isCustomLinkAvailable} onClick={(e) => { updateNewSchedule(e, "OneOnOne"); }}>Save Schedule</button>
                            </div>
                          </div>
                        </div>

                        {/* ##################### END OF NEW SCHEDULE SYSTEM ONE TO ONE ###################### */}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6 mb-4">
                        <div className="container-fluid border insightBackground rounded" style={{ position: "relative", zIndex: "3" }}>
                          <div className="row">
                            <div className="col-12">
                              <span style={{ fontWeight: "500" }} className='my-3 d-block'>Want to add time before or after your events?</span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-12 d-flex align-items-center gap-4 py-2">
                              {
                                eventBeforeTimeActive && (
                                  <input type="checkbox" defaultChecked onClick={() => { setEventBeforeTimeActive(false); }} className='form-check-input checkboxInpage' />
                                )
                              }
                              {
                                eventBeforeTimeActive === false && (
                                  <input type="checkbox" onClick={() => { setEventBeforeTimeActive(true); }} className='form-check-input checkboxInpage' />
                                )
                              }

                              <div className=''>
                                <h6 className={`${eventBeforeTimeActive ? "" : "text-muted"}`}>
                                  Before Event
                                </h6>

                                {/* <DropdownButton
                                  as="buttonGroup"
                                  className='w-100'
                                  id={`dropdown-button-drop-down`}
                                  drop={"down"}
                                  variant="light"
                                  disabled={eventBeforeTimeActive ? false : true}
                                  title={
                                    eventBeforeTime > 60 ?
                                      Math.floor(eventBeforeTime % 60) === 0 ?
                                        Math.floor(eventBeforeTime / 60) + " hrs"
                                        : Math.floor(eventBeforeTime / 60) + " hrs, " + Math.floor(eventBeforeTime % 60) + " min"
                                      : Math.floor(eventBeforeTime) + " min"
                                  }
                                  onSelect={(e, event) => { setEventBeforeTime(e); }}

                                >
                                  <Dropdown.Item eventKey="15">15 minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="30">30 minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="45">45 minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="60">1 hr</Dropdown.Item>
                                  <Dropdown.Item eventKey="90">1 hr 30 min</Dropdown.Item>
                                  <Dropdown.Item eventKey="120">2 hr</Dropdown.Item>
                                  <Dropdown.Item eventKey="150">2 hr 30 min</Dropdown.Item>
                                  <Dropdown.Item eventKey="180">3 hr</Dropdown.Item>
                                </DropdownButton> */}
                                <Select
                                  isDisabled={eventBeforeTimeActive ? false : true}
                                  options={addTimeAfterBeforeForSelect}
                                  defaultValue={[{ label: "30 minutes", value: 30 }]}
                                  onChange={(e) => { setEventBeforeTime(e.value) }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mb-2">
                            <div className="col-12 d-flex align-items-center gap-4 py-2">
                              <div>
                                {
                                  eventAfterTimeActive && (
                                    <input type="checkbox" defaultChecked onClick={() => { setEventAfterTimeActive(false); }} className='form-check-input checkboxInpage' />
                                  )
                                }
                                {
                                  eventAfterTimeActive === false && (
                                    <input type="checkbox" onClick={() => { setEventAfterTimeActive(true); }} className='form-check-input checkboxInpage' />
                                  )
                                }
                              </div>
                              <div>
                                <h6 className={`${eventAfterTimeActive ? "" : "text-muted"}`}>
                                  After Event
                                </h6>
                                {/* <DropdownButton
                                  as="buttonGroup"
                                  className='w-100'
                                  id={`dropdown-button-drop-down`}
                                  drop={"down"}
                                  variant="light"
                                  disabled={eventAfterTimeActive ? false : true}
                                  title={
                                    eventAfterTime > 60 ?
                                      Math.floor(eventAfterTime % 60) === 0 ?
                                        Math.floor(eventAfterTime / 60) + " hrs"
                                        : Math.floor(eventAfterTime / 60) + " hrs, " + Math.floor(eventAfterTime % 60) + " min"
                                      : Math.floor(eventAfterTime) + " min"
                                  }
                                  onSelect={(e, event) => { setEventAfterTime(e); }}

                                >
                                  <Dropdown.Item eventKey="15">15 minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="30">30 minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="45">45 minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="60">1 hr</Dropdown.Item>
                                  <Dropdown.Item eventKey="90">1 hr 30 min</Dropdown.Item>
                                  <Dropdown.Item eventKey="120">2 hr</Dropdown.Item>
                                  <Dropdown.Item eventKey="150">2 hr 30 min</Dropdown.Item>
                                  <Dropdown.Item eventKey="180">3 hr</Dropdown.Item>
                                </DropdownButton> */}

                                <Select
                                  isDisabled={eventAfterTimeActive ? false : true}
                                  options={addTimeAfterBeforeForSelect}
                                  defaultValue={[{ label: "30 minutes", value: 30 }]}
                                  onChange={(e) => { setEventAfterTime(e.value) }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-5 col-lg-5 d-flex flex-column">
                        <p className='mediumTextSize'>Give yourself some buffer time to prepare for or wrap up from booked Event.</p>
                        <div className=" rounded h-100">
                          <div className="card rounded overflow-hidden insightBackground">
                            <div className={`card-header p-1 insightBackground ${eventBeforeTimeActive ? "salesforce-style" : ""}`}></div>
                            <div className="card-body p-0">
                              <button type='button' className='btn insightBackground w-100 text-break'>{summary}</button>
                            </div>
                            <div className={`card-footer p-1 insightBackground ${eventAfterTimeActive ? "salesforce-style" : ""}`}></div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6 d-flex align-items-center gap-2 mb-2">
                        <div className='border insightBackground rounded p-3 w-100'>
                          <div className='mb-3'>
                            <span style={{ fontWeight: "500" }}>Secret Event</span>
                            <p className='m-0 p-0 mediumTextSize'>Use this to hide the event on your main scheduling page.</p>
                          </div>
                          <div className='mb-2 d-flex align-items-center justify-content-start gap-2'>
                            {
                              secretEvent && (
                                <input type="checkbox" defaultChecked onClick={() => { setSecretEvent(false); }} className='form-check-input checkboxInpage' />
                              )
                            }
                            {
                              secretEvent === false && (
                                <input type="checkbox" onClick={() => { setSecretEvent(true); }} className='form-check-input checkboxInpage' />
                              )
                            }
                            <span className={`${secretEvent ? "" : "text-muted"} mediumTextSize`} style={{ fontWeight: "500" }}>
                              Make this a Secret Event
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='col-12 col-md-6 col-lg-6'>
                        <div className='mediumTextSize w-100'>
                          <span style={{ fontWeight: "500" }}> Note:</span> Only Invitees who have this {process.env.REACT_APP_DOMAINNAME.split(".")[0]} link can schedule with you.
                        </div>
                      </div>
                    </div>

                    {/* #################################### Additional Settings Started ############################################### */}

                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6">
                        <span style={{ fontWeight: "500", fontSize: "1.2rem" }}>Additional Settings</span>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6">
                        <div className='insightBackground border p-3 rounded' style={{ position: "relative", zIndex: "2" }}>
                          <span className='d-block mb-2' style={{ fontWeight: "500" }}>Stop Bookings before:</span>
                          {/* <select id='endDateTime' className=' mt-2 shadow-sm' defaultValue={stopBookingTime[1]} required onChange={(e) => { }}>
                            <option value="1">1 Hour</option>
                            <option value="2">2 Hours</option>
                            <option value="3">3 Hours</option>
                            <option value="4">4 Hours</option>
                            <option value="custom">Custom</option>
                          </select> */}


                          {/* <DropdownButton
                            as="buttonGroup"
                            className='w-100'
                            drop={"down"}
                            variant="light"
                            title={
                              parseInt(stopBookingTime[1]) === 1 ? stopBookingTime[1] + " Hour" : stopBookingTime[1] + " Hours"
                            }
                            onSelect={(e, event) => { if (e !== "custom") { setStopBookingTime([stopTimeForBookingType, e]); setCustomStopBookingView(false); } else { setCustomStopBookingView(true); setStopBookingTime([stopTimeForBookingType, 1]); } }}

                          >
                            <Dropdown.Item eventKey="1">1 Hour</Dropdown.Item>
                            <Dropdown.Item eventKey="2">2 Hours</Dropdown.Item>
                            <Dropdown.Item eventKey="3">3 Hours</Dropdown.Item>
                            <Dropdown.Item eventKey="4">4 Hours</Dropdown.Item>
                            <Dropdown.Item eventKey="custom">custom</Dropdown.Item>
                          </DropdownButton> */}

                          <Select
                            options={stopBookingBeforeListForSelect}
                            defaultValue={[{ label: "1 Hour", value: 1 }]}
                            onChange={(e) => {
                              if (e.label !== "custom") {
                                setStopBookingTime([stopTimeForBookingType, e.value]);
                                setCustomStopBookingView(false);
                              }
                              else {
                                setCustomStopBookingView(true);
                                setStopBookingTime([stopTimeForBookingType, 1]);
                              }
                            }}
                          />
                          {
                            customStopBookingView ?
                              <div className='d-flex justify-content-center align-items-center gap-2 my-2'>
                                {
                                  stopTimeForBookingType === "hours" ?
                                    <input className='customInputFieldFocus css-13cymwt-control w-100' required type="number" min="1" max="6" defaultValue={"1"} onChange={(e) => { setStopBookingTime(["hours", e.target.value]); }} />
                                    :
                                    <input className='customInputFieldFocus css-13cymwt-control w-100' required type="number" min="1" max="60" defaultValue={"1"} onChange={(e) => { setStopBookingTime(["days", e.target.value]); }} />
                                }
                                {/* <DropdownButton
                                  as="buttonGroup"
                                  className='w-100'
                                  id={`dropdown-button-drop-down`}
                                  drop={"down"}
                                  variant="light"
                                  title="Hours"
                                  onSelect={(e, event) => { setStopTimeForBookingType(e); }}
                                >
                                  <Dropdown.Item eventKey="hours">hours</Dropdown.Item>
                                </DropdownButton> */}

                                <Select
                                  className='w-100'
                                  options={[{ label: "hours", value: "hours" }]}
                                  defaultValue={[{ label: "hours", value: "hours" }]}
                                  onChange={(e) => { setStopTimeForBookingType(e.label); }}
                                />

                                {/* <select className='' onChange={(e) => { setStopTimeForBookingType(e.target.value); }} value={stopTimeForBookingType === "hours" ? "hours" : "days"}>
                                  <option value="hours">hours</option>
                                  <option value="days">days</option>
                                </select> */}

                              </div>
                              :
                              ""
                          }
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6">
                        <div className='mediumTextSize w-100'>
                          <span style={{ fontWeight: "500" }}>Note:</span> This feature can control the last minute booking problems. Set your desired time to stop bookings after that particular time.
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-9 col-sm-9 col-md-9 col-lg-9">
                        <span style={{ fontWeight: "500", fontSize: "1.2rem" }}>Questions for Invitees:</span>
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                        <span className="mediumTextSize" style={{ fontWeight: "500" }}>Required</span>
                        <OverlayTrigger
                          placement="left"
                          delay={{ show: 100, hide: 200 }}
                          overlay={
                            <Popover id="popover-basic">
                              <Popover.Header className='salesforce-style' as="h3">Required Questions</Popover.Header>
                              <Popover.Body>
                                <p className='p-0 m-0 mb-2 helpTextSize'>You can make a questions mandatory while your invitees book a meeting whith you by using this switch.</p>
                                <img src={requiredQuestionPng} className='w-100' alt="" />
                              </Popover.Body>
                            </Popover>
                          }>
                          <HelpOutlineIcon style={{ maxWidth: "15px", cursor: "help" }} />
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-9 col-md-9 col-lg-9">
                        <input type="text" className='customInputFieldFocus w-100' onChange={(e) => { setOneOnOneQuestions(e.target.value); }} defaultValue={"Share anything that can help us prepare the meeting."} placeholder='enter your question here?' />
                      </div>
                      <div className="col-3 col-md-3 col-lg-3 d-flex align-items-center">
                        <div className="outerToggleSwitch" onClick={() => { setIsDescriptionRequired(!isDescriptionRequired); }}>
                          <div className={`frameOfToggleSwitch ${isDescriptionRequired === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                            <span>On</span>
                            <div className="toggleSwitchBall"></div>
                            <span>Off</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* #################################### Additional Settings End ############################################### */}

                    <div className="row mb-3">
                      <div className="col d-flex align-items-center justify-content-center">
                        <button className='btn salesforce-style rounded-pill py-2 px-5 d-flex align-items-center justify-content-center gap-2' id="oneToOneCreationButton" disabled={!updateButtonEnabled}>Finish</button>
                      </div>
                    </div>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas show={groupEventCreation} onHide={() => { setGroupEventCreation(false); setViewNewEventCreate(true); setViewOf("NewMeetingCreation"); setToHistory("NewMeetingCreation", null); resetAllMeetingDetails(); }}
        scroll={true}
        backdrop={true}
        placement="start"
      >
        <Offcanvas.Header className='salesforce-style' closeButton closeVariant="white">
          <Offcanvas.Title>New Group Event</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='backgroundFiller bg-soul py-0'>
          <div className={`container-fluid rounded-bottom ${groupEventCreationSteps === 1 ? "" : "d-none"}`}>
            <div className="row sticky-top">
              <div className="col p-0">
                <div className='eventPreview insightBackground shadow-sm border'>
                  <div style={{ height: "2em", width: "2em", borderRadius: "100%", pointerEvents: "none", aspectRatio: "1" }} className={` repo-event-color-${groupColorId}`} ></div>
                  <div>
                    <div className='prewEventWhich'>What event is this?</div>
                    <div className='eventNamePrew d-flex align-items-center gap-2 flex-wrap'>
                      <span className="text-capitalize text-break mediumTextSize" style={groupSummary.includes(" ") ? { wordBreak: "break-word" } : { wordBreak: "break-all" }}>
                        {
                          groupSummary.length > 0 ?
                            <>
                              {groupSummary},
                            </>
                            :
                            ""
                        }
                      </span>
                      <span className="d-flex text-break align-items-center gap-1 mediumTextSize">
                        {
                          groupMeetingType === "phone" ?
                            <span className='selectedLocation'>
                              <img src={callimg} alt="call" className='iconsForLocation' />
                              <span>{groupLocation}</span>
                            </span> :
                            groupMeetingType === "meet" ?
                              <span className='selectedLocation'>
                                <img src={meetImg} alt="Meet" className='iconsForLocation' />
                                <span>Google Meet Conference</span>
                              </span> :
                              groupMeetingType === "zoom" ?
                                <span className='selectedLocation'>
                                  <img src={zoomImg} alt="Zoom" className='iconsForLocation' />
                                  <span>Zoom Conference</span>
                                  {
                                    needZoomToken ?
                                      <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                      : ""
                                  }
                                </span> :
                                groupMeetingType === "goto" ?
                                  <span className='selectedLocation'>
                                    <img src={gotoImg} alt="GoTo" className='iconsForLocation' />
                                    <span>GoTo Conference</span>
                                    {
                                      needGTMToken ?
                                        <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                        : ""
                                    }
                                  </span> :
                                  groupMeetingType === "webex" ?
                                    <span className='selectedLocation'>
                                      <img src={webexImg} alt="Webex" className='iconsForLocation' />
                                      <span>Webex Conference</span>
                                      {
                                        needWebexToken ?
                                          <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                          : ""
                                      }
                                    </span> :
                                    groupMeetingType === "teams" ?
                                      <span className='selectedLocation'>
                                        <img src={teamsImg} alt="Teams" className='iconsForLocation' />
                                        <span>Teams Conference</span>
                                        {
                                          needTeamsToken ?
                                            <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                            : ""
                                        }
                                      </span> :
                                      groupMeetingType === "address" && groupLocation.length > 0 ?
                                        <span className='selectedLocation'>
                                          <img src={addressImg} alt="" className='iconsForLocation' />
                                          <span>{groupLocation}</span>
                                        </span> :
                                        "No location Given"
                        }
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <form id="eventFormHandle" className="eventGoogleForm p-0" onSubmit={nextPageToGroupEvent}>
                  <br />
                  <div className="container-fluid p-0">
                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6 p-0">
                        <label htmlFor='summary' className='border w-100 p-2 insightBackground rounded'>
                          <span className='' style={{ fontWeight: "500" }}>
                            Event Name *
                          </span>
                          <input
                            maxLength={100}
                            type="text"
                            id="summary"
                            className='customInputFieldFocus w-100 border px-2'
                            value={groupSummary}
                            required
                            placeholder="Add the name of the event"
                            onChange={e => setGroupSummary(e.target.value)}
                          />
                        </label>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6">

                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6 p-0">
                        <label htmlFor='selectLocation' className={`border w-100 p-2 insightBackground rounded ${groupLocationSelection ? "mb-0" : ""}`} onClick={() => { setGroupLocationSelection(!groupLocationSelection); }}>
                          <span className='' style={{ fontWeight: "500" }}>
                            Select Location
                          </span>
                          <div id="selectLocation" className='locationFrame bg-soul rounded shadow-sm'>
                            {
                              groupMeetingType ?
                                groupMeetingType === "phone" ?
                                  (
                                    <div className='selectedLocation'>
                                      <img alt="call" src={callimg} className='iconsForLocation' />
                                      Phone call
                                    </div>
                                  )
                                  :
                                  groupMeetingType === "meet" ?
                                    (
                                      <div className='selectedLocation'>

                                        <img alt="meet" src={meetImg} className='iconsForLocation' />
                                        Google Meet Conference
                                      </div>
                                    )
                                    :
                                    groupMeetingType === "zoom" ?
                                      (<div className='selectedLocation'>
                                        <img alt="zoom" src={zoomImg} className='iconsForLocation' />
                                        <span>
                                          Zoom Conference
                                        </span>
                                        {
                                          needZoomToken ?
                                            <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                            : ""
                                        }
                                      </div>)
                                      :
                                      groupMeetingType === "goto" ?
                                        (<div className='selectedLocation'>
                                          <img alt="Goto" src={gotoImg} className='iconsForLocation' />
                                          <span>
                                            GoTo Conference
                                          </span>
                                          {
                                            needGTMToken ?
                                              <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                              : ""
                                          }
                                        </div>)
                                        :
                                        groupMeetingType === "webex" ?
                                          (<div className='selectedLocation'>
                                            <img alt="Webex" src={webexImg} className='iconsForLocation' />
                                            <span>
                                              Webex Conference
                                            </span>
                                            {
                                              needWebexToken ?
                                                <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                : ""
                                            }
                                          </div>)
                                          :
                                          groupMeetingType === "teams" ?
                                            (<div className='selectedLocation'>
                                              <img alt="Teams" src={teamsImg} className='iconsForLocation' />
                                              <span>
                                                Teams Conference
                                              </span>
                                              {
                                                needTeamsToken ?
                                                  <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                  : ""
                                              }
                                            </div>)
                                            :
                                            groupMeetingType === "address" ?
                                              (
                                                <div className='selectedLocation'>
                                                  <img alt="address" src={addressImg} className='iconsForLocation' />
                                                  Custom Address
                                                </div>
                                              )
                                              :
                                              (
                                                <div className='selectedLocation'>
                                                  <img alt="address" src={addressImg} className='iconsForLocation' />
                                                  Custom Address
                                                </div>
                                              )
                                :
                                ""
                            }
                          </div>
                        </label>

                        {
                          groupLocationSelection &&
                          <div id='locationSelector' className="locationSelector bg-body rounded shadow-sm">
                            <ul>
                              <li title='Phone Call' onClick={() => { setGroupMeetingType("phone"); setGroupLocation("phone"); setGroupLocationSelection(false); }}>
                                <span>
                                  <img alt="Call" src={callimg} className='iconsForLocation' />
                                </span>
                                Phone Call
                              </li>
                              <li title='Google Meet Conference' onClick={() => { setGroupMeetingType("meet"); setGroupLocation("meet"); setGroupLocationSelection(false); }}>
                                <span>
                                  <img alt="Meet" src={meetImg} className='iconsForLocation' />
                                </span>
                                Google Meet Conference
                              </li>
                              <li title='Zoom Conference' onClick={() => { setGroupMeetingType("zoom"); setGroupLocation("zoom"); setGroupLocationSelection(false); }}>
                                <span>
                                  <img alt="Zoom" src={zoomImg} className='iconsForLocation' />
                                </span>
                                <span>
                                  Zoom Conference
                                </span>
                                {
                                  needZoomToken ?
                                    <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                    : ""
                                }
                              </li>
                              <li title='GoTo Conference' onClick={() => { setGroupMeetingType("goto"); setGroupLocation("goto"); setGroupLocationSelection(false); }}>
                                <span>
                                  <img alt="Goto" src={gotoImg} className='iconsForLocation' />
                                </span>
                                <span>
                                  GoTo Conference
                                </span>
                                {
                                  needGTMToken ?
                                    <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                    : ""
                                }
                              </li>
                              <li title='Webex Conference' onClick={() => { setGroupMeetingType("webex"); setGroupLocation("webex"); setGroupLocationSelection(false); }}>
                                <span>
                                  <img alt="Webex" src={webexImg} className='iconsForLocation' />
                                </span>
                                <span>
                                  Webex Conference
                                </span>
                                {
                                  needWebexToken ?
                                    <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                    : ""
                                }
                              </li>
                              <li title='Teams Conference' onClick={() => { setGroupMeetingType("teams"); setGroupLocation("teams"); setGroupLocationSelection(false); }}>
                                <span>
                                  <img alt="Teams" src={teamsImg} className='iconsForLocation' />
                                </span>
                                <span>
                                  Teams Conference
                                </span>
                                {
                                  needTeamsToken ?
                                    <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                    : ""
                                }
                              </li>
                              <li title='Address' onClick={() => { setGroupMeetingType("address"); setGroupLocationSelection(false); }}>
                                <span>
                                  <img alt="Address" src={addressImg} className='iconsForLocation' />
                                </span>
                                <span>
                                  Custom Address
                                </span>
                              </li>
                            </ul>
                          </div>
                        }

                        {
                          groupMeetingType === "phone" && (
                            <label className={`border p-2 insightBackground shadow-sm w-100 ${groupInboundCall ? "rounded-top" : "rounded"}`}>
                              <div className="askWhich">
                                <label htmlFor="outboundCalloff2">
                                  <input type="radio" defaultChecked id="outboundCalloff2" name="callBound" onClick={() => { setGroupInboundCall(false); }} />
                                  I should call my invitees
                                </label>

                                <label htmlFor="inboundCallonn2">
                                  {
                                    phoneList ?
                                      optionListForPhone.length > 0 ?
                                        <>
                                          <input type="radio" id="inboundCallonn2" name="callBound" onClick={() => { setGroupInboundCall(true); }} />
                                          <span>Invitees should call me</span>
                                        </>
                                        :
                                        <>
                                          <input type="radio" id="inboundCallonn2" name="callBound" disabled={true} onClick={() => { setGroupInboundCall(true); }} />
                                          <span className='text-muted'>Invitees should call me</span>
                                        </>
                                      :
                                      <>
                                        <input type="radio" id="inboundCallonn2" name="callBound" disabled={true} onClick={() => { setGroupInboundCall(true); }} />
                                        <span className='text-muted'>Invitees should call me</span>
                                      </>
                                  }
                                </label>
                              </div>

                            </label>
                          )
                          // setInboundCall
                        }


                        {
                          groupMeetingType === "phone" && groupInboundCall ?
                            <label htmlFor='phoneCallGroup' className='border p-2 insightBackground w-100 rounded-bottom' style={{ position: "relative", zIndex: "2" }}>
                              <span style={{ fontWeight: "500" }}>
                                Choose Phone Number *
                              </span>
                              <PhoneInput
                                required
                                className="mb-1 w-100 phoneNUmberInpurJHSGFUGDSFIY pointer-events-none"
                                style={{ pointerEvents: "none" }}
                                placeholder=""
                                value={setGroupLocation ? groupLocation : optionListForPhone[0].label}
                                onChange={setGroupLocation} />

                              <Select
                                id='phoneCallGroup'
                                options={optionListForPhone}
                                defaultValue={optionListForPhone[0]}
                                onChange={(e) => { setGroupLocation(e.label) }}
                              />
                            </label>
                            :
                            groupMeetingType === "address" ?
                              <label className='border p-2 insightBackground w-100 rounded'>
                                <span style={{ fontWeight: "500" }}>
                                  Enter Address Here *
                                </span>
                                <input
                                  required
                                  type="text"
                                  className='customInputFieldFocus w-100 border px-2'
                                  defaultValue={""}
                                  placeholder=""
                                  onChange={(e) => setGroupLocation(e.target.value)}
                                />
                              </label>
                              :
                              groupMeetingType === "meet" && needToken ?
                                <label>
                                  <GoogleLogin
                                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                    buttonText='Sign in & Authorize Calendar'
                                    onSuccess={responceGoogle}
                                    onFailure={responseError}
                                    cookiePolicy={"single_host_origin"}
                                    fetchBasicProfile={true}
                                    responseType="code"
                                    prompt="consent"
                                    accessType='offline'
                                    scope='openid email profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'
                                  />
                                </label>
                                :
                                groupMeetingType === "zoom" && needZoomToken ?
                                  <label>
                                    <a href={"#Integrations"} onClick={(e) => {
                                      setViewOf("Integrations");
                                      setViewAdminPage(true);

                                      setViewNewEventCreate(false);
                                      setOneOnOneEventCreation(false);
                                      setEventCreationSteps(1);
                                      setGroupEventCreation(false);
                                      setGroupEventCreationSteps(1);
                                      setRoundRobinEventCreation(false);
                                      setRoundRobinCreationSteps(1);
                                      setAlertShow(false);
                                      setContactShow(false);
                                      setMeetingShow(false);
                                      setAvailabilityShow(false);
                                    }} className='text-primary'>
                                      Click to Connect Zoom.
                                    </a>
                                  </label> :
                                  groupMeetingType === "webex" && needWebexToken ?
                                    <label>
                                      <a href={"#Integrations"} onClick={(e) => {
                                        setViewOf("Integrations");
                                        setViewAdminPage(true);

                                        setViewNewEventCreate(false);
                                        setOneOnOneEventCreation(false);
                                        setEventCreationSteps(1);
                                        setGroupEventCreation(false);
                                        setGroupEventCreationSteps(1);
                                        setRoundRobinEventCreation(false);
                                        setRoundRobinCreationSteps(1);
                                        setAlertShow(false);
                                        setContactShow(false);
                                        setMeetingShow(false);
                                        setAvailabilityShow(false);
                                      }} className='text-primary'>
                                        Click to Connect Webex.
                                      </a>
                                    </label> :
                                    groupMeetingType === "goto" && needGTMToken ?
                                      <label>
                                        <a href={"#Integrations"} onClick={(e) => {
                                          setViewOf("Integrations");
                                          setViewAdminPage(true);

                                          setViewNewEventCreate(false);
                                          setOneOnOneEventCreation(false);
                                          setEventCreationSteps(1);
                                          setGroupEventCreation(false);
                                          setGroupEventCreationSteps(1);
                                          setRoundRobinEventCreation(false);
                                          setRoundRobinCreationSteps(1);
                                          setAlertShow(false);
                                          setContactShow(false);
                                          setMeetingShow(false);
                                          setAvailabilityShow(false);
                                        }} className='text-primary'>
                                          Click to Connect GoTo Meeting.
                                        </a>
                                      </label> :
                                      groupMeetingType === "teams" && needTeamsToken ?
                                        <label>
                                          <a href={"#Integrations"} onClick={(e) => {
                                            setViewOf("Integrations");
                                            setViewAdminPage(true);

                                            setViewNewEventCreate(false);
                                            setOneOnOneEventCreation(false);
                                            setEventCreationSteps(1);
                                            setGroupEventCreation(false);
                                            setGroupEventCreationSteps(1);
                                            setRoundRobinEventCreation(false);
                                            setRoundRobinCreationSteps(1);
                                            setAlertShow(false);
                                            setContactShow(false);
                                            setMeetingShow(false);
                                            setAvailabilityShow(false);
                                          }} className='text-primary'>
                                            Click to Connect Teams.
                                          </a>
                                        </label> :
                                        ""
                        }
                      </div>
                      <div className='col-12 col-md-6 col-lg-6'>

                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6 p-0">
                        <label htmlFor='description' className='border p-2 insightBackground w-100 rounded'>
                          <div className='d-flex align-items-center justify-content-between w-100'>
                            <span style={{ fontWeight: "500" }}>
                              Description *
                            </span>
                          </div>
                          {
                            isDescriptionRequired ?
                              <textarea
                                id="description"
                                className='customInputFieldFocus w-100 border px-2'
                                value={groupDescription}
                                required
                                placeholder="Write a summary and any details your invitee should know about the event."
                                onChange={e => setGroupDescription(e.target.value)}
                              />
                              :
                              <textarea
                                id="description"
                                className='customInputFieldFocus w-100 border px-2'
                                value={groupDescription}
                                placeholder="Write a summary and any details your invitee should know about the event."
                                onChange={e => setGroupDescription(e.target.value)}
                              />
                          }
                        </label>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 p-0">

                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6 p-0">
                        <label htmlFor='description' className='border p-2 insightBackground w-100 rounded d-flex flex-column'>
                          <span className='d-flex gap-1 align-items-center' style={{ fontWeight: "500" }}>
                            Event Link *

                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 100, hide: 200 }}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Header className='salesforce-style' as="h3">Unique event links</Popover.Header>
                                  <Popover.Body>
                                    <p className='p-0 m-0 helpTextSize'>
                                      Use this field to change the unique url for this event.
                                    </p>
                                    <img src={uniqueLinkHelpText} className='img img-responsive w-100' alt='helpText' />
                                  </Popover.Body>
                                </Popover>
                              }>
                              <HelpOutlineIcon style={{ maxWidth: "15px", cursor: "help" }} />
                            </OverlayTrigger>
                          </span>
                          <span className='mediumTextSize text-muted'>{process.env.REACT_APP_DOMAINNAME + `/${username.replaceAll(" ", "-")}/`}</span>

                          <div className="input-group w-100 d-flex flex-nowrap rounded">
                            <input type="text" maxLength={50} id="UID" required className='customInputFieldFocus w-100 border px-2' onClick={(e) => { if (e.target.value.length < 1) { checkCustomLinkAvailable({ target: { value: groupSummary.replaceAll(" ", "-") } }); } }} autoComplete="off" placeholder='UID' value={customEventLink} onChange={checkCustomLinkAvailable} />
                            <div className="input-group-append">
                              <span className="input-group-text h-100 border-0" style={{ borderRadius: "0px 0.375rem 0.375rem 0px" }}>
                                {
                                  isCustomLinkAvailable ?
                                    <i className='fa fa-check text-success'></i>
                                    :
                                    <i className='fa fa-times text-danger'></i>
                                }
                              </span>
                            </div>
                          </div>


                        </label>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6">

                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6 p-0">
                        <div className='border p-2 insightBackground w-100 rounded d-flex flex-column align-items-start justify-content-center gap-2'>
                          <label htmlFor='maxInvities' className=' d-flex flex-column gap-1 '>
                            <span style={{ fontWeight: "500" }}>
                              Max invitees in a spot *
                            </span>
                            <input
                              type="number"
                              max="500"
                              min="2"
                              id="maxInvities"
                              className='customInputFieldFocus w-100 border w-100 px-2'
                              // style={{ maxWidth: "100px" }}
                              value={maxInvities}
                              required
                              onChange={e => setMaxInvities(e.target.value)}
                            />
                          </label>
                          <label htmlFor='maxInvitiesEnable' className='d-flex align-items-center gap-1'>
                            {
                              maxInvitiesActive && (
                                <input id="maxInvitiesEnable" type="checkbox" defaultChecked onClick={() => { setMaxInvitiesActive(false); }} className='form-check-input checkboxInpage p-0 m-0' />
                              )
                            }
                            {
                              maxInvitiesActive === false && (
                                <input id="maxInvitiesEnable" type="checkbox" onClick={() => { setMaxInvitiesActive(true); }} className='form-check-input checkboxInpage p-0 m-0' />
                              )
                            }
                            <span className={`${maxInvitiesActive ? "" : "text-muted"} d-flex p-0 align-items-center h-100 mediumTextSize`}>
                              Display remaining spots on booking page
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6">

                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 p-0">
                        <label className='border p-2 insightBackground w-100 rounded d-flex flex-column'>
                          <span className='pb-1' style={{ fontWeight: "500" }}>
                            Event Color
                          </span>
                          <div className='event-color-holder pb-2'>
                            <div onClick={(e) => { setGroupColorId('1'); checkThisTab(e.target); }} className='event-color-tab event-color-1 checked-color'></div>
                            <div onClick={(e) => { setGroupColorId('2'); checkThisTab(e.target); }} className='event-color-tab event-color-2'></div>
                            <div onClick={(e) => { setGroupColorId('3'); checkThisTab(e.target); }} className='event-color-tab event-color-3'></div>
                            <div onClick={(e) => { setGroupColorId('4'); checkThisTab(e.target); }} className='event-color-tab event-color-4'></div>
                            <div onClick={(e) => { setGroupColorId('5'); checkThisTab(e.target); }} className='event-color-tab event-color-5'></div>
                            <div onClick={(e) => { setGroupColorId('6'); checkThisTab(e.target); }} className='event-color-tab event-color-6'></div>
                            <div onClick={(e) => { setGroupColorId('7'); checkThisTab(e.target); }} className='event-color-tab event-color-7'></div>
                            <div onClick={(e) => { setGroupColorId('8'); checkThisTab(e.target); }} className='event-color-tab event-color-8'></div>
                            <div onClick={(e) => { setGroupColorId('9'); checkThisTab(e.target); }} className='event-color-tab event-color-9'></div>
                            <div onClick={(e) => { setGroupColorId('10'); checkThisTab(e.target); }} className='event-color-tab event-color-10'></div>
                            <div onClick={(e) => { setGroupColorId('11'); checkThisTab(e.target); }} className='event-color-tab event-color-11'></div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-12 p-0">
                        <center>
                          <button type='submit' disabled={!isCustomLinkAvailable} className='btn salesforce-style px-5 rounded-pill'>Next</button>
                        </center>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className={`container-fluid rounded-bottom ${groupEventCreationSteps === 2 ? "" : "d-none"}`}>
            <div className="row sticky-top">
              <div className="col p-0">
                <div className='eventPreview insightBackground shadow-sm border'>
                  <button type='button' className='btn align-self-center salesforce-style border rounded-circle' onClick={() => { setGroupEventCreationSteps(1); }}>
                    <i className='fa fa-arrow-left'></i>
                  </button>
                  <img src={caneldarDark} alt="" className="iconsForLocation" />
                  <div>
                    <div className='prewEventWhich'>When can people book this event?</div>
                    <div className='eventNamePrew d-flex align-items-center gap-2 flex-wrap'>
                      <span id="eventNamePrew">
                        {
                          groupDuration > 60 ?
                            Math.floor(groupDuration % 60) === 0 ?
                              Math.floor(groupDuration / 60) + " hrs"
                              : Math.floor(groupDuration / 60) + " hrs, " + Math.floor(groupDuration % 60) + " min"
                            : Math.floor(groupDuration) + " min"
                        },
                      </span>
                      <span id="eventLocationPrew">{dayjs(betweenDays[0].startDate).format("D MMM YYYY")} - {betweenDays[0].endDate ? dayjs(betweenDays[0].endDate).format("D MMM YYYY") : "Continuous"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col p-0">
                <form onSubmit={(e) => { createGroupMeeting(e); }}>
                  <div className='container-fluid p-0'>

                    <div className="row my-3">
                      <div className="col">
                        <div className='p-3 insightBackground rounded border'>
                          <span style={{ fontWeight: "500" }}>
                            Date Range
                          </span>
                          <p className='m-0'>Set a range of dates when you can accept meetings.</p>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col">
                        <div className='rescheduler'>
                          <label className='h6' style={{ fontWeight: "500" }}>
                            Invitees can schedule...
                          </label>
                          {/* <div className="w-100 overflow-auto">
                            <DateRange
                              // editableDateInputs={true}
                              onChange={item => setBetweenDays([item.selection])}
                              // showSelectionPreview={true}
                              // moveRangeOnFirstSelection={false}
                              months={2}
                              minDate={new Date()}
                              ranges={betweenDays}
                              direction="horizontal"
                              editableDateInputs={true}
                            />
                          </div> */}

                          <div className='w-100 d-flex align-items-center gap-2 mb-4'>
                            <label htmlFor="rollingDaysStraight" className={`d-flex align-items-center gap-2 ${straightRollingDays ? "" : "text-muted"}`}
                              onClick={() => {
                                setStraightRollingDays(true);
                                setWithinDateRange(false);
                                setInfiniteDateRange(false);
                                setBetweenDays([
                                  {
                                    startDate: new Date(),
                                    endDate: addDays(new Date(), parseInt(rollingDaysRef.current.value)),
                                    key: 'selection'
                                  }
                                ]);

                              }}
                            >
                              {
                                straightRollingDays ?
                                  <input type="radio" checked id='rollingDaysStraight' className='form-check-input p-0 m-0' name="rollingDays" />
                                  :
                                  <input type="radio" id='rollingDaysStraight' className='form-check-input p-0 m-0' name="rollingDays" />
                              }

                              <input
                                type="number"
                                disabled={!straightRollingDays}
                                defaultValue={30}
                                min={1}
                                required
                                ref={rollingDaysRef}
                                onClick={
                                  (e) => {
                                    setBetweenDays([
                                      {
                                        startDate: new Date(),
                                        endDate: addDays(new Date(), parseInt(e.target.value)),
                                        key: 'selection'
                                      }
                                    ]);
                                  }}
                                onInput={
                                  (e) => {
                                    setBetweenDays([
                                      {
                                        startDate: new Date(),
                                        endDate: addDays(new Date(), parseInt(e.target.value)),
                                        key: 'selection'
                                      }
                                    ]);

                                    // console.log(addDays(new Date(), parseInt(e.target.value)))
                                  }
                                } className='customInputFieldFocus rounded border' />
                            </label>
                            <div style={{ position: "relative", zIndex: "10" }}>
                              <Select
                                isDisabled={!straightRollingDays}
                                className='mediumTextSize'
                                options={
                                  [
                                    { label: "calendar days", value: "calendardays" },
                                    // { label: "weekdays", value: "weekdays" }
                                  ]
                                }
                                defaultValue={[{ label: "calendar days", value: "calendardays" }]}
                                onChange={(e) => { }}
                              />
                            </div>
                            <div className={`${straightRollingDays ? "" : "text-muted"}`}>
                              into the future
                            </div>
                          </div>

                          <div className='w-100 d-flex align-items-center gap-2 mb-4'>
                            <label htmlFor="betweenForever" className={`d-flex align-items-center gap-2 ${withinDateRange ? "" : "text-muted"}`}
                              onClick={(e) => {
                                setStraightRollingDays(false);
                                setWithinDateRange(true);
                                setInfiniteDateRange(false);
                              }}
                            >
                              {
                                withinDateRange ?
                                  <input type="radio" checked id='betweenForever' className='form-check-input p-0 m-0' name="rollingDays" />
                                  :
                                  <input type="radio" id='betweenForever' className='form-check-input p-0 m-0' name="rollingDays" />
                              }
                              <span className='mediumTextSize'>Within a date range</span>
                            </label>
                            {
                              withinDateRange ?
                                <div className='flex-fill' style={{ position: "relative", zIndex: "9", maxWidth: "max-content" }} onClick={() => { setShowDateRangeSelector(true); setWithinDateRange(true); setStraightRollingDays(false); setInfiniteDateRange(false); }}>
                                  <input type="text" disabled={!withinDateRange} value={`${dayjs(betweenDays[0].startDate).format("D MMM YYYY")} - ${betweenDays[0].endDate ? dayjs(betweenDays[0].endDate).format("D MMM YYYY") : "Continuous"}`} className=" w-100 rounded p-2 border mediumTextSize" />
                                </div>
                                :
                                ""
                            }
                          </div>

                          <div className='w-100 mb-4'>
                            <label htmlFor="foreversRoll" className={`d-flex align-items-center gap-2  ${infiniteDateRange ? "" : "text-muted"}`}
                              onClick={(e) => {
                                setStraightRollingDays(false);
                                setWithinDateRange(false);
                                setInfiniteDateRange(true);

                                setBetweenDays([
                                  {
                                    startDate: new Date(),
                                    endDate: null,
                                    key: 'selection'
                                  }
                                ]);
                              }}
                            >
                              {
                                infiniteDateRange ?
                                  <input type="radio" checked id='foreversRoll' className='form-check-input p-0 m-0' name="rollingDays" />
                                  :
                                  <input type="radio" id='foreversRoll' className='form-check-input p-0 m-0' name="rollingDays" />
                              }
                              <span className={`mediumTextSize`}>Indefinitely into the future</span>
                            </label>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12">
                        <label htmlFor='endDateTime' className='w-100 border rounded p-3 insightBackground' style={{ position: "relative", zIndex: "5" }}>
                          <span style={{ fontWeight: "500" }}>Meeting Duration</span>
                          <p className='m-0 mb-2 mediumTextSize'>Define how long your event will be. It can be as long as 12 hours.</p>
                          <div className="container-fluid p-0">
                            <div className="row">
                              <div className="col-12 col-md-6 col-lg-6">
                                {/* <DropdownButton
                            as="buttonGroup"
                            className='w-100'
                            id={`dropdown-button-drop-down-group`}
                            drop={"right"}
                            variant="light"
                            title={
                              groupDuration > 60 ?
                                Math.floor(groupDuration % 60) === 0 ?
                                  Math.floor(groupDuration / 60) + " hrs"
                                  : Math.floor(groupDuration / 60) + " hrs, " + Math.floor(groupDuration % 60) + " min"
                                : Math.floor(groupDuration) + " min"
                            }
                            onSelect={(e, event) => { if (e !== "custom") { setGroupDuration(e); setCustomHourView(false); } else { setCustomHourView(true); setGroupDuration(15) } }}

                          >
                            <Dropdown.Item eventKey="15">15 minutes</Dropdown.Item>
                            <Dropdown.Item eventKey="30">30 minutes</Dropdown.Item>
                            <Dropdown.Item eventKey="45">45 minutes</Dropdown.Item>
                            <Dropdown.Item eventKey="60">60 minutes</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item eventKey="custom">Custom</Dropdown.Item>
                          </DropdownButton> */}

                                <Select
                                  options={meetingDurationListForSelect}
                                  defaultValue={[{ label: "30 minutes", value: "30" }]}
                                  onChange={(e) => { if (e.value !== "custom") { setGroupDuration(e.value); setCustomHourView(false); } else { setCustomHourView(true); setGroupDuration(15) } }}
                                />

                                {
                                  customHourView ?
                                    <div className='d-flex justify-content-center align-items-center gap-2 my-2'>
                                      {
                                        durationType === "hours" ?
                                          <input className='customInputFieldFocus css-13cymwt-control w-100' required type="number" min="1" max="12" defaultValue={"1"} ref={minutesGroup} onChange={(e) => { convertToMinutesGroup(e.target.value); }} />
                                          :
                                          <input className='customInputFieldFocus css-13cymwt-control w-100' required type="number" min="15" max="720" defaultValue={"15"} ref={minutesGroup} onChange={(e) => { convertToMinutesGroup(e.target.value); }} />
                                      }

                                      {/* <DropdownButton
                                  as="buttonGroup"
                                  className='w-100'
                                  id={`dropdown-button-drop-down`}
                                  drop={"down"}
                                  variant="light"
                                  title={durationType}

                                  onSelect={(e, event) => { setDurationType(e) }}

                                >
                                  <Dropdown.Item eventKey="minutes">minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="hours">hours</Dropdown.Item>
                                </DropdownButton> */}

                                      <Select
                                        className='w-50'
                                        options={[{ label: "minutes", value: "minutes" }, { label: "hours", value: "hours" },]}
                                        defaultValue={[{ label: "minutes", value: "minutes" }]}
                                        onChange={(e) => { setDurationType(e.value); }}
                                      />

                                      {/* <select className='' onChange={(e) => { setDurationType(e.target.value); }} value={durationType === "hours" ? "hours" : "minutes"}>
                                <option value="minutes">minutes</option>
                                <option value="hours">hours</option>
                              </select> */}
                                    </div>
                                    :
                                    ""
                                }
                              </div>
                            </div>
                          </div>
                        </label>

                      </div>
                      <div className="col-12 col-lg-6 col-md-6"></div>
                    </div>

                    <div className='row mb-3'>
                      <div className="col">
                        <div className='w-100 border rounded p-3 insightBackground'>
                          <span style={{ fontWeight: "500" }}>How do you want to offer your availability for this event type?</span>
                          <p>Select one of your schedules or define custom hours specific to this type of event.</p>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col d-flex">
                        <button type='button' className={`btn ${existingScheduleView ? "salesforce-style" : "bg-body border"}`} onClick={() => { setExistingScheduleView(true); setCustomScheduleView(false); }}>Use an existing schedule</button>
                        <button type='button' className={`btn mx-3 ${customScheduleView ? "salesforce-style" : "bg-body border"}`} onClick={() => { setExistingScheduleView(false); setCustomScheduleView(true); }}>Set custom hours</button>
                      </div>
                    </div>

                    {/* Existing schedule */}

                    <div className={`row mb-3 ${existingScheduleView ? "" : "d-none"}`}>
                      <div className="col">

                        <div className="rounded text-dark insightBackground border" style={{ position: "relative", zIndex: "4" }}>
                          <div className="border-bottom p-3">
                            <span className='mb-1 w-100 d-block' style={{ fontWeight: "500" }}>Which schedule type do you want to use for this event type?</span>

                            {/* <select className=' my-2' value={scheduleName} onChange={(e) => { toogleScheduleSet(e.target.value); setScheduleName(e.target.value); }}>
                              {
                                userSchedules ?
                                  Object.keys(userSchedules).map((name, key) => {
                                    return <option value={name}>{name}</option>
                                  })
                                  :
                                  <option value="working hours">Working Hours</option>
                              }
                            </select> */}

                            {/* <DropdownButton
                                as="buttonGroup"
                                className='w-100'
                                drop={"down"}
                                variant="light"
                                title={scheduleName ? scheduleName : ""}
                                onSelect={(e, event) => { toogleScheduleSet(e); setScheduleName(e); }}

                              >
                                {
                                  userSchedules ?
                                    Object.keys(userSchedules).map((name, key) => {
                                      return <Dropdown.Item key={key} eventKey={name}>{name}</Dropdown.Item>
                                    })
                                    :
                                    <Dropdown.Item eventKey="working hours">Working Hours</Dropdown.Item>
                                }
                              </DropdownButton> */}

                            <Select
                              options={userScheduleListForSelect}
                              defaultValue={[{ label: scheduleName, value: scheduleName }]}
                              onChange={(e) => { toogleScheduleSet(e.label); setScheduleName(e.label); }}
                            />

                            <span style={{ fontWeight: "500" }} className="d-block mb-1 mt-3">
                              Time Zone
                            </span>
                            <Select className='mb-3 p-0 w-100' value={[selectedTimezone]} options={filteredTimezonesListSchedule} isDisabled={true} onChange={(e) => { setSelectedTimezone(e); }} />
                            <div className="d-flex align-items-center gap-2 my-3">
                              <i className='fa fa-globe fs-5'></i>
                              <span style={{ fontWeight: "600" }}>
                                {selectedTimezone.value ? selectedTimezone.value.name : ""}
                              </span>
                            </div>
                            <p className='mediumTextSize'>Automatically detected timezone is {selectedTimezone.value ? selectedTimezone.value.name : ""}. You may change it to specify default time-zone for your calendar.</p>
                          </div>
                          <div className="p-2">
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-12 col-lg-8 ">
                                  <div className="container-fluid bg-body rounded border h-100">
                                    <div className="row">
                                      <div className="col-4 col-md-2 col-lg-2 h6 p-2">SUN</div>
                                      <div className="col-8 col-md-10 col-lg-10 p-2">
                                        {
                                          isSundayActive ?
                                            schedule.sunday.times.map((time, key) => {
                                              return <p className='w-100 mb-2 text-start' key={key}>
                                                {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                              </p>
                                            })
                                            :
                                            <span className='text-muted'>unavailable</span>
                                        }
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className=" col-4 col-md-2 col-lg-2 h6 p-2">MON</div>
                                      <div className="col-8 col-md-10 col-lg-10 p-2">
                                        {
                                          isMondayActive ?
                                            schedule.monday.times.map((time, key) => {
                                              return <p className='w-100 mb-2 text-start' key={key}>
                                                {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                              </p>
                                            })
                                            :
                                            <span className='text-muted'>unavailable</span>
                                        }
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className=" col-4 col-md-2 col-lg-2 h6 p-2">TUE</div>
                                      <div className="col-8 col-md-10 col-lg-10 p-2">
                                        {
                                          isTuesdayActive ?
                                            schedule.tuesday.times.map((time, key) => {
                                              return <p className='w-100 mb-2 text-start' key={key}>
                                                {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                              </p>
                                            })
                                            :
                                            <span className='text-muted'>unavailable</span>
                                        }
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className=" col-4 col-md-2 col-lg-2 h6 p-2">WED</div>
                                      <div className="col-8 col-md-10 col-lg-10 p-2">
                                        {
                                          isWednesdayActive ?
                                            schedule.wednesday.times.map((time, key) => {
                                              return <p className='w-100 mb-2 text-start' key={key}>
                                                {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                              </p>
                                            })
                                            :
                                            <span className='text-muted'>unavailable</span>
                                        }
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className=" col-4 col-md-2 col-lg-2 h6 p-2">THU</div>
                                      <div className="col-8 col-md-10 col-lg-10 p-2">
                                        {
                                          isThursdayActive ?
                                            schedule.thursday.times.map((time, key) => {
                                              return <p className='w-100 mb-2 text-start' key={key}>
                                                {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                              </p>
                                            })
                                            :
                                            <span className='text-muted'>unavailable</span>
                                        }
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className=" col-4 col-md-2 col-lg-2 h6 p-2">FRI</div>
                                      <div className="col-8 col-md-10 col-lg-10 p-2">
                                        {
                                          isFridayActive ?
                                            schedule.friday.times.map((time, key) => {
                                              return <p className='w-100 mb-2 text-start' key={key}>
                                                {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                              </p>
                                            })
                                            :
                                            <span className='text-muted'>unavailable</span>
                                        }
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className=" col-4 col-md-2 col-lg-2 h6 p-2">SAT</div>
                                      <div className="col-8 col-md-10 col-lg-10 p-2">
                                        {
                                          isSaturdayActive ?
                                            schedule.saturday.times.map((time, key) => {
                                              return <p className='w-100 mb-2 text-start' key={key}>
                                                {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                              </p>
                                            })
                                            :
                                            <span className='text-muted'>unavailable</span>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-12 col-lg-4">
                                  <h5 className='py-3 text-center'>Date Overrides</h5>
                                  {
                                    dateOverrides ?
                                      Object.keys(dateOverrides).length > 0 ?
                                        Object.keys(dateOverrides).map((data, key) => {
                                          return (
                                            <div key={key} className={`card mb-2 datesOverrides_${dateOverrides[data].uid}`}>
                                              <div className='card-header salesforce-style d-flex align-items-center justify-content-between'>
                                                <span>{dateOverrides[data].date}</span>
                                                {/* <span><i className='fa fa-trash' id={dateOverrides[data].uid} onClick={(e) => { deleteOverrideDate(dateOverrides[data].date, `datesOverrides_${dateOverrides[data].uid}`); }}></i></span> */}
                                              </div>
                                              <div className='card-body d-flex justify-content-between align-items-center'>
                                                {
                                                  dateOverrides[data].active ?
                                                    <>
                                                      <div className='d-flex flex-column align-items-start justify-content-center'>
                                                        {
                                                          dateOverrides[data].timeSet.map((time, key) => {
                                                            return (
                                                              <React.Fragment key={key}>
                                                                <button disabled className='btn btn-secondary border-0 mb-1 w-100'>
                                                                  {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                </button>
                                                              </React.Fragment>
                                                            )
                                                          })
                                                        }
                                                      </div>
                                                    </>
                                                    :
                                                    <>
                                                      <span className='text-muted'>unavailable</span>
                                                    </>
                                                }
                                              </div>
                                            </div>
                                          )
                                        })
                                        :
                                        <p className='text-center'>No overrides for now</p>
                                      :
                                      <p className='text-center'>No overrides for now</p>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className={`row ${customScheduleView ? "" : "d-none"}`} >
                      <div className="col-12">

                        {/* ######################## NEW SCHEDULE SYSTEM GROUP ######################### */}

                        <div className="">
                          <div className='mb-3'>
                            <p className='smallTextNote p-3 m-0'>Choose a schedule below to edit or create a new one that you can apply to your event types</p>
                            <div className="conatiner-fluid border p-3 rounded insightBackground" style={{ position: "relative", zIndex: "4" }}>
                              <div className="row mb-3">
                                <div className="col">
                                  <span style={{ fontWeight: "600" }}>SCHEDULE TEMPLATES</span>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <div className='d-flex flex-wrap align-items-center gap-2'>
                                    {
                                      userSchedules &&
                                      Object.keys(userSchedules).map((name, key) => {
                                        if (scheduleName === name) {
                                          return <button type='button' key={key} className='btn salesforce-style rounded-pill border shadow-sm' onClick={() => { toogleScheduleSet(name); setScheduleName(name); }}>{name}</button>
                                        } else {
                                          return <button type='button' key={key} className='btn btn-outline-dark rounded-pill border shadow-sm' onClick={() => { toogleScheduleSet(name); setScheduleName(name); }}>{name}</button>
                                        }
                                      })
                                    }
                                    {/* {
                                          createNewSchedule &&
                                          <input type="text" className="customInputFieldFocus w-100 rounded-pill" style={{ maxWidth: "250px" }} onChange={(e) => { setScheduleName(e.target.value); }} required placeholder="Name of the Schedule..." />
                                      } */}
                                    <button type='button' className='btn btn-outline-dark rounded-pill shadow-sm border' onClick={() => { setCreateNewSchedule(true); }}>+ New Schedule</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card insightBackground">
                            <div className="card-header insightBackground" style={{ position: "relative", zIndex: "2" }}>
                              <div className="container-fluid">
                                <div className="row mb-3">
                                  <div className="col p-0">
                                    <div className='w-100 d-flex justify-content-between align-items-center'>
                                      <span className='' style={{ fontWeight: "600" }}>{scheduleName ? scheduleName : "Working Hours"}</span>
                                      <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 100, hide: 200 }}
                                        overlay={
                                          <Popover id="popover-basic">
                                            <Popover.Header className='salesforce-style' as="h3">Delete Schedule</Popover.Header>
                                            <Popover.Body>
                                              <p className='p-0 m-0 helpTextSize'>By clicking this you can delete this schedule template.</p>
                                            </Popover.Body>
                                          </Popover>
                                        }>
                                        <i
                                          className='fa fa-trash cursor-pointer'
                                          onClick={() => {
                                            setCustomAlertBox(true);
                                            setAlertBoxMessages(<p>Do you want to delete this schedule <br />"{scheduleName}"?</p>);
                                            setAlertErrorType("queryDelSchedule");

                                          }}
                                        ></i>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-12 col-lg-6 p-0">
                                    <div className='d-flex flex-column align-items-start gap-3 h-100 w-100'>

                                      <div className='d-flex align-items-start flex-column justify-content-between gap-1 h-100 w-100' style={{ position: "relative", zIndex: "4" }}>
                                        <label htmlFor='timezoneGds'>Country *</label>
                                        <div className='w-100'>
                                          <Select options={countriesList} value={countrySchedule} onChange={changeHandlerSchedule} />
                                        </div>
                                      </div>

                                      <div className='d-flex align-items-start flex-column justify-content-between gap-1 h-100 w-100' style={{ position: "relative", zIndex: "3" }}>
                                        <label htmlFor='timezoneGds'>Time Zone</label>
                                        <div className='w-100'>
                                          <Select value={[selectedTimezone]} options={filteredTimezonesListSchedule} onChange={(e) => { setSelectedTimezone(e); }} />
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-6">
                                    {/* <div className='d-flex align-items-end justify-content-end h-100'>
                                        <span className='d-flex align-items-center'>
                                            <button className='btn rounded-0 border btn-light d-flex align-items-center justify-content-start gap-1'>
                                                <i className='fa fa-list'></i>
                                                <span>List View</span>
                                            </button>
                                            <button className='btn rounded-0 d-flex align-items-center justify-content-start gap-1'>
                                                <i className='fa fa-calendar'></i>
                                                <span>Calendar View</span>
                                            </button>
                                        </span>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card-body">
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-12 col-md-12 col-lg-7 border rounded shadow-sm insightBackground">
                                    <p className='text-center pt-4' style={{ fontWeight: "600" }}>Set your weekly hours</p>
                                    {
                                      <AvailabilityTemplates
                                        setIsSundayActive={setIsSundayActive}
                                        setIsMondayActive={setIsMondayActive}
                                        setIsTuesdayActive={setIsTuesdayActive}
                                        setIsWednesdayActive={setIsWednesdayActive}
                                        setIsThursdayActive={setIsThursdayActive}
                                        setIsFridayActive={setIsFridayActive}
                                        setIsSaturdayActive={setIsSaturdayActive}
                                        isSundayActive={isSundayActive}
                                        isMondayActive={isMondayActive}
                                        isTuesdayActive={isTuesdayActive}
                                        isWednesdayActive={isWednesdayActive}
                                        isThursdayActive={isThursdayActive}
                                        isFridayActive={isFridayActive}
                                        isSaturdayActive={isSaturdayActive}
                                        checkDayOnToggle={checkDayOnToggle}
                                        schedule={schedule}
                                        timeListToMeasure={timeListToMeasure}
                                        filterTimeFieldNow={filterTimeFieldNow}
                                        checkUpdatedStateOfTimeValidation={checkUpdatedStateOfTimeValidation}
                                        checkValidTiming={checkValidTiming}
                                        timeFormat={timeFormat}
                                        addTimeSetIn={addTimeSetIn}
                                        dayjs={dayjs}
                                        deleteTimeSet={deleteTimeSet}
                                        uuidv4={uuidv4}
                                        ContentCopyIcon={ContentCopyIcon}
                                        copyTimesTo={copyTimesTo}
                                        categoryOfFrame={"Group"}
                                      />
                                    }
                                  </div>
                                  <div className="col-12 col-lg-1 d-lg-flex py-2"></div>
                                  <div className="col-12 col-md-12 col-lg-4 border rounded shadow-sm insightBackground">
                                    <div className="alert d-flex flex-column align-items-center">
                                      <p className='text-center' style={{ fontWeight: "600" }}>Date overrides</p>
                                      <button type='button' className='btn btn-sm btn-outline-primary rounded-pill salesforce-style px-3' style={{ maxWidth: "max-content" }} onClick={() => { setViewDateOverridePanel(true); }}>
                                        <AddIcon /> Add date override
                                      </button>
                                    </div>
                                    {
                                      dateOverrides ?
                                        Object.keys(dateOverrides).length > 0 ?
                                          Object.keys(dateOverrides).map((data, key) => {
                                            return (
                                              <div key={key} className={`card mb-2 datesOverrides_${dateOverrides[data].uid}`}>
                                                <div className='card-header salesforce-style d-flex align-items-center justify-content-between'>
                                                  <span>{dayjs(dateOverrides[data].date).format("ddd, DD MMM YYYY")}</span>
                                                  <span>
                                                    <i className="fa fa-trash" style={{ padding: "0 5px" }} id={dateOverrides[data].uid} onClick={(e) => { deleteOverrideDate(dateOverrides[data].date, `datesOverrides_${dateOverrides[data].uid}`); }} />
                                                  </span>
                                                </div>
                                                <div className='card-body d-flex justify-content-between align-items-center'>
                                                  {
                                                    dateOverrides[data].active ?
                                                      <>
                                                        <div className='d-flex flex-column align-items-start justify-content-center'>
                                                          {
                                                            dateOverrides[data].timeSet.map((time, key) => {
                                                              const set = time.split("-");
                                                              return (
                                                                <React.Fragment key={key}>
                                                                  <button disabled className='btn btn-secondary border-0 mb-1 w-100'>
                                                                    {
                                                                      timeFormat.value === "12 Hours" ?
                                                                        `${dayjs(`2 November 1999 ${set[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")}-${dayjs(`2 November 1999 ${set[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")}`
                                                                        :
                                                                        `${dayjs(`2 November 1999 ${set[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm")}-${dayjs(`2 November 1999 ${set[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm")}`
                                                                    }
                                                                  </button>
                                                                </React.Fragment>
                                                              )
                                                            })
                                                          }
                                                        </div>
                                                      </>
                                                      :
                                                      <>
                                                        <span>Unavailable</span>
                                                      </>
                                                  }
                                                </div>
                                              </div>
                                            )
                                          })
                                          :
                                          <p className='text-center'>No overrides for now</p>
                                        :
                                        <p className='text-center'>No overrides for now</p>
                                    }
                                  </div>

                                  <div className="col-12 col-lg-1"></div>
                                </div>
                              </div>
                            </div>
                            <div className="card-footer d-flex align-items-center justify-content-center gap-3">
                              {/* <button style={{ maxWidth: "120px" }} className='w-100 text-center btn btn-secondary rounded-pill py-1' type='button' onClick={() => { setCreateNewSchedule(false); setSchedule(defaultSchedule); setDateOverrides(defaultOverride); window.location.reload(); }}>Cancel</button> */}
                              <button style={{ maxWidth: "max-content" }} className='w-100 text-center btn salesforce-style rounded-pill py-1' type='button' disabled={!updateButtonEnabled || !isCustomLinkAvailable} onClick={(e) => { updateNewSchedule(e, "Group"); }}>Save Schedule</button>
                            </div>
                          </div>
                        </div>

                        {/* ##################### END OF NEW SCHEDULE SYSTEM GROUP ###################### */}
                      </div>
                    </div>



                    <div className="row my-3">
                      <div className="col-12 col-md-6 col-lg-6 mb-4">
                        <div className="container-fluid border insightBackground rounded" style={{ position: "relative", zIndex: "3" }}>
                          <div className="row">
                            <div className="col-12">
                              <span style={{ fontWeight: "500" }} className='my-3 d-block'>Want to add time before or after your events?</span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-12 d-flex align-items-center gap-4 py-2">
                              {
                                eventGroupBeforeTimeActive && (
                                  <input type="checkbox" defaultChecked onClick={() => { setEventGroupBeforeTimeActive(false); }} className='form-check-input checkboxInpage' />
                                )
                              }
                              {
                                eventGroupBeforeTimeActive === false && (
                                  <input type="checkbox" onClick={() => { setEventGroupBeforeTimeActive(true); }} className='form-check-input checkboxInpage' />
                                )
                              }

                              <div className="">
                                <h6 className={`${eventGroupBeforeTimeActive ? "" : "text-muted"}`}>
                                  Before Event
                                </h6>

                                {/* <DropdownButton
                                  as="buttonGroup"
                                  className='w-100'
                                  id={`dropdown-button-drop-down`}
                                  drop={"down"}
                                  variant="light"
                                  disabled={eventGroupBeforeTimeActive ? false : true}
                                  title={
                                    eventGroupBeforeTime > 60 ?
                                      Math.floor(eventGroupBeforeTime % 60) === 0 ?
                                        Math.floor(eventGroupBeforeTime / 60) + " hrs"
                                        : Math.floor(eventGroupBeforeTime / 60) + " hrs, " + Math.floor(eventGroupBeforeTime % 60) + " min"
                                      : Math.floor(eventGroupBeforeTime) + " min"
                                  }
                                  onSelect={(e, event) => { setEventGroupBeforeTime(e); }}

                                >
                                  <Dropdown.Item eventKey="15">15 minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="30">30 minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="45">45 minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="60">1 hr</Dropdown.Item>
                                  <Dropdown.Item eventKey="90">1 hr 30 min</Dropdown.Item>
                                  <Dropdown.Item eventKey="120">2 hr</Dropdown.Item>
                                  <Dropdown.Item eventKey="150">2 hr 30 min</Dropdown.Item>
                                  <Dropdown.Item eventKey="180">3 hr</Dropdown.Item>
                                </DropdownButton> */}

                                <Select
                                  isDisabled={eventGroupBeforeTimeActive ? false : true}
                                  options={addTimeAfterBeforeForSelect}
                                  defaultValue={[{ label: "30 minutes", value: 30 }]}
                                  onChange={(e) => { setEventGroupBeforeTime(e.value) }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mb-2">
                            <div className="col-12 d-flex align-items-center gap-4 py-2">
                              <div>
                                {
                                  eventGroupAfterTimeActive && (
                                    <input type="checkbox" defaultChecked onClick={() => { setEventGroupAfterTimeActive(false); }} className='form-check-input checkboxInpage' />
                                  )
                                }
                                {
                                  eventGroupAfterTimeActive === false && (
                                    <input type="checkbox" onClick={() => { setEventGroupAfterTimeActive(true); }} className='form-check-input checkboxInpage' />
                                  )
                                }
                              </div>
                              <div>
                                <h6 className={`${eventGroupAfterTimeActive ? "" : "text-muted"}`}>
                                  After Event
                                </h6>

                                {/* <DropdownButton
                                  as="buttonGroup"
                                  className='w-100'
                                  id={`dropdown-button-drop-down`}
                                  drop={"down"}
                                  variant="light"
                                  disabled={eventGroupAfterTimeActive ? false : true}
                                  title={
                                    eventGroupAfterTime > 60 ?
                                      Math.floor(eventGroupAfterTime % 60) === 0 ?
                                        Math.floor(eventGroupAfterTime / 60) + " hrs"
                                        : Math.floor(eventGroupAfterTime / 60) + " hrs, " + Math.floor(eventGroupAfterTime % 60) + " min"
                                      : Math.floor(eventGroupAfterTime) + " min"
                                  }
                                  onSelect={(e, event) => { setEventGroupAfterTime(e); }}

                                >
                                  <Dropdown.Item eventKey="15">15 minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="30">30 minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="45">45 minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="60">1 hr</Dropdown.Item>
                                  <Dropdown.Item eventKey="90">1 hr 30 min</Dropdown.Item>
                                  <Dropdown.Item eventKey="120">2 hr</Dropdown.Item>
                                  <Dropdown.Item eventKey="150">2 hr 30 min</Dropdown.Item>
                                  <Dropdown.Item eventKey="180">3 hr</Dropdown.Item>
                                </DropdownButton> */}

                                <Select
                                  isDisabled={eventGroupAfterTimeActive ? false : true}
                                  options={addTimeAfterBeforeForSelect}
                                  defaultValue={[{ label: "30 minutes", value: 30 }]}
                                  onChange={(e) => { setEventGroupAfterTime(e.value) }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-5 col-lg-5 mb-4 d-flex flex-column">
                        <p className='mediumTextSize'>Give yourself some buffer time to prepare for or wrap up from booked Event.</p>
                        <div className="rounded h-100">
                          <div className="card rounded overflow-hidden insightBackground">
                            <div className={`card-header p-1 ${eventGroupBeforeTimeActive ? "salesforce-style" : ""}`}></div>
                            <div className="card-body p-0">
                              <button type='button' className='btn insightBackground w-100 text-break'>{groupSummary}</button>
                            </div>
                            <div className={`card-footer p-1 ${eventGroupAfterTimeActive ? "salesforce-style" : ""}`}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6 d-flex align-items-center gap-2 mb-2">
                        <div className='border insightBackground rounded p-3 w-100'>
                          <div className='mb-3'>
                            <span style={{ fontWeight: "500" }}>Secret Event</span>
                            <p className='m-0 p-0 mediumTextSize'>Use this to hide the event on your main scheduling page.</p>
                          </div>
                          <div className='mb-2 d-flex align-items-center justify-content-start gap-2'>
                            {
                              secretEvent && (
                                <input type="checkbox" defaultChecked onClick={() => { setGroupSecretEvent(false); }} className='form-check-input checkboxInpage' />
                              )
                            }
                            {
                              secretEvent === false && (
                                <input type="checkbox" onClick={() => { setGroupSecretEvent(true); }} className='form-check-input checkboxInpage' />
                              )
                            }
                            <span className={`${groupSecretEvent ? "" : "text-muted"} mediumTextSize`} style={{ fontWeight: "500" }}>
                              Make this a Secret Event
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='col-12 col-md-6 col-lg-6'>
                        <div className='mediumTextSize w-100'>
                          <span style={{ fontWeight: "500" }}> Note:</span> Only Invitees who have this {process.env.REACT_APP_DOMAINNAME.split(".")[0]} link can schedule with you.
                        </div>
                      </div>
                    </div>


                    {/* #################################### Additional Settings Started ############################################### */}

                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6">
                        <span style={{ fontWeight: "500", fontSize: "1.2rem" }}>Additional Settings</span>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6">
                        <div className='insightBackground border p-3 rounded' style={{ position: "relative", zIndex: "2" }}>
                          <span className='d-block mb-2' style={{ fontWeight: "500" }}>Stop Bookings before:</span>
                          {/* <select id='endDateTime' className=' mt-2 shadow-sm' defaultValue={stopBookingTime[1]} required onChange={(e) => { }}>
                            <option value="1">1 Hour</option>
                            <option value="2">2 Hours</option>
                            <option value="3">3 Hours</option>
                            <option value="4">4 Hours</option>
                            <option value="custom">Custom</option>
                          </select> */}


                          {/* <DropdownButton
                            as="buttonGroup"
                            className='w-100'
                            drop={"down"}
                            variant="light"
                            title={
                              parseInt(stopBookingTime[1]) === 1 ? stopBookingTime[1] + " Hour" : stopBookingTime[1] + " Hours"
                            }
                            onSelect={(e, event) => { if (e !== "custom") { setStopBookingTime([stopTimeForBookingType, e]); setCustomStopBookingView(false); } else { setCustomStopBookingView(true); setStopBookingTime([stopTimeForBookingType, 1]); } }}

                          >
                            <Dropdown.Item eventKey="1">1 Hour</Dropdown.Item>
                            <Dropdown.Item eventKey="2">2 Hours</Dropdown.Item>
                            <Dropdown.Item eventKey="3">3 Hours</Dropdown.Item>
                            <Dropdown.Item eventKey="4">4 Hours</Dropdown.Item>
                            <Dropdown.Item eventKey="custom">custom</Dropdown.Item>
                          </DropdownButton> */}

                          <Select
                            options={stopBookingBeforeListForSelect}
                            defaultValue={[{ label: "1 Hour", value: 1 }]}
                            onChange={(e) => {
                              if (e.label !== "custom") {
                                setStopBookingTime([stopTimeForBookingType, e.value]);
                                setCustomStopBookingView(false);
                              }
                              else {
                                setCustomStopBookingView(true);
                                setStopBookingTime([stopTimeForBookingType, 1]);
                              }
                            }}
                          />
                          {
                            customStopBookingView ?
                              <div className='d-flex justify-content-center align-items-center gap-2 my-2'>
                                {
                                  stopTimeForBookingType === "hours" ?
                                    <input className='customInputFieldFocus css-13cymwt-control w-100' required type="number" min="1" max="6" defaultValue={"1"} onChange={(e) => { setStopBookingTime(["hours", e.target.value]); }} />
                                    :
                                    <input className='customInputFieldFocus css-13cymwt-control w-100' required type="number" min="1" max="60" defaultValue={"1"} onChange={(e) => { setStopBookingTime(["days", e.target.value]); }} />
                                }
                                {/* <DropdownButton
                                  as="buttonGroup"
                                  className='w-100'
                                  id={`dropdown-button-drop-down`}
                                  drop={"down"}
                                  variant="light"
                                  title="Hours"
                                  onSelect={(e, event) => { setStopTimeForBookingType(e); }}
                                >
                                  <Dropdown.Item eventKey="hours">hours</Dropdown.Item>
                                </DropdownButton> */}

                                <Select
                                  className='w-100'
                                  options={[{ label: "hours", value: "hours" }]}
                                  defaultValue={[{ label: "hours", value: "hours" }]}
                                  onChange={(e) => { setStopTimeForBookingType(e.label); }}
                                />

                                {/* <select className='' onChange={(e) => { setStopTimeForBookingType(e.target.value); }} value={stopTimeForBookingType === "hours" ? "hours" : "days"}>
                                  <option value="hours">hours</option>
                                  <option value="days">days</option>
                                </select> */}

                              </div>
                              :
                              ""
                          }
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6">
                        <div className='mediumTextSize w-100'>
                          <span style={{ fontWeight: "500" }}>Note:</span> This feature can control the last minute booking problems. Set your desired time to stop bookings after that particular time.
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-9 col-sm-9 col-md-9 col-lg-9">
                        <span style={{ fontWeight: "500", fontSize: "1.2rem" }}>Questions for Invitees:</span>
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                        <span className="mediumTextSize" style={{ fontWeight: "500" }}>Required</span>
                        <OverlayTrigger
                          placement="left"
                          delay={{ show: 100, hide: 200 }}
                          overlay={
                            <Popover id="popover-basic">
                              <Popover.Header className='salesforce-style' as="h3">Required Questions</Popover.Header>
                              <Popover.Body>
                                <p className='p-0 m-0 mb-2 helpTextSize'>You can make a questions mandatory while your invitees book a meeting whith you by using this switch.</p>
                                <img src={requiredQuestionPng} className='w-100' alt="" />
                              </Popover.Body>
                            </Popover>
                          }>
                          <HelpOutlineIcon style={{ maxWidth: "15px", cursor: "help" }} />
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-9 col-md-9 col-lg-9">
                        <input type="text" className='customInputFieldFocus w-100' onChange={(e) => { setGroupQuestions(e.target.value); }} defaultValue={"Share anything that can help us prepare the meeting."} placeholder='enter your question here?' />
                      </div>
                      <div className="col-3 col-md-3 col-lg-3 d-flex align-items-center">

                        <div className="outerToggleSwitch" onClick={() => { setIsDescriptionRequired(!isDescriptionRequired); }}>
                          <div className={`frameOfToggleSwitch ${isDescriptionRequired === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                            <span>On</span>
                            <div className="toggleSwitchBall"></div>
                            <span>Off</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* #################################### Additional Settings End ############################################### */}

                    <div className="row my-3">
                      <div className="col d-flex align-items-center justify-content-center">
                        <button className='btn salesforce-style rounded-pill py-2 px-5 d-flex align-items-center justify-content-center gap-2' id="groupCreationButton" disabled={!updateButtonEnabled}>Finish</button>
                      </div>
                    </div>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas show={roundRobinEventCreation} onHide={() => { setRoundRobinEventCreation(false); setViewNewEventCreate(true); setViewOf("NewMeetingCreation"); setToHistory("NewMeetingCreation", null); resetAllMeetingDetails(); }}
        scroll={true}
        backdrop={true}
        placement="start"
      >
        <Offcanvas.Header className='salesforce-style' closeButton closeVariant="white">
          <Offcanvas.Title>New Round Robin</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='backgroundFiller bg-soul py-0'>
          <div className={`container-fluid rounded-bottom ${roundRobinCreationSteps === 1 ? "" : "d-none"}`}>
            <div className="row sticky-top">
              <div className="col p-0">
                <div className='eventPreview insightBackground shadow-sm border'>
                  <div style={{ height: "2em", width: "2em", borderRadius: "100%", pointerEvents: "none", aspectRatio: "1" }} className={` repo-event-color-${roundRobinColorId}`} ></div>
                  <div>
                    <div className='prewEventWhich'>What event is this?</div>
                    <div className='eventNamePrew d-flex align-items-center gap-2 flex-wrap'>
                      <span className="text-capitalize text-break mediumTextSize" style={roundRobinSummary.includes(" ") ? { wordBreak: "break-word" } : { wordBreak: "break-all" }}>
                        {
                          roundRobinSummary.length > 0 ?
                            <>
                              {roundRobinSummary},
                            </>
                            :
                            ""
                        }
                      </span>
                      <span className="d-flex text-break align-items-center gap-1 mediumTextSize">
                        {
                          roundRobinMeetingType === "phone" ?
                            <span className='selectedLocation'>
                              <img src={callimg} alt="call" className='iconsForLocation' />
                              <span>{roundRobinLocation}</span>
                            </span> :
                            roundRobinMeetingType === "meet" ?
                              <span className='selectedLocation'>
                                <img src={meetImg} alt="meet" className='iconsForLocation' />
                                <span>Google Meet Conference</span>
                              </span> :
                              roundRobinMeetingType === "zoom" ?
                                <span className='selectedLocation'>
                                  <img src={zoomImg} alt="Zoom" className='iconsForLocation' />
                                  <span>Zoom Conference</span>
                                  {
                                    needZoomToken ?
                                      <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                      : ""
                                  }
                                </span> :
                                roundRobinMeetingType === "goto" ?
                                  <span className='selectedLocation'>
                                    <img src={gotoImg} alt="GoTo" className='iconsForLocation' />
                                    <span>GoTo Conference</span>
                                    {
                                      needGTMToken ?
                                        <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                        : ""
                                    }
                                  </span> :
                                  roundRobinMeetingType === "webex" ?
                                    <span className='selectedLocation'>
                                      <img src={webexImg} alt="Webex" className='iconsForLocation' />
                                      <span>Webex Conference</span>
                                      {
                                        needWebexToken ?
                                          <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                          : ""
                                      }
                                    </span> :
                                    roundRobinMeetingType === "teams" ?
                                      <span className='selectedLocation'>
                                        <img src={teamsImg} alt="Teams" className='iconsForLocation' />
                                        <span>Teams Conference</span>
                                        {
                                          needTeamsToken ?
                                            <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                            : ""
                                        }
                                      </span> :
                                      roundRobinMeetingType === "address" && roundRobinLocation.length > 0 ?
                                        <span className='selectedLocation'>
                                          <img src={addressImg} alt="address" className='iconsForLocation' />
                                          <span>{roundRobinLocation}</span>
                                        </span> :
                                        "No location Given"
                        }
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <form id="eventFormHandle" className="eventGoogleForm p-0" onSubmit={nextPageToRoundRobin}>
                  <br />
                  <div className="container-fluid p-0">
                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6 p-0">
                        <label htmlFor='summary' className='border w-100 p-2 insightBackground rounded'>
                          <span className='' style={{ fontWeight: "500" }}>
                            Event Name *
                          </span>
                          <input
                            type="text"
                            maxLength={100}
                            id="summary"
                            className='customInputFieldFocus w-100 px-2'
                            value={roundRobinSummary}
                            required
                            placeholder="Add the name of the event"
                            onChange={e => setRoundRobinSummary(e.target.value)}
                          />
                        </label>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 p-0">

                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6 p-0">
                        <label htmlFor='selectLocation' className={`border w-100 p-2 insightBackground rounded ${locationSelection ? "mb-0" : ""}`} onClick={() => { setLocationSelection(!locationSelection); }}>
                          <span className='' style={{ fontWeight: "500" }}>
                            Select Location
                          </span>
                          <div id="selectLocation" className='locationFrame bg-soul rounded shadow-sm'>
                            {
                              roundRobinMeetingType ?
                                roundRobinMeetingType === "phone" ?
                                  <div className='selectedLocation'>
                                    <img alt="call" src={callimg} className='iconsForLocation' />
                                    <span>
                                      Phone call
                                    </span>
                                  </div>
                                  :
                                  roundRobinMeetingType === "meet" ?
                                    <div className='selectedLocation'>
                                      <img alt="meet" src={meetImg} className='iconsForLocation' />
                                      <span>
                                        Google Meet Conference
                                      </span>
                                    </div>
                                    :
                                    roundRobinMeetingType === "zoom" ?
                                      <div className='selectedLocation'>
                                        <img alt="zoom" src={zoomImg} className='iconsForLocation' />
                                        <span>
                                          Zoom Conference
                                        </span>
                                        {
                                          needZoomToken ?
                                            <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                            : ""
                                        }
                                      </div>
                                      :
                                      roundRobinMeetingType === "goto" ?
                                        <div className='selectedLocation'>
                                          <img alt="Goto" src={gotoImg} className='iconsForLocation' />
                                          <span>
                                            GoTo Conference
                                          </span>
                                          {
                                            needGTMToken ?
                                              <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                              : ""
                                          }
                                        </div>
                                        :
                                        roundRobinMeetingType === "webex" ?
                                          <div className='selectedLocation'>
                                            <img alt="Webex" src={webexImg} className='iconsForLocation' />
                                            <span>
                                              Webex Conference
                                            </span>
                                            {
                                              needWebexToken ?
                                                <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                : ""
                                            }
                                          </div>
                                          :
                                          roundRobinMeetingType === "teams" ?
                                            <div className='selectedLocation'>
                                              <img alt="Teams" src={teamsImg} className='iconsForLocation' />
                                              <span>
                                                Teams Conference
                                              </span>
                                              {
                                                needTeamsToken ?
                                                  <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                                  : ""
                                              }
                                            </div>
                                            :
                                            roundRobinMeetingType === "address" ?
                                              <div className='selectedLocation'>
                                                <img alt="address" src={addressImg} className='iconsForLocation' />
                                                <span>
                                                  Custom Address
                                                </span>
                                              </div>
                                              :
                                              <div className='selectedLocation'>
                                                <img alt="address" src={addressImg} className='iconsForLocation' />
                                                <span>
                                                  Custom Address
                                                </span>
                                              </div>
                                :
                                ""
                            }

                          </div>
                        </label>


                        {
                          locationSelection &&
                          <div id='locationSelector' className="locationSelector bg-body rounded shadow-sm">
                            <ul>
                              <li title='Phone Call' onClick={() => { setRoundRobinMeetingType("phone"); setRoundRobinLocation("phone"); setLocationSelection(false); }}>
                                <span>
                                  <img alt="Call" src={callimg} className='iconsForLocation' />
                                </span>
                                <span>
                                  Phone Call
                                </span>
                              </li>
                              <li title='Google Meet Conference' onClick={() => { setRoundRobinMeetingType("meet"); setRoundRobinLocation("meet"); setLocationSelection(false); }}>
                                <span>
                                  <img alt="Meet" src={meetImg} className='iconsForLocation' />
                                </span>
                                <span>
                                  Google Meet Conference
                                </span>
                              </li>
                              <li title='Zoom Conference' onClick={() => { setRoundRobinMeetingType("zoom"); setRoundRobinLocation("zoom"); setLocationSelection(false); }}>
                                <span>
                                  <img alt="Zoom" src={zoomImg} className='iconsForLocation' />
                                </span>
                                <span>
                                  Zoom Conference
                                </span>
                                {
                                  needZoomToken ?
                                    <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                    : ""
                                }
                              </li>
                              <li title='GoTo Conference' onClick={() => { setRoundRobinMeetingType("goto"); setRoundRobinLocation("goto"); setLocationSelection(false); }}>
                                <span>
                                  <img alt="Goto" src={gotoImg} className='iconsForLocation' />
                                </span>
                                <span>
                                  GoTo Conference
                                </span>
                                {
                                  needGTMToken ?
                                    <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                    : ""
                                }
                              </li>
                              <li title='Webex Conference' onClick={() => { setRoundRobinMeetingType("webex"); setRoundRobinLocation("webex"); setLocationSelection(false); }}>
                                <span>
                                  <img alt="Webex" src={webexImg} className='iconsForLocation' />
                                </span>
                                <span>
                                  Webex Conference
                                </span>
                                {
                                  needWebexToken ?
                                    <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                    : ""
                                }
                              </li>
                              <li title='Teams Conference' onClick={() => { setRoundRobinMeetingType("teams"); setRoundRobinLocation("teams"); setLocationSelection(false); }}>
                                <span>
                                  <img alt="Teams" src={teamsImg} className='iconsForLocation' />
                                </span>
                                <span>
                                  Teams Conference
                                </span>
                                {
                                  needTeamsToken ?
                                    <i className="fa fa-chain-broken text-danger" aria-hidden="true"></i>
                                    : ""
                                }
                              </li>
                              <li title='Address' onClick={() => { setRoundRobinMeetingType("address"); setLocationSelection(false); }}>
                                <span>
                                  <img alt="Address" src={addressImg} className='iconsForLocation' />
                                </span>
                                <span>
                                  Custom Address
                                </span>
                              </li>
                            </ul>
                          </div>
                        }

                        {
                          roundRobinMeetingType === "phone" && (
                            <label className={`border p-2 insightBackground w-100 rounded ${inboundCall ? "rounded-top" : "rounded"}`}>
                              <div className="askWhich">
                                <label htmlFor="outboundCalloff2">
                                  <input type="radio" defaultChecked id="outboundCalloff2" name="callBound" onClick={() => { setInboundCall(false); }} />
                                  I should call my invitees
                                </label>
                                <label htmlFor="inboundCallonn2">
                                  {
                                    phoneList ?
                                      optionListForPhone.length > 0 ?
                                        <>
                                          <input type="radio" id="inboundCallonn2" name="callBound" onClick={() => { setInboundCall(true); }} />
                                          <span>Invitees should call me</span>
                                        </>
                                        :
                                        <>
                                          <input type="radio" id="inboundCallonn2" name="callBound" disabled={true} onClick={() => { setInboundCall(true); }} />
                                          <span className='text-muted'>Invitees should call me</span>
                                        </>
                                      :
                                      <>
                                        <input type="radio" id="inboundCallonn2" name="callBound" disabled={true} onClick={() => { setInboundCall(true); }} />
                                        <span className='text-muted'>Invitees should call me</span>
                                      </>
                                  }
                                </label>
                              </div>

                            </label>
                          )
                        }


                        {
                          roundRobinMeetingType === "phone" && inboundCall ?
                            <label htmlFor='phoneCallRoundRobin' className='border p-2 insightBackground w-100 rounded-bottom' style={{ position: "relative", zIndex: "2" }}>
                              <span style={{ fontWeight: "500" }}>
                                Choose Phone Number *
                              </span>
                              <PhoneInput
                                required
                                className="mb-1 w-100 phoneNUmberInpurJHSGFUGDSFIY pointer-events-none"
                                style={{ pointerEvents: "none" }}
                                placeholder=""
                                value={roundRobinLocation ? roundRobinLocation : optionListForPhone[0].label}
                                onChange={setRoundRobinLocation} />
                              <Select
                                id='phoneCallRoundRobin'
                                options={optionListForPhone}
                                defaultValue={optionListForPhone[0]}
                                onChange={(e) => { setRoundRobinLocation(e.label) }}
                              />
                            </label>
                            :
                            roundRobinMeetingType === "address" ?
                              <label className='border p-2 insightBackground w-100 rounded'>
                                <span style={{ fontWeight: "500" }}>
                                  Enter Address Here *
                                </span>
                                <input
                                  required
                                  type="text"
                                  className='customInputFieldFocus w-100 border px-2'
                                  defaultValue={""}
                                  placeholder=""
                                  onChange={(e) => setRoundRobinLocation(e.target.value)}
                                />
                              </label>
                              :
                              roundRobinMeetingType === "meet" && needToken ?
                                <label>
                                  <GoogleLogin
                                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                    buttonText='Sign in & Authorize Calendar'
                                    onSuccess={responceGoogle}
                                    onFailure={responseError}
                                    cookiePolicy={"single_host_origin"}
                                    fetchBasicProfile={true}
                                    responseType="code"
                                    prompt="consent"
                                    accessType='offline'
                                    scope='openid email profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'
                                  />
                                </label>
                                :
                                roundRobinMeetingType === "zoom" && needZoomToken ?
                                  <label>
                                    <a href={"#Integrations"} onClick={(e) => {
                                      setViewOf("Integrations");
                                      setViewAdminPage(true);

                                      setViewNewEventCreate(false);
                                      setOneOnOneEventCreation(false);
                                      setEventCreationSteps(1);
                                      setGroupEventCreation(false);
                                      setGroupEventCreationSteps(1);
                                      setRoundRobinEventCreation(false);
                                      setRoundRobinCreationSteps(1);
                                      setAlertShow(false);
                                      setContactShow(false);
                                      setMeetingShow(false);
                                      setAvailabilityShow(false);
                                    }} className='color-salesforce text-decoration-none mediumTextSize'>
                                      Click to Connect Zoom.
                                    </a>
                                  </label> :
                                  roundRobinMeetingType === "webex" && needWebexToken ?
                                    <label>
                                      <a href={"#Integrations"} onClick={(e) => {
                                        setViewOf("Integrations");
                                        setViewAdminPage(true);

                                        setViewNewEventCreate(false);
                                        setOneOnOneEventCreation(false);
                                        setEventCreationSteps(1);
                                        setGroupEventCreation(false);
                                        setGroupEventCreationSteps(1);
                                        setRoundRobinEventCreation(false);
                                        setRoundRobinCreationSteps(1);
                                        setAlertShow(false);
                                        setContactShow(false);
                                        setMeetingShow(false);
                                        setAvailabilityShow(false);
                                      }} className='color-salesforce text-decoration-none mediumTextSize'>
                                        Click to Connect Webex.
                                      </a>
                                    </label> :
                                    roundRobinMeetingType === "goto" && needGTMToken ?
                                      <label>
                                        <a href={"#Integrations"} onClick={(e) => {
                                          setViewOf("Integrations");
                                          setViewAdminPage(true);

                                          setViewNewEventCreate(false);
                                          setOneOnOneEventCreation(false);
                                          setEventCreationSteps(1);
                                          setGroupEventCreation(false);
                                          setGroupEventCreationSteps(1);
                                          setRoundRobinEventCreation(false);
                                          setRoundRobinCreationSteps(1);
                                          setAlertShow(false);
                                          setContactShow(false);
                                          setMeetingShow(false);
                                          setAvailabilityShow(false);
                                        }} className='color-salesforce text-decoration-none mediumTextSize'>
                                          Click to Connect GoTo Meeting.
                                        </a>
                                      </label> :
                                      roundRobinMeetingType === "teams" && needTeamsToken ?
                                        <label>
                                          <a href={"#Integrations"} onClick={(e) => {
                                            setViewOf("Integrations");
                                            setViewAdminPage(true);

                                            setViewNewEventCreate(false);
                                            setOneOnOneEventCreation(false);
                                            setEventCreationSteps(1);
                                            setGroupEventCreation(false);
                                            setGroupEventCreationSteps(1);
                                            setRoundRobinEventCreation(false);
                                            setRoundRobinCreationSteps(1);
                                            setAlertShow(false);
                                            setContactShow(false);
                                            setMeetingShow(false);
                                            setAvailabilityShow(false);
                                          }} className='color-salesforce text-decoration-none mediumTextSize'>
                                            Click to Connect Teams.
                                          </a>
                                        </label> :
                                        ""
                        }
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 p-0">

                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6 p-0">
                        <label htmlFor='description' className='border p-2 insightBackground w-100 rounded'>
                          <div className='d-flex align-items-center justify-content-between w-100'>
                            <span style={{ fontWeight: "500" }}>
                              Description *
                            </span>
                          </div>
                          {
                            isDescriptionRequired ?
                              <textarea
                                id="description"
                                className='customInputFieldFocus w-100 border px-2'
                                value={roundRobinDescription}
                                required
                                placeholder="Write a summary and any details your invitee should know about the event."
                                onChange={e => setRoundRobinDescription(e.target.value)}
                              />
                              :
                              <textarea
                                id="description"
                                className='customInputFieldFocus w-100 px-2'
                                value={roundRobinDescription}
                                placeholder="Write a summary and any details your invitee should know about the event."
                                onChange={e => setRoundRobinDescription(e.target.value)}
                              />
                          }
                        </label>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 p-0">

                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6 p-0">
                        <label htmlFor='description' className='border p-2 insightBackground w-100 rounded d-flex flex-column'>
                          <span className='d-flex gap-1 align-items-center' style={{ fontWeight: "500" }}>
                            Event Link *

                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 100, hide: 200 }}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Header className='salesforce-style' as="h3">Unique event links</Popover.Header>
                                  <Popover.Body>
                                    <p className='p-0 m-0 helpTextSize'>
                                      Use this field to change the unique url for this event.
                                    </p>
                                    <img src={uniqueLinkHelpText} className='img img-responsive w-100' alt='helpText' />
                                  </Popover.Body>
                                </Popover>
                              }>
                              <HelpOutlineIcon style={{ maxWidth: "15px", cursor: "help" }} />
                            </OverlayTrigger>
                          </span>
                          <span className='mediumTextSize text-muted'>{process.env.REACT_APP_DOMAINNAME + `/${username.replaceAll(" ", "-")}/`}</span>

                          <div className="input-group w-100 d-flex flex-nowrap rounded">
                            <input type="text" maxLength={50} id="UID" required className='customInputFieldFocus w-100 border px-2' placeholder='UID' onClick={(e) => { if (e.target.value.length < 1) { checkCustomLinkAvailable({ target: { value: roundRobinSummary.replaceAll(" ", "-") } }); } }} autoComplete="off" value={customEventLink} onChange={checkCustomLinkAvailable} />
                            <div className="input-group-append">
                              <span className="input-group-text h-100 border-0" style={{ borderRadius: "0px 0.375rem 0.375rem 0px" }}>
                                {
                                  isCustomLinkAvailable ?
                                    <i className='fa fa-check text-success'></i>
                                    :
                                    <i className='fa fa-times text-danger'></i>
                                }
                              </span>
                            </div>
                          </div>


                        </label>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 p-0">

                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12 p-0">
                        <label className='border p-2 insightBackground w-100 rounded d-flex flex-column'>
                          <span className='pb-1' style={{ fontWeight: "500" }}>
                            Event Color
                          </span>
                          <div className='event-color-holder pb-2'>
                            <div onClick={(e) => { setRoundRobinColorId('1'); checkThisTab(e.target); }} className='event-color-tab event-color-1 checked-color'></div>
                            <div onClick={(e) => { setRoundRobinColorId('2'); checkThisTab(e.target); }} className='event-color-tab event-color-2'></div>
                            <div onClick={(e) => { setRoundRobinColorId('3'); checkThisTab(e.target); }} className='event-color-tab event-color-3'></div>
                            <div onClick={(e) => { setRoundRobinColorId('4'); checkThisTab(e.target); }} className='event-color-tab event-color-4'></div>
                            <div onClick={(e) => { setRoundRobinColorId('5'); checkThisTab(e.target); }} className='event-color-tab event-color-5'></div>
                            <div onClick={(e) => { setRoundRobinColorId('6'); checkThisTab(e.target); }} className='event-color-tab event-color-6'></div>
                            <div onClick={(e) => { setRoundRobinColorId('7'); checkThisTab(e.target); }} className='event-color-tab event-color-7'></div>
                            <div onClick={(e) => { setRoundRobinColorId('8'); checkThisTab(e.target); }} className='event-color-tab event-color-8'></div>
                            <div onClick={(e) => { setRoundRobinColorId('9'); checkThisTab(e.target); }} className='event-color-tab event-color-9'></div>
                            <div onClick={(e) => { setRoundRobinColorId('10'); checkThisTab(e.target); }} className='event-color-tab event-color-10'></div>
                            <div onClick={(e) => { setRoundRobinColorId('11'); checkThisTab(e.target); }} className='event-color-tab event-color-11'></div>
                          </div>
                        </label>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12 p-0">
                        <center>
                          <button type='submit' disabled={!isCustomLinkAvailable} className='btn salesforce-style px-5 rounded-pill'>Next</button>
                        </center>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className={`container-fluid rounded-bottom ${roundRobinCreationSteps === 2 ? "" : "d-none"}`}>
            <div className="row sticky-top">
              <div className="col p-0">
                <div className='eventPreview bg-body shadow-sm border'>
                  <button type='button' className='btn align-self-center salesforce-style border rounded-circle' onClick={() => { setRoundRobinCreationSteps(1); }}>
                    <i className='fa fa-arrow-left'></i>
                  </button>
                  <img src={caneldarDark} alt="" className="iconsForLocation" />
                  <div>
                    <div className='prewEventWhich'>When can people book this event?</div>
                    <div className='eventNamePrew d-flex align-items-center gap-2 flex-wrap'>
                      <span id="eventNamePrew">
                        {
                          roundRobinDuration > 60 ?
                            Math.floor(roundRobinDuration % 60) === 0 ?
                              Math.floor(roundRobinDuration / 60) + " hrs"
                              : Math.floor(roundRobinDuration / 60) + " hrs, " + Math.floor(roundRobinDuration % 60) + " min"
                            : Math.floor(roundRobinDuration) + " min"
                        },
                      </span>
                      <span id="eventLocationPrew">{dayjs(betweenDays[0].startDate).format("D MMM YYYY")} - {betweenDays[0].endDate ? dayjs(betweenDays[0].endDate).format("D MMM YYYY") : "Continuous"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col p-0">
                <form onSubmit={(e) => { createRoundRobinMeeting(e); }}>
                  <div className='container-fluid p-0'>

                    <div className="row my-3">
                      <div className="col">
                        <div className='p-3 insightBackground rounded border'>
                          <span style={{ fontWeight: "500" }}>
                            Date Range
                          </span>
                          <p className='m-0'>Set a range of dates when you can accept meetings.</p>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col">
                        <div className='rescheduler'>
                          <label className='h6' style={{ fontWeight: "500" }}>
                            Invitees can schedule...
                          </label>
                          {/* <div className="w-100 overflow-auto">
                            <DateRange
                              // editableDateInputs={true}
                              onChange={item => setBetweenDays([item.selection])}
                              // showSelectionPreview={true}
                              // moveRangeOnFirstSelection={false}
                              months={2}
                              minDate={new Date()}
                              ranges={betweenDays}
                              direction="horizontal"
                              editableDateInputs={true}
                            />
                          </div> */}


                          <div className='w-100 d-flex align-items-center gap-2 mb-4'>
                            <label htmlFor="rollingDaysStraight" className={`d-flex align-items-center gap-2 ${straightRollingDays ? "" : "text-muted"}`}
                              onClick={() => {
                                setStraightRollingDays(true);
                                setWithinDateRange(false);
                                setInfiniteDateRange(false);
                                setBetweenDays([
                                  {
                                    startDate: new Date(),
                                    endDate: addDays(new Date(), parseInt(rollingDaysRef.current.value)),
                                    key: 'selection'
                                  }
                                ]);

                              }}
                            >
                              {
                                straightRollingDays ?
                                  <input type="radio" checked id='rollingDaysStraight' className='form-check-input p-0 m-0' name="rollingDays" />
                                  :
                                  <input type="radio" id='rollingDaysStraight' className='form-check-input p-0 m-0' name="rollingDays" />
                              }

                              <input
                                type="number"
                                disabled={!straightRollingDays}
                                defaultValue={30}
                                min={1}
                                required
                                ref={rollingDaysRef}
                                onClick={
                                  (e) => {
                                    setBetweenDays([
                                      {
                                        startDate: new Date(),
                                        endDate: addDays(new Date(), parseInt(e.target.value)),
                                        key: 'selection'
                                      }
                                    ]);
                                  }}
                                onInput={
                                  (e) => {
                                    setBetweenDays([
                                      {
                                        startDate: new Date(),
                                        endDate: addDays(new Date(), parseInt(e.target.value)),
                                        key: 'selection'
                                      }
                                    ]);

                                    // console.log(addDays(new Date(), parseInt(e.target.value)))
                                  }
                                } className='customInputFieldFocus rounded border' />
                            </label>
                            <div style={{ position: "relative", zIndex: "10" }}>
                              <Select
                                isDisabled={!straightRollingDays}
                                className='mediumTextSize'
                                options={
                                  [
                                    { label: "calendar days", value: "calendardays" },
                                    // { label: "weekdays", value: "weekdays" }
                                  ]
                                }
                                defaultValue={[{ label: "calendar days", value: "calendardays" }]}
                                onChange={(e) => { }}
                              />
                            </div>
                            <div className={`${straightRollingDays ? "" : "text-muted"}`}>
                              into the future
                            </div>
                          </div>

                          <div className='w-100 d-flex align-items-center gap-2 mb-4'>
                            <label htmlFor="betweenForever" className={`d-flex align-items-center gap-2 ${withinDateRange ? "" : "text-muted"}`}
                              onClick={(e) => {
                                setStraightRollingDays(false);
                                setWithinDateRange(true);
                                setInfiniteDateRange(false);
                              }}
                            >
                              {
                                withinDateRange ?
                                  <input type="radio" checked id='betweenForever' className='form-check-input p-0 m-0' name="rollingDays" />
                                  :
                                  <input type="radio" id='betweenForever' className='form-check-input p-0 m-0' name="rollingDays" />
                              }
                              <span className='mediumTextSize'>Within a date range</span>
                            </label>
                            {
                              withinDateRange ?
                                <div className='flex-fill' style={{ position: "relative", zIndex: "9", maxWidth: "max-content" }} onClick={() => { setShowDateRangeSelector(true); setWithinDateRange(true); setStraightRollingDays(false); setInfiniteDateRange(false); }}>
                                  <input type="text" disabled={!withinDateRange} value={`${dayjs(betweenDays[0].startDate).format("D MMM YYYY")} - ${betweenDays[0].endDate ? dayjs(betweenDays[0].endDate).format("D MMM YYYY") : "Continuous"}`} className=" w-100 rounded p-2 border mediumTextSize" />
                                </div>
                                :
                                ""
                            }
                          </div>

                          <div className='w-100 mb-4'>
                            <label htmlFor="foreversRoll" className={`d-flex align-items-center gap-2  ${infiniteDateRange ? "" : "text-muted"}`}
                              onClick={(e) => {
                                setStraightRollingDays(false);
                                setWithinDateRange(false);
                                setInfiniteDateRange(true);

                                setBetweenDays([
                                  {
                                    startDate: new Date(),
                                    endDate: null,
                                    key: 'selection'
                                  }
                                ]);
                              }}
                            >
                              {
                                infiniteDateRange ?
                                  <input type="radio" checked id='foreversRoll' className='form-check-input p-0 m-0' name="rollingDays" />
                                  :
                                  <input type="radio" id='foreversRoll' className='form-check-input p-0 m-0' name="rollingDays" />
                              }
                              <span className={`mediumTextSize`}>Indefinitely into the future</span>
                            </label>
                          </div>


                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12">
                        <label htmlFor='endDateTime' className='w-100 border rounded p-3 insightBackground' style={{ position: "relative", zIndex: "5" }}>
                          <span style={{ fontWeight: "500" }}>Meeting Duration</span>
                          <p className='m-0 mb-2 mediumTextSize'>Define how long your event will be. It can be as long as 12 hours.</p>
                          <div className="container-fluid p-0">
                            <div className="row">
                              <div className="col-12 col-md-6 col-lg-6">
                                {/* <DropdownButton
                            as="buttonGroup"
                            className='w-100'
                            id={`dropdown-button-drop-down-round-robin`}
                            drop={"right"}
                            variant="light"
                            title={
                              roundRobinDuration > 60 ?
                                Math.floor(roundRobinDuration % 60) === 0 ?
                                  Math.floor(roundRobinDuration / 60) + " hrs"
                                  : Math.floor(roundRobinDuration / 60) + " hrs, " + Math.floor(roundRobinDuration % 60) + " min"
                                : Math.floor(roundRobinDuration) + " min"
                            }
                            onSelect={(e, event) => { if (e !== "custom") { setRoundRobinDuration(e); setCustomHourView(false); } else { setCustomHourView(true); setRoundRobinDuration(15) } }}

                          >
                            <Dropdown.Item eventKey="15">15 minutes</Dropdown.Item>
                            <Dropdown.Item eventKey="30">30 minutes</Dropdown.Item>
                            <Dropdown.Item eventKey="45">45 minutes</Dropdown.Item>
                            <Dropdown.Item eventKey="60">60 minutes</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item eventKey="custom">Custom</Dropdown.Item>
                          </DropdownButton> */}

                                <Select
                                  options={meetingDurationListForSelect}
                                  defaultValue={[{ label: "30 minutes", value: "30" }]}
                                  onChange={(e) => { if (e.value !== "custom") { setRoundRobinDuration(e.value); setCustomHourView(false); } else { setCustomHourView(true); setRoundRobinDuration(15) } }}
                                />

                                {
                                  customHourView ?
                                    <div className='d-flex justify-content-center align-items-center gap-2 my-2'>
                                      {
                                        durationType === "hours" ?
                                          <input className='customInputFieldFocus css-13cymwt-control w-100' required type="number" min="1" max="12" defaultValue={"1"} ref={minutesRoundRobin} onChange={(e) => { convertToMinutesRoundRobin(e.target.value); }} />
                                          :
                                          <input className='customInputFieldFocus css-13cymwt-control w-100' required type="number" min="15" max="720" defaultValue={"15"} ref={minutesRoundRobin} onChange={(e) => { convertToMinutesRoundRobin(e.target.value); }} />
                                      }

                                      {/* <DropdownButton
                                  as="buttonGroup"
                                  className='w-100'
                                  id={`dropdown-button-drop-down`}
                                  drop={"down"}
                                  variant="light"
                                  title={durationType}

                                  onSelect={(e, event) => { setDurationType(e) }}

                                >
                                  <Dropdown.Item eventKey="minutes">minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="hours">hours</Dropdown.Item>
                                </DropdownButton> */}

                                      <Select
                                        className='w-50'
                                        options={[{ label: "minutes", value: "minutes" }, { label: "hours", value: "hours" },]}
                                        defaultValue={[{ label: "minutes", value: "minutes" }]}
                                        onChange={(e) => { setDurationType(e.value); }}
                                      />


                                      {/* <select className='' onChange={(e) => { setDurationType(e.target.value); }} value={durationType === "hours" ? "hours" : "minutes"}>
                                <option value="minutes">minutes</option>
                                <option value="hours">hours</option>
                              </select> */}
                                    </div>
                                    :
                                    ""
                                }
                              </div>
                            </div>
                          </div>
                        </label>

                      </div>
                    </div>

                    <div className='row mb-3'>
                      <div className="col">
                        <div className='w-100 border rounded p-3 insightBackground'>
                          <span style={{ fontWeight: "500" }}>How do you want to offer your availability for this event type?</span>
                          <p>Select one of your schedules or define custom hours specific to this type of event.</p>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col">
                        <div className='w-100'>
                          <button type='button' className={`btn ${existingScheduleView ? "salesforce-style" : "bg-body border"}`} onClick={() => { setExistingScheduleView(true); setCustomScheduleView(false); }}>Use an existing schedule</button>
                          <button type='button' className={`btn mx-3 ${customScheduleView ? "salesforce-style" : "bg-body border"}`} onClick={() => { setExistingScheduleView(false); setCustomScheduleView(true); }}>Set custom hours</button>
                        </div>
                      </div>
                    </div>

                    {/* Existing schedule */}

                    <div className={`row mb-3 ${existingScheduleView ? "" : "d-none"}`}>
                      <div className="col">

                        <div className="rounded text-dark insightBackground border" style={{ position: "relative", zIndex: "4" }}>
                          <div className="border-bottom p-3">
                            <span className='mb-1 w-100 d-block' style={{ fontWeight: "500" }}>Which schedule type do you want to use for this event type?</span>

                            {/* <select className=' my-2' value={scheduleName} onChange={(e) => { toogleScheduleSet(e.target.value); setScheduleName(e.target.value); }}>
                              {
                                userSchedules ?
                                  Object.keys(userSchedules).map((name, key) => {
                                    return <option value={name}>{name}</option>
                                  })
                                  :
                                  <option value="working hours">Working Hours</option>
                              }
                            </select> */}

                            {/* <DropdownButton
                                as="buttonGroup"
                                className='w-100'
                                drop={"down"}
                                variant="light"
                                title={scheduleName ? scheduleName : ""}
                                onSelect={(e, event) => { toogleScheduleSet(e); setScheduleName(e); }}

                              >
                                {
                                  userSchedules ?
                                    Object.keys(userSchedules).map((name, key) => {
                                      return <Dropdown.Item key={key} eventKey={name}>{name}</Dropdown.Item>
                                    })
                                    :
                                    <Dropdown.Item eventKey="working hours">Working Hours</Dropdown.Item>
                                }
                              </DropdownButton> */}

                            <Select
                              options={userScheduleListForSelect}
                              defaultValue={[{ label: scheduleName, value: scheduleName }]}
                              onChange={(e) => { toogleScheduleSet(e.label); setScheduleName(e.label); }}
                            />


                            <span style={{ fontWeight: "500" }} className="d-block mb-1 mt-3">
                              Time Zone
                            </span>
                            <Select className='mb-3 p-0 w-100' value={[selectedTimezone]} options={filteredTimezonesListSchedule} isDisabled={true} onChange={(e) => { setSelectedTimezone(e); }} />
                            <div className="d-flex align-items-center gap-2 my-3">
                              <i className='fa fa-globe fs-5'></i>
                              <span style={{ fontWeight: "600" }}>
                                {selectedTimezone.value ? selectedTimezone.value.name : ""}
                              </span>
                            </div>
                            <p className='mediumTextSize'>Automatically detected timezone is {selectedTimezone.value ? selectedTimezone.value.name : ""}. You may change it to specify default time-zone for your calendar.</p>
                          </div>
                          <div className="p-2">
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-12 col-lg-8 ">
                                  <div className="container-fluid insightBackground rounded border h-100">
                                    <div className="row">
                                      <div className="col-4 col-md-2 col-lg-2 h6 p-2">SUN</div>
                                      <div className="col-8 col-md-10 col-lg-10 p-2">
                                        {
                                          isSundayActive ?
                                            schedule.sunday.times.map((time, key) => {
                                              return <p className='w-100 mb-2 text-start' key={key}>
                                                {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                              </p>
                                            })
                                            :
                                            <span className='text-muted'>unavailable</span>
                                        }
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className=" col-4 col-md-2 col-lg-2 h6 p-2">MON</div>
                                      <div className="col-8 col-md-10 col-lg-10 p-2">
                                        {
                                          isMondayActive ?
                                            schedule.monday.times.map((time, key) => {
                                              return <p className='w-100 mb-2 text-start' key={key}>
                                                {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                              </p>
                                            })
                                            :
                                            <span className='text-muted'>unavailable</span>
                                        }
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className=" col-4 col-md-2 col-lg-2 h6 p-2">TUE</div>
                                      <div className="col-8 col-md-10 col-lg-10 p-2">
                                        {
                                          isTuesdayActive ?
                                            schedule.tuesday.times.map((time, key) => {
                                              return <p className='w-100 mb-2 text-start' key={key}>
                                                {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                              </p>
                                            })
                                            :
                                            <span className='text-muted'>unavailable</span>
                                        }
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className=" col-4 col-md-2 col-lg-2 h6 p-2">WED</div>
                                      <div className="col-8 col-md-10 col-lg-10 p-2">
                                        {
                                          isWednesdayActive ?
                                            schedule.wednesday.times.map((time, key) => {
                                              return <p className='w-100 mb-2 text-start' key={key}>
                                                {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                              </p>
                                            })
                                            :
                                            <span className='text-muted'>unavailable</span>
                                        }
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className=" col-4 col-md-2 col-lg-2 h6 p-2">THU</div>
                                      <div className="col-8 col-md-10 col-lg-10 p-2">
                                        {
                                          isThursdayActive ?
                                            schedule.thursday.times.map((time, key) => {
                                              return <p className='w-100 mb-2 text-start' key={key}>
                                                {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                              </p>
                                            })
                                            :
                                            <span className='text-muted'>unavailable</span>
                                        }
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className=" col-4 col-md-2 col-lg-2 h6 p-2">FRI</div>
                                      <div className="col-8 col-md-10 col-lg-10 p-2">
                                        {
                                          isFridayActive ?
                                            schedule.friday.times.map((time, key) => {
                                              return <p className='w-100 mb-2 text-start' key={key}>
                                                {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                              </p>
                                            })
                                            :
                                            <span className='text-muted'>unavailable</span>
                                        }
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className=" col-4 col-md-2 col-lg-2 h6 p-2">SAT</div>
                                      <div className="col-8 col-md-10 col-lg-10 p-2">
                                        {
                                          isSaturdayActive ?
                                            schedule.saturday.times.map((time, key) => {
                                              return <p className='w-100 mb-2 text-start' key={key}>
                                                {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                              </p>
                                            })
                                            :
                                            <span className='text-muted'>unavailable</span>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-12 col-lg-4">
                                  <h5 className='py-3 text-center'>Date Overrides</h5>
                                  {
                                    dateOverrides ?
                                      Object.keys(dateOverrides).length > 0 ?
                                        Object.keys(dateOverrides).map((data, key) => {
                                          return (
                                            <div key={key} className={`card mb-2 datesOverrides_${dateOverrides[data].uid}`}>
                                              <div className='card-header salesforce-style d-flex align-items-center justify-content-between'>
                                                <span>{dateOverrides[data].date}</span>
                                                {/* <span><i className='fa fa-trash' id={dateOverrides[data].uid} onClick={(e) => { deleteOverrideDate(dateOverrides[data].date, `datesOverrides_${dateOverrides[data].uid}`); }}></i></span> */}
                                              </div>
                                              <div className='card-body d-flex justify-content-between align-items-center'>
                                                {
                                                  dateOverrides[data].active ?
                                                    <>
                                                      <div className='d-flex flex-column align-items-start justify-content-center'>
                                                        {
                                                          dateOverrides[data].timeSet.map((time, key) => {
                                                            return (
                                                              <React.Fragment key={key}>
                                                                <button disabled className='btn btn-secondary border-0 mb-1 w-100'>
                                                                  {dayjs(`2 November 1999 ${time.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}-{dayjs(`2 November 1999 ${time.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format(timeFormat.value !== "12 Hours" ? "H:mm" : "h:mma")}
                                                                </button>
                                                              </React.Fragment>
                                                            )
                                                          })
                                                        }
                                                      </div>
                                                    </>
                                                    :
                                                    <>
                                                      <span className='text-muted'>unavailable</span>
                                                    </>
                                                }
                                              </div>
                                            </div>
                                          )
                                        })
                                        :
                                        <p className='text-center'>No overrides for now</p>
                                      :
                                      <p className='text-center'>No overrides for now</p>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`row mb-3 ${customScheduleView ? "" : "d-none"}`} >
                      <div className="col-12">

                        {/* ######################## NEW SCHEDULE SYSTEM Round Robin ######################### */}

                        <div className="">
                          <div className='mb-3'>
                            <p className='smallTextNote p-3 m-0'>Choose a schedule below to edit or create a new one that you can apply to your event types</p>
                            <div className="conatiner-fluid border p-3 rounded insightBackground">
                              <div className="row mb-3">
                                <div className="col">
                                  <span style={{ fontWeight: "600" }}>SCHEDULE TEMPLATES</span>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <div className='d-flex flex-wrap align-items-center gap-2'>
                                    {
                                      userSchedules &&
                                      Object.keys(userSchedules).map((name, key) => {
                                        if (scheduleName === name) {
                                          return <button type='button' key={key} className='btn salesforce-style rounded-pill border shadow-sm' onClick={() => { toogleScheduleSet(name); setScheduleName(name); }}>{name}</button>
                                        } else {
                                          return <button type='button' key={key} className='btn btn-outline-dark rounded-pill border shadow-sm' onClick={() => { toogleScheduleSet(name); setScheduleName(name); }}>{name}</button>
                                        }
                                      })
                                    }
                                    {/* {
                                              createNewSchedule &&
                                              <input type="text" className="customInputFieldFocus w-100 rounded-pill" style={{ maxWidth: "250px" }} onChange={(e) => { setScheduleName(e.target.value); }} required placeholder="Name of the Schedule..." />
                                          } */}
                                    <button type='button' className='btn btn-outline-dark rounded-pill shadow-sm border' onClick={() => { setCreateNewSchedule(true); }}>+ New Schedule</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card insightBackground">
                            <div className="card-header insightBackground" style={{ position: "relative", zIndex: "2" }}>
                              <div className="container-fluid">
                                <div className="row mb-3">
                                  <div className="col p-0">
                                    <div className='w-100 d-flex justify-content-between align-items-center'>
                                      <span className='' style={{ fontWeight: "600" }}>{scheduleName ? scheduleName : "Working Hours"}</span>
                                      <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 100, hide: 200 }}
                                        overlay={
                                          <Popover id="popover-basic">
                                            <Popover.Header className='salesforce-style' as="h3">Delete Schedule</Popover.Header>
                                            <Popover.Body>
                                              <p className='p-0 m-0 helpTextSize'>By clicking this you can delete this schedule template.</p>
                                            </Popover.Body>
                                          </Popover>
                                        }>
                                        <i
                                          className='fa fa-trash cursor-pointer'
                                          onClick={() => {
                                            setCustomAlertBox(true);
                                            setAlertBoxMessages(<p>Do you want to delete this schedule <br />"{scheduleName}"?</p>);
                                            setAlertErrorType("queryDelSchedule");

                                          }}
                                        ></i>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-12 col-lg-6 p-0">
                                    <div className='d-flex flex-column align-items-start gap-3 h-100 w-100'>

                                      <div className='d-flex align-items-start flex-column justify-content-between gap-1 h-100 w-100' style={{ position: "relative", zIndex: "4" }}>
                                        <label htmlFor='timezoneGds'>Country *</label>
                                        <div className='w-100'>
                                          <Select options={countriesList} value={countrySchedule} onChange={changeHandlerSchedule} />
                                        </div>
                                      </div>

                                      <div className='d-flex align-items-start flex-column justify-content-between gap-1 h-100 w-100' style={{ position: "relative", zIndex: "3" }}>
                                        <label htmlFor='timezoneGds'>Time Zone</label>
                                        <div className='w-100'>
                                          <Select value={[selectedTimezone]} options={filteredTimezonesListSchedule} onChange={(e) => { setSelectedTimezone(e); }} />
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-6">

                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card-body">
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-12 col-md-12 col-lg-7 border rounded shadow-sm insightBackground">
                                    <p className='text-center pt-4' style={{ fontWeight: "600" }}>Set your weekly hours</p>
                                    {
                                      <AvailabilityTemplates
                                        setIsSundayActive={setIsSundayActive}
                                        setIsMondayActive={setIsMondayActive}
                                        setIsTuesdayActive={setIsTuesdayActive}
                                        setIsWednesdayActive={setIsWednesdayActive}
                                        setIsThursdayActive={setIsThursdayActive}
                                        setIsFridayActive={setIsFridayActive}
                                        setIsSaturdayActive={setIsSaturdayActive}
                                        isSundayActive={isSundayActive}
                                        isMondayActive={isMondayActive}
                                        isTuesdayActive={isTuesdayActive}
                                        isWednesdayActive={isWednesdayActive}
                                        isThursdayActive={isThursdayActive}
                                        isFridayActive={isFridayActive}
                                        isSaturdayActive={isSaturdayActive}
                                        checkDayOnToggle={checkDayOnToggle}
                                        schedule={schedule}
                                        timeListToMeasure={timeListToMeasure}
                                        filterTimeFieldNow={filterTimeFieldNow}
                                        checkUpdatedStateOfTimeValidation={checkUpdatedStateOfTimeValidation}
                                        checkValidTiming={checkValidTiming}
                                        timeFormat={timeFormat}
                                        addTimeSetIn={addTimeSetIn}
                                        dayjs={dayjs}
                                        deleteTimeSet={deleteTimeSet}
                                        uuidv4={uuidv4}
                                        ContentCopyIcon={ContentCopyIcon}
                                        copyTimesTo={copyTimesTo}
                                        categoryOfFrame={"RoundRobin"}
                                      />
                                    }

                                  </div>
                                  <div className="col-12 col-lg-1 d-lg-flex py-2"></div>
                                  <div className="col-12 col-md-12 col-lg-4 border rounded shadow-sm insightBackground">
                                    <div className="alert d-flex flex-column align-items-center">
                                      <p className='text-center' style={{ fontWeight: "600" }}>Date overrides</p>
                                      <button type='button' className='btn btn-sm btn-outline-primary rounded-pill salesforce-style px-3' style={{ maxWidth: "max-content" }} onClick={() => { setViewDateOverridePanel(true); }}>
                                        <AddIcon /> Add date override
                                      </button>
                                    </div>
                                    {
                                      dateOverrides ?
                                        Object.keys(dateOverrides).length > 0 ?
                                          Object.keys(dateOverrides).map((data, key) => {
                                            return (
                                              <div key={key} className={`card mb-2 datesOverrides_${dateOverrides[data].uid}`}>
                                                <div className='card-header salesforce-style d-flex align-items-center justify-content-between'>
                                                  <span>{dayjs(dateOverrides[data].date).format("ddd, DD MMM YYYY")}</span>
                                                  <span>
                                                    <i className="fa fa-trash" style={{ padding: "0 5px" }} id={dateOverrides[data].uid} onClick={(e) => { deleteOverrideDate(dateOverrides[data].date, `datesOverrides_${dateOverrides[data].uid}`); }} />
                                                  </span>
                                                </div>
                                                <div className='card-body d-flex justify-content-between align-items-center'>
                                                  {
                                                    dateOverrides[data].active ?
                                                      <>
                                                        <div className='d-flex flex-column align-items-start justify-content-center'>
                                                          {
                                                            dateOverrides[data].timeSet.map((time, key) => {
                                                              const set = time.split("-");
                                                              return (
                                                                <React.Fragment key={key}>
                                                                  <button disabled className='btn btn-secondary border-0 mb-1 w-100'>
                                                                    {
                                                                      timeFormat.value === "12 Hours" ?
                                                                        `${dayjs(`2 November 1999 ${set[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")}-${dayjs(`2 November 1999 ${set[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")}`
                                                                        :
                                                                        `${dayjs(`2 November 1999 ${set[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm")}-${dayjs(`2 November 1999 ${set[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm")}`
                                                                    }
                                                                  </button>
                                                                </React.Fragment>
                                                              )
                                                            })
                                                          }
                                                        </div>
                                                      </>
                                                      :
                                                      <>
                                                        <span>Unavailable</span>
                                                      </>
                                                  }
                                                </div>
                                              </div>
                                            )
                                          })
                                          :
                                          <p className='text-center'>No overrides for now</p>
                                        :
                                        <p className='text-center'>No overrides for now</p>
                                    }
                                  </div>

                                  <div className="col-12 col-lg-1"></div>
                                </div>
                              </div>
                            </div>
                            <div className="card-footer d-flex align-items-center justify-content-center gap-3">
                              {/* <button style={{ maxWidth: "120px" }} className='w-100 text-center btn btn-secondary rounded-pill py-1' type='button' onClick={() => { setCreateNewSchedule(false); setSchedule(defaultSchedule); setDateOverrides(defaultOverride); window.location.reload(); }}>Cancel</button> */}
                              <button style={{ maxWidth: "max-content" }} className='w-100 text-center btn salesforce-style rounded-pill py-1' type='button' disabled={!updateButtonEnabled || !isCustomLinkAvailable} onClick={(e) => { updateNewSchedule(e, "RoundRobin"); }}>Save Schedule</button>
                            </div>
                          </div>
                        </div>

                        {/* ##################### END OF NEW SCHEDULE SYSTEM Round Robin ###################### */}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6 mb-4">
                        <div className="container-fluid border insightBackground rounded" style={{ position: "relative", zIndex: "2" }}>
                          <div className="row">
                            <div className="col-12">
                              <span style={{ fontWeight: "500" }} className='my-3 d-block'>Want to add time before or after your events?</span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-12 d-flex align-items-center gap-4 py-2">
                              {
                                eventBeforeTimeActive && (
                                  <input type="checkbox" defaultChecked onClick={() => { setEventBeforeTimeActive(false); }} className='form-check-input checkboxInpage' />
                                )
                              }
                              {
                                eventBeforeTimeActive === false && (
                                  <input type="checkbox" onClick={() => { setEventBeforeTimeActive(true); }} className='form-check-input checkboxInpage' />
                                )
                              }

                              <div className=''>
                                <h6 className={`${eventBeforeTimeActive ? "" : "text-muted"}`}>
                                  Before Event
                                </h6>

                                {/* <DropdownButton
                                  as="buttonGroup"
                                  className='w-100'
                                  id={`dropdown-button-drop-down`}
                                  drop={"down"}
                                  variant="light"
                                  disabled={eventBeforeTimeActive ? false : true}
                                  title={
                                    eventBeforeTime > 60 ?
                                      Math.floor(eventBeforeTime % 60) === 0 ?
                                        Math.floor(eventBeforeTime / 60) + " hrs"
                                        : Math.floor(eventBeforeTime / 60) + " hrs, " + Math.floor(eventBeforeTime % 60) + " min"
                                      : Math.floor(eventBeforeTime) + " min"
                                  }
                                  onSelect={(e, event) => { setEventBeforeTime(e); }}

                                >
                                  <Dropdown.Item eventKey="15">15 minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="30">30 minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="45">45 minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="60">1 hr</Dropdown.Item>
                                  <Dropdown.Item eventKey="90">1 hr 30 min</Dropdown.Item>
                                  <Dropdown.Item eventKey="120">2 hr</Dropdown.Item>
                                  <Dropdown.Item eventKey="150">2 hr 30 min</Dropdown.Item>
                                  <Dropdown.Item eventKey="180">3 hr</Dropdown.Item>
                                </DropdownButton> */}

                                <Select
                                  isDisabled={eventBeforeTimeActive ? false : true}
                                  options={addTimeAfterBeforeForSelect}
                                  defaultValue={[{ label: "30 minutes", value: 30 }]}
                                  onChange={(e) => { setEventBeforeTime(e.value) }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mb-2">
                            <div className="col-12 d-flex align-items-center gap-4 py-2">
                              <div>
                                {
                                  eventAfterTimeActive && (
                                    <input type="checkbox" defaultChecked onClick={() => { setEventAfterTimeActive(false); }} className='form-check-input checkboxInpage' />
                                  )
                                }
                                {
                                  eventAfterTimeActive === false && (
                                    <input type="checkbox" onClick={() => { setEventAfterTimeActive(true); }} className='form-check-input checkboxInpage' />
                                  )
                                }
                              </div>
                              <div>
                                <h6 className={`${eventAfterTimeActive ? "" : "text-muted"}`}>
                                  After Event
                                </h6>
                                {/* <DropdownButton
                                  as="buttonGroup"
                                  className='w-100'
                                  id={`dropdown-button-drop-down`}
                                  drop={"down"}
                                  variant="light"
                                  disabled={eventAfterTimeActive ? false : true}
                                  title={
                                    eventAfterTime > 60 ?
                                      Math.floor(eventAfterTime % 60) === 0 ?
                                        Math.floor(eventAfterTime / 60) + " hrs"
                                        : Math.floor(eventAfterTime / 60) + " hrs, " + Math.floor(eventAfterTime % 60) + " min"
                                      : Math.floor(eventAfterTime) + " min"
                                  }
                                  onSelect={(e, event) => { setEventAfterTime(e); }}

                                >
                                  <Dropdown.Item eventKey="15">15 minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="30">30 minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="45">45 minutes</Dropdown.Item>
                                  <Dropdown.Item eventKey="60">1 hr</Dropdown.Item>
                                  <Dropdown.Item eventKey="90">1 hr 30 min</Dropdown.Item>
                                  <Dropdown.Item eventKey="120">2 hr</Dropdown.Item>
                                  <Dropdown.Item eventKey="150">2 hr 30 min</Dropdown.Item>
                                  <Dropdown.Item eventKey="180">3 hr</Dropdown.Item>
                                </DropdownButton> */}

                                <Select
                                  isDisabled={eventAfterTimeActive ? false : true}
                                  options={addTimeAfterBeforeForSelect}
                                  defaultValue={[{ label: "30 minutes", value: 30 }]}
                                  onChange={(e) => { setEventAfterTime(e.value) }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-5 col-lg-5 d-flex flex-column">
                        <p className='mediumTextSize'>Give yourself some buffer time to prepare for or wrap up from booked Event.</p>
                        <div className=" rounded h-100">
                          <div className="card insightBackground rounded overflow-hidden">
                            <div className={`card-header p-1 ${eventBeforeTimeActive ? "salesforce-style" : ""}`}></div>
                            <div className="card-body p-0">
                              <button type='button' className='btn insightBackground w-100 text-break'>{roundRobinSummary}</button>
                            </div>
                            <div className={`card-footer insightBackground p-1 ${eventAfterTimeActive ? "salesforce-style" : ""}`}></div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6 d-flex align-items-center gap-2 mb-2">
                        <div className='border insightBackground rounded p-3 w-100'>
                          <div className='mb-3'>
                            <span style={{ fontWeight: "500" }}>Secret Event</span>
                            <p className='m-0 p-0 mediumTextSize'>Use this to hide the event on your main scheduling page.</p>
                          </div>
                          <div className='mb-2 d-flex align-items-center justify-content-start gap-2'>
                            {
                              secretEvent && (
                                <input type="checkbox" defaultChecked onClick={() => { setSecretEvent(false); }} className='form-check-input checkboxInpage' />
                              )
                            }
                            {
                              secretEvent === false && (
                                <input type="checkbox" onClick={() => { setSecretEvent(true); }} className='form-check-input checkboxInpage' />
                              )
                            }
                            <span className={`${secretEvent ? "" : "text-muted"} mediumTextSize`} style={{ fontWeight: "500" }}>
                              Make this a Secret Event
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='col-12 col-md-6 col-lg-6'>
                        <div className='mediumTextSize w-100'>
                          <span style={{ fontWeight: "500" }}> Note:</span> Only Invitees who have this {process.env.REACT_APP_DOMAINNAME.split(".")[0]} link can schedule with you.
                        </div>
                      </div>
                    </div>

                    {/* #################################### Additional Settings Started ############################################### */}

                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6">
                        <span style={{ fontWeight: "500", fontSize: "1.2rem" }}>Additional Settings</span>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-lg-6">
                        <div className='insightBackground border p-3 rounded' style={{ position: "relative", zIndex: "1" }}>
                          <span className='d-block mb-2' style={{ fontWeight: "500" }}>Stop Bookings before:</span>
                          {/* <select id='endDateTime' className=' mt-2 shadow-sm' defaultValue={stopBookingTime[1]} required onChange={(e) => { }}>
                            <option value="1">1 Hour</option>
                            <option value="2">2 Hours</option>
                            <option value="3">3 Hours</option>
                            <option value="4">4 Hours</option>
                            <option value="custom">Custom</option>
                          </select> */}


                          {/* <DropdownButton
                            as="buttonGroup"
                            className='w-100'
                            drop={"down"}
                            variant="light"
                            title={
                              parseInt(stopBookingTime[1]) === 1 ? stopBookingTime[1] + " Hour" : stopBookingTime[1] + " Hours"
                            }
                            onSelect={(e, event) => { if (e !== "custom") { setStopBookingTime([stopTimeForBookingType, e]); setCustomStopBookingView(false); } else { setCustomStopBookingView(true); setStopBookingTime([stopTimeForBookingType, 1]); } }}

                          >
                            <Dropdown.Item eventKey="1">1 Hour</Dropdown.Item>
                            <Dropdown.Item eventKey="2">2 Hours</Dropdown.Item>
                            <Dropdown.Item eventKey="3">3 Hours</Dropdown.Item>
                            <Dropdown.Item eventKey="4">4 Hours</Dropdown.Item>
                            <Dropdown.Item eventKey="custom">custom</Dropdown.Item>
                          </DropdownButton> */}

                          <Select
                            options={stopBookingBeforeListForSelect}
                            defaultValue={[{ label: "1 Hour", value: 1 }]}
                            onChange={(e) => {
                              if (e.label !== "custom") {
                                setStopBookingTime([stopTimeForBookingType, e.value]);
                                setCustomStopBookingView(false);
                              }
                              else {
                                setCustomStopBookingView(true);
                                setStopBookingTime([stopTimeForBookingType, 1]);
                              }
                            }}
                          />
                          {
                            customStopBookingView ?
                              <div className='d-flex justify-content-center align-items-center gap-2 my-2'>
                                {
                                  stopTimeForBookingType === "hours" ?
                                    <input className='customInputFieldFocus css-13cymwt-control w-100' required type="number" min="1" max="6" defaultValue={"1"} onChange={(e) => { setStopBookingTime(["hours", e.target.value]); }} />
                                    :
                                    <input className='customInputFieldFocus css-13cymwt-control w-100' required type="number" min="1" max="60" defaultValue={"1"} onChange={(e) => { setStopBookingTime(["days", e.target.value]); }} />
                                }
                                {/* <DropdownButton
                                  as="buttonGroup"
                                  className='w-100'
                                  id={`dropdown-button-drop-down`}
                                  drop={"down"}
                                  variant="light"
                                  title="Hours"
                                  onSelect={(e, event) => { setStopTimeForBookingType(e); }}
                                >
                                  <Dropdown.Item eventKey="hours">hours</Dropdown.Item>
                                </DropdownButton> */}

                                <Select
                                  className='w-100'
                                  options={[{ label: "hours", value: "hours" }]}
                                  defaultValue={[{ label: "hours", value: "hours" }]}
                                  onChange={(e) => { setStopTimeForBookingType(e.label); }}
                                />

                                {/* <select className='' onChange={(e) => { setStopTimeForBookingType(e.target.value); }} value={stopTimeForBookingType === "hours" ? "hours" : "days"}>
                                  <option value="hours">hours</option>
                                  <option value="days">days</option>
                                </select> */}

                              </div>
                              :
                              ""
                          }
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6">
                        <div className='mediumTextSize w-100'>
                          <span style={{ fontWeight: "500" }}>Note:</span> This feature can control the last minute booking problems. Set your desired time to stop bookings after that particular time.
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-9 col-sm-9 col-md-9 col-lg-9">
                        <span style={{ fontWeight: "500", fontSize: "1.2rem" }}>Questions for Invitees:</span>
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                        <span className="mediumTextSize" style={{ fontWeight: "500" }}>Required</span>
                        <OverlayTrigger
                          placement="left"
                          delay={{ show: 100, hide: 200 }}
                          overlay={
                            <Popover id="popover-basic">
                              <Popover.Header className='salesforce-style' as="h3">Required Questions</Popover.Header>
                              <Popover.Body>
                                <p className='p-0 m-0 mb-2 helpTextSize'>You can make a questions mandatory while your invitees book a meeting whith you by using this switch.</p>
                                <img src={requiredQuestionPng} className='w-100' alt="" />
                              </Popover.Body>
                            </Popover>
                          }>
                          <HelpOutlineIcon style={{ maxWidth: "15px", cursor: "help" }} />
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-9 col-md-9 col-lg-9">
                        <input type="text" className='customInputFieldFocus w-100' onChange={(e) => { setRoundRobinQuestions(e.target.value); }} defaultValue={"Share anything that can help us prepare the meeting."} placeholder='enter your question here?' />
                      </div>
                      <div className="col-3 col-md-3 col-lg-3 d-flex align-items-center">
                        <div className="outerToggleSwitch" onClick={() => { setIsDescriptionRequired(!isDescriptionRequired); }}>
                          <div className={`frameOfToggleSwitch ${isDescriptionRequired === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                            <span>On</span>
                            <div className="toggleSwitchBall"></div>
                            <span>Off</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* #################################### Additional Settings End ############################################### */}

                    <div className="row mb-3">
                      <div className="col d-flex align-items-center justify-content-center">
                        <button className='btn salesforce-style rounded-pill py-2 px-5 d-flex align-items-center justify-content-center gap-2' id="roundRobinCreationButton" disabled={!updateButtonEnabled}>Finish</button>
                      </div>
                    </div>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <div className={`${viewAdminPage ? "adminPanelView" : "adminPanelClosed"} light-bg-1 `} id="megaMenue">
        <div className="container-fluid">
          <div className="row d-none d-md-flex d-lg-flex">
            <div className="col py-1">
              <button className='btn btn-outline-secondary rounded-pill backButton' onClick={() => { setViewAdminPage(false); setViewOf(null); setAccountInnerView(null); setToHistory(null, null); }}>
                <ArrowBackIcon />
              </button>
            </div>
            <div className="col text-center d-flex align-items-center justify-content-center">
              <span className='adminPanelName'>Admin Panel</span>
            </div>
            <div className="col d-flex justify-content-end py-1 align-items-center">
              <div className='adminPageClockPc d-none d-lg-block d-md-block mx-1'>
                {clockTIme}
                <br />
                {clockDate}
              </div>

              <div className='adminPageClockMobile d-lg-none d-md-none mx-1'>
                {clockTIme}
                <br />
                {clockDate}
              </div>

              <div className="">
                <img className='profilePictureForHome rounded-pill' src={currentGlobalProfile} alt="profile picture" />
              </div>
            </div>
          </div>


          <div className="row d-flex d-md-none d-lg-none">
            <div className="col-4 py-1">
              <button className='btn btn-outline-secondary rounded-pill backButton' onClick={() => { setViewAdminPage(false); setViewOf(null); setAccountInnerView(null); setToHistory(null, null); }}>
                <ArrowBackIcon />
              </button>
            </div>
            <div className="col-8 text-center d-flex align-items-center justify-content-end">
              <span className='adminPanelName'>Admin Panel</span>
            </div>
            <div className="col-12 gap-2 d-flex justify-content-start py-3 align-items-center">
              <div className="">
                <img className='profilePictureForHome rounded-pill' src={currentGlobalProfile} alt="profile picture" />
              </div>

              <div className='adminPageClockMobile d-lg-none d-md-none mx-1 text-start'>
                {clockTIme}
                <br />
                {clockDate}
              </div>


            </div>
          </div>


          <div className="row">
            <div className="col">
              <Tabs
                id="uncontrolled-tab-example"
                className="glitchedNavPils"
                activeKey={viewOf ? viewOf : "AccountSettings"}
              >
                <Tab eventKey="AccountSettings" title={<div className='h-100 glitchedPanTabsLinks color-salesforce' style={{ fontWeight: "500" }} onClick={() => { setViewOf("AccountSettings"); setToHistory("AccountSettings", null); }}>Account</div>}>
                  <div className="sticky-top bg-body">
                    <h5 className='p-3 m-0'>Account Settings</h5>
                  </div>
                  <div className="">
                    <Tab.Container id="left-tabs-example" activeKey={accountInnerView ? accountInnerView : "Profile"}>
                      <Row>
                        <Col sm={3} md={2} lg={2} className="niceRightBorderLg niceRightBorderMd niceRightBorderSm insightBackground pt-2">
                          <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                              <Nav.Link eventKey="Profile" className='salesforce-buttons color-salesforce' style={{ fontWeight: "500" }} onClick={() => { setAccountInnerView("Profile"); setToHistory("AccountSettings", "Profile"); }}>Profile</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="MyLink" className='salesforce-buttons color-salesforce' style={{ fontWeight: "500" }} onClick={() => { setAccountInnerView("MyLink"); setToHistory("AccountSettings", "MyLink"); }}>My Link</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="Branding" className='salesforce-buttons color-salesforce' style={{ fontWeight: "500" }} onClick={() => { setAccountInnerView("Branding"); setToHistory("AccountSettings", "Branding"); }}>Branding</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="PhoneNumbers" className='salesforce-buttons color-salesforce' style={{ fontWeight: "500" }} onClick={() => { setAccountInnerView("PhoneNumbers"); setToHistory("AccountSettings", "PhoneNumbers"); }}>Phone Number(s)</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                        <Col sm={9} md={10} lg={10} className="">
                          <Tab.Content>
                            <Tab.Pane eventKey="Profile">
                              <form onSubmit={updateProfile}>
                                <div className="container-fluid">
                                  <div className="row mt-3">
                                    <div className="col border insightBackground rounded py-3">
                                      <div className='d-flex gap-3 justify-content-start align-items-center'>
                                        <div className='rounded-circle bg-dark'>
                                          <img src={currentGlobalProfile} style={{ height: "5rem", width: "5rem" }} className="rounded-circle profilePicture" alt="profile pictures" />
                                        </div>
                                        <div className='d-flex flex-column align-items-start justify-content-center gap-1'>
                                          <button type="button" className='btn btn-sm btn-outline-primary rounded-pill px-5 salesforce-style' onClick={() => { setViewImageUploader(true); }}>Update Picture</button>
                                          <p className='m-0 mediumTextSize'>JPEG, PNG , HEIF or BMP, max size of 2MB.</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row mt-3">
                                    <div className="col border rounded insightBackground py-2">
                                      <label className='w-100' htmlFor='name'>
                                        <span style={{ fontWeight: "500" }} className="d-flex aling-items-center gap-3">
                                          Name *

                                          <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 100, hide: 200 }}
                                            overlay={
                                              <Popover id="popover-basic">
                                                <Popover.Header className='salesforce-style' as="h3">Your Name On Booking Pages</Popover.Header>
                                                <Popover.Body>
                                                  <p className='p-0 m-0 helpTextSize'>
                                                    Use this field to change the name present on your booking page.
                                                  </p>
                                                  <img src={nameHelpText} className='img img-responsive w-100' alt='helpText' />
                                                </Popover.Body>
                                              </Popover>
                                            }>
                                            <HelpOutlineIcon style={{ maxWidth: "15px", cursor: "help" }} />
                                          </OverlayTrigger>
                                        </span>
                                        <input required id="name" maxLength={30} type="text" value={name} onChange={validateAndSetName} className='customInputFieldFocus w-100 border' />
                                        <div className='d-flex align-items-center justify-content-between w-100'>
                                          <p className='text-dark text-start m-0 smallTextSize'>Max length 30 chars.</p>
                                          {
                                            name ?
                                              <span className='smallTextSize'>{30 - name.length} / 30</span>
                                              :
                                              <span className='smallTextSize'>30 / 30</span>
                                          }
                                        </div>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row mt-3">
                                    <div className="col border rounded insightBackground py-2">
                                      <label className='w-100' htmlFor='welcomeMessage'>
                                        <span style={{ fontWeight: "500" }} className="d-flex aling-items-center gap-1">
                                          Welcome Message *
                                          <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 100, hide: 200 }}
                                            overlay={
                                              <Popover id="popover-basic">
                                                <Popover.Header className='salesforce-style' as="h3">Welcome Message On Booking Pages</Popover.Header>
                                                <Popover.Body>
                                                  <p className='p-0 m-0 helpTextSize'>
                                                    Use this field to change the welcome message present on your booking page.
                                                  </p>
                                                  <img src={welcomeMessageHelpText} className='img img-responsive w-100' alt='helpText' />
                                                </Popover.Body>
                                              </Popover>
                                            }>
                                            <HelpOutlineIcon style={{ maxWidth: "15px", cursor: "help" }} />
                                          </OverlayTrigger>
                                        </span>
                                        <textarea required id="welcomeMessage" value={welcome} onChange={(e) => { setWelcome(e.target.value); }} type="text" className='customInputFieldFocus w-100 border px-2' />
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row mt-3">
                                    <div className="col border rounded insightBackground py-2" style={{ position: "relative", zIndex: "5" }}>
                                      <label className='w-100' htmlFor='timeFormat'>
                                        <span style={{ fontWeight: "500" }}>Time Format *</span>
                                        {/* <select id="timeFormat" type="text" defaultValue={timeFormat} className='w-100 customInputFieldFocus w-100 border' onChange={(e) => { setTimeFormat(e.target.value); }}>
                                          <option value="12">12 Hours AM/PM</option>
                                          <option value="24">24 Hours</option>
                                        </select> */}
                                        <Select
                                          options={[{ label: "12 Hours AM/PM", value: "12 Hours" }, { label: "24 Hours", value: "24 Hours" }]}
                                          value={timeFormat}
                                          onChange={setTimeFormat}
                                        />

                                      </label>
                                    </div>
                                  </div>


                                  {/* <div className="row mt-3">
                                    <div className="col border rounded insightBackground py-2" style={{ position: "relative", zIndex: "4" }}>
                                      <label className='w-100'>
                                        <span style={{ fontWeight: "500" }}>Country *</span>
                                        <Select required className='w-100' options={options} value={country} onChange={changeHandler} />
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row mt-3">
                                    <div className="col border rounded insightBackground py-2" style={{ position: "relative", zIndex: "3" }}>
                                      <label className='w-100'>
                                        <span style={{ fontWeight: "500" }}>Time Zone *</span>
                                        <TimezoneSelect
                                          required
                                          className='w-100 p-0'
                                          value={selectedTimezoneSettings}
                                          onChange={setSelectedTimezoneSettings}
                                        // timezones={{
                                        //   ...allTimezones,
                                        //   'America/Lima': 'Pittsburgh',
                                        //   'Europe/Berlin': 'Frankfurt',
                                        // }}
                                        />
                                      </label>
                                    </div>
                                  </div> */}


                                  {/* incomplete work here */}
                                  <div className="row mt-3">
                                    <div className="col border rounded insightBackground py-2" style={{ position: "relative", zIndex: "4" }}>
                                      <label className='w-100'>
                                        <span style={{ fontWeight: "500" }}>Country *</span>
                                        <Select options={countriesList} value={country} onChange={changeHandler} />
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row mt-3">
                                    <div className="col border rounded insightBackground py-2" style={{ position: "relative", zIndex: "3" }}>
                                      <label className='w-100'>
                                        <span style={{ fontWeight: "500" }}>Time Zone *</span>
                                        <Select value={[selectedTimezoneSettings]} options={filteredTimezonesList} onChange={(e) => { setSelectedTimezoneSettings(e); }} />
                                      </label>
                                    </div>
                                  </div>



                                  <div className="row my-3">
                                    <div className="col d-flex align-items-center justify-content-between">
                                      <div className="d-flex align-items-center gap-3 justify-content-start">
                                        <button type="submit" id="updateProfileInfoButton" className='btn btn-sm salesforce-style rounded-pill salesforce-style d-flex align-items-center gap-2'>Save Changes</button>
                                      </div>
                                      <div className='d-flex align-items-center justify-content-center'>
                                        <button type='button' className='btn btn-sm btn-danger rounded-pill' onClick={(e) => {
                                          setCustomAlertBox(true);
                                          setAlertBoxMessages(`You are about to delete your ${process.env.REACT_APP_DOMAINNAME.split(".")[0]} account. This will delete all of your meeting history and records permanently. Are you sure you want to proceed ?`);
                                          setAlertErrorType("queryDelAccount");
                                          setDeletionContents(<>
                                            <div className='w-100'>
                                              <p className='alert alert-danger mediumTextSize w-100 text-break text-center'>You are about to delete your {process.env.REACT_APP_DOMAINNAME.split(".")[0]} account.<br />This will delete all of your meeting history and records permanently. <br />Are you sure you want to proceed ?</p>
                                              <textarea required className='customInputFieldFocus mediumTextSize w-100 border px-2' rows="5" placeholder='Why do you want to delete your account.' onChange={(e) => { setDeletionPolicy(e.target.value); }}></textarea>
                                            </div>
                                          </>)
                                        }}>Delete Account</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </Tab.Pane>
                            <Tab.Pane eventKey="MyLink">
                              <form onSubmit={updateLink}>
                                <div className="container-fluid p-0">
                                  <div className="row mt-3">
                                    <div className="col d-flex align-items-center gap-3">
                                      <span className='mediumTextSize'>Changing your {process.env.REACT_APP_DOMAINNAME.split(".")[0]} URL will mean that all of your copied links will no longer work and will need to be updated.</span>
                                      {/* <p> */}
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 100, hide: 200 }}
                                        overlay={
                                          <Popover id="popover-basic">
                                            <Popover.Header className='salesforce-style' as="h3">Customize Your Booking Link</Popover.Header>
                                            <Popover.Body>
                                              <p className='p-0 m-0 helpTextSize'>
                                                Use this field to change your custom links to share with your invitees.
                                              </p>
                                              <img src={myLinkHelpText} className='img img-responsive w-100' alt='helpText' />
                                            </Popover.Body>
                                          </Popover>
                                        }>
                                        <HelpOutlineIcon style={{ maxWidth: "15px", cursor: "help" }} />
                                      </OverlayTrigger>
                                      {/* </p> */}
                                    </div>
                                  </div>
                                  <div className="row mt-3">
                                    <div className="col-12">
                                      <div className="w-100 d-flex align-items-start justify-content-start flex-column gap-2 border rounded insightBackground p-3">
                                        <div className="w-100">
                                          <div className="input-group w-100 flex-nowrap">
                                            <span className="input-group-text" id="basic-addon3" style={{ marginLeft: "0" }}>{process.env.REACT_APP_DOMAINNAME}/</span>
                                            <input type="text" id="UID" maxLength={20} required className='customInputFieldFocus w-100' placeholder='UID' defaultValue={username} onChange={isUsernameAvailable} />
                                            <span className="input-group-text" style={{ marginLeft: "0", borderLeft: "none" }}>
                                              {
                                                usernameAvailable && username.length > 0 ?
                                                  <DoneIcon color="success" />
                                                  :
                                                  <CloseIcon color="error" />
                                              }
                                            </span>
                                          </div>
                                          <div className='d-flex align-items-center justify-content-between w-100'>
                                            <p className='text-dark m-0 text-start smallTextSize'>Max length 20 chars.</p>
                                            <span className='smallTextSize'>{20 - username.length} / 20</span>
                                          </div>
                                        </div>
                                        {
                                          usernameFaults ?
                                            <div className="alert alert-danger mediumTextSize m-0 w-100 p-1 text-center">
                                              {usernameFaults}
                                            </div>
                                            :
                                            username.length < 1 ?
                                              <div className="alert alert-danger mediumTextSize m-0 w-100 p-1 text-center">
                                                Please provide a username.
                                              </div>
                                              :
                                              ""
                                        }
                                      </div>
                                    </div>
                                    <div className='col d-flex align-items-center justify-content-start gap-2'>

                                    </div>
                                  </div>
                                  <div className="row my-3">
                                    <div className="col d-flex align-items-center gap-3">
                                      <button type="submit" disabled={!usernameAvailable || username.length < 1 ? true : false} id="updateLinkFromAdmin" className='btn btn-sm salesforce-style rounded-pill salesforce-style d-flex align-items-center gap-2'>Save Changes</button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Branding">
                              <form className='mt-3' onSubmit={(e) => { e.preventDefault() }}>
                                <label className='d-flex flex-wrap align-items-center justify-content-start gap-3 my-3'>
                                  <span>Schedule AI Branding</span>
                                  <span>
                                    {
                                      calendaryBranding ?
                                        (
                                          <div className="outerToggleSwitch" onClick={() => { swtichBrandingToggle(false); }}>
                                            <div className={`frameOfToggleSwitch ${calendaryBranding === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                              <span>On</span>
                                              <div className="toggleSwitchBall"></div>
                                              <span>Off</span>
                                            </div>
                                          </div>
                                        )
                                        :
                                        (
                                          <div className="outerToggleSwitch" onClick={() => { swtichBrandingToggle(true); }}>
                                            <div className={`frameOfToggleSwitch ${calendaryBranding === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                              <span>On</span>
                                              <div className="toggleSwitchBall"></div>
                                              <span>Off</span>
                                            </div>
                                          </div>
                                        )
                                    }
                                  </span>
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 100, hide: 200 }}
                                    overlay={
                                      <Popover id="popover-basic">
                                        <Popover.Header className='salesforce-style' as="h3">Change Your Branding Logo</Popover.Header>
                                        <Popover.Body>
                                          <p className='p-0 m-0 helpTextSize'>
                                            By turning this off you can set your own brand logo to your booking pages.
                                          </p>
                                          <img src={brandingLogo} className='img img-responsive w-100' alt='helpText' />
                                        </Popover.Body>
                                      </Popover>
                                    }>
                                    <HelpOutlineIcon style={{ maxWidth: "15px", cursor: "help" }} />
                                  </OverlayTrigger>
                                </label>
                                <div className={`border rounded insightBackground p-3 position-relative w-100`}>
                                  <div className={`rounded ${calendaryBranding ? "cursor-not-allowed" : "d-none"}`} style={{ position: 'absolute', backdropFilter: "blur(5px)", left: "0px", top: "0px", zIndex: "999", width: "100%", height: "100%", background: "rgba(255,255,255,0.5)" }}></div>
                                  <div className='w-100 bg-soul p-3'>
                                    {/* <div className='logoContainer'>
                                      {
                                        newBrandLogoPreview && (
                                          <div className="shareImg" id="chatShareImg">
                                            <img src={newBrandLogoPreview} style={{ maxWidth: "200px" }} />
                                          </div>
                                        )
                                      }
                                    </div> */}
                                    <div className='alert alert-secondary text-start'>My Logos</div>
                                    <div className='d-flex align-items-start justify-content-start flex-wrap gap-2'>
                                      {
                                        allBrandsLogos ?
                                          allBrandsLogos.length ?
                                            allBrandsLogos.length > 0 ?
                                              allBrandsLogos.map((val, key) => {
                                                return (
                                                  <div key={key} className="bg-body shadow-sm border rounded">
                                                    <div className="p-2 border-bottom">
                                                      <div className='d-flex justify-content-between align-items-center w-100'>

                                                        <OverlayTrigger
                                                          placement="top"
                                                          delay={{ show: 100, hide: 200 }}
                                                          overlay={
                                                            <Popover id="popover-basic">
                                                              <Popover.Header className='salesforce-style' as="h6">Copy Brand Link</Popover.Header>
                                                              <Popover.Body>
                                                                <p className='m-0 p-0 smallTextSize'>Copy this brand link to share it with others.</p>
                                                              </Popover.Body>
                                                            </Popover>
                                                          }>
                                                          <CopyToClipboard text={val.brandName}
                                                            onCopy={() => {
                                                              setViewToast(true); popupAlert.play();
                                                              setToastTitle("Successful");
                                                              setToastBody("Brand link copied..!");
                                                              setToastMessageType("success");

                                                              // setCustomAlertBox(true);
                                                              // setAlertBoxMessages("Link Copied..!");
                                                              // setAlertErrorType("success");
                                                            }}>
                                                            {/* <button className='btn  rounded-pill'> */}
                                                            <img alt="forward" src={forwardImg} className="copyIcon cursor-pointer" />
                                                            {/* </button> */}
                                                          </CopyToClipboard>
                                                        </OverlayTrigger>


                                                        <OverlayTrigger
                                                          placement="top"
                                                          delay={{ show: 100, hide: 200 }}
                                                          overlay={
                                                            <Popover id="popover-basic">
                                                              <Popover.Header className='salesforce-style' as="h6">Delete Brand</Popover.Header>
                                                              <Popover.Body>
                                                                <p className='m-0 p-0 smallTextSize'>By clicking this button the brand will be permanently deleted from your brands list.</p>
                                                              </Popover.Body>
                                                            </Popover>
                                                          }>
                                                          <i className='fa fa-trash text-danger' onClick={() => { deleteBrandLogo(val.uuid) }}></i>
                                                        </OverlayTrigger>
                                                      </div>
                                                    </div>
                                                    <div className="p-2">
                                                      <img src={val.logo} className='img img-thumbnail' style={{ maxWidth: "120px", aspectRatio: "1", objectFit: "contain" }} />
                                                    </div>
                                                    <div className="p-2 border-top">
                                                      <div className='d-flex justify-content-between align-items-center gap-2'>
                                                        <div className='mediumTextSize'>Active</div>
                                                        <div>
                                                          <div className="outerToggleSwitch" onClick={() => { turnBrandActivness(val.uuid); }}>
                                                            <div className={`frameOfToggleSwitch ${val.active === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                              <span>On</span>
                                                              <div className="toggleSwitchBall"></div>
                                                              <span>Off</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              })
                                              :
                                              ""
                                            :
                                            ""
                                          :
                                          ""
                                      }
                                    </div>
                                  </div>
                                  <div className="py-2">
                                    <div className="d-flex flex-wrap align-items-center justify-content-start gap-3">
                                      <button type='button' className='btn btn-sm salesforce-style px-3' onClick={(e) => { setStartCropperForBrand(true); }}>Add Logo</button>
                                      <span className='mediumTextSize'>JPEG, PNG , HEIF or BMP, max size of 2MB.</span>
                                    </div>
                                  </div>

                                </div>

                              </form>
                            </Tab.Pane>
                            <Tab.Pane eventKey="PhoneNumbers">
                              <form onSubmit={updateNumbers}>
                                <div className="container-fluid">
                                  <div className="row">
                                    <div className="col align-items-center gap-3 p-3">
                                      <h6>Your phone number(s) for workflows</h6>
                                      <p className='mediumTextSize'>Phone numbers you add here will be used in any “text to host” workflows  you create. Message and data rates may apply.</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="container-fluid border rounded insightBackground py-3">
                                  <div className="row">
                                    <div className="col px-3">
                                      {
                                        phoneList ?
                                          Object.keys(phoneList).length > 0 ?
                                            Object.keys(phoneList).map((number, key) => {
                                              return (
                                                <React.Fragment key={key}>
                                                  <div className="alert alert-success border shadow-sm py-2 d-flex align-items-center justify-content-between">
                                                    <span className='fw-bold text-dark'>{number}</span>
                                                    <div className='h-100 p-0 m-0 d-flex align-items-center justify-content-end gap-2'>
                                                      <div className="outerToggleSwitch" onClick={() => { phoneActiveSwitcher(number); }}>
                                                        <div className={`frameOfToggleSwitch ${phoneList[number].active === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                          <span>On</span>
                                                          <div className="toggleSwitchBall"></div>
                                                          <span>Off</span>
                                                        </div>
                                                      </div>
                                                      <button type='button' onClick={(e) => { deletePhoneNow(number); }} className='btn p-1 btn-sm d-flex align-items-center justify-content-center rounded-circle' style={{ lineHeight: "0", aspectRatio: "1" }}>
                                                        <i className='fa fa-trash text-dark'></i>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </React.Fragment>
                                              )
                                            })
                                            :
                                            <div className='alert alert-secondary'>There are no existing contacts</div>
                                          :
                                          <div className='alert alert-secondary'>There are no existing contacts</div>
                                      }
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col d-flex align-items-center gap-3 px-3">

                                      <button type='button' className='btn btn-sm btn-outline-dark rounded-pill d-flex align-items-center gap-1 salesforce-style' onClick={(e) => {
                                        setPhoneAddView(true);
                                        setTimerForOTP(60);
                                        setPhoneAddError(null);
                                        setPhoneNumberForOTP("");
                                        setPhoneAddingStage(1);
                                        clearInterval(OTPInterval.current);
                                      }}>
                                        <i className='fa fa-plus' />
                                        <span>Add More</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>
                </Tab>
                <Tab eventKey="People" title={<div className='h-100 glitchedPanTabsLinks color-salesforce' style={{ fontWeight: "500" }} onClick={() => { setViewOf("People"); setToHistory("People", null); }}>People</div>}>
                  <div className="sticky-top bg-body">
                    <h5 className='p-3 m-0'>People Management</h5>
                  </div>

                  <p className='text-center m-0 p-3 mediumTextSize'>
                    Adding People to your account helps the round robin feature to select alternative meeting host for your events if you are not free on a particular time.
                    <br />
                    <span style={{ fontWeight: "500" }}>Note:</span> Members must have the same integrations like yours to create meeting links for them as well.
                  </p>


                  <div className='insightBackground p-3 rounded border'>

                    <button className='btn btn-outline-dark px-4 salesforce-style mb-3' onClick={() => { setAddNewMember(true); }}>+ Add member</button>

                    {
                      people ?
                        Object.keys(people).length > 0 ?
                          Object.keys(people).map((emails, keys) => {
                            return (
                              <div key={keys} className=' d-flex align-items-center justify-content-start flex-wrap gap-3 p-2 mb-2 rounded border shadow-sm position-relative bg-body'>
                                <div className='d-flex align-items-center justify-content-start'>
                                  <img src={people[emails].image} alt={people[emails].name} className="rounded-circle border-0 profilePicture" style={{ maxHeight: "60px" }} />
                                </div>
                                <div className='d-flex flex-column align-items-start justify-content-between w-75'>
                                  <span className='d-inline-block text-truncate mw-100' style={{ fontSize: "1.12rem", fontWeight: "500" }}>{people[emails].name}</span>
                                  <span className='d-inline-block text-truncate mw-100 mediumTextSize'>{emails}</span>
                                </div>
                                <i className='fa fa-trash position-absolute p-1 top-0 end-0 d-flex align-items-center text-danger rounded-circle pe-auto cursor-pointer' onClick={() => { setAskForDeleteMember(true); setDeleteMemberEmail(emails); }} style={{ aspectRatio: "1", width: "2rem", height: "2rem" }} ></i>
                              </div>
                            )
                          })
                          :
                          <span className='d-block mediumTextSize'>There are no members for now!</span>
                        :
                        <span className='d-block mediumTextSize'>There are no members for now!</span>
                    }
                  </div>
                </Tab>
                <Tab eventKey="Billing" title={<div className='h-100 glitchedPanTabsLinks color-salesforce' style={{ fontWeight: "500" }} onClick={() => { setViewOf("Billing"); setToHistory("Billing", null); }}>Billing</div>}>
                  <div className="p-0 sticky-top bg-body">
                    <h5 className='p-3 m-0'>Billing</h5>
                  </div>
                  <div className="bg-body text-center d-flex flex-column gap-3 py-3 my-3 border rounded">
                    <p className='m-0 p-0' style={{ fontWeight: "600" }}>Want to upgrade your account?</p>
                    <p className='m-0 p-0'>
                      You are on the <span className='fw-bold'>
                        {
                          subscription ?
                            subscription.amount === 900 ?
                              "BUSINESS" :
                              subscription.amount === 700 ?
                                "GOLD" :
                                subscription.amount === 500 ?
                                  "SILVER" :
                                  "BUSINESS" :
                            "Trial"

                        }
                      </span>
                    </p>
                    <p className='m-0 p-0' style={{ fontWeight: "600" }}>Your Account started on {trialDate}</p>
                    <p className='m-0 p-0' style={{ fontWeight: "600" }}>
                      Days Left: {
                        30 - dayjs(dayjs().format("YYYY-MM-D")).diff(dayjs(trialDate).format("YYYY-MM-D"), 'day') >= 0 ?
                          30 - dayjs(dayjs().format("YYYY-MM-D")).diff(dayjs(trialDate).format("YYYY-MM-D"), 'day')
                          :
                          0
                      }
                    </p>
                  </div>

                  <div className='p-3'>
                    <p className='text-center fw-bold m-0 p-0'>Booking Solo? With a team? Choose the right plan to fit your needs.</p>
                  </div>

                  <div className='bg-body my-3'>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <div className='d-flex flex-column gap-2'>
                            <span style={{ fontWeight: "600" }}>BASIC</span>
                            <span>Free Forever</span>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />1 Calendar connections per person</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Connect to Google, Office 365, Outlook, and iCloud calendars</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Access {process.env.REACT_APP_DOMAINNAME.split(".")[0]} with Web</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />1 Active event types</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Create unlimited one-on-one events</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Unlimited Create one-off meetings</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Customize your booking link</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Automated event notifications</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Add branding to your booking page</p>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <div className='d-flex w-100 justify-content-between'>
                            <div className='d-flex flex-column gap-2'>
                              <span style={{ fontWeight: "600" }}>SILVER</span>
                              <span>$5/user/mo</span>
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />2 Calendar connections per person</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Connect to Google, Office 365, Outlook, and iCloud calendars</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Access {process.env.REACT_APP_DOMAINNAME.split(".")[0]} with web</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Unlimited Active event types</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Create unlimited one-on-one events</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Create unlimited one-off meetings</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Customize your booking link</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Create group events</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Automated event notifications</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Send your invitee email reminders and follow-ups</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Update your cancellation policy</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Add links to your confirmation pages</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />View metrics and reporting</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Add branding to your booking page</p>
                          <div className='payFrame d-flex align-items-center w-100 bg-soul'>
                            <button className='btn btn-rounded salesforce-style' disabled={subscription ? subscription.amount === 500 ? true : false : false} onClick={(e) => { handlePay(e, 5) }}>
                              {
                                subscription ? subscription.amount === 500 ? "Purchased" : "Pay Now" : "Pay Now"
                              }
                            </button>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          <div className='d-flex w-100 justify-content-between'>
                            <div className='d-flex flex-column gap-2'>
                              <span style={{ fontWeight: "600" }}>GOLD</span>
                              <span>$7/user/mo</span>
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />6 Calendar connections per person</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Connect to Google, Office 365, Outlook, and iCloud calendars</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Access {process.env.REACT_APP_DOMAINNAME.split(".")[0]} with Web</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Unlimited Active event types</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Create unlimited one-on-one events</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Create unlimited one-off meetings</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Customize your booking link</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Create group events</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Automated event notifications</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Send your invitee email reminders and follow-ups</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Customize email notifications and reminders</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Get meeting notifications via text</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Update your cancellation policy</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Create automated workflows</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Add links to your confirmation pages</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Redirect your invitees</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />View metrics and reporting</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Remove {process.env.REACT_APP_DOMAINNAME.split(".")[0]} branding</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Add branding to your booking page</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Customize colors when you add {process.env.REACT_APP_DOMAINNAME.split(".")[0]} to your website</p>
                          <div className='payFrame d-flex align-items-center bg-soul'>
                            <button className='btn btn-rounded salesforce-style' disabled={subscription ? subscription.amount === 700 ? true : false : false} onClick={(e) => { handlePay(e, 7) }}>
                              {
                                subscription ? subscription.amount === 700 ? "Purchased" : "Pay Now" : "Pay Now"
                              }
                            </button>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="3">
                        <Accordion.Header>
                          <div className='d-flex w-100 justify-content-between'>
                            <div className='d-flex flex-column gap-2'>
                              <span style={{ fontWeight: "600" }}>BUSINESS</span>
                              <span>$9/user/mo</span>
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />6 Calendar connections per person</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Connect to Google, Office 365, Outlook, and iCloud calendars</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Access {process.env.REACT_APP_DOMAINNAME.split(".")[0]} with Web</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Unlimited Active event types</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Create unlimited one-on-one events</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Create unlimited one-off meetings</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Customize your booking link</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Create group events</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Automated event notifications</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Send your invitee email reminders and follow-ups</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Customize email notifications and reminders</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Get meeting notifications via text</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Update your cancellation policy</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Create automated workflows</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Add links to your confirmation pages</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Redirect your invitees</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />View metrics and reporting</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Remove {process.env.REACT_APP_DOMAINNAME.split(".")[0]} branding</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Add branding to your booking page</p>
                          <p className="featureName"><DoneOutlineIcon className='text-success' />Customize colors when you add {process.env.REACT_APP_DOMAINNAME.split(".")[0]} to your website</p>

                          <div className='payFrame d-flex align-items-center bg-soul'>
                            <button className='btn btn-rounded salesforce-style' disabled={subscription ? subscription.amount === 900 ? true : false : false} onClick={(e) => { handlePay(e, 9) }}>
                              {
                                subscription ? subscription.amount === 900 ? "Purchased" : "Pay Now" : "Pay Now"
                              }
                            </button>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </Tab>
                <Tab eventKey="CalendarConnections" title={<div className='h-100 glitchedPanTabsLinks color-salesforce' style={{ fontWeight: "500" }} onClick={() => { setViewOf("CalendarConnections"); setToHistory("CalendarConnections", null); }}>Calendars</div>}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className='col p-0'>
                        <div className="sticky-top bg-body">
                          <h5 className='p-3 m-0'>Calendar Connections</h5>
                        </div>

                        <div className=" d-flex align-items-center justify-content-between flex-wrap p-3">
                          <div className='d-flex align-items-center justify-content-start gap-3 ' style={{ fontWeight: "600" }}>
                            <span>My Calendar Account</span>
                            <span>{myRefreshTokens ? Object.keys(myRefreshTokens).length : 0}/6</span>
                          </div>
                          <div className='d-flex align-items-center justify-content-center'>
                            <button className='btn btn-sm rounded-pill px-3 d-flex gap-2 align-items-center border-0' disabled={myRefreshTokens ? Object.keys(myRefreshTokens).length >= 6 ? true : false : false}>
                              {/* <i className='fa fa-plus'></i> */}
                              {/* <span>Add Calendar Account</span> */}

                              <GoogleLogin
                                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                buttonText='Add Calendar Account'
                                onSuccess={responceGoogle}
                                onFailure={responseError}
                                cookiePolicy={"single_host_origin"}
                                fetchBasicProfile={true}
                                responseType="code"
                                prompt="consent"
                                accessType='offline'
                                className='shadow-sm rounded border overflow-hidden'
                                scope='openid email profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'
                              />
                            </button>
                          </div>
                        </div>


                        <ListGroup className='mb-3'>
                          {
                            myRefreshTokens ?
                              Object.keys(myRefreshTokens).map((elem, key) => {
                                return (

                                  <ListGroup.Item key={key}>
                                    <div className="d-flex align-items-center justify-content-between py-2 insightBackground" >
                                      <div className='d-flex align-items-center justify-content-start gap-2'>
                                        <div className='d-flex align-items-center justify-content-center'>
                                          <img style={{ width: "50px" }} className="rounded-circle" src={myRefreshTokens[elem].image} alt='Calendar Connection' />
                                        </div>
                                        <div className='d-flex flex-column align-items-start justify-content-center gap-1'>
                                          <span className='text-muted'>{myRefreshTokens[elem].accountType.includes("google") ? "Google" : "Microsoft"}</span>
                                          <span>
                                            {myRefreshTokens[elem].email}
                                          </span>
                                        </div>
                                      </div>
                                      <button type='button' className='btn d-flex align-items-center justify-content-center gap-2 text-danger p-0 m-0' onClick={(e) => { removeCalendar(myRefreshTokens[elem].email) }}>
                                        <PowerIcon />
                                        <span>Disconnect</span>
                                      </button>
                                    </div>
                                  </ListGroup.Item>
                                )
                              })
                              :
                              ""
                          }
                        </ListGroup>





                        <div className='border rounded insightBackground'>
                          <div className='d-flex align-items-center justify-content-start'>
                            <span className='p-3' style={{ fontWeight: "600" }}>Configuration</span>
                          </div>
                          <div className="container-fluid">

                            <div className="row d-none">
                              <div className="col-12 col-md-6 col-lg-6">
                                <div className='d-flex align-items-start gap-3'>
                                  <div className='d-flex align-items-center justify-content-start h-100'>
                                    <EventRepeatIcon style={{ height: "2rem", width: "2rem" }} />
                                    {/* <img src={""} style={{ height: "30px" }} alt="Email Image" className='img' /> */}
                                  </div>

                                  <div className='d-flex flex-column align-items-start justify-content-center '>
                                    <h6 className='m-0 p-0 mediumTextSize'>Check for conflicts</h6>
                                    <p className='m-0 p-0 smallTextNote mediumTextSize text-muted'>Set the calendar(s) to check for conflicts to prevent double bookings.</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-lg-6">
                                <div className="alert p-1 rounded border bg-body d-flex align-items-start justify-content-between overflow-hidden">
                                  {
                                    myRefreshTokens ?
                                      Object.keys(myRefreshTokens).map((elem, key) => {
                                        if (myRefreshTokens[elem].active === true) {
                                          return (
                                            <React.Fragment key={key}>
                                              <div className='d-flex gap-2'>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                  <img src={myRefreshTokens[elem].image} style={{ height: "25px", aspectRatio: "1" }} alt="calendarPng" />
                                                </div>
                                                <div className=''>
                                                  <p className='text-muted m-0 p-0 mediumTextSize'>
                                                    Check {myRefreshTokens[elem].email}
                                                  </p>
                                                  <ul style={{ paddingLeft: "20px" }}>
                                                    <li className='mediumTextSize'>{myRefreshTokens[elem].email}</li>
                                                  </ul>
                                                </div>
                                              </div>
                                              <div className='d-flex align-items-start'>
                                                <button className='btn text-primary btn-sm' onClick={() => { setShowCalendarList(true); }}>Edit</button>
                                              </div>
                                            </React.Fragment>
                                          )
                                        }
                                      })
                                      :
                                      <div className='d-flex align-items-start justify-content-center w-100'>
                                        <button className='btn btn-sm salesforce-style rounded' disabled onClick={() => { setShowCalendarList(true); }}>Add a Calendar first</button>
                                      </div>
                                  }
                                </div>
                              </div>
                            </div>

                            <div className="row  d-none">
                              <div className="col-12">
                                <hr className='mb-4 w-100' />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-12 col-md-6 col-lg-6">
                                <div className='d-flex align-items-start gap-3'>
                                  <div className='d-flex align-items-center justify-content-start h-100'>
                                    <EventAvailableIcon style={{ height: "2rem", width: "2rem" }} />
                                    {/* <img src={""} style={{ height: "30px" }} alt="alternate" className='img' /> */}
                                  </div>
                                  <div className='d-flex flex-column align-items-start justify-content-center '>
                                    <h6 className='m-0 p-0 mediumTextSize'>Add to calendar</h6>
                                    <p className='m-0 p-0 mediumTextSize text-muted'>Set the calendar you would like to add new events to as they’re scheduled.</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-lg-6">
                                <div className="alert p-1 rounded border bg-body d-flex align-items-start justify-content-between overflow-hidden">
                                  {
                                    myRefreshTokens ?
                                      Object.keys(myRefreshTokens).map((elem, key) => {
                                        if (myRefreshTokens[elem].active === true) {
                                          return (
                                            <React.Fragment key={key}>
                                              <div className='d-flex gap-2'>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                  <img src={myRefreshTokens[elem].image} style={{ height: "25px", aspectRatio: "1" }} alt="calendarPng" />
                                                </div>
                                                <div className=''>
                                                  <p className='text-muted m-0 p-0 mediumTextSize'>
                                                    Check {myRefreshTokens[elem].email}
                                                  </p>
                                                  <ul style={{ paddingLeft: "20px" }}>
                                                    <li className='mediumTextSize'>{myRefreshTokens[elem].email}</li>
                                                  </ul>
                                                </div>
                                              </div>
                                              <div className='d-flex align-items-start'>
                                                <button className='btn text-primary btn-sm' onClick={() => { setShowCalendarList(true); }}>Edit</button>
                                              </div>
                                            </React.Fragment>
                                          )
                                        }
                                        else {
                                          return <></>
                                        }
                                      })
                                      :
                                      <div className='d-flex align-items-start justify-content-center w-100'>
                                        <button className='btn btn-sm salesforce-style rounded' disabled onClick={() => { setShowCalendarList(true); }}>Add a Calendar first</button>
                                      </div>
                                  }
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div className="">

                        </div>
                      </div>

                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Availability" title={<div className='h-100 glitchedPanTabsLinks color-salesforce' style={{ fontWeight: "500" }} onClick={() => { setViewOf("Availability"); setToHistory("Availability", null); }}>Availablity</div>}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className='col p-0'>
                        <div className="rounded-0">
                          <div className="p-0 sticky-top bg-body">
                            <h5 className='p-3 m-0'>Set your availability</h5>
                          </div>
                          <div className="">
                            <div className='mb-3'>
                              <p className='smallTextNote p-3 m-0'>Choose a schedule below to edit or create a new one that you can apply to your event types</p>
                              <div className="conatiner-fluid border p-3 rounded insightBackground">
                                <div className="row mb-3">
                                  <div className="col">
                                    <span style={{ fontWeight: "600" }}>SCHEDULE TEMPLATES</span>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col">
                                    <div className='d-flex flex-wrap align-items-center gap-2'>
                                      {
                                        userSchedules &&
                                        Object.keys(userSchedules).map((name, key) => {
                                          if (scheduleName === name) {
                                            return <button type='button' key={key} className='btn salesforce-style rounded-pill border shadow-sm' onClick={() => { toogleScheduleSet(name); setScheduleName(name); }}>{name}</button>
                                          } else {
                                            return <button type='button' key={key} className='btn btn-outline-dark rounded-pill border shadow-sm' onClick={() => { toogleScheduleSet(name); setScheduleName(name); }}>{name}</button>
                                          }
                                        })
                                      }
                                      {/* {
                                          createNewSchedule &&
                                          <input type="text" className="customInputFieldFocus w-100 rounded-pill" style={{ maxWidth: "250px" }} onChange={(e) => { setScheduleName(e.target.value); }} required placeholder="Name of the Schedule..." />
                                      } */}
                                      <button type="button" className='btn btn-outline-dark rounded-pill shadow-sm border' onClick={() => { setCreateNewSchedule(true); }}>+ New Schedule</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card insightBackground">
                              <div className="card-header insightBackground" style={{ position: "relative", zIndex: "2" }}>
                                <div className="container-fluid">
                                  <div className="row mb-3">
                                    <div className="col p-0">
                                      <div className='w-100 d-flex justify-content-between align-items-center'>
                                        <span className='' style={{ fontWeight: "600" }}>{scheduleName ? scheduleName : "Working Hours"}</span>
                                        <OverlayTrigger
                                          placement="left"
                                          delay={{ show: 100, hide: 200 }}
                                          overlay={
                                            <Popover id="popover-basic">
                                              <Popover.Header className='salesforce-style' as="h3">Delete Schedule</Popover.Header>
                                              <Popover.Body>
                                                <p className='p-0 m-0 helpTextSize'>
                                                  By clicking this you can delete this schedule.
                                                </p>
                                              </Popover.Body>
                                            </Popover>
                                          }>
                                          <i
                                            className='fa fa-trash cursor-pointer'
                                            onClick={() => {
                                              setCustomAlertBox(true);
                                              setAlertBoxMessages(<p>Do you want to delete this schedule <br />"{scheduleName}"?</p>);
                                              setAlertErrorType("queryDelSchedule");
                                            }}
                                          ></i>
                                        </OverlayTrigger>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <div className="col-12 col-lg-6 p-0">
                                      <div className='d-flex flex-column align-items-start gap-3 h-100 w-100'>

                                        <div className='d-flex align-items-start flex-column justify-content-between gap-1 h-100 w-100' style={{ position: "relative", zIndex: "4" }}>
                                          <label htmlFor='timezoneGds'>Country *</label>
                                          <div className='w-100'>
                                            <Select options={countriesList} value={countrySchedule} onChange={changeHandlerSchedule} />
                                          </div>
                                        </div>

                                        <div className='d-flex align-items-start flex-column justify-content-between gap-1 h-100 w-100' style={{ position: "relative", zIndex: "3" }}>
                                          <label htmlFor='timezoneGds'>Time Zone</label>
                                          <div className='w-100'>
                                            <Select value={[selectedTimezone]} options={filteredTimezonesListSchedule} onChange={(e) => { setSelectedTimezone(e); }} />
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                    <div className="col">
                                      {/* <div className='d-flex align-items-end justify-content-end h-100'>
                                            <span className='d-flex align-items-center'>
                                                <button className='btn rounded-0 border btn-light d-flex align-items-center justify-content-start gap-1'>
                                                    <i className='fa fa-list'></i>
                                                    <span>List View</span>
                                                </button>
                                                <button className='btn rounded-0 d-flex align-items-center justify-content-start gap-1'>
                                                    <i className='fa fa-calendar'></i>
                                                    <span>Calendar View</span>
                                                </button>
                                            </span>
                                        </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="container-fluid">
                                  <div className="row">
                                    <div className="col-12 col-md-12 col-lg-7 border rounded shadow-sm bg-body">
                                      <p className='text-center pt-4' style={{ fontWeight: "600" }}>Set your weekly hours</p>
                                      {
                                        viewOf === "Availability" &&
                                        <AvailabilityTemplates
                                          setIsSundayActive={setIsSundayActive}
                                          setIsMondayActive={setIsMondayActive}
                                          setIsTuesdayActive={setIsTuesdayActive}
                                          setIsWednesdayActive={setIsWednesdayActive}
                                          setIsThursdayActive={setIsThursdayActive}
                                          setIsFridayActive={setIsFridayActive}
                                          setIsSaturdayActive={setIsSaturdayActive}
                                          isSundayActive={isSundayActive}
                                          isMondayActive={isMondayActive}
                                          isTuesdayActive={isTuesdayActive}
                                          isWednesdayActive={isWednesdayActive}
                                          isThursdayActive={isThursdayActive}
                                          isFridayActive={isFridayActive}
                                          isSaturdayActive={isSaturdayActive}
                                          checkDayOnToggle={checkDayOnToggle}
                                          schedule={schedule}
                                          timeListToMeasure={timeListToMeasure}
                                          filterTimeFieldNow={filterTimeFieldNow}
                                          checkUpdatedStateOfTimeValidation={checkUpdatedStateOfTimeValidation}
                                          checkValidTiming={checkValidTiming}
                                          timeFormat={timeFormat}
                                          addTimeSetIn={addTimeSetIn}
                                          dayjs={dayjs}
                                          deleteTimeSet={deleteTimeSet}
                                          uuidv4={uuidv4}
                                          ContentCopyIcon={ContentCopyIcon}
                                          copyTimesTo={copyTimesTo}
                                          categoryOfFrame={"AdminPage"}
                                        />
                                      }
                                    </div>
                                    <div className="col-12 col-lg-1 d-none d-lg-flex"></div>
                                    <div className="col-12 col-md-12 col-lg-4 border rounded shadow-sm insightBackground">
                                      <div className="alert d-flex flex-column align-items-center">
                                        <p className='text-center' style={{ fontWeight: "600" }}>Date overrides</p>
                                        <button type='button' className='btn btn-sm btn-outline-primary rounded-pill salesforce-style px-3' style={{ maxWidth: "max-content" }} onClick={() => { setViewDateOverridePanel(true); }}>
                                          <AddIcon /> Add date override
                                        </button>
                                      </div>
                                      {
                                        dateOverrides ?
                                          Object.keys(dateOverrides).length > 0 ?
                                            Object.keys(dateOverrides).map((data, key) => {
                                              return (
                                                <div key={key} className={`card mb-2 datesOverrides_${dateOverrides[data].uid}`}>
                                                  <div className='card-header salesforce-style d-flex align-items-center justify-content-between'>
                                                    <span>{dayjs(dateOverrides[data].date).format("ddd, DD MMM YYYY")}</span>
                                                    <span>
                                                      <i className="fa fa-trash" style={{ padding: "0 5px" }} id={dateOverrides[data].uid} onClick={(e) => { deleteOverrideDate(dateOverrides[data].date, `datesOverrides_${dateOverrides[data].uid}`); }} />
                                                    </span>
                                                  </div>
                                                  <div className='card-body d-flex justify-content-between align-items-center'>
                                                    {
                                                      dateOverrides[data].active ?
                                                        <>
                                                          <div className='d-flex flex-column align-items-start justify-content-center'>
                                                            {
                                                              dateOverrides[data].timeSet.map((time, key) => {
                                                                const set = time.split("-");
                                                                return (
                                                                  <React.Fragment key={key}>
                                                                    <button disabled className='btn btn-secondary border-0 mb-1 w-100'>
                                                                      {
                                                                        timeFormat.value === "12 Hours" ?
                                                                          `${dayjs(`2 November 1999 ${set[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")}-${dayjs(`2 November 1999 ${set[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")}`
                                                                          :
                                                                          `${dayjs(`2 November 1999 ${set[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm")}-${dayjs(`2 November 1999 ${set[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm")}`
                                                                      }
                                                                    </button>
                                                                  </React.Fragment>
                                                                )
                                                              })
                                                            }
                                                          </div>
                                                        </>
                                                        :
                                                        <>
                                                          <span>Unavailable</span>
                                                        </>
                                                    }
                                                  </div>
                                                </div>
                                              )
                                            })
                                            :
                                            <p className='text-center'>No overrides for now</p>
                                          :
                                          <p className='text-center'>No overrides for now</p>
                                      }
                                    </div>

                                    <div className="col-12 col-lg-1"></div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="py-3 d-flex align-items-center justify-content-center gap-3 w-100">
                            <button className='btn btn-sm btn-secondary rounded-pill px-4 py-1' type='button' onClick={() => { window.location.reload(); }}>Cancel</button>
                            <button className='btn btn-sm salesforce-style rounded-pill px-4 py-1' disabled={!updateButtonEnabled} type='button' onClick={(e) => { updateNewSchedule(e, "AdminPage"); }}>Update</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Integrations" title={<div className='h-100 glitchedPanTabsLinks color-salesforce' style={{ fontWeight: "500" }} onClick={() => { setViewOf("Integrations"); setToHistory("Integrations", null); }}>Integrations</div>}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className='col p-0'>
                        <div className="rounded-0 border-0">
                          <div className="sticky-top bg-light">
                            <h5 className='p-3 m-0 bg-body'>Integrations</h5>
                          </div>
                          <div className="d-flex justify-content-lg-start justify-content-center gap-5 flex-wrap p-3 border-top border-bottom">
                            <div className="Integrations-parent shadow-sm insightBackground">
                              <div className='company-frame'>
                                <a
                                  href={`/integration/zoom`}>
                                  <div className="company-image">
                                    <img src={zoomImg} style={{ width: "5rem", height: "5rem", objectFit: "contain" }} alt="Zoom Integration" />
                                  </div>
                                </a>
                              </div>
                              <p className='text-center p-0 m-0'>Zoom</p>
                            </div>
                            <div className="Integrations-parent shadow-sm insightBackground">
                              <div className='company-frame'>
                                <a
                                  href={`https://webexapis.com/v1/authorize?client_id=Ceed9b8ec2b98b002aa65cced5d1b9291a176b28625aa4c2a1b4969e300b4e50d&response_type=code&redirect_uri=https%3A%2F%2F${process.env.REACT_APP_DOMAINNAME}%2Fintegration%2Fwebex&scope=spark%3Akms%20meeting%3Aschedules_read%20spark-admin%3Alocations_read%20identity%3Atokens_write%20meeting%3Aadmin_config_write%20identity%3Atokens_read%20meeting%3Aadmin_config_read%20meeting%3Aschedules_write`}>
                                  <div className="company-image">
                                    <img src={webexImg} style={{ width: "5rem", height: "5rem", objectFit: "contain" }} alt="Webex Integration" />
                                  </div>
                                </a>
                              </div>
                              <p className='text-center p-0 m-0'>Cisco Webex</p>
                            </div>
                            <div className="Integrations-parent shadow-sm insightBackground">
                              <div className='company-frame'>
                                <a
                                  href={`https://authentication.logmeininc.com/oauth/authorize?client_id=4dafa5ed-21a1-4ee1-97b0-592b30c41b13&response_type=code&redirect_uri=https://${process.env.REACT_APP_DOMAINNAME}/integration/gotoMeeting`}>
                                  <div className="company-image">
                                    <img src={gotoImg} style={{ width: "5rem", height: "5rem", objectFit: "contain" }} alt="Goto Integration" />
                                  </div>
                                </a>
                              </div>
                              <p className='text-center p-0 m-0'>GoTo Meet</p>
                            </div>
                            <div className="Integrations-parent shadow-sm insightBackground">
                              <div className='company-frame'>
                                <a href="/integration/salesforce">
                                  <div className="company-image">
                                    <img src={SalseforceImg} style={{ width: "5rem", height: "5rem", objectFit: "contain" }} alt="Salesforce Integration" />
                                  </div>
                                </a>
                              </div>
                              <p className='text-center p-0 m-0'>Salesforce</p>
                            </div>
                            <div className="Integrations-parent shadow-sm insightBackground">
                              <div className='company-frame'>
                                <a href="/integration/teams" disabled title='This integration is under development'>
                                  <div className="company-image">
                                    <img src={teamsImg} style={{ width: "5rem", height: "5rem", objectFit: "contain" }} alt="MS Teams Integration" />
                                  </div>
                                </a>
                              </div>
                              <p className='text-center p-0 m-0'>MS Teams</p>
                            </div>
                          </div>
                          <div className="p-3 insightBackground">
                            <p className='text-dark m-0'><b>Please note.</b> MS Teams integration is under development. Kindly use other integrations for the events.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Logout" title={<div className='h-100 glitchedPanTabsLinks color-salesforce' style={{ fontWeight: "500" }} onClick={() => { setViewOf("Logout"); setToHistory("Logout", null); }}>Logout</div>}>
                  <div className="sticky-top bg-body">
                    <h5 className='p-3 m-0'>Logout</h5>
                  </div>

                  <div className='p-3 insightBackground border'>
                    <button className='btn btn-sm btn-danger rounded px-3' onClick={(e) => { logout(); }}>Logout</button>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>


      <div className={`sticky-bottom px-2 pb-2 ${needAccountSetup ? "d-none" : "d-block"} d-sm-none d-md-none d-lg-none`} style={{ zIndex: "10" }}>
        <div className='py-1 px-1 rounded-pill d-flex align-items-center justify-content-between w-100 insightBackground shadow-sm'>

          <div className='cursor-pointer' title={user ? username : "Profile"} onClick={(e) => { setViewAdminPage(true); setViewOf("AccountSettings"); setToHistory("AccountSettings", "Profile"); }} style={{ height: "35px", aspectRatio: "1" }}>
            <OverlayTrigger
              placement="top"
              delay={{ show: 100, hide: 200 }}
              overlay={
                <Popover id="popover-basic">
                  <Popover.Header className='salesforce-style' as="h3">Admin Panel</Popover.Header>
                  <Popover.Body>
                    <p className='p-0 m-0 helpTextSize'>
                      In this panel you can control all settings like Phone Numbers, Branding Logos, Profile Picture etc.
                    </p>
                  </Popover.Body>
                </Popover>
              }>
              <img className='h-100 rounded-pill' align="center" src={currentGlobalProfile} alt="" />
            </OverlayTrigger>
            {/* {JSON.stringify(images ? images[images.length - 1]['data_url'] ? images[images.length - 1]['data_url'] : defaultImage['data_url'] : user.profilePicture)} */}
          </div>
          <div className='w-100 px-3 d-flex align-items-center justify-content-between'>

            <button className='rounded border-0 p-1 salesforce-style' style={{ height: "35px", aspectRatio: "1" }} onClick={() => { setAlertShow(true); setViewOf("Notifications"); setToHistory("Notifications", null); }}>
              <NotificationsActiveIcon className='h-100 w-100' />
            </button>
            <button className='rounded border-0 p-1 salesforce-style' style={{ height: "35px", aspectRatio: "1" }} onClick={() => { setContactShow(true); setViewOf("PeopleShortcut"); setToHistory("PeopleShortcut", null); }}>
              <PeopleAltIcon className='h-100 w-100' />
            </button>
            <button className='rounded border-0 p-1 salesforce-style' style={{ height: "35px", aspectRatio: "1" }} onClick={() => { setMeetingShow(true); setMeetingsTabType("scheduledEvents"); setViewOf("Meetings"); setToHistory("Meetings", "scheduledEvents"); }}>
              <EventAvailableIcon className='h-100 w-100' />
            </button>
            <button className='rounded border-0 p-1 salesforce-style' style={{ height: "35px", aspectRatio: "1" }} onClick={() => { setAvailabilityShow(true); setViewOf("Schedules"); setToHistory("Schedules", null); }}>
              <ScheduleIcon className='h-100 w-100' />
            </button>
            {
              process.env.REACT_APP_DOMAINNAME === "scheduleai.co" ?
                ""
                :
                <button className='rounded border-0 p-1 salesforce-style' style={{ height: "35px", aspectRatio: "1" }} onClick={() => { setMessangerShow(true); setViewOf("Messanger"); setToHistory("Messanger", null); }}>
                  <Chat className='h-100 w-100' />
                </button>
            }
          </div>
        </div>
      </div>


      {/* ################################################################################################################ */}
      {/* ################################################################################################################ */}
      {/* ################################################# SIGNUP PROCEDURE ############################################# */}
      {/* ################################################################################################################ */}
      {/* ################################################################################################################ */}
      <div className={`min-vh-100 w-100 position-absolute backgroundFiller bg-soul top-0 left-start ${needAccountSetup ? "" : "d-none"}`} style={{ zIndex: 999 }}>
        <div className='rounded border p-2 p-lg-5 p-md-3 p-sm-2 min-vh-100 h-100 d-flex flex-column'>
          <div ref={progressBars} className='animateOn justify-content-between align-items-center w-100 sticky-top insightBackground' style={{ display: "none" }}>
            <div className={`rounded-circle ${stepOnWay > 0 ? "currentStepBorder" : "defaultStepBorder"} d-flex align-items-center justify-content-center`} style={{ height: "50px", width: "50px" }}>1</div>
            <div className='flex-grow-1'><hr className='w-100' style={{ border: "2px solid #0b5cab" }} /></div>
            <div className={`rounded-circle ${stepOnWay > 1 ? "currentStepBorder" : "defaultStepBorder"} d-flex align-items-center justify-content-center`} style={{ height: "50px", width: "50px" }}>2</div>
            <div className='flex-grow-1'><hr className='w-100' style={{ border: "2px solid #0b5cab" }} /></div>
            <div className={`rounded-circle ${stepOnWay > 2 ? "currentStepBorder" : "defaultStepBorder"} d-flex align-items-center justify-content-center`} style={{ height: "50px", width: "50px" }}>3</div>
            <div className='flex-grow-1'><hr className='w-100' style={{ border: "2px solid #0b5cab" }} /></div>
            <div className={`rounded-circle ${stepOnWay > 3 ? "currentStepBorder" : "defaultStepBorder"} d-flex align-items-center justify-content-center`} style={{ height: "50px", width: "50px" }}>4</div>
            <div className='flex-grow-1'><hr className='w-100' style={{ border: "2px solid #0b5cab" }} /></div>
            <div className={`rounded-circle ${stepOnWay > 4 ? "currentStepBorder" : "defaultStepBorder"} d-flex align-items-center justify-content-center`} style={{ height: "50px", width: "50px" }}>5</div>
          </div>
          <div ref={welcomeText1} className={`animateOn flex-fill d-flex flex-column justify-content-center align-items-center`} style={{ display: "block" }}>
            <a href={`https://${process.env.REACT_APP_DOMAINNAME}`} target='_Blank'><img src={calendaryLogo} className='mb-2' style={{ maxWidth: "200px" }} /></a>
            <p className='mb-5 display-6 color-salesforce text-center h-100 d-flex align-items-center justify-content-center'>
              Welcome
            </p>
            <p className='text-center'>Let's setup your account to get started.</p>
            <div className='d-flex align-items-center justify-content-center gap-4 w-100'>
              <button onClick={() => { setUpToStep1(); }} className="salesforce-style rounded-pill btn btn-sm px-3 border-0">Let's Start</button>
            </div>
          </div>
          <div ref={welcomeText2} className={`animateOn`} style={{ display: "none" }}>
            <div className='container-fluid p-0'>
              <div className="row">
                <div className="col-12">
                  <div className="">
                    <div className="border-bottom p-3">
                      <p className='display-6 p-0 m-0 color-salesforce text-center d-flex align-items-center justify-content-center'>
                        Step 1
                      </p>
                      <p className='p-0 my-2 color-salesforce text-center d-flex align-items-center justify-content-center'>Let's start with your name.</p>
                    </div>
                    <div className="p-3">
                      <div className="container-fluid p-0" style={{ maxWidth: "500px" }}>
                        <div className="row mt-3">
                          <div className="col">
                            <label className='w-100' htmlFor='name'>
                              <span style={{ fontWeight: "500" }} className="d-flex aling-items-center gap-1">
                                Name
                                <OverlayTrigger
                                  placement="right"
                                  delay={{ show: 100, hide: 200 }}
                                  overlay={
                                    <Popover id="popover-basic">
                                      <Popover.Header className='salesforce-style' as="h3">Your Name On Booking Pages</Popover.Header>
                                      <Popover.Body>
                                        <p className='p-0 m-0 helpTextSize'>
                                          Use this field to change the name present on your booking page.
                                        </p>
                                        <img src={nameHelpText} className='img img-responsive w-100' alt='helpText' />
                                      </Popover.Body>
                                    </Popover>
                                  }>
                                  <HelpOutlineIcon style={{ maxWidth: "15px", cursor: "help", margin: "0 5px" }} />
                                </OverlayTrigger>
                              </span>
                              <input required maxLength={30} type="text" value={name} onChange={validateAndSetName} className='customInputFieldFocus w-100 border mediumTextSize' />
                              <div className='d-flex align-items-center justify-content-between w-100'>
                                <p className='text-dark text-start m-0 smallTextSize'>Max length 30 chars.</p>
                                {
                                  name ?
                                    <span className='smallTextSize'>{30 - name.length} / 30</span>
                                    :
                                    <span className='smallTextSize'>30 / 30</span>
                                }
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-top p-3">
                      <div className='d-flex align-items-center justify-content-center gap-4 w-100'>
                        <button disabled={name ? name.replaceAll(" ", "").length < 1 ? true : false : true} onClick={() => { saveNewNameFromSignUp(); setUpToStep2(); }} className="salesforce-style rounded-pill btn btn-sm px-3 border-0">Next Step</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div ref={welcomeText3} className={`animateOn`} style={{ display: "none" }}>
            <div className='container-fluid p-0'>
              <div className="row">
                <div className="col-12">
                  <div className="">
                    <div className="border-bottom p-3">
                      <p className='display-6 color-salesforce text-center h-100 d-flex align-items-center justify-content-center'>
                        Step 2
                      </p>
                      <p className='p-0 my-2 color-salesforce text-center d-flex align-items-center justify-content-center'>Setting Up Your {process.env.REACT_APP_DOMAINNAME.split(".")[0]} URL.</p>
                    </div>
                    <div className="p-3">
                      <div className="container-fluid p-0" style={{ maxWidth: "500px" }}>
                        <div className="row mt-3">
                          <div className="col">
                            <span className='mediumTextSize'>Changing your {process.env.REACT_APP_DOMAINNAME.split(".")[0]} URL will mean that all of your copied links will no longer work and will need to be updated.</span>

                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 100, hide: 200 }}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Header className='salesforce-style' as="h3">Customize Your Booking Link</Popover.Header>
                                  <Popover.Body>
                                    <p className='p-0 m-0 helpTextSize'>
                                      Use this field to change your custom links to share with your invitees.
                                    </p>
                                    <img src={myLinkHelpText} className='img img-responsive w-100' alt='helpText' />
                                  </Popover.Body>
                                </Popover>
                              }>
                              <HelpOutlineIcon style={{ maxWidth: "15px", cursor: "help", margin: "0 5px" }} />
                            </OverlayTrigger>

                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12">
                            <div className="w-100 d-flex align-items-start justify-content-start flex-column gap-2 border rounded bg-body p-3">
                              <div className="w-100">
                                <div className="input-group w-100 flex-nowrap">
                                  <span className="input-group-text" id="basic-addon3" style={{ marginLeft: "0" }}>{process.env.REACT_APP_DOMAINNAME}/</span>
                                  <input type="text" maxLength={20} required className='customInputFieldFocus w-100' placeholder='UID' defaultValue={username} onChange={isUsernameAvailable} />
                                  <span className="input-group-text" style={{ marginLeft: "0", borderLeft: "none" }}>
                                    {
                                      usernameAvailable && username.length > 0 ?
                                        <DoneIcon color="success" />
                                        :
                                        <CloseIcon color="error" />
                                    }
                                  </span>
                                </div>
                                <div className='d-flex align-items-center justify-content-between w-100'>
                                  <p className='text-dark m-0 text-start smallTextSize'>Max length 20 chars.</p>
                                  <span className='smallTextSize'>{20 - username.length} / 20</span>
                                </div>
                              </div>
                              {
                                usernameFaults ?
                                  <div className="alert alert-danger mediumTextSize m-0 w-100 p-1 text-center">
                                    {usernameFaults}
                                  </div>
                                  :
                                  username.length < 1 ?
                                    <div className="alert alert-danger mediumTextSize m-0 w-100 p-1 text-center">
                                      Please provide a username.
                                    </div>
                                    :
                                    ""
                              }
                            </div>
                          </div>
                          <div className='col d-flex align-items-center justify-content-start gap-2'>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-top p-3">
                      <div className='d-flex align-items-center justify-content-center gap-4 w-100'>
                        <button type='button' onClick={() => { backtoStep1(); }} className="salesforce-style rounded-pill btn btn-sm px-3 border-0">Previous</button>
                        <button type='button' disabled={!usernameAvailable || username.length < 1 ? true : false} onClick={(e) => { updateLink(e); setUpToStep3(); }} id="updateLinkFromSignUp" className="salesforce-style rounded-pill btn btn-sm px-3 border-0 d-flex align-items-center gap-2">Next Step</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div ref={welcomeText4} className={`animateOn`} style={{ display: "none" }}>
            <div className='container-fluid p-0'>
              <div className="row">
                <div className="col-12">
                  <form onSubmit={(e) => { e.preventDefault(); setUpToStep4(); saveNewPassFromSignUp(); }}>
                    <div className="border-bottom p-3">
                      <p className='display-6 color-salesforce text-center h-100 d-flex align-items-center justify-content-center'>
                        Step 3
                      </p>
                      <p className='p-0 my-2 color-salesforce text-center d-flex align-items-center justify-content-center'>Setting Up Your New Password.</p>
                    </div>
                    <div className="p-3">
                      <div className="container-fluid p-0" style={{ maxWidth: "500px" }}>
                        <div className="row mt-3">
                          <div className="col">
                            <div className='d-flex w-100 flex-column gap-3 px-3'>
                              <span className='color-salesforce w-100 m-0 '>Set password for <span style={{ fontWeight: "600" }}>{user.email}</span></span>
                              <input type="password" autoComplete='off' role='presentation' ref={newPasswordField} placeholder='New password' className='customInputFieldFocus w-100' style={{ fontSize: ".9rem" }} value={newPass} required onInput={(e) => { setNewPass(e.target.value); passwordValidation(e.target.value) }} />
                              <input type="password" autoComplete='off' role='presentation' ref={newPasswordFieldVarified} placeholder='Confirm new password' className='customInputFieldFocus w-100' style={{ fontSize: ".9rem" }} value={newPassConf} required onInput={(e) => { setNewPassConf(e.target.value); passwordValidation(e.target.value) }} />
                              <p className='color-salesforce'>
                                <span className='cursor-pointer mediumTextSize' style={{ marginTop: "-10px" }} onClick={(e) => {
                                  if (newPasswordField.current.type == "text") {
                                    newPasswordField.current.type = "password";
                                    newPasswordFieldVarified.current.type = "password";
                                    e.target.innerHTML = "Show Password";
                                  } else {
                                    newPasswordField.current.type = "text";
                                    newPasswordFieldVarified.current.type = "text";
                                    e.target.innerHTML = "Hide Password";
                                  }
                                }}>Show Password</span>
                              </p>
                              {
                                <ul className='p-0 m-0 alert bg-soul p-1'>
                                  <li className={`mediumTextSize d-flex align-items-center gap-2 ${lowercaseAvailable ? "text-success" : "text-danger"}`}><i className={`text-center fa ${lowercaseAvailable ? "fa-check" : "fa-close"}`} style={{ width: "15px" }}></i>A lowercase</li>
                                  <li className={`mediumTextSize d-flex align-items-center gap-2 ${uppercaseAvailable ? "text-success" : "text-danger"}`}><i className={`text-center fa ${uppercaseAvailable ? "fa-check" : "fa-close"}`} style={{ width: "15px" }}></i>A uppercase</li>
                                  <li className={`mediumTextSize d-flex align-items-center gap-2 ${numbersAvailable ? "text-success" : "text-danger"}`}><i className={`text-center fa ${numbersAvailable ? "fa-check" : "fa-close"}`} style={{ width: "15px" }}></i>A number</li>
                                  <li className={`mediumTextSize d-flex align-items-center gap-2 ${specialCharsAvailable ? "text-success" : "text-danger"}`}><i className={`text-center fa ${specialCharsAvailable ? "fa-check" : "fa-close"}`} style={{ width: "15px" }}></i>A special character</li>
                                  <li className={`mediumTextSize d-flex align-items-center gap-2 ${minimumCharsAvailable ? "text-success" : "text-danger"}`}><i className={`text-center fa ${minimumCharsAvailable ? "fa-check" : "fa-close"}`} style={{ width: "15px" }}></i>Minimum 8 characters</li>
                                  <li className={`mediumTextSize d-flex align-items-center gap-2 ${passwordMatch ? "text-success" : "text-danger"}`}><i className={`text-center fa ${minimumCharsAvailable ? "fa-check" : "fa-close"}`} style={{ width: "15px" }}></i>Password Match</li>
                                </ul>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-top p-3">
                      <div className='d-flex align-items-center justify-content-center gap-4 w-100'>
                        <button onClick={() => { backtoStep2(); }} type='button' className="salesforce-style rounded-pill btn btn-sm px-3 border-0">Previous</button>
                        <button disabled={
                          lowercaseAvailable === false ||
                          uppercaseAvailable === false ||
                          numbersAvailable === false ||
                          specialCharsAvailable === false ||
                          minimumCharsAvailable === false ||
                          passwordMatch === false
                        }
                          type='submit' className="salesforce-style rounded-pill btn btn-sm px-3 border-0">Next Step</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>


          </div>
          <div ref={welcomeText5} className={`animateOn`} style={{ display: "none" }}>
            <div className='container-fluid p-0'>
              <div className="row">
                <div className="col-12">
                  <div className="">
                    <div className="border-bottom p-3">
                      <p className='display-6 color-salesforce text-center h-100 d-flex align-items-center justify-content-center'>
                        Step 4
                      </p>
                      <p className='p-0 my-2 color-salesforce text-center d-flex align-items-center justify-content-center'>Setting Up Some Basic Settings.</p>
                    </div>
                    <div className="p-3">
                      <div className="container-fluid p-0" style={{ maxWidth: "500px" }}>
                        <div className="row mt-3">
                          <div className="col">
                            <label className='w-100 mb-3'>
                              <span style={{ fontWeight: "500" }}>Time Format</span>
                              <Select
                                name="signUpTimeFormat"
                                id="timeFormatSignUp"
                                className='mediumTextSize'
                                options={[{ label: "12 Hours AM/PM", value: "12 Hours" }, { label: "24 Hours", value: "24 Hours" }]}
                                defaultValue={timeFormat}
                                onChange={(e) => { setTimeListToMeasure(e.label !== "12 Hours" ? timeListToMeasureData24 : timeListToMeasureData); setTimeFormat(e) }}
                              />
                            </label>

                            <label className='w-100 mb-3'>
                              <span style={{ fontWeight: "500" }}>Country</span>
                              {/* <Select name="countrySignUpPage" id="countrySignUp" required className='w-100 mediumTextSize' options={options} value={country} onChange={changeHandler} /> */}
                              <Select name="countrySignUpPage" id="countrySignUp" required className='w-100 mediumTextSize' options={countriesList} value={country} onChange={changeHandler} />
                            </label>

                            <label className='w-100 mb-3'>
                              <span style={{ fontWeight: "500" }}>Time Zone</span>
                              <Select value={[selectedTimezoneSettings]} options={filteredTimezonesList} onChange={(e) => { setSelectedTimezoneSettings(e); }} />
                            </label>

                            <label className='w-100'>
                              <span style={{ fontWeight: "500" }} className="d-flex aling-items-center gap-1">
                                Welcome Message *
                                <OverlayTrigger
                                  placement="right"
                                  delay={{ show: 100, hide: 200 }}
                                  overlay={
                                    <Popover id="popover-basic">
                                      <Popover.Header className='salesforce-style' as="h3">Welcome Message On Booking Pages</Popover.Header>
                                      <Popover.Body>
                                        <p className='p-0 m-0 mediumTextSize'>
                                          Use this field to change the welcome message present on your booking page.
                                        </p>
                                        <img src={welcomeMessageHelpText} className='img img-responsive w-100' alt='helpText' />
                                      </Popover.Body>
                                    </Popover>
                                  }>
                                  <HelpOutlineIcon style={{ maxWidth: "15px", cursor: "help" }} />
                                </OverlayTrigger>
                              </span>
                              <textarea required id="welcomeMessage" defaultValue={welcome} onChange={(e) => { setWelcome(e.target.value); }} type="text" className='customInputFieldFocus w-100 border px-2 mediumTextSize' style={welcome ? welcome.length > 0 ? {} : { border: "1px solid red" } : { border: "1px solid red" }} />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-top p-3">
                      <div className='d-flex align-items-center justify-content-center gap-4 w-100'>
                        <button onClick={() => { backtoStep3(); }} className="salesforce-style rounded-pill btn btn-sm px-3 border-0">Previous</button>
                        <button disabled={welcome ? welcome.length > 0 ? false : true : true} onClick={() => { updateBasicSettingsFromSignUp(); setUpToStep5(); }} className="salesforce-style rounded-pill btn btn-sm px-3 border-0">Next Step</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div ref={welcomeText6} className={`animateOn`} style={{ display: "none" }}>
            <div className='container-fluid p-0'>
              <div className="row">
                <div className="col-12">
                  <div className="">
                    <div className="border-bottom p-3">
                      <p className='display-6 color-salesforce text-center h-100 d-flex align-items-center justify-content-center'>
                        Step 5
                      </p>
                      <p className='p-0 my-2 color-salesforce text-center d-flex align-items-center justify-content-center'>Setting Up Your Availability.</p>
                    </div>
                    <div className="p-3">

                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-12 col-md-12 col-lg-7 border rounded shadow-sm insightBackground">
                            {
                              stepOnWay == 5 && (
                                <AvailabilityTemplates
                                  setIsSundayActive={setIsSundayActive}
                                  setIsMondayActive={setIsMondayActive}
                                  setIsTuesdayActive={setIsTuesdayActive}
                                  setIsWednesdayActive={setIsWednesdayActive}
                                  setIsThursdayActive={setIsThursdayActive}
                                  setIsFridayActive={setIsFridayActive}
                                  setIsSaturdayActive={setIsSaturdayActive}
                                  isSundayActive={isSundayActive}
                                  isMondayActive={isMondayActive}
                                  isTuesdayActive={isTuesdayActive}
                                  isWednesdayActive={isWednesdayActive}
                                  isThursdayActive={isThursdayActive}
                                  isFridayActive={isFridayActive}
                                  isSaturdayActive={isSaturdayActive}
                                  checkDayOnToggle={checkDayOnToggle}
                                  schedule={schedule}
                                  timeListToMeasure={timeListToMeasure}
                                  filterTimeFieldNow={filterTimeFieldNow}
                                  checkUpdatedStateOfTimeValidation={checkUpdatedStateOfTimeValidation}
                                  checkValidTiming={checkValidTiming}
                                  timeFormat={timeFormat}
                                  addTimeSetIn={addTimeSetIn}
                                  dayjs={dayjs}
                                  deleteTimeSet={deleteTimeSet}
                                  uuidv4={uuidv4}
                                  ContentCopyIcon={ContentCopyIcon}
                                  copyTimesTo={copyTimesTo}
                                  categoryOfFrame={"SignUp"}
                                />
                              )
                            }
                            {/* <div className="container-fluid p-0">
                              <div className="row my-4">
                                <div className="col-3">
                                  <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                    <label className='d-flex gap-1 align-items-center justify-content-between'>
                                      <div className="outerToggleSwitch" onClick={() => { setIsSundayActive(!isSundayActive); checkDayOnToggle(!isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, "Shrtcut"); }}>
                                        <div className={`frameOfToggleSwitch ${isSundayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                          <span>On</span>
                                          <div className="toggleSwitchBall"></div>
                                          <span>Off</span>
                                        </div>
                                      </div>
                                      <span className='smallTextNote'>SUN</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-7">
                                  {
                                    isSundayActive ?
                                      <div className='m-0 p-0' id="sundayFrameSignUp">
                                        {
                                          schedule.sunday.times.map((sadn, key) => {
                                            const startTimeGiving = sadn.split("-")[0];
                                            const endTimeGiving = sadn.split("-")[1];
                                            const uid = uuidv4();
                                            return (
                                              <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"sundayTimeFrame_" + uid}>
                                                <div>
                                                  <input list="sundayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm sundayStartTabsSignUp`} />
                                                  <datalist id="sundayStart" className='smallTextNote'>
                                                    {
                                                      timeListToMeasure.map((times, key) => {
                                                        return (
                                                          <option key={key} value={times}>{times}</option>
                                                        )
                                                      })
                                                    }
                                                  </datalist>
                                                </div>
                                                <div>
                                                  <input list="sundayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm sundayEndTabsSignUp`} />
                                                  <datalist id="sundayEnd" className='smallTextNote'>
                                                    {
                                                      timeListToMeasure.map((times, key) => {
                                                        return (
                                                          <option key={key} value={times}>{times}</option>
                                                        )
                                                      })
                                                    }
                                                  </datalist>
                                                </div>
                                                <div className='d-flex align-items-center h-100'>
                                                  <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("sunday", "SignUp", uid); }} />
                                                </div>
                                              </span>
                                            )
                                          })
                                        }
                                      </div>
                                      :
                                      <span className='smallTextNote'>Unavailable</span>
                                  }

                                </div>
                                <div className="col-2">
                                  <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                    <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("sunday", "SignUp", uuidv4()); }} ></i>
                                    <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("sunday", "SignUp"); }} />
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-4">
                                <div className="col-3">
                                  <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                    <label className='d-flex gap-1 align-items-center justify-content-between'>
                                      <div className="outerToggleSwitch" onClick={() => { setIsMondayActive(!isMondayActive); checkDayOnToggle(isSundayActive, !isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, "Shrtcut"); }}>
                                        <div className={`frameOfToggleSwitch ${isMondayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                          <span>On</span>
                                          <div className="toggleSwitchBall"></div>
                                          <span>Off</span>
                                        </div>
                                      </div>
                                      <span className='smallTextNote'>MON</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-7">

                                  {
                                    isMondayActive ?
                                      <div className='m-0 p-0' id="mondayFrameSignUp">
                                        {
                                          schedule.monday.times.map((sadn, key) => {
                                            const startTimeGiving = sadn.split("-")[0];
                                            const endTimeGiving = sadn.split("-")[1];
                                            const uid = uuidv4();
                                            return (
                                              <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"mondayTimeFrame_" + uid}>
                                                <div>
                                                  <input list="mondayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm mondayStartTabsSignUp`} />
                                                  <datalist id="mondayStart" className='smallTextNote'>
                                                    {
                                                      timeListToMeasure.map((times, key) => {
                                                        return (
                                                          <option key={key} value={times}>{times}</option>
                                                        )
                                                      })
                                                    }
                                                  </datalist>
                                                </div>
                                                <div>
                                                  <input list="mondayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm mondayEndTabsSignUp`} />
                                                  <datalist id="mondayEnd" className='smallTextNote'>
                                                    {
                                                      timeListToMeasure.map((times, key) => {
                                                        return (
                                                          <option key={key} value={times}>{times}</option>
                                                        )
                                                      })
                                                    }
                                                  </datalist>
                                                </div>
                                                <div className='d-flex align-items-center h-100'>
                                                  <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("monday", "SignUp", uid); }} />
                                                </div>
                                              </span>
                                            )
                                          })
                                        }
                                      </div>
                                      :
                                      <span className='smallTextNote'>Unavailable</span>
                                  }

                                </div>
                                <div className="col-2">
                                  <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                    <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("monday", "SignUp", uuidv4()); }} ></i>
                                    <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("monday", "SignUp"); }} />
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-4">
                                <div className="col-3">
                                  <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                    <label className='d-flex gap-1 align-items-center justify-content-between'>
                                      <div className="outerToggleSwitch" onClick={() => { setIsTuesdayActive(!isTuesdayActive); checkDayOnToggle(isSundayActive, isMondayActive, !isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, "Shrtcut"); }}>
                                        <div className={`frameOfToggleSwitch ${isTuesdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                          <span>On</span>
                                          <div className="toggleSwitchBall"></div>
                                          <span>Off</span>
                                        </div>
                                      </div>
                                      <span className='smallTextNote'>TUE</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-7">
                                  {
                                    isTuesdayActive ?
                                      <div className='m-0 p-0' id="tuesdayFrameSignUp">
                                        {
                                          schedule.tuesday.times.map((sadn, key) => {
                                            const startTimeGiving = sadn.split("-")[0];
                                            const endTimeGiving = sadn.split("-")[1];
                                            const uid = uuidv4();
                                            return (
                                              <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"tuesdayTimeFrame_" + uid}>
                                                <div>
                                                  <input list="tuesdayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm tuesdayStartTabsSignUp`} />
                                                  <datalist id="tuesdayStart" className='smallTextNote'>
                                                    {
                                                      timeListToMeasure.map((times, key) => {
                                                        return (
                                                          <option key={key} value={times}>{times}</option>
                                                        )
                                                      })
                                                    }
                                                  </datalist>
                                                </div>
                                                <div>
                                                  <input list="tuesdayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm tuesdayEndTabsSignUp`} />
                                                  <datalist id="tuesdayEnd" className='smallTextNote'>
                                                    {
                                                      timeListToMeasure.map((times, key) => {
                                                        return (
                                                          <option key={key} value={times}>{times}</option>
                                                        )
                                                      })
                                                    }
                                                  </datalist>
                                                </div>
                                                <div className='d-flex align-items-center h-100'>
                                                  <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("tuesday", "SignUp", uid); }} />
                                                </div>
                                              </span>
                                            )
                                          })
                                        }
                                      </div>
                                      :
                                      <span className='smallTextNote'>Unavailable</span>
                                  }

                                </div>
                                <div className="col-2">
                                  <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                    <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("tuesday", "SignUp", uuidv4()); }} ></i>
                                    <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("tuesday", "SignUp"); }} />
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-4">
                                <div className="col-3">
                                  <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                    <label className='d-flex gap-1 align-items-center justify-content-between'>
                                      <div className="outerToggleSwitch" onClick={() => { setIsWednesdayActive(!isWednesdayActive); checkDayOnToggle(isSundayActive, isMondayActive, isTuesdayActive, !isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, "Shrtcut"); }}>
                                        <div className={`frameOfToggleSwitch ${isWednesdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                          <span>On</span>
                                          <div className="toggleSwitchBall"></div>
                                          <span>Off</span>
                                        </div>
                                      </div>
                                      <span className='smallTextNote'>WED</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-7">
                                  {
                                    isWednesdayActive ?
                                      <div className='m-0 p-0' id="wednesdayFrameSignUp">
                                        {
                                          schedule.wednesday.times.map((sadn, key) => {
                                            const startTimeGiving = sadn.split("-")[0];
                                            const endTimeGiving = sadn.split("-")[1];
                                            const uid = uuidv4();
                                            return (
                                              <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"wednesdayTimeFrame_" + uid}>
                                                <div>
                                                  <input list="wednesdayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm wednesdayStartTabsSignUp`} />
                                                  <datalist id="wednesdayStart" className='smallTextNote'>
                                                    {
                                                      timeListToMeasure.map((times, key) => {
                                                        return (
                                                          <option key={key} value={times}>{times}</option>
                                                        )
                                                      })
                                                    }
                                                  </datalist>
                                                </div>
                                                <div>
                                                  <input list="wednesdayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm wednesdayEndTabsSignUp`} />
                                                  <datalist id="wednesdayEnd" className='smallTextNote'>
                                                    {
                                                      timeListToMeasure.map((times, key) => {
                                                        return (
                                                          <option key={key} value={times}>{times}</option>
                                                        )
                                                      })
                                                    }
                                                  </datalist>
                                                </div>
                                                <div className='d-flex align-items-center h-100'>
                                                  <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("wednesday", "SignUp", uid); }} />
                                                </div>
                                              </span>
                                            )
                                          })
                                        }
                                      </div>
                                      :
                                      <span className='smallTextNote'>Unavailable</span>
                                  }

                                </div>
                                <div className="col-2">
                                  <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                    <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("wednesday", "SignUp", uuidv4()); }} ></i>
                                    <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("wednesday", "SignUp"); }} />
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-4">
                                <div className="col-3">
                                  <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                    <label className='d-flex gap-1 align-items-center justify-content-between'>
                                      <div className="outerToggleSwitch" onClick={() => { setIsThursdayActive(!isThursdayActive); checkDayOnToggle(isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, !isThursdayActive, isFridayActive, isSaturdayActive, "Shrtcut"); }}>
                                        <div className={`frameOfToggleSwitch ${isThursdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                          <span>On</span>
                                          <div className="toggleSwitchBall"></div>
                                          <span>Off</span>
                                        </div>
                                      </div>
                                      <span className='smallTextNote'>THU</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-7">
                                  {
                                    isThursdayActive ?
                                      <div className='m-0 p-0' id="thursdayFrameSignUp">
                                        {
                                          schedule.thursday.times.map((sadn, key) => {
                                            const startTimeGiving = sadn.split("-")[0];
                                            const endTimeGiving = sadn.split("-")[1];
                                            const uid = uuidv4();
                                            return (
                                              <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"thursdayTimeFrame_" + uid}>
                                                <div>
                                                  <input list="thursdayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm thursdayStartTabsSignUp`} />
                                                  <datalist id="thursdayStart" className='smallTextNote'>
                                                    {
                                                      timeListToMeasure.map((times, key) => {
                                                        return (
                                                          <option key={key} value={times}>{times}</option>
                                                        )
                                                      })
                                                    }
                                                  </datalist>
                                                </div>
                                                <div>
                                                  <input list="thursdayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm thursdayEndTabsSignUp`} />
                                                  <datalist id="thursdayEnd" className='smallTextNote'>
                                                    {
                                                      timeListToMeasure.map((times, key) => {
                                                        return (
                                                          <option key={key} value={times}>{times}</option>
                                                        )
                                                      })
                                                    }
                                                  </datalist>
                                                </div>
                                                <div className='d-flex align-items-center h-100'>
                                                  <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("thursday", "SignUp", uid); }} />
                                                </div>
                                              </span>
                                            )
                                          })
                                        }
                                      </div>
                                      :
                                      <span className='smallTextNote'>Unavailable</span>
                                  }

                                </div>
                                <div className="col-2">
                                  <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                    <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("thursday", "SignUp", uuidv4()); }} ></i>
                                    <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("thursday", "SignUp"); }} />
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-4">
                                <div className="col-3">
                                  <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                    <label className='d-flex gap-1 align-items-center justify-content-between'>
                                      <div className="outerToggleSwitch" onClick={() => { setIsFridayActive(!isFridayActive); checkDayOnToggle(!isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, !isFridayActive, isSaturdayActive, "Shrtcut"); }}>
                                        <div className={`frameOfToggleSwitch ${isFridayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                          <span>On</span>
                                          <div className="toggleSwitchBall"></div>
                                          <span>Off</span>
                                        </div>
                                      </div>
                                      <span className='smallTextNote'>FRI</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-7">
                                  {
                                    isFridayActive ?
                                      <div className='m-0 p-0' id="fridayFrameSignUp">
                                        {
                                          schedule.friday.times.map((sadn, key) => {
                                            const startTimeGiving = sadn.split("-")[0];
                                            const endTimeGiving = sadn.split("-")[1];
                                            const uid = uuidv4();
                                            return (
                                              <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"fridayTimeFrame_" + uid}>
                                                <div>
                                                  <input list="fridayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm fridayStartTabsSignUp`} />
                                                  <datalist id="fridayStart" className='smallTextNote'>
                                                    {
                                                      timeListToMeasure.map((times, key) => {
                                                        return (
                                                          <option key={key} value={times}>{times}</option>
                                                        )
                                                      })
                                                    }
                                                  </datalist>
                                                </div>
                                                <div>
                                                  <input list="fridayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm fridayEndTabsSignUp`} />
                                                  <datalist id="fridayEnd" className='smallTextNote'>
                                                    {
                                                      timeListToMeasure.map((times, key) => {
                                                        return (
                                                          <option key={key} value={times}>{times}</option>
                                                        )
                                                      })
                                                    }
                                                  </datalist>
                                                </div>
                                                <div className='d-flex align-items-center h-100'>
                                                  <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("friday", "SignUp", uid); }} />
                                                </div>
                                              </span>
                                            )
                                          })
                                        }
                                      </div>
                                      :
                                      <span className='smallTextNote'>Unavailable</span>
                                  }

                                </div>
                                <div className="col-2">
                                  <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                    <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("friday", "SignUp", uuidv4()); }} ></i>
                                    <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("friday", "SignUp"); }} />
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-4">
                                <div className="col-3">
                                  <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                    <label className='d-flex gap-1 align-items-center justify-content-between'>
                                      <div className="outerToggleSwitch" onClick={() => { setIsSaturdayActive(!isSaturdayActive); checkDayOnToggle(isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, !isSaturdayActive, "Shrtcut"); }}>
                                        <div className={`frameOfToggleSwitch ${isSaturdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                          <span>On</span>
                                          <div className="toggleSwitchBall"></div>
                                          <span>Off</span>
                                        </div>
                                      </div>
                                      <span className='smallTextNote'>SAT</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-7">
                                  {
                                    isSaturdayActive ?
                                      <div className='m-0 p-0' id="saturdayFrameSignUp">
                                        {
                                          schedule.saturday.times.map((sadn, key) => {
                                            const startTimeGiving = sadn.split("-")[0];
                                            const endTimeGiving = sadn.split("-")[1];
                                            const uid = uuidv4();
                                            return (
                                              <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"saturdayTimeFrame_" + uid}>
                                                <div>
                                                  <input list="saturdayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${startTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm saturdayStartTabsSignUp`} />
                                                  <datalist id="saturdayStart" className='smallTextNote'>
                                                    {
                                                      timeListToMeasure.map((times, key) => {
                                                        return (
                                                          <option key={key} value={times}>{times}</option>
                                                        )
                                                      })
                                                    }
                                                  </datalist>
                                                </div>
                                                <div>
                                                  <input list="saturdayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${endTimeGiving.replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} autoComplete="off" name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm saturdayEndTabsSignUp`} />
                                                  <datalist id="saturdayEnd" className='smallTextNote'>
                                                    {
                                                      timeListToMeasure.map((times, key) => {
                                                        return (
                                                          <option key={key} value={times}>{times}</option>
                                                        )
                                                      })
                                                    }
                                                  </datalist>
                                                </div>
                                                <div className='d-flex align-items-center h-100'>
                                                  <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("saturday", "SignUp", uid); }} />
                                                </div>
                                              </span>
                                            )
                                          })
                                        }
                                      </div>
                                      :
                                      <span className='smallTextNote'>Unavailable</span>
                                  }

                                </div>
                                <div className="col-2">
                                  <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                    <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("saturday", "SignUp", uuidv4()); }} ></i>
                                    <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("saturday", "SignUp"); }} />
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </div>
                          <div className="col-12 col-lg-1 d-lg-flex py-2"></div>
                          <div className="col-12 col-md-12 col-lg-4 border rounded shadow-sm insightBackground">
                            <div className="alert d-flex flex-column align-items-center">
                              <p className='text-center' style={{ fontWeight: "600" }}>Date overrides</p>
                              <button type='button' className='btn btn-sm btn-outline-primary rounded-pill salesforce-style px-3' style={{ maxWidth: "max-content" }} onClick={() => { setViewDateOverridePanel(true); }}>
                                <AddIcon /> Add date override
                              </button>
                            </div>
                            {
                              dateOverrides ?
                                Object.keys(dateOverrides).length > 0 ?
                                  Object.keys(dateOverrides).map((data, key) => {
                                    return (
                                      <div key={key} className={`card mb-2 datesOverrides_${dateOverrides[data].uid}`}>
                                        <div className='card-header salesforce-style d-flex align-items-center justify-content-between'>
                                          <span>{dayjs(dateOverrides[data].date).format("ddd, DD MMM YYYY")}</span>
                                          <span>
                                            <i className="fa fa-trash" style={{ padding: "0 5px" }} id={dateOverrides[data].uid} onClick={(e) => { deleteOverrideDate(dateOverrides[data].date, `datesOverrides_${dateOverrides[data].uid}`); }} />
                                          </span>
                                        </div>
                                        <div className='card-body d-flex justify-content-between align-items-center'>
                                          {
                                            dateOverrides[data].active ?
                                              <>
                                                <div className='d-flex flex-column align-items-start justify-content-center'>
                                                  {
                                                    dateOverrides[data].timeSet.map((time, key) => {
                                                      const set = time.split("-");
                                                      return (
                                                        <React.Fragment key={key}>
                                                          <button disabled className='btn btn-secondary border-0 mb-1 w-100'>
                                                            {
                                                              timeFormat.value === "12 Hours" ?
                                                                `${dayjs(`2 November 1999 ${set[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")}-${dayjs(`2 November 1999 ${set[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")}`
                                                                :
                                                                `${dayjs(`2 November 1999 ${set[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm")}-${dayjs(`2 November 1999 ${set[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm")}`
                                                            }
                                                          </button>
                                                        </React.Fragment>
                                                      )
                                                    })
                                                  }
                                                </div>
                                              </>
                                              :
                                              <>
                                                <span>Unavailable</span>
                                              </>
                                          }
                                        </div>
                                      </div>
                                    )
                                  })
                                  :
                                  <p className='text-center'>No overrides for now</p>
                                :
                                <p className='text-center'>No overrides for now</p>
                            }
                          </div>

                          <div className="col-12 col-lg-1"></div>
                        </div>

                      </div>
                    </div>
                    <div className="border-top p-3">
                      <div className='d-flex align-items-center justify-content-center gap-4 w-100'>
                        <button onClick={() => { backtoStep4(); }} className="salesforce-style rounded-pill btn btn-sm px-3 border-0">Previous</button>
                        <button disabled={!updateButtonEnabled || !overrideButtonEnabled} onClick={(e) => { updateNewSchedule(e, "SignUp"); finalStep(); }} className="salesforce-style rounded-pill btn btn-sm px-3 border-0">Finish</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ################################################################################################################ */}
      {/* ################################################################################################################ */}
      {/* ################################################# SIGNUP PROCEDURE ############################################# */}
      {/* ################################################################################################################ */}
      {/* ################################################################################################################ */}

      {/* ############################################################ */}
      <Modal
        show={customAlertBox}
        // show={true}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => { setCustomAlertBox(false) }}
      >
        <Modal.Header className="salesforce-style py-2" closeButton closeVariant='white'>
          <Modal.Title id="contained-modal-title-vcenter">
            <span style={{ fontSize: "1rem" }}>{alertErrorType === "queryDelSchedule" ? "Delete Schedule" : alertErrorType === "queryDelAccount" ? "Delete Account" : alertErrorType === "queryDel" ? "Delete Event" : alertErrorType === "queryCanc" ? "Cancel Event" : ""}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex flex-column justify-content-center align-items-center gap-3'>
            {/* <img className='alertImageType' src={
              alertErrorType === "invalid" ? invalidPng :
                alertErrorType === "queryDel" ? queryPng :
                  alertErrorType === "queryDelAccount" ? queryPng :
                    alertErrorType === "queryCanc" ? queryPng :
                      alertErrorType === "queryDelSchedule" ? queryPng :
                        successPng} alt="alert Image" /> */}
            <span className={`alertMessageContent text-break py-3 text-center ${alertErrorType === "queryCanc" || alertErrorType === "queryDelAccount" ? "d-none" : ""}`}>
              {
                alertBoxMessages
              }
            </span>
            <div className={`w-100 ${alertErrorType === "queryDelAccount" ? "" : "d-none"}`}>
              {
                deletionContents
              }
            </div>
            <div className={`w-100 ${alertErrorType === "queryCanc" ? "" : "d-none"}`}>
              {
                cancelContents
              }
            </div>
            {
              alertErrorType === "queryDel" ?
                (
                  <div className='d-flex justify-content-center align-items-center gap-3'>
                    <button className='btn btn-sm btn-secondary px-5 text-center rounded-pill my-1' onClick={() => { setCustomAlertBox(false); }}>No</button>
                    <button className='btn btn-sm salesforce-style px-5 text-center rounded-pill my-1' onClick={() => { deleteOneOffEvent(alertBoxIdData); setCustomAlertBox(false); }}>Yes</button>
                  </div>
                )
                :
                alertErrorType === "queryDelAccount" ?
                  (
                    <div className='d-flex justify-content-center align-items-center gap-3'>
                      <button className='btn btn-sm btn-secondary px-5 text-center rounded-pill my-1' onClick={() => { setCustomAlertBox(false); }}>No</button>
                      <button className='btn btn-sm salesforce-style px-5 text-center rounded-pill my-1 d-flex align-items-center gap-2' onClick={() => { setCustomAlertBox(false); setAskOwnership(true); }}>Yes</button>
                    </div>
                  )
                  :
                  alertErrorType === "queryCanc" ?
                    (
                      <div className='d-flex justify-content-center align-items-center gap-3'>
                        <button className='btn btn-sm btn-secondary px-5 text-center rounded-pill my-1' onClick={() => { setCustomAlertBox(false); cancellationPolicy.current.value = ""; }}>No, don't cancel</button>
                        <button className='btn btn-sm salesforce-style px-5 text-center rounded-pill my-1 d-flex align-items-center gap-2' onClick={() => { cancelThisMeeting(alertCancIdOne, alertCancIdTwo, cancellationOrigionalAdminOfEvent, selectedMeetingCategory, assignedRefreshToken); }} id="loadingHereCancel">Yes, cancel</button>
                      </div>
                    )
                    :
                    alertErrorType === "queryDelSchedule" ?
                      (
                        <div className='d-flex justify-content-center align-items-center gap-3'>
                          <button className='btn btn-sm salesforce-style px-5 text-center rounded-pill my-1' onClick={() => { startDeleteSchedule(scheduleName); }}>Yes</button>
                          <button className='btn btn-sm btn-secondary px-5 text-center rounded-pill my-1' onClick={() => { setCustomAlertBox(false); }}>No</button>
                        </div>
                      )
                      :
                      (
                        <button className='btn btn-sm salesforce-style px-5 text-center rounded-pill my-1' onClick={() => { setCustomAlertBox(false); }}>OK</button>
                      )
            }
          </div>
        </Modal.Body>
      </Modal>
      {/* ############################################################ */}

      {/* ############################################################ */}
      <Modal
        show={askOwnership}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => { setAskOwnership(false) }}
      >
        <Modal.Header className="salesforce-style py-2" closeButton closeVariant='white'>
          <Modal.Title id="contained-modal-title-vcenter">
            <span style={{ fontSize: "1rem" }}>Ownership Confirmation</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className='w-100 d-flex flex-column align-items-center justify-content-start gap-2' onSubmit={(e) => { verifyOwnerShip(e); }}>
            <div className='w-100 py-2 d-flex flex-column gap-2' style={{ maxWidth: "350px" }}>
              <div className='alert alert-info w-100 text-center'>Authorization Required</div>
              <label htmlFor="" className='w-100 mediumTextSize'>
                Password:
                <input type="password" className='customInputFieldFocus w-100' autoComplete='off' role='presentation' required ref={ownershipPassOne} />
              </label>
              <label htmlFor="" className='w-100 mediumTextSize'>
                Confirm Password:
                <input type="password" className='customInputFieldFocus w-100' autoComplete='off' role='presentation' required ref={ownershipPassTwo} />
              </label>
            </div>
            <button type="submit" id="deleteAccountButton" className='btn btn-sm salesforce-style w-100 rounded-pill text-center' style={{ maxWidth: "130px" }}>Authorize Now</button>
          </form>
        </Modal.Body>
      </Modal>
      {/* ############################################################ */}


      {/* ################################# Update Profile Picture Panel ############################### */}
      <Modal
        show={viewImageUploader}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => { setViewImageUploader(false); resetImageCropperSettings(); setImageSizeError(null); }}
      >
        <Modal.Header className="salesforce-style py-2" closeButton closeVariant='white'>
          <Modal.Title id="contained-modal-title-vcenter">
            <span style={{ fontSize: "1rem" }}>Update Profile Picture</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="container-fluid bg-light p-3 rounded">
            <div className="row">
              <div className="col-12 col-md-6 col-sm-6 col-lg-6">
                <form className='cropperPanels border w-100 h-100 p-2 d-flex flex-column flex-wrap gap-2' onSubmit={(e) => { updateProfilePicture(e); }}>
                  <label htmlFor="fileSelector" className='w-100'>
                    <button className='btn btn-outline-dark w-100 rounded p-0 px-1' style={{ pointerEvents: "none" }}>Choose file</button>
                    <input id="fileSelector" type="file" accept="image/*" onChange={onSelectFile} className='d-none' />
                  </label>
                  <label htmlFor="" className='w-100'>
                    <span className='mediumTextSize'>Scale</span>
                    <div className='d-flex align-items-center'>
                      <input
                        type="range"
                        className='form-range w-100'
                        min="1"
                        max="10"
                        step="0.1"
                        value={scale}
                        disabled={!imgSrc}
                        onChange={(e) => setScale(Number(e.target.value))}
                      />
                      <input type="text" maxLength={1} disabled className='customInputFieldFocus w-25 pe-none' value={scale} onChange={() => { }} />
                    </div>
                  </label>
                  <label htmlFor="" className='w-100'>
                    <span className='mediumTextSize'>Rotate</span>
                    <div className='d-flex align-items-center'>
                      <input
                        type="range"
                        className='form-range w-100'
                        min="0"
                        max="360"
                        step="1"
                        value={rotate}
                        disabled={!imgSrc}
                        onChange={(e) =>
                          setRotate(Math.min(360, Math.max(-180, Number(e.target.value))))
                        }
                      />
                      <input type="text" maxLength={3} disabled className='customInputFieldFocus w-25 pe-none' value={rotate} onChange={(e) => { handleRotateValue(e) }} />
                    </div>
                  </label>
                  <label htmlFor="" className='w-100 d-flex justify-content-between'>
                    <span className='mediumTextSize'>Aspect</span>
                    <div className="outerToggleSwitch" onClick={handleToggleAspectClick}>
                      <div className={`frameOfToggleSwitch ${aspect ? "slideRightToogle" : "slideLeftToogle"}`}>
                        <span>On</span>
                        <div className="toggleSwitchBall"></div>
                        <span>Off</span>
                      </div>
                    </div>
                  </label>

                  <label htmlFor="" className='w-100 d-flex justify-content-between'>
                    <span className='mediumTextSize'>Flip Horizontal</span>
                    <div className="outerToggleSwitch" onClick={() => { if (imgRef.current) { flipHorizontal ? setFlipHorizontal(false) : setFlipHorizontal(true); } }}>
                      <div className={`frameOfToggleSwitch ${flipHorizontal ? "slideRightToogle" : "slideLeftToogle"}`}>
                        <span>On</span>
                        <div className="toggleSwitchBall"></div>
                        <span>Off</span>
                      </div>
                    </div>
                  </label>

                  <label htmlFor="" className='w-100 d-flex justify-content-between'>
                    <span className='mediumTextSize'>Flip Vertical</span>
                    <div className="outerToggleSwitch" onClick={() => { if (imgRef.current) { flipVertical ? setFlipVertical(false) : setFlipVertical(true); } }}>
                      <div className={`frameOfToggleSwitch ${flipVertical ? "slideRightToogle" : "slideLeftToogle"}`}>
                        <span>On</span>
                        <div className="toggleSwitchBall"></div>
                        <span>Off</span>
                      </div>
                    </div>
                  </label>

                  {
                    imgSrc && (
                      <ReactCrop
                        crop={crop}
                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={aspect}
                      >
                        <img
                          ref={imgRef}
                          alt="Crop me"
                          src={imgSrc}
                          style={{ transform: `scale(${scale}) rotate(${rotate}deg) rotateX(${flipVertical ? "180deg" : "0deg"}) rotateY(${flipHorizontal ? "180deg" : "0deg"})`, width: "100%" }}
                          onLoad={onImageLoad}
                        />
                      </ReactCrop>
                    )


                  }
                  {
                    completedCrop && (
                      <div className=''>
                        <button type='submit' id="profileUpdateButton" disabled={!imgSrc} className='btn btn-sm salesforce-style border-0 mediumTextSize rounded-pill w-100 d-flex align-items-center justify-content-center gap-2'>Set Image</button>
                        {/* <button className='btn btn-primary btn-sm rounded-pill w-100 mt-1' onClick={onDownloadCropClick}>Crop Download</button> */}
                        {/* <a
                                        ref={hiddenAnchorRef}
                                        download
                                        style={{
                                            position: 'absolute',
                                            top: '0vh',
                                            visibility: '',
                                        }}
                                    >
                                        Hidden download
                                    </a> */}
                      </div>
                    )
                  }

                </form>
              </div>
              <div className="col-12 col-md-6 col-sm-6 col-lg-6 wh-100 d-flex align-items-center">
                <div className='cropperPanels border w-100 h-100 d-flex align-items-center'>
                  {
                    completedCrop === null && imageSizeError === null && (
                      <div className='w-100 d-flex flex-column align-items-center gap-2'>
                        <div className='alert alert-secondary rounded-0 text-center p-0 m-0 w-100'>Current Picture</div>
                        <img
                          src={currentGlobalProfile}
                          style={{
                            // border: '1px solid black',
                            objectFit: 'contain',
                            // width: completedCrop.width,
                            // height: completedCrop.height,
                            width: "100%",
                            height: "100%",
                          }}
                        />
                        {
                          globalProfileType !== "deleted" ?
                            <button type='button' className='btn btn-sm btn-danger' onClick={() => { deleteProfilePicture(); }}>Delete <i className='fa fa-trash'></i></button>
                            :
                            ""
                        }
                      </div>
                    )
                  }

                  {
                    completedCrop && imageSizeError === null && (
                      <>
                        <div className='w-100'>
                          <div className='alert alert-secondary rounded-0 text-center p-0 m-0'>Preview</div>
                          <canvas
                            ref={previewCanvasRef}
                            style={{
                              // border: '1px solid black',
                              objectFit: 'contain',
                              // width: completedCrop.width,
                              // height: completedCrop.height,
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </div>
                      </>
                    )
                  }
                  {
                    imageSizeError != null ?
                      <div className='alert alert-secondary'>
                        {imageSizeError}
                      </div>
                      :
                      ""
                  }
                </div>
              </div>
            </div>
          </div>



          {/* setCurrentGlobalProfile */}
          {/* 
          <ImageUploading
            multiple={true}
            value={images}
            onChange={updateProfileImage}
            maxNumber={maxNumber}
            dataURLKey="data_url"
            acceptType={accepedFileTypes}
            maxFileSize={maxfileSize}
            resolutionType={"absolute"}
            // resolutionWidth={5000}
            // resolutionHeight={4000}
            allowNonImageType={false}
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
              errors,
            }) => (
              <div className="mt-2">
                <div className="d-flex justify-content-center">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 d-flex justify-content-center">
                        {
                          imageList.map((image, index) => (
                            index === imageList.length - 1 ?
                              <div key={index} className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row  justify-content-center align-items-center gap-3" style={{ maxWidth: "max-content" }}>
                                <img src={currentGlobalProfile} className="rounded-circle profilePicture w-75" alt="Edit Profile Picture" style={{ maxWidth: "200px" }} />
                                <div className='d-flex flex-row flex-sm-column flex-md-column flex-lg-column flex-wrap justify-content-center align-items-center py-3 gap-3'>
                                  <button type='button' className='btn salesforce-style rounded-pill  d-flex align-items-center justify-content-center gap-2 ' style={{ maxWidth: "40px", aspectRatio: "1" }} onClick={() => onImageUpdate(index)}>
                                    <EditIcon />
                                  </button>
                                  <button type='button' className='btn btn-danger rounded-pill d-flex align-items-center justify-content-center' style={{ maxWidth: "40px", aspectRatio: "1" }} onClick={() => { onImageRemoveAll(); setImages([defaultImage]); setCurrentGlobalProfile(defaultImage.data_url) }}>
                                    <i className='fa fa-trash cursor-pointer'></i>
                                  </button>
                                </div>
                              </div>
                              :
                              ""
                          ))
                        }
                      </div>
                      <div className="col-12 col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center">
                        <button type='button' className={`btn d-none d-lg-block d-md-block d-sm-block btn-light border ${"w-100"}`} style={isDragging ? { color: 'red' } : undefined} onClick={onImageUpload} {...dragProps}>Click or Drop here</button>
                      </div> 
                    </div>
                  </div>
                </div>
                <div className="px-0 ">
                   <button className='btn btn-danger' onClick={onImageRemoveAll}>Remove all</button>
                  {
                    ({ imageList, dragProps, isDragging }) => (
                      <div {...dragProps}>
                        {isDragging ? "Drop here please" : "Upload space"}
                        {imageList.map((image, index) => (
                          <img key={index} src={image.data_url} alt="Image Uploaded" />
                        ))}
                      </div>
                    )
                  }

                  {
                    errors && <div>
                      {errors.maxNumber && <span className='alert d-block mt-4 rounded-0 alert-danger'>Number of selected images exceed maxNumber</span>}
                      {errors.acceptType && <span className='alert d-block mt-4 rounded-0 alert-danger'>Only {accepedFileTypes.map((type, key) => { return <span key={key}>{type.toUpperCase() + ", "}</span> })} are allowed.</span>}
                      {errors.maxFileSize && <span className='alert d-block mt-4 rounded-0 alert-danger'>Maximum filesize is {maxfileSize / 1024000} Megabytes</span>}
                      {errors.resolution && <span className='alert d-block mt-4 rounded-0 alert-danger'>Selected file is not match your desired resolution</span>}
                    </div>
                  }


                  <div className='d-flex flex-wrap gap-3 align-items-center justify-content-center mt-4'>
                    <button className='btn btn-sm btn-secondary rounded-pill' onClick={() => { console.log(defaultImage); setViewImageUploader(false); setImages([defaultImage]); setCurrentGlobalProfile(defaultImage.data_url) }}>Cancel</button>
                    <button className='btn btn-sm salesforce-style rounded-pill px-4' onClick={() => { setViewImageUploader(false); }} >Finish</button>
                  </div>

                </div>
              </div>
            )}
          </ImageUploading > */}

        </Modal.Body>
      </Modal>
      {/* ############################### Update Profile Picture Panel End ############################# */}

      {/* ############################### Update Brand Picture Panel ############################# */}
      <Modal
        show={startCropperForBrand}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => { setStartCropperForBrand(false); resetImageCropperSettings(); setImageSizeError(null); }}
      >
        <Modal.Header className="salesforce-style py-2" closeButton closeVariant='white'>
          <Modal.Title id="contained-modal-title-vcenter">
            <span style={{ fontSize: "1rem" }}>Update Brand Picture</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid bg-light p-3 rounded">
            <div className="row">
              <div className="col-12 col-md-6 col-sm-6 col-lg-6">
                <form className='cropperPanels border w-100 h-100 p-2 d-flex flex-column flex-wrap gap-2' onSubmit={(e) => { updateBrandLogo(e); }}>
                  <label htmlFor="fileSelector" className='w-100'>
                    <button className='btn btn-outline-dark w-100 rounded p-1  mediumTextSize' style={{ pointerEvents: "none" }}>Choose file</button>
                    <input id="fileSelector" type="file" required accept="image/png, image/jpeg, image/heif, image/bmp" onChange={onSelectFile} className='d-none' />
                  </label>
                  <label htmlFor="BrandName" className='w-100'>
                    <div className="input-group flex-nowrap">
                      <input type="url" required id='BrandName' className="customInputFieldFocus w-100 mediumTextSize" placeholder="URL.." aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={(e) => { setNewBrandName(e.target.value); }} />
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 100, hide: 200 }}
                        overlay={
                          <Popover id="popover-basic">
                            <Popover.Header className='salesforce-style' as="h3">Test Visit</Popover.Header>
                            <Popover.Body>
                              <p className='m-0 p-0 smallTextSize'>Click to check whether the link is working or not. It will be open in a new tab.</p>
                            </Popover.Body>
                          </Popover>
                        }>
                        <span className="input-group-text" id="basic-addon2" style={{ marginLeft: "0", borderLeft: "none" }}>
                          <a href={newBrandName} target='_Blank'>
                            <TravelExploreIcon className='text-dark cursor-pointer' />
                          </a>
                        </span>
                      </OverlayTrigger>
                    </div>
                  </label>
                  <label htmlFor="" className='w-100'>
                    <span className='mediumTextSize'>Scale</span>
                    <div className='d-flex align-items-center'>
                      <input
                        type="range"
                        className='form-range w-100'
                        min="1"
                        max="10"
                        step="0.1"
                        value={scale}
                        disabled={!imgSrc}
                        onChange={(e) => setScale(Number(e.target.value))}
                      />
                      <input type="text" maxLength={1} className='customInputFieldFocus w-25 pe-none' value={scale} onChange={() => { }} />
                    </div>
                  </label>
                  <label htmlFor="" className='w-100'>
                    <span className='mediumTextSize'>Rotate</span>
                    <div className='d-flex align-items-center'>
                      <input
                        type="range"
                        className='form-range w-100'
                        min="0"
                        max="360"
                        step="1"
                        value={rotate}
                        disabled={!imgSrc}
                        onChange={(e) =>
                          setRotate(Math.min(360, Math.max(-180, Number(e.target.value))))
                        }
                      />
                      <input type="text" maxLength={3} className='customInputFieldFocus w-25 pe-none' value={rotate} onChange={(e) => { handleRotateValue(e) }} />
                    </div>
                  </label>
                  <label htmlFor="" className='w-100 d-flex justify-content-between'>
                    <span className='mediumTextSize'>Aspect</span>
                    <div className="outerToggleSwitch" onClick={handleToggleAspectClick}>
                      <div className={`frameOfToggleSwitch ${aspect && imgRef.current ? "slideRightToogle" : "slideLeftToogle"}`}>
                        <span>On</span>
                        <div className="toggleSwitchBall"></div>
                        <span>Off</span>
                      </div>
                    </div>
                  </label>

                  <label htmlFor="" className='w-100 d-flex justify-content-between'>
                    <span className='mediumTextSize'>Flip Horizontal</span>
                    <div className="outerToggleSwitch" onClick={() => { if (imgRef.current) { flipHorizontal ? setFlipHorizontal(false) : setFlipHorizontal(true) } }}>
                      <div className={`frameOfToggleSwitch ${flipHorizontal ? "slideRightToogle" : "slideLeftToogle"}`}>
                        <span>On</span>
                        <div className="toggleSwitchBall"></div>
                        <span>Off</span>
                      </div>
                    </div>
                  </label>

                  <label htmlFor="" className='w-100 d-flex justify-content-between'>
                    <span className='mediumTextSize'>Flip Vertical</span>
                    <div className="outerToggleSwitch" onClick={() => { if (imgRef.current) { flipVertical ? setFlipVertical(false) : setFlipVertical(true) } }}>
                      <div className={`frameOfToggleSwitch ${flipVertical ? "slideRightToogle" : "slideLeftToogle"}`}>
                        <span>On</span>
                        <div className="toggleSwitchBall"></div>
                        <span>Off</span>
                      </div>
                    </div>
                  </label>

                  {
                    imgSrc && (
                      <ReactCrop
                        crop={crop}
                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={aspect}
                      >
                        <img
                          ref={imgRef}
                          alt="Crop me"
                          src={imgSrc}
                          style={{ transform: `scale(${scale}) rotate(${rotate}deg) rotateX(${flipVertical ? "180deg" : "0deg"}) rotateY(${flipHorizontal ? "180deg" : "0deg"})`, width: "100%" }}
                          onLoad={onImageLoad}
                        />
                      </ReactCrop>
                    )


                  }
                  {
                    completedCrop && (
                      <div className=''>
                        <button type='submit' id="brandsUpdateButton" disabled={!imgSrc} className='btn btn-sm salesforce-style border-0 mediumTextSize rounded-pill w-100 d-flex align-items-center justify-content-center gap-2'>Set Image</button>
                        {/* <button className='btn btn-primary btn-sm rounded-pill w-100 mt-1' onClick={onDownloadCropClick}>Crop Download</button> */}
                        {/* <a
                              ref={hiddenAnchorRef}
                              download
                              style={{
                                  position: 'absolute',
                                  top: '0vh',
                                  visibility: '',
                              }}
                          >
                              Hidden download
                          </a> */}
                      </div>
                    )
                  }

                </form>
              </div>
              <div className="col-12 col-md-6 col-sm-6 col-lg-6 wh-100 d-flex align-items-center">
                <div className='cropperPanels border w-100 h-100 d-flex align-items-center'>
                  {
                    completedCrop && imageSizeError === null && (
                      <>
                        <div className='w-100'>
                          <div className='alert alert-secondary rounded-0 text-center p-0 m-0'>Preview</div>
                          <canvas
                            ref={previewCanvasRef}
                            style={{
                              // border: '1px solid black',
                              objectFit: 'contain',
                              // width: completedCrop.width,
                              // height: completedCrop.height,
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </div>
                      </>
                    )
                  }
                  {
                    imageSizeError != null ?
                      <div className='alert alert-secondary'>
                        {imageSizeError}
                      </div>
                      :
                      ""
                  }
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* ############################### Update Brand Picture Panel End ############################# */}

      {/* ################################# Add Phone Number Panel ############################### */}
      <Modal
        show={phoneAddView}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setPhoneAddView(false);
          setPhoneNumberForOTP("");
          setPhoneAddError(null);
          setPhoneAddingStage(1);
          clearInterval(OTPInterval.current);
          setTimerForOTP(60);
        }}
      >
        <Modal.Header className="salesforce-style py-2" closeButton closeVariant='white'>
          <Modal.Title id="contained-modal-title-vcenter">
            <span style={{ fontSize: "1rem" }}>Add New Phone Number</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            phoneAddingStage === 1 ?
              <form className='w-100' onSubmit={(e) => { handlePhoneAddStage1(e); }}>
                <div className="mt-2 mb-4">
                  <label className='w-100'>
                    <span style={{ fontWeight: "500" }}>Enter phone number:</span>
                    <PhoneInput
                      required
                      className="customInputFieldFocus w-100 py-3 mt-1 phoneNUmberInpurJHSGFUGDSFIY"
                      placeholder="Enter the phone number"
                      onChange={setPhoneNumberForOTP} />
                  </label>
                  {
                    phoneAddError ?
                      <p className='w-100 text-danger'>
                        {
                          phoneAddError
                        }
                      </p>
                      :
                      ""
                  }
                </div>
                <div className="d-flex gap-3 align-items-center justify-content-center">
                  <button type='button' className='btn btn-sm btn-secondary rounded-pill px-3 w-100' style={{ maxWidth: "120px" }} onClick={() => { setPhoneNumberForOTP(""); setPhoneAddError(null); setPhoneAddingStage(1); setPhoneAddView(false) }}>Cancel</button>
                  <button type='submit' className='btn btn-sm salesforce-style rounded-pill px-3 w-100 d-flex align-items-center justify-content-center gap-1' id="sendOTPButton" style={{ maxWidth: "120px" }}>Send OTP</button>
                </div>
              </form>
              :
              phoneAddingStage === 2 ?
                <form className='w-100' onSubmit={(e) => { handlePhoneAddStage2(e); }}>

                  <div className="mt-2 mb-4">
                    <label htmlFor="w-100" className='w-100'>
                      <span style={{ fontWeight: "500" }}>OTP send to <span style={{ fontWeight: "800" }}>{phoneNumberForOTP ? phoneNumberForOTP : ""}</span> : </span>
                      <input className='customInputFieldFocus fw-bold w-100 my-1' type="text" required placeholder='Enter the OTP here' ref={phoneAddOTP} />
                    </label>
                    {
                      phoneAddError ?
                        <div className="alert alert-danger py-2 my-2">
                          {
                            phoneAddError
                          }
                        </div>
                        :
                        ""
                    }
                    {
                      <div className="smallTextSize fw-bold mt-2">
                        {
                          resendOTP ?
                            <button className='btn btn-sm btn-secondary smallTextSize rounded-pill w-100 d-flex align-items-center justify-content-center gap-1' style={{ maxWidth: "120px" }} id="resendOTPButton" onClick={(e) => { resendOTPNow(e); }}>Resend OTP</button>
                            :
                            <p className='text-dark p-0 m-0'>
                              Resend OTP again in {timerForOTP} seconds.
                            </p>
                        }
                      </div>
                    }
                  </div>
                  <div className="d-flex align-items-center justify-content-center gap-3">
                    <button type='button' className='btn btn-sm btn-secondary rounded-pill px-3 w-100' style={{ maxWidth: "120px" }} onClick={() => { setPhoneAddError(null); setPhoneAddingStage(1); clearInterval(OTPInterval.current); phoneAddOTP.current.value = ""; }}>Back</button>
                    <button type='submit' className='btn btn-sm salesforce-style rounded-pill px-3 w-100' style={{ maxWidth: "120px" }}>Verify</button>
                  </div>
                </form>
                :
                phoneAddingStage === 3 ?
                  <>
                    <div className="mt-3 mb-5 ">
                      <h4 className='w-100 text-success h-4 m-0 p-0 py-3 text-center' style={{ fontWeight: "600" }}>Your new phone number is added successfully.</h4>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      <button type='submit' className='btn btn-sm salesforce-style rounded-pill px-3 w-100' style={{ maxWidth: "120px" }} onClick={() => { setPhoneNumberForOTP(""); setPhoneAddView(false); setPhoneAddingStage(1); phoneAddOTP.current = ""; }}>Finish</button>
                    </div>
                  </>
                  :
                  <form className='w-100' onSubmit={(e) => { handlePhoneAddStage1(e); }}>
                    <div className='card w-100' style={{ background: "none", border: "none" }}>
                      <div className="card-header">
                        <h5 className='text-center w-100 m-0 p-0 py-3 h-5'>Add New Phone Number</h5>
                      </div>
                      <div className="card-body">
                        <label className='w-100'>
                          <span>Enter phone number:</span>
                          <PhoneInput
                            required
                            className="customInputFieldFocus w-100 py-3 mt-1 phoneNUmberInpurJHSGFUGDSFIY"
                            placeholder="Enter the phone number"
                            onChange={setPhoneNumberForOTP} />
                        </label>
                        {
                          phoneAddError ?
                            <p className='w-100 text-danger'>
                              {
                                phoneAddError
                              }
                            </p>
                            :
                            ""
                        }
                      </div>
                      <div className="card-footer d-flex gap-3 align-items-center justify-content-center">
                        <button type='button' className='btn btn-sm btn-secondary rounded-pill px-3 w-100' style={{ maxWidth: "120px" }} onClick={() => { setPhoneNumberForOTP(""); setPhoneAddError(null); setPhoneAddingStage(1); }}>Cancel</button>
                        <button type='submit' className='btn btn-sm salesforce-style rounded-pill px-3 w-100 d-flex align-items-center justify-content-center gap-1' id="sendOTPButton" style={{ maxWidth: "120px" }}>Send OTP</button>
                      </div>
                    </div>
                  </form>

          }
        </Modal.Body>
      </Modal>
      {/* ############################### Add Phone Number Panel End ############################# */}

      {/* ################################# Add People Panel ############################### */}
      <Modal
        show={addNewMember}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => { setAddNewMember(false); setAddMemberMessage(false); }}
      >
        <Modal.Header className="salesforce-style py-2" closeButton closeVariant='white'>
          <Modal.Title id="contained-modal-title-vcenter">
            <span style={{ fontSize: "1rem" }}>Add New Member</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className='w-100' onSubmit={(e) => { addNewMemberToPeople(e); }}>
            <div className="container-fluid">
              <div className="row my-3">
                <div className="col-12">
                  <label className='w-100 text-start fw-bold d-flex flex-column gap-1'>
                    <span style={{ fontWeight: "600" }}>Enter the email of member</span>
                    <input type="email" className='customInputFieldFocus w-100  border' required placeholder='someone@email.com' ref={newPeopleEmail} />
                  </label>
                </div>
              </div>
              <div className="row mb-4">
                <div className={`col-12 ${addMemberMessage ? "" : "d-none"}`}>
                  <p className='alert alert-danger text-center my-3'>{addMemberMessage}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex gap-3 justify-content-center">
                  <input type="reset" style={{ width: "100%", maxWidthL: "120px" }} className='btn btn-sm btn-secondary w-100 rounded-pill ' value="Cancel" onClick={() => { setAddNewMember(false); setAddMemberMessage(false); newPeopleEmail.current.value = "" }} />
                  <input type="submit" style={{ width: "100%", maxWidthL: "120px" }} className='btn btn-sm salesforce-style w-100 rounded-pill ' value="Add" />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* ############################### Add People Panel End ############################# */}

      {/* ################################# Create New Schedule Panel ############################### */}
      <Modal
        show={createNewSchedule}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => { setCreateNewSchedule(false); setNewScheduleName(""); }}
      >
        <Modal.Header className="salesforce-style py-2" closeButton closeVariant='white'>
          <Modal.Title id="contained-modal-title-vcenter">
            <span style={{ fontSize: "1rem" }}>New schedule</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <form className='w-100' onSubmit={(e) => { updateNewSchedule(e, "model"); }}> */}
          <form className='w-100' onSubmit={(e) => { creatingNewDefaultSchedule(e); }}>
            <div className="container-fluid">
              <div className="row mb-3">
                <div className="col-12">
                  <label className='w-100'>
                    <p className='p-0 my-2 fw-bold'>Schedule name</p>
                    <input type="text" maxLength={30} className='customInputFieldFocus w-100 mb-2' required placeholder='Working Hours, Holidays, etc...' value={newScheduleName} onChange={(e) => { validateAndSetScheduleName(e); setScheduleNameCounter(true); }} />
                  </label>
                </div>
                <div className={`col-12 d-flex justify-content-between align-items-center}`}>
                  <p className='text-dark text-start smallTextSize'>Max length 30 chars.</p>
                  {
                    scheduleNameCounter
                      ?
                      <span className='smallTextSize'>{30 - newScheduleName.length} / 30</span>
                      :
                      <span className='smallTextSize'>30 / 30</span>
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex gap-3 justify-content-center align-items-center">
                  <input
                    type="reset"
                    style={{ maxWidth: "120px", width: "100%" }}
                    className='btn btn-sm btn-secondary w-100 rounded-pill'
                    value="Cancel"
                    onClick={() => {
                      setCreateNewSchedule(false)
                      setNewScheduleName("");
                    }} />
                  <input type="submit" style={{ maxWidth: "120px", width: "100%" }} className='btn btn-sm salesforce-style w-100 rounded-pill ' value="Add" />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        {/* <Modal.Footer>

        </Modal.Footer> */}
      </Modal>
      {/* ############################### Create New Schedule Panel End ############################# */}

      {/* ################################## COPY TIMES TO ############################# */}
      <Modal
        show={viewFromSunday || viewFromMonday || viewFromTuesday || viewFromWednesday || viewFromThursday || viewFromFriday || viewFromSaturday}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setViewFromSunday(false);
          setViewFromMonday(false);
          setViewFromTuesday(false);
          setViewFromWednesday(false);
          setViewFromThursday(false);
          setViewFromFriday(false);
          setViewFromSaturday(false);
        }
        }
      >
        <Modal.Header className="salesforce-style py-2" closeButton closeVariant="white">
          <Modal.Title id="contained-modal-title-vcenter">
            <span style={{ fontSize: "1rem" }}>Copy times to</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={`copyDays`}>
            <div className="daysLabel">
              <span>
                Sunday
              </span>
              {
                viewFromSunday ?
                  <button className={`default`} title="This day is selected to copy times slotes.">
                  </button>
                  :
                  <button onClick={(e) => { setSundayCopy(!sundayCopy); }} className={sundayCopy ? "enabled" : "disabled"}>
                  </button>
              }
            </div>
            <div className="daysLabel">
              <span>
                Monday
              </span>
              {
                viewFromMonday ?
                  <button className={`default`} title="This day is selected to copy times slotes.">
                  </button>
                  :
                  <button onClick={(e) => { setMondayCopy(!mondayCopy); }} className={mondayCopy ? "enabled" : "disabled"}>
                  </button>
              }

            </div>
            <div className="daysLabel">
              <span>
                Tuesday
              </span>
              {
                viewFromTuesday ?
                  <button className={`default`} title="This day is selected to copy times slotes.">
                  </button>
                  :
                  <button onClick={(e) => { setTuesdayCopy(!tuesdayCopy); }} className={tuesdayCopy ? "enabled" : "disabled"}>
                  </button>
              }

            </div>
            <div className="daysLabel">
              <span>
                Wednesday
              </span>
              {
                viewFromWednesday ?
                  <button className={`default`} title="This day is selected to copy times slotes.">
                  </button>
                  :
                  <button onClick={(e) => { setWednesdayCopy(!wednesdayCopy); }} className={wednesdayCopy ? "enabled" : "disabled"}>
                  </button>
              }
            </div>
            <div className="daysLabel">
              <span>
                Thursday
              </span>
              {
                viewFromThursday ?
                  <button className={`default`} title="This day is selected to copy times slotes.">
                  </button>
                  :
                  <button onClick={(e) => { setThursdayCopy(!thursdayCopy); }} className={thursdayCopy ? "enabled" : "disabled"}>
                  </button>
              }
            </div>
            <div className="daysLabel">
              <span>
                Friday
              </span>
              {
                viewFromFriday ?
                  <button className={`default`} title="This day is selected to copy times slotes.">
                  </button>
                  :
                  <button onClick={(e) => { setFridayCopy(!fridayCopy); }} className={fridayCopy ? "enabled" : "disabled"}>
                  </button>
              }
            </div>
            <div className="daysLabel">
              <span>
                Saturday
              </span>
              {
                viewFromSaturday ?
                  <button className={`default`} title="This day is selected to copy times slotes.">
                  </button>
                  :
                  <button onClick={(e) => { setSaturdayCopy(!saturdayCopy); }} className={saturdayCopy ? "enabled" : "disabled"}>
                  </button>
              }
            </div>
            <div className='mt-4 d-flex justify-content-center'>
              {
                viewFromSunday ?
                  <button className="btn  salesforce-style rounded-pill w-100 applyButtonCopyTimes" onClick={() => {
                    copyTimesNow("sunday", copyTimeFromFrame);
                    setViewFromSunday(false);
                  }}>Apply</button>
                  :
                  viewFromMonday ?
                    <button className="btn  salesforce-style rounded-pill w-100 applyButtonCopyTimes" onClick={() => {
                      copyTimesNow("monday", copyTimeFromFrame);
                      setViewFromMonday(false);
                    }}>Apply</button>
                    :
                    viewFromTuesday ?
                      <button className="btn  salesforce-style rounded-pill w-100 applyButtonCopyTimes" onClick={() => {
                        copyTimesNow("tuesday", copyTimeFromFrame);
                        setViewFromTuesday(false);
                      }}>Apply</button>
                      :
                      viewFromWednesday ?
                        <button className="btn  salesforce-style rounded-pill w-100 applyButtonCopyTimes" onClick={() => {
                          copyTimesNow("wednesday", copyTimeFromFrame);
                          setViewFromWednesday(false);
                        }}>Apply</button>
                        :
                        viewFromThursday ?
                          <button className="btn  salesforce-style rounded-pill w-100 applyButtonCopyTimes" onClick={() => {
                            copyTimesNow("thursday", copyTimeFromFrame);
                            setViewFromThursday(false);
                          }}>Apply</button>
                          :
                          viewFromFriday ?
                            <button className="btn  salesforce-style rounded-pill w-100 applyButtonCopyTimes" onClick={() => {
                              copyTimesNow("friday", copyTimeFromFrame);
                              setViewFromFriday(false);
                            }}>Apply</button>
                            :
                            viewFromSaturday ?
                              <button className="btn salesforce-style rounded-pill w-100 applyButtonCopyTimes" onClick={() => {
                                copyTimesNow("saturday", copyTimeFromFrame);
                                setViewFromSaturday(false);
                              }}>Apply</button>
                              :
                              ""


              }
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* ################################ COPY TIMES TO END ################################### */}

      {/* ################################ add date override ################################### */}
      <Modal
        show={viewDateOverridePanel}
        onHide={() => { setViewDateOverridePanel(false); setMonthIndexCurrent(dayjs().month()); setOverrideDate(null); setCurrntOverrideDateSelected(null); }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="salesforce-style py-2" closeButton closeVariant="white">
          <Modal.Title id="contained-modal-title-vcenter">
            <span style={{ fontSize: "1rem" }}>ADD DATE OVERRIDE</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => { startAddingTheOverride(e); }} className="w-100 p-0 m-0">
            <div className='container-fluid'>
              <div className="row">
                <div className="col-12 p-0 fw-bold">
                  {/* <div className='d-flex align-items-center pt-3 pb-3 justify-content-between'>
                    
                    <button type='button' disabled={monthIndexCurrent <= minMonthIndex} onClick={() => { setMonthIndexCurrent(monthIndexCurrent - 1); }} className={`${monthIndexCurrent <= minMonthIndex ? "cursor-not-allowed" : "cursor-pointer"} salesforce-style circleBbutton p-1 text-dark bg-light rounded-pill fw-bold`}>
                      <ArrowBackIcon />
                    </button>
                    <span className='monthAndDate'>
                      {dayjs(new Date(dayjs().year(), monthIndexCurrent)).format("MMMM YYYY")}
                    </span>
                    <button type='button' onClick={() => { setMonthIndexCurrent(monthIndexCurrent + 1); }} className={`cursor-pointer salesforce-style circleBbutton p-1 text-dark bg-light rounded-pill fw-bold`}>
                      <ArrowForwardIcon />
                    </button>
                  </div>
                  <div className="d-flex justify-content-center w-100 transparentCalendar text-dark" onClick={(e) => { putOverrideDateToStart(e); }}>
                    <Month month={currentMonth} nextMonth={nextMonth} selectedDayTab={currntOverrideDateSelected} />
                  </div> */}

                  <div className="d-flex justify-content-center w-100 transparentCalendar text-dark">
                    <Calendar
                      showSelectionPreview={true}
                      shownDate={new Date()}
                      minDate={new Date()}
                      maxDate={undefined}
                      color='#0b5cab'
                      date={overrideDate}
                      onChange={(e) => { setOverrideDate(e); }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={`col-12 ${overrideDate ? "" : "d-none"}`}>
                  <hr />
                  <div className='d-flex align-items-center justify-content-between'>
                    <span>
                      <b>{dayjs(overrideDate).format("ddd, D MMM YYYY")}</b>
                    </span>
                    <div className="outerToggleSwitch" onClick={() => { setOverrideDateEnabled(!overrideDateEnabled); checkOverridesTimesCorrect(); }}>
                      <div className={`frameOfToggleSwitch ${overrideDateEnabled === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                        <span>On</span>
                        <div className="toggleSwitchBall"></div>
                        <span>Off</span>
                      </div>
                    </div>
                  </div>

                  {
                    existingOverride && overrideDateEnabled ?

                      <div className='container-fluid mt-3'>
                        <div className="row">
                          <div className="col-8 p-0 " id="overrideMainFrameModelAdmin">
                            {
                              overrideDate ?
                                dateOverrides[overrideDate.toISOString()] ?
                                  dateOverrides[overrideDate.toISOString()].timeSet ?
                                    dateOverrides[overrideDate.toISOString()].timeSet.length > 0 ?
                                      dateOverrides[overrideDate.toISOString()].timeSet.map((times, key) => {
                                        let uid = uuidv4();
                                        return (
                                          <div className='d-flex align-items-center gap-2' key={key} id={`overrideframe_${uid}`}>
                                            <div className='w-50 h-100'>
                                              <input list={`overrideStart_${uid}`} maxLength={7} required defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${times.split("-")[0].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} name="activeOn" className='customInputFieldFocus w-100 smallTextNote shadow-sm overrideStartTabs' />
                                              <datalist id={`overrideStart_${uid}`} className='smallTextNote'>
                                                {
                                                  timeListToMeasure.map((times, key) => {
                                                    return (
                                                      <option key={key} value={times}>{times}</option>
                                                    )
                                                  })
                                                }
                                              </datalist>
                                            </div>
                                            <div className='w-50 h-100 '>
                                              <input list={`overrideEnd_${uid}`} maxLength={7} required defaultValue={timeFormat.value !== "12 Hours" ? dayjs(`2 November 1999 ${times.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("HH:mm") : dayjs(`2 November 1999 ${times.split("-")[1].replaceAll("am", " am").replaceAll("pm", " pm")}`).format("h:mma")} name="activeOn" className='customInputFieldFocus w-100 smallTextNote shadow-sm overrideEndTabs' />
                                              <datalist id={`overrideEnd_${uid}`} className='smallTextNote'>
                                                {
                                                  timeListToMeasure.map((times, key) => {
                                                    return (
                                                      <option key={key} value={times}>{times}</option>
                                                    )
                                                  })
                                                }
                                              </datalist>
                                            </div>
                                            <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSetOverride("override", `overrideframe_${uid}`); }} />
                                          </div>
                                        )
                                      })
                                      :
                                      <div className='d-flex align-items-center gap-2 ' id="overrideframe_one">
                                        <div className='w-50 h-100'>
                                          <input list="overrideStart" maxLength={7} required defaultValue={timeFormat.value !== "12 Hours" ? "09:00" : "9:00am"} onInput={(e) => { filterTimeFieldNow(e); checkOverridesTimesCorrect() }} name="activeOn" className='customInputFieldFocus w-100 smallTextNote overrideStartTabs' />
                                          <datalist id="overrideStart" className='smallTextNote'>
                                            {
                                              timeListToMeasure.map((times, key) => {
                                                return (
                                                  <option key={key} value={times}>{times}</option>
                                                )
                                              })
                                            }
                                          </datalist>
                                        </div>
                                        <div className='w-50 h-100'>
                                          <input list="overrideEnd" maxLength={7} required defaultValue={timeFormat.value !== "12 Hours" ? "17:00" : "5:00pm"} onInput={(e) => { filterTimeFieldNow(e); checkOverridesTimesCorrect() }} name="activeOn" className='customInputFieldFocus w-100 smallTextNote overrideEndTabs' />
                                          <datalist id="overrideEnd" className='smallTextNote'>
                                            {
                                              timeListToMeasure.map((times, key) => {
                                                return (
                                                  <option key={key} value={times}>{times}</option>
                                                )
                                              })
                                            }
                                          </datalist>
                                        </div>
                                        <i className='fa fa-trash' onClick={() => { deleteTimeSetOverride("override", "overrideframe_one"); }}></i>
                                      </div>
                                    :
                                    ""
                                  :
                                  ""
                                :
                                ""
                            }

                          </div>
                          <div className="col-4 p-2">
                            <div className='d-flex align-items-start justify-content-end w-100 h-100'>
                              <AddIcon onClick={(e) => { addTimeSetInOverride("override", "ModelAdmin", uuidv4()); }} />
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      existingOverride === false && overrideDateEnabled ?
                        <div className='container-fluid mt-3'>
                          <div className="row">
                            <div className="col-8 p-0" id="overrideMainFrameModelAdmin">
                              <div className='d-flex align-items-center gap-2 ' id="overrideframe_one">
                                <div className='w-50 h-100'>
                                  <input list="overrideStart" maxLength={7} required defaultValue={timeFormat.value !== "12 Hours" ? "09:00" : "9:00am"} onInput={(e) => { filterTimeFieldNow(e); checkOverridesTimesCorrect() }} name="activeOn" className='customInputFieldFocus w-100 smallTextNote overrideStartTabs' />
                                  <datalist id="overrideStart" className='smallTextNote'>
                                    {
                                      timeListToMeasure.map((times, key) => {
                                        return (
                                          <option key={key} value={times}>{times}</option>
                                        )
                                      })
                                    }
                                  </datalist>
                                </div>
                                <div className='w-50 h-100'>
                                  <input list="overrideEnd" maxLength={7} required defaultValue={timeFormat.value !== "12 Hours" ? "17:00" : "5:00pm"} onInput={(e) => { filterTimeFieldNow(e); checkOverridesTimesCorrect() }} name="activeOn" className='customInputFieldFocus w-100 smallTextNote overrideEndTabs' />
                                  <datalist id="overrideEnd" className='smallTextNote'>
                                    {
                                      timeListToMeasure.map((times, key) => {
                                        return (
                                          <option key={key} value={times}>{times}</option>
                                        )
                                      })
                                    }
                                  </datalist>
                                </div>
                                <i className='fa fa-trash' onClick={() => { deleteTimeSetOverride("override", "overrideframe_one"); }}></i>
                              </div>
                            </div>
                            <div className='col-4 p-2'>
                              <div className='d-flex align-items-start justify-content-end w-100 h-100'>
                                <AddIcon onClick={(e) => { addTimeSetInOverride("override", "ModelAdmin", uuidv4()); }} />
                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        <span className='smallTextNote'>Unavailable</span>
                  }
                </div>
              </div>
              {
                overrideDate ?

                  <div className="row ">
                    <div className="col-12 p-0">
                      <hr />
                      <div className='d-flex justify-content-center align-items-center gap-3'>
                        <button type='button' style={{ maxWidth: "120px", width: "100%" }} className='btn btn-sm btn-secondary text-center rounded-pill my-1' onClick={() => {
                          setViewDateOverridePanel(false);
                          setOverrideDate(null);
                          setOverrideDateEnabled(false);
                          setOverrideStartTime(null);
                          setOverrideEndTime(null);

                          setMonthIndexCurrent(dayjs().month());
                          setCurrntOverrideDateSelected(null);
                        }}>Cancel</button>
                        <button type="submit" style={{ maxWidth: "120px", width: "100%" }} className='btn btn-sm text-center rounded-pill my-1 salesforce-style' disabled={overrideDateEnabled === false ? false : overrideButtonEnabled ? false : true}>Add</button>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="row">
                    <div className="col-12 p-0">
                      <hr />
                      <p className='my-3 text-center'>
                        Select a Date to Override
                      </p>
                      <div className='d-flex justify-content-center align-items-center gap-3'>
                        <button type='button' style={{ maxWidth: "120px", width: "100%" }} className='btn btn-sm btn-secondary text-center rounded-pill my-1' onClick={() => {
                          setViewDateOverridePanel(false);
                          setOverrideDate(null);
                          setOverrideDateEnabled(false);
                          setOverrideStartTime(null);
                          setOverrideEndTime(null);

                          setMonthIndexCurrent(dayjs().month());
                          setCurrntOverrideDateSelected(null);
                        }}>Cancel</button>
                      </div>
                    </div>
                  </div>
              }

            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* ################################ add date override ################################### */}

      {/* ############################# Calendar Selection Panel ############################### */}
      <Modal
        show={showCalendarList}
        onHide={() => { setShowCalendarList(false) }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="salesforce-style py-2" closeButton closeVariant='white'>
          <Modal.Title id="contained-modal-title-vcenter">
            <span style={{ fontSize: "1rem" }}>Which calendar should we add new events to?</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-soul'>
          {
            myRefreshTokens ?
              Object.keys(myRefreshTokens).map((elem, key) => {
                return (
                  <React.Fragment key={key}>
                    <div className="bg-body d-flex align-items-center justify-content-between mb-3 border p-2 rounded" >
                      <div className='d-flex align-items-center justify-content-start gap-2'>
                        <div className='d-flex align-items-center justify-content-center'>
                          <img style={{ width: "40px" }} className="rounded-circle" src={myRefreshTokens[elem].image} alt='Calender Connection' />
                        </div>
                        <div className='d-flex flex-column align-items-start justify-content-center'>
                          <span className='text-muted'>{myRefreshTokens[elem].accountType.includes("google") ? "Google" : "Microsoft"}</span>
                          <span>
                            {myRefreshTokens[elem].email}
                          </span>
                        </div>
                      </div>
                      <button type='button' className='btn d-flex align-items-center justify-content-center gap-2 text-primary p-0 m-0' onClick={(e) => { switchCalendar(myRefreshTokens[elem].email) }}>
                        <i className='fa fa-plug'></i>
                        <span>Select</span>
                      </button>
                    </div>
                  </React.Fragment>
                )
              })
              :
              ""
          }
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-secondary px-3 py-2' onClick={(e) => { setShowCalendarList(false); }}>Cancel</button>
          {/* <button className='btn salesforce-style px-3 py-2' onClick={(e) => { setShowCalendarList(false); }}>Done</button> */}
        </Modal.Footer>
      </Modal>
      {/* ########################### Calendar Selection Panel End ############################# */}

      {/* ############################### DATE RANGE SELECTOR ################################## */}
      <Modal
        show={showDateRangeSelector}
        onHide={() => { setShowDateRangeSelector(false) }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="salesforce-style py-2" closeButton closeVariant='white'>
          <Modal.Title id="contained-modal-title-vcenter">
            <span style={{ fontSize: "1rem" }}>Select Date Range</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-soul text-center'>
          <div className={`w-100 overflow-auto`}>
            <DateRangePicker
              // editableDateInputs={true}
              onChange={(item) => { setBetweenDays([item.selection]); }}
              // showSelectionPreview={true}
              // moveRangeOnFirstSelection={false}
              months={2}
              minDate={new Date()}
              ranges={betweenDays}
              direction="horizontal"
              editableDateInputs={true}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn salesforce-style' onClick={(e) => { setShowDateRangeSelector(false); }}>Confirm</button>
          {/* <button className='btn salesforce-style px-3 py-2' onClick={(e) => { setShowCalendarList(false); }}>Done</button> */}
        </Modal.Footer>
      </Modal>
      {/* ############################# DATE RANGE SELECTOR END ################################ */}



      {/* ############################### DATE RANGE SELECTOR ################################## */}
      <Modal
        show={askForDeleteMember}
        onHide={() => { setAskForDeleteMember(false); setDeleteMemberEmail(false); }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="salesforce-style py-2" closeButton closeVariant='white'>
          <Modal.Title id="contained-modal-title-vcenter">
            <span style={{ fontSize: "1rem" }}>Delete Member</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-soul text-center'>
          <div className="alert alert-info m-0">
            <p className="text-start">After deleting members from your account, any future <b>Round Robin</b> events may not be done due to lack of additional hosts.</p>
            <hr />
            <p className='m-0'>Do you want to delete <b>{deleteMemberEmail}</b>?</p>
          </div>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-center align-items-center'>
          <button style={{ maxWidth: "120px" }} className='btn btn-sm btn-secondary rounded-pill w-100' onClick={(e) => { setAskForDeleteMember(false); setDeleteMemberEmail(false); }}>Cancel</button>
          <button style={{ maxWidth: "120px" }} className='btn btn-sm salesforce-style rounded-pill w-100' id="deletingMembersRightNow" onClick={(e) => { deletePersonFromTheList(deleteMemberEmail); }}>Confirm</button>
        </Modal.Footer>
      </Modal>
      {/* ############################# DATE RANGE SELECTOR END ################################ */}


      {/* ############################### SHARE LINK END ################################## */}
      <Modal
        show={showShareYourEventPanel}
        onHide={() => { completeLinkSharing(); }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="salesforce-style py-2" closeButton closeVariant='white'>
          <Modal.Title id="contained-modal-title-vcenter">
            <span style={{ fontSize: "1rem" }}>Sharing Event</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-soul text-center'>
          <div className='alert alert-success d-flex gap-1 justify-content-center align-items-center'>
            <i className='fa fa-check fs-3'></i>
            <span className='mediumTextSize fw-bold'>
              Your event has been created successfully
            </span>
          </div>
          <Tabs
            id="uncontrolled-tab-example"
            className="glitchedNavPils"
            activeKey={sharingViewOf ? sharingViewOf : "LinkCopy"}
          >
            <Tab eventKey="LinkCopy" title={<div className='h-100 glitchedPanTabsLinks color-salesforce mediumTextSize' style={{ fontWeight: "500" }} onClick={() => { setSharingViewOf("LinkCopy"); }}>Share link</div>}>
              <div className='bg-body w-100 p-3'>
                <p className='mediumTextSize py-3 text-start'>Copy and paste your scheduling link into a message</p>
                <div className="input-group w-100 d-flex flex-nowrap rounded">
                  <input type="text" disabled maxLength={50} id="UID" required className='customInputFieldFocus mediumTextSize w-100 border px-2' placeholder={"https://" + process.env.REACT_APP_DOMAINNAME + "/" + username + "/" + customEventLink} value={"https://" + process.env.REACT_APP_DOMAINNAME + "/" + username + "/" + customEventLink} />
                  <div className="input-group-append">
                    <span className="input-group-text btn salesforce-style h-100 border-0 mediumTextSize" style={{ borderRadius: "0px 0.375rem 0.375rem 0px" }} onClick={
                      (e) => {
                        navigator.clipboard.writeText("https://" + process.env.REACT_APP_DOMAINNAME + "/" + username + "/" + customEventLink);
                        e.target.innerHTML = "<span class='d-flex align-items-center gap-1'><i class='fa fa-check text-light'></i>Copied</span>";
                        e.target.classList.remove("salesforce-style");
                        e.target.classList.add("btn-success");
                        setTimeout(() => {
                          e.target.innerHTML = "Copy Link";
                          e.target.classList.remove("btn-success");
                          e.target.classList.add("salesforce-style");
                        }, 2000);
                      }}>
                      Copy Link
                    </span>
                  </div>
                </div>
              </div>
            </Tab>
            {/* <Tab eventKey="CopyToEmail" title={<div className='h-100 glitchedPanTabsLinks color-salesforce mediumTextSize' style={{ fontWeight: "500" }} onClick={() => { setSharingViewOf("CopyToEmail"); }}>Copy times to email</div>}>
            </Tab>
            <Tab eventKey="EmbedToSite" title={<div className='h-100 glitchedPanTabsLinks color-salesforce mediumTextSize' style={{ fontWeight: "500" }} onClick={() => { setSharingViewOf("EmbedToSite"); }}>Add to website</div>}>
            </Tab> */}
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-sm salesforce-style w-100' style={{ maxWidth: "120px" }} onClick={(e) => { completeLinkSharing(); }}>Done</button>
        </Modal.Footer>
      </Modal>
      {/* ############################# SHARE LINK END ################################ */}

      <div style={{ position: "fixed", bottom: "10px", right: "10px", zIndex: "99999999" }}>


        <Toast onClose={() => setViewToast(false)} show={viewToast} delay={3000} autohide className='overflow-hidden'>
          <Toast.Header>
            {/* <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2 bg-primary"
              alt="toastImage"
            /> */}
            <span style={{ fontWeight: "500" }} className={`me-auto ${toastMessageType === "invalid" ? "text-danger" : "text-success"}`}>{toastTitle}</span>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body style={toastMessageType === "success" ? { fontWeight: "500", background: "#d1e7dd", color: "#0f5132" } : { fontWeight: "500", background: "#f8d7da", color: "#842029" }}>
            {toastBody}

          </Toast.Body>
        </Toast>
      </div>

    </>
    // )
    // :
    // <div>
    //   <button onClick={() => { setNeedAccountSetup(false); }}>change</button>
    // </div>
  )
}

export default Home