import React from 'react';
import ProfessionalServicesPng from "../../images/Professional Services.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';

const ProfessionalServices = () => {
    return (
        <>
            <div className="heWhoRemains">
                <Header />
                <div>
                    <section>
                        <div className="container mt-5">
                            <div className="row">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">
                                        <h1 className=" display-4 fw-bold smd_text">Schedule AI for <br />
                                            <span style={{ color: "#0B5ED7" }}> Professional Services
                                            </span>
                                        </h1>
                                        <p className="lead_smd text-black" style={{ fontSize: "1.1em" }}>
                                            If you're running a professional service, chances are good that you need to schedule appointments with your clients. And if you're looking for ways to streamline your business and make things run more smoothly, ScheduleAI software can be a real godsend.
                                        </p>
                                        <a href="/login"><button
                                            className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                                            Demo</button></a>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="w-100" src={ProfessionalServicesPng} alt='Professional Services' />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold text-center">Benefits of Schedule AI for<br /> Professional Services
                                </h2>
                            </div>

                            <ul>
                                <li className='liListDevind'>It can help you keep track of your appointments and make sure that you don't double-book yourself or forget about any important meetings.


                                </li>

                                <li className='liListDevind'>It can also help you keep track of your client's contact information and preferences, which can be really helpful when it comes time to follow up with them.
                                </li>

                                <li className='liListDevind'>It can help you keep track of your appointments and clients. This can be extremely helpful when it comes to managing your time and ensuring that you're meeting all of your obligations.

                                </li>

                                <li className='liListDevind'>The software can also help you manage your finances by tracking payments and invoices.

                                </li>

                                <li className='liListDevind'>
                                    It helps to automate many of the tasks associated with managing your appointments.
                                </li>
                            </ul>

                        </div>
                    </section>

                    <section>
                        <div className="container textDevLay pb-4">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold">Conclusion
                                </h2>
                                <p className='lead_smd text-justify'>Overall, ScheduleAI can be a valuable tool for any professional services business. If you're looking to streamline your operation and improve your bottom line, then this type of system is definitely worth considering.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default ProfessionalServices
