import React from 'react'
import Header from '../header/Header';
import Footer from '../footer/Footer';
import SalesProfilePng from "../../images/sales-profile.png";
import RecruitingProfilePng from "../../images/recruiting-profile.png";
import CustomerSuccessPng from "../../images/customer-success.png";
import marketingProfilePng from "../../images/marketing-profile.png";
import educationPng from "../../images/education.png";
import InformationTechnologyPng from "../../images/Information-technology.png";
import profilePng from "../../images/Profile.png";
import "../../Custom.css";

function Solutions() {
  return (
    <div className='heWhoRemains'>
      <Header />
      <div>
        <div className="container-fluid pt-5 pb-5 d-flex align-items-center">
          <div className="container mt-5" style={{ width: "70%" }}>
            <h6 className="text-center" style={{ letterSpacing: "1px", color: "#0D6EFD" }}>SOLUTIONS</h6>
            <h2 className="display-5 text-center fw-bold" style={{ fontSize: "2.5em", color: "#0A2540" }}>A booking automation
              answer<br /> for <span className="text-primary">each group</span></h2>
            <p className=" text-center pt-2" style={{ fontSize: "1.2em", letterSpacing: "1px" }}>Drive results, meeting by
              meeting. Schedule AI mechanizes <br />booking so groups can zero in on conveying results.</p>
            <div className="cta_btn_smd text-center p-3">
              <a href="/support" className="text-white">
                <button type="button" className="btn btn-primary btn-lg">
                  Contact Us
                </button>
              </a>

            </div>
          </div>
        </div>

        <div className="container-fluid pt-5 pb-5 d-flex align-items-center">
          <div className="container mt-5" style={{ width: "70%" }}>
            <h6 className="text-center" style={{ letterSpacing: "1px", color: "#0D6EFD" }}>Improved on Planning</h6>
            <h2 className="display-5 text-center fw-bold" style={{ fontSize: "2.5em", color: "#0A2540" }}>Schedule AI can settle your
              business <br />challenges</h2>
          </div>
        </div>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                <div className="mt-4 ">
                  <h1 className="display-4 smd_text" style={{ fontSize: "3em" }}>Sales
                  </h1>
                  <p className="text-black lead_smd">Transform booking into an upper hand to accelerate
                    your deals cycle, increment your success rate, and accomplish your income objectives.

                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-6 pt-5">
                <div>
                  <img className="w-100" src={SalesProfilePng} alt="sales-profile" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 pt-5">
                <div>
                  <img className="w-100" src={RecruitingProfilePng} alt="recruiting-profile" />
                </div>
              </div>
              <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                <div className="mt-4 ">
                  <h1 className="display-4 smd_text" style={{ fontSize: "3em" }}>Recruiting
                  </h1>
                  <p className="text-black lead_smd">Fill your ability pipeline quicker, decrease time-to-employ, and
                    smooth out the meeting planning process.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                <div className="mt-4 ">
                  <h1 className="display-4 smd_text" style={{ fontSize: "3em" }}>Customer Success
                  </h1>
                  <p className="text-black lead_smd">Associate with clients at each phase of their excursion to convey
                    extraordinary client support, further develop NPS, and increment your maintenance.

                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-6 pt-5">
                <div>
                  <img className="w-100" src={CustomerSuccessPng} alt="customer success" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 pt-5">
                <div>
                  <img className="w-100" src={marketingProfilePng} alt="marketing-profile" />
                </div>
              </div>
              <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                <div className="mt-4 ">
                  <h1 className="display-4 smd_text" style={{ fontSize: "3em" }}>Marketing
                  </h1>
                  <p className="text-black lead_smd">Convert more leads into gatherings, quicker, when you kill steps
                    that cause client erosion.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                <div className="mt-4 ">
                  <h1 className="display-4 smd_text" style={{ fontSize: "3em" }}>Education
                  </h1>
                  <p className="text-black lead_smd">Control your available time and drive more grounded associations
                    by engaging understudies and guardians to effectively plan arrangements.

                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-6 pt-5">
                <div>
                  <img className="w-100" src={educationPng} alt="education" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 pt-5">
                <div>
                  <img className="w-100" src={InformationTechnologyPng} alt="Information-technology " />
                </div>
              </div>
              <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                <div className="mt-4 ">
                  <h1 className="display-4 smd_text" style={{ fontSize: "3em" }}>Information Technology
                  </h1>
                  <p className="text-black lead_smd">Convert more leads into social affairs, faster, when you kill
                    steps
                    that cause client disintegration.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-light mt-5">
          <div className="container profile-wrapper">
            <div className="row">

              <div className="col-12 col-lg-6 align-items-center d-flex">
                <div className="mt-4 ">
                  <p className="lead_dev text-black" style={{ fontSize: "1.5em" }}>"Schedule AI has expanded gatherings
                    <br />with clients by 216% for <br />our Vonage group."
                  </p>
                  <h4>Chris Williams</h4>
                  <p style={{ fontSize: "1.4em" }}>Head of Client Achievement UK/EMEA</p>
                </div>
              </div>
              <div className="col-12 col-lg-6 align-items-center d-flex mt-5">
                <img className="w-100" src={profilePng} alt="profile" />
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  )
}

export default Solutions
