import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./Custom.css"
import App from './App';
import { io } from "socket.io-client";
const root = ReactDOM.createRoot(document.getElementById('root'));

const socket = io("wss://" + process.env.REACT_APP_DOMAINNAME + ":8443");
//const socket = io("ws://localhost:8443");

socket.on("storeUser", async (socketId) => {
  root.render(
    //<React.StrictMode>
    <App socket={socket} socketId={socketId} />
    //</React.StrictMode>
  )
});
