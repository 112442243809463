import React from 'react';
import BankingBannerPng from "../../images/Banking Banner.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';
function Transport() {
  return (
    <>
      <div className="heWhoRemains">
        <Header />
        <div>
          <section>
            <div className="container mt-5">
              <div className="row">

                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-up"
                  data-aos-duration="2000">
                  <div className="mt-4 ">
                    <h1 className=" display-4 fw-bold smd_text">Schedule AI for The
                      <span style={{ color: "#0B5ED7" }}> Transport Industry</span>
                    </h1>
                    <p className="lead_smd text-black" style={{ fontSize: "1.1em" }}>
                      Are you in the transport industry? If so, you know how important it is to maintain efficiency and productivity. Scheduling appointments can be a tedious task, but with appointment scheduling software, your business can benefit from improved efficiency.

                    </p>
                    <a href="/login"><button
                      className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                      Demo</button></a>
                  </div>
                </div>
                <div className="col-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                  <img className="w-100" src={BankingBannerPng} alt='Banking Banner' />
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container mt-5">
              <div className="column pt-5">
                <h2 className="display-6 fw-bold">Some of the most common features of Schedule AI software include:
                </h2>
              </div>
              <ul>
                <li className='liListDev'> The ability to schedule appointments quickly and easily</li>
                <li className='liListDev'> The ability to view a calendar of upcoming appointments</li>
                <li className='liListDev'>The ability to send reminders to clients about their upcoming appointments </li>
                <li className='liListDev'> The ability to track client contact information and preferences
                </li>
                <li className='liListDev'> The ability to integrate with other software programs (such as accounting or CRM software)</li>
              </ul>

            </div>
          </section>

          <section>
            <div className="container textDevLay pb-4">
              <div className="column pt-5">
                <h2 className="text-center display-6 fw-bold">Conclusion
                </h2>
                <p className='lead_smd text-center'>ScheduleAI is a powerful tool that can help transport businesses increase their efficiency and save time. Streamlining the process of creating, managing, and tracking appointments, allows businesses to focus on providing better service for their customers. As technology continues to advance, appointment scheduling software will become more prevalent in the transportation industry and offer a range of benefits for both businesses and customers alike.
                </p>
              </div>
            </div>
          </section>
        </div>
        
        <Footer />
      </div>
    </>
  )
}

export default Transport
