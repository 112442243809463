import React from 'react'
import Day from './Day'
function Month({ month, nextMonth, selectedDayTab }) {
    // let data= month.push(nextMonth[0])

    if (parseInt(month[4][6].format("D")) < 7) {
        // console.log("no need")
    } else {
        if (parseInt(nextMonth[0][0].format("D")) >= 28) {
            // console.log("need line")
            month[5] = nextMonth[0]
        }
    }
    return (
        <div className='gridTemplating'>
            {
                month.map((row, i) => {
                    if (i === 0) {
                        return <React.Fragment key={i}>
                            {
                                row.map((day, idx) => {
                                    return <React.Fragment key={idx}>
                                        <div className='daysTab dayNames m-0'>
                                            <div className='fw-bold h-100 w-100 d-flex justify-content-center align-items-center'>
                                                {day.format("ddd").toUpperCase().slice(0, 1)}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                })
                            }
                        </React.Fragment>
                    }
                })
            }

            {
                month.map((row, i) => (
                    <React.Fragment key={i}>
                        {
                            row.map((day, idx) => {
                                if (i === 4) {
                                    if (day.format("D") >= 15) {
                                        return <Day day={day} key={idx} rowIdx={i} selectedDayTab={selectedDayTab} />
                                    }
                                } else if (i === 5) {
                                    if (day.format("D") >= 20) {
                                        return <Day day={day} key={idx} rowIdx={i} selectedDayTab={selectedDayTab} />
                                    }
                                } else {
                                    return <Day day={day} key={idx} rowIdx={i} selectedDayTab={selectedDayTab} />
                                }
                            })
                        }
                    </React.Fragment>
                ))
            }
        </div>
    )
}

export default Month