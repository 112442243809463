import React from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import zoomIntegrationPng from "../../images/zoom integration.png";
import integrationWorkPng from "../../images/integration work.png";
import zoomIntegrationsSuccessPng from "../../images/zoom-integrations-success.png";
import eventDetailsPagePng from "../../images/event-details-page.png";
import selectZoomWhenCreatingEventPng from "../../images/select-zoom-when-creating-event.png";
import "../../Custom.css";
import { Helmet } from "react-helmet";
import ScrollTopButton from '../scrolltopbtn/ScrollTopButton';

function WebexDocs() {
    return (
        <div className='heWhoRemains'>
            <div>
                <Helmet>
                    <title>Webex Scheduling Integration - Scheduleai</title>
                    <meta name="description" content="Integrate scheduleai + Webex Meetings to  add video conferencing details to your calendar events and meeting reminders so that everyone is prepared on that time." />
                </Helmet>
                <Header />
                <section>
                    <div className="container smd_priv-bg">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="p-5 mt-4">
                                    <h1 className="display-4 pt-8">Get Started
                                        <span>with Webex Integrations.</span>
                                    </h1>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img className="w-100"
                                    src={zoomIntegrationPng} alt="webex docs" />
                            </div>
                        </div>
                    </div>
                </section>


                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="mb-4">Webex Integration.</h2>
                                <p className="para">Scheduling meetings and coordinating the details between all the tools in your tech
                                    stack can be a headache!</p>
                                <p className="para">When you manually schedule meetings, there’s endless room for error or
                                    forgetfulness: the hassle of back-and-forth emailing to coordinate times (which Schedule AI
                                    eliminates), making sure to include the correct Webex or video conferencing link, remembering
                                    call-in details and passcodes … the list goes on.</p>

                                <p className="para mt-4">With this video conferencing integration, you can easily reduce the time you
                                    spend manually sharing Webex details. Plus, you’ll be able to:</p>
                                <ul>
                                    <li>Automatically generate unique Webex video conference details for every meeting or new event
                                        you schedule.</li>
                                    <li>Eliminate the need to set up and remember video conferencing details for all your meetings
                                    </li>
                                    <li>Instantly update meeting details when events are rescheduled or canceled</li>
                                </ul>
                                <p className="para">The best part? The Schedule AI + Webex integration (and all our video conferencing
                                    integrations) are free forever on all Schedule AI <span className="text-primary fw-bold">pricing
                                        plans!</span></p>

                            </div>

                            <div className="col-md-12 mt-2">
                                <h1 className="text-center mb-4 mt-4">How the Schedule AI + Webex integration works</h1>
                                <p className="para text-center">To get started, all you need to do is connect your Webex account with
                                    Schedule AI. Just visit your Schedule AI integrations page, select the Webex integration, and sign
                                    in when you’re automatically redirected to Webex.</p>
                                <div>
                                    <img className="w-100 mb-5 mt-4" src={integrationWorkPng} alt="integration work" />
                                    <div>
                                        <img className="w-100 mb-5" src={zoomIntegrationsSuccessPng} alt="zoom Integrations Success Png" />
                                        <p className="para">Please note: If your workplace owns your Webex account, your Webex account
                                            administrator must pre-approve Schedule AI in the Webex Marketplace permissions to connect
                                            Schedule AI, and then all users must individually connect their Webex account.</p>
                                        <p className="para">Once connected, you’re prompted to set Webex as the Location for any of your
                                            Schedule AI Event Types. By setting Webex as your Event Type Location, Schedule AI will
                                            automatically include a dynamic video conference link for each meeting. Simply select the Event
                                            Type you’d like to edit from your Schedule AI dashboard by clicking on the event name or the
                                            settings wheel. Expand the “What event is this?” section, and select Webex under the Location
                                            dropdown, then Save & Close.</p>
                                        <div>
                                            <img className="w-100 mt-4 mb-5" src={selectZoomWhenCreatingEventPng} alt="select Zoom When Creating Event" />
                                            <p className="para">Pro tip: Webex users can offer invitees a dial-in number in addition to the web
                                                conferencing link for each event scheduled. For international numbers, you can select multiple
                                                countries to include in your Webex account settings and a link will be displayed in the invitee
                                                notification leading to their dial-in options.</p>
                                            <h1 className="text-center mb-4 mt-4">What this means for your invitees</h1>
                                            <p className="para">Now, when an invitee books a meeting with you, they’ll receive instant access to the
                                                Webex meeting details via a calendar invitation or meeting confirmation email. You can also
                                                access these details and your unique link from your Scheduled Events details on your Schedule AI
                                                dashboard, and under Upcoming Meetings in Webex.</p>
                                            <p className="para">Pro tip: Your invitees won’t see Webex details until they’ve booked a meeting with
                                                you, but you may want to include a note in your event type Description/Instructions that you’re
                                                meeting on Webex and they’ll receive details after booking.</p>
                                            <div>
                                                <img className="w-100 mt-4 mb-5" src={eventDetailsPagePng} alt="event Details Page" />
                                                <p className="para">Plus, be sure to use Schedule AI features like Workflows and Notifications to set-up
                                                    and personalize how Schedule AI sends out calendar invitations/email confirmations to include your
                                                    Webex details.</p>
                                                <p className="para">To start saving time by automatically adding Webex video conference details to your
                                                    Schedule AI meetings, set your integration up today.
                                                </p>
                                            </div>
                                            <div className="cta_smd w-100 text-center mb-4">
                                                <a href={`https://${process.env.REACT_APP_DOMAINNAME}/calendar#Integrations`}>
                                                    <button className="btn btn-primary cta_smd" type="submit">Add Webex Now</button>
                                                </a>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ScrollTopButton />
                <Footer />
            </div>
        </div>
    )
}

export default WebexDocs
