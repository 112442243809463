import React from 'react'
import Header from '../header/Header';
import Footer from '../footer/Footer';
import termAndConditionsPng from "../../images/term and conditions.png";
import "../../Custom.css";
import {Helmet} from "react-helmet";
import ScrollTopButton from '../scrolltopbtn/ScrollTopButton';


function PrivacyPolicy() {
    return (
        <div className='heWhoRemains'>
            <Helmet>
                <title>Privacy Policy | Scheduleai</title>
                <meta name="description" content="Scheduleai realize the significance of privacy, we want to feel secure using our services.Privacy and terms are to assist you with how we connect with your information." />
            </Helmet>
            <div>
                <Header />
                <section>
                    <div className="container smd_priv-bg">
                        <div className="row">
                            <div className="col-lg-6 d-flex justfy-content-center align-items-center">
                                <div className="p-5 mt-4">
                                    <h1 className="display-4 pt-8 fw-bold">Privacy
                                        <span>Policy</span>
                                    </h1>
                                </div>
                            </div>
                            <div className="col-lg-6" data-aos="fade-left" data-aos-duration="2000">
                                <img className="w-100" src={termAndConditionsPng} alt="Privacy policy" />
                            </div>
                        </div>

                        <section>
                            <div className="container mt-4 border-bottom">
                                <div className="row">
                                    <div className="col-md-12 border-bottom">
                                        <h3 className="mb-4">Privacy Policy</h3>
                                        <p className="lead_smd">Schedule AI (“Calendary ”, “we”, or “us”) recognizes the importance of privacy. We
                                            want you to feel confident using our services, and this privacy notice (“Privacy Notice” or
                                            “Notice”) is to help you understand and describe how we collect, use, and disclose your
                                            information.</p>
                                        <p className="lead_smd">Throughout this document, we will use a few terms to describe various products
                                            and roles. All of the text, data, information, software, graphics, photographs and more that
                                            we and our affiliates may make available to you, plus our websites and any services,
                                            plug-ins, software, or applications (including Schedule AI Click, Schedule AI for Chrome,
                                            Schedule AI for Firefox, and any Schedule AI applications) that we may provide all together
                                            constitute the Schedule AI “Website.”</p>
                                        <p className="lead_smd"> Schedule AI “Users” are the people who have created a Schedule AI account, and
                                            “Ventas Invitees” are people who have scheduled a meeting with a Schedule AI User but may or
                                            may not have registered with Schedule AI. A Schedule AI “Viewer” is a person who visits the
                                            Schedule AI Website but may or may not be a Schedule AI User or Schedule AI Invitee.</p>

                                        <ul>
                                            <li>What information we collect and how it is collected</li>
                                            <li>With whom we may share information</li>
                                            <li>Legal basis for processing the information</li>
                                            <li>Your rights and choices</li>
                                            <li>Security and storage of the information</li>
                                            <li>Third-party websites</li>
                                            <li>Users outside the United States</li>
                                            <li>Changes to the Notice</li>
                                            <li>Contacting Schedule AI</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 mt-4 border-bottom">
                                        <h3 className="mb-4" id="limitedUseDisclosure">Limited Use Policy Disclosure.</h3>
                                        <p className="lead_smd">
                                            Schedule AI use and transfer of information received from Google APIs to any other app will adhere to <a href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes' target='_Blank'>Google API Services User Data Policy</a>, including the Limited Use requirements.
                                        </p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 mt-4 border-bottom">
                                        <h3 className="mb-4">Information We Collect.</h3>
                                        <p className="lead_smd">We collect information about you directly from you and automatically through
                                            your use of our Website. We may combine information automatically collected with other
                                            information that we have collected about you including from publicly available sources.</p>
                                        <p className="lead_smd">In order to help you protect yourself and your information, we encourage you to
                                            provide only that information that is necessary for using our Website. For example, to
                                            schedule a meeting you may only need to provide us with a name, email address, date, and
                                            time.</p>
                                    </div>
                                </div>

                                <div className="row border-bottom">
                                    <div className="col-md-12 mt-4 ">
                                        <h3 className="mb-4">Information You Provide Voluntarily.</h3>
                                        <p className="lead_smd">Schedule AI collects the following information, either directly from Schedule AI
                                            Users, Invitees, or Viewers, or through third-parties regarding Users, Invitees, or Viewers.
                                        </p>
                                        <p className="lead_smd">In order to help you protect yourself and your information, we encourage you to
                                            provide only that information that is necessary for using our Website. For example, to
                                            schedule a meeting you may only need to provide us with a name, email address, date, and
                                            time.</p>
                                    </div>

                                    <div className="col-md-12 mt-2">
                                        <h4 className="text-center text-primary mb-4">Appointment Information.</h4>
                                        <p className="lead_smd text-center">Schedule AI collects the following information, either directly from
                                            Schedule AI Users, Invitees, or Viewers, or through third-parties regarding Users, Invitees,
                                            or Viewers.In order to help you protect yourself and your information, we encourage you to
                                            provide only that information that is necessary for using our Website. For example, to
                                            schedule a meeting you may only need to provide us with a name, email address, date, and
                                            time.</p>

                                    </div>

                                    <div className="col-md-12 mt-2">
                                        <h4 className="text-center text-primary mb-4">Calendar Information.</h4>
                                        <p className="lead_smd text-center">A Schedule AI User may connect their calendar with Schedule AI. Our
                                            calendar integration only checks the duration and free/busy status of the events in your
                                            calendar so that we don’t book you when you’re busy. We never store who you are meeting
                                            with, their email address, the meeting title, or any other details about the appointments in
                                            your connected calendar.</p>

                                    </div>
                                </div>

                                <div className="row border-bottom">
                                    <div className="col-md-12 mt-4 ">
                                        <h3 className="mb-4">Information Collected Automatically.</h3>
                                        <p className="lead_smd">Like many websites, we and our Service Providers use Cookies, web beacons, and
                                            other technologies to receive and store certain types of information when you interact with
                                            us through your computer or mobile device subject to your opt-out preferences (see Your
                                            Rights and Choices section below). Using these technologies helps us customize your
                                            experience with our Website, improve your experience, and tailor marketing messages. Here
                                            are some of the types of information we collect:</p>
                                    </div>

                                    <div className="col-md-12 mt-2">
                                        <h4 className="text-center text-primary mb-4">Cookie data.</h4>
                                        <p className="lead_smd text-center">Depending on how you’re accessing our services and subject to your
                                            opt-out preferences, we may use “Cookies” (a small text file sent by your computer each time
                                            you visit our Website, unique to your Schedule AI account or your browser) or similar
                                            technologies to record log data. When we use Cookies, we may use ‘session’ Cookies (that
                                            last until you close your browser) or ‘persistent’ Cookies (that last until you or your
                                            browser deletes them). For example, we may use Cookies to keep you logged into Schedule AI.
                                            Some of the Cookies we use are associated with your Schedule AI account (including personal
                                            information about you, such as the email address you gave us), and other Cookies are not.
                                            Schedule AI provides a centralized cookie management service across the entire Schedule AI
                                            application. You can find the link to manage your cookie preferences at the bottom of the
                                            Schedule AI homepage.</p>
                                    </div>
                                </div>


                                <div className="row border-bottom">
                                    <div className="col-md-12 mt-4 ">
                                        <h3 className="mb-4">How We Use Your Information.</h3>
                                        <p className="lead_smd">We may use information that we collect about you, including Personal Data, to:
                                        </p>
                                    </div>

                                    <div className="col-md-12 mt-2">
                                        <h4 className="text-center text-primary mb-4">Provide the Schedule AI Service.</h4>
                                        <p className="lead_smd text-center">We will use your information to provide our Website to you;
                                            including facilitating scheduling; managing your account; responding to your inquiries; and
                                            for other customer service and support purposes.</p>
                                    </div>

                                    <div className="col-md-12 mt-2">
                                        <h4 className="text-center text-primary mb-4">Understand and improve our products.</h4>
                                        <p className="lead_smd text-center">We will perform research and analysis about your use of, or interest
                                            in, our products, services, or content, or products, services or content offered by others.
                                            We do this to help make our products better and to develop new products.</p>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <h4 className="text-center text-primary mb-4">Service related communications.</h4>
                                        <p className="lead_smd text-center">We may send you service and administrative emails to ensure the
                                            service is working properly. We will also email you regarding your calendar appointments.
                                            These messages are considered part of the service and you may not opt out of these messages.
                                        </p>
                                    </div>

                                    <div className="col-md-12 mt-2">
                                        <h4 className="text-center text-primary mb-4">Promotional.</h4>
                                        <p className="lead_smd text-center">Subject to your opt-out preference, we may send you emails about new
                                            product features or other news about Schedule AI or on topics we think would be relevant to
                                            you. You may opt out of receiving these communications at any time. Visit the ‘Your Rights
                                            and Choices’ section below. For Schedule AI Invitees, please be assured that we do not use the
                                            email addresses that you enter to schedule a meeting with a Schedule AI User to send any type
                                            of direct marketing.</p>
                                    </div>

                                    <div className="col-md-12 mt-2">
                                        <h4 className="text-center text-primary mb-4">Administrative</h4>
                                        <p className="lead_smd text-center">We may contact you to inform you about changes in your services, our
                                            service offering and other important service-related notices, such as changes to the Notice
                                            or about security or fraud notices.</p>
                                    </div>

                                    <div className="col-md-12 mt-2">
                                        <h4 className="text-center text-primary mb-4">Protecting Rights and Interests.</h4>
                                        <p className="lead_smd text-center">We will use your information to protect our rights and interests as
                                            well as the rights and interests of our Users and any other person, as well as to enforce
                                            this Notice or our Terms of Use.</p>
                                    </div>

                                    <div className="col-md-12 mt-2">
                                        <h4 className="text-center text-primary mb-4">Legal Compliance.</h4>
                                        <p className="lead_smd text-center">We may use your information to comply with applicable legal or
                                            regulatory obligations, including complying with requests from law enforcement or other
                                            governmental authorities, or in legal proceedings involving Schedule AI.</p>
                                    </div>

                                    <div className="col-md-12 mt-2">
                                        <h4 className="text-center text-primary mb-4">Other.</h4>
                                        <p className="lead_smd text-center">We also may use your information to manage our business or perform
                                            functions as otherwise described to you at the time of collection subject to your consent.
                                        </p>
                                    </div>


                                </div>

                                <div className="row">
                                    <div className="col-md-12 mt-4 border-bottom">
                                        <h3 className="mb-4">With Whom We May Share Your Information.</h3>
                                        <p className="lead_smd">We do not sell your information to any third parties or disclose it in exchange
                                            for money or other valuable consideration. We do not share your Personal Data with others
                                            except as indicated within this Notice, or when we inform you and give you an opportunity to
                                            opt-out of having your Personal Data shared.</p>
                                        <p className="lead_smd">We will never use Invitee data to send direct marketing via emails, SMS,
                                            physical mailings, or other similar communication channels to advertise or promote the use
                                            of our product and services or those of a third-party.</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 mt-4 border-bottom">
                                        <h3 className="mb-4">Your Rights and Choices.</h3>
                                        <div className="col-md-12 mt-4">
                                            <h4 className="text-primary mb-4">Account</h4>
                                            <p className="lead_smd">In order to keep your Personal Data accurate and complete, you can log in to
                                                review and update your account information, including contact and billing information,
                                                via your account settings page. You may also contact us to request information about the
                                                personal data we have collected from you and to request the correction, modification or
                                                deletion of such Personal Data. We will do our best to honor your requests subject to
                                                any legal and contractual obligations. If you would like to make a request, cancel your
                                                account or request we delete or no longer use your account information to provide you
                                                our Website, contact us at <a className="fw-bold" href="">contact@kcloudtechnologies.com</a>
                                                or the address set forth at the end of this Notice. Subject to applicable law, we will
                                                retain and use your account information only as necessary to comply with our legal
                                                obligations, resolve disputes, and enforce our agreements.</p>
                                        </div>

                                        <div className="col-md-12 mt-4">
                                            <h4 className="text-primary mb-4">Email.</h4>
                                            <p className="lead_smd">As described above, if you do not wish to receive promotional emails from
                                                us, you may opt out at any time by following the opt-out link contained in the email
                                                itself. Please note that it may take up to ten (10) days to process your request. Please
                                                also note that if you opt out of receiving marketing communications from us, we may
                                                continue to send to you service-related emails which are not available for opt-out. If
                                                you do not wish to receive any service-related emails from us, you have the option to
                                                delete your account.</p>
                                        </div>

                                        <div className="col-md-12 mt-4">
                                            <h4 className="text-primary mb-4">Cookies.</h4>
                                            <p className="lead_smd">You may also refrain from providing or may withdraw your consent for Cookies
                                                via your browser settings. Your browser’s help function should contain instructions on
                                                how to set your computer to accept all Cookies, to notify you when a Cookie is issued,
                                                or to not receive Cookies at any time. Please keep in mind that Cookies are required to
                                                authenticate Schedule AI Users as well as perform some actions within Schedule AI (such as
                                                to pay for an event as an Invitee via Schedule AI), so in order to use the Website, some
                                                Cookies are often required. You may also manage the use of targeting, performance, and
                                                functional cookies on this website by clicking the Cookie Settings link located on the
                                                footer of this page.</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mt-4 border-bottom">
                                            <h3 className="mb-4">Security and Storage of Information.</h3>
                                            <p className="lead_smd">We have taken reasonable steps to help protect the personal information we
                                                collect.

                                                Schedule AI takes the security of your personal data very seriously. We work hard to
                                                protect the personal data that you provide from loss, misuse, and unauthorized access,
                                                or disclosure. Given the nature of communications and information processing technology,
                                                there is no guarantee that personal data will be absolutely safe from access,
                                                alteration, or destruction by a breach of any of our physical, technical, and managerial
                                                safeguards.</p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 mt-4 border-bottom">
                                            <h3 className="mb-4">Do Not Track.</h3>
                                            <p className="lead_smd">We do not currently recognize or respond to browser-initiated Do Not Track
                                                signals as there is no consistent industry standard for compliance.</p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 mt-4 border-bottom">
                                            <h3 className="mb-4">Contacting Us.</h3>
                                            <p className="lead_smd">If you have any questions or comments about this Notice, please contact us
                                                at <a className="fw-bold" href="mailto:info@scheduleai.co">info@scheduleai.co</a>.</p>

                                            <p className="lead_smd">For questions or comments outside of the Privacy Notice, please contact us
                                                at <a className="fw-bold" href="mailto:info@scheduleai.co">info@scheduleai.co</a>.</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="row mt-4">
                                    <div className="col-md-12 border-bottom">
                                        <h3 className="mb-4">Sharing Personal Information.</h3>
                                        <p className="lead_smd">We may disclose your Personal Information to a third-party for a business
                                            purpose. When we disclose Personal Information for a business purpose, we enter a contract
                                            that describes the purpose and requires the recipient to both keep that Personal Information
                                            confidential and not use it for any purpose except performing your contract.</p>
                                        <p className="lead_smd">We disclose your Personal Information for a business purpose to the following
                                            categories of third parties:</p>
                                        <ul>
                                            <li>Service Providers.</li>
                                            <li>Third parties to whom you or your agents authorize us to disclose your Personal
                                                Information in connection with the services we provide to you.</li>
                                            <li>Government representatives as required by law.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="row mt-4 border-bottom">
                                    <div className="col-md-12 ">
                                        <h3 className="mb-4">Deletion Request Rights.</h3>
                                        <p className="lead_smd">You have the right to request that we delete any of your Personal Information
                                            that we have collected from you and retained, subject to certain exceptions. All deletion
                                            requests will be managed in accordance with Schedule AI Deletion Procedures. Deletion requests
                                            should be sent to <a className="fw-bold" href="">contact@kcloudtechnologies.com.</a> Once we
                                            receive and confirm your verifiable request, we will delete (and direct our Service
                                            Providers to delete) your Personal Information from our records, unless an exception
                                            applies.</p>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <h3 className="mb-4">Exercising Access, Data Portability, and Deletion Rights.</h3>
                                        <p className="lead_smd">To exercise the access, data portability, and deletion rights described above,
                                            please submit a verifiable consumer request to us by reaching out to <a className="fw-bold"
                                                href="#">contact@kcloudtechnologies.com</a>.</p>
                                        <p className="lead_smd">Only you or a person registered with the California Secretary of State that you
                                            authorize to act on your behalf may make a verifiable consumer request related to your
                                            Personal Information. You may also make a verifiable consumer request on behalf of your
                                            minor child. You may only make a verifiable California Resident request for access or data
                                            portability twice within a 12-month period.</p>
                                        <p>The request must:</p>
                                        <ul>
                                            <li>Provide sufficiently detailed information to allow Schedule AI to reasonably verify that
                                                you are the person to whom the requested Personal Information pertains or their
                                                authorized representative.</li>
                                            <li>Include sufficient detail to allow us to properly understand, evaluate, and respond to
                                                it.</li>
                                            <li>We cannot respond to your request or provide you with Personal information if we cannot
                                                verify your identity or authority to make the request and confirm the Personal
                                                Information relates to you.</li>
                                            <li>Making a verifiable consumer request does not require you to create an account with us.
                                            </li>
                                            <li>Schedule AI will only use Personal Information provided in a verifiable consumer request
                                                to verify the requestor's identity or authority to make the request.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <ScrollTopButton/>
                <Footer />
            </div>
        </div>
    )
}

export default PrivacyPolicy
