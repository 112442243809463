import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import dayjs from 'dayjs';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Your Schedule AI Meetings Summary',
        },
    },
};





function Area({ meetings }) {

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let upcomingIndexes = [];
    let pastIndexes = [];
    let cancelledIndex = [];

    let monthsData = {
        "January": {
            "upcoming": 0,
            "pending": 0,
            "past": 0,
        },
        "February": {
            "upcoming": 0,
            "pending": 0,
            "past": 0,
        },
        "March": {
            "upcoming": 0,
            "pending": 0,
            "past": 0,
        },
        "April": {
            "upcoming": 0,
            "pending": 0,
            "past": 0,
        },
        "May": {
            "upcoming": 0,
            "pending": 0,
            "past": 0,
        },
        "June": {
            "upcoming": 0,
            "pending": 0,
            "past": 0,
        },
        "July": {
            "upcoming": 0,
            "pending": 0,
            "past": 0,
        },
        "August": {
            "upcoming": 0,
            "pending": 0,
            "past": 0,
        },
        "September": {
            "upcoming": 0,
            "pending": 0,
            "past": 0,
        },
        "October": {
            "upcoming": 0,
            "pending": 0,
            "past": 0,
        },
        "November": {
            "upcoming": 0,
            "pending": 0,
            "past": 0,
        },
        "December": {
            "upcoming": 0,
            "pending": 0,
            "past": 0,
        },
    }

    meetings.map((meeting, key) => {
        let date = new Date(meeting.date).getDate();
        let month = new Date(meeting.date).getMonth();
        let year = new Date(meeting.date).getFullYear();


        let meridium = meeting.startTime.split(" ")[1];
        let timing = meeting.startTime.split(" ")[0];

        let OgHour = parseInt(timing.split(":")[0]);
        let OgMin = parseInt(timing.split(":")[1]);

        let hour = 0;

        if (meridium.includes("pm")) {
            if (OgHour < 12) {
                hour = OgHour + 12;
            }
            else {
                hour = OgHour;
            }
        } else {
            hour = OgHour;
        }

        let min = OgMin;
        let sec = 0;

        if (dayjs(new Date(year, month, date, hour, min, sec)).valueOf() > dayjs(new Date()).valueOf() && meeting.status == "active") {
            upcomingIndexes.push(key);
        } else if (meeting.status == "cancelled") {
            cancelledIndex.push(key);
        }
        else if (dayjs(new Date(year, month, date, hour, min, sec)).valueOf() < dayjs(new Date()).valueOf()) {
            pastIndexes.push(key);
        } else {

        }
    })

    // upcomingIndexes
    // pastIndexes

    if (upcomingIndexes.length > 0) {
        upcomingIndexes.map((index, key) => {
            const monthName = meetings[index].date.split(" ")[1];

            switch (monthName) {
                case "January":
                    monthsData.January.upcoming += 1;
                    break;
                case "February":
                    monthsData.February.upcoming += 1;
                    break;
                case "March":
                    monthsData.March.upcoming += 1;
                    break;
                case "April":
                    monthsData.April.upcoming += 1;
                    break;
                case "May":
                    monthsData.May.upcoming += 1;
                    break;
                case "June":
                    monthsData.June.upcoming += 1;
                    break;
                case "July":
                    monthsData.July.upcoming += 1;
                    break;
                case "August":
                    monthsData.August.upcoming += 1;
                    break;
                case "September":
                    monthsData.September.upcoming += 1;
                    break;
                case "October":
                    monthsData.October.upcoming += 1;
                    break;
                case "November":
                    monthsData.November.upcoming += 1;
                    break;
                case "December":
                    monthsData.December.upcoming += 1;
                    break;
            }
        })
    }

    if (pastIndexes.length > 0) {
        pastIndexes.map((index, key) => {
            // console.log(key)
            const monthName = meetings[index].date.split(" ")[1];

            switch (monthName) {
                case "January":
                    monthsData.January.past += 1;
                    break;
                case "February":
                    monthsData.February.past += 1;
                    break;
                case "March":
                    monthsData.March.past += 1;
                    break;
                case "April":
                    monthsData.April.past += 1;
                    break;
                case "May":
                    monthsData.May.past += 1;
                    break;
                case "June":
                    monthsData.June.past += 1;
                    break;
                case "July":
                    monthsData.July.past += 1;
                    break;
                case "August":
                    monthsData.August.past += 1;
                    break;
                case "September":
                    monthsData.September.past += 1;
                    break;
                case "October":
                    monthsData.October.past += 1;
                    break;
                case "November":
                    monthsData.November.past += 1;
                    break;
                case "December":
                    monthsData.December.past += 1;
                    break;
            }
        })
    }

    if (cancelledIndex.length > 0) {
        cancelledIndex.map((index, key) => {
            const monthName = meetings[index].date.split(" ")[1];

            switch (monthName) {
                case "January":
                    monthsData.January.pending += 1;
                    break;
                case "February":
                    monthsData.February.pending += 1;
                    break;
                case "March":
                    monthsData.March.pending += 1;
                    break;
                case "April":
                    monthsData.April.pending += 1;
                    break;
                case "May":
                    monthsData.May.pending += 1;
                    break;
                case "June":
                    monthsData.June.pending += 1;
                    break;
                case "July":
                    monthsData.July.pending += 1;
                    break;
                case "August":
                    monthsData.August.pending += 1;
                    break;
                case "September":
                    monthsData.September.pending += 1;
                    break;
                case "October":
                    monthsData.October.pending += 1;
                    break;
                case "November":
                    monthsData.November.pending += 1;
                    break;
                case "December":
                    monthsData.December.pending += 1;
                    break;
            }
        })
    }




    const upcoming = [
        monthsData.January.upcoming,
        monthsData.February.upcoming,
        monthsData.March.upcoming,
        monthsData.April.upcoming,
        monthsData.May.upcoming,
        monthsData.June.upcoming,
        monthsData.July.upcoming,
        monthsData.August.upcoming,
        monthsData.September.upcoming,
        monthsData.October.upcoming,
        monthsData.November.upcoming,
        monthsData.December.upcoming
    ];
    const pending = [
        monthsData.January.pending,
        monthsData.February.pending,
        monthsData.March.pending,
        monthsData.April.pending,
        monthsData.May.pending,
        monthsData.June.pending,
        monthsData.July.pending,
        monthsData.August.pending,
        monthsData.September.pending,
        monthsData.October.pending,
        monthsData.November.pending,
        monthsData.December.pending,
    ];
    const past = [
        monthsData.January.past,
        monthsData.February.past,
        monthsData.March.past,
        monthsData.April.past,
        monthsData.May.past,
        monthsData.June.past,
        monthsData.July.past,
        monthsData.August.past,
        monthsData.September.past,
        monthsData.October.past,
        monthsData.November.past,
        monthsData.December.past,
    ];




    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: 'Upcoming Meetings',
                data: upcoming,
                // data: labels.map(() => faker.datatype.number({ min: 0, max: 500 })),
                borderColor: 'rgba(54, 162, 235, 1)',
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
            },
            {
                fill: true,
                label: 'Cancelled Meetings',
                data: pending,
                // data: labels.map(() => faker.datatype.number({ min: 0, max: 500 })),
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                fill: true,
                label: 'Past Meetings',
                data: past,
                // data: labels.map(() => faker.datatype.number({ min: 0, max: 500 })),
                borderColor: 'rgba(80, 200, 80, 1)',
                backgroundColor: 'rgba(80, 200, 80, 0.5)',
            },
        ],
    }
    return (
        <Line options={options} data={data} />
    )
}

export default Area