import axios from 'axios';
import React, { useRef, useState } from 'react'
import { ListGroup, ListGroupItem, Modal, Tab, Tabs, Toast } from 'react-bootstrap'
import { res } from 'react-email-validator';

function Stracker({ serverURL }) {

    const linkUrl = useRef(null);

    const [showAddLink, setShowAddLink] = useState(false);

    const [shortLink, setShortLink] = useState(null);

    const [titleForNewUrl, setTitleForNewUrl] = useState("");

    const [resultDataForUrl, setResultDataForUrl] = useState(null);
    const [resultDataFinal, setResultDataFinal] = useState(null);
    const [allTra, setAllTra] = useState(null);


    const [viewToast, setViewToast] = useState(false);
    const [toastTitle, setToastTitle] = useState(null);
    const [toastBody, setToastBody] = useState(null);
    const [toastMessageType, setToastMessageType] = useState(null);


    const getAllTrackers = async () => {
        const allTra = await axios.post(serverURL + "/linksT/getAll");

        if (allTra.status === 200) {
            setAllTra(allTra.data);
        } else {
            console.log("no tera for now");
        }
    }

    useState(() => {
        getAllTrackers();
    }, [])

    const getShortUrl = async (e) => {
        e.preventDefault();
        const query = {
            "url": linkUrl.current.value,
            "email": "gulfan@kcloudtechnologies.com"
        }

        const getShortUrlNow = await axios.post(serverURL + "/linksT/getAUrl", query);

        if (getShortUrlNow.status === 200) {
            console.log(getShortUrlNow.data);
            setResultDataForUrl(getShortUrlNow.data);
            setShortLink(getShortUrlNow.data.shortId);
        } else {
            console.log(getShortUrlNow.data);
            setViewToast(true);
            setToastTitle("Error");
            setToastBody(getShortUrlNow.data);
            setToastMessageType("invalid");
        }

    }

    const setTheFinalUserNow = async (e) => {
        e.preventDefault();
        const query = {
            title: titleForNewUrl,
            email: resultDataForUrl.email,
            referenceURL: resultDataForUrl.referenceURL,
            origionalLink: resultDataForUrl.origionalLink,
            shortId: resultDataForUrl.shortId,
        }
        const saveUrlNow = await axios.post(serverURL + "/linksT/saveUrl", query);
        if (saveUrlNow.status === 200) {
            setResultDataFinal(saveUrlNow.data);
            getAllTrackers();
        } else {
            console.log(saveUrlNow.data);
            setViewToast(true);
            setToastTitle("Error");
            setToastBody(saveUrlNow.data);
            setToastMessageType("invalid");
        }
    }

    const deleteTracker = async (shortId) => {
        const deleteNow = await axios.post(serverURL + "/linksT/deleteTracker", { shortId: shortId });
        if (deleteNow.status === 200) {
            setAllTra(deleteNow.data);
        } else {
            console.log(deleteNow.data);

            setViewToast(true);
            setToastTitle("Error");
            setToastBody(deleteNow.data);
            setToastMessageType("invalid");
        }
    }

    const completionUrl = async (e) => {
        e.preventDefault();
        setResultDataForUrl(null);
        setResultDataFinal(null);
        setShortLink(null);
        setShowAddLink(false);
    }


    // @return Promise<boolean>
    async function askWritePermission() {
        try {
            // The clipboard-write permission is granted automatically to pages 
            // when they are the active tab. So it's not required, but it's more safe.
            const { state } = await navigator.permissions.query({ name: 'clipboard-write' })
            return state === 'granted'
        } catch (error) {
            // Browser compatibility / Security error (ONLY HTTPS) ...
            return false
        }
    }


    const copyImageNow = async () => {
        // @params blob - The ClipboardItem takes an object with the MIME type as key, and the actual blob as the value.
        // @return Promise<void>
        const setToClipboard = async blob => {
            const data = [new ClipboardItem({ [blob.type]: blob })]
            await navigator.clipboard.write(data)
        }

        // Can we copy a text or an image ?
        const canWriteToClipboard = await askWritePermission()

        // Copy a PNG image to clipboard
        if (canWriteToClipboard) {
            const response = await fetch('/image/my-beautiful-image.png')
            const blob = await response.blob()
            await setToClipboard(blob)
        }
    }




    return (
        <>
            <div className='d-flex flex-column justify-content-start vh-100'>
                <div className="alert alert-dark w-100 rounded-0 p-2 m-0 fw-bold">
                    Stracker
                </div>
                <div className='d-flex align-items-start flex-fill h-100'>
                    <div className='mainPanel w-100 h-100'>
                        <Tabs
                            className='bg-light'
                            defaultActiveKey={"dashboard"}
                        >
                            <Tab eventKey={"dashboard"} onClick={(e) => { }} title={<div className='h-100 text-dark' style={{ fontWeight: "500" }}>Dashboard</div>}>
                                <div className="alert m-0 py-2 bg-light rounded-0"></div>
                                <div className="alert alert-secondary rounded-0 m-0 p-2">
                                    <button className='btn btn-sm btn-secondary' onClick={(e) => { setShowAddLink(true); }}>Add</button>
                                </div>

                                <div className='alert p-1 d-flex flex-column'>
                                    <table className='table table-bordered  table-hover table-stripped'>
                                        <thead className='fw-bold text-center thea+d-dark'>
                                            <tr>
                                                <td>Open Count</td>
                                                <td>Title</td>
                                                <td>short Code</td>
                                                <td>origional URL</td>
                                                <td>tracking URL</td>
                                                <td>actions</td>
                                            </tr>
                                        </thead>
                                        <tbody className='text-center bg-light'>
                                            {
                                                allTra ?
                                                    allTra.length ?
                                                        allTra.map((data, key) => {
                                                            return (

                                                                <tr key={key}>
                                                                    <td>{data.openCount}</td>
                                                                    <td>{data.title}</td>
                                                                    <td>{data.shortId}</td>
                                                                    <td><a href={data.referenceURL}>{data.referenceURL}</a></td>
                                                                    <td><a href={data.url}>{data.url}</a></td>
                                                                    <td>
                                                                        <div className="btn btn-sm btn-danger" onClick={(e) => { deleteTracker(data.shortId) }}>
                                                                            <i className='fa fa-trash'></i>
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                            )
                                                        })
                                                        :
                                                        <tr>
                                                            <td colSpan={6}>
                                                                <div className="alert alert-secondary m-0">No trackers for now</div>
                                                            </td>
                                                        </tr>
                                                    :
                                                    <tr>
                                                        <td colSpan={6}>
                                                            <div className="alert alert-secondary m-0">No trackers for now</div>
                                                        </td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>

                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>

            <Modal
                show={showAddLink}
                centered
                onHide={() => { setShowAddLink(false); }}>
                <Modal.Header className='salesforce-style py-2' closeButton closeVariant='white'>
                    <span style={{ fontWeight: "500", fontSize: "0.9rem" }}>Add new Link</span>
                </Modal.Header>
                {
                    !shortLink && !resultDataFinal ?
                        <form onSubmit={(e) => { getShortUrl(e); }}>
                            <Modal.Body className='p-0 overflow-hidden'>
                                <div className="alert  rounded-0 m-0">
                                    <label htmlFor="linkLog" className='w-100'>
                                        <span className='mediumTextSize fw-bold'>Pase Long URL</span>
                                        <input id="linkLog" required type="text" className='form-control w-100 mediumTextSize mb-2' placeholder='URL' ref={linkUrl} />
                                    </label>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className='d-flex justify-content-center p-1'>
                                <input className='btn btn-sm btn-success rounded-pill w-100' style={{ maxWidth: "120px" }} type="submit" value="Get URL" />
                            </Modal.Footer>
                        </form>
                        :
                        shortLink && !resultDataFinal ?
                            <form onSubmit={(e) => { setTheFinalUserNow(e); }}>
                                <Modal.Body className='p-0 overflow-hidden'>
                                    <div className="alert rounded-0 m-0">
                                        <label htmlFor="linkLog" className='w-100'>
                                            <span className='mediumTextSize fw-bold'>Set title for your link</span>
                                            <input id="linkLog" required type="text" onChange={(e) => { setTitleForNewUrl(e.target.value); }} defaultValue={titleForNewUrl} className='form-control w-100 mediumTextSize mb-2' placeholder='title' />
                                        </label>
                                        <label htmlFor="linkLog" className='w-100'>
                                            <span className='mediumTextSize fw-bold'>Your link</span>
                                            <input id="linkLog" disabled onChange={(e) => { }} required type="text" className='form-control w-100 mediumTextSize mb-2' placeholder='title' value={shortLink} />
                                        </label>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer className='d-flex justify-content-center p-1'>
                                    <input className='btn btn-sm btn-success rounded-pill w-100' style={{ maxWidth: "120px" }} type="submit" value="Save" />
                                </Modal.Footer>
                            </form>
                            :
                            shortLink && resultDataFinal ?
                                <form onSubmit={(e) => { completionUrl(e); }}>
                                    <Modal.Body className='p-0 overflow-hidden'>
                                        <div className="alert rounded-0 m-0">
                                            <p className='text-success text-center fw-bold'>You have succeessfully created your link</p>
                                            <div className="input-group w-100 d-flex flex-nowrap rounded">
                                                <input type="text" disabled maxLength={50} id="UID" required className='customInputFieldFocus mediumTextSize w-100 border px-2' placeholder={resultDataFinal.url} value={resultDataFinal.url} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text btn salesforce-style h-100 border-0 mediumTextSize" style={{ borderRadius: "0px 0.375rem 0.375rem 0px" }} onClick={
                                                        (e) => {
                                                            navigator.clipboard.writeText(resultDataFinal.url);
                                                            e.target.innerHTML = "<span class='d-flex align-items-center gap-1'><i class='fa fa-check text-light'></i>Copied</span>";
                                                            e.target.classList.remove("salesforce-style");
                                                            e.target.classList.add("btn-success");
                                                            setTimeout(() => {
                                                                e.target.innerHTML = "Copy Link";
                                                                e.target.classList.remove("btn-success");
                                                                e.target.classList.add("salesforce-style");
                                                            }, 2000);
                                                        }}>
                                                        Copy Link
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className='d-flex justify-content-center p-1'>
                                        <input className='btn btn-sm btn-success rounded-pill w-100' style={{ maxWidth: "120px" }} type="submit" value="Complete" />
                                    </Modal.Footer>
                                </form>
                                :
                                ""

                }
            </Modal>


            {/* #################################### TOAST FRAME ##################################### */}

            <div style={{ position: "fixed", bottom: "10px", right: "10px", zIndex: "99999999" }}>
                <Toast onClose={() => setViewToast(false)} show={viewToast} delay={3000} autohide className='overflow-hidden'>
                    <Toast.Header>
                        {/* <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2 bg-primary"
                        alt="toastImage"
                        /> */}
                        <span style={{ fontWeight: "500" }} className={`me-auto ${toastMessageType === "invalid" ? "text-danger" : "text-success"} `}>{toastTitle}</span>
                        <small>just now</small>
                    </Toast.Header>
                    <Toast.Body style={toastMessageType === "success" ? { fontWeight: "500", background: "#d1e7dd", color: "#0f5132" } : { fontWeight: "500", background: "#f8d7da", color: "#842029" }}>
                        {toastBody}
                    </Toast.Body>
                </Toast>
            </div>

            {/* #################################### TOAST FRAME ##################################### */}
        </>
    )
}

export default Stracker