import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import QRCode from "react-qr-code";
import notFoundPng from "../../images/notFound.png";

const Successpage = () => {
    const serverUrl = "https://" + process.env.REACT_APP_DOMAINNAME + ":8443";
    const [myEvent, setMyEvent] = useState(null);
    const [viewSuccessPage, setViewSuccessPage] = useState(false);
    const [loadingComplete, setLoadingComplete] = useState(false);

    const [appBranding, setAppBranding] = useState(true);
    const [brandLogo, setBrandLogo] = useState([]);
    const [brandLink, setBrandLink] = useState("#");

    useEffect(() => {
        const getEvents = async () => {
            const username = window.location.href.split("/")[window.location.href.split("/").length - 4];
            const eventId = window.location.href.split("/")[window.location.href.split("/").length - 2];
            const bookingId = window.location.href.split("/")[window.location.href.split("/").length - 1];

            const query = {
                "username": username,
                "eventId": eventId,
                "bookingId": bookingId
            }


            console.log(query)
            const getUsername = await axios.post(serverUrl + "/mainIds/getSuccessBooking", query);
            if (getUsername.status === 200) {

                const getNewUser = await axios.post(serverUrl + "/mainIds/getEvents", { "username": username });
                if (getNewUser.status === 200) {
                    // console.log(getUsername.data);
                    setAppBranding(getNewUser.data.appBranding);

                    getNewUser.data.brandLogos.map((logo, key) => {
                        if (logo.active === true) {
                            // console.log(logo.logo)
                            setBrandLink(logo.brandName)
                            setBrandLogo(logo.logo);
                        }
                    });

                } else {
                    // console.log("user not found");
                }
                // console.log(getUsername.data);
                setMyEvent(getUsername.data);
                setViewSuccessPage(true);
                setLoadingComplete(true);

            } else {
                // console.log(getUsername.data);
                setViewSuccessPage(false);
                setLoadingComplete(true);
            }
        }
        getEvents();
    }, [])

    return (

        <>
            {
                loadingComplete ?
                    (
                        viewSuccessPage ?
                            <div className={`freshMain ${viewSuccessPage ? "" : "d-none"}`}>
                                <div className='freshSecond'>
                                    <div className="freshThird border insightBackground shadow p-3">
                                        {
                                            appBranding ?
                                                <a href="/" target='_Blank' className="freshAncore">
                                                    <div className="freshBranding">
                                                        <div className="freshBrandingOne">powered by</div>
                                                        <div className="freshBrandingTwo">Schedule AI</div>
                                                    </div>
                                                </a>
                                                :
                                                <a href={brandLink} target='_Blank' className="freshAncoreCustom">
                                                    <div className="freshBrandingCustom">
                                                        <img src={brandLogo} alt="anchor" className='brandLogo' />
                                                    </div>
                                                </a>
                                        }
                                        <div className="freshThirdMains text-dark rounded w-100">
                                            <h6 className='fw-bold text-center text-dark display-5'>Confirmed</h6>
                                            <p className='text-center text-dark'>You are scheduled with {myEvent.adminName}.</p>
                                            <center>
                                                <a href={myEvent.calendarLink} className="color-salesforce p-1 rounded-pill text-center mb-3" style={{ fontWeight: "600" }}>+ Add to Your Calendar</a>
                                            </center>
                                            <div className='w-100 d-flex justify-content-center'>
                                                {
                                                    <div className='w-100 d-flex flex-wrap justify-content-center gap-0 gap-sm-3 gap-md-4 gap-lg-4' style={{ maxWidth: "600px" }}>
                                                        <hr className='bg-light' />
                                                        <table cellPadding="5" className='mb-4' style={{ maxWidth: "350px" }}>
                                                            <tr>
                                                                <td className='text-dark text-center p-3'>
                                                                    <button className={`freshEventItemColor m-0 event-color-${myEvent.colorId}`}></button>
                                                                </td>
                                                                <td className='text-dark'>
                                                                    <span style={{ wordBreak: "break-word", fontWeight: "600" }}>{myEvent.eventName}</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-dark text-center p-3'>
                                                                    <i className="fa fa-calendar" aria-hidden="true" style={{ fontSize: "25px" }}></i>
                                                                </td>
                                                                <td className='text-dark' style={{ fontWeight: "600" }}>
                                                                    {
                                                                        myEvent.date
                                                                    }
                                                                    ,&nbsp;
                                                                    {
                                                                        myEvent.startTime
                                                                    }
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td className='text-dark text-center p-3'>
                                                                    <i className="fa fa-globe" style={{ fontSize: "25px" }}></i>
                                                                </td>
                                                                <td className='text-dark' style={{ fontWeight: "600" }}>
                                                                    {
                                                                        myEvent.givenTimezone ?
                                                                            myEvent.givenTimezone.name
                                                                            :
                                                                            ""
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-dark text-center p-3'>
                                                                    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" data-testid="location-marker-icon" data-id="details-item-icon"><title>Physical location</title><path d="M12 0C7.453 0 3.623 3.853 3.623 8.429c0 6.502 7.18 14.931 7.42 15.172.479.482 1.197.482 1.675.24l.24-.24c.239-.24 7.419-8.67 7.419-15.172C20.377 3.853 16.547 0 12 0zm0 11.56c-1.675 0-2.872-1.445-2.872-2.89S10.566 5.78 12 5.78c1.436 0 2.872 1.445 2.872 2.89S13.675 11.56 12 11.56z" fill="currentColor"></path></svg>
                                                                </td>
                                                                <td className='text-dark'>
                                                                    {
                                                                        myEvent.eventType === "phone" ?

                                                                            myEvent.inbound ?
                                                                                myEvent.location
                                                                                :
                                                                                (
                                                                                    <span className='d-flex justify-content-start align-items-center flex-wrap'>
                                                                                        <p className='m-0 fw-bold' style={{ fontWeight: "600" }}>Phone Call </p>
                                                                                        <p className='m-0 mediumTextSize'>Event Organizer will contact you on <span className='mediumTextSize' style={{ fontWeight: "600" }}>{myEvent.phoneNumber}</span></p>
                                                                                    </span>
                                                                                )
                                                                            :
                                                                            myEvent.eventType === "meet" ?
                                                                                (
                                                                                    <>
                                                                                        <span style={{ fontWeight: "600" }}>Google Meet Conference</span>
                                                                                        {
                                                                                            myEvent.hangoutLink.link.includes("https://") ?
                                                                                                <a href={myEvent.hangoutLink.link} target="_blank">
                                                                                                    <button className='btn btn-sm btn-primary mx-1' style={{ fontWeight: "500" }}>Join Now</button>
                                                                                                </a>
                                                                                                :
                                                                                                ""
                                                                                        }

                                                                                    </>
                                                                                )
                                                                                :
                                                                                myEvent.eventType === "zoom" ?
                                                                                    (
                                                                                        <>
                                                                                            <span style={{ fontWeight: "600" }}>Zoom Conference</span>
                                                                                            {
                                                                                                myEvent.hangoutLink.link.includes("https://") ?
                                                                                                    <a href={myEvent.hangoutLink.link} target="_blank">
                                                                                                        <button className='btn btn-sm btn-warning mx-1' style={{ fontWeight: "500" }}>Join Now</button>
                                                                                                    </a>
                                                                                                    :
                                                                                                    ""
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                    :
                                                                                    myEvent.eventType === "webex" ?
                                                                                        (
                                                                                            <>
                                                                                                <span style={{ fontWeight: "600" }}>Webex Conference</span>
                                                                                                {
                                                                                                    myEvent.hangoutLink.link.includes("https://") ?
                                                                                                        <a href={myEvent.hangoutLink.link} target="_blank">
                                                                                                            <button className='btn btn-sm btn-dark mx-1' style={{ fontWeight: "500" }}>Join Now</button>
                                                                                                        </a>
                                                                                                        :
                                                                                                        ""
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                        :
                                                                                        myEvent.eventType === "teams" ?
                                                                                            (
                                                                                                <>
                                                                                                    <span style={{ fontWeight: "600" }}>Teams Conference</span>
                                                                                                    {
                                                                                                        myEvent.hangoutLink.link.includes("https://") ?
                                                                                                            <a href={myEvent.hangoutLink.link} target="_blank">
                                                                                                                <button className='btn btn-sm btn-primary mx-1' style={{ fontWeight: "500" }}>Join Now</button>
                                                                                                            </a>
                                                                                                            :
                                                                                                            ""
                                                                                                    }
                                                                                                </>
                                                                                            )
                                                                                            :
                                                                                            myEvent.eventType === "goto" ?
                                                                                                (
                                                                                                    <>
                                                                                                        <span style={{ fontWeight: "600" }}>GoTo Conference</span>
                                                                                                        {
                                                                                                            myEvent.hangoutLink.link.includes("https://") ?
                                                                                                                <a href={myEvent.hangoutLink.link} target="_blank">
                                                                                                                    <button className='btn btn-sm btn-warning mx-1' style={{ fontWeight: "500" }}>Join Now</button>
                                                                                                                </a>
                                                                                                                :
                                                                                                                ""
                                                                                                        }
                                                                                                    </>
                                                                                                )
                                                                                                :
                                                                                                myEvent.location

                                                                    }
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        {
                                                            myEvent.hangoutLink.link.includes("https://") || myEvent.eventType === "address" || myEvent.eventType === "phone" && myEvent.inbound === true ?
                                                                <div className='d-flex flex-column align-items-center justify-content-center gap-3 mb-4'>
                                                                    <QRCode
                                                                        size={150}
                                                                        style={{ height: "auto", maxWidth: "150px", width: "150px" }}
                                                                        value={
                                                                            myEvent.eventType === "phone" && myEvent.inbound ?
                                                                                "tel:" + myEvent.location
                                                                                :
                                                                                myEvent.eventType === "meet" || myEvent.eventType === "zoom" || myEvent.eventType === "webex" || myEvent.eventType === "teams" || myEvent.eventType === "goto" ?
                                                                                    myEvent.hangoutLink.link
                                                                                    :
                                                                                    myEvent.location.toLowerCase().includes("http://") || myEvent.location.toLowerCase().includes("https://") ?
                                                                                        myEvent.location
                                                                                        :
                                                                                        "https://maps.google.com/?q=" + myEvent.location

                                                                        }
                                                                        viewBox={`0 0 150 150`}
                                                                    />
                                                                    {
                                                                        myEvent.eventType === "meet" || myEvent.eventType === "zoom" || myEvent.eventType === "webex" || myEvent.eventType === "teams" || myEvent.eventType === "goto" ?
                                                                            <span className='text-secondary text-center w-100'>Scan to Join Meeting</span>
                                                                            :
                                                                            <span className='text-secondary text-center w-100'>Scan to get Google Location</span>
                                                                    }
                                                                </div>
                                                                :
                                                                ""
                                                        }
                                                        <hr className='bg-light' />
                                                    </div>
                                                }
                                            </div>
                                            <div className=" w-100 text-center text-dark fs-5 py-2 mb-2" style={{ fontWeight: "600" }}>
                                                Thank you for booking with Schedule AI
                                            </div>
                                            <center>
                                                <Link to="/calendar">
                                                    <button className='btn salesforce-style text-dark rounded-pill px-3'>Back to home</button>
                                                </Link>
                                            </center>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='freshMain'>
                                <div className='freshSecond'>
                                    <div className="freshThird border insightBackground shadow p-3">
                                        <a href="/calendar" className="freshAncore">
                                            <div className="freshBranding">
                                                <div className="freshBrandingOne">powered by</div>
                                                <div className="freshBrandingTwo">Schedule AI</div>
                                            </div>
                                        </a>
                                        <div className="w-100 rounded">
                                            {/* <button type='button' onClick={() => { window.location.href = "/calendar"; }} className='btn salesforce-style rounded-circle fw-bold backButtonAbs d-none'>
                                                <i className='fa fa-arrow-left'></i>
                                            </button> */}

                                            <div className={`container-fluid h-100`}>
                                                <div className="row h-100 d-flex align-items-center justify-content-center">
                                                    <div className="col-12 p-4 h-100 d-flex flex-column justify-content-center align-items-center">
                                                        <h6 className='text-dark text-center'>
                                                            <img src={notFoundPng} className="w-100" style={{ maxWidth: "350px" }} alt="not found" />
                                                            <p className='color-salesforce mt-5 display-6' style={{ fontWeight: "600" }}>Broken URL</p>
                                                            <p className='mediumTextSize text-muted'>Looks like the current url is not available.</p>
                                                        </h6>
                                                        <button type='button' onClick={() => { window.location.href = "/calendar"; }} className='btn btn-sm salesforce-style rounded-pill px-3'>
                                                            {/* <i className='fa fa-arrow-left'></i> */}
                                                            Back
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                    )
                    :
                    (
                        <div className={`min-vh-100 w-100 position-absolute top-0 left-start d-flex align-items-center justify-content-center`}>
                            <span className="spinner-border spinner-border-lg text-secondary"></span>
                        </div>
                    )
            }
        </>
    )
}

export default Successpage