import React from 'react';
import ScheduleAIForTelecommunicationBannerPng from "../../images/ScheduleAI for Telecommunication banner.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';
function Telecommunication() {
    return (
        <>
            <div className="heWhoRemains">
                <Header />
                <div>
                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">
                                        <h1 className="mainHomeTitle">Schedule AI for
                                            <span style={{ color: "#0B5ED7" }}> Telecommunication
                                            </span>
                                        </h1>
                                        <p className="lead_smd">
                                            The telecom sector is a rapidly growing industry with many new players entering the market everyday. In order to stay ahead of the competition, it is important to have an efficient way to schedule appointments with customers. Appointment scheduling software can help telecom companies keep track of their customer appointments and ensure that they are always on time.
                                        </p>
                                        <a href="/login"><button
                                            className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                                            Demo</button></a>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <img className="w-100" src={ScheduleAIForTelecommunicationBannerPng} alt='ScheduleAI for Telecommunication banner' />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold">Benefits of using Schedule AI for Telecom Sector
                                </h2>
                            </div>
                            <div className="row">
                                <ul>
                                    <li className='liListDevind'>
                                        One of the main benefits is that it can help increase customer satisfaction. When customers have their appointments scheduled in advance, they are more likely to be satisfied with the service they receive.
                                    </li>

                                    <li className='liListDevind'>
                                        It can also help improve employee productivity. By having all of the appointments in one place, employees can easily see what their next task is and plan their day accordingly.
                                    </li>

                                    <li className='liListDevind'>
                                        It can help reduce costs by eliminating the need for paper records or manual entry of data.
                                    </li>

                                    <li className='liListDevind'>
                                        It helps to manage a high volume of customer appointments efficiently. With so many customers wanting to schedule appointments, it can be difficult to keep track of all the requests.
                                    </li>

                                    <li className='liListDevind'>
                                        By having an efficient appointment scheduling system in place, the company can avoid spending time and resources on managing appointments manually.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container textDevLay pb-4">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold">Conclusion
                                </h2>
                                <p className='lead_smd text-justify'>Overall, this software provides many benefits for telecom companies. By using this type of software, telecom companies can increase customer satisfaction, improve employee productivity, and reduce costs.
                                </p>
                            </div>
                        </div>
                    </section>

                </div>
                
            <Footer />
            </div>
        </>
    )
}

export default Telecommunication