import axios from 'axios';
import React, { useEffect } from 'react'

function MarkerUp({ serverURL }) {

    useEffect(() => {

        async function callNow() {

            const data = window.location.href.split("/");

            const code = data[data.length - 1];

            const updateTheUrl = await axios.post(serverURL + "/linksT/trackThis", { "code": code });
            if (updateTheUrl.status === 200) {
                window.location.href=updateTheUrl.data;
            } else {
                console.log(updateTheUrl.data)
            }
        }
        callNow();
    }, [])

    return (
        ""
    )
}

export default MarkerUp