import React, {useEffect} from 'react';
import AutomotiveSectorBannerPng from "../../images/Automotive Sector banner.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Automotive() {

    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <>
            <div className="heWhoRemains">
                <Header />
                <div>
                    <section>
                        <div className="container mt-5">
                            <div className="row">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">
                                        <h1 className="fw-bold mainHomeTitle">Schedule AI for
                                            <span style={{ color: "#0B5ED7" }}> Automotive Sector
                                            </span>
                                        </h1>
                                        <p className="lead_smd">
                                            When it comes to running a successful automotive business, efficiency is key. That's why appointment scheduling software can be such a valuable tool for streamlining your operation. With the ability to automate and keep track of appointments, you can free up time and resources that can be better spent elsewhere.
                                        </p>
                                        <a href="/login"><button
                                            className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                                            Demo</button></a>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="w-100" src={AutomotiveSectorBannerPng} alt='Automotive Sector banner' />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold">Benefits of Schedule AI for Automotive Industry
                                </h2>
                            </div>
                            <div className="row">
                                <ul>
                                    <li className='liListDevind'>
                                        It can help you save time by keeping all of your appointments in one place. This can be a big time-saver when you're trying to juggle multiple appointments at once.
                                    </li>

                                    <li className='liListDevind'>
                                        It can help you keep track of your customer's information and preferences.
                                    </li>

                                    <li className='liListDevind'>
                                        It can help you create and send out reminders for upcoming appointments.
                                    </li>

                                    <li className='liListDevind'>
                                        It can help you save time, keep track of your customers, and provide them with the best possible service.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container textDevLay pb-4">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold">Conclusion
                                </h2>
                                <p className='lead_smd text-justify'>This software can also help you boost revenue and improve customer satisfaction. By keeping track of customer preferences and providing reminders and alerts, you can make sure that they always have a positive experience with your business. What's more, by offering online booking, you can make it even easier for customers to do business with you.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default Automotive



