import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import educationPng from "../../images/education.png";
import "./Cards.css";
import HealthcareScheduleaiPng from "../../images/Healthcare scheduleai.png";
import { Link, Router, useLocation } from 'react-router-dom';

import dots1 from "../../images/dots/scheduleAIDots (1).jpg"
import dots2 from "../../images/dots/scheduleAIDots (2).jpg"
import dots3 from "../../images/dots/scheduleAIDots (3).jpg"
import dots4 from "../../images/dots/scheduleAIDots (4).jpg"
import dots5 from "../../images/dots/scheduleAIDots (5).jpg"
import dots6 from "../../images/dots/scheduleAIDots (6).jpg"


const Cards = () => {

    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <>
            <section className="cardMainWrapper">
                <div className='container'>
                    <div className="row cardRow">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="my-2">
                                <div className="card DevCard h-100" data-aos="flip-left" data-aos-duration="1000">
                                    <div className="card-body">
                                        <img className='cardImg' src={dots1} />
                                        <h5 className="card-title cardTitleDev" data-aos="fade-left" data-aos-duration="1000">Talk with our Customers</h5>
                                        <p className="card-text cardPara">1 hour Round Robin</p>
                                        <Link to="/login" className='devBtn'>Book Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="my-2">
                                <div className="card DevCardT h-100" data-aos="flip-right" data-aos-duration="1200">
                                    <div className="card-body">
                                        <img className='cardImg' src={dots2} />
                                        <h5 className="card-title cardTitleDev" data-aos="fade-left" data-aos-duration="1000">Personalized Scheduling page</h5>
                                        <p className="card-text cardPara">30 mins, One-to-One</p>
                                        <Link to="/login" className='devBtn'>Book Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row cardRow">
                        <div className="col-lg-6 col-md-6 col-12 ">
                            <div className="my-2">
                                <div className="card DevCardTwM" data-aos="zoom-in-up" data-aos-duration="2000">
                                    <div className="card-body">
                                        <img className='cardImg' src={dots3} />
                                        <h5 className="card-title cardTitleDev" data-aos="fade-left" data-aos-duration="1000">Sales Demo</h5>
                                        <p className="card-text cardPara">30 mins collective</p>
                                        <Link to="/login" className='devBtn'>Book Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="my-2">
                                <div className="card DevCardTwM" data-aos="zoom-in-up" data-aos-duration="2000">
                                    <div className="card-body">
                                        <img className='cardImg' src={dots4} />
                                        <h5 className="card-title cardTitleDev" data-aos="fade-left" data-aos-duration="1000">Appointment with Doctor</h5>
                                        <p className="card-text cardPara">30 mins collective</p>
                                        <Link to="/login" className='devBtn'>Book Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row cardRow">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="my-2">
                                <div className="card DevCardTw h-100" data-aos="zoom-in-up" data-aos-duration="2000">
                                    <div className="card-body">
                                        <img className='cardImg' src={dots5} />
                                        <h5 className="card-title cardTitleDev" data-aos="fade-left" data-aos-duration="1000">Calendar Sync</h5>
                                        <p className="card-text cardPara">30 mins collective</p>
                                        <Link to="/login" className='devBtn'>Book Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="my-2">
                                <div className="card DevCardTwt h-100" data-aos="zoom-in-left" data-aos-duration="2200">
                                    <div className="card-body">
                                        <img className='cardImg' src={dots6} />
                                        <h5 className="card-title cardTitleDev" data-aos="fade-left" data-aos-duration="1000">Schedule a Call </h5>
                                        <p className="card-text cardPara">1 hour group meeting</p>
                                        <Link to="/login" className='devBtn'>Book Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>





        </>
    )
}

export default Cards