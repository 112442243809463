import React from 'react';
import iprLogo from "../../images/ipr.png";
import huaweiPng from "../../images/huawei.png";
import testClient20Png from "../../images/testclient20.jpg";
import sasPng from "../../images/sas.png";
import worldPng01Png from "../../images/world png-01.png";
import percentPng from "../../images/percent.png";
import preSalesPng from "../../images/Pre Sales.png";
import lessSecurityRiskPng from "../../images/less-security risk.png";
import conveyPresentDayProgrammingPng from "../../images/convey-present-day-programming.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { Tab, Tabs, Accordion } from 'react-bootstrap';
import InterationsPng from "../../images/interations.png";
import CalendaryNewsPng from "../../images/Calendary news.png";
import authorityGroupPng from "../../images/authority group.png";
import CalendarySchedulePng from "../../images/Calendar Schedule.png";
import enterpriseBannerPng from "../../images/enterprise banner.png";
import BookingSchedulePng from "../../images/Booking Schedule.png";
import { Link } from 'react-router-dom';
import Slider from '../cards/Slider';
import {Helmet} from "react-helmet";
import ScrollTopButton from '../scrolltopbtn/ScrollTopButton';


function About() {
    return (
        <div className="heWhoRemains">
            <Helmet>
                <title>About us | Scheduleai</title>
                <meta name="description" content="For countless businesses worldwide, Scheduleai handles the task of scheduling, freeing up your time to accomplish more." />
            </Helmet>
            <Header />
            <div>
                <section>
                    <div className="container-fluid pt-5 pb-5 d-flex align-items-center">
                        <div className="container" style={{ width: "70%" }}>
                            <h2 className="display-4 text-center fw-bold" style={{ fontSize: "2.5em" }}><span className="text-primary">Improving Scheduling</span> for Millions
                            </h2>
                            <p className="lead_smd text-center pt-2" style={{ fontSize: "1.1em", letterSpacing: "1px" }}>From small businesses to many companies globally, countless individuals across the globe trust Scheduleai to close deals, recruit talent, foster connections, and expand their businesses at an accelerated pace.
                            </p>
                            <div className="cta d-flex justify-content-center">
                                <Link to="/login" className='heroBtn'>Login</Link>
                            </div>
                        </div>
                    </div>
                </section>


                <div className="container pt-5 pb-5">
                    <div className="row">
                        <div className="col-12 col-lg-3 col-md-6">
                            <h2 className="display-4 text-center fw-bold"><span className="text-primary">5</span>m</h2>
                            <h5 className="text-center" style={{ letterSpacing: "1px" }}>Clients around <br />the world</h5>
                        </div>
                        <div className="col-12 col-lg-3 col-md-6">
                            <h2 className="display-4 text-center fw-bold"><span className="text-primary">20</span>k</h2>
                            <h5 className="text-center" style={{ letterSpacing: "1px" }}>Companies use <br />{process.env.REACT_APP_DOMAINNAME.split(".")[0]}</h5>
                        </div>
                        <div className="col-12 col-lg-3 col-md-6">
                            <h2 className="display-4 text-center fw-bold"><span className="text-primary">165</span>+</h2>
                            <h5 className="text-center" style={{ letterSpacing: "1px" }}>Nations with<br /> monthly dynamic <br />clients</h5>
                        </div>
                        <div className="col-12 col-lg-3 col-md-6">
                            <h2 className="display-4 text-center fw-bold"><span className="text-primary">150</span>+</h2>
                            <h5 className="text-center" style={{ letterSpacing: "1px" }}>Partner integrations</h5>
                        </div>
                    </div>
                </div>

                <Slider />

                <div className="container mt-5">
                    <div className="row pt-2">
                        <div className="col-lg-6 col-md-6 d-flex align-items-center">
                            <img className="w-100 homeTabImgDev" src={worldPng01Png} alt="world globe" />
                        </div>
                        <div className="col-lg-6 col-md-6 d-flex align-items-center">
                            <div className="mt-4">
                                <h1 className="display-4 fw-bold" style={{ letterSpacing: "1px", fontSize: "3em" }}>Our <span
                                    className="text-primary">
                                    Purpose</span>
                                </h1>
                                <p className="lead_smd"> From its simple and easy beginnings as a time-saving scheduling link, we've evolved into a versatile scheduling hub for teams and enterprises globally. We firmly believe that a scheduling automation platform should offer comprehensive support for a wide range of meeting situations, particularly for teams' various meetings with external partners on a grand scale.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container pt-5">
                    <div className="column">
                        <h2 className="text-center display-5 fw-bold">Our Pledge to Our Valued Customers</h2>
                        <p className="text-center lead_smd" style={{ fontSize: "1.2em" }}>{process.env.REACT_APP_DOMAINNAME.split(".")[0]} Scheduleai is dedicated to providing a robust and user-friendly scheduling platform that aids our customers in accelerating deal closures, landing top talent, nurturing connections, and propelling business expansion with enhanced speed.
                            .</p>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6 col-md-6 pt-5 align-items-center justify-content-center d-flex">
                            <div className="mt-4 ">
                                <h2 className="display-5 fw-bold" style={{ color: "#0B3558" }}>Boosting Business Growth
                                </h2>
                                <p className="lead_smd">Enhance meeting outcomes organization-wide to bolster revenue, retain clientele, attract skilled individuals, and broaden operations.</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-md-6 pt-5">
                            <img className="SchAvailDevImg" src={percentPng} alt="percent-hit-number" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-6 pt-5 align-items-center justify-content-center d-flex">
                            <div className="mt-4 ">
                                <h2 className="display-5 fw-bold" style={{ color: "#0B3558" }}>Elevating Team Efficiency and Productivity
                                </h2>
                                <p className="lead_smd">Supply instinctive scheduling software that seamlessly integrates into employees' workflows and aligns with department-specific requirements.</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 pt-5">
                            <img className="SchAvailDevImg" src={preSalesPng} alt="pre-sales" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6 pt-5 align-items-center justify-content-center d-flex">
                            <div className="mt-4 ">
                                <h2 className="display-5 fw-bold" style={{ color: "#0B3558" }}>Mitigating Security and Compliance Concerns
                                </h2>
                                <p className="lead_smd">Protect an organization's security and risk stance during scaling, while ensuring adherence to regulatory mandates.</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 pt-5">
                            <img className="SchAvailDevImg" src={lessSecurityRiskPng} alt="less-security risk" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6 pt-5 align-items-center justify-content-center d-flex">
                            <div className="mt-4 ">
                                <h2 className="display-5 fw-bold" style={{ color: "#0B3558" }}>Delivering Cutting-Edge Software
                                </h2>
                                <p className="lead_smd">Provide a premier scheduling automation platform that grasps and adapts to evolving business demands, remaining at the forefront of modern software solutions.</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 pt-5">
                            <img className="SchAvailDevImg" src={conveyPresentDayProgrammingPng} alt="convey-present-day-programming" />
                        </div>
                    </div>

                </div>

                <div className="container mt-5">
                    <div className="column">
                        <h2 className="text-center display-4 fw-bold pt-5"><span style={{ color: "#0B5ED7" }}>Our</span> Story</h2>
                        <p className="text-center lead_smd" style={{ fontSize: "1.2em" }}>{process.env.REACT_APP_DOMAINNAME.split(".")[0]} is far beyond a planning stage, we're a group of
                            client fixated,<br /> mission-situated individuals who accept the sky is the limit.</p>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6 pt-5 align-items-center justify-content-center d-flex">
                            <div className="mt-4 ">
                                <h2 className="display-6 fw-bold" style={{ color: "#0B3558" }}>Meet our <span style={{ color: "#0B5ED7" }}>authority
                                    <br />group</span>
                                </h2>
                                <p className='lead_smd'>Rahul Chauhan established {process.env.REACT_APP_DOMAINNAME.split(".")[0]} in 2013 through<br /> sheer coarseness, steadiness, and the
                                    total
                                    and <br />unadulterated purging of his own bank accounts. <br />With everything on the line, he
                                    transformed
                                    his<br /> vision of basic planning for everybody into an<br /> energetic, developing stage that
                                    oversees<br />
                                    booking for in excess of 10 million people <br />and organizations.</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 pt-5">
                            <img className="SchAvailDevImg" src={authorityGroupPng} alt="authority group" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-6 pt-5">
                            <img className="SchAvailDevImg" src={CalendaryNewsPng} alt={`${process.env.REACT_APP_DOMAINNAME.split(".")[0]} news`} />
                        </div>
                        <div className="col-12 col-lg-6 pt-5 align-items-center justify-content-center d-flex">
                            <div className="mt-4 ">
                                <h2 className="display-5 smd_text fw-bold">{process.env.REACT_APP_DOMAINNAME.split(".")[0]} in the <span style={{ color: "#0B5ED7" }}>news</span>
                                </h2>
                                <p className='lead_smd'>Get the most recent {process.env.REACT_APP_DOMAINNAME.split(".")[0]} news. Furthermore, a media pack and instruments for writers.</p>
                            </div>
                        </div>
                    </div>


                </div>

                <div className="container mt-5">
                    <div className="row">
                        <div className="col">
                            <h6 className="text-primary text-center" style={{ letterSpacing: "2px" }}>THE TIME ECONOMY</h6>
                            <h2 className="text-center display-5 mb-4 fw-bold" style={{ color: "#0B3558" }}>A booking computerization stage
                                <br />worked for the Time Economy
                            </h2>
                        </div>
                    </div>
                    <div className="row d-none d-lg-flex">
                        <div className="col">
                            <Tabs
                                id="uncontrolled-tab-example">
                                <Tab eventKey="Center Planning Stage" title={<>
                                    Center Planning Stage

                                </>}>

                                    <div id="collapseOne" className="accordion-collapse collapse show  d-lg-block"
                                        aria-labelledby="headingOne" data-bs-parent="#myTabContent">
                                        <div className="accordion-body d-flex">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 d-flex align-items-center justify-content-cente">
                                                        <div className="column">
                                                            <h3 style={{ color: "#0B3558" }}>Center Booking Stage</h3>
                                                            <p className="lead_smd" style={{ fontSize: "1.1em" }}>Consequently book gatherings from your site,
                                                                have an online course or
                                                                className, or timetable calls with clients — while controlling your
                                                                accessibility and saving everybody time.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-6 mt-4">
                                                        <img className="w-100" src={CalendarySchedulePng} alt={`${process.env.REACT_APP_DOMAINNAME.split(".")[0]} schedule`} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>

                                <Tab eventKey="The Time Economy" title={<>
                                    The Time Economy</>}>
                                    <h2 className="accordion-header d-lg-none" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            The Time Economy
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse d-lg-block" aria-labelledby="headingTwo"
                                        data-bs-parent="#myTabContent">
                                        <div className="accordion-body">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                                        <div className="mt-4 ">
                                                            <h3 style={{ color: "#0B3558" }}>The Time Economy</h3>
                                                            <p className="lead_smd" style={{ fontSize: "1.1em" }}>Our most valuable asset is time — and the
                                                                following ten years will be
                                                                characterized by how we oversee it. {process.env.REACT_APP_DOMAINNAME.split(".")[0]} studied 1,000 information
                                                                laborers to dive more deeply into how they worth and utilize their time.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-6 mt-4">
                                                        <img className="w-100" src={BookingSchedulePng} alt="Booking Schedule" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>

                                <Tab eventKey="Industry Solutions" title={<>Industry Solutions</>}>
                                    <h2 className="accordion-header d-lg-none" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Industry Solutions
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse d-lg-block"
                                        aria-labelledby="headingThree" data-bs-parent="#myTabContent">
                                        <div className="accordion-body">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 d-flex align-items-center  justify-content-center">
                                                        <div className="mt-4 ">
                                                            <h3 style={{ color: "#0B3558" }}>Industry Solutions</h3>
                                                            <p className='lead_smd' style={{ fontSize: "1.1em" }}>Whether you lead a Business, Showcasing,
                                                                Enlisting, Client Achievement, Schooling or other group, we have
                                                                arrangements that meet your specialty needs.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-6 mt-4">
                                                        <img className="w-100" src={CalendaryNewsPng} alt={`${process.env.REACT_APP_DOMAINNAME.split(".")[0]} news`} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Calendary for Enterprise" title={<>{process.env.REACT_APP_DOMAINNAME.split(".")[0]} for Enterprise</>}>
                                    <h2 className="accordion-header d-lg-none" id="headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            {process.env.REACT_APP_DOMAINNAME.split(".")[0]} for Enterprise
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse d-lg-block" aria-labelledby="headingFour"
                                        data-bs-parent="#myTabContent">
                                        <div className="accordion-body">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                                        <div className="mt-4 ">
                                                            <h3 style={{ color: "#0B3558" }}>{process.env.REACT_APP_DOMAINNAME.split(".")[0]} for Enterprise</h3>
                                                            <p className='lead_smd' style={{ fontSize: "1.1em" }}>Many collaborators use {process.env.REACT_APP_DOMAINNAME.split(".")[0]}. Normalize in
                                                                one secure, midway oversaw
                                                                account where you have more control and they can work all the more
                                                                productively.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-6 mt-5">
                                                        <img className="w-100" src={enterpriseBannerPng} alt="enterprise banner" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>

                <Accordion flush className='d-lg-none'>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Center Booking Stage</Accordion.Header>
                        <Accordion.Body>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-lg-6 d-flex align-items-center justify-content-cente">
                                        <div className="column">
                                            <h3 style={{ color: "#0B3558" }}>Center Booking Stage</h3>
                                            <p style={{ fontSize: "1.1em" }}>Consequently book gatherings from your site,
                                                have an online course or
                                                className, or timetable calls with clients — while controlling your
                                                accessibility and saving everybody time.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 mt-4">
                                        <img className="w-100" src={CalendarySchedulePng} alt={`${process.env.REACT_APP_DOMAINNAME.split(".")[0]} schedule`} />
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1">
                        <Accordion.Header>The Time Economy</Accordion.Header>
                        <Accordion.Body>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                        <div className="mt-4 ">
                                            <h3 style={{ color: "#0B3558" }}>The Time Economy</h3>
                                            <p style={{ fontSize: "1.1em" }}>Our most valuable asset is time — and the
                                                following ten years will be
                                                characterized by how we oversee it. {process.env.REACT_APP_DOMAINNAME.split(".")[0]} studied 1,000 information
                                                laborers to dive more deeply into how they worth and utilize their time.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 mt-4">
                                        <img className="w-100" src={BookingSchedulePng} alt="BookingSchedule" />
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Industry Solutions</Accordion.Header>
                        <Accordion.Body>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                        <div className="mt-4 ">
                                            <h3 style={{ color: "#0B3558" }}>Industry Solutions</h3>
                                            <p style={{ fontSize: "1.1em" }}>Whether you lead a Business, Showcasing,
                                                Enlisting, Client Achievement, Schooling or other group, we have
                                                arrangements that meet your specialty needs.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 mt-4">
                                        <img className="w-100" src={CalendaryNewsPng} alt="CalendaryNews" />
                                    </div>
                                </div>
                            </div>

                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                        <Accordion.Header>{process.env.REACT_APP_DOMAINNAME.split(".")[0]} for Enterprise</Accordion.Header>
                        <Accordion.Body>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                        <div className="mt-4 ">
                                            <h3 style={{ color: "#0B3558" }}>{process.env.REACT_APP_DOMAINNAME.split(".")[0]} for Enterprise</h3>
                                            <p style={{ fontSize: "1.1em" }}>Many collaborators use {process.env.REACT_APP_DOMAINNAME.split(".")[0]}. Normalize in
                                                one secure, midway oversaw
                                                account where you have more control and they can work all the more
                                                productively.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <img className="w-100" src={enterpriseBannerPng} alt="enterprise Banner" />
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>



                </Accordion>

                <section className="bg-light pb-0">
                    <div className="container">
                        <div className="row">

                            <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                <div className="mt-4 ">
                                    <h1 className=" display-4 fw-bold smd_text">Integrations and Expansions
                                    </h1>
                                    <p className="lead_dev text-black" style={{ fontSize: "1.1em" }}>Help efficiency with combinations that
                                        overlay squarely into your work process.

                                    </p>
                                    <a href="/login"><button
                                        className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Explore
                                        Integrations</button></a>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 pt-5 pb-5">
                                <img className="SchAvailDevImg" src={InterationsPng} alt="interations" />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <ScrollTopButton/>
            <Footer />
        </div>
    )
}

export default About
