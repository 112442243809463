import React from 'react'
import Header from '../header/Header';
import Footer from '../footer/Footer';
import termAndConditionsPng from "../../images/term and conditions.png";
import "../../Custom.css";
function TermsAndConditions() {
  return (
    <div className='heWhoRemains'>
      <div>
        <Header />
        <div>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 d-flex justfy-content-center align-items-center">
                  <div className="p-5 mt-4">
                    <h1 className="display-4 fw-bold">Terms
                      <span> of Use</span>
                    </h1>
                  </div>
                </div>
                <div className="col-lg-6 d-flex justfy-content-center align-items-center mt-4">
                  <img className="w-100" src={termAndConditionsPng} alt="term and conditions" />
                </div>
              </div>

              <section>
                <div className="container mt-4 border-bottom">
                  <div className="row">
                    <div className="col-md-12 border-bottom">
                      <h3 className="mb-4">Terms of Use</h3>
                      <p>Effective June 29, 2021</p>
                      <p className="lead_smd">Our sincere gratitude for using Schedule AI. Consider these Terms of Use
                        (changeable words
                        “Term” or “Agreement”) as a legal agreement between the user(you) and the company
                        (Kcloudtechnologies.com). </p>
                      <p className="lead_smd">These terms are meant to regulate the use of all the data, software,
                        photographs, designs,
                        and other items that our company and our associates may facilitate to you (these all are
                        referred to as
                        “Documentation”), including services and our website, downloadable tools (defined in the
                        terms below),
                        software or plug-ins that are provided through any of our websites (the “Website” or
                        “Platform” and
                        together with the Materials, these “Service(s)”).</p>
                      <p className="lead_smd">People who create their account with Schedule AI are considered Schedule AI
                        “Users”.
                      </p>
                      <p className="lead_smd">People who are registered or not registered users of Schedule AI but have
                        scheduled a meeting
                        with a Schedule AI user as considered Schedule AI “Invitees”</p>

                      <p className="lead_smd">A person who may or may not be Schedule AI Invitee or Schedule AI User and
                        visits
                        the
                        Schedule AI Website is considered Schedule AI “ Viewer”.</p>

                      <p className="lead_smd">People who are entering on behalf of an organization or company will
                        represent
                        the
                        organization and the company should give them the authority to enter into the Term of
                        Use.
                        These users
                        will be considered “Authorized User”</p>

                      <p className="lead_smd">Kindly go through the terms and conditions thoroughly before visiting the
                        website. One using
                        the services will be considered as they have read the terms and conditions and agree to
                        abide by the same
                        (means that they have accepted our terms and conditions). If you don't agree to our
                        terms
                        and conditions,
                        then don't use the services. THESE TERMS CONTAIN A DISPUTE RESOLUTION AND ARBITRATION
                        PROVISION, INCLUDING
                        A className ACTION WAIVER THAT AFFECTS YOUR RIGHTS UNDER THESE TERMS AND WITH RESPECT TO
                        DISPUTES YOU MAY HAVE
                        WITH Schedule AI.</p>

                      <p className="lead_smd">IF YOU ARE A Schedule AI USER, AUTHORIZED USER, VIEWER, OR INVITEE LOCATED
                        IN
                        THE EUROPEAN
                        ECONOMIC AREA, SWITZERLAND, OR UNITED KINGDOM, THE DATA PROCESSING ADDENDUM ACCOMPANIES
                        THESE TERMS AND
                        SETS FORTH OTHER TERMS OF OUR AGREEMENT THAT APPLY SOLELY TO THE EXTENT ANY INFORMATION
                        YOU
                        PROVIDE TO
                        Schedule AI INCLUDES PERSONAL DATA OF INDIVIDUALS LOCATED IN THE EUROPEAN ECONOMIC
                        AREA,
                        SWITZERLAND, AND
                        THE UNITED KINGDOM.</p>


                      <p className="lead_smd"><strong>Scope:</strong> Schedule AI provides you with a smooth experience of
                        scheduling
                        appointments. Use the services only when you accept the terms and conditions. These
                        terms
                        and conditions
                        control you and the process of your Invitees' use of services.</p>

                      <p className="lead_smd">When using the features of the Services you are subject to any agreements,
                        policies, or
                        guidelines applicable to such features that may be posted from time to time. All such
                        agreements,
                        policies, or guidelines, including the Privacy Notice, the End User License Agreement,
                        and
                        the Data
                        Processing Addendum (as applicable) are hereby incorporated by reference into these
                        Terms.
                      </p>
                      <h5 className="mb-4 mt-4">General Use:</h5>
                      <p className="lead_smd"> Note that these services are for individuals and not for commercial
                        (reselling:
                        “Permitted
                        Purpose”) use. We hope you have pleasant experiences using it!</p>

                      <p className="lead_smd">Subject to these Terms, we allow you a restricted, individual, not limited
                        to
                        you only, and
                        non-transferable license both for use and display of the Documentation and the right to
                        access and use the
                        Service to have a Free account or the Paid account (stated below). The right of usage of
                        the
                        Services is
                        limited to the scope of the access granted in this Agreement and not beyond that.</p>

                      <p className="lead_smd">The following points are highly not acceptable by the customers ( directly
                        or
                        indirectly, or
                        as a representative of the company )</p>

                      <ol>
                        <li className="smd_order-list">Copy, modify or imitate works of the Services or
                          Documentation
                          wholly or
                          partly.</li>
                        <li className="smd_order-list">Allot, distribute, transfer, license or sublicense, rent,
                          lend,
                          lease, charge,
                          or otherwise to the Services or Documentation available.</li>
                        <li className="smd_order-list">Reverse implication, dismantle, disband, decompile, adapt,
                          decode, disassemble
                          or otherwise try to derive or have access to any software component of the Services,
                          wholly or partly.
                        </li>
                        <li className="smd_order-list">Try to eradicate or eliminate any registered notices from the
                          Services or
                          Documentation.</li>
                        <li className="smd_order-list"> Deliberately misusing or fiddling with the Services or
                          Documentation in any
                          manner or for any purpose that invades or violates the intellectual property rights
                          or
                          other rights or
                          laws.</li>
                      </ol>
                      <p className="lead_smd_smd">If you make copies of any portion of this Website while engaging in
                        Permitted Purposes
                        then you agree to keep on these copies all of our copyright and other proprietary
                        notices as
                        they appear
                        on this Website.</p>

                      <p className="lead_smd">Subject to these Terms and Conditions, we allow you a restricted,
                        individual,
                        not limited to
                        you only, and non-transferable license to download, install and run any software,
                        plug-ins,
                        information,
                        or other content that is explicitly recognized within the website which is available to
                        download
                        exclusively in connecting to our website or other Schedule AI browser extensions.</p>

                      <p className="lead_smd">Such Downloadable Tools are also subject to the additional terms provided to
                        you
                        upon
                        download, including without limitation our End User License Agreement.</p>

                      <p className="lead_smd">These services are intended for adults ( 18 years and above) so individuals
                        under 13 years
                        of age except as provided by the Educational Use Section of these Terms. If one
                        registers
                        with Schedule AI,
                        this means one confirms that he/she is 18 years and above and all its Schedule AI Invitees
                        and
                        Users are at
                        least 13 years of age. It will be a breach of the Terms and Conditions if an account is
                        created with false
                        information or on behalf of another person who is less than 18 years of age.</p>

                      <h5 className="mb-4 mt-4">Educational Use:</h5>
                      <p className="lead_smd">Children under the age of 13 years are forbidden from the use of Services
                        but
                        can use the
                        Schedule AI under an account created by their parents/guardians or school/educator (18
                        years
                        above). In this
                        case, parents/guardians or school/educators are responsible for complying with the U.S.
                        Family Educational
                        Rights and Privacy Act ("FERPA") and Children’s Online Privacy Protection Act of 1998
                        and
                        its rules
                        (collectively, “COPPA”).</p>

                      <p className="lead_smd">To the extent that Schedule AI has access to “education records,” as
                        defined by
                        FERPA and
                        its implementing regulations, it is deemed and shall function as a “school official”
                        with
                        “legitimate
                        educational interests” when fulfilling its responsibilities under this Agreement, and
                        both
                        parties agree
                        that you have “direct control” over the use of “education records” as these terms are
                        defined under FERPA.
                        Schedule AI agrees to comply with its obligations under FERPA, including but not
                        limited to
                        the
                        limitations on re-disclosure of personally identifiable information from education
                        records
                        set forth in
                        FERPA and with the terms set forth below.</p>

                      <p className="lead_smd">This means you shall notify those students' parents/guardians of the
                        personally
                        identifiable
                        information that you will collect and share with us and obtain parental/guardian consent
                        before its
                        students sign up or use the Services. When obtaining such consent, you shall provide
                        parents/guardians
                        with a copy of our Privacy Policies, including our FERPA and COPPA Privacy Policy. All
                        the
                        consents should
                        be documented( maintained in a file) so that you can give us when required. Along with
                        this
                        you shall be
                        responsible for verification of the student-parent relationship and shall be accountable
                        for
                        compliance
                        with the right of a parent to assess personal information provided by the child. You
                        shall
                        comply with
                        such requirements by requesting and providing Schedule AI with a data subject request
                        for
                        the
                        aforementioned data, or any changes to such data requested by the parent, and providing
                        the
                        data to the
                        parent after verification of the student parent relationship. If you are located outside
                        of
                        the United
                        States, Schedule AI shall rely upon you and you shall obtain any required consents or
                        approvals from the
                        parent or guardian of any student covered by similar laws and, as a condition to your
                        and
                        your students'
                        use of the Services, you shall comply with such laws.</p>

                      <h5 className="mb-4 mt-4">Account Setup</h5>

                      <p className="lead_smd">One needs to set up a Schedule AI account first to use the website. Two
                        options
                        are available
                        for it. (1) Free Account or (2) Paid Account. Calendar authorizes the users to set up
                        only
                        one Free
                        Account and has a right to abort any additional Free Accounts set up by the user and any
                        Free Account that
                        is not used for the past 6 months.</p>
                      <p className="lead_smd">At the time of signing up (creating an account), Calendar requires
                        registration-related
                        information, like your name and email address. On completing the form, we reserve the
                        right
                        to approve or
                        not to approve the creation of an account. The email with guidelines to complete the
                        registration will be
                        sent to the mentioned email address if your request for the set-up of an account is
                        approved. One needs to
                        provide complete, accurate, and true current information as long as one uses the
                        account.
                        This information
                        can be changed directly by the user by logging into their account. It is the duty of the
                        user to comply
                        with Terms and Conditions when using the website directly or through the account that
                        has
                        been set up
                        through or on the website. Given its user’s account, it is the user's job to acquire and
                        maintain all
                        equipment and services required for access to and use of this Website as well as pay
                        charges
                        related to
                        it. Users are required to set a strong and secured password and maintain the privacy of
                        the
                        password(s).
                        Make an extra effort to secure your password especially when a third party is involved.
                        In
                        case you think
                        there is a violation of your password or security for this Website, immediately contact
                        us
                        at
                        support@scheduleai.co</p>

                      <p className="lead_smd">For Paid Accounts, Schedule AI requires your billing information. This
                        information
                        is secured
                        and safe in compliance with our Privacy Notice.</p>

                      <p className="lead_smd">Although your account is yours, Schedule AI governs the URL(s) allotted to the
                        account. We
                        have the right to change or deactivate the URL(s) if required. For the Paid Accounts
                        that
                        are paid and
                        managed by a third party ( e.g. employer ), the third party has the right to regulate
                        it,
                        manage it, and
                        get reports on the use of the account. The third party does not have rights on the
                        personal
                        account for
                        which they don't pay.</p>

                      <h5 className="mb-4 mt-4">Subscription Period and Subscription Fees.</h5>

                      <p className="lead_smd">The applicable fee related to your use of this Website is given in detail on
                        our
                        pricing
                        page. One needs to pay according to the usage of services and subscriptions. Once the
                        payment method is
                        provided, one agrees to grant us the right to charge the applicable fees along with the
                        taxes and other
                        charges, applicable on regular intervals according to the particular subscription and
                        utilized services
                        acquired.</p>

                      <p className="lead_smd">Charges are applicable for Paid Accounts based on the type selected. For
                        renewal, we will
                        charge the then-current fee plus the taxes for the type by the approved payment method.
                        Each
                        Renewal Term
                        may include a price increase of the Consumer Price Index (“CPI”) + 3% unless we notify
                        you
                        of a different
                        rate before each Renewal Term starts. Note that all payments are non-refundable.To the
                        extent that payment
                        mechanisms are provided through third parties, you agree that Schedule AI will have no
                        liability to you
                        arising out of the acts or omissions of such third parties. If applicable, you agree to
                        maintain current
                        billing information.</p>

                      <p className="lead_smd">WE ADVISE YOU TO GET THESE TERMS AND CONDITIONS PRINTED TO FILE FOR FURTHER
                        REFERENCE. ONCE
                        THE PAID ACCOUNT IS CREATED, THIS MEANS YOU GRANT CALENDARY THE CONSENT TO CHARGE (THE
                        AMOUNT DUE FOR THE
                        INITIAL SUBSCRIPTION PERIOD AND ADDED SUBSCRIPTION PERIODS UNTIL YOUR ACCOUNT IS
                        CANCELED OR
                        TERMINATED)
                        DIRECTLY OR THROUGH THE PAYMENT PROCESSES OF THE PAYMENT METHOD PROVIDED.</p>
                      <h5 className="mb-4 mt-4">Renewal/Cancellation</h5>
                      <p className="lead_smd">To cancel the subscription for an account, contact us at
                        support@scheduleai.co The
                        cancellation is with immediate effect. After cancellation, one cannot use the Services
                        or
                        the Platform and
                        cannot claim for refund of any fee paid by them. If the present subscription period is
                        about
                        to end and
                        the subscription is not canceled, it will automatically get renewed for the same
                        subscription type and
                        period.</p>

                      <h5 className="mb-4 mt-4">Legal Compliance</h5>
                      <p className="lead_smd">You agree to obey all applicable domestic and international laws, statutes,
                        ordinances, and
                        regulations regarding your use of Schedule AI Website and agree to inform us immediately
                        if
                        you witness and
                        get to know of any unauthorized or illegal use of the Website. We reserve the rights to
                        scrutinize
                        complaints and report a breach of our Terms and Conditions and take action that we think
                        is
                        appropriate
                        like cancellation of user’s account, reporting to law enforcement officials, regulators,
                        or
                        third parties,
                        and disclosing personal information of the account holders like e-mail addresses, usage
                        history, posted
                        materials, IP addresses, and traffic information to them as allowed under our Privacy
                        Notice.</p>

                      <h5 className="mb-4 mt-4">Intellectual Property</h5>

                      <p className="lead_smd">Our company is protected under international copyright laws. Due to which
                        users
                        are strictly
                        forbidden from copying, redistribution, use, or publication of any portion of our
                        Website or
                        those of our
                        affiliates or other companies such as logo, service marks/trademarks, and any and all
                        intellectual
                        property provided in connection with the Services to Customer or any Authorized User.
                        Grants
                        given for use
                        of Services do not include ownership rights of any kind in our Website or Materials. We
                        reserve all rights
                        not explicitly given to the Customer and mentioned in this Agreement. Except for the
                        limited
                        rights and
                        licenses expressly granted under this Agreement, nothing in this Agreement grants, by
                        implication, waiver,
                        estoppel, or otherwise, to Customer or any third party any intellectual property rights
                        or
                        other rights,
                        title, or interest in or to the Schedule AI IP.</p>

                      <h5 className="mb-4 mt-4">Notices and Messages</h5>
                      <p className="lead_smd">You give us the consensus to send you emails that include information
                        related to
                        the
                        Services e.g. notification of charges and fees. If you are an Invitee who has provided
                        an
                        email, then you
                        give us the consensus to send the emails that include transactional information like
                        booking
                        confirmations
                        and reminders. If you are an Invitee who has provided a phone number then you give us
                        the
                        consensus to
                        send the SMS that includes transactional information like booking confirmations and
                        reminders.</p>

                      <p className="lead_smd">You accept that any electronic or written communication that includes any
                        announcements,
                        notices, agreements, disclosures, or others will satisfy any legal communication needs.
                        You
                        will keep your
                        contact details up to date and make sure that you receive your communication either
                        through
                        email or SMS.
                        If, as an Invitee and/or Schedule AI User you do consent to the use of SMS
                        notifications as
                        described
                        herein, you also agree to comply with the terms and conditions of the Twilio Acceptance
                        Use
                        Policy when
                        sending and/or receiving such SMS notifications.</p>

                      <h5 className="mb-4 mt-4">Links to Other Websites.</h5>
                      <p className="lead_smd">Merely as a convenience to you, we may include some third-party links on our
                        website. By
                        linking to these websites, we do not create or have an affiliation with or sponsor such
                        third party
                        websites. Note that we do not provide any endorsement, guarantee, warranty, or
                        recommendation of such
                        third party websites and we have no check over their legal documents and privacy
                        practices.
                        By using the
                        third party link, you may end up in leakage of your information or give them control of
                        your
                        information.
                        Note that when you access these third-party sites then you are doing this at your own
                        risk
                        and Schedule AI
                        is not responsible for anything in that respect.</p>

                      <p className="lead_smd">Schedule AI has no control over the legal documents and privacy practices
                        of
                        third party
                        websites, and by using them, you may be giving those third parties permission to use or
                        control your
                        information in ways Schedule AI would not. As such, you access any third-party websites
                        at
                        your own risk.
                      </p>

                      <h5 className="mb-4 mt-4">Forums</h5>
                      <p className="lead_smd">Certain areas of this Website are public-facing and allow you to interact
                        with
                        others (e.g.,
                        the Schedule AI blog and the idea portal) (together, “Forums”). These Forums may permit
                        you
                        to submit
                        feedback, information, data, text, software, messages, or other materials (each, a
                        "Submission"). We love
                        hearing from our Users and Invitees! It's incredibly helpful and helps us to make a
                        better
                        Schedule AI
                        for all. If you choose to provide a Submission, either directly to us or via a Forum,
                        you
                        agree that we
                        can use them without any restrictions or compensation to you.</p>

                      <p className="lead_smd">Any information that you post in a Forum might be read, collected, and used
                        by
                        others who
                        access it. To request the removal of your information from a Forum, contact
                        support@scheduleai.co In
                        some cases, we may not be able to remove your information, in which case we will let you
                        know that, and
                        why.</p>

                      <p className="lead_smd">You agree that you are solely responsible for all of your Submissions and
                        that
                        any such
                        Submissions are considered both non-confidential and non-proprietary. If you use Forums,
                        you
                        also
                        understand that anyone could see your Submissions. Schedule AI does not endorse, nor
                        are we
                        required to
                        pre-screen your Submissions (although we might), and, like elsewhere on the Internet,
                        Submissions from
                        other people could offend you. Further, we do not guarantee that you will be able to
                        edit or
                        delete any
                        Submission you have submitted.</p>

                      <p className="lead_smd">By submitting any Submission, you are promising us that you own all rights
                        in
                        your
                        Submission, your Submission does not infringe on the rights of others, your Submission
                        does
                        not violate
                        any laws or regulations, and your Submission is not in poor taste.</p>

                      <p className="lead_smd">You grant to us an irrevocable royalty-free license to copy, modify, publish
                        and
                        display
                        your Submission, and to make, use (and permit others to use) your Submission for any
                        purpose
                        (including,
                        without limitation, to use your Submission in our Website).</p>

                      <p className="lead_smd">We are not obligated to publish any information or content on our Forum and
                        can
                        remove
                        information or content at our sole discretion, with or without notice.</p>

                      <h5 className="mb-4 mt-4">Unauthorized Activities</h5>
                      <p className="lead_smd">Use of Services is limited to Permitter Purpose only for a user and beyond
                        these
                        are
                        strictly prohibited. If used other than these then it will be considered unauthorized
                        and
                        breaching
                        various United States and international copyright laws which may result in discontinuing
                        of
                        the Services
                        or deletion of your Schedule AI account. Listing a few of the examples (NOT a complete
                        list )
                        of what you
                        are not allowed to do. User is not authorized to use these Services in any way( unless
                        you
                        have written
                        permission from us)</p>
                      <ul>
                        <li>One is not allowed to create a similar product, act as a dealer, and sell it to a
                          third
                          party.</li>
                        <li>One is not allowed to use services in a manner that restructures or modifies,
                          displays
                          or performs it
                          publicly, replicates or allot any of the Services.</li>
                        <li>Not allowed to breach any rules, the relevant law, or the Terms of the Agreement
                          (not
                          restricted to
                          the region where the User/Invitee/ Authorized User belongs).</li>
                        <li>Not allowed to harass, stalk, bother, harm, or ill-treat another individual,
                          organization, or business
                        </li>
                        <li>Not allowed to reveal any information that you don't have permission to.</li>
                        <li>Not allowed to pretend or misrepresent to be any person or entity, or falsify any
                          personal
                          information.</li>
                        <li>Not allowed to hinder with or disturb the Services or servers or networks connected
                          to
                          the Services.
                        </li>
                        <li>In terms of Services one is not allowed to apply data mining, robotics, or other
                          means
                          to data
                          collection or extraction methods.</li>
                        <li>Not allowed to diffuse any virus in the code or harm it in any respect.</li>
                        <li>Not allowed to use means like hacking, password mining, etc to get illegal access to
                          whole or part of
                          the Services or any other accounts, computer systems, or networks connected to the
                          Services.</li>
                        <li>Unless and until using authorized Schedule AI payment integration, one is not
                          allowed
                          to misuse
                          Payment Card Industry data by storing or submitting.</li>
                        <li>To disclose any types of information listed in the California Customer Records
                          statute
                          (Cal. Civ. Code
                          § 1798.80(e)) or other relevant privacy regulations, including medical or health
                          insurance information,
                          education information, employment information, credit or debit card information, or
                          any
                          other financial
                          information, driver’s license, or other government identification number.</li>
                        <li>To disclose or collect any types of information considered to be protected
                          classNameification
                          characteristics under California or other relevant law, including Age (if 40 years
                          or
                          older), race,
                          color, ancestry, national origin, citizenship, religion or creed, marital status,
                          medical condition,
                          physical or mental disability, sex (including gender, gender identity, gender
                          expression, pregnancy or
                          childbirth, and related medical conditions), sexual orientation, veteran or military
                          status, and genetic
                          information (including familial genetic information); and</li>
                        <li>To disclose or collect any information defined by relevant laws as biometric
                          information, or any
                          information that falls within the definition of “special categories of data” under
                          EU
                          Data Protection
                          Laws, “protected health information” under California or other relevant law or
                          regulations. “Special
                          categories of data” include, for example, information about an individual’s race,
                          ethnic
                          origin,
                          politics, religion, trade union membership, genetics, biometrics, health, sex life,
                          sexual orientation,
                          or criminal records.</li>
                      </ul>
                      <h5 className="mb-4 mt-4">Indemnification</h5>
                      <p className="lead_smd">You alone are responsible for any violation of these Terms by you. You agree
                        to
                        indemnify
                        and hold Schedule AI, its parents, subsidiaries, affiliates, officers, agents,
                        employees,
                        resellers or
                        other partners, and licensors harmless from any claim, demand, loss, or damages,
                        including
                        reasonable
                        attorneys’ fees (“Losses”), resulting from any third-party claim, suit, action, or
                        proceeding
                        (“Third-Party Claim”) that the Customer Data, or the use thereof in accordance with this
                        Agreement,
                        infringes or misappropriation such third party’s intellectual property rights or
                        violates
                        any applicable
                        law or regulation and any Third-Party Claims based on Customer’s or any Authorized
                        Users’
                        (i) negligence
                        or willful misconduct; (ii) use of the Services in a manner not authorized by this
                        Agreement; (iii) use of
                        the Services in combination with data, software, hardware, equipment, or technology not
                        provided by
                        Schedule AI or authorized by Schedule AI in writing; or (iv) modifications to the
                        Services
                        not made by
                        Schedule AI, provided that Customer may not settle any Third-Party Claim against
                        Schedule AI unless
                        Schedule AI consents to such settlement, and further provided that Schedule AI will
                        have
                        the right, at
                        its option, to defend itself against any such Third-Party Claim or to participate in the
                        defense thereof
                        by counsel of its own choice.</p>
                      <h5 className="mb-4 mt-4">Warranty Disclaimer</h5>
                      <p className="lead_smd_smd_upp">THE SERVICES ARE PROVIDED "AS IS" AND Schedule AI HEREBY DISCLAIMS
                        ALL
                        WARRANTIES,
                        WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE. Schedule AI SPECIFICALLY DISCLAIMS
                        ALL
                        IMPLIED
                        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
                        NON-INFRINGEMENT, AND ALL
                        WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE PRACTICE. Schedule AI MAKES
                        NO
                        WARRANTY OF ANY
                        KIND THAT THE Schedule AI IP, OR SERVICES, OR ANY PRODUCTS OR RESULTS OF THE USE
                        THEREOF,
                        WILL MEET
                        CUSTOMER'S OR ANY OTHER PERSON'S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY
                        INTENDED RESULT,
                        BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM, OR OTHER SERVICES, OR BE SECURE,
                        ACCURATE,
                        COMPLETE, FREE
                        OF HARMFUL CODE, OR ERROR FREE. Schedule AI STRICTLY DISCLAIMS ALL WARRANTIES, WITH
                        RESPECT
                        TO ANY
                        THIRD-PARTY INTEGRATIONS.

                        THIS MEANS THAT WE DO NOT PROMISE YOU THAT THE SERVICES ARE FREE OF PROBLEMS. WITHOUT
                        LIMITING THE
                        GENERALITY OF THE FOREGOING, WE MAKE NO WARRANTY THAT THE SERVICES WILL MEET YOUR
                        REQUIREMENTS OR WILL BE
                        UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, OR THAT DEFECTS WILL BE CORRECTED. WE MAKE
                        NO
                        WARRANTY AS TO
                        THE RESULTS THAT MAY BE OBTAINED OR AS TO THE ACCURACY OR RELIABILITY OF ANY INFORMATION
                        OBTAINED THROUGH
                        THE SERVICES. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU THROUGH
                        THE
                        SERVICES OR
                        FROM US OR OUR SUBSIDIARIES/OTHER AFFILIATED COMPANIES WILL CREATE ANY WARRANTY. WE
                        DISCLAIM
                        ALL EQUITABLE
                        INDEMNITIES.</p>

                      <h5 className="mb-4 mt-4">Limitation of Liability</h5>
                      <p className="lead_smd">Schedule AI is cannot be held responsible to you or others for any damage
                        caused
                        by you while
                        using the Services, or by displaying or downloading any documentation from or to the
                        website. In no case
                        Schedule AI will be liable for any UNDER OR IN CONNECTION WITH THIS AGREEMENT UNDER ANY
                        LEGAL
                        OR EQUITABLE
                        THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND
                        OTHERWISE, FOR
                        ANY: (a) CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED, OR PUNITIVE
                        DAMAGES; (b)
                        INCREASED COSTS, DIMINUTION IN VALUE OR LOST BUSINESS, PRODUCTION, REVENUES, OR PROFITS;
                        (c)
                        LOSS OF
                        GOODWILL OR REPUTATION; (d) USE, INABILITY TO USE, LOSS, INTERRUPTION, DELAY, OR
                        RECOVERY OF
                        ANY DATA, OR
                        BREACH OF DATA OR SYSTEM SECURITY; OR (e) COST OF REPLACEMENT GOODS OR SERVICES, IN EACH
                        CASE REGARDLESS
                        OF WHETHER Schedule AI WAS ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH
                        LOSSES OR DAMAGES
                        WERE OTHERWISE FORESEEABLE.</p>
                      <p className="lead_smd_smd_upp">IN NO EVENT WILL Schedule AI’S AGGREGATE LIABILITY OR
                      Schedule AI’S
                        INDEMNIFICATION OBLIGATIONS ARISING OUT OF OR RELATED TO THIS AGREEMENT UNDER ANY LEGAL
                        OR
                        EQUITABLE
                        THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND
                        OTHERWISE EXCEED
                        THE GREATER OF (I) THE AMOUNT PAID, IF ANY, BY YOU TO Schedule AI IN CONNECTION WITH
                        THESE
                        TERMS IN THE
                        TWELVE (12) MONTHS PRIOR TO THE ACTION GIVING RISE TO LIABILITY OR (II) INR 10,100.</p>
                      <p className="lead_smd_smd_upp">THIS LIMITATION OF LIABILITY IS PART OF THE BASIS OF THE AGREEMENT
                        BETWEEN YOU AND
                        Schedule AI AND SHALL APPLY TO ALL CLAIMS OF LIABILITY, EVEN IF Schedule AI HAS BEEN
                        TOLD
                        OF THE
                        POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF THESE REMEDIES FAIL THEIR ESSENTIAL PURPOSE.
                      </p>

                      <h5 className="mb-4 mt-4">Local Laws; Export Control</h5>
                      <p className="lead_smd">We have headquarters in the United States of America which controls and runs
                        our
                        Services.
                        So the entire or partial of the Services may not be suitable or available for use in
                        other
                        geographies.
                        Users will be responsible for following applicable local laws and regulations, if the
                        Services are used
                        outside the United States of America.</p>
                      <p className="lead_smd">Schedule AI and you shall comply with all relevant United States export
                        control
                        laws and
                        regulations. Without limiting the foregoing: (a) you acknowledge that the Services may
                        be
                        subject to the
                        United States Export Administration Regulations; (b) you affirm that you are not, and
                        you
                        will not (i)
                        permit Schedule AI Users or Invitees to access or use the Services in a United States
                        embargoed country;
                        (ii) or permit Schedule AI Users or Invitees on the U.S. Commerce Department’s Denied
                        Persons, Entity, or
                        Unverified Lists or (iii) permit Schedule AI Users or Invitees on the U.S. Treasury
                        Department’s list of
                        Specially Designated Nationals and Consolidated Sanctions list; and (c) you are
                        responsible
                        for complying
                        with any local laws and regulations which may impact your right to import, export,
                        access,
                        or use the
                        Services.</p>
                      <h5 className="mb-4 mt-4">Dispute Resolution and Arbitration; className Action Waiver.</h5>
                      <p className="lead_smd_smd_upp">THIS PORTION OF THE TERM AFFECTS OUR LEGAL RIGHTS SO PLEASE READ
                        THIS
                        CAREFULLY</p>
                      <p className="lead_smd">In case of any dispute or disagreement, this provision provides an easy,
                        quick
                        and efficient
                        solution Effectively, then, “dispute” is given the broadest meaning enforceable by law
                        and
                        includes any
                        claims against other parties relating to services or products provided or billed to you
                        (such as our
                        licensors, suppliers, dealers or third-party vendors) whenever you also assert claims
                        against us in the
                        same proceeding.</p>
                      <p className="lead_smd">This provision offers that all arguments and disagreements between you and
                        us
                        will be
                        resolved by binding arbitration because acceptance of these Terms establishes an
                        abdication
                        of your right
                        to file claims and all opportunities to be heard by a judge or jury. We believe that
                        arbitration is a
                        better method to resolve the issues than going legally or taking legal action. To be
                        clear,
                        there is no
                        judge or jury in arbitration, and court review of an arbitration award is limited. The
                        arbitrator must
                        follow this Agreement and can award the same damages and relief as a court (including
                        attorney’s fees).
                        YOU AND Schedule AI AGREE THAT ANY AND ALL DISPUTES, AS DEFINED ABOVE, WHETHER
                        PRESENTLY IN
                        EXISTENCE OR
                        BASED ON ACTS OR OMISSIONS IN THE PAST OR IN THE FUTURE, WILL BE RESOLVED EXCLUSIVELY
                        AND
                        FINALLY BY
                        BINDING ARBITRATION RATHER THAN IN COURT IN ACCORDANCE WITH THIS PROVISION.</p>

                      <h5 className="mb-4 mt-4">Pre-Arbitration Claim Resolution</h5>
                      <p className="lead_smd">In case of any dispute or disagreement we would first like you to send us
                        the
                        email( Email
                        address) stating the details like a) your name b) your contact details c) description of
                        issue d) solution
                        that you think will resolve this issue. This will give us the opportunity to understand
                        the
                        issue and try
                        to. Please give us 45 days to respond to the issue. If still after 45 days you do not
                        get a
                        response from
                        us, then you can pursue your issue in arbitration.</p>
                      <h5 className="mb-4 mt-4">Arbitration Procedures</h5>
                      <p className="lead_smd">If this provision applies and the dispute is not resolved as provided above
                        (Pre-Arbitration
                        Claim Resolution) either you or we may initiate arbitration proceedings. JAMS,
                        www.jamsadr.com, will
                        arbitrate all disputes, and the arbitration will be conducted before a single
                        arbitrator.
                        The arbitration
                        will be commenced as an individual arbitration, and will in no event be commenced as a
                        className
                        arbitration.
                        This provision governs in the event it conflicts with the applicable arbitration rules.
                        Under no
                        circumstances will className action procedures or rules apply to the arbitration. Because
                        these
                        Services and
                        these Terms concern interstate commerce, the Federal Arbitration Act (“FAA”) governs the
                        arbitrability of
                        all disputes. However, the arbitrator will apply applicable substantive law consistent
                        with
                        the FAA and
                        the applicable statute of limitations or condition precedent to suit. You or we may
                        initiate
                        arbitration
                        in either Georgia or the U.S federal judicial district where you are located.</p>

                      <h5 className="mb-4 mt-4">className Action Waiver</h5>
                      <p className="lead_smd">Except as otherwise provided in this provision, the arbitrator may not
                        consolidate more than
                        one person’s claims, and may not otherwise preside over any form of a className or
                        representative proceeding
                        or claims (such as a className action, consolidated action or private attorney general
                        action)
                        unless both you
                        and Schedule AI specifically agree to do so following initiation of the arbitration.
                      </p>

                      <h5 className="mb-4 mt-4">Other</h5>
                      <p className="lead_smd">You understand and agree that by accepting this provision in these Terms,
                        you
                        and we are
                        each waiving the right to a jury trial or a trial before a judge in a public court. If
                        any
                        clause within
                        this provision (other than the className Action Waiver clause above) is found to be illegal
                        or
                        unenforceable,
                        that clause will be severed from this provision whose remainder will be given full force
                        and
                        effect. If
                        the className Action Waiver clause is found to be illegal or unenforceable, this entire
                        provision will be
                        unenforceable and the dispute will be decided by a court. This provision will survive
                        the
                        termination of
                        your account with us or our affiliates and your discontinued use of this Website.
                        Notwithstanding any
                        provision in this Agreement to the contrary, we agree that if we make any change to this
                        provision (other
                        than a change to the Notice Address), you may reject any such change and require us to
                        adhere to the
                        language in this provision if a dispute between us arises.</p>

                      <h5 className="mb-4 mt-4">General Terms</h5>
                      <p className="lead_smd">We believe that mutual discussion or communication can resolve many issues.
                        So
                        If you are
                        found to be not abiding by the Terms or violating the Terms then we would definitely
                        like to
                        communicate
                        them to you and try to rectify it. But in some situations, we might need to immediately
                        discontinue the
                        Services and stop your access to our website, without any prior notice.</p>

                      <p className="lead_smd">The Federal Arbitration Act, Georgia state law and applicable U.S. federal
                        law,
                        without
                        regard to the choice or conflicts of law provisions, will govern these Terms to the
                        fullest
                        extent
                        permitted by applicable law. Except for disputes subject to arbitration as described
                        above,
                        any disputes
                        relating to these Terms or this Website will be heard in the courts located in Noida,
                        UP.
                        Except as
                        otherwise set forth in the dispute resolution provision, if any of these Terms are
                        deemed
                        inconsistent
                        with applicable law, then such term(s) will be interpreted to reflect the intentions of
                        the
                        parties, and
                        no other terms will be modified. By choosing not to enforce any of these Terms, we are
                        not
                        waiving our
                        rights. These Terms, together with our Privacy Notice, End User License Agreement, FERPA
                        COPPA Privacy
                        Policy, and the Data Processing Addendum (as applicable), contain the entire agreement
                        between you and us
                        and, therefore, supersede all prior or contemporaneous negotiations, discussions or
                        agreements between
                        Schedule AI and you about the Services. Any definitions found in the Privacy Notice,
                        End
                        User License
                        Agreement, FERPA COPPA Privacy Policy, and Data Processing Addendum are hereby adopted
                        by
                        reference in
                        this Agreement. The rights of Schedule AI under our Terms will survive the termination
                        of
                        this Agreement.
                        No additional terms, conditions, consent, waiver, alteration, or modification shall be
                        binding unless in
                        writing and signed by both parties. Acceptance of any order by Schedule AI is expressly
                        limited to the
                        terms and conditions of this Agreement. Any proposed additional or inconsistent terms or
                        conditions,
                        including those in or accompanying any Customer proposal, any Customer purchase order,
                        or
                        other agreement
                        , or any other Customer document issued in connection with the sale or delivery of
                        Products
                        or Services is
                        deemed by Schedule AI to be a material change and is objected to and rejected by
                        Schedule AI.
                        Schedule AI's acceptance of any orders will not constitute acceptance of any terms and
                        conditions
                        contained therein
                      </p>
                      <p className="lead_smd">In no event shall Schedule AI be liable to Customer, or be deemed to have
                        breached this
                        Agreement, for any failure or delay in performing its obligations under this Agreement,
                        if
                        and to the
                        extent such failure or delay is caused by any circumstances beyond Schedule AI's
                        reasonable
                        control,
                        including but not limited to acts of God, flood, fire, earthquake, explosion, war,
                        terrorism, invasion,
                        riot or other civil unrest, strikes, epidemics, contractor difficulties, labor stoppages
                        or
                        slowdowns or
                        other industrial disturbances, or passage of law or any action taken by a governmental
                        or
                        public
                        authority, including imposing an embargo.</p>
                      <p className="lead_smd">Unless Customer has specifically notified Schedule AI to the contrary in
                        writing including
                        via email to contact@scheduleai.co, Schedule AI may disclose your company as a customer
                        of
                        Schedule AI
                        (if you use Schedule AI for business purposes and/or use a business email address as
                        your
                        Schedule AI
                        User email address) and may use your company’s name and logo on the Website and in
                        Schedule AI’s
                        promotional content in accordance with Customer’s brand guidelines or related
                        documentation
                        if provided by
                        Customer. Schedule AI will be granted a revocable, non-exclusive, non-sub-licensable,
                        non-transferable,
                        royalty-free right and license (the "Trademark License") to use, display and reproduce
                        such
                        party's name,
                        logo, trademarks and service marks (the "Trademarks"). Both acknowledge that the other
                        party's Trademark
                        is and will remain the exclusive property of such party.</p>
                      <h5 className="mb-4 mt-4">Changes</h5>
                      <p className="lead_smd">This page contains the current Terms and Conditions. If there is any change
                        in
                        the Terms and
                        Conditions then we will update this page. Along with this, we will communicate those to
                        you
                        by the mode
                        that you have opted for (email or SMS). Please view emails received from us on a regular
                        basis. In case
                        you continue to use Schedule AI after the amendments then this means that you have agreed
                        to
                        the changes. We
                        advise you to check the Terms and Conditions page at regular intervals to keep yourself
                        updated with the
                        same. Schedule AI holds the right to change any or all features of our Services without
                        any
                        prior notice. In
                        case there is an objection to any of the revised Terms, you can opt to stop using our
                        services. Schedule AI
                        is not to be blamed for any changes.</p>
                      <h5 className="mb-4 mt-4">Canada</h5>
                      <p className="lead_smd">The parties declare that they have required that these Terms and Conditions
                        and
                        all
                        documents related hereto, either present or future, be drawn up in the English language
                        only. Les parties
                        déclarent par les présentes qu’elles exigent que cette entente et tous les documents y
                        afferents, soit
                        pour le present ou le future, soient rédigés en langue anglaise seulement.</p>
                      <h5 className="mb-4 mt-4">Contact Us.</h5>
                      <p className="lead_smd">If you have any questions about these Terms or otherwise need to contact us
                        for
                        any reason,
                        you can reach us <a className="fw-bold" href="/">here.</a></p>



                    </div>
                  </div>



                </div>

              </section>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default TermsAndConditions
