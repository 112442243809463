import React, { useState, useEffect } from 'react';
import "../../Custom.css";

const ScrollTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleScroll = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div className={`scroll-to-top ${isVisible ? 'visible' : ''}`} onClick={scrollToTop}>
                <i className="fas fa-arrow-up"></i> {/* You can use any icon library */}
            </div>
        </>
    )
}

export default ScrollTopButton