import React, {useEffect} from 'react'
import BankingBannerPng from "../../images/Banking Banner.png";
import AutomateAppointmentBankingPng from "../../images/Automate appointment banking.png";
import ScheduleAppointmentsBankingPng from "../../images/Schedule appointments banking.png"
import UseColorCodedCalendarsBankingPng from "../../images/Use color coded calendars banking.png";
import interationsPng from "../../images/interations.png";
import ImplementARealTimeBankingPng from "../../images/Implement a real time banking.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Banking = () => {

    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <>
            <div className="heWhoRemains">
                <Header />
                <div>
                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">
                                        <h1 className="mainHomeTitle">Schedule AI software for
                                            <span style={{ color: "#0B5ED7" }}> Banking Sector</span>
                                        </h1>
                                        <p className="lead_smd">
                                            Banks have a lot of different tasks to manage on a daily basis, and the need for efficiency is ever-present. That’s why appointment scheduling software can be a great asset for banking operations - it helps streamline the process and saves time.

                                        </p>
                                        <a href="/login"><button
                                            className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                                            Demo</button></a>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <img className="mainDevImg" src={BankingBannerPng} alt='Banking Banner' />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="column pt-5">
                                <h2 className="text-center display-6 fw-bold">Benefits of Using Schedule AI<br />
                                    Software for Banks
                                </h2>
                                <p className='lead_smd text-center'>Banks are under constant pressure to streamline their operations <br />and appointment scheduling software can be a big help in this regard.<br /> Here are some of the benefits of using such software: </p>
                            </div>
                            <div className="row webDevRow">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-up"
                                    data-aos-duration="2000">
                                    <div className="mt-4 ">

                                        <h1 className="display-6 smd_title" style={{ fontWeight: "400" }}> Automate appointment reminders
                                        </h1>
                                        <p className="lead_smd">Schedule AI software can automate the process of sending appointment reminders to customers. This will free up staff time and reduce the number of no-shows.


                                        </p>

                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 mt-5" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="mainDevImg" src={AutomateAppointmentBankingPng} alt="Automate appointment banking" />
                                </div>

                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">

                                <div className="col-12 col-lg-6 mt-5" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="mainDevImg" src={ScheduleAppointmentsBankingPng} alt="Schedule appointments banking" />
                                </div>
                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-up"
                                    data-aos-duration="2000">
                                    <div className="mt-4 ">

                                        <h1 className="display-6 smd_title" style={{ fontWeight: "400" }}>Schedule appointments online
                                        </h1>
                                        <p className="lead_smd"> Allow customers to schedule their own appointments online. This will save staff time and make it easier for customers to schedule appointments that fit their schedules.


                                        </p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">
                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-up"
                                    data-aos-duration="2000">
                                    <div className="mt-4 ">

                                        <h1 className="display-6 smd_title" style={{ fontWeight: "400" }}> Use color-coded calendars
                                        </h1>
                                        <p className="lead_smd"> Color-coded calendars make it easy to see which staff members are available at any given time. This will help you schedule appointments more efficiently and avoid overbooking.
                                        </p>

                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 mt-5" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="mainDevImg" src={UseColorCodedCalendarsBankingPng} alt="Use color coded calendars banking" />
                                </div>

                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">

                                <div className="col-12 col-lg-6 mt-5" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="homeTabImgDev" src={interationsPng} alt="interations" />
                                </div>
                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-up"
                                    data-aos-duration="2000">
                                    <div className="mt-4 ">

                                        <h1 className="display-5 smd_title" style={{ fontWeight: "400" }}>Integrate with your existing systems
                                        </h1>
                                        <p className="lead_smd">ScheduleAI integrates with your existing banking systems, such as your customer relationship management (CRM) system or your enterprise resource planning (ERP) system. This will allow for a seamless workflow and avoid disruptions in service.



                                        </p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>


                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">
                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-up"
                                    data-aos-duration="1500">
                                    <div className="mt-4 ">

                                        <h1 className="display-6 smd_title" style={{ fontWeight: "400" }}>Implement a real-time queue
                                        </h1>
                                        <p className="lead_smd">A real-time queue will allow you to see which customers are waiting for service and how long they have been waiting. This will help you better manage customer expectations and provide a more efficient customer experience.

                                        </p>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6 mt-5" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="mainDevImg" src={ImplementARealTimeBankingPng} alt="Schedule appointments banking" />
                                </div>

                            </div>
                        </div>
                    </section>


                    <section>
                        <div className="container textDevLay pt-5 pb-4">
                            <div className="column pt-5">
                                <h2 className="text-center display-6 fw-bold">Conclusion
                                </h2>
                                <p className='lead_smd text-center'>ScheduleAI is a great tool to help banks streamline their operations. By automating the booking process, customers will be able to quickly and easily book appointments for services such as opening accounts, applying for loans, or taking out insurance policies. Banks can also benefit from appointment </p>
                            </div>
                        </div>
                    </section>

                </div>

                <Footer />
            </div>
        </>
    )
}

export default Banking
