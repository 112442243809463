import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import notFoundPng from "../../images/notFound.png";
import "./EventListing.css";

import QRCode from "react-qr-code";
import html2canvas from 'html2canvas';
import { Modal, Toast } from 'react-bootstrap';
import domtoimage from 'dom-to-image';
import CopyToClipboard from 'react-copy-to-clipboard';

import copyImg from "../../images/calendly4.png";

import popupData from "../../audio/popup-alert.mp3";
import pageData from "../../audio/page-turn.wav";

function EventListing() {
    const serverUrl = "https://" + process.env.REACT_APP_DOMAINNAME + ":8443";
    const [myEvents, setMyEvents] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [allHidden, setAllHidden] = useState(true);
    const [showNow, setShowNow] = useState(false);
    const [userNotFound, setUserNotFound] = useState(false);
    const [appBranding, setAppBranding] = useState(true);
    const [brandLogo, setBrandLogo] = useState([]);
    const [brandLink, setBrandLink] = useState("#");
    const [sharemeetingProcedures, setSharemeetingProcedures] = useState(false);
    const elementRef = useRef(null);

    const [viewToast, setViewToast] = useState(false);
    const [toastTitle, setToastTitle] = useState(null);
    const [toastBody, setToastBody] = useState(null);
    const [toastMessageType, setToastMessageType] = useState(null);

    const popupAlert = new Audio(popupData);
    const pageTurnSound = new Audio(pageData);

    // const [welcomeMessage, setWelcomeMessage] = useState("Welcome to my scheduling page. Please follow the instructions to add an event to my calendar.");

    useEffect(() => {
        async function startUp() {
            let username = window.location.href.split("/")[window.location.href.split("/").length - 1];

            if (username.length === 0) {
                username = window.location.href.split("/")[window.location.href.split("/").length - 2]
            }

            // console.log("triggered")
            const getUsername = await axios.post(serverUrl + "/mainIds/getEvents", { "username": username });
            if (getUsername.status === 200) {
                // console.log(getUsername.data);
                setAppBranding(getUsername.data.appBranding);

                getUsername.data.brandLogos.map((logo, key) => {
                    if (logo.active === true) {
                        // console.log(logo.logo)
                        setBrandLink(logo.brandName)
                        setBrandLogo(logo.logo);
                    }
                });

                setMyEvents(getUsername.data);

                setEventList(getUsername.data.events);

                if (getUsername.data.events.length > 0) {
                    getUsername.data.events.map((eve) => {
                        if (eve.secretEvent === false) {
                            setAllHidden(false);
                        }
                        else {
                            return
                        }
                    })
                    setShowNow(true);
                } else {
                    setShowNow(true);
                }

            } else {
                // console.log(getUsername.data);
                setShowNow(true);
                setUserNotFound(true);
            }
        }
        startUp()
    }, [])


    const captureAndDownload = (id) => {
        const element = document.getElementById(id);

        html2canvas(element).then(function (canvas) {
            const dataURL = canvas.toDataURL('image/png');

            const link = document.createElement('a');
            link.href = dataURL;
            link.download = myEvents.name + '_meetings.png';

            link.click();
        });
    };


    const startSharemeetingProcedures = () => {
        setSharemeetingProcedures(true);
    }

    return (
        showNow ?
            (
                <>
                    <div className='freshMain'>
                        <div className='freshSecond'>
                            <div className="freshThird border insightBackground shadow p-3">
                                {
                                    appBranding ?
                                        <a target='_Blank' href="/" className="freshAncore">
                                            <div className="freshBranding">
                                                <div className="freshBrandingOne">powered by</div>
                                                <div className="freshBrandingTwo">Schedule AI</div>
                                            </div>
                                        </a>
                                        :
                                        <a target='_Blank' href={brandLink} className="freshAncoreCustom">
                                            <div className="freshBrandingCustom">
                                                <img src={brandLogo} alt="anchor" className='brandLogo' />
                                            </div>
                                        </a>
                                }
                                <div className="w-100 rounded">
                                    <button type='button' onClick={() => { window.location.href = "/calendar"; }} className={`btn salesforce-style rounded-circle fw-bold backButtonAbs ${userNotFound ? "d-none" : ""}`}>
                                        <i className='fa fa-arrow-left'></i>
                                    </button>
                                    {

                                        userNotFound ?

                                            <div className={`container-fluid h-100`}>
                                                <div className="row h-100 d-flex align-items-center justify-content-center">
                                                    <div className="col-12 h-100 p-4 d-flex flex-column justify-content-center align-items-center">
                                                        <h6 className='text-dark text-center'>
                                                            <img src={notFoundPng} className="w-100" style={{ maxWidth: "350px" }} alt="not found" />
                                                            <p className='color-salesforce mt-5 display-6' style={{ fontWeight: "600" }}>Broken URL</p>
                                                            <p className='mediumTextSize text-muted'>Looks like the current url is not available.</p>
                                                        </h6>
                                                        <button type='button' onClick={() => { window.location.href = "/calendar"; }} className='btn btn-sm salesforce-style rounded-pill px-3'>
                                                            {/* <i className='fa fa-arrow-left'></i> */}
                                                            Back
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className={`freshUserInfo ${showNow ? "" : "d-none"}`}>
                                                <div className='container-fluid'>
                                                    <div className="row">

                                                        <div className='col-12 col-lg-3 col-md-4 col-sm-5 d-flex justify-content-center align-items-start'>
                                                            <div className='sticky-top pt-3 d-flex flex-column gap-3 justify-content-start align-items-center'>
                                                                <div id="qrcodeExactSharing" className='p-3 rounded shadow' style={{ background: "linear-gradient(to bottom,skyblue 50%,#0b5cab 50%)", maxWidth: "200px", width: "200px", boxSizing: "border-box" }}>
                                                                    <div className='insightBackground rounded p-2'>
                                                                        <div className=' d-flex flex-column justify-content-center align-items-center py-1'>
                                                                            <span className='fw-bold p-0' style={{ lineHeight: ".6em", fontSize: "1.4em" }}> <span className='text-dark'>SCHEDULE</span> <span style={{ color: "#0b5cab" }}>AI</span></span>
                                                                            <span className='fw-bold p-0' style={{ fontSize: "0.8em" }}>Book your appointments</span>
                                                                        </div>
                                                                        <div className='my-2' style={{ maxWidth: "100%", width: "100%", boxSizing: "border-box" }}>
                                                                            <QRCode
                                                                                // size={150}
                                                                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                                                value={window.location.href}
                                                                                viewBox={`0 0 150 150`}
                                                                                bgColor='white'
                                                                                fgColor='#0b5cab'
                                                                                level="L"//L M Q H
                                                                                title={window.location.href}
                                                                            />
                                                                        </div>
                                                                        <span className=' d-flex flex-column justify-content-center align-items-start'>
                                                                            <span className='fw-bold text-center mb-1' style={{ fontSize: ".7em" }}>AI Powered Appointment Scheduling App</span>
                                                                            <ol className='m-0 d-block' style={{ paddingLeft: "1em" }}>
                                                                                <li style={{ fontSize: ".6em" }} className='fw-bold'>Book your appointments</li>
                                                                                <li style={{ fontSize: ".6em" }} className='fw-bold'>Book virtual meetings</li>
                                                                                <li style={{ fontSize: ".6em" }} className='fw-bold'>Book face 2 face meetings</li>
                                                                                <li style={{ fontSize: ".6em" }} className='fw-bold'>Instant meeting bookings</li>
                                                                            </ol>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <button className='btn btn-sm salesforce-style rounded-pill shadow-sm' onClick={startSharemeetingProcedures}>Share your meetings</button>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-lg-9 col-md-8 col-sm-7 freshDassierOne d-flex flex-column align-items-center">

                                                            <div className='border-bottom w-100 my-4 d-block d-sm-none d-md-none d-lg-none'></div>

                                                            <div className="freshDassierOneProfile w-100 d-flex flex-column align-items-center">
                                                                <img className="freshDassierOneImage" src={myEvents.customProfilePicture ? JSON.stringify(myEvents.customProfilePicture).length > 5 ? myEvents.customProfilePicture.image : myEvents.profilePicture : myEvents.profilePicture} alt="Avatar" title={myEvents.name} />
                                                                <span className="freshDasierOneName text-capitalize">{myEvents.name}</span>
                                                            </div>
                                                            {
                                                                eventList.length > 0 ? (

                                                                    <div className="freshDassierOneText text-center">
                                                                        {
                                                                            myEvents.settings.welcome
                                                                        }
                                                                    </div>
                                                                )
                                                                    :
                                                                    (
                                                                        <span className='mediumTextSize' style={{ fontWeight: "500" }}>No Openings at this moment</span>
                                                                    )
                                                            }

                                                            <div className="freshDessierTwo mt-4 container-fluid p-0">
                                                                <div className="row">
                                                                    {
                                                                        eventList.length > 0 && allHidden === false && userNotFound === false ?
                                                                            eventList.map((ds, key) => {
                                                                                if (ds.secretEvent === false) {
                                                                                    return (
                                                                                        <div className="col-12 col-md-12 col-lg-6 mb-2" key={key}>
                                                                                            <Link className='freshEventsList' to={ds.EventLink}>
                                                                                                <div className="freshEventItem d-flex justify-content-between border rounded bg-body py-2 mb-2 h-100">
                                                                                                    <div className='d-flex align-items-center gap-3 justify-content-start'>
                                                                                                        <div className={`repo-event-color-${ds.EventColor} verticalColorBars`}></div>
                                                                                                        <div className='freshEventItemName d-flex flex-column justify-content-start h-100 color-salesforce'>
                                                                                                            <span className='' style={{ fontWeight: "700" }}>{ds.eventName}</span>
                                                                                                            <p className='m-0 listingTime d-flex justify-content-start align-items-center gap-2 text-muted' style={{ fontWeight: "600" }}>
                                                                                                                <i className="fa-solid fa-clock"></i>
                                                                                                                {
                                                                                                                    ds.Duration > 60 ?
                                                                                                                        ds.Duration % 60 === 0 ?
                                                                                                                            Math.floor(ds.Duration / 60) + " hrs" :
                                                                                                                            Math.floor(ds.Duration / 60) + " hrs " + Math.floor(ds.Duration % 60) + " min"
                                                                                                                        : Math.floor(ds.Duration) + " min"
                                                                                                                }
                                                                                                            </p>
                                                                                                            <p className='listingDescription m-0 text-dark mediumTextSize' style={{ fontWeight: "600" }}>{ds.description}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="freshEventItemArrow text-dark"></div>
                                                                                                </div>
                                                                                                {/* <div className="freshExtra"></div> */}
                                                                                            </Link>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                                else {
                                                                                    return false;
                                                                                }
                                                                            })
                                                                            :
                                                                            eventList.length > 0 && allHidden === true ?
                                                                                (
                                                                                    <>
                                                                                        <div className='col-12 text-center mediumTextSize' style={{ fontWeight: "500" }}>Looks like all of your event types are secret. Making event types secret hides them from your main {process.env.REACT_APP_DOMAINNAME.split(".")[0]} page.</div>
                                                                                    </>
                                                                                )
                                                                                :
                                                                                (
                                                                                    <>
                                                                                        <div className='col-12 text-center mediumTextSize' style={{ fontWeight: "500" }}>Looks Like this user doesn't have any events at this moment.</div>

                                                                                        <div className="col-12 text-center d-flex justify-content-center py-3">
                                                                                            <a href={`https://${process.env.REACT_APP_DOMAINNAME}/calendar`}>
                                                                                                <button className='btn btn-sm salesforce-style rounded-pill'>View Your Event Types</button>
                                                                                            </a>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 col-lg-3 d-flex flex-column'>
                                                        </div>

                                                    </div>
                                                </div>
                                                {/* <button className='freshCookies'>
                                            Cookie settings
                                        </button> */}
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ############################################################ */}
                    <Modal
                        show={sharemeetingProcedures}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        onHide={() => { setSharemeetingProcedures(false) }}
                    >
                        <Modal.Header className="salesforce-style py-2" closeButton closeVariant='white'>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <span style={{ fontSize: "1rem" }}>Share Your Meetings</span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='backgroundFiller'>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 col-lg-8 col-md-8 col-sm-8 d-flex justify-content-center mb-3">
                                        <div ref={elementRef} id="qrcodeExact" className='p-3 sticky-top rounded shadow' style={{ background: "linear-gradient(to bottom,skyblue 50%,#0b5cab 50%)", maxWidth: "220px", width: "220px", boxSizing: "border-box" }}>
                                            <div className='insightBackground rounded p-3'>
                                                <div className="freshDassierOneProfile w-100 d-flex flex-column align-items-center">
                                                    <img className="rounded-circle" style={{
                                                        maxWidth: "80px", aspectRatio: "1", backgroundImage: `
                                                        ${JSON.stringify(myEvents.customProfilePicture)?.length < 5 ?
                                                                "url('https://scheduleai.co/man.jpg')"
                                                                :
                                                                "none"
                                                            }`, backgroundSize: "100% 100%"
                                                    }} src={myEvents.customProfilePicture ? JSON.stringify(myEvents.customProfilePicture).length > 5 ? myEvents.customProfilePicture.image : myEvents.profilePicture : myEvents.profilePicture} alt="Avatar" title={myEvents.name} />
                                                    <span className="fw-bold text-capitalize">{myEvents.name}</span>
                                                </div>

                                                <div className='mt-1 mb-2' style={{ borderTop: "1px solid rgba(0,0,0,0.2)" }}></div>

                                                <div className='d-flex flex-column justify-content-center align-items-center py-1'>
                                                    <span className='fw-bold p-0' style={{ lineHeight: ".6em", fontSize: "1.4em" }}> <span className='text-dark'>SCHEDULE</span> <span style={{ color: "#0b5cab" }}>AI</span></span>
                                                    <span className='fw-bold p-0' style={{ fontSize: "0.8em" }}>Book your appointments</span>
                                                </div>

                                                <div className='my-2' style={{ maxWidth: "100%", width: "100%", boxSizing: "border-box" }}>
                                                    <QRCode
                                                        // size={150}
                                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                        value={window.location.href}
                                                        viewBox={`0 0 150 150`}
                                                        bgColor='white'
                                                        fgColor='#0b5cab'
                                                        level="L"//L M Q H
                                                        title={window.location.href}
                                                    />
                                                </div>
                                                <span className=' d-flex flex-column justify-content-center align-items-start'>
                                                    <span className='fw-bold text-center mb-1' style={{ fontSize: ".7em" }}>AI Powered Appointment Scheduling App</span>
                                                    <ol className='m-0 d-block' style={{ paddingLeft: "1em" }}>
                                                        <li style={{ fontSize: ".6em" }} className='fw-bold'>Book your appointments</li>
                                                        <li style={{ fontSize: ".6em" }} className='fw-bold'>Book virtual meetings</li>
                                                        <li style={{ fontSize: ".6em" }} className='fw-bold'>Book face 2 face meetings</li>
                                                        <li style={{ fontSize: ".6em" }} className='fw-bold'>Instant meeting bookings</li>
                                                    </ol>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-4 col-sm-4">
                                        <div className='d-flex justify-content-center align-items-end flex-wrap gap-2'>
                                            <button className='btn btn-sm w-100 rounded-pill mb-3 salesforce-style' style={{ maxWidth: "120px" }} onClick={() => { captureAndDownload("qrcodeExact") }}>Download QR</button>

                                            <CopyToClipboard text={window.location.href}
                                                onCopy={() => {
                                                    setViewToast(true);
                                                    popupAlert.play();
                                                    setToastTitle("Successful");
                                                    setToastBody("Meeting link copied.");
                                                    setToastMessageType("success");

                                                    // setCustomAlertBox(true);
                                                    // setAlertBoxMessages("Link Copied..!");
                                                    // setAlertErrorType("success");
                                                }}>
                                                <button className='btn btn-sm w-100 rounded-pill mb-3 salesforce-style' style={{ maxWidth: "120px" }}>Copy Link</button>
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    {/* ############################################################ */}


                    <div style={{ position: "fixed", bottom: "10px", right: "10px", zIndex: "99999999" }}>


                        <Toast onClose={() => setViewToast(false)} show={viewToast} delay={3000} autohide className='overflow-hidden'>
                            <Toast.Header>
                                <span style={{ fontWeight: "500" }} className={`me-auto ${toastMessageType === "invalid" ? "text-danger" : "text-success"}`}>{toastTitle}</span>
                                <small>just now</small>
                            </Toast.Header>
                            <Toast.Body style={toastMessageType === "success" ? { fontWeight: "500", background: "#d1e7dd", color: "#0f5132" } : { fontWeight: "500", background: "#f8d7da", color: "#842029" }}>
                                {toastBody}

                            </Toast.Body>
                        </Toast>
                    </div>
                </>
            )
            :
            (
                <div className={`min-vh-100 w-100 position-absolute top-0 left-start d-flex align-items-center justify-content-center`}>
                    <span className="spinner-border spinner-border-lg text-secondary"></span>
                </div>
            )
    )
}

export default EventListing