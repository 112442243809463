import { Battery0Bar, Battery20TwoTone, Battery90TwoTone, Call, Contacts, Dialpad, FiveGTwoTone, History, NetworkCell, SignalCellularAlt2Bar, SignalCellularAltTwoTone, Wifi } from '@mui/icons-material'
import React, { useEffect, useRef, useState } from 'react'
import wallpaper from "./wallpaper.jpg";
import dayjs from 'dayjs';

import Dialer from './DialerLayout';
import ContactsLayout from './ContactsLayout';
import DialerLayout from './DialerLayout';


function Phone({ serverURL }) {
    const call = useRef();
    const dialer = useRef();
    const contact = useRef();
    const [phone, setPhone] = useState("");

    const [time, setTime] = useState(dayjs(new Date()).tz(dayjs.tz.guess()).format("h:mm A"));
    const [date, setDate] = useState(dayjs(new Date()).tz(dayjs.tz.guess()).format("dddd, D MMMM YYYY"));
    const [viewMode, setViewMode] = useState("Dashboard");
    useEffect(() => {
        const interval = setInterval(() => {
            setTime(dayjs(new Date()).tz(dayjs.tz.guess()).format("h:mm A"));
            setDate(dayjs(new Date()).tz(dayjs.tz.guess()).format("dddd, D MMMM YYYY"));
        }, 1000)

    }, [])
    return (
        <div className='vh-100 bg-dark d-flex align-items-center justify-content-center'>
            <div className="border shadow w-100 d-flex flex-column" style={{ overflow: "hidden", maxWidth: "300px", minHeight: "600px", borderRadius: "20px", backgroundImage: `linear-gradient(-180deg, black -50%, transparent 200%), url(${wallpaper})`, backgroundSize: "cover", backgroundPosition: "center center" }}>
                <div className="d-flex justify-content-between px-3 pt-2">
                    <div className='text-light' style={{ fontSize: "0.8rem" }}>{time}</div>
                    <div></div>
                    <div className='text-light d-flex gap-1 align-items-center'>
                        <Wifi style={{ fontSize: "1rem" }} />
                        <SignalCellularAltTwoTone style={{ fontSize: "1rem" }} />
                        <SignalCellularAltTwoTone style={{ fontSize: "1rem" }} />
                        {/* <FiveGTwoTone style={{ fontSize: "1rem" }} /> */}
                        <span className='d-flex align-items-center'>
                            <Battery90TwoTone style={{ fontSize: "1rem" }} />
                            <span style={{ fontSize: "0.8rem" }} >92%</span>
                        </span>
                    </div>
                </div>
                <div className="flex-fill d-flex overflow-auto">
                    {
                        viewMode === "Call" ?
                            <ContactsLayout setPhone={setPhone} serverURL={serverURL} />
                            :
                            viewMode === "Dialer" ?
                                <DialerLayout setPhone={setPhone} serverURL={serverURL} />
                                :
                                <div className='w-100 text-center text-light py-4'>
                                    <p className='m-0' style={{ fontSize: "1.8rem", fontWeight: "200" }}>
                                        {time}
                                    </p>
                                    <p className='m-0' style={{ fontSize: "0.8rem", fontWeight: "200" }}>
                                        {date}
                                    </p>
                                </div>

                    }


                </div>
                <div className="p-2 d-flex align-items-center justify-content-around">
                    <button onClick={() => { setViewMode("Call") }} ref={call} className='btn btn-sm rounded-pill text-light w-100' style={{ maxWidth: "15%", transitionDuration: "0.3", background: "rgba(255,255,255,0.2)" }} onMouseOver={(e) => { call.current.style.scale = "1.1"; call.current.style.transform = "translateY(-1.5px)" }} onMouseOut={(e) => { call.current.style.scale = "1.0"; call.current.style.transform = "translateY(0px)" }}>
                        <Call></Call>
                    </button>
                    <button onClick={() => { setViewMode("Dialer") }} ref={dialer} className='btn btn-sm rounded-pill text-light w-100' style={{ maxWidth: "15%", transitionDuration: "0.3", background: "rgba(255,255,255,0.2)" }} onMouseOver={(e) => { dialer.current.style.scale = "1.1"; dialer.current.style.transform = "translateY(-1.5px)" }} onMouseOut={(e) => { dialer.current.style.scale = "1.0"; dialer.current.style.transform = "translateY(0px)" }}>
                        <Dialpad></Dialpad>
                    </button>
                    <button onClick={() => { setViewMode("Dashboard") }} ref={contact} className='btn btn-sm rounded-pill text-light w-100' style={{ maxWidth: "15%", transitionDuration: "0.3", background: "rgba(255,255,255,0.2)" }} onMouseOver={(e) => { contact.current.style.scale = "1.1"; contact.current.style.transform = "translateY(-1.5px)" }} onMouseOut={(e) => { contact.current.style.scale = "1.0"; contact.current.style.transform = "translateY(0px)" }}>
                        <Contacts></Contacts>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Phone