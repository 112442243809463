import React from 'react'
import "./footer.css";
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import NewScheduleAiLogo from "../../images/logo/New ScheduleAi Logo.png";

function Footer() {
    return (
        <div className="container-fluid bg-success p-2 text-dark bg-opacity-10">
            <footer className="text-black pt-4 pb-2">

                <div className="container-fluid text-center text-md-left">

                    <div className="row text-center text-md-left">

                        <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                            {/* <a href="/"><img className=" HeaderLogo"
                                src={scheduleAiLogo} alt={process.env.REACT_APP_DOMAINNAME} /></a> */}
                            <Link to="/">
                                <Image src={NewScheduleAiLogo} className="logoHeader" alt="footer logo" />
                            </Link>
                        </div>

                        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
                            <h5 className="text-uppercase mb-4 font-weight-bold">Product</h5>
                            <div>
                                <Link to="/zoomintegration"><h6>Zoom</h6></Link>
                            </div>
                            <div>
                                <Link to="/webexdocs"><h6>Webex </h6></Link>
                            </div>
                            <div>
                                <Link to="/integrations/salesforce-docs"><h6>Salesforce</h6></Link>
                            </div>
                        </div>

                        <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                            <h5 className="text-uppercase mb-4 font-weight-bold">Solutions</h5>
                            <Link to="/"><h6>Home</h6></Link>

                            <Link to="/about"><h6>About</h6></Link>

                            <Link to="/support"><h6>Support</h6></Link>

                            <Link to="/privacypolicy"><h6>Privacy and Policy</h6></Link>

                            <Link to="/termsandconditions"><h6>Terms and Condition</h6></Link>

                            <Link to="/refundandcancellation"><h6>Refund & Cancellation</h6></Link>

                        </div>

                        <div className="col-md-4 col-lg-3 col-xl-3  mt-3">
                            <h5 className="text-uppercase mb-4 font-weight-bold">Contact</h5>

                            <p>
                                <a href='mailto:info@scheduleai.co'>
                                    <i className="fas fa-envelope mx-1"></i>
                                    info@scheduleai.co</a>
                            </p>
                            <p>
                                <i className="fas fa-home mr-3"></i> First Canadian Place, 100 King St W Suite 5600, Toronto, ON
                                M5X 1C9, Canada
                            </p>
                            {/* <p>
                                <a href={`mailto:info@${process.env.REACT_APP_DOMAINNAME}`} className=' text-decoration-none'><i className="fas fa-envelope mr-3"></i> info@{process.env.REACT_APP_DOMAINNAME}</a>

                            </p> */}

                            {/* <p>
                                <a href="tel:+1 619 798 6435" className=' text-decoration-none'> <i className="fas fa-phone mr-3"></i> +1 619 798 6435</a>
                            </p> */}
                        </div>

                    </div>

                    <hr className="mb-4" />

                    <div className="row align-items-center">
                        <div className="col-md-7 col-lg-8 mx-auto w-100" >
                            <div className="text-center text-md-right">
                                <ul className="list-unstyled list-inline">
                                    <li className="list-inline-item">
                                        <a target='_blank' href="https://www.linkedin.com/company/scheduleai/" className="btn-floating btn-sm text-dark fs-23" ><i
                                            className="fab fa-facebook"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a target='_blank' href="https://twitter.com/Schedule_ai" className="btn-floating btn-sm text-dark fs-23" ><i
                                            className="fab fa-twitter"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a target='_blank' href="https://instagram.com/schedule_ai?igshid=MzRlODBiNWFlZA==" className="btn-floating btn-sm text-dark fs-23" ><i
                                            className="fab fa-instagram"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a target='_blank' href="/" className="btn-floating btn-sm text-dark fs-23" ><i
                                            className="fab fa-linkedin-in"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a target='_blank' href="/" className="btn-floating btn-sm text-dark fs-23" ><i
                                            className="fab fa-youtube"></i></a>
                                    </li>
                                </ul>
                            </div>
                            <p> copyright @2023 {process.env.REACT_APP_DOMAINNAME}.</p>
                        </div>
                    </div>

                </div>
            </footer>
        </div>
    )
}

export default Footer