import React, { useEffect } from 'react';
import homePageBannerPng from "../../images/home-page-banner.png";
import iprLogo from "../../images/ipr.png";
import Header from '../header/Header';
import Footer from "../footer/Footer";
import huaweiPng from "../../images/huawei.png"
import testClient20Png from "../../images/testclient20.jpg";
import sasPng from "../../images/sas.png";
import salesProfit from "../../images/sales-profile.png";
import marketingProfilePng from "../../images/marketing-profile.png";
import customerSuccessPng from "../../images/customer-success.png";
import recruitementProfilePng from "../../images/recruiting-profile.png";
import informationTechPng from "../../images/Information-technology.png";
import automaticScheduling from "../../images/Automatic scheduling.png";
import timeOptimisationPng from "../../images/Time optimization.png";
import percentPng from "../../images/percent.png";
import { Tab, Tabs, Accordion } from 'react-bootstrap';
import educationPng from "../../images/education.png";
import MeetingSummariesPng from "../../images/Meeting summaries.png";
import IntelligentNotificationsPng from "../../images/Intelligent notifications.png";
import ResourceManagementPng from "../../images/Resource Management.png";
import PredictiveSchedulingPng from "../../images/Predictive Scheduling.png";
import ScheduleAiAvailabilityPng from "../../images/Schedule ai Availability.png";
import PreSalesPng from "../../images/Pre Sales.png";
import ProfilePng from "../../images/Profile.png";
import CalendarSchedulePng from "../../images/Calendar Schedule.png";
import WorkflowPng from "../../images/workflow.png";
import SalesTeamPeoplePng from "../../images/Sales team people.png";
import RequestMeetingPng from "../../images/Request meeting.png";
import InterationsPng from "../../images/interations.png";
import CRMCalendaryPng from "../../images/CRM calendary.png";
import DevelopPrivateCompanyPng from "../../images/Develop private company.png";
import SalesforceTeamPng from "../../images/Salesforce team.png";
import HealthcareScheduleaiPng from "../../images/Healthcare scheduleai.png";
import { Link, Router, useLocation } from 'react-router-dom';
import { Container, Row, Col, Nav, Navbar, Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import "../../Custom.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Cards from '../cards/Cards';
import { motion } from "framer-motion";
import DemoScrollVideo from "../../video/Demo Scroll Video.mp4";
import ScheduleaiAppointmentBooking from "../../video/Scheduleai appointment booking.mp4";
import { Helmet } from "react-helmet";
import ScrollTopButton from '../scrolltopbtn/ScrollTopButton';


function Homepage() {

    useEffect(() => {
        AOS.init();
    }, [])
    return (

        <div className='heWhoRemains'>
            <Helmet>
                <title>Free Online Appointment Scheduling Software in 2023 - Scheduleai
                </title>
                <meta name="description" content="Scheduleai is the lastest scheduling software for google calender, zoom,GoTo meeting, salesforce integration & more conference options to schedule appointments online." />
            </Helmet>
            <Header />

            <section className='MainHeroWrapper'>
                <div className="container main-hero-section">
                    <h1 className='main-heroTitle'>AI Powered <br /> Appointment Scheduling</h1>
                    <p className='hero-para'>
                        Best platform where you can access the most useful technologies, like realtime messaging, team management, video and audio conferences, media sharing, screen sharing, conference recording, calendar management
                    </p>
                    <div className="cta">
                        <Link to="/login" className='heroBtn'>Login</Link>
                    </div>
                </div>
            </section>

            <section>
                <div className="container videoWrapper">
                    <video src={ScheduleaiAppointmentBooking} autoPlay loop muted></video>
                </div>
            </section>

            <Cards />



            <section className='mt-4' style={{ background: "#0a7bdd26" }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <h2 className="text-center smd_text mt-5 mb-4">Intended for groups who direct <br />gatherings
                                at scale</h2>
                        </div>
                    </div>
                    <div className="row d-none d-lg-flex insideGlassDev">
                        <div className="col">
                            <Tabs
                                id="uncontrolled-tab-example"
                                className='justify-content-center'>
                                <Tab eventKey="sales" title={<><i className="fa-solid fa-chart-line d-flex justify-content-center" style={{ fontSize: "1.5em", maxHeight: "10px" }}></i><br /><span className=' text-capitalize'>Sales</span></>}>

                                    <div id="collapseOne" className="accordion-collapse collapse show  d-lg-block"
                                        aria-labelledby="headingOne" data-bs-parent="#myTabContent">
                                        <div className="accordion-body d-flex pt-4">
                                            <div className="container">
                                                <div className="row DevRow">
                                                    <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                                                        <div>
                                                            <ol>
                                                                <li className="d-flex">
                                                                    <div className="icon-dollar">
                                                                        <i className="fa-solid fa-dollar-sign text-primary icon_Dev"></i>
                                                                    </div>
                                                                    <div className="drive-text-dev">
                                                                        <h5 className="align-items-center tab-textDev"
                                                                            style={{ marginLeft: "5px" }}>Drive
                                                                            more income</h5>
                                                                        <p className="tab-paraDev"
                                                                            style={{ marginLeft: "5px" }}>Instantly book high-value meetings to gain a competitive advantage.
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                                <li className="d-flex">
                                                                    <div className="icon-dollar">
                                                                        <i className="fa-solid fa-arrows-rotate text-primary icon_Dev"></i>
                                                                    </div>
                                                                    <div className="drive-text-dev">
                                                                        <h5 className="align-items-center tab-textDev"
                                                                            style={{ marginLeft: "5px" }}>
                                                                            Accelerate your sales cycle</h5>
                                                                        <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Maintain high deal momentum and eliminate scheduling friction at every stage of your sales process.
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                                <li className="d-flex">
                                                                    <div className="icon-dollar">
                                                                        <i className="fa-solid fa-handshake text-primary icon_Dev"></i>
                                                                    </div>
                                                                    <div className="drive-text-dev">
                                                                        <h5 className="align-items-center tab-textDev"
                                                                            style={{ marginLeft: "5px" }}>Close more deals</h5>
                                                                        <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Tailor reminders and follow-ups to keep deals moving, integrate with sales tools, and remove logistical tasks to focus on selling.
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                            </ol>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <img className="homeTabImgDev" title="Schedule Ai for Sales" src={salesProfit} alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="marketing" title={<><i className="fa-solid fa-magnifying-glass-dollar d-flex justify-content-center" style={{ fontSize: "2em", maxHeight: "10px" }}></i><br /><span className=' text-capitalize'>marketing</span></>}>
                                    <h2 className="accordion-header d-lg-none" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Marketing
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse d-lg-block" aria-labelledby="headingTwo"
                                        data-bs-parent="#myTabContent">
                                        <div className="accordion-body pt-4">
                                            <div className="container">
                                                <div className="row DevRow">
                                                    <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                                                        <div className="mt-4 ">
                                                            <ol>
                                                                <li className="d-flex">
                                                                    <div className="icon-dollar">
                                                                        <i className="fa-solid fa-user text-primary icon_Dev"></i>
                                                                    </div>
                                                                    <div className="drive-text-dev">
                                                                        <h5 className="align-items-center tab-textDev"
                                                                            style={{ marginLeft: "5px" }}>Drive more pipeline
                                                                        </h5>
                                                                        <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Convert marketing leads into booked meetings faster.</p>
                                                                    </div>
                                                                </li>
                                                                <li className="d-flex">
                                                                    <div className="icon-dollar">
                                                                        <i className="fa-regular fa-clock text-primary icon_Dev"></i>
                                                                    </div>
                                                                    <div className="drive-text-dev">
                                                                        <h5 className="align-items-center tab-textDev"
                                                                            style={{ marginLeft: "5px" }}>Improve lead response times</h5>
                                                                        <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Gain a competitive advantage by quickly qualifying, routing, and booking leads.
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                                <li className="d-flex">
                                                                    <div className="icon-dollar">
                                                                        <i className="fa-solid fa-chart-line text-primary icon_Dev"></i>
                                                                    </div>
                                                                    <div className="drive-text-dev">
                                                                        <h5 className="align-items-center tab-textDev"
                                                                            style={{ marginLeft: "5px" }}>Boost conversion rates</h5>
                                                                        <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Reduce friction in the sales pipeline and close more deals.
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                            </ol>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <img className="homeTabImgDev" title="Schedule Ai for Marketng" src={marketingProfilePng} alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="CustomerSuccess" title={<><i className="fa-solid fa-users d-flex justify-content-center" style={{ fontSize: "1.5em", maxHeight: "10px" }}></i><br /><span className=' text-capitalize'>Customer Success</span></>}>
                                    <h2 className="accordion-header d-lg-none" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Customer Success
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse d-lg-block"
                                        aria-labelledby="headingThree" data-bs-parent="#myTabContent">
                                        <div className="accordion-body pt-4">
                                            <div className="container">
                                                <div className="row DevRow">
                                                    <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center" >
                                                        <div className="mt-4 ">
                                                            <ol>
                                                                <li className="d-flex">
                                                                    <div className="icon-dollar">
                                                                        <i className="fa-regular fa-face-smile text-primary icon_Dev"></i>
                                                                    </div>
                                                                    <div className="drive-text-dev">
                                                                        <h5 className="align-items-center tab-textDev"
                                                                            style={{ marginLeft: "5px" }}>Drive more maintenance</h5>
                                                                        <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Unite all your specialists and engage with clients throughout their journey to build lasting relationships.
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                                <li className="d-flex">
                                                                    <div className="icon-dollar">
                                                                        <i className="fa-regular fa-clock text-primary icon_Dev"></i>
                                                                    </div>
                                                                    <div className="drive-text-dev">
                                                                        <h5 className="align-items-center tab-textDev"
                                                                            style={{ marginLeft: "5px" }}>
                                                                            Accelerate your response times
                                                                        </h5>
                                                                        <p className="tab-paraDev" style={{ marginLeft: "5px" }}>IQuickly schedule a time to address clients' needs and assist them in achieving their goals efficiently.
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                                <li className="d-flex">
                                                                    <div className="icon-dollar">
                                                                        <i className="fa-solid fa-star text-primary icon_Dev"></i>
                                                                    </div>
                                                                    <div className="drive-text-dev">
                                                                        <h5 className="align-items-center tab-textDev"
                                                                            style={{ marginLeft: "5px" }}>Elevate NPS and Delight Customers</h5>
                                                                        <p className="tab-paraDev" style={{ marginLeft: "5px" }}>By optimizing meeting scheduling, you can elevate customer satisfaction to new heights. Keep customers engaged and satisfied through well-timed reminders and efficient follow-up workflows.</p>
                                                                    </div>
                                                                </li>
                                                            </ol>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <img className="homeTabImgDev" title="Schedule Ai for Customer Success" src={customerSuccessPng} alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Recruiting" title={<><i className="fa-solid fa-user-graduate d-flex justify-content-center" style={{ fontSize: "1.3em", maxHeight: "10px" }}></i><br /><span className=' text-capitalize'>Recruiting</span></>}>
                                    <h2 className="accordion-header d-lg-none" id="headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            Recruiting
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse d-lg-block" aria-labelledby="headingFour"
                                        data-bs-parent="#myTabContent">
                                        <div className="accordion-body pt-4">
                                            <div className="container">
                                                <div className="row DevRow">
                                                    <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                                                        <div>
                                                            <ol>
                                                                <li className="d-flex">
                                                                    <div className="icon-dollar">
                                                                        <i className="fa-solid fa-user-tie text-primary icon_Dev"></i>
                                                                    </div>
                                                                    <div className="drive-text-dev">
                                                                        <h5 className="align-items-center tab-textDev"
                                                                            style={{ marginLeft: "5px" }}>Boost employee productivity</h5>
                                                                        <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Spend less time struggling with schedules and more time connecting with candidates.
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                                <li className="d-flex">
                                                                    <div className="icon-dollar">
                                                                        <i className="fa-solid fa-gauge-simple-high text-primary icon_Dev"></i>
                                                                    </div>
                                                                    <div className="drive-text-dev">
                                                                        <h5 className="align-items-center tab-textDev"
                                                                            style={{ marginLeft: "5px" }}>
                                                                            Accelerate your recruiting cycle</h5>
                                                                        <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Schedule interviews in a matter of minutes and reduce time-to-fill positions.</p>
                                                                    </div>
                                                                </li>
                                                                <li className="d-flex">
                                                                    <div className="icon-dollar">
                                                                        <i className="fa-solid fa-user-tie text-primary icon_Dev"></i>
                                                                    </div>
                                                                    <div className="drive-text-dev">
                                                                        <h5 className="align-items-center tab-textDev"
                                                                            style={{ marginLeft: "5px" }}>Improve NPS and candidate experience
                                                                        </h5>
                                                                        <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Eliminate friction and turn your recruiting process into a competitive advantage.
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                            </ol>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <img className="homeTabImgDev" title="Schedule Ai for Recruiting" src={recruitementProfilePng} alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="InformationTechnology" title={<><i className="fa-solid fa-laptop-code d-flex justify-content-center" style={{ fontSize: "1.2em", maxHeight: "10px" }}></i><br /><span className=' text-capitalize'>Information Technology</span></>}>
                                    <h2 className="accordion-header d-lg-none" id="headingFive">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFive">
                                            Information Technology
                                        </button>
                                    </h2>
                                    <div id="collapseFive" className="accordion-collapse collapse d-lg-block" aria-labelledby="headingFive"
                                        data-bs-parent="#myTabContent">
                                        <div className="accordion-body pt-4">
                                            <div className="container">
                                                <div className="row DevRow">
                                                    <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                                                        <div className="mt-4 ">
                                                            <ol>
                                                                <li className="d-flex">
                                                                    <div className="icon-dollar">
                                                                        <i className="fa-sharp fa-solid fa-shield-halved text-primary icon_Dev"></i>
                                                                    </div>
                                                                    <div className="drive-text-dev">
                                                                        <h5 className="align-items-center tab-textDev"
                                                                            style={{ marginLeft: "5px" }}>Ensure enterprise-grade security with booking automation
                                                                        </h5>
                                                                        <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Stay aligned with your security requirements and reduce risk throughout the organization.</p>
                                                                    </div>
                                                                </li>
                                                                <li className="d-flex">
                                                                    <div className="icon-dollar">
                                                                        <i className="fa-solid fa-laptop-code text-primary icon_Dev"></i>
                                                                    </div>
                                                                    <div className="drive-text-dev">
                                                                        <h5 className="align-items-center tab-textDev"
                                                                            style={{ marginLeft: "5px" }}>Implement and manage effortlessly</h5>
                                                                        <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Eliminate the manual processes of implementing and managing client access and permissions on the platform.
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                                <li className="d-flex">
                                                                    <div className="icon-dollar">
                                                                        <i className="fa-solid fa-dollar-sign text-primary icon_Dev"></i>
                                                                    </div>
                                                                    <div className="drive-text-dev">
                                                                        <h5 className="align-items-center tab-textDev"
                                                                            style={{ marginLeft: "5px" }}>Drive adoption and ROI across teams</h5>
                                                                        <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Partner with our team to onboard seamlessly, drive adoption, and identify success metrics to achieve greater value, faster.
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                            </ol>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <img className="homeTabImgDev" title="Schedule Ai for Information Technology" src={informationTechPng} alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Education" title={<><i className="fa-solid fa-school d-flex justify-content-center" style={{ fontSize: "1.2em", maxHeight: "10px" }}></i><br /><span className=' text-capitalize'>Education</span></>}>
                                    <h2 className="accordion-header d-lg-none d-flex justify-content-center align-items-center" id="headingFive">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                            Education
                                        </button>
                                    </h2>
                                    <div id="collapseSix" className="accordion-collapse collapse d-lg-block" aria-labelledby="headingSix"
                                        data-bs-parent="#myTabContent">
                                        <div className="accordion-body pt-4">
                                            <div className="container">
                                                <div className="row DevRow">
                                                    <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                                                        <div className="mt-4 ">
                                                            <ol>
                                                                <li className="d-flex">
                                                                    <div className="icon-dollar">
                                                                        <i className="fa-solid fa-bolt text-primary icon_Dev"></i>
                                                                    </div>
                                                                    <div className="drive-text-dev">
                                                                        <h5 className="align-items-center tab-textDev"
                                                                            style={{ marginLeft: "5px" }}>Foster deeper connections through the automated booking</h5>
                                                                        <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Dedicate higher-quality time to support students in advising, mentoring, career planning, and counseling.</p>
                                                                    </div>
                                                                </li>
                                                                <li className="d-flex">
                                                                    <div className="icon-dollar">
                                                                        <i className="fa-solid fa-calendar-days text-primary icon_Dev"></i>
                                                                    </div>
                                                                    <div className="drive-text-dev">
                                                                        <h5 className="align-items-center tab-textDev"
                                                                            style={{ marginLeft: "5px" }}>
                                                                            Enhance communication and streamline coordination</h5>
                                                                        <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Increase accessibility of your schedule to students and prospects, empowering them to book time when they need assistance the most.
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                                <li className="d-flex">
                                                                    <div className="icon-dollar">
                                                                        <i className="fa-solid fa-person-chalkboard text-primary icon_Dev"></i>
                                                                    </div>
                                                                    <div className="drive-text-dev">
                                                                        <h5 className="align-items-center tab-textDev"
                                                                            style={{ marginLeft: "5px" }}>Deliver an enriched educational experience
                                                                        </h5>
                                                                        <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Focus on what truly matters: supporting and engaging with your students, empowering them to thrive and succeed.
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                            </ol>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <img className="homeTabImgDev" title="Schedule Ai for Education" src={educationPng} alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Healthcare" title={<><i className="fa-solid fa-hospital d-flex justify-content-center" style={{ fontSize: "1.2em", maxHeight: "10px" }}></i><br /><span className=' text-capitalize'>Healthcare</span></>}>
                                    <h2 className="accordion-header d-lg-none d-flex justify-content-center align-items-center" id="headingFive">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                            Healthcare
                                        </button>
                                    </h2>
                                    <div id="collapseSeven" className="accordion-collapse collapse d-lg-block" aria-labelledby="headingSeven"
                                        data-bs-parent="#myTabContent">
                                        <div className="accordion-body pt-4">
                                            <div className="container">
                                                <div className="row DevRow">
                                                    <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                                                        <div className="mt-4 ">
                                                            <ol>
                                                                <li className="d-flex">
                                                                    <div className="icon-dollar">
                                                                        <i className="fa-solid fa-calendar-check text-primary icon_Dev"></i>
                                                                    </div>
                                                                    <div className="drive-text-dev">
                                                                        <h5 className="align-items-center tab-textDev"
                                                                            style={{ marginLeft: "5px" }}>Intelligent Appointment Booking</h5>
                                                                        <p className="tab-paraDev" style={{ marginLeft: "5px" }}>ScheduleAI utilizes cutting-edge algorithms to analyze patient data, availability, and preferences, suggesting the most suitable appointment slots. Eliminate long wait times and scheduling conflicts.
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                                <li className="d-flex">
                                                                    <div className="icon-dollar">
                                                                        <i className="fa-solid fa-notes-medical text-primary icon_Dev"></i>
                                                                    </div>
                                                                    <div className="drive-text-dev">
                                                                        <h5 className="align-items-center tab-textDev"
                                                                            style={{ marginLeft: "5px" }}>
                                                                            Tailored Workflows</h5>
                                                                        <p className="tab-paraDev" style={{ marginLeft: "5px" }}>ScheduleAI adapts to the unique needs of your healthcare facility. Whether you run a hospital, clinic, or specialized practice, you can customize workflows and scheduling rules to meet your specific requirements.</p>
                                                                    </div>
                                                                </li>
                                                                <li className="d-flex">
                                                                    <div className="icon-dollar">
                                                                        <i className="fa-sharp fa-solid fa-shield-halved text-primary icon_Dev"></i>
                                                                    </div>
                                                                    <div className="drive-text-dev">
                                                                        <h5 className="align-items-center tab-textDev"
                                                                            style={{ marginLeft: "5px" }}>Compliance and Data Security</h5>
                                                                        <p className="tab-paraDev" style={{ marginLeft: "5px" }}>We prioritize patient privacy and data security. ScheduleAI adheres to the highest industry standards, ensuring that patient information remains protected at all times.
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                            </ol>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <img className="w-100" title="Schedule Ai for Education" src={HealthcareScheduleaiPng} alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </section>


            <Accordion flush className='d-lg-none'>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Sales</Accordion.Header>
                    <Accordion.Body>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="mt-4 ">
                                        <ol>
                                            <li className="d-flex">
                                                <div className="icon-dollar">
                                                    <i className="fa-solid fa-dollar-sign text-primary icon_Dev"></i>
                                                </div>
                                                <div className="drive-text-dev">
                                                    <h5 className="align-items-center tab-textDev"
                                                        style={{ marginLeft: "5px" }}>Drive more income
                                                    </h5>
                                                    <p className="tab-paraDev"
                                                        style={{ marginLeft: "5px" }}>Instantly book high-value meetings to gain a competitive advantage.</p>
                                                </div>
                                            </li>
                                            <li className="d-flex">
                                                <div className="icon-dollar">
                                                    <i className="fa-solid fa-arrows-rotate text-primary icon_Dev"></i>
                                                </div>
                                                <div className="drive-text-dev">
                                                    <h5 className="align-items-center tab-textDev"
                                                        style={{ marginLeft: "5px" }}>
                                                        Accelerate your sales cycle</h5>
                                                    <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Maintain high deal momentum and eliminate scheduling friction at every stage of your sales process.</p>
                                                </div>
                                            </li>
                                            <li className="d-flex">
                                                <div className="icon-dollar">
                                                    <i className="fa-solid fa-handshake text-primary icon_Dev"></i>
                                                </div>
                                                <div className="drive-text-dev">
                                                    <h5 className="align-items-center tab-textDev"
                                                        style={{ marginLeft: "5px" }}>Close more deals</h5>
                                                    <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Tailor reminders and follow-ups to keep deals moving, integrate with sales tools, and remove logistical tasks to focus on selling.</p>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <img className="w-100" src={salesProfit} alt='' />
                                </div>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Marketing</Accordion.Header>
                    <Accordion.Body>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="mt-4 ">
                                        <ol>
                                            <li className="d-flex">
                                                <div className="icon-dollar">
                                                    <i className="fa-solid fa-user text-primary icon_Dev"></i>
                                                </div>
                                                <div className="drive-text-dev">
                                                    <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                        Drive more pipeline
                                                    </h5>
                                                    <p className="tab-paraDev" style={{ marginLeft: "5px" }}>TConvert marketing leads into booked meetings faster.</p>
                                                </div>
                                            </li>
                                            <li className="d-flex">
                                                <div className="icon-dollar">
                                                    <i className="fa-regular fa-clock text-primary icon_Dev"></i>
                                                </div>
                                                <div className="drive-text-dev">
                                                    <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                        Improve lead response times</h5>
                                                    <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Gain a competitive advantage by quickly qualifying, routing, and booking leads.</p>
                                                </div>
                                            </li>
                                            <li className="d-flex">
                                                <div className="icon-dollar">
                                                    <i className="fa-solid fa-chart-line text-primary icon_Dev"></i>
                                                </div>
                                                <div className="drive-text-dev">
                                                    <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                        Boost conversion rates</h5>
                                                    <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Reduce friction in the sales pipeline and close more deals.</p>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <img className="w-100" title="Schedule Ai for Marketing" src={marketingProfilePng} alt='' />
                                </div>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Customer Success</Accordion.Header>
                    <Accordion.Body>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="mt-4 ">
                                        <ol>
                                            <li className="d-flex">
                                                <div className="icon-dollar">
                                                    <i className="fa-regular fa-face-smile text-primary icon_Dev"></i>
                                                </div>
                                                <div className="drive-text-dev">
                                                    <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>Drive more maintenance
                                                    </h5>
                                                    <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Unite all your specialists and engage with clients throughout their journey to build lasting relationships.</p>
                                                </div>
                                            </li>
                                            <li className="d-flex">
                                                <div className="icon-dollar">
                                                    <i className="fa-regular fa-clock text-primary icon_Dev"></i>
                                                </div>
                                                <div className="drive-text-dev">
                                                    <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                        Accelerate your response times</h5>
                                                    <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Quickly schedule a time to address clients' needs and assist them in achieving their goals efficiently.
                                                    </p>
                                                </div>
                                            </li>
                                            <li className="d-flex">
                                                <div className="icon-dollar">
                                                    <i className="fa-solid fa-star text-primary icon_Dev"></i>
                                                </div>
                                                <div className="drive-text-dev">
                                                    <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                        Elevate NPS and Delight Customers</h5>
                                                    <p className="tab-paraDev" style={{ marginLeft: "5px" }}>By optimizing meeting scheduling, you can elevate customer satisfaction to new heights. Keep customers engaged and satisfied through well-timed reminders and efficient follow-up workflows.</p>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <img className="w-100" src={customerSuccessPng} alt='customer success' />
                                </div>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Recruiting</Accordion.Header>
                    <Accordion.Body>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="mt-4 ">
                                        <ol>
                                            <li className="d-flex">
                                                <div className="icon-dollar">
                                                    <i className="fa-solid fa-user-tie text-primary icon_Dev"></i>
                                                </div>
                                                <div className="drive-text-dev">
                                                    <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                        Boost employee productivity</h5>
                                                    <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Spend less time struggling with schedules and more time connecting with candidates.</p>
                                                </div>
                                            </li>
                                            <li className="d-flex">
                                                <div className="icon-dollar">
                                                    <i className="fa-solid fa-gauge-simple-high text-primary icon_Dev"></i>
                                                </div>
                                                <div className="drive-text-dev">
                                                    <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                        Accelerate your recruiting cycle</h5>
                                                    <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Schedule interviews in a matter of minutes and reduce time-to-fill positions.</p>
                                                </div>
                                            </li>
                                            <li className="d-flex">
                                                <div className="icon-dollar">
                                                    <i className="fa-solid fa-user-tie text-primary icon_Dev"></i>
                                                </div>
                                                <div className="drive-text-dev">
                                                    <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                        Improve NPS and candidate experience</h5>
                                                    <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Eliminate friction and turn your recruiting process into a competitive advantage.</p>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <img className="w-100" src={recruitementProfilePng} alt='recruitement' />
                                </div>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>Information Technology</Accordion.Header>
                    <Accordion.Body>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="mt-4 ">
                                        <ol>
                                            <li className="d-flex">
                                                <div className="icon-dollar">
                                                    <i className="fa-sharp fa-solid fa-shield-halved text-primary icon_Dev"></i>
                                                </div>
                                                <div className="drive-text-dev">
                                                    <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>Ensure enterprise-grade security with booking automation
                                                    </h5>
                                                    <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Stay aligned with your security requirements and reduce risk throughout the organization.</p>
                                                </div>
                                            </li>
                                            <li className="d-flex">
                                                <div className="icon-dollar">
                                                    <i className="fa-solid fa-laptop-code text-primary icon_Dev"></i>
                                                </div>
                                                <div className="drive-text-dev">
                                                    <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                        Implement and manage effortlessly</h5>
                                                    <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Eliminate the manual processes of implementing and managing client access and permissions on the platform.</p>
                                                </div>
                                            </li>
                                            <li className="d-flex">
                                                <div className="icon-dollar">
                                                    <i className="fa-solid fa-dollar-sign text-primary icon_Dev"></i>
                                                </div>
                                                <div className="drive-text-dev">
                                                    <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                        Drive adoption and ROI across teams</h5>
                                                    <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Partner with our team to onboard seamlessly, drive adoption, and identify success metrics to achieve greater value, faster.</p>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <img className="w-100" src={informationTechPng} alt='information technology' />
                                </div>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>Education</Accordion.Header>
                    <Accordion.Body>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="mt-4 ">
                                        <ol>
                                            <li className="d-flex">
                                                <div className="icon-dollar">
                                                    <i className="fa-solid fa-bolt text-primary icon_Dev"></i>
                                                </div>
                                                <div className="drive-text-dev">
                                                    <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                        Foster deeper connections through the automated booking</h5>
                                                    <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Dedicate higher-quality time to support students in advising, mentoring, career planning, and counseling.</p>
                                                </div>
                                            </li>
                                            <li className="d-flex">
                                                <div className="icon-dollar">
                                                    <i className="fa-solid fa-calendar-days text-primary icon_Dev"></i>
                                                </div>
                                                <div className="drive-text-dev">
                                                    <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                        Enhance communication and streamline coordination</h5>
                                                    <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Increase accessibility of your schedule to students and prospects, empowering them to book time when they need assistance the most.</p>
                                                </div>
                                            </li>
                                            <li className="d-flex">
                                                <div className="icon-dollar">
                                                    <i className="fa-solid fa-person-chalkboard text-primary icon_Dev"></i>
                                                </div>
                                                <div className="drive-text-dev">
                                                    <h5 className="align-items-center tab-textDev" style={{ marginLeft: "5px" }}>
                                                        Deliver an enriched educational experience</h5>
                                                    <p className="tab-paraDev" style={{ marginLeft: "5px" }}>Focus on what truly matters: supporting and engaging with your students, empowering them to thrive and succeed.</p>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <img className="w-100" src={educationPng} alt='education' />
                                </div>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>Healthcare</Accordion.Header>
                    <Accordion.Body>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                                    <div className="mt-4 ">
                                        <ol>
                                            <li className="d-flex">
                                                <div className="icon-dollar">
                                                    <i className="fa-solid fa-calendar-check text-primary icon_Dev"></i>
                                                </div>
                                                <div className="drive-text-dev">
                                                    <h5 className="align-items-center tab-textDev"
                                                        style={{ marginLeft: "5px" }}>Intelligent Appointment Booking</h5>
                                                    <p className="tab-paraDev" style={{ marginLeft: "5px" }}>ScheduleAI utilizes cutting-edge algorithms to analyze patient data, availability, and preferences, suggesting the most suitable appointment slots. Eliminate long wait times and scheduling conflicts.
                                                    </p>
                                                </div>
                                            </li>
                                            <li className="d-flex">
                                                <div className="icon-dollar">
                                                    <i className="fa-solid fa-notes-medical text-primary icon_Dev"></i>
                                                </div>
                                                <div className="drive-text-dev">
                                                    <h5 className="align-items-center tab-textDev"
                                                        style={{ marginLeft: "5px" }}>
                                                        Tailored Workflows</h5>
                                                    <p className="tab-paraDev" style={{ marginLeft: "5px" }}>ScheduleAI adapts to the unique needs of your healthcare facility. Whether you run a hospital, clinic, or specialized practice, you can customize workflows and scheduling rules to meet your specific requirements.</p>
                                                </div>
                                            </li>
                                            <li className="d-flex">
                                                <div className="icon-dollar">
                                                    <i className="fa-sharp fa-solid fa-shield-halved text-primary icon_Dev"></i>
                                                </div>
                                                <div className="drive-text-dev">
                                                    <h5 className="align-items-center tab-textDev"
                                                        style={{ marginLeft: "5px" }}>Compliance and Data Security
                                                    </h5>
                                                    <p className="tab-paraDev" style={{ marginLeft: "5px" }}>We prioritize patient privacy and data security. ScheduleAI adheres to the highest industry standards, ensuring that patient information remains protected at all times.
                                                    </p>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <img className="homeTabImgDev" title="Schedule Ai for Education" src={HealthcareScheduleaiPng} alt='Healthcare scheduleai' />
                                </div>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <section className='auto_AI-wrapper'>
                <div className="container mt-5">
                    <div className="column">
                        <h2 className="text-center fw-bold aiWrapperTitle">AI finds versatile applications in calendar booking applications, such as</h2>
                    </div>
                    <div className="row DevRow pb-5">

                        <div className="col-12 col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                            <div className="inner_Dev">

                                <h1 className="smd_title" style={{ color: "#0B5ED7" }}>Automatic scheduling
                                </h1>
                                <p className="lead_smd">An AI-powered calendar booking application can autonomously schedule meetings based on participants' availability, eliminating the need for manual input.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-md-6">
                            <img className="w-100 homeTabImgDev" src={automaticScheduling} alt="Automatic scheduling" />
                        </div>
                    </div>

                    <div className="row DevRow pb-4">
                        <div className="col-12 col-lg-6 col-md-6">
                            <img className="w-100 homeTabImgDev" src={timeOptimisationPng} alt="Time optimization" />
                        </div>

                        <div className="col-12 col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                            <div className="inner_Dev">

                                <h1 className="smd_title" style={{ color: "#0B5ED7" }}>Time optimization
                                </h1>
                                <p className="lead_smd">Utilizing AI, the calendar booking application suggests the optimal meeting time, considering factors like participants' availability, locations, and time zones.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row DevRow pb-5">
                        <div className="col-12 col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                            <div className="inner_Dev">

                                <h1 className="smd_title" style={{ color: "#0B5ED7" }}>Meeting Summaries
                                </h1>
                                <p className="lead_smd">The AI-powered app generates meeting summaries and action items automatically, helping participants stay informed about discussions and follow-up tasks.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-md-6">

                            <img className="w-100 homeTabImgDev" src={MeetingSummariesPng} alt="Meeting Summaries" />
                        </div>
                    </div>

                    <div className="row DevRow pb-5">
                        <div className="col-12 col-lg-6 col-md-6">
                            <img className="w-100 homeTabImgDev" src={IntelligentNotificationsPng} alt="Intelligent notifications" />
                        </div>
                        <div className="col-12 col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                            <div className="inner_Dev">

                                <h1 className="smd_title" style={{ color: "#0B5ED7" }}>Intelligent Notifications
                                </h1>
                                <p className="lead_smd">AI-driven notifications keep participants informed, sending timely reminders for upcoming meetings or suggesting rescheduling if needed.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row DevRow pb-5">
                        <div className="col-12 col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                            <div className="inner_Dev">

                                <h1 className="smd_title" style={{ color: "#0B5ED7" }}>Resource Management
                                </h1>
                                <p className="lead_smd">The AI-powered application efficiently manages resources, including conference rooms, equipment, and other meeting facilities.
                                </p>

                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-md-6">
                            <img className="w-100 homeTabImgDev" src={ResourceManagementPng} alt="Resource Management" />
                        </div>
                    </div>

                    <div className="row DevRow pb-5">
                        <div className="col-12 col-lg-6 col-md-6">
                            <img className="w-100 homeTabImgDev" src={PredictiveSchedulingPng} alt="Predictive Scheduling" />
                        </div>
                        <div className="col-12 col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                            <div className="inner_Dev">

                                <h1 className="smd_title" style={{ color: "#0B5ED7" }}>Predictive Scheduling
                                </h1>
                                <p className="lead_smd">The AI-driven calendar booking application predicts participants' availability and schedules meetings accordingly, streamlining the scheduling process.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='product_wrapper'>
                <div className="container">
                    <div className="product_content-wrapper">
                        <span className='text-center product_subtitle mb-2' >
                            Our Products</span>
                        <h2 className='product_maintitle mb-2'>Discover the forefront of Online Appointment
                            booking with our Products</h2>
                        <p className='product_para'>Explore the cutting-edge technology that makes millions of users trust our digital booking solutions.</p>
                    </div>
                    <div className="row mt-5 prd_Row">
                        <div className="col-md-4 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <div className="media d-flex justify-content-between">
                                        <i className="fa-solid fa-chart-line prdIcon"></i>
                                        <div className="media-body mediaCont">
                                            <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
                                        </div>
                                    </div>
                                    <div className="media-body mediaCont">
                                        <h4 className='Prd_Title'>Availability</h4>
                                        <p className='Prd_Para'>Enhance event organization with custom availability settings, ensuring a smoother experience than ever before.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <div className="media d-flex justify-content-between">
                                        <i className="fa-solid fa-laptop-code prdIcon"></i>
                                        <div className="media-body mediaCont">
                                            <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
                                        </div>
                                    </div>
                                    <div className="media-body">
                                        <h4 className='Prd_Title'>Customize Design</h4>
                                        <p className='Prd_Para'>Match your brand identity seamlessly with custom colors, backgrounds, themes, and logos on your booking website.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <div className="media d-flex justify-content-between">
                                        <i className="fa-regular fa-calendar-check prdIcon"></i>
                                        <div className="media-body mediaCont">
                                            <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
                                        </div>
                                    </div>
                                    <div className="media-body mediaCont">
                                        <h4 className='Prd_Title'>Calendar Sync</h4>
                                        <p className='Prd_Para'>Associate your Google and Microsoft Schedule to synchronize your accessibility.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container pt-5">
                    <div className="column">
                        <h2 className="text-center aiWrapperTitle fw-bold">We've simplified scheduling like never <br />before, just for you.
                        </h2>
                    </div>
                    <div className="row DevRow m-2">
                        <div className="col-12 col-lg-6 col-md-6 pt-5 align-items-center justify-content-center d-flex">
                            <div className="simDev_wrapper">
                                <h2 className='smd_title' style={{ color: "#0B5ED7" }}>Simplify planning
                                </h2>
                                <p className='lead_smd p-0'>Share your ScheduleAI availability effortlessly and experience the power of booking automation. Whether through email, text, or your website, watch as prospects and clients book high-value meetings with you.</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-md-5 pt-5">
                            <img className="SchAvailDevImg" src={ScheduleAiAvailabilityPng} alt="Schedule AI Availability" />
                        </div>
                    </div>

                    <div className="row DevRowai">
                        <div className="col-12 col-lg-6 col-md-6 pt-5 align-items-center justify-content-center d-flex">
                            <div className="simDev_wrapper">
                                <h2 className='smd_title' style={{ color: "#0B5ED7" }}>Collaborative scheduling with Schedule AI Availability
                                </h2>
                                <p className='lead_smd p-0'>Align ScheduleAI with your team's booking preferences. Co-host client calls, manage email updates and follow-ups, and seamlessly integrate with your preferred software tools.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg col-md-6 pt-5">
                            <img className="SchAvailDevImg" src={PreSalesPng} alt="Pre Sales" />
                        </div>
                    </div>
                    <div className="row DevRowai">
                        <div className="col-12 col-lg-6 col-md-6 pt-5 align-items-center justify-content-center d-flex">
                            <div className="simDev_wrapper">
                                <h2 className='smd_title' style={{ color: "#0B5ED7" }}>Pre-Sales efficiency
                                </h2>
                                <p className='lead_smd p-0'>Achieve your targets with high-value meetings, the backbone of your business. Boost revenue, retain customers, and secure new leads with the #1 booking automation platform.</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-md-6 pt-5">
                            <img className="SchAvailDevImg" src={percentPng} alt="percent-hit-number" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="mt-5" style={{ background: "#0A2540" }}>
                <div className="container profile-wrapper">
                    <div className="row DevRow pb-4">

                        <div className="col-12 col-lg-6 col-md-6 align-items-center d-flex">
                            <div className="mt-4">
                                <p className="lead_dev text-white" style={{ fontSize: "1.5em" }}>"Scheduleai has expanded gatherings
                                    <br />with clients by 216% for <br />our Vonage group."
                                </p>
                                <h4 className='text-white'>Robert Williams</h4>
                                <p className='text-white' style={{ fontSize: "1.4em" }}>Head of Client Achievement UK/EMEA</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-md-6 align-items-center d-flex mt-5 p-4">
                            <img className="w-100" title="Robert Williams" src={ProfilePng} alt="profile" />
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="bg-white pb-5">
                <div className="container mt-5">
                    <div className="column">
                        <h2 className="fw-bold text-center smd_text">Booking robotization occurs <br />when gatherings</h2>
                    </div>
                    <div className="row pt-4">
                        <div className="col-12 col-lg-3">
                            <div className="border rounded p-2">
                                <img className="w-100" src={CalendarSchedulePng} alt="Schedule AI Schedule" />
                                <h6 className="text-center my-2 salesContent-Dev" style={{ color: "rgb(11, 53, 88)" }}>Allow individuals <br />to plan gatherings<br /> on your website</h6>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className="border rounded p-2">
                                <img className="w-100" src={WorkflowPng} alt="workflow" />
                                <h6 className="text-center my-2 salesContent-Dev" style={{ color: "rgb(11, 53, 88)" }}>Cause individuals to reconfirm gatherings<br /> with you</h6>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className="border rounded p-2">
                                <img className="w-100" src={SalesTeamPeoplePng} alt="sales team people" />
                                <h6 className="text-center salesContent-Dev" style={{ color: "rgb(11, 53, 88)" }}>Screen individuals<br /> before they can <br />book with you</h6>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className="border rounded p-2">
                                <img className="w-100" src={RequestMeetingPng} alt="request meeting" />
                                <h6 className="text-center salesContent-Dev" style={{ color: "rgb(11, 53, 88)" }}>Consequently move an arrangement alongside supportive substance</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="pb-0 bg-light" >
                <div className="container">
                    <div className="row DevRow">

                        <div className="col-12 col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                            <div className="mt-4 ">
                                <h1 className="fw-bold smd_text">Integration and Expansions</h1>
                                <p className="lead_smd p-0" style={{ fontSize: "1.1em" }}>Enhance productivity with seamless integrations that seamlessly fit into your workflow
                                </p>
                                <a href="/login"><button
                                    className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Explore
                                    Integrations</button></a>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-md-6 pt-5 pb-5 d-flex">
                            <img className="homeTabImgDev" title="Integrations" src={InterationsPng} alt="interations" />
                        </div>
                    </div>
                </div>
            </section>



            <div className="container mt-5">
                <div className="column">
                    <h2 className="fw-bold text-center aiWrapperTitle" style={{ fontSize: "28px" }}>
                        Access the most useful technologies like real-time messaging team management to elevate your collaboration experience.
                    </h2>
                </div>
                <div className="row pt-4">
                    <div className="col-12 col-lg-4 col-md-4">
                        <img className="CRMimgDev" src={CRMCalendaryPng} alt={`CRM ${process.env.REACT_APP_DOMAINNAME.split(".")[0]}`} />
                        <h6 className="text-center my-2 salesContent-Dev" style={{ color: "rgb(11, 53, 88)" }}>What is Schedule AI?</h6>
                        <p className="text-center sales_Para_Dev">Best Appointment <br />Scheduling Software app.</p>
                    </div>
                    <div className="col-12 col-lg-4 col-md-4">
                        <img className="CRMimgDev" src={DevelopPrivateCompanyPng} alt="develop-private-company" />
                        <h6 className="text-center my-2 salesContent-Dev" style={{ color: "rgb(11, 53, 88)" }}>Manage your Day to Day
                            Meetings</h6>
                        <p className="text-center sales_Para_Dev">Access most useful technologies like realtime messaging team
                            management video & audio conference</p>
                    </div>
                    <div className="col-12 col-lg-4 col-md-4">
                        <img className="CRMimgDev" src={SalesforceTeamPng} alt={`salesforce team ${process.env.REACT_APP_DOMAINNAME.split(".")[0]}`} />
                        <h6 className="text-center my-2 salesContent-Dev" style={{ color: "rgb(11, 53, 88)" }}>Features by Plan</h6>
                        <p className="text-center sales_Para_Dev">Track down the membership seems <br /> fine for you or your group.</p>
                    </div>
                </div>
            </div>
            <ScrollTopButton />
            <Footer />
        </div>
    )
}

export default Homepage