import React from 'react';
import CapitalMarketBannerPng from "../../images/Capital Market Banner.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';

const CapitalMarkets = () => {
    return (
        <>
            <div className="heWhoRemains">
                <Header />
                <div>
                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">
                                        <h1 className=" mainHomeTitle">Schedule AI for <br />
                                            <span style={{ color: "#0B5ED7" }}> Capital Markets
                                            </span>
                                        </h1>
                                        <p className="lead_smd">
                                            Capital markets are constantly changing and evolving, making it essential for businesses to keep up with the latest appointment-scheduling software. This type of software can offer a number of benefits for businesses in the capital markets, including improved efficiency, better communication, and greater flexibility.
                                        </p>
                                        <a href="/login"><button
                                            className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                                            Demo</button></a>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <img className="w-100" src={CapitalMarketBannerPng} alt='Capital Market Banner' />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold text-center">Benefits of ScheduleAI for <br />Capital Markets
                                </h2>
                            </div>

                            <ul>
                                <li className='liListDevind'>Improved efficiency is one of the most important benefits of using ScheduleAI software.

                                </li>

                                <li className='liListDevind'> Better communication is another benefit of using ScheduleAI software.
                                </li>

                                <li className='liListDevind'>This type of software can help you keep track of all your appointments and contacts in one place, making it easy to stay organized and communicate with clients or colleagues.

                                </li>

                                <li className='liListDevind'>It can help to streamline the process of scheduling appointments with clients or potential investors.

                                </li>

                                <li className='liListDevind'> The software can also help to keep track of important details surrounding each meeting, such as the location, time, and purpose of the meeting.
                                </li>

                                <li className='liListDevind'>
                                    Using ScheduleAI  software can also help to create a more professional image for a company or individual involved in capital markets
                                </li>
                            </ul>

                        </div>
                    </section>

                    <section>
                        <div className="container textDevLay pb-4">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold">Conclusion
                                </h2>
                                <p className='lead_smd text-justify'>Schedule AI software can offer greater flexibility for businesses in the capital markets. With so many moving parts, it is often difficult to make last-minute changes to your schedule. However, with appointment scheduling software, you can easily adjust your schedule as needed, giving you the flexibility you need to succeed in this ever-changing industry.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
                
            <Footer />
            </div>
        </>
    )
}

export default CapitalMarkets
