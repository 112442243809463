import React from 'react';
import "../../Custom.css";
import Button from 'react-bootstrap/Button';
import { Link, useLocation } from 'react-router-dom';
import { Container, Row, Col, Nav, Navbar, Image } from 'react-bootstrap';

const ThankYou = () => {
    return (
        <>
            <div className="container vh-100 d-flex align-items-center justify-content-center flex-column">
                <h2 className='Thank_Dev display-6 '>Thank You</h2>
                <p className='ThanksPara_Dev'>Thanks for contacting us! We will be in touch with you shortly.</p>
                <Nav.Link as={Link} to="/" className='text-center'>
                    <Button variant="primary" className='headerNavLogin'>
                        Back to home</Button>
                </Nav.Link>
            </div>
        </>
    )
}

export default ThankYou