import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import "../../index.css";
import Cookies from 'js-cookie';
import SalseforceImg from "../../images/salesforceCompany.png";

import calendaryLogo from "../../images/ScheduleAILogo.png";


function SalseforceIntegration() {
    const serverUrl = `https://${process.env.REACT_APP_DOMAINNAME}:8443`;
    const [user, setUser] = useState(null);
    // const [access_token, setAccess_Token] = useState(null);
    const [refresh_token, setRefreshToken] = useState(null);
    const [clientID, setClientID] = useState("");
    const [clientSecret, setClientSecret] = useState("");
    const [instanceId, setInstanceId] = useState("");
    const [integrationMessage, setIntegrationMessage] = useState("");

    const [disabled, setDisabled] = useState(true);
    useEffect(() => {
        async function getCookies() {
            let gettingUserByCookie = null;

            if (process.env.REACT_APP_DEPLOY_MODE === "production") {
                let b = Cookies.get('jwtToken')
                const get = await axios.get(serverUrl + "/getCookieUser", {
                    headers: {
                        "Authorization": `Bearer ${b}`
                    }
                });
                if (get.status === 200) {
                    if (Object.keys(get.data).length > 0 && Boolean(get.data)) {
                        gettingUserByCookie = await axios.post(serverUrl + "/users/getLoginUserNow", { "email": get.data.email });
                    } else {
                        window.location.href = "/login";
                        // console.log("undefinned cookies")
                    }
                } else {
                    window.location.href = "/login";
                    // console.log("undefined get")
                }
            }
            else {
                gettingUserByCookie = await axios.post(serverUrl + "/users/getLoginUserNow", { "email": "gulfan@kcloudtechnologies.com" });
            }

            if (gettingUserByCookie.status === 200) {
                setUser(gettingUserByCookie.data);

            } else {
                window.location.href = "/login";
                // console.log("getting user error")
            }

        }
        getCookies();
    }, [])


    const connectSalesforceAccount = async (e) => {
        e.preventDefault();

        const load = document.querySelector("#loadingHere");
        load.disabled = true;
        load.innerHTML = '<span class="spinner-border spinner-border-sm text-light"></span>Loading..';


        const query = {
            "email": user.email,
            "instanceId": instanceId,
            "clientID": clientID,
            "clientSecret": clientSecret,
            "refreshToken": refresh_token,
        }
        const saveSalesforce = await axios.post(serverUrl + "/events/saveSalesforceToken", query);

        if (saveSalesforce.status === 200) {
            setDisabled(false);
            setIntegrationMessage("Successfully Integrated with Salseforce.");

            load.disabled = false;
            load.innerHTML = 'Connect Now';
        } else {
            setDisabled(true);
            setIntegrationMessage("Invalid credentials. Please check your salesforce credentials.");

            load.disabled = false;
            load.innerHTML = 'Connect Now';
        }

    }
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <div className="insightBackground border rounded">
                        <div className="border-bottom p-3 ">
                            <h1 className="display-6 fw-bold text-center py-3 color-salesforce">Connect {process.env.REACT_APP_DOMAINNAME.split(".")[0]} with Salseforce</h1>
                            <p className="text-center">Connect your salesforce account with the {process.env.REACT_APP_DOMAINNAME.split(".")[0]} in few seconds..</p>

                            <h3 className='text-center py-2 text-dark'>How it works?</h3>
                            <p className='text-center mediumTextSize'>
                                We have made it simple for you to connect your desired salesforce account to {process.env.REACT_APP_DOMAINNAME}.
                                Simply fill out two mandatory field including your salesforce instance url and your access token and you are ready to use the salesforce with {process.env.REACT_APP_DOMAINNAME.split(".")[0]}.
                                After the successfull integration, whenever someone books your event, it will automatically generate leads into your salesforce dashboard.
                            </p>
                            <alert className="alert alert-secondary d-flex gap-1 mediumTextSize">
                                <span style={{ fontWeight: "500" }}>Note:</span> The above mentioned salesforce instance url and access token are not the part of {process.env.REACT_APP_DOMAINNAME}.<br />
                                You will need to generate them in your salesforce dashboard manually to use them.
                            </alert>
                        </div>
                        <div className="p-3 insightBackground d-flex justify-content-center align-items-center gap-5">
                            <div className="Calendary">
                                <img src={calendaryLogo} alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} className='w-100' style={{ maxWidth: "150px" }} />
                            </div>
                            <div className="display-1"><i className="fa fa-plus"></i></div>
                            <div className="zoom">
                                <img src={SalseforceImg} alt={process.env.REACT_APP_DOMAINNAME.split(".")[0]} className='w-100' style={{ maxWidth: "150px" }} />
                            </div>
                        </div>
                        <div className="border-top text-center p-3">
                            <form onSubmit={(e) => { connectSalesforceAccount(e); }} className="w-100 d-flex justify-content-start align-items-center flex-column gap-3 mt-2">
                                <label className='w-100 text-start border rounded insightBackground p-2' htmlFor="" style={{ maxWidth: "800px" }}>
                                    <span style={{ fontWeight: "500" }}>Salesforce instance id: *</span>
                                    <input className="customInputFieldFocus w-100 rounded mb-2" type="text" onInput={(e) => { setInstanceId(e.target.value); }} required placeholder='https://yourusername.develop.my.salesforce.com' />
                                </label>

                                <label className='w-100 text-start border rounded insightBackground p-2' htmlFor="" style={{ maxWidth: "800px" }}>
                                    <span style={{ fontWeight: "500" }}>Client ID: *</span>
                                    <input className="customInputFieldFocus w-100 rounded mb-2" type="text" onInput={(e) => { setClientID(e.target.value); }} required placeholder='3MVG9fe4g9fhX......' />
                                </label>

                                <label className='w-100 text-start border rounded insightBackground p-2' htmlFor="" style={{ maxWidth: "800px" }}>
                                    <span style={{ fontWeight: "500" }}>Client Secret: *</span>
                                    <input className="customInputFieldFocus w-100 rounded mb-2" type="text" onInput={(e) => { setClientSecret(e.target.value); }} required placeholder='E9B895B4B5F43......' />
                                </label>

                                <label className='w-100 text-start border rounded insightBackground p-2' htmlFor="" style={{ maxWidth: "800px" }}>
                                    <span style={{ fontWeight: "500" }}>Latest Refresh Token: *</span>
                                    <input className="customInputFieldFocus w-100 rounded mb-2" type="text" onInput={(e) => { setRefreshToken(e.target.value); }} required placeholder='5Aep861NGChd......' />
                                </label>
                                <button className="btn salesforce-style rounded-pill mb-2 px-4 d-flex align-items-center justify-content-center gap-2 " type="submit" id="loadingHere" disabled={!disabled}>Connect Now</button>
                            </form>
                            {
                                disabled ? (
                                    <p className="text-danger py-2">{integrationMessage}</p>
                                )
                                    :
                                    (
                                        <>
                                            <p className="text-success py-2">{integrationMessage}</p>
                                            <button className="btn btn-success rounded-pill" onClick={() => { window.location.href = "https://" + process.env.REACT_APP_DOMAINNAME + "/calendar#Integrations" }} disabled={disabled}>Continue</button>
                                        </>
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SalseforceIntegration