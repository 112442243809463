import React from 'react';
import OilAndGasBannerPng from "../../images/Oil and Gas Banner.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';

const OilAndGas = () => {
    return (
        <>
            <div className="heWhoRemains">
                <Header />
                <div>
                    <section>
                        <div className="container mt-5">
                            <div className="row">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">
                                        <h1 className=" display-4 fw-bold smd_text">Schedule AI for <br />
                                            <span style={{ color: "#0B5ED7" }}> Oil & Gas
                                            </span>
                                        </h1>
                                        <p className="lead_smd text-black" style={{ fontSize: "1.1em" }}>
                                            If you're in the oil and gas industry, chances are you've heard of appointment scheduling software. This type of software can help you keep track of your appointments, customers, and employees.
                                        </p>
                                        <a href="/login"><button
                                            className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                                            Demo</button></a>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="w-100" src={OilAndGasBannerPng} alt='Oil and Gas Banner' />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold text-center">Benefits of Schedule AI for <br />Oil & Gas

                                </h2>
                            </div>

                            <ul>
                                <li className='liListDevind'>Schedule AI software can help you save time. With all of your appointments in one place, you can quickly see which ones are coming up and plan accordingly.
                                </li>

                                <li className='liListDevind'>It can help you stay organized. With all of your appointments in one place, it's easy to see which ones are conflicts and make sure that everyone is where they're supposed to be.
                                </li>

                                <li className='liListDevind'>Using ScheduleAI software can help you save money. By keeping track of your appointments, you can avoid overbooking or double-booking employees.

                                </li>

                                <li className='liListDevind'>It can help to save time and money. When appointments are properly scheduled, businesses can avoid the wasted time and resources that come from double-booking or having gaps in the schedule.
                                </li>

                                <li className='liListDevind'>
                                    When customers have the ability to schedule their own appointments online, they are more likely to be punctual and keep their appointments. This can lead to happier customers and fewer no-shows.
                                </li>
                            </ul>

                        </div>
                    </section>

                    <section>
                        <div className="container textDevLay pb-4">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold">Conclusion
                                </h2>
                                <p className='lead_smd text-justify'>Overall, there are many reasons why businesses in the oil and gas industry should consider using ScheduleAI software. When used correctly, it can lead to greater efficiency, happier customers, and improved bottom lines.
                                </p>
                            </div>
                        </div>
                    </section>

                </div>

                <Footer />
            </div>
        </>
    )
}

export default OilAndGas
