import React, { useState } from 'react'
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css'; 


function MultiEmailsReschedule({ prevGuests }) {
  const [email, setEmails] = useState(prevGuests?.length > 0 ? prevGuests.split(",") : []);
  return (
    <>
      <ReactMultiEmail
        placeholder="someone@mail.com..."
        emails={email}
        className="w-100"
        onChange={(_emails) => {
          setEmails(_emails);
        }}
        validateEmail={email => {
          return isEmail(email); // return boolean
        }}
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag key={index}>
              <div data-tag-item>{email}</div>
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          );
        }}
      />
      <input type="text" className='d-none' onChange={() => { }} value={email.join(', ') || 'empty'} id="multiEmailValids" />
    </>
  )
}

export default MultiEmailsReschedule