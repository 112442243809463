import React, { useRef, useState } from 'react';
import './Support.css';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import "../../Custom.css";
import axios from 'axios';
import { Helmet } from "react-helmet";
import ScrollTopButton from '../scrolltopbtn/ScrollTopButton';



function Support() {
    const nameField = useRef();
    const emailField = useRef();
    const phoneField = useRef();
    const messageField = useRef();
    const [phoneError, setPhoneError] = useState('');
    const [selectedTimezone, setSelectedTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

    const sendTheMail = async (e) => {

        e.preventDefault();

        const phoneRegex = /^\d{10}$/;
        const phoneNumber = phoneField.current.value;

        if (!phoneRegex.test(phoneNumber)) {
            setPhoneError('Invalid phone number. Please enter a 10-digit number.');
        } else {
            // Phone number is valid, proceed with form submission
            setPhoneError('');
            // Your form submission logic here

        }
        const serverUrl = "https://" + process.env.REACT_APP_DOMAINNAME + ":8443";
        const query = {
            "name": nameField.current.value,
            "email": emailField.current.value,
            "phone": phoneField.current.value,
            "message": messageField.current.value,
            "selectedTimezone": selectedTimezone
        }

        const sendEmail = await axios.post(serverUrl + "/users/sendSupportMail", query);

        if (sendTheMail.status === 200) {
            console.log(sendEmail.data);
        } else {
            console.log(sendEmail.data);
        }
    }
    return (
        <div className='heWhoRemains'>
            <Helmet>
                <title>Get a support from our team of Scheduleai</title>
                <meta name="description" content="Description - let’s get in touch have any queries regarding this calendar app please find the solution to every question." />
            </Helmet>
            <Header />
            <div>
                <section className="contact pt-5">
                    <div className="contact-heading">
                        <h2 className="title-cont-Dev">Contact Us</h2>
                    </div>
                    <div className="container contact-container">
                        <div className="row Dev-row">
                            <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                                <div className="contact-widget">
                                    <div className="contact-widget-item">
                                        <div className="icon-Dev">
                                            <i className="fa-solid fa-location-dot Dev-i"></i>
                                        </div>

                                        <div className="text-wrapper">
                                            <h5 className="Dev-loc">Address</h5>
                                            <p className="Dev-para">First Canadian Place, 100 King St W Suite <br />5600, Toronto, ON M5X 1C9, Canada</p>
                                        </div>
                                    </div>

                                    <div className="contact-widget-item">
                                        <div className="icon-Dev">
                                            <i className="fa-solid fa-phone Dev-i"></i>
                                        </div>

                                        <div className="text-wrapper">
                                            <h5 className="Dev-loc">Contact Us</h5>
                                            <p className="Dev-para">+1 619 798 6435</p>
                                        </div>
                                    </div>
                                    <div className="contact-widget-item">
                                        <div className="icon-Dev">
                                            <i className="fa-regular fa-envelope Dev-i"></i>
                                        </div>

                                        <div className="text-wrapper">
                                            <h5 className="Dev-loc">Mail</h5>
                                            <p className="Dev-para">support@{process.env.REACT_APP_DOMAINNAME}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                                <div className="contact-form">
                                    <form onSubmit={sendTheMail} className="form-wrapper">
                                        <input className="Dev-input" type="text" ref={nameField} placeholder="Name" required />
                                        <input className="Dev-input" type="email" ref={emailField} placeholder="Email" required />
                                        <input className="Dev-input" type="text" ref={phoneField} placeholder="Number" required />
                                        {phoneError && <p className="error-message">{phoneError}</p>}
                                        <textarea className="Dev-textarea" ref={messageField} placeholder="Message" required></textarea>
                                        <button type="submit" className="site-btn">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <ScrollTopButton/>
            <Footer />
        </div>
    )
}

export default Support
