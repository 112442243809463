import React, { useEffect, useState } from 'react'
import axios from 'axios';

import invalidPng from "../../images/invalidPng.gif";
import successPng from "../../images/successPng.gif";
import queryPng from "../../images/help.png";
import { Modal, Toast } from 'react-bootstrap';
import notFoundPng from "../../images/notFound.png";


import popupData from "../../audio/popup-alert.mp3";
import pageData from "../../audio/page-turn.wav";
import dayjs from 'dayjs';
import { res } from 'react-email-validator';


function CancellationPage() {
    const serverUrl = "https://" + process.env.REACT_APP_DOMAINNAME + ":8443/";
    //const serverUrl = "http://" + process.env.REACT_APP_DOMAINNAME + ":8443/";
    const [codeDecode, setCodeDecode] = useState({});

    const [appBranding, setAppBranding] = useState(true);
    const [brandLogo, setBrandLogo] = useState([]);
    const [brandLink, setBrandLink] = useState("#");

    const popupAlert = new Audio(popupData);
    const pageTurnSound = new Audio(pageData);

    const [viewToast, setViewToast] = useState(false);
    const [toastMessageType, setToastMessageType] = useState("");
    const [toastTitle, setToastTitle] = useState("");
    const [toastBody, setToastBody] = useState("");

    const [showAvailble, setShowAvailble] = useState(null)

    const [name, setName] = useState("");
    const [reasonOfCancel, setReasonOfCancel] = useState("");
    const [varifiedEmail, setVarifiedEmail] = useState(null);
    const [groupCancelButton, setGroupCancelButton] = useState(false);

    const [OTPVarifier, setOTPVarifier] = useState(false);
    const [OTP, setOTP] = useState(false);
    const [backendOTP, setBackendOTP] = useState(false);


    const [eventAvailable, setEventAvailable] = useState(false);

    const [myEvent, setMyEvent] = useState({});


    const [customAlertBox, setCustomAlertBox] = useState(false);
    const [alertBoxMessages, setAlertBoxMessages] = useState("");
    const [alertErrorType, setAlertErrorType] = useState("invalid");
    const [eventStatus, setEventStatus] = useState("cancelled");

    const [pastMeeting, setPastMeeting] = useState(false);

    const [isVerified, setIsVerified] = useState(false);


    useEffect(() => {
        async function getEvent() {
            const eventId = window.location.href.split("/")[window.location.href.split("/").length - 1];

            const getDetails = await axios.post(serverUrl + "events/decodeJWT", { "code": eventId });

            if (getDetails.status === 200) {
                // console.log(getDetails.data);

                const getEventDetails = await axios.post(serverUrl + "events/decryptEvent", getDetails.data);

                if (getEventDetails.status === 200) {

                    setCodeDecode(getEventDetails.data);

                    // console.log(getEventDetails.data)

                    const getEventNow = {
                        "adminEmail": getEventDetails.data["adminEmailForEvent"],
                        "meetingId": getEventDetails.data["meetingId"]
                    }

                    const getAllMeetingDetails = await axios.post(serverUrl + "events/getEventDetailsForEmailLinks", getEventNow);
                    if (getAllMeetingDetails.status === 200) {
                        console.log(getAllMeetingDetails.data);
                        // console.log(getEventDetails.data.adminEmail)
                        const getUserbyEmail = await axios.post(serverUrl + "users/findUserByEmail", { "email": getEventDetails.data.adminEmailForEvent });
                        // console.log(getUserbyEmail.data)
                        if (getUserbyEmail.status === 200) {
                            setAppBranding(getUserbyEmail.data.appBranding);
                            // console.log(getUserbyEmail.data.appBranding)

                            getUserbyEmail.data.brandLogos.map((logo, key) => {
                                if (logo.active === true) {
                                    // console.log(logo.logo)
                                    setBrandLink(logo.brandName)
                                    setBrandLogo(logo.logo);
                                }
                            });
                        } else {
                            // console.log("user not found")
                        }



                        let date = new Date(getAllMeetingDetails.data.date).getDate();
                        let month = new Date(getAllMeetingDetails.data.date).getMonth();
                        let year = new Date(getAllMeetingDetails.data.date).getFullYear();


                        let meridium = getAllMeetingDetails.data.startTime.split(" ")[1];
                        let timing = getAllMeetingDetails.data.startTime.split(" ")[0];

                        let OgHour = parseInt(timing.split(":")[0]);
                        let OgMin = parseInt(timing.split(":")[1]);

                        let hour = 0;

                        if (meridium.includes("pm")) {
                            if (OgHour < 12) {
                                hour = OgHour + 12;
                            }
                            else {
                                hour = OgHour;
                            }
                        } else {
                            hour = OgHour;
                        }

                        let min = OgMin;
                        let sec = 0;
                        if (dayjs(new Date(year, month, date, hour, min, sec)).valueOf() < dayjs(new Date()).valueOf()) {
                            // console.log("That was a past Meeting");
                            setPastMeeting(true);
                        }
                        setEventStatus(getAllMeetingDetails.data.status)
                        setMyEvent(getAllMeetingDetails.data);
                        setShowAvailble(true)
                        setEventAvailable(true);
                    } else {
                        setMyEvent({ "error": "No event found with the given uid." });
                        setShowAvailble(false);
                        setEventAvailable(false);
                    }

                } else {
                    // console.log(getEventDetails.data);
                    setCodeDecode(getEventDetails.data);
                    setEventAvailable(true);
                }

            } else {
                // console.log(getDetails.data);
                setCodeDecode(getDetails.data);
            }
        }
        getEvent();
    }, []);

    const handleSubmission = async () => {
        const query = {
            "cancelPolicy": reasonOfCancel,
            "cancelledBy": name,
            "varifiedEmail": varifiedEmail,
            "calenderId": codeDecode["calendarId"],
            "calenderEventId": myEvent.calendarEventId,
            "refresh_token": codeDecode["refreshToken"],
            "adminEmail": codeDecode["adminEmailForEvent"],
            "origionalEmailForReschedule": codeDecode["origionalEmailForReschedule"],
            "eventId": myEvent.eventId,
            "uuid": myEvent.uuid,
            "dateCreated": dayjs().format("h:mm:ssa - dddd, D MMMM YYYY") + " (" + dayjs.tz.guess() + ")",
        }

        const cancelEventNow = await axios.post(serverUrl + `events/${myEvent.meetingCategory !== "Group" ? "cancelFromExternal" : "cancelGroupFromExternal"}`, query);

        if (cancelEventNow.status === 200) {
            // console.log(cancelEventNow.data);

            popupAlert.play();
            setViewToast(true);
            setToastTitle("Successful");
            setToastBody("Event cancelled successfully.");
            setToastMessageType("success");

            setTimeout(() => {
                window.location.href = "/calendar";
            }, 2000)

        } else if (cancelEventNow.status === 203) {
            // console.log(cancelEventNow.data);

            popupAlert.play();
            setViewToast(true);
            setToastTitle("Error");
            setToastBody("This meeting doesn't exist.");
            setToastMessageType("invalid");

        } else {
            // console.log(cancelEventNow.data);

            popupAlert.play();
            setViewToast(true);
            setToastTitle("Error");
            setToastBody(cancelEventNow.data);
            setToastMessageType("invalid");
        }
    }


    const getVarificationFromEmail = async (email) => {
        setOTPVarifier(true);
        const sendVarificationEmailandVarify = await axios.post(serverUrl + "users/sendEmailVarification", { "email": email });

        if (sendVarificationEmailandVarify.status === 200) {
            console.log(sendVarificationEmailandVarify.data);
            setBackendOTP(sendVarificationEmailandVarify.data.otp);
            setVarifiedEmail(email);
            setGroupCancelButton(true);
        } else {
            setIsVerified(false);
            console.log(sendVarificationEmailandVarify.data);
            setVarifiedEmail(null);
            setGroupCancelButton(false);
        }
    }


    const checkOTPSubmission = async (e) => {
        e.preventDefault();
        if (OTP === backendOTP) {
            popupAlert.play();
            setViewToast(true);
            setToastTitle("Successful");
            setToastBody("Email Verified");
            setToastMessageType("success");
            setIsVerified(true);
            setOTPVarifier(false);
        } else {
            popupAlert.play();
            setViewToast(true);
            setToastTitle("Wrong OTP");
            setToastBody("Entered OTP is not correct.");
            setToastMessageType("invalid");
        }
    }

    return (
        <>
            {
                showAvailble === true && pastMeeting === false && eventStatus !== "cancelled" ?
                    <div className='freshMain'>
                        <div className='freshSecond'>
                            <div className="freshThird border insightBackground shadow p-3">
                                {
                                    appBranding ?
                                        <a href="/" target='_Blank' className="freshAncore">
                                            <div className="freshBranding">
                                                <div className="freshBrandingOne">powered by</div>
                                                <div className="freshBrandingTwo">Schedule AI</div>
                                            </div>
                                        </a>
                                        :
                                        <a href={brandLink} target='_Blank' className="freshAncoreCustom">
                                            <div className="freshBrandingCustom">
                                                <img src={brandLogo} alt="anchor" className='brandLogo' />
                                            </div>
                                        </a>
                                }
                                <div className="w-100 rounded">
                                    <button type='button' onClick={() => { window.location.href = "/calendar"; }} className='btn salesforce-style rounded-circle fw-bold backButtonAbs'>
                                        <i className='fa fa-arrow-left'></i>
                                    </button>
                                    <div className={`container-fluid ${eventAvailable ? "" : "d-none"}`}>
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6 d-flex justify-content-center px-3 py-5">
                                                <div className="insightBackground w-100 border-0 rounded overflow-hidden shadow" style={{ maxWidth: "450px" }}>
                                                    <form onSubmit={(e) => {
                                                        e.preventDefault();
                                                        setCustomAlertBox(true);
                                                        setAlertBoxMessages("Are you sure you want to cancel this event ?");
                                                        setAlertErrorType("queryCanc");
                                                    }}>
                                                        <div className="p-2 salesforce-style">
                                                            <h5 className=' w-100 text-center p-0 m-0'>Cancel the Event</h5>
                                                        </div>
                                                        <div className="p-3 d-flex flex-column justify-content-start align-items-start gap-3">
                                                            <input required type="text" className='customInputFieldFocus w-100' name="" placeholder='Your name' value={name} onChange={(e) => { setName(e.target.value); }} />
                                                            <input required type="email" className='customInputFieldFocus w-100' name="" placeholder='Your email' value={varifiedEmail} onChange={(e) => { setVarifiedEmail(e.target.value) }} />
                                                            <input type="button" disabled={isVerified ? true : varifiedEmail ? false : true} className='btn btn-success btn-sm rounded-pill ' style={{ maxWidth: "100px" }} value={isVerified ? "Verified" : "Varify Email"} onClick={(e) => { getVarificationFromEmail(varifiedEmail); }} />
                                                            <textarea placeholder='Reason for cancel' className='customInputFieldFocus w-100' rows="10" style={{ resizeX: "none" }} value={reasonOfCancel} onChange={(e) => { setReasonOfCancel(e.target.value); }}></textarea>
                                                        </div>
                                                        <div className="p-2 border-top d-flex align-items-center justify-content-center gap-3 flex-wrap">
                                                            <button disabled={myEvent.meetingCategory === "Group" ? groupCancelButton ? false : true : false} type='submit' className='btn btn-sm btn-secondary rounded-pill w-100' style={{ maxWidth: "130px" }}>Cancel Event</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 d-flex justify-content-center px-3 py-5">
                                                <div disabled className="insightBackground rounded w-100 border-0 overflow-hidden" style={{ maxWidth: "450px" }}>
                                                    <div className="p-2 border-bottom text-center bg-secondary text-light">
                                                        <span style={{ fontWeight: "500" }}>
                                                            Event Preview...
                                                        </span>
                                                    </div>
                                                    <div className="py-3">
                                                        <div className="insightBackground overflow-hidden w-100 h-100">
                                                            <div className="px-2 py-2 d-flex gap-3 justify-content-start h-100">
                                                                <div className='d-flex gap-2'>
                                                                    <div className={`event-shade-color-${myEvent.colorId} verticalColorBars`}></div>
                                                                    <div className='d-flex flex-column gap-3 align-items-start justify-content-center'>
                                                                        <p style={{ fontWeight: "600" }} className='mediumTextSize p-0 m-0' title="Meeting Name">
                                                                            <i style={{ width: "30px", fontSize: "18px" }} className='color-salesforce text-center fa fa-edit'></i> <span className='text-muted'>{myEvent.eventName}</span>
                                                                        </p>
                                                                        <p style={{ fontWeight: "600" }} className='mediumTextSize p-0 m-0' title="Attendee Name">
                                                                            <i style={{ width: "30px", fontSize: "18px" }} className='color-salesforce text-center fa fa-user'></i> <span className='text-muted'>{myEvent.name}</span>
                                                                        </p>
                                                                        {
                                                                            myEvent.meetingCategory !== "Group" ?
                                                                                <p style={{ fontWeight: "600" }} className='mediumTextSize p-0 m-0' title="Attendee Email Address">
                                                                                    <i style={{ width: "30px", fontSize: "18px" }} className="color-salesforce text-center fa fa-envelope"></i> <span className='text-muted'>{myEvent.email}</span>
                                                                                </p>
                                                                                :
                                                                                ""
                                                                        }

                                                                        <p style={{ fontWeight: "600" }} className={`mediumTextSize p-0 m-0 ${myEvent.phoneNumber.length > 0 ? "" : "d-none"}`} title="Attendee Phone Number">
                                                                            <i style={{ width: "30px", fontSize: "18px" }} className="color-salesforce text-center fa fa-phone"></i> <span className='text-muted'>{myEvent.phoneNumber}</span>
                                                                        </p>
                                                                        <p style={{ fontWeight: "600" }} className='mediumTextSize p-0 m-0' title="Meeting Date">
                                                                            <i style={{ width: "30px", fontSize: "18px" }} className='color-salesforce text-center fa fa-calendar'></i> <span className='text-muted'>{myEvent.date}</span>
                                                                        </p>
                                                                        <p style={{ fontWeight: "600" }} className='mediumTextSize p-0 m-0' title="Meeting Location">
                                                                            <i style={{ width: "30px", fontSize: "18px" }} className='color-salesforce text-center fa fa-location-dot'></i> <span className='text-muted'>
                                                                                {
                                                                                    myEvent.eventType === "meet" ?
                                                                                        "Google Meet Conference"
                                                                                        :
                                                                                        myEvent.eventType === "zoom" ?
                                                                                            "Zoom Conference"
                                                                                            :
                                                                                            myEvent.eventType === "webex" ?
                                                                                                "Webex Conference"
                                                                                                :
                                                                                                myEvent.eventType === "goto" ?
                                                                                                    "GoTo Conference"
                                                                                                    :
                                                                                                    myEvent.eventType === "teams" ?
                                                                                                        "Teams Conference"
                                                                                                        :
                                                                                                        myEvent.location

                                                                                }
                                                                            </span>
                                                                        </p>
                                                                        <p style={{ fontWeight: "600" }} className='mediumTextSize p-0 m-0' title="Meeting Time">
                                                                            <i style={{ width: "30px", fontSize: "18px" }} className='color-salesforce text-center fa fa-clock'></i> <span className='text-muted'>{myEvent.duration}, {myEvent.startTime} ({myEvent.givenTimezone.name})</span>
                                                                        </p>
                                                                        <p style={{ fontWeight: "600" }} className='mediumTextSize p-0 m-0 d-none' title="Attendee's shared suggestions.">
                                                                            <i style={{ width: "30px", fontSize: "18px" }} className="color-salesforce text-center fa fa-comment"></i>
                                                                            <span className='text-muted'>
                                                                                {/* {myEvent.sharedContent} */}

                                                                                {
                                                                                    typeof (myEvent.sharedContent) == "object" ?
                                                                                        myEvent.sharedContent.map((content, key) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <div className='d-flex flex-column gap-1 alert alert-info rounded m-0 mb-2 p-1 shadow-sm' key={key}>
                                                                                                        <span className='mediumTextSize'>From: <span className='fw-bold'>{content.email}</span></span>
                                                                                                        <span className='mediumTextSize'>Message: {content.content}</span>
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                        :
                                                                                        myEvent.sharedContent
                                                                                }
                                                                            </span>
                                                                        </p>
                                                                        <p style={{ fontWeight: "600" }} className='mediumTextSize p-0 m-0' title="Meeting Category">
                                                                            <i style={{ width: "30px", fontSize: "18px" }} className="color-salesforce text-center fa-solid fa-layer-group"></i> <span className='text-muted'>{myEvent.meetingCategory}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    eventAvailable && eventStatus === "cancelled" ?
                        <div className='freshMain'>
                            <div className='freshSecond'>
                                <div className="freshThird border insightBackground shadow p-3">
                                    {
                                        appBranding ?
                                            <a href="/" target='_Blank' className="freshAncore">
                                                <div className="freshBranding">
                                                    <div className="freshBrandingOne">powered by</div>
                                                    <div className="freshBrandingTwo">Schedule AI</div>
                                                </div>
                                            </a>
                                            :
                                            <a href={brandLink} target='_Blank' className="freshAncoreCustom">
                                                <div className="freshBrandingCustom">
                                                    <img src={brandLogo} alt="anchor" className='brandLogo' />
                                                </div>
                                            </a>
                                    }
                                    <div className="w-100 rounded">
                                        {/* <button type='button' onClick={() => { window.location.href = "/calendar"; }} className='btn salesforce-style rounded-circle fw-bold backButtonAbs'>
                                            <i className='fa fa-arrow-left'></i>
                                        </button> */}

                                        <div className={`container-fluid`}>
                                            <div className="row h-100 d-flex align-items-center justify-content-center">
                                                <div className="col-12 p-4 d-flex flex-column gap-5 justify-content-center align-items-center">
                                                    <h6 className='text-dark text-center'>
                                                        <img src={notFoundPng} className="w-100" style={{ maxWidth: "350px" }} alt="not found" />
                                                        <p className='color-salesforce mt-5 display-6' style={{ fontWeight: "600" }}>Cancelled</p>
                                                        <p className='mediumTextSize text-muted'>This event is already cancelled.</p>
                                                    </h6>
                                                    <button type='button' onClick={() => { window.location.href = "/calendar"; }} className='btn btn-sm salesforce-style rounded-pill px-3 w-100' style={{ maxWidth: "120px" }}>
                                                        {/* <i className='fa fa-arrow-left'></i> */}
                                                        Back
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        eventAvailable && eventStatus !== "cancelled" && pastMeeting ?
                            <div className='freshMain'>
                                <div className='freshSecond'>
                                    <div className="freshThird border insightBackground shadow p-3">
                                        {
                                            appBranding ?
                                                <a href="/" target='_Blank' className="freshAncore">
                                                    <div className="freshBranding">
                                                        <div className="freshBrandingOne">powered by</div>
                                                        <div className="freshBrandingTwo">Schedule AI</div>
                                                    </div>
                                                </a>
                                                :
                                                <a href={brandLink} target='_Blank' className="freshAncoreCustom">
                                                    <div className="freshBrandingCustom">
                                                        <img src={brandLogo} alt="anchor" className='brandLogo' />
                                                    </div>
                                                </a>
                                        }
                                        <div className="w-100 rounded">
                                            {/* <button type='button' onClick={() => { window.location.href = "/calendar"; }} className='btn salesforce-style rounded-circle fw-bold backButtonAbs'>
                                            <i className='fa fa-arrow-left'></i>
                                        </button> */}

                                            <div className={`container-fluid`}>
                                                <div className="row h-100 d-flex align-items-center justify-content-center">
                                                    <div className="col-12 p-4 d-flex flex-column gap-5 justify-content-center align-items-center">
                                                        <h6 className='text-dark text-center'>
                                                            <img src={notFoundPng} className="w-100" style={{ maxWidth: "350px" }} alt="not found" />
                                                            <p className='color-salesforce mt-5 display-6' style={{ fontWeight: "600" }}>Meeting Unavailable</p>
                                                            <p className='mediumTextSize text-muted'>This meeting was already held and cannot be cancelled.</p>
                                                            <p className='mediumTextSize text-muted'>We hope you have joined the meeting.</p>
                                                        </h6>
                                                        <button type='button' onClick={() => { window.location.href = "/calendar"; }} className='btn btn-sm salesforce-style rounded-pill px-3 w-100' style={{ maxWidth: "120px" }}>
                                                            {/* <i className='fa fa-arrow-left'></i> */}
                                                            Ok
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            showAvailble === false ?
                                <div className='freshMain'>
                                    <div className='freshSecond'>
                                        <div className="freshThird border insightBackground shadow p-3">
                                            {
                                                appBranding ?
                                                    <a href="/" target='_Blank' className="freshAncore">
                                                        <div className="freshBranding">
                                                            <div className="freshBrandingOne">powered by</div>
                                                            <div className="freshBrandingTwo">Schedule AI</div>
                                                        </div>
                                                    </a>
                                                    :
                                                    <a href={brandLink} target='_Blank' className="freshAncoreCustom">
                                                        <div className="freshBrandingCustom">
                                                            <img src={brandLogo} alt="anchor" className='brandLogo' />
                                                        </div>
                                                    </a>
                                            }
                                            <div className="w-100 rounded">
                                                {/* <button type='button' onClick={() => { window.location.href = "/calendar"; }} className='btn salesforce-style rounded-circle fw-bold backButtonAbs'>
                                            <i className='fa fa-arrow-left'></i>
                                        </button> */}

                                                <div className={`container-fluid`}>
                                                    <div className="row h-100 d-flex align-items-center justify-content-center">
                                                        <div className="col-12 p-4 d-flex flex-column gap-5 justify-content-center align-items-center">
                                                            <h6 className='text-dark text-center'>
                                                                <img src={notFoundPng} className="w-100" style={{ maxWidth: "350px" }} alt="not found" />
                                                                <p className='color-salesforce mt-5 display-6' style={{ fontWeight: "600" }}>Broken URL</p>
                                                                <p className='mediumTextSize text-muted'>Looks like the current url is not available.</p>
                                                            </h6>
                                                            <button type='button' onClick={() => { window.location.href = "/calendar"; }} className='btn btn-sm salesforce-style rounded-pill px-3 w-100' style={{ maxWidth: "120px" }}>
                                                                {/* <i className='fa fa-arrow-left'></i> */}
                                                                Back
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                (
                                    <div className={`min-vh-100 w-100 position-absolute top-0 left-start d-flex align-items-center justify-content-center`}>
                                        <span className="spinner-border spinner-border-lg text-secondary"></span>
                                    </div>
                                )
            }

            {/* ############################################################################################################ */}
            <Modal
                show={customAlertBox}
                // show={true}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => { setCustomAlertBox(false) }}
            >
                <Modal.Header className='salesforce-style py-2' closeButton closeVariant='white'>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <span style={{ fontSize: "1.2rem" }}>
                            {alertErrorType === "queryDelSchedule" ? "Delete" : alertErrorType === "queryDel" ? "Delete Event" : alertErrorType === "queryCanc" ? "Cancel Event" : ""}
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex flex-column justify-content-center align-items-center gap-3'>
                        {/* <img className='alertImageType' src={alertErrorType === "invalid" ? invalidPng : alertErrorType === "queryDel" ? queryPng : alertErrorType === "queryCanc" ? queryPng : alertErrorType === "queryDelSchedule" ? queryPng : successPng} alt="alert Image" /> */}
                        <span className={`alertMessageContent text-break text-center py-3`}>
                            {
                                alertBoxMessages
                            }
                        </span>
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-flex align-items-center justify-content-center py-2'>
                    {
                        alertErrorType === "queryCanc" ?
                            (
                                <div className='d-flex justify-content-center align-items-center gap-3'>
                                    <button className='btn btn-sm salesforce-style px-5 text-center rounded-pill my-1' onClick={() => { handleSubmission(); setCustomAlertBox(false); }}>Yes</button>
                                    <button className='btn btn-sm btn-secondary px-5 text-center rounded-pill my-1' onClick={() => { setCustomAlertBox(false); }}>No</button>
                                </div>
                            )
                            :
                            (
                                <button className='btn btn-sm salesforce-style px-5 text-center rounded-pill my-1' onClick={() => { setCustomAlertBox(false); }}>OK</button>
                            )
                    }
                </Modal.Footer>
            </Modal>
            {/* ############################################################################################################ */}


            <Modal
                show={OTPVarifier}
                // show={true}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => { setOTPVarifier(false) }}
            >
                <Modal.Header className='salesforce-style py-2' closeButton closeVariant='white'>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <span style={{ fontSize: "1.2rem" }}>
                            Email Verification
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <form method='post' onSubmit={(e) => { checkOTPSubmission(e); }}>
                    <Modal.Body>
                        <div className='d-flex flex-column justify-content-center align-items-center gap-3'>
                            <div className="alert alert-info w-100 mb-2">
                                Please Enter the OTP send to <b>{varifiedEmail}</b>
                            </div>
                            <input type="text" className='form-control shadow-sm mediumTextSize' placeholder='Enter OTP ' required onChange={(e) => { setOTP(e.target.value); }} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='d-flex align-items-center justify-content-center py-2'>
                        <div className='d-flex justify-content-center align-items-center gap-3'>
                            <button className='btn btn-sm salesforce-style px-5 text-center rounded-pill my-1' type="submit">Varify</button>
                            <button className='btn btn-sm btn-secondary px-5 text-center rounded-pill my-1' onClick={() => { setOTPVarifier(false); }}>Cancel</button>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>

            {/* #################################### TOAST FRAME ##################################### */}
            <div style={{ position: "fixed", bottom: "10px", right: "10px", zIndex: "99999999" }}>
                <Toast onClose={() => setViewToast(false)} show={viewToast} delay={3000} autohide className='overflow-hidden'>
                    <Toast.Header>
                        {/* <img
                                src="holder.js/20x20?text=%20"
                                className="rounded me-2 bg-primary"
                                alt="toastImage"
                                /> */}
                        <span style={{ fontWeight: "500" }} className={`me-auto ${toastMessageType === "invalid" ? "text-danger" : "text-success"}`}>{toastTitle}</span>
                        <small>just now</small>
                    </Toast.Header>
                    <Toast.Body style={toastMessageType === "success" ? { fontWeight: "500", background: "#d1e7dd", color: "#0f5132" } : { fontWeight: "500", background: "#f8d7da", color: "#842029" }}>
                        {toastBody}
                    </Toast.Body>
                </Toast>
            </div>
            {/* #################################### TOAST FRAME ##################################### */}
        </>
    )
}

export default CancellationPage