import React, { useEffect } from 'react'
// import BannerImgForCalendaryJpg from "../../images/Banner Img For Calendary.jpg";
import ScheduelAiBannerForIndividualsPng from "../../images/Scheduelai Banner For individuals.png";
import SaveTimePng from "../../images/Save Time.png";
import BookingSchedulePng from "../../images/Booking Schedule.png";
import MechanizedWarningPng from "../../images/Mechanized Warning.png";
import PlaningAtScalePng from "../../images/Planing at Scale.png";
import CalendaryVideoMp4 from "../../video/Calendary Video.mp4";
import WorkflowPng from "../../images/workflow.png";
import ScheduleAiAvailabilityPng from "../../images/Schedule ai Availability.png";
import SalesTeamPeoplePng from "../../images/Sales team people.png";
import { Link, Router, useLocation } from 'react-router-dom';
import { Container, Row, Col, Nav, Navbar, Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import "../../Custom.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import ScrollTopButton from '../scrolltopbtn/ScrollTopButton';



function Individuals() {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <div className='heWhoRemains'>
            <Helmet>
                <title>Free Scheduling Software for Individuals | Scheduleai</title>
                <meta name="description" content="Scheduleai simplifies the art of working smarter during your solo endeavors. Claim your individual account today." />
            </Helmet>
            <Header />
            <div>
                <div>
                    <section>
                        <div className="container">
                            <div className="row webDevRow">
                                <div className="col-12 col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4">
                                        <h1 className="smd_text fw-bold">The Smart Way to
                                            <span style={{ color: "#0B5ED7" }}> Work Efficiently</span>
                                        </h1>
                                        <p className="lead_smd text-black" style={{ fontSize: "1em" }}>Scheduleai simplifies working smarter while maximizing productivity. Meetings, appointments, and arrangements become powerful tools for progress and goal achievement.
                                        </p>
                                        <div data-aos="fade-right"
                                            data-aos-duration="2000">
                                            <Link to="/login" className='btnDev'>Get Started</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-md-6 pt-5">
                                    <img className="mainDevImg" title="Scheduelai Banner For individuals" src={ScheduelAiBannerForIndividualsPng} alt="Scheduelai Banner For individuals" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-4">
                            <div className="row webDevRow">
                                <div className="col-12 col-lg-6 col-md-6">
                                    <img className="mainDevImg" title="Schedule Ai BOOKING CONNECTION" src={SaveTimePng} alt="Schedule Ai BOOKING CONNECTION" />
                                </div>
                                <div className="col-12 col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4">
                                        <h6 className="text-primary" style={{ letterSpacing: "2px" }}>THE SCHEDULEAI BOOKING CONNECTION</h6>
                                        <h1 className="smd_title" style={{ letterSpacing: "1px" }}>Scheduleai Booking Connection
                                        </h1>
                                        <p className="lead_smd">Save time with personalized booking links, the courteous way to schedule meetings and appointments. With just a few clicks, everyone's time is optimized.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">
                                <div className="col-12 col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 pb-3">
                                        <h6 className="text-primary" style={{ letterSpacing: "2px" }}>AN ORGANIZED SCHEDULE</h6>
                                        <h1 className="smd_title" style={{ letterSpacing: "1px" }}>An Organized Schedule
                                        </h1>
                                        <p className="lead_smd">Efficiently book meetings as invitees select from your schedule, showing only available times, meeting lengths, and types. Streamline your schedule and reduce email exchanges.
                                        </p>

                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-md-6">
                                    <img className="mainDevImg" title="An Organized Schedule" src={BookingSchedulePng} alt="Booking Schedule" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">
                                <div className="col-12 col-lg-6 col-md-6">
                                    <img className="mainDevImg" title="Schedule a meeting" src={MechanizedWarningPng} alt="Mechanized Warning" />
                                </div>
                                <div className="col-12 col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">
                                        <h6 className="text-primary" style={{ letterSpacing: "2px", textTransform: "uppercase" }}>Mechanized Warnings and Subsequent meet-ups
                                        </h6>
                                        <h1 className="smd_title" style={{ letterSpacing: "1px" }}>Automated Reminders and Follow-ups
                                        </h1>
                                        <p className="lead_smd">Work like you have a personal assistant as Scheduleai automates administrative tasks like sending reminders and follow-ups. Focus on building your business and ensuring customer satisfaction.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">
                                <div className="col-12 col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">
                                        <h6 className="text-primary" style={{ letterSpacing: "2px", textTransform: "uppercase" }}>Planning AT SCALE</h6>
                                        <h1 className="display-5 smd_title fw-bold" style={{ letterSpacing: "0.5px" }}>Scaling with Ease
                                        </h1>
                                        <p className="lead_smd">Retain more clients by offering instant scheduling with Scheduleai. Ensure no one falls through the cracks, maintaining privacy, professionalism, and respect for their
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-md-6">
                                    <img className="mainDevImg" title="Planning AT SCALE" src={PlaningAtScalePng} alt="Planing at Scale" />
                                </div>
                            </div>
                        </div>
                    </section>


                    {/* <section>
                        <div className="container-fluid pt-5 pb-4 smd-book-conn">
                            <div className="container">
                                <h6 className="text-primary text-center" style={{ letterSpacing: "2px" }}>HOW IT WORKS</h6>
                                <h2 className="display-4 text-center smd_text">See Schedule AI in real life</h2>
                                <p className=" text-center pt-2" style={{ fontSize: "1.3em" }}>With booking bothers and
                                    interferences gone, your<br /> day is cleared for achievement.</p>
                                <div className="col text-center">
                                    <video className="w-100" autoPlay controls loop muted src={CalendaryVideoMp4}>
                                    </video>
                                </div>
                            </div>
                        </div>
                    </section> */}

                    <section className="bg-white pb-5">
                        <div className="container mt-5">
                            <div className="column">
                                <h2 className="fw-bold text-center smd_text">Most loved highlights and capacities</h2>
                            </div>
                            <div className="row pt-4">
                                <div className="col-12 col-lg-4 col-md-4 mb-2">
                                    <div className="border rounded p-2 h-100">
                                        <img className="w-100" title="Create workflow" src={WorkflowPng} alt="workflow" />
                                        <h4 className="text-center my-2 salesContent-Dev" style={{ color: "rgb(11, 53, 88)" }}>Workflow</h4>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-4 col-md-4 mb-2 ">
                                    <div className="border rounded p-2 h-100 mb-2">
                                        <img className="w-100" title="Embed website" src={ScheduleAiAvailabilityPng} alt="Schedule AI Schedule" />
                                        <h4 className="text-center my-2 salesContent-Dev" style={{ color: "rgb(11, 53, 88)" }}>WebSite embed</h4>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-4 col-md-4 mb-2">
                                    <div className="border rounded p-2 h-100">
                                        <img className="w-100" title="Customized Branding" src={SalesTeamPeoplePng} alt="sales team people" />
                                        <h4 className="text-center salesContent-Dev" style={{ color: "rgb(11, 53, 88)" }}>Customized branding</h4>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container-fluid smd_simple mt-5 pb-4">
                            <div className="container">
                                <div className="row">
                                    <h2 className="display-5 fw-bold text-center text-white pt-5">
                                        Track down perfectly makes arrangements for people and little groups</h2>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <ScrollTopButton/>
            <Footer />
        </div>

    )
}

export default Individuals
