import React, {useEffect} from 'react'
import HealthcareCloudBannerPng from "../../images/Healthcare Cloud Banner.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';


const HealthcareIndustry = () => {

    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <>
            <div className="heWhoRemains">
                <Header />
                <div>
                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">
                                        <h1 className="smd_text">ScheduleAI for
                                            <span style={{ color: "#0B5ED7" }}> Healthcare Industry
                                            </span>
                                        </h1>
                                        <p className="lead_smd">
                                            ScheduleAI software can be extremely beneficial for the healthcare industry. It can help to keep track of appointments and ensure that they are all filled. This can save a lot of time and money for the healthcare provider, as well as provide a better experience for the patients.

                                        </p>
                                        <a href="/login"><button
                                            className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                                            Demo</button></a>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="mainDevImg" src={HealthcareCloudBannerPng} alt='Healthcare Cloud Baner' />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold text-center">Benefits of ScheduleAI Software for the<br /> Healthcare Sector:
                                </h2>
                            </div>
                            <ul>
                                <li className='liListDevind'> ScheduleAI can help to improve patient satisfaction. When patients are able to schedule their own appointments, they are more likely to be satisfied with the process. </li>

                                <li className='liListDevind'> It can help to reduce no-shows. When patients schedule their own appointments, they are more likely to remember them and show up for them. This is because they have a personal investment in the appointment and do not want to waste their time or money.
                                </li>

                                <li className='liListDevind'>It can help to improve patient satisfaction by ensuring that appointments are scheduled in a timely and efficient manner. In addition, appointment scheduling software can help to reduce no-show rates and increase practice revenue.
                                </li>

                                <li className='liListDevind'> It can also help to streamline the appointment scheduling process for staff members, freeing up time for them to focus on other tasks.

                                </li>

                                <li className='liListDevind'> When used in conjunction with electronic medical records (EMR) systems, appointment scheduling software can further improve efficiency by providing information about patients’ past visits and medications.</li>
                            </ul>

                        </div>
                    </section>

                    <section>
                        <div className="container textDevLay pb-4">
                            <div className="column pt-5">
                                <h2 className="text-center display-6 fw-bold">Conclusion
                                </h2>
                                <p className='lead_smd text-center'>ScheduleAI can help to improve patient care by ensuring that appointments are scheduled in a timely and efficient manner. Additionally, this software can help to reduce no-shows, which can waste valuable time and resources. Furthermore, the use of appointment scheduling software can help to streamline the check-in process, making it more efficient and effective.
                                </p>
                            </div>
                        </div>
                    </section>


                </div>

                <Footer />
            </div>
        </>
    )
}

export default HealthcareIndustry
