import React from 'react';
import ScheduleAIForNonProfitBannerPng from "../../images/ScheduleAI for Non Profit banner.png"
import Header from '../header/Header';
import Footer from '../footer/Footer';

export default function NonProfit() {
    return (
        <>
            <div className="heWhoRemains">
                <Header />
                <div>

                    <section>
                        <div className="container mt-5">
                            <div className="row">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">
                                        <h1 className=" display-4 fw-bold smd_text">Schedule AI for<br />
                                            <span style={{ color: "#0B5ED7" }}> Non Profit
                                            </span>
                                        </h1>
                                        <p className="lead_smd text-black" style={{ fontSize: "1.1em" }}>
                                            If you work in the non-profit sector, you know how important it is to stay organized and efficient. Appointment scheduling software can be a big help in this area. Here are some of the benefits of using such software
                                        </p>
                                        <a href="/login"><button
                                            className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                                            Demo</button></a>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="w-100" src={ScheduleAIForNonProfitBannerPng} alt='ScheduleAI for Non Profit banner' />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold">Benefits of using Schedule AI for Non-Profit
                                </h2>
                            </div>
                            <div className="row">
                                <ul>
                                    <li className='liListDevind'>
                                        It can help you keep track of your appointments and deadlines. This is especially important if you work with multiple clients or have a lot of meetings.
                                    </li>

                                    <li className='liListDevind'>
                                        It can save you time by automating appointment reminders and follow-ups. This way, you can focus on other aspects of your work.
                                    </li>

                                    <li className='liListDevind'>
                                        It can help you manage your finances by tracking donations and expenses. This information can be very helpful when it comes time to report to donors or file taxes.
                                    </li>

                                    <li className='liListDevind'>
                                        It can help to streamline the process of scheduling appointments and keep track of important dates and events.
                                    </li>

                                    <li className='liListDevind'>
                                        It can help to reduce the amount of time spent on administrative tasks, freeing up more time for other activities.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container textDevLay pb-4">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold">Conclusion
                                </h2>
                                <p className='lead_smd text-justify'>Using this software can also improve communication between staff members and volunteers. By keeping everyone updated on the latest schedule, it helps to avoid conflicts and confusion. It can also make it easier to coordinate activities between different departments or groups.
                                </p>
                            </div>
                        </div>
                    </section>

                </div>

                <Footer />
            </div>
        </>
    )
}
