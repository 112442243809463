import React from 'react'
import Header from '../header/Header';
import Footer from '../footer/Footer';
import zoomIntegrationPng from "../../images/zoom integration.png";
import integrationWorkPng from "../../images/zoomIntegrationPage.png"
import zoomIntegrationsSuccessPng from "../../images/zoom-integrations-success.png";
import zoomDeauthorizationPng from "../../images/zoom deauthorization.png";
import selectZoomWhenCreatingEventPng from "../../images/zoomEventCreation.png";
import eventDetailsPagePng from "../../images/event-details-page.png";
import "../../Custom.css";

function ZoomIntegration() {
    return (
        <>
            <div>
                <Header />
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="p-5 mt-4">
                                    <h1 className="display-4 pt-8">Get Started
                                        <span>with Zoom Integrations.</span>
                                    </h1>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img className="w-100 rounded shadow"
                                    src={zoomIntegrationPng} alt="Zoom integration" />
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mt-4 mb-4">
                                <h2 className="mb-4">Zoom Integration.</h2>
                                <p className="lead_smd">Scheduling meetings and coordinating the details between all the tools in your tech
                                    stack can be a headache!</p>
                                <p className="lead_smd">When you manually schedule meetings, there’s endless room for error or
                                    forgetfulness: the hassle of back-and-forth emailing to coordinate times (which Schedule AI
                                    eliminates), making sure to include the correct Zoom or video conferencing link, remembering
                                    call-in details and passcodes … the list goes on.</p>

                                <p className="lead_smd mt-4">With this video conferencing integration, you can easily reduce the time you
                                    spend manually sharing Zoom details. Plus, you’ll be able to:</p>
                                <ul>
                                    <li>Automatically generate unique Zoom video conference details for every meeting or new event
                                        you schedule.</li>
                                    <li>Eliminate the need to set up and remember video conferencing details for all your meetings
                                    </li>
                                    <li>Instantly update meeting details when events are rescheduled or canceled</li>
                                </ul>
                                <p className="lead_smd">The best part? The Schedule AI + Zoom integration (and all our video conferencing
                                    integrations) are free forever on all Schedule AI pricing plans!</p>

                            </div>

                            <div className="col-md-12 mt-4">
                                <h1 className="text-center mb-4">How the Schedule AI + Zoom integration works</h1>
                                <p className="lead_smd text-center">To get started, all you need to do is connect your Zoom account with
                                    Schedule AI. Just visit your Schedule AI integrations page, select the Zoom integration, and sign in
                                    when you’re automatically redirected to Zoom. </p>

                                <img className="w-100 mb-5 mt-4 rounded shadow" src={integrationWorkPng} alt="integration work" />
                                <img className="w-100 mb-5 mt-4 rounded shadow" src={zoomIntegrationsSuccessPng} alt="Zoom-integrations-success" />

                                <p className="lead_smd">Please note: If your workplace owns your Zoom account, your Zoom account
                                    administrator must pre-approve Schedule AI in the Zoom Marketplace permissions to connect
                                    Schedule AI, and then all users must individually connect their Zoom account.</p>

                                <p className="lead_smd">Once connected, you’re prompted to set Zoom as the Location for any of your
                                    Schedule AI Event Types. By setting Zoom as your Event Type Location, Schedule AI will automatically
                                    include a dynamic video conference link for each meeting. Simply select the Event Type you’d
                                    like to edit from your Schedule AI dashboard by clicking on the event name or the settings wheel.
                                    Expand the “What event is this?” section, and select Zoom under the Location dropdown, then Save
                                    & Close.</p>
                                <div>
                                    <img className="w-100 mt-4 mb-5 rounded shadow" src={selectZoomWhenCreatingEventPng} alt="select-zoom-when-creating-event" />

                                    <p className="lead_smd">Pro tip: Zoom users can offer invitees a dial-in number in addition to the web
                                        conferencing link for each event scheduled. For international numbers, you can select multiple
                                        countries to include in your Zoom account settings and a link will be displayed in the invitee
                                        notification leading to their dial-in options.</p>

                                    <h1 className="text-center mb-4 mt-4">What this means for your invitees</h1>
                                    <p className="lead_smd">Now, when an invitee books a meeting with you, they’ll receive instant access to the
                                        Zoom meeting details via a calendar invitation or meeting confirmation email. You can also
                                        access these details and your unique link from your Scheduled Events details on your Schedule AI
                                        dashboard, and under Upcoming Meetings in Zoom.</p>
                                    <p className="lead_smd">Pro tip: Your invitees won’t see Zoom details until they’ve booked a meeting with
                                        you, but you may want to include a note in your event type Description/Instructions that you’re
                                        meeting on Zoom and they’ll receive details after booking.</p>
                                    <div>
                                        <img className="w-100 mt-4 mb-5 rounded shadow" src={eventDetailsPagePng} alt="event-details-page" />
                                        <p className="lead_smd">Plus, be sure to use Schedule AI features like Workflows and Notifications to set-up
                                            and personalize how Schedule AI sends out calendar invitations/email confirmations to include your
                                            Zoom details.</p>
                                        <p className="lead_smd">To start saving time by automatically adding Zoom video conference details to your
                                            Schedule AI meetings, set your integration up today.
                                        </p>
                                    </div>
                                    <div className="cta_smd w-100 text-center mb-4">
                                        <a href={`https://${process.env.REACT_APP_DOMAINNAME}/integration/zoom`}>
                                            <button className="btn btn-primary cta_smd" type="submit">Add Zoom Now</button>
                                        </a>
                                    </div>
                                </div>
                                <div className='container-fluid p-0 my-5'>
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="text-center mb-4">Deauthorize Your Zoom Account</h1>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <p>If any user want's to swtich or complete remove the Zoom integration from their Schedule AI Account, they can follow the steps below:</p>
                                            <ul>
                                                <li>Login to your <a href='https://scheduleai.co/calendar'>Schedule AI Account</a>.</li>
                                                <li>Go to <a href='https://scheduleai.co/calendar#AccountSettings#Profile'>Admin Panel</a> by clicking on profile picture placed on upper left corner.</li>
                                                <li>Navigate to <a href="https://scheduleai.co/calendar#Integrations">Integrations</a> page.</li>
                                                <li>Choose <a href="https://scheduleai.co/integration/zoom">Zoom Integraion</a>. If you have any Zoom account linked to your account it will be appear on this page.</li>
                                                <li>Finally Click Deauthorize button to completly remove your Zoom account from your Schedule AI account.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col text-center">
                                            <img src={zoomDeauthorizationPng} alt="" className='w-100 my-4 rounded shadow' />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <p>After deauthorization of your Zoom account you can add a new Zoom account or swtich to any other Zoom account as per your choise.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>
                <Footer />
            </div >
        </>
    )
}

export default ZoomIntegration
