import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import calendaryLogo from "../../images/ScheduleAILogo.png";
import Cookies from 'js-cookie';
import teamImg from "../../images/teamsCompany.png";
import "../../index.css";
function TeamsIntegration() {
    const serverUrl = "https://" + process.env.REACT_APP_DOMAINNAME + ":8443";
    const [user, setUser] = useState(null);
    const [access_token, setAccess_Token] = useState(null);
    const [refresh_token, setRefreshToken] = useState(null);

    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        async function getCookies() {
            let b = Cookies.get('jwtToken')
            const get = await axios.get(serverUrl + "/getCookieUser", {
                headers: {
                    "Authorization": `Bearer ${b}`
                }
            });
            if (get.status === 200) {
                if (Object.keys(get.data).length > 0 && Boolean(get.data)) {
                    const gettingUserByCookie = await axios.post(serverUrl + "/users/getLoginUserNow", { "email": get.data.email });

                    if (gettingUserByCookie.status === 200) {
                        setUser(gettingUserByCookie.data);

                        setDisabled(false)

                    } else {
                        window.location.href = process.env.REACT_APP_DOMAINNAME1 + "/login";
                        // console.log("getting user error")
                    }
                } else {
                    window.location.href = process.env.REACT_APP_DOMAINNAME1 + "/login";
                    // console.log("undefinned cookies")
                }
            } else {
                window.location.href = process.env.REACT_APP_DOMAINNAME1 + "/login";
                // console.log("undefined get")
            }
        }
        getCookies();
    }, [])
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <div className="insightBackground border rounded">
                        <div className="border-bottom p-3">
                            <h1 className="display-6 fw-bold text-center py-3 text-primary">Connect Schedule AI with Teams</h1>
                            <p className="text-center">Connecting your Teams account to Schedule AI. Please be patient</p>
                        </div>
                        <div className="p-3 d-flex justify-content-center align-items-center gap-5">
                            <div className="Calendary">
                                <img src={calendaryLogo} className='w-100' style={{ maxWidth: "150px" }} alt="" />
                            </div>
                            <div className="display-1"><i className="fa fa-plus"></i></div>
                            <div className="zoom">
                                <img src={teamImg} className='w-100' style={{ maxWidth: "150px" }} alt="" />
                            </div>
                        </div>
                        <div className="border-top text-center py-4">
                            {
                                disabled ? (
                                    <p className="text-success py-2">Successfully Integrated with Teams</p>
                                )
                                    :
                                    (
                                        <>
                                            <p className="text-warning py-2">Connecting with Teams</p>
                                            <button className="btn salesforce-style rounded-pill px-5" onClick={() => { window.location.href = process.env.REACT_APP_DOMAINNAME1 + "/calendar#Integrations" }} disabled={!disabled}>Continue</button>
                                        </>
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamsIntegration