import React from 'react';
import RetailSectorBannerPng from "../../images/Retail Sector Banner.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';

const Retail = () => {
  return (
    <>
      <div className="heWhoRemains">
        <Header />
        <div>
          <section>
            <div className="container mt-5">
              <div className="row">

                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                  <div className="mt-4 ">
                    <h1 className=" display-4 fw-bold smd_text">Schedule AI for<br />
                      <span style={{ color: "#0B5ED7" }}> Retail Sector
                      </span>
                    </h1>
                    <p className="lead_smd text-black" style={{ fontSize: "1.1em" }}>
                      The retail sector is a fast-paced and competitive industry. In order to stay ahead of the competition, it is important to have an efficient system for managing appointments. ScheduleAI software can be a valuable tool for retail businesses.
                    </p>
                    <a href="/login"><button
                      className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                      Demo</button></a>
                  </div>
                </div>
                <div className="col-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                  <img className="w-100" src={RetailSectorBannerPng} alt='Retail Sector Banner' />
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container mt-5">
              <div className="column pt-5">
                <h2 className="display-6 fw-bold text-center">Benefits of Schedule AI for the <br />Retail Sector

                </h2>
              </div>
              <ul>
                <li className='liListDevind'>The software can help to improve customer service levels, as it can send reminders to customers about their upcoming appointments.
                </li>

                <li className='liListDevind'> This can help to reduce the number of missed or forgotten appointments, which can save businesses both time and money.

                </li>

                <li className='liListDevind'>This software can help businesses to track customer preferences and requirements. This information can be used to improve the overall customer experience by ensuring that they are matched with the right service provider for their needs.

                </li>

                <li className='liListDevind'>With an effective appointment scheduling system in place, businesses can avoid the wasted time and effort associated with double-booking or overbooking appointments.

                </li>

                <li className='liListDevind'> This software can also help businesses to improve their customer service. By allowing customers to book their own appointments online, businesses can provide a more convenient and flexible service. This can lead to happier customers and repeat business.
                </li>
                <li className='liListDevind'> By having all appointments in one centralized system, businesses can better plan staff availability and other resources. This can result in cost savings and improved efficiency overall.
                </li>
              </ul>

            </div>
          </section>

          <section>
            <div className="container textDevLay pb-4">
              <div className="column pt-5">
                <h2 className="display-6 fw-bold">Conclusion
                </h2>
                <p className='lead_smd text-justify'>ScheduleAI can be a valuable asset for any retail business. The benefits of using such a system are numerous and can help businesses to gain a competitive edge.
                </p>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </div>
    </>
  )
}

export default Retail
