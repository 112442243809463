import React from 'react'
import Header from '../header/Header';
import Footer from '../footer/Footer';
import termAndConditionsPng from "../../images/term and conditions.png";
import "../../Custom.css";

function RefundAndCancellation() {
    return (
        <div className='heWhoRemains'>
            <div>
                <Header />
                <section>
                    <div className="container smd_priv-bg">
                        <div className="row">
                            <div className="col-lg-6 d-flex justfy-content-center align-items-center">
                                <div className="p-5 mt-4">
                                    <h1 className="display-4 fw-bold">Refund and
                                        <span>Cancellation</span>
                                    </h1>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img className="w-100" src={termAndConditionsPng} alt="Refund and Cancellation" />
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container mt-4 border-bottom">
                        <div className="row">
                            <div className="col-md-12 border-bottom">
                                <h3 className="mb-4">Refund and Cancellation</h3>
                                <h4 className="text-primary">Subscription Period and Subscription Fees.</h4>
                                <p className="lead_smd">The applicable fee related to your use of this Website is given in detail on our
                                    pricing page. One needs to pay according to the usage of services and subscriptions. Once
                                    the payment method is provided, one agrees to grant us the right to charge the applicable
                                    fees along with the taxes and other charges, applicable on regular intervals according to
                                    the particular subscription and utilized services acquired.</p>
                                <p className="lead_smd">Charges are applicable for Paid Accounts based on the type selected. For renewal, we
                                    will charge the then-current fee plus the taxes for the type by the approved payment method.
                                    Each Renewal Term may include a price increase of the Consumer Price Index (“CPI”) + 3% unless
                                    we notify you of a different rate before each Renewal Term starts. Note that all payments are
                                    non-refundable.To the extent that payment mechanisms are provided through third parties, you
                                    agree that {process.env.REACT_APP_DOMAINNAME} will have no liability to you arising out of the acts or omissions of
                                    such third parties. If applicable, you agree to maintain current billing information.</p>
                                <p className="lead_smd">WE ADVISE YOU TO GET THESE TERMS AND CONDITIONS PRINTED TO FILE FOR FURTHER
                                    REFERENCE. ONCE THE PAID ACCOUNT IS CREATED, THIS MEANS YOU GRANT CALENDARY THE CONSENT TO
                                    CHARGE (THE AMOUNT DUE FOR THE INITIAL SUBSCRIPTION PERIOD AND ADDED SUBSCRIPTION PERIODS UNTIL
                                    YOUR ACCOUNT IS CANCELED OR TERMINATED) DIRECTLY OR THROUGH THE PAYMENT PROCESSES OF THE PAYMENT
                                    METHOD PROVIDED.</p>

                                <h5 className="mb-4">Renewal/Cancellation</h5>
                                <p className="lead_smd"> To cancel the subscription for an account, contact us at support@{process.env.REACT_APP_DOMAINNAME}.com.
                                    The cancellation is with immediate effect. After cancellation, one cannot use the Services or
                                    the Platform and cannot claim for refund of any fee paid by them. If the present subscription
                                    period is about to end and the subscription is not canceled, it will automatically get renewed
                                    for the same subscription type and period.</p>


                                <h5 className="mb-4">Legal Compliance</h5>
                                <p className="lead_smd">You agree to obey all applicable domestic and international laws, statutes,
                                    ordinances, and regulations regarding your use of Schedule AI Website and agree to inform us
                                    immediately if you witness and get to know of any unauthorized or illegal use of the Website. We
                                    reserve the rights to scrutinize complaints and report a breach of our Terms and Conditions and
                                    take action that we think is appropriate like cancellation of user’s account, reporting to law
                                    enforcement officials, regulators, or third parties, and disclosing personal information of the
                                    account holders like e-mail addresses, usage history, posted materials, IP addresses, and
                                    traffic information to them as allowed under our Privacy Notice.</p>


                                <h5 className="mb-4">Intellectual Property</h5>

                                <p className="lead_smd">Our company is protected under international copyright laws. Due to which users are
                                    strictly forbidden from copying, redistribution, use, or publication of any portion of our
                                    Website or those of our affiliates or other companies such as logo, service marks/trademarks,
                                    and any and all intellectual property provided in connection with the Services to Customer or
                                    any Authorized User. Grants given for use of Services do not include ownership rights of any
                                    kind in our Website or Materials. We reserve all rights not explicitly given to the Customer and
                                    mentioned in this Agreement. Except for the limited rights and licenses expressly granted under
                                    this Agreement, nothing in this Agreement grants, by implication, waiver, estoppel, or
                                    otherwise, to Customer or any third party any intellectual property rights or other rights,
                                    title, or interest in or to the {process.env.REACT_APP_DOMAINNAME} IP.</p>

                                <h5 className="mb-4">Notices and Messages</h5>

                                <p className="lead_smd">You give us the consensus to send you emails that include information related to the
                                    Services e.g. notification of charges and fees. If you are an Invitee who has provided an email,
                                    then you give us the consensus to send the emails that include transactional information like
                                    booking confirmations and reminders. If you are an Invitee who has provided a phone number then
                                    you give us the consensus to send the SMS that includes transactional information like booking
                                    confirmations and reminders.</p>

                                <p className="lead_smd">You accept that any electronic or written communication that includes any
                                    announcements, notices, agreements, disclosures, or others will satisfy any legal communication
                                    needs. You will keep your contact details up to date and make sure that you receive your
                                    communication either through email or SMS. If, as an Invitee and/or {process.env.REACT_APP_DOMAINNAME} User you do
                                    consent to the use of SMS notifications as described herein, you also agree to comply with the
                                    terms and conditions of the Twilio Acceptance Use Policy when sending and/or receiving such SMS
                                    notifications.</p>


                                <h5 className="mb-4">Links to Other Websites.</h5>
                                <p className="lead_smd">Merely as a convenience to you, we may include some third-party links on our
                                    website. By linking to these websites, we do not create or have an affiliation with or sponsor
                                    such third party websites. Note that we do not provide any endorsement, guarantee, warranty, or
                                    recommendation of such third party websites and we have no check over their legal documents and
                                    privacy practices. By using the third party link, you may end up in leakage of your information
                                    or give them control of your information. Note that when you access these third-party sites then
                                    you are doing this at your own risk and Schedule AI is not responsible for anything in that
                                    respect.</p>
                                <p className="lead_smd">{process.env.REACT_APP_DOMAINNAME} has no control over the legal documents and privacy practices of third
                                    party websites, and by using them, you may be giving those third parties permission to use or
                                    control your information in ways {process.env.REACT_APP_DOMAINNAME} would not. As such, you access any third-party
                                    websites at your own risk.</p>

                                <h5 className="mb-4">Forums</h5>

                                <p className="lead_smd">Certain areas of this Website are public-facing and allow you to interact with
                                    others (e.g., the {process.env.REACT_APP_DOMAINNAME} blog and the idea portal) (together, “Forums”). These Forums may
                                    permit you to submit feedback, information, data, text, software, messages, or other materials
                                    (each, a "Submission"). We love hearing from our Users and Invitees! It's incredibly helpful and
                                    helps us to make a better {process.env.REACT_APP_DOMAINNAME} for all. If you choose to provide a Submission, either
                                    directly to us or via a Forum, you agree that we can use them without any restrictions or
                                    compensation to you.</p>

                                <p className="lead_smd">Any information that you post in a Forum might be read, collected, and used by
                                    others who access it. To request the removal of your information from a Forum, contact
                                    support@{process.env.REACT_APP_DOMAINNAME}.com. In some cases, we may not be able to remove your information, in which
                                    case we will let you know that, and why.</p>
                                <p className="lead_smd">You agree that you are solely responsible for all of your Submissions and that any
                                    such Submissions are considered both non-confidential and non-proprietary. If you use Forums,
                                    you also understand that anyone could see your Submissions. {process.env.REACT_APP_DOMAINNAME} does not endorse, nor
                                    are we required to pre-screen your Submissions (although we might), and, like elsewhere on the
                                    Internet, Submissions from other people could offend you. Further, we do not guarantee that you
                                    will be able to edit or delete any Submission you have submitted.</p>

                                <p className="lead_smd">By submitting any Submission, you are promising us that you own all rights in your
                                    Submission, your Submission does not infringe on the rights of others, your Submission does not
                                    violate any laws or regulations, and your Submission is not in poor taste.</p>

                                <p className="lead_smd">You grant to us an irrevocable royalty-free license to copy, modify, publish and
                                    display your Submission, and to make, use (and permit others to use) your Submission for any
                                    purpose (including, without limitation, to use your Submission in our Website).</p>

                                <p className="lead_smd">We are not obligated to publish any information or content on our Forum and can
                                    remove information or content at our sole discretion, with or without notice.</p>

                                <h5 className="mb-4">Unauthorized Activities</h5>
                                <p className="lead_smd">Use of Services is limited to Permitter Purpose only for a user and beyond these are
                                    strictly prohibited. If used other than these then it will be considered unauthorized and
                                    breaching various United States and international copyright laws which may result in
                                    discontinuing of the Services or deletion of your Schedule AI account. Listing a few of the
                                    examples (NOT a complete list ) of what you are not allowed to do. User is not authorized to use
                                    these Services in any way( unless you have written permission from us)</p>
                                <ul className="shadow p-3 mb-5 bg-body rounded smd_list_refund">
                                    <li>One is not allowed to create a similar product, act as a dealer, and sell it to a third
                                        party.</li>
                                    <li>One is not allowed to use services in a manner that restructures or modifies, displays or
                                        performs it publicly, replicates or allot any of the Services.</li>
                                    <li>Not allowed to breach any rules, the relevant law, or the Terms of the Agreement (not
                                        restricted to the region where the User/Invitee/ Authorized User belongs).</li>
                                    <li>Not allowed to harass, stalk, bother, harm, or ill-treat another individual, organization,
                                        or business.</li>
                                    <li>Not allowed to reveal any information that you don't have permission to.</li>
                                    <li>Not allowed to pretend or misrepresent to be any person or entity, or falsify any personal
                                        information.</li>
                                    <li>Not allowed to hinder with or disturb the Services or servers or networks connected to the
                                        Services.</li>
                                    <li>In terms of Services one is not allowed to apply data mining, robotics, or other means to
                                        data collection or extraction methods.</li>
                                    <li>Not allowed to diffuse any virus in the code or harm it in any respect.</li>
                                    <li>Not allowed to use means like hacking, password mining, etc to get illegal access to whole
                                        or part of the Services or any other accounts, computer systems, or networks connected to
                                        the Services.</li>
                                    <li>Unless and until using authorized {process.env.REACT_APP_DOMAINNAME} payment integration, one is not allowed to
                                        misuse Payment Card Industry data by storing or submitting.</li>
                                    <li>To disclose any types of information listed in the California Customer Records statute (Cal.
                                        Civ. Code § 1798.80(e)) or other relevant privacy regulations, including medical or health
                                        insurance information, education information, employment information, credit or debit card
                                        information, or any other financial information, driver’s license, or other government
                                        identification number.</li>
                                    <li>To disclose or collect any types of information considered to be protected classNameification
                                        characteristics under California or other relevant law, including Age (if 40 years or
                                        older), race, color, ancestry, national origin, citizenship, religion or creed, marital
                                        status, medical condition, physical or mental disability, sex (including gender, gender
                                        identity, gender expression, pregnancy or childbirth, and related medical conditions),
                                        sexual orientation, veteran or military status, and genetic information (including familial
                                        genetic information); and</li>
                                    <li>To disclose or collect any information defined by relevant laws as biometric information, or
                                        any information that falls within the definition of “special categories of data” under EU
                                        Data Protection Laws, “protected health information” under California or other relevant law
                                        or regulations. “Special categories of data” include, for example, information about an
                                        individual’s race, ethnic origin, politics, religion, trade union membership, genetics,
                                        biometrics, health, sex life, sexual orientation, or criminal records.</li>
                                </ul>


                                <h5 className="mb-4">Indemnification</h5>
                                <p className="lead_smd">You alone are responsible for any violation of these Terms by you. You agree to
                                    indemnify
                                    and hold {process.env.REACT_APP_DOMAINNAME}, its parents, subsidiaries, affiliates, officers, agents, employees,
                                    resellers or
                                    other partners, and licensors harmless from any claim, demand, loss, or damages, including
                                    reasonable
                                    attorneys’ fees (“Losses”), resulting from any third-party claim, suit, action, or
                                    proceeding
                                    (“Third-Party Claim”) that the Customer Data, or the use thereof in accordance with this
                                    Agreement,
                                    infringes or misappropriation such third party’s intellectual property rights or violates
                                    any applicable
                                    law or regulation and any Third-Party Claims based on Customer’s or any Authorized Users’
                                    (i) negligence
                                    or willful misconduct; (ii) use of the Services in a manner not authorized by this
                                    Agreement; (iii) use of
                                    the Services in combination with data, software, hardware, equipment, or technology not
                                    provided by
                                    {process.env.REACT_APP_DOMAINNAME} or authorized by {process.env.REACT_APP_DOMAINNAME} in writing; or (iv) modifications to the Services
                                    not made by
                                    {process.env.REACT_APP_DOMAINNAME}, provided that Customer may not settle any Third-Party Claim against
                                    {process.env.REACT_APP_DOMAINNAME} unless
                                    {process.env.REACT_APP_DOMAINNAME} consents to such settlement, and further provided that {process.env.REACT_APP_DOMAINNAME} will have
                                    the right, at
                                    its option, to defend itself against any such Third-Party Claim or to participate in the
                                    defense thereof
                                    by counsel of its own choice.</p>
                                <h5 className="mb-4">Warranty Disclaimer</h5>
                                <p className="lead_smd">THE SERVICES ARE PROVIDED "AS IS" AND {process.env.REACT_APP_DOMAINNAME} HEREBY DISCLAIMS ALL
                                    WARRANTIES,
                                    WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE. {process.env.REACT_APP_DOMAINNAME} SPECIFICALLY DISCLAIMS ALL
                                    IMPLIED
                                    WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
                                    NON-INFRINGEMENT, AND ALL
                                    WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE PRACTICE. {process.env.REACT_APP_DOMAINNAME} MAKES NO
                                    WARRANTY OF ANY
                                    KIND THAT THE {process.env.REACT_APP_DOMAINNAME} IP, OR SERVICES, OR ANY PRODUCTS OR RESULTS OF THE USE THEREOF,
                                    WILL MEET
                                    CUSTOMER'S OR ANY OTHER PERSON'S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY
                                    INTENDED RESULT,
                                    BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM, OR OTHER SERVICES, OR BE SECURE, ACCURATE,
                                    COMPLETE, FREE
                                    OF HARMFUL CODE, OR ERROR FREE. {process.env.REACT_APP_DOMAINNAME} STRICTLY DISCLAIMS ALL WARRANTIES, WITH RESPECT
                                    TO ANY
                                    THIRD-PARTY INTEGRATIONS.

                                    THIS MEANS THAT WE DO NOT PROMISE YOU THAT THE SERVICES ARE FREE OF PROBLEMS. WITHOUT
                                    LIMITING THE
                                    GENERALITY OF THE FOREGOING, WE MAKE NO WARRANTY THAT THE SERVICES WILL MEET YOUR
                                    REQUIREMENTS OR WILL BE
                                    UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, OR THAT DEFECTS WILL BE CORRECTED. WE MAKE NO
                                    WARRANTY AS TO
                                    THE RESULTS THAT MAY BE OBTAINED OR AS TO THE ACCURACY OR RELIABILITY OF ANY INFORMATION
                                    OBTAINED THROUGH
                                    THE SERVICES. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU THROUGH THE
                                    SERVICES OR
                                    FROM US OR OUR SUBSIDIARIES/OTHER AFFILIATED COMPANIES WILL CREATE ANY WARRANTY. WE DISCLAIM
                                    ALL EQUITABLE
                                    INDEMNITIES.</p>

                                <h5 className="mb-4">Limitation of Liability</h5>
                                <p className="lead_smd">Schedule AI is cannot be held responsible to you or others for any damage caused
                                    by you while
                                    using the Services, or by displaying or downloading any documentation from or to the
                                    website. In no case
                                    Schedule AI will be liable for any UNDER OR IN CONNECTION WITH THIS AGREEMENT UNDER ANY LEGAL
                                    OR EQUITABLE
                                    THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND
                                    OTHERWISE, FOR
                                    ANY: (a) CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED, OR PUNITIVE
                                    DAMAGES; (b)
                                    INCREASED COSTS, DIMINUTION IN VALUE OR LOST BUSINESS, PRODUCTION, REVENUES, OR PROFITS; (c)
                                    LOSS OF
                                    GOODWILL OR REPUTATION; (d) USE, INABILITY TO USE, LOSS, INTERRUPTION, DELAY, OR RECOVERY OF
                                    ANY DATA, OR
                                    BREACH OF DATA OR SYSTEM SECURITY; OR (e) COST OF REPLACEMENT GOODS OR SERVICES, IN EACH
                                    CASE REGARDLESS
                                    OF WHETHER {process.env.REACT_APP_DOMAINNAME} WAS ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH
                                    LOSSES OR DAMAGES
                                    WERE OTHERWISE FORESEEABLE.</p>
                                <p className="lead_smd">IN NO EVENT WILL {process.env.REACT_APP_DOMAINNAME}’S AGGREGATE LIABILITY OR {process.env.REACT_APP_DOMAINNAME}’S
                                    INDEMNIFICATION OBLIGATIONS ARISING OUT OF OR RELATED TO THIS AGREEMENT UNDER ANY LEGAL OR
                                    EQUITABLE
                                    THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND
                                    OTHERWISE EXCEED
                                    THE GREATER OF (I) THE AMOUNT PAID, IF ANY, BY YOU TO {process.env.REACT_APP_DOMAINNAME} IN CONNECTION WITH THESE
                                    TERMS IN THE
                                    TWELVE (12) MONTHS PRIOR TO THE ACTION GIVING RISE TO LIABILITY OR (II) INR 10,100.</p>
                                <p className="lead_smd">THIS LIMITATION OF LIABILITY IS PART OF THE BASIS OF THE AGREEMENT
                                    BETWEEN YOU AND
                                    {process.env.REACT_APP_DOMAINNAME} AND SHALL APPLY TO ALL CLAIMS OF LIABILITY, EVEN IF {process.env.REACT_APP_DOMAINNAME} HAS BEEN TOLD
                                    OF THE
                                    POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF THESE REMEDIES FAIL THEIR ESSENTIAL PURPOSE.</p>


                                <h5 className="mb-4">Contact Us.</h5>
                                <p className="lead_smd">If you have any questions about these Terms or otherwise need to contact us for
                                    any reason,
                                    you can reach us <a className="fw-bold" href="/">here.</a></p>



                            </div>
                        </div>



                    </div>

                </section>
                <Footer />
            </div>
        </div>
    )
}

export default RefundAndCancellation
