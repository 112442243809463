import React, {useEffect} from 'react'
import EducationWebpageBannerPng from "../../images/Education webpage banner.png";
import AutomatedAppointmentRemindersPng from "../../images/Automated appointment reminders.png";
import EasyReschedulingPng from "../../images/Easy rescheduling.png";
import BetterTimeManagementPng from "../../images/Better time management.png";
import IncreasedProductivityPng from "../../images/Increased productivity.png";
import OnlineBookingPng from "../../images/Online Booking.png";
import ResourceManagementIndustriesPng from "../../images/Resource Management industries.png";
import ReportingPng from "../../images/Reporting.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';


function Education() {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <>
            <div className="heWhoRemains">
                <Header />
                <div>
                    <section>
                        <div className="container">
                            <div className="row webDevRow">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">
                                        <h1 className="mainHomeTitle">Schedule Ai for The
                                            <span style={{ color: "#0B5ED7" }}> Education Sector</span>
                                        </h1>
                                        <p className="lead_smd">
                                            Schools and universities face a number of challenges when it comes to managing their student timetables and appointment bookings. But with the help of Appointment Scheduling Software, these challenges can be addressed in an efficient and timely manner.
                                        </p>
                                        <a href="/login"><button
                                            className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                                            Demo</button></a>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <img className="mainDevImg" src={EducationWebpageBannerPng} alt='Education webpage banner' />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="column pt-5">
                                <h2 className="text-center display-6 fw-bold">Benefits of Schedule AI Software <br />for the Education Sector
                                </h2>
                            </div>
                            <div className="row webDevRow">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div>

                                        <h1 className="display-6 smd_title">Automated appointment reminders
                                        </h1>
                                        <p className="lead_smd"> Appointment scheduling software can send out automatic reminders to students and staff about upcoming appointments. This ensures that no one forgets about an appointment and that everyone is aware of their commitments in advance.
                                        </p>

                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 mt-5">
                                    <img className="w-100" src={AutomatedAppointmentRemindersPng} alt="Automated appointment reminders" />
                                </div>

                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">
                                <div className="col-12 col-lg-6 mt-5">
                                    <img className="mainDevImg" src={EasyReschedulingPng} alt="Easy rescheduling" />
                                </div>
                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">

                                        <h1 className="display-5 smd_title">Easy rescheduling
                                        </h1>
                                        <p className="lead_smd"> If there are changes to the schedule, or if someone needs to reschedule an appointment, it is quick and easy to do so with appointment scheduling software.

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">
                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div>
                                        <h1 className="display-6 smd_title" style={{ fontWeight: "400" }}>Better time management
                                        </h1>
                                        <p className="lead_smd"> With appointment scheduling software, it is easy to see at a glance what appointments are coming up and when they are scheduled for. This makes it much easier to manage your time effectively and avoid double bookings or overloading yourself with too many appointments in a short space of time.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 mt-5">
                                    <img className="mainDevImg" src={BetterTimeManagementPng} alt="Better time management" />
                                </div>

                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">

                                <div className="col-12 col-lg-6 mt-5">
                                    <img className="mainDevImg" src={IncreasedProductivityPng} alt="Increased productivity" />
                                </div>
                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">

                                        <h1 className="display-6 smd_title" style={{ fontWeight: "400" }}>Increased productivity
                                        </h1>
                                        <p className="lead_smd"> By streamlining the process of managing appointments, appointment scheduling software can free up your time to focus on other tasks. This can lead to increased productivity
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">
                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div>
                                        <h1 className="display-5 smd_title" style={{ fontWeight: "400" }}>Online Booking
                                        </h1>
                                        <p className="lead_smd">Another useful feature of appointment scheduling software is online booking. This allows students and parents to book appointments online, without having to contact the school directly.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6 mt-5" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="mainDevImg" src={OnlineBookingPng} alt="Online Booking" />
                                </div>

                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">

                                <div className="col-12 col-lg-6 mt-5" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="mainDevImg" src={ResourceManagementIndustriesPng} alt="Resource Management industries" />
                                </div>
                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-up"
                                    data-aos-duration="2000">
                                    <div>

                                        <h1 className="display-6 smd_title" style={{ fontWeight: "400" }}>Resource Management
                                        </h1>
                                        <p className="lead_smd"> Another key feature of appointment scheduling software is resource management. This allows schools to track and manage all of the resources that are required for appointments, such as classrooms, textbooks, and other materials.



                                        </p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="row webDevRow">
                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center" data-aos="fade-up"
                                    data-aos-duration="2000">
                                    <div>

                                        <h1 className="display-6 smd_title" style={{ fontWeight: "400" }}>Reporting
                                        </h1>
                                        <p className="lead_smd">Finally, another important feature of appointment scheduling software is reporting. This allows schools to generate reports on various aspects of their appointment schedule, such as attendance rates, cancellation rates, and more.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6 mt-5" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="mainDevImg" src={ReportingPng} alt="Reporting" />
                                </div>

                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container textDevLay pt-5 pb-4">
                            <div className="column pt-5">
                                <h2 className="text-center display-6 fw-bold">Conclusion
                                </h2>
                                <p className='lead_smd text-center'>Appointment scheduling software is a great way for schools and universities to streamline their operations. It can help save time, improve efficiency, reduce costs, and provide a more organized experience for students and staff alike. With its automated features such as notifications, reminders, and reporting capabilities, ScheduleAi software can give educational institutions the edge they need to stay competitive in an ever-changing landscape.</p>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default Education
