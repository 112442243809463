import React from 'react'
import Home from './Home'
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

function BridgeToHome({ socket, socketId }) {
    const serverUrl = "https://" + process.env.REACT_APP_DOMAINNAME + ":8443";
    //const serverUrl = "http://" + process.env.REACT_APP_DOMAINNAME + ":8443";
    const [user, setUser] = useState(null);
    const [firstCreatedAt, setFirstCreatedAt] = useState(null);

    useEffect(() => {
        // async function getCookies() {
        //     let b = Cookies.get('jwtToken')

        //     const get = await axios.get(serverUrl + "/getCookieUser", {
        //         headers: {
        //             "Authorization": `Bearer ${b}`
        //         }
        //     });
        //     if (get.status === 200) {
        //         if (get.data !== undefined && get.data.length > 1) {
        //             const gettingUserByCookie = await axios.post(serverUrl + "/users/getLoginUserNow", { "email": get.data }, { headers: { "Authorization": process.env.REACT_APP_REST_API_AUTHORIZATION } });

        //             if (gettingUserByCookie.status === 200) {
        //                 setUser(gettingUserByCookie.data);

        //                 const findStartedDate = await axios.post(serverUrl + "/mainIds/get", { "email": get.data });
        //                 if (findStartedDate.status === 200) {
        //                     setFirstCreatedAt(findStartedDate.data.createdAt);
        //                 } else {
        //                     console.log('redirecting login ---->1')
        //                     // window.location.href = "https://" + process.env.REACT_APP_DOMAINNAME + "/login";
        //                 }
        //             } else {
        //                 console.log('redirecting login ---->1---->user not found')
        //                 // window.location.href = "https://" + process.env.REACT_APP_DOMAINNAME + "/login";
        //                 // console.log("getting user error")
        //             }
        //         } else {
        //             console.log('redirecting login ---->1---> cookies not found')
        //             window.location.href = process.env.REACT_APP_DOMAINNAME1 + "/login";
        //             // console.log("undefinned cookies")
        //         }
        //     } else {
        //         console.log('redirecting login ---->1no data found')
        //         //  window.location.href = "https://" + process.env.REACT_APP_DOMAINNAME + "/login";
        //         // console.log("undefined get")
        //     }
        // }

        async function getCookies() {

            try {
                let b = Cookies.get('jwtToken')
                const get = await axios.get(serverUrl + "/getCookieUser", {
                    headers: {
                        "Authorization": `Bearer ${b}`
                    }
                });
                console.log('cookes ====>', get)
                if (get.status === 200) {

                    if (get.data !== undefined) {

                        const gettingUserByCookie = await axios.post(serverUrl + "/users/getLoginUserNow", { "email": get.data.email });
                        console.log("user ====>", gettingUserByCookie.status)
                        if (gettingUserByCookie.status === 200) {


                            setUser(gettingUserByCookie.data);

                            const findStartedDate = await axios.post(serverUrl + "/mainIds/get", { "email": get.data.email });
                            console.log("findStartedDate ====>", findStartedDate)
                            if (findStartedDate.status === 200) {
                                setFirstCreatedAt(findStartedDate.data.createdAt);
                            } else {
                                console.log("im redirecting to login page",)
                                window.location.href = process.env.REACT_APP_DOMAINNAME1 + "/login";
                            }
                        } else {
                            console.log("im redirecting to login =====2",)
                            window.location.href = process.env.REACT_APP_DOMAINNAME1 + "/login";
                            console.log("getting user error")
                        }
                    } else {
                        console.log("im redirecting to login page=====3",)
                        window.location.href = process.env.REACT_APP_DOMAINNAME1 + "/login";
                        console.log("undefinned cookies")
                    }
                } else {
                    console.log("im redirecting to login page====4",)
                    window.location.href = process.env.REACT_APP_DOMAINNAME1 + "/login";
                    console.log("undefined get")
                }
            } catch (error) {
                if (error.response && error.response.status === 402) {
                    console.log("Token expired or invalid. Redirecting to login page...1");
                    // Redirect to the login page or handle the error accordingly
                    window.location.href = process.env.REACT_APP_DOMAINNAME1 + "/login";
                } else if (error.response && error.response.status === 401) {
                    console.log("Token expired or invalid. Redirecting to login page...2");
                    // Redirect to the login page or handle the error accordingly
                    window.location.href = process.env.REACT_APP_DOMAINNAME1 + "/login";
                } else {
                    console.error("Error occurred while fetching user data:", error);
                }
            }
        }

        async function getManualUser() {

            const gettingUserByCookie = await axios.post(serverUrl + "/users/getLoginUserNow");

            if (gettingUserByCookie.status === 200) {
                setUser(gettingUserByCookie.data);

                const findStartedDate = await axios.post(serverUrl + "/mainIds/get", { "email": "gulfan@kcloudtechnologies.com" });
                if (findStartedDate.status === 200) {
                    setFirstCreatedAt(findStartedDate.data.createdAt);
                } else {
                    // window.location.href =  process.env.REACT_APP_DOMAINNAME1 + "/login";
                    console.log(findStartedDate.data)
                }
            } else {
                // window.location.href =  process.env.REACT_APP_DOMAINNAME1 + "/login";
                console.log("getting user error");
            }
        }

        if (process.env.REACT_APP_DEPLOY_MODE === "production") {
            getCookies();

        } else {
            getManualUser();
        }
    }, [])


    useEffect(() => {
        if (user) {
            socket.emit("storeMe", user.email)
        }
    }, [user])
    return (
        user && firstCreatedAt ?
            (
                <Home user={user} firstCreatedAt={firstCreatedAt} socket={socket} />
            )
            :
            (
                <div className={`min-vh-100 w-100 position-absolute top-0 left-start d-flex align-items-center justify-content-center`}>
                    <span className="spinner-border spinner-border-lg text-secondary"></span>
                </div>
            )
    )
}

export default BridgeToHome