import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import gotoImg from "../../images/gotoCompany.png";
import "../../index.css";
import calendaryLogo from "../../images/ScheduleAILogo.png";
import Cookies from 'js-cookie';
function GotoIntegration() {
    const serverUrl = "https://" + process.env.REACT_APP_DOMAINNAME + ":8443";
    const [user, setUser] = useState(null);
    const [access_token, setAccess_Token] = useState(null);
    const [refresh_token, setRefresh_Token] = useState(null);
    const [disabled, setDisabled] = useState(true);
    useEffect(() => {
        async function getCookies() {
            let gettingUserByCookie = null;

            if (process.env.REACT_APP_DEPLOY_MODE === "production") {
                let b = Cookies.get('jwtToken')
                const get = await axios.get(serverUrl + "/getCookieUser", {
                    headers: {
                        "Authorization": `Bearer ${b}`
                    }
                });
                if (get.status === 200) {
                    if (Object.keys(get.data).length > 0 && Boolean(get.data)) {
                        gettingUserByCookie = await axios.post(serverUrl + "/users/getLoginUserNow", { "email": get.data.email });
                    } else {
                        window.location.href = "/login";
                        console.log("undefinned cookies")
                    }
                } else {
                    window.location.href = "/login";
                    // console.log("undefined get")
                }
            }
            else {
                gettingUserByCookie = await axios.post(serverUrl + "/users/getLoginUserNow", { "email": "gulfan@kcloudtechnologies.com" });
            }

            if (gettingUserByCookie.status === 200) {
                setUser(gettingUserByCookie.data);

                const queryParams = new URLSearchParams(window.location.search)
                const code = queryParams.get("code");

                const getTokens = await axios.post(serverUrl + "/events/getGotoMeetingToken", { "code": code });
                if (getTokens.status === 200) {
                    // console.log(getTokens.data);
                    setAccess_Token(getTokens.data.access_token);
                    setRefresh_Token(getTokens.data.refresh_token);
                    setDisabled(false);

                    const saveNewTokens = await axios.post(serverUrl + "/events/saveNewGotoMeetingRefreshToken", { "tokens": getTokens.data, "email": gettingUserByCookie.data.email });
                    if (saveNewTokens.status === 200) {
                        // console.log(saveNewTokens.data);
                    } else {
                        // console.log(saveNewTokens.data);
                    }
                } else {
                    // console.log(getTokens.data);
                }
            } else {
                window.location.href = "https://" + process.env.REACT_APP_DOMAINNAME + "/login";
                // console.log("getting user error")
            }
        }
        getCookies();
    }, [])
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <div className="insightBackground border rounded">
                        <div className="border-bottom p-3">
                            <h1 className="display-6 fw-bold text-center py-3 color-salesforce">Connect Schedule AI with GoTo Meeting</h1>
                            <p className="text-center">Connecting your GoTo Meeting account to Schedule AI. Please be patient</p>
                        </div>
                        <div className="p-3 d-flex justify-content-center align-items-center gap-5">
                            <div className="Calendary">
                                <img src={calendaryLogo} className='w-100' style={{ maxWidth: "150px" }} alt="" />
                            </div>
                            <div className="display-1"><i className="fa fa-plus"></i></div>
                            <div className="zoom">
                                <img src={gotoImg} className='w-100' style={{ maxWidth: "150px" }} alt="" />
                            </div>
                        </div>
                        <div className="border-top text-center py-4">
                            {
                                disabled ? (
                                    <p className="text-warning py-2">Connecting with GoTo Meeting</p>
                                )
                                    :
                                    (
                                        <>
                                            <p className="text-success py-2">Successfully Integrated with GoTo Meeting</p>
                                            <button className="btn salesforce-style rounded-pill px-5" onClick={() => { window.location.href = "https://" + process.env.REACT_APP_DOMAINNAME + "/calendar#Integrations" }} disabled={disabled}>Continue</button>
                                        </>
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GotoIntegration