import React, { useEffect } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { useState } from 'react';
import './pricing.css';
import "../../Custom.css";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ScrollTopButton from '../scrolltopbtn/ScrollTopButton';

function Pricing() {

    const [year, setYear] = useState(false)
    const [monthActive, setMonthActive] = useState(true)
    const [yearActive, setYearActive] = useState(false)

    const monthBtn = () => {
        setYear(false);
        setMonthActive(true);
        setYearActive(false);
    }
    const yearBtn = () => {
        setYear(true);
        setMonthActive(false);
        setYearActive(true);
    }


    return (
        <div className='heWhoRemains'>
            <Helmet>
                <title>Pricing | Free Appointment scheduling app- Schedulai</title>
                <meta name="description" content="Use Scheduleai for free with all basic features, upgrade it to acquire all features. Pricing of online appointment bookings starts from $5 per user/month." />
            </Helmet>
            <Header />
            <div>

                <div className="container-fluid px-5 pricingWrapper">
                    <h2 className='textPricing text-center pt-4'>Plans & Pricing</h2>
                    <div className="price-toggler">
                        <span className={monthActive ? "month active" : "month"} onClick={monthBtn}>monthly</span>
                        <span className={yearActive ? "year active" : "year"} onClick={yearBtn}>yearly</span>
                    </div>

                    {/* <div className="row text-center h-100 d-none">
                        <div className="col-12 col-lg-6 h-100">
                            <div className="box my-2 pb-2 rounded">
                                <h3 className='titleDev'>Free</h3>
                                <div className="price"><span>$0/user/mo</span></div>
                                <div className="list">
                                    <div className="container-fluid w-100">
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Connect to Google, Office 365, Outlook calendars
                                            </div>
                                            <div className="col-4 text-center">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Access Schedule AI with Web
                                            </div>
                                            <div className="col-4 text-center">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Unlimited Active event types
                                            </div>
                                            <div className="col-4 text-center">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Create unlimited one-on-one, group and round robin events
                                            </div>
                                            <div className="col-4 text-center">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Customize your booking link
                                            </div>
                                            <div className="col-4 text-center">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Automated event notifications
                                            </div>
                                            <div className="col-4 text-center">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Send your invitee email reminders and follow-ups
                                            </div>
                                            <div className="col-4 text-center">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Get meeting notifications via text
                                            </div>
                                            <div className="col-4 text-center text-danger">
                                                <i className="fa-solid fa-circle-xmark "></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Update your cancellation policy
                                            </div>
                                            <div className="col-4 text-center">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                View metrics and reporting
                                            </div>
                                            <div className="col-4 text-center">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Remove Schedule AI branding
                                            </div>
                                            <div className="col-4 text-center text-danger">
                                                <i className="fa-solid fa-circle-xmark "></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Add branding to your booking page
                                            </div>
                                            <div className="col-4 text-center text-danger">
                                                <i className="fa-solid fa-circle-xmark "></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Salesforce Integraion
                                            </div>
                                            <div className="col-4 text-center text-danger">
                                                <i className="fa-solid fa-circle-xmark "></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a href="/https://calendary.io/login" className="btn">get start</a>
                            </div>
                        </div>


                        <div className="col-12 col-lg-6 h-100">
                            <div className="box my-2 pb-2 rounded">
                                <h3 className='titleDev'>Enterprise</h3>
                                <div className="price">{year ? <span>$4/user/mo</span> : <span>$8/user/mo</span>}</div>
                                <div className="list">
                                    <div className="container-fluid w-100">
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Connect to Google, Office 365, Outlook calendars
                                            </div>
                                            <div className="col-4 text-center">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Access Schedule AI with Web
                                            </div>
                                            <div className="col-4 text-center">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Unlimited Active event types
                                            </div>
                                            <div className="col-4 text-center">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Create unlimited one-on-one, group and round robin events
                                            </div>
                                            <div className="col-4 text-center">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Customize your booking link
                                            </div>
                                            <div className="col-4 text-center">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Automated event notifications
                                            </div>
                                            <div className="col-4 text-center">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Send your invitee email reminders and follow-ups
                                            </div>
                                            <div className="col-4 text-center">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Get meeting notifications via text
                                            </div>
                                            <div className="col-4 text-center">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Update your cancellation policy
                                            </div>
                                            <div className="col-4 text-center">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                View metrics and reporting
                                            </div>
                                            <div className="col-4 text-center">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Remove Schedule AI branding
                                            </div>
                                            <div className="col-4 text-center">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Add branding to your booking page
                                            </div>
                                            <div className="col-4 text-center">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                        <div className="row table border-bottom p-1">
                                            <div className="col-8 text-start">
                                                Salesforce Integraion
                                            </div>
                                            <div className="col-4 text-center">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a href="/login" className="btn">get start</a>
                            </div>
                        </div>
                    </div> */}

                    <div className="row text-center h-100">
                        <div className="col-12 col-md-6 col-lg-3 mb-4">
                            <div className="box d-flex flex-column rounded">
                                <h3 className='titlePrice'>Basic</h3>
                                <div className="list">
                                    <p className="paraPrice m-0 pb-0">At the point when <br />you
                                        simply need the
                                        rudiments</p>
                                </div>
                                <div className="price">{year ? <span className='d-flex gap-1 align-items-center'><span className='PriceFdev'>Always Free</span>
                                </span> : <span className='d-flex gap-1 align-items-center'><span className='PriceFdev'>Always Free</span></span>}</div>
                                <div>
                                    <ul>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Connect one calendar</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Create one active event type, and schedule unlimited meetings</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Customize your booking link</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Send automated event notifications</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Add Scheduleai to your site</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Connect to Google Meet, Zoom, and others</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Get QR code </span>
                                        </li>


                                    </ul>
                                </div>
                                <Link to="/login">
                                    <button className="btn">get start</button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mb-4">
                            <div className="box d-flex flex-column rounded">
                                <h3 className='titlePrice'>Silver</h3>
                                <div className="list">
                                    <p className="paraPrice m-0 pb-0">At the point when you really want strong booking robotization</p>
                                </div>
                                <div className="price">{year ? <span className='d-flex gap-1 align-items-center'><span className='DollarPrice'>$3</span><span className='innerPrice'>/user/mo</span></span> : <span className='d-flex gap-1 align-items-center'><span className='DollarPrice'>$5</span><span className='innerPrice'>/user/mo</span></span>}</div>
                                {/* <div className="list flex-fill">
                                    <p className="leadDev m-0 pb-0">At the point when you really want strong booking robotization</p>
                                </div> */}
                                <div>
                                    <ul>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Connect two calendar</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Create unlimited event types</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Create group events</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Send your invitees email reminders and follow-ups</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Automated event notifications</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Redirect your invitees</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Connect with live chat support</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Update your cancellation policy</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Connect to Google Meet, Zoom, and others</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Get QR code</span>
                                        </li>
                                    </ul>
                                </div>
                                <Link to="/login">
                                    <button className="btn">get start</button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mb-4">
                            <div className="box d-flex flex-column rounded h-100">
                                <h3 className='titlePrice'>Gold</h3>
                                <div className="list">
                                    <p className="paraPrice m-0 pb-0">Customization, Integrations, and basic team features</p>
                                </div>
                                <div className="price">{year ? <span className='d-flex gap-1 align-items-center'><span className='DollarPrice'>$4</span><span className='innerPrice'>/user/mo</span></span> : <span className='d-flex gap-1 align-items-center'><span className='DollarPrice'>$7</span><span className='innerPrice'>/user/mo</span></span>}</div>
                                <div>
                                    <ul>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Connect four calendar</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Create unlimited event types</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Create group events</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Send your invitees email reminders and follow-ups</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Automated event notifications</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Redirect your invitees</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Connect with live chat support</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Connect to Google Meet, Zoom, and others</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Customize email notifications and reminders</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Get meeting notifications via text</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Create automated workflows</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Get QR code</span>
                                        </li>
                                    </ul>
                                </div>
                                <Link to="/login">
                                    <button className="btn">get start</button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mb-4">
                            <div className="box d-flex flex-column rounded h-100">
                                <h3 className='titlePrice'>Business</h3>
                                <div className="list">
                                    <p className="paraPrice m-0 pb-0">Group necessities to adjust on a free implementations</p>
                                </div>
                                <div className="price">{year ? <span className='d-flex gap-1 align-items-center'><span className='DollarPrice'>$6</span><span className='innerPrice'>/user/mo</span></span> : <span className='d-flex gap-1 align-items-center'><span className='DollarPrice'>$9</span><span className='innerPrice'>/user/mo</span></span>}</div>
                                <div>
                                    <ul>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Connect six calendar</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Create unlimited event types</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Create group events</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Send your invitees email reminders and follow-ups</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Automated event notifications</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Redirect your invitees</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Connect with live chat support</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Connect to Google Meet, Zoom, and others</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Customize email notifications and reminders</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Get meeting notifications via text</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Create automated workflows</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-check priceIcon"></i>
                                            <span>Get QR code</span>
                                        </li>
                                    </ul>
                                </div>
                                <Link to="/login">
                                    <button className="btn">get start</button>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>

                <section className="container-fluid d-none d-lg-block d-md-block" id="smd-product-header">
                    <div className="container-fluid smd-features-plan bg-light mt-5 ">
                        <div className="row">
                            <div className="col-md-4 col-lg-4 col-sm-8 py-2">
                                <h6 className="fw-bold">Features</h6>
                                <h4 className="fw-bold text-primary">Features by plan</h4>
                                <p>Track down the membership that seems OK for you or your group</p>
                            </div>
                            <div className="col-12 col-lg-2 col-md-2 py-2">
                                <div className="catg_Dev border h-100 d-flex flex-column">
                                    {/* <p className="text-center fw-bold flex-fill" style={{ fontSize: "15px" }}>Basic</p> */}
                                    <div className='d-flex align-items-center justify-content-between flex-column flex-fill'>
                                        <h5 className="text-center fw-bold text-primary">Basic</h5>
                                        <p className="text-center"><span style={{ fontSize: "2.5vw" }}>Free</span></p>
                                    </div>
                                    <div className="text-center">
                                        <Link to="/login">
                                            <button className="btn btn-sm btn-primary rounded-pill  w-100">Sign Up</button>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                            <div className="col-12 col-lg-2 col-md-2 py-2">
                                <div className="catg_Dev border h-100 d-flex flex-column">
                                    {/* <p className="text-center fw-bold flex-fill" style={{ fontSize: "15px" }}>Strong Booking</p> */}
                                    <div className='d-flex align-items-center justify-content-between flex-column flex-fill'>
                                        <h5 className="text-center fw-bold text-primary">Silver</h5>
                                        <p className="text-center fw-bold" style={{ fontSize: "1.2rem" }}>
                                            {year ?
                                                <span className='d-flex justify-content-center gap-1 align-items-center'>
                                                    <span style={{ fontSize: "2.5vw" }}>$3</span>
                                                    <span style={{ fontSize: "1.25vw" }}>/user/mo</span>
                                                </span>
                                                :
                                                <span className='d-flex justify-content-center gap-1 align-items-center'>
                                                    <span style={{ fontSize: "2.5vw" }}>$5</span>
                                                    <span style={{ fontSize: "1.25vw" }}>/user/mo</span>
                                                </span>
                                            }
                                        </p>
                                    </div>
                                    <div className="text-center">
                                        <Link to="/login">
                                            <button className="btn btn-sm btn-primary rounded-pill  w-100">Sign Up</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-2 col-md-2 py-2">
                                <div className="catg_Dev border h-100 d-flex flex-column">
                                    {/* <p className="text-center fw-bold flex-fill" style={{ fontSize: "15px" }}>Customization</p> */}
                                    <div className='d-flex align-items-center justify-content-between flex-column flex-fill'>
                                        <h5 className="text-center fw-bold text-primary">Gold</h5>
                                        <p className="text-center fw-bold" style={{ fontSize: "1.2rem" }}>{year ?
                                            <span className='d-flex justify-content-center gap-1 align-items-center'><span style={{ fontSize: "2.5vw" }}>$4</span><span style={{ fontSize: "1.25vw" }}>/user/mo</span></span> : <span className='d-flex justify-content-center gap-1 align-items-center'><span style={{ fontSize: "2.5vw" }}>$7</span><span style={{ fontSize: "1.25vw" }}>/user/mo</span></span>}</p>
                                    </div>
                                    <div className="text-center">
                                        <Link to="/login">
                                            <button className="btn btn-sm btn-primary rounded-pill  w-100">Sign Up</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-2 col-md-2 py-2">
                                <div className="catg_Dev border h-100 d-flex flex-column">
                                    {/* <p className="text-center fw-bold flex-fill" style={{ fontSize: "15px" }}>Free implementations </p> */}
                                    <div className='d-flex align-items-center justify-content-between flex-column flex-fill'>
                                        <h5 className="text-center fw-bold text-primary">Business</h5>
                                        <p className="text-center fw-bold" style={{ fontSize: "2.5vw" }}>{year ? <span className='d-flex justify-content-center gap-1 align-items-center'><span style={{ fontSize: "2.5vw" }}>$6</span><span style={{ fontSize: "1.25vw" }}>/user/mo</span></span> : <span className='d-flex justify-content-center gap-1 align-items-center'><span style={{ fontSize: "2.5vw" }}>$9</span><span style={{ fontSize: "1.25vw" }}>/user/mo</span></span>}</p>
                                    </div>
                                    <div className="text-center">
                                        <Link to="/login">
                                            <button className="btn btn-sm btn-primary rounded-pill  w-100">Sign Up</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-md-4 col-lg-4 col-sm-4">
                                <div className="catg_Dev my-2 border">
                                    <h5 className="text-center fw-bold text-primary">Free for users</h5>
                                    <div className="text-center">
                                        <a href="/login"><button className="btn btn-primary">Let's Start</button></a>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>


                    <Accordion defaultActiveKey="0" className='d-block'>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <h5 className='fw-bold'>Core Features</h5>
                            </Accordion.Header>
                            <Accordion.Body className='p-0'>
                                <div className="container-fluid w-100">
                                    <div className="row border-bottom py-1">
                                        <div className="col-12  col-lg-4 col-md-4">
                                            Calendar connections per user
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center fw-bold" style={{ fontSize: "17px" }}>
                                            1
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center fw-bold" style={{ fontSize: "17px" }}>
                                            2
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center fw-bold" style={{ fontSize: "17px" }}>
                                            4
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center fw-bold" style={{ fontSize: "17px" }}>
                                            6
                                        </div>
                                    </div>
                                    <div className="row border-bottom py-1">
                                        <div className="col-12  col-lg-4 col-md-4">
                                            Connect to Google, Office 365, Outlook, and iCloud calendars
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                    </div>
                                    <div className="row border-bottom py-1">
                                        <div className="col-12 col-lg-4 col-md-4">
                                            Access Schedule AI with Web
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2  text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                    </div>
                                    <div className="row border-bottom py-1">
                                        <div className="col-12 col-lg-4 col-md-4">
                                            Unlimited Active event types
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                    </div>
                                    <div className="row border-bottom py-1">
                                        <div className="col-12 col-lg-4 col-md-4">
                                            Create unlimited one-on-one events
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2  text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                    </div>
                                    <div className="row border-bottom py-1">
                                        <div className="col-12  col-lg-4 col-md-4">
                                            Create unlimited one-off meetings
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                    </div>
                                    <div className="row border-bottom py-1">
                                        <div className="col-12  col-lg-4 col-md-4">
                                            Customize your booking link
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                    </div>
                                    <div className="row border-bottom py-1">
                                        <div className="col-12  col-lg-4 col-md-4">
                                            Create group events
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                    </div>
                                    <div className="row border-bottom py-1">
                                        <div className="col-12  col-lg-4 col-md-4">
                                            Automated event notifications
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                    </div>
                                    <div className="row border-bottom py-1">
                                        <div className="col-12  col-lg-4 col-md-4">
                                            Send your invitee email reminders and follow-ups
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2  text-center">
                                            <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2  text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2  text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                    </div>
                                    <div className="row border-bottom py-1">
                                        <div className="col-12  col-lg-4 col-md-4">
                                            Customize email notifications and reminders
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2  text-center">
                                            <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2  text-center">
                                            <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2  text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                    </div>
                                    <div className="row border-bottom py-1">
                                        <div className="col-12  col-lg-4 col-md-4">
                                            Get meeting notifications via text
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2  text-center">
                                            <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                    </div>
                                    <div className="row border-bottom py-1">
                                        <div className="col-12  col-lg-4 col-md-4">
                                            Update your cancellation policy
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                    </div>
                                    <div className="row border-bottom py-1">
                                        <div className="col-12  col-lg-4 col-md-4">
                                            Create automated workflows
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid text-danger fa-circle-xmark Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid text-danger fa-circle-xmark Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                    </div>
                                    <div className="row border-bottom py-1">
                                        <div className="col-12  col-lg-4 col-md-4">
                                            Add links to your confirmation pages
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                    </div>
                                    <div className="row border-bottom py-1">
                                        <div className="col-12  col-lg-4 col-md-4">
                                            Redirect your invitees
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                    </div>
                                    <div className="row border-bottom py-1">
                                        <div className="col-12  col-lg-4 col-md-4">
                                            View metrics and reporting
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                    </div>
                                    <div className="row border-bottom py-1">
                                        <div className="col-12  col-lg-4 col-md-4">
                                            Remove Schedule AI branding
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                    </div>
                                    <div className="row border-bottom py-1">
                                        <div className="col-12  col-lg-4 col-md-4">
                                            Add branding to your booking page
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                    </div>
                                    <div className="row border-bottom py-1">
                                        <div className="col-12  col-lg-4 col-md-4">
                                            Customize colors when you add Schedule AI to your website
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                        <div className="col-12 col-lg-2 col-md-2 text-center">
                                            <i className="fa-solid fa-circle-check Dev_check"></i>
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </section>

                <section className="d-lg-none d-md-none d-sm-block">
                    <div className="container-fluid dev_FeatureHeader">
                        <div className="row">
                            <div className='col-sm-12 text-center bg-light p-3'>
                                <h6 className="fw-bold">Features</h6>
                                <h4 className="fw-bold text-primary">Features by plan</h4>
                                <p>Track down the membership that seems OK for you or your group</p>
                            </div>
                            <div className="col-sm-3 dev_Pricing py-3 d-flex align-items-center justify-content-center">
                                <p className="text-center m-0" style={{ fontSize: "1rem" }}><span className='fs-4'>Free</span></p>
                            </div>
                            <div className="col-sm-3 bg-primary dev_Pricing py-3 d-flex align-items-center justify-content-center">
                                <p className="text-center text-white m-0" style={{ fontSize: "1rem" }}><span className='fs-4'>$5</span></p>
                            </div>
                            <div className="col-sm-3 dev_Pricing py-3 d-flex align-items-center justify-content-center">
                                {/* <h5 className="text-center fw-bold text-primary fs-6">Gold</h5> */}
                                <p className="text-center m-0" style={{ fontSize: "1rem" }}><span className='fs-4'>$7</span></p>
                            </div>
                            <div className="col-sm-3 bg-primary dev_Pricing py-3 d-flex align-items-center justify-content-center">

                                <p className="text-center text-white m-0" style={{ fontSize: "1rem" }}><span className='fs-4'>$9</span></p>
                            </div>

                        </div>

                    </div>

                    <Accordion defaultActiveKey="0" className='d-block'>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <h5 className='fw-bold'>Core Features</h5>
                            </Accordion.Header>
                            <Accordion.Body className='p-0'>
                                <div className="container-fluid">
                                    <div className='row'>
                                        <div className="col-12  col-lg-4 col-md-4 text-center mx-auto fw-bold py-3 fs-6">
                                            Calendar connections per user
                                        </div>
                                        <div className='d-flex justify-content-space-between border-bottom pb-2'>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <span>1</span>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <span>2</span>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <span>4</span>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <span>6</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12  col-lg-4 col-md-4 text-center mx-auto fw-bold py-3 fs-6">
                                            Connect to Google, Office 365, Outlook, and iCloud calendars
                                        </div>
                                        <div className='d-flex justify-content-space-between border-bottom pb-2'>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12  col-lg-4 col-md-4 text-center mx-auto fw-bold py-3 fs-6">
                                            Access Schedule AI with Web
                                        </div>
                                        <div className='d-flex justify-content-space-between border-bottom pb-2'>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12  col-lg-4 col-md-4 text-center mx-auto fw-bold py-3 fs-6">
                                            Unlimited Active event types
                                        </div>
                                        <div className='d-flex justify-content-space-between border-bottom pb-2'>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12  col-lg-4 col-md-4 text-center mx-auto fw-bold py-3 fs-6">
                                            Create unlimited one-on-one events
                                        </div>
                                        <div className='d-flex justify-content-space-between border-bottom pb-2'>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12  col-lg-4 col-md-4 text-center mx-auto fw-bold py-3 fs-6">
                                            Create unlimited one-off meetings
                                        </div>
                                        <div className='d-flex justify-content-space-between border-bottom pb-2'>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12  col-lg-4 col-md-4 text-center mx-auto fw-bold py-3 fs-6">
                                            Customize your booking link
                                        </div>
                                        <div className='d-flex justify-content-space-between border-bottom pb-2'>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12  col-lg-4 col-md-4 text-center mx-auto fw-bold py-3 fs-6">
                                            Create group events
                                        </div>
                                        <div className='d-flex justify-content-space-between border-bottom pb-2'>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12  col-lg-4 col-md-4 text-center mx-auto fw-bold py-3 fs-6">
                                            Automated event notifications
                                        </div>
                                        <div className='d-flex justify-content-space-between border-bottom pb-2'>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12  col-lg-4 col-md-4 text-center mx-auto fw-bold py-3 fs-6">
                                            Send your invitee email reminders and follow-ups
                                        </div>
                                        <div className='d-flex justify-content-space-between border-bottom pb-2'>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12  col-lg-4 col-md-4 text-center mx-auto fw-bold py-3 fs-6">
                                            Customize email notifications and reminders
                                        </div>
                                        <div className='d-flex justify-content-space-between border-bottom pb-2'>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12  col-lg-4 col-md-4 text-center mx-auto fw-bold py-3 fs-6">
                                            Get meeting notifications via text
                                        </div>
                                        <div className='d-flex justify-content-space-between border-bottom pb-2'>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12  col-lg-4 col-md-4 text-center mx-auto fw-bold py-3 fs-6">
                                            Update your cancellation policy
                                        </div>
                                        <div className='d-flex justify-content-space-between border-bottom pb-2'>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12  col-lg-4 col-md-4 text-center mx-auto fw-bold py-3 fs-6">
                                            Create automated workflows
                                        </div>
                                        <div className='d-flex justify-content-space-between border-bottom pb-2'>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12  col-lg-4 col-md-4 text-center mx-auto fw-bold py-3 fs-6">
                                            Add links to your confirmation pages
                                        </div>
                                        <div className='d-flex justify-content-space-between border-bottom pb-2'>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12  col-lg-4 col-md-4 text-center mx-auto fw-bold py-3 fs-6">
                                            Redirect your invitees
                                        </div>
                                        <div className='d-flex justify-content-space-between border-bottom pb-2'>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12  col-lg-4 col-md-4 text-center mx-auto fw-bold py-3 fs-6">
                                            View metrics and reporting
                                        </div>
                                        <div className='d-flex justify-content-space-between border-bottom pb-2'>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12  col-lg-4 col-md-4 text-center mx-auto fw-bold py-3 fs-6">
                                            Remove Schedule AI branding
                                        </div>
                                        <div className='d-flex justify-content-space-between border-bottom pb-2'>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12  col-lg-4 col-md-4 text-center mx-auto fw-bold py-3 fs-6">
                                            Add branding to your booking page
                                        </div>
                                        <div className='d-flex justify-content-space-between border-bottom pb-2'>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-xmark Dev_cross"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12  col-lg-4 col-md-4 text-center mx-auto fw-bold py-3 fs-6">
                                            Customize colors when you add Schedule AI to your website
                                        </div>
                                        <div className='d-flex justify-content-space-between border-bottom pb-2'>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                            <div className="col-sm-3 Price_DevNum fw-bold fs-6">
                                                <i className="fa-solid fa-circle-check Dev_check"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                </section>

                <div className="container-fluid">
                    <div className="container pt-5 pb-5">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-4">
                                <h2 className="fw-bold" style={{ fontSize: "3.2em" }}>Frequently Asked<br /> Questions</h2>
                            </div>
                            <div className="col-12 col-md-6 col-lg-8">
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>What occurs toward the finish of my preliminary?</Accordion.Header>
                                        <Accordion.Body>
                                            Toward the finish of your 14-day preliminary, you'll naturally be downsized to the Complementary plan and you'll have the option to keep on involving Schedule AI insofar as you'd like. In the event that you choose to move up to a paid arrangement, you can do as such from the charging page in your record whenever during or after your preliminary.
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Which plan is best for myself as well as my group?</Accordion.Header>
                                        <Accordion.Body>
                                            Look at this video to analyze our different paid designs and select the one that best meets your requirements. You can involve the sections in the play bar to get out ahead to explicit plans.
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Could we at any point attempt Schedule AI with different clients?</Accordion.Header>
                                        <Accordion.Body>
                                            Totally! You can welcome colleagues to join your preliminary
                                            record at no expense. Toward the finish of your 14-day preliminary, all individuals
                                            will be naturally minimized to the Free arrangement.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>What does the restoration cycle resemble?</Accordion.Header>
                                        <Accordion.Body>
                                            Paid memberships naturally reestablish for a similar
                                            membership period except if you downsize your arrangement before your recharging
                                            date.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>How would I overhaul or downsize?</Accordion.Header>
                                        <Accordion.Body>
                                            Visit your charging page by going to My Record Charging.
                                            From that point, you can change your arrangement, add or eliminate client seats, and
                                            update your installment data
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid smd_simple pt-5 pb-4">
                    <div className="container">
                        <h2 className="display-4 fw-bold text-center text-white">Simple access for simple appointments</h2>
                        <p className="text-white text-center pt-2">Share your Schedule AI connect right from your program, so you can plan
                            <br />gatherings without the this way and that
                        </p>
                        <div className="cta_btn_smd text-center p-3">
                            <Link to="/login"><button type="button" className="btn btn-primary btn-lg my-2">Start for
                                free</button></Link>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollTopButton/>
            <Footer />
        </div>
    )
}

export default Pricing
