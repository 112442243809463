import React, { useEffect, useRef, useState } from 'react';
import "./AccountSetup.css";
function AccountSetup() {
    const welcomeText1 = useRef();
    const welcomeText2 = useRef();
    const welcomeText3 = useRef();
    const welcomeText4 = useRef();
    const welcomeText5 = useRef();
    const welcomeText6 = useRef();
    const progressBars = useRef();
    const [stepOnWay, setStepOnWay] = useState(1);
    useEffect(() => {
        setTimeout(() => {
            welcomeText1.current.style.opacity = 1;
        }, 1000)
    }, [])

    const setUpToStep1 = () => {
        welcomeText1.current.style.opacity = 0;
        welcomeText2.current.style.display = "block";
        setTimeout(() => {
            welcomeText1.current.style.display = "none";
            welcomeText2.current.style.opacity = 1;
            progressBars.current.style.opacity = 1;
            setStepOnWay(1);
        }, 1000)
    }

    const setUpToStep2 = () => {
        welcomeText2.current.style.opacity = 0;
        setTimeout(() => {
            welcomeText2.current.style.display = "none";
            welcomeText3.current.style.display = "block";
            welcomeText3.current.style.opacity = 1;
            setStepOnWay(2);
        }, 1000);
    }
    const setUpToStep3 = () => {
        welcomeText3.current.style.opacity = 0;
        setTimeout(() => {
            welcomeText3.current.style.display = "none";
            welcomeText4.current.style.display = "block";
            welcomeText4.current.style.opacity = 1;
            setStepOnWay(3);
        }, 1000);
    }
    const setUpToStep4 = () => {
        welcomeText4.current.style.opacity = 0;
        setTimeout(() => {
            welcomeText4.current.style.display = "none";
            welcomeText5.current.style.display = "block";
            welcomeText5.current.style.opacity = 1;
            setStepOnWay(4);
        }, 1000);
    }
    const setUpToStep5 = () => {
        welcomeText5.current.style.opacity = 0;
        setTimeout(() => {
            welcomeText5.current.style.display = "none";
            welcomeText6.current.style.display = "block";
            welcomeText6.current.style.opacity = 1;
            setStepOnWay(5);
        }, 1000);
    }


    const backtoStep1 = () => {
        welcomeText3.current.style.opacity = 0;
        setTimeout(() => {
            welcomeText3.current.style.display = "none";
            welcomeText2.current.style.display = "block";
            welcomeText2.current.style.opacity = 1;
            setStepOnWay(1);
        }, 1000);
    }

    const backtoStep2 = () => {
        welcomeText4.current.style.opacity = 0;
        setTimeout(() => {
            welcomeText4.current.style.display = "none";
            welcomeText3.current.style.display = "block";
            welcomeText3.current.style.opacity = 1;
            setStepOnWay(2);
        }, 1000);
    }
    const backtoStep3 = () => {
        welcomeText5.current.style.opacity = 0;
        setTimeout(() => {
            welcomeText5.current.style.display = "none";
            welcomeText4.current.style.display = "block";
            welcomeText4.current.style.opacity = 1;
            setStepOnWay(3);
        }, 1000);
    }
    const backtoStep4 = () => {
        welcomeText6.current.style.opacity = 0;
        setTimeout(() => {
            welcomeText6.current.style.display = "none";
            welcomeText5.current.style.display = "block";
            welcomeText5.current.style.opacity = 1;
            setStepOnWay(4);
        }, 1000);
    }
    const finalStep = () => {
        alert("all done");
    }
    return (
        <div className='p-0 p-lg-5 p-md-3 p-sm-2 vh-100'>
            <div className='insightBackground rounded border p-4 h-100'>
                <div ref={progressBars} className='animateOn d-flex justify-content-between align-items-center w-100'>
                    <div className={`rounded-circle ${stepOnWay > 0 ? "currentStepBorder" : "defaultStepBorder"} d-flex align-items-center justify-content-center`} style={{ height: "50px", width: "50px" }}>1</div>
                    <div className='flex-grow-1'><hr className='w-100' style={{ border: "2px solid #0b5cab" }} /></div>
                    <div className={`rounded-circle ${stepOnWay > 1 ? "currentStepBorder" : "defaultStepBorder"} d-flex align-items-center justify-content-center`} style={{ height: "50px", width: "50px" }}>2</div>
                    <div className='flex-grow-1'><hr className='w-100' style={{ border: "2px solid #0b5cab" }} /></div>
                    <div className={`rounded-circle ${stepOnWay > 2 ? "currentStepBorder" : "defaultStepBorder"} d-flex align-items-center justify-content-center`} style={{ height: "50px", width: "50px" }}>3</div>
                    <div className='flex-grow-1'><hr className='w-100' style={{ border: "2px solid #0b5cab" }} /></div>
                    <div className={`rounded-circle ${stepOnWay > 3 ? "currentStepBorder" : "defaultStepBorder"} d-flex align-items-center justify-content-center`} style={{ height: "50px", width: "50px" }}>4</div>
                    <div className='flex-grow-1'><hr className='w-100' style={{ border: "2px solid #0b5cab" }} /></div>
                    <div className={`rounded-circle ${stepOnWay > 4 ? "currentStepBorder" : "defaultStepBorder"} d-flex align-items-center justify-content-center`} style={{ height: "50px", width: "50px" }}>5</div>
                </div>
                <div ref={welcomeText1} className={`animateOn`}>
                    <p className='display-6 color-salesforce text-center h-100 d-flex align-items-center justify-content-center'>
                        Welcome
                    </p>
                    <div className='d-flex align-items-center justify-content-center gap-4 w-100'>
                        <button onClick={() => { setUpToStep1(); }} className="salesforce-style rounded-pill px-3 border-0">let's start</button>
                    </div>
                </div>
                <div ref={welcomeText2} className={`animateOn`}>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <p className='display-6 p-0 m-0 color-salesforce text-center d-flex align-items-center justify-content-center'>
                                            Step 1
                                        </p>
                                    </div>
                                    <div className="card-body">
                                        {/* <label className='w-100' htmlFor='name'>
                                            <span style={{ fontWeight: "500" }} className="d-flex aling-items-center gap-3">
                                                Name *

                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={{ show: 100, hide: 200 }}
                                                    overlay={
                                                        <Popover id="popover-basic">
                                                            <Popover.Header className='salesforce-style' as="h3">Your Name On Booking Pages</Popover.Header>
                                                            <Popover.Body>
                                                                <p className='p-0 m-0 helpTextSize'>
                                                                    Use this field to change the name present on your booking page.
                                                                </p>
                                                                <img src={nameHelpText} className='img img-responsive w-100' alt='helpText' />
                                                            </Popover.Body>
                                                        </Popover>
                                                    }>
                                                    <HelpOutlineIcon style={{ maxWidth: "15px", cursor: "help" }} />
                                                </OverlayTrigger>
                                            </span>
                                            <input required id="name" maxLength={30} type="text" value={name} onChange={validateAndSetName} className='customInputFieldFocus w-100 border' />
                                            <div className='d-flex align-items-center justify-content-between w-100'>
                                                <p className='text-dark text-start m-0 smallTextSize'>Max length 30 chars.</p>
                                                {
                                                    name ?
                                                        <span className='smallTextSize'>{30 - name.length} / 30</span>
                                                        :
                                                        <span className='smallTextSize'>30 / 30</span>
                                                }
                                            </div>
                                        </label> */}
                                    </div>
                                    <div className="card-footer">
                                        <div className='d-flex align-items-center justify-content-center gap-4 w-100'>
                                            <button onClick={() => { setUpToStep2(); }} className="salesforce-style rounded-pill px-3 border-0">next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={welcomeText3} className={`animateOn`}>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <p className='display-6 color-salesforce text-center h-100 d-flex align-items-center justify-content-center'>
                                            Step 2
                                        </p>
                                    </div>
                                    <div className="card-body">
                                        {/* <form onSubmit={updateLink}>
                                            <div className="container-fluid p-0">
                                                <div className="row mt-3">
                                                    <div className="col d-flex align-items-center gap-3">
                                                        <span>Changing your Schedule AI URL will mean that all of your copied links will no longer work and will need to be updated.</span>

                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            delay={{ show: 100, hide: 200 }}
                                                            overlay={
                                                                <Popover id="popover-basic">
                                                                    <Popover.Header className='salesforce-style' as="h3">Customise Your Booking Link</Popover.Header>
                                                                    <Popover.Body>
                                                                        <p className='p-0 m-0 helpTextSize'>
                                                                            Use this field to change your custom links to share with your invitees.
                                                                        </p>
                                                                        <img src={myLinkHelpText} className='img img-responsive w-100' alt='helpText' />
                                                                    </Popover.Body>
                                                                </Popover>
                                                            }>
                                                            <HelpOutlineIcon style={{ maxWidth: "15px", cursor: "help" }} />
                                                        </OverlayTrigger>

                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-12">
                                                        <div className="w-100 d-flex align-items-start justify-content-start flex-column gap-2 border rounded bg-body p-3">
                                                            <div className="div">
                                                                <div className="input-group w-100 flex-nowrap">
                                                                    <span className="input-group-text" id="basic-addon3" style={{ marginLeft: "0" }}>{process.env.REACT_APP_DOMAINNAME}/</span>
                                                                    <input type="text" id="UID" maxLength={20} required className='customInputFieldFocus w-100' placeholder='UID' defaultValue={user.username} onChange={isUsernameAvailable} />
                                                                    <span className="input-group-text" style={{ marginLeft: "0", borderLeft: "none" }}>
                                                                        {
                                                                            usernameAvailable ?
                                                                                <DoneIcon color="success" />
                                                                                :
                                                                                <CloseIcon color="error" />
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className='d-flex align-items-center justify-content-between w-100'>
                                                                    <p className='text-dark m-0 text-start smallTextSize'>Max length 20 chars.</p>
                                                                    <span className='smallTextSize'>{20 - username.length} / 20</span>
                                                                </div>
                                                            </div>
                                                            {
                                                                usernameFaults ?
                                                                    <div className="alert alert-danger mediumTextSize m-0 w-100 p-1 text-center">
                                                                        {usernameFaults}
                                                                    </div>
                                                                    :
                                                                    ""
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='col d-flex align-items-center justify-content-start gap-2'>

                                                    </div>
                                                </div>
                                                <div className="row my-3">
                                                    <div className="col d-flex align-items-center gap-3">
                                                        <button type="submit" disabled={!usernameAvailable} className='btn salesforce-style px-3 py-2 rounded-pill salesforce-style'>Save Changes</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form> */}
                                    </div>
                                    <div className="card-footer">
                                        <div className='d-flex align-items-center justify-content-center gap-4 w-100'>
                                            <button onClick={() => { backtoStep1(); }} className="salesforce-style rounded-pill px-3 border-0">previous</button>
                                            <button onClick={() => { setUpToStep3(); }} className="salesforce-style rounded-pill px-3 border-0">next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={welcomeText4} className={`animateOn`}>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <p className='display-6 color-salesforce text-center h-100 d-flex align-items-center justify-content-center'>
                                            Step 3
                                        </p>
                                    </div>
                                    <div className="card-body">
                                        {/* <form className='d-flex w-100 flex-column gap-3 px-3' onSubmit={(e) => { saveNewPass(e); }}>
                                            <span className='color-salesforce w-100 m-0 '>Enter New Password for <span style={{ fontWeight: "600" }}>{userEmailOTP}</span></span>
                                            <input type="password" ref={newPasswordField} placeholder='New password' className='customInputFieldFocus w-100' style={{ fontSize: ".9rem" }} value={newPass} required onInput={(e) => { setNewPass(e.target.value); passwordValidation(e.target.value) }} />
                                            <input type="password" ref={newPasswordFieldVarified} placeholder='Confirm new password' className='customInputFieldFocus w-100' style={{ fontSize: ".9rem" }} value={newPassConf} required onInput={(e) => { setNewPassConf(e.target.value); passwordValidation(e.target.value) }} />
                                            <p className='color-salesforce'>
                                                <span className='cursor-pointer' onClick={(e) => {
                                                    if (newPasswordField.current.type == "text") {
                                                        newPasswordField.current.type = "password";
                                                        newPasswordFieldVarified.current.type = "password";
                                                        e.target.innerHTML = "Show";

                                                    } else {
                                                        newPasswordField.current.type = "text";
                                                        newPasswordFieldVarified.current.type = "text";
                                                        e.target.innerHTML = "Hide";
                                                    }
                                                }}>Show</span>
                                            </p>

                                            {
                                                <ul className='p-0 m-0 alert bg-soul p-1'>
                                                    <li className={`mediumTextSize d-flex align-items-center gap-2 ${lowercaseAvailable ? "text-success" : "text-danger"}`}><i className={`text-center fa ${lowercaseAvailable ? "fa-check" : "fa-close"}`} style={{ width: "15px" }}></i>A lowercase</li>
                                                    <li className={`mediumTextSize d-flex align-items-center gap-2 ${uppercaseAvailable ? "text-success" : "text-danger"}`}><i className={`text-center fa ${uppercaseAvailable ? "fa-check" : "fa-close"}`} style={{ width: "15px" }}></i>A uppercase</li>
                                                    <li className={`mediumTextSize d-flex align-items-center gap-2 ${numbersAvailable ? "text-success" : "text-danger"}`}><i className={`text-center fa ${numbersAvailable ? "fa-check" : "fa-close"}`} style={{ width: "15px" }}></i>A number</li>
                                                    <li className={`mediumTextSize d-flex align-items-center gap-2 ${specialCharsAvailable ? "text-success" : "text-danger"}`}><i className={`text-center fa ${specialCharsAvailable ? "fa-check" : "fa-close"}`} style={{ width: "15px" }}></i>A special character</li>
                                                    <li className={`mediumTextSize d-flex align-items-center gap-2 ${minimumCharsAvailable ? "text-success" : "text-danger"}`}><i className={`text-center fa ${minimumCharsAvailable ? "fa-check" : "fa-close"}`} style={{ width: "15px" }}></i>Minimum 8 characters</li>
                                                    <li className={`mediumTextSize d-flex align-items-center gap-2 ${passwordMatch ? "text-success" : "text-danger"}`}><i className={`text-center fa ${minimumCharsAvailable ? "fa-check" : "fa-close"}`} style={{ width: "15px" }}></i>Password Match</li>
                                                </ul>
                                            }
                                            {
                                                mismatchPass === "mismatch" ?
                                                    <span className="text-danger my-2">Password Mismatch</span>
                                                    :
                                                    mismatchPass === "matched" ?
                                                        <span className='text-success my-2'>Password Matched</span>
                                                        :
                                                        ""
                                            }
                                            <div className='d-flex w-100 align-items-center justify-content-end gap-3'>
                                                <input type="button" className='btn btn-sm btn-secondary rounded-pill px-4' value={"Cancel"} onClick={() => { resetAllFields(); }} />
                                                <input type="submit"
                                                    className='btn btn-sm salesforce-style rounded-pill px-4'
                                                    value={"Verify"}
                                                    disabled={
                                                        lowercaseAvailable === false ||
                                                        uppercaseAvailable === false ||
                                                        numbersAvailable === false ||
                                                        specialCharsAvailable === false ||
                                                        minimumCharsAvailable === false ||
                                                        passwordMatch === false
                                                    } />
                                            </div>

                                        </form> */}
                                    </div>
                                    <div className="card-footer">
                                        <div className='d-flex align-items-center justify-content-center gap-4 w-100'>
                                            <button onClick={() => { backtoStep2(); }} className="salesforce-style rounded-pill px-3 border-0">previous</button>
                                            <button onClick={() => { setUpToStep4(); }} className="salesforce-style rounded-pill px-3 border-0">next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div ref={welcomeText5} className={`animateOn`}>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <p className='display-6 color-salesforce text-center h-100 d-flex align-items-center justify-content-center'>
                                            Step 4
                                        </p>
                                    </div>
                                    <div className="card-body">
                                        {/* <label className='w-100' htmlFor='timeFormat'>
                                            <span style={{ fontWeight: "500" }}>Time Format *</span>
                                            <Select
                                                options={[{ label: "12 Hours AM/PM", value: "12 Hours" }, { label: "24 Hours", value: "24 Hours" }]}
                                                defaultValue={timeFormat}
                                                onChange={setTimeFormat}
                                            />
                                        </label>

                                        <label className='w-100'>
                                            <span style={{ fontWeight: "500" }}>Country *</span>
                                            <Select required className='w-100' options={options} value={country} onChange={changeHandler} />
                                        </label>

                                        <label className='w-100'>
                                            <span style={{ fontWeight: "500" }}>Time Zone *</span>
                                            <TimezoneSelect
                                                required
                                                className='w-100 p-0'
                                                value={selectedTimezoneSettings}
                                                onChange={setSelectedTimezoneSettings}
                                            // timezones={{
                                            //   ...allTimezones,
                                            //   'America/Lima': 'Pittsburgh',
                                            //   'Europe/Berlin': 'Frankfurt',
                                            // }}
                                            />
                                        </label> */}
                                    </div>
                                    <div className="card-footer">
                                        <div className='d-flex align-items-center justify-content-center gap-4 w-100'>
                                            <button onClick={() => { backtoStep3(); }} className="salesforce-style rounded-pill px-3 border-0">previous</button>
                                            <button onClick={() => { setUpToStep5(); }} className="salesforce-style rounded-pill px-3 border-0">next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={welcomeText6} className={`animateOn`}>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <p className='display-6 color-salesforce text-center h-100 d-flex align-items-center justify-content-center'>
                                            Step 5
                                        </p>
                                    </div>
                                    <div className="card-body">
                                        {/* <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-12 col-md-12 col-lg-7 border rounded shadow-sm insightBackground">
                                                    <p className='text-center pt-4' style={{ fontWeight: "600" }}>Set your weekly hours</p>
                                                    <div className="container-fluid p-0">
                                                        <div className="row mb-4">
                                                            <div className="col-3">
                                                                <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                    <label className='d-flex gap-1 align-items-center justify-content-between'>
                                                                        <div className="outerToggleSwitch" onClick={() => { setIsSundayActive(!isSundayActive); checkDayOnToggle(!isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, "Shrtcut"); }}>
                                                                            <div className={`frameOfToggleSwitch ${isSundayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                <span>On</span>
                                                                                <div className="toggleSwitchBall"></div>
                                                                                <span>Off</span>
                                                                            </div>
                                                                        </div>
                                                                        <span className='smallTextNote'>SUN</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-7">
                                                                {
                                                                    isSundayActive ?
                                                                        <div className='m-0 p-0' id="sundayFrameSignUp">
                                                                            {
                                                                                schedule.sunday.times.map((sadn, key) => {
                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                    const uid = uuidv4();
                                                                                    return (
                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"sundayTimeFrame_" + uid}>
                                                                                            <div>
                                                                                                <input list="sundayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={startTimeGiving} name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm sundayStartTabsSignUp`} />
                                                                                                <datalist id="sundayStart" className='smallTextNote'>
                                                                                                    {
                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                            return (
                                                                                                                <option key={key} value={times}>{times}</option>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </datalist>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input list="sundayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={endTimeGiving} name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm sundayEndTabsSignUp`} />
                                                                                                <datalist id="sundayEnd" className='smallTextNote'>
                                                                                                    {
                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                            return (
                                                                                                                <option key={key} value={times}>{times}</option>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </datalist>
                                                                                            </div>
                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("sunday", "SignUp", uid); }} />
                                                                                            </div>
                                                                                        </span>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                        :
                                                                        <span className='smallTextNote'>Unavailable</span>
                                                                }
                                                            </div>
                                                            <div className="col-2">
                                                                <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                    <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("sunday", "SignUp", uuidv4()); }} ></i>
                                                                    <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("sunday", "SignUp"); }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-3">
                                                                <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                    <label className='d-flex gap-1 align-items-center justify-content-between'>
                                                                        <div className="outerToggleSwitch" onClick={() => { setIsMondayActive(!isMondayActive); checkDayOnToggle(isSundayActive, !isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, "Shrtcut"); }}>
                                                                            <div className={`frameOfToggleSwitch ${isMondayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                <span>On</span>
                                                                                <div className="toggleSwitchBall"></div>
                                                                                <span>Off</span>
                                                                            </div>
                                                                        </div>
                                                                        <span className='smallTextNote'>MON</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-7">

                                                                {
                                                                    isMondayActive ?
                                                                        <div className='m-0 p-0' id="mondayFrameSignUp">
                                                                            {
                                                                                schedule.monday.times.map((sadn, key) => {
                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                    const uid = uuidv4();
                                                                                    return (
                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"mondayTimeFrame_" + uid}>
                                                                                            <div>
                                                                                                <input list="mondayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={startTimeGiving} name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm mondayStartTabsSignUp`} />
                                                                                                <datalist id="mondayStart" className='smallTextNote'>
                                                                                                    {
                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                            return (
                                                                                                                <option key={key} value={times}>{times}</option>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </datalist>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input list="mondayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={endTimeGiving} name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm mondayEndTabsSignUp`} />
                                                                                                <datalist id="mondayEnd" className='smallTextNote'>
                                                                                                    {
                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                            return (
                                                                                                                <option key={key} value={times}>{times}</option>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </datalist>
                                                                                            </div>
                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("monday", "SignUp", uid); }} />
                                                                                            </div>
                                                                                        </span>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <span className='smallTextNote'>Unavailable</span>
                                                                }

                                                            </div>
                                                            <div className="col-2">
                                                                <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                    <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("monday", "SignUp", uuidv4()); }} ></i>
                                                                    <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("monday", "SignUp"); }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-3">
                                                                <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                    <label className='d-flex gap-1 align-items-center justify-content-between'>
                                                                        <div className="outerToggleSwitch" onClick={() => { setIsTuesdayActive(!isTuesdayActive); checkDayOnToggle(isSundayActive, isMondayActive, !isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, "Shrtcut"); }}>
                                                                            <div className={`frameOfToggleSwitch ${isTuesdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                <span>On</span>
                                                                                <div className="toggleSwitchBall"></div>
                                                                                <span>Off</span>
                                                                            </div>
                                                                        </div>
                                                                        <span className='smallTextNote'>TUE</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-7">
                                                                {
                                                                    isTuesdayActive ?
                                                                        <div className='m-0 p-0' id="tuesdayFrameSignUp">
                                                                            {
                                                                                schedule.tuesday.times.map((sadn, key) => {
                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                    const uid = uuidv4();
                                                                                    return (
                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"tuesdayTimeFrame_" + uid}>
                                                                                            <div>
                                                                                                <input list="tuesdayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={startTimeGiving} name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm tuesdayStartTabsSignUp`} />
                                                                                                <datalist id="tuesdayStart" className='smallTextNote'>
                                                                                                    {
                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                            return (
                                                                                                                <option key={key} value={times}>{times}</option>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </datalist>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input list="tuesdayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={endTimeGiving} name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm tuesdayEndTabsSignUp`} />
                                                                                                <datalist id="tuesdayEnd" className='smallTextNote'>
                                                                                                    {
                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                            return (
                                                                                                                <option key={key} value={times}>{times}</option>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </datalist>
                                                                                            </div>
                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("tuesday", "SignUp", uid); }} />
                                                                                            </div>
                                                                                        </span>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <span className='smallTextNote'>Unavailable</span>
                                                                }

                                                            </div>
                                                            <div className="col-2">
                                                                <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                    <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("tuesday", "SignUp", uuidv4()); }} ></i>
                                                                    <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("tuesday", "SignUp"); }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-3">
                                                                <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                    <label className='d-flex gap-1 align-items-center justify-content-between'>
                                                                        <div className="outerToggleSwitch" onClick={() => { setIsWednesdayActive(!isWednesdayActive); checkDayOnToggle(isSundayActive, isMondayActive, isTuesdayActive, !isWednesdayActive, isThursdayActive, isFridayActive, isSaturdayActive, "Shrtcut"); }}>
                                                                            <div className={`frameOfToggleSwitch ${isWednesdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                <span>On</span>
                                                                                <div className="toggleSwitchBall"></div>
                                                                                <span>Off</span>
                                                                            </div>
                                                                        </div>
                                                                        <span className='smallTextNote'>WED</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-7">
                                                                {
                                                                    isWednesdayActive ?
                                                                        <div className='m-0 p-0' id="wednesdayFrameSignUp">
                                                                            {
                                                                                schedule.wednesday.times.map((sadn, key) => {
                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                    const uid = uuidv4();
                                                                                    return (
                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"wednesdayTimeFrame_" + uid}>
                                                                                            <div>
                                                                                                <input list="wednesdayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={startTimeGiving} name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm wednesdayStartTabsSignUp`} />
                                                                                                <datalist id="wednesdayStart" className='smallTextNote'>
                                                                                                    {
                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                            return (
                                                                                                                <option key={key} value={times}>{times}</option>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </datalist>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input list="wednesdayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={endTimeGiving} name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm wednesdayEndTabsSignUp`} />
                                                                                                <datalist id="wednesdayEnd" className='smallTextNote'>
                                                                                                    {
                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                            return (
                                                                                                                <option key={key} value={times}>{times}</option>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </datalist>
                                                                                            </div>
                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("wednesday", "SignUp", uid); }} />
                                                                                            </div>
                                                                                        </span>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <span className='smallTextNote'>Unavailable</span>
                                                                }

                                                            </div>
                                                            <div className="col-2">
                                                                <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                    <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("wednesday", "SignUp", uuidv4()); }} ></i>
                                                                    <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("wednesday", "SignUp"); }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-3">
                                                                <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                    <label className='d-flex gap-1 align-items-center justify-content-between'>
                                                                        <div className="outerToggleSwitch" onClick={() => { setIsThursdayActive(!isThursdayActive); checkDayOnToggle(isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, !isThursdayActive, isFridayActive, isSaturdayActive, "Shrtcut"); }}>
                                                                            <div className={`frameOfToggleSwitch ${isThursdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                <span>On</span>
                                                                                <div className="toggleSwitchBall"></div>
                                                                                <span>Off</span>
                                                                            </div>
                                                                        </div>
                                                                        <span className='smallTextNote'>THU</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-7">
                                                                {
                                                                    isThursdayActive ?
                                                                        <div className='m-0 p-0' id="thursdayFrameSignUp">
                                                                            {
                                                                                schedule.thursday.times.map((sadn, key) => {
                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                    const uid = uuidv4();
                                                                                    return (
                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"thursdayTimeFrame_" + uid}>
                                                                                            <div>
                                                                                                <input list="thursdayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={startTimeGiving} name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm thursdayStartTabsSignUp`} />
                                                                                                <datalist id="thursdayStart" className='smallTextNote'>
                                                                                                    {
                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                            return (
                                                                                                                <option key={key} value={times}>{times}</option>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </datalist>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input list="thursdayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={endTimeGiving} name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm thursdayEndTabsSignUp`} />
                                                                                                <datalist id="thursdayEnd" className='smallTextNote'>
                                                                                                    {
                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                            return (
                                                                                                                <option key={key} value={times}>{times}</option>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </datalist>
                                                                                            </div>
                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("thursday", "SignUp", uid); }} />
                                                                                            </div>
                                                                                        </span>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <span className='smallTextNote'>Unavailable</span>
                                                                }

                                                            </div>
                                                            <div className="col-2">
                                                                <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                    <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("thursday", "SignUp", uuidv4()); }} ></i>
                                                                    <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("thursday", "SignUp"); }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-3">
                                                                <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                    <label className='d-flex gap-1 align-items-center justify-content-between'>
                                                                        <div className="outerToggleSwitch" onClick={() => { setIsFridayActive(!isFridayActive); checkDayOnToggle(!isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, !isFridayActive, isSaturdayActive, "Shrtcut"); }}>
                                                                            <div className={`frameOfToggleSwitch ${isFridayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                <span>On</span>
                                                                                <div className="toggleSwitchBall"></div>
                                                                                <span>Off</span>
                                                                            </div>
                                                                        </div>
                                                                        <span className='smallTextNote'>FRI</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-7">
                                                                {
                                                                    isFridayActive ?
                                                                        <div className='m-0 p-0' id="fridayFrameSignUp">
                                                                            {
                                                                                schedule.friday.times.map((sadn, key) => {
                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                    const uid = uuidv4();
                                                                                    return (
                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"fridayTimeFrame_" + uid}>
                                                                                            <div>
                                                                                                <input list="fridayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={startTimeGiving} name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm fridayStartTabsSignUp`} />
                                                                                                <datalist id="fridayStart" className='smallTextNote'>
                                                                                                    {
                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                            return (
                                                                                                                <option key={key} value={times}>{times}</option>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </datalist>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input list="fridayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={endTimeGiving} name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm fridayEndTabsSignUp`} />
                                                                                                <datalist id="fridayEnd" className='smallTextNote'>
                                                                                                    {
                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                            return (
                                                                                                                <option key={key} value={times}>{times}</option>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </datalist>
                                                                                            </div>
                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("friday", "SignUp", uid); }} />
                                                                                            </div>
                                                                                        </span>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <span className='smallTextNote'>Unavailable</span>
                                                                }

                                                            </div>
                                                            <div className="col-2">
                                                                <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                    <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("friday", "SignUp", uuidv4()); }} ></i>
                                                                    <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("friday", "SignUp"); }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-3">
                                                                <div className='d-flex align-items-start gap-3 w-100 h-100'>
                                                                    <label className='d-flex gap-1 align-items-center justify-content-between'>
                                                                        <div className="outerToggleSwitch" onClick={() => { setIsSaturdayActive(!isSaturdayActive); checkDayOnToggle(isSundayActive, isMondayActive, isTuesdayActive, isWednesdayActive, isThursdayActive, isFridayActive, !isSaturdayActive, "Shrtcut"); }}>
                                                                            <div className={`frameOfToggleSwitch ${isSaturdayActive === false ? "slideLeftToogle" : "slideRightToogle"}`}>
                                                                                <span>On</span>
                                                                                <div className="toggleSwitchBall"></div>
                                                                                <span>Off</span>
                                                                            </div>
                                                                        </div>
                                                                        <span className='smallTextNote'>SAT</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-7">
                                                                {
                                                                    isSaturdayActive ?
                                                                        <div className='m-0 p-0' id="saturdayFrameSignUp">
                                                                            {
                                                                                schedule.saturday.times.map((sadn, key) => {
                                                                                    const startTimeGiving = sadn.split("-")[0];
                                                                                    const endTimeGiving = sadn.split("-")[1];
                                                                                    const uid = uuidv4();
                                                                                    return (
                                                                                        <span className='d-flex align-items-center gap-1 justify-content-start' key={key} id={"saturdayTimeFrame_" + uid}>
                                                                                            <div>
                                                                                                <input list="saturdayStart" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={startTimeGiving} name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm saturdayStartTabsSignUp`} />
                                                                                                <datalist id="saturdayStart" className='smallTextNote'>
                                                                                                    {
                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                            return (
                                                                                                                <option key={key} value={times}>{times}</option>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </datalist>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input list="saturdayEnd" maxLength={7} onInput={(e) => { filterTimeFieldNow(e); checkUpdatedStateOfTimeValidation("SignUp"); checkValidTiming(e, "SignUp"); }} defaultValue={endTimeGiving} name="activeOn" className={`customInputFieldFocus w-100 smallTextNote shadow-sm saturdayEndTabsSignUp`} />
                                                                                                <datalist id="saturdayEnd" className='smallTextNote'>
                                                                                                    {
                                                                                                        timeListToMeasure.map((times, key) => {
                                                                                                            return (
                                                                                                                <option key={key} value={times}>{times}</option>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </datalist>
                                                                                            </div>
                                                                                            <div className='d-flex align-items-center h-100'>
                                                                                                <i className="fa fa-trash" style={{ padding: "0 5px" }} onClick={() => { deleteTimeSet("saturday", "SignUp", uid); }} />
                                                                                            </div>
                                                                                        </span>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <span className='smallTextNote'>Unavailable</span>
                                                                }

                                                            </div>
                                                            <div className="col-2">
                                                                <div className='d-flex align-items-center justify-content-end gap-3 w-100'>
                                                                    <i className='fa fa-plus' onClick={(e) => { addTimeSetIn("saturday", "SignUp", uuidv4()); }} ></i>
                                                                    <ContentCopyIcon style={{ width: "1.2rem" }} onClick={(e) => { copyTimesTo("saturday", "SignUp"); }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-1 d-lg-flex py-2"></div>
                                                <div className="col-12 col-md-12 col-lg-4 border rounded shadow-sm insightBackground">
                                                    <div className="alert d-flex flex-column align-items-center">
                                                        <p className='text-center' style={{ fontWeight: "600" }}>Date overrides</p>
                                                        <button type='button' className='btn btn-sm btn-outline-primary rounded-pill salesforce-style px-3' style={{ maxWidth: "max-content" }} onClick={() => { setViewDateOverridePanel(true); }}>
                                                            <AddIcon /> Add date override
                                                        </button>
                                                    </div>
                                                    {
                                                        dateOverrides ?
                                                            Object.keys(dateOverrides).length > 0 ?
                                                                Object.keys(dateOverrides).map((data, key) => {
                                                                    return (
                                                                        <div key={key} className={`card mb-2 datesOverrides_${dateOverrides[data].uid}`}>
                                                                            <div className='card-header salesforce-style d-flex align-items-center justify-content-between'>
                                                                                <span>{dateOverrides[data].date}</span>
                                                                                <span>
                                                                                    <i className="fa fa-trash" style={{ padding: "0 5px" }} id={dateOverrides[data].uid} onClick={(e) => { deleteOverrideDate(dateOverrides[data].date, `datesOverrides_${dateOverrides[data].uid}`); }} />
                                                                                </span>
                                                                            </div>
                                                                            <div className='card-body d-flex justify-content-between align-items-center'>
                                                                                {
                                                                                    dateOverrides[data].active ?
                                                                                        <>
                                                                                            <div className='d-flex flex-column align-items-start justify-content-center'>
                                                                                                {
                                                                                                    dateOverrides[data].timeSet.map((time, key) => {
                                                                                                        return (
                                                                                                            <React.Fragment key={key}>
                                                                                                                <button disabled className='btn btn-secondary border-0 mb-1 w-100'>{time}</button>
                                                                                                            </React.Fragment>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <span>Unavailable</span>
                                                                                        </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                                :
                                                                <p className='text-center'>No overrides for now</p>
                                                            :
                                                            <p className='text-center'>No overrides for now</p>
                                                    }
                                                </div>

                                                <div className="col-12 col-lg-1"></div>
                                            </div>

                                        </div>
                                        <button className='btn btn-sm salesforce-style rounded-pill px-4 py-1' type='button' disabled={!updateButtonEnabled || !overrideButtonEnabled} onClick={(e) => { updateNewSchedule(e, "SignUp"); }}>Update</button> */}

                                    </div>
                                    <div className="card-footer">
                                        <div className='d-flex align-items-center justify-content-center gap-4 w-100'>
                                            <button onClick={() => { backtoStep4(); }} className="salesforce-style rounded-pill px-3 border-0">previous</button>
                                            <button onClick={() => { finalStep(); }} className="salesforce-style rounded-pill px-3 border-0">final</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountSetup