import React from 'react';
import NaturalResourcesBannerPng from "../../images/Natural resources Banner.png";
import Header from '../header/Header';
import Footer from '../footer/Footer';

const NaturalResources = () => {
    return (
        <>
            <div className="heWhoRemains">
                <Header />
                <div>
                    <section>
                        <div className="container mt-5">
                            <div className="row">

                                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                                    <div className="mt-4 ">
                                        <h1 className=" display-4 fw-bold smd_text">Schedule AI for <br />
                                            <span style={{ color: "#0B5ED7" }}> Natural Resources
                                            </span>
                                        </h1>
                                        <p className="lead_smd text-black" style={{ fontSize: "1.1em" }}>
                                            There are many benefits of using the appointment scheduling software for natural resources. When it comes to natural resources, one of the benefits of using ScheduleAI software is that it can help to streamline the process. In other words, it can help to make sure that appointments are made in a timely and efficient manner.
                                        </p>
                                        <a href="/login"><button
                                            className="loginbtn btn btn-sm px-4 py-2 btn-primary fw-0 smd_button" type="submit">Get a
                                            Demo</button></a>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                                    <img className="w-100" src={NaturalResourcesBannerPng} alt='Natural resources Banner' />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container mt-5">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold text-center">Benefits of Schedule AI for<br /> Natural Resources
                                </h2>
                            </div>

                            <ul>
                                <li className='liListDevind'>With ScheduleAI software, you can quickly and easily schedule appointments with clients or customers without manually setting up each meeting

                                </li>

                                <li className='liListDevind'>Using ScheduleAI software is that it can help improve organization and communication within your business.
                                </li>

                                <li className='liListDevind'>This can help ensure that everyone is on the same page and no important meetings are forgotten about or missed.

                                </li>

                                <li className='liListDevind'>This type of software can help you keep track of your appointments, deadlines, and tasks
                                </li>

                                <li className='liListDevind'>
                                    It can also help you manage your time more efficiently
                                </li>
                            </ul>

                        </div>
                    </section>


                    <section>
                        <div className="container textDevLay pb-4">
                            <div className="column pt-5">
                                <h2 className="display-6 fw-bold">Conclusion
                                </h2>
                                <p className='lead_smd text-justify'>ScheduleAI software can help you communicate with your team members and clients more effectively.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default NaturalResources
